import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { DocsApi } from "api-services/DocsApi";
import {
    autoDocService,
    getAutoContracts,
} from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import { Container, DocumentName, NewIcon } from "components";
import { Icon } from "components/Icon/Icon";
import { createAndRedirectToDraft } from "components/Library";
import History from "history";
import EmptyDirImg from "img/tempEmpty.png";
import { UploadFileStatus } from "proto/PLegalUnit";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Actions } from "store";
import { PDoc, PDocStatus } from "../../proto/PDoc";
import { activateDraft, callDialog } from "../../store/mainActions";
import { createdByMyAccount } from "../../store/mainStore";
import { DialogType, MainStore } from "../../store/mainTypes";
import { documentToImg, documentToName, documentToTitle } from "../ContractChooser";
import { formatDate } from "../utils/UtilFunctions";
import LifeCycleBox from "./LifeCycleBox";
import { ProgressBarForMobile, ProgressBarWithText } from "./ProgressBar";
import { usePlanState } from "hooks/usePlanState";

export interface DocsBaseProps {
    caption: string;
    noDocsMsg: string;
    relevantDocs: PDoc[];
    mainCTA: string;
    hideDuplicate?: boolean;
    headerButton?: JSX.Element;
    showRemind?: boolean;
    customCTA?: (doc: PDoc) => JSX.Element;
}

export default function DocsBase(props: DocsBaseProps) {
    const dispatch = useDispatch();

    useEffect(() => {
        DocsApi.getAllDocs(dispatch);
    }, []);

    return (
        <div className="w-full h-full" data-name="DocsBase">
            <div className="lg:flex justify-between w-full h-32 bg-white shadow-md border hidden">
                <Container className="flex justify-between items-center">
                    <div className="flex gap-2 text-2xl items-center justify-start">
                        <p className="text-darkIndigo font-medium">My Documents</p>
                        <p className="text-primary">{">"}</p>
                        <p className="text-darkIndigo">{props.caption}</p>
                    </div>
                    <div>{props.headerButton}</div>
                </Container>
            </div>
            <div className="flex lg:hidden pb-8">
                <MobileLayout {...props} />
            </div>
            <div className="hidden lg:flex pb-8">
                <DesktopLayout {...props} />
            </div>
        </div>
    );
}

function MobileLayout(props: DocsBaseProps) {
    return (
        <div className="w-full h-full">
            <div className="flex flex-col w-full bg-primary h-33 pt-4">
                <Container>
                    <LifeCycleBox />
                </Container>
            </div>
            <Container className="-mt-20">
                {props.relevantDocs.length === 0 ? (
                    <EmptyDir noDocsMsg={props.noDocsMsg} />
                ) : (
                    <div className="flex flex-col w-full gap-6">
                        {props.relevantDocs.map((doc: PDoc) => (
                            <DocCard
                                key={doc.id}
                                doc={doc}
                                mainCTA={props.mainCTA}
                                hideDuplicate={!!props.hideDuplicate}
                                showRemind={!!props.showRemind}
                                customCTA={props.customCTA}
                            />
                        ))}
                    </div>
                )}
            </Container>
        </div>
    );
}

function DesktopLayout(props: DocsBaseProps) {
    return (
        <Container>
            <div className="flex flex-col lg:flex-row gap-5 mt-7">
                <LifeCycleBox />
                {props.relevantDocs.length === 0 ? (
                    <EmptyDir noDocsMsg={props.noDocsMsg} />
                ) : (
                    <div className="flex flex-col w-full gap-4">
                        {props.relevantDocs.map((doc: PDoc) => (
                            <DocCard
                                key={doc.id}
                                doc={doc}
                                mainCTA={props.mainCTA}
                                hideDuplicate={!!props.hideDuplicate}
                                showRemind={!!props.showRemind}
                                customCTA={props.customCTA}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Container>
    );
}

function EmptyDir({ noDocsMsg }: { noDocsMsg: string }) {
    const history = useHistory();

    return (
        <div className="flex flex-col justify-center items-center w-full py-8">
            <img className="w-96" src={EmptyDirImg} alt="Empty Directory" />
            <p className="text-darkIndigo text-2xl mt-4">{"No contracts to see here :)"}</p>
            <p className="text-darkIndigo text-lg">{noDocsMsg}</p>
            <button
                onClick={() => {
                    history.push("/library");
                }}
                className="bg-primary text-white text-lg mt-4 px-4 py-2 rounded-lg"
            >
                Go to Library
            </button>
        </div>
    );
}

export function DocCard(props: {
    doc: PDoc;
    mainCTA: string;
    hideDuplicate: boolean;
    showRemind: boolean;
    customCTA: ((doc: PDoc) => JSX.Element) | undefined;
}) {
    const history = useHistory();
    const img = documentToImg(props.doc);
    const { isDocumentLocked } = usePlanState();

    return (
        <div>
            <div
                onClick={() => {
                    if (props.customCTA) {
                        return;
                    }

                    const specificStep = isDocumentLocked(props.doc.id) ? "finish/1" : undefined;
                    continueDraft(props.doc, history, specificStep);
                }}
                className="flex flex-col xl:flex-row bg-white border-lightSkyBlue border-opacity-50 xl:border rounded-b-sm xl:rounded-b-lg rounded-lg px-4 py-4 md:h-52 xl:h-38 justify-between cursor-pointer
            shadow-card hover:shadow-cardHover bg-lifeCycleCard backdrop-blur-md
            "
            >
                <div className="flex justify-start xl:justify-center items-center gap-4 xl:gap-12">
                    {img ? <img className="w-20 xl:w-32 aspect-auto" src={img} /> : <div />}
                    <CardTexts doc={props.doc} />
                </div>
                <CardButtons
                    doc={props.doc}
                    mainCTA={props.mainCTA}
                    hideDuplicate={props.hideDuplicate}
                    showRemind={props.showRemind}
                    customCTA={props.customCTA}
                />
            </div>

            {props.doc.docStatus === PDocStatus.DRAFT && (
                <div className="flex xl:hidden">
                    <ProgressBarForMobile doc={props.doc} />
                </div>
            )}
        </div>
    );
}

function CardTexts({ doc }: { doc: PDoc }) {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const ownerEmail = doc.permanentContext?.ownerUser?.user?.email || dbUser?.email;
    const sharedWithEmail = doc.permanentContext?.sharedWithUser?.user?.email;

    return (
        <div className="flex flex-col">
            <p className="text-darkIndigo text-base xl:text-lg font-medium">
                {documentToTitle(doc)}
            </p>

            <div className="flex gap-4">
                <DocumentName doc={doc} />
                {doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.ANALYZED && (
                    <AnalyzedLabel />
                )}
            </div>

            <p className="hidden md:block text-darkIndigo text-[15px] mt-4 flex-col xl:flex-row xl:block">
                <span>
                    Created by:{" "}
                    <span className="text-primary">
                        {ownerEmail}
                        {" • "}
                    </span>
                </span>
                <span>
                    {"Created on: "}
                    <span className="text-primary">
                        {formatDate(doc.legalUnit?.createdAt)}
                        {sharedWithEmail ? " • " : ""}
                    </span>
                </span>
                <span>
                    {sharedWithEmail && (
                        <span>
                            {"Shared with: "}
                            <span className="text-primary">{sharedWithEmail}</span>
                        </span>
                    )}
                </span>
            </p>
        </div>
    );
}

function CardButtons({
    doc,
    mainCTA,
    hideDuplicate,
    showRemind,
    customCTA,
}: {
    doc: PDoc;
    mainCTA: string;
    hideDuplicate: boolean;
    showRemind?: boolean;
    customCTA: ((doc: PDoc) => JSX.Element) | undefined;
}) {
    const myAccountName = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.dbUser?.accountName
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const { isDocumentLocked } = usePlanState();

    async function tryDeleteDoc(doc: PDoc) {
        dispatch(
            callDialog(
                DialogType.CONFIRM,
                "Are you sure you want to delete this doc?",
                (answer: boolean) => {
                    if (answer) {
                        DocsApi.deleteDraft(doc.id, () => DocsApi.getAllDocs(dispatch));
                    }
                }
            )
        );
    }

    return (
        <div className="flex flex-col items-justify justify-center gap-4 mt-4 xl:mt-0">
            <div className="flex flex-row gap-2 items-center justify-center">
                {createdByMyAccount(myAccountName, doc.id) ? (
                    <div className="flex flex-row gap-2 items-center justify-end w-full">
                        {isDocumentLocked(doc.id) && (
                            <SimpleTooltip
                                element={
                                    <button
                                        type="button"
                                        className="flex justify-center cursor-pointer bg-secondary items-center border rounded-lg px-2 py-2 group"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            history.push("/settings/usage");
                                        }}
                                    >
                                        <NewIcon name="lock" />
                                    </button>
                                }
                            >
                                <p className="flex items-center justify-center text-darkIndigo text-sm">
                                    This document is locked. Please upgrade your plan to modify it.
                                </p>
                            </SimpleTooltip>
                        )}
                        <SimpleTooltip
                            element={
                                <button
                                    type="button"
                                    className="flex justify-center cursor-pointer items-center border-powderBlue border rounded-lg px-2 py-2 group hover:border-warnRed hover:text-warnRed"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        tryDeleteDoc(doc);
                                    }}
                                >
                                    <NewIcon name="remove" className="group-hover:fill-warnRed" />
                                </button>
                            }
                        >
                            <p className="flex items-center justify-center text-darkIndigo text-sm">
                                Delete
                            </p>
                        </SimpleTooltip>

                        {hideDuplicate ? null : (
                            <SimpleTooltip
                                element={
                                    <button
                                        type="button"
                                        className="flex justify-center items-center cursor-pointer border-powderBlue border rounded-lg px-2 py-2 group hover:border-primary hover:text-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            let autoContract = getAutoContracts().find(
                                                (z) => z.kind === doc.legalUnit?.kind
                                            );

                                            if (autoContract) {
                                                dispatch(
                                                    callDialog(
                                                        DialogType.CONFIRM,
                                                        "A duplicate of this document will be created in Draft mode under My Docs > Drafts.\
                                \n\nMake sure to carefully review all your answers (names, dates and more) to thoroughly adjust them.",
                                                        (answer: boolean) => {
                                                            if (answer) {
                                                                createAndRedirectToDraft(
                                                                    autoContract!,
                                                                    dispatch,
                                                                    history,
                                                                    dbUser,
                                                                    doc
                                                                );
                                                            }
                                                        }
                                                    )
                                                );
                                            } else {
                                                dispatch(
                                                    callDialog(
                                                        DialogType.FAILURE,
                                                        "This contract cannot be duplicated"
                                                    )
                                                );
                                            }
                                        }}
                                    >
                                        <NewIcon
                                            name="library_add"
                                            className="group-hover:fill-primary"
                                        />
                                    </button>
                                }
                            >
                                <p className="flex items-center justify-center text-darkIndigo text-sm">
                                    Duplicate
                                </p>
                            </SimpleTooltip>
                        )}
                    </div>
                ) : null}

                {!showRemind ? null : (
                    <SimpleTooltip
                        element={
                            <button
                                type="button"
                                className="flex justify-center items-center cursor-pointer border-powderBlue border rounded-lg px-2 py-2 group hover:border-primary hover:text-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    const ownerEmail =
                                        doc.permanentContext?.ownerUser?.user?.email ||
                                        dbUser?.email;
                                    const sharedWithEmail =
                                        doc.permanentContext?.sharedWithUser?.user?.email;
                                    let email = ownerEmail;

                                    if (doc.docStatus === PDocStatus.SENT) {
                                        email = sharedWithEmail;
                                    }

                                    dispatch(
                                        callDialog(
                                            DialogType.CONFIRM,
                                            `A reminder will be sent to ${email} to sign the contract.`,
                                            (answer: boolean) => {
                                                if (answer) {
                                                    DocsApi.remindSigners(
                                                        doc.id,
                                                        () => {
                                                            dispatch(
                                                                callDialog(
                                                                    DialogType.SUCCESS,
                                                                    `Reminder sent to ${email}. They will receive an email with a link to sign the contract.\r\n\r\n (If they don't receive one, make sure they check their spam folder)`
                                                                )
                                                            );
                                                        },
                                                        () => {
                                                            dispatch(
                                                                callDialog(
                                                                    DialogType.FAILURE,
                                                                    `Failed to send reminder to ${email}.`
                                                                )
                                                            );
                                                        }
                                                    );
                                                }
                                            }
                                        )
                                    );
                                }}
                            >
                                <NewIcon name="bell" className="group-hover:fill-primary" />
                            </button>
                        }
                    >
                        <p className="flex items-center justify-center text-darkIndigo text-sm">
                            Remind
                        </p>
                    </SimpleTooltip>
                )}

                {customCTA ? (
                    customCTA(doc)
                ) : (
                    <button
                        type="button"
                        className="flex justify-center text-white text-base w-full bg-primary text-center hover:bg-primaryHover border-primary border rounded-lg px-7 py-2 overflow-hidden whitespace-nowrap"
                        onClick={() => continueDraft(doc, history)}
                    >
                        {mainCTA}
                    </button>
                )}
            </div>
            {doc.docStatus === PDocStatus.DRAFT && <ProgressBarWithText doc={doc} />}
        </div>
    );
}

export function defaultCTA(doc: PDoc, dispatch: Dispatch<any>) {
    dispatch(activateDraft(doc));

    if (doc.legalUnit?.docVersion) {
        autoDocService.loadContract(doc.legalUnit.docVersion).then((data) => {
            dispatch(Actions.agreementManager.setCurrentAgreement(data));
        });
    }
}

export const continueDraft = (draft: PDoc, history: History.History, specificStep?: string) => {
    const gotoStep = specificStep ? specificStep : draft.legalUnit?.lastStep;

    if (gotoStep) {
        // todo - use consts
        if (gotoStep.endsWith("finish/payment-plans")) {
            history.push(`/${draft.id}/finish/1`);
        } else {
            history.push(`/${draft.id}/${gotoStep}`);
        }
    } else {
        history.push(`/${draft.id}/A/1`);
    }
};

function SimpleTooltip({
    element,
    children,
}: {
    element: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={100}>
                <TooltipPrimitive.Trigger asChild>{element}</TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <TooltipPrimitive.Content
                        side="top"
                        className="relative m-2 text-center px-4 py-2 bg-white shadow-card rounded-lg border border-powderBlue"
                    >
                        <div className="absolute -bottom-0.5 left-1/2 -translate-x-1/2 w-2 h-2 bg-white rotate-45 border-b border-r border-powderBlue translate-y-1/2" />
                        {children}
                    </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
}

function AnalyzedLabel() {
    return (
        <div className="flex items-center justify-center bg-greens-100 rounded-full pl-1 pr-4">
            <NewIcon
                name="checkmark"
                className="fill-white bg-greens-400 rounded-full scale-[65%]"
            />
            <p className="text-darkIndigo text-sm font-medium">Analyzed</p>
        </div>
    );
}
