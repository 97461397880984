import firebase from "firebase/app"
import "firebase/auth"
import { inIframe } from "utils/iframe-utils"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

// In case it's monday, we don't want to persist the user's session
if (inIframe()) {
  const query = window.location.search
  const params = new URLSearchParams(query)

  const isMonday = params.get("monday") === "true"

  if (isMonday) {
    app.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
  }
}

export const auth = app.auth()
export default app
