/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export interface FullResignationLetter {
  effectiveDate: Date | undefined;
  companyFull: string;
  companyAddressOther: string;
  currCompanyAddress: string;
  companyEmail: string;
  companyName: string;
  dateResignation: Date | undefined;
  conditionText: string;
  condition: InternalBoolean;
  signerName: string;
}

function createBaseFullResignationLetter(): FullResignationLetter {
  return {
    effectiveDate: undefined,
    companyFull: "",
    companyAddressOther: "",
    currCompanyAddress: "",
    companyEmail: "",
    companyName: "",
    dateResignation: undefined,
    conditionText: "",
    condition: 0,
    signerName: "",
  };
}

export const FullResignationLetter = {
  encode(message: FullResignationLetter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyFull !== "") {
      writer.uint32(18).string(message.companyFull);
    }
    if (message.companyAddressOther !== "") {
      writer.uint32(26).string(message.companyAddressOther);
    }
    if (message.currCompanyAddress !== "") {
      writer.uint32(34).string(message.currCompanyAddress);
    }
    if (message.companyEmail !== "") {
      writer.uint32(42).string(message.companyEmail);
    }
    if (message.companyName !== "") {
      writer.uint32(50).string(message.companyName);
    }
    if (message.dateResignation !== undefined) {
      Timestamp.encode(toTimestamp(message.dateResignation), writer.uint32(58).fork()).ldelim();
    }
    if (message.conditionText !== "") {
      writer.uint32(66).string(message.conditionText);
    }
    if (message.condition !== 0) {
      writer.uint32(72).int32(message.condition);
    }
    if (message.signerName !== "") {
      writer.uint32(82).string(message.signerName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullResignationLetter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullResignationLetter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyAddressOther = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.currCompanyAddress = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.companyEmail = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.companyName = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.dateResignation = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.conditionText = reader.string();
          continue;
        case 9:
          if (tag != 72) {
            break;
          }

          message.condition = reader.int32() as any;
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.signerName = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullResignationLetter {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyAddressOther: isSet(object.companyAddressOther) ? String(object.companyAddressOther) : "",
      currCompanyAddress: isSet(object.currCompanyAddress) ? String(object.currCompanyAddress) : "",
      companyEmail: isSet(object.companyEmail) ? String(object.companyEmail) : "",
      companyName: isSet(object.companyName) ? String(object.companyName) : "",
      dateResignation: isSet(object.dateResignation) ? fromJsonTimestamp(object.dateResignation) : undefined,
      conditionText: isSet(object.conditionText) ? String(object.conditionText) : "",
      condition: isSet(object.condition) ? internalBooleanFromJSON(object.condition) : 0,
      signerName: isSet(object.signerName) ? String(object.signerName) : "",
    };
  },

  toJSON(message: FullResignationLetter): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyAddressOther !== undefined && (obj.companyAddressOther = message.companyAddressOther);
    message.currCompanyAddress !== undefined && (obj.currCompanyAddress = message.currCompanyAddress);
    message.companyEmail !== undefined && (obj.companyEmail = message.companyEmail);
    message.companyName !== undefined && (obj.companyName = message.companyName);
    message.dateResignation !== undefined && (obj.dateResignation = message.dateResignation.toISOString());
    message.conditionText !== undefined && (obj.conditionText = message.conditionText);
    message.condition !== undefined && (obj.condition = internalBooleanToJSON(message.condition));
    message.signerName !== undefined && (obj.signerName = message.signerName);
    return obj;
  },

  create<I extends Exact<DeepPartial<FullResignationLetter>, I>>(base?: I): FullResignationLetter {
    return FullResignationLetter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FullResignationLetter>, I>>(object: I): FullResignationLetter {
    const message = createBaseFullResignationLetter();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyFull = object.companyFull ?? "";
    message.companyAddressOther = object.companyAddressOther ?? "";
    message.currCompanyAddress = object.currCompanyAddress ?? "";
    message.companyEmail = object.companyEmail ?? "";
    message.companyName = object.companyName ?? "";
    message.dateResignation = object.dateResignation ?? undefined;
    message.conditionText = object.conditionText ?? "";
    message.condition = object.condition ?? 0;
    message.signerName = object.signerName ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
