interface Position {
    open: { line: number; start: number; end?: number };
    close?: { line: number; start: number; end?: number };
}

export interface BaseNode {
    parent: BlockNode<any> | undefined;
    fullMatch: string;
    position?: Position;
}
export interface TextNode extends BaseNode {
    content: string;
    type: "text";
}

export interface BlockNode<type = string> extends BaseNode {
    children: Nodes[];
    type: type;
    payload: Nodes[]; // text in open tag without tags
}

export interface RootNode extends BlockNode<"root"> {
    type: "root";
}
export interface ConditionNode extends BlockNode<"conditional"> {
    type: "conditional";
}
export interface KbNode extends BlockNode<"kb"> {
    type: "kb";
}
export interface OrderedListNode extends BlockNode<"ol"> {
    type: "ol";
}

export type Nodes = TextNode | RootNode | OrderedListNode | KbNode | ConditionNode;

// export interface Node {
//     type: string;
//     content: string;
//     fullMatch: string;
//     parent: Node | undefined;
//     type: "root" | "conditional" | "kb" | "regular" | "text";
//     index: number;
//     children: Node[];
// }

export const VALID_TAGS = ["if", "kb", "ol"];

// const blockRegex = /<<([a-zA-Z]+)((?:(?!<<).|[\s\n])+)>>|<<\/([a-zA-Z]+)>>/g;
// const blockRegex = /<<([a-zA-Z]+)((?:(?!<<).|[\n])*)>>|<<\/([a-zA-Z]+)>>/g;
const blockRegex = /<<(?<openTag>kb|if)|(?<openTagClose>>>)|(?<closeTag><<\/[a-zA-Z]+>>)/g;

enum MessageCode {
    MISSING_OPEN_TAG,
    MISSING_CLOSE_TAG,
    INVALID_TAG_NAME,
    OPEN_TAG__END_BEFORE_OPEN_TAG_START,
    CLOSE_TAG_BEFORE_OPEN_TAG,
    KB_WITH_CONDITIONAL_SINGLE_CHILD,
}

// markdown
// fillable
// condition
// kb
// signature
export function preprocess({ txt, root }: { txt: string; root?: BlockNode }) {
    const lines = txt.split("\n");

    const linesNumbers = lines?.reduce<{ map: { [key: number]: number }; total: number }>(
        (acc, line, index) => {
            if (index > 0) {
                // \n char
                acc.map[acc.total] = +index;
                acc.total++;
            }
            for (const char in [...line]) {
                acc.map[acc.total] = +index + 1;
                acc.total++;
            }
            return acc;
        },
        { total: 0, map: {} }
    );

    const getLineIndex = (i: number) => linesNumbers.map[i];

    let tree: BlockNode<any> = root || {
        parent: undefined,
        fullMatch: "",
        type: "root",
        children: [],
        payload: [],
        position: {
            open: { line: 1, start: 0, end: 0 },
            close: {
                line: lines.length,
                start: txt.length,
                end: txt.length,
            },
        },
    };

    let lastNode: BlockNode<any> = tree;
    let messages: {
        type: "warning" | "error";
        description: string;
        code: MessageCode;
        position: {
            start: number;
            end: number;
            line: number;
            length: number;
        };
    }[] = [];

    // todo: postion nodes base on lines

    function appendNewNode(node: BlockNode<any> | TextNode) {
        if (lastNode.position?.open.end !== undefined) {
            // last node already closed, append child
            lastNode.children.push(node);
        } else {
            // last node is open, append payload
            lastNode.payload.push(node);
        }
    }

    // lines.forEach((line, lineIndex) => {
    // const txt = line;
    const matches = [...txt.matchAll(blockRegex)];
    const line = txt;

    const lastMatch = matches[matches.length - 1];
    const contentAfter = txt.substring(lastMatch ? lastMatch.index! + lastMatch[0].length : 0, txt.length);

    matches.forEach((match, matchIndex) => {
        const [fullMatch] = match;
        const index = match.index ?? -1; // todo - error handling
        const startMatch = match.index ?? 0;
        const endMatch = startMatch + match[0].length;
        const groups = match.groups! as { closeTag: string; openTag: string; openTagClose: string };
        const { openTag, closeTag, openTagClose } = groups;

        const lineIndex = getLineIndex(startMatch);

        const nextMatch = matches[matchIndex + 1];
        const prevMatch = matches[matchIndex - 1];

        const contentBefore = txt.substring(
            prevMatch?.index !== undefined ? prevMatch.index + prevMatch[0].length : 0,
            index
        );
        const contentAfter = txt.substring(endMatch, nextMatch?.index ?? txt.length);

        // contentBefore
        if (contentBefore) {
            const textNode: TextNode = {
                parent: lastNode,
                content: contentBefore,
                type: "text",
                position: {
                    open: { line: lineIndex, start: 0, end: 0 },
                    close: { line: lineIndex, start: contentBefore.length, end: contentBefore.length },
                },
                fullMatch: contentBefore,
            };
            appendNewNode(textNode);
        }

        if (openTag) {
            // start new block
            const node: BlockNode<string> = {
                fullMatch,
                parent: lastNode,
                type: getNodeTypeByTag(openTag),
                children: [],
                payload: [],
                position: {
                    open: { line: lineIndex, start: startMatch },
                },
            };
            appendNewNode(node);

            lastNode = node;
        }
        if (closeTag) {
            // close current block
            if (lastNode.parent) {
                if (lastNode.position) {
                    lastNode.position.close = { line: lineIndex, start: startMatch, end: endMatch };
                    if (closeTag === "<</kb>>") {
                        if (lastNode.children.length === 1 && lastNode.children[0].type === "conditional") {
                            messages.push({
                                code: MessageCode.KB_WITH_CONDITIONAL_SINGLE_CHILD,
                                description: "kb cant wrap conditional as single child",
                                type: "error",
                                position: {
                                    start: startMatch,
                                    end: startMatch + closeTag.length,
                                    line: getLineIndex(startMatch),
                                    length: closeTag.length,
                                },
                            });
                        }
                    }
                    lastNode = lastNode.parent;
                } else {
                    // error close tag before open tag
                    console.error("close tag without position", lastNode);
                }
            } else {
                messages.push({
                    code: MessageCode.CLOSE_TAG_BEFORE_OPEN_TAG,
                    description: "close tag appear before open tag",
                    type: "error",
                    position: {
                        start: startMatch,
                        end: startMatch + closeTag.length,
                        line: getLineIndex(startMatch),
                        length: closeTag.length,
                    },
                });
            }
        }
        if (openTagClose && lastNode.position) {
            // close open tag end
            if (lastNode.position.open.end !== undefined) {
                messages.push({
                    code: MessageCode.OPEN_TAG__END_BEFORE_OPEN_TAG_START,
                    description: "close open tag appear before open tag",
                    type: "error",
                    position: {
                        start: startMatch,
                        end: startMatch + openTagClose.length,
                        line: getLineIndex(startMatch),
                        length: openTagClose.length,
                    },
                });
            } else {
                lastNode.position.open.end = endMatch;
            }
        }
    });

    if (contentAfter) {
        const textNode: TextNode = {
            parent: lastNode,
            content: contentAfter,
            type: "text",
            position: {
                open: { line: 1, start: 0, end: 0 },
                close: { line: lines.length, start: contentAfter.length, end: contentAfter.length },
            },
            fullMatch: contentAfter,
        };
        appendNewNode(textNode);
    }
    // });

    return { tree, messages, linesNumbers };
}

const TAG_TO_TYPE_MAP: { [key: string]: Nodes["type"] } = {
    if: "conditional",
    kb: "kb",
    ol: "ol",
};

function getNodeTypeByTag(tag: string) {
    if (TAG_TO_TYPE_MAP.hasOwnProperty(tag)) {
        return TAG_TO_TYPE_MAP[tag as keyof typeof TAG_TO_TYPE_MAP];
    }
    console.warn("getNodeTypeByTag invalid tag name: ", tag);
    return "text";
}
