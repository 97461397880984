/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EProposalOfServicesFeeBasis {
  ProposalOfServices_feeBasis_NA = 0,
  ProposalOfServices_feeBasis_FIXED = 1,
  ProposalOfServices_feeBasis_HOURLY = 2,
  ProposalOfServices_feeBasis_DAILY = 3,
  ProposalOfServices_feeBasis_WEEKLY = 4,
  ProposalOfServices_feeBasis_MONTHLY = 5,
  ProposalOfServices_feeBasis_CUSTOM = 6,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesFeeBasisFromJSON(object: any): EProposalOfServicesFeeBasis {
  switch (object) {
    case 0:
    case "ProposalOfServices_feeBasis_NA":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_NA;
    case 1:
    case "ProposalOfServices_feeBasis_FIXED":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_FIXED;
    case 2:
    case "ProposalOfServices_feeBasis_HOURLY":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_HOURLY;
    case 3:
    case "ProposalOfServices_feeBasis_DAILY":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_DAILY;
    case 4:
    case "ProposalOfServices_feeBasis_WEEKLY":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_WEEKLY;
    case 5:
    case "ProposalOfServices_feeBasis_MONTHLY":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_MONTHLY;
    case 6:
    case "ProposalOfServices_feeBasis_CUSTOM":
      return EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesFeeBasis.UNRECOGNIZED;
  }
}

export function eProposalOfServicesFeeBasisToJSON(object: EProposalOfServicesFeeBasis): string {
  switch (object) {
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_NA:
      return "ProposalOfServices_feeBasis_NA";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_FIXED:
      return "ProposalOfServices_feeBasis_FIXED";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_HOURLY:
      return "ProposalOfServices_feeBasis_HOURLY";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_DAILY:
      return "ProposalOfServices_feeBasis_DAILY";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_WEEKLY:
      return "ProposalOfServices_feeBasis_WEEKLY";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_MONTHLY:
      return "ProposalOfServices_feeBasis_MONTHLY";
    case EProposalOfServicesFeeBasis.ProposalOfServices_feeBasis_CUSTOM:
      return "ProposalOfServices_feeBasis_CUSTOM";
    case EProposalOfServicesFeeBasis.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EProposalOfServicesEstimateTimeDate {
  ProposalOfServices_estimateTimeDate_NA = 0,
  ProposalOfServices_estimateTimeDate_DURATION = 1,
  ProposalOfServices_estimateTimeDate_COMPLETIONDATE = 2,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesEstimateTimeDateFromJSON(object: any): EProposalOfServicesEstimateTimeDate {
  switch (object) {
    case 0:
    case "ProposalOfServices_estimateTimeDate_NA":
      return EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_NA;
    case 1:
    case "ProposalOfServices_estimateTimeDate_DURATION":
      return EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_DURATION;
    case 2:
    case "ProposalOfServices_estimateTimeDate_COMPLETIONDATE":
      return EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_COMPLETIONDATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesEstimateTimeDate.UNRECOGNIZED;
  }
}

export function eProposalOfServicesEstimateTimeDateToJSON(object: EProposalOfServicesEstimateTimeDate): string {
  switch (object) {
    case EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_NA:
      return "ProposalOfServices_estimateTimeDate_NA";
    case EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_DURATION:
      return "ProposalOfServices_estimateTimeDate_DURATION";
    case EProposalOfServicesEstimateTimeDate.ProposalOfServices_estimateTimeDate_COMPLETIONDATE:
      return "ProposalOfServices_estimateTimeDate_COMPLETIONDATE";
    case EProposalOfServicesEstimateTimeDate.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EProposalOfServicesInvoiceFreq {
  ProposalOfServices_invoiceFreq_NA = 0,
  ProposalOfServices_invoiceFreq_MADVANCE = 1,
  ProposalOfServices_invoiceFreq_MARREARS = 2,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesInvoiceFreqFromJSON(object: any): EProposalOfServicesInvoiceFreq {
  switch (object) {
    case 0:
    case "ProposalOfServices_invoiceFreq_NA":
      return EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_NA;
    case 1:
    case "ProposalOfServices_invoiceFreq_MADVANCE":
      return EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_MADVANCE;
    case 2:
    case "ProposalOfServices_invoiceFreq_MARREARS":
      return EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_MARREARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesInvoiceFreq.UNRECOGNIZED;
  }
}

export function eProposalOfServicesInvoiceFreqToJSON(object: EProposalOfServicesInvoiceFreq): string {
  switch (object) {
    case EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_NA:
      return "ProposalOfServices_invoiceFreq_NA";
    case EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_MADVANCE:
      return "ProposalOfServices_invoiceFreq_MADVANCE";
    case EProposalOfServicesInvoiceFreq.ProposalOfServices_invoiceFreq_MARREARS:
      return "ProposalOfServices_invoiceFreq_MARREARS";
    case EProposalOfServicesInvoiceFreq.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EProposalOfServicesRemainderType {
  ProposalOfServices_remainderType_NA = 0,
  ProposalOfServices_remainderType_FULL_ADVANCE = 1,
  ProposalOfServices_remainderType_FULL_END = 2,
  ProposalOfServices_remainderType_INITIAL = 3,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesRemainderTypeFromJSON(object: any): EProposalOfServicesRemainderType {
  switch (object) {
    case 0:
    case "ProposalOfServices_remainderType_NA":
      return EProposalOfServicesRemainderType.ProposalOfServices_remainderType_NA;
    case 1:
    case "ProposalOfServices_remainderType_FULL_ADVANCE":
      return EProposalOfServicesRemainderType.ProposalOfServices_remainderType_FULL_ADVANCE;
    case 2:
    case "ProposalOfServices_remainderType_FULL_END":
      return EProposalOfServicesRemainderType.ProposalOfServices_remainderType_FULL_END;
    case 3:
    case "ProposalOfServices_remainderType_INITIAL":
      return EProposalOfServicesRemainderType.ProposalOfServices_remainderType_INITIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesRemainderType.UNRECOGNIZED;
  }
}

export function eProposalOfServicesRemainderTypeToJSON(object: EProposalOfServicesRemainderType): string {
  switch (object) {
    case EProposalOfServicesRemainderType.ProposalOfServices_remainderType_NA:
      return "ProposalOfServices_remainderType_NA";
    case EProposalOfServicesRemainderType.ProposalOfServices_remainderType_FULL_ADVANCE:
      return "ProposalOfServices_remainderType_FULL_ADVANCE";
    case EProposalOfServicesRemainderType.ProposalOfServices_remainderType_FULL_END:
      return "ProposalOfServices_remainderType_FULL_END";
    case EProposalOfServicesRemainderType.ProposalOfServices_remainderType_INITIAL:
      return "ProposalOfServices_remainderType_INITIAL";
    case EProposalOfServicesRemainderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EProposalOfServicesEarlyTermFee {
  ProposalOfServices_earlyTermFee_NA = 0,
  ProposalOfServices_earlyTermFee_COMPLETED = 3,
  ProposalOfServices_earlyTermFee_VALUE = 2,
  ProposalOfServices_earlyTermFee_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesEarlyTermFeeFromJSON(object: any): EProposalOfServicesEarlyTermFee {
  switch (object) {
    case 0:
    case "ProposalOfServices_earlyTermFee_NA":
      return EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_NA;
    case 3:
    case "ProposalOfServices_earlyTermFee_COMPLETED":
      return EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_COMPLETED;
    case 2:
    case "ProposalOfServices_earlyTermFee_VALUE":
      return EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_VALUE;
    case 999999:
    case "ProposalOfServices_earlyTermFee_OTHER":
      return EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesEarlyTermFee.UNRECOGNIZED;
  }
}

export function eProposalOfServicesEarlyTermFeeToJSON(object: EProposalOfServicesEarlyTermFee): string {
  switch (object) {
    case EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_NA:
      return "ProposalOfServices_earlyTermFee_NA";
    case EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_COMPLETED:
      return "ProposalOfServices_earlyTermFee_COMPLETED";
    case EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_VALUE:
      return "ProposalOfServices_earlyTermFee_VALUE";
    case EProposalOfServicesEarlyTermFee.ProposalOfServices_earlyTermFee_OTHER:
      return "ProposalOfServices_earlyTermFee_OTHER";
    case EProposalOfServicesEarlyTermFee.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EProposalOfServicesContinuing {
  ProposalOfServices_continuing_NA = 0,
  ProposalOfServices_continuing_DATE = 1,
  ProposalOfServices_continuing_COMPLETION = 2,
  ProposalOfServices_continuing_ONGOING = 3,
  UNRECOGNIZED = -1,
}

export function eProposalOfServicesContinuingFromJSON(object: any): EProposalOfServicesContinuing {
  switch (object) {
    case 0:
    case "ProposalOfServices_continuing_NA":
      return EProposalOfServicesContinuing.ProposalOfServices_continuing_NA;
    case 1:
    case "ProposalOfServices_continuing_DATE":
      return EProposalOfServicesContinuing.ProposalOfServices_continuing_DATE;
    case 2:
    case "ProposalOfServices_continuing_COMPLETION":
      return EProposalOfServicesContinuing.ProposalOfServices_continuing_COMPLETION;
    case 3:
    case "ProposalOfServices_continuing_ONGOING":
      return EProposalOfServicesContinuing.ProposalOfServices_continuing_ONGOING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EProposalOfServicesContinuing.UNRECOGNIZED;
  }
}

export function eProposalOfServicesContinuingToJSON(object: EProposalOfServicesContinuing): string {
  switch (object) {
    case EProposalOfServicesContinuing.ProposalOfServices_continuing_NA:
      return "ProposalOfServices_continuing_NA";
    case EProposalOfServicesContinuing.ProposalOfServices_continuing_DATE:
      return "ProposalOfServices_continuing_DATE";
    case EProposalOfServicesContinuing.ProposalOfServices_continuing_COMPLETION:
      return "ProposalOfServices_continuing_COMPLETION";
    case EProposalOfServicesContinuing.ProposalOfServices_continuing_ONGOING:
      return "ProposalOfServices_continuing_ONGOING";
    case EProposalOfServicesContinuing.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MProposalOfServicesFeeBasis {
  optionDbValue: EProposalOfServicesFeeBasis;
  other: string;
}

export interface MProposalOfServicesEstimateTimeDate {
  optionDbValue: EProposalOfServicesEstimateTimeDate;
  other: string;
}

export interface MProposalOfServicesInvoiceFreq {
  optionDbValue: EProposalOfServicesInvoiceFreq;
  other: string;
}

export interface MProposalOfServicesRemainderType {
  optionDbValue: EProposalOfServicesRemainderType;
  other: string;
}

export interface MProposalOfServicesEarlyTermFee {
  optionDbValue: EProposalOfServicesEarlyTermFee;
  other: string;
}

export interface MProposalOfServicesContinuing {
  optionDbValue: EProposalOfServicesContinuing;
  other: string;
}

export interface ProposalOfServices {
  companyShort: string;
  clientFull: string;
  expireDate: Date | undefined;
  companyServices: string[];
  kickoffDate: Date | undefined;
  workload: string;
  completionDate: Date | undefined;
  fixedFee: number;
  feeAmount: number;
  feeBasis: MProposalOfServicesFeeBasis | undefined;
  paymentDays: number;
  clientResponsibilities: InternalBoolean;
  clientTasks: string[];
  estimateTimeDate: MProposalOfServicesEstimateTimeDate | undefined;
  invoiceFreq: MProposalOfServicesInvoiceFreq | undefined;
  kickoffPayment: number;
  servicesEndDate: Date | undefined;
  noticePeriod: number;
  feeCustom: string;
  earlyTerm: InternalBoolean;
  remainderType: MProposalOfServicesRemainderType | undefined;
  earlyTermFee: MProposalOfServicesEarlyTermFee | undefined;
  timeLimit: InternalBoolean;
  timeLimitMax: number;
  limitFixedFee: InternalBoolean;
  limitFixedFeeList: string[];
  continuing: MProposalOfServicesContinuing | undefined;
}

function createBaseMProposalOfServicesFeeBasis(): MProposalOfServicesFeeBasis {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesFeeBasis = {
  encode(message: MProposalOfServicesFeeBasis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesFeeBasis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesFeeBasis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesFeeBasis {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eProposalOfServicesFeeBasisFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesFeeBasis): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesFeeBasisToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesFeeBasis>, I>>(base?: I): MProposalOfServicesFeeBasis {
    return MProposalOfServicesFeeBasis.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesFeeBasis>, I>>(object: I): MProposalOfServicesFeeBasis {
    const message = createBaseMProposalOfServicesFeeBasis();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMProposalOfServicesEstimateTimeDate(): MProposalOfServicesEstimateTimeDate {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesEstimateTimeDate = {
  encode(message: MProposalOfServicesEstimateTimeDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesEstimateTimeDate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesEstimateTimeDate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesEstimateTimeDate {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eProposalOfServicesEstimateTimeDateFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesEstimateTimeDate): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesEstimateTimeDateToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesEstimateTimeDate>, I>>(
    base?: I,
  ): MProposalOfServicesEstimateTimeDate {
    return MProposalOfServicesEstimateTimeDate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesEstimateTimeDate>, I>>(
    object: I,
  ): MProposalOfServicesEstimateTimeDate {
    const message = createBaseMProposalOfServicesEstimateTimeDate();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMProposalOfServicesInvoiceFreq(): MProposalOfServicesInvoiceFreq {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesInvoiceFreq = {
  encode(message: MProposalOfServicesInvoiceFreq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesInvoiceFreq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesInvoiceFreq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesInvoiceFreq {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eProposalOfServicesInvoiceFreqFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesInvoiceFreq): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesInvoiceFreqToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesInvoiceFreq>, I>>(base?: I): MProposalOfServicesInvoiceFreq {
    return MProposalOfServicesInvoiceFreq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesInvoiceFreq>, I>>(
    object: I,
  ): MProposalOfServicesInvoiceFreq {
    const message = createBaseMProposalOfServicesInvoiceFreq();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMProposalOfServicesRemainderType(): MProposalOfServicesRemainderType {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesRemainderType = {
  encode(message: MProposalOfServicesRemainderType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesRemainderType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesRemainderType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesRemainderType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eProposalOfServicesRemainderTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesRemainderType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesRemainderTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesRemainderType>, I>>(
    base?: I,
  ): MProposalOfServicesRemainderType {
    return MProposalOfServicesRemainderType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesRemainderType>, I>>(
    object: I,
  ): MProposalOfServicesRemainderType {
    const message = createBaseMProposalOfServicesRemainderType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMProposalOfServicesEarlyTermFee(): MProposalOfServicesEarlyTermFee {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesEarlyTermFee = {
  encode(message: MProposalOfServicesEarlyTermFee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesEarlyTermFee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesEarlyTermFee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesEarlyTermFee {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eProposalOfServicesEarlyTermFeeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesEarlyTermFee): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesEarlyTermFeeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesEarlyTermFee>, I>>(base?: I): MProposalOfServicesEarlyTermFee {
    return MProposalOfServicesEarlyTermFee.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesEarlyTermFee>, I>>(
    object: I,
  ): MProposalOfServicesEarlyTermFee {
    const message = createBaseMProposalOfServicesEarlyTermFee();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMProposalOfServicesContinuing(): MProposalOfServicesContinuing {
  return { optionDbValue: 0, other: "" };
}

export const MProposalOfServicesContinuing = {
  encode(message: MProposalOfServicesContinuing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MProposalOfServicesContinuing {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMProposalOfServicesContinuing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MProposalOfServicesContinuing {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eProposalOfServicesContinuingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MProposalOfServicesContinuing): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eProposalOfServicesContinuingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MProposalOfServicesContinuing>, I>>(base?: I): MProposalOfServicesContinuing {
    return MProposalOfServicesContinuing.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MProposalOfServicesContinuing>, I>>(
    object: I,
  ): MProposalOfServicesContinuing {
    const message = createBaseMProposalOfServicesContinuing();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseProposalOfServices(): ProposalOfServices {
  return {
    companyShort: "",
    clientFull: "",
    expireDate: undefined,
    companyServices: [],
    kickoffDate: undefined,
    workload: "",
    completionDate: undefined,
    fixedFee: 0,
    feeAmount: 0,
    feeBasis: undefined,
    paymentDays: 0,
    clientResponsibilities: 0,
    clientTasks: [],
    estimateTimeDate: undefined,
    invoiceFreq: undefined,
    kickoffPayment: 0,
    servicesEndDate: undefined,
    noticePeriod: 0,
    feeCustom: "",
    earlyTerm: 0,
    remainderType: undefined,
    earlyTermFee: undefined,
    timeLimit: 0,
    timeLimitMax: 0,
    limitFixedFee: 0,
    limitFixedFeeList: [],
    continuing: undefined,
  };
}

export const ProposalOfServices = {
  encode(message: ProposalOfServices, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyShort !== "") {
      writer.uint32(10).string(message.companyShort);
    }
    if (message.clientFull !== "") {
      writer.uint32(18).string(message.clientFull);
    }
    if (message.expireDate !== undefined) {
      Timestamp.encode(toTimestamp(message.expireDate), writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.companyServices) {
      writer.uint32(34).string(v!);
    }
    if (message.kickoffDate !== undefined) {
      Timestamp.encode(toTimestamp(message.kickoffDate), writer.uint32(42).fork()).ldelim();
    }
    if (message.workload !== "") {
      writer.uint32(50).string(message.workload);
    }
    if (message.completionDate !== undefined) {
      Timestamp.encode(toTimestamp(message.completionDate), writer.uint32(58).fork()).ldelim();
    }
    if (message.fixedFee !== 0) {
      writer.uint32(69).float(message.fixedFee);
    }
    if (message.feeAmount !== 0) {
      writer.uint32(77).float(message.feeAmount);
    }
    if (message.feeBasis !== undefined) {
      MProposalOfServicesFeeBasis.encode(message.feeBasis, writer.uint32(82).fork()).ldelim();
    }
    if (message.paymentDays !== 0) {
      writer.uint32(93).float(message.paymentDays);
    }
    if (message.clientResponsibilities !== 0) {
      writer.uint32(96).int32(message.clientResponsibilities);
    }
    for (const v of message.clientTasks) {
      writer.uint32(106).string(v!);
    }
    if (message.estimateTimeDate !== undefined) {
      MProposalOfServicesEstimateTimeDate.encode(message.estimateTimeDate, writer.uint32(114).fork()).ldelim();
    }
    if (message.invoiceFreq !== undefined) {
      MProposalOfServicesInvoiceFreq.encode(message.invoiceFreq, writer.uint32(122).fork()).ldelim();
    }
    if (message.kickoffPayment !== 0) {
      writer.uint32(133).float(message.kickoffPayment);
    }
    if (message.servicesEndDate !== undefined) {
      Timestamp.encode(toTimestamp(message.servicesEndDate), writer.uint32(138).fork()).ldelim();
    }
    if (message.noticePeriod !== 0) {
      writer.uint32(149).float(message.noticePeriod);
    }
    if (message.feeCustom !== "") {
      writer.uint32(154).string(message.feeCustom);
    }
    if (message.earlyTerm !== 0) {
      writer.uint32(160).int32(message.earlyTerm);
    }
    if (message.remainderType !== undefined) {
      MProposalOfServicesRemainderType.encode(message.remainderType, writer.uint32(170).fork()).ldelim();
    }
    if (message.earlyTermFee !== undefined) {
      MProposalOfServicesEarlyTermFee.encode(message.earlyTermFee, writer.uint32(178).fork()).ldelim();
    }
    if (message.timeLimit !== 0) {
      writer.uint32(184).int32(message.timeLimit);
    }
    if (message.timeLimitMax !== 0) {
      writer.uint32(197).float(message.timeLimitMax);
    }
    if (message.limitFixedFee !== 0) {
      writer.uint32(200).int32(message.limitFixedFee);
    }
    for (const v of message.limitFixedFeeList) {
      writer.uint32(210).string(v!);
    }
    if (message.continuing !== undefined) {
      MProposalOfServicesContinuing.encode(message.continuing, writer.uint32(218).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProposalOfServices {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposalOfServices();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.clientFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.expireDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.companyServices.push(reader.string());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.kickoffDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.workload = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.completionDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.fixedFee = reader.float();
          continue;
        case 9:
          if (tag != 77) {
            break;
          }

          message.feeAmount = reader.float();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.feeBasis = MProposalOfServicesFeeBasis.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 93) {
            break;
          }

          message.paymentDays = reader.float();
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.clientResponsibilities = reader.int32() as any;
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.clientTasks.push(reader.string());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.estimateTimeDate = MProposalOfServicesEstimateTimeDate.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.invoiceFreq = MProposalOfServicesInvoiceFreq.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.kickoffPayment = reader.float();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.servicesEndDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag != 149) {
            break;
          }

          message.noticePeriod = reader.float();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.feeCustom = reader.string();
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.earlyTerm = reader.int32() as any;
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.remainderType = MProposalOfServicesRemainderType.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.earlyTermFee = MProposalOfServicesEarlyTermFee.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag != 184) {
            break;
          }

          message.timeLimit = reader.int32() as any;
          continue;
        case 24:
          if (tag != 197) {
            break;
          }

          message.timeLimitMax = reader.float();
          continue;
        case 25:
          if (tag != 200) {
            break;
          }

          message.limitFixedFee = reader.int32() as any;
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.limitFixedFeeList.push(reader.string());
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.continuing = MProposalOfServicesContinuing.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProposalOfServices {
    return {
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      clientFull: isSet(object.clientFull) ? String(object.clientFull) : "",
      expireDate: isSet(object.expireDate) ? fromJsonTimestamp(object.expireDate) : undefined,
      companyServices: Array.isArray(object?.companyServices) ? object.companyServices.map((e: any) => String(e)) : [],
      kickoffDate: isSet(object.kickoffDate) ? fromJsonTimestamp(object.kickoffDate) : undefined,
      workload: isSet(object.workload) ? String(object.workload) : "",
      completionDate: isSet(object.completionDate) ? fromJsonTimestamp(object.completionDate) : undefined,
      fixedFee: isSet(object.fixedFee) ? Number(object.fixedFee) : 0,
      feeAmount: isSet(object.feeAmount) ? Number(object.feeAmount) : 0,
      feeBasis: isSet(object.feeBasis) ? MProposalOfServicesFeeBasis.fromJSON(object.feeBasis) : undefined,
      paymentDays: isSet(object.paymentDays) ? Number(object.paymentDays) : 0,
      clientResponsibilities: isSet(object.clientResponsibilities)
        ? internalBooleanFromJSON(object.clientResponsibilities)
        : 0,
      clientTasks: Array.isArray(object?.clientTasks) ? object.clientTasks.map((e: any) => String(e)) : [],
      estimateTimeDate: isSet(object.estimateTimeDate)
        ? MProposalOfServicesEstimateTimeDate.fromJSON(object.estimateTimeDate)
        : undefined,
      invoiceFreq: isSet(object.invoiceFreq) ? MProposalOfServicesInvoiceFreq.fromJSON(object.invoiceFreq) : undefined,
      kickoffPayment: isSet(object.kickoffPayment) ? Number(object.kickoffPayment) : 0,
      servicesEndDate: isSet(object.servicesEndDate) ? fromJsonTimestamp(object.servicesEndDate) : undefined,
      noticePeriod: isSet(object.noticePeriod) ? Number(object.noticePeriod) : 0,
      feeCustom: isSet(object.feeCustom) ? String(object.feeCustom) : "",
      earlyTerm: isSet(object.earlyTerm) ? internalBooleanFromJSON(object.earlyTerm) : 0,
      remainderType: isSet(object.remainderType)
        ? MProposalOfServicesRemainderType.fromJSON(object.remainderType)
        : undefined,
      earlyTermFee: isSet(object.earlyTermFee)
        ? MProposalOfServicesEarlyTermFee.fromJSON(object.earlyTermFee)
        : undefined,
      timeLimit: isSet(object.timeLimit) ? internalBooleanFromJSON(object.timeLimit) : 0,
      timeLimitMax: isSet(object.timeLimitMax) ? Number(object.timeLimitMax) : 0,
      limitFixedFee: isSet(object.limitFixedFee) ? internalBooleanFromJSON(object.limitFixedFee) : 0,
      limitFixedFeeList: Array.isArray(object?.limitFixedFeeList)
        ? object.limitFixedFeeList.map((e: any) => String(e))
        : [],
      continuing: isSet(object.continuing) ? MProposalOfServicesContinuing.fromJSON(object.continuing) : undefined,
    };
  },

  toJSON(message: ProposalOfServices): unknown {
    const obj: any = {};
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.clientFull !== undefined && (obj.clientFull = message.clientFull);
    message.expireDate !== undefined && (obj.expireDate = message.expireDate.toISOString());
    if (message.companyServices) {
      obj.companyServices = message.companyServices.map((e) => e);
    } else {
      obj.companyServices = [];
    }
    message.kickoffDate !== undefined && (obj.kickoffDate = message.kickoffDate.toISOString());
    message.workload !== undefined && (obj.workload = message.workload);
    message.completionDate !== undefined && (obj.completionDate = message.completionDate.toISOString());
    message.fixedFee !== undefined && (obj.fixedFee = message.fixedFee);
    message.feeAmount !== undefined && (obj.feeAmount = message.feeAmount);
    message.feeBasis !== undefined &&
      (obj.feeBasis = message.feeBasis ? MProposalOfServicesFeeBasis.toJSON(message.feeBasis) : undefined);
    message.paymentDays !== undefined && (obj.paymentDays = message.paymentDays);
    message.clientResponsibilities !== undefined &&
      (obj.clientResponsibilities = internalBooleanToJSON(message.clientResponsibilities));
    if (message.clientTasks) {
      obj.clientTasks = message.clientTasks.map((e) => e);
    } else {
      obj.clientTasks = [];
    }
    message.estimateTimeDate !== undefined && (obj.estimateTimeDate = message.estimateTimeDate
      ? MProposalOfServicesEstimateTimeDate.toJSON(message.estimateTimeDate)
      : undefined);
    message.invoiceFreq !== undefined &&
      (obj.invoiceFreq = message.invoiceFreq ? MProposalOfServicesInvoiceFreq.toJSON(message.invoiceFreq) : undefined);
    message.kickoffPayment !== undefined && (obj.kickoffPayment = message.kickoffPayment);
    message.servicesEndDate !== undefined && (obj.servicesEndDate = message.servicesEndDate.toISOString());
    message.noticePeriod !== undefined && (obj.noticePeriod = message.noticePeriod);
    message.feeCustom !== undefined && (obj.feeCustom = message.feeCustom);
    message.earlyTerm !== undefined && (obj.earlyTerm = internalBooleanToJSON(message.earlyTerm));
    message.remainderType !== undefined && (obj.remainderType = message.remainderType
      ? MProposalOfServicesRemainderType.toJSON(message.remainderType)
      : undefined);
    message.earlyTermFee !== undefined && (obj.earlyTermFee = message.earlyTermFee
      ? MProposalOfServicesEarlyTermFee.toJSON(message.earlyTermFee)
      : undefined);
    message.timeLimit !== undefined && (obj.timeLimit = internalBooleanToJSON(message.timeLimit));
    message.timeLimitMax !== undefined && (obj.timeLimitMax = message.timeLimitMax);
    message.limitFixedFee !== undefined && (obj.limitFixedFee = internalBooleanToJSON(message.limitFixedFee));
    if (message.limitFixedFeeList) {
      obj.limitFixedFeeList = message.limitFixedFeeList.map((e) => e);
    } else {
      obj.limitFixedFeeList = [];
    }
    message.continuing !== undefined &&
      (obj.continuing = message.continuing ? MProposalOfServicesContinuing.toJSON(message.continuing) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProposalOfServices>, I>>(base?: I): ProposalOfServices {
    return ProposalOfServices.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProposalOfServices>, I>>(object: I): ProposalOfServices {
    const message = createBaseProposalOfServices();
    message.companyShort = object.companyShort ?? "";
    message.clientFull = object.clientFull ?? "";
    message.expireDate = object.expireDate ?? undefined;
    message.companyServices = object.companyServices?.map((e) => e) || [];
    message.kickoffDate = object.kickoffDate ?? undefined;
    message.workload = object.workload ?? "";
    message.completionDate = object.completionDate ?? undefined;
    message.fixedFee = object.fixedFee ?? 0;
    message.feeAmount = object.feeAmount ?? 0;
    message.feeBasis = (object.feeBasis !== undefined && object.feeBasis !== null)
      ? MProposalOfServicesFeeBasis.fromPartial(object.feeBasis)
      : undefined;
    message.paymentDays = object.paymentDays ?? 0;
    message.clientResponsibilities = object.clientResponsibilities ?? 0;
    message.clientTasks = object.clientTasks?.map((e) => e) || [];
    message.estimateTimeDate = (object.estimateTimeDate !== undefined && object.estimateTimeDate !== null)
      ? MProposalOfServicesEstimateTimeDate.fromPartial(object.estimateTimeDate)
      : undefined;
    message.invoiceFreq = (object.invoiceFreq !== undefined && object.invoiceFreq !== null)
      ? MProposalOfServicesInvoiceFreq.fromPartial(object.invoiceFreq)
      : undefined;
    message.kickoffPayment = object.kickoffPayment ?? 0;
    message.servicesEndDate = object.servicesEndDate ?? undefined;
    message.noticePeriod = object.noticePeriod ?? 0;
    message.feeCustom = object.feeCustom ?? "";
    message.earlyTerm = object.earlyTerm ?? 0;
    message.remainderType = (object.remainderType !== undefined && object.remainderType !== null)
      ? MProposalOfServicesRemainderType.fromPartial(object.remainderType)
      : undefined;
    message.earlyTermFee = (object.earlyTermFee !== undefined && object.earlyTermFee !== null)
      ? MProposalOfServicesEarlyTermFee.fromPartial(object.earlyTermFee)
      : undefined;
    message.timeLimit = object.timeLimit ?? 0;
    message.timeLimitMax = object.timeLimitMax ?? 0;
    message.limitFixedFee = object.limitFixedFee ?? 0;
    message.limitFixedFeeList = object.limitFixedFeeList?.map((e) => e) || [];
    message.continuing = (object.continuing !== undefined && object.continuing !== null)
      ? MProposalOfServicesContinuing.fromPartial(object.continuing)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
