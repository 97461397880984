/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface PFrontendError {
  methodName: string;
  details: string;
}

function createBasePFrontendError(): PFrontendError {
  return { methodName: "", details: "" };
}

export const PFrontendError = {
  encode(message: PFrontendError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.methodName !== "") {
      writer.uint32(10).string(message.methodName);
    }
    if (message.details !== "") {
      writer.uint32(18).string(message.details);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PFrontendError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePFrontendError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.methodName = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.details = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PFrontendError {
    return {
      methodName: isSet(object.methodName) ? String(object.methodName) : "",
      details: isSet(object.details) ? String(object.details) : "",
    };
  },

  toJSON(message: PFrontendError): unknown {
    const obj: any = {};
    message.methodName !== undefined && (obj.methodName = message.methodName);
    message.details !== undefined && (obj.details = message.details);
    return obj;
  },

  create<I extends Exact<DeepPartial<PFrontendError>, I>>(base?: I): PFrontendError {
    return PFrontendError.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PFrontendError>, I>>(object: I): PFrontendError {
    const message = createBasePFrontendError();
    message.methodName = object.methodName ?? "";
    message.details = object.details ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
