import { ReactNode, useEffect, useRef, useState } from "react";
import cx from "clsx";
import { NewIcon, SvgIconKnowledge } from "../Icons";

type AlertType = "knowledge" | "warning";

type Props = {
    variant?: "contained";
    type?: AlertType;
    children?: ReactNode;
};

function AlertIcon({ type }: { type: AlertType }) {
    switch (type) {
        case "warning":
            return <NewIcon name="exclamation_triangle" />;
        case "knowledge":
            return <SvgIconKnowledge />;
        default:
            return null;
    }
}

export const Alert = (props: Props) => {
    const { variant = "contained", type = "knowledge", children } = props;
    const typedStyled = getTypedStyle(type);

    const [isTextClamped, setIsTextClamped] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (!ref.current) return;

            const { scrollHeight, clientHeight } = ref.current;
            setIsTextClamped(scrollHeight > clientHeight);
            resizeObserver.unobserve(ref.current);
        });

        resizeObserver.observe(ref.current, {});
        return () => {
            ref.current && resizeObserver.unobserve(ref.current);
        };
    }, []);

    return (
        <div id="Alert" className={cx(typedStyled, "flex p-3 gap-3 rounded-lg")}>
            <div className="flex-shrink-0">
                <AlertIcon type={type} />
            </div>
            <div className="flex flex-col items-end bg-inherit relative">
                <div
                    ref={ref}
                    className={`text-sm whitespace-pre-wrap ${
                        showMore ? "line-clamp-none" : "line-clamp-[8]"
                    } overflow-hidden ${isTextClamped && "clamp-text"}`}
                >
                    {children}
                </div>
                {(isTextClamped || showMore) && (
                    <div
                        onClick={() => setShowMore(!showMore)}
                        className="mt-2 text-xs whitespace-nowrap font-semibold cursor-pointer"
                    >
                        {showMore ? "show less" : "show more"}
                    </div>
                )}
            </div>
        </div>
    );
};

function getTypedStyle(type: AlertType) {
    switch (type) {
        case "knowledge":
            return "bg-secondaryLight";

        case "warning":
            return "bg-orange-100 border-l-4 border-orange-500 text-orange-700";
        default:
            return "";
    }
}
