import { PDoc } from "proto/PDoc";
import { Field } from "protobufjs";
import { Dispatch } from "react";
import { setDecisionSupportingToolAlert } from "store/mainActions";
import { DecisionSupportingToolAlertType } from "store/mainTypes";

export const DecisionSupportingTools = {
    onQuestionFilled: function onQuestionFilled(question: Question, value: any, activeDraft: PDoc | undefined, dispatch: Dispatch<any>, agreement: Agreement) {
        if (activeDraft?.legalUnit?.kind === "privacyPolicy") {
            if (agreement.fields.find(f => f.id === question.fieldId && f.name === "websiteUrl")) {
                dispatch(setDecisionSupportingToolAlert({
                    type: DecisionSupportingToolAlertType.PRIVACY_POLICY_WEBSITE_SCAN,
                    text: value,
                    callback: () => {
                    }
                }))
            }
        }
    }
}
