/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EAffiliateCompanyEntityType {
  Affiliate_companyEntityType_NA = 0,
  Affiliate_companyEntityType_CORPORATION = 1,
  Affiliate_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  Affiliate_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  Affiliate_companyEntityType_LIMITED_PARTNERSHIP = 4,
  Affiliate_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  Affiliate_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  Affiliate_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  Affiliate_companyEntityType_NONPROFIT_CORPORATION = 8,
  Affiliate_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  Affiliate_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eAffiliateCompanyEntityTypeFromJSON(object: any): EAffiliateCompanyEntityType {
  switch (object) {
    case 0:
    case "Affiliate_companyEntityType_NA":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_NA;
    case 1:
    case "Affiliate_companyEntityType_CORPORATION":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_CORPORATION;
    case 2:
    case "Affiliate_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "Affiliate_companyEntityType_SOLE_PROPRIETORSHIP":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "Affiliate_companyEntityType_LIMITED_PARTNERSHIP":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "Affiliate_companyEntityType_PROFESSIONAL_CORPORATION":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "Affiliate_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "Affiliate_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "Affiliate_companyEntityType_NONPROFIT_CORPORATION":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "Affiliate_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "Affiliate_companyEntityType_PARTNERSHIP":
      return EAffiliateCompanyEntityType.Affiliate_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAffiliateCompanyEntityType.UNRECOGNIZED;
  }
}

export function eAffiliateCompanyEntityTypeToJSON(object: EAffiliateCompanyEntityType): string {
  switch (object) {
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_NA:
      return "Affiliate_companyEntityType_NA";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_CORPORATION:
      return "Affiliate_companyEntityType_CORPORATION";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "Affiliate_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_SOLE_PROPRIETORSHIP:
      return "Affiliate_companyEntityType_SOLE_PROPRIETORSHIP";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_LIMITED_PARTNERSHIP:
      return "Affiliate_companyEntityType_LIMITED_PARTNERSHIP";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_CORPORATION:
      return "Affiliate_companyEntityType_PROFESSIONAL_CORPORATION";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "Affiliate_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "Affiliate_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_NONPROFIT_CORPORATION:
      return "Affiliate_companyEntityType_NONPROFIT_CORPORATION";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "Affiliate_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EAffiliateCompanyEntityType.Affiliate_companyEntityType_PARTNERSHIP:
      return "Affiliate_companyEntityType_PARTNERSHIP";
    case EAffiliateCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EAffiliateAffiliateEntityType {
  Affiliate_affiliateEntityType_NA = 0,
  Affiliate_affiliateEntityType_CORPORATION = 1,
  Affiliate_affiliateEntityType_LIMITED_LIABILITY_COMPANY = 2,
  Affiliate_affiliateEntityType_SOLE_PROPRIETORSHIP = 3,
  Affiliate_affiliateEntityType_LIMITED_PARTNERSHIP = 4,
  Affiliate_affiliateEntityType_PROFESSIONAL_CORPORATION = 5,
  Affiliate_affiliateEntityType_PROFESSIONAL_ASSOCIATION = 6,
  Affiliate_affiliateEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  Affiliate_affiliateEntityType_NONPROFIT_CORPORATION = 8,
  Affiliate_affiliateEntityType_UNINCORPORATED_ASSOCIATION = 9,
  Affiliate_affiliateEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eAffiliateAffiliateEntityTypeFromJSON(object: any): EAffiliateAffiliateEntityType {
  switch (object) {
    case 0:
    case "Affiliate_affiliateEntityType_NA":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_NA;
    case 1:
    case "Affiliate_affiliateEntityType_CORPORATION":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_CORPORATION;
    case 2:
    case "Affiliate_affiliateEntityType_LIMITED_LIABILITY_COMPANY":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "Affiliate_affiliateEntityType_SOLE_PROPRIETORSHIP":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "Affiliate_affiliateEntityType_LIMITED_PARTNERSHIP":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "Affiliate_affiliateEntityType_PROFESSIONAL_CORPORATION":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "Affiliate_affiliateEntityType_PROFESSIONAL_ASSOCIATION":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "Affiliate_affiliateEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "Affiliate_affiliateEntityType_NONPROFIT_CORPORATION":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "Affiliate_affiliateEntityType_UNINCORPORATED_ASSOCIATION":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "Affiliate_affiliateEntityType_PARTNERSHIP":
      return EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAffiliateAffiliateEntityType.UNRECOGNIZED;
  }
}

export function eAffiliateAffiliateEntityTypeToJSON(object: EAffiliateAffiliateEntityType): string {
  switch (object) {
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_NA:
      return "Affiliate_affiliateEntityType_NA";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_CORPORATION:
      return "Affiliate_affiliateEntityType_CORPORATION";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_LIMITED_LIABILITY_COMPANY:
      return "Affiliate_affiliateEntityType_LIMITED_LIABILITY_COMPANY";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_SOLE_PROPRIETORSHIP:
      return "Affiliate_affiliateEntityType_SOLE_PROPRIETORSHIP";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_LIMITED_PARTNERSHIP:
      return "Affiliate_affiliateEntityType_LIMITED_PARTNERSHIP";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_CORPORATION:
      return "Affiliate_affiliateEntityType_PROFESSIONAL_CORPORATION";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_ASSOCIATION:
      return "Affiliate_affiliateEntityType_PROFESSIONAL_ASSOCIATION";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "Affiliate_affiliateEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_NONPROFIT_CORPORATION:
      return "Affiliate_affiliateEntityType_NONPROFIT_CORPORATION";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_UNINCORPORATED_ASSOCIATION:
      return "Affiliate_affiliateEntityType_UNINCORPORATED_ASSOCIATION";
    case EAffiliateAffiliateEntityType.Affiliate_affiliateEntityType_PARTNERSHIP:
      return "Affiliate_affiliateEntityType_PARTNERSHIP";
    case EAffiliateAffiliateEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EAffiliateNondisclosurePlan {
  Affiliate_nondisclosurePlan_NA = 0,
  Affiliate_nondisclosurePlan_TAIL = 3,
  Affiliate_nondisclosurePlan_INDEFINITE = 2,
  Affiliate_nondisclosurePlan_TERM = 1,
  UNRECOGNIZED = -1,
}

export function eAffiliateNondisclosurePlanFromJSON(object: any): EAffiliateNondisclosurePlan {
  switch (object) {
    case 0:
    case "Affiliate_nondisclosurePlan_NA":
      return EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_NA;
    case 3:
    case "Affiliate_nondisclosurePlan_TAIL":
      return EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_TAIL;
    case 2:
    case "Affiliate_nondisclosurePlan_INDEFINITE":
      return EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_INDEFINITE;
    case 1:
    case "Affiliate_nondisclosurePlan_TERM":
      return EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_TERM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAffiliateNondisclosurePlan.UNRECOGNIZED;
  }
}

export function eAffiliateNondisclosurePlanToJSON(object: EAffiliateNondisclosurePlan): string {
  switch (object) {
    case EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_NA:
      return "Affiliate_nondisclosurePlan_NA";
    case EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_TAIL:
      return "Affiliate_nondisclosurePlan_TAIL";
    case EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_INDEFINITE:
      return "Affiliate_nondisclosurePlan_INDEFINITE";
    case EAffiliateNondisclosurePlan.Affiliate_nondisclosurePlan_TERM:
      return "Affiliate_nondisclosurePlan_TERM";
    case EAffiliateNondisclosurePlan.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EAffiliateNewCustomer {
  Affiliate_newCustomer_NA = 0,
  Affiliate_newCustomer_NEW = 1,
  Affiliate_newCustomer_PAST = 2,
  Affiliate_newCustomer_RECENT = 3,
  UNRECOGNIZED = -1,
}

export function eAffiliateNewCustomerFromJSON(object: any): EAffiliateNewCustomer {
  switch (object) {
    case 0:
    case "Affiliate_newCustomer_NA":
      return EAffiliateNewCustomer.Affiliate_newCustomer_NA;
    case 1:
    case "Affiliate_newCustomer_NEW":
      return EAffiliateNewCustomer.Affiliate_newCustomer_NEW;
    case 2:
    case "Affiliate_newCustomer_PAST":
      return EAffiliateNewCustomer.Affiliate_newCustomer_PAST;
    case 3:
    case "Affiliate_newCustomer_RECENT":
      return EAffiliateNewCustomer.Affiliate_newCustomer_RECENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAffiliateNewCustomer.UNRECOGNIZED;
  }
}

export function eAffiliateNewCustomerToJSON(object: EAffiliateNewCustomer): string {
  switch (object) {
    case EAffiliateNewCustomer.Affiliate_newCustomer_NA:
      return "Affiliate_newCustomer_NA";
    case EAffiliateNewCustomer.Affiliate_newCustomer_NEW:
      return "Affiliate_newCustomer_NEW";
    case EAffiliateNewCustomer.Affiliate_newCustomer_PAST:
      return "Affiliate_newCustomer_PAST";
    case EAffiliateNewCustomer.Affiliate_newCustomer_RECENT:
      return "Affiliate_newCustomer_RECENT";
    case EAffiliateNewCustomer.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MAffiliateCompanyEntityType {
  optionDbValue: EAffiliateCompanyEntityType;
  other: string;
}

export interface MAffiliateAffiliateEntityType {
  optionDbValue: EAffiliateAffiliateEntityType;
  other: string;
}

export interface MAffiliateNondisclosurePlan {
  optionDbValue: EAffiliateNondisclosurePlan;
  other: string;
}

export interface MAffiliateNewCustomer {
  optionDbValue: EAffiliateNewCustomer[];
  other: string;
}

export interface Affiliate {
  effectiveDate: Date | undefined;
  companyState: string;
  companyEntityType: MAffiliateCompanyEntityType | undefined;
  affiliateFull: string;
  affiliateState: string;
  affiliateEntityType: MAffiliateAffiliateEntityType | undefined;
  governLawState: string;
  venue: string;
  arbShort: InternalBoolean;
  termNoticeDays: number;
  nondisclosurePlan: MAffiliateNondisclosurePlan | undefined;
  newCommissionPercentage: number;
  nondisclosureYears: number;
  newCustomerPeriod: number;
  newCustomer: MAffiliateNewCustomer | undefined;
  pastCommissionPercentage: number;
  recentCommissionPercentage: number;
  referredCustomerPeriod: number;
}

function createBaseMAffiliateCompanyEntityType(): MAffiliateCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MAffiliateCompanyEntityType = {
  encode(message: MAffiliateCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAffiliateCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAffiliateCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAffiliateCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAffiliateCompanyEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAffiliateCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eAffiliateCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAffiliateCompanyEntityType>, I>>(base?: I): MAffiliateCompanyEntityType {
    return MAffiliateCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAffiliateCompanyEntityType>, I>>(object: I): MAffiliateCompanyEntityType {
    const message = createBaseMAffiliateCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMAffiliateAffiliateEntityType(): MAffiliateAffiliateEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MAffiliateAffiliateEntityType = {
  encode(message: MAffiliateAffiliateEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAffiliateAffiliateEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAffiliateAffiliateEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAffiliateAffiliateEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAffiliateAffiliateEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAffiliateAffiliateEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eAffiliateAffiliateEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAffiliateAffiliateEntityType>, I>>(base?: I): MAffiliateAffiliateEntityType {
    return MAffiliateAffiliateEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAffiliateAffiliateEntityType>, I>>(
    object: I,
  ): MAffiliateAffiliateEntityType {
    const message = createBaseMAffiliateAffiliateEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMAffiliateNondisclosurePlan(): MAffiliateNondisclosurePlan {
  return { optionDbValue: 0, other: "" };
}

export const MAffiliateNondisclosurePlan = {
  encode(message: MAffiliateNondisclosurePlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAffiliateNondisclosurePlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAffiliateNondisclosurePlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAffiliateNondisclosurePlan {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAffiliateNondisclosurePlanFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAffiliateNondisclosurePlan): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eAffiliateNondisclosurePlanToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAffiliateNondisclosurePlan>, I>>(base?: I): MAffiliateNondisclosurePlan {
    return MAffiliateNondisclosurePlan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAffiliateNondisclosurePlan>, I>>(object: I): MAffiliateNondisclosurePlan {
    const message = createBaseMAffiliateNondisclosurePlan();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMAffiliateNewCustomer(): MAffiliateNewCustomer {
  return { optionDbValue: [], other: "" };
}

export const MAffiliateNewCustomer = {
  encode(message: MAffiliateNewCustomer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAffiliateNewCustomer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAffiliateNewCustomer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAffiliateNewCustomer {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => eAffiliateNewCustomerFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAffiliateNewCustomer): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => eAffiliateNewCustomerToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAffiliateNewCustomer>, I>>(base?: I): MAffiliateNewCustomer {
    return MAffiliateNewCustomer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAffiliateNewCustomer>, I>>(object: I): MAffiliateNewCustomer {
    const message = createBaseMAffiliateNewCustomer();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseAffiliate(): Affiliate {
  return {
    effectiveDate: undefined,
    companyState: "",
    companyEntityType: undefined,
    affiliateFull: "",
    affiliateState: "",
    affiliateEntityType: undefined,
    governLawState: "",
    venue: "",
    arbShort: 0,
    termNoticeDays: 0,
    nondisclosurePlan: undefined,
    newCommissionPercentage: 0,
    nondisclosureYears: 0,
    newCustomerPeriod: 0,
    newCustomer: undefined,
    pastCommissionPercentage: 0,
    recentCommissionPercentage: 0,
    referredCustomerPeriod: 0,
  };
}

export const Affiliate = {
  encode(message: Affiliate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MAffiliateCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.affiliateFull !== "") {
      writer.uint32(34).string(message.affiliateFull);
    }
    if (message.affiliateState !== "") {
      writer.uint32(42).string(message.affiliateState);
    }
    if (message.affiliateEntityType !== undefined) {
      MAffiliateAffiliateEntityType.encode(message.affiliateEntityType, writer.uint32(50).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(58).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(66).string(message.venue);
    }
    if (message.arbShort !== 0) {
      writer.uint32(72).int32(message.arbShort);
    }
    if (message.termNoticeDays !== 0) {
      writer.uint32(85).float(message.termNoticeDays);
    }
    if (message.nondisclosurePlan !== undefined) {
      MAffiliateNondisclosurePlan.encode(message.nondisclosurePlan, writer.uint32(90).fork()).ldelim();
    }
    if (message.newCommissionPercentage !== 0) {
      writer.uint32(101).float(message.newCommissionPercentage);
    }
    if (message.nondisclosureYears !== 0) {
      writer.uint32(109).float(message.nondisclosureYears);
    }
    if (message.newCustomerPeriod !== 0) {
      writer.uint32(117).float(message.newCustomerPeriod);
    }
    if (message.newCustomer !== undefined) {
      MAffiliateNewCustomer.encode(message.newCustomer, writer.uint32(122).fork()).ldelim();
    }
    if (message.pastCommissionPercentage !== 0) {
      writer.uint32(133).float(message.pastCommissionPercentage);
    }
    if (message.recentCommissionPercentage !== 0) {
      writer.uint32(141).float(message.recentCommissionPercentage);
    }
    if (message.referredCustomerPeriod !== 0) {
      writer.uint32(149).float(message.referredCustomerPeriod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Affiliate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MAffiliateCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.affiliateFull = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.affiliateState = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.affiliateEntityType = MAffiliateAffiliateEntityType.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 9:
          if (tag != 72) {
            break;
          }

          message.arbShort = reader.int32() as any;
          continue;
        case 10:
          if (tag != 85) {
            break;
          }

          message.termNoticeDays = reader.float();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.nondisclosurePlan = MAffiliateNondisclosurePlan.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.newCommissionPercentage = reader.float();
          continue;
        case 13:
          if (tag != 109) {
            break;
          }

          message.nondisclosureYears = reader.float();
          continue;
        case 14:
          if (tag != 117) {
            break;
          }

          message.newCustomerPeriod = reader.float();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.newCustomer = MAffiliateNewCustomer.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.pastCommissionPercentage = reader.float();
          continue;
        case 17:
          if (tag != 141) {
            break;
          }

          message.recentCommissionPercentage = reader.float();
          continue;
        case 18:
          if (tag != 149) {
            break;
          }

          message.referredCustomerPeriod = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Affiliate {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MAffiliateCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      affiliateFull: isSet(object.affiliateFull) ? String(object.affiliateFull) : "",
      affiliateState: isSet(object.affiliateState) ? String(object.affiliateState) : "",
      affiliateEntityType: isSet(object.affiliateEntityType)
        ? MAffiliateAffiliateEntityType.fromJSON(object.affiliateEntityType)
        : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      arbShort: isSet(object.arbShort) ? internalBooleanFromJSON(object.arbShort) : 0,
      termNoticeDays: isSet(object.termNoticeDays) ? Number(object.termNoticeDays) : 0,
      nondisclosurePlan: isSet(object.nondisclosurePlan)
        ? MAffiliateNondisclosurePlan.fromJSON(object.nondisclosurePlan)
        : undefined,
      newCommissionPercentage: isSet(object.newCommissionPercentage) ? Number(object.newCommissionPercentage) : 0,
      nondisclosureYears: isSet(object.nondisclosureYears) ? Number(object.nondisclosureYears) : 0,
      newCustomerPeriod: isSet(object.newCustomerPeriod) ? Number(object.newCustomerPeriod) : 0,
      newCustomer: isSet(object.newCustomer) ? MAffiliateNewCustomer.fromJSON(object.newCustomer) : undefined,
      pastCommissionPercentage: isSet(object.pastCommissionPercentage) ? Number(object.pastCommissionPercentage) : 0,
      recentCommissionPercentage: isSet(object.recentCommissionPercentage)
        ? Number(object.recentCommissionPercentage)
        : 0,
      referredCustomerPeriod: isSet(object.referredCustomerPeriod) ? Number(object.referredCustomerPeriod) : 0,
    };
  },

  toJSON(message: Affiliate): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MAffiliateCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.affiliateFull !== undefined && (obj.affiliateFull = message.affiliateFull);
    message.affiliateState !== undefined && (obj.affiliateState = message.affiliateState);
    message.affiliateEntityType !== undefined && (obj.affiliateEntityType = message.affiliateEntityType
      ? MAffiliateAffiliateEntityType.toJSON(message.affiliateEntityType)
      : undefined);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.arbShort !== undefined && (obj.arbShort = internalBooleanToJSON(message.arbShort));
    message.termNoticeDays !== undefined && (obj.termNoticeDays = message.termNoticeDays);
    message.nondisclosurePlan !== undefined && (obj.nondisclosurePlan = message.nondisclosurePlan
      ? MAffiliateNondisclosurePlan.toJSON(message.nondisclosurePlan)
      : undefined);
    message.newCommissionPercentage !== undefined && (obj.newCommissionPercentage = message.newCommissionPercentage);
    message.nondisclosureYears !== undefined && (obj.nondisclosureYears = message.nondisclosureYears);
    message.newCustomerPeriod !== undefined && (obj.newCustomerPeriod = message.newCustomerPeriod);
    message.newCustomer !== undefined &&
      (obj.newCustomer = message.newCustomer ? MAffiliateNewCustomer.toJSON(message.newCustomer) : undefined);
    message.pastCommissionPercentage !== undefined && (obj.pastCommissionPercentage = message.pastCommissionPercentage);
    message.recentCommissionPercentage !== undefined &&
      (obj.recentCommissionPercentage = message.recentCommissionPercentage);
    message.referredCustomerPeriod !== undefined && (obj.referredCustomerPeriod = message.referredCustomerPeriod);
    return obj;
  },

  create<I extends Exact<DeepPartial<Affiliate>, I>>(base?: I): Affiliate {
    return Affiliate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Affiliate>, I>>(object: I): Affiliate {
    const message = createBaseAffiliate();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MAffiliateCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.affiliateFull = object.affiliateFull ?? "";
    message.affiliateState = object.affiliateState ?? "";
    message.affiliateEntityType = (object.affiliateEntityType !== undefined && object.affiliateEntityType !== null)
      ? MAffiliateAffiliateEntityType.fromPartial(object.affiliateEntityType)
      : undefined;
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.arbShort = object.arbShort ?? 0;
    message.termNoticeDays = object.termNoticeDays ?? 0;
    message.nondisclosurePlan = (object.nondisclosurePlan !== undefined && object.nondisclosurePlan !== null)
      ? MAffiliateNondisclosurePlan.fromPartial(object.nondisclosurePlan)
      : undefined;
    message.newCommissionPercentage = object.newCommissionPercentage ?? 0;
    message.nondisclosureYears = object.nondisclosureYears ?? 0;
    message.newCustomerPeriod = object.newCustomerPeriod ?? 0;
    message.newCustomer = (object.newCustomer !== undefined && object.newCustomer !== null)
      ? MAffiliateNewCustomer.fromPartial(object.newCustomer)
      : undefined;
    message.pastCommissionPercentage = object.pastCommissionPercentage ?? 0;
    message.recentCommissionPercentage = object.recentCommissionPercentage ?? 0;
    message.referredCustomerPeriod = object.referredCustomerPeriod ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
