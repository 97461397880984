import { request } from "./ApiCommon";

async function retrieveUser(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/user?email=" + urlEncodedEmail, {
        method: "GET"
    }).then(successCallback).catch(errorCallback);
}

async function retrieveUserType(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/userType?email=" + urlEncodedEmail, {
        method: "GET"
    }).then(successCallback).catch(errorCallback);
}

async function cancelSubscription(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/cancel?email=" + urlEncodedEmail, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

async function wixInstanceIdToEmail(instanceId: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    return await request("/api/v1/admin-console/instanceIdToEmail?instanceId=" + instanceId, {
        method: "GET"
    }).then(successCallback).catch(errorCallback);
}

async function wixRenewalsCsv(csv: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    return await request("/api/v1/admin-console/wixRenewals", {
        method: "POST",
        data: csv
    }).then(successCallback).catch(errorCallback);
}

async function changeEmail(email: string, newEmail: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)
    const urlEncodedNewEmail = encodeURIComponent(newEmail)

    return await request("/api/v1/admin-console/changeEmail?email=" + urlEncodedEmail + "&newEmail=" + urlEncodedNewEmail, {
        method: "POST",
    }).then(successCallback).catch(errorCallback);
}

async function changeCountry(email: string, newCountry: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)
    const urlEncodedCountry = encodeURIComponent(newCountry)

    return await request("/api/v1/admin-console/changeCountry?email=" + urlEncodedEmail + "&country=" + urlEncodedCountry, {
        method: "POST",
    }).then(successCallback).catch(errorCallback);
}

async function lockUser(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/lock?email=" + urlEncodedEmail, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

async function unlockUser(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/unlock?email=" + urlEncodedEmail, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

async function deleteEmail(email: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)

    return await request("/api/v1/admin-console/deleteEmail?email=" + urlEncodedEmail, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

async function reducePayproFee(email: string, newFee: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedEmail = encodeURIComponent(email)
    const urlEncodedNewFee = encodeURIComponent(newFee)

    return await request("/api/v1/admin-console/reducePayproFee?email=" + urlEncodedEmail + "&fee=" + urlEncodedNewFee, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

async function markAppSumoCodeRefunded(code: string, successCallback: (data: any) => void, errorCallback: (error: any) => void) {
    const urlEncodedCode = encodeURIComponent(code)

    return await request("/api/v1/admin-console/markAppSumoCodeRefunded?code=" + urlEncodedCode, {
        method: "POST"
    }).then(successCallback).catch(errorCallback);
}

export const AdminConsoleApi = { retrieveUser, cancelSubscription, wixInstanceIdToEmail, wixRenewalsCsv, changeEmail, changeCountry,
    unlockUser, lockUser, deleteEmail, reducePayproFee,
    markAppSumoCodeRefunded, retrieveUserType
};
