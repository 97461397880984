import { SAFE, ESAFEFlavor, ESAFEPreOrPost } from "../../proto/contracts/auto-contracts/SAFE";
import { CapTableActionTypes, INIT_ALL_SAFES, INIT_SHOULD_APPLY_SAFES, SET_ALLOCATED_OPTIONS, SET_AMOUNT_RAISED, SET_FOUNDERS_SHARES, SET_PRE_MONEY_VALUATION, SET_SHOULD_APPLY_SAFE, SET_SHOW_CONVERSION, SET_TARGET_OPTION_POOL, SET_UNALLOCATED_OPTIONS } from "./capTableActions";
import { CapTableStore } from "./capTableTypes";

let initialStore = {
    shouldApplySafes: [],
    allSafes: [],
    targetOptionPool: 0,
    preMoneyValuation: 0,
    amountRaised: 0,
    foundersShares: 0,
    allocatedOptions: 0,
    unallocatedOptions: 0,
    showConversion: false,
    sharesPerSafe: [],
    qualifiedRoundShares: 0,
    newOptionPoolShares: 0,
    totalShares: 0,
};

export default function capTableReducer(state: CapTableStore = initialStore, action: CapTableActionTypes): CapTableStore {
    return recalculateSafes(capTableReducerCases(state, action))
}

function capTableReducerCases(state: CapTableStore, action: CapTableActionTypes): CapTableStore {
    switch (action.type) {
        case SET_SHOULD_APPLY_SAFE: {
            let newShouldApply = [...state.shouldApplySafes]
            newShouldApply[action.payload.index] = action.payload.shouldApply

            return { ...state, shouldApplySafes: newShouldApply }
        }

        case INIT_SHOULD_APPLY_SAFES: {
            return { ...state, shouldApplySafes: new Array<boolean>(action.payload).fill(true) }
        }

        case INIT_ALL_SAFES: {
            return { ...state, allSafes: action.payload }
        }

        case SET_TARGET_OPTION_POOL: {
            return { ...state, targetOptionPool: action.payload }
        }

        case SET_PRE_MONEY_VALUATION: {
            return { ...state, preMoneyValuation: action.payload }
        }

        case SET_AMOUNT_RAISED: {
            return { ...state, amountRaised: action.payload }
        }

        case SET_FOUNDERS_SHARES: {
            return { ...state, foundersShares: action.payload }
        }

        case SET_ALLOCATED_OPTIONS: {
            return { ...state, allocatedOptions: action.payload }
        }

        case SET_UNALLOCATED_OPTIONS: {
            return { ...state, unallocatedOptions: action.payload }
        }

        case SET_SHOW_CONVERSION: {
            return { ...state, showConversion: action.payload }
        }

        default: return { ...state };
    }
}

function recalculateSafes(state: CapTableStore): CapTableStore {
    if (!state.showConversion) {
        return state
    }

    return iterateOverGuess(state)
}

function iterateOverGuess(state: CapTableStore): CapTableStore {
    if (state.foundersShares == 0) {
        return { ...state }
    }

    const allCurrentShares = state.allocatedOptions + state.unallocatedOptions + state.foundersShares

    const targetAOwnership = state.amountRaised / (state.amountRaised + state.preMoneyValuation)

    const estimatedSharesPerSafe = new Array(state.allSafes.length).fill(0)
    const usedDiscount = new Array(state.allSafes.length).fill(false)
    let newOptionPoolShares = 0

    let totalShares = allCurrentShares // this is a guess
    let rounds = 0;
    let guess = 0

    while (Math.abs(totalShares - guess) >= 1 && rounds < 100) {
        rounds++;
        guess = totalShares

        let totalPostSafePercentage = 0

        const preSafes = state.allSafes.filter(safe => safe.legalUnit?.safe?.preOrPost?.optionDbValue === ESAFEPreOrPost.SAFE_preOrPost_PRE)
        const postSafes = state.allSafes.filter(safe => safe.legalUnit?.safe?.preOrPost?.optionDbValue === ESAFEPreOrPost.SAFE_preOrPost_POST)

        let allPreSafesShares = 0
        preSafes.forEach(safeDoc => {
            const theSafe = safeDoc.legalUnit?.safe
            const index = state.allSafes.indexOf(safeDoc)

            if (theSafe && state.shouldApplySafes[index]) {
                let priceCap = 9999999999999999999
                let priceDiscount = 9999999999999999999

                if (hasCap(theSafe)) {
                    priceCap = theSafe.valCap / (allCurrentShares - state.unallocatedOptions + guess * state.targetOptionPool / 100)
                }

                if (hasDiscount(theSafe)) {
                    const priceA = state.amountRaised / (guess * targetAOwnership)
                    priceDiscount = priceA * (1 - theSafe.discount / 100)
                }

                const price = Math.min(priceCap, priceDiscount)
                const shares = theSafe.amount / price
                const index = state.allSafes.indexOf(safeDoc)

                estimatedSharesPerSafe[index] = shares

                allPreSafesShares += shares
            }
        })

        postSafes.forEach(safeDoc => {
            const theSafe = safeDoc.legalUnit?.safe
            const index = state.allSafes.indexOf(safeDoc)

            if (state.shouldApplySafes[index] && theSafe && hasCap(theSafe)) {
                totalPostSafePercentage += theSafe?.amount / theSafe.valCap
            }
        })

        const totalSharesAssumingCapDrives = (allCurrentShares + allPreSafesShares) / (1 - totalPostSafePercentage)

        postSafes.forEach(safeDoc => {
            const theSafe = safeDoc.legalUnit?.safe
            const index = state.allSafes.indexOf(safeDoc)

            if (state.shouldApplySafes[index] && theSafe && hasCap(theSafe)) {
                estimatedSharesPerSafe[index] = totalSharesAssumingCapDrives * theSafe?.amount / theSafe.valCap
            }
        })

        const priceA = state.amountRaised / (guess * targetAOwnership)

        let allSharesViaDiscount = 0
        let allPercentagesViaCap = 0

        // discount
        postSafes.forEach(safeDoc => {
            const theSafe = safeDoc.legalUnit?.safe

            const index = state.allSafes.indexOf(safeDoc)

            if (state.shouldApplySafes[index] && theSafe && hasDiscount(theSafe)) {
                const estimateSharesWithDiscount = theSafe.amount / ((priceA) * (1 - theSafe.discount / 100))

                if (estimateSharesWithDiscount > estimatedSharesPerSafe[index]) {
                    estimatedSharesPerSafe[index] = estimateSharesWithDiscount
                    usedDiscount[index] = true

                    allSharesViaDiscount += estimateSharesWithDiscount
                }
            }

            if (theSafe && !usedDiscount[index]) {
                allPercentagesViaCap += theSafe?.amount / theSafe.valCap
            }
        })

        const allSharesViaCap = (allCurrentShares + allPreSafesShares) / (1 - allPercentagesViaCap)

        // fix cap based SAFEs
        postSafes.forEach(safeDoc => {
            const theSafe = safeDoc.legalUnit?.safe

            const index = state.allSafes.indexOf(safeDoc)

            if (state.shouldApplySafes[index] && theSafe && hasCap(theSafe) && !usedDiscount[index]) {
                estimatedSharesPerSafe[index] = allSharesViaCap * theSafe?.amount / theSafe.valCap
            }
        })

        const allPostSafesShares = allSharesViaDiscount + allSharesViaCap

        const totalSharesNow = allPostSafesShares
        const totalSharesWithNoOptionPool = totalSharesNow + targetAOwnership * guess - state.unallocatedOptions
        const optionPool = guess * state.targetOptionPool / 100

        totalShares = optionPool + totalSharesWithNoOptionPool

        newOptionPoolShares = optionPool
    }

    return { ...state, sharesPerSafe: estimatedSharesPerSafe, qualifiedRoundShares: guess * targetAOwnership, newOptionPoolShares: newOptionPoolShares, totalShares: guess,
    }
}

function hasCap(theSafe: SAFE) {
    return (theSafe.flavor?.optionDbValue === ESAFEFlavor.SAFE_flavor_DISCOUNT ||
        theSafe.flavor?.optionDbValue === ESAFEFlavor.SAFE_flavor_VAL_CAP)
}

function hasDiscount(theSafe: SAFE) {
    return (theSafe.flavor?.optionDbValue === ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT ||
        theSafe.flavor?.optionDbValue === ESAFEFlavor.SAFE_flavor_DISCOUNT)
}
