/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export enum PUserStatus {
  PRE_ONBOARDING = 0,
  POST_ONBOARDING = 1,
  POST_PAYMENT = 2,
  REFUNDED = 3,
  CHARGEBACK = 4,
  CANCELED = 5,
  UNRECOGNIZED = -1,
}

export function pUserStatusFromJSON(object: any): PUserStatus {
  switch (object) {
    case 0:
    case "PRE_ONBOARDING":
      return PUserStatus.PRE_ONBOARDING;
    case 1:
    case "POST_ONBOARDING":
      return PUserStatus.POST_ONBOARDING;
    case 2:
    case "POST_PAYMENT":
      return PUserStatus.POST_PAYMENT;
    case 3:
    case "REFUNDED":
      return PUserStatus.REFUNDED;
    case 4:
    case "CHARGEBACK":
      return PUserStatus.CHARGEBACK;
    case 5:
    case "CANCELED":
      return PUserStatus.CANCELED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PUserStatus.UNRECOGNIZED;
  }
}

export function pUserStatusToJSON(object: PUserStatus): string {
  switch (object) {
    case PUserStatus.PRE_ONBOARDING:
      return "PRE_ONBOARDING";
    case PUserStatus.POST_ONBOARDING:
      return "POST_ONBOARDING";
    case PUserStatus.POST_PAYMENT:
      return "POST_PAYMENT";
    case PUserStatus.REFUNDED:
      return "REFUNDED";
    case PUserStatus.CHARGEBACK:
      return "CHARGEBACK";
    case PUserStatus.CANCELED:
      return "CANCELED";
    case PUserStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum POrgType {
  STARTUP = 0,
  E_COMM = 1,
  UNRECOGNIZED = -1,
}

export function pOrgTypeFromJSON(object: any): POrgType {
  switch (object) {
    case 0:
    case "STARTUP":
      return POrgType.STARTUP;
    case 1:
    case "E_COMM":
      return POrgType.E_COMM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return POrgType.UNRECOGNIZED;
  }
}

export function pOrgTypeToJSON(object: POrgType): string {
  switch (object) {
    case POrgType.STARTUP:
      return "STARTUP";
    case POrgType.E_COMM:
      return "E_COMM";
    case POrgType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PUserSubscriptionType {
  MONTHLY_SUBSCRIPTION = 0,
  YEARLY_SUBSCRIPTION = 1,
  QUARTERLY_SUBSCRIPTION = 3,
  LIFETIME_SUBSCRIPTION = 4,
  UNLIMITED = 2,
  UNRECOGNIZED = -1,
}

export function pUserSubscriptionTypeFromJSON(object: any): PUserSubscriptionType {
  switch (object) {
    case 0:
    case "MONTHLY_SUBSCRIPTION":
      return PUserSubscriptionType.MONTHLY_SUBSCRIPTION;
    case 1:
    case "YEARLY_SUBSCRIPTION":
      return PUserSubscriptionType.YEARLY_SUBSCRIPTION;
    case 3:
    case "QUARTERLY_SUBSCRIPTION":
      return PUserSubscriptionType.QUARTERLY_SUBSCRIPTION;
    case 4:
    case "LIFETIME_SUBSCRIPTION":
      return PUserSubscriptionType.LIFETIME_SUBSCRIPTION;
    case 2:
    case "UNLIMITED":
      return PUserSubscriptionType.UNLIMITED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PUserSubscriptionType.UNRECOGNIZED;
  }
}

export function pUserSubscriptionTypeToJSON(object: PUserSubscriptionType): string {
  switch (object) {
    case PUserSubscriptionType.MONTHLY_SUBSCRIPTION:
      return "MONTHLY_SUBSCRIPTION";
    case PUserSubscriptionType.YEARLY_SUBSCRIPTION:
      return "YEARLY_SUBSCRIPTION";
    case PUserSubscriptionType.QUARTERLY_SUBSCRIPTION:
      return "QUARTERLY_SUBSCRIPTION";
    case PUserSubscriptionType.LIFETIME_SUBSCRIPTION:
      return "LIFETIME_SUBSCRIPTION";
    case PUserSubscriptionType.UNLIMITED:
      return "UNLIMITED";
    case PUserSubscriptionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PLogoOptions {
  PLogoOptions_EVERY_PAGE = 0,
  PLogoOptions_FIRST_PAGE = 1,
  PLogoOptions_DISABLE_LOGO = 2,
  UNRECOGNIZED = -1,
}

export function pLogoOptionsFromJSON(object: any): PLogoOptions {
  switch (object) {
    case 0:
    case "PLogoOptions_EVERY_PAGE":
      return PLogoOptions.PLogoOptions_EVERY_PAGE;
    case 1:
    case "PLogoOptions_FIRST_PAGE":
      return PLogoOptions.PLogoOptions_FIRST_PAGE;
    case 2:
    case "PLogoOptions_DISABLE_LOGO":
      return PLogoOptions.PLogoOptions_DISABLE_LOGO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PLogoOptions.UNRECOGNIZED;
  }
}

export function pLogoOptionsToJSON(object: PLogoOptions): string {
  switch (object) {
    case PLogoOptions.PLogoOptions_EVERY_PAGE:
      return "PLogoOptions_EVERY_PAGE";
    case PLogoOptions.PLogoOptions_FIRST_PAGE:
      return "PLogoOptions_FIRST_PAGE";
    case PLogoOptions.PLogoOptions_DISABLE_LOGO:
      return "PLogoOptions_DISABLE_LOGO";
    case PLogoOptions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PUser {
  email: string;
  fullName: string;
  accountName: string;
  userStatus: PUserStatus;
  orgType: POrgType;
  usState: string;
  title: string;
  address: string;
  companyFullName: string;
  paymentTime: Date | undefined;
  subscriptionType: PUserSubscriptionType;
  checklistPurchased: boolean;
  agreedToTerms: boolean;
  agreedToNotLawyerAck: boolean;
  logoSettings: PLogoSettings | undefined;
  pendingEmailData: PPendingEmailData | undefined;
  firstPaymentTime: Date | undefined;
  attributionData: PAttributionData | undefined;
  isWixUser: boolean;
  isShopifyUser: boolean;
  isDudaUser: boolean;
  tier: string;
  shopifyShop: string;
  dudaSite: string;
  dealProviders: string[];
  phone: string;
  isSuperAdmin: boolean;
  supportedOauthProviders: string[];
  country: string;
  markUserAsPrepaid: boolean;
  checklistDetails: PChecklistDetails | undefined;
  isChecklistUser: boolean;
}

export interface PLogoSettings {
  logoOption: PLogoOptions;
  imgBase64: string;
  width: number;
}

export interface PPendingEmailData {
  batchId: string;
}

export interface PAttributionData {
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  utmTerm: string;
  os: string;
  deviceType: string;
  hubspotCookie: string;
}

export interface PChecklistDetails {
  clawdiaCoupon: string;
  clawdiaCouponSentAt: Date | undefined;
  clawdiaCouponUid: string;
}

function createBasePUser(): PUser {
  return {
    email: "",
    fullName: "",
    accountName: "",
    userStatus: 0,
    orgType: 0,
    usState: "",
    title: "",
    address: "",
    companyFullName: "",
    paymentTime: undefined,
    subscriptionType: 0,
    checklistPurchased: false,
    agreedToTerms: false,
    agreedToNotLawyerAck: false,
    logoSettings: undefined,
    pendingEmailData: undefined,
    firstPaymentTime: undefined,
    attributionData: undefined,
    isWixUser: false,
    isShopifyUser: false,
    isDudaUser: false,
    tier: "",
    shopifyShop: "",
    dudaSite: "",
    dealProviders: [],
    phone: "",
    isSuperAdmin: false,
    supportedOauthProviders: [],
    country: "",
    markUserAsPrepaid: false,
    checklistDetails: undefined,
    isChecklistUser: false,
  };
}

export const PUser = {
  encode(message: PUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.fullName !== "") {
      writer.uint32(18).string(message.fullName);
    }
    if (message.accountName !== "") {
      writer.uint32(26).string(message.accountName);
    }
    if (message.userStatus !== 0) {
      writer.uint32(32).int32(message.userStatus);
    }
    if (message.orgType !== 0) {
      writer.uint32(40).int32(message.orgType);
    }
    if (message.usState !== "") {
      writer.uint32(50).string(message.usState);
    }
    if (message.title !== "") {
      writer.uint32(58).string(message.title);
    }
    if (message.address !== "") {
      writer.uint32(66).string(message.address);
    }
    if (message.companyFullName !== "") {
      writer.uint32(210).string(message.companyFullName);
    }
    if (message.paymentTime !== undefined) {
      Timestamp.encode(toTimestamp(message.paymentTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.subscriptionType !== 0) {
      writer.uint32(80).int32(message.subscriptionType);
    }
    if (message.checklistPurchased === true) {
      writer.uint32(296).bool(message.checklistPurchased);
    }
    if (message.agreedToTerms === true) {
      writer.uint32(88).bool(message.agreedToTerms);
    }
    if (message.agreedToNotLawyerAck === true) {
      writer.uint32(232).bool(message.agreedToNotLawyerAck);
    }
    if (message.logoSettings !== undefined) {
      PLogoSettings.encode(message.logoSettings, writer.uint32(98).fork()).ldelim();
    }
    if (message.pendingEmailData !== undefined) {
      PPendingEmailData.encode(message.pendingEmailData, writer.uint32(106).fork()).ldelim();
    }
    if (message.firstPaymentTime !== undefined) {
      Timestamp.encode(toTimestamp(message.firstPaymentTime), writer.uint32(114).fork()).ldelim();
    }
    if (message.attributionData !== undefined) {
      PAttributionData.encode(message.attributionData, writer.uint32(202).fork()).ldelim();
    }
    if (message.isWixUser === true) {
      writer.uint32(216).bool(message.isWixUser);
    }
    if (message.isShopifyUser === true) {
      writer.uint32(280).bool(message.isShopifyUser);
    }
    if (message.isDudaUser === true) {
      writer.uint32(320).bool(message.isDudaUser);
    }
    if (message.tier !== "") {
      writer.uint32(330).string(message.tier);
    }
    if (message.shopifyShop !== "") {
      writer.uint32(290).string(message.shopifyShop);
    }
    if (message.dudaSite !== "") {
      writer.uint32(338).string(message.dudaSite);
    }
    for (const v of message.dealProviders) {
      writer.uint32(258).string(v!);
    }
    if (message.phone !== "") {
      writer.uint32(226).string(message.phone);
    }
    if (message.isSuperAdmin === true) {
      writer.uint32(240).bool(message.isSuperAdmin);
    }
    for (const v of message.supportedOauthProviders) {
      writer.uint32(250).string(v!);
    }
    if (message.country !== "") {
      writer.uint32(266).string(message.country);
    }
    if (message.markUserAsPrepaid === true) {
      writer.uint32(272).bool(message.markUserAsPrepaid);
    }
    if (message.checklistDetails !== undefined) {
      PChecklistDetails.encode(message.checklistDetails, writer.uint32(306).fork()).ldelim();
    }
    if (message.isChecklistUser === true) {
      writer.uint32(312).bool(message.isChecklistUser);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.fullName = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.userStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.orgType = reader.int32() as any;
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.usState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.title = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.address = reader.string();
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.companyFullName = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.paymentTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 80) {
            break;
          }

          message.subscriptionType = reader.int32() as any;
          continue;
        case 37:
          if (tag != 296) {
            break;
          }

          message.checklistPurchased = reader.bool();
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.agreedToTerms = reader.bool();
          continue;
        case 29:
          if (tag != 232) {
            break;
          }

          message.agreedToNotLawyerAck = reader.bool();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.logoSettings = PLogoSettings.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.pendingEmailData = PPendingEmailData.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.firstPaymentTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.attributionData = PAttributionData.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag != 216) {
            break;
          }

          message.isWixUser = reader.bool();
          continue;
        case 35:
          if (tag != 280) {
            break;
          }

          message.isShopifyUser = reader.bool();
          continue;
        case 40:
          if (tag != 320) {
            break;
          }

          message.isDudaUser = reader.bool();
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.tier = reader.string();
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.shopifyShop = reader.string();
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.dudaSite = reader.string();
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.dealProviders.push(reader.string());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 30:
          if (tag != 240) {
            break;
          }

          message.isSuperAdmin = reader.bool();
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.supportedOauthProviders.push(reader.string());
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.country = reader.string();
          continue;
        case 34:
          if (tag != 272) {
            break;
          }

          message.markUserAsPrepaid = reader.bool();
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.checklistDetails = PChecklistDetails.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag != 312) {
            break;
          }

          message.isChecklistUser = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PUser {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      fullName: isSet(object.fullName) ? String(object.fullName) : "",
      accountName: isSet(object.accountName) ? String(object.accountName) : "",
      userStatus: isSet(object.userStatus) ? pUserStatusFromJSON(object.userStatus) : 0,
      orgType: isSet(object.orgType) ? pOrgTypeFromJSON(object.orgType) : 0,
      usState: isSet(object.usState) ? String(object.usState) : "",
      title: isSet(object.title) ? String(object.title) : "",
      address: isSet(object.address) ? String(object.address) : "",
      companyFullName: isSet(object.companyFullName) ? String(object.companyFullName) : "",
      paymentTime: isSet(object.paymentTime) ? fromJsonTimestamp(object.paymentTime) : undefined,
      subscriptionType: isSet(object.subscriptionType) ? pUserSubscriptionTypeFromJSON(object.subscriptionType) : 0,
      checklistPurchased: isSet(object.checklistPurchased) ? Boolean(object.checklistPurchased) : false,
      agreedToTerms: isSet(object.agreedToTerms) ? Boolean(object.agreedToTerms) : false,
      agreedToNotLawyerAck: isSet(object.agreedToNotLawyerAck) ? Boolean(object.agreedToNotLawyerAck) : false,
      logoSettings: isSet(object.logoSettings) ? PLogoSettings.fromJSON(object.logoSettings) : undefined,
      pendingEmailData: isSet(object.pendingEmailData)
        ? PPendingEmailData.fromJSON(object.pendingEmailData)
        : undefined,
      firstPaymentTime: isSet(object.firstPaymentTime) ? fromJsonTimestamp(object.firstPaymentTime) : undefined,
      attributionData: isSet(object.attributionData) ? PAttributionData.fromJSON(object.attributionData) : undefined,
      isWixUser: isSet(object.isWixUser) ? Boolean(object.isWixUser) : false,
      isShopifyUser: isSet(object.isShopifyUser) ? Boolean(object.isShopifyUser) : false,
      isDudaUser: isSet(object.isDudaUser) ? Boolean(object.isDudaUser) : false,
      tier: isSet(object.tier) ? String(object.tier) : "",
      shopifyShop: isSet(object.shopifyShop) ? String(object.shopifyShop) : "",
      dudaSite: isSet(object.dudaSite) ? String(object.dudaSite) : "",
      dealProviders: Array.isArray(object?.dealProviders) ? object.dealProviders.map((e: any) => String(e)) : [],
      phone: isSet(object.phone) ? String(object.phone) : "",
      isSuperAdmin: isSet(object.isSuperAdmin) ? Boolean(object.isSuperAdmin) : false,
      supportedOauthProviders: Array.isArray(object?.supportedOauthProviders)
        ? object.supportedOauthProviders.map((e: any) => String(e))
        : [],
      country: isSet(object.country) ? String(object.country) : "",
      markUserAsPrepaid: isSet(object.markUserAsPrepaid) ? Boolean(object.markUserAsPrepaid) : false,
      checklistDetails: isSet(object.checklistDetails)
        ? PChecklistDetails.fromJSON(object.checklistDetails)
        : undefined,
      isChecklistUser: isSet(object.isChecklistUser) ? Boolean(object.isChecklistUser) : false,
    };
  },

  toJSON(message: PUser): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.fullName !== undefined && (obj.fullName = message.fullName);
    message.accountName !== undefined && (obj.accountName = message.accountName);
    message.userStatus !== undefined && (obj.userStatus = pUserStatusToJSON(message.userStatus));
    message.orgType !== undefined && (obj.orgType = pOrgTypeToJSON(message.orgType));
    message.usState !== undefined && (obj.usState = message.usState);
    message.title !== undefined && (obj.title = message.title);
    message.address !== undefined && (obj.address = message.address);
    message.companyFullName !== undefined && (obj.companyFullName = message.companyFullName);
    message.paymentTime !== undefined && (obj.paymentTime = message.paymentTime.toISOString());
    message.subscriptionType !== undefined &&
      (obj.subscriptionType = pUserSubscriptionTypeToJSON(message.subscriptionType));
    message.checklistPurchased !== undefined && (obj.checklistPurchased = message.checklistPurchased);
    message.agreedToTerms !== undefined && (obj.agreedToTerms = message.agreedToTerms);
    message.agreedToNotLawyerAck !== undefined && (obj.agreedToNotLawyerAck = message.agreedToNotLawyerAck);
    message.logoSettings !== undefined &&
      (obj.logoSettings = message.logoSettings ? PLogoSettings.toJSON(message.logoSettings) : undefined);
    message.pendingEmailData !== undefined &&
      (obj.pendingEmailData = message.pendingEmailData
        ? PPendingEmailData.toJSON(message.pendingEmailData)
        : undefined);
    message.firstPaymentTime !== undefined && (obj.firstPaymentTime = message.firstPaymentTime.toISOString());
    message.attributionData !== undefined &&
      (obj.attributionData = message.attributionData ? PAttributionData.toJSON(message.attributionData) : undefined);
    message.isWixUser !== undefined && (obj.isWixUser = message.isWixUser);
    message.isShopifyUser !== undefined && (obj.isShopifyUser = message.isShopifyUser);
    message.isDudaUser !== undefined && (obj.isDudaUser = message.isDudaUser);
    message.tier !== undefined && (obj.tier = message.tier);
    message.shopifyShop !== undefined && (obj.shopifyShop = message.shopifyShop);
    message.dudaSite !== undefined && (obj.dudaSite = message.dudaSite);
    if (message.dealProviders) {
      obj.dealProviders = message.dealProviders.map((e) => e);
    } else {
      obj.dealProviders = [];
    }
    message.phone !== undefined && (obj.phone = message.phone);
    message.isSuperAdmin !== undefined && (obj.isSuperAdmin = message.isSuperAdmin);
    if (message.supportedOauthProviders) {
      obj.supportedOauthProviders = message.supportedOauthProviders.map((e) => e);
    } else {
      obj.supportedOauthProviders = [];
    }
    message.country !== undefined && (obj.country = message.country);
    message.markUserAsPrepaid !== undefined && (obj.markUserAsPrepaid = message.markUserAsPrepaid);
    message.checklistDetails !== undefined &&
      (obj.checklistDetails = message.checklistDetails
        ? PChecklistDetails.toJSON(message.checklistDetails)
        : undefined);
    message.isChecklistUser !== undefined && (obj.isChecklistUser = message.isChecklistUser);
    return obj;
  },

  create<I extends Exact<DeepPartial<PUser>, I>>(base?: I): PUser {
    return PUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PUser>, I>>(object: I): PUser {
    const message = createBasePUser();
    message.email = object.email ?? "";
    message.fullName = object.fullName ?? "";
    message.accountName = object.accountName ?? "";
    message.userStatus = object.userStatus ?? 0;
    message.orgType = object.orgType ?? 0;
    message.usState = object.usState ?? "";
    message.title = object.title ?? "";
    message.address = object.address ?? "";
    message.companyFullName = object.companyFullName ?? "";
    message.paymentTime = object.paymentTime ?? undefined;
    message.subscriptionType = object.subscriptionType ?? 0;
    message.checklistPurchased = object.checklistPurchased ?? false;
    message.agreedToTerms = object.agreedToTerms ?? false;
    message.agreedToNotLawyerAck = object.agreedToNotLawyerAck ?? false;
    message.logoSettings = (object.logoSettings !== undefined && object.logoSettings !== null)
      ? PLogoSettings.fromPartial(object.logoSettings)
      : undefined;
    message.pendingEmailData = (object.pendingEmailData !== undefined && object.pendingEmailData !== null)
      ? PPendingEmailData.fromPartial(object.pendingEmailData)
      : undefined;
    message.firstPaymentTime = object.firstPaymentTime ?? undefined;
    message.attributionData = (object.attributionData !== undefined && object.attributionData !== null)
      ? PAttributionData.fromPartial(object.attributionData)
      : undefined;
    message.isWixUser = object.isWixUser ?? false;
    message.isShopifyUser = object.isShopifyUser ?? false;
    message.isDudaUser = object.isDudaUser ?? false;
    message.tier = object.tier ?? "";
    message.shopifyShop = object.shopifyShop ?? "";
    message.dudaSite = object.dudaSite ?? "";
    message.dealProviders = object.dealProviders?.map((e) => e) || [];
    message.phone = object.phone ?? "";
    message.isSuperAdmin = object.isSuperAdmin ?? false;
    message.supportedOauthProviders = object.supportedOauthProviders?.map((e) => e) || [];
    message.country = object.country ?? "";
    message.markUserAsPrepaid = object.markUserAsPrepaid ?? false;
    message.checklistDetails = (object.checklistDetails !== undefined && object.checklistDetails !== null)
      ? PChecklistDetails.fromPartial(object.checklistDetails)
      : undefined;
    message.isChecklistUser = object.isChecklistUser ?? false;
    return message;
  },
};

function createBasePLogoSettings(): PLogoSettings {
  return { logoOption: 0, imgBase64: "", width: 0 };
}

export const PLogoSettings = {
  encode(message: PLogoSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logoOption !== 0) {
      writer.uint32(8).int32(message.logoOption);
    }
    if (message.imgBase64 !== "") {
      writer.uint32(18).string(message.imgBase64);
    }
    if (message.width !== 0) {
      writer.uint32(24).int32(message.width);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PLogoSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePLogoSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.logoOption = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.imgBase64 = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.width = reader.int32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PLogoSettings {
    return {
      logoOption: isSet(object.logoOption) ? pLogoOptionsFromJSON(object.logoOption) : 0,
      imgBase64: isSet(object.imgBase64) ? String(object.imgBase64) : "",
      width: isSet(object.width) ? Number(object.width) : 0,
    };
  },

  toJSON(message: PLogoSettings): unknown {
    const obj: any = {};
    message.logoOption !== undefined && (obj.logoOption = pLogoOptionsToJSON(message.logoOption));
    message.imgBase64 !== undefined && (obj.imgBase64 = message.imgBase64);
    message.width !== undefined && (obj.width = Math.round(message.width));
    return obj;
  },

  create<I extends Exact<DeepPartial<PLogoSettings>, I>>(base?: I): PLogoSettings {
    return PLogoSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PLogoSettings>, I>>(object: I): PLogoSettings {
    const message = createBasePLogoSettings();
    message.logoOption = object.logoOption ?? 0;
    message.imgBase64 = object.imgBase64 ?? "";
    message.width = object.width ?? 0;
    return message;
  },
};

function createBasePPendingEmailData(): PPendingEmailData {
  return { batchId: "" };
}

export const PPendingEmailData = {
  encode(message: PPendingEmailData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.batchId !== "") {
      writer.uint32(10).string(message.batchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPendingEmailData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPendingEmailData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.batchId = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPendingEmailData {
    return { batchId: isSet(object.batchId) ? String(object.batchId) : "" };
  },

  toJSON(message: PPendingEmailData): unknown {
    const obj: any = {};
    message.batchId !== undefined && (obj.batchId = message.batchId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPendingEmailData>, I>>(base?: I): PPendingEmailData {
    return PPendingEmailData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPendingEmailData>, I>>(object: I): PPendingEmailData {
    const message = createBasePPendingEmailData();
    message.batchId = object.batchId ?? "";
    return message;
  },
};

function createBasePAttributionData(): PAttributionData {
  return { utmSource: "", utmCampaign: "", utmMedium: "", utmTerm: "", os: "", deviceType: "", hubspotCookie: "" };
}

export const PAttributionData = {
  encode(message: PAttributionData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utmSource !== "") {
      writer.uint32(10).string(message.utmSource);
    }
    if (message.utmCampaign !== "") {
      writer.uint32(18).string(message.utmCampaign);
    }
    if (message.utmMedium !== "") {
      writer.uint32(26).string(message.utmMedium);
    }
    if (message.utmTerm !== "") {
      writer.uint32(34).string(message.utmTerm);
    }
    if (message.os !== "") {
      writer.uint32(42).string(message.os);
    }
    if (message.deviceType !== "") {
      writer.uint32(50).string(message.deviceType);
    }
    if (message.hubspotCookie !== "") {
      writer.uint32(58).string(message.hubspotCookie);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PAttributionData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePAttributionData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.utmSource = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.utmCampaign = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.utmMedium = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.utmTerm = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.os = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.hubspotCookie = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PAttributionData {
    return {
      utmSource: isSet(object.utmSource) ? String(object.utmSource) : "",
      utmCampaign: isSet(object.utmCampaign) ? String(object.utmCampaign) : "",
      utmMedium: isSet(object.utmMedium) ? String(object.utmMedium) : "",
      utmTerm: isSet(object.utmTerm) ? String(object.utmTerm) : "",
      os: isSet(object.os) ? String(object.os) : "",
      deviceType: isSet(object.deviceType) ? String(object.deviceType) : "",
      hubspotCookie: isSet(object.hubspotCookie) ? String(object.hubspotCookie) : "",
    };
  },

  toJSON(message: PAttributionData): unknown {
    const obj: any = {};
    message.utmSource !== undefined && (obj.utmSource = message.utmSource);
    message.utmCampaign !== undefined && (obj.utmCampaign = message.utmCampaign);
    message.utmMedium !== undefined && (obj.utmMedium = message.utmMedium);
    message.utmTerm !== undefined && (obj.utmTerm = message.utmTerm);
    message.os !== undefined && (obj.os = message.os);
    message.deviceType !== undefined && (obj.deviceType = message.deviceType);
    message.hubspotCookie !== undefined && (obj.hubspotCookie = message.hubspotCookie);
    return obj;
  },

  create<I extends Exact<DeepPartial<PAttributionData>, I>>(base?: I): PAttributionData {
    return PAttributionData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PAttributionData>, I>>(object: I): PAttributionData {
    const message = createBasePAttributionData();
    message.utmSource = object.utmSource ?? "";
    message.utmCampaign = object.utmCampaign ?? "";
    message.utmMedium = object.utmMedium ?? "";
    message.utmTerm = object.utmTerm ?? "";
    message.os = object.os ?? "";
    message.deviceType = object.deviceType ?? "";
    message.hubspotCookie = object.hubspotCookie ?? "";
    return message;
  },
};

function createBasePChecklistDetails(): PChecklistDetails {
  return { clawdiaCoupon: "", clawdiaCouponSentAt: undefined, clawdiaCouponUid: "" };
}

export const PChecklistDetails = {
  encode(message: PChecklistDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clawdiaCoupon !== "") {
      writer.uint32(10).string(message.clawdiaCoupon);
    }
    if (message.clawdiaCouponSentAt !== undefined) {
      Timestamp.encode(toTimestamp(message.clawdiaCouponSentAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.clawdiaCouponUid !== "") {
      writer.uint32(26).string(message.clawdiaCouponUid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PChecklistDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePChecklistDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.clawdiaCoupon = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.clawdiaCouponSentAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.clawdiaCouponUid = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PChecklistDetails {
    return {
      clawdiaCoupon: isSet(object.clawdiaCoupon) ? String(object.clawdiaCoupon) : "",
      clawdiaCouponSentAt: isSet(object.clawdiaCouponSentAt)
        ? fromJsonTimestamp(object.clawdiaCouponSentAt)
        : undefined,
      clawdiaCouponUid: isSet(object.clawdiaCouponUid) ? String(object.clawdiaCouponUid) : "",
    };
  },

  toJSON(message: PChecklistDetails): unknown {
    const obj: any = {};
    message.clawdiaCoupon !== undefined && (obj.clawdiaCoupon = message.clawdiaCoupon);
    message.clawdiaCouponSentAt !== undefined && (obj.clawdiaCouponSentAt = message.clawdiaCouponSentAt.toISOString());
    message.clawdiaCouponUid !== undefined && (obj.clawdiaCouponUid = message.clawdiaCouponUid);
    return obj;
  },

  create<I extends Exact<DeepPartial<PChecklistDetails>, I>>(base?: I): PChecklistDetails {
    return PChecklistDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PChecklistDetails>, I>>(object: I): PChecklistDetails {
    const message = createBasePChecklistDetails();
    message.clawdiaCoupon = object.clawdiaCoupon ?? "";
    message.clawdiaCouponSentAt = object.clawdiaCouponSentAt ?? undefined;
    message.clawdiaCouponUid = object.clawdiaCouponUid ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
