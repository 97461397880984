import { isWixFlow } from "components/utils/UtilFunctions";
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useAppSelector } from "store";
import { PUserStatus } from "../../proto/PUser";
import { useAuth } from "../contexts/AuthContext"
import { createRedirectSearchParam } from "./AuthUtils";

export default function ProtectedRoute(routeProps: RouteProps) {
  const auth = useAuth()
  const currentUser = auth?.user

  const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

  const isWix = dbUser?.isWixUser || isWixFlow();

  const isIsraeliButCameToSign = dbUser?.country === "Israel" &&
  ((routeProps.location?.pathname?.startsWith("/docs/") ||
    (routeProps.location?.pathname?.split("/")?.length || 0) >= 5 && routeProps.location?.pathname.split("/")[4] == "finish")
     || routeProps.location?.pathname?.startsWith("/settings")
     || routeProps.location?.pathname === "/")

    const isIsraeliForChecklistVideo = dbUser?.country === "Israel" &&
      (routeProps.location?.pathname == "/tools" || routeProps.location?.pathname == "/tools/" || routeProps.location?.pathname?.startsWith("/tools/checklist-video"))

  const redirectUrlStr = createRedirectSearchParam();

  if (!currentUser) {
    return <Redirect to={{ pathname: "/signup", search: redirectUrlStr }} />;
  }

  if (routeProps.path === "/onboard" || routeProps.path === "/il-payment" || routeProps.path === "/logout") {
    return <Route {...routeProps} />;
  }

  if (isIsraeliButCameToSign) {
    return <Route {...routeProps} />;
  }

  if (isIsraeliForChecklistVideo) {
    return <Route {...routeProps} />;
  }

  if (auth?.userStatus === PUserStatus.PRE_ONBOARDING) {
    return <Redirect to={{ pathname: "/onboard", search: redirectUrlStr }} />;
  }

  // Somebody that came from Wix but chose Israel as his country, should be considered as a Wix user not an Israeli user
  if (auth?.userStatus === PUserStatus.POST_ONBOARDING && dbUser?.country === "Israel" && !isWix) {
    return <Redirect to={{ pathname: "/il-payment", search: redirectUrlStr }} />;
  }

  return <Route {...routeProps} />;
};
