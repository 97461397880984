import clsx from "clsx";
import { NewIcon } from "components";
import ReactTooltip from "react-tooltip";
import { Actions, useAppDispatch } from "store";
import "../../components/PreviewAgreement/WizardNavigator.scss";
import { ConditionRule } from "../ConditionRule";
import { toCompoundJsonLogic } from "../WizardUi/WizardForm";

const colors: { [key: string]: any } = {
    red: "border-red-500",
    blue: "border-blue-500",
    green: "border-greens-500",
};

interface ItemProps {
    children: JSX.Element | JSX.Element[];
    onRemove?: () => void;
    onChangeTitle?: (name: string, value: string) => void;
    provided?: any;
    className: string;
    item: Step | Question | Section;
    value: any;
    label: string;
}

export function Item(props: ItemProps) {
    const { value, item, children, onChangeTitle, onRemove, provided } = props;

    const onRemoveWithConfirmation = () => {
        if (onRemove) {
            if (window.confirm(`Are you sure you want to delete this item (with label ${props.label})?`)) {
                onRemove();
            }
        }
    };

    return (
        <div className="flex">
            <div className="flex flex-col w-full justify-center">
                <div className="flex items-center gap-1 relative">
                    <ItemBranch item={item} />
                    <div className={props.className} {...provided?.dragHandleProps}>
                        <span>{props.label}</span>
                    </div>
                    <div className={"flex items-center justify-between gap-1 text-2xl w-full"}>
                        <div className="mr-auto w-full">
                            {/* <TextField size="sm" name={item.id} onChange={onChangeTitle} type="text" value={value} /> */}
                            {/* <div className="text-sm">{value}</div> */}
                            <InlineEditableBox value={value} name={item.id} onChangeTitle={onChangeTitle} />
                        </div>
                    </div>

                    <NewIcon
                        onClick={onRemoveWithConfirmation}
                        name="x_circle"
                        className="text-gray-400 hover:text-primary"
                    />
                </div>

                <div className="pr-8">{children}</div>
            </div>
        </div>
    );
}

export function ItemBranch({ item }: { item: Step | Question | Section | FieldOption }) {
    const dispatch = useAppDispatch();

    const id = "branch_for_" + item.id;

    const showConditionModal = () => {
        dispatch(Actions.ui.openModal({ name: "LEGAL_IDE_WIZARD_CONDITION", data: item }));
    };

    return (
        <>
            {item.condition && (
                <ReactTooltip
                    place="bottom"
                    id={id}
                    className="bg-knowledge"
                    backgroundColor={"unknown"}
                    textColor={"#000"}
                >
                    <ConditionRule
                        key={id}
                        compoundJsonLogic={toCompoundJsonLogic(item.condition)}
                        onChange={() => {}}
                    />
                </ReactTooltip>
            )}
            <span
                data-tip
                data-for={id}
                className={clsx(
                    { "text-secondary": !!item.condition },
                    { "text-gray-300": !item.condition },
                    "material-symbols-outlined text-2xl cursor-pointer rotate-90 absolute -translate-x-7"
                )}
                onClick={showConditionModal}
            >
                alt_route
            </span>
        </>
    );
}

function InlineEditableBox({
    value,
    name,
    onChangeTitle,
}: {
    value: string;
    name: string;
    onChangeTitle?: (name: string, value: string) => void;
}) {
    return (
        <div className="inline-flex flex-col w-full">
            <input
                onChange={(e) => {
                    if (onChangeTitle) {
                        if (e.target.value) {
                            onChangeTitle(name, e.target.value);
                        } else {
                            onChangeTitle(name, "");
                        }
                    }
                }}
                className="_EditBox text-base border-0 outline-none font-medium overflow-ellipsis font-mono w-full"
                style={{ width: value + "ch" }}
                placeholder="Untitled"
                value={value}
            />
            <span className="_EditBoxBorder" />
        </div>
    );
}
