import { request } from "./ApiCommon";

function signInEndpoint(search: string, providerName: string, successCallback: Function, errorCallback: Function) {
    return request("/api/v1/oauth/" + providerName + "/signin" + search, {
        method: "GET",
    }).then((response) => {
        successCallback(response.data);
    }).catch((e) => {
        errorCallback(e);
    })
}

export const oauthApi = { signInEndpoint }
