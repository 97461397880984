/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum ENoticeOfTerminationTermScenario {
  NoticeOfTermination_termScenario_NA = 0,
  NoticeOfTermination_termScenario_IMMED_NONE = 1,
  NoticeOfTermination_termScenario_IMMED_NOTICE_PAY = 2,
  NoticeOfTermination_termScenario_FUTURE_NOTICE = 3,
  NoticeOfTermination_termScenario_FUTURE_NONE = 4,
  UNRECOGNIZED = -1,
}

export function eNoticeOfTerminationTermScenarioFromJSON(object: any): ENoticeOfTerminationTermScenario {
  switch (object) {
    case 0:
    case "NoticeOfTermination_termScenario_NA":
      return ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_NA;
    case 1:
    case "NoticeOfTermination_termScenario_IMMED_NONE":
      return ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_IMMED_NONE;
    case 2:
    case "NoticeOfTermination_termScenario_IMMED_NOTICE_PAY":
      return ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_IMMED_NOTICE_PAY;
    case 3:
    case "NoticeOfTermination_termScenario_FUTURE_NOTICE":
      return ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_FUTURE_NOTICE;
    case 4:
    case "NoticeOfTermination_termScenario_FUTURE_NONE":
      return ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_FUTURE_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENoticeOfTerminationTermScenario.UNRECOGNIZED;
  }
}

export function eNoticeOfTerminationTermScenarioToJSON(object: ENoticeOfTerminationTermScenario): string {
  switch (object) {
    case ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_NA:
      return "NoticeOfTermination_termScenario_NA";
    case ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_IMMED_NONE:
      return "NoticeOfTermination_termScenario_IMMED_NONE";
    case ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_IMMED_NOTICE_PAY:
      return "NoticeOfTermination_termScenario_IMMED_NOTICE_PAY";
    case ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_FUTURE_NOTICE:
      return "NoticeOfTermination_termScenario_FUTURE_NOTICE";
    case ENoticeOfTerminationTermScenario.NoticeOfTermination_termScenario_FUTURE_NONE:
      return "NoticeOfTermination_termScenario_FUTURE_NONE";
    case ENoticeOfTerminationTermScenario.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENoticeOfTerminationTermCause {
  NoticeOfTermination_termCause_NA = 0,
  NoticeOfTermination_termCause_NO_CAUSE = 1,
  NoticeOfTermination_termCause_CAUSE_UNDEF = 2,
  NoticeOfTermination_termCause_CAUSE_DEF = 3,
  UNRECOGNIZED = -1,
}

export function eNoticeOfTerminationTermCauseFromJSON(object: any): ENoticeOfTerminationTermCause {
  switch (object) {
    case 0:
    case "NoticeOfTermination_termCause_NA":
      return ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_NA;
    case 1:
    case "NoticeOfTermination_termCause_NO_CAUSE":
      return ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_NO_CAUSE;
    case 2:
    case "NoticeOfTermination_termCause_CAUSE_UNDEF":
      return ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_CAUSE_UNDEF;
    case 3:
    case "NoticeOfTermination_termCause_CAUSE_DEF":
      return ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_CAUSE_DEF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENoticeOfTerminationTermCause.UNRECOGNIZED;
  }
}

export function eNoticeOfTerminationTermCauseToJSON(object: ENoticeOfTerminationTermCause): string {
  switch (object) {
    case ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_NA:
      return "NoticeOfTermination_termCause_NA";
    case ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_NO_CAUSE:
      return "NoticeOfTermination_termCause_NO_CAUSE";
    case ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_CAUSE_UNDEF:
      return "NoticeOfTermination_termCause_CAUSE_UNDEF";
    case ENoticeOfTerminationTermCause.NoticeOfTermination_termCause_CAUSE_DEF:
      return "NoticeOfTermination_termCause_CAUSE_DEF";
    case ENoticeOfTerminationTermCause.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MNoticeOfTerminationTermScenario {
  optionDbValue: ENoticeOfTerminationTermScenario;
  other: string;
}

export interface MNoticeOfTerminationTermCause {
  optionDbValue: ENoticeOfTerminationTermCause;
  other: string;
}

export interface NoticeOfTermination {
  effectiveDate: Date | undefined;
  employeeAddressOther: string;
  employeeAddress: string;
  employeeEmail: string;
  employeeName: string;
  employeeSalutation: string;
  termScenario: MNoticeOfTerminationTermScenario | undefined;
  termCause: MNoticeOfTerminationTermCause | undefined;
  terminationDate: Date | undefined;
  customTerms: InternalBoolean;
  restrictiveCovenantSource: string;
  pendingSeparationAgreement: InternalBoolean;
  noncompete: InternalBoolean;
  nonsolicit: InternalBoolean;
  antiraiding: InternalBoolean;
  noNoticeDate: Date | undefined;
  customTermsDetail: string;
  employmentAgreement: string;
  restrictiveCovenants: InternalBoolean;
  nondisparagement: InternalBoolean;
}

function createBaseMNoticeOfTerminationTermScenario(): MNoticeOfTerminationTermScenario {
  return { optionDbValue: 0, other: "" };
}

export const MNoticeOfTerminationTermScenario = {
  encode(message: MNoticeOfTerminationTermScenario, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNoticeOfTerminationTermScenario {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNoticeOfTerminationTermScenario();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNoticeOfTerminationTermScenario {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNoticeOfTerminationTermScenarioFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNoticeOfTerminationTermScenario): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNoticeOfTerminationTermScenarioToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNoticeOfTerminationTermScenario>, I>>(
    base?: I,
  ): MNoticeOfTerminationTermScenario {
    return MNoticeOfTerminationTermScenario.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNoticeOfTerminationTermScenario>, I>>(
    object: I,
  ): MNoticeOfTerminationTermScenario {
    const message = createBaseMNoticeOfTerminationTermScenario();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNoticeOfTerminationTermCause(): MNoticeOfTerminationTermCause {
  return { optionDbValue: 0, other: "" };
}

export const MNoticeOfTerminationTermCause = {
  encode(message: MNoticeOfTerminationTermCause, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNoticeOfTerminationTermCause {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNoticeOfTerminationTermCause();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNoticeOfTerminationTermCause {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNoticeOfTerminationTermCauseFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNoticeOfTerminationTermCause): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNoticeOfTerminationTermCauseToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNoticeOfTerminationTermCause>, I>>(base?: I): MNoticeOfTerminationTermCause {
    return MNoticeOfTerminationTermCause.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNoticeOfTerminationTermCause>, I>>(
    object: I,
  ): MNoticeOfTerminationTermCause {
    const message = createBaseMNoticeOfTerminationTermCause();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseNoticeOfTermination(): NoticeOfTermination {
  return {
    effectiveDate: undefined,
    employeeAddressOther: "",
    employeeAddress: "",
    employeeEmail: "",
    employeeName: "",
    employeeSalutation: "",
    termScenario: undefined,
    termCause: undefined,
    terminationDate: undefined,
    customTerms: 0,
    restrictiveCovenantSource: "",
    pendingSeparationAgreement: 0,
    noncompete: 0,
    nonsolicit: 0,
    antiraiding: 0,
    noNoticeDate: undefined,
    customTermsDetail: "",
    employmentAgreement: "",
    restrictiveCovenants: 0,
    nondisparagement: 0,
  };
}

export const NoticeOfTermination = {
  encode(message: NoticeOfTermination, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.employeeAddressOther !== "") {
      writer.uint32(18).string(message.employeeAddressOther);
    }
    if (message.employeeAddress !== "") {
      writer.uint32(26).string(message.employeeAddress);
    }
    if (message.employeeEmail !== "") {
      writer.uint32(34).string(message.employeeEmail);
    }
    if (message.employeeName !== "") {
      writer.uint32(42).string(message.employeeName);
    }
    if (message.employeeSalutation !== "") {
      writer.uint32(50).string(message.employeeSalutation);
    }
    if (message.termScenario !== undefined) {
      MNoticeOfTerminationTermScenario.encode(message.termScenario, writer.uint32(58).fork()).ldelim();
    }
    if (message.termCause !== undefined) {
      MNoticeOfTerminationTermCause.encode(message.termCause, writer.uint32(66).fork()).ldelim();
    }
    if (message.terminationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.terminationDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.customTerms !== 0) {
      writer.uint32(80).int32(message.customTerms);
    }
    if (message.restrictiveCovenantSource !== "") {
      writer.uint32(90).string(message.restrictiveCovenantSource);
    }
    if (message.pendingSeparationAgreement !== 0) {
      writer.uint32(96).int32(message.pendingSeparationAgreement);
    }
    if (message.noncompete !== 0) {
      writer.uint32(104).int32(message.noncompete);
    }
    if (message.nonsolicit !== 0) {
      writer.uint32(112).int32(message.nonsolicit);
    }
    if (message.antiraiding !== 0) {
      writer.uint32(120).int32(message.antiraiding);
    }
    if (message.noNoticeDate !== undefined) {
      Timestamp.encode(toTimestamp(message.noNoticeDate), writer.uint32(130).fork()).ldelim();
    }
    if (message.customTermsDetail !== "") {
      writer.uint32(138).string(message.customTermsDetail);
    }
    if (message.employmentAgreement !== "") {
      writer.uint32(146).string(message.employmentAgreement);
    }
    if (message.restrictiveCovenants !== 0) {
      writer.uint32(152).int32(message.restrictiveCovenants);
    }
    if (message.nondisparagement !== 0) {
      writer.uint32(160).int32(message.nondisparagement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NoticeOfTermination {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNoticeOfTermination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.employeeAddressOther = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.employeeAddress = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.employeeEmail = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.employeeName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.employeeSalutation = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.termScenario = MNoticeOfTerminationTermScenario.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.termCause = MNoticeOfTerminationTermCause.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.terminationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 80) {
            break;
          }

          message.customTerms = reader.int32() as any;
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.restrictiveCovenantSource = reader.string();
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.pendingSeparationAgreement = reader.int32() as any;
          continue;
        case 13:
          if (tag != 104) {
            break;
          }

          message.noncompete = reader.int32() as any;
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.nonsolicit = reader.int32() as any;
          continue;
        case 15:
          if (tag != 120) {
            break;
          }

          message.antiraiding = reader.int32() as any;
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.noNoticeDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.customTermsDetail = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.employmentAgreement = reader.string();
          continue;
        case 19:
          if (tag != 152) {
            break;
          }

          message.restrictiveCovenants = reader.int32() as any;
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.nondisparagement = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NoticeOfTermination {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      employeeAddressOther: isSet(object.employeeAddressOther) ? String(object.employeeAddressOther) : "",
      employeeAddress: isSet(object.employeeAddress) ? String(object.employeeAddress) : "",
      employeeEmail: isSet(object.employeeEmail) ? String(object.employeeEmail) : "",
      employeeName: isSet(object.employeeName) ? String(object.employeeName) : "",
      employeeSalutation: isSet(object.employeeSalutation) ? String(object.employeeSalutation) : "",
      termScenario: isSet(object.termScenario)
        ? MNoticeOfTerminationTermScenario.fromJSON(object.termScenario)
        : undefined,
      termCause: isSet(object.termCause) ? MNoticeOfTerminationTermCause.fromJSON(object.termCause) : undefined,
      terminationDate: isSet(object.terminationDate) ? fromJsonTimestamp(object.terminationDate) : undefined,
      customTerms: isSet(object.customTerms) ? internalBooleanFromJSON(object.customTerms) : 0,
      restrictiveCovenantSource: isSet(object.restrictiveCovenantSource)
        ? String(object.restrictiveCovenantSource)
        : "",
      pendingSeparationAgreement: isSet(object.pendingSeparationAgreement)
        ? internalBooleanFromJSON(object.pendingSeparationAgreement)
        : 0,
      noncompete: isSet(object.noncompete) ? internalBooleanFromJSON(object.noncompete) : 0,
      nonsolicit: isSet(object.nonsolicit) ? internalBooleanFromJSON(object.nonsolicit) : 0,
      antiraiding: isSet(object.antiraiding) ? internalBooleanFromJSON(object.antiraiding) : 0,
      noNoticeDate: isSet(object.noNoticeDate) ? fromJsonTimestamp(object.noNoticeDate) : undefined,
      customTermsDetail: isSet(object.customTermsDetail) ? String(object.customTermsDetail) : "",
      employmentAgreement: isSet(object.employmentAgreement) ? String(object.employmentAgreement) : "",
      restrictiveCovenants: isSet(object.restrictiveCovenants)
        ? internalBooleanFromJSON(object.restrictiveCovenants)
        : 0,
      nondisparagement: isSet(object.nondisparagement) ? internalBooleanFromJSON(object.nondisparagement) : 0,
    };
  },

  toJSON(message: NoticeOfTermination): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.employeeAddressOther !== undefined && (obj.employeeAddressOther = message.employeeAddressOther);
    message.employeeAddress !== undefined && (obj.employeeAddress = message.employeeAddress);
    message.employeeEmail !== undefined && (obj.employeeEmail = message.employeeEmail);
    message.employeeName !== undefined && (obj.employeeName = message.employeeName);
    message.employeeSalutation !== undefined && (obj.employeeSalutation = message.employeeSalutation);
    message.termScenario !== undefined && (obj.termScenario = message.termScenario
      ? MNoticeOfTerminationTermScenario.toJSON(message.termScenario)
      : undefined);
    message.termCause !== undefined &&
      (obj.termCause = message.termCause ? MNoticeOfTerminationTermCause.toJSON(message.termCause) : undefined);
    message.terminationDate !== undefined && (obj.terminationDate = message.terminationDate.toISOString());
    message.customTerms !== undefined && (obj.customTerms = internalBooleanToJSON(message.customTerms));
    message.restrictiveCovenantSource !== undefined &&
      (obj.restrictiveCovenantSource = message.restrictiveCovenantSource);
    message.pendingSeparationAgreement !== undefined &&
      (obj.pendingSeparationAgreement = internalBooleanToJSON(message.pendingSeparationAgreement));
    message.noncompete !== undefined && (obj.noncompete = internalBooleanToJSON(message.noncompete));
    message.nonsolicit !== undefined && (obj.nonsolicit = internalBooleanToJSON(message.nonsolicit));
    message.antiraiding !== undefined && (obj.antiraiding = internalBooleanToJSON(message.antiraiding));
    message.noNoticeDate !== undefined && (obj.noNoticeDate = message.noNoticeDate.toISOString());
    message.customTermsDetail !== undefined && (obj.customTermsDetail = message.customTermsDetail);
    message.employmentAgreement !== undefined && (obj.employmentAgreement = message.employmentAgreement);
    message.restrictiveCovenants !== undefined &&
      (obj.restrictiveCovenants = internalBooleanToJSON(message.restrictiveCovenants));
    message.nondisparagement !== undefined && (obj.nondisparagement = internalBooleanToJSON(message.nondisparagement));
    return obj;
  },

  create<I extends Exact<DeepPartial<NoticeOfTermination>, I>>(base?: I): NoticeOfTermination {
    return NoticeOfTermination.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NoticeOfTermination>, I>>(object: I): NoticeOfTermination {
    const message = createBaseNoticeOfTermination();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.employeeAddressOther = object.employeeAddressOther ?? "";
    message.employeeAddress = object.employeeAddress ?? "";
    message.employeeEmail = object.employeeEmail ?? "";
    message.employeeName = object.employeeName ?? "";
    message.employeeSalutation = object.employeeSalutation ?? "";
    message.termScenario = (object.termScenario !== undefined && object.termScenario !== null)
      ? MNoticeOfTerminationTermScenario.fromPartial(object.termScenario)
      : undefined;
    message.termCause = (object.termCause !== undefined && object.termCause !== null)
      ? MNoticeOfTerminationTermCause.fromPartial(object.termCause)
      : undefined;
    message.terminationDate = object.terminationDate ?? undefined;
    message.customTerms = object.customTerms ?? 0;
    message.restrictiveCovenantSource = object.restrictiveCovenantSource ?? "";
    message.pendingSeparationAgreement = object.pendingSeparationAgreement ?? 0;
    message.noncompete = object.noncompete ?? 0;
    message.nonsolicit = object.nonsolicit ?? 0;
    message.antiraiding = object.antiraiding ?? 0;
    message.noNoticeDate = object.noNoticeDate ?? undefined;
    message.customTermsDetail = object.customTermsDetail ?? "";
    message.employmentAgreement = object.employmentAgreement ?? "";
    message.restrictiveCovenants = object.restrictiveCovenants ?? 0;
    message.nondisparagement = object.nondisparagement ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
