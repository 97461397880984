import  {useEffect, useState} from "react";

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const windowChangeListener = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', windowChangeListener);
        return () => {
            window.removeEventListener('resize', windowChangeListener);
        }
    }, []);

    // 640 needs to be in-sync with tailwind
    // todo - make sure this const is loaded from tailwind
    return (width <= 640);
}

export default useCheckMobileScreen
