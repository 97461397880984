import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import "./style.css";

import * as ContextMenu from "@radix-ui/react-context-menu";
import { nanoid } from "nanoid";
import Modal from "components/Modal";
import { ContextProps, TableContextProvider, useTable } from "./TableContext";
import { Cell } from "./Cell";
import { Column, Columns } from "./Column";
import { Row } from "./Row";
import { PTable, PTableData, PTableRow } from "proto/contracts/PUtils";

class Convertor {
    to(data: { [key: string]: any }[]): PTable {
        // to PTable
        let rows: PTableRow[] = [];
        for (const row of data) {
            if (!row) {
                rows.push({ data: [] });
                continue;
            }
            const tableData: PTableData[] = Object.keys(row).map((key) => {
                return {
                    key: key,
                    value: row[key].toString(),
                };
            });
            rows.push({ data: tableData });
        }

        return { rows: rows };
    }
    from(table: PTable): { [key: string]: any }[] {
        // form PTable
        if (!table?.rows) return [];
        return table.rows.map((row) => {
            const rowData: { [key: string]: any } = {};
            row.data.forEach((data) => {
                rowData[data.key] = data.value;
            });
            return rowData;
        });
    }
}

export const tableConvertor = new Convertor();

export const createTableDefaultValue = (options?: TableProperties) => {
    let data = new Array(options?.rows?.length ?? 1).fill({});

    for (const item of data) {
        for (const column of options?.columns ?? []) {
            item[column.id] = "";
        }
    }

    return data;
};

export const CTable = (props: Omit<ContextProps, "children">) => {
    const { data, options } = props;

    return (
        <TableContextProvider {...props}>
            <div className="relative">
                {!!options?.editable && <TableBar />}
                <table className="w-full">
                    <thead>
                        <Columns />
                    </thead>
                    <tbody>
                        {data.map((item, rowIndex) => {
                            return <Row item={item} index={rowIndex} key={rowIndex} />;
                        })}
                    </tbody>
                </table>
                {!options?.footer?.hide && <TableFooter />}
            </div>
        </TableContextProvider>
    );
};

function TableBar() {
    const api = useTable();

    return (
        <div className="flex gap-4">
            <div className="border">
                <button onClick={api.addColumn}>add column</button>
            </div>
            <div className="border">
                <button onClick={api.addDefaultRow}>add row</button>
            </div>
            <div className="border">
                <input defaultChecked={!!api.options?.row?.heading} type={"checkbox"} onChange={api.toggleRowHeading} />
                rows heading
            </div>
            <div className="border">
                <input
                    defaultChecked={api.options?.row?.unlimited}
                    type={"checkbox"}
                    onChange={api.toggleUnlimitedRows}
                />
                unlimited rows
            </div>
        </div>
    );
}

function TableFooter() {
    const api = useTable();

    if (!api.options?.row?.unlimited) {
        return null;
    }

    return (
        <div className="py-4">
            {(api.isRowUnlimited() || true) && (
                <div className="">
                    <button onClick={api.addRow} className="flex  items-center">
                        + add row
                    </button>
                </div>
            )}
        </div>
    );
}
