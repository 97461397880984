export const ContentMock = ({ style }: { style?: React.CSSProperties }) => {
    return (
        <div
            style={style}
            className="absolute pointer-events-none overflow-hidden top-0 left-0 bottom-0 right-0 px-[60px] mt-[100px] leading-6"
        >
            <h1 className="text-center text-2xl">Example Header</h1>
            {new Array(10).fill(0).map((_, i) => (
                <p key={i} className="leading-10 text-base mb-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolore porro, deserunt tempore maxime
                    aliquam harum accusantium nesciunt explicabo reprehenderit, alias natus fuga facilis aut corrupti
                    nemo? Quisquam omnis commodi et!
                </p>
            ))}
        </div>
    );
};
