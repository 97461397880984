import React from "react";
import { Node } from "slate";
import { jsx } from "slate-hyperscript";
import { El } from "../types/custom-types";

const ELEMENT_TAGS: { [key: string]: any } = {
    A: (el: any) => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "quote" }),
    H1: () => ({ type: "heading-one" }),
    H2: () => ({ type: "heading-two" }),
    H3: () => ({ type: "heading-three" }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    IMG: (el: any) => ({ type: "image", url: el.getAttribute("src") }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    PRE: () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS: { [key: string]: any } = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    U: () => ({ underline: true }),
    SPAN: () => ({}),
};

function cssPropertyToJsx(property: string): string {
    return property.replace(/-./g, (a) => {
        return a.split("")[1].toUpperCase();
    });
}

export const serialize = (nodes: any) => {
    return nodes.map((n: any) => Node.string(n)).join("\n");
};

interface SignatureCounter {
    count: number;
}

export const deserialize = (signatureCounter: SignatureCounter, el: El): any => {
    try {
        if (el.nodeType === 3) {
            // TEXT_NODE (3) => The actual Text inside an Element or Attr.
            return el.textContent;
        } else if (el.nodeType !== 1) {
            return null;
        } else if (el.nodeName === "BR") {
            return "\n";
        }

        const style = el.style;
        const styleList = style.cssText.split("; ");

        const elementStyle: { [key: string]: string } = {};
        for (const rule of styleList) {
            if (!rule) continue;
            let [property, value] = rule.split(": ");
            value = value?.replace(";", "");
            value = value?.replaceAll('"', "");

            if (property === "font-size") {
                // conver pt to rem;
                let pt = +value.replace("pt", "");
                value = pt / 12 + "rem";
            }

            elementStyle[cssPropertyToJsx(property)] = value?.replace(";", "");
        }

        const { nodeName } = el;

        let parent = el;

        if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
            parent = el.childNodes[0] as El;
        }

        if (el.dataset["component"] === "kb") {
            const content = el.querySelector('[data-content="true"]');
            let children = [{ text: content?.textContent }];

            return jsx("element", { type: "paragraph" }, children);
        }

        if (el.dataset["component"] === "signature") {
            let children = [{ text: "" }];
            return jsx("element", { type: "signature", count: signatureCounter.count++ }, children);
        }

        let children = Array.from(parent.childNodes as unknown as El[])
            .map(deserialize.bind(null, signatureCounter))
            .flat()
            .filter((child) => child !== "");

        if (children?.length === 0) {
            return null // filter empty elements
        }

        if (el.nodeName === "BODY") {
            return jsx("fragment", {}, children);
        }

        if (ELEMENT_TAGS[nodeName]) {
            const attrs = ELEMENT_TAGS[nodeName](el);
            const props = { ...attrs, style: elementStyle };
            return jsx("element", props, children);
        }

        if (TEXT_TAGS[nodeName]) {
            const attrs = TEXT_TAGS[nodeName](el);

            return children.map((child) => {
                return jsx("text", { ...attrs, style: elementStyle }, child);
            });
        }

        return children;
    } catch (error) {
        console.warn("deserialize error", error);
    }
};
