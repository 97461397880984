import CompletedIcon from "img/icons/completed.svg"
import DraftsIcon from "img/icons/drafts.svg"
import InboxIcon from "img/icons/inbox.svg"
import SentIcon from "img/icons/sent.svg"
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { MainStore } from 'store/mainTypes'


export default function LifeCycleBox() {
    return (
        <>
            <div data-name="LifeCycleBoxDesktop" className='hidden lg:flex w-80 bg-primaryVeryLight py-8 px-4 h-fit rounded-lg sticky top-5'>
                <LifeCycleBoxOptions />
            </div>
            <div data-name="LifeCycleBoxMobile" className="flex lg:hidden w-full bg-primary">
                <LifeCycleBoxOptions />
            </div>
        </>
    )
}

function LifeCycleBoxOptions() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    const uploaded = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.uploadedDocs);

    return (
        <div className='flex justify-between flex-row lg:flex-col gap-3 lg:gap-4 w-full overflow-x-auto sm:overflow-x-hidden s'>
            <LifeCycleBoxOption icon={CompletedIcon} text={"Upload & Analyze"} path="/docs/uploaded" count={uploaded.length} />
            <LifeCycleBoxOption icon={DraftsIcon} text="Drafts" path="/docs/drafts" count={drafts.length} />
            <LifeCycleBoxOption icon={SentIcon} text="Out for Signature" path="/docs/out-for-signature" count={sentDocs.length} />
            <LifeCycleBoxOption icon={InboxIcon} text="Awaiting Signature" path="/docs/awaiting-signature" count={inboxDocs.length} />
            <LifeCycleBoxOption icon={CompletedIcon} text="Completed" path="/docs/completed" count={completedDocs.length} />
        </div>
    )
}

function LifeCycleBoxOption({ icon, text, path, count }: { icon: string; text: string; path: string, count: number }) {
    const history = useHistory()
    const location = useLocation()

    const isActive = location.pathname === path

    return (
        <div className={`flex rounded-xl px-0 lg:px-3 lg:py-4 items-center gap-3 cursor-pointer
            lg:hover:bg-white  lg:border border-none
            ${isActive ? "lg:bg-white lg:border-lightSkyBlue lg:border-opacity-50 shadow-lifeCycleOption" : "opacity-50 lg:opacity-100"}
            `}
            onClick={() => {
                history.push(path)
            }}
        >
            <img className='h-4 hidden lg:flex' src={icon} alt={text} />
            <p className='inline-flex text-white lg:text-darkIndigo text-lg lg:text-base font-medium whitespace-nowrap'>{text}&nbsp;
                {count > 0 && <span className={`text-white lg:text-primary`}>{`(${count})`}</span>}
            </p>
        </div>
    )
}

export function LifeCycleBoxesForDashboard() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    const uploaded = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.uploadedDocs);

    return <div className='grid lg:grid-cols-5 grid-cols-2 justify-between divide-x divide-y lg:divide-y-0 divide-solid divide-lightSkyBlue lg:py-5 h-full'>
        <div className='hidden lg:block' >
            <LifeCycleBoxOptionForDashboard icon={CompletedIcon} text={"Upload & Analyze"} path="/docs/uploaded" count={uploaded.length} />
        </div>

        <LifeCycleBoxOptionForDashboard icon={DraftsIcon} text="Drafts" path="/docs/drafts" count={drafts.length} />
        <LifeCycleBoxOptionForDashboard icon={SentIcon} text="Out for Signature" path="/docs/out-for-signature" count={sentDocs.length} />
        <LifeCycleBoxOptionForDashboard icon={InboxIcon} text="Awaiting Signature" path="/docs/awaiting-signature" count={inboxDocs.length} />
        <LifeCycleBoxOptionForDashboard icon={CompletedIcon} text="Completed" path="/docs/completed" count={completedDocs.length} />
    </div >
}

function LifeCycleBoxOptionForDashboard({ icon, text, path, count }: { icon: string, text: string, path: string, count: number }) {
    const history = useHistory()

    return <div className='flex flex-col items-center justify-center gap-1 lg:gap-6 cursor-pointer h-full' onClick={() => history.push(path)}>
        <div className='flex flex-row lg:flex-row-reverse justify-center items-center gap-2'>
            <div className='text-xl lg:text-3xl text-darkIndigo font-medium'>{count}</div>
            <img src={icon} alt={text} className='h-4 aspect-auto' />
        </div>
        <div className='text-base text-darkIndigo'>{text}</div>
    </div >
}