/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "";

export enum ETermsOfUseEntityType {
  TermsOfUse_entityType_NA = 0,
  TermsOfUse_entityType_CORPORATION = 1,
  TermsOfUse_entityType_LIMITED_LIABILITY_COMPANY = 2,
  TermsOfUse_entityType_SOLE_PROPRIETORSHIP = 3,
  TermsOfUse_entityType_LIMITED_PARTNERSHIP = 4,
  TermsOfUse_entityType_PROFESSIONAL_CORPORATION = 5,
  TermsOfUse_entityType_PROFESSIONAL_ASSOCIATION = 6,
  TermsOfUse_entityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  TermsOfUse_entityType_NONPROFIT_CORPORATION = 8,
  TermsOfUse_entityType_UNINCORPORATED_ASSOCIATION = 9,
  UNRECOGNIZED = -1,
}

export function eTermsOfUseEntityTypeFromJSON(object: any): ETermsOfUseEntityType {
  switch (object) {
    case 0:
    case "TermsOfUse_entityType_NA":
      return ETermsOfUseEntityType.TermsOfUse_entityType_NA;
    case 1:
    case "TermsOfUse_entityType_CORPORATION":
      return ETermsOfUseEntityType.TermsOfUse_entityType_CORPORATION;
    case 2:
    case "TermsOfUse_entityType_LIMITED_LIABILITY_COMPANY":
      return ETermsOfUseEntityType.TermsOfUse_entityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "TermsOfUse_entityType_SOLE_PROPRIETORSHIP":
      return ETermsOfUseEntityType.TermsOfUse_entityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "TermsOfUse_entityType_LIMITED_PARTNERSHIP":
      return ETermsOfUseEntityType.TermsOfUse_entityType_LIMITED_PARTNERSHIP;
    case 5:
    case "TermsOfUse_entityType_PROFESSIONAL_CORPORATION":
      return ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "TermsOfUse_entityType_PROFESSIONAL_ASSOCIATION":
      return ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "TermsOfUse_entityType_PROFESSIONAL_LIMITED_LIABILITY":
      return ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "TermsOfUse_entityType_NONPROFIT_CORPORATION":
      return ETermsOfUseEntityType.TermsOfUse_entityType_NONPROFIT_CORPORATION;
    case 9:
    case "TermsOfUse_entityType_UNINCORPORATED_ASSOCIATION":
      return ETermsOfUseEntityType.TermsOfUse_entityType_UNINCORPORATED_ASSOCIATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ETermsOfUseEntityType.UNRECOGNIZED;
  }
}

export function eTermsOfUseEntityTypeToJSON(object: ETermsOfUseEntityType): string {
  switch (object) {
    case ETermsOfUseEntityType.TermsOfUse_entityType_NA:
      return "TermsOfUse_entityType_NA";
    case ETermsOfUseEntityType.TermsOfUse_entityType_CORPORATION:
      return "TermsOfUse_entityType_CORPORATION";
    case ETermsOfUseEntityType.TermsOfUse_entityType_LIMITED_LIABILITY_COMPANY:
      return "TermsOfUse_entityType_LIMITED_LIABILITY_COMPANY";
    case ETermsOfUseEntityType.TermsOfUse_entityType_SOLE_PROPRIETORSHIP:
      return "TermsOfUse_entityType_SOLE_PROPRIETORSHIP";
    case ETermsOfUseEntityType.TermsOfUse_entityType_LIMITED_PARTNERSHIP:
      return "TermsOfUse_entityType_LIMITED_PARTNERSHIP";
    case ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_CORPORATION:
      return "TermsOfUse_entityType_PROFESSIONAL_CORPORATION";
    case ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_ASSOCIATION:
      return "TermsOfUse_entityType_PROFESSIONAL_ASSOCIATION";
    case ETermsOfUseEntityType.TermsOfUse_entityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "TermsOfUse_entityType_PROFESSIONAL_LIMITED_LIABILITY";
    case ETermsOfUseEntityType.TermsOfUse_entityType_NONPROFIT_CORPORATION:
      return "TermsOfUse_entityType_NONPROFIT_CORPORATION";
    case ETermsOfUseEntityType.TermsOfUse_entityType_UNINCORPORATED_ASSOCIATION:
      return "TermsOfUse_entityType_UNINCORPORATED_ASSOCIATION";
    case ETermsOfUseEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ETermsOfUsePurposeType {
  TermsOfUse_purposeType_NA = 0,
  TermsOfUse_purposeType_PERSONAL = 1,
  TermsOfUse_purposeType_BUSINESS = 2,
  TermsOfUse_purposeType_PERSONAL_AND_BUSINESS = 3,
  UNRECOGNIZED = -1,
}

export function eTermsOfUsePurposeTypeFromJSON(object: any): ETermsOfUsePurposeType {
  switch (object) {
    case 0:
    case "TermsOfUse_purposeType_NA":
      return ETermsOfUsePurposeType.TermsOfUse_purposeType_NA;
    case 1:
    case "TermsOfUse_purposeType_PERSONAL":
      return ETermsOfUsePurposeType.TermsOfUse_purposeType_PERSONAL;
    case 2:
    case "TermsOfUse_purposeType_BUSINESS":
      return ETermsOfUsePurposeType.TermsOfUse_purposeType_BUSINESS;
    case 3:
    case "TermsOfUse_purposeType_PERSONAL_AND_BUSINESS":
      return ETermsOfUsePurposeType.TermsOfUse_purposeType_PERSONAL_AND_BUSINESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ETermsOfUsePurposeType.UNRECOGNIZED;
  }
}

export function eTermsOfUsePurposeTypeToJSON(object: ETermsOfUsePurposeType): string {
  switch (object) {
    case ETermsOfUsePurposeType.TermsOfUse_purposeType_NA:
      return "TermsOfUse_purposeType_NA";
    case ETermsOfUsePurposeType.TermsOfUse_purposeType_PERSONAL:
      return "TermsOfUse_purposeType_PERSONAL";
    case ETermsOfUsePurposeType.TermsOfUse_purposeType_BUSINESS:
      return "TermsOfUse_purposeType_BUSINESS";
    case ETermsOfUsePurposeType.TermsOfUse_purposeType_PERSONAL_AND_BUSINESS:
      return "TermsOfUse_purposeType_PERSONAL_AND_BUSINESS";
    case ETermsOfUsePurposeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ETermsOfUseHelpType {
  TermsOfUse_helpType_NA = 0,
  TermsOfUse_helpType_EMAIL = 1,
  TermsOfUse_helpType_CONTACT_PAGE = 2,
  UNRECOGNIZED = -1,
}

export function eTermsOfUseHelpTypeFromJSON(object: any): ETermsOfUseHelpType {
  switch (object) {
    case 0:
    case "TermsOfUse_helpType_NA":
      return ETermsOfUseHelpType.TermsOfUse_helpType_NA;
    case 1:
    case "TermsOfUse_helpType_EMAIL":
      return ETermsOfUseHelpType.TermsOfUse_helpType_EMAIL;
    case 2:
    case "TermsOfUse_helpType_CONTACT_PAGE":
      return ETermsOfUseHelpType.TermsOfUse_helpType_CONTACT_PAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ETermsOfUseHelpType.UNRECOGNIZED;
  }
}

export function eTermsOfUseHelpTypeToJSON(object: ETermsOfUseHelpType): string {
  switch (object) {
    case ETermsOfUseHelpType.TermsOfUse_helpType_NA:
      return "TermsOfUse_helpType_NA";
    case ETermsOfUseHelpType.TermsOfUse_helpType_EMAIL:
      return "TermsOfUse_helpType_EMAIL";
    case ETermsOfUseHelpType.TermsOfUse_helpType_CONTACT_PAGE:
      return "TermsOfUse_helpType_CONTACT_PAGE";
    case ETermsOfUseHelpType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MTermsOfUseEntityType {
  optionDbValue: ETermsOfUseEntityType;
  other: string;
}

export interface MTermsOfUsePurposeType {
  optionDbValue: ETermsOfUsePurposeType;
  other: string;
}

export interface MTermsOfUseHelpType {
  optionDbValue: ETermsOfUseHelpType;
  other: string;
}

export interface TermsOfUse {
  companyFull: string;
  websiteUrl: string;
  privacyUrl: string;
  helpEmail: string;
  helpLink: string;
  websitePurpose: string;
  companyShort: string;
  entityState: string;
  entityType: MTermsOfUseEntityType | undefined;
  purposeType: MTermsOfUsePurposeType | undefined;
  emailLegal: string;
  addressLegal: string;
  governLawState: string;
  venue: string;
  lastUpdateTime: Date | undefined;
  helpType: MTermsOfUseHelpType | undefined;
}

function createBaseMTermsOfUseEntityType(): MTermsOfUseEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MTermsOfUseEntityType = {
  encode(message: MTermsOfUseEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MTermsOfUseEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMTermsOfUseEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MTermsOfUseEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eTermsOfUseEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MTermsOfUseEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eTermsOfUseEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MTermsOfUseEntityType>, I>>(base?: I): MTermsOfUseEntityType {
    return MTermsOfUseEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MTermsOfUseEntityType>, I>>(object: I): MTermsOfUseEntityType {
    const message = createBaseMTermsOfUseEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMTermsOfUsePurposeType(): MTermsOfUsePurposeType {
  return { optionDbValue: 0, other: "" };
}

export const MTermsOfUsePurposeType = {
  encode(message: MTermsOfUsePurposeType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MTermsOfUsePurposeType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMTermsOfUsePurposeType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MTermsOfUsePurposeType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eTermsOfUsePurposeTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MTermsOfUsePurposeType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eTermsOfUsePurposeTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MTermsOfUsePurposeType>, I>>(base?: I): MTermsOfUsePurposeType {
    return MTermsOfUsePurposeType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MTermsOfUsePurposeType>, I>>(object: I): MTermsOfUsePurposeType {
    const message = createBaseMTermsOfUsePurposeType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMTermsOfUseHelpType(): MTermsOfUseHelpType {
  return { optionDbValue: 0, other: "" };
}

export const MTermsOfUseHelpType = {
  encode(message: MTermsOfUseHelpType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MTermsOfUseHelpType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMTermsOfUseHelpType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MTermsOfUseHelpType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eTermsOfUseHelpTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MTermsOfUseHelpType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eTermsOfUseHelpTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MTermsOfUseHelpType>, I>>(base?: I): MTermsOfUseHelpType {
    return MTermsOfUseHelpType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MTermsOfUseHelpType>, I>>(object: I): MTermsOfUseHelpType {
    const message = createBaseMTermsOfUseHelpType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseTermsOfUse(): TermsOfUse {
  return {
    companyFull: "",
    websiteUrl: "",
    privacyUrl: "",
    helpEmail: "",
    helpLink: "",
    websitePurpose: "",
    companyShort: "",
    entityState: "",
    entityType: undefined,
    purposeType: undefined,
    emailLegal: "",
    addressLegal: "",
    governLawState: "",
    venue: "",
    lastUpdateTime: undefined,
    helpType: undefined,
  };
}

export const TermsOfUse = {
  encode(message: TermsOfUse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.websiteUrl !== "") {
      writer.uint32(18).string(message.websiteUrl);
    }
    if (message.privacyUrl !== "") {
      writer.uint32(26).string(message.privacyUrl);
    }
    if (message.helpEmail !== "") {
      writer.uint32(34).string(message.helpEmail);
    }
    if (message.helpLink !== "") {
      writer.uint32(42).string(message.helpLink);
    }
    if (message.websitePurpose !== "") {
      writer.uint32(50).string(message.websitePurpose);
    }
    if (message.companyShort !== "") {
      writer.uint32(58).string(message.companyShort);
    }
    if (message.entityState !== "") {
      writer.uint32(66).string(message.entityState);
    }
    if (message.entityType !== undefined) {
      MTermsOfUseEntityType.encode(message.entityType, writer.uint32(74).fork()).ldelim();
    }
    if (message.purposeType !== undefined) {
      MTermsOfUsePurposeType.encode(message.purposeType, writer.uint32(82).fork()).ldelim();
    }
    if (message.emailLegal !== "") {
      writer.uint32(90).string(message.emailLegal);
    }
    if (message.addressLegal !== "") {
      writer.uint32(98).string(message.addressLegal);
    }
    if (message.governLawState !== "") {
      writer.uint32(106).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(114).string(message.venue);
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(122).fork()).ldelim();
    }
    if (message.helpType !== undefined) {
      MTermsOfUseHelpType.encode(message.helpType, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TermsOfUse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTermsOfUse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.privacyUrl = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.helpEmail = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.helpLink = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.websitePurpose = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.entityState = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.entityType = MTermsOfUseEntityType.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.purposeType = MTermsOfUsePurposeType.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.emailLegal = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.addressLegal = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.helpType = MTermsOfUseHelpType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TermsOfUse {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      privacyUrl: isSet(object.privacyUrl) ? String(object.privacyUrl) : "",
      helpEmail: isSet(object.helpEmail) ? String(object.helpEmail) : "",
      helpLink: isSet(object.helpLink) ? String(object.helpLink) : "",
      websitePurpose: isSet(object.websitePurpose) ? String(object.websitePurpose) : "",
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      entityState: isSet(object.entityState) ? String(object.entityState) : "",
      entityType: isSet(object.entityType) ? MTermsOfUseEntityType.fromJSON(object.entityType) : undefined,
      purposeType: isSet(object.purposeType) ? MTermsOfUsePurposeType.fromJSON(object.purposeType) : undefined,
      emailLegal: isSet(object.emailLegal) ? String(object.emailLegal) : "",
      addressLegal: isSet(object.addressLegal) ? String(object.addressLegal) : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      lastUpdateTime: isSet(object.lastUpdateTime) ? fromJsonTimestamp(object.lastUpdateTime) : undefined,
      helpType: isSet(object.helpType) ? MTermsOfUseHelpType.fromJSON(object.helpType) : undefined,
    };
  },

  toJSON(message: TermsOfUse): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    message.privacyUrl !== undefined && (obj.privacyUrl = message.privacyUrl);
    message.helpEmail !== undefined && (obj.helpEmail = message.helpEmail);
    message.helpLink !== undefined && (obj.helpLink = message.helpLink);
    message.websitePurpose !== undefined && (obj.websitePurpose = message.websitePurpose);
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.entityState !== undefined && (obj.entityState = message.entityState);
    message.entityType !== undefined &&
      (obj.entityType = message.entityType ? MTermsOfUseEntityType.toJSON(message.entityType) : undefined);
    message.purposeType !== undefined &&
      (obj.purposeType = message.purposeType ? MTermsOfUsePurposeType.toJSON(message.purposeType) : undefined);
    message.emailLegal !== undefined && (obj.emailLegal = message.emailLegal);
    message.addressLegal !== undefined && (obj.addressLegal = message.addressLegal);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.lastUpdateTime !== undefined && (obj.lastUpdateTime = message.lastUpdateTime.toISOString());
    message.helpType !== undefined &&
      (obj.helpType = message.helpType ? MTermsOfUseHelpType.toJSON(message.helpType) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TermsOfUse>, I>>(base?: I): TermsOfUse {
    return TermsOfUse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TermsOfUse>, I>>(object: I): TermsOfUse {
    const message = createBaseTermsOfUse();
    message.companyFull = object.companyFull ?? "";
    message.websiteUrl = object.websiteUrl ?? "";
    message.privacyUrl = object.privacyUrl ?? "";
    message.helpEmail = object.helpEmail ?? "";
    message.helpLink = object.helpLink ?? "";
    message.websitePurpose = object.websitePurpose ?? "";
    message.companyShort = object.companyShort ?? "";
    message.entityState = object.entityState ?? "";
    message.entityType = (object.entityType !== undefined && object.entityType !== null)
      ? MTermsOfUseEntityType.fromPartial(object.entityType)
      : undefined;
    message.purposeType = (object.purposeType !== undefined && object.purposeType !== null)
      ? MTermsOfUsePurposeType.fromPartial(object.purposeType)
      : undefined;
    message.emailLegal = object.emailLegal ?? "";
    message.addressLegal = object.addressLegal ?? "";
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.helpType = (object.helpType !== undefined && object.helpType !== null)
      ? MTermsOfUseHelpType.fromPartial(object.helpType)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
