import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { getAutoContracts } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import { ScrollableFillable } from "apps/legal-ide/App/Editor/customElements";
import { SignatureElement } from "apps/legal-ide/App/Editor/customElements/Signature/SignaureElement";
import {
    FillableStrategy,
    PageBreakStrategy,
    GptSplitStrategy,
    SignatureStrategy,
} from "apps/legal-ide/App/Editor/parser";
import { Layers } from "apps/legal-ide/features";
import { useContext } from "react";
import { useAppSelector } from "store";

// headings
// bolds
// lists
// underline
// italick

// function InnerMarkdown({line}: {line: string}){
//     return (
//         // if bold
//         return <InnerMarkdown leftOfFillable/><b><InnerMarkdown center/></b><Right.../>

//         // if fillable
//         return <InnerMarkdown leftOfFillable/><Fillable /><Right.../>

//         return <spab></spab>

//     )
// }

function InlineMarkdown({ line }: { line: string }) {
    const fillableStrategy = new FillableStrategy();
    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);

    const boldRegex = /\*\*([^\*]+)\*\*/;

    const boldMatch = line.match(boldRegex);
    const underlineRegex = /\_\_([^\_]+)\_\_/;
    const underlineMatch = line.match(underlineRegex);
    const italicRegex = /\*([^\*]+)\*/;
    const italicMatch = line.match(italicRegex);

    const tableRegex = /^ *\|(.+)\n *\|( *[-:]+[-| :]*)\n((?: *\|.*(?:\n|$))*)/m;
    const [match, headerRow, alignmentRow, contentRows] = line.match(tableRegex) || [];

    const boldItalicRegex = /\*\*\*([^\*]+)\*\*\*/;
    const boldItalicMatch = line.match(boldItalicRegex);

    if (underlineMatch && underlineMatch.index !== undefined) {
        const left = line.substring(0, underlineMatch.index);
        const right = line.substring(underlineMatch.index + underlineMatch[0].length);

        return (
            <>
                <InlineMarkdown line={left} />
                <u>
                    <InlineMarkdown line={underlineMatch[1]} />
                </u>
                <InlineMarkdown line={right} />
            </>
        );
    }
    if (boldItalicMatch && boldItalicMatch.index !== undefined) {
        const left = line.substring(0, boldItalicMatch.index);
        const right = line.substring(boldItalicMatch.index + boldItalicMatch[0].length);

        return (
            <>
                <InlineMarkdown line={left} />
                <b>
                    <i>
                        <InlineMarkdown line={boldItalicMatch[1]} />
                    </i>
                </b>
                <InlineMarkdown line={right} />
            </>
        );
    }

    if (boldMatch && boldMatch.index !== undefined) {
        const left = line.substring(0, boldMatch.index);
        const right = line.substring(boldMatch.index + boldMatch[0].length);

        return (
            <>
                <InlineMarkdown line={left} />
                <b>
                    <InlineMarkdown line={boldMatch[1]} />
                </b>
                <InlineMarkdown line={right} />
            </>
        );
    }
    if (italicMatch && italicMatch.index !== undefined) {
        const left = line.substring(0, italicMatch.index);
        const right = line.substring(italicMatch.index + italicMatch[0].length);

        return (
            <>
                <InlineMarkdown line={left} />
                <i>
                    <InlineMarkdown line={italicMatch[1]} />
                </i>
                <InlineMarkdown line={right} />
            </>
        );
    }

    const GptSplit = new GptSplitStrategy();

    const GptSplitMatch = GptSplit.match(line);

    if (GptSplitMatch) {
        return <div className="gpt-split" style={{ display: "none" }} />;
    }

    const signatureStrategy = new SignatureStrategy();
    const matchSignature = signatureStrategy.match(line);
    if (matchSignature) {
        return <SignatureElementForMarkdown />;
    }

    const pageBreak = new PageBreakStrategy();
    const matchPageBreak = pageBreak.test(line);
    if (matchPageBreak) {
        return <div style={{ pageBreakAfter: "always" }} />;
    }

    const fillableMatch = fillableStrategy.match(line);
    if (fillableMatch) {
        const { fieldName, index, fullMatch } = fillableMatch;

        const left = line.substring(0, index);
        const right = line.substring(index + fullMatch.length);

        const field = fields?.find((f) => f.name === fieldName);
        const layer = (field?.layer || Layers.LEGAL_UNIT) as Layers;

        return (
            <>
                <InlineMarkdown line={left} />
                {field ? (
                    <ScrollableFillable
                        key={index}
                        field={field}
                        isFromCondition={false}
                        element={{
                            hasABefore: fillableMatch.prefix === " a ",
                            type: "input",
                            fieldId: "",
                            children: [],
                            layer: layer,
                        }}
                    />
                ) : (
                    <span className="text-red-500">[MISSING FIELD: {fieldName}]</span>
                )}
                <InlineMarkdown line={right} />
            </>
        );
    }

    return <>{line}</>;
}
export function MarkDownRenderer({ txt }: { txt: string }) {
    const blocks = {
        // todo: find better way
        "# ": ({ children }: any) => <h1>{children}</h1>,
        "## ": ({ children }: any) => <h2>{children}</h2>,
        "### ": ({ children }: any) => <h3>{children}</h3>,
        "#### ": ({ children }: any) => <h4>{children}</h4>,
        "##### ": ({ children }: any) => <h5>{children}</h5>,
        "###### ": ({ children }: any) => <h6>{children}</h6>,
        "- ": ({ children }: any) => <li>{children}</li>,
        "* ": ({ children }: any) => <li>{children}</li>,
        "1. Section.": ({ children }: any) => <li className="numbered-section">{children}</li>,
    } as const;

    const splitText = txt.split("\n");

    const mapped = splitText
        .map((l) => l.trim())
        .map((line, index) => {
            // our way of avoiding too many new lines
            if (line.length === 0) {
                if (index > 0 && splitText[index - 1].trim().length === 0) {
                    if (index > 1 && splitText[index - 2].trim().length === 0) {
                        return <p key={index} />;
                    }

                    return (
                        <p key={index}>
                            <br />
                        </p>
                    );
                } else {
                    return <p key={index} />;
                }
            }

            let newLine = line.replace(/~~([^\~]+)~~/, "$1"); //align center ~~center~~
            const style: any = {};

            if (newLine !== line) {
                style.textAlign = "center";
            }
            const isListItem = newLine.match(/^\s*\d{1,2}\.\s+/);

            if (isListItem) {
                const matchItem = isListItem[0];
                newLine = newLine.replaceAll(matchItem, "");

                return (
                    <li>
                        <InlineMarkdown line={newLine} />
                    </li>
                );
            }

            for (const block of Object.entries(blocks)) {
                const [key, Value] = block;

                if (newLine.startsWith(key)) {
                    return (
                        <Value key={index} {...style}>
                            <InlineMarkdown line={newLine.substring(block[0].length)} />
                        </Value>
                    );
                }
            }

            if (splitText.length === 1) {
                return (
                    <span key={index} style={style}>
                        <InlineMarkdown line={newLine} />
                    </span>
                );
            }

            return (
                <p style={style}>
                    <InlineMarkdown line={newLine === "" ? " " : newLine} />
                </p>
            );
        });

    return <>{mapped}</>;
}

function SignatureElementForMarkdown() {
    const agreementContext = useContext(AgreementContext);
    let legalUnit = agreementContext?.getActiveDraft()?.legalUnit;

    const foundAutoContract = getAutoContracts().find((autoContract) => legalUnit?.kind === autoContract.kind);
    const signer = foundAutoContract?.signer?.(legalUnit);
    const otherPartyType = signer?.titleForOtherSigner || "Other Party";
    const otherPartyName = signer?.otherNameField || "";
    const otherPartyAddress = signer?.otherAddressField || "";
    const otherPartyTitle = signer?.otherTitleField || "";

    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);

    const otherPartyNameField = fields?.filter((field) => field.name === otherPartyName)?.[0];
    const otherPartyNameFillable = otherPartyNameField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyNameField.id }}
            isFromCondition={false}
            field={otherPartyNameField}
        />
    ) : undefined;

    const otherPartyAddressField = fields?.filter((field) => field.name === otherPartyAddress)?.[0];
    const otherPartyAddressFillable = otherPartyAddressField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyAddressField.id }}
            isFromCondition={false}
            field={otherPartyAddressField}
        />
    ) : undefined;

    const otherPartyTitleField = fields?.filter((field) => field.name === otherPartyTitle)?.[0];
    const otherPartyTitleFillable = otherPartyTitleField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyTitleField.id }}
            isFromCondition={false}
            field={otherPartyTitleField}
        />
    ) : undefined;

    return (
        <SignatureElement
            count={0}
            otherPartyType={otherPartyType}
            otherPartyDefaultName={otherPartyNameFillable}
            otherPartyDefaultAddress={otherPartyAddressFillable}
            otherPartyDefaultTitle={otherPartyTitleFillable}
        />
    );
}
