/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EJointTermsOfUseSaaSEntityType {
  JointTermsOfUseSaaS_entityType_NA = 0,
  JointTermsOfUseSaaS_entityType_CORPORATION = 1,
  JointTermsOfUseSaaS_entityType_LIMITED_LIABILITY_COMPANY = 2,
  JointTermsOfUseSaaS_entityType_SOLE_PROPRIETORSHIP = 3,
  JointTermsOfUseSaaS_entityType_LIMITED_PARTNERSHIP = 4,
  JointTermsOfUseSaaS_entityType_PROFESSIONAL_CORPORATION = 5,
  JointTermsOfUseSaaS_entityType_PROFESSIONAL_ASSOCIATION = 6,
  JointTermsOfUseSaaS_entityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  JointTermsOfUseSaaS_entityType_NONPROFIT_CORPORATION = 8,
  JointTermsOfUseSaaS_entityType_UNINCORPORATED_ASSOCIATION = 9,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSEntityTypeFromJSON(object: any): EJointTermsOfUseSaaSEntityType {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_entityType_NA":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_NA;
    case 1:
    case "JointTermsOfUseSaaS_entityType_CORPORATION":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_CORPORATION;
    case 2:
    case "JointTermsOfUseSaaS_entityType_LIMITED_LIABILITY_COMPANY":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "JointTermsOfUseSaaS_entityType_SOLE_PROPRIETORSHIP":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "JointTermsOfUseSaaS_entityType_LIMITED_PARTNERSHIP":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_LIMITED_PARTNERSHIP;
    case 5:
    case "JointTermsOfUseSaaS_entityType_PROFESSIONAL_CORPORATION":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "JointTermsOfUseSaaS_entityType_PROFESSIONAL_ASSOCIATION":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "JointTermsOfUseSaaS_entityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "JointTermsOfUseSaaS_entityType_NONPROFIT_CORPORATION":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_NONPROFIT_CORPORATION;
    case 9:
    case "JointTermsOfUseSaaS_entityType_UNINCORPORATED_ASSOCIATION":
      return EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_UNINCORPORATED_ASSOCIATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSEntityType.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSEntityTypeToJSON(object: EJointTermsOfUseSaaSEntityType): string {
  switch (object) {
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_NA:
      return "JointTermsOfUseSaaS_entityType_NA";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_CORPORATION:
      return "JointTermsOfUseSaaS_entityType_CORPORATION";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_LIMITED_LIABILITY_COMPANY:
      return "JointTermsOfUseSaaS_entityType_LIMITED_LIABILITY_COMPANY";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_SOLE_PROPRIETORSHIP:
      return "JointTermsOfUseSaaS_entityType_SOLE_PROPRIETORSHIP";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_LIMITED_PARTNERSHIP:
      return "JointTermsOfUseSaaS_entityType_LIMITED_PARTNERSHIP";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_CORPORATION:
      return "JointTermsOfUseSaaS_entityType_PROFESSIONAL_CORPORATION";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_ASSOCIATION:
      return "JointTermsOfUseSaaS_entityType_PROFESSIONAL_ASSOCIATION";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "JointTermsOfUseSaaS_entityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_NONPROFIT_CORPORATION:
      return "JointTermsOfUseSaaS_entityType_NONPROFIT_CORPORATION";
    case EJointTermsOfUseSaaSEntityType.JointTermsOfUseSaaS_entityType_UNINCORPORATED_ASSOCIATION:
      return "JointTermsOfUseSaaS_entityType_UNINCORPORATED_ASSOCIATION";
    case EJointTermsOfUseSaaSEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSHelpType {
  JointTermsOfUseSaaS_helpType_NA = 0,
  JointTermsOfUseSaaS_helpType_EMAIL = 1,
  JointTermsOfUseSaaS_helpType_CONTACT_PAGE = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSHelpTypeFromJSON(object: any): EJointTermsOfUseSaaSHelpType {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_helpType_NA":
      return EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_NA;
    case 1:
    case "JointTermsOfUseSaaS_helpType_EMAIL":
      return EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_EMAIL;
    case 2:
    case "JointTermsOfUseSaaS_helpType_CONTACT_PAGE":
      return EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_CONTACT_PAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSHelpType.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSHelpTypeToJSON(object: EJointTermsOfUseSaaSHelpType): string {
  switch (object) {
    case EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_NA:
      return "JointTermsOfUseSaaS_helpType_NA";
    case EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_EMAIL:
      return "JointTermsOfUseSaaS_helpType_EMAIL";
    case EJointTermsOfUseSaaSHelpType.JointTermsOfUseSaaS_helpType_CONTACT_PAGE:
      return "JointTermsOfUseSaaS_helpType_CONTACT_PAGE";
    case EJointTermsOfUseSaaSHelpType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSSubscriptionTerms {
  JointTermsOfUseSaaS_subscriptionTerms_NA = 0,
  JointTermsOfUseSaaS_subscriptionTerms_MONTHLY = 1,
  JointTermsOfUseSaaS_subscriptionTerms_ANNUAL = 2,
  JointTermsOfUseSaaS_subscriptionTerms_FREE_CONVERT = 3,
  JointTermsOfUseSaaS_subscriptionTerms_FREE_NOCONVERT = 4,
  JointTermsOfUseSaaS_subscriptionTerms_ONCE_LIMIT = 5,
  JointTermsOfUseSaaS_subscriptionTerms_ONCE_PERM = 6,
  JointTermsOfUseSaaS_subscriptionTerms_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSSubscriptionTermsFromJSON(object: any): EJointTermsOfUseSaaSSubscriptionTerms {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_subscriptionTerms_NA":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_NA;
    case 1:
    case "JointTermsOfUseSaaS_subscriptionTerms_MONTHLY":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_MONTHLY;
    case 2:
    case "JointTermsOfUseSaaS_subscriptionTerms_ANNUAL":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ANNUAL;
    case 3:
    case "JointTermsOfUseSaaS_subscriptionTerms_FREE_CONVERT":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_FREE_CONVERT;
    case 4:
    case "JointTermsOfUseSaaS_subscriptionTerms_FREE_NOCONVERT":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_FREE_NOCONVERT;
    case 5:
    case "JointTermsOfUseSaaS_subscriptionTerms_ONCE_LIMIT":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ONCE_LIMIT;
    case 6:
    case "JointTermsOfUseSaaS_subscriptionTerms_ONCE_PERM":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ONCE_PERM;
    case 999999:
    case "JointTermsOfUseSaaS_subscriptionTerms_OTHER":
      return EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSSubscriptionTerms.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSSubscriptionTermsToJSON(object: EJointTermsOfUseSaaSSubscriptionTerms): string {
  switch (object) {
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_NA:
      return "JointTermsOfUseSaaS_subscriptionTerms_NA";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_MONTHLY:
      return "JointTermsOfUseSaaS_subscriptionTerms_MONTHLY";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ANNUAL:
      return "JointTermsOfUseSaaS_subscriptionTerms_ANNUAL";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_FREE_CONVERT:
      return "JointTermsOfUseSaaS_subscriptionTerms_FREE_CONVERT";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_FREE_NOCONVERT:
      return "JointTermsOfUseSaaS_subscriptionTerms_FREE_NOCONVERT";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ONCE_LIMIT:
      return "JointTermsOfUseSaaS_subscriptionTerms_ONCE_LIMIT";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_ONCE_PERM:
      return "JointTermsOfUseSaaS_subscriptionTerms_ONCE_PERM";
    case EJointTermsOfUseSaaSSubscriptionTerms.JointTermsOfUseSaaS_subscriptionTerms_OTHER:
      return "JointTermsOfUseSaaS_subscriptionTerms_OTHER";
    case EJointTermsOfUseSaaSSubscriptionTerms.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSSaasHelpType {
  JointTermsOfUseSaaS_saasHelpType_NA = 0,
  JointTermsOfUseSaaS_saasHelpType_EMAIL = 1,
  JointTermsOfUseSaaS_saasHelpType_CONTACT_PAGE = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSSaasHelpTypeFromJSON(object: any): EJointTermsOfUseSaaSSaasHelpType {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_saasHelpType_NA":
      return EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_NA;
    case 1:
    case "JointTermsOfUseSaaS_saasHelpType_EMAIL":
      return EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_EMAIL;
    case 2:
    case "JointTermsOfUseSaaS_saasHelpType_CONTACT_PAGE":
      return EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_CONTACT_PAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSSaasHelpType.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSSaasHelpTypeToJSON(object: EJointTermsOfUseSaaSSaasHelpType): string {
  switch (object) {
    case EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_NA:
      return "JointTermsOfUseSaaS_saasHelpType_NA";
    case EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_EMAIL:
      return "JointTermsOfUseSaaS_saasHelpType_EMAIL";
    case EJointTermsOfUseSaaSSaasHelpType.JointTermsOfUseSaaS_saasHelpType_CONTACT_PAGE:
      return "JointTermsOfUseSaaS_saasHelpType_CONTACT_PAGE";
    case EJointTermsOfUseSaaSSaasHelpType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSEarlyCancel {
  JointTermsOfUseSaaS_earlyCancel_NA = 0,
  JointTermsOfUseSaaS_earlyCancel_NONE = 3,
  JointTermsOfUseSaaS_earlyCancel_YES_ALL = 1,
  JointTermsOfUseSaaS_earlyCancel_YES_MONTHLY = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSEarlyCancelFromJSON(object: any): EJointTermsOfUseSaaSEarlyCancel {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_earlyCancel_NA":
      return EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_NA;
    case 3:
    case "JointTermsOfUseSaaS_earlyCancel_NONE":
      return EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_NONE;
    case 1:
    case "JointTermsOfUseSaaS_earlyCancel_YES_ALL":
      return EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_YES_ALL;
    case 2:
    case "JointTermsOfUseSaaS_earlyCancel_YES_MONTHLY":
      return EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_YES_MONTHLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSEarlyCancel.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSEarlyCancelToJSON(object: EJointTermsOfUseSaaSEarlyCancel): string {
  switch (object) {
    case EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_NA:
      return "JointTermsOfUseSaaS_earlyCancel_NA";
    case EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_NONE:
      return "JointTermsOfUseSaaS_earlyCancel_NONE";
    case EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_YES_ALL:
      return "JointTermsOfUseSaaS_earlyCancel_YES_ALL";
    case EJointTermsOfUseSaaSEarlyCancel.JointTermsOfUseSaaS_earlyCancel_YES_MONTHLY:
      return "JointTermsOfUseSaaS_earlyCancel_YES_MONTHLY";
    case EJointTermsOfUseSaaSEarlyCancel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSRecaptureDiscount {
  JointTermsOfUseSaaS_recaptureDiscount_NA = 0,
  JointTermsOfUseSaaS_recaptureDiscount_PRO_RATED = 1,
  JointTermsOfUseSaaS_recaptureDiscount_RECAPTURE = 2,
  JointTermsOfUseSaaS_recaptureDiscount_FULL_REFUND = 3,
  JointTermsOfUseSaaS_recaptureDiscount_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSRecaptureDiscountFromJSON(object: any): EJointTermsOfUseSaaSRecaptureDiscount {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_recaptureDiscount_NA":
      return EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_NA;
    case 1:
    case "JointTermsOfUseSaaS_recaptureDiscount_PRO_RATED":
      return EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_PRO_RATED;
    case 2:
    case "JointTermsOfUseSaaS_recaptureDiscount_RECAPTURE":
      return EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_RECAPTURE;
    case 3:
    case "JointTermsOfUseSaaS_recaptureDiscount_FULL_REFUND":
      return EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_FULL_REFUND;
    case 999999:
    case "JointTermsOfUseSaaS_recaptureDiscount_OTHER":
      return EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSRecaptureDiscount.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSRecaptureDiscountToJSON(object: EJointTermsOfUseSaaSRecaptureDiscount): string {
  switch (object) {
    case EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_NA:
      return "JointTermsOfUseSaaS_recaptureDiscount_NA";
    case EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_PRO_RATED:
      return "JointTermsOfUseSaaS_recaptureDiscount_PRO_RATED";
    case EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_RECAPTURE:
      return "JointTermsOfUseSaaS_recaptureDiscount_RECAPTURE";
    case EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_FULL_REFUND:
      return "JointTermsOfUseSaaS_recaptureDiscount_FULL_REFUND";
    case EJointTermsOfUseSaaSRecaptureDiscount.JointTermsOfUseSaaS_recaptureDiscount_OTHER:
      return "JointTermsOfUseSaaS_recaptureDiscount_OTHER";
    case EJointTermsOfUseSaaSRecaptureDiscount.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSRenewalDate {
  JointTermsOfUseSaaS_renewalDate_NA = 0,
  JointTermsOfUseSaaS_renewalDate_ANNIVERSARY = 3,
  JointTermsOfUseSaaS_renewalDate_BEGIN = 1,
  JointTermsOfUseSaaS_renewalDate_END = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSRenewalDateFromJSON(object: any): EJointTermsOfUseSaaSRenewalDate {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_renewalDate_NA":
      return EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_NA;
    case 3:
    case "JointTermsOfUseSaaS_renewalDate_ANNIVERSARY":
      return EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_ANNIVERSARY;
    case 1:
    case "JointTermsOfUseSaaS_renewalDate_BEGIN":
      return EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_BEGIN;
    case 2:
    case "JointTermsOfUseSaaS_renewalDate_END":
      return EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_END;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSRenewalDate.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSRenewalDateToJSON(object: EJointTermsOfUseSaaSRenewalDate): string {
  switch (object) {
    case EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_NA:
      return "JointTermsOfUseSaaS_renewalDate_NA";
    case EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_ANNIVERSARY:
      return "JointTermsOfUseSaaS_renewalDate_ANNIVERSARY";
    case EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_BEGIN:
      return "JointTermsOfUseSaaS_renewalDate_BEGIN";
    case EJointTermsOfUseSaaSRenewalDate.JointTermsOfUseSaaS_renewalDate_END:
      return "JointTermsOfUseSaaS_renewalDate_END";
    case EJointTermsOfUseSaaSRenewalDate.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsOfUseSaaSPriceChanges {
  JointTermsOfUseSaaS_priceChanges_NA = 0,
  JointTermsOfUseSaaS_priceChanges_RENEWAL = 1,
  JointTermsOfUseSaaS_priceChanges_GRANDFATHER = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsOfUseSaaSPriceChangesFromJSON(object: any): EJointTermsOfUseSaaSPriceChanges {
  switch (object) {
    case 0:
    case "JointTermsOfUseSaaS_priceChanges_NA":
      return EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_NA;
    case 1:
    case "JointTermsOfUseSaaS_priceChanges_RENEWAL":
      return EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_RENEWAL;
    case 2:
    case "JointTermsOfUseSaaS_priceChanges_GRANDFATHER":
      return EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_GRANDFATHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsOfUseSaaSPriceChanges.UNRECOGNIZED;
  }
}

export function eJointTermsOfUseSaaSPriceChangesToJSON(object: EJointTermsOfUseSaaSPriceChanges): string {
  switch (object) {
    case EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_NA:
      return "JointTermsOfUseSaaS_priceChanges_NA";
    case EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_RENEWAL:
      return "JointTermsOfUseSaaS_priceChanges_RENEWAL";
    case EJointTermsOfUseSaaSPriceChanges.JointTermsOfUseSaaS_priceChanges_GRANDFATHER:
      return "JointTermsOfUseSaaS_priceChanges_GRANDFATHER";
    case EJointTermsOfUseSaaSPriceChanges.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MJointTermsOfUseSaaSEntityType {
  optionDbValue: EJointTermsOfUseSaaSEntityType;
  other: string;
}

export interface MJointTermsOfUseSaaSHelpType {
  optionDbValue: EJointTermsOfUseSaaSHelpType;
  other: string;
}

export interface MJointTermsOfUseSaaSSubscriptionTerms {
  optionDbValue: EJointTermsOfUseSaaSSubscriptionTerms[];
  other: string;
}

export interface MJointTermsOfUseSaaSSaasHelpType {
  optionDbValue: EJointTermsOfUseSaaSSaasHelpType;
  other: string;
}

export interface MJointTermsOfUseSaaSEarlyCancel {
  optionDbValue: EJointTermsOfUseSaaSEarlyCancel;
  other: string;
}

export interface MJointTermsOfUseSaaSRecaptureDiscount {
  optionDbValue: EJointTermsOfUseSaaSRecaptureDiscount;
  other: string;
}

export interface MJointTermsOfUseSaaSRenewalDate {
  optionDbValue: EJointTermsOfUseSaaSRenewalDate;
  other: string;
}

export interface MJointTermsOfUseSaaSPriceChanges {
  optionDbValue: EJointTermsOfUseSaaSPriceChanges;
  other: string;
}

export interface JointTermsOfUseSaaS {
  websiteUrl: string;
  privacyUrl: string;
  helpEmail: string;
  helpLink: string;
  companyShort: string;
  entityState: string;
  entityType: MJointTermsOfUseSaaSEntityType | undefined;
  emailLegal: string;
  addressLegal: string;
  governLawState: string;
  venue: string;
  lastUpdateTime: Date | undefined;
  helpType: MJointTermsOfUseSaaSHelpType | undefined;
  saasName: string;
  saasUrl: string;
  noticeAddress: string;
  noticePerson: string;
  noticeCopyName: string;
  noticeCopyAddress: string;
  noticeCopyPerson: string;
  noticeCopy: InternalBoolean;
  noticeAddressOther: InternalBoolean;
  noticeCopyAddressOther: InternalBoolean;
  noticeAddressOtherDetail: string;
  noticeCopyAddressOtherDetail: string;
  subscriptionOptions: string[];
  subscriptionTerms: MJointTermsOfUseSaaSSubscriptionTerms | undefined;
  saasHelpEmail: string;
  saasHelpLink: string;
  extendedSupportOptions: string[];
  saasHelpType: MJointTermsOfUseSaaSSaasHelpType | undefined;
  extendedSupport: InternalBoolean;
  earlyCancel: MJointTermsOfUseSaaSEarlyCancel | undefined;
  recaptureDiscount: MJointTermsOfUseSaaSRecaptureDiscount | undefined;
  renewalDate: MJointTermsOfUseSaaSRenewalDate | undefined;
  priceChanges: MJointTermsOfUseSaaSPriceChanges | undefined;
}

function createBaseMJointTermsOfUseSaaSEntityType(): MJointTermsOfUseSaaSEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSEntityType = {
  encode(message: MJointTermsOfUseSaaSEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSEntityType>, I>>(base?: I): MJointTermsOfUseSaaSEntityType {
    return MJointTermsOfUseSaaSEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSEntityType>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSEntityType {
    const message = createBaseMJointTermsOfUseSaaSEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSHelpType(): MJointTermsOfUseSaaSHelpType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSHelpType = {
  encode(message: MJointTermsOfUseSaaSHelpType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSHelpType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSHelpType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSHelpType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSHelpTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSHelpType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSHelpTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSHelpType>, I>>(base?: I): MJointTermsOfUseSaaSHelpType {
    return MJointTermsOfUseSaaSHelpType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSHelpType>, I>>(object: I): MJointTermsOfUseSaaSHelpType {
    const message = createBaseMJointTermsOfUseSaaSHelpType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSSubscriptionTerms(): MJointTermsOfUseSaaSSubscriptionTerms {
  return { optionDbValue: [], other: "" };
}

export const MJointTermsOfUseSaaSSubscriptionTerms = {
  encode(message: MJointTermsOfUseSaaSSubscriptionTerms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSSubscriptionTerms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSSubscriptionTerms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSSubscriptionTerms {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => eJointTermsOfUseSaaSSubscriptionTermsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSSubscriptionTerms): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => eJointTermsOfUseSaaSSubscriptionTermsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSSubscriptionTerms>, I>>(
    base?: I,
  ): MJointTermsOfUseSaaSSubscriptionTerms {
    return MJointTermsOfUseSaaSSubscriptionTerms.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSSubscriptionTerms>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSSubscriptionTerms {
    const message = createBaseMJointTermsOfUseSaaSSubscriptionTerms();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSSaasHelpType(): MJointTermsOfUseSaaSSaasHelpType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSSaasHelpType = {
  encode(message: MJointTermsOfUseSaaSSaasHelpType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSSaasHelpType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSSaasHelpType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSSaasHelpType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSSaasHelpTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSSaasHelpType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSSaasHelpTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSSaasHelpType>, I>>(
    base?: I,
  ): MJointTermsOfUseSaaSSaasHelpType {
    return MJointTermsOfUseSaaSSaasHelpType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSSaasHelpType>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSSaasHelpType {
    const message = createBaseMJointTermsOfUseSaaSSaasHelpType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSEarlyCancel(): MJointTermsOfUseSaaSEarlyCancel {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSEarlyCancel = {
  encode(message: MJointTermsOfUseSaaSEarlyCancel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSEarlyCancel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSEarlyCancel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSEarlyCancel {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSEarlyCancelFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSEarlyCancel): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSEarlyCancelToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSEarlyCancel>, I>>(base?: I): MJointTermsOfUseSaaSEarlyCancel {
    return MJointTermsOfUseSaaSEarlyCancel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSEarlyCancel>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSEarlyCancel {
    const message = createBaseMJointTermsOfUseSaaSEarlyCancel();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSRecaptureDiscount(): MJointTermsOfUseSaaSRecaptureDiscount {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSRecaptureDiscount = {
  encode(message: MJointTermsOfUseSaaSRecaptureDiscount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSRecaptureDiscount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSRecaptureDiscount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSRecaptureDiscount {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eJointTermsOfUseSaaSRecaptureDiscountFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSRecaptureDiscount): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSRecaptureDiscountToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSRecaptureDiscount>, I>>(
    base?: I,
  ): MJointTermsOfUseSaaSRecaptureDiscount {
    return MJointTermsOfUseSaaSRecaptureDiscount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSRecaptureDiscount>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSRecaptureDiscount {
    const message = createBaseMJointTermsOfUseSaaSRecaptureDiscount();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSRenewalDate(): MJointTermsOfUseSaaSRenewalDate {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSRenewalDate = {
  encode(message: MJointTermsOfUseSaaSRenewalDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSRenewalDate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSRenewalDate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSRenewalDate {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSRenewalDateFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSRenewalDate): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSRenewalDateToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSRenewalDate>, I>>(base?: I): MJointTermsOfUseSaaSRenewalDate {
    return MJointTermsOfUseSaaSRenewalDate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSRenewalDate>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSRenewalDate {
    const message = createBaseMJointTermsOfUseSaaSRenewalDate();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsOfUseSaaSPriceChanges(): MJointTermsOfUseSaaSPriceChanges {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsOfUseSaaSPriceChanges = {
  encode(message: MJointTermsOfUseSaaSPriceChanges, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsOfUseSaaSPriceChanges {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsOfUseSaaSPriceChanges();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsOfUseSaaSPriceChanges {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsOfUseSaaSPriceChangesFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsOfUseSaaSPriceChanges): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsOfUseSaaSPriceChangesToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsOfUseSaaSPriceChanges>, I>>(
    base?: I,
  ): MJointTermsOfUseSaaSPriceChanges {
    return MJointTermsOfUseSaaSPriceChanges.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsOfUseSaaSPriceChanges>, I>>(
    object: I,
  ): MJointTermsOfUseSaaSPriceChanges {
    const message = createBaseMJointTermsOfUseSaaSPriceChanges();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseJointTermsOfUseSaaS(): JointTermsOfUseSaaS {
  return {
    websiteUrl: "",
    privacyUrl: "",
    helpEmail: "",
    helpLink: "",
    companyShort: "",
    entityState: "",
    entityType: undefined,
    emailLegal: "",
    addressLegal: "",
    governLawState: "",
    venue: "",
    lastUpdateTime: undefined,
    helpType: undefined,
    saasName: "",
    saasUrl: "",
    noticeAddress: "",
    noticePerson: "",
    noticeCopyName: "",
    noticeCopyAddress: "",
    noticeCopyPerson: "",
    noticeCopy: 0,
    noticeAddressOther: 0,
    noticeCopyAddressOther: 0,
    noticeAddressOtherDetail: "",
    noticeCopyAddressOtherDetail: "",
    subscriptionOptions: [],
    subscriptionTerms: undefined,
    saasHelpEmail: "",
    saasHelpLink: "",
    extendedSupportOptions: [],
    saasHelpType: undefined,
    extendedSupport: 0,
    earlyCancel: undefined,
    recaptureDiscount: undefined,
    renewalDate: undefined,
    priceChanges: undefined,
  };
}

export const JointTermsOfUseSaaS = {
  encode(message: JointTermsOfUseSaaS, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.websiteUrl !== "") {
      writer.uint32(10).string(message.websiteUrl);
    }
    if (message.privacyUrl !== "") {
      writer.uint32(18).string(message.privacyUrl);
    }
    if (message.helpEmail !== "") {
      writer.uint32(26).string(message.helpEmail);
    }
    if (message.helpLink !== "") {
      writer.uint32(34).string(message.helpLink);
    }
    if (message.companyShort !== "") {
      writer.uint32(42).string(message.companyShort);
    }
    if (message.entityState !== "") {
      writer.uint32(50).string(message.entityState);
    }
    if (message.entityType !== undefined) {
      MJointTermsOfUseSaaSEntityType.encode(message.entityType, writer.uint32(58).fork()).ldelim();
    }
    if (message.emailLegal !== "") {
      writer.uint32(66).string(message.emailLegal);
    }
    if (message.addressLegal !== "") {
      writer.uint32(74).string(message.addressLegal);
    }
    if (message.governLawState !== "") {
      writer.uint32(82).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(90).string(message.venue);
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.helpType !== undefined) {
      MJointTermsOfUseSaaSHelpType.encode(message.helpType, writer.uint32(106).fork()).ldelim();
    }
    if (message.saasName !== "") {
      writer.uint32(114).string(message.saasName);
    }
    if (message.saasUrl !== "") {
      writer.uint32(122).string(message.saasUrl);
    }
    if (message.noticeAddress !== "") {
      writer.uint32(130).string(message.noticeAddress);
    }
    if (message.noticePerson !== "") {
      writer.uint32(138).string(message.noticePerson);
    }
    if (message.noticeCopyName !== "") {
      writer.uint32(146).string(message.noticeCopyName);
    }
    if (message.noticeCopyAddress !== "") {
      writer.uint32(154).string(message.noticeCopyAddress);
    }
    if (message.noticeCopyPerson !== "") {
      writer.uint32(162).string(message.noticeCopyPerson);
    }
    if (message.noticeCopy !== 0) {
      writer.uint32(168).int32(message.noticeCopy);
    }
    if (message.noticeAddressOther !== 0) {
      writer.uint32(176).int32(message.noticeAddressOther);
    }
    if (message.noticeCopyAddressOther !== 0) {
      writer.uint32(184).int32(message.noticeCopyAddressOther);
    }
    if (message.noticeAddressOtherDetail !== "") {
      writer.uint32(194).string(message.noticeAddressOtherDetail);
    }
    if (message.noticeCopyAddressOtherDetail !== "") {
      writer.uint32(202).string(message.noticeCopyAddressOtherDetail);
    }
    for (const v of message.subscriptionOptions) {
      writer.uint32(210).string(v!);
    }
    if (message.subscriptionTerms !== undefined) {
      MJointTermsOfUseSaaSSubscriptionTerms.encode(message.subscriptionTerms, writer.uint32(218).fork()).ldelim();
    }
    if (message.saasHelpEmail !== "") {
      writer.uint32(226).string(message.saasHelpEmail);
    }
    if (message.saasHelpLink !== "") {
      writer.uint32(234).string(message.saasHelpLink);
    }
    for (const v of message.extendedSupportOptions) {
      writer.uint32(242).string(v!);
    }
    if (message.saasHelpType !== undefined) {
      MJointTermsOfUseSaaSSaasHelpType.encode(message.saasHelpType, writer.uint32(250).fork()).ldelim();
    }
    if (message.extendedSupport !== 0) {
      writer.uint32(256).int32(message.extendedSupport);
    }
    if (message.earlyCancel !== undefined) {
      MJointTermsOfUseSaaSEarlyCancel.encode(message.earlyCancel, writer.uint32(266).fork()).ldelim();
    }
    if (message.recaptureDiscount !== undefined) {
      MJointTermsOfUseSaaSRecaptureDiscount.encode(message.recaptureDiscount, writer.uint32(274).fork()).ldelim();
    }
    if (message.renewalDate !== undefined) {
      MJointTermsOfUseSaaSRenewalDate.encode(message.renewalDate, writer.uint32(282).fork()).ldelim();
    }
    if (message.priceChanges !== undefined) {
      MJointTermsOfUseSaaSPriceChanges.encode(message.priceChanges, writer.uint32(290).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointTermsOfUseSaaS {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointTermsOfUseSaaS();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.privacyUrl = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.helpEmail = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.helpLink = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.entityState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.entityType = MJointTermsOfUseSaaSEntityType.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.emailLegal = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.addressLegal = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.helpType = MJointTermsOfUseSaaSHelpType.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.saasName = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.saasUrl = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.noticeAddress = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.noticePerson = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.noticeCopyName = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.noticeCopyAddress = reader.string();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.noticeCopyPerson = reader.string();
          continue;
        case 21:
          if (tag != 168) {
            break;
          }

          message.noticeCopy = reader.int32() as any;
          continue;
        case 22:
          if (tag != 176) {
            break;
          }

          message.noticeAddressOther = reader.int32() as any;
          continue;
        case 23:
          if (tag != 184) {
            break;
          }

          message.noticeCopyAddressOther = reader.int32() as any;
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.noticeAddressOtherDetail = reader.string();
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.noticeCopyAddressOtherDetail = reader.string();
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.subscriptionOptions.push(reader.string());
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.subscriptionTerms = MJointTermsOfUseSaaSSubscriptionTerms.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.saasHelpEmail = reader.string();
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.saasHelpLink = reader.string();
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.extendedSupportOptions.push(reader.string());
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.saasHelpType = MJointTermsOfUseSaaSSaasHelpType.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 256) {
            break;
          }

          message.extendedSupport = reader.int32() as any;
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.earlyCancel = MJointTermsOfUseSaaSEarlyCancel.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.recaptureDiscount = MJointTermsOfUseSaaSRecaptureDiscount.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.renewalDate = MJointTermsOfUseSaaSRenewalDate.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.priceChanges = MJointTermsOfUseSaaSPriceChanges.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointTermsOfUseSaaS {
    return {
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      privacyUrl: isSet(object.privacyUrl) ? String(object.privacyUrl) : "",
      helpEmail: isSet(object.helpEmail) ? String(object.helpEmail) : "",
      helpLink: isSet(object.helpLink) ? String(object.helpLink) : "",
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      entityState: isSet(object.entityState) ? String(object.entityState) : "",
      entityType: isSet(object.entityType) ? MJointTermsOfUseSaaSEntityType.fromJSON(object.entityType) : undefined,
      emailLegal: isSet(object.emailLegal) ? String(object.emailLegal) : "",
      addressLegal: isSet(object.addressLegal) ? String(object.addressLegal) : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      lastUpdateTime: isSet(object.lastUpdateTime) ? fromJsonTimestamp(object.lastUpdateTime) : undefined,
      helpType: isSet(object.helpType) ? MJointTermsOfUseSaaSHelpType.fromJSON(object.helpType) : undefined,
      saasName: isSet(object.saasName) ? String(object.saasName) : "",
      saasUrl: isSet(object.saasUrl) ? String(object.saasUrl) : "",
      noticeAddress: isSet(object.noticeAddress) ? String(object.noticeAddress) : "",
      noticePerson: isSet(object.noticePerson) ? String(object.noticePerson) : "",
      noticeCopyName: isSet(object.noticeCopyName) ? String(object.noticeCopyName) : "",
      noticeCopyAddress: isSet(object.noticeCopyAddress) ? String(object.noticeCopyAddress) : "",
      noticeCopyPerson: isSet(object.noticeCopyPerson) ? String(object.noticeCopyPerson) : "",
      noticeCopy: isSet(object.noticeCopy) ? internalBooleanFromJSON(object.noticeCopy) : 0,
      noticeAddressOther: isSet(object.noticeAddressOther) ? internalBooleanFromJSON(object.noticeAddressOther) : 0,
      noticeCopyAddressOther: isSet(object.noticeCopyAddressOther)
        ? internalBooleanFromJSON(object.noticeCopyAddressOther)
        : 0,
      noticeAddressOtherDetail: isSet(object.noticeAddressOtherDetail) ? String(object.noticeAddressOtherDetail) : "",
      noticeCopyAddressOtherDetail: isSet(object.noticeCopyAddressOtherDetail)
        ? String(object.noticeCopyAddressOtherDetail)
        : "",
      subscriptionOptions: Array.isArray(object?.subscriptionOptions)
        ? object.subscriptionOptions.map((e: any) => String(e))
        : [],
      subscriptionTerms: isSet(object.subscriptionTerms)
        ? MJointTermsOfUseSaaSSubscriptionTerms.fromJSON(object.subscriptionTerms)
        : undefined,
      saasHelpEmail: isSet(object.saasHelpEmail) ? String(object.saasHelpEmail) : "",
      saasHelpLink: isSet(object.saasHelpLink) ? String(object.saasHelpLink) : "",
      extendedSupportOptions: Array.isArray(object?.extendedSupportOptions)
        ? object.extendedSupportOptions.map((e: any) => String(e))
        : [],
      saasHelpType: isSet(object.saasHelpType)
        ? MJointTermsOfUseSaaSSaasHelpType.fromJSON(object.saasHelpType)
        : undefined,
      extendedSupport: isSet(object.extendedSupport) ? internalBooleanFromJSON(object.extendedSupport) : 0,
      earlyCancel: isSet(object.earlyCancel) ? MJointTermsOfUseSaaSEarlyCancel.fromJSON(object.earlyCancel) : undefined,
      recaptureDiscount: isSet(object.recaptureDiscount)
        ? MJointTermsOfUseSaaSRecaptureDiscount.fromJSON(object.recaptureDiscount)
        : undefined,
      renewalDate: isSet(object.renewalDate) ? MJointTermsOfUseSaaSRenewalDate.fromJSON(object.renewalDate) : undefined,
      priceChanges: isSet(object.priceChanges)
        ? MJointTermsOfUseSaaSPriceChanges.fromJSON(object.priceChanges)
        : undefined,
    };
  },

  toJSON(message: JointTermsOfUseSaaS): unknown {
    const obj: any = {};
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    message.privacyUrl !== undefined && (obj.privacyUrl = message.privacyUrl);
    message.helpEmail !== undefined && (obj.helpEmail = message.helpEmail);
    message.helpLink !== undefined && (obj.helpLink = message.helpLink);
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.entityState !== undefined && (obj.entityState = message.entityState);
    message.entityType !== undefined &&
      (obj.entityType = message.entityType ? MJointTermsOfUseSaaSEntityType.toJSON(message.entityType) : undefined);
    message.emailLegal !== undefined && (obj.emailLegal = message.emailLegal);
    message.addressLegal !== undefined && (obj.addressLegal = message.addressLegal);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.lastUpdateTime !== undefined && (obj.lastUpdateTime = message.lastUpdateTime.toISOString());
    message.helpType !== undefined &&
      (obj.helpType = message.helpType ? MJointTermsOfUseSaaSHelpType.toJSON(message.helpType) : undefined);
    message.saasName !== undefined && (obj.saasName = message.saasName);
    message.saasUrl !== undefined && (obj.saasUrl = message.saasUrl);
    message.noticeAddress !== undefined && (obj.noticeAddress = message.noticeAddress);
    message.noticePerson !== undefined && (obj.noticePerson = message.noticePerson);
    message.noticeCopyName !== undefined && (obj.noticeCopyName = message.noticeCopyName);
    message.noticeCopyAddress !== undefined && (obj.noticeCopyAddress = message.noticeCopyAddress);
    message.noticeCopyPerson !== undefined && (obj.noticeCopyPerson = message.noticeCopyPerson);
    message.noticeCopy !== undefined && (obj.noticeCopy = internalBooleanToJSON(message.noticeCopy));
    message.noticeAddressOther !== undefined &&
      (obj.noticeAddressOther = internalBooleanToJSON(message.noticeAddressOther));
    message.noticeCopyAddressOther !== undefined &&
      (obj.noticeCopyAddressOther = internalBooleanToJSON(message.noticeCopyAddressOther));
    message.noticeAddressOtherDetail !== undefined && (obj.noticeAddressOtherDetail = message.noticeAddressOtherDetail);
    message.noticeCopyAddressOtherDetail !== undefined &&
      (obj.noticeCopyAddressOtherDetail = message.noticeCopyAddressOtherDetail);
    if (message.subscriptionOptions) {
      obj.subscriptionOptions = message.subscriptionOptions.map((e) => e);
    } else {
      obj.subscriptionOptions = [];
    }
    message.subscriptionTerms !== undefined && (obj.subscriptionTerms = message.subscriptionTerms
      ? MJointTermsOfUseSaaSSubscriptionTerms.toJSON(message.subscriptionTerms)
      : undefined);
    message.saasHelpEmail !== undefined && (obj.saasHelpEmail = message.saasHelpEmail);
    message.saasHelpLink !== undefined && (obj.saasHelpLink = message.saasHelpLink);
    if (message.extendedSupportOptions) {
      obj.extendedSupportOptions = message.extendedSupportOptions.map((e) => e);
    } else {
      obj.extendedSupportOptions = [];
    }
    message.saasHelpType !== undefined && (obj.saasHelpType = message.saasHelpType
      ? MJointTermsOfUseSaaSSaasHelpType.toJSON(message.saasHelpType)
      : undefined);
    message.extendedSupport !== undefined && (obj.extendedSupport = internalBooleanToJSON(message.extendedSupport));
    message.earlyCancel !== undefined &&
      (obj.earlyCancel = message.earlyCancel ? MJointTermsOfUseSaaSEarlyCancel.toJSON(message.earlyCancel) : undefined);
    message.recaptureDiscount !== undefined && (obj.recaptureDiscount = message.recaptureDiscount
      ? MJointTermsOfUseSaaSRecaptureDiscount.toJSON(message.recaptureDiscount)
      : undefined);
    message.renewalDate !== undefined &&
      (obj.renewalDate = message.renewalDate ? MJointTermsOfUseSaaSRenewalDate.toJSON(message.renewalDate) : undefined);
    message.priceChanges !== undefined && (obj.priceChanges = message.priceChanges
      ? MJointTermsOfUseSaaSPriceChanges.toJSON(message.priceChanges)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<JointTermsOfUseSaaS>, I>>(base?: I): JointTermsOfUseSaaS {
    return JointTermsOfUseSaaS.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JointTermsOfUseSaaS>, I>>(object: I): JointTermsOfUseSaaS {
    const message = createBaseJointTermsOfUseSaaS();
    message.websiteUrl = object.websiteUrl ?? "";
    message.privacyUrl = object.privacyUrl ?? "";
    message.helpEmail = object.helpEmail ?? "";
    message.helpLink = object.helpLink ?? "";
    message.companyShort = object.companyShort ?? "";
    message.entityState = object.entityState ?? "";
    message.entityType = (object.entityType !== undefined && object.entityType !== null)
      ? MJointTermsOfUseSaaSEntityType.fromPartial(object.entityType)
      : undefined;
    message.emailLegal = object.emailLegal ?? "";
    message.addressLegal = object.addressLegal ?? "";
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.helpType = (object.helpType !== undefined && object.helpType !== null)
      ? MJointTermsOfUseSaaSHelpType.fromPartial(object.helpType)
      : undefined;
    message.saasName = object.saasName ?? "";
    message.saasUrl = object.saasUrl ?? "";
    message.noticeAddress = object.noticeAddress ?? "";
    message.noticePerson = object.noticePerson ?? "";
    message.noticeCopyName = object.noticeCopyName ?? "";
    message.noticeCopyAddress = object.noticeCopyAddress ?? "";
    message.noticeCopyPerson = object.noticeCopyPerson ?? "";
    message.noticeCopy = object.noticeCopy ?? 0;
    message.noticeAddressOther = object.noticeAddressOther ?? 0;
    message.noticeCopyAddressOther = object.noticeCopyAddressOther ?? 0;
    message.noticeAddressOtherDetail = object.noticeAddressOtherDetail ?? "";
    message.noticeCopyAddressOtherDetail = object.noticeCopyAddressOtherDetail ?? "";
    message.subscriptionOptions = object.subscriptionOptions?.map((e) => e) || [];
    message.subscriptionTerms = (object.subscriptionTerms !== undefined && object.subscriptionTerms !== null)
      ? MJointTermsOfUseSaaSSubscriptionTerms.fromPartial(object.subscriptionTerms)
      : undefined;
    message.saasHelpEmail = object.saasHelpEmail ?? "";
    message.saasHelpLink = object.saasHelpLink ?? "";
    message.extendedSupportOptions = object.extendedSupportOptions?.map((e) => e) || [];
    message.saasHelpType = (object.saasHelpType !== undefined && object.saasHelpType !== null)
      ? MJointTermsOfUseSaaSSaasHelpType.fromPartial(object.saasHelpType)
      : undefined;
    message.extendedSupport = object.extendedSupport ?? 0;
    message.earlyCancel = (object.earlyCancel !== undefined && object.earlyCancel !== null)
      ? MJointTermsOfUseSaaSEarlyCancel.fromPartial(object.earlyCancel)
      : undefined;
    message.recaptureDiscount = (object.recaptureDiscount !== undefined && object.recaptureDiscount !== null)
      ? MJointTermsOfUseSaaSRecaptureDiscount.fromPartial(object.recaptureDiscount)
      : undefined;
    message.renewalDate = (object.renewalDate !== undefined && object.renewalDate !== null)
      ? MJointTermsOfUseSaaSRenewalDate.fromPartial(object.renewalDate)
      : undefined;
    message.priceChanges = (object.priceChanges !== undefined && object.priceChanges !== null)
      ? MJointTermsOfUseSaaSPriceChanges.fromPartial(object.priceChanges)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
