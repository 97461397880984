import { getMarketingUtmTerm } from "components/utils/UtilFunctions";
import { PLegalUnit } from "proto/PLegalUnit";
import { PUser } from "proto/PUser";
import SAFEImg from "img/safe.png";

export type AutoContractGroup = "Loan Agreements" | "Convertible Loan Agreements";

export interface ContractGroup {
    type: AutoContractGroup;
    desc: string;
    title: string;
    img: string;
}

export const contractGroups: { [key in AutoContractGroup]: ContractGroup } = {
    "Loan Agreements": {
        type: "Loan Agreements",
        desc: "Legal documents concerned with borrowing or lending money between parties.",
        title: "Loan Agreements",
        img: SAFEImg,
    },
    "Convertible Loan Agreements": {
        type: "Convertible Loan Agreements",
        desc: "Legal documents concerned with borrowing or lending money between parties, with the option to convert the loan into equity.",
        title: "Convertible Loan Agreements",
        img: SAFEImg,
    },
};

export interface AutoContractDocument {
    name: string;
    title: string;
    image: string;
    desc: string;
    // for Option card component
    text: string;
    blur?: boolean;
    subtitle?: string;
    comingSoon: Function;
    // TODO: after migration to only legal-IDE keyto will be canceled, only kind will be used
    // it will be keyof (LegalUnit)
    kind: string;
    keyto: keyof PLegalUnit;
    contract: (legalUnit: PLegalUnit | undefined) => any;
    filename: string;
    namer: (legalUnit: PLegalUnit | undefined) => string;
    signer?: (legalUnit: PLegalUnit | undefined) => SignatureInfo;
    hideInLibrary?: (user?: PUser) => boolean;
    families: Family[];
    tags: string[];
    timeAddedInSeconds?: number;
    group?: AutoContractGroup;
    forUSA: boolean;
    forIsrael: boolean;
    hebOnly?: boolean;
}

export interface SignatureInfo {
    titleForOtherSigner: string;
    otherName: string;
    otherNameField: string;
    otherAddressField?: string;
    otherTitleField?: string;
}

export enum Family {
    Freelance = "Freelance",
    HR = "HR",
    Website = "Website",
    SaaS = "SaaS",
    Startup = "Startup",
}

export function nameOfICA() {
    const utmTermValue = getMarketingUtmTerm().toLowerCase();

    if (!utmTermValue) {
        return "Independent Contractor Agreement";
    }

    if (utmTermValue.includes("service") && !utmTermValue.includes("proposal")) {
        return "Service Agreement";
    }

    if (utmTermValue.includes("consulting")) {
        return "Consulting Agreement";
    }

    if (utmTermValue.includes("freelance")) {
        return "Freelance Agreement";
    }

    return "Independent Contractor Agreement";
}

export function findFamily(utmTermValue: string) {
    if (
        utmTermValue.includes("freelance") ||
        utmTermValue.includes("consulting") ||
        utmTermValue.includes("contractor") ||
        utmTermValue.includes("contractor")
    ) {
        return Family.Freelance;
    }

    if (utmTermValue.includes("saas") || utmTermValue.includes("software")) {
        return Family.SaaS;
    }

    if (utmTermValue.includes("privacy")) {
        return Family.Website;
    }

    if (
        utmTermValue.includes("job") ||
        utmTermValue.includes("employment") ||
        utmTermValue.includes("employee")
    ) {
        return Family.HR;
    }

    return Family.Freelance;
}

export function preferContractsFromThisFamily(
    autoContracts: AutoContractDocument[],
    family: Family
) {
    const familyContracts = autoContracts.filter((c) => c.families.includes(family));

    const otherContracts = autoContracts.filter((c) => !c.families.includes(family));

    return familyContracts.concat(otherContracts);
}
