import { wixGetCheckoutUrl } from "api-services/WixApi";
import { ShopifyApi } from "api-services/ShopifyApi";
import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { Ribbon, Spinner } from "components";
import { useAuth } from "components/contexts/AuthContext";
import Modal from "components/Modal";
import ABTesting, { AB_TEST_NAMES } from "components/utils/ABTesting";
import { isWixFlow } from "components/utils/UtilFunctions";
import mixpanel from "mixpanel-browser";
import { PUser } from "proto/PUser";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { checkPayment, userEventApi } from "../../../api-services/UseresApi";
import { MainStore } from "../../../store/mainTypes";
import { isProd } from "../../utils/EnvResolver";
import { GTMHelper } from "../../utils/GTMHelper";
import { usePrices } from "./PricePlans";
import { TestimonialsCarousel } from "./TestimonialsCarousel";
import clsx from "clsx";
import { Link } from "react-router-dom";

const SHOPIFY_PLAN = {
    monthlyPrice: 14.9,
    planName: "SHOPIFY",
    saveMoreThan: "80%",
    yearlyPrice: 8,
};
export default function PaymentPlans({
    closeUrlProp,
    feature,
}: {
    closeUrlProp?: string;
    feature?: string;
}) {
    const history = useHistory();
    const agreementContext = useContext(AgreementContext)!;
    const location = useLocation();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const [quotaExceeded, setQuotaExceeded] = useState(false);

    const auth = useAuth();
    const firstName = auth?.getFirstName();
    const dispatch = useDispatch();

    const activeDraft = agreementContext.getActiveDraft();

    // wixFlow 5 - detect if it wix user
    const isWIxUser = dbUser?.isWixUser || isWixFlow();
    const isShopifyUser = dbUser?.isShopifyUser;
    const isIsraeliUser = dbUser?.country === "Israel" && !isWIxUser;

    const pricePlans = usePrices();

    const shopifyPlan = SHOPIFY_PLAN; // TODO: Update our database and retrieve the plan from the response
    const wixPlan = pricePlans?.find((plan) => plan.planName === "WIX");
    const israeliPlan = pricePlans?.find((plan) => plan.planName === "PAYPLUS_ISRAEL");
    const webflowPlan = pricePlans?.find((plan) => plan.planName === "WEBFLOW");
    const freshworksPlan = pricePlans?.find((plan) => plan.planName === "FRESHWORKS");
    const defaultPlan = pricePlans?.find((plan) => plan.planName === "DEFAULT");

    const isWebflowUser = dbUser?.supportedOauthProviders?.includes("webflow");
    const isFreshWorksUser = dbUser?.supportedOauthProviders?.includes("freshworks");
    const isDudaUser = dbUser?.isDudaUser;

    let MONTHLY_PRICE: number | undefined = 29;
    let YEARLY_PRICE: number | undefined = 168;
    let DISCOUNT: string | undefined = "50%";

    let showMonthly = true;
    let showYearly = true;

    if (isShopifyUser) {
        MONTHLY_PRICE = shopifyPlan?.monthlyPrice;
        YEARLY_PRICE = shopifyPlan?.yearlyPrice;
        DISCOUNT = shopifyPlan?.saveMoreThan;
    } else if (isWIxUser) {
        MONTHLY_PRICE = wixPlan?.monthlyPrice;
        YEARLY_PRICE = wixPlan?.yearlyPrice;
        DISCOUNT = wixPlan?.saveMoreThan;
    } else if (isIsraeliUser) {
        MONTHLY_PRICE = israeliPlan?.monthlyPrice;
        YEARLY_PRICE = israeliPlan?.yearlyPrice;
        DISCOUNT = israeliPlan?.saveMoreThan;
    } else if (isWebflowUser) {
        MONTHLY_PRICE = webflowPlan?.monthlyPrice;
        YEARLY_PRICE = webflowPlan?.yearlyPrice;
        DISCOUNT = webflowPlan?.saveMoreThan;
    } else if (isFreshWorksUser) {
        MONTHLY_PRICE = freshworksPlan?.monthlyPrice;
        YEARLY_PRICE = freshworksPlan?.yearlyPrice;
        DISCOUNT = freshworksPlan?.saveMoreThan;
    } else if (isDudaUser) {
        MONTHLY_PRICE = 14.9;
        DISCOUNT = undefined;
        showYearly = false;
    } else {
        MONTHLY_PRICE = defaultPlan?.monthlyPrice;
        YEARLY_PRICE = defaultPlan?.yearlyPrice;
        DISCOUNT = defaultPlan?.saveMoreThan;
    }

    let closeUrl = closeUrlProp;

    // TODO - put inside a util to arrange all of the routes
    if (location.pathname.split("/").length == 6) {
        closeUrl = agreementContext.wizardApi.getMaxStep();
    }

    useEffect(() => {
        if (isProd()) {
            mixpanel.track("payment_page_view");
            GTMHelper.push({ event: "payment_page_view" });

            if (dbUser?.email) {
                userEventApi("payment-page-view");
            }
        }

        function checkPaymentAndRedirect() {
            checkPayment(
                () => {
                    history.push("./1");
                },
                (data: any) => {
                    if (data.code === "quota_exceeded") {
                        setQuotaExceeded(true);
                    }
                },
                dbUser,
                dispatch,
                activeDraft?.id
            );
        }

        checkPaymentAndRedirect();

        let interval = setInterval(() => {
            checkPaymentAndRedirect();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const features = [
        <div>
            <ABTesting
                componentA={
                    <span>
                        <span className="font-semibold">Unlimited access</span> - use of all
                        documents, tools and features
                    </span>
                }
                componentB={
                    <span>
                        Create <span className="font-semibold">unlimited</span> contracts from our
                        extensive library
                    </span>
                }
                isSticky={true}
                firstVariantProbability={0.95}
                testName={AB_TEST_NAMES.TEST_8__PAYMENT_DR_JACOB}
            />
        </div>,
        <div>Store, share, download and e-sign any agreement</div>,
        <div>
            <span className="font-semibold">7-day money back guarantee</span>
        </div>,
        <div>Cancel anytime</div>,
        <div>Secured from desktop or mobile</div>,
        <div>Integrated free-text editor</div>,
    ];

    return (
        <Modal closeUrl={closeUrl} closeable={true} className="w-full">
            <div className="px-8 py-4 sm:w-[50vw]  overflow-y-auto h-[95vh] overflow-x-hidden">
                <p className="text-2xl text-center font-semibold mt-4">
                    {quotaExceeded ? (
                        <span>
                            Your {agreementContext.getDocTitle()} is{" "}
                            <span className="text-greens-500">100%</span> ready! However, you have
                            reached your documents' quota (View your usage{" "}
                            <Link to="/settings/usage">here</Link>).
                        </span>
                    ) : agreementContext.getDocTitle() ? (
                        <p className="text-2xl text-center font-semibold mt-4">
                            {firstName ? firstName + ", " : ""}your {agreementContext.getDocTitle()}{" "}
                            is <span className="text-greens-500">100%</span> ready!
                        </p>
                    ) : null}
                </p>
                {feature && (
                    <p className="text-2xl text-center font-semibold mt-4">Unlock {feature} now!</p>
                )}
                <ABTesting
                    componentA={<></>}
                    componentB={
                        <p className="text-2xl text-center font-semibold mt-4">
                            Get it Now + Unlimited Number of Tailor-Made Contracts
                        </p>
                    }
                    isSticky={true}
                    firstVariantProbability={0.95}
                    testName={AB_TEST_NAMES.TEST_8__PAYMENT_DR_JACOB}
                />

                {agreementContext.getDocTitle() && (
                    <p className="text-2xl text-center mt-2">
                        Select one of our packages to <span className="text-primary">Share</span>,{" "}
                        <span className="text-primary">E-Sign</span> or{" "}
                        <span className="text-primary">Download</span> it now!
                    </p>
                )}
                {!agreementContext.getDocTitle() && (
                    <p className="text-2xl text-center mt-2">
                        Unlock your Clawdia AI plan to safely manage and grow your business!
                    </p>
                )}
                {/* <div className="border w-full border-primary rounded-xl mt-2 p-2">
                    <p className="text-sm">You can call us at <a href="tel:+1 844-793-0727">+1 844-793-0727</a> or email us at <a href="mailto:clawdia@clawdia.ai">clawdia@clawdia.ai</a> if you have any questions.</p>
                </div> */}

                <div
                    className={clsx("flex lg:flex-row flex-col gap-4 mt-4", {
                        "w-1/2 mx-auto": Number(showMonthly) + Number(showYearly) === 1,
                    })}
                >
                    {!isIsraeliUser && showMonthly && (
                        <Plan
                            draftId={activeDraft?.id || ""}
                            planType={PlanType.MONTHLY}
                            type="Monthly Plan"
                            price={MONTHLY_PRICE + ""}
                            priceDescription="Per month"
                            features={features}
                            onPlanClick={async (...payload) => {
                                if (isDudaUser) {
                                    const upgradeConfig = {
                                        type: "upgradeApp",
                                        appId: "d1e7cea8-4b8d-408e-80b2-1ab7317a4680",
                                        planList: [
                                            "d723fbf6-ef55-4ca0-84ea-de35845d9bf7", // Free
                                            "525c3c74-cefa-49d3-b0dd-e57be9586f2c", // Paid
                                        ],
                                    };
                                    // @ts-ignore
                                    window._dAPI.upgrade(upgradeConfig);
                                    return;
                                }
                                const url = await getCheckoutUrl(...payload);
                                const success = await onPlanClick(url, PlanType.MONTHLY);
                                if (!success) {
                                    history.push(
                                        history.location.pathname +
                                            "/payment" +
                                            `?plan=${PlanType.MONTHLY}`
                                    );
                                }
                            }}
                        />
                    )}

                    {showYearly && (
                        <Plan
                            draftId={activeDraft?.id || ""}
                            planType={PlanType.YEARLY}
                            type="Yearly Plan"
                            price={YEARLY_PRICE + ""}
                            priceDescription={
                                isIsraeliUser ? (
                                    ""
                                ) : (
                                    <div>
                                        Per month, billed yearly{" "}
                                        {DISCOUNT && (
                                            <span>
                                                (save more than{" "}
                                                <span className="font-semibold">{DISCOUNT}</span>)
                                            </span>
                                        )}
                                    </div>
                                )
                            }
                            features={features}
                            ribbon={isIsraeliUser || isDudaUser ? "" : "BEST VALUE"}
                            onPlanClick={async (...payload) => {
                                if (isDudaUser) {
                                    const upgradeConfig = {
                                        type: "upgradeApp",
                                        appId: "d1e7cea8-4b8d-408e-80b2-1ab7317a4680",
                                        planList: [
                                            "d723fbf6-ef55-4ca0-84ea-de35845d9bf7", // Free
                                            "525c3c74-cefa-49d3-b0dd-e57be9586f2c", // Paid
                                        ],
                                    };
                                    // @ts-ignore
                                    window._dAPI.upgrade(upgradeConfig);
                                    return;
                                }
                                const url = await getCheckoutUrl(...payload);
                                const success = await onPlanClick(url, PlanType.YEARLY);
                                console.log("success", success);
                                if (!success) {
                                    history.push(
                                        history.location.pathname +
                                            "/payment" +
                                            `?plan=${PlanType.YEARLY}`
                                    );
                                }
                            }}
                        />
                    )}
                </div>
                <WhatOurCustomersAreSaying />
            </div>
        </Modal>
    );
}

export const getCheckoutUrl = async (
    planType: PlanType,
    dbUser: PUser | undefined,
    isWixUser: boolean,
    draftId?: string
) => {
    let url;

    const isShopifyUser = dbUser?.isShopifyUser;
    const isWebflowUser = dbUser?.supportedOauthProviders?.includes("webflow");
    const isFreshWorksUser = dbUser?.supportedOauthProviders?.includes("freshworks");

    if (planType === PlanType.YEARLY) {
        if (isShopifyUser) {
            const res = await ShopifyApi.getCheckoutUrl({
                cycle: planType,
                email: dbUser?.email,
                draftId: draftId!,
            });
            url = res.checkoutUrl;
        } else if (isWixUser) {
            const res = await wixGetCheckoutUrl({ cycle: planType, email: dbUser?.email });

            url = res.checkoutUrl;
        } else {
            if (dbUser?.country === "Israel") {
                url = "https://payments.payplus.co.il/l/09eb5dae-9d00-4dbe-ada5-6e31cbf314be";
            } else {
                if (isWebflowUser) {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=86327&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                } else if (isFreshWorksUser) {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=86330&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                } else {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=70299&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                }
            }
        }
    } else {
        if (isShopifyUser) {
            const res = await ShopifyApi.getCheckoutUrl({
                cycle: planType,
                email: dbUser?.email,
                draftId: draftId!,
            });
            url = res.checkoutUrl;
        } else if (isWixUser) {
            const res = await wixGetCheckoutUrl({ cycle: planType, email: dbUser?.email });

            url = res.checkoutUrl;
        } else {
            if (dbUser?.country === "Israel") {
                url = "https://payments.payplus.co.il/l/09eb5dae-9d00-4dbe-ada5-6e31cbf314be";
            } else {
                if (isWebflowUser) {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=86328&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                } else if (isFreshWorksUser) {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=86330&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                } else {
                    url =
                        "https://store.payproglobal.com/checkout?products[1][id]=70298&page-template=15553&billing-country=US&exfo=742&billing-email=" +
                        encodeURIComponent(dbUser?.email || "");
                }
            }
        }
    }

    return url;
};

const onPlanClick = async (url: string, planType: PlanType): Promise<boolean> => {
    // wixFlow 6 - if it wix user show wix external pricing page
    return new Promise(async (resolve) => {
        if (isProd()) {
            mixpanel.track("payment-continue-clicked", { plan: planType, checkoutUrl: url });
            userEventApi("payment-continue-clicked");
        }

        const newWindow = window.open(url, "_blank");

        setTimeout(() => {
            if (newWindow?.closed === true) {
                // window no opened

                if (isProd()) {
                    mixpanel.track("using_popup_blocker");
                }

                resolve(false);
            } else {
                resolve(true);
            }
        }, 100);
    });
};

export enum PlanType {
    MONTHLY = "monthly",
    QUARTERLY = "quarterly",
    YEARLY = "yearly",
}

function Plan(props: {
    draftId: string;
    type: string;
    price: string;
    priceDescription: ReactNode | string;
    features: ReactNode[] | string[];
    ribbon?: ReactNode;
    planType: PlanType;
    onPlanClick?: (
        planType: PlanType,
        dbUser: PUser | undefined,
        isWixUser: boolean,
        draftId: string
    ) => void;
}) {
    const { features, type, price, priceDescription, ribbon, planType, onPlanClick, draftId } =
        props;

    const [awaitingPayment, setAwaitingPayment] = useState(false);
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    // wixFlow 5 - detect if it wix user
    const isWIxUser = dbUser?.isWixUser || isWixFlow();

    const isIsraeliUser = dbUser?.country === "Israel";
    // sfp0LxHbBb7C8zKRn1vjK/jointTermsPhysical/1
    const history = useHistory();

    return (
        <div
            className="shadow-xl cursor-pointer bg-[#ecf1fd] rounded-xl flex-grow  relative flex flex-col items-center pb-4 gap-2"
            onClick={() => {
                setAwaitingPayment(true);

                onPlanClick?.(planType, dbUser, isWIxUser, draftId);
            }}
        >
            <div className="flex flex-col items-center gap-2 rounded-xl w-full bg-primary p-2">
                <div className="font-semibold text-[darkblue] text-2xl">{type}</div>
                <div className="text-white font-semibold">
                    <span className="text-3xl">$ </span>
                    <span className="text-5xl">{price}</span>
                </div>
                <div className="text-white text-sm">{priceDescription}</div>
            </div>
            <div className="w-full py-1 px-6 flex flex-col gap-1.5">
                {features?.map((feature, i) => (
                    <div key={i} className="flex gap-2 text-lg">
                        <div className="circle shrink-0">
                            <div className="checkmark"></div>
                        </div>
                        <span className=""> {feature}</span>
                    </div>
                ))}
            </div>
            <div className="px-8 w-full">
                <GetStartedButton className="w-full">
                    {awaitingPayment ? (
                        <div className="h-8 w-8 m-auto">
                            <Spinner />
                        </div>
                    ) : (
                        <ABTesting
                            componentA={<span className="text-accent">Get Started</span>}
                            componentB={<span className="text-accent">Get Contract</span>}
                            isSticky={true}
                            firstVariantProbability={0.95}
                            testName={AB_TEST_NAMES.TEST_8__PAYMENT_DR_JACOB}
                        />
                    )}
                </GetStartedButton>
            </div>

            {!!ribbon && (
                <Ribbon
                    content={ribbon}
                    position="top-right"
                    bgClassName="bg-seaweed"
                    textClassName="text-darkIndigo"
                />
            )}

            {isIsraeliUser && (
                <p
                    className="text-xs mt-2 text=primary underline"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        history.push("/logout");
                    }}
                >
                    Log out
                </p>
            )}
        </div>
    );
}

export function GetStartedButton({ className, onClick, children }: any) {
    return (
        <button
            onClick={onClick}
            className={`px-12 text-accent py-3 text-center bg-secondary text-xl cursor-pointer rounded-xl font-semibold tracking-tight hover:scale-105 ${className}`}
        >
            {children}
        </button>
    );
}

function WhatOurCustomersAreSaying() {
    return (
        <div className="flex flex-col items-center mt-6">
            <div className="text-2xl font-semibold text-center">What our customers are saying</div>
            <TestimonialsCarousel />
        </div>
    );
}
