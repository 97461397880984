/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum ESingleMemberLLCAgreementLlcAct {
  SingleMemberLLCAgreement_llcAct_NA = 0,
  SingleMemberLLCAgreement_llcAct_DE = 2,
  SingleMemberLLCAgreement_llcAct_CA = 1,
  SingleMemberLLCAgreement_llcAct_NY = 3,
  SingleMemberLLCAgreement_llcAct_NJ = 4,
  SingleMemberLLCAgreement_llcAct_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eSingleMemberLLCAgreementLlcActFromJSON(object: any): ESingleMemberLLCAgreementLlcAct {
  switch (object) {
    case 0:
    case "SingleMemberLLCAgreement_llcAct_NA":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NA;
    case 2:
    case "SingleMemberLLCAgreement_llcAct_DE":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_DE;
    case 1:
    case "SingleMemberLLCAgreement_llcAct_CA":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_CA;
    case 3:
    case "SingleMemberLLCAgreement_llcAct_NY":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NY;
    case 4:
    case "SingleMemberLLCAgreement_llcAct_NJ":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NJ;
    case 999999:
    case "SingleMemberLLCAgreement_llcAct_OTHER":
      return ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ESingleMemberLLCAgreementLlcAct.UNRECOGNIZED;
  }
}

export function eSingleMemberLLCAgreementLlcActToJSON(object: ESingleMemberLLCAgreementLlcAct): string {
  switch (object) {
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NA:
      return "SingleMemberLLCAgreement_llcAct_NA";
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_DE:
      return "SingleMemberLLCAgreement_llcAct_DE";
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_CA:
      return "SingleMemberLLCAgreement_llcAct_CA";
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NY:
      return "SingleMemberLLCAgreement_llcAct_NY";
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_NJ:
      return "SingleMemberLLCAgreement_llcAct_NJ";
    case ESingleMemberLLCAgreementLlcAct.SingleMemberLLCAgreement_llcAct_OTHER:
      return "SingleMemberLLCAgreement_llcAct_OTHER";
    case ESingleMemberLLCAgreementLlcAct.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MSingleMemberLLCAgreementLlcAct {
  optionDbValue: ESingleMemberLLCAgreementLlcAct;
  other: string;
}

export interface SingleMemberLLCAgreement {
  effectiveDate: Date | undefined;
  llcAct: MSingleMemberLLCAgreementLlcAct | undefined;
  memberAddress: string;
  registeredAgentName: string;
  registeredAgentAddress: string;
  entityState: string;
  formationDate: Date | undefined;
  registeredAgent: InternalBoolean;
  memberName: string;
  companyAddress: string;
}

function createBaseMSingleMemberLLCAgreementLlcAct(): MSingleMemberLLCAgreementLlcAct {
  return { optionDbValue: 0, other: "" };
}

export const MSingleMemberLLCAgreementLlcAct = {
  encode(message: MSingleMemberLLCAgreementLlcAct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MSingleMemberLLCAgreementLlcAct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMSingleMemberLLCAgreementLlcAct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MSingleMemberLLCAgreementLlcAct {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eSingleMemberLLCAgreementLlcActFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MSingleMemberLLCAgreementLlcAct): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eSingleMemberLLCAgreementLlcActToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MSingleMemberLLCAgreementLlcAct>, I>>(base?: I): MSingleMemberLLCAgreementLlcAct {
    return MSingleMemberLLCAgreementLlcAct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MSingleMemberLLCAgreementLlcAct>, I>>(
    object: I,
  ): MSingleMemberLLCAgreementLlcAct {
    const message = createBaseMSingleMemberLLCAgreementLlcAct();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseSingleMemberLLCAgreement(): SingleMemberLLCAgreement {
  return {
    effectiveDate: undefined,
    llcAct: undefined,
    memberAddress: "",
    registeredAgentName: "",
    registeredAgentAddress: "",
    entityState: "",
    formationDate: undefined,
    registeredAgent: 0,
    memberName: "",
    companyAddress: "",
  };
}

export const SingleMemberLLCAgreement = {
  encode(message: SingleMemberLLCAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.llcAct !== undefined) {
      MSingleMemberLLCAgreementLlcAct.encode(message.llcAct, writer.uint32(18).fork()).ldelim();
    }
    if (message.memberAddress !== "") {
      writer.uint32(26).string(message.memberAddress);
    }
    if (message.registeredAgentName !== "") {
      writer.uint32(34).string(message.registeredAgentName);
    }
    if (message.registeredAgentAddress !== "") {
      writer.uint32(42).string(message.registeredAgentAddress);
    }
    if (message.entityState !== "") {
      writer.uint32(50).string(message.entityState);
    }
    if (message.formationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.formationDate), writer.uint32(58).fork()).ldelim();
    }
    if (message.registeredAgent !== 0) {
      writer.uint32(64).int32(message.registeredAgent);
    }
    if (message.memberName !== "") {
      writer.uint32(74).string(message.memberName);
    }
    if (message.companyAddress !== "") {
      writer.uint32(82).string(message.companyAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleMemberLLCAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleMemberLLCAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.llcAct = MSingleMemberLLCAgreementLlcAct.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.memberAddress = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.registeredAgentName = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.registeredAgentAddress = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.entityState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.formationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.registeredAgent = reader.int32() as any;
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.memberName = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingleMemberLLCAgreement {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      llcAct: isSet(object.llcAct) ? MSingleMemberLLCAgreementLlcAct.fromJSON(object.llcAct) : undefined,
      memberAddress: isSet(object.memberAddress) ? String(object.memberAddress) : "",
      registeredAgentName: isSet(object.registeredAgentName) ? String(object.registeredAgentName) : "",
      registeredAgentAddress: isSet(object.registeredAgentAddress) ? String(object.registeredAgentAddress) : "",
      entityState: isSet(object.entityState) ? String(object.entityState) : "",
      formationDate: isSet(object.formationDate) ? fromJsonTimestamp(object.formationDate) : undefined,
      registeredAgent: isSet(object.registeredAgent) ? internalBooleanFromJSON(object.registeredAgent) : 0,
      memberName: isSet(object.memberName) ? String(object.memberName) : "",
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
    };
  },

  toJSON(message: SingleMemberLLCAgreement): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.llcAct !== undefined &&
      (obj.llcAct = message.llcAct ? MSingleMemberLLCAgreementLlcAct.toJSON(message.llcAct) : undefined);
    message.memberAddress !== undefined && (obj.memberAddress = message.memberAddress);
    message.registeredAgentName !== undefined && (obj.registeredAgentName = message.registeredAgentName);
    message.registeredAgentAddress !== undefined && (obj.registeredAgentAddress = message.registeredAgentAddress);
    message.entityState !== undefined && (obj.entityState = message.entityState);
    message.formationDate !== undefined && (obj.formationDate = message.formationDate.toISOString());
    message.registeredAgent !== undefined && (obj.registeredAgent = internalBooleanToJSON(message.registeredAgent));
    message.memberName !== undefined && (obj.memberName = message.memberName);
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleMemberLLCAgreement>, I>>(base?: I): SingleMemberLLCAgreement {
    return SingleMemberLLCAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleMemberLLCAgreement>, I>>(object: I): SingleMemberLLCAgreement {
    const message = createBaseSingleMemberLLCAgreement();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.llcAct = (object.llcAct !== undefined && object.llcAct !== null)
      ? MSingleMemberLLCAgreementLlcAct.fromPartial(object.llcAct)
      : undefined;
    message.memberAddress = object.memberAddress ?? "";
    message.registeredAgentName = object.registeredAgentName ?? "";
    message.registeredAgentAddress = object.registeredAgentAddress ?? "";
    message.entityState = object.entityState ?? "";
    message.formationDate = object.formationDate ?? undefined;
    message.registeredAgent = object.registeredAgent ?? 0;
    message.memberName = object.memberName ?? "";
    message.companyAddress = object.companyAddress ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
