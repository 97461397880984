import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type modalName = keyof uiState["modals"];

interface FillTableModal {
    name: "FillTableModal";
    data?: null | { field: Field | undefined; value: any; onSave: any };
}

interface EditKbModal {
    name: "LEGAL_IDE_WIZARD_EDIT_KB";
    data?: { kb: string | undefined; onRemove?: () => void; onSave: (kb: string) => void };
}
interface EditFieldModal {
    name: "LEGAL_IDE_WIZARD_EDIT_FIELD";
    data?: Field;
}
interface EditStepModal {
    name: "editWizardStep";
    data?: Step;
}
interface EditSectionModal {
    name: "ForLawyers_editSection";
    data?: Section;
}
interface CreateFillableModal {
    name: "ForLawyers_createFillable";
    data?: any;
}

interface ConditionModal {
    name: "LEGAL_IDE_WIZARD_CONDITION";
    data?: Section | Step | Question | FieldOption;
}
interface EditVideoModal {
    name: "editVideo";
    data?: {
        videoInfo: VideoInfo | undefined;
        isIntro?: boolean;
        onRemove?: () => void;
        onSave: (videoInfo: VideoInfo) => void;
    };
}

export interface uiState {
    modals: {
        editWizardStep: { isOpen: boolean; data?: EditStepModal["data"] | null };
        editVideo: {
            isOpen: boolean;
            data?: EditVideoModal["data"] | null;
        };
        ForLawyers_editSection: { isOpen: boolean; data?: EditSectionModal["data"] | null };
        ForLawyers_createFillable: { isOpen: boolean; data?: CreateFillableModal["data"] | null };
        LEGAL_IDE_WIZARD_CONDITION: { isOpen: boolean; data?: ConditionModal["data"] | null };
        LEGAL_IDE_WIZARD_EDIT_FIELD: { isOpen: boolean; data?: EditFieldModal["data"] | null };
        LEGAL_IDE_WIZARD_EDIT_KB: { isOpen: boolean; data?: EditKbModal["data"] | null };
        FillTableModal: { isOpen: boolean; data: FillTableModal["data"] };
    };
    pages: {
        library: {
            searchText: string;
            hebOnly: boolean;
        };
    };
}

type ModalsTypes =
    | EditStepModal
    | EditSectionModal
    | EditFieldModal
    | EditKbModal
    | EditVideoModal
    | CreateFillableModal
    | ConditionModal
    | FillTableModal;

const initialState: uiState = {
    modals: {
        editWizardStep: { isOpen: false },
        editVideo: { isOpen: false },
        ForLawyers_editSection: { isOpen: false },
        ForLawyers_createFillable: { isOpen: false },
        LEGAL_IDE_WIZARD_CONDITION: { isOpen: false },
        LEGAL_IDE_WIZARD_EDIT_FIELD: { isOpen: false },
        LEGAL_IDE_WIZARD_EDIT_KB: { isOpen: false },
        FillTableModal: { isOpen: false, data: null },
    },
    pages: {
        library: {
            searchText: "",
            hebOnly: false,
        },
    },
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<ModalsTypes>) => {
            state.modals[action.payload.name].isOpen = true;
            state.modals[action.payload.name].data = action.payload.data;
        },
        closeModal: (state, action: PayloadAction<{ name: modalName }>) => {
            state.modals[action.payload.name].isOpen = false;
            state.modals[action.payload.name].data = null;
        },
        librarySetSearchText(state, action) {
            state.pages.library.searchText = action.payload;
        },
        hebrewOnly(state, action) {
            state.pages.library.hebOnly = action.payload;
        }
    },
});
