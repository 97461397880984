import { DocsApi } from "api-services/DocsApi";
import { Spinner, WizardLayout } from "components";
import PaymentPlans from "components/life-cycle/payment/PaymentPlans";
import { getLastStep } from "components/utils/RouteManager";
import { DocPreviewContent } from "components/wizard-display/agreements/DocPreview";
import FinishDraft from "components/wizard-display/FinishDraft";
import { FinishDraftMessage } from "components/wizard-display/FinishDraftMessage";
import "components/wizard-display/WizardDispaly.scss";
import { useMedia } from "hooks";
import { PDocStatus } from "proto/PDoc";
import { PLegalUnit } from "proto/PLegalUnit";
import React, { ReactNode, useContext, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useAppSelector } from "store";
import { activateDraft, markLastStep, setIsDocPreviewOpen, setWizardNavigatorOpen } from "store/mainActions";
import { isInMyInbox } from "store/mainStore";
import { MainStore } from "store/mainTypes";
import { AgreementContext } from "./AgreementContext/AgreementContext";
import { useNavigation } from "./AgreementContext/useNavigation";
import { AutoContractDocument } from "./auto-contracts/autoContractsUtils";
import { callToSaveDraft, WizardForm } from "./WizardForm";
import { WizardHeaderNew } from "./WizardHeaderNew";
import { WizardNavigatorNew } from "./WizardNavigatorNew";

let done = false;

export function WizardUi(props: { contract: AutoContractDocument; children?: any; paymentDocPreviewMode?: boolean }) {
    const dispatch = useDispatch();
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const agreementContext = useContext(AgreementContext)!;

    let activeDraft = agreementContext.getActiveDraft();

    const myAccountName = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser?.accountName);
    const inMyInbox = isInMyInbox(activeDraft, myAccountName);

    const navigationApi = useNavigation({ draft: agreementContext.getActiveDraft() });
    const notCompletedSteps = agreementContext.wizardApi.getNotCompletedSteps();
    const isFinishSection = navigationApi.isFinishStep();
    let isAllDoneCompletley = agreementContext.wizardApi.isWizardCompleted();
    const isPaymentPage = navigationApi.isPaymentPage();

    const isEditableDraft = activeDraft?.docStatus === PDocStatus.DRAFT && !props.paymentDocPreviewMode;

    const isSpecialScreen = isFinishSection || isPaymentPage;

    const smallScreen = useMedia({ max: "lg" });
    const isDocPreviewOpen = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.isDocPreviewOpen);

    const location = useLocation();
    let newWiz: PLegalUnit | undefined = activeDraft?.legalUnit;

    useEffect(() => {
        if (activeDraft?.legalUnit) {
            let lastStep: string = getLastStep(location);

            dispatch(markLastStep(lastStep));
            let newLegalUnit: PLegalUnit = {
                ...activeDraft.legalUnit,
                lastStep: lastStep,
            };

            callToSaveDraft(location, newLegalUnit); // todo: fix
        }
    }, [location]);

    useEffect(() => {
        if (!activeDraft?.legalUnit?.docVersion) return;
        agreementContext.loadAgreement();
    }, [activeDraft?.legalUnit?.docVersion]);

    useEffect(() => {
        DocsApi.getAllDocs(dispatch);

        agreementContext.getDoc();

        return () => {
            dispatch(activateDraft(undefined));
            agreementContext.cleanup();
        };
    }, []);

    const currentStep = agreementContext.navigation.getCurrentStep();

    const stepQuestions = agreementContext.wizardApi.getFilteredQuestionsForStep(currentStep);

    if (!newWiz || !currentAgreement) {
        return (
            <div className="m-auto">
                <Spinner />
            </div>
        );
    }

    let mainContent: ReactNode;
    if (isPaymentPage) mainContent = <PaymentPlans />;
    else if (agreementContext.navigation.isFinishStep()) {
        if (!isAllDoneCompletley) mainContent = <FinishDraftMessage steps={notCompletedSteps} />;
        else mainContent = <FinishDraft />;
    } else {
        mainContent = activeDraft?.legalUnit?.freestyleSrc ? null : (
            <WizardForm
                stepQuestions={stepQuestions}
                key={location.pathname}
                children={undefined}
                wizard={currentAgreement?.wizard!}
                agreement={currentAgreement!}
            />
        );
    }

    return (
        <WizardLayout
            left={isEditableDraft && <WizardNavigatorNew agreement={currentAgreement!} />}
            center={
                (!isEditableDraft || (!smallScreen && !isSpecialScreen && isDocPreviewOpen)) && (
                    <DocPreviewContent sign={inMyInbox} completed={!isEditableDraft} />
                )
            }
            bottomSheet={isEditableDraft && <DocPreviewContent sign={false} />}
            header={
                <WizardHeaderNew
                    activeDraft={activeDraft}
                    docTitle={agreementContext.getDocTitle()}
                    onPreview={() => dispatch(setIsDocPreviewOpen(true))}
                    onShowNavigation={() => dispatch(setWizardNavigatorOpen(true))}
                    onHideNavigation={() => dispatch(setWizardNavigatorOpen(false))}
                    contract={props.contract}
                    paymentDocPreviewMode={props.paymentDocPreviewMode}
                />
            }
            right={isEditableDraft && mainContent}
        />
    );
}

class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.error("ERROR", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
