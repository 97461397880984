import { NewIcon } from "components";
import { useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

// edit knowledgebase modal
// who use this modal should provide on save callback and inital value if exists
export const EditKBModal = () => {
    const payload = useAppSelector((state) => state.ui.modals.LEGAL_IDE_WIZARD_EDIT_KB.data);

    const [kb, setKb] = useState(payload?.kb || "");
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_EDIT_KB" }));
    };

    const onSave = () => {
        dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_EDIT_KB" }));
        payload?.onSave(kb);
    };

    const onRemove = () => {
        payload?.onRemove?.();
        dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_EDIT_KB" }));
    };
    return (
        <BaseModal title="Edit KB" onClose={onCancel} size="lg">
            <div className="flex flex-col gap-4">
                <div className="flex gap-2">
                    <textarea
                        value={kb}
                        onChange={(e) => setKb(e.target.value)}
                        className="border-2 p-1 w-full flex-grow min-h-[250px]"
                    />
                    <NewIcon
                        onClick={onRemove}
                        size="lg"
                        className="text-accent group-hover:text-primaryHover"
                        name="remove"
                    />
                </div>
                <div className="">
                    <button
                        className=" py-2 px-8 bg-primary text-white text-base font-bold  rounded-md hover:bg-primaryHover cursor-pointer"
                        onClick={onSave}
                    >
                        Save
                    </button>
                    <button
                        className="py-2 px-8"
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        cancel
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};
