import { AxiosResponse } from "axios";
import { isProd } from "components/utils/EnvResolver";
import { GTMHelper } from "components/utils/GTMHelper";
import mixpanel from "mixpanel-browser";
import { PLogoSettings, PUser, PUserStatus, PUserSubscriptionType } from "proto/PUser";
import { setDBUser } from "store/mainActions";
import { PGetUserResponse, PPostUserRequest, PValidateAccountRequest } from "../proto/PApi";
import { request } from "./ApiCommon";

export function getUser(successCallback: Function) {
    request("/api/v1/user", { method: "GET" })
        ?.then((response) => {
            const resp = PGetUserResponse.fromJSON(response.data);

            successCallback(resp);

            if (resp.user?.userStatus === PUserStatus.POST_PAYMENT) {
                checkLocalStorage(resp.user);
            }
        })
        .catch((e: any) => {}); // todo
}

export function postUser(req: PPostUserRequest, successCallback: Function) {
    request("/api/v1/user", { method: "PUT", data: PPostUserRequest.toJSON(req) })
        ?.then((response) => successCallback(response.data))
        .catch(() => {}); // todo

    // let x : Uint8Array =[0, 1];
    // new AddServiceClientImpl({request: {service: "", method: "", data: x})
}

export function validateAccount(req: PValidateAccountRequest, successCallback: Function) {
    request("/api/v1/user/validate-account", {
        method: "POST",
        data: PValidateAccountRequest.toJSON(req),
    })
        ?.then((response) => successCallback(response.data))
        .catch(() => {}); // todo
}

export async function checkPayment(
    successCallback: Function,
    notPayingCallback: Function,
    user: PUser | undefined,
    dispatch: Function,
    docId?: string
) {
    const response = await request("/api/v1/user/check-payment", {
        method: "GET",
        params: { docId },
    });

    if (response.success) {
        successCallback();

        getUser((resp: PGetUserResponse) => {
            dispatch(setDBUser(resp.user));
        });

        checkLocalStorage(user);
    } else {
        if (response?.status === 403) {
            notPayingCallback(response.data);

            storeNotPaying();
        }
    }
}

export function userEventApi(event: string) {
    request("/api/v1/user/event?event=" + event, { method: "POST" })
        ?.then(() => {})
        .catch(() => {}); // todo
}

const USER_NOT_PAYING_KEY = "user-not-paying";

function storeNotPaying() {
    localStorage.setItem(USER_NOT_PAYING_KEY, "true");
}

function checkLocalStorage(user: PUser | undefined) {
    let wasUserNotPaying = localStorage.getItem(USER_NOT_PAYING_KEY);
    localStorage.removeItem(USER_NOT_PAYING_KEY);

    if (isProd()) {
        if (wasUserNotPaying === "true") {
            mixpanel.track("Purchase");
            GTMHelper.push({ event: "purchase" });

            if (user?.subscriptionType === PUserSubscriptionType.YEARLY_SUBSCRIPTION) {
                GTMHelper.push({ event: "yearly_purchase" });
            }
        }
    }
}

export function updateLogoSettings(
    req: PLogoSettings,
    successfulCallback: Function,
    failureCallback: Function
) {
    request("/api/v1/user/logo-settings", { method: "POST", data: PLogoSettings.toJSON(req) })
        ?.then((response) => {
            if (response?.status === 200) {
                successfulCallback();
            }
        })
        .catch(() => {
            failureCallback();
        });
}
