import { Container, DropDown } from "components";
import React from "react";
import LogoImgWhite from "img/white-logo.png";
import LogoImgBlue from "img/blue-logo.png";
import { useHistory, useLocation } from "react-router";
import { useAuth, UserDetails } from "components/contexts/AuthContext";
import { useSelector } from "react-redux";

import EmptyAvatarImg from "img/icons/empty-avatar.png";
import { MainStore } from "store/mainTypes";
import useOutsideAlerter from "components/0_common/OutsideClickAlerter/OutsideClickAlerter";
import { useMedia } from "hooks/useMedia";
import { NotificationsWidget } from "./NotificationsWidget";
import { DropdownMenu } from "components/0_common/DropDownMenu";

// TODO: this is a copy from website. Think of merging this with website
// TODO: container vs. section
export function Menu({
    menuItemDetails,
    widgets,
}: {
    menuItemDetails: MenuItemDetails[];
    widgets: MenuWidget[];
}) {
    const [hamburgerMenuOpen, setHamburgerMenuOpen] = React.useState(false);
    const menuRef = React.createRef<HTMLDivElement>();
    useOutsideAlerter(menuRef, () => setHamburgerMenuOpen(false));

    return (
        <div ref={menuRef} className="w-full bg-primary md:pt-5 md:pb-9 pt-2 pb-5">
            <Container className={`w-full bg-primary`}>
                <div className="flex justify-start">
                    <a className="text-white text-xs" href="tel:1-844-793-0727">
                        1-844-793-0727
                    </a>
                </div>
                <div className="flex justify-between items-center w-full">
                    <Logo />
                    <div className="hidden lg:flex justify-center items-center gap-10">
                        {menuItemDetails
                            .filter((item) => !item.mobileOnly)
                            .map((item) => {
                                const menuItem = (
                                    <MenuItem
                                        isParentMenu={!!item.component}
                                        href={item.href}
                                        key={item.text}
                                    >
                                        {item.text}
                                    </MenuItem>
                                );

                                if (item.component) {
                                    return item.component(menuItem);
                                }

                                return menuItem;
                            })}
                    </div>
                    <div className="hidden lg:flex items-center gap-8">
                        <div className="flex gap-4 items-center">
                            {widgets.map((link, i) => (
                                <div
                                    className="flex h-12 w-12 justify-center text-white items-center rounded-full bg-iceBlue bg-opacity-10"
                                    key={i}
                                >
                                    {link.isUser ? (
                                        <div className="w-full h-full rounded-full">
                                            {link.label}
                                        </div>
                                    ) : (
                                        <div className="w-5 h-5">{link.label}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex lg:hidden gap-4 items-center">
                        <div className="flex h-12 w-12 justify-center text-white items-center rounded-full bg-iceBlue bg-opacity-10">
                            <NotificationsWidget />
                        </div>
                        <HamburgerButton
                            hamburgerMenuOpen={hamburgerMenuOpen}
                            setHamburgerMenuOpen={setHamburgerMenuOpen}
                        />
                    </div>
                </div>
            </Container>
            <HamburgerMenu
                menuItemDetails={menuItemDetails}
                widgets={widgets}
                hamburgerMenuOpen={hamburgerMenuOpen}
                setHamburgerMenuOpen={setHamburgerMenuOpen}
            />
        </div>
    );
}

function Logo() {
    const history = useHistory();

    return (
        <div className="flex items-center justify-center h-8 md:h-14">
            <a onClick={() => history.push("/")}>
                <LogoImg mode="dark" size="medium" />
            </a>
        </div>
    );
}

export function LogoImg({ mode, size }: { mode: "light" | "dark"; size: "large" | "medium" }) {
    let className = "";

    switch (size) {
        case "medium":
            className = "w-28";
            break;
        case "large":
            className = "w-50";
            break;
    }

    if (mode == "light") {
        return <img className={className} src={LogoImgBlue} alt="logo" />;
    }

    return <img className={className} src={LogoImgWhite} alt="logo" />;
}

function MenuItem({
    href,
    children,
    isParentMenu,
}: {
    href: string;
    children: React.ReactNode;
    isParentMenu: boolean;
}) {
    const history = useHistory();
    const location = useLocation();
    const isActive = checkIsActive(href, location.pathname);

    return (
        <a
            className={`flex text-lg font-medium hover:no-underline
        hover:text-darkIndigo hover:bg-secondary rounded-lg px-3 py-2.5
        ${isActive ? "bg-secondary text-darkIndigo" : "text-white"}
        `}
            onClick={() => {
                if (!isParentMenu) {
                    history.push(href);
                }
            }}
        >
            {children}
        </a>
    );
}

export function SmallMenuItem({ href, children }: { href: string; children: React.ReactNode }) {
    const history = useHistory();

    return (
        <a
            className="text-sm hover:underline tracking-slight text-white"
            onClick={() => {
                history.push(href);
            }}
        >
            {children}
        </a>
    );
}

export function HamburgerButton({
    hamburgerMenuOpen,
    setHamburgerMenuOpen,
}: {
    hamburgerMenuOpen: boolean;
    setHamburgerMenuOpen: (value: boolean) => void;
}) {
    return (
        <div
            className="hamburger-menu w-5 h-7 z-50 -scale-x-100 relative
        flex items-center
        "
        >
            <input
                id="hamburger-checkbox"
                type="checkbox"
                className="opacity-0 peer"
                checked={hamburgerMenuOpen}
                onChange={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
            />
            <label
                className={`cursor-pointer 
                before:w-6 before:h-0.5 before:block before:absolute before:transition-all before:duration-300
                before:-top-[0.4rem]
                peer-checked:before:rotate-0 peer-checked:before:top-0 peer-checked:before:w-full
                w-4 h-0.5 block absolute transition-all duration-300
                peer-checked:rotate-45 peer-checked:w-full
                after:w-2 after:h-0.5 after:block after:absolute after:transition-all after:duration-300
                after:top-[0.4rem]
                peer-checked:after:rotate-90 peer-checked:after:top-0 peer-checked:after:w-full
                 bg-white before:bg-white after:bg-white peer-checked::bg-white peer-checked:before:bg-white peer-checked:after:bg-white"
            `}
                htmlFor="hamburger-checkbox"
            ></label>
        </div>
    );
}

export function HamburgerMenu({
    menuItemDetails,
    widgets,
    hamburgerMenuOpen,
    setHamburgerMenuOpen,
}: {
    menuItemDetails: MenuItemDetails[];
    widgets: MenuWidget[];
    hamburgerMenuOpen: boolean;
    setHamburgerMenuOpen: (value: boolean) => void;
}) {
    const location = useLocation();
    const history = useHistory();
    const myRef = React.createRef<HTMLInputElement>();

    return (
        <div
            ref={myRef}
            className={`lg:hidden transition-all duration-300 w-full absolute top-0 bg-primary border-0 ${
                hamburgerMenuOpen ? "opacity-100 z-40" : "opacity-0 -z-10"
            }`}
        >
            <Container className="w-full pt-4 pb-4 bg-primary">
                <div className="flex justify-between items-center w-full">
                    <User />
                </div>
                <div className="flex flex-col justify-start gap-4 mt-8 mb-8">
                    {menuItemDetails.map((item) => {
                        const isActive = checkIsActive(item.href, location.pathname);

                        return (
                            <Container
                                key={item.href}
                                className={`text-center ${
                                    isActive ? "bg-secondary" : "bg-none"
                                } px-4 py-3 rounded-lg`}
                            >
                                <a
                                    onClick={() => {
                                        history.push(item.href);
                                        setHamburgerMenuOpen(false);
                                    }}
                                    key={item.text}
                                    className={`${
                                        isActive ? "text-darkIndigo" : "text-white"
                                    } text-lg font-semibold`}
                                >
                                    {item.text}
                                </a>
                            </Container>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
}

function checkIsActive(href: string, pathname: string) {
    return href === pathname || pathname.includes(href + "/");
}

export function User() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    return (
        <div className="flex items-center gap-4 mt-3">
            <UserImg className="w-6 h-6 rounded-full" />
            <p className="text-white font-medium text-lg">{dbUser?.fullName}</p>
        </div>
    );
}

export function userImgUrl(auth: UserDetails | null) {
    return auth?.user?.photoURL ?? EmptyAvatarImg;
}

export function UserImg({ className }: { className?: string }) {
    const auth = useAuth();
    const history = useHistory();
    const smallScreen = useMedia({ max: "lg" });

    const userImage = (
        <img
            alt="user"
            className={className}
            src={userImgUrl(auth)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = EmptyAvatarImg;
            }}
        />
    );

    if (smallScreen) {
        return userImage;
    }

    return (
        <DropdownMenu align="end" element={userImage}>
            <div className="flex flex-col w-40 rounded-lg bg-white shadow-lg">
                <a
                    className="text-sm tracking-slight text-darkIndigo hover:bg-stroke1 px-4 py-3 rounded-t-lg"
                    onClick={() => {
                        history.push("/settings");
                    }}
                >
                    Settings
                </a>
                <a
                    className="text-sm tracking-slight text-darkIndigo hover:bg-stroke1 px-4 py-3 rounded-b-lg"
                    onClick={() => {
                        auth?.logout();
                        history.push("/login");
                        window.location.reload();
                    }}
                >
                    Logout
                </a>
            </div>
        </DropdownMenu>
    );
}

export interface MenuItemDetails {
    href: string;
    text: string;
    mobileOnly?: boolean;
    component?: (x: React.ReactNode) => React.ReactNode;
}

export interface MenuWidget {
    label: JSX.Element;
    isUser?: boolean;
}
