import { errorHandler } from "api-services/ErrorHandlingApi";
import { AxiosError } from "axios";
import { isProd } from "components/utils/EnvResolver";

interface ErrorDetails {
    methodName: string;
    error: any;
}
export async function reportErrorToBE(errorDetails: ErrorDetails) {
    const { error, methodName } = errorDetails;
    const message = isError(error) ? error.message : "Unknown error";
    const details: any = {
        message,
        methodName,
    };

    if (isAxiosError(error)) {
        details.status = error.response?.status;
        details.statusText = error.response?.statusText;
        details.url = error.response?.config?.url;
    }

    errorHandler({
        methodName,
        details: JSON.stringify(details),
    });

    if (!isProd()) {
        // TODO: we may want add flag to allow print to console on prod
        console.error(details);
    }
}

function isError(err: any): err is Error {
    return !!err.message;
}

export function isAxiosError(err: any): err is AxiosError {
    return !!err.response;
}
