/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "";

export enum EInfluencerAgreementCompanyEntityType {
  InfluencerAgreement_companyEntityType_NA = 0,
  InfluencerAgreement_companyEntityType_CORPORATION = 1,
  InfluencerAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  InfluencerAgreement_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  InfluencerAgreement_companyEntityType_LIMITED_PARTNERSHIP = 4,
  InfluencerAgreement_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  InfluencerAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  InfluencerAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  InfluencerAgreement_companyEntityType_NONPROFIT_CORPORATION = 8,
  InfluencerAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  InfluencerAgreement_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eInfluencerAgreementCompanyEntityTypeFromJSON(object: any): EInfluencerAgreementCompanyEntityType {
  switch (object) {
    case 0:
    case "InfluencerAgreement_companyEntityType_NA":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_NA;
    case 1:
    case "InfluencerAgreement_companyEntityType_CORPORATION":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_CORPORATION;
    case 2:
    case "InfluencerAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "InfluencerAgreement_companyEntityType_SOLE_PROPRIETORSHIP":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "InfluencerAgreement_companyEntityType_LIMITED_PARTNERSHIP":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "InfluencerAgreement_companyEntityType_PROFESSIONAL_CORPORATION":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "InfluencerAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "InfluencerAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "InfluencerAgreement_companyEntityType_NONPROFIT_CORPORATION":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "InfluencerAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "InfluencerAgreement_companyEntityType_PARTNERSHIP":
      return EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EInfluencerAgreementCompanyEntityType.UNRECOGNIZED;
  }
}

export function eInfluencerAgreementCompanyEntityTypeToJSON(object: EInfluencerAgreementCompanyEntityType): string {
  switch (object) {
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_NA:
      return "InfluencerAgreement_companyEntityType_NA";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_CORPORATION:
      return "InfluencerAgreement_companyEntityType_CORPORATION";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "InfluencerAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_SOLE_PROPRIETORSHIP:
      return "InfluencerAgreement_companyEntityType_SOLE_PROPRIETORSHIP";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_LIMITED_PARTNERSHIP:
      return "InfluencerAgreement_companyEntityType_LIMITED_PARTNERSHIP";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_CORPORATION:
      return "InfluencerAgreement_companyEntityType_PROFESSIONAL_CORPORATION";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "InfluencerAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "InfluencerAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_NONPROFIT_CORPORATION:
      return "InfluencerAgreement_companyEntityType_NONPROFIT_CORPORATION";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "InfluencerAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EInfluencerAgreementCompanyEntityType.InfluencerAgreement_companyEntityType_PARTNERSHIP:
      return "InfluencerAgreement_companyEntityType_PARTNERSHIP";
    case EInfluencerAgreementCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EInfluencerAgreementInvoiceFreq {
  InfluencerAgreement_invoiceFreq_NA = 0,
  InfluencerAgreement_invoiceFreq_MADVANCE = 1,
  InfluencerAgreement_invoiceFreq_MARREARS = 2,
  UNRECOGNIZED = -1,
}

export function eInfluencerAgreementInvoiceFreqFromJSON(object: any): EInfluencerAgreementInvoiceFreq {
  switch (object) {
    case 0:
    case "InfluencerAgreement_invoiceFreq_NA":
      return EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_NA;
    case 1:
    case "InfluencerAgreement_invoiceFreq_MADVANCE":
      return EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_MADVANCE;
    case 2:
    case "InfluencerAgreement_invoiceFreq_MARREARS":
      return EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_MARREARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EInfluencerAgreementInvoiceFreq.UNRECOGNIZED;
  }
}

export function eInfluencerAgreementInvoiceFreqToJSON(object: EInfluencerAgreementInvoiceFreq): string {
  switch (object) {
    case EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_NA:
      return "InfluencerAgreement_invoiceFreq_NA";
    case EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_MADVANCE:
      return "InfluencerAgreement_invoiceFreq_MADVANCE";
    case EInfluencerAgreementInvoiceFreq.InfluencerAgreement_invoiceFreq_MARREARS:
      return "InfluencerAgreement_invoiceFreq_MARREARS";
    case EInfluencerAgreementInvoiceFreq.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EInfluencerAgreementRemainderType {
  InfluencerAgreement_remainderType_NA = 0,
  InfluencerAgreement_remainderType_FULL_ADVANCE = 1,
  InfluencerAgreement_remainderType_FULL_END = 2,
  InfluencerAgreement_remainderType_INITIAL = 3,
  UNRECOGNIZED = -1,
}

export function eInfluencerAgreementRemainderTypeFromJSON(object: any): EInfluencerAgreementRemainderType {
  switch (object) {
    case 0:
    case "InfluencerAgreement_remainderType_NA":
      return EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_NA;
    case 1:
    case "InfluencerAgreement_remainderType_FULL_ADVANCE":
      return EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_FULL_ADVANCE;
    case 2:
    case "InfluencerAgreement_remainderType_FULL_END":
      return EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_FULL_END;
    case 3:
    case "InfluencerAgreement_remainderType_INITIAL":
      return EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_INITIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EInfluencerAgreementRemainderType.UNRECOGNIZED;
  }
}

export function eInfluencerAgreementRemainderTypeToJSON(object: EInfluencerAgreementRemainderType): string {
  switch (object) {
    case EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_NA:
      return "InfluencerAgreement_remainderType_NA";
    case EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_FULL_ADVANCE:
      return "InfluencerAgreement_remainderType_FULL_ADVANCE";
    case EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_FULL_END:
      return "InfluencerAgreement_remainderType_FULL_END";
    case EInfluencerAgreementRemainderType.InfluencerAgreement_remainderType_INITIAL:
      return "InfluencerAgreement_remainderType_INITIAL";
    case EInfluencerAgreementRemainderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EInfluencerAgreementFeeBasis {
  InfluencerAgreement_feeBasis_NA = 0,
  InfluencerAgreement_feeBasis_FIXED = 1,
  InfluencerAgreement_feeBasis_DAILY = 3,
  InfluencerAgreement_feeBasis_WEEKLY = 4,
  InfluencerAgreement_feeBasis_MONTHLY = 5,
  InfluencerAgreement_feeBasis_CUSTOM = 6,
  UNRECOGNIZED = -1,
}

export function eInfluencerAgreementFeeBasisFromJSON(object: any): EInfluencerAgreementFeeBasis {
  switch (object) {
    case 0:
    case "InfluencerAgreement_feeBasis_NA":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_NA;
    case 1:
    case "InfluencerAgreement_feeBasis_FIXED":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_FIXED;
    case 3:
    case "InfluencerAgreement_feeBasis_DAILY":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_DAILY;
    case 4:
    case "InfluencerAgreement_feeBasis_WEEKLY":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_WEEKLY;
    case 5:
    case "InfluencerAgreement_feeBasis_MONTHLY":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_MONTHLY;
    case 6:
    case "InfluencerAgreement_feeBasis_CUSTOM":
      return EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EInfluencerAgreementFeeBasis.UNRECOGNIZED;
  }
}

export function eInfluencerAgreementFeeBasisToJSON(object: EInfluencerAgreementFeeBasis): string {
  switch (object) {
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_NA:
      return "InfluencerAgreement_feeBasis_NA";
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_FIXED:
      return "InfluencerAgreement_feeBasis_FIXED";
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_DAILY:
      return "InfluencerAgreement_feeBasis_DAILY";
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_WEEKLY:
      return "InfluencerAgreement_feeBasis_WEEKLY";
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_MONTHLY:
      return "InfluencerAgreement_feeBasis_MONTHLY";
    case EInfluencerAgreementFeeBasis.InfluencerAgreement_feeBasis_CUSTOM:
      return "InfluencerAgreement_feeBasis_CUSTOM";
    case EInfluencerAgreementFeeBasis.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EInfluencerAgreementRole {
  InfluencerAgreement_role_NA = 0,
  InfluencerAgreement_role_INFLUENCER = 1,
  InfluencerAgreement_role_PARTY_HIRING = 2,
  UNRECOGNIZED = -1,
}

export function eInfluencerAgreementRoleFromJSON(object: any): EInfluencerAgreementRole {
  switch (object) {
    case 0:
    case "InfluencerAgreement_role_NA":
      return EInfluencerAgreementRole.InfluencerAgreement_role_NA;
    case 1:
    case "InfluencerAgreement_role_INFLUENCER":
      return EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER;
    case 2:
    case "InfluencerAgreement_role_PARTY_HIRING":
      return EInfluencerAgreementRole.InfluencerAgreement_role_PARTY_HIRING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EInfluencerAgreementRole.UNRECOGNIZED;
  }
}

export function eInfluencerAgreementRoleToJSON(object: EInfluencerAgreementRole): string {
  switch (object) {
    case EInfluencerAgreementRole.InfluencerAgreement_role_NA:
      return "InfluencerAgreement_role_NA";
    case EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER:
      return "InfluencerAgreement_role_INFLUENCER";
    case EInfluencerAgreementRole.InfluencerAgreement_role_PARTY_HIRING:
      return "InfluencerAgreement_role_PARTY_HIRING";
    case EInfluencerAgreementRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MInfluencerAgreementCompanyEntityType {
  optionDbValue: EInfluencerAgreementCompanyEntityType;
  other: string;
}

export interface MInfluencerAgreementInvoiceFreq {
  optionDbValue: EInfluencerAgreementInvoiceFreq;
  other: string;
}

export interface MInfluencerAgreementRemainderType {
  optionDbValue: EInfluencerAgreementRemainderType;
  other: string;
}

export interface MInfluencerAgreementFeeBasis {
  optionDbValue: EInfluencerAgreementFeeBasis;
  other: string;
}

export interface MInfluencerAgreementRole {
  optionDbValue: EInfluencerAgreementRole;
  other: string;
}

export interface InfluencerAgreement {
  effectiveDate: Date | undefined;
  companyFull: string;
  companyState: string;
  companyEntityType: MInfluencerAgreementCompanyEntityType | undefined;
  consultantFull: string;
  consultantState: string;
  governLawState: string;
  arbVenue: string;
  causeTermNotice: number;
  consultantServices: string[];
  fixedFee: number;
  feeAmount: number;
  feeCustom: string;
  invoiceFreq: MInfluencerAgreementInvoiceFreq | undefined;
  remainderType: MInfluencerAgreementRemainderType | undefined;
  kickoffPayment: number;
  paymentDays: number;
  feeBasis: MInfluencerAgreementFeeBasis | undefined;
  brand: string;
  role: MInfluencerAgreementRole | undefined;
  earlyTermFee: string;
}

function createBaseMInfluencerAgreementCompanyEntityType(): MInfluencerAgreementCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MInfluencerAgreementCompanyEntityType = {
  encode(message: MInfluencerAgreementCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MInfluencerAgreementCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMInfluencerAgreementCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MInfluencerAgreementCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eInfluencerAgreementCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MInfluencerAgreementCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eInfluencerAgreementCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MInfluencerAgreementCompanyEntityType>, I>>(
    base?: I,
  ): MInfluencerAgreementCompanyEntityType {
    return MInfluencerAgreementCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MInfluencerAgreementCompanyEntityType>, I>>(
    object: I,
  ): MInfluencerAgreementCompanyEntityType {
    const message = createBaseMInfluencerAgreementCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMInfluencerAgreementInvoiceFreq(): MInfluencerAgreementInvoiceFreq {
  return { optionDbValue: 0, other: "" };
}

export const MInfluencerAgreementInvoiceFreq = {
  encode(message: MInfluencerAgreementInvoiceFreq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MInfluencerAgreementInvoiceFreq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMInfluencerAgreementInvoiceFreq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MInfluencerAgreementInvoiceFreq {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eInfluencerAgreementInvoiceFreqFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MInfluencerAgreementInvoiceFreq): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eInfluencerAgreementInvoiceFreqToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MInfluencerAgreementInvoiceFreq>, I>>(base?: I): MInfluencerAgreementInvoiceFreq {
    return MInfluencerAgreementInvoiceFreq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MInfluencerAgreementInvoiceFreq>, I>>(
    object: I,
  ): MInfluencerAgreementInvoiceFreq {
    const message = createBaseMInfluencerAgreementInvoiceFreq();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMInfluencerAgreementRemainderType(): MInfluencerAgreementRemainderType {
  return { optionDbValue: 0, other: "" };
}

export const MInfluencerAgreementRemainderType = {
  encode(message: MInfluencerAgreementRemainderType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MInfluencerAgreementRemainderType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMInfluencerAgreementRemainderType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MInfluencerAgreementRemainderType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eInfluencerAgreementRemainderTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MInfluencerAgreementRemainderType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eInfluencerAgreementRemainderTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MInfluencerAgreementRemainderType>, I>>(
    base?: I,
  ): MInfluencerAgreementRemainderType {
    return MInfluencerAgreementRemainderType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MInfluencerAgreementRemainderType>, I>>(
    object: I,
  ): MInfluencerAgreementRemainderType {
    const message = createBaseMInfluencerAgreementRemainderType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMInfluencerAgreementFeeBasis(): MInfluencerAgreementFeeBasis {
  return { optionDbValue: 0, other: "" };
}

export const MInfluencerAgreementFeeBasis = {
  encode(message: MInfluencerAgreementFeeBasis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MInfluencerAgreementFeeBasis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMInfluencerAgreementFeeBasis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MInfluencerAgreementFeeBasis {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eInfluencerAgreementFeeBasisFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MInfluencerAgreementFeeBasis): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eInfluencerAgreementFeeBasisToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MInfluencerAgreementFeeBasis>, I>>(base?: I): MInfluencerAgreementFeeBasis {
    return MInfluencerAgreementFeeBasis.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MInfluencerAgreementFeeBasis>, I>>(object: I): MInfluencerAgreementFeeBasis {
    const message = createBaseMInfluencerAgreementFeeBasis();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMInfluencerAgreementRole(): MInfluencerAgreementRole {
  return { optionDbValue: 0, other: "" };
}

export const MInfluencerAgreementRole = {
  encode(message: MInfluencerAgreementRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MInfluencerAgreementRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMInfluencerAgreementRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MInfluencerAgreementRole {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eInfluencerAgreementRoleFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MInfluencerAgreementRole): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eInfluencerAgreementRoleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MInfluencerAgreementRole>, I>>(base?: I): MInfluencerAgreementRole {
    return MInfluencerAgreementRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MInfluencerAgreementRole>, I>>(object: I): MInfluencerAgreementRole {
    const message = createBaseMInfluencerAgreementRole();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseInfluencerAgreement(): InfluencerAgreement {
  return {
    effectiveDate: undefined,
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    consultantFull: "",
    consultantState: "",
    governLawState: "",
    arbVenue: "",
    causeTermNotice: 0,
    consultantServices: [],
    fixedFee: 0,
    feeAmount: 0,
    feeCustom: "",
    invoiceFreq: undefined,
    remainderType: undefined,
    kickoffPayment: 0,
    paymentDays: 0,
    feeBasis: undefined,
    brand: "",
    role: undefined,
    earlyTermFee: "",
  };
}

export const InfluencerAgreement = {
  encode(message: InfluencerAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyFull !== "") {
      writer.uint32(18).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(26).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MInfluencerAgreementCompanyEntityType.encode(message.companyEntityType, writer.uint32(34).fork()).ldelim();
    }
    if (message.consultantFull !== "") {
      writer.uint32(42).string(message.consultantFull);
    }
    if (message.consultantState !== "") {
      writer.uint32(50).string(message.consultantState);
    }
    if (message.governLawState !== "") {
      writer.uint32(58).string(message.governLawState);
    }
    if (message.arbVenue !== "") {
      writer.uint32(66).string(message.arbVenue);
    }
    if (message.causeTermNotice !== 0) {
      writer.uint32(77).float(message.causeTermNotice);
    }
    for (const v of message.consultantServices) {
      writer.uint32(82).string(v!);
    }
    if (message.fixedFee !== 0) {
      writer.uint32(93).float(message.fixedFee);
    }
    if (message.feeAmount !== 0) {
      writer.uint32(101).float(message.feeAmount);
    }
    if (message.feeCustom !== "") {
      writer.uint32(106).string(message.feeCustom);
    }
    if (message.invoiceFreq !== undefined) {
      MInfluencerAgreementInvoiceFreq.encode(message.invoiceFreq, writer.uint32(114).fork()).ldelim();
    }
    if (message.remainderType !== undefined) {
      MInfluencerAgreementRemainderType.encode(message.remainderType, writer.uint32(122).fork()).ldelim();
    }
    if (message.kickoffPayment !== 0) {
      writer.uint32(133).float(message.kickoffPayment);
    }
    if (message.paymentDays !== 0) {
      writer.uint32(141).float(message.paymentDays);
    }
    if (message.feeBasis !== undefined) {
      MInfluencerAgreementFeeBasis.encode(message.feeBasis, writer.uint32(146).fork()).ldelim();
    }
    if (message.brand !== "") {
      writer.uint32(154).string(message.brand);
    }
    if (message.role !== undefined) {
      MInfluencerAgreementRole.encode(message.role, writer.uint32(162).fork()).ldelim();
    }
    if (message.earlyTermFee !== "") {
      writer.uint32(170).string(message.earlyTermFee);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InfluencerAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInfluencerAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.companyEntityType = MInfluencerAgreementCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.consultantFull = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.consultantState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.arbVenue = reader.string();
          continue;
        case 9:
          if (tag != 77) {
            break;
          }

          message.causeTermNotice = reader.float();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.consultantServices.push(reader.string());
          continue;
        case 11:
          if (tag != 93) {
            break;
          }

          message.fixedFee = reader.float();
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.feeAmount = reader.float();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.feeCustom = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.invoiceFreq = MInfluencerAgreementInvoiceFreq.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.remainderType = MInfluencerAgreementRemainderType.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.kickoffPayment = reader.float();
          continue;
        case 17:
          if (tag != 141) {
            break;
          }

          message.paymentDays = reader.float();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.feeBasis = MInfluencerAgreementFeeBasis.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.role = MInfluencerAgreementRole.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.earlyTermFee = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InfluencerAgreement {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MInfluencerAgreementCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      consultantFull: isSet(object.consultantFull) ? String(object.consultantFull) : "",
      consultantState: isSet(object.consultantState) ? String(object.consultantState) : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      arbVenue: isSet(object.arbVenue) ? String(object.arbVenue) : "",
      causeTermNotice: isSet(object.causeTermNotice) ? Number(object.causeTermNotice) : 0,
      consultantServices: Array.isArray(object?.consultantServices)
        ? object.consultantServices.map((e: any) => String(e))
        : [],
      fixedFee: isSet(object.fixedFee) ? Number(object.fixedFee) : 0,
      feeAmount: isSet(object.feeAmount) ? Number(object.feeAmount) : 0,
      feeCustom: isSet(object.feeCustom) ? String(object.feeCustom) : "",
      invoiceFreq: isSet(object.invoiceFreq) ? MInfluencerAgreementInvoiceFreq.fromJSON(object.invoiceFreq) : undefined,
      remainderType: isSet(object.remainderType)
        ? MInfluencerAgreementRemainderType.fromJSON(object.remainderType)
        : undefined,
      kickoffPayment: isSet(object.kickoffPayment) ? Number(object.kickoffPayment) : 0,
      paymentDays: isSet(object.paymentDays) ? Number(object.paymentDays) : 0,
      feeBasis: isSet(object.feeBasis) ? MInfluencerAgreementFeeBasis.fromJSON(object.feeBasis) : undefined,
      brand: isSet(object.brand) ? String(object.brand) : "",
      role: isSet(object.role) ? MInfluencerAgreementRole.fromJSON(object.role) : undefined,
      earlyTermFee: isSet(object.earlyTermFee) ? String(object.earlyTermFee) : "",
    };
  },

  toJSON(message: InfluencerAgreement): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MInfluencerAgreementCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.consultantFull !== undefined && (obj.consultantFull = message.consultantFull);
    message.consultantState !== undefined && (obj.consultantState = message.consultantState);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.arbVenue !== undefined && (obj.arbVenue = message.arbVenue);
    message.causeTermNotice !== undefined && (obj.causeTermNotice = message.causeTermNotice);
    if (message.consultantServices) {
      obj.consultantServices = message.consultantServices.map((e) => e);
    } else {
      obj.consultantServices = [];
    }
    message.fixedFee !== undefined && (obj.fixedFee = message.fixedFee);
    message.feeAmount !== undefined && (obj.feeAmount = message.feeAmount);
    message.feeCustom !== undefined && (obj.feeCustom = message.feeCustom);
    message.invoiceFreq !== undefined &&
      (obj.invoiceFreq = message.invoiceFreq ? MInfluencerAgreementInvoiceFreq.toJSON(message.invoiceFreq) : undefined);
    message.remainderType !== undefined && (obj.remainderType = message.remainderType
      ? MInfluencerAgreementRemainderType.toJSON(message.remainderType)
      : undefined);
    message.kickoffPayment !== undefined && (obj.kickoffPayment = message.kickoffPayment);
    message.paymentDays !== undefined && (obj.paymentDays = message.paymentDays);
    message.feeBasis !== undefined &&
      (obj.feeBasis = message.feeBasis ? MInfluencerAgreementFeeBasis.toJSON(message.feeBasis) : undefined);
    message.brand !== undefined && (obj.brand = message.brand);
    message.role !== undefined && (obj.role = message.role ? MInfluencerAgreementRole.toJSON(message.role) : undefined);
    message.earlyTermFee !== undefined && (obj.earlyTermFee = message.earlyTermFee);
    return obj;
  },

  create<I extends Exact<DeepPartial<InfluencerAgreement>, I>>(base?: I): InfluencerAgreement {
    return InfluencerAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InfluencerAgreement>, I>>(object: I): InfluencerAgreement {
    const message = createBaseInfluencerAgreement();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MInfluencerAgreementCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.consultantFull = object.consultantFull ?? "";
    message.consultantState = object.consultantState ?? "";
    message.governLawState = object.governLawState ?? "";
    message.arbVenue = object.arbVenue ?? "";
    message.causeTermNotice = object.causeTermNotice ?? 0;
    message.consultantServices = object.consultantServices?.map((e) => e) || [];
    message.fixedFee = object.fixedFee ?? 0;
    message.feeAmount = object.feeAmount ?? 0;
    message.feeCustom = object.feeCustom ?? "";
    message.invoiceFreq = (object.invoiceFreq !== undefined && object.invoiceFreq !== null)
      ? MInfluencerAgreementInvoiceFreq.fromPartial(object.invoiceFreq)
      : undefined;
    message.remainderType = (object.remainderType !== undefined && object.remainderType !== null)
      ? MInfluencerAgreementRemainderType.fromPartial(object.remainderType)
      : undefined;
    message.kickoffPayment = object.kickoffPayment ?? 0;
    message.paymentDays = object.paymentDays ?? 0;
    message.feeBasis = (object.feeBasis !== undefined && object.feeBasis !== null)
      ? MInfluencerAgreementFeeBasis.fromPartial(object.feeBasis)
      : undefined;
    message.brand = object.brand ?? "";
    message.role = (object.role !== undefined && object.role !== null)
      ? MInfluencerAgreementRole.fromPartial(object.role)
      : undefined;
    message.earlyTermFee = object.earlyTermFee ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
