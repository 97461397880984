import { useSelector } from "react-redux";
import { MainStore } from "../../store/mainTypes";
import DocsBase from "./DocsBase";

export default function InboxDocs() {
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    
    return <DocsBase relevantDocs={completedDocs}
        caption="Completed"
        noDocsMsg="You currently don't have any completed docs"
        mainCTA="View"
    />
}
