import { PlansApi } from "api-services/PlansApi";
import React, { createContext, useContext, useEffect } from "react";
import { PPlanState } from "proto/PPlanState";
import { setPlanState, setPlan } from "store/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { MainStore } from "store/mainTypes";

interface PricePlan {
    planName: string;
    monthlyPrice: number;
    yearlyPrice: number;
    saveMoreThan: string;
}

const PriceContext = createContext<PricePlan[] | null>(null);

export const usePrices = (): PricePlan[] | null => {
    return useContext(PriceContext);
};

interface PriceProviderProps {
    children: React.ReactNode;
}

export const UsageProvider: React.FC<PriceProviderProps> = ({ children }) => {
    const dispatch = useDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    useEffect(() => {
        if (dbUser && dbUser.tier) {
            Promise.all([PlansApi.getPlan(), PlansApi.getPlanState()])
                .then((responses) => {
                    dispatch(setPlan(responses[0].data));
                    dispatch(setPlanState(responses[1].data));
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [dbUser, dispatch]);

    return <>{children}</>;
};
