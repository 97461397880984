import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MainStore } from "store/mainTypes";

export const ReferralProgram = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const link =
        "https://clawdia.ai?utm_campaign=" +
        encodeURIComponent(dbUser?.email || "") +
        "&utm_medium=referral&utm_source=friends";

    return (
        <div className="relative py-3 w-full mx-auto">
            <div className="absolute inset-0 bg-gradient-to-r from-iceBlue to-stroke1 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl"></div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14">
                <p className="text-2xl text-mainAccent text-center mb-6">
                    Welcome to the Clawdia AI Referral Program!
                </p>
                <div className="">
                    <p className="mt-2 text-base">
                        We're glad you've decided to help other businesses by sharing our services.
                        For every new client you refer to us who purchases a service,{" "}
                        <span className="font-semibold">we'll reward you with $200!</span>
                        <br />
                        <br />
                        <span className="font-semibold">It's simple:</span> share this unique
                        referral link with friends, colleagues, or other business owners, and watch
                        your rewards grow.
                    </p>
                    <div className="mt-4 flex items-center justify-center">
                        <input
                            ref={inputRef}
                            type="text"
                            value={link}
                            readOnly
                            className="bg-gray-200 text-gray-700 w-full pl-4 overflow-ellipsis py-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                        />
                        <CopyButton textToCopy={link} />
                    </div>
                    <p className="mt-4 text-lg">
                        Together, we'll help businesses thrive with the right legal protection.
                    </p>
                    <hr className="mt-4 border-t-2 border-stroke1" />
                    <p className="mt-4 text-sm">
                        Disclaimer: We reserve the right to change or terminate this Referral
                        Program and its terms at any time. Misuse of the program may result in
                        disqualification and forfeiture of rewards. By participating in the Referral
                        Program, you agree to abide by these terms and any changes that may be made.
                    </p>
                </div>
            </div>
        </div>
    );
};

const CopyButton = ({ textToCopy }: { textToCopy: string }) => {
    const [buttonText, setButtonText] = useState("Copy");

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setButtonText("Copied");
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const resetButtonText = () => {
        setTimeout(() => {
            setButtonText("Copy");
        }, 1000);
    };

    return (
        <button
            className="bg-primary hover:bg-primaryHover text-white font-medium py-2 w-24 rounded-r-lg"
            onClick={() => {
                copyToClipboard();
                resetButtonText();
            }}
        >
            {buttonText}
        </button>
    );
};
