import { postHelpInWizard } from 'api-services/TrackApi'
import clsx from 'clsx'
import { PHelpInWizard } from 'proto/PTracking'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { callDialog } from 'store/mainActions'
import { DialogType } from 'store/mainTypes'
import Modal from '../Modal'

export default function HelpInWizard() {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const locationWithoutHelp = location.pathname.split("/").slice(0, -1).join("/")
    const [textareContent, setTextareaContent] = useState<string>("")

    return (
        <Modal closeable={true} closeUrl={locationWithoutHelp}>
            <div className="mt-8 px-10">
                <p className="text-xl mb-4 font-bold text-secondary text-center">Need help with this question or any other?<br/>Clawdia is here to help!</p>

                <div className='text-sm'>
                    <p>Need more options and questions?</p>
                    <p>Found a mistake?</p>
                    <p>Something isn't clear? Need more explanations?</p>
                    <br />
                    <p><strong>Whatever your issue is - please let us know.</strong></p>
                    <p>We will do our best to answer your issue and try to fix it within 24 hours.</p>
                    <p>Please provide as many details as you can and we will get back to you ASAP.</p>
                </div>

                <form className="flex flex-col mt-8" onSubmit={(e) => {alert("ok")}}>
                    <textarea rows={8} cols={20} className="p-2 resize-none border border-accent" autoFocus={true} onChange={e => setTextareaContent(e.target.value)}>
                    </textarea>

                    <button onClick={
                        () => {
                            let helpRequest = { text: textareContent, url: location.pathname } as PHelpInWizard
                            postHelpInWizard(helpRequest, async () => {
                                dispatch(callDialog(DialogType.SUCCESS, "Successfully alerted the Clawdia team. We will get back to you ASAP.", () => {
                                    history.push(locationWithoutHelp)
                                }))
                            })
                        }}
                        disabled={textareContent.length == 0}
                        type="submit" className={clsx("mt-4 mb-4 text-white bg-primary w-1/2 self-center px-4 py-3 rounded-lg font-bold",
                            { "bg-grayAccent": textareContent.length == 0 }
                        )}>Submit</button>
                </form >
            </div>
        </Modal >
    )
}
