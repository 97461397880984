/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum PLegalTopics {
  INCORPORATION = 0,
  ADVISORY_AGREEMENT = 1,
  NDA = 2,
  EMPLOYMENT_AGREEMENTS = 3,
  SAFE_INVEST = 4,
  PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS = 5,
  UNRECOGNIZED = -1,
}

export function pLegalTopicsFromJSON(object: any): PLegalTopics {
  switch (object) {
    case 0:
    case "INCORPORATION":
      return PLegalTopics.INCORPORATION;
    case 1:
    case "ADVISORY_AGREEMENT":
      return PLegalTopics.ADVISORY_AGREEMENT;
    case 2:
    case "NDA":
      return PLegalTopics.NDA;
    case 3:
    case "EMPLOYMENT_AGREEMENTS":
      return PLegalTopics.EMPLOYMENT_AGREEMENTS;
    case 4:
    case "SAFE_INVEST":
      return PLegalTopics.SAFE_INVEST;
    case 5:
    case "PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS":
      return PLegalTopics.PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PLegalTopics.UNRECOGNIZED;
  }
}

export function pLegalTopicsToJSON(object: PLegalTopics): string {
  switch (object) {
    case PLegalTopics.INCORPORATION:
      return "INCORPORATION";
    case PLegalTopics.ADVISORY_AGREEMENT:
      return "ADVISORY_AGREEMENT";
    case PLegalTopics.NDA:
      return "NDA";
    case PLegalTopics.EMPLOYMENT_AGREEMENTS:
      return "EMPLOYMENT_AGREEMENTS";
    case PLegalTopics.SAFE_INVEST:
      return "SAFE_INVEST";
    case PLegalTopics.PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS:
      return "PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS";
    case PLegalTopics.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PNumericStats {
  totalSignups: number;
  completedDocs: number;
  unqiueUsersCompletedDocs: number;
  usersBought: number;
  activeUsersWithSubscription: number;
  refundedUsers: number;
}

export interface PLegalTopicsSurvey {
  email: string;
  legalTopics: PLegalTopics[];
  other: string;
}

export interface PHelpInWizard {
  email: string;
  timeEpoch: number;
  url: string;
  text: string;
}

function createBasePNumericStats(): PNumericStats {
  return {
    totalSignups: 0,
    completedDocs: 0,
    unqiueUsersCompletedDocs: 0,
    usersBought: 0,
    activeUsersWithSubscription: 0,
    refundedUsers: 0,
  };
}

export const PNumericStats = {
  encode(message: PNumericStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.totalSignups !== 0) {
      writer.uint32(8).int64(message.totalSignups);
    }
    if (message.completedDocs !== 0) {
      writer.uint32(16).int64(message.completedDocs);
    }
    if (message.unqiueUsersCompletedDocs !== 0) {
      writer.uint32(24).int64(message.unqiueUsersCompletedDocs);
    }
    if (message.usersBought !== 0) {
      writer.uint32(32).int64(message.usersBought);
    }
    if (message.activeUsersWithSubscription !== 0) {
      writer.uint32(40).int64(message.activeUsersWithSubscription);
    }
    if (message.refundedUsers !== 0) {
      writer.uint32(48).int64(message.refundedUsers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PNumericStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePNumericStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.totalSignups = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.completedDocs = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.unqiueUsersCompletedDocs = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.usersBought = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.activeUsersWithSubscription = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag != 48) {
            break;
          }

          message.refundedUsers = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PNumericStats {
    return {
      totalSignups: isSet(object.totalSignups) ? Number(object.totalSignups) : 0,
      completedDocs: isSet(object.completedDocs) ? Number(object.completedDocs) : 0,
      unqiueUsersCompletedDocs: isSet(object.unqiueUsersCompletedDocs) ? Number(object.unqiueUsersCompletedDocs) : 0,
      usersBought: isSet(object.usersBought) ? Number(object.usersBought) : 0,
      activeUsersWithSubscription: isSet(object.activeUsersWithSubscription)
        ? Number(object.activeUsersWithSubscription)
        : 0,
      refundedUsers: isSet(object.refundedUsers) ? Number(object.refundedUsers) : 0,
    };
  },

  toJSON(message: PNumericStats): unknown {
    const obj: any = {};
    message.totalSignups !== undefined && (obj.totalSignups = Math.round(message.totalSignups));
    message.completedDocs !== undefined && (obj.completedDocs = Math.round(message.completedDocs));
    message.unqiueUsersCompletedDocs !== undefined &&
      (obj.unqiueUsersCompletedDocs = Math.round(message.unqiueUsersCompletedDocs));
    message.usersBought !== undefined && (obj.usersBought = Math.round(message.usersBought));
    message.activeUsersWithSubscription !== undefined &&
      (obj.activeUsersWithSubscription = Math.round(message.activeUsersWithSubscription));
    message.refundedUsers !== undefined && (obj.refundedUsers = Math.round(message.refundedUsers));
    return obj;
  },

  create<I extends Exact<DeepPartial<PNumericStats>, I>>(base?: I): PNumericStats {
    return PNumericStats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PNumericStats>, I>>(object: I): PNumericStats {
    const message = createBasePNumericStats();
    message.totalSignups = object.totalSignups ?? 0;
    message.completedDocs = object.completedDocs ?? 0;
    message.unqiueUsersCompletedDocs = object.unqiueUsersCompletedDocs ?? 0;
    message.usersBought = object.usersBought ?? 0;
    message.activeUsersWithSubscription = object.activeUsersWithSubscription ?? 0;
    message.refundedUsers = object.refundedUsers ?? 0;
    return message;
  },
};

function createBasePLegalTopicsSurvey(): PLegalTopicsSurvey {
  return { email: "", legalTopics: [], other: "" };
}

export const PLegalTopicsSurvey = {
  encode(message: PLegalTopicsSurvey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    writer.uint32(18).fork();
    for (const v of message.legalTopics) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(26).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PLegalTopicsSurvey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePLegalTopicsSurvey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag == 16) {
            message.legalTopics.push(reader.int32() as any);
            continue;
          }

          if (tag == 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.legalTopics.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag != 26) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PLegalTopicsSurvey {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      legalTopics: Array.isArray(object?.legalTopics)
        ? object.legalTopics.map((e: any) => pLegalTopicsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: PLegalTopicsSurvey): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    if (message.legalTopics) {
      obj.legalTopics = message.legalTopics.map((e) => pLegalTopicsToJSON(e));
    } else {
      obj.legalTopics = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<PLegalTopicsSurvey>, I>>(base?: I): PLegalTopicsSurvey {
    return PLegalTopicsSurvey.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PLegalTopicsSurvey>, I>>(object: I): PLegalTopicsSurvey {
    const message = createBasePLegalTopicsSurvey();
    message.email = object.email ?? "";
    message.legalTopics = object.legalTopics?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBasePHelpInWizard(): PHelpInWizard {
  return { email: "", timeEpoch: 0, url: "", text: "" };
}

export const PHelpInWizard = {
  encode(message: PHelpInWizard, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.timeEpoch !== 0) {
      writer.uint32(16).int64(message.timeEpoch);
    }
    if (message.url !== "") {
      writer.uint32(26).string(message.url);
    }
    if (message.text !== "") {
      writer.uint32(34).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PHelpInWizard {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePHelpInWizard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.timeEpoch = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PHelpInWizard {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      timeEpoch: isSet(object.timeEpoch) ? Number(object.timeEpoch) : 0,
      url: isSet(object.url) ? String(object.url) : "",
      text: isSet(object.text) ? String(object.text) : "",
    };
  },

  toJSON(message: PHelpInWizard): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.timeEpoch !== undefined && (obj.timeEpoch = Math.round(message.timeEpoch));
    message.url !== undefined && (obj.url = message.url);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<PHelpInWizard>, I>>(base?: I): PHelpInWizard {
    return PHelpInWizard.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PHelpInWizard>, I>>(object: I): PHelpInWizard {
    const message = createBasePHelpInWizard();
    message.email = object.email ?? "";
    message.timeEpoch = object.timeEpoch ?? 0;
    message.url = object.url ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
