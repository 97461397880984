import { documentToName } from "components/ContractChooser";
import { PDoc } from "proto/PDoc";

interface Props {
    doc: PDoc;
}
export const DocumentName = (props: Props) => {
    const { doc } = props;
    const docName = documentToName(doc);

    return (
        <div data-name="DocumentName" className="grid">
            <p
                title={docName}
                className="text-darkIndigo text-xl xl:text-2_5xl font-medium mt-2 xl:mt-0 overflow-ellipsis overflow-hidden whitespace-nowrap"
            >
                {docName}
            </p>
        </div>
    );
};
