import { KBNew, Layers } from "apps/legal-ide/features";
import { DecisionSupportingTools } from "apps/legal-ide/features/DecisionSupportingTools";
import DecisionSupportingToolOptionHint from "apps/legal-ide/features/DecisionSupportingTools/DecisionSupportingToolOptionHint";
import clsx from "clsx";
import { formatAddress } from "common";
import {
    Alert,
    Button,
    Spinner,
    SvgIconBack,
    SvgIconForward,
    SvgResetIcon,
    Toggle,
} from "components";
import "components/0_common/Scrollbar/Scrollbar.scss";
import { specificContractType } from "components/ContractChooser";
import { Select } from "components/Select/Select";
import ABTesting, { AB_TEST_NAMES } from "components/utils/ABTesting";
import { DropDownStates } from "components/utils/DropDownStates";
import { arrayBufferToBase64, capitalizeFirstLetter } from "components/utils/UtilFunctions";
import FinishDraft from "components/wizard-display/FinishDraft";
import { FinishDraftMessage } from "components/wizard-display/FinishDraftMessage";
import { SELECT_CUSTOM_OTHER_DB_VALUE } from "consts";
import { Location } from "history";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import parse from "html-react-parser";
import ClawdiaHead2 from "img/clawdia-head2.png";
import VideoIconBlack from "img/icons/video-black.png";
import jsonLogic from "json-logic-js";
import { InternalBoolean, PFile } from "proto/contracts/PUtils";
import { PLegalUnit } from "proto/PLegalUnit";
import React, { useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Actions, useAppSelector } from "store";
import { playVideo, setIsDocPreviewOpen } from "store/mainActions";
import { MainStore } from "store/mainTypes";
import { getSearchParams } from "utils";
import {
    adjustApostropheForPluralVsSingularInWizard,
    jsonToCompoundJsonLogic,
} from "../../Editor/customElements";
import { FieldType } from "../../Editor/types/custom-types";
import { VideosCollection } from "../QuestionWizard/VideosCollection";
import { AgreementContext } from "./AgreementContext/AgreementContext";
import { DocsApi } from "api-services/DocsApi";
import { usePlanState } from "hooks/usePlanState";

export function conditionToJsonLogic(
    condition?: CompoundJsonLogic | JsonLogic,
    allFields?: Field[],
    data?: any
) {
    const compoundCondition = toCompoundJsonLogic(condition);

    if (!compoundCondition || !compoundCondition?.concatOperator) return true;

    const jsonLogicValue = jsonToCompoundJsonLogic(compoundCondition, allFields);

    const isValid = jsonLogic.apply(jsonLogicValue, data);

    return isValid;
}

export function toCompoundJsonLogic(condition: JsonLogic | CompoundJsonLogic | undefined) {
    if (!condition) return undefined;

    let compound: CompoundJsonLogic;

    if ((condition as any).fieldId !== undefined) {
        compound = {} as CompoundJsonLogic;
        compound.conditions = [condition as JsonLogic];
        compound.concatOperator = "AND";
    } else {
        compound = condition as CompoundJsonLogic;
    }

    return compound;
}

// todo - NumberInput should be an all powerful util
function adjustFormatValLength(formattedVal: string) {
    const numberOfCommas = (formattedVal.length + 2) / 3;

    return 0.5 + formattedVal.length - 0.5 * numberOfCommas + "rem";
}

// service_provider/23/A/1

export function WizardForm(props: {
    children: JSX.Element | JSX.Element[] | undefined;
    wizard?: Wizard;
    stepQuestions?: Question[];
    agreement: Agreement;
}) {
    const { stepQuestions = [], agreement } = props;

    const agreementContext = useContext(AgreementContext)!;

    const activeDraft = agreementContext.getActiveDraft();

    const notCompletedSteps = agreementContext.wizardApi.getNotCompletedSteps();

    const dispatch = useDispatch();
    const history = useHistory();
    const formRef = useRef<HTMLFormElement>(null);

    const { isDocumentLocked } = usePlanState();
    const isFormDisabled = isDocumentLocked(activeDraft?.id!);

    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    let isAllDoneCompletley = agreementContext.wizardApi.isWizardCompleted();

    const sec = agreementContext.wizardApi.getCurrentSection();
    const step = agreementContext.navigation.getCurrentStep();

    const sectionAlpha = agreementContext.navigation.getActiveSectionAlpa();

    const isLast = agreementContext.navigation.isLastStep();
    const isVideoIntro = sectionAlpha === "intro";

    const isDocPreviewOpen = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.isDocPreviewOpen
    );

    const he = agreement.lang === "HE";

    useEffect(() => {
        if (isVideoIntro && agreement.introVideo?.urlRef) {
            history.push("../A/1");
        }

        const listener = (e: KeyboardEvent) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                formRef?.current?.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
            }
        };

        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, []);

    if (!activeDraft?.legalUnit) {
        return (
            <div className="m-auto">
                <Spinner />
            </div>
        );
    }

    if (agreementContext.navigation.isFinishStep()) {
        if (!isAllDoneCompletley) return <FinishDraftMessage steps={notCompletedSteps} />;
        return <FinishDraft />;
    }

    let stepDone = !step ? false : agreementContext!.wizardApi.isStepCompleted(step);

    return (
        <div className="flex flex-col gap-6 mt-4 justify-between p-4 flex-grow flex-shrink-0 lg:max-h-[85vh] lg:overflow-auto _ScrollbarNarrow">
            {props.children && <div>{props.children}</div>}
            <div className="self-start flex-grow w-full">
                {isVideoIntro ? (
                    <p className="text-gray font-bold text-lg">{agreement.introVideo?.title}</p>
                ) : (
                    <div className="flex flex-row items-center justify-between w-full">
                        <p className="text-darkIndigo font-medium text-lg">
                            <span className="text-primary">{sectionAlpha}. </span>
                            {sec?.name}
                        </p>
                        <div className="hidden lg:flex gap-2 items-center">
                            <p className="text-[0.8125rem]">{!he ? "preview" : "תצוגה מקדימה"}</p>
                            <Toggle
                                onChange={() => {
                                    dispatch(setIsDocPreviewOpen(!isDocPreviewOpen));
                                }}
                                open={isDocPreviewOpen}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col flex-grow">
                <form
                    ref={formRef}
                    onSubmit={(e) => {
                        e.preventDefault();

                        step?.questions.forEach((question) => {
                            // if (question.onNext) {
                            //     question.onNext();
                            // }
                        });

                        if (isVideoIntro) {
                            history.push("../A/1");
                            return;
                        }

                        if (!step) {
                            return;
                        }

                        if (!agreementContext.wizardApi.isStepCompleted(step)) {
                            return;
                        }

                        if (isLast) {
                            history.push("../finish/1");
                            return;
                        }

                        agreementContext.navigation.onNextStep();
                    }}
                >
                    <>
                        <div className="flex flex-col gap-4">
                            {stepQuestions.map((q) => {
                                return (
                                    <div className="DisplayOptions" key={q.id}>
                                        {!!q.kb && (
                                            <Alert type="knowledge" variant="contained">
                                                {q.kb}
                                            </Alert>
                                        )}

                                        <QuestionDisplay
                                            key={q.fieldId}
                                            question={q}
                                            legalUnit={activeDraft.legalUnit}
                                            agreement={agreement}
                                            isDisabled={isFormDisabled}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="mt-10 flex justify-between flex-col-reverse gap-4 sm:flex-row sm:gap-0 items-center pb-8">
                            <Button
                                color="skyBlue"
                                type="button"
                                onClick={agreementContext.navigation.onBack}
                                variant="outlined"
                            >
                                <div className="rtl:-scale-x-100">
                                    <SvgIconBack />
                                </div>
                                <span>{!he ? "Back" : "אחורה"}</span>
                            </Button>

                            {(stepDone || isLast) && (
                                <>
                                    {stepDone && !isFormDisabled && (
                                        <p
                                            className="text-sm text-center text-paleLilac cursor-pointer"
                                            onClick={() => {
                                                step?.questions?.forEach((q) => {
                                                    const question =
                                                        currentAgreement?.wizard.questions.byId[q]!;
                                                    const field = currentAgreement?.fields.find(
                                                        (f) => f.id === question?.fieldId
                                                    );

                                                    let fieldType = field?.type;

                                                    let defaultValue = null;

                                                    switch (fieldType) {
                                                        case FieldType.BOOL:
                                                            defaultValue = 0;
                                                            break;
                                                        case FieldType.DATE:
                                                            defaultValue = undefined;
                                                            break;
                                                        case FieldType.NUMBER:
                                                            defaultValue = 0;
                                                            break;
                                                        case FieldType.STRING:
                                                            defaultValue = "";
                                                            break;
                                                        case FieldType.ADDRESS:
                                                            defaultValue = "";
                                                            break;
                                                        case FieldType.GOVERNING_LAW_STATE:
                                                            defaultValue = "";
                                                            break;
                                                        case FieldType.ENUM:
                                                            defaultValue = 0;
                                                            break;
                                                        case FieldType.MULTI_ENUM:
                                                            defaultValue = {
                                                                optionDbValue: [],
                                                                other: "",
                                                            };
                                                            break;
                                                        case FieldType.LINK:
                                                            defaultValue = "";
                                                            break;
                                                        case FieldType.ARRAY:
                                                            defaultValue = [];
                                                            break;
                                                        case FieldType.EMBEDDED_FILE:
                                                            defaultValue = {};
                                                            break;
                                                        case FieldType.TABLE:
                                                            defaultValue = null;
                                                            break;
                                                        default:
                                                            defaultValue = undefined;
                                                            break;
                                                    }

                                                    agreementContext.handleAnswer({
                                                        question,
                                                        value: defaultValue,
                                                    });
                                                });
                                            }}
                                        >
                                            <span className="flex flex-row rtl:flex-row-reverse items-center justify-center gap-1 text-darkIndigo cursor-pointer">
                                                <span>{!he ? "Reset" : "איפוס"}</span>
                                                <SvgResetIcon />
                                            </span>
                                        </p>
                                    )}

                                    {stepDone && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={false}
                                        >
                                            <span>{!he ? "Next" : "הבא"}</span>
                                            <div className="rtl:-scale-x-100">
                                                <SvgIconForward />
                                            </div>
                                        </Button>
                                    )}
                                </>
                            )}

                            {!stepDone && !isVideoIntro && (
                                <>
                                    {!isLast && (
                                        <p
                                            className="text-sm text-center text-darkIndigo cursor-pointer"
                                            onClick={agreementContext.navigation.onNextStep}
                                        >
                                            <span>{!he ? "Skip for now" : "דילוג"}</span>
                                        </p>
                                    )}

                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled
                                    >
                                        <span>{!he ? "Next" : "הבא"}</span>
                                        <div className="rtl:-scale-x-100">
                                            <SvgIconForward />
                                        </div>
                                    </Button>
                                </>
                            )}
                        </div>
                    </>
                </form>
            </div>
            {/* <div className="mt-8 mb-8 text-primaryLight text-sm text-center font-medium">
                <hr className="mb-2" />
                <u
                    className="cursor-pointer"
                    onClick={() => {
                        history.push(location.pathname + "/help");

                        if (isProd()) {
                            mixpanel.track("help-button-pressed");
                        }
                    }}
                >
                    <p>Need help?</p>
                </u>
            </div> */}
        </div>
    );
}

function QuestionDisplay(props: {
    legalUnit?: PLegalUnit;
    question: Question;
    agreement: Agreement;
    isDisabled?: boolean;
}) {
    const { agreement, question, isDisabled } = props;
    const field = agreement.fields.find((f) => f.id === question.fieldId);

    return (
        <div id="QuestionDisplay">
            {!!props.question && (
                <ABTesting
                    componentA={
                        <div className="flex">
                            <p className="text-lg font-medium flex">{parse(question.text)}</p>
                            <div className="flex justify-center gap-2 ml-4">
                                <VideoForQuestion
                                    videoUrl={
                                        VideosCollection[
                                            props.question.video as keyof typeof VideosCollection
                                        ]
                                    }
                                />
                            </div>
                        </div>
                    }
                    componentB={
                        question.text ? (
                            <div className="flex gap-2 bg-stroke2 rounded-xl py-1">
                                <img
                                    className="w-14 h-14 self-end -mb-1 rounded-bl-xl"
                                    src={ClawdiaHead2}
                                />
                                <p className="text-lg font-medium flex">
                                    Q: {parse(question.text)}
                                </p>
                                <div className="flex justify-center gap-2 ml-4">
                                    <VideoForQuestion
                                        videoUrl={
                                            VideosCollection[
                                                props.question
                                                    .video as keyof typeof VideosCollection
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div />
                        )
                    }
                    isSticky={true}
                    firstVariantProbability={0.75}
                    testName={AB_TEST_NAMES.TEST_1__MORE_CLAWDIA_IMAGES}
                />
            )}

            <PerTypeInput {...props} isDisabled={isDisabled} field={field} agreement={agreement} />
        </div>
    );
}
function PerTypeInput(props: {
    legalUnit?: PLegalUnit;
    question: Question;
    field?: Field;
    agreement: Agreement;
    isDisabled?: boolean;
}) {
    const { field, agreement, question, isDisabled } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const isMobile = useCheckMobileScreen();
    const agreementContext = useContext(AgreementContext)!;
    const activeDraft = agreementContext.getActiveDraft();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const value = agreementContext!.wizardApi.getQuestionValue(question);

    const questionIndex = agreementContext.wizardApi.getQuestionIndexInStep(question);
    const isFirstQuestionInStep = questionIndex === 0;

    const searchParams = getSearchParams();

    const activeField = searchParams.get("field");
    const isActive = field?.name === activeField;

    const hasFocus = !isMobile && (activeField ? isActive : isFirstQuestionInStep);

    const myRef = React.createRef<HTMLInputElement>();
    const textAreaRef = React.createRef<HTMLTextAreaElement>();

    let fieldType = Number(field?.type);

    const currentContract = agreementContext?.wizardApi.getCurrentContract(activeDraft);

    const he = agreement.lang === "HE";

    useEffect(() => {
        hasFocus && myRef.current?.focus();
    }, [hasFocus]);

    if (fieldType == FieldType.TABLE) {
        return (
            <div className="">
                <Button
                    onClick={() =>
                        dispatch(
                            Actions.ui.openModal({
                                name: "FillTableModal",
                                data: {
                                    field,
                                    value,
                                    onSave: (newValue: any) => {
                                        agreementContext?.handleAnswer({
                                            question,
                                            value: newValue,
                                        });
                                    },
                                },
                            })
                        )
                    }
                    variant="contained"
                    className="w-full"
                    color="primary"
                    disabled={isDisabled}
                >
                    Fill table
                </Button>
            </div>
        );
    }

    if (fieldType === FieldType.STRING || fieldType === FieldType.LINK) {
        // todo
        return (
            <input
                type="text"
                ref={myRef}
                disabled={isDisabled}
                autoFocus={hasFocus}
                className="w-full mt-6 text-lg rounded-md border py-4 px-4 caret-primary border-paleLilac focus:outline-primary"
                onChange={() => {
                    // TODO: update only client store
                    agreementContext?.handleAnswer({
                        question,
                        value: myRef?.current?.value,
                    });
                }}
                onBlur={() => {
                    if (value !== myRef?.current?.value) {
                        // todo: may need better solution
                        agreementContext?.handleAnswer({ question, value: myRef?.current?.value });
                    }
                    DecisionSupportingTools.onQuestionFilled(
                        question,
                        value,
                        activeDraft,
                        dispatch,
                        agreement
                    );
                }}
                required={true}
                value={value || ""} //todo
            />
        );
    }
    if (fieldType === FieldType.LONG_STRING) {
        return (
            <textarea
                ref={textAreaRef}
                disabled={isDisabled}
                rows={3}
                autoFocus={hasFocus}
                className="w-full mt-6 text-lg rounded-md border py-4 px-4 caret-primary border-paleLilac focus:outline-primary resize-none"
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.stopPropagation();
                    }
                }}
                onChange={(e) => {
                    e.preventDefault();
                    // TODO: update only client store
                    agreementContext?.handleAnswer({
                        question,
                        value: e.target.value,
                    });
                }}
                onBlur={() => {
                    agreementContext?.handleAnswer({
                        question,
                        value: textAreaRef?.current?.value,
                    });
                }}
                required={true}
                value={value || ""} //todo
            />
        );
    } else if (fieldType === FieldType.ENUM) {
        let options = field?.options || [];

        const enumValue = value ?? { optionDbValue: 0, other: "" };

        if (!field?.selectProperties?.isDropdown) {
            return (
                <>
                    <EnumButtons
                        q={props.question}
                        value={enumValue.optionDbValue}
                        disabled={isDisabled}
                        options={options.filter((o) =>
                            conditionToJsonLogic(o.condition, agreement?.fields, currentContract)
                        )}
                        onChange={(newValue: any) => {
                            agreementContext!.handleAnswer({
                                value: { ...enumValue, optionDbValue: newValue },
                                question: question,
                            });
                        }}
                    />
                    {enumValue?.optionDbValue === SELECT_CUSTOM_OTHER_DB_VALUE && (
                        <input
                            type="text"
                            ref={myRef}
                            autoFocus={hasFocus}
                            disabled={isDisabled}
                            className="w-full mt-6 text-lg rounded-md border py-4 px-4 border-paleLilac focus:outline-primary"
                            onChange={() => {
                                // TODO: update only client store
                                agreementContext!.handleAnswer({
                                    value: { ...enumValue, other: myRef?.current?.value },
                                    question: question,
                                });
                            }}
                            required={true}
                            value={enumValue.other} //todo
                        />
                    )}
                </>
            );
        } else {
            return (
                <>
                    <Select
                        className="my-1 w-2/3 bg-white mr-2 px-4 border rounded-lg text-xl
                outline outline-gray-100 focus:caret-primary focus:outline-primary h-16 mt-6"
                        name={question.id}
                        disabled={isDisabled}
                        required={true}
                        autoFocus={hasFocus}
                        value={
                            field?.options?.find((o) => o.dbValue === enumValue?.optionDbValue)
                                ?.value || 0
                        }
                        options={options
                            .map((o) => ({ ...o, label: formatOption(o.label) }))
                            .filter((o) =>
                                conditionToJsonLogic(
                                    o.condition,
                                    agreement?.fields,
                                    currentContract
                                )
                            )}
                        onChange={(_, val: any) => {
                            agreementContext!.handleAnswer({
                                value: {
                                    ...enumValue,
                                    optionDbValue: field?.options?.find((o) => o.value === val)
                                        ?.dbValue,
                                },
                                question: question,
                            });
                        }}
                    />
                    {enumValue?.optionDbValue === SELECT_CUSTOM_OTHER_DB_VALUE && (
                        <input
                            type="text"
                            ref={myRef}
                            autoFocus={hasFocus}
                            disabled={isDisabled}
                            className="w-full mt-6 text-lg rounded-md border py-4 px-4 border-paleLilac focus:outline-primary"
                            onChange={() => {
                                // TODO: update only client store
                                agreementContext!.handleAnswer({
                                    value: { ...enumValue, other: myRef?.current?.value },
                                    question: question,
                                });
                            }}
                            required={true}
                            value={enumValue.other} //todo
                        />
                    )}
                </>
            );
        }
    } else if (fieldType === FieldType.MULTI_ENUM) {
        const enumValue = value ?? { optionDbValue: [], other: "" };

        return (
            <>
                <MultiEnumButtons
                    q={props.question}
                    value={enumValue.optionDbValue}
                    disabled={isDisabled}
                    options={
                        field?.options?.filter((o) =>
                            conditionToJsonLogic(o.condition, agreement?.fields, currentContract)
                        ) || []
                    }
                    onChange={(value: any) => {
                        agreementContext!.handleAnswer({
                            value: { ...enumValue, optionDbValue: value },
                            question: question,
                        });
                    }}
                />
                {enumValue?.optionDbValue?.includes(SELECT_CUSTOM_OTHER_DB_VALUE) && (
                    <input
                        type="text"
                        ref={myRef}
                        autoFocus={hasFocus}
                        disabled={isDisabled}
                        className="w-full mt-6 text-lg rounded-md border py-4 px-4 border-paleLilac focus:outline-primary"
                        onChange={() => {
                            // TODO: update only client store
                            agreementContext!.handleAnswer({
                                value: { ...enumValue, other: myRef?.current?.value },
                                question: question,
                            });
                        }}
                        required={true}
                        value={enumValue.other} //todo
                    />
                )}
            </>
        );
    } else if (fieldType === FieldType.BOOL) {
        return (
            <div className="w-full flex justify-between gap-2 mt-8">
                {[InternalBoolean.YES, InternalBoolean.NO].map((boolVal: InternalBoolean) => (
                    <button
                        type="button"
                        disabled={isDisabled}
                        onClick={() => {
                            agreementContext!.handleAnswer({ question, value: boolVal });
                        }}
                        className={clsx(
                            "flex-grow border-2 rounded-lg py-4 text-xl font-medium text-primary",
                            {
                                "border-primary text-primary bg-lightSkyBlue": value === boolVal,
                                "border-paleLilac": value !== boolVal,
                                "cursor-not-allowed": isDisabled,
                            }
                        )}
                    >
                        {boolVal === InternalBoolean.YES ? (!he ? "YES" : "כן") : !he ? "NO" : "לא"}
                    </button>
                ))}
            </div>
        );
    } else if (fieldType === FieldType.NUMBER) {
        let tempVal = value + "";
        tempVal = tempVal?.replaceAll(",", "");
        const numVal = parseFloat(tempVal);
        let formattedVal = isNaN(numVal) ? "" : numVal.toLocaleString();

        // TODO: can be improved
        if (tempVal.endsWith(".")) {
            formattedVal += ".";
        }

        return (
            <div className="flex relative">
                <span
                    onClick={() => {
                        myRef.current?.focus();
                    }}
                    className="absolute ml-5 mt-[2.35rem] text-lg text-gray-400"
                >
                    {field?.numberProperties?.prefixSymbol}
                </span>
                <input
                    type="text"
                    ref={myRef}
                    autoFocus={hasFocus}
                    disabled={isDisabled}
                    inputMode="numeric"
                    className={clsx(
                        "w-full mt-5 text-lg rounded-md border py-4 border-paleLilac focus:outline-primary",
                        {
                            "pl-8": !!field?.numberProperties?.prefixSymbol,
                            "pl-4": !field?.numberProperties?.prefixSymbol,
                        }
                    )}
                    onChange={() => {
                        let originalVal = myRef?.current?.value;

                        let val = originalVal?.replaceAll(",", "");

                        if (field?.numberProperties?.maxValue !== undefined) {
                            if (parseFloat(val || "0") > field?.numberProperties?.maxValue) {
                                val = "" + field?.numberProperties?.maxValue;
                            }
                        }

                        if (field?.numberProperties?.minValue !== undefined) {
                            if (parseFloat(val || "0") < field?.numberProperties?.minValue) {
                                val = "" + field?.numberProperties?.minValue;
                            }
                        }

                        if (
                            (!isNaN(parseFloat(val || "0")) &&
                                parseFloat(val || "") + "" === val) ||
                            val === "" ||
                            val?.endsWith(".")
                        ) {
                            if (val === "0") {
                                val = "0.0000000000000000000000000000000000000000001";
                            }

                            if (originalVal?.endsWith(".")) {
                                val += ".";
                            }

                            agreementContext?.handleAnswer({ question, value: val || undefined });
                        }
                    }}
                    required={true}
                    value={formattedVal}
                ></input>
                <span
                    onClick={() => {
                        myRef.current?.focus();
                    }}
                    className="absolute ml-5 mt-[2.35rem] text-lg text-gray-400"
                    style={{ paddingLeft: adjustFormatValLength(formattedVal) }}
                >
                    {adjustApostropheForPluralVsSingularInWizard(
                        field?.numberProperties?.suffixSymbol
                    )}
                </span>
            </div>
        );
    } else if (fieldType === FieldType.DATE) {
        return (
            <DatePicker
                scrollableYearDropdown
                showYearDropdown
                disabled={isDisabled}
                autoFocus={hasFocus}
                onChange={() => {}}
                onChangeRaw={(e) => e.preventDefault()}
                dateFormat={"yyyy-MM-dd"}
                className="w-full mt-6 text-lg rounded-md border py-4 px-4 border-paleLilac focus:outline-primary"
                selected={value}
                onSelect={(date) => {
                    agreementContext!.handleAnswer({ question, value: date });
                }}
                showTimeSelect={false}
            />
        );
    } else if (fieldType === FieldType.ADDRESS) {
        let addressValue = formatAddress(value);

        return (
            <div className="flex flex-col">
                <input
                    type="text"
                    ref={myRef}
                    autoFocus={hasFocus}
                    disabled={isDisabled}
                    className="w-full mt-6 text-lg rounded-md border py-4 px-4 caret-primary border-paleLilac focus:outline-primary"
                    onChange={() => {
                        agreementContext?.handleAnswer({
                            question,
                            value: myRef?.current?.value,
                        });
                    }}
                    onBlur={() => {
                        if (value !== myRef?.current?.value) {
                            agreementContext?.handleAnswer({
                                question,
                                value: myRef?.current?.value,
                            });
                        }
                    }}
                    required={true}
                    placeholder="Street address, City, State, Zip code"
                    value={addressValue || ""}
                />
            </div>
        );
    } else if (fieldType === FieldType.GOVERNING_LAW_STATE) {
        return (
            <div className="flex flex-col justify-center w-full mt-6">
                <DropDownStates
                    fullAddress={true}
                    usState={value}
                    disabled={!!isDisabled}
                    setUsState={(usState: string) => {
                        agreementContext!.handleAnswer({
                            question,
                            value: usState,
                        });
                    }}
                    defVal={value}
                    className="text-base sm:text-xl w-2/3"
                />
            </div>
        );
    } else if (fieldType === FieldType.ARRAY) {
        let len = value?.length || 0;
        let refs: React.RefObject<HTMLInputElement>[] = [];

        const listValue = value && value?.length ? value : [""];

        return (
            <>
                {listValue.map((val: string, index: number) => {
                    const arrSlotRef = React.createRef<HTMLInputElement>();
                    refs.push(arrSlotRef);

                    return (
                        <div key={index} className="w-full flex items-center">
                            <input
                                type="text"
                                ref={arrSlotRef}
                                autoFocus={hasFocus}
                                disabled={isDisabled}
                                className="w-10/12 mt-6 text-lg rounded-md border py-4 px-4 border-paleLilac focus:outline-primary"
                                onChange={() => {
                                    const newValue = [...listValue];
                                    newValue[index] = arrSlotRef.current?.value;
                                    agreementContext!.handleAnswer({ question, value: newValue });
                                }}
                                onBlur={() => callToSaveDraft(location, props.legalUnit)}
                                required={true}
                                value={val} //todo
                            ></input>
                            {index > 0 && (
                                <span
                                    onClick={() => {
                                        agreementContext!.handleAnswer({
                                            question,
                                            value: [...listValue].filter((_, i) => i !== index),
                                        });
                                        if (refs.length > 0) {
                                            refs[refs.length - 1]?.current?.focus();
                                        }
                                    }}
                                    className="mt-3 font-bold text-grayAccent hover:text-accent text-xl ml-4 cursor-pointer"
                                >
                                    X
                                </span>
                            )}

                            {index === 0 && (
                                <span
                                    onClick={() => {
                                        agreementContext!.handleAnswer({
                                            question,
                                            value: [...listValue, ""],
                                        });
                                    }}
                                    className="flex justify-center text-center items-center mt-3 rounded-full text-white h-8 w-8 font-bold bg-primary hover:text-lightGray text-lg ml-2 cursor-pointer"
                                >
                                    +
                                </span>
                            )}
                        </div>
                    );
                })}
            </>
        );
    } else if (fieldType === FieldType.EMBEDDED_FILE) {
        let valueAsPFile = value as PFile;

        return (
            <div className="mt-8">
                <input
                    className="text-white"
                    type="file"
                    disabled={isDisabled}
                    accept={field?.fileProperties?.accept || "*"}
                    onChange={(e) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            file.arrayBuffer().then((buffer) => {
                                const base64Flag = "data:" + file.type + ";base64,";
                                const base64str = arrayBufferToBase64(buffer);
                                const pFile = PFile.fromJSON({
                                    content: base64Flag + base64str,
                                    name: file.name,
                                    type: file.type,
                                });

                                agreementContext!.handleAnswer({ question, value: pFile });
                            });
                        }
                    }}
                />
                <p className="mt-2">
                    {valueAsPFile ? (
                        <span>
                            <b>Chosen file:</b> {valueAsPFile?.name}
                        </span>
                    ) : (
                        <span>No file chosen</span>
                    )}
                </p>
            </div>
        );
    }

    return (
        <div id="PerTypeInput">
            {fieldType} {props.question.fieldId}
        </div>
    );
}

function EnumButtons({
    q,
    value,
    options,
    onChange,
    disabled,
}: {
    q: Question;
    value: any;
    options: FieldOption[] | undefined;
    onChange: (value: any) => void;
    disabled?: boolean;
}) {
    return (
        <div className="flex flex-col">
            {options?.map((option) => (
                <div key={option.value} className="flex flex-row gap-2 mt-5 items-center">
                    <input
                        className="h-6 w-6 text-sm cursor-pointers text-primary flex-shrink-0"
                        id={option.value}
                        type="radio"
                        name={q.id}
                        checked={option.dbValue === value}
                        value={option.value}
                        onChange={() => onChange(option.dbValue)}
                        disabled={disabled}
                    />
                    <label className="text-base cursor-pointer" htmlFor={option.value}>
                        {formatOption(option.label || option.value)}
                    </label>
                    {option.kb && (
                        <KBNew text={option.kb} uniqueId={option.fieldId + "_" + option.dbValue} />
                    )}
                </div>
            ))}
        </div>
    );
}

function MultiEnumButtons({
    q,
    value,
    options,
    onChange,
    disabled,
}: {
    q: Question;
    value: any;
    options: FieldOption[] | undefined;
    onChange: (value: any) => void;
    disabled?: boolean;
}) {
    const dbValues = value as any[];
    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);
    const field = fields?.find((f) => f.id === q.fieldId);

    const handleSelect = (dbVal: any) => {
        if (!dbValues) {
            return;
        }

        let newSelected = [...dbValues];

        if (dbValues.includes(dbVal)) {
            newSelected = newSelected.filter((v) => v !== dbVal);
        } else {
            newSelected.push(dbVal);
        }

        onChange(newSelected);
    };

    return (
        <div className="flex flex-col">
            {options?.map((option) => (
                <div key={option.value} className="flex flex-row gap-2 mt-5 items-center">
                    <label className="flex justify-center gap-2 text-base cursor-pointer">
                        <input
                            className="h-6 w-6 text-sm cursor-pointers text-primary flex-shrink-0 border-primary"
                            id={option.value}
                            type="checkbox"
                            name={q.id}
                            disabled={disabled}
                            checked={dbValues && dbValues.includes(option.dbValue)}
                            value={option.dbValue}
                            onChange={() => handleSelect(option.dbValue)}
                        />
                        {formatOption(option.label || option.value)}
                    </label>
                    {option.kb && (
                        <KBNew text={option.kb} uniqueId={option.fieldId + "_" + option.dbValue} />
                    )}
                    <DecisionSupportingToolOptionHint
                        option={option}
                        fieldName={field?.name || ""}
                    />
                </div>
            ))}
        </div>
    );
}

export function VideoForQuestion(props: { videoUrl: string | undefined }) {
    const dispatch = useDispatch();

    if (!props.videoUrl) {
        return null;
    }

    return (
        <Button
            shape="square"
            color="secondary"
            size="sm"
            type="button"
            onClick={(e) => {
                e.preventDefault();
                dispatch(playVideo(props.videoUrl));
            }}
        >
            <img className="h-6 w-6" src={VideoIconBlack} alt="video icon" />
        </Button>
    );
}

export function callToSaveDraft(location: Location, legalUnit?: PLegalUnit) {
    if (!legalUnit) {
        return;
    }

    // todo: make this better
    if (location.pathname.startsWith("/apps/legal-ide/")) {
        return;
    }

    {
        // this is linked to OwnerAddress - todo - make this code prettier
        const sp: any = specificContractType(legalUnit);

        let parts = location.pathname.split("/");
        let draftId = parts[1] + "/" + parts[2] + "/" + parts[3];

        DocsApi.updateDraft(
            draftId,
            legalUnit,
            () => {},
            () => {
                console.error("bad");
            }
        );
    }
}

function formatOption(option?: string): string {
    return capitalizeFirstLetter(option);
}
