import { redeemCodeApi } from "api-services/redeemCodeApi";
import { Button, TextField } from "components";
import { LogoImg } from "components/1_features/AppBar/Menu";
import Modal from "components/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { callDialog } from "store/mainActions";
import { DialogType } from "store/mainTypes";

export function RedeemCode() {
    const { path } = useRouteMatch();

    return <Switch>
        <Route path={`${path}/appsumo`} children={<GenericRedemption providerName="appsumo" officalTitle="AppSumo" />} />
        <Route path={`${path}/dealify`} children={<GenericRedemption providerName="dealify" officalTitle="Dealify"/>} />
        <Route path={`${path}/saasmantra`} children={<GenericRedemption providerName="saasmantra" officalTitle="SaaS Mantra" />} />
        <Route path={`${path}/dealmirror`} children={<GenericRedemption providerName="dealmirror" officalTitle="Deal Mirror" />} />
        <Route path={`${path}/pitchground`} children={<GenericRedemption providerName="pitchground" officalTitle="Pitch Ground" />} />
        <Route path="/*" component={UnknownRedemption} />
    </Switch>
}

function GenericRedemption({providerName, officalTitle} : {providerName: string, officalTitle: string}) {
    const [code, setCode] = useState<string>("");
    const dispatch = useDispatch();
    const history = useHistory();

    const successCallback = () => {
        dispatch(callDialog(DialogType.SUCCESS, `Your ${officalTitle} code has been redeemed!`,
            () => {
                history.push("/")
            }))
    }

    const failureCallback = (data: any) => {
        dispatch(callDialog(DialogType.FAILURE, `Something went wrong while trying to redeem your ${officalTitle} code. Error: ` + data))
    }

    return (
        <Modal>
            <form className="flex flex-col justify-center items-center w-full h-full gap-4 border-2 border-primary rounded-lg p-8" onSubmit={(e) => {
                e.preventDefault();
                
                redeemCodeApi.redeemCodeEndpoint(code, providerName)
                .then((resp) => {
                    if (resp.success === true) {
                        successCallback();
                    } else {
                        failureCallback(resp.data);
                    }
                })
                .catch((data) => failureCallback(data))
            }}>
                <p className="text-xl font-medium">Your {officalTitle} code here:</p>
                <TextField width={400} size="lg" label="" placeholder="" value={code} onChange={(_, val) => setCode(val)} />
                <Button size="lg" type="submit">Redeem Now!</Button>
                <p className="text-md mt-8">For support, please contact us at <a href="mailto:clawdia@clawdia.ai">clawdia@clawdia.ai</a> for any feedback or questions.</p>
            </form>
        </Modal>
    );
}

function UnknownRedemption() {
    return (
        <div className="flex flex-col justify-center items-center text-center w-full h-full">
            <h1 className="text-2xl">Unknown Redemption. This link does not exist.</h1>
            <p className="text-xl"> Please make sure you are using the correct link, or contact support at&nbsp;<a href="mailto:clawdia@clawdia.ai">clawdia@clawdia.ai</a></p>
        </div>
    );
}
