import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { getTrack, postTrack } from '../../api-services/TrackApi';
import { PLegalTopics, pLegalTopicsFromJSON, PLegalTopicsSurvey } from '../../proto/PTracking'
import Modal from '../Modal'

let count: number = 0;
while (pLegalTopicsFromJSON(count) !== pLegalTopicsFromJSON(-1)) {
    count++
}
let initialArray = Array(count).fill(false)

export default function LegalTopicsSurvey() {
    const history = useHistory()

    const [allTopics, setallTopics] = useState<boolean[]>(initialArray)
    const [other, setOther] = useState(false)
    const [otherText, setotherText] = useState("")

    useEffect(() => {
        getTrack((resp: PLegalTopicsSurvey) => {
            if (resp.other) {
                setOther(true)
                setotherText(resp.other)
            }

            let arr = Array(count).fill(false)
            resp.legalTopics.forEach(idx => {
                arr[idx] = true
            })

            setallTopics(arr)
        })
        return () => {
            
        }
    }, [])

    return (
        <Modal closeable={true} closeUrl={"/library"}>
            <p className="mt-8 text-xl px-10 font-medium text-primary text-c">What legal topic would you like me to support?</p>
            <p className="mt-2 text-xl px-10 text-center"><span className=" font-medium text-primary">I am all ears </span>🙂</p>

            <div className="mx-12 my-8">
                <form className="flex flex-col">
                    {Object.values(allTopics).map((topic, index) => <div className="flex mt-2" onClick={() => {
                        setTimeout(() => {
                            setallTopics({ ...allTopics, [index]: !allTopics[index] })
                        }, 1)
                    }} >
                        <input type="checkbox" className="mt-1" checked={allTopics[index]} />
                        <label className="ml-2 font-semibold">{tempTranslate(index)}</label>
                    </div>
                    )
                    }

                    <div className="flex mt-2" onClick={() => {
                        setTimeout(() => {
                            setOther(!other)
                        }, 1)
                    }}>
                        <input type="checkbox" className="mt-1" checked={other} />
                        <label className="ml-2 font-semibold">Other</label>
                        <input defaultValue={otherText} onChange={e => {
                            setotherText(e.target.value)
                        }} onClick={e => e.stopPropagation()} type="text" className={"px-2 border ml-2 w-full border-accent rounded-sm " + (other ? "opacity-100" : "opacity-0")}></input>
                    </div>

                    <button onClick={
                        () => {
                            postTrack(PLegalTopicsSurvey.fromJSON(
                                {
                                    other: (other ? otherText : ""),
                                    legalTopics: Object.entries(allTopics).filter(x => x[1]).map(x => parseInt(x[0]))
                                }
                            ),
                                () => { history.push("/")})
                        }}
                        type="submit" className="mt-8 text-white bg-primary w-1/2 self-center px-4 py-2 rounded-lg font-bold">Submit</button>
                </form >
            </div>
        </Modal>
    )
}

// todo
export function tempTranslate(i: number) {
    switch (i) {
        case PLegalTopics.INCORPORATION: return "Incorporation";
        case PLegalTopics.ADVISORY_AGREEMENT: return "Advisory Agreement";
        case PLegalTopics.NDA: return "NDA";
        case PLegalTopics.EMPLOYMENT_AGREEMENTS: return "Employment Agreements";
        case PLegalTopics.PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS: return "Privacy policy & Terms and conditions";
        case PLegalTopics.SAFE_INVEST: return "SAFE and other investment types";
    }
}