import { animated, useTransition } from "@react-spring/web";
import { createContext, ReactNode, useCallback, useContext, useMemo, useReducer } from "react";
import { createPortal } from "react-dom";
import { Snackbar } from "../Snackbar/Snackbar";

import { nanoid } from "nanoid";

type NewSnackbar = Omit<Snackbar, "id">;
interface ContextType {
    snackbars: Snackbar[];
    push: (snacknar: NewSnackbar) => void;
}

interface State {
    snackbars: any[];
}

const Context = createContext<ContextType>({
    push: () => {},
    snackbars: [],
});

const rootEl = document.getElementById("notistack") || document.body;

export const useNotiStack = () => useContext(Context);

const initialState: State = { snackbars: [] };

type Action = { type: "push"; payload: Snackbar } | { type: "pop"; payload: { id: any } };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "push":
            return { ...state, snackbars: [...state.snackbars, action.payload] };
        case "pop":
            return { ...state, snackbars: state.snackbars.filter((s) => s.id !== action.payload.id) };
        default:
            return state;
    }
}

export const NotiStackContext = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const push = useCallback(
        (snackbar: NewSnackbar) => {
            const id = nanoid(10);
            dispatch({ type: "push", payload: { ...snackbar, id } });
            setTimeout(() => {
                dispatch({ type: "pop", payload: { id } });
            }, 2000);
        },
        [state]
    );

    const value = useMemo<ContextType>(() => {
        return { snackbars: state.snackbars, push };
    }, [state]);

    return (
        <Context.Provider value={value}>
            {children}
            <SnackBars />
        </Context.Provider>
    );
};

function SnackBars() {
    const notistack = useNotiStack();

    const transitions = useTransition(notistack.snackbars, {
        from: { x: -350 },
        enter: { x: 0 },
        leave: { x: -350 },
    });

    return createPortal(
        <div className="SnackBars">
            {transitions((style, snackbar) => {
                return (
                    <animated.div style={style}>
                        <Snackbar snackbar={snackbar} />
                    </animated.div>
                );
            })}
        </div>,
        rootEl
    );
}
