import firebase from "firebase/app";
import { auth } from "../contexts/firebase";
import { History } from 'history';
import { getRedirectUrl } from "./AuthUtils";

export enum SocialType {
  GOOGLE,
  TWITTER,
  LINKEDIN
}

export default function socialSignin(type: SocialType, history: History, errorCallback: Function) {


  let provider: firebase.auth.AuthProvider;

  switch (type) {
    case SocialType.GOOGLE: provider = new firebase.auth.GoogleAuthProvider().addScope("email"); break;
    case SocialType.TWITTER: provider = new firebase.auth.TwitterAuthProvider(); break;
    case SocialType.LINKEDIN: provider = new firebase.auth.TwitterAuthProvider(); break;

    default: throw console.error("sdsd"); // todo
  }

  auth.signInWithPopup(provider)
    .then((result: firebase.auth.UserCredential) => {
      // let credential = result.credential;

      // //    var token = credential?;
      // var user = result.user;

      setTimeout(() => {
        history.push(getRedirectUrl())
      }, 20);

      // ...
    }).catch((error: any) => {
      errorCallback(error.message)
      // // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // // ...
    });
}

export function SocialButton(props: { text: string, icon: string, onClick: Function }) {
  return <div onClick={() => { props.onClick() }}
      className="bg-knowledge hover:bg-knowledgeHover cursor-pointer flex items-center justify-center gap-6 border flex-grow rounded-lg h-14 mb-4">
      <img src={props.icon} className="w-8 h-8" />
      <p className="text-xl sm:pr-6 text-darkIndigo font-medium">{props.text}</p>
  </div>
}
