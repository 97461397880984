import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { Button } from "components";
import { EmptyState } from "components/0_common/EmptyState/EmptyState";
import { ErrorBoundary } from "components/0_common/ErrorBoundary/ErrorBoundary";
import { PriceProvider } from "components/life-cycle/payment/PricePlans";
import { NotiStackContext } from "components/NotiStack/NotiStackContext/NotiStackContext";
import { saveQueryParamsToLocalStorage } from "components/utils/UtilFunctions";
import { ErrorHandler } from "ErrorHandler";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./components/contexts/AuthContext";
import { UsageProvider } from "components/contexts/UsageProvider";
import { isProd } from "./components/utils/EnvResolver";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import store from "./store/mainStore";
import "./styles/tailwind.css";

export const MIXPANEL_KEY = "8362fa79290ca91b8aed81f796f720f1";

if (isProd()) {
    const tagManagerArgs = {
        gtmId: "GTM-NKK3GLH",
    };
    TagManager.initialize(tagManagerArgs);

    mixpanel.init(MIXPANEL_KEY);
    mixpanel.track("app_view");

    ErrorHandler.init();
}

saveQueryParamsToLocalStorage(window.location.href);

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary
            fallback={
                <div className="grid place-items-center w-screen h-screen ">
                    <div className="max-w-2xl mx-auto w-full">
                        <EmptyState
                            title="An Unexpected Error Occur"
                            description="An unexpected error has occurred. Our team is working to make sure it never happens again. Please refresh the page."
                            action={
                                <Button onClick={() => window.location.reload()}>Refresh</Button>
                            }
                        />
                    </div>
                </div>
            }
        >
            <PriceProvider>
                <TooltipPrimitive.Provider>
                    <Provider store={store}>
                        <Router>
                            <AuthProvider>
                                <NotiStackContext>
                                    <UsageProvider>
                                        <App />
                                    </UsageProvider>
                                </NotiStackContext>
                            </AuthProvider>
                        </Router>
                    </Provider>
                </TooltipPrimitive.Provider>
            </PriceProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
