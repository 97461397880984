import { DocsApi } from "api-services/DocsApi";
import { postUser } from "api-services/UseresApi";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import _ from "lodash";
import { PDocGetResponse } from "proto/PApi";
import { PDoc } from "proto/PDoc";
import { PUser } from "proto/PUser";
import { useLocation } from "react-router-dom";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { activateDraft, setDBUser, updateActiveDraft } from "store/mainActions";
import { autoDocService, getAutoContracts } from "../auto-contracts";
import { callToSaveDraft } from "../WizardForm";
import { AgreementContextType, Answer } from "./AgreementContextType";
import { getFullDocId } from "./shared-api";
import { useNavigation } from "./useNavigation";
import { useWizardApi } from "./useWizardApi";

export const useProdApi = (): AgreementContextType => {
    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const activeDraft = useAppSelector((state) => state.mainReducer.activeDraft);
    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);
    const location = useLocation();
    const wizardApi = useWizardApi({ agreement: agreement, draft: activeDraft });
    const dispatch = useAppDispatch();

    const navigation = useNavigation({ draft: activeDraft });

    const getActiveDraft = () => {
        return activeDraft;
    };

    const getDocTitle = () => {
        return getAutoContracts().find((contract) => contract.kind === activeDraft?.legalUnit?.kind)?.title || "";
    };

    const getOtherSideName = () => {
        return (
            getAutoContracts()
                .find((contract) => contract.kind === activeDraft?.legalUnit?.kind)
                ?.signer?.(activeDraft?.legalUnit).otherName || ""
        );
    };

    const handleAnswer = ({ value, question }: Answer) => {
        const field = agreement?.fields.find((f) => f.id === question.fieldId);

        if (!field) return null;
        if (field.layer === "owner") {
            if (!dbUser) return;
            const newUser: PUser = { ...dbUser, [field.id]: value };

            dispatch(setDBUser(newUser));
            postUser({ user: newUser }, () => {});
        }

        const contract = wizardApi.getCurrentContract(activeDraft);

        if (!contract) return;

        if (field.layer === "legalUnit" || !field.layer) {
            contract[field.name] = value;
        }

        // TODO: this can be improved or be done by the BE as part of derived actions
        contract["lastUpdateTime"] = new Date();

        let kind = activeDraft?.legalUnit!.kind || "";
        const newLegalUnit = {
            ...activeDraft?.legalUnit!,
            [kind]: contract,
            progress: wizardApi.getProgress().percentage,
        };

        dispatch(updateActiveDraft(newLegalUnit));

        // There are some states where a number field can is not a number - 
        // for example, 3.
        // and user wants to change it to 3.5, but in that case, the value is not a number yet.
        // In this case, we don't want to save the draft.
        const legalUnitCopy = _.cloneDeep(newLegalUnit) as any;
        const contractCopy = legalUnitCopy[kind];

        for (const f of agreement?.fields || []) {
            if (f.type === FieldType.NUMBER && contractCopy[f.name]?.toString().endsWith(".")) {
                contractCopy[f.name] = contractCopy[f.name]?.replace(/\.+$/, "");
            }
        }

        DocsApi.updateDraft(
            getFullDocId(),
            legalUnitCopy,
            () => {},
            () => {}
        );
    };
    const handleAnswerByField = ({ value, field }: { value: any; field: Field }) => {
        if (!field) return null;
        if (field.layer === "owner") {
            if (!dbUser) return;
            const newUser: PUser = { ...dbUser, [field.id]: value };

            dispatch(setDBUser(newUser));
            postUser({ user: newUser }, () => {});
        }

        const contract = wizardApi.getCurrentContract(activeDraft);

        if (!contract) return;

        if (field.layer === "legalUnit" || !field.layer) {
            contract[field.name] = value;
        }

        // TODO: this can be improved or be done by the BE as part of derived actions
        contract["lastUpdateTime"] = new Date();

        let kind = activeDraft?.legalUnit!.kind || "";
        const newLegalUnit = {
            ...activeDraft?.legalUnit!,
            [kind]: contract,
            progress: wizardApi.getProgress().percentage,
        };

        DocsApi.updateDraft(
            getFullDocId(),
            newLegalUnit,
            () => {},
            () => {}
        );

        dispatch(updateActiveDraft(newLegalUnit));
    };

    const loadAgreement = () => {
        if (!activeDraft?.legalUnit?.docVersion) return;

        autoDocService
            .loadContract(activeDraft?.legalUnit?.docVersion)
            .then((agreement: Agreement) => {
                dispatch(Actions.agreementManager.setCurrentAgreement(agreement));
            })
            .catch((err: any) => {});
    };

    const getDoc = () => {
        DocsApi.getDoc(getFullDocId(), (resp: PDocGetResponse) => {
            if (resp.docs && resp.docs.length === 1) {
                let savedDraft = resp.docs[0];
                let translated = savedDraft.legalUnit?.kind || "";

                let lu: any = savedDraft.legalUnit!; // todo - not any

                // todo
                let newWiz = {
                    docVersion: "",
                    ...lu,
                    [translated]: {
                        ...lu[translated],
                        accountName: dbUser?.accountName!,
                        usState: dbUser?.usState!,
                        title: dbUser?.title || "",

                        // todo ADDRESS: fix part of refactoring and migration
                        addressNeedToBeSet: !dbUser?.address || lu?.[translated]?.addressNeedToBeSet == 2 ? 2 : 1,
                    },
                };

                let draft = PDoc.fromJSON({ ...savedDraft, legalUnit: newWiz });

                dispatch(activateDraft(draft));

                callToSaveDraft(location, newWiz);
            }
        });
    };

    const cleanup = () => {
        dispatch(Actions.agreementManager.setCurrentAgreement(undefined));
    };

    return {
        loadAgreement,
        wizardApi,
        navigation,
        getActiveDraft,
        handleAnswer,
        handleAnswerByField,
        getFullDocId,
        getDoc,
        getDocTitle,
        getOtherSideName,
        cleanup,
        isSimulator: false,
    };
};
