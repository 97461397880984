import Joyride, { CallBackProps, EVENTS } from "react-joyride";
import { useSelector } from "react-redux";
import { MainStore } from "../../store/mainTypes";
import { SHOPIFY_TOUR_STEPS } from "../../consts";

const ShopifyTour = () => {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const firstTimer = JSON.parse(localStorage.getItem("firstTimer") || "true");
    const showTour = dbUser?.isShopifyUser && firstTimer;

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { type } = data;

        if (type === EVENTS.TOUR_END) {
            localStorage.setItem("firstTimer", "false");
        }
    };

    if (!showTour) {
        return null;
    }

    return (
        <Joyride
            callback={handleJoyrideCallback}
            steps={SHOPIFY_TOUR_STEPS}
            styles={{
                options: {
                    primaryColor: "#2D5FFB",
                },
            }}
            disableScrolling
            run={showTour}
            continuous
            showProgress
            showSkipButton
        />
    );
};

export { ShopifyTour };
