import mixpanel from "mixpanel-browser";
import { nanoid } from "nanoid";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { useAppSelector } from "store";
import { getUser, postUser } from "../../api-services/UseresApi";
import { PGetUserResponse, PPostUserRequest } from "../../proto/PApi";
import { PUserStatus } from "../../proto/PUser";
import { setDBUser } from "../../store/mainActions";
import { useAuth } from "../contexts/AuthContext";
import Modal from "../Modal";
import { isProd } from "../utils/EnvResolver";
import { GTMHelper } from "../utils/GTMHelper";
import { getRedirectUrl } from "./AuthUtils";

import GlobalFlag from "img/icons/global.png";
import ILFlag from "img/icons/il.png";
import USFlag from "img/icons/usa.png";

export default function OnBoarding() {
    const dispatch = useDispatch();
    const auth = useAuth();
    const history = useHistory();

    const [fullName, setFullName] = useState<string>(auth?.user?.displayName || "");
    const [title, setTitle] = useState<string>("");
    const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
    const [agreedToNotLawyerAck, setAgreedToNotLawyerAck] = useState<boolean>(false);

    const [countryOption, setCountryOption] = useState<CountryOption>(COUNTRY_OPTIONS[0]);

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

    if (auth?.userStatus !== PUserStatus.PRE_ONBOARDING) {
        return <Redirect to={getRedirectUrl()} />;
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        let request = PPostUserRequest.fromJSON({
            user: {
                ...dbUser,
                fullName: fullName,
                accountName: nanoid(),
                userStatus: PUserStatus.POST_ONBOARDING,
                title: title,
                agreedToTerms: agreedToTerms,
                agreedToNotLawyerAck: agreedToNotLawyerAck,
                country: countryOption.value,
                isChecklistUser: history.location.search.includes("checklist-video"),
            },
        });

        postUser(request, async () => {
            // If the user already paid (in Duda, for example), we want to change their status to POST_PAYMENT
            const newStatus =
                dbUser?.tier === "pro" ? PUserStatus.POST_PAYMENT : PUserStatus.POST_ONBOARDING;
            await auth?.setUserStatus(newStatus);

            getUser(async (resp: PGetUserResponse) => {
                await dispatch(setDBUser(resp?.user));

                if (isProd()) {
                    mixpanel.track("user_details_completed");
                    GTMHelper.push({ event: "user_details_completed" });
                }
            });
        });
    }

    return (
        <Modal>
            <div className="sm:w-172 h-[35rem] sm:h-[33rem] flex flex-col">
                <div className="flex-grow">
                    <div className="mt-6 mx-6 sm:mx-28">
                        <form onSubmit={handleSubmit}>
                            <p className="mt-2 text-primary text-base sm:text-2xl font-bold">
                                One more step and you're done!
                            </p>
                            <p className="text-base mt-4">Your full name *</p>

                            <input
                                onChange={(e) => setFullName(e.target.value)}
                                required
                                type="text"
                                autoFocus
                                className="InputField w-full outline-stroke3 mt-1 text-base rounded-md border h-10 px-4 py-2 border-lightGray"
                                placeholder="Ex: Julia Roberts"
                                defaultValue={auth.user?.displayName || ""}
                            />

                            <p className="text-base mt-4">Your title *</p>

                            <input
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                className="InputField outline-stroke3 w-full mt-1 text-base rounded-md border h-10 px-4 py-2 border-lightGray"
                                placeholder="Ex: CEO"
                            />

                            <p className="text-base mt-4">Country *</p>
                            <CountryDropDown
                                disable={false}
                                options={COUNTRY_OPTIONS}
                                selected={countryOption}
                                setSelected={setCountryOption}
                            />

                            <div className="flex flex-col mt-6 gap-4">
                                <label className="flex">
                                    <input
                                        className="w-6 h-6 mr-2 flex-shrink-0"
                                        type="checkbox"
                                        required
                                        onChange={(e) => {
                                            setAgreedToTerms(e.target.checked);
                                        }}
                                    />
                                    <span>
                                        I agree to the&nbsp;
                                        <a
                                            href="https://www.clawdia.ai/customer-terms-and-conditions"
                                            target="_blank"
                                        >
                                            Terms and Conditions
                                        </a>
                                        &nbsp;and&nbsp;
                                        <a
                                            href="https://www.clawdia.ai/privacy-policy"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </span>
                                </label>

                                <label className="flex">
                                    <input
                                        className="w-6 h-6 mr-2 flex-shrink-0"
                                        type="checkbox"
                                        required
                                        onChange={(e) => {
                                            setAgreedToNotLawyerAck(e.target.checked);
                                        }}
                                    />
                                    I acknowledge Clawdia AI is not a lawyer, provides no legal
                                    advice or representation, and no attorney-client relationship is
                                    created
                                </label>
                            </div>

                            <input
                                type="submit"
                                className="cursor-pointer mt-2 sm:mt-7 h-10 bg-primary text-white text-base w-full rounded-md"
                                value="Let's start!"
                            />

                            <p
                                className="text-xs underline mt-4 cursor-pointer text-center"
                                onClick={() => {
                                    auth.logout();
                                    history.push("/signup");
                                }}
                            >
                                Go back
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export interface CountryOption {
    value: string;
    label: string;
    img: string;
}

export const COUNTRY_OPTIONS = [
    {
        value: "USA",
        label: "USA",
        img: USFlag,
    },
    {
        value: "Israel",
        label: "Israel",
        img: ILFlag,
    },
    {
        value: "Other (Global)",
        label: "Other (Global)",
        img: GlobalFlag,
    },
] as CountryOption[];

export function CountryDropDown({
    selected,
    setSelected,
    options,
    disable,
}: {
    selected: CountryOption;
    setSelected: (c: CountryOption) => void;
    options: CountryOption[];
    disable?: boolean;
}) {
    return (
        <div className="relative w-full">
            <div className="flex w-full items-center justify-start gap-2 outline-stroke3 mt-1 text-base rounded-md border h-10 px-4 py-2 border-lightGray">
                <img
                    src={selected.img}
                    alt={selected.label}
                    className="py-[0.125rem] h-full aspect-auto"
                />
                <span>{selected.label}</span>
            </div>
            <select
                disabled={disable}
                className="absolute block opacity-0 w-full h-full top-0 left-0 appearance-none"
                value={selected.value}
                onChange={(e) => {
                    const value = e.target.value;
                    const option = options.find((opt) => opt.value === value);
                    setSelected(option || options[0]);
                }}
            >
                {options.map((option) => (
                    <option className="px-4 py-4 text-lg" key={option.value} value={option.value}>
                        &nbsp;&nbsp;&nbsp;&nbsp;{option.value}
                    </option>
                ))}
            </select>
        </div>
    );
}
