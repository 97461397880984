import { ReactNode } from "react";

interface Props {
    title?: ReactNode | string;
    description?: ReactNode | string;
    image?: string;
    action?: ReactNode;
}

export const EmptyState = (props: Props) => {
    const { title, description, image, action } = props;
    return (
        <div className="flex flex-col justify-center items-center w-full rounded p-8 shadow-md bg-white">
            {!!image && <img className="w-full h-auto px-20" src={image} alt="Empty Directory" />}
            {!!title && <p className="text-darkIndigo text-2xl my-4">{title}</p>}
            {description && <p className="text-darkIndigo text-lg whitespace-pre-wrap mb-4">{description}</p>}
            {action}
        </div>
    );
};
