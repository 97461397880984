import "./Auth.scss"

export function TextWithCrossingLine(props: { text: string, vertical?: boolean }) {
    if (!props.vertical) {
        return <p className="Cross text-lg"><span>{props.text}</span></p>
    }

    return <div className="or-separator">
        <div className="vertical-line"></div>
        <div className="text-paleLilac">Or</div>
        <div className="vertical-line"></div>
    </div>
}

const REDIRECT_URL = "redirect_url";

export function createRedirectSearchParam() {
    return REDIRECT_URL + "=" + encodeURIComponent(window.location.pathname)
}

export function getRedirectUrl() {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectUrl = params.get(REDIRECT_URL)

    if (!redirectUrl) {
        return "/"
    }

    return decodeURIComponent(redirectUrl)
}

export function passCurrentSearchQuery() {
    return window.location.search
}
