import { scriptsRetrieve } from "api-services/WebScrapingApi"
import { Button } from "components"
import { SvgRadarScan } from "components/0_common/SvgIcons"
import { PScrapeWebsiteRequest } from "proto/PApi"
import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { setDecisionSupportingToolAlert } from "store/mainActions"
import SvgRadarPausedIcon from "img/icons/radar-paused.svg"
import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext"
import { Answer } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContextType"
import { useAppSelector } from "store"
import { EPrivacyPolicyAdDisplayFields, EPrivacyPolicyAdserviceFields, EPrivacyPolicyAnalyticFields } from "proto/contracts/auto-contracts/PrivacyPolicy"

export function PrivacyPolicyScanner({ websiteUrl }: { websiteUrl: string }) {
    const dispatch = useDispatch()
    const [animate, setAnimate] = React.useState(false)
    const [done, setDone] = React.useState(false)
    const [scripts, setScripts] = React.useState<string[]>([])

    const agreementContext = useContext(AgreementContext);
    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    if (!websiteUrl) {
        dispatch(setDecisionSupportingToolAlert(undefined));
        return null;
    }

    if (!isValidUrl(websiteUrl)) {
        if (isValidUrl("http://" + websiteUrl)) {
            websiteUrl = "http://" + websiteUrl;
        }
        else {
            return <div>
                <p className='text-xl font-semibold'>It appears like {websiteUrl} is not a valid url</p>
                <Button color="skyBlue" onClick={() => dispatch(setDecisionSupportingToolAlert(undefined))}>Ok</Button>
            </div>
        }
    }

    return <div className='flex flex-col'>
        <p className='text-xl font-semibold'>Clawdia Can Scan<br />{websiteUrl}</p>
        <div className='flex flex-1 mt-4 items-center justify-center gap-8'>
            <SvgRadarScan paused={!animate} />
            <div className="flex flex-col w-2/3 text-left">
                {animate || done ? <ScanningMessage done={done} /> : <PreScanMessage />}
                {done && <DoneMessage scripts={scripts} />}
            </div>
        </div>
       <div className="mt-8 w-1/2 self-center">
        <Button onClick={() => {
                if (done) {
                    dispatch(setDecisionSupportingToolAlert(undefined))
                    return
                }

                setAnimate(true)

                scriptsRetrieve(PScrapeWebsiteRequest.fromJSON({ url: websiteUrl }),
                    (resp: any) => {
                        setScripts(resp.scripts)
                        setDone(true)
                        setAnimate(false)

                        let answer: Answer = {
                            question: { fieldId: agreement?.fields.find(f => f.name === "scripts")?.id } as Question,
                            value: resp.scripts
                        }

                        agreementContext?.handleAnswer(answer);

                    }, () => {
                        alert("Failure while scanning website. Make sure the url is correct and try again.\n\nIf you don't have a running website yet, that's ok. Just skip this step.")
                        setDone(false)
                        setAnimate(false)
                    })
            }}
                color='secondary'
                fullWidth
                >
                {done ? "Ok!" : "Start Scan"}
            </Button>
       </div>
    </div>
}

function PreScanMessage() {
    return <div>
        <p className='text-lg font-medium'>Clawdia will attempt to identify privacy-related tools that you might want to disclose in your privacy policy.</p>
        <p className='text-base mt-2'>Be aware - some tools might not be detectable, so the scanner might not always catch everything.</p>
    </div>
}

export const ANALYTICS_TOOLS = getAnalyticsToolsMapping()
export const AD_SERVICE_TOOLS = getAdServiceToolsMapping()
export const DISPLAY_ADS = getDisplayAdsMapping()

function DoneMessage({ scripts }: { scripts: string[] }) {
    const TOOLS = [...ANALYTICS_TOOLS, ...AD_SERVICE_TOOLS, ...DISPLAY_ADS].filter(tool => !!tool)

    const privacyRelatedTools = Object.values(TOOLS).filter((tool: PrivacyRelatedTool) => scripts?.reduce((acc, script) =>
        acc || checkIfScriptIncludesUrlPiece(script, tool.urlPieceToDetect), false))

    return <div>
        <p className='text-lg font-medium'>Clawdia found {privacyRelatedTools.length} privacy-related tools on your website.</p>
        {privacyRelatedTools.length > 0 && (
            <>
                {privacyRelatedTools.length > 0 && <p className='text-sm mt-2'>For example: <br />&nbsp;&nbsp;- {privacyRelatedTools[0].name}</p>}
                {privacyRelatedTools.length > 1 && <p className='text-sm mt-2'>&nbsp;&nbsp;- {privacyRelatedTools[1].name}</p>}
                {privacyRelatedTools.length > 2 && <p className='text-sm mt-2'>&nbsp;&nbsp;- {privacyRelatedTools[2].name}</p>}
                <p className="text-base mt-6 font-semibold text-primary">While creating your Privacy Policy, you will see the icon
                    <img className="inline" src={SvgRadarPausedIcon} width={30} height={30} /> next to the tools that Clawdia found with an explanation.</p>
            </>
        )}
    </div>
}

function ScanningMessage({ done }: { done: boolean }) {
    const list = ["Connecting", "Scanning", "Analyzing", "Checking latest legal regulations", "Generating Report"]
    const [threeDots, setThreeDots] = React.useState('...')
    const [message, setMessage] = React.useState(list[0])

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (done) {
                setThreeDots('')
                return
            }
            setThreeDots(threeDots => {
                if (threeDots === '...') {
                    return '.'
                }
                if (threeDots === '.') {
                    return '..'
                }
                if (threeDots === '..') {
                    return '...'
                }
                return '...'
            })
        }, 300)

        return () => clearInterval(interval)
    }, [done])

    React.useEffect(() => {
        if (done) {
            setMessage('Done!')
            return
        }

        const interval = setInterval(() => {
            setMessage(message => {
                if (message === '') {
                    return list[0]
                }
                if (message === list[0]) {
                    return list[1]
                }
                if (message === list[1]) {
                    return list[2]
                }
                if (message === list[2]) {
                    return list[3]
                }
                if (message === list[3]) {
                    return list[4]
                }

                return list[4];
            })
        }, 1500)

        return () => clearInterval(interval)
    }, [done])

    return <p className='text-lg font-medium text-primary'>&#x2714;&nbsp;{message}{threeDots}</p>
}

function isValidUrl(websiteUrl: string) {
    // check if string is a valid url
    try {
        new URL(websiteUrl);
        return true;
    }
    catch (e) {
        return false;
    }
}

export interface PrivacyRelatedTool {
    enumVal: number;
    name: string;
    urlPieceToDetect: string;
}

function getAnalyticsToolsMapping() {
    let analyticsToolsMapping: PrivacyRelatedTool[] = [];

    const allAnalyticsTools = Object.values(EPrivacyPolicyAnalyticFields).filter((item) => {
        return !isNaN(Number(item));
    });

    allAnalyticsTools.forEach((item) => {
        switch (item) {
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CLICKY:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Clicky",
                    urlPieceToDetect: "clicky.com"
                }
                break;
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CRAZY:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Crazy Egg",
                    urlPieceToDetect: "crazyegg.com"
                }
                break;
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_KISSMETRICS:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Kissmetrics",
                    urlPieceToDetect: "kissmetrics.com"
                }
                break;
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_MIXPANEL:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Mixpanel",
                    urlPieceToDetect: "mixpanel.com"
                }
                break;
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_OPENWEB:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Open Web Analytics",
                    urlPieceToDetect: "openwebanalytics.com"
                }
                break;
            case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_STATCOUNTER:
                analyticsToolsMapping[item] = {
                    enumVal: item,
                    name: "Statcounter",
                    urlPieceToDetect: "statcounter.com"
                }
                break;
        }
    });

    return analyticsToolsMapping;
}

function getAdServiceToolsMapping() {
    let pixelsMapping: PrivacyRelatedTool[] = [];

    const allPixels = Object.values(EPrivacyPolicyAdserviceFields).filter((item) => {
        return !isNaN(Number(item));
    });

    allPixels.forEach((item) => {
        switch (item) {
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Facebook Pixel",
                    urlPieceToDetect: "connect.facebook.net"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "LinkedIn Pixel",
                    urlPieceToDetect: "snap.licdn.com"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_MARKETO:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Marketo",
                    urlPieceToDetect: "munchkin.marketo.net"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_HUBSPOT:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Hubspot",
                    urlPieceToDetect: "js.hs-analytics.net"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Outbrain Pixel",
                    urlPieceToDetect: "widgets.outbrain.com"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Snapchat Pixel",
                    urlPieceToDetect: "sc-static.net"
                }
                break;
            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TIKTOK_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "TikTok Pixel",
                    urlPieceToDetect: "analytics.tiktok.com"
                }
                break;

            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TWITTER_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Twitter Pixel",
                    urlPieceToDetect: "analytics.twitter.com"
                }
                break;

            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TABOOLA_PIXEL:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Taboola Pixel",
                    urlPieceToDetect: "cdn.taboola.com"
                }
                break;

            case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PARDOT:
                pixelsMapping[item] = {
                    enumVal: item,
                    name: "Pardot",
                    urlPieceToDetect: "d2hs7jy4r5n0xu.cloudfront.net"
                }
                break;
        }
    });

    return pixelsMapping;
}

function getDisplayAdsMapping() {
    let displayAdsMapping: PrivacyRelatedTool[] = [];

    const allDisplayAds = Object.values(EPrivacyPolicyAdDisplayFields).filter((item) => {
        return !isNaN(Number(item));
    });

    allDisplayAds.forEach((item) => {
        switch (item) {
            case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_APPLOVIN:
                displayAdsMapping[item] = {
                    enumVal: item,
                    name: "AppLovin",
                    urlPieceToDetect: "applovin.com"
                }
                break;

            case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_ADCOLONY:
                displayAdsMapping[item] = {
                    enumVal: item,
                    name: "AdColony",
                    urlPieceToDetect: "adcolony.com"
                }
                break;

            case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_BING_ADS:
                displayAdsMapping[item] = {
                    enumVal: item,
                    name: "Bing Ads",
                    urlPieceToDetect: "bingads.microsoft.com"
                }
                break;

            case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_FACEBOOK_ADS:
                displayAdsMapping[item] = {
                    enumVal: item,
                    name: "Facebook Ads",
                    // url to test if using facebook display ads as part of the privacy policy detect
                    urlPieceToDetect: "facebook.com/ads"
                }
                break;

            case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE:
                displayAdsMapping[item] = {
                    enumVal: item,
                    name: "Google Ads",
                    urlPieceToDetect: "google.com/adsense"
                }
                break;
        }
    });

    return displayAdsMapping;

}

export function checkIfScriptIncludesUrlPiece(script: string, urlPiece: string) {
    return script.toLowerCase().includes(urlPiece.toLowerCase());
}
