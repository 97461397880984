/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PTable } from "../PUtils";

export const protobufPackage = "";

export enum ENotePurchaseAgreementShortFormCompanyEntityType {
  NotePurchaseAgreementShortForm_companyEntityType_NA = 0,
  NotePurchaseAgreementShortForm_companyEntityType_CORPORATION = 1,
  NotePurchaseAgreementShortForm_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  NotePurchaseAgreementShortForm_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  NotePurchaseAgreementShortForm_companyEntityType_LIMITED_PARTNERSHIP = 4,
  NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  NotePurchaseAgreementShortForm_companyEntityType_NONPROFIT_CORPORATION = 8,
  NotePurchaseAgreementShortForm_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  NotePurchaseAgreementShortForm_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormCompanyEntityTypeFromJSON(
  object: any,
): ENotePurchaseAgreementShortFormCompanyEntityType {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_companyEntityType_NA":
      return ENotePurchaseAgreementShortFormCompanyEntityType.NotePurchaseAgreementShortForm_companyEntityType_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_companyEntityType_CORPORATION":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_CORPORATION;
    case 2:
    case "NotePurchaseAgreementShortForm_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "NotePurchaseAgreementShortForm_companyEntityType_SOLE_PROPRIETORSHIP":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "NotePurchaseAgreementShortForm_companyEntityType_LIMITED_PARTNERSHIP":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_CORPORATION":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "NotePurchaseAgreementShortForm_companyEntityType_NONPROFIT_CORPORATION":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "NotePurchaseAgreementShortForm_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "NotePurchaseAgreementShortForm_companyEntityType_PARTNERSHIP":
      return ENotePurchaseAgreementShortFormCompanyEntityType
        .NotePurchaseAgreementShortForm_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormCompanyEntityType.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormCompanyEntityTypeToJSON(
  object: ENotePurchaseAgreementShortFormCompanyEntityType,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormCompanyEntityType.NotePurchaseAgreementShortForm_companyEntityType_NA:
      return "NotePurchaseAgreementShortForm_companyEntityType_NA";
    case ENotePurchaseAgreementShortFormCompanyEntityType.NotePurchaseAgreementShortForm_companyEntityType_CORPORATION:
      return "NotePurchaseAgreementShortForm_companyEntityType_CORPORATION";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "NotePurchaseAgreementShortForm_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_SOLE_PROPRIETORSHIP:
      return "NotePurchaseAgreementShortForm_companyEntityType_SOLE_PROPRIETORSHIP";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_LIMITED_PARTNERSHIP:
      return "NotePurchaseAgreementShortForm_companyEntityType_LIMITED_PARTNERSHIP";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_CORPORATION:
      return "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_CORPORATION";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "NotePurchaseAgreementShortForm_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_NONPROFIT_CORPORATION:
      return "NotePurchaseAgreementShortForm_companyEntityType_NONPROFIT_CORPORATION";
    case ENotePurchaseAgreementShortFormCompanyEntityType
      .NotePurchaseAgreementShortForm_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "NotePurchaseAgreementShortForm_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case ENotePurchaseAgreementShortFormCompanyEntityType.NotePurchaseAgreementShortForm_companyEntityType_PARTNERSHIP:
      return "NotePurchaseAgreementShortForm_companyEntityType_PARTNERSHIP";
    case ENotePurchaseAgreementShortFormCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormJurisdictions {
  NotePurchaseAgreementShortForm_jurisdictions_NA = 0,
  NotePurchaseAgreementShortForm_jurisdictions_NONE = 1,
  NotePurchaseAgreementShortForm_jurisdictions_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormJurisdictionsFromJSON(
  object: any,
): ENotePurchaseAgreementShortFormJurisdictions {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_jurisdictions_NA":
      return ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_jurisdictions_NONE":
      return ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_jurisdictions_OTHER":
      return ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormJurisdictions.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormJurisdictionsToJSON(
  object: ENotePurchaseAgreementShortFormJurisdictions,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_NA:
      return "NotePurchaseAgreementShortForm_jurisdictions_NA";
    case ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_NONE:
      return "NotePurchaseAgreementShortForm_jurisdictions_NONE";
    case ENotePurchaseAgreementShortFormJurisdictions.NotePurchaseAgreementShortForm_jurisdictions_OTHER:
      return "NotePurchaseAgreementShortForm_jurisdictions_OTHER";
    case ENotePurchaseAgreementShortFormJurisdictions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure31 {
  NotePurchaseAgreementShortForm_disclosure31_NA = 0,
  NotePurchaseAgreementShortForm_disclosure31_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure31_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure31FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure31 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure31_NA":
      return ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure31_NONE":
      return ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure31_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure31.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure31ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure31,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_NA:
      return "NotePurchaseAgreementShortForm_disclosure31_NA";
    case ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_NONE:
      return "NotePurchaseAgreementShortForm_disclosure31_NONE";
    case ENotePurchaseAgreementShortFormDisclosure31.NotePurchaseAgreementShortForm_disclosure31_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure31_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure31.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure32 {
  NotePurchaseAgreementShortForm_disclosure32_NA = 0,
  NotePurchaseAgreementShortForm_disclosure32_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure32_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure32FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure32 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure32_NA":
      return ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure32_NONE":
      return ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure32_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure32.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure32ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure32,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_NA:
      return "NotePurchaseAgreementShortForm_disclosure32_NA";
    case ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_NONE:
      return "NotePurchaseAgreementShortForm_disclosure32_NONE";
    case ENotePurchaseAgreementShortFormDisclosure32.NotePurchaseAgreementShortForm_disclosure32_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure32_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure32.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure33 {
  NotePurchaseAgreementShortForm_disclosure33_NA = 0,
  NotePurchaseAgreementShortForm_disclosure33_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure33_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure33FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure33 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure33_NA":
      return ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure33_NONE":
      return ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure33_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure33.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure33ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure33,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_NA:
      return "NotePurchaseAgreementShortForm_disclosure33_NA";
    case ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_NONE:
      return "NotePurchaseAgreementShortForm_disclosure33_NONE";
    case ENotePurchaseAgreementShortFormDisclosure33.NotePurchaseAgreementShortForm_disclosure33_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure33_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure33.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure34 {
  NotePurchaseAgreementShortForm_disclosure34_NA = 0,
  NotePurchaseAgreementShortForm_disclosure34_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure34_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure34FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure34 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure34_NA":
      return ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure34_NONE":
      return ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure34_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure34.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure34ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure34,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_NA:
      return "NotePurchaseAgreementShortForm_disclosure34_NA";
    case ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_NONE:
      return "NotePurchaseAgreementShortForm_disclosure34_NONE";
    case ENotePurchaseAgreementShortFormDisclosure34.NotePurchaseAgreementShortForm_disclosure34_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure34_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure34.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure35 {
  NotePurchaseAgreementShortForm_disclosure35_NA = 0,
  NotePurchaseAgreementShortForm_disclosure35_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure35_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure35FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure35 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure35_NA":
      return ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure35_NONE":
      return ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure35_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure35.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure35ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure35,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_NA:
      return "NotePurchaseAgreementShortForm_disclosure35_NA";
    case ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_NONE:
      return "NotePurchaseAgreementShortForm_disclosure35_NONE";
    case ENotePurchaseAgreementShortFormDisclosure35.NotePurchaseAgreementShortForm_disclosure35_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure35_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure35.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure36 {
  NotePurchaseAgreementShortForm_disclosure36_NA = 0,
  NotePurchaseAgreementShortForm_disclosure36_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure36_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure36FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure36 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure36_NA":
      return ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure36_NONE":
      return ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure36_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure36.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure36ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure36,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_NA:
      return "NotePurchaseAgreementShortForm_disclosure36_NA";
    case ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_NONE:
      return "NotePurchaseAgreementShortForm_disclosure36_NONE";
    case ENotePurchaseAgreementShortFormDisclosure36.NotePurchaseAgreementShortForm_disclosure36_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure36_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure36.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure37 {
  NotePurchaseAgreementShortForm_disclosure37_NA = 0,
  NotePurchaseAgreementShortForm_disclosure37_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure37_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure37FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure37 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure37_NA":
      return ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure37_NONE":
      return ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure37_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure37.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure37ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure37,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_NA:
      return "NotePurchaseAgreementShortForm_disclosure37_NA";
    case ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_NONE:
      return "NotePurchaseAgreementShortForm_disclosure37_NONE";
    case ENotePurchaseAgreementShortFormDisclosure37.NotePurchaseAgreementShortForm_disclosure37_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure37_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure37.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure38 {
  NotePurchaseAgreementShortForm_disclosure38_NA = 0,
  NotePurchaseAgreementShortForm_disclosure38_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure38_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure38FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure38 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure38_NA":
      return ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure38_NONE":
      return ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure38_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure38.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure38ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure38,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_NA:
      return "NotePurchaseAgreementShortForm_disclosure38_NA";
    case ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_NONE:
      return "NotePurchaseAgreementShortForm_disclosure38_NONE";
    case ENotePurchaseAgreementShortFormDisclosure38.NotePurchaseAgreementShortForm_disclosure38_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure38_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure38.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure39 {
  NotePurchaseAgreementShortForm_disclosure39_NA = 0,
  NotePurchaseAgreementShortForm_disclosure39_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure39_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure39FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure39 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure39_NA":
      return ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure39_NONE":
      return ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure39_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure39.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure39ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure39,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_NA:
      return "NotePurchaseAgreementShortForm_disclosure39_NA";
    case ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_NONE:
      return "NotePurchaseAgreementShortForm_disclosure39_NONE";
    case ENotePurchaseAgreementShortFormDisclosure39.NotePurchaseAgreementShortForm_disclosure39_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure39_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure39.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure310 {
  NotePurchaseAgreementShortForm_disclosure310_NA = 0,
  NotePurchaseAgreementShortForm_disclosure310_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure310_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure310FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure310 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure310_NA":
      return ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure310_NONE":
      return ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure310_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure310.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure310ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure310,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_NA:
      return "NotePurchaseAgreementShortForm_disclosure310_NA";
    case ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_NONE:
      return "NotePurchaseAgreementShortForm_disclosure310_NONE";
    case ENotePurchaseAgreementShortFormDisclosure310.NotePurchaseAgreementShortForm_disclosure310_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure310_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure310.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure311 {
  NotePurchaseAgreementShortForm_disclosure311_NA = 0,
  NotePurchaseAgreementShortForm_disclosure311_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure311_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure311FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure311 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure311_NA":
      return ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure311_NONE":
      return ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure311_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure311.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure311ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure311,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_NA:
      return "NotePurchaseAgreementShortForm_disclosure311_NA";
    case ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_NONE:
      return "NotePurchaseAgreementShortForm_disclosure311_NONE";
    case ENotePurchaseAgreementShortFormDisclosure311.NotePurchaseAgreementShortForm_disclosure311_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure311_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure311.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure312 {
  NotePurchaseAgreementShortForm_disclosure312_NA = 0,
  NotePurchaseAgreementShortForm_disclosure312_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure312_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure312FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure312 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure312_NA":
      return ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure312_NONE":
      return ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure312_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure312.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure312ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure312,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_NA:
      return "NotePurchaseAgreementShortForm_disclosure312_NA";
    case ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_NONE:
      return "NotePurchaseAgreementShortForm_disclosure312_NONE";
    case ENotePurchaseAgreementShortFormDisclosure312.NotePurchaseAgreementShortForm_disclosure312_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure312_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure312.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure313 {
  NotePurchaseAgreementShortForm_disclosure313_NA = 0,
  NotePurchaseAgreementShortForm_disclosure313_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure313_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure313FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure313 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure313_NA":
      return ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure313_NONE":
      return ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure313_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure313.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure313ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure313,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_NA:
      return "NotePurchaseAgreementShortForm_disclosure313_NA";
    case ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_NONE:
      return "NotePurchaseAgreementShortForm_disclosure313_NONE";
    case ENotePurchaseAgreementShortFormDisclosure313.NotePurchaseAgreementShortForm_disclosure313_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure313_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure313.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure314 {
  NotePurchaseAgreementShortForm_disclosure314_NA = 0,
  NotePurchaseAgreementShortForm_disclosure314_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure314_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure314FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure314 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure314_NA":
      return ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure314_NONE":
      return ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure314_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure314.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure314ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure314,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_NA:
      return "NotePurchaseAgreementShortForm_disclosure314_NA";
    case ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_NONE:
      return "NotePurchaseAgreementShortForm_disclosure314_NONE";
    case ENotePurchaseAgreementShortFormDisclosure314.NotePurchaseAgreementShortForm_disclosure314_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure314_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure314.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure41 {
  NotePurchaseAgreementShortForm_disclosure41_NA = 0,
  NotePurchaseAgreementShortForm_disclosure41_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure41_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure41FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure41 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure41_NA":
      return ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure41_NONE":
      return ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure41_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure41.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure41ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure41,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_NA:
      return "NotePurchaseAgreementShortForm_disclosure41_NA";
    case ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_NONE:
      return "NotePurchaseAgreementShortForm_disclosure41_NONE";
    case ENotePurchaseAgreementShortFormDisclosure41.NotePurchaseAgreementShortForm_disclosure41_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure41_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure41.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure42 {
  NotePurchaseAgreementShortForm_disclosure42_NA = 0,
  NotePurchaseAgreementShortForm_disclosure42_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure42_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure42FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure42 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure42_NA":
      return ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure42_NONE":
      return ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure42_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure42.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure42ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure42,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_NA:
      return "NotePurchaseAgreementShortForm_disclosure42_NA";
    case ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_NONE:
      return "NotePurchaseAgreementShortForm_disclosure42_NONE";
    case ENotePurchaseAgreementShortFormDisclosure42.NotePurchaseAgreementShortForm_disclosure42_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure42_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure42.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure43 {
  NotePurchaseAgreementShortForm_disclosure43_NA = 0,
  NotePurchaseAgreementShortForm_disclosure43_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure43_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure43FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure43 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure43_NA":
      return ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure43_NONE":
      return ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure43_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure43.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure43ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure43,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_NA:
      return "NotePurchaseAgreementShortForm_disclosure43_NA";
    case ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_NONE:
      return "NotePurchaseAgreementShortForm_disclosure43_NONE";
    case ENotePurchaseAgreementShortFormDisclosure43.NotePurchaseAgreementShortForm_disclosure43_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure43_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure43.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure44 {
  NotePurchaseAgreementShortForm_disclosure44_NA = 0,
  NotePurchaseAgreementShortForm_disclosure44_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure44_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure44FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure44 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure44_NA":
      return ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure44_NONE":
      return ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure44_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure44.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure44ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure44,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_NA:
      return "NotePurchaseAgreementShortForm_disclosure44_NA";
    case ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_NONE:
      return "NotePurchaseAgreementShortForm_disclosure44_NONE";
    case ENotePurchaseAgreementShortFormDisclosure44.NotePurchaseAgreementShortForm_disclosure44_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure44_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure44.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure45 {
  NotePurchaseAgreementShortForm_disclosure45_NA = 0,
  NotePurchaseAgreementShortForm_disclosure45_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure45_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure45FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure45 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure45_NA":
      return ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure45_NONE":
      return ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure45_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure45.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure45ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure45,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_NA:
      return "NotePurchaseAgreementShortForm_disclosure45_NA";
    case ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_NONE:
      return "NotePurchaseAgreementShortForm_disclosure45_NONE";
    case ENotePurchaseAgreementShortFormDisclosure45.NotePurchaseAgreementShortForm_disclosure45_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure45_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure45.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormDisclosure46 {
  NotePurchaseAgreementShortForm_disclosure46_NA = 0,
  NotePurchaseAgreementShortForm_disclosure46_NONE = 1,
  NotePurchaseAgreementShortForm_disclosure46_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormDisclosure46FromJSON(
  object: any,
): ENotePurchaseAgreementShortFormDisclosure46 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_disclosure46_NA":
      return ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_disclosure46_NONE":
      return ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_NONE;
    case 999999:
    case "NotePurchaseAgreementShortForm_disclosure46_OTHER":
      return ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormDisclosure46.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormDisclosure46ToJSON(
  object: ENotePurchaseAgreementShortFormDisclosure46,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_NA:
      return "NotePurchaseAgreementShortForm_disclosure46_NA";
    case ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_NONE:
      return "NotePurchaseAgreementShortForm_disclosure46_NONE";
    case ENotePurchaseAgreementShortFormDisclosure46.NotePurchaseAgreementShortForm_disclosure46_OTHER:
      return "NotePurchaseAgreementShortForm_disclosure46_OTHER";
    case ENotePurchaseAgreementShortFormDisclosure46.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementShortFormPaymentSchedule {
  NotePurchaseAgreementShortForm_paymentSchedule_NA = 0,
  NotePurchaseAgreementShortForm_paymentSchedule_BALLOON = 1,
  NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED = 2,
  NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED_BALLOON = 3,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementShortFormPaymentScheduleFromJSON(
  object: any,
): ENotePurchaseAgreementShortFormPaymentSchedule {
  switch (object) {
    case 0:
    case "NotePurchaseAgreementShortForm_paymentSchedule_NA":
      return ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_NA;
    case 1:
    case "NotePurchaseAgreementShortForm_paymentSchedule_BALLOON":
      return ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_BALLOON;
    case 2:
    case "NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED":
      return ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED;
    case 3:
    case "NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED_BALLOON":
      return ENotePurchaseAgreementShortFormPaymentSchedule
        .NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED_BALLOON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementShortFormPaymentSchedule.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementShortFormPaymentScheduleToJSON(
  object: ENotePurchaseAgreementShortFormPaymentSchedule,
): string {
  switch (object) {
    case ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_NA:
      return "NotePurchaseAgreementShortForm_paymentSchedule_NA";
    case ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_BALLOON:
      return "NotePurchaseAgreementShortForm_paymentSchedule_BALLOON";
    case ENotePurchaseAgreementShortFormPaymentSchedule.NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED:
      return "NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED";
    case ENotePurchaseAgreementShortFormPaymentSchedule
      .NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED_BALLOON:
      return "NotePurchaseAgreementShortForm_paymentSchedule_AMORTIZED_BALLOON";
    case ENotePurchaseAgreementShortFormPaymentSchedule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MNotePurchaseAgreementShortFormCompanyEntityType {
  optionDbValue: ENotePurchaseAgreementShortFormCompanyEntityType;
  other: string;
}

export interface MNotePurchaseAgreementShortFormJurisdictions {
  optionDbValue: ENotePurchaseAgreementShortFormJurisdictions;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure31 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure31;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure32 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure32;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure33 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure33;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure34 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure34;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure35 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure35;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure36 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure36;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure37 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure37;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure38 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure38;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure39 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure39;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure310 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure310;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure311 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure311;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure312 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure312;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure313 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure313;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure314 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure314;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure41 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure41;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure42 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure42;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure43 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure43;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure44 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure44;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure45 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure45;
  other: string;
}

export interface MNotePurchaseAgreementShortFormDisclosure46 {
  optionDbValue: ENotePurchaseAgreementShortFormDisclosure46;
  other: string;
}

export interface MNotePurchaseAgreementShortFormPaymentSchedule {
  optionDbValue: ENotePurchaseAgreementShortFormPaymentSchedule;
  other: string;
}

export interface NotePurchaseAgreementShortForm {
  companyFull: string;
  companyState: string;
  companyEntityType: MNotePurchaseAgreementShortFormCompanyEntityType | undefined;
  effectiveDate: Date | undefined;
  lenderFull: string;
  principalAmount: number;
  contractThreshold: number;
  financialStatementsDate: Date | undefined;
  governLawState: string;
  jurisdictions: MNotePurchaseAgreementShortFormJurisdictions | undefined;
  complianceSigner: string;
  tableAdvances: PTable | undefined;
  baseInterestRate: number;
  maturityDate: Date | undefined;
  defaultInterestRate: number;
  venue: string;
  companyAddress: string;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyPerson: string;
  companyNoticeCopyEmail: string;
  lenderAddress: string;
  holderNoticeEmail: string;
  holderNoticePerson: string;
  companyNoticeCopy: InternalBoolean;
  disclosure31: MNotePurchaseAgreementShortFormDisclosure31 | undefined;
  disclosure32: MNotePurchaseAgreementShortFormDisclosure32 | undefined;
  disclosure33: MNotePurchaseAgreementShortFormDisclosure33 | undefined;
  disclosure34: MNotePurchaseAgreementShortFormDisclosure34 | undefined;
  disclosure35: MNotePurchaseAgreementShortFormDisclosure35 | undefined;
  disclosure36: MNotePurchaseAgreementShortFormDisclosure36 | undefined;
  disclosure37: MNotePurchaseAgreementShortFormDisclosure37 | undefined;
  disclosure38: MNotePurchaseAgreementShortFormDisclosure38 | undefined;
  disclosure39: MNotePurchaseAgreementShortFormDisclosure39 | undefined;
  disclosure310: MNotePurchaseAgreementShortFormDisclosure310 | undefined;
  disclosure311: MNotePurchaseAgreementShortFormDisclosure311 | undefined;
  disclosure312: MNotePurchaseAgreementShortFormDisclosure312 | undefined;
  disclosure313: MNotePurchaseAgreementShortFormDisclosure313 | undefined;
  disclosure314: MNotePurchaseAgreementShortFormDisclosure314 | undefined;
  disclosure41: MNotePurchaseAgreementShortFormDisclosure41 | undefined;
  disclosure42: MNotePurchaseAgreementShortFormDisclosure42 | undefined;
  disclosure43: MNotePurchaseAgreementShortFormDisclosure43 | undefined;
  disclosure44: MNotePurchaseAgreementShortFormDisclosure44 | undefined;
  disclosure45: MNotePurchaseAgreementShortFormDisclosure45 | undefined;
  disclosure46: MNotePurchaseAgreementShortFormDisclosure46 | undefined;
  pastAdvances: InternalBoolean;
  deferMonths: number;
  amortizedMonths: number;
  paymentSchedule: MNotePurchaseAgreementShortFormPaymentSchedule | undefined;
  deferPayment: InternalBoolean;
}

function createBaseMNotePurchaseAgreementShortFormCompanyEntityType(): MNotePurchaseAgreementShortFormCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormCompanyEntityType = {
  encode(
    message: MNotePurchaseAgreementShortFormCompanyEntityType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormCompanyEntityType>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormCompanyEntityType {
    return MNotePurchaseAgreementShortFormCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormCompanyEntityType>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormCompanyEntityType {
    const message = createBaseMNotePurchaseAgreementShortFormCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormJurisdictions(): MNotePurchaseAgreementShortFormJurisdictions {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormJurisdictions = {
  encode(message: MNotePurchaseAgreementShortFormJurisdictions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormJurisdictions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormJurisdictions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormJurisdictions {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormJurisdictionsFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormJurisdictions): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormJurisdictionsToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormJurisdictions>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormJurisdictions {
    return MNotePurchaseAgreementShortFormJurisdictions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormJurisdictions>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormJurisdictions {
    const message = createBaseMNotePurchaseAgreementShortFormJurisdictions();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure31(): MNotePurchaseAgreementShortFormDisclosure31 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure31 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure31, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure31 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure31();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure31 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure31FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure31): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure31ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure31>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure31 {
    return MNotePurchaseAgreementShortFormDisclosure31.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure31>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure31 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure31();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure32(): MNotePurchaseAgreementShortFormDisclosure32 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure32 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure32, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure32 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure32();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure32 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure32FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure32): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure32ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure32>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure32 {
    return MNotePurchaseAgreementShortFormDisclosure32.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure32>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure32 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure32();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure33(): MNotePurchaseAgreementShortFormDisclosure33 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure33 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure33, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure33 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure33();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure33 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure33FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure33): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure33ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure33>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure33 {
    return MNotePurchaseAgreementShortFormDisclosure33.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure33>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure33 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure33();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure34(): MNotePurchaseAgreementShortFormDisclosure34 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure34 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure34, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure34 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure34();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure34 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure34FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure34): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure34ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure34>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure34 {
    return MNotePurchaseAgreementShortFormDisclosure34.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure34>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure34 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure34();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure35(): MNotePurchaseAgreementShortFormDisclosure35 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure35 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure35, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure35 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure35();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure35 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure35FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure35): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure35ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure35>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure35 {
    return MNotePurchaseAgreementShortFormDisclosure35.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure35>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure35 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure35();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure36(): MNotePurchaseAgreementShortFormDisclosure36 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure36 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure36, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure36 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure36();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure36 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure36FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure36): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure36ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure36>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure36 {
    return MNotePurchaseAgreementShortFormDisclosure36.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure36>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure36 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure36();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure37(): MNotePurchaseAgreementShortFormDisclosure37 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure37 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure37, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure37 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure37();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure37 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure37FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure37): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure37ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure37>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure37 {
    return MNotePurchaseAgreementShortFormDisclosure37.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure37>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure37 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure37();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure38(): MNotePurchaseAgreementShortFormDisclosure38 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure38 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure38, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure38 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure38();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure38 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure38FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure38): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure38ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure38>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure38 {
    return MNotePurchaseAgreementShortFormDisclosure38.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure38>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure38 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure38();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure39(): MNotePurchaseAgreementShortFormDisclosure39 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure39 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure39, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure39 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure39();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure39 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure39FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure39): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure39ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure39>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure39 {
    return MNotePurchaseAgreementShortFormDisclosure39.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure39>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure39 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure39();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure310(): MNotePurchaseAgreementShortFormDisclosure310 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure310 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure310, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure310 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure310();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure310 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure310FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure310): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure310ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure310>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure310 {
    return MNotePurchaseAgreementShortFormDisclosure310.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure310>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure310 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure310();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure311(): MNotePurchaseAgreementShortFormDisclosure311 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure311 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure311, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure311 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure311();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure311 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure311FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure311): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure311ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure311>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure311 {
    return MNotePurchaseAgreementShortFormDisclosure311.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure311>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure311 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure311();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure312(): MNotePurchaseAgreementShortFormDisclosure312 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure312 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure312, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure312 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure312();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure312 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure312FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure312): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure312ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure312>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure312 {
    return MNotePurchaseAgreementShortFormDisclosure312.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure312>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure312 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure312();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure313(): MNotePurchaseAgreementShortFormDisclosure313 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure313 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure313, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure313 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure313();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure313 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure313FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure313): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure313ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure313>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure313 {
    return MNotePurchaseAgreementShortFormDisclosure313.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure313>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure313 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure313();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure314(): MNotePurchaseAgreementShortFormDisclosure314 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure314 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure314, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure314 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure314();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure314 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure314FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure314): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure314ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure314>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure314 {
    return MNotePurchaseAgreementShortFormDisclosure314.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure314>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure314 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure314();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure41(): MNotePurchaseAgreementShortFormDisclosure41 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure41 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure41, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure41 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure41();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure41 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure41FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure41): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure41ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure41>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure41 {
    return MNotePurchaseAgreementShortFormDisclosure41.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure41>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure41 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure41();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure42(): MNotePurchaseAgreementShortFormDisclosure42 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure42 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure42, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure42 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure42();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure42 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure42FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure42): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure42ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure42>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure42 {
    return MNotePurchaseAgreementShortFormDisclosure42.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure42>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure42 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure42();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure43(): MNotePurchaseAgreementShortFormDisclosure43 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure43 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure43, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure43 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure43();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure43 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure43FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure43): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure43ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure43>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure43 {
    return MNotePurchaseAgreementShortFormDisclosure43.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure43>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure43 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure43();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure44(): MNotePurchaseAgreementShortFormDisclosure44 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure44 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure44, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure44 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure44();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure44 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure44FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure44): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure44ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure44>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure44 {
    return MNotePurchaseAgreementShortFormDisclosure44.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure44>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure44 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure44();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure45(): MNotePurchaseAgreementShortFormDisclosure45 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure45 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure45, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure45 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure45();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure45 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure45FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure45): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure45ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure45>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure45 {
    return MNotePurchaseAgreementShortFormDisclosure45.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure45>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure45 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure45();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormDisclosure46(): MNotePurchaseAgreementShortFormDisclosure46 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormDisclosure46 = {
  encode(message: MNotePurchaseAgreementShortFormDisclosure46, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormDisclosure46 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure46();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormDisclosure46 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormDisclosure46FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormDisclosure46): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormDisclosure46ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure46>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormDisclosure46 {
    return MNotePurchaseAgreementShortFormDisclosure46.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormDisclosure46>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormDisclosure46 {
    const message = createBaseMNotePurchaseAgreementShortFormDisclosure46();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementShortFormPaymentSchedule(): MNotePurchaseAgreementShortFormPaymentSchedule {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementShortFormPaymentSchedule = {
  encode(
    message: MNotePurchaseAgreementShortFormPaymentSchedule,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementShortFormPaymentSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementShortFormPaymentSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementShortFormPaymentSchedule {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementShortFormPaymentScheduleFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementShortFormPaymentSchedule): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementShortFormPaymentScheduleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormPaymentSchedule>, I>>(
    base?: I,
  ): MNotePurchaseAgreementShortFormPaymentSchedule {
    return MNotePurchaseAgreementShortFormPaymentSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementShortFormPaymentSchedule>, I>>(
    object: I,
  ): MNotePurchaseAgreementShortFormPaymentSchedule {
    const message = createBaseMNotePurchaseAgreementShortFormPaymentSchedule();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseNotePurchaseAgreementShortForm(): NotePurchaseAgreementShortForm {
  return {
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    effectiveDate: undefined,
    lenderFull: "",
    principalAmount: 0,
    contractThreshold: 0,
    financialStatementsDate: undefined,
    governLawState: "",
    jurisdictions: undefined,
    complianceSigner: "",
    tableAdvances: undefined,
    baseInterestRate: 0,
    maturityDate: undefined,
    defaultInterestRate: 0,
    venue: "",
    companyAddress: "",
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyPerson: "",
    companyNoticeCopyEmail: "",
    lenderAddress: "",
    holderNoticeEmail: "",
    holderNoticePerson: "",
    companyNoticeCopy: 0,
    disclosure31: undefined,
    disclosure32: undefined,
    disclosure33: undefined,
    disclosure34: undefined,
    disclosure35: undefined,
    disclosure36: undefined,
    disclosure37: undefined,
    disclosure38: undefined,
    disclosure39: undefined,
    disclosure310: undefined,
    disclosure311: undefined,
    disclosure312: undefined,
    disclosure313: undefined,
    disclosure314: undefined,
    disclosure41: undefined,
    disclosure42: undefined,
    disclosure43: undefined,
    disclosure44: undefined,
    disclosure45: undefined,
    disclosure46: undefined,
    pastAdvances: 0,
    deferMonths: 0,
    amortizedMonths: 0,
    paymentSchedule: undefined,
    deferPayment: 0,
  };
}

export const NotePurchaseAgreementShortForm = {
  encode(message: NotePurchaseAgreementShortForm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MNotePurchaseAgreementShortFormCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork())
        .ldelim();
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.lenderFull !== "") {
      writer.uint32(42).string(message.lenderFull);
    }
    if (message.principalAmount !== 0) {
      writer.uint32(53).float(message.principalAmount);
    }
    if (message.contractThreshold !== 0) {
      writer.uint32(61).float(message.contractThreshold);
    }
    if (message.financialStatementsDate !== undefined) {
      Timestamp.encode(toTimestamp(message.financialStatementsDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(74).string(message.governLawState);
    }
    if (message.jurisdictions !== undefined) {
      MNotePurchaseAgreementShortFormJurisdictions.encode(message.jurisdictions, writer.uint32(82).fork()).ldelim();
    }
    if (message.complianceSigner !== "") {
      writer.uint32(90).string(message.complianceSigner);
    }
    if (message.tableAdvances !== undefined) {
      PTable.encode(message.tableAdvances, writer.uint32(98).fork()).ldelim();
    }
    if (message.baseInterestRate !== 0) {
      writer.uint32(109).float(message.baseInterestRate);
    }
    if (message.maturityDate !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityDate), writer.uint32(114).fork()).ldelim();
    }
    if (message.defaultInterestRate !== 0) {
      writer.uint32(125).float(message.defaultInterestRate);
    }
    if (message.venue !== "") {
      writer.uint32(130).string(message.venue);
    }
    if (message.companyAddress !== "") {
      writer.uint32(138).string(message.companyAddress);
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(146).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(154).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(162).string(message.companyNoticeCopyPerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(170).string(message.companyNoticeCopyEmail);
    }
    if (message.lenderAddress !== "") {
      writer.uint32(178).string(message.lenderAddress);
    }
    if (message.holderNoticeEmail !== "") {
      writer.uint32(186).string(message.holderNoticeEmail);
    }
    if (message.holderNoticePerson !== "") {
      writer.uint32(194).string(message.holderNoticePerson);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(200).int32(message.companyNoticeCopy);
    }
    if (message.disclosure31 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure31.encode(message.disclosure31, writer.uint32(210).fork()).ldelim();
    }
    if (message.disclosure32 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure32.encode(message.disclosure32, writer.uint32(218).fork()).ldelim();
    }
    if (message.disclosure33 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure33.encode(message.disclosure33, writer.uint32(226).fork()).ldelim();
    }
    if (message.disclosure34 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure34.encode(message.disclosure34, writer.uint32(234).fork()).ldelim();
    }
    if (message.disclosure35 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure35.encode(message.disclosure35, writer.uint32(242).fork()).ldelim();
    }
    if (message.disclosure36 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure36.encode(message.disclosure36, writer.uint32(250).fork()).ldelim();
    }
    if (message.disclosure37 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure37.encode(message.disclosure37, writer.uint32(258).fork()).ldelim();
    }
    if (message.disclosure38 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure38.encode(message.disclosure38, writer.uint32(266).fork()).ldelim();
    }
    if (message.disclosure39 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure39.encode(message.disclosure39, writer.uint32(274).fork()).ldelim();
    }
    if (message.disclosure310 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure310.encode(message.disclosure310, writer.uint32(282).fork()).ldelim();
    }
    if (message.disclosure311 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure311.encode(message.disclosure311, writer.uint32(290).fork()).ldelim();
    }
    if (message.disclosure312 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure312.encode(message.disclosure312, writer.uint32(298).fork()).ldelim();
    }
    if (message.disclosure313 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure313.encode(message.disclosure313, writer.uint32(306).fork()).ldelim();
    }
    if (message.disclosure314 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure314.encode(message.disclosure314, writer.uint32(314).fork()).ldelim();
    }
    if (message.disclosure41 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure41.encode(message.disclosure41, writer.uint32(322).fork()).ldelim();
    }
    if (message.disclosure42 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure42.encode(message.disclosure42, writer.uint32(330).fork()).ldelim();
    }
    if (message.disclosure43 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure43.encode(message.disclosure43, writer.uint32(338).fork()).ldelim();
    }
    if (message.disclosure44 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure44.encode(message.disclosure44, writer.uint32(346).fork()).ldelim();
    }
    if (message.disclosure45 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure45.encode(message.disclosure45, writer.uint32(354).fork()).ldelim();
    }
    if (message.disclosure46 !== undefined) {
      MNotePurchaseAgreementShortFormDisclosure46.encode(message.disclosure46, writer.uint32(362).fork()).ldelim();
    }
    if (message.pastAdvances !== 0) {
      writer.uint32(368).int32(message.pastAdvances);
    }
    if (message.deferMonths !== 0) {
      writer.uint32(381).float(message.deferMonths);
    }
    if (message.amortizedMonths !== 0) {
      writer.uint32(389).float(message.amortizedMonths);
    }
    if (message.paymentSchedule !== undefined) {
      MNotePurchaseAgreementShortFormPaymentSchedule.encode(message.paymentSchedule, writer.uint32(394).fork())
        .ldelim();
    }
    if (message.deferPayment !== 0) {
      writer.uint32(400).int32(message.deferPayment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotePurchaseAgreementShortForm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotePurchaseAgreementShortForm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MNotePurchaseAgreementShortFormCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.lenderFull = reader.string();
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.principalAmount = reader.float();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.contractThreshold = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.financialStatementsDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.jurisdictions = MNotePurchaseAgreementShortFormJurisdictions.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.complianceSigner = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.tableAdvances = PTable.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag != 109) {
            break;
          }

          message.baseInterestRate = reader.float();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.maturityDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag != 125) {
            break;
          }

          message.defaultInterestRate = reader.float();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.lenderAddress = reader.string();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.holderNoticeEmail = reader.string();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.holderNoticePerson = reader.string();
          continue;
        case 25:
          if (tag != 200) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.disclosure31 = MNotePurchaseAgreementShortFormDisclosure31.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.disclosure32 = MNotePurchaseAgreementShortFormDisclosure32.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.disclosure33 = MNotePurchaseAgreementShortFormDisclosure33.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.disclosure34 = MNotePurchaseAgreementShortFormDisclosure34.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.disclosure35 = MNotePurchaseAgreementShortFormDisclosure35.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.disclosure36 = MNotePurchaseAgreementShortFormDisclosure36.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.disclosure37 = MNotePurchaseAgreementShortFormDisclosure37.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.disclosure38 = MNotePurchaseAgreementShortFormDisclosure38.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.disclosure39 = MNotePurchaseAgreementShortFormDisclosure39.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.disclosure310 = MNotePurchaseAgreementShortFormDisclosure310.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.disclosure311 = MNotePurchaseAgreementShortFormDisclosure311.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.disclosure312 = MNotePurchaseAgreementShortFormDisclosure312.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.disclosure313 = MNotePurchaseAgreementShortFormDisclosure313.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.disclosure314 = MNotePurchaseAgreementShortFormDisclosure314.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.disclosure41 = MNotePurchaseAgreementShortFormDisclosure41.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.disclosure42 = MNotePurchaseAgreementShortFormDisclosure42.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.disclosure43 = MNotePurchaseAgreementShortFormDisclosure43.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag != 346) {
            break;
          }

          message.disclosure44 = MNotePurchaseAgreementShortFormDisclosure44.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag != 354) {
            break;
          }

          message.disclosure45 = MNotePurchaseAgreementShortFormDisclosure45.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag != 362) {
            break;
          }

          message.disclosure46 = MNotePurchaseAgreementShortFormDisclosure46.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag != 368) {
            break;
          }

          message.pastAdvances = reader.int32() as any;
          continue;
        case 47:
          if (tag != 381) {
            break;
          }

          message.deferMonths = reader.float();
          continue;
        case 48:
          if (tag != 389) {
            break;
          }

          message.amortizedMonths = reader.float();
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.paymentSchedule = MNotePurchaseAgreementShortFormPaymentSchedule.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag != 400) {
            break;
          }

          message.deferPayment = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotePurchaseAgreementShortForm {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MNotePurchaseAgreementShortFormCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      lenderFull: isSet(object.lenderFull) ? String(object.lenderFull) : "",
      principalAmount: isSet(object.principalAmount) ? Number(object.principalAmount) : 0,
      contractThreshold: isSet(object.contractThreshold) ? Number(object.contractThreshold) : 0,
      financialStatementsDate: isSet(object.financialStatementsDate)
        ? fromJsonTimestamp(object.financialStatementsDate)
        : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      jurisdictions: isSet(object.jurisdictions)
        ? MNotePurchaseAgreementShortFormJurisdictions.fromJSON(object.jurisdictions)
        : undefined,
      complianceSigner: isSet(object.complianceSigner) ? String(object.complianceSigner) : "",
      tableAdvances: isSet(object.tableAdvances) ? PTable.fromJSON(object.tableAdvances) : undefined,
      baseInterestRate: isSet(object.baseInterestRate) ? Number(object.baseInterestRate) : 0,
      maturityDate: isSet(object.maturityDate) ? fromJsonTimestamp(object.maturityDate) : undefined,
      defaultInterestRate: isSet(object.defaultInterestRate) ? Number(object.defaultInterestRate) : 0,
      venue: isSet(object.venue) ? String(object.venue) : "",
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      lenderAddress: isSet(object.lenderAddress) ? String(object.lenderAddress) : "",
      holderNoticeEmail: isSet(object.holderNoticeEmail) ? String(object.holderNoticeEmail) : "",
      holderNoticePerson: isSet(object.holderNoticePerson) ? String(object.holderNoticePerson) : "",
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      disclosure31: isSet(object.disclosure31)
        ? MNotePurchaseAgreementShortFormDisclosure31.fromJSON(object.disclosure31)
        : undefined,
      disclosure32: isSet(object.disclosure32)
        ? MNotePurchaseAgreementShortFormDisclosure32.fromJSON(object.disclosure32)
        : undefined,
      disclosure33: isSet(object.disclosure33)
        ? MNotePurchaseAgreementShortFormDisclosure33.fromJSON(object.disclosure33)
        : undefined,
      disclosure34: isSet(object.disclosure34)
        ? MNotePurchaseAgreementShortFormDisclosure34.fromJSON(object.disclosure34)
        : undefined,
      disclosure35: isSet(object.disclosure35)
        ? MNotePurchaseAgreementShortFormDisclosure35.fromJSON(object.disclosure35)
        : undefined,
      disclosure36: isSet(object.disclosure36)
        ? MNotePurchaseAgreementShortFormDisclosure36.fromJSON(object.disclosure36)
        : undefined,
      disclosure37: isSet(object.disclosure37)
        ? MNotePurchaseAgreementShortFormDisclosure37.fromJSON(object.disclosure37)
        : undefined,
      disclosure38: isSet(object.disclosure38)
        ? MNotePurchaseAgreementShortFormDisclosure38.fromJSON(object.disclosure38)
        : undefined,
      disclosure39: isSet(object.disclosure39)
        ? MNotePurchaseAgreementShortFormDisclosure39.fromJSON(object.disclosure39)
        : undefined,
      disclosure310: isSet(object.disclosure310)
        ? MNotePurchaseAgreementShortFormDisclosure310.fromJSON(object.disclosure310)
        : undefined,
      disclosure311: isSet(object.disclosure311)
        ? MNotePurchaseAgreementShortFormDisclosure311.fromJSON(object.disclosure311)
        : undefined,
      disclosure312: isSet(object.disclosure312)
        ? MNotePurchaseAgreementShortFormDisclosure312.fromJSON(object.disclosure312)
        : undefined,
      disclosure313: isSet(object.disclosure313)
        ? MNotePurchaseAgreementShortFormDisclosure313.fromJSON(object.disclosure313)
        : undefined,
      disclosure314: isSet(object.disclosure314)
        ? MNotePurchaseAgreementShortFormDisclosure314.fromJSON(object.disclosure314)
        : undefined,
      disclosure41: isSet(object.disclosure41)
        ? MNotePurchaseAgreementShortFormDisclosure41.fromJSON(object.disclosure41)
        : undefined,
      disclosure42: isSet(object.disclosure42)
        ? MNotePurchaseAgreementShortFormDisclosure42.fromJSON(object.disclosure42)
        : undefined,
      disclosure43: isSet(object.disclosure43)
        ? MNotePurchaseAgreementShortFormDisclosure43.fromJSON(object.disclosure43)
        : undefined,
      disclosure44: isSet(object.disclosure44)
        ? MNotePurchaseAgreementShortFormDisclosure44.fromJSON(object.disclosure44)
        : undefined,
      disclosure45: isSet(object.disclosure45)
        ? MNotePurchaseAgreementShortFormDisclosure45.fromJSON(object.disclosure45)
        : undefined,
      disclosure46: isSet(object.disclosure46)
        ? MNotePurchaseAgreementShortFormDisclosure46.fromJSON(object.disclosure46)
        : undefined,
      pastAdvances: isSet(object.pastAdvances) ? internalBooleanFromJSON(object.pastAdvances) : 0,
      deferMonths: isSet(object.deferMonths) ? Number(object.deferMonths) : 0,
      amortizedMonths: isSet(object.amortizedMonths) ? Number(object.amortizedMonths) : 0,
      paymentSchedule: isSet(object.paymentSchedule)
        ? MNotePurchaseAgreementShortFormPaymentSchedule.fromJSON(object.paymentSchedule)
        : undefined,
      deferPayment: isSet(object.deferPayment) ? internalBooleanFromJSON(object.deferPayment) : 0,
    };
  },

  toJSON(message: NotePurchaseAgreementShortForm): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MNotePurchaseAgreementShortFormCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.lenderFull !== undefined && (obj.lenderFull = message.lenderFull);
    message.principalAmount !== undefined && (obj.principalAmount = message.principalAmount);
    message.contractThreshold !== undefined && (obj.contractThreshold = message.contractThreshold);
    message.financialStatementsDate !== undefined &&
      (obj.financialStatementsDate = message.financialStatementsDate.toISOString());
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.jurisdictions !== undefined && (obj.jurisdictions = message.jurisdictions
      ? MNotePurchaseAgreementShortFormJurisdictions.toJSON(message.jurisdictions)
      : undefined);
    message.complianceSigner !== undefined && (obj.complianceSigner = message.complianceSigner);
    message.tableAdvances !== undefined &&
      (obj.tableAdvances = message.tableAdvances ? PTable.toJSON(message.tableAdvances) : undefined);
    message.baseInterestRate !== undefined && (obj.baseInterestRate = message.baseInterestRate);
    message.maturityDate !== undefined && (obj.maturityDate = message.maturityDate.toISOString());
    message.defaultInterestRate !== undefined && (obj.defaultInterestRate = message.defaultInterestRate);
    message.venue !== undefined && (obj.venue = message.venue);
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.lenderAddress !== undefined && (obj.lenderAddress = message.lenderAddress);
    message.holderNoticeEmail !== undefined && (obj.holderNoticeEmail = message.holderNoticeEmail);
    message.holderNoticePerson !== undefined && (obj.holderNoticePerson = message.holderNoticePerson);
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.disclosure31 !== undefined && (obj.disclosure31 = message.disclosure31
      ? MNotePurchaseAgreementShortFormDisclosure31.toJSON(message.disclosure31)
      : undefined);
    message.disclosure32 !== undefined && (obj.disclosure32 = message.disclosure32
      ? MNotePurchaseAgreementShortFormDisclosure32.toJSON(message.disclosure32)
      : undefined);
    message.disclosure33 !== undefined && (obj.disclosure33 = message.disclosure33
      ? MNotePurchaseAgreementShortFormDisclosure33.toJSON(message.disclosure33)
      : undefined);
    message.disclosure34 !== undefined && (obj.disclosure34 = message.disclosure34
      ? MNotePurchaseAgreementShortFormDisclosure34.toJSON(message.disclosure34)
      : undefined);
    message.disclosure35 !== undefined && (obj.disclosure35 = message.disclosure35
      ? MNotePurchaseAgreementShortFormDisclosure35.toJSON(message.disclosure35)
      : undefined);
    message.disclosure36 !== undefined && (obj.disclosure36 = message.disclosure36
      ? MNotePurchaseAgreementShortFormDisclosure36.toJSON(message.disclosure36)
      : undefined);
    message.disclosure37 !== undefined && (obj.disclosure37 = message.disclosure37
      ? MNotePurchaseAgreementShortFormDisclosure37.toJSON(message.disclosure37)
      : undefined);
    message.disclosure38 !== undefined && (obj.disclosure38 = message.disclosure38
      ? MNotePurchaseAgreementShortFormDisclosure38.toJSON(message.disclosure38)
      : undefined);
    message.disclosure39 !== undefined && (obj.disclosure39 = message.disclosure39
      ? MNotePurchaseAgreementShortFormDisclosure39.toJSON(message.disclosure39)
      : undefined);
    message.disclosure310 !== undefined && (obj.disclosure310 = message.disclosure310
      ? MNotePurchaseAgreementShortFormDisclosure310.toJSON(message.disclosure310)
      : undefined);
    message.disclosure311 !== undefined && (obj.disclosure311 = message.disclosure311
      ? MNotePurchaseAgreementShortFormDisclosure311.toJSON(message.disclosure311)
      : undefined);
    message.disclosure312 !== undefined && (obj.disclosure312 = message.disclosure312
      ? MNotePurchaseAgreementShortFormDisclosure312.toJSON(message.disclosure312)
      : undefined);
    message.disclosure313 !== undefined && (obj.disclosure313 = message.disclosure313
      ? MNotePurchaseAgreementShortFormDisclosure313.toJSON(message.disclosure313)
      : undefined);
    message.disclosure314 !== undefined && (obj.disclosure314 = message.disclosure314
      ? MNotePurchaseAgreementShortFormDisclosure314.toJSON(message.disclosure314)
      : undefined);
    message.disclosure41 !== undefined && (obj.disclosure41 = message.disclosure41
      ? MNotePurchaseAgreementShortFormDisclosure41.toJSON(message.disclosure41)
      : undefined);
    message.disclosure42 !== undefined && (obj.disclosure42 = message.disclosure42
      ? MNotePurchaseAgreementShortFormDisclosure42.toJSON(message.disclosure42)
      : undefined);
    message.disclosure43 !== undefined && (obj.disclosure43 = message.disclosure43
      ? MNotePurchaseAgreementShortFormDisclosure43.toJSON(message.disclosure43)
      : undefined);
    message.disclosure44 !== undefined && (obj.disclosure44 = message.disclosure44
      ? MNotePurchaseAgreementShortFormDisclosure44.toJSON(message.disclosure44)
      : undefined);
    message.disclosure45 !== undefined && (obj.disclosure45 = message.disclosure45
      ? MNotePurchaseAgreementShortFormDisclosure45.toJSON(message.disclosure45)
      : undefined);
    message.disclosure46 !== undefined && (obj.disclosure46 = message.disclosure46
      ? MNotePurchaseAgreementShortFormDisclosure46.toJSON(message.disclosure46)
      : undefined);
    message.pastAdvances !== undefined && (obj.pastAdvances = internalBooleanToJSON(message.pastAdvances));
    message.deferMonths !== undefined && (obj.deferMonths = message.deferMonths);
    message.amortizedMonths !== undefined && (obj.amortizedMonths = message.amortizedMonths);
    message.paymentSchedule !== undefined && (obj.paymentSchedule = message.paymentSchedule
      ? MNotePurchaseAgreementShortFormPaymentSchedule.toJSON(message.paymentSchedule)
      : undefined);
    message.deferPayment !== undefined && (obj.deferPayment = internalBooleanToJSON(message.deferPayment));
    return obj;
  },

  create<I extends Exact<DeepPartial<NotePurchaseAgreementShortForm>, I>>(base?: I): NotePurchaseAgreementShortForm {
    return NotePurchaseAgreementShortForm.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotePurchaseAgreementShortForm>, I>>(
    object: I,
  ): NotePurchaseAgreementShortForm {
    const message = createBaseNotePurchaseAgreementShortForm();
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MNotePurchaseAgreementShortFormCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.lenderFull = object.lenderFull ?? "";
    message.principalAmount = object.principalAmount ?? 0;
    message.contractThreshold = object.contractThreshold ?? 0;
    message.financialStatementsDate = object.financialStatementsDate ?? undefined;
    message.governLawState = object.governLawState ?? "";
    message.jurisdictions = (object.jurisdictions !== undefined && object.jurisdictions !== null)
      ? MNotePurchaseAgreementShortFormJurisdictions.fromPartial(object.jurisdictions)
      : undefined;
    message.complianceSigner = object.complianceSigner ?? "";
    message.tableAdvances = (object.tableAdvances !== undefined && object.tableAdvances !== null)
      ? PTable.fromPartial(object.tableAdvances)
      : undefined;
    message.baseInterestRate = object.baseInterestRate ?? 0;
    message.maturityDate = object.maturityDate ?? undefined;
    message.defaultInterestRate = object.defaultInterestRate ?? 0;
    message.venue = object.venue ?? "";
    message.companyAddress = object.companyAddress ?? "";
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.lenderAddress = object.lenderAddress ?? "";
    message.holderNoticeEmail = object.holderNoticeEmail ?? "";
    message.holderNoticePerson = object.holderNoticePerson ?? "";
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.disclosure31 = (object.disclosure31 !== undefined && object.disclosure31 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure31.fromPartial(object.disclosure31)
      : undefined;
    message.disclosure32 = (object.disclosure32 !== undefined && object.disclosure32 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure32.fromPartial(object.disclosure32)
      : undefined;
    message.disclosure33 = (object.disclosure33 !== undefined && object.disclosure33 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure33.fromPartial(object.disclosure33)
      : undefined;
    message.disclosure34 = (object.disclosure34 !== undefined && object.disclosure34 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure34.fromPartial(object.disclosure34)
      : undefined;
    message.disclosure35 = (object.disclosure35 !== undefined && object.disclosure35 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure35.fromPartial(object.disclosure35)
      : undefined;
    message.disclosure36 = (object.disclosure36 !== undefined && object.disclosure36 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure36.fromPartial(object.disclosure36)
      : undefined;
    message.disclosure37 = (object.disclosure37 !== undefined && object.disclosure37 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure37.fromPartial(object.disclosure37)
      : undefined;
    message.disclosure38 = (object.disclosure38 !== undefined && object.disclosure38 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure38.fromPartial(object.disclosure38)
      : undefined;
    message.disclosure39 = (object.disclosure39 !== undefined && object.disclosure39 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure39.fromPartial(object.disclosure39)
      : undefined;
    message.disclosure310 = (object.disclosure310 !== undefined && object.disclosure310 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure310.fromPartial(object.disclosure310)
      : undefined;
    message.disclosure311 = (object.disclosure311 !== undefined && object.disclosure311 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure311.fromPartial(object.disclosure311)
      : undefined;
    message.disclosure312 = (object.disclosure312 !== undefined && object.disclosure312 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure312.fromPartial(object.disclosure312)
      : undefined;
    message.disclosure313 = (object.disclosure313 !== undefined && object.disclosure313 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure313.fromPartial(object.disclosure313)
      : undefined;
    message.disclosure314 = (object.disclosure314 !== undefined && object.disclosure314 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure314.fromPartial(object.disclosure314)
      : undefined;
    message.disclosure41 = (object.disclosure41 !== undefined && object.disclosure41 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure41.fromPartial(object.disclosure41)
      : undefined;
    message.disclosure42 = (object.disclosure42 !== undefined && object.disclosure42 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure42.fromPartial(object.disclosure42)
      : undefined;
    message.disclosure43 = (object.disclosure43 !== undefined && object.disclosure43 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure43.fromPartial(object.disclosure43)
      : undefined;
    message.disclosure44 = (object.disclosure44 !== undefined && object.disclosure44 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure44.fromPartial(object.disclosure44)
      : undefined;
    message.disclosure45 = (object.disclosure45 !== undefined && object.disclosure45 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure45.fromPartial(object.disclosure45)
      : undefined;
    message.disclosure46 = (object.disclosure46 !== undefined && object.disclosure46 !== null)
      ? MNotePurchaseAgreementShortFormDisclosure46.fromPartial(object.disclosure46)
      : undefined;
    message.pastAdvances = object.pastAdvances ?? 0;
    message.deferMonths = object.deferMonths ?? 0;
    message.amortizedMonths = object.amortizedMonths ?? 0;
    message.paymentSchedule = (object.paymentSchedule !== undefined && object.paymentSchedule !== null)
      ? MNotePurchaseAgreementShortFormPaymentSchedule.fromPartial(object.paymentSchedule)
      : undefined;
    message.deferPayment = object.deferPayment ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
