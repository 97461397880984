import React, { InputHTMLAttributes, useState } from "react";

interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "size"> {
    label?: string;
    dataList?: any;
    size?: "xs" | "sm" | "md" | "lg";
    onChange?: (name: string, value: any, options: { event: React.ChangeEvent<HTMLInputElement> }) => void;
}

const sizes = {
    xs: 10,
    sm: 14,
    md: 16,
    lg: 20,
};


// old
export const TextField = ({ label = "", dataList, size = "lg", onChange, ...props }: TextFieldProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        onChange?.(name, value, { event });
    };

    const h = sizes[size];

    return (
        <div className="flex gap-2 items-center">
            {label && <label className="text-base flex-shrink-0 ">{label}:</label>}
            <input
                {...props}
                className={`w-full text-gray-700 text-2xl rounded-md border h-${h} px-4 py-2 border-paleLilac focus:outline-primary ${props.className ? props.className : ""}`}
                list={`datalist-${props.name}`}
                onChange={handleChange}
            />
            <datalist style={{ maxHeight: "300px" }} id={`datalist-${props.name}`}>
                {dataList}
            </datalist>
        </div>
    );
};
