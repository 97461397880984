import { useDispatch, useSelector } from "react-redux";
import { MainStore } from "../../store/mainTypes";
import DocsBase from "./DocsBase";

export default function InboxDocs() {
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const dispatch = useDispatch()

    return <DocsBase relevantDocs={inboxDocs}
        caption="Awaiting my Signature"
        noDocsMsg="You currently don't have any docs awaiting your signature"
        mainCTA="View & Sign"
    />
}
