import { getAutoContracts } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import { AutoContractDocument } from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { PDoc } from "../proto/PDoc";
import { PLegalUnit } from "../proto/PLegalUnit";

function getDocCommon(u: PLegalUnit | undefined): AutoContractDocument | undefined {      
    const foundAutoContract = getAutoContracts().find((autoContract => u?.kind === autoContract.kind))

    if (foundAutoContract) {
        return foundAutoContract
    }

    return undefined
}

export function specificContractType(legalUnit?: PLegalUnit) {
    return getDocCommon(legalUnit)?.contract(legalUnit);
}

// TODO: improve this when removing old contracts
export function documentToName(doc: PDoc) {
    if (doc.legalUnit?.name) {
        return doc.legalUnit.name;
    }
    
    let contract = getDocCommon(doc.legalUnit)
    
    if ((contract as AutoContractDocument)?.namer) {
        return (contract as AutoContractDocument).namer(doc.legalUnit) || "Untitled";
    }

    return specificContractType(doc.legalUnit)?.name || "Untitled";
}

export function documentToTitle(doc: PDoc | undefined) {
    return getDocCommon(doc?.legalUnit)?.title
}

export function documentToImg(doc: PDoc | undefined) {
    return getDocCommon(doc?.legalUnit)?.image || ""
}
