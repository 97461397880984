import { PlanType } from "components/life-cycle/payment/PaymentPlans";
import { reportErrorToBE } from "utils";
import { PShopifyCheckoutUrlResponse } from "../proto/PApi";
import { request } from "./ApiCommon";

export async function updatePolicy({
    kind,
    content,
    docId,
}: {
    kind: string;
    content: string;
    docId: string;
}) {
    try {
        return await request("/api/v1/shopify/update-document", {
            method: "POST",
            data: {
                docId,
                kind,
                content,
            },
        });
    } catch (error) {
        reportErrorToBE({ methodName: "updatePolicy", error });
        throw error;
    }
}

export async function getCheckoutUrl(req: {
    cycle: PlanType;
    draftId: string;
    email?: string;
}): Promise<PShopifyCheckoutUrlResponse> {
    const fallbackUrl = fallbackUrls[req.cycle] + encodeURIComponent(req?.email || "");

    try {
        const response = await request(
            `/api/v1/shopify/checkout?cycle=${req.cycle}&draftId=${req.draftId}`,
            {
                method: "GET",
            }
        );

        if (response?.status !== 200) {
            return {
                checkoutUrl: fallbackUrl,
            };
        }
        return PShopifyCheckoutUrlResponse.fromJSON({ checkoutUrl: response?.data });
    } catch (error) {
        reportErrorToBE({ methodName: "shopifyGetCheckoutUrl", error });
        return {
            checkoutUrl: fallbackUrl,
        };
    }
}

const fallbackUrls = {
    yearly: "https://store.payproglobal.com/checkout?products[1][id]=82706&page-template=15553&billing-country=US&exfo=742&billing-email=",
    monthly:
        "https://store.payproglobal.com/checkout?products[1][id]=82705&page-template=15553&billing-country=US&exfo=742&billing-email=",

    // same as monthly
    quarterly:
        "https://store.payproglobal.com/checkout?products[1][id]=82705&page-template=15553&billing-country=US&exfo=742&billing-email=",
} as const;

export const ShopifyApi = { getCheckoutUrl, updatePolicy };
