import { useEffect, useRef } from "react";

import { TextElement } from "./PdfElements";

export const Text = ({ text, updateElement }: { text: TextElement; updateElement: (element: TextElement) => void }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        ref.current!.innerText = text.value;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={ref}
            contentEditable
            onKeyUp={(e) => {
                //todo: handle multi line
                const newText = { ...text };
                newText.value = e.currentTarget.textContent ?? "";
                updateElement(newText);
            }}
            className="outline-none whitespace-no-wrap"
            style={{
                fontSize: text.fontSize + "px",
                lineHeight: 1.333,
                fontFamily: "Times New Roman, Times, serif",
            }}
        ></div>
    );
};
