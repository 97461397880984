import { VideosCollection } from "apps/legal-ide/App/components/QuestionWizard/VideosCollection";
import { Button, Select, TextField } from "components";
import { useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

const videoFields = [{ name: "title" }, { name: "subTitle" }];

export const EditVideoModal = () => {
    const payload = useAppSelector((state) => state.ui.modals.editVideo.data);

    const [videoInfo, setVideoInfo] = useState<VideoInfo | undefined>(payload?.videoInfo);
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "editVideo" }));
    };

    const onSave = () => {
        if (!videoInfo) return;
        dispatch(Actions.ui.closeModal({ name: "editVideo" }));
        payload?.onSave(videoInfo);
    };

    const onRemove = () => {
        payload?.onRemove?.();
        dispatch(Actions.ui.closeModal({ name: "editVideo" }));
    };

    return (
        <BaseModal>
            <div className="flex flex-col gap-8 w-full">
                {payload?.isIntro && videoFields.map((field) => (
                    <TextField
                        size="lg"
                        key={field.name}
                        name={field.name}
                        placeholder={`Enter ${field.name}`}
                        onChange={(_, val) => { setVideoInfo({ ...videoInfo, [field.name]: val }) }}
                        value={videoInfo?.[field.name as keyof VideoInfo] || ""}
                    />
                ))}
                <Select
                    className="w-96 h-12 text-lg"
                    name="urlRef"
                    placeholder="Select Video"
                    onChange={(_, val) => setVideoInfo({ ...videoInfo, urlRef: val })}
                    value={videoInfo?.urlRef}
                    options={Object.keys(VideosCollection).map((key) => ({ value: key, label: key }))}
                />
                <div className="flex justify-between">
                    <Button onClick={onCancel}>Cancel</Button>
                    <div className="flex gap-4">
                        <Button onClick={onRemove}>
                            Remove
                        </Button>
                        <Button onClick={onSave}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};
