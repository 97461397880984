import clsx from "clsx";
import { useMedia, useToggle } from "hooks";
import React, { cloneElement, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

type position = "left" | "right";
type Props = {
    children: JSX.Element;
    title: ReactNode;
    position?: position;
    onChange?: (open: boolean) => void;
};

export const Tooltip = (props: Props) => {
    const { children, title, position = "left", onChange } = props;

    const toggle = useToggle();
    const smallScreen = useMedia({ max: "lg" });

    const callback = useCallback(() => {
        toggle.state.isOn && toggle.off();
    }, [toggle.state.isOn]);

    useEffect(() => {
        window.addEventListener("scroll", () => {});
        document.body.addEventListener("scroll", () => {});
    });

    useEffect(() => {
        window.addEventListener("click", callback);
        window.addEventListener("scroll", callback, true);
        window.addEventListener("resize", callback);
        return () => {
            window.removeEventListener("click", callback);
            window.removeEventListener("scroll", callback);
            window.removeEventListener("resize", callback);
        };
    }, [toggle.state.isOn]);


    return (
        <TooltipPrimitive.Root
            onOpenChange={onChange}
            open={!smallScreen ? undefined : toggle.state.isOn}
            delayDuration={300}
        >
            <TooltipPrimitive.Trigger
                onClick={(event) => {
                    event.stopPropagation();
                    toggle.toggle();
                }}
                asChild
            >
                {children}
            </TooltipPrimitive.Trigger>
            {!!title && (
                <TooltipPrimitive.Content
                    className={clsx(
                        `bg-secondaryLight z-[1000000] overflow-auto border whitespace-pre-wrap p-4 rounded-lg  shadow-md w-72`
                    )}
                    side={position}
                    sideOffset={4}
                >
                    {title}
                    <TooltipPrimitive.Arrow fill="#feefb7" />
                </TooltipPrimitive.Content>
            )}
        </TooltipPrimitive.Root>
    );
};
