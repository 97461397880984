import { getSearchParams } from "utils";

export const GenericOauthHandlingProviders = [
    {
        name: "dropbox",
        clientId: "nfiuizb8j68kfc5",
        redirectUrl: `${window.location.origin}/oauth/dropbox/callback`,
        getCodeUrl() {
            return `https://www.dropbox.com/oauth2/authorize?client_id=${this.clientId}&response_type=code&token_access_type=offline&redirect_uri=${this.redirectUrl}`;
        },
    },
    {
        name: "webflow",
        clientId: "caac589678bb8edc2119151cd1d14e3c7014b18dc6cfd1e732694b3c5ffedf7f",
        redirectUrl: `${window.location.origin}/oauth/webflow/callback`,
        getCodeUrl() {
            return `https://webflow.com/oauth/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUrl}`;
        },
    },
    {
        name: "hubspot",
        clientId: "eadf470b-8f0f-4188-bbdc-04521dc9096d",
        redirectUrl: `${window.location.origin}/oauth/hubspot/callback`,
        getCodeUrl() {
            return `https://app.hubspot.com/oauth/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUrl}&scope=files`;
        },
    },
    {
        name: "xero",
        clientId: "F7FCBB8797AA48EE93E39923A07FD740",
        redirectUrl: `${window.location.origin}/oauth/xero/callback`,
        getCodeUrl() {
            const scopes = [
                "email",
                "profile",
                "openid",

                "projects",
                "accounting.settings",
                "accounting.settings.read",

                "offline_access",
            ];
            return `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${
                this.clientId
            }&redirect_uri=${this.redirectUrl}&scope=${scopes.join(" ")}&state=123`;
        },
    },
    {
        name: "shopify",
        clientId: "936ae5055787e2bf58ef925e1fdd9f7d",
        redirectUrl: `${window.location.origin}/oauth/shopify/callback`,
        getCodeUrl() {
            const params = getSearchParams();
            const shop = params.get("shop");
            const host = params.get("host");
            console.log("shop", shop);
            console.log("host", host);

            const scope = [].join(",");

            return `http://${shop}/admin/oauth/authorize?client_id=${this.clientId}&scope=${scope}&redirect_uri=${this.redirectUrl}`;
        },
        // getEmbeddedUrl() {
        //     const params = getSearchParams();
        //     const host = params.get("host");
        //     if (!host) return;
        //     window.location.href = `https://${window.atob(host)}/apps/${this.clientId}/`;
        // },
    },
] as GenericOauthHandlingProvider[];

// never store clientSecret in FE!
export interface GenericOauthHandlingProvider {
    name: string;
    clientId: string;
    redirectUrl: string;
    getCodeUrl: () => string;
    getEmbeddedUrl?: () => string; // final url to display the app
}
