import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { toCompoundJsonLogic } from "apps/legal-ide/App/components/WizardUi/WizardForm";
import { jsonToCompoundJsonLogic } from "apps/legal-ide/App/Editor/customElements";
import { MULTI_SELECTION_OPERATORS_ARR, MULTI_SELECTION_OPERATORS_REGEX } from "apps/legal-ide/App/Editor/parser";
import ABTesting, { ABTestContractText, AB_TEST_NAMES } from "components/utils/ABTesting";
import jsonLogic from "json-logic-js";
import { InternalBoolean } from "proto/contracts/PUtils";
import { Children, ReactNode, useContext } from "react";
import { useAppSelector } from "store";
import { ConditionNode } from "../Parser";

import { CONDITION, CONDITION_REGEX } from "features/Contract";

export const CONDITION_RENDERER_REGEX = new RegExp(
    `\\s*([A-Za-z]+)\\s*(==|!=|${MULTI_SELECTION_OPERATORS_REGEX})\\s*(true|false|[A-Z][A-Z0-9_,\\s*]*)?\\s*`,
    ""
);

export const ConditionRenderer = (props: { node: ConditionNode; children: ReactNode }) => {
    const { node, children } = props;

    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const agreementContext = useContext(AgreementContext);

    const t = node.payload.map((node: any) => node.content).join("\n");

    const _match = t.match(CONDITION_REGEX);

    if (!_match) {
        return null;
    }

    let groups = _match?.groups ?? {};

    const { conOne, opt, conTwo } = groups;

    const con1 = conOne?.match(CONDITION);
    const con2 = conTwo?.match(CONDITION);

    if (!con1) return null;

    let conOneData = prepareData(con1, currentAgreement?.fields);
    let conOneData2 = prepareData(con2, currentAgreement?.fields);

    if (!conOneData) {
        console.warn("ConditionRenderer warning: invalid field name");
        return null;
    }

    const _jsonLogic: CompoundJsonLogic = {
        concatOperator: "AND",
        conditions: [conOneData],
    };
    if (opt && conOneData2) {
        _jsonLogic.concatOperator = opt as any;
        _jsonLogic.conditions.push(conOneData2);
    }

    const condition = toCompoundJsonLogic(_jsonLogic);

    if (!condition) return null;

    const jsonLogicValue = jsonToCompoundJsonLogic(condition, currentAgreement?.fields);
    const data = agreementContext?.wizardApi?.getCurrentContract(agreementContext.getActiveDraft());
    const isValid = jsonLogic.apply(jsonLogicValue, data);

    const firstField = currentAgreement?.fields?.find((f) => f.id === condition?.conditions?.[0]?.fieldId);

    return <>{isValid && <ABTestContractText children={children} />}</>;
};

function prepareData(match: RegExpMatchArray | null, fields: Field[] | undefined): JsonLogic | null {
    if (!match) return null;
    let [, fieldName, operator, value] = match;
    const field = fields?.find((f) => f.name === fieldName);
    if (!field) {
        console.warn("ConditionRenderer warning: invalid field name");
        return null;
    }

    value = value.trim();
    let finalValue: any;
    if (value === "true") {
        finalValue = InternalBoolean.YES;
    } else if (value === "false") {
        finalValue = InternalBoolean.NO;
    } else if (MULTI_SELECTION_OPERATORS_ARR.includes(operator) && value) {
        const values = value.split(",").map((v: string) => v.trim());
        const options = field.options?.filter((o) => values.includes(o.value)).map((o) => o.dbValue) || [];

        finalValue = options;
    } else {
        const option = field.options?.find((o) => o.value === value);

        finalValue = option?.dbValue;
    }
    return {
        fieldId: field.id,
        operator: operator as JsonLogic["operator"],
        value: finalValue,
    };
}
