import { ContactApi } from "api-services/ContactInfoApi";
import { TimeRecordApi } from "api-services/TimeRecord";
import { PContactInfo } from "proto/PContactInfo";
import { PDoc } from "proto/PDoc";
import { PTimeRecord } from "proto/PTimeRecord";
import { PUser } from "proto/PUser";
import { Actions } from "store";

export function reloadAllTimeRecords(dispatch: Function) {
    TimeRecordApi.getAllTimeRecords((timeRecordsResp) => {
        dispatch(Actions.timeTracking.setAllTimeRecords(timeRecordsResp.timeRecords))
    })
}

export function stopTimerAndReload(dispatch: Function, timeRecord: PTimeRecord) {
    const timeRecordCopy = { ...timeRecord }

    timeRecordCopy.elapsedTime = timeRecordCopy.elapsedTime + (Date.now() - (timeRecordCopy.startTime?.getTime() || 0));
    timeRecordCopy.isRunning = false;

    debugger;

    TimeRecordApi.putTimeRecord(timeRecordCopy, () => {
        reloadAllTimeRecords(dispatch)
    })
}

export function updateTimeRecordField(dispatch: Function, timeRecord: PTimeRecord, field: keyof PTimeRecord, value: any) {
    const timeRecordCopy: any = { ...timeRecord }

    timeRecordCopy[field] = value;

    TimeRecordApi.putTimeRecord(timeRecordCopy, () => {
        reloadAllTimeRecords(dispatch)
    })
}

export function onStartOrResume(dispatch: Function, timeRecord: PTimeRecord) {
    const timeRecordCopy = { ...timeRecord }

    timeRecordCopy.startTime = new Date(Date.now());
    timeRecordCopy.isRunning = true;
    timeRecordCopy.isPaused = false;

    TimeRecordApi.putTimeRecord(timeRecordCopy, () => {
        reloadAllTimeRecords(dispatch)
    })
}

export function onPause(dispatch: Function, timeRecord: PTimeRecord) {
    const timeRecordCopy = { ...timeRecord }

    timeRecordCopy.elapsedTime = timeRecordCopy.elapsedTime + (Date.now() - (timeRecordCopy.startTime?.getTime() || 0));
    timeRecordCopy.isRunning = false;
    timeRecordCopy.isPaused = true;

    TimeRecordApi.putTimeRecord(timeRecordCopy, () => {
        reloadAllTimeRecords(dispatch)
    })
}

export function deleteTimeRecord(dispatch: Function, timeRecord: PTimeRecord) {
    TimeRecordApi.removeTimeRecord(timeRecord.recordUuid, () => {
        reloadAllTimeRecords(dispatch)
    })
}

export function reloadAllContacts(dispatch: Function, dbUser: PUser | undefined, totalDocs: PDoc[]) {
    ContactApi.getAllContacts((contactsResp) => {
        let totalContacts = contactsResp.contactInfos;

        if (dbUser) {
            totalContacts = enrichWithDocsSent(totalContacts, totalDocs, dbUser.email)
        }

        dispatch(Actions.timeTracking.setAllContacts(totalContacts))
    })
}

export function addManualEntry(dispatch: Function, timeRecord: PTimeRecord, cb: Function) {
    TimeRecordApi.putTimeRecord(timeRecord, (resp) => {
        reloadAllTimeRecords(dispatch)
        cb(resp.uuid)
    })
}

function enrichWithDocsSent(contacts: PContactInfo[], totalDocs: PDoc[], dbUserEmail: string) {
    const tempContacts = [] as PContactInfo[];

    totalDocs.forEach(doc => {
        if (doc.permanentContext?.ownerUser?.user?.email === dbUserEmail) {
            const clientEmail = doc.permanentContext?.sharedWithUser?.user?.email;

            if (clientEmail) {
                tempContacts.push({
                    ownerEmail: dbUserEmail,
                    clientEmail: clientEmail,
                    clientName: doc.permanentContext?.sharedWithUser?.user?.fullName || "",
                    billingAddress: "",
                    phone: "",
                })
            }
        }
        else {
            const clientEmail = doc.permanentContext?.ownerUser?.user?.email;

            if (clientEmail) {
                tempContacts.push({
                    ownerEmail: dbUserEmail,
                    clientEmail: clientEmail,
                    clientName: doc.permanentContext?.ownerUser?.user?.fullName || "",
                    billingAddress: "",
                    phone: "",
                })
            }
        }
    })

    const totalContacts = [...contacts, ...tempContacts];

    const uniqueContacts = totalContacts.filter((contact, index) => {
        return totalContacts.findIndex((contact2) => {
            return contact2.clientEmail === contact.clientEmail
        }) === index
    })

    return uniqueContacts;
}

export function convertDurationToSeconds(duration: string) {
    const regExp = new RegExp(":", "gi");

    if (duration.match(regExp)?.length === 2) {
        const [hours, minutes, seconds] = duration.split(':');
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
    }

    if (duration.match(regExp)?.length === 1) {
        const [minutes, seconds] = duration.split(':');
        return Number(minutes) * 60 + Number(seconds);
    }

    return 0;
};
