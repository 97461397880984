import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { Button, SvgIconFinishDraft, SvgIconPencilSign, SvgIconPencilEdit } from "components";
import CopyToClipboard from "components/utils/CopyToClipboard";
import { isProd } from "components/utils/EnvResolver";
import { useMedia } from "hooks";
import CopyAsHtmlImg from "img/icons/copy-as-html.png";
import CopyAsTextImg from "img/icons/copy-as-text.png";
import mixpanel from "mixpanel-browser";
import { PDocStatus } from "proto/PDoc";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { inIframe } from "utils/iframe-utils";
import { DocsApi } from "../../api-services/DocsApi";
import { checkPayment } from "../../api-services/UseresApi";
import { callDialog, updateActiveDraftStatus } from "../../store/mainActions";
import { DialogType, MainStore } from "../../store/mainTypes";
import { documentToTitle } from "../ContractChooser";
import { getFullDocId } from "../utils/RouteManager";
import { HeadlessDocPreview, HeadlessSaveButtons } from "./agreements/DocPreview";
import { usePlanState } from "hooks/usePlanState";

const SHARE_WITH_QUERY_PARAM = "share-with";

export default function FinishDraft() {
    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const smallScreen = useMedia({ max: "lg" });

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isDocumentLocked } = usePlanState();

    const [paid, setPaid] = useState<boolean>(true); //todo: its should be global state

    const isAllDoneCompletley = agreementContext?.wizardApi.isWizardCompleted();

    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const onFreeStyleEditDoc = () => {
        if (activeDraft?.legalUnit?.freestyleSrc) {
            return history.push(`/${agreementContext?.getFullDocId()}/edit`);
        }
        return dispatch(
            callDialog(
                DialogType.CONFIRM,
                "If you choose this option - you will not be able to go back and edit your answers!",
                (yes: boolean) => {
                    if (yes) {
                        history.push(`/${agreementContext?.getFullDocId()}/edit`);
                    }
                }
            )
        );
    };

    useEffect(() => {
        if (isAllDoneCompletley) {
            checkPayment(
                () => {},
                () => {
                    if (!location.pathname.includes("/finish/payment")) {
                        history.replace("../finish/payment-plans");
                    }

                    setPaid(false);
                },
                dbUser,
                dispatch,
                activeDraft?.id
            );
        }
    }, [isAllDoneCompletley]);

    return (
        <div className="flex flex-col p-4">
            <p className="text-base sm:text-xl font-semibold mb-6">Finish and download</p>
            <p className="text-base sm:text-2xl font-semibold mb-4">
                Hurray! You finished drafting your {documentToTitle(activeDraft)}
            </p>
            {currentAgreement?.docShareType === "AGREEMENT" && <ShareWithSuggestion />}
            {currentAgreement?.docShareType === "ONLY_OWNER_SIGNS" && <ESignSuggestion />}
            {currentAgreement?.docShareType === "DOCUMENT" && !inIframe() && <CopySuggestion />}
            <div className="mx-auto my-10">
                <SvgIconFinishDraft
                    width={smallScreen ? "200" : "300"}
                    height={smallScreen ? "130" : "217"}
                />
            </div>

            <div className="flex flex-col gap-4 flex-shrink-0 self-center items-center">
                <div className="flex flex-col gap-2">
                    {paid && <HeadlessSaveButtons />}
                    {paid && !isDocumentLocked(activeDraft?.id!) && (
                        <Button onClick={onFreeStyleEditDoc} variant={"outlined"} color={"skyBlue"}>
                            <div className="hidden md:block flex-shrink-0">
                                <SvgIconPencilEdit />
                            </div>
                            <p className="text-xs md:text-base">Free-Text Edit</p>
                        </Button>
                    )}
                </div>
                <p className="text-base font-bold mb-14"> </p>
            </div>
        </div>
    );
}

function ShareWithSuggestion() {
    const docNamerRef = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.docNamerRef
    );
    const inputRef = createRef<HTMLInputElement>();
    const location = useLocation();
    let fullDocId = getFullDocId(location);
    const [defaultVal, setDefaultVal] = useState("");
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const dispatch = useDispatch();
    const [formRef, setformRef] = useState<HTMLFormElement | null>(null);
    const history = useHistory();
    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();

    useEffect(() => {
        let query = location.search;

        if (query) {
            let shareWith = new URLSearchParams(query).get(SHARE_WITH_QUERY_PARAM);

            if (shareWith) {
                setDefaultVal(shareWith);
            }
        }

        const listener = (e: KeyboardEvent) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();

                if (formRef) {
                    setTimeout(() => {
                        if (formRef.reportValidity()) {
                            formRef?.dispatchEvent(
                                new Event("submit", { cancelable: true, bubbles: true })
                            );
                        }
                    }, 10);
                }
            }
        };

        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [formRef]);

    return (
        <div className="flex-col items-center">
            <p className="text-lg  mb-4">
                We recommend you use our{" "}
                <span className="text-primary">Integrated Management Tools</span> and share it with{" "}
                <span className="text-primary">{agreementContext?.getOtherSideName()}</span> to
                e-sign!
            </p>

            <form
                autoComplete="on"
                ref={(ref) => setformRef(ref)}
                onSubmit={(e) => {
                    e.preventDefault();

                    if (docNamerRef?.value.trim().length === 0) {
                        dispatch(
                            callDialog(
                                DialogType.FAILURE,
                                "Note: You haven't set a name for this draft. Please set a name to be able to track it easily.",
                                () => {
                                    docNamerRef.focus();
                                }
                            )
                        );

                        return;
                    }

                    if (inputRef?.current?.value) {
                        let shareWithEmail = inputRef?.current?.value;

                        if (dbUser?.email === shareWithEmail) {
                            dispatch(
                                callDialog(
                                    DialogType.FAILURE,
                                    "You can't share a document with yourself. Please insert a different email!",
                                    () => {}
                                )
                            );
                            return;
                        }

                        DocsApi.shareDoc(
                            fullDocId,
                            shareWithEmail,
                            () => {
                                if (isProd()) {
                                    mixpanel.track("share-contract", {
                                        sharedWith: shareWithEmail,
                                    });
                                }

                                DocsApi.getAllDocs(dispatch);

                                if (shareWithEmail) {
                                    dispatch(
                                        callDialog(
                                            DialogType.SUCCESS,
                                            "Document successfully shared with " +
                                                shareWithEmail +
                                                ".",
                                            () => {
                                                history.push("/");
                                            }
                                        )
                                    );
                                }
                            },
                            () => {}
                        );
                    }
                }}
            >
                <div className="flex items-center w-full">
                    <input
                        id="shareWith"
                        name="shareWith"
                        ref={inputRef}
                        type="email"
                        autoFocus
                        placeholder={"Enter " + agreementContext?.getOtherSideName() + "'s email"}
                        className="text-lg lg:text-xl rounded-l-lg w-2/3 lg:w-auto border h-12 px-4 py-2 caret-primary border-primaryLight focus:outline-primary placeholder-primaryLight"
                        required={true}
                        defaultValue={defaultVal}
                        autoComplete="on"
                    />
                    <button
                        type="submit"
                        className="h-12 bg-primary border border-primary px-4 py-2 font-medium text-sm lg:text-lg rounded-r-lg text-white flex justify-center items-center"
                    >
                        <p className="text-white">Send Contract</p>
                    </button>
                </div>
            </form>
        </div>
    );
}

function CopySuggestion() {
    const htmlRef = React.createRef<HTMLDivElement>();
    const user = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    return (
        <div id="CopySuggestion" className="w-full flex flex-col items-center">
            <HeadlessDocPreview htmlRef={htmlRef} />
            <p className="text-lg self-center text-center font-bold">Copy to easily embed:</p>
            <div className="flex gap-4">
                <CopyToClipboard
                    btnCaption="Copy Text"
                    type="text/html"
                    getContent={() =>
                        htmlRef.current?.textContent || "Error occurred while copying"
                    }
                    icon={CopyAsTextImg}
                />

                {!user?.supportedOauthProviders?.includes("webflow") && (
                    <CopyToClipboard
                        btnCaption="Copy HTML"
                        type="text/plain"
                        getContent={() =>
                            htmlRef.current?.innerHTML || "Error occurred while copying"
                        }
                        icon={CopyAsHtmlImg}
                    />
                )}
            </div>
        </div>
    );
}

function ESignSuggestion() {
    const dispatch = useDispatch();

    return (
        <div id="ESignSuggestion" className="w-full flex flex-col items-center mt-8">
            <Button
                onClick={() => {
                    dispatch(
                        callDialog(
                            DialogType.CONFIRM,
                            "You will switch to signing mode, and you will not be able to continue editing your answers. Are you sure?",
                            (confirm: boolean) => {
                                if (confirm) {
                                    dispatch(updateActiveDraftStatus(PDocStatus.INBOX));
                                }
                            }
                        )
                    );
                }}
                className="bg-secondary hover:bg-secondaryHover w-44 h-20 text-3xl flex items-center justify-between gap-2 text-darkIndigo"
            >
                <SvgIconPencilSign />
                <span>E-Sign</span>
                <span />
            </Button>
        </div>
    );
}
