import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

interface DropdownMenuProps {
    children: React.ReactNode;
    element: React.ReactNode;
    side?: DropdownMenuPrimitive.MenuContentProps['side'];
    sideOffset?: DropdownMenuPrimitive.MenuContentProps['sideOffset'];
    align?: DropdownMenuPrimitive.MenuContentProps['align'];
}

export const DropdownMenu = (props: DropdownMenuProps) => {
    const { children, element, side = "bottom", align = "center", sideOffset } = props;

    const alignOffsetDirection = align === "end" ? -1 : 1;
    return (
        <DropdownMenuPrimitive.Root open={!children ? false : undefined}>
            <DropdownMenuPrimitive.Trigger className="outline-none">{element}</DropdownMenuPrimitive.Trigger>
            <DropdownMenuPrimitive.Content
                alignOffset={20 * alignOffsetDirection}
                align={align}
                side={side}
                sideOffset={sideOffset}
            >
                {children}
                <DropdownMenuPrimitive.Arrow height={5} width={10} fill="white" />
            </DropdownMenuPrimitive.Content>
        </DropdownMenuPrimitive.Root>
    );
};
