import { GPTApi } from "api-services/gptApi";
import { getAutoContracts } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import {
    AutoContractDocument,
    findFamily,
    preferContractsFromThisFamily,
} from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { PTipRequest, PTipResponse } from "proto/PApi";
import { PDoc } from "proto/PDoc";
import { PUserStatus } from "proto/PUser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MainStore } from "store/mainTypes";
import { Button, Container, SvgIconArrowRightTertiary } from "./0_common";
import { LoadingDots } from "./0_common/Loading/LoadingDots";
import { DocumentName } from "./1_features";
import { documentToTitle } from "./ContractChooser";
import { AutoOptionCard } from "./Library";
import { continueDraft } from "./life-cycle/DocsBase";
import { LifeCycleBoxesForDashboard } from "./life-cycle/LifeCycleBox";
import ABTesting, { AB_TEST_NAMES } from "./utils/ABTesting";
import { getMarketingUtmTerm } from "./utils/UtilFunctions";
import { SHOPIFY_SUPPORTED_DOCUMENTS } from "consts";

export default function HomePage() {
    return (
        <div className="w-full h-full">
            <div className="w-full bg-primary">
                <HomePageHeader />
            </div>
            <HomePageBody />
        </div>
    );
}

function HomePageHeader() {
    return (
        <Container className="flex items-start lg:h-20 h-24 pt-1 lg:pt-0">
            <div className="flex flex-col lg:h-37 h-46 w-full rounded-lg bg-white shadow-card bg-card backdrop-blur-md">
                <LifeCycleBoxesForDashboard />
            </div>
        </Container>
    );
}

function HomePageBody() {
    return (
        <Container className="mt-32">
            <div
                className="grid lg:grid-cols-2 grid-cols-1 pb-8 justify-between gap-12
          lg:bg-divider bg-opacity-50 bg-[length:1px_100%] bg-center bg-no-repeat"
            >
                <div className="flex flex-col order-2 lg:order-1 gap-12">
                    <LegalTopics />
                </div>
                <div className="flex flex-col order-1 lg:order-2 lg:gap-24 gap-12">
                    <DailyProTip />
                    <LatestDrafts />
                </div>
            </div>
        </Container>
    );
}

function LatestDrafts() {
    const history = useHistory();
    let drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    drafts = drafts.sort((a, b) => (b.legalUnit?.createdAt! < a.legalUnit?.createdAt! ? -1 : 1));
    drafts = drafts.slice(0, 4);

    if (drafts.length == 0) {
        return null;
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <p className="font-medium text-2xl text-darkIndigo">Recent Drafts</p>
                <p
                    className="underline text-base cursor-pointer"
                    onClick={() => history.push("/docs/drafts")}
                >
                    Go to Drafts
                </p>
            </div>
            <div className="flex flex-col gap-4 mt-3">
                {drafts.map((draft: PDoc) => {
                    return <LatestDraft key={draft.id} draft={draft} />;
                })}
            </div>
        </div>
    );
}

function LatestDraft({ draft }: { draft: PDoc }) {
    const history = useHistory();

    return (
        <div
            onClick={() => continueDraft(draft, history)}
            className="group flex items-center px-6 lg:px-10 py-6 shadow-card hover:shadow-cardHover cursor-pointer rounded-2xl justify-between"
        >
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-4">
                <LatestDraftDate date={draft.legalUnit?.createdAt} />
                <div className="flex flex-col">
                    <p className="text-darkIndigo text-base lg:font-medium">
                        {documentToTitle(draft)}
                    </p>
                    <DocumentName doc={draft} />
                </div>
            </div>
            <div>
                <div className="hidden lg:block">
                    <Button>Continue</Button>
                </div>
                <div className="flex justify-center items-center lg:hidden">
                    <Button shape="circle" variant="outlined">
                        <SvgIconArrowRightTertiary />
                    </Button>
                </div>
            </div>
        </div>
    );
}

function LatestDraftDate({ date }: { date: Date | undefined }) {
    const theDate = date || new Date();

    return (
        <div className="w-20 lg:w-14 h-8 lg:h-14 rounded-3xl lg:rounded-lg bg-secondary px-2 flex-shrink-0">
            <div className="flex flex-row lg:flex-col justify-center items-center h-full gap-2 lg:gap-0">
                <p className="text-darkIndigo font-medium text-base lg:text-2xl">
                    {theDate.toLocaleString("default", { day: "2-digit" })}
                </p>
                <p className="text-darkIndigo text-base lg:text-sm">
                    {theDate.toLocaleString("default", { month: "short" })}
                </p>
            </div>
        </div>
    );
}

function LegalTopics() {
    const history = useHistory();

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <p className="font-medium text-2xl text-darkIndigo">Legal Topics</p>
                <p
                    className="underline text-base cursor-pointer"
                    onClick={() => history.push("/library")}
                >
                    Go to Library
                </p>
            </div>
            <div
                id="contracts-container"
                className="grid xl:grid-cols-3 grid-cols-2 gap-x-4 gap-y-4 mt-3"
            >
                <ABTesting
                    componentA={<AutoContractsCards order={false} />}
                    componentB={<AutoContractsCards order={true} />}
                    isSticky={true}
                    firstVariantProbability={0.7}
                    testName={AB_TEST_NAMES.TEST_9__ORDER_CARDS_BY_FAMILY}
                />
            </div>
        </div>
    );
}

function DailyProTip() {
    return (
        <div className="">
            <p className="font-medium text-2xl text-darkIndigo">Your Tip of the Day</p>
            <div className="bg-tip w-full min-h-40 lg:h-full bg-center bg-no-repeat bg-cover mt-3 rounded-2xl">
                <div className="p-4 lg:p-8 text-white pr-16 lg:pr-32 text-justify text-base lg:text-xl">
                    <TheTip />
                </div>
            </div>
        </div>
    );
}

function TheTip() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const [tip, setTip] = useState<string | undefined>("Generating your personalized tip");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const tips = [
            "What turns a contact into a contract? Did you ever notice the only difference between them is the letter R? The R stands for Relationship! It is the human factor that makes the difference.",
            "A foot-in-the-door technique in negotiation is the idea of asking for something small from the other side and after they say yes, asking for a bigger thing. It is effective because compliance with a small request makes the other person feel a positive attitude toward cooperating.",
            "Don’t be afraid to share your ideas with others. It is true that many entrepreneurs are afraid of talking about their idea, fearing someone might copy them. However, an idea itself has little value. What does have value? Execution.",
            "Put an expiration date on every offer you give.",
            "Verbal and informal communication such as emails and text messages can be legally binding. Pay attention to what you commit to in written or verbal communications.",
            "Try to conduct your meetings at a round table. This way, it’s no longer an “Us vs. Them” mentality – as would be the case when the two parties sit on opposite sides of a rectangle table – and opens the door to a collaborative effort by all participants to make progress.",
            "Many others have already gone down the same path succeeded in doing what you are doing. Make sure to only take advice from those who have been in your shoes.",
            "Don’t put all your eggs in one basket. You don’t want your success to depend on a single person, customer, investor, partnership, or plan of action.",
            "Always ensure you have more alternatives to the deal on the table.",
        ];

        const startDate = new Date("2022-09-29");
        const today = new Date();

        const diffTime = Math.abs(today.getTime() - startDate.getTime());

        let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const weekOffset = Math.floor(diffDays / 7);
        const mondayOrThursday = diffDays % 7 < 4 ? 0 : 1;

        const totalOffset = 2 * weekOffset + mondayOrThursday;

        const tipIndex = totalOffset % tips.length; // change every Monday or Thursday

        if (dbUser && dbUser?.userStatus < PUserStatus.POST_PAYMENT) {
            setTip(tips[tipIndex]);
            setLoading(false);
            return;
        }

        if (localStorage.getItem("tip")) {
            try {
                const tipData = JSON.parse(localStorage.getItem("tip") || "{}");
                const tipDate = new Date(tipData.date);

                if (
                    tipDate.getDate() === today.getDate() &&
                    tipDate.getHours() === today.getHours()
                ) {
                    setTip(tipData.tip + "\n\n#AI-generated");
                    setLoading(false);
                    return;
                }
            } catch (e) {
                setTip(tips[tipIndex]);
                setLoading(false);
                return;
            }
        }

        if (dbUser?.companyFullName) {
            let input = "the company name is " + dbUser.companyFullName;

            const tipReq = PTipRequest.fromJSON({ companyDetails: input });

            GPTApi.tip(
                tipReq,
                (tipRes: PTipResponse) => {
                    if (tipRes.theTip) {
                        setTip(tipRes.theTip + "\n\n#AI-generated");
                        setLoading(false);

                        localStorage.setItem(
                            "tip",
                            JSON.stringify({ tip: tipRes.theTip, date: today })
                        );
                    }
                },
                () => {
                    setTip(tips[tipIndex]);
                    setLoading(false);
                }
            );
        } else {
            setTip(tips[tipIndex]);
            setLoading(false);
        }
    }, []);

    return (
        <div className="flex flex-col">
            <span className="whitespace-pre-wrap">{tip}</span>
            {loading && (
                <div className="self-start mt-4">
                    <LoadingDots />
                </div>
            )}
        </div>
    );
}

function AutoContractsCards({ order }: { order: boolean }) {
    let autoContracts = getAutoContracts();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    if (order) {
        const utmTermValue = getMarketingUtmTerm().toLowerCase();

        if (utmTermValue) {
            const family = findFamily(utmTermValue);

            autoContracts = preferContractsFromThisFamily(autoContracts, family);
        }
    }

    // If the user is a Shopify user, we want to show the privacy policy and the e-comm documents first,
    // since they can automatically be uploaded to Shopify.
    if (dbUser?.isShopifyUser) {
        autoContracts.sort((a, b) =>
            SHOPIFY_SUPPORTED_DOCUMENTS.includes(a.kind) &&
            !SHOPIFY_SUPPORTED_DOCUMENTS.includes(b.kind)
                ? -1
                : 1
        );
    }

    return (
        <>
            {autoContracts
                .filter((c) => !c.hideInLibrary?.(dbUser))
                .filter(
                    (c) =>
                        (c.forUSA && dbUser?.country !== "Israel") ||
                        (c.forIsrael && dbUser?.country === "Israel")
                )
                .slice(0, 18)
                .map((autoContract: AutoContractDocument) => {
                    return (
                        <AutoOptionCard
                            key={autoContract.kind}
                            autoContract={autoContract}
                            showDesc={false}
                            className="flex flex-col items-center justify-center h-56 w-full"
                            textClassName="text-center text-base"
                            imgClassName="md:h-18 h-20 md:mt-10 mt-6"
                        />
                    );
                })}
        </>
    );
}
