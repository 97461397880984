import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { DocsApi } from "api-services/DocsApi";
import { PDocStatus } from "proto/PDoc";
import {
    callDialog,
    changeAlreadySigned,
    draftsLoaded,
    registerSignCanvas,
} from "store/mainActions";
import { createdByMyAccount } from "store/mainStore";
import { DialogType, MainStore } from "store/mainTypes";
import "./Signature.scss";
import DownArrow from "img/icons/down-arrow.png";
import { useHistory } from "react-router";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import { isProd } from "components/utils/EnvResolver";
import mixpanel from "mixpanel-browser";
import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { Button } from "components";
import { useAppSelector } from "store";

function Chip(props: any) {
    return (
        <div className="flex text-sm bg-secondaryLight h-32 w-max h-max rounded-2xl px-4 py-2">
            {props.children}
        </div>
    );
}
function SignatureBox({
    signCanvas,
    count,
    hidden,
    reuse,
    ...props
}: {
    signCanvas: any;
    count: number;
    hidden?: boolean;
    reuse: boolean;
    props: any;
}) {
    const alreadySigned = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.alreadySigned
    );
    const validSignCanvases = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.signCanvases
    ).filter((signCanvas) => !!signCanvas);
    const dispatch = useDispatch();
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (reuse) {
            let firstIndex = alreadySigned.indexOf(true);

            if (firstIndex === -1) {
                return;
            }

            if (!validSignCanvases[firstIndex]) {
                return;
            }

            let sourceImageData = validSignCanvases[firstIndex].current
                .getCanvas()
                .toDataURL("image/png");
            let destContext = signCanvas.current.getCanvas().getContext("2d");

            var destinationImage = new Image();
            destinationImage.onload = function () {
                destContext.drawImage(
                    destinationImage,
                    0,
                    0,
                    signCanvas.current.getCanvas().width,
                    signCanvas.current.getCanvas().height
                );
            };
            destinationImage.src = sourceImageData;
        }
    }, [alreadySigned]);

    return (
        <div data-name="SignatureBox" className="relative w-full" hidden={hidden}>
            <SignatureCanvas
                {...props}
                penColor="#111c5b"
                onEnd={() => {
                    dispatch(changeAlreadySigned(true, count));
                }}
                ref={signCanvas}
                canvasProps={{
                    width: isMobile ? (window.innerWidth / 4) * 3 : 392,
                    height: isMobile ? 120 : 134,
                    className: "Signature",
                }}
            />
            <span className="absolute text-sm text-[#bdccf9] top-2 left-2 z-30">
                {"Your signature here"}
            </span>
            {reuse && (
                <div
                    className="absolute cursor-pointer top-0 left-0 flex w-full h-full justify-center items-center bg-primaryBg bg-opacity-60 rounded-lg z-10"
                    onClick={() => {
                        dispatch(changeAlreadySigned(true, count));
                    }}
                >
                    <img
                        className="h-full w-full p-8 aspect-auto bg-white hover:bg-transparent opacity-100 hover:opacity-40"
                        src={DownArrow}
                    />
                </div>
            )}
        </div>
    );
}

export default function Signature(props: {
    owner: boolean;
    count: number;
    signatureHeadline: string;
}) {
    const signCanvas = React.useRef() as React.MutableRefObject<any>;

    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();
    const validSignCanvases = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.signCanvases
    ).filter((signCanvas) => !!signCanvas);

    const alreadySigned = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.alreadySigned
    );
    const isMobile = useCheckMobileScreen();

    let enabled = alreadySigned[props.count];

    const myAccountName = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.dbUser?.accountName
    );
    const history = useHistory();

    const amIOwner = createdByMyAccount(myAccountName, activeDraft?.id);

    const dispatch = useDispatch();

    const emptyReturn = <div style={{ width: "100%" }} />;

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    useEffect(() => {
        dispatch(changeAlreadySigned(false, props.count));
        return () => {};
    }, []);

    useEffect(() => {
        if (amIOwner === props.owner) {
            dispatch(registerSignCanvas(signCanvas, props.count));
        }

        return () => {
            dispatch(registerSignCanvas(undefined, props.count));
        };
    }, [signCanvas]);

    if (activeDraft?.docStatus === PDocStatus.DRAFT) {
        return emptyReturn;
    }

    if (activeDraft?.docStatus === PDocStatus.SENT && amIOwner) {
        return emptyReturn;
    }

    if (!agreement) {
        return emptyReturn;
    }

    if (!props.owner) {
        let canViewOtherSig = amIOwner || activeDraft?.docStatus === PDocStatus.COMPLETED;
        canViewOtherSig =
            canViewOtherSig || (!amIOwner && activeDraft?.docStatus === PDocStatus.INBOX);

        if (canViewOtherSig) {
            return (
                <div
                    className="OverrideSig"
                    style={{
                        display: "block",
                        textAlign: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        width: "100%",
                    }}
                >
                    <p className="text-primary text-base">{props.signatureHeadline}</p>
                    {props.count === 0 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.sharedWithUser?.signatures[0]}
                        />
                    )}
                    {props.count === 1 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.sharedWithUser?.signatures[1]}
                        />
                    )}
                    {props.count === 2 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.sharedWithUser?.signatures[2]}
                        />
                    )}
                </div>
            );
        }
    } else {
        if (activeDraft?.docStatus === PDocStatus.COMPLETED) {
            return (
                <div
                    className="OverrideSig"
                    style={{
                        display: "block",
                        textAlign: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        width: "100%",
                    }}
                >
                    <p className="text-primary text-base">{props.signatureHeadline}</p>
                    {props.count === 0 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.ownerUser?.signatures[0]}
                        />
                    )}
                    {props.count === 1 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.ownerUser?.signatures[1]}
                        />
                    )}
                    {props.count === 2 && (
                        <img
                            style={{ marginTop: "0.5rem", width: "22rem", marginBottom: "2.5rem" }}
                            src={activeDraft?.permanentContext?.ownerUser?.signatures[2]}
                        />
                    )}
                </div>
            );
        }
    }

    if (!amIOwner && props.owner) {
        return emptyReturn;
    }

    let alreadySignedLen = alreadySigned.filter((x) => !!x).length;

    let btnText = buttonText(alreadySignedLen, validSignCanvases);

    return (
        <div className="flex flex-col items-center w-full justify-center ml">
            <SignatureBox
                key={props.count}
                signCanvas={signCanvas}
                count={props.count}
                reuse={alreadySignedLen > 0 && !enabled}
                props={undefined}
            />
            <div className="flex my-4 justify-between items-center gap-4 w-full">
                <div className="w-32">
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            signCanvas.current.clear();
                            dispatch(changeAlreadySigned(false, props.count));
                        }}
                    >
                        Clear
                    </Button>
                </div>

                <Chip>
                    {props.count + 1}/{validSignCanvases.length}
                </Chip>
                <div className="w-32">
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        color={enabled ? "secondary" : "primary"}
                        onClick={() => {
                            if (!enabled || !activeDraft?.id) {
                                return;
                            }

                            if (btnText === "Next" && validSignCanvases) {
                                validSignCanvases[
                                    (props.count + 1) % validSignCanvases.length
                                ].current
                                    .getCanvas()
                                    .parentElement.parentElement.scrollIntoView({
                                        behavior: "smooth",
                                    });
                                return;
                            }

                            let text = amIOwner
                                ? "Are you sure? Once you approve, the contract is finalized."
                                : "Are you sure? Once you approve, " +
                                  activeDraft.permanentContext?.ownerUser?.user?.fullName +
                                  ` (${activeDraft.permanentContext?.ownerUser?.user?.email})` +
                                  " can sign and the contract will be approved.";

                            if (agreement?.docShareType === "ONLY_OWNER_SIGNS") {
                                text =
                                    "Once signature is complete, you can always find it under Contracts > Completed.";
                            }

                            dispatch(
                                callDialog(DialogType.CONFIRM, text, (answer: boolean) => {
                                    if (answer) {
                                        let arr = validSignCanvases.map((signCanva) =>
                                            signCanva.current.getCanvas().toDataURL("image/png")
                                        );

                                        DocsApi.attachSignature(
                                            activeDraft.id,
                                            arr,
                                            agreement.docShareType,
                                            () => {
                                                getAttachSignatureSuccessText(amIOwner);
                                                dispatch(
                                                    callDialog(
                                                        DialogType.SUCCESS,
                                                        "Successfully shared",
                                                        () => {
                                                            if (isProd()) {
                                                                mixpanel.track(
                                                                    amIOwner
                                                                        ? "signatures-completed"
                                                                        : "re-share-contract",
                                                                    {
                                                                        otherSide: amIOwner
                                                                            ? activeDraft
                                                                                  .permanentContext
                                                                                  ?.sharedWithUser
                                                                            : activeDraft
                                                                                  .permanentContext
                                                                                  ?.ownerUser,
                                                                    }
                                                                );
                                                            }

                                                            DocsApi.getAllDocs(dispatch);
                                                            history.push("/");
                                                        }
                                                    )
                                                );
                                            }
                                        );
                                    }
                                })
                            );
                        }}
                    >
                        {btnText}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function buttonText(alreadySignedLen: number, signCanvasas: React.MutableRefObject<any>[]) {
    if (!signCanvasas) {
        return "";
    }

    if (alreadySignedLen === signCanvasas.length) {
        return "Submit";
    } else return "Next";
}

function getAttachSignatureSuccessText(amIOwner: boolean) {
    if (!amIOwner) {
        return "Your signature has been sent to the owner. Once they approve, you will be notified.";
    }

    return "Signature successfully completed. You can always find it under Contracts > Completed.";
}
