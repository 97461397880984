import { PdfPageLayout } from "./layout/PdfPageLayout";
import { PdfStoreProvider } from "./PdfContext";

export const PdfTools = () => {
    return (
        <PdfStoreProvider>
            <PdfPageLayout />
        </PdfStoreProvider>
    );
};
