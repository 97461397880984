import {  AxiosResponse } from "axios"
import { PGetAllTracks } from "../proto/PApi";
import { PHelpInWizard, PLegalTopicsSurvey } from "../proto/PTracking";
import { request } from "./ApiCommon";

export function postTrack(req: PLegalTopicsSurvey, successCallback: Function) {
  request("/api/v1/track", {method: "POST", data: PLegalTopicsSurvey.toJSON(req)})
  ?.then((response) => successCallback(response.data))
  .catch(() => {}) // todo
}

export function getTrack(successCallback: Function)
{
    request("/api/v1/track", {method: "GET"})
    ?.then((response) => successCallback(PLegalTopicsSurvey.fromJSON(response.data)))
    .catch((e: any) => {
    }) // todo
}

export function getAllTracks(successCallback: Function)
{
    request("/api/v1/track/all", {method: "GET"})
    ?.then((response) => successCallback(PGetAllTracks.fromJSON(response.data)))
    .catch((e: any) => {
    }) // todo
}

export function postHelpInWizard(req: PHelpInWizard, successCallback: Function)
{
  request("/api/v1/track/wizard-help", {method: "POST", data: PHelpInWizard.toJSON(req)})
  ?.then((response) => successCallback(response.data))
  .catch(() => {}) // todo
}
