import { forwardRef, useContext } from "react";
import { useAppSelector } from "store";

import jsonLogic from "json-logic-js";

import { AgreementContext } from "../../components/WizardUi/AgreementContext/AgreementContext";
import { ScrollableFillable } from ".";
import { ConditionalStrategy, MULTI_SELECTION_OPERATORS, MULTI_SELECTION_OPERATORS_ARR } from "../parser";
import { KnowLedgeBaseElement, KnowLedgeBaseElementNew } from "apps/legal-ide/features";
import { FieldType } from "../types/custom-types";
import { toCompoundJsonLogic } from "../../components/WizardUi/WizardForm";
import { ABTestContractText } from "components/utils/ABTesting";

export const Element = ({ attributes, children, element }: any) => {
    const elStyles = { textAlign: element.align };

    const style = { ...elStyles, ...element.style };

    switch (element.type) {
        case "quote":
            return (
                <blockquote
                    {...attributes}
                    style={{
                        ...element.style,
                        ...{
                            borderLeft: "2px solid #ddd",
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: "10px",
                            color: "#aaa",
                            fontStyle: "italic",
                        },
                        ...elStyles,
                    }}
                >
                    {children}
                </blockquote>
            );
        case "bulleted-list":
            return (
                <ul {...attributes} style={style}>
                    {children}
                </ul>
            );
        case "heading-one":
            return (
                <h1 {...attributes} style={{ ...element.style, ...{ fontSize: "2rem" } }}>
                    {children}
                </h1>
            );
        case "heading-two":
            return (
                <h2 {...attributes} style={{ ...element.style, ...{ fontSize: "1.5rem" } }}>
                    {children}
                </h2>
            );
        case "heading-three":
            return (
                <h3 {...attributes} style={{ ...element.style, ...{ fontSize: "1rem" } }}>
                    {children}
                </h3>
            );
        case "heading-five":
            return (
                <h5 {...attributes} style={{ ...element.style, ...{ fontSize: "0.8rem" } }}>
                    {children}
                </h5>
            );
        case "pre-paragraph-margin":
            return (
                <p {...attributes} style={{ ...element.style, ...{ marginTop: "1rem" } }}>
                    {children}
                </p>
            );
        case "list-item":
            return (
                <li {...attributes} style={style}>
                    {children}
                </li>
            );
        case "numbered-list":
            return (
                <ol {...attributes} style={style}>
                    {children}
                </ol>
            );
        case "b":
            return (
                <b {...attributes} style={style}>
                    {children}
                </b>
            );
        case "kb":
            return (
                <KnowLedgeBaseElementNew {...attributes} element={element}>
                    {children}
                </KnowLedgeBaseElementNew>
            );
        case "condition-block":
        case "condition-inline":
        case "condition":
            return (
                <ConditionWrapper {...attributes} style={style} element={element}>
                    {children}
                </ConditionWrapper>
            );
        default:
            return (
                <p {...attributes} style={style}>
                    {children}
                </p>
            );
    }
};

export function jsonToCompoundJsonLogic(json: CompoundJsonLogic | JsonLogic, allFields: Field[] | undefined): any {
    let compound = toCompoundJsonLogic(json);

    const andOrOr = compound?.concatOperator === "OR" ? "or" : "and";

    if (!compound?.conditions) {
        return true;
    }

    return {
        [andOrOr]: compound.conditions.map((condition) =>
            jsonToLogic(
                condition,
                allFields?.find((f) => f.id === condition.fieldId)
            )
        ),
    };
}

function jsonToLogic(json: JsonLogic, field: Field | undefined) {
    let fieldPath = field?.name;

    if ([FieldType.ENUM, FieldType.MULTI_ENUM].includes(field?.type)) {
        fieldPath = `${fieldPath}.optionDbValue`;
    }

    try {
        if (!json || !json.operator || !fieldPath) {
            console.warn("jsonToLogic invalid data");

            return false;
        }
        const value = isNaN(Number(json.value)) ? json.value : Number(json.value);

        if (MULTI_SELECTION_OPERATORS_ARR.includes(json.operator)) {
            return multiSelectOperatorLogic(json, fieldPath);
        }

        return {
            [json.operator]: [{ var: fieldPath || "" }, value],
        };
    } catch (error: any) {
        console.error("jsonToLogic err", error.message);

        return false;
    }
}

function multiSelectOperatorLogic(json: JsonLogic, fieldName: string) {
    if (json.operator === MULTI_SELECTION_OPERATORS.IS_EMPTY) {
        return { "!": [{ var: fieldName }] };
    } else if (json.operator === MULTI_SELECTION_OPERATORS.NOT_EMPTY) {
        return { "!!": [{ var: fieldName }] };
    }

    const logicPerDBVal = (json.value as number[]).map((dbVal) => {
        return {
            ["in"]: [dbVal, { var: fieldName || "" }],
        };
    });

    let operator = "";
    let negate = false;

    if (json.operator === MULTI_SELECTION_OPERATORS.ALL) {
        operator = "and";
        negate = false;
    } else if (json.operator === MULTI_SELECTION_OPERATORS.SOME) {
        operator = "or";
        negate = false;
    } else if (json.operator === MULTI_SELECTION_OPERATORS.NOT_ALL) {
        operator = "and";
        negate = true;
    } else {
        // NOT_SOME
        operator = "or";
        negate = true;
    }

    const yesOrNot = negate ? "!" : "!!";

    return {
        [yesOrNot]: { [operator]: logicPerDBVal },
    };
}

const ConditionWrapper = forwardRef<HTMLDivElement>((props: any, ref) => {
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const agreementContext = useContext(AgreementContext);

    const condition = toCompoundJsonLogic(props?.element?.condition);

    if (!condition) {
        return null;
    }

    const jsonLogicValue = jsonToCompoundJsonLogic(condition, currentAgreement?.fields);

    const data = agreementContext?.wizardApi?.getCurrentContract(agreementContext.getActiveDraft());

    const isValid = jsonLogic.apply(jsonLogicValue, data);

    const firstField = currentAgreement?.fields?.find((f) => f.id === condition?.conditions?.[0]?.fieldId);

    return (
        <>
            {firstField && <ScrollableFillable field={firstField} element={props.element} isFromCondition={true} />}
            {isValid && <ABTestContractText children={props.children} />}
        </>
    );
});
