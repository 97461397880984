/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile, PTable } from "../PUtils";

export const protobufPackage = "";

export enum ENotePurchaseAgreementCompanyEntityType {
  NotePurchaseAgreement_companyEntityType_NA = 0,
  NotePurchaseAgreement_companyEntityType_CORPORATION = 1,
  NotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  NotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  NotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP = 4,
  NotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  NotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  NotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  NotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION = 8,
  NotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  NotePurchaseAgreement_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementCompanyEntityTypeFromJSON(object: any): ENotePurchaseAgreementCompanyEntityType {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_companyEntityType_NA":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_NA;
    case 1:
    case "NotePurchaseAgreement_companyEntityType_CORPORATION":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_CORPORATION;
    case 2:
    case "NotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "NotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "NotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return ENotePurchaseAgreementCompanyEntityType
        .NotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "NotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "NotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "NotePurchaseAgreement_companyEntityType_PARTNERSHIP":
      return ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementCompanyEntityType.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementCompanyEntityTypeToJSON(object: ENotePurchaseAgreementCompanyEntityType): string {
  switch (object) {
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_NA:
      return "NotePurchaseAgreement_companyEntityType_NA";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_CORPORATION:
      return "NotePurchaseAgreement_companyEntityType_CORPORATION";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "NotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP:
      return "NotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP:
      return "NotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION:
      return "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "NotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION:
      return "NotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "NotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case ENotePurchaseAgreementCompanyEntityType.NotePurchaseAgreement_companyEntityType_PARTNERSHIP:
      return "NotePurchaseAgreement_companyEntityType_PARTNERSHIP";
    case ENotePurchaseAgreementCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementJurisdictions {
  NotePurchaseAgreement_jurisdictions_NA = 0,
  NotePurchaseAgreement_jurisdictions_NONE = 1,
  NotePurchaseAgreement_jurisdictions_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementJurisdictionsFromJSON(object: any): ENotePurchaseAgreementJurisdictions {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_jurisdictions_NA":
      return ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_NA;
    case 1:
    case "NotePurchaseAgreement_jurisdictions_NONE":
      return ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_NONE;
    case 999999:
    case "NotePurchaseAgreement_jurisdictions_OTHER":
      return ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementJurisdictions.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementJurisdictionsToJSON(object: ENotePurchaseAgreementJurisdictions): string {
  switch (object) {
    case ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_NA:
      return "NotePurchaseAgreement_jurisdictions_NA";
    case ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_NONE:
      return "NotePurchaseAgreement_jurisdictions_NONE";
    case ENotePurchaseAgreementJurisdictions.NotePurchaseAgreement_jurisdictions_OTHER:
      return "NotePurchaseAgreement_jurisdictions_OTHER";
    case ENotePurchaseAgreementJurisdictions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure31 {
  NotePurchaseAgreement_disclosure31_NA = 0,
  NotePurchaseAgreement_disclosure31_NONE = 1,
  NotePurchaseAgreement_disclosure31_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure31FromJSON(object: any): ENotePurchaseAgreementDisclosure31 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure31_NA":
      return ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure31_NONE":
      return ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure31_OTHER":
      return ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure31.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure31ToJSON(object: ENotePurchaseAgreementDisclosure31): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_NA:
      return "NotePurchaseAgreement_disclosure31_NA";
    case ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_NONE:
      return "NotePurchaseAgreement_disclosure31_NONE";
    case ENotePurchaseAgreementDisclosure31.NotePurchaseAgreement_disclosure31_OTHER:
      return "NotePurchaseAgreement_disclosure31_OTHER";
    case ENotePurchaseAgreementDisclosure31.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure32 {
  NotePurchaseAgreement_disclosure32_NA = 0,
  NotePurchaseAgreement_disclosure32_NONE = 1,
  NotePurchaseAgreement_disclosure32_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure32FromJSON(object: any): ENotePurchaseAgreementDisclosure32 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure32_NA":
      return ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure32_NONE":
      return ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure32_OTHER":
      return ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure32.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure32ToJSON(object: ENotePurchaseAgreementDisclosure32): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_NA:
      return "NotePurchaseAgreement_disclosure32_NA";
    case ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_NONE:
      return "NotePurchaseAgreement_disclosure32_NONE";
    case ENotePurchaseAgreementDisclosure32.NotePurchaseAgreement_disclosure32_OTHER:
      return "NotePurchaseAgreement_disclosure32_OTHER";
    case ENotePurchaseAgreementDisclosure32.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure33 {
  NotePurchaseAgreement_disclosure33_NA = 0,
  NotePurchaseAgreement_disclosure33_NONE = 1,
  NotePurchaseAgreement_disclosure33_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure33FromJSON(object: any): ENotePurchaseAgreementDisclosure33 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure33_NA":
      return ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure33_NONE":
      return ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure33_OTHER":
      return ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure33.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure33ToJSON(object: ENotePurchaseAgreementDisclosure33): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_NA:
      return "NotePurchaseAgreement_disclosure33_NA";
    case ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_NONE:
      return "NotePurchaseAgreement_disclosure33_NONE";
    case ENotePurchaseAgreementDisclosure33.NotePurchaseAgreement_disclosure33_OTHER:
      return "NotePurchaseAgreement_disclosure33_OTHER";
    case ENotePurchaseAgreementDisclosure33.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure34 {
  NotePurchaseAgreement_disclosure34_NA = 0,
  NotePurchaseAgreement_disclosure34_NONE = 1,
  NotePurchaseAgreement_disclosure34_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure34FromJSON(object: any): ENotePurchaseAgreementDisclosure34 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure34_NA":
      return ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure34_NONE":
      return ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure34_OTHER":
      return ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure34.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure34ToJSON(object: ENotePurchaseAgreementDisclosure34): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_NA:
      return "NotePurchaseAgreement_disclosure34_NA";
    case ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_NONE:
      return "NotePurchaseAgreement_disclosure34_NONE";
    case ENotePurchaseAgreementDisclosure34.NotePurchaseAgreement_disclosure34_OTHER:
      return "NotePurchaseAgreement_disclosure34_OTHER";
    case ENotePurchaseAgreementDisclosure34.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure35 {
  NotePurchaseAgreement_disclosure35_NA = 0,
  NotePurchaseAgreement_disclosure35_NONE = 1,
  NotePurchaseAgreement_disclosure35_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure35FromJSON(object: any): ENotePurchaseAgreementDisclosure35 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure35_NA":
      return ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure35_NONE":
      return ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure35_OTHER":
      return ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure35.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure35ToJSON(object: ENotePurchaseAgreementDisclosure35): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_NA:
      return "NotePurchaseAgreement_disclosure35_NA";
    case ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_NONE:
      return "NotePurchaseAgreement_disclosure35_NONE";
    case ENotePurchaseAgreementDisclosure35.NotePurchaseAgreement_disclosure35_OTHER:
      return "NotePurchaseAgreement_disclosure35_OTHER";
    case ENotePurchaseAgreementDisclosure35.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure36 {
  NotePurchaseAgreement_disclosure36_NA = 0,
  NotePurchaseAgreement_disclosure36_NONE = 1,
  NotePurchaseAgreement_disclosure36_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure36FromJSON(object: any): ENotePurchaseAgreementDisclosure36 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure36_NA":
      return ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure36_NONE":
      return ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure36_OTHER":
      return ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure36.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure36ToJSON(object: ENotePurchaseAgreementDisclosure36): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_NA:
      return "NotePurchaseAgreement_disclosure36_NA";
    case ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_NONE:
      return "NotePurchaseAgreement_disclosure36_NONE";
    case ENotePurchaseAgreementDisclosure36.NotePurchaseAgreement_disclosure36_OTHER:
      return "NotePurchaseAgreement_disclosure36_OTHER";
    case ENotePurchaseAgreementDisclosure36.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure37 {
  NotePurchaseAgreement_disclosure37_NA = 0,
  NotePurchaseAgreement_disclosure37_NONE = 1,
  NotePurchaseAgreement_disclosure37_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure37FromJSON(object: any): ENotePurchaseAgreementDisclosure37 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure37_NA":
      return ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure37_NONE":
      return ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure37_OTHER":
      return ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure37.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure37ToJSON(object: ENotePurchaseAgreementDisclosure37): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_NA:
      return "NotePurchaseAgreement_disclosure37_NA";
    case ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_NONE:
      return "NotePurchaseAgreement_disclosure37_NONE";
    case ENotePurchaseAgreementDisclosure37.NotePurchaseAgreement_disclosure37_OTHER:
      return "NotePurchaseAgreement_disclosure37_OTHER";
    case ENotePurchaseAgreementDisclosure37.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure38 {
  NotePurchaseAgreement_disclosure38_NA = 0,
  NotePurchaseAgreement_disclosure38_NONE = 1,
  NotePurchaseAgreement_disclosure38_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure38FromJSON(object: any): ENotePurchaseAgreementDisclosure38 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure38_NA":
      return ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure38_NONE":
      return ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure38_OTHER":
      return ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure38.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure38ToJSON(object: ENotePurchaseAgreementDisclosure38): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_NA:
      return "NotePurchaseAgreement_disclosure38_NA";
    case ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_NONE:
      return "NotePurchaseAgreement_disclosure38_NONE";
    case ENotePurchaseAgreementDisclosure38.NotePurchaseAgreement_disclosure38_OTHER:
      return "NotePurchaseAgreement_disclosure38_OTHER";
    case ENotePurchaseAgreementDisclosure38.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure39 {
  NotePurchaseAgreement_disclosure39_NA = 0,
  NotePurchaseAgreement_disclosure39_NONE = 1,
  NotePurchaseAgreement_disclosure39_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure39FromJSON(object: any): ENotePurchaseAgreementDisclosure39 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure39_NA":
      return ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure39_NONE":
      return ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure39_OTHER":
      return ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure39.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure39ToJSON(object: ENotePurchaseAgreementDisclosure39): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_NA:
      return "NotePurchaseAgreement_disclosure39_NA";
    case ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_NONE:
      return "NotePurchaseAgreement_disclosure39_NONE";
    case ENotePurchaseAgreementDisclosure39.NotePurchaseAgreement_disclosure39_OTHER:
      return "NotePurchaseAgreement_disclosure39_OTHER";
    case ENotePurchaseAgreementDisclosure39.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure310 {
  NotePurchaseAgreement_disclosure310_NA = 0,
  NotePurchaseAgreement_disclosure310_NONE = 1,
  NotePurchaseAgreement_disclosure310_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure310FromJSON(object: any): ENotePurchaseAgreementDisclosure310 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure310_NA":
      return ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure310_NONE":
      return ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure310_OTHER":
      return ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure310.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure310ToJSON(object: ENotePurchaseAgreementDisclosure310): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_NA:
      return "NotePurchaseAgreement_disclosure310_NA";
    case ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_NONE:
      return "NotePurchaseAgreement_disclosure310_NONE";
    case ENotePurchaseAgreementDisclosure310.NotePurchaseAgreement_disclosure310_OTHER:
      return "NotePurchaseAgreement_disclosure310_OTHER";
    case ENotePurchaseAgreementDisclosure310.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure311 {
  NotePurchaseAgreement_disclosure311_NA = 0,
  NotePurchaseAgreement_disclosure311_NONE = 1,
  NotePurchaseAgreement_disclosure311_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure311FromJSON(object: any): ENotePurchaseAgreementDisclosure311 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure311_NA":
      return ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure311_NONE":
      return ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure311_OTHER":
      return ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure311.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure311ToJSON(object: ENotePurchaseAgreementDisclosure311): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_NA:
      return "NotePurchaseAgreement_disclosure311_NA";
    case ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_NONE:
      return "NotePurchaseAgreement_disclosure311_NONE";
    case ENotePurchaseAgreementDisclosure311.NotePurchaseAgreement_disclosure311_OTHER:
      return "NotePurchaseAgreement_disclosure311_OTHER";
    case ENotePurchaseAgreementDisclosure311.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure312 {
  NotePurchaseAgreement_disclosure312_NA = 0,
  NotePurchaseAgreement_disclosure312_NONE = 1,
  NotePurchaseAgreement_disclosure312_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure312FromJSON(object: any): ENotePurchaseAgreementDisclosure312 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure312_NA":
      return ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure312_NONE":
      return ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure312_OTHER":
      return ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure312.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure312ToJSON(object: ENotePurchaseAgreementDisclosure312): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_NA:
      return "NotePurchaseAgreement_disclosure312_NA";
    case ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_NONE:
      return "NotePurchaseAgreement_disclosure312_NONE";
    case ENotePurchaseAgreementDisclosure312.NotePurchaseAgreement_disclosure312_OTHER:
      return "NotePurchaseAgreement_disclosure312_OTHER";
    case ENotePurchaseAgreementDisclosure312.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure313 {
  NotePurchaseAgreement_disclosure313_NA = 0,
  NotePurchaseAgreement_disclosure313_NONE = 1,
  NotePurchaseAgreement_disclosure313_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure313FromJSON(object: any): ENotePurchaseAgreementDisclosure313 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure313_NA":
      return ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure313_NONE":
      return ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure313_OTHER":
      return ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure313.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure313ToJSON(object: ENotePurchaseAgreementDisclosure313): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_NA:
      return "NotePurchaseAgreement_disclosure313_NA";
    case ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_NONE:
      return "NotePurchaseAgreement_disclosure313_NONE";
    case ENotePurchaseAgreementDisclosure313.NotePurchaseAgreement_disclosure313_OTHER:
      return "NotePurchaseAgreement_disclosure313_OTHER";
    case ENotePurchaseAgreementDisclosure313.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure314 {
  NotePurchaseAgreement_disclosure314_NA = 0,
  NotePurchaseAgreement_disclosure314_NONE = 1,
  NotePurchaseAgreement_disclosure314_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure314FromJSON(object: any): ENotePurchaseAgreementDisclosure314 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure314_NA":
      return ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure314_NONE":
      return ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure314_OTHER":
      return ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure314.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure314ToJSON(object: ENotePurchaseAgreementDisclosure314): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_NA:
      return "NotePurchaseAgreement_disclosure314_NA";
    case ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_NONE:
      return "NotePurchaseAgreement_disclosure314_NONE";
    case ENotePurchaseAgreementDisclosure314.NotePurchaseAgreement_disclosure314_OTHER:
      return "NotePurchaseAgreement_disclosure314_OTHER";
    case ENotePurchaseAgreementDisclosure314.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure315 {
  NotePurchaseAgreement_disclosure315_NA = 0,
  NotePurchaseAgreement_disclosure315_NONE = 1,
  NotePurchaseAgreement_disclosure315_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure315FromJSON(object: any): ENotePurchaseAgreementDisclosure315 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure315_NA":
      return ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure315_NONE":
      return ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure315_OTHER":
      return ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure315.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure315ToJSON(object: ENotePurchaseAgreementDisclosure315): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_NA:
      return "NotePurchaseAgreement_disclosure315_NA";
    case ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_NONE:
      return "NotePurchaseAgreement_disclosure315_NONE";
    case ENotePurchaseAgreementDisclosure315.NotePurchaseAgreement_disclosure315_OTHER:
      return "NotePurchaseAgreement_disclosure315_OTHER";
    case ENotePurchaseAgreementDisclosure315.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure316 {
  NotePurchaseAgreement_disclosure316_NA = 0,
  NotePurchaseAgreement_disclosure316_NONE = 1,
  NotePurchaseAgreement_disclosure316_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure316FromJSON(object: any): ENotePurchaseAgreementDisclosure316 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure316_NA":
      return ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure316_NONE":
      return ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure316_OTHER":
      return ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure316.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure316ToJSON(object: ENotePurchaseAgreementDisclosure316): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_NA:
      return "NotePurchaseAgreement_disclosure316_NA";
    case ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_NONE:
      return "NotePurchaseAgreement_disclosure316_NONE";
    case ENotePurchaseAgreementDisclosure316.NotePurchaseAgreement_disclosure316_OTHER:
      return "NotePurchaseAgreement_disclosure316_OTHER";
    case ENotePurchaseAgreementDisclosure316.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure317 {
  NotePurchaseAgreement_disclosure317_NA = 0,
  NotePurchaseAgreement_disclosure317_NONE = 1,
  NotePurchaseAgreement_disclosure317_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure317FromJSON(object: any): ENotePurchaseAgreementDisclosure317 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure317_NA":
      return ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure317_NONE":
      return ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure317_OTHER":
      return ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure317.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure317ToJSON(object: ENotePurchaseAgreementDisclosure317): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_NA:
      return "NotePurchaseAgreement_disclosure317_NA";
    case ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_NONE:
      return "NotePurchaseAgreement_disclosure317_NONE";
    case ENotePurchaseAgreementDisclosure317.NotePurchaseAgreement_disclosure317_OTHER:
      return "NotePurchaseAgreement_disclosure317_OTHER";
    case ENotePurchaseAgreementDisclosure317.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure318 {
  NotePurchaseAgreement_disclosure318_NA = 0,
  NotePurchaseAgreement_disclosure318_NONE = 1,
  NotePurchaseAgreement_disclosure318_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure318FromJSON(object: any): ENotePurchaseAgreementDisclosure318 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure318_NA":
      return ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure318_NONE":
      return ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure318_OTHER":
      return ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure318.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure318ToJSON(object: ENotePurchaseAgreementDisclosure318): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_NA:
      return "NotePurchaseAgreement_disclosure318_NA";
    case ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_NONE:
      return "NotePurchaseAgreement_disclosure318_NONE";
    case ENotePurchaseAgreementDisclosure318.NotePurchaseAgreement_disclosure318_OTHER:
      return "NotePurchaseAgreement_disclosure318_OTHER";
    case ENotePurchaseAgreementDisclosure318.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure319 {
  NotePurchaseAgreement_disclosure319_NA = 0,
  NotePurchaseAgreement_disclosure319_NONE = 1,
  NotePurchaseAgreement_disclosure319_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure319FromJSON(object: any): ENotePurchaseAgreementDisclosure319 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure319_NA":
      return ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure319_NONE":
      return ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure319_OTHER":
      return ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure319.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure319ToJSON(object: ENotePurchaseAgreementDisclosure319): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_NA:
      return "NotePurchaseAgreement_disclosure319_NA";
    case ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_NONE:
      return "NotePurchaseAgreement_disclosure319_NONE";
    case ENotePurchaseAgreementDisclosure319.NotePurchaseAgreement_disclosure319_OTHER:
      return "NotePurchaseAgreement_disclosure319_OTHER";
    case ENotePurchaseAgreementDisclosure319.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure320 {
  NotePurchaseAgreement_disclosure320_NA = 0,
  NotePurchaseAgreement_disclosure320_NONE = 1,
  NotePurchaseAgreement_disclosure320_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure320FromJSON(object: any): ENotePurchaseAgreementDisclosure320 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure320_NA":
      return ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure320_NONE":
      return ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure320_OTHER":
      return ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure320.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure320ToJSON(object: ENotePurchaseAgreementDisclosure320): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_NA:
      return "NotePurchaseAgreement_disclosure320_NA";
    case ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_NONE:
      return "NotePurchaseAgreement_disclosure320_NONE";
    case ENotePurchaseAgreementDisclosure320.NotePurchaseAgreement_disclosure320_OTHER:
      return "NotePurchaseAgreement_disclosure320_OTHER";
    case ENotePurchaseAgreementDisclosure320.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure321 {
  NotePurchaseAgreement_disclosure321_NA = 0,
  NotePurchaseAgreement_disclosure321_NONE = 1,
  NotePurchaseAgreement_disclosure321_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure321FromJSON(object: any): ENotePurchaseAgreementDisclosure321 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure321_NA":
      return ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure321_NONE":
      return ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure321_OTHER":
      return ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure321.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure321ToJSON(object: ENotePurchaseAgreementDisclosure321): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_NA:
      return "NotePurchaseAgreement_disclosure321_NA";
    case ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_NONE:
      return "NotePurchaseAgreement_disclosure321_NONE";
    case ENotePurchaseAgreementDisclosure321.NotePurchaseAgreement_disclosure321_OTHER:
      return "NotePurchaseAgreement_disclosure321_OTHER";
    case ENotePurchaseAgreementDisclosure321.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure322 {
  NotePurchaseAgreement_disclosure322_NA = 0,
  NotePurchaseAgreement_disclosure322_NONE = 1,
  NotePurchaseAgreement_disclosure322_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure322FromJSON(object: any): ENotePurchaseAgreementDisclosure322 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure322_NA":
      return ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure322_NONE":
      return ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure322_OTHER":
      return ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure322.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure322ToJSON(object: ENotePurchaseAgreementDisclosure322): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_NA:
      return "NotePurchaseAgreement_disclosure322_NA";
    case ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_NONE:
      return "NotePurchaseAgreement_disclosure322_NONE";
    case ENotePurchaseAgreementDisclosure322.NotePurchaseAgreement_disclosure322_OTHER:
      return "NotePurchaseAgreement_disclosure322_OTHER";
    case ENotePurchaseAgreementDisclosure322.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure323 {
  NotePurchaseAgreement_disclosure323_NA = 0,
  NotePurchaseAgreement_disclosure323_NONE = 1,
  NotePurchaseAgreement_disclosure323_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure323FromJSON(object: any): ENotePurchaseAgreementDisclosure323 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure323_NA":
      return ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure323_NONE":
      return ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure323_OTHER":
      return ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure323.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure323ToJSON(object: ENotePurchaseAgreementDisclosure323): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_NA:
      return "NotePurchaseAgreement_disclosure323_NA";
    case ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_NONE:
      return "NotePurchaseAgreement_disclosure323_NONE";
    case ENotePurchaseAgreementDisclosure323.NotePurchaseAgreement_disclosure323_OTHER:
      return "NotePurchaseAgreement_disclosure323_OTHER";
    case ENotePurchaseAgreementDisclosure323.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure49 {
  NotePurchaseAgreement_disclosure49_NA = 0,
  NotePurchaseAgreement_disclosure49_NONE = 1,
  NotePurchaseAgreement_disclosure49_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure49FromJSON(object: any): ENotePurchaseAgreementDisclosure49 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure49_NA":
      return ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure49_NONE":
      return ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure49_OTHER":
      return ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure49.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure49ToJSON(object: ENotePurchaseAgreementDisclosure49): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_NA:
      return "NotePurchaseAgreement_disclosure49_NA";
    case ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_NONE:
      return "NotePurchaseAgreement_disclosure49_NONE";
    case ENotePurchaseAgreementDisclosure49.NotePurchaseAgreement_disclosure49_OTHER:
      return "NotePurchaseAgreement_disclosure49_OTHER";
    case ENotePurchaseAgreementDisclosure49.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure41 {
  NotePurchaseAgreement_disclosure41_NA = 0,
  NotePurchaseAgreement_disclosure41_NONE = 1,
  NotePurchaseAgreement_disclosure41_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure41FromJSON(object: any): ENotePurchaseAgreementDisclosure41 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure41_NA":
      return ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure41_NONE":
      return ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure41_OTHER":
      return ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure41.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure41ToJSON(object: ENotePurchaseAgreementDisclosure41): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_NA:
      return "NotePurchaseAgreement_disclosure41_NA";
    case ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_NONE:
      return "NotePurchaseAgreement_disclosure41_NONE";
    case ENotePurchaseAgreementDisclosure41.NotePurchaseAgreement_disclosure41_OTHER:
      return "NotePurchaseAgreement_disclosure41_OTHER";
    case ENotePurchaseAgreementDisclosure41.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure42 {
  NotePurchaseAgreement_disclosure42_NA = 0,
  NotePurchaseAgreement_disclosure42_NONE = 1,
  NotePurchaseAgreement_disclosure42_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure42FromJSON(object: any): ENotePurchaseAgreementDisclosure42 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure42_NA":
      return ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure42_NONE":
      return ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure42_OTHER":
      return ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure42.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure42ToJSON(object: ENotePurchaseAgreementDisclosure42): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_NA:
      return "NotePurchaseAgreement_disclosure42_NA";
    case ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_NONE:
      return "NotePurchaseAgreement_disclosure42_NONE";
    case ENotePurchaseAgreementDisclosure42.NotePurchaseAgreement_disclosure42_OTHER:
      return "NotePurchaseAgreement_disclosure42_OTHER";
    case ENotePurchaseAgreementDisclosure42.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure43 {
  NotePurchaseAgreement_disclosure43_NA = 0,
  NotePurchaseAgreement_disclosure43_NONE = 1,
  NotePurchaseAgreement_disclosure43_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure43FromJSON(object: any): ENotePurchaseAgreementDisclosure43 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure43_NA":
      return ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure43_NONE":
      return ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure43_OTHER":
      return ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure43.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure43ToJSON(object: ENotePurchaseAgreementDisclosure43): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_NA:
      return "NotePurchaseAgreement_disclosure43_NA";
    case ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_NONE:
      return "NotePurchaseAgreement_disclosure43_NONE";
    case ENotePurchaseAgreementDisclosure43.NotePurchaseAgreement_disclosure43_OTHER:
      return "NotePurchaseAgreement_disclosure43_OTHER";
    case ENotePurchaseAgreementDisclosure43.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure44 {
  NotePurchaseAgreement_disclosure44_NA = 0,
  NotePurchaseAgreement_disclosure44_NONE = 1,
  NotePurchaseAgreement_disclosure44_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure44FromJSON(object: any): ENotePurchaseAgreementDisclosure44 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure44_NA":
      return ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure44_NONE":
      return ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure44_OTHER":
      return ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure44.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure44ToJSON(object: ENotePurchaseAgreementDisclosure44): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_NA:
      return "NotePurchaseAgreement_disclosure44_NA";
    case ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_NONE:
      return "NotePurchaseAgreement_disclosure44_NONE";
    case ENotePurchaseAgreementDisclosure44.NotePurchaseAgreement_disclosure44_OTHER:
      return "NotePurchaseAgreement_disclosure44_OTHER";
    case ENotePurchaseAgreementDisclosure44.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure45 {
  NotePurchaseAgreement_disclosure45_NA = 0,
  NotePurchaseAgreement_disclosure45_NONE = 1,
  NotePurchaseAgreement_disclosure45_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure45FromJSON(object: any): ENotePurchaseAgreementDisclosure45 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure45_NA":
      return ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure45_NONE":
      return ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure45_OTHER":
      return ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure45.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure45ToJSON(object: ENotePurchaseAgreementDisclosure45): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_NA:
      return "NotePurchaseAgreement_disclosure45_NA";
    case ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_NONE:
      return "NotePurchaseAgreement_disclosure45_NONE";
    case ENotePurchaseAgreementDisclosure45.NotePurchaseAgreement_disclosure45_OTHER:
      return "NotePurchaseAgreement_disclosure45_OTHER";
    case ENotePurchaseAgreementDisclosure45.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure46 {
  NotePurchaseAgreement_disclosure46_NA = 0,
  NotePurchaseAgreement_disclosure46_NONE = 1,
  NotePurchaseAgreement_disclosure46_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure46FromJSON(object: any): ENotePurchaseAgreementDisclosure46 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure46_NA":
      return ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure46_NONE":
      return ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure46_OTHER":
      return ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure46.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure46ToJSON(object: ENotePurchaseAgreementDisclosure46): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_NA:
      return "NotePurchaseAgreement_disclosure46_NA";
    case ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_NONE:
      return "NotePurchaseAgreement_disclosure46_NONE";
    case ENotePurchaseAgreementDisclosure46.NotePurchaseAgreement_disclosure46_OTHER:
      return "NotePurchaseAgreement_disclosure46_OTHER";
    case ENotePurchaseAgreementDisclosure46.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure47 {
  NotePurchaseAgreement_disclosure47_NA = 0,
  NotePurchaseAgreement_disclosure47_NONE = 1,
  NotePurchaseAgreement_disclosure47_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure47FromJSON(object: any): ENotePurchaseAgreementDisclosure47 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure47_NA":
      return ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure47_NONE":
      return ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure47_OTHER":
      return ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure47.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure47ToJSON(object: ENotePurchaseAgreementDisclosure47): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_NA:
      return "NotePurchaseAgreement_disclosure47_NA";
    case ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_NONE:
      return "NotePurchaseAgreement_disclosure47_NONE";
    case ENotePurchaseAgreementDisclosure47.NotePurchaseAgreement_disclosure47_OTHER:
      return "NotePurchaseAgreement_disclosure47_OTHER";
    case ENotePurchaseAgreementDisclosure47.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementDisclosure48 {
  NotePurchaseAgreement_disclosure48_NA = 0,
  NotePurchaseAgreement_disclosure48_NONE = 1,
  NotePurchaseAgreement_disclosure48_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementDisclosure48FromJSON(object: any): ENotePurchaseAgreementDisclosure48 {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_disclosure48_NA":
      return ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_NA;
    case 1:
    case "NotePurchaseAgreement_disclosure48_NONE":
      return ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_NONE;
    case 999999:
    case "NotePurchaseAgreement_disclosure48_OTHER":
      return ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementDisclosure48.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementDisclosure48ToJSON(object: ENotePurchaseAgreementDisclosure48): string {
  switch (object) {
    case ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_NA:
      return "NotePurchaseAgreement_disclosure48_NA";
    case ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_NONE:
      return "NotePurchaseAgreement_disclosure48_NONE";
    case ENotePurchaseAgreementDisclosure48.NotePurchaseAgreement_disclosure48_OTHER:
      return "NotePurchaseAgreement_disclosure48_OTHER";
    case ENotePurchaseAgreementDisclosure48.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ENotePurchaseAgreementPaymentSchedule {
  NotePurchaseAgreement_paymentSchedule_NA = 0,
  NotePurchaseAgreement_paymentSchedule_BALLOON = 1,
  NotePurchaseAgreement_paymentSchedule_AMORTIZED = 2,
  NotePurchaseAgreement_paymentSchedule_AMORTIZED_BALLOON = 3,
  UNRECOGNIZED = -1,
}

export function eNotePurchaseAgreementPaymentScheduleFromJSON(object: any): ENotePurchaseAgreementPaymentSchedule {
  switch (object) {
    case 0:
    case "NotePurchaseAgreement_paymentSchedule_NA":
      return ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_NA;
    case 1:
    case "NotePurchaseAgreement_paymentSchedule_BALLOON":
      return ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_BALLOON;
    case 2:
    case "NotePurchaseAgreement_paymentSchedule_AMORTIZED":
      return ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_AMORTIZED;
    case 3:
    case "NotePurchaseAgreement_paymentSchedule_AMORTIZED_BALLOON":
      return ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_AMORTIZED_BALLOON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ENotePurchaseAgreementPaymentSchedule.UNRECOGNIZED;
  }
}

export function eNotePurchaseAgreementPaymentScheduleToJSON(object: ENotePurchaseAgreementPaymentSchedule): string {
  switch (object) {
    case ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_NA:
      return "NotePurchaseAgreement_paymentSchedule_NA";
    case ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_BALLOON:
      return "NotePurchaseAgreement_paymentSchedule_BALLOON";
    case ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_AMORTIZED:
      return "NotePurchaseAgreement_paymentSchedule_AMORTIZED";
    case ENotePurchaseAgreementPaymentSchedule.NotePurchaseAgreement_paymentSchedule_AMORTIZED_BALLOON:
      return "NotePurchaseAgreement_paymentSchedule_AMORTIZED_BALLOON";
    case ENotePurchaseAgreementPaymentSchedule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MNotePurchaseAgreementCompanyEntityType {
  optionDbValue: ENotePurchaseAgreementCompanyEntityType;
  other: string;
}

export interface MNotePurchaseAgreementJurisdictions {
  optionDbValue: ENotePurchaseAgreementJurisdictions;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure31 {
  optionDbValue: ENotePurchaseAgreementDisclosure31;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure32 {
  optionDbValue: ENotePurchaseAgreementDisclosure32;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure33 {
  optionDbValue: ENotePurchaseAgreementDisclosure33;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure34 {
  optionDbValue: ENotePurchaseAgreementDisclosure34;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure35 {
  optionDbValue: ENotePurchaseAgreementDisclosure35;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure36 {
  optionDbValue: ENotePurchaseAgreementDisclosure36;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure37 {
  optionDbValue: ENotePurchaseAgreementDisclosure37;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure38 {
  optionDbValue: ENotePurchaseAgreementDisclosure38;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure39 {
  optionDbValue: ENotePurchaseAgreementDisclosure39;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure310 {
  optionDbValue: ENotePurchaseAgreementDisclosure310;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure311 {
  optionDbValue: ENotePurchaseAgreementDisclosure311;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure312 {
  optionDbValue: ENotePurchaseAgreementDisclosure312;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure313 {
  optionDbValue: ENotePurchaseAgreementDisclosure313;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure314 {
  optionDbValue: ENotePurchaseAgreementDisclosure314;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure315 {
  optionDbValue: ENotePurchaseAgreementDisclosure315;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure316 {
  optionDbValue: ENotePurchaseAgreementDisclosure316;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure317 {
  optionDbValue: ENotePurchaseAgreementDisclosure317;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure318 {
  optionDbValue: ENotePurchaseAgreementDisclosure318;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure319 {
  optionDbValue: ENotePurchaseAgreementDisclosure319;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure320 {
  optionDbValue: ENotePurchaseAgreementDisclosure320;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure321 {
  optionDbValue: ENotePurchaseAgreementDisclosure321;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure322 {
  optionDbValue: ENotePurchaseAgreementDisclosure322;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure323 {
  optionDbValue: ENotePurchaseAgreementDisclosure323;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure49 {
  optionDbValue: ENotePurchaseAgreementDisclosure49;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure41 {
  optionDbValue: ENotePurchaseAgreementDisclosure41;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure42 {
  optionDbValue: ENotePurchaseAgreementDisclosure42;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure43 {
  optionDbValue: ENotePurchaseAgreementDisclosure43;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure44 {
  optionDbValue: ENotePurchaseAgreementDisclosure44;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure45 {
  optionDbValue: ENotePurchaseAgreementDisclosure45;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure46 {
  optionDbValue: ENotePurchaseAgreementDisclosure46;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure47 {
  optionDbValue: ENotePurchaseAgreementDisclosure47;
  other: string;
}

export interface MNotePurchaseAgreementDisclosure48 {
  optionDbValue: ENotePurchaseAgreementDisclosure48;
  other: string;
}

export interface MNotePurchaseAgreementPaymentSchedule {
  optionDbValue: ENotePurchaseAgreementPaymentSchedule;
  other: string;
}

export interface NotePurchaseAgreement {
  companyFull: string;
  companyState: string;
  companyEntityType: MNotePurchaseAgreementCompanyEntityType | undefined;
  effectiveDate: Date | undefined;
  lenderFull: string;
  principalAmount: number;
  contractThreshold: number;
  financialStatementsDate: Date | undefined;
  governLawState: string;
  jurisdictions: MNotePurchaseAgreementJurisdictions | undefined;
  capTableUpload: PFile | undefined;
  complianceSigner: string;
  tableAdvances: PTable | undefined;
  baseInterestRate: number;
  maturityDate: Date | undefined;
  defaultInterestRate: number;
  venue: string;
  companyAddress: string;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyPerson: string;
  companyNoticeCopyEmail: string;
  lenderAddress: string;
  holderNoticeEmail: string;
  holderNoticePerson: string;
  companyNoticeCopy: InternalBoolean;
  disclosure31: MNotePurchaseAgreementDisclosure31 | undefined;
  disclosure32: MNotePurchaseAgreementDisclosure32 | undefined;
  disclosure33: MNotePurchaseAgreementDisclosure33 | undefined;
  disclosure34: MNotePurchaseAgreementDisclosure34 | undefined;
  disclosure35: MNotePurchaseAgreementDisclosure35 | undefined;
  disclosure36: MNotePurchaseAgreementDisclosure36 | undefined;
  disclosure37: MNotePurchaseAgreementDisclosure37 | undefined;
  disclosure38: MNotePurchaseAgreementDisclosure38 | undefined;
  disclosure39: MNotePurchaseAgreementDisclosure39 | undefined;
  disclosure310: MNotePurchaseAgreementDisclosure310 | undefined;
  disclosure311: MNotePurchaseAgreementDisclosure311 | undefined;
  disclosure312: MNotePurchaseAgreementDisclosure312 | undefined;
  disclosure313: MNotePurchaseAgreementDisclosure313 | undefined;
  disclosure314: MNotePurchaseAgreementDisclosure314 | undefined;
  disclosure315: MNotePurchaseAgreementDisclosure315 | undefined;
  disclosure316: MNotePurchaseAgreementDisclosure316 | undefined;
  disclosure317: MNotePurchaseAgreementDisclosure317 | undefined;
  disclosure318: MNotePurchaseAgreementDisclosure318 | undefined;
  disclosure319: MNotePurchaseAgreementDisclosure319 | undefined;
  disclosure320: MNotePurchaseAgreementDisclosure320 | undefined;
  disclosure321: MNotePurchaseAgreementDisclosure321 | undefined;
  disclosure322: MNotePurchaseAgreementDisclosure322 | undefined;
  disclosure323: MNotePurchaseAgreementDisclosure323 | undefined;
  disclosure49: MNotePurchaseAgreementDisclosure49 | undefined;
  disclosure41: MNotePurchaseAgreementDisclosure41 | undefined;
  disclosure42: MNotePurchaseAgreementDisclosure42 | undefined;
  disclosure43: MNotePurchaseAgreementDisclosure43 | undefined;
  disclosure44: MNotePurchaseAgreementDisclosure44 | undefined;
  disclosure45: MNotePurchaseAgreementDisclosure45 | undefined;
  disclosure46: MNotePurchaseAgreementDisclosure46 | undefined;
  disclosure47: MNotePurchaseAgreementDisclosure47 | undefined;
  disclosure48: MNotePurchaseAgreementDisclosure48 | undefined;
  pastAdvances: InternalBoolean;
  deferMonths: number;
  amortizedMonths: number;
  paymentSchedule: MNotePurchaseAgreementPaymentSchedule | undefined;
  deferPayment: InternalBoolean;
}

function createBaseMNotePurchaseAgreementCompanyEntityType(): MNotePurchaseAgreementCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementCompanyEntityType = {
  encode(message: MNotePurchaseAgreementCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementCompanyEntityType>, I>>(
    base?: I,
  ): MNotePurchaseAgreementCompanyEntityType {
    return MNotePurchaseAgreementCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementCompanyEntityType>, I>>(
    object: I,
  ): MNotePurchaseAgreementCompanyEntityType {
    const message = createBaseMNotePurchaseAgreementCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementJurisdictions(): MNotePurchaseAgreementJurisdictions {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementJurisdictions = {
  encode(message: MNotePurchaseAgreementJurisdictions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementJurisdictions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementJurisdictions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementJurisdictions {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementJurisdictionsFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementJurisdictions): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementJurisdictionsToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementJurisdictions>, I>>(
    base?: I,
  ): MNotePurchaseAgreementJurisdictions {
    return MNotePurchaseAgreementJurisdictions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementJurisdictions>, I>>(
    object: I,
  ): MNotePurchaseAgreementJurisdictions {
    const message = createBaseMNotePurchaseAgreementJurisdictions();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure31(): MNotePurchaseAgreementDisclosure31 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure31 = {
  encode(message: MNotePurchaseAgreementDisclosure31, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure31 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure31();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure31 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure31FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure31): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure31ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure31>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure31 {
    return MNotePurchaseAgreementDisclosure31.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure31>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure31 {
    const message = createBaseMNotePurchaseAgreementDisclosure31();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure32(): MNotePurchaseAgreementDisclosure32 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure32 = {
  encode(message: MNotePurchaseAgreementDisclosure32, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure32 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure32();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure32 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure32FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure32): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure32ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure32>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure32 {
    return MNotePurchaseAgreementDisclosure32.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure32>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure32 {
    const message = createBaseMNotePurchaseAgreementDisclosure32();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure33(): MNotePurchaseAgreementDisclosure33 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure33 = {
  encode(message: MNotePurchaseAgreementDisclosure33, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure33 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure33();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure33 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure33FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure33): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure33ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure33>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure33 {
    return MNotePurchaseAgreementDisclosure33.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure33>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure33 {
    const message = createBaseMNotePurchaseAgreementDisclosure33();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure34(): MNotePurchaseAgreementDisclosure34 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure34 = {
  encode(message: MNotePurchaseAgreementDisclosure34, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure34 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure34();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure34 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure34FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure34): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure34ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure34>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure34 {
    return MNotePurchaseAgreementDisclosure34.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure34>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure34 {
    const message = createBaseMNotePurchaseAgreementDisclosure34();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure35(): MNotePurchaseAgreementDisclosure35 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure35 = {
  encode(message: MNotePurchaseAgreementDisclosure35, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure35 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure35();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure35 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure35FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure35): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure35ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure35>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure35 {
    return MNotePurchaseAgreementDisclosure35.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure35>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure35 {
    const message = createBaseMNotePurchaseAgreementDisclosure35();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure36(): MNotePurchaseAgreementDisclosure36 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure36 = {
  encode(message: MNotePurchaseAgreementDisclosure36, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure36 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure36();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure36 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure36FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure36): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure36ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure36>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure36 {
    return MNotePurchaseAgreementDisclosure36.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure36>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure36 {
    const message = createBaseMNotePurchaseAgreementDisclosure36();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure37(): MNotePurchaseAgreementDisclosure37 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure37 = {
  encode(message: MNotePurchaseAgreementDisclosure37, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure37 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure37();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure37 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure37FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure37): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure37ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure37>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure37 {
    return MNotePurchaseAgreementDisclosure37.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure37>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure37 {
    const message = createBaseMNotePurchaseAgreementDisclosure37();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure38(): MNotePurchaseAgreementDisclosure38 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure38 = {
  encode(message: MNotePurchaseAgreementDisclosure38, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure38 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure38();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure38 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure38FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure38): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure38ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure38>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure38 {
    return MNotePurchaseAgreementDisclosure38.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure38>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure38 {
    const message = createBaseMNotePurchaseAgreementDisclosure38();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure39(): MNotePurchaseAgreementDisclosure39 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure39 = {
  encode(message: MNotePurchaseAgreementDisclosure39, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure39 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure39();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure39 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure39FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure39): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure39ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure39>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure39 {
    return MNotePurchaseAgreementDisclosure39.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure39>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure39 {
    const message = createBaseMNotePurchaseAgreementDisclosure39();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure310(): MNotePurchaseAgreementDisclosure310 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure310 = {
  encode(message: MNotePurchaseAgreementDisclosure310, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure310 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure310();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure310 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure310FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure310): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure310ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure310>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure310 {
    return MNotePurchaseAgreementDisclosure310.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure310>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure310 {
    const message = createBaseMNotePurchaseAgreementDisclosure310();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure311(): MNotePurchaseAgreementDisclosure311 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure311 = {
  encode(message: MNotePurchaseAgreementDisclosure311, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure311 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure311();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure311 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure311FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure311): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure311ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure311>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure311 {
    return MNotePurchaseAgreementDisclosure311.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure311>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure311 {
    const message = createBaseMNotePurchaseAgreementDisclosure311();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure312(): MNotePurchaseAgreementDisclosure312 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure312 = {
  encode(message: MNotePurchaseAgreementDisclosure312, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure312 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure312();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure312 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure312FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure312): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure312ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure312>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure312 {
    return MNotePurchaseAgreementDisclosure312.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure312>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure312 {
    const message = createBaseMNotePurchaseAgreementDisclosure312();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure313(): MNotePurchaseAgreementDisclosure313 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure313 = {
  encode(message: MNotePurchaseAgreementDisclosure313, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure313 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure313();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure313 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure313FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure313): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure313ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure313>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure313 {
    return MNotePurchaseAgreementDisclosure313.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure313>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure313 {
    const message = createBaseMNotePurchaseAgreementDisclosure313();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure314(): MNotePurchaseAgreementDisclosure314 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure314 = {
  encode(message: MNotePurchaseAgreementDisclosure314, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure314 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure314();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure314 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure314FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure314): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure314ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure314>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure314 {
    return MNotePurchaseAgreementDisclosure314.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure314>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure314 {
    const message = createBaseMNotePurchaseAgreementDisclosure314();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure315(): MNotePurchaseAgreementDisclosure315 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure315 = {
  encode(message: MNotePurchaseAgreementDisclosure315, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure315 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure315();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure315 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure315FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure315): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure315ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure315>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure315 {
    return MNotePurchaseAgreementDisclosure315.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure315>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure315 {
    const message = createBaseMNotePurchaseAgreementDisclosure315();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure316(): MNotePurchaseAgreementDisclosure316 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure316 = {
  encode(message: MNotePurchaseAgreementDisclosure316, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure316 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure316();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure316 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure316FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure316): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure316ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure316>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure316 {
    return MNotePurchaseAgreementDisclosure316.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure316>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure316 {
    const message = createBaseMNotePurchaseAgreementDisclosure316();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure317(): MNotePurchaseAgreementDisclosure317 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure317 = {
  encode(message: MNotePurchaseAgreementDisclosure317, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure317 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure317();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure317 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure317FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure317): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure317ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure317>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure317 {
    return MNotePurchaseAgreementDisclosure317.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure317>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure317 {
    const message = createBaseMNotePurchaseAgreementDisclosure317();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure318(): MNotePurchaseAgreementDisclosure318 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure318 = {
  encode(message: MNotePurchaseAgreementDisclosure318, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure318 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure318();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure318 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure318FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure318): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure318ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure318>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure318 {
    return MNotePurchaseAgreementDisclosure318.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure318>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure318 {
    const message = createBaseMNotePurchaseAgreementDisclosure318();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure319(): MNotePurchaseAgreementDisclosure319 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure319 = {
  encode(message: MNotePurchaseAgreementDisclosure319, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure319 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure319();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure319 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure319FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure319): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure319ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure319>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure319 {
    return MNotePurchaseAgreementDisclosure319.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure319>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure319 {
    const message = createBaseMNotePurchaseAgreementDisclosure319();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure320(): MNotePurchaseAgreementDisclosure320 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure320 = {
  encode(message: MNotePurchaseAgreementDisclosure320, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure320 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure320();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure320 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure320FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure320): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure320ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure320>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure320 {
    return MNotePurchaseAgreementDisclosure320.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure320>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure320 {
    const message = createBaseMNotePurchaseAgreementDisclosure320();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure321(): MNotePurchaseAgreementDisclosure321 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure321 = {
  encode(message: MNotePurchaseAgreementDisclosure321, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure321 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure321();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure321 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure321FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure321): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure321ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure321>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure321 {
    return MNotePurchaseAgreementDisclosure321.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure321>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure321 {
    const message = createBaseMNotePurchaseAgreementDisclosure321();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure322(): MNotePurchaseAgreementDisclosure322 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure322 = {
  encode(message: MNotePurchaseAgreementDisclosure322, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure322 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure322();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure322 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure322FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure322): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure322ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure322>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure322 {
    return MNotePurchaseAgreementDisclosure322.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure322>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure322 {
    const message = createBaseMNotePurchaseAgreementDisclosure322();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure323(): MNotePurchaseAgreementDisclosure323 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure323 = {
  encode(message: MNotePurchaseAgreementDisclosure323, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure323 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure323();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure323 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementDisclosure323FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure323): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure323ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure323>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure323 {
    return MNotePurchaseAgreementDisclosure323.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure323>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure323 {
    const message = createBaseMNotePurchaseAgreementDisclosure323();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure49(): MNotePurchaseAgreementDisclosure49 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure49 = {
  encode(message: MNotePurchaseAgreementDisclosure49, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure49 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure49();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure49 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure49FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure49): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure49ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure49>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure49 {
    return MNotePurchaseAgreementDisclosure49.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure49>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure49 {
    const message = createBaseMNotePurchaseAgreementDisclosure49();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure41(): MNotePurchaseAgreementDisclosure41 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure41 = {
  encode(message: MNotePurchaseAgreementDisclosure41, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure41 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure41();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure41 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure41FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure41): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure41ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure41>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure41 {
    return MNotePurchaseAgreementDisclosure41.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure41>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure41 {
    const message = createBaseMNotePurchaseAgreementDisclosure41();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure42(): MNotePurchaseAgreementDisclosure42 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure42 = {
  encode(message: MNotePurchaseAgreementDisclosure42, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure42 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure42();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure42 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure42FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure42): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure42ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure42>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure42 {
    return MNotePurchaseAgreementDisclosure42.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure42>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure42 {
    const message = createBaseMNotePurchaseAgreementDisclosure42();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure43(): MNotePurchaseAgreementDisclosure43 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure43 = {
  encode(message: MNotePurchaseAgreementDisclosure43, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure43 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure43();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure43 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure43FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure43): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure43ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure43>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure43 {
    return MNotePurchaseAgreementDisclosure43.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure43>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure43 {
    const message = createBaseMNotePurchaseAgreementDisclosure43();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure44(): MNotePurchaseAgreementDisclosure44 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure44 = {
  encode(message: MNotePurchaseAgreementDisclosure44, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure44 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure44();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure44 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure44FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure44): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure44ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure44>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure44 {
    return MNotePurchaseAgreementDisclosure44.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure44>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure44 {
    const message = createBaseMNotePurchaseAgreementDisclosure44();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure45(): MNotePurchaseAgreementDisclosure45 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure45 = {
  encode(message: MNotePurchaseAgreementDisclosure45, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure45 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure45();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure45 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure45FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure45): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure45ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure45>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure45 {
    return MNotePurchaseAgreementDisclosure45.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure45>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure45 {
    const message = createBaseMNotePurchaseAgreementDisclosure45();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure46(): MNotePurchaseAgreementDisclosure46 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure46 = {
  encode(message: MNotePurchaseAgreementDisclosure46, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure46 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure46();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure46 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure46FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure46): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure46ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure46>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure46 {
    return MNotePurchaseAgreementDisclosure46.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure46>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure46 {
    const message = createBaseMNotePurchaseAgreementDisclosure46();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure47(): MNotePurchaseAgreementDisclosure47 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure47 = {
  encode(message: MNotePurchaseAgreementDisclosure47, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure47 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure47();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure47 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure47FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure47): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure47ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure47>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure47 {
    return MNotePurchaseAgreementDisclosure47.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure47>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure47 {
    const message = createBaseMNotePurchaseAgreementDisclosure47();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementDisclosure48(): MNotePurchaseAgreementDisclosure48 {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementDisclosure48 = {
  encode(message: MNotePurchaseAgreementDisclosure48, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementDisclosure48 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementDisclosure48();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementDisclosure48 {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eNotePurchaseAgreementDisclosure48FromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementDisclosure48): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementDisclosure48ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure48>, I>>(
    base?: I,
  ): MNotePurchaseAgreementDisclosure48 {
    return MNotePurchaseAgreementDisclosure48.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementDisclosure48>, I>>(
    object: I,
  ): MNotePurchaseAgreementDisclosure48 {
    const message = createBaseMNotePurchaseAgreementDisclosure48();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMNotePurchaseAgreementPaymentSchedule(): MNotePurchaseAgreementPaymentSchedule {
  return { optionDbValue: 0, other: "" };
}

export const MNotePurchaseAgreementPaymentSchedule = {
  encode(message: MNotePurchaseAgreementPaymentSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MNotePurchaseAgreementPaymentSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMNotePurchaseAgreementPaymentSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MNotePurchaseAgreementPaymentSchedule {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eNotePurchaseAgreementPaymentScheduleFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MNotePurchaseAgreementPaymentSchedule): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eNotePurchaseAgreementPaymentScheduleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MNotePurchaseAgreementPaymentSchedule>, I>>(
    base?: I,
  ): MNotePurchaseAgreementPaymentSchedule {
    return MNotePurchaseAgreementPaymentSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MNotePurchaseAgreementPaymentSchedule>, I>>(
    object: I,
  ): MNotePurchaseAgreementPaymentSchedule {
    const message = createBaseMNotePurchaseAgreementPaymentSchedule();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseNotePurchaseAgreement(): NotePurchaseAgreement {
  return {
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    effectiveDate: undefined,
    lenderFull: "",
    principalAmount: 0,
    contractThreshold: 0,
    financialStatementsDate: undefined,
    governLawState: "",
    jurisdictions: undefined,
    capTableUpload: undefined,
    complianceSigner: "",
    tableAdvances: undefined,
    baseInterestRate: 0,
    maturityDate: undefined,
    defaultInterestRate: 0,
    venue: "",
    companyAddress: "",
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyPerson: "",
    companyNoticeCopyEmail: "",
    lenderAddress: "",
    holderNoticeEmail: "",
    holderNoticePerson: "",
    companyNoticeCopy: 0,
    disclosure31: undefined,
    disclosure32: undefined,
    disclosure33: undefined,
    disclosure34: undefined,
    disclosure35: undefined,
    disclosure36: undefined,
    disclosure37: undefined,
    disclosure38: undefined,
    disclosure39: undefined,
    disclosure310: undefined,
    disclosure311: undefined,
    disclosure312: undefined,
    disclosure313: undefined,
    disclosure314: undefined,
    disclosure315: undefined,
    disclosure316: undefined,
    disclosure317: undefined,
    disclosure318: undefined,
    disclosure319: undefined,
    disclosure320: undefined,
    disclosure321: undefined,
    disclosure322: undefined,
    disclosure323: undefined,
    disclosure49: undefined,
    disclosure41: undefined,
    disclosure42: undefined,
    disclosure43: undefined,
    disclosure44: undefined,
    disclosure45: undefined,
    disclosure46: undefined,
    disclosure47: undefined,
    disclosure48: undefined,
    pastAdvances: 0,
    deferMonths: 0,
    amortizedMonths: 0,
    paymentSchedule: undefined,
    deferPayment: 0,
  };
}

export const NotePurchaseAgreement = {
  encode(message: NotePurchaseAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MNotePurchaseAgreementCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.lenderFull !== "") {
      writer.uint32(42).string(message.lenderFull);
    }
    if (message.principalAmount !== 0) {
      writer.uint32(53).float(message.principalAmount);
    }
    if (message.contractThreshold !== 0) {
      writer.uint32(61).float(message.contractThreshold);
    }
    if (message.financialStatementsDate !== undefined) {
      Timestamp.encode(toTimestamp(message.financialStatementsDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(74).string(message.governLawState);
    }
    if (message.jurisdictions !== undefined) {
      MNotePurchaseAgreementJurisdictions.encode(message.jurisdictions, writer.uint32(82).fork()).ldelim();
    }
    if (message.capTableUpload !== undefined) {
      PFile.encode(message.capTableUpload, writer.uint32(90).fork()).ldelim();
    }
    if (message.complianceSigner !== "") {
      writer.uint32(98).string(message.complianceSigner);
    }
    if (message.tableAdvances !== undefined) {
      PTable.encode(message.tableAdvances, writer.uint32(106).fork()).ldelim();
    }
    if (message.baseInterestRate !== 0) {
      writer.uint32(117).float(message.baseInterestRate);
    }
    if (message.maturityDate !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityDate), writer.uint32(122).fork()).ldelim();
    }
    if (message.defaultInterestRate !== 0) {
      writer.uint32(133).float(message.defaultInterestRate);
    }
    if (message.venue !== "") {
      writer.uint32(138).string(message.venue);
    }
    if (message.companyAddress !== "") {
      writer.uint32(146).string(message.companyAddress);
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(154).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(162).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(170).string(message.companyNoticeCopyPerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(178).string(message.companyNoticeCopyEmail);
    }
    if (message.lenderAddress !== "") {
      writer.uint32(186).string(message.lenderAddress);
    }
    if (message.holderNoticeEmail !== "") {
      writer.uint32(194).string(message.holderNoticeEmail);
    }
    if (message.holderNoticePerson !== "") {
      writer.uint32(202).string(message.holderNoticePerson);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(208).int32(message.companyNoticeCopy);
    }
    if (message.disclosure31 !== undefined) {
      MNotePurchaseAgreementDisclosure31.encode(message.disclosure31, writer.uint32(218).fork()).ldelim();
    }
    if (message.disclosure32 !== undefined) {
      MNotePurchaseAgreementDisclosure32.encode(message.disclosure32, writer.uint32(226).fork()).ldelim();
    }
    if (message.disclosure33 !== undefined) {
      MNotePurchaseAgreementDisclosure33.encode(message.disclosure33, writer.uint32(234).fork()).ldelim();
    }
    if (message.disclosure34 !== undefined) {
      MNotePurchaseAgreementDisclosure34.encode(message.disclosure34, writer.uint32(242).fork()).ldelim();
    }
    if (message.disclosure35 !== undefined) {
      MNotePurchaseAgreementDisclosure35.encode(message.disclosure35, writer.uint32(250).fork()).ldelim();
    }
    if (message.disclosure36 !== undefined) {
      MNotePurchaseAgreementDisclosure36.encode(message.disclosure36, writer.uint32(258).fork()).ldelim();
    }
    if (message.disclosure37 !== undefined) {
      MNotePurchaseAgreementDisclosure37.encode(message.disclosure37, writer.uint32(266).fork()).ldelim();
    }
    if (message.disclosure38 !== undefined) {
      MNotePurchaseAgreementDisclosure38.encode(message.disclosure38, writer.uint32(274).fork()).ldelim();
    }
    if (message.disclosure39 !== undefined) {
      MNotePurchaseAgreementDisclosure39.encode(message.disclosure39, writer.uint32(282).fork()).ldelim();
    }
    if (message.disclosure310 !== undefined) {
      MNotePurchaseAgreementDisclosure310.encode(message.disclosure310, writer.uint32(290).fork()).ldelim();
    }
    if (message.disclosure311 !== undefined) {
      MNotePurchaseAgreementDisclosure311.encode(message.disclosure311, writer.uint32(298).fork()).ldelim();
    }
    if (message.disclosure312 !== undefined) {
      MNotePurchaseAgreementDisclosure312.encode(message.disclosure312, writer.uint32(306).fork()).ldelim();
    }
    if (message.disclosure313 !== undefined) {
      MNotePurchaseAgreementDisclosure313.encode(message.disclosure313, writer.uint32(314).fork()).ldelim();
    }
    if (message.disclosure314 !== undefined) {
      MNotePurchaseAgreementDisclosure314.encode(message.disclosure314, writer.uint32(322).fork()).ldelim();
    }
    if (message.disclosure315 !== undefined) {
      MNotePurchaseAgreementDisclosure315.encode(message.disclosure315, writer.uint32(330).fork()).ldelim();
    }
    if (message.disclosure316 !== undefined) {
      MNotePurchaseAgreementDisclosure316.encode(message.disclosure316, writer.uint32(338).fork()).ldelim();
    }
    if (message.disclosure317 !== undefined) {
      MNotePurchaseAgreementDisclosure317.encode(message.disclosure317, writer.uint32(346).fork()).ldelim();
    }
    if (message.disclosure318 !== undefined) {
      MNotePurchaseAgreementDisclosure318.encode(message.disclosure318, writer.uint32(354).fork()).ldelim();
    }
    if (message.disclosure319 !== undefined) {
      MNotePurchaseAgreementDisclosure319.encode(message.disclosure319, writer.uint32(362).fork()).ldelim();
    }
    if (message.disclosure320 !== undefined) {
      MNotePurchaseAgreementDisclosure320.encode(message.disclosure320, writer.uint32(370).fork()).ldelim();
    }
    if (message.disclosure321 !== undefined) {
      MNotePurchaseAgreementDisclosure321.encode(message.disclosure321, writer.uint32(378).fork()).ldelim();
    }
    if (message.disclosure322 !== undefined) {
      MNotePurchaseAgreementDisclosure322.encode(message.disclosure322, writer.uint32(386).fork()).ldelim();
    }
    if (message.disclosure323 !== undefined) {
      MNotePurchaseAgreementDisclosure323.encode(message.disclosure323, writer.uint32(394).fork()).ldelim();
    }
    if (message.disclosure49 !== undefined) {
      MNotePurchaseAgreementDisclosure49.encode(message.disclosure49, writer.uint32(402).fork()).ldelim();
    }
    if (message.disclosure41 !== undefined) {
      MNotePurchaseAgreementDisclosure41.encode(message.disclosure41, writer.uint32(410).fork()).ldelim();
    }
    if (message.disclosure42 !== undefined) {
      MNotePurchaseAgreementDisclosure42.encode(message.disclosure42, writer.uint32(418).fork()).ldelim();
    }
    if (message.disclosure43 !== undefined) {
      MNotePurchaseAgreementDisclosure43.encode(message.disclosure43, writer.uint32(426).fork()).ldelim();
    }
    if (message.disclosure44 !== undefined) {
      MNotePurchaseAgreementDisclosure44.encode(message.disclosure44, writer.uint32(434).fork()).ldelim();
    }
    if (message.disclosure45 !== undefined) {
      MNotePurchaseAgreementDisclosure45.encode(message.disclosure45, writer.uint32(442).fork()).ldelim();
    }
    if (message.disclosure46 !== undefined) {
      MNotePurchaseAgreementDisclosure46.encode(message.disclosure46, writer.uint32(450).fork()).ldelim();
    }
    if (message.disclosure47 !== undefined) {
      MNotePurchaseAgreementDisclosure47.encode(message.disclosure47, writer.uint32(458).fork()).ldelim();
    }
    if (message.disclosure48 !== undefined) {
      MNotePurchaseAgreementDisclosure48.encode(message.disclosure48, writer.uint32(466).fork()).ldelim();
    }
    if (message.pastAdvances !== 0) {
      writer.uint32(472).int32(message.pastAdvances);
    }
    if (message.deferMonths !== 0) {
      writer.uint32(485).float(message.deferMonths);
    }
    if (message.amortizedMonths !== 0) {
      writer.uint32(493).float(message.amortizedMonths);
    }
    if (message.paymentSchedule !== undefined) {
      MNotePurchaseAgreementPaymentSchedule.encode(message.paymentSchedule, writer.uint32(498).fork()).ldelim();
    }
    if (message.deferPayment !== 0) {
      writer.uint32(504).int32(message.deferPayment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotePurchaseAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotePurchaseAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MNotePurchaseAgreementCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.lenderFull = reader.string();
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.principalAmount = reader.float();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.contractThreshold = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.financialStatementsDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.jurisdictions = MNotePurchaseAgreementJurisdictions.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.capTableUpload = PFile.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.complianceSigner = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.tableAdvances = PTable.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 117) {
            break;
          }

          message.baseInterestRate = reader.float();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.maturityDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.defaultInterestRate = reader.float();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.lenderAddress = reader.string();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.holderNoticeEmail = reader.string();
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.holderNoticePerson = reader.string();
          continue;
        case 26:
          if (tag != 208) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.disclosure31 = MNotePurchaseAgreementDisclosure31.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.disclosure32 = MNotePurchaseAgreementDisclosure32.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.disclosure33 = MNotePurchaseAgreementDisclosure33.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.disclosure34 = MNotePurchaseAgreementDisclosure34.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.disclosure35 = MNotePurchaseAgreementDisclosure35.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.disclosure36 = MNotePurchaseAgreementDisclosure36.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.disclosure37 = MNotePurchaseAgreementDisclosure37.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.disclosure38 = MNotePurchaseAgreementDisclosure38.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.disclosure39 = MNotePurchaseAgreementDisclosure39.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.disclosure310 = MNotePurchaseAgreementDisclosure310.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.disclosure311 = MNotePurchaseAgreementDisclosure311.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.disclosure312 = MNotePurchaseAgreementDisclosure312.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.disclosure313 = MNotePurchaseAgreementDisclosure313.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.disclosure314 = MNotePurchaseAgreementDisclosure314.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.disclosure315 = MNotePurchaseAgreementDisclosure315.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.disclosure316 = MNotePurchaseAgreementDisclosure316.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag != 346) {
            break;
          }

          message.disclosure317 = MNotePurchaseAgreementDisclosure317.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag != 354) {
            break;
          }

          message.disclosure318 = MNotePurchaseAgreementDisclosure318.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag != 362) {
            break;
          }

          message.disclosure319 = MNotePurchaseAgreementDisclosure319.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag != 370) {
            break;
          }

          message.disclosure320 = MNotePurchaseAgreementDisclosure320.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag != 378) {
            break;
          }

          message.disclosure321 = MNotePurchaseAgreementDisclosure321.decode(reader, reader.uint32());
          continue;
        case 48:
          if (tag != 386) {
            break;
          }

          message.disclosure322 = MNotePurchaseAgreementDisclosure322.decode(reader, reader.uint32());
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.disclosure323 = MNotePurchaseAgreementDisclosure323.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag != 402) {
            break;
          }

          message.disclosure49 = MNotePurchaseAgreementDisclosure49.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag != 410) {
            break;
          }

          message.disclosure41 = MNotePurchaseAgreementDisclosure41.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag != 418) {
            break;
          }

          message.disclosure42 = MNotePurchaseAgreementDisclosure42.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag != 426) {
            break;
          }

          message.disclosure43 = MNotePurchaseAgreementDisclosure43.decode(reader, reader.uint32());
          continue;
        case 54:
          if (tag != 434) {
            break;
          }

          message.disclosure44 = MNotePurchaseAgreementDisclosure44.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag != 442) {
            break;
          }

          message.disclosure45 = MNotePurchaseAgreementDisclosure45.decode(reader, reader.uint32());
          continue;
        case 56:
          if (tag != 450) {
            break;
          }

          message.disclosure46 = MNotePurchaseAgreementDisclosure46.decode(reader, reader.uint32());
          continue;
        case 57:
          if (tag != 458) {
            break;
          }

          message.disclosure47 = MNotePurchaseAgreementDisclosure47.decode(reader, reader.uint32());
          continue;
        case 58:
          if (tag != 466) {
            break;
          }

          message.disclosure48 = MNotePurchaseAgreementDisclosure48.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag != 472) {
            break;
          }

          message.pastAdvances = reader.int32() as any;
          continue;
        case 60:
          if (tag != 485) {
            break;
          }

          message.deferMonths = reader.float();
          continue;
        case 61:
          if (tag != 493) {
            break;
          }

          message.amortizedMonths = reader.float();
          continue;
        case 62:
          if (tag != 498) {
            break;
          }

          message.paymentSchedule = MNotePurchaseAgreementPaymentSchedule.decode(reader, reader.uint32());
          continue;
        case 63:
          if (tag != 504) {
            break;
          }

          message.deferPayment = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotePurchaseAgreement {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MNotePurchaseAgreementCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      lenderFull: isSet(object.lenderFull) ? String(object.lenderFull) : "",
      principalAmount: isSet(object.principalAmount) ? Number(object.principalAmount) : 0,
      contractThreshold: isSet(object.contractThreshold) ? Number(object.contractThreshold) : 0,
      financialStatementsDate: isSet(object.financialStatementsDate)
        ? fromJsonTimestamp(object.financialStatementsDate)
        : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      jurisdictions: isSet(object.jurisdictions)
        ? MNotePurchaseAgreementJurisdictions.fromJSON(object.jurisdictions)
        : undefined,
      capTableUpload: isSet(object.capTableUpload) ? PFile.fromJSON(object.capTableUpload) : undefined,
      complianceSigner: isSet(object.complianceSigner) ? String(object.complianceSigner) : "",
      tableAdvances: isSet(object.tableAdvances) ? PTable.fromJSON(object.tableAdvances) : undefined,
      baseInterestRate: isSet(object.baseInterestRate) ? Number(object.baseInterestRate) : 0,
      maturityDate: isSet(object.maturityDate) ? fromJsonTimestamp(object.maturityDate) : undefined,
      defaultInterestRate: isSet(object.defaultInterestRate) ? Number(object.defaultInterestRate) : 0,
      venue: isSet(object.venue) ? String(object.venue) : "",
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      lenderAddress: isSet(object.lenderAddress) ? String(object.lenderAddress) : "",
      holderNoticeEmail: isSet(object.holderNoticeEmail) ? String(object.holderNoticeEmail) : "",
      holderNoticePerson: isSet(object.holderNoticePerson) ? String(object.holderNoticePerson) : "",
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      disclosure31: isSet(object.disclosure31)
        ? MNotePurchaseAgreementDisclosure31.fromJSON(object.disclosure31)
        : undefined,
      disclosure32: isSet(object.disclosure32)
        ? MNotePurchaseAgreementDisclosure32.fromJSON(object.disclosure32)
        : undefined,
      disclosure33: isSet(object.disclosure33)
        ? MNotePurchaseAgreementDisclosure33.fromJSON(object.disclosure33)
        : undefined,
      disclosure34: isSet(object.disclosure34)
        ? MNotePurchaseAgreementDisclosure34.fromJSON(object.disclosure34)
        : undefined,
      disclosure35: isSet(object.disclosure35)
        ? MNotePurchaseAgreementDisclosure35.fromJSON(object.disclosure35)
        : undefined,
      disclosure36: isSet(object.disclosure36)
        ? MNotePurchaseAgreementDisclosure36.fromJSON(object.disclosure36)
        : undefined,
      disclosure37: isSet(object.disclosure37)
        ? MNotePurchaseAgreementDisclosure37.fromJSON(object.disclosure37)
        : undefined,
      disclosure38: isSet(object.disclosure38)
        ? MNotePurchaseAgreementDisclosure38.fromJSON(object.disclosure38)
        : undefined,
      disclosure39: isSet(object.disclosure39)
        ? MNotePurchaseAgreementDisclosure39.fromJSON(object.disclosure39)
        : undefined,
      disclosure310: isSet(object.disclosure310)
        ? MNotePurchaseAgreementDisclosure310.fromJSON(object.disclosure310)
        : undefined,
      disclosure311: isSet(object.disclosure311)
        ? MNotePurchaseAgreementDisclosure311.fromJSON(object.disclosure311)
        : undefined,
      disclosure312: isSet(object.disclosure312)
        ? MNotePurchaseAgreementDisclosure312.fromJSON(object.disclosure312)
        : undefined,
      disclosure313: isSet(object.disclosure313)
        ? MNotePurchaseAgreementDisclosure313.fromJSON(object.disclosure313)
        : undefined,
      disclosure314: isSet(object.disclosure314)
        ? MNotePurchaseAgreementDisclosure314.fromJSON(object.disclosure314)
        : undefined,
      disclosure315: isSet(object.disclosure315)
        ? MNotePurchaseAgreementDisclosure315.fromJSON(object.disclosure315)
        : undefined,
      disclosure316: isSet(object.disclosure316)
        ? MNotePurchaseAgreementDisclosure316.fromJSON(object.disclosure316)
        : undefined,
      disclosure317: isSet(object.disclosure317)
        ? MNotePurchaseAgreementDisclosure317.fromJSON(object.disclosure317)
        : undefined,
      disclosure318: isSet(object.disclosure318)
        ? MNotePurchaseAgreementDisclosure318.fromJSON(object.disclosure318)
        : undefined,
      disclosure319: isSet(object.disclosure319)
        ? MNotePurchaseAgreementDisclosure319.fromJSON(object.disclosure319)
        : undefined,
      disclosure320: isSet(object.disclosure320)
        ? MNotePurchaseAgreementDisclosure320.fromJSON(object.disclosure320)
        : undefined,
      disclosure321: isSet(object.disclosure321)
        ? MNotePurchaseAgreementDisclosure321.fromJSON(object.disclosure321)
        : undefined,
      disclosure322: isSet(object.disclosure322)
        ? MNotePurchaseAgreementDisclosure322.fromJSON(object.disclosure322)
        : undefined,
      disclosure323: isSet(object.disclosure323)
        ? MNotePurchaseAgreementDisclosure323.fromJSON(object.disclosure323)
        : undefined,
      disclosure49: isSet(object.disclosure49)
        ? MNotePurchaseAgreementDisclosure49.fromJSON(object.disclosure49)
        : undefined,
      disclosure41: isSet(object.disclosure41)
        ? MNotePurchaseAgreementDisclosure41.fromJSON(object.disclosure41)
        : undefined,
      disclosure42: isSet(object.disclosure42)
        ? MNotePurchaseAgreementDisclosure42.fromJSON(object.disclosure42)
        : undefined,
      disclosure43: isSet(object.disclosure43)
        ? MNotePurchaseAgreementDisclosure43.fromJSON(object.disclosure43)
        : undefined,
      disclosure44: isSet(object.disclosure44)
        ? MNotePurchaseAgreementDisclosure44.fromJSON(object.disclosure44)
        : undefined,
      disclosure45: isSet(object.disclosure45)
        ? MNotePurchaseAgreementDisclosure45.fromJSON(object.disclosure45)
        : undefined,
      disclosure46: isSet(object.disclosure46)
        ? MNotePurchaseAgreementDisclosure46.fromJSON(object.disclosure46)
        : undefined,
      disclosure47: isSet(object.disclosure47)
        ? MNotePurchaseAgreementDisclosure47.fromJSON(object.disclosure47)
        : undefined,
      disclosure48: isSet(object.disclosure48)
        ? MNotePurchaseAgreementDisclosure48.fromJSON(object.disclosure48)
        : undefined,
      pastAdvances: isSet(object.pastAdvances) ? internalBooleanFromJSON(object.pastAdvances) : 0,
      deferMonths: isSet(object.deferMonths) ? Number(object.deferMonths) : 0,
      amortizedMonths: isSet(object.amortizedMonths) ? Number(object.amortizedMonths) : 0,
      paymentSchedule: isSet(object.paymentSchedule)
        ? MNotePurchaseAgreementPaymentSchedule.fromJSON(object.paymentSchedule)
        : undefined,
      deferPayment: isSet(object.deferPayment) ? internalBooleanFromJSON(object.deferPayment) : 0,
    };
  },

  toJSON(message: NotePurchaseAgreement): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MNotePurchaseAgreementCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.lenderFull !== undefined && (obj.lenderFull = message.lenderFull);
    message.principalAmount !== undefined && (obj.principalAmount = message.principalAmount);
    message.contractThreshold !== undefined && (obj.contractThreshold = message.contractThreshold);
    message.financialStatementsDate !== undefined &&
      (obj.financialStatementsDate = message.financialStatementsDate.toISOString());
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.jurisdictions !== undefined && (obj.jurisdictions = message.jurisdictions
      ? MNotePurchaseAgreementJurisdictions.toJSON(message.jurisdictions)
      : undefined);
    message.capTableUpload !== undefined &&
      (obj.capTableUpload = message.capTableUpload ? PFile.toJSON(message.capTableUpload) : undefined);
    message.complianceSigner !== undefined && (obj.complianceSigner = message.complianceSigner);
    message.tableAdvances !== undefined &&
      (obj.tableAdvances = message.tableAdvances ? PTable.toJSON(message.tableAdvances) : undefined);
    message.baseInterestRate !== undefined && (obj.baseInterestRate = message.baseInterestRate);
    message.maturityDate !== undefined && (obj.maturityDate = message.maturityDate.toISOString());
    message.defaultInterestRate !== undefined && (obj.defaultInterestRate = message.defaultInterestRate);
    message.venue !== undefined && (obj.venue = message.venue);
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.lenderAddress !== undefined && (obj.lenderAddress = message.lenderAddress);
    message.holderNoticeEmail !== undefined && (obj.holderNoticeEmail = message.holderNoticeEmail);
    message.holderNoticePerson !== undefined && (obj.holderNoticePerson = message.holderNoticePerson);
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.disclosure31 !== undefined && (obj.disclosure31 = message.disclosure31
      ? MNotePurchaseAgreementDisclosure31.toJSON(message.disclosure31)
      : undefined);
    message.disclosure32 !== undefined && (obj.disclosure32 = message.disclosure32
      ? MNotePurchaseAgreementDisclosure32.toJSON(message.disclosure32)
      : undefined);
    message.disclosure33 !== undefined && (obj.disclosure33 = message.disclosure33
      ? MNotePurchaseAgreementDisclosure33.toJSON(message.disclosure33)
      : undefined);
    message.disclosure34 !== undefined && (obj.disclosure34 = message.disclosure34
      ? MNotePurchaseAgreementDisclosure34.toJSON(message.disclosure34)
      : undefined);
    message.disclosure35 !== undefined && (obj.disclosure35 = message.disclosure35
      ? MNotePurchaseAgreementDisclosure35.toJSON(message.disclosure35)
      : undefined);
    message.disclosure36 !== undefined && (obj.disclosure36 = message.disclosure36
      ? MNotePurchaseAgreementDisclosure36.toJSON(message.disclosure36)
      : undefined);
    message.disclosure37 !== undefined && (obj.disclosure37 = message.disclosure37
      ? MNotePurchaseAgreementDisclosure37.toJSON(message.disclosure37)
      : undefined);
    message.disclosure38 !== undefined && (obj.disclosure38 = message.disclosure38
      ? MNotePurchaseAgreementDisclosure38.toJSON(message.disclosure38)
      : undefined);
    message.disclosure39 !== undefined && (obj.disclosure39 = message.disclosure39
      ? MNotePurchaseAgreementDisclosure39.toJSON(message.disclosure39)
      : undefined);
    message.disclosure310 !== undefined && (obj.disclosure310 = message.disclosure310
      ? MNotePurchaseAgreementDisclosure310.toJSON(message.disclosure310)
      : undefined);
    message.disclosure311 !== undefined && (obj.disclosure311 = message.disclosure311
      ? MNotePurchaseAgreementDisclosure311.toJSON(message.disclosure311)
      : undefined);
    message.disclosure312 !== undefined && (obj.disclosure312 = message.disclosure312
      ? MNotePurchaseAgreementDisclosure312.toJSON(message.disclosure312)
      : undefined);
    message.disclosure313 !== undefined && (obj.disclosure313 = message.disclosure313
      ? MNotePurchaseAgreementDisclosure313.toJSON(message.disclosure313)
      : undefined);
    message.disclosure314 !== undefined && (obj.disclosure314 = message.disclosure314
      ? MNotePurchaseAgreementDisclosure314.toJSON(message.disclosure314)
      : undefined);
    message.disclosure315 !== undefined && (obj.disclosure315 = message.disclosure315
      ? MNotePurchaseAgreementDisclosure315.toJSON(message.disclosure315)
      : undefined);
    message.disclosure316 !== undefined && (obj.disclosure316 = message.disclosure316
      ? MNotePurchaseAgreementDisclosure316.toJSON(message.disclosure316)
      : undefined);
    message.disclosure317 !== undefined && (obj.disclosure317 = message.disclosure317
      ? MNotePurchaseAgreementDisclosure317.toJSON(message.disclosure317)
      : undefined);
    message.disclosure318 !== undefined && (obj.disclosure318 = message.disclosure318
      ? MNotePurchaseAgreementDisclosure318.toJSON(message.disclosure318)
      : undefined);
    message.disclosure319 !== undefined && (obj.disclosure319 = message.disclosure319
      ? MNotePurchaseAgreementDisclosure319.toJSON(message.disclosure319)
      : undefined);
    message.disclosure320 !== undefined && (obj.disclosure320 = message.disclosure320
      ? MNotePurchaseAgreementDisclosure320.toJSON(message.disclosure320)
      : undefined);
    message.disclosure321 !== undefined && (obj.disclosure321 = message.disclosure321
      ? MNotePurchaseAgreementDisclosure321.toJSON(message.disclosure321)
      : undefined);
    message.disclosure322 !== undefined && (obj.disclosure322 = message.disclosure322
      ? MNotePurchaseAgreementDisclosure322.toJSON(message.disclosure322)
      : undefined);
    message.disclosure323 !== undefined && (obj.disclosure323 = message.disclosure323
      ? MNotePurchaseAgreementDisclosure323.toJSON(message.disclosure323)
      : undefined);
    message.disclosure49 !== undefined && (obj.disclosure49 = message.disclosure49
      ? MNotePurchaseAgreementDisclosure49.toJSON(message.disclosure49)
      : undefined);
    message.disclosure41 !== undefined && (obj.disclosure41 = message.disclosure41
      ? MNotePurchaseAgreementDisclosure41.toJSON(message.disclosure41)
      : undefined);
    message.disclosure42 !== undefined && (obj.disclosure42 = message.disclosure42
      ? MNotePurchaseAgreementDisclosure42.toJSON(message.disclosure42)
      : undefined);
    message.disclosure43 !== undefined && (obj.disclosure43 = message.disclosure43
      ? MNotePurchaseAgreementDisclosure43.toJSON(message.disclosure43)
      : undefined);
    message.disclosure44 !== undefined && (obj.disclosure44 = message.disclosure44
      ? MNotePurchaseAgreementDisclosure44.toJSON(message.disclosure44)
      : undefined);
    message.disclosure45 !== undefined && (obj.disclosure45 = message.disclosure45
      ? MNotePurchaseAgreementDisclosure45.toJSON(message.disclosure45)
      : undefined);
    message.disclosure46 !== undefined && (obj.disclosure46 = message.disclosure46
      ? MNotePurchaseAgreementDisclosure46.toJSON(message.disclosure46)
      : undefined);
    message.disclosure47 !== undefined && (obj.disclosure47 = message.disclosure47
      ? MNotePurchaseAgreementDisclosure47.toJSON(message.disclosure47)
      : undefined);
    message.disclosure48 !== undefined && (obj.disclosure48 = message.disclosure48
      ? MNotePurchaseAgreementDisclosure48.toJSON(message.disclosure48)
      : undefined);
    message.pastAdvances !== undefined && (obj.pastAdvances = internalBooleanToJSON(message.pastAdvances));
    message.deferMonths !== undefined && (obj.deferMonths = message.deferMonths);
    message.amortizedMonths !== undefined && (obj.amortizedMonths = message.amortizedMonths);
    message.paymentSchedule !== undefined && (obj.paymentSchedule = message.paymentSchedule
      ? MNotePurchaseAgreementPaymentSchedule.toJSON(message.paymentSchedule)
      : undefined);
    message.deferPayment !== undefined && (obj.deferPayment = internalBooleanToJSON(message.deferPayment));
    return obj;
  },

  create<I extends Exact<DeepPartial<NotePurchaseAgreement>, I>>(base?: I): NotePurchaseAgreement {
    return NotePurchaseAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotePurchaseAgreement>, I>>(object: I): NotePurchaseAgreement {
    const message = createBaseNotePurchaseAgreement();
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MNotePurchaseAgreementCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.lenderFull = object.lenderFull ?? "";
    message.principalAmount = object.principalAmount ?? 0;
    message.contractThreshold = object.contractThreshold ?? 0;
    message.financialStatementsDate = object.financialStatementsDate ?? undefined;
    message.governLawState = object.governLawState ?? "";
    message.jurisdictions = (object.jurisdictions !== undefined && object.jurisdictions !== null)
      ? MNotePurchaseAgreementJurisdictions.fromPartial(object.jurisdictions)
      : undefined;
    message.capTableUpload = (object.capTableUpload !== undefined && object.capTableUpload !== null)
      ? PFile.fromPartial(object.capTableUpload)
      : undefined;
    message.complianceSigner = object.complianceSigner ?? "";
    message.tableAdvances = (object.tableAdvances !== undefined && object.tableAdvances !== null)
      ? PTable.fromPartial(object.tableAdvances)
      : undefined;
    message.baseInterestRate = object.baseInterestRate ?? 0;
    message.maturityDate = object.maturityDate ?? undefined;
    message.defaultInterestRate = object.defaultInterestRate ?? 0;
    message.venue = object.venue ?? "";
    message.companyAddress = object.companyAddress ?? "";
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.lenderAddress = object.lenderAddress ?? "";
    message.holderNoticeEmail = object.holderNoticeEmail ?? "";
    message.holderNoticePerson = object.holderNoticePerson ?? "";
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.disclosure31 = (object.disclosure31 !== undefined && object.disclosure31 !== null)
      ? MNotePurchaseAgreementDisclosure31.fromPartial(object.disclosure31)
      : undefined;
    message.disclosure32 = (object.disclosure32 !== undefined && object.disclosure32 !== null)
      ? MNotePurchaseAgreementDisclosure32.fromPartial(object.disclosure32)
      : undefined;
    message.disclosure33 = (object.disclosure33 !== undefined && object.disclosure33 !== null)
      ? MNotePurchaseAgreementDisclosure33.fromPartial(object.disclosure33)
      : undefined;
    message.disclosure34 = (object.disclosure34 !== undefined && object.disclosure34 !== null)
      ? MNotePurchaseAgreementDisclosure34.fromPartial(object.disclosure34)
      : undefined;
    message.disclosure35 = (object.disclosure35 !== undefined && object.disclosure35 !== null)
      ? MNotePurchaseAgreementDisclosure35.fromPartial(object.disclosure35)
      : undefined;
    message.disclosure36 = (object.disclosure36 !== undefined && object.disclosure36 !== null)
      ? MNotePurchaseAgreementDisclosure36.fromPartial(object.disclosure36)
      : undefined;
    message.disclosure37 = (object.disclosure37 !== undefined && object.disclosure37 !== null)
      ? MNotePurchaseAgreementDisclosure37.fromPartial(object.disclosure37)
      : undefined;
    message.disclosure38 = (object.disclosure38 !== undefined && object.disclosure38 !== null)
      ? MNotePurchaseAgreementDisclosure38.fromPartial(object.disclosure38)
      : undefined;
    message.disclosure39 = (object.disclosure39 !== undefined && object.disclosure39 !== null)
      ? MNotePurchaseAgreementDisclosure39.fromPartial(object.disclosure39)
      : undefined;
    message.disclosure310 = (object.disclosure310 !== undefined && object.disclosure310 !== null)
      ? MNotePurchaseAgreementDisclosure310.fromPartial(object.disclosure310)
      : undefined;
    message.disclosure311 = (object.disclosure311 !== undefined && object.disclosure311 !== null)
      ? MNotePurchaseAgreementDisclosure311.fromPartial(object.disclosure311)
      : undefined;
    message.disclosure312 = (object.disclosure312 !== undefined && object.disclosure312 !== null)
      ? MNotePurchaseAgreementDisclosure312.fromPartial(object.disclosure312)
      : undefined;
    message.disclosure313 = (object.disclosure313 !== undefined && object.disclosure313 !== null)
      ? MNotePurchaseAgreementDisclosure313.fromPartial(object.disclosure313)
      : undefined;
    message.disclosure314 = (object.disclosure314 !== undefined && object.disclosure314 !== null)
      ? MNotePurchaseAgreementDisclosure314.fromPartial(object.disclosure314)
      : undefined;
    message.disclosure315 = (object.disclosure315 !== undefined && object.disclosure315 !== null)
      ? MNotePurchaseAgreementDisclosure315.fromPartial(object.disclosure315)
      : undefined;
    message.disclosure316 = (object.disclosure316 !== undefined && object.disclosure316 !== null)
      ? MNotePurchaseAgreementDisclosure316.fromPartial(object.disclosure316)
      : undefined;
    message.disclosure317 = (object.disclosure317 !== undefined && object.disclosure317 !== null)
      ? MNotePurchaseAgreementDisclosure317.fromPartial(object.disclosure317)
      : undefined;
    message.disclosure318 = (object.disclosure318 !== undefined && object.disclosure318 !== null)
      ? MNotePurchaseAgreementDisclosure318.fromPartial(object.disclosure318)
      : undefined;
    message.disclosure319 = (object.disclosure319 !== undefined && object.disclosure319 !== null)
      ? MNotePurchaseAgreementDisclosure319.fromPartial(object.disclosure319)
      : undefined;
    message.disclosure320 = (object.disclosure320 !== undefined && object.disclosure320 !== null)
      ? MNotePurchaseAgreementDisclosure320.fromPartial(object.disclosure320)
      : undefined;
    message.disclosure321 = (object.disclosure321 !== undefined && object.disclosure321 !== null)
      ? MNotePurchaseAgreementDisclosure321.fromPartial(object.disclosure321)
      : undefined;
    message.disclosure322 = (object.disclosure322 !== undefined && object.disclosure322 !== null)
      ? MNotePurchaseAgreementDisclosure322.fromPartial(object.disclosure322)
      : undefined;
    message.disclosure323 = (object.disclosure323 !== undefined && object.disclosure323 !== null)
      ? MNotePurchaseAgreementDisclosure323.fromPartial(object.disclosure323)
      : undefined;
    message.disclosure49 = (object.disclosure49 !== undefined && object.disclosure49 !== null)
      ? MNotePurchaseAgreementDisclosure49.fromPartial(object.disclosure49)
      : undefined;
    message.disclosure41 = (object.disclosure41 !== undefined && object.disclosure41 !== null)
      ? MNotePurchaseAgreementDisclosure41.fromPartial(object.disclosure41)
      : undefined;
    message.disclosure42 = (object.disclosure42 !== undefined && object.disclosure42 !== null)
      ? MNotePurchaseAgreementDisclosure42.fromPartial(object.disclosure42)
      : undefined;
    message.disclosure43 = (object.disclosure43 !== undefined && object.disclosure43 !== null)
      ? MNotePurchaseAgreementDisclosure43.fromPartial(object.disclosure43)
      : undefined;
    message.disclosure44 = (object.disclosure44 !== undefined && object.disclosure44 !== null)
      ? MNotePurchaseAgreementDisclosure44.fromPartial(object.disclosure44)
      : undefined;
    message.disclosure45 = (object.disclosure45 !== undefined && object.disclosure45 !== null)
      ? MNotePurchaseAgreementDisclosure45.fromPartial(object.disclosure45)
      : undefined;
    message.disclosure46 = (object.disclosure46 !== undefined && object.disclosure46 !== null)
      ? MNotePurchaseAgreementDisclosure46.fromPartial(object.disclosure46)
      : undefined;
    message.disclosure47 = (object.disclosure47 !== undefined && object.disclosure47 !== null)
      ? MNotePurchaseAgreementDisclosure47.fromPartial(object.disclosure47)
      : undefined;
    message.disclosure48 = (object.disclosure48 !== undefined && object.disclosure48 !== null)
      ? MNotePurchaseAgreementDisclosure48.fromPartial(object.disclosure48)
      : undefined;
    message.pastAdvances = object.pastAdvances ?? 0;
    message.deferMonths = object.deferMonths ?? 0;
    message.amortizedMonths = object.amortizedMonths ?? 0;
    message.paymentSchedule = (object.paymentSchedule !== undefined && object.paymentSchedule !== null)
      ? MNotePurchaseAgreementPaymentSchedule.fromPartial(object.paymentSchedule)
      : undefined;
    message.deferPayment = object.deferPayment ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
