import { retrieveAxios } from 'api-services/ApiCommon'
import { ContactApi } from 'api-services/ContactInfoApi'
import { DocsApi } from 'api-services/DocsApi'
import { checkPayment } from 'api-services/UseresApi'
import { BigToggle, Button, Container, NewIcon, Select, SvgOvalActive, SvgOvalPaused, Toggle } from 'components'
import { isProd } from 'components/utils/EnvResolver'
import { History } from 'history'
import { useMedia } from 'hooks'
import mixpanel from 'mixpanel-browser'
import { PContactInfo } from 'proto/PContactInfo'
import { PDoc } from 'proto/PDoc'
import { PTimeRecord } from 'proto/PTimeRecord'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker"
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Actions, useAppDispatch, useAppSelector } from 'store'
import { EditingMode } from 'store/features/notifications/timeTracking'
import { callDialog } from 'store/mainActions'
import { DialogType, MainStore } from 'store/mainTypes'
import { InputWithDollarSign } from './InputWithDollarSign'
import { TimeTrackingPdfTemplate } from './TimeTrackingPdfTemplate'
import { addManualEntry, convertDurationToSeconds, deleteTimeRecord, onPause, onStartOrResume, reloadAllContacts, reloadAllTimeRecords, stopTimerAndReload, updateTimeRecordField } from './TimeTrackingToolUtils'

const MAX_RECORDS_BEFORE_PAYMENT = 10;

export default function TimeTrackingTool() {
    const newClientMode = useAppSelector((state) => state.timeTracking.newClientMode);
    const dispatch = useAppDispatch();

    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    const totalDocs: PDoc[] = [...sentDocs, ...inboxDocs, ...completedDocs];

    const smallScreen = useMedia({ max: "md" });

    const allRecords = useAppSelector((state) => state.timeTracking.records);
    const history = useHistory();

    useEffect(() => {
        DocsApi.getAllDocs(dispatch)
    }, [])

    useEffect(() => {
        reloadAllContacts(dispatch, dbUser, totalDocs);
        reloadAllTimeRecords(dispatch);
    }, [totalDocs.length])

    useEffect(() => {
        if (allRecords.length >= MAX_RECORDS_BEFORE_PAYMENT) {
            checkPayment(() => { },
                () => {
                    history.push(history.location.pathname + "/payment");
                },
                dbUser,
                dispatch
            )
        }
    }, [allRecords])

    if (smallScreen) {
        return <p>Currently time tracking isn't supported on mobile</p>
    }

    let captionAddition = "";

    if (newClientMode === EditingMode.NEW) {
        captionAddition = "- New Client";
    } else if (newClientMode === EditingMode.EDIT) {
        captionAddition = "- Edit Client";
    }

    return (
        <div className="w-full h-full" data-name="CapTable">
            <div className='w-full bg-primary h-44'>
                <Container>
                    <p className='text-white text-3xl'>Time Tracking {captionAddition}</p>
                </Container>
            </div>
            <Container className='-mt-30'>
                <div className="flex w-full rounded-xl justify-between flex-col px-14 bg-white pt-4 shadow-card h-90">
                    <div className='pt-8'>
                        <TimeTrackingToolHeader />
                    </div>
                </div>
                <div className="flex w-full rounded-xl justify-between flex-col px-8 pt-8">
                    <TimeTrackingToolHistory />
                </div>
            </Container>
        </div>
    )
}

function TimeTrackingToolHeader() {
    const newClientMode = useAppSelector((state) => state.timeTracking.newClientMode);
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const currentContactEmail = useAppSelector((state) => state.timeTracking.currentContactEmail);
    const currentTaskName = useAppSelector((state) => state.timeTracking.currentTaskName);
    const currentIsBillable = useAppSelector((state) => state.timeTracking.currentIsBillable);
    const currentHourlyRate = useAppSelector((state) => state.timeTracking.currentHourlyRate);
    const dispatch = useAppDispatch();

    const timeRecords = useAppSelector((state) => state.timeTracking.records);
    const runningOrPausedTimeRecords = timeRecords.filter(tr => tr.isRunning || tr.isPaused)
    const runningOrPausedTimeRecord = runningOrPausedTimeRecords.length > 0 ? runningOrPausedTimeRecords[0] : undefined;

    if (newClientMode != EditingMode.NONE) {
        return <EditClientMode />
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();

            if (!currentContactEmail) return;

            let timeRecordNew = undefined;

            if (!runningOrPausedTimeRecord) {
                timeRecordNew = {
                    ownerEmail: dbUser?.email || "",
                    contactEmail: currentContactEmail,
                    isRunning: true,
                    taskName: currentTaskName,
                    isBillable: currentIsBillable,
                    elapsedTime: 0,
                    hourlyFee: currentHourlyRate,
                    recordUuid: "",
                    startTime: new Date(Date.now()),
                    isPaused: false,
                }
            } else {
                timeRecordNew = {
                    ...runningOrPausedTimeRecord
                }
            }

            onStartOrResume(dispatch, timeRecordNew);
        }}>
            <div className="flex flex-row justify-between divide-x divide-primaryVeryLight">
                <div className='w-1/2'>
                    <LeftHeader />
                </div>
                <div className='w-1/2'>
                    <RightHeader />
                </div>
            </div>
        </form >
    )
}

function EditClientMode() {
    const dispatch = useAppDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const editMode = useAppSelector((state) => state.timeTracking.newClientMode);
    const currentContactEmail = useAppSelector((state) => state.timeTracking.currentContactEmail);
    const allContacts = useAppSelector((state) => state.timeTracking.allContacts);
    const currentContact = editMode === EditingMode.EDIT && allContacts.find(contact => contact.clientEmail === currentContactEmail);

    const clientNameRef = React.useRef<HTMLInputElement>(null);
    const clientEmailRef = React.useRef<HTMLInputElement>(null);
    const clientPhoneRef = React.useRef<HTMLInputElement>(null);
    const clientAddressRef = React.useRef<HTMLInputElement>(null);

    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    const totalDocs: PDoc[] = [...sentDocs, ...inboxDocs, ...completedDocs];

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();

                const contact = {
                    ownerEmail: dbUser?.email || "",
                    clientName: clientNameRef.current?.value || "",
                    clientEmail: clientEmailRef.current?.value || "",
                    phone: clientPhoneRef.current?.value || "",
                    billingAddress: clientAddressRef.current?.value || "",
                } as PContactInfo;

                ContactApi.putContact(contact, () => {
                    dispatch(Actions.timeTracking.setNewClientMode(EditingMode.NONE))
                    reloadAllContacts(dispatch, dbUser, totalDocs);
                }
                )
            }}>
            <div className="grid grid-cols-2 gap-4 gap-x-72 gap-y-6">
                <div className="flex flex-row gap-4 justify-between">
                    <p className='text-lg text-darkIndigo'>Client Name *</p>
                    <input defaultValue={currentContact ? currentContact.clientName : ""} ref={clientNameRef} required className='w-56 text-lg rounded-md px-2 py-3 border border-powderBlue focus:border-primary bg-white' />
                </div>
                <div className="flex flex-row gap-4 justify-between">
                    <p className='text-lg text-darkIndigo'>Client Email *</p>
                    <input type={"email"} defaultValue={currentContact ? currentContact.clientEmail : ""} ref={clientEmailRef} required className='w-56 text-lg rounded-md px-2 py-3 border border-powderBlue focus:border-primary bg-white' />
                </div>
                <div className="flex flex-row gap-4 justify-between">
                    <p className='text-lg text-darkIndigo'>Phone</p>
                    <input defaultValue={currentContact ? currentContact.phone : ""} ref={clientPhoneRef} className='w-56 text-lg rounded-md px-2 py-3 border border-powderBlue focus:border-primary bg-white' />
                </div>
                <div className="flex flex-row gap-4 justify-between">
                    <p className='text-lg text-darkIndigo'>Billing Address</p>
                    <input defaultValue={currentContact ? currentContact.billingAddress : ""} ref={clientAddressRef} className='w-56 text-lg rounded-md px-2 py-3 border border-powderBlue focus:border-primary bg-white' />
                </div>
            </div>

            <div className="flex flex-row gap-4 justify-center mt-8">
                <div className="w-56">
                    <Button size='lg' fullWidth type='submit' >Save</Button>
                </div>
                <div className="w-56">
                <Button size='lg' fullWidth type='button' variant='outlined' onClick={() => {
                    dispatch(Actions.timeTracking.setNewClientMode(EditingMode.NONE))
                }}>Cancel</Button>
                </div>
            </div>
        </form >
    )
}

function LeftHeader() {
    const dispatch = useAppDispatch();
    const currentIsBillable = useAppSelector((state) => state.timeTracking.currentIsBillable);
    const currentTaskName = useAppSelector((state) => state.timeTracking.currentTaskName);
    const currentHourlyRate = useAppSelector((state) => state.timeTracking.currentHourlyRate);

    const allRecords = useAppSelector((state) => state.timeTracking.records);
    const isRunning = allRecords.filter(record => record.isRunning).length > 0;
    const isPaused = allRecords.filter(record => record.isPaused).length > 0;
    const shouldDisable = isRunning || isPaused;

    return (
        <div className='flex flex-col-reverse xl:flex-row gap-4'>
            <div className="flex flex-col justify-between">
                <div className="flex gap-4 justify-between">
                    <p className='text-lg text-darkIndigo'>Client Name</p>
                    <SelectClients disabled={shouldDisable} />
                </div>
                <div className="flex gap-4 mt-7 justify-between">
                    <p className='text-lg text-darkIndigo'>Task Name</p>
                    <input disabled={shouldDisable} required value={currentTaskName} onChange={(e) => {
                        dispatch(Actions.timeTracking.setCurrentTaskName(e.target.value))
                    }} className='w-56 h-10 rounded-lg border border-powderBlue px-4 py-2 text-lg' />
                </div>
                <div className="flex gap-4 mt-7 justify-between">
                    <p className='text-lg text-darkIndigo'>Hourly Rate</p>
                    <InputWithDollarSign disabled={shouldDisable} val={currentHourlyRate} onChange={(e) => {
                        dispatch(Actions.timeTracking.setCurrentHourlyRate(e))
                    }} className='w-56 h-10 rounded-lg border border-powderBlue text-lg' />
                </div>

                <div className="flex gap-4 mt-7 items-center">
                    <p className='text-lg text-darkIndigo mr-8'>Billable</p>
                    <BigToggle disabled={shouldDisable} open={currentIsBillable} onChange={() => dispatch(Actions.timeTracking.setCurrentIsBillable(!currentIsBillable))} />
                </div>
            </div>
            <div className="flex flex-row ml-5 gap-5">
                <EditButton />
                <NewClientButton />
            </div>
        </div>
    )
}

function EditButton() {
    const dispatch = useAppDispatch()

    return <div className='h-min w-min' onClick={() => {
        dispatch(Actions.timeTracking.setNewClientMode(EditingMode.EDIT))
    }}>
        <NewIcon name="edit_pencil" className="hover:fill-primary cursor-pointer" />
    </div>
}

function NewClientButton() {
    const dispatch = useAppDispatch()

    return (
        <div className="flex justify-center flex-row cursor-pointer w-min h-min" onClick={() => {
            dispatch(Actions.timeTracking.setNewClientMode(EditingMode.NEW))
        }}>
            <Button shape='circle' size='sm'>+</Button>
            <span className='text-darkIndigo font-light text-base ml-2'>New Client</span>
        </div>
    )
}

function SelectClients({ disabled }: { disabled: boolean }) {
    const allContacts = useAppSelector((state) => state.timeTracking.allContacts);
    const currentContactEmail = useAppSelector((state) => state.timeTracking.currentContactEmail);
    const dispatch = useAppDispatch();

    const options: Option[] = allContacts.map(c => {
        return {
            label: c.clientName,
            value: c.clientEmail,
        }
    })

    return (
        <Select disabled={disabled} required name='timeTrackingToolSelectClients' options={options}
            className="w-56 border border-powderBlue rounded-lg px-4 py-2 text-lg focus:outline-primary bg-white"
            onChange={(_, val) => {
                dispatch(Actions.timeTracking.setCurrentContactEmail(val))
            }} value={currentContactEmail} />
    )
}

function SelectClientsFilter() {
    const allContacts = useAppSelector((state) => state.timeTracking.allContacts);
    const clientFilter = useAppSelector((state) => state.timeTracking.clientEmailFilter);
    const dispatch = useAppDispatch();

    const options: Option[] = allContacts.map(c => {
        return {
            label: c.clientName,
            value: c.clientEmail,
        }
    })

    return (
        <Select placeholder='Select client' name='timeTrackingToolSelectClients' options={options}
            className="w-44 border border-powderBlue rounded-lg px-4 py-2 text-lg focus:outline-primary bg-white"
            onChange={(_, val) => {
                dispatch(Actions.timeTracking.setClientFilter(val))
            }} value={clientFilter} />
    )
}

function SelectMonthFilter() {
    const dispatch = useAppDispatch();
    const monthFilter = useAppSelector((state) => state.timeTracking.monthEpochFilter);

    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var date = new Date();

    let options: Option[] = [];

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    for (var i = 0; i < 12; i++) {
        const copy = new Date(firstDay);
        copy.setMonth(copy.getMonth() - i);

        options.push({
            label: months[copy.getMonth()] + ' ' + copy.getFullYear(),
            value: copy.getTime()
        })
    }

    return (
        <Select placeholder='Select month' name='timeTrackingToolSelectMonth' options={options}
            className="w-44 border border-powderBlue rounded-lg px-4 py-2 text-lg focus:outline-primary bg-white"
            onChange={(_, val: number) => {
                dispatch(Actions.timeTracking.setMonthFilter(val))
            }} value={monthFilter} />
    )
}

function SelectClientsForTimeRecord({ data, disabled }: { data: PTimeRecord, disabled: boolean }) {
    const allContacts = useAppSelector((state) => state.timeTracking.allContacts);
    const dispatch = useAppDispatch();

    const options: Option[] = allContacts.map(c => {
        return {
            label: c.clientName,
            value: c.clientEmail,
        }
    })

    return (
        <Select disabled={disabled} required name='timeTrackingToolSelectClients' options={options}
            className="w-full text-lg rounded-md px-2 py-3 focus:outline-primary bg-white"
            overrideOriginalClass={true}
            onChange={(_, val) => {
                updateTimeRecordField(dispatch, data, 'contactEmail', val)
            }} value={data.contactEmail} />
    )
}

function RightHeader() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const dispatch = useAppDispatch()

    const timeRecords = useAppSelector((state) => state.timeTracking.records);
    const runningOrPausedTimeRecords = timeRecords.filter(tr => tr.isRunning || tr.isPaused)
    const runningOrPausedTimeRecord = runningOrPausedTimeRecords.length > 0 ? runningOrPausedTimeRecords[0] : undefined;

    const currentContactEmail = useAppSelector((state) => state.timeTracking.currentContactEmail);

    useEffect(() => {
        if (runningOrPausedTimeRecord) {
            dispatch(Actions.timeTracking.setCurrentContactEmail(runningOrPausedTimeRecord.contactEmail))
            dispatch(Actions.timeTracking.setCurrentTaskName(runningOrPausedTimeRecord.taskName))
            dispatch(Actions.timeTracking.setCurrentHourlyRate(runningOrPausedTimeRecord.hourlyFee))
            dispatch(Actions.timeTracking.setCurrentIsBillable(runningOrPausedTimeRecord.isBillable))
        }
    }, [runningOrPausedTimeRecord])

    if (!currentContactEmail) {
        return null;
    }

    return (
        <div className="flex flex-col px-2">
            {(!!runningOrPausedTimeRecord) ?
                <>
                    <div className="flex gap-4">
                        {runningOrPausedTimeRecord.isPaused && <SvgOvalPaused />}
                        {runningOrPausedTimeRecord.isRunning && <SvgOvalActive />}
                        <div className='text-4xl font-semibold'>Elapsed Time: <StopWatch runningOrPausedTimeRecord={runningOrPausedTimeRecord} /></div>
                    </div>
                    <div className="flex gap-4 mt-4 ml-3">
                        <p className='text-base text-darkIndigo font-light'>Date:</p>
                        <p className='text-base text-primary font-medium'>{new Date(Date.now()).toLocaleDateString("en-us", { year: "numeric", month: "short", day: "numeric" })}</p>
                    </div>
                </> :
                <MetricsForCurrent currentContactEmail={currentContactEmail} />
            }

            <div className="flex gap-4 mt-8 justify-center">
                {runningOrPausedTimeRecord?.isRunning ?
                    <Button type='button' size='lg' onClick={() => {
                        stopTimerAndReload(dispatch, runningOrPausedTimeRecord)
                    }}>
                        <NewIcon name='stop' className='fill-white' />
                        <span>Stop Tracking</span>
                    </Button> :
                    <Button type='submit' size='lg' >
                        <NewIcon name={runningOrPausedTimeRecord?.isPaused ? "resume" : "play"} className='fill-white' />
                        <span>{runningOrPausedTimeRecord?.isPaused ? "Resume Tracking" : "Start Tracking"}</span>
                    </Button>
                }
                {runningOrPausedTimeRecord?.isRunning ?
                    <Button type='button' variant='outlined' size='lg' onClick={() => {
                        onPause(dispatch, runningOrPausedTimeRecord)
                    }}>
                        <NewIcon name="pause" className='fill-darkIndigo' />
                        Pause Tracking
                    </Button> :
                    <Button type='button' variant='outlined' size='lg' onClick={() => {
                        addManualEntry(dispatch, {
                            ownerEmail: dbUser?.email || "",
                            contactEmail: currentContactEmail,
                            isBillable: false,
                            taskName: "",
                            elapsedTime: 1,
                            hourlyFee: 0,
                            recordUuid: "",
                            startTime: new Date(Date.now()),
                            isRunning: false,
                            isPaused: false,
                        }, (uuid: string) => {
                            dispatch(Actions.timeTracking.setCurrentRecordBeingEditedUuid(uuid))
                        })
                    }}>
                        Add Manual Entry
                    </Button>
                }
            </div>
        </div >
    )
}

function MetricsForCurrent({ currentContactEmail }: { currentContactEmail: string }) {
    const timeRecords = useAppSelector((state) => state.timeTracking.records);
    const timeRecordsForCurrent = timeRecords.filter(tr => tr.contactEmail === currentContactEmail);

    const timeRecordsForCurrentThisMonth = timeRecordsForCurrent.filter(tr => {
        const trDate = tr.startTime;
        const now = new Date(Date.now());
        return trDate?.getMonth() === now.getMonth() && trDate.getFullYear() === now.getFullYear();
    })

    const totalHours = timeRecordsForCurrentThisMonth?.reduce((acc, tr) => acc + tr.elapsedTime, 0) / 1000 / 60 / 60;
    const totalBillableHours = timeRecordsForCurrentThisMonth.filter(tr => tr.isBillable)?.reduce((acc, tr) => acc + tr.elapsedTime, 0) / 1000 / 60 / 60;
    const totalBillableAmount = timeRecordsForCurrentThisMonth.filter(tr => tr.isBillable)?.reduce((acc, tr) => acc + tr.elapsedTime * tr.hourlyFee / (1000 * 60 * 60), 0);

    return (
        <div className="flex gap-4 justify-between text-center">
            <div className="flex flex-col gap-2 text-center">
                <p className='text-4xl text-darkIndigo font-medium'>{totalHours.toFixed(2)}</p>
                <p className='text-base text-darkIndigo'>Hours This Month</p>
            </div>
            <div className="flex flex-col gap-2">
                <p className='text-4xl text-darkIndigo font-medium'>{totalBillableHours.toFixed(2)}</p>
                <p className='text-base text-darkIndigo'>Billable This Month</p>
            </div>
            <div className="flex flex-col gap-2">
                <p className='text-4xl text-darkIndigo font-medium'>${totalBillableAmount.toFixed(2)}</p>
                <p className='text-base text-darkIndigo'>Value This Month</p>
            </div>
        </div>
    )
}

export function secondsToClock(seconds: number) {
    let hours = Math.floor(seconds / 3600) + "";
    let minutes = Math.floor((seconds % 3600) / 60) + "";
    let secs = Math.floor(seconds % 60) + "";

    // Add leading zeroes
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    secs = secs.toString().padStart(2, "0");

    return `${hours}:${minutes}:${secs}`;
}

function StopWatch({ runningOrPausedTimeRecord }: { runningOrPausedTimeRecord: PTimeRecord }) {
    const initialTime = runningOrPausedTimeRecord.elapsedTime;
    const [elapsed, setElapsed] = useState(initialTime);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined = undefined;

        if (runningOrPausedTimeRecord.isRunning) {
            setElapsed(initialTime + (Date.now() - (runningOrPausedTimeRecord.startTime?.getTime() || 0)));

            intervalId = setInterval(() => {
                setTimeout(() => {
                    setElapsed(initialTime + (Date.now() - (runningOrPausedTimeRecord.startTime?.getTime() || 0)));
                }, 1)
            }, 1000);
        } else if (!runningOrPausedTimeRecord.isRunning && elapsed !== 0) {
            clearInterval(intervalId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [runningOrPausedTimeRecord.isRunning]);

    return (
        <span>{secondsToClock(elapsed / 1000)}</span>
    );
}

function TimeTrackingToolHistory() {
    const clientEmailFilter = useAppSelector((state) => state.timeTracking.clientEmailFilter);
    const monthFilter = useAppSelector((state) => state.timeTracking.monthEpochFilter);
    const allContacts = useAppSelector((state) => state.timeTracking.allContacts);

    const dispatch = useAppDispatch();
    const uuidEdited = useAppSelector(state => state.timeTracking.currentRecordBeingEditedUuid)

    const contact = allContacts.find(c => c.clientEmail === clientEmailFilter);
    const date = monthFilter ? new Date(Number(monthFilter)) : undefined;
    const dateFormatted = date ? date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear() : '';
    const ref = useRef<HTMLDivElement>(null);
    const history = useHistory();

    const allRecords = useAppSelector((state) => state.timeTracking.records);

    const relevantRecords = allRecords.filter(r => !clientEmailFilter || clientEmailFilter === r.contactEmail).filter(r => !monthFilter || checkIsInMonth(monthFilter, r.startTime))

    return (
        <div className="flex flex-col pb-20">
            <div className="flex w-full justify-between">
                <div className="flex gap-4">
                    <p className='text-2xl text-darkIndigo font-semibold'>Tracking History</p>
                    <SelectClientsFilter />
                    <SelectMonthFilter />
                </div>
                <Button onClick={() => {
                    if (contact && dateFormatted) {
                        generatePdf(ref.current, contact, dateFormatted, history);
                    }
                    else {
                        dispatch(callDialog(DialogType.FAILURE, "Please select a client and a month to generate a report"))
                    }
                }} variant={dateFormatted && contact? 'contained': 'outlined'} >Generate Report</Button>
                <div className='hidden'>
                    {contact && monthFilter &&
                        <TimeTrackingPdfTemplate ref={ref} client={contact} monthStr={dateFormatted} relevantRecords={relevantRecords} />}
                </div>
            </div>
            <TimeTrackingToolTable headersAndRenderers={headerWithRenderer(dispatch, uuidEdited)} />
        </div>
    )
}

function generatePdf(ref: HTMLDivElement | null, contact: PContactInfo | undefined, dateFormatted: string, history: History) {
    const fileName = "time-tracking-report-" + contact?.clientEmail + "-" + dateFormatted;

    DocsApi.withSecurityHeaders()?.then(async (authHeaders) => {

        await retrieveAxios()({
            url: "/api/v1/html-to-pdf",
            method: "POST",
            responseType: "blob",
            headers: { ...authHeaders },
            data: {
                content: ref?.innerHTML,
                id: "time-tracking-report",
                templateId: "",
            },
        })
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName + ".pdf"); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                if (isProd()) {
                    mixpanel.track("download-time-tracking", { fileName: fileName });
                }
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    history.push(history.location.pathname + "/payment");
                }
            })
            .finally(() => {
            }); //todo catch
    });
}

const headerWithRenderer = (dispatch: Function, uuidEdited: string) => [
    {
        header: 'Billable',
        renderer: (data: PTimeRecord) => {
            return <div key="billable" className='pl-4'>
                <Toggle disabled={data.recordUuid !== uuidEdited} onChange={() => { updateTimeRecordField(dispatch, data, "isBillable", !data.isBillable) }} open={data.isBillable} />
            </div>
        }
    },
    {
        header: 'Client',
        renderer: (data: PTimeRecord) => {
            return <SelectClientsForTimeRecord key="client" disabled={data.recordUuid !== uuidEdited} data={data} />
        },
    },
    {
        header: 'Task',
        renderer: (data: PTimeRecord) => {
            return <input key="task" disabled={data.recordUuid !== uuidEdited} type="text" className="w-full text-lg rounded-md px-2 py-2 focus:outline-primary bg-white" defaultValue={data.taskName} onBlur={(e) => {
                updateTimeRecordField(dispatch, data, "taskName", e.target.value)
            }} />
        },
    },
    {
        header: 'Date',
        renderer: (data: PTimeRecord) => {
            return <DatePicker
                key="date"
                disabled={data.recordUuid !== uuidEdited}
                onChange={() => { }}
                onChangeRaw={(e) => e.preventDefault()}
                dateFormat={"yyyy MMM dd"}
                className="w-full text-lg rounded-md px-2 py-2 focus:outline-primary bg-white"
                selected={data.startTime}
                onSelect={(newDate) => {
                    updateTimeRecordField(dispatch, data, "startTime", newDate)
                }}
                showTimeSelect={false}
            />
        },
    },
    {
        header: 'Duration',
        renderer: (data: PTimeRecord) => {
            return <input key="duration" step={3} disabled={data.recordUuid !== uuidEdited} type="time" className="w-full text-lg rounded-md px-2 py-2 focus:outline-primary bg-white" value={secondsToClock(data.elapsedTime / 1000)} onChange={(e) => {
                const seconds = convertDurationToSeconds(e.target.value)
                updateTimeRecordField(dispatch, data, "elapsedTime", Number(seconds) * 1000)
            }} />
        },

    },
    {
        header: 'Hourly Rate',
        renderer: (data: PTimeRecord) => {
            return <InputWithDollarSign key="hurlyFee" disabled={data.recordUuid !== uuidEdited} val={data.hourlyFee} onChange={(num) => {
                updateTimeRecordField(dispatch, data, "hourlyFee", num)
            }} className={"w-full text-lg rounded-md px-2 py-2 focus:outline-primary bg-white"} />
        },
    },
    {
        header: 'Total',
        renderer: (data: PTimeRecord) => {
            return <div key="total" className='text-lg font-medium px-2 py-2'>
                {data.isBillable ? "$" + (data.elapsedTime / 1000 / 60 / 60 * data.hourlyFee).toFixed(2) : "N/A"}
            </div>
        },
    },
    {
        header: 'Actions',
        renderer: (data: PTimeRecord) => {
            return <div className='flex gap-2'>
                <div onClick={() => {
                    deleteTimeRecord(dispatch, data)
                }}>
                    <NewIcon name="remove" className="hover:fill-warnRed cursor-pointer" />
                </div>

                <div onClick={() => {
                    if (data.recordUuid !== uuidEdited) {
                        dispatch(Actions.timeTracking.setCurrentRecordBeingEditedUuid(data.recordUuid))
                    }
                    else {
                        dispatch(Actions.timeTracking.setCurrentRecordBeingEditedUuid(""))
                    }
                }}>
                    <NewIcon name="edit_pencil" className="hover:fill-primary cursor-pointer" />
                </div>
            </div >
        }
    },
]

export function TimeTrackingToolTable({ headersAndRenderers }: { headersAndRenderers: { header: string, renderer: (p: PTimeRecord) => JSX.Element }[] }) {
    const records = useAppSelector(state => state.timeTracking.records).filter(record => record.isRunning === false && record.isPaused === false)

    const clientFilter = useAppSelector(state => state.timeTracking.clientEmailFilter)
    const monthFilter = useAppSelector(state => state.timeTracking.monthEpochFilter)
    const uuidEdited = useAppSelector(state => state.timeTracking.currentRecordBeingEditedUuid)

    return <table style={{ width: "100%", tableLayout: "fixed" }} className='mt-8'>
        <thead>
            <tr className='bg-primaryVeryLight'>
                {headersAndRenderers.map((header, index) => {
                    return <th style={{ fontWeight: "500", textAlign: "left" }} className='text-base px-2 py-2' key={index}>{header.header}</th>
                })}
            </tr>
        </thead>
        <tbody>
            {records.filter(r => !clientFilter || clientFilter === r.contactEmail).filter(r => !monthFilter || checkIsInMonth(monthFilter, r.startTime))
                .sort((a, b) => (b.startTime?.getTime() || 0) - (a.startTime?.getTime() || 0))
                .map((data) => {
                    return <tr style={{ height: "4rem" }} className={`${uuidEdited === data.recordUuid ? " bg-primaryVeryLight outline outline-primaryHalf" : ""}`} key={data.recordUuid}>
                        {headersAndRenderers.map((header, index) => {
                            return <td key={data.recordUuid + "_" + header.header}>
                                {header.renderer(data)}
                            </td>
                        })}
                    </tr>
                })}
        </tbody>
    </table>
}

function checkIsInMonth(monthEpoch: number, startTime: Date | undefined) {
    if (!startTime) return false

    const month = new Date(Number(monthEpoch))
    const start = new Date(startTime)


    return month.getFullYear() === start.getFullYear() && month.getMonth() === start.getMonth()
}
