import { KBNew } from 'apps/legal-ide/features';
import { Container, Spinner, Toggle } from 'components';
import { LoadingDots } from 'components/0_common/Loading/LoadingDots';
import { documentToName } from 'components/ContractChooser';
import { getFullDocId } from 'components/utils/RouteManager';
import { PDocGetResponse } from 'proto/PApi';
import { PDoc } from 'proto/PDoc';
import { PContractParagraphAnalysis, PContractReviewResult } from 'proto/PGPT';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import * as H from "history";
import { UploadFileStatus } from 'proto/PLegalUnit';
import { DocsApi } from 'api-services/DocsApi';

const headersAndRenderers = [
    {
        header: "ISSUES", renderer: (data: PContractParagraphAnalysis, index: number) =>
            <div className='flex flex-col gap-16'>
                <div className='flex gap-4 items-center'>
                    <p className='flex justify-center items-center w-10 h-10 flex-shrink-0 rounded-full bg-white border border-[#d3deff] text-primary text-opacity-40 shadow-xl'>{numberToLetter(index)}</p>
                    <div className='flex flex-col'>
                        <p className='text-darkIndigo text-sm font-medium'>{"Issue " + (index + 1)}</p>
                        <p className='text-darkIndigo text-lg font-medium'>{data.topic}</p>
                    </div>
                </div>
                <DangerLevel level={data.standardScore} />
            </div>
    },
    { header: "EXPLANATION", renderer: (data: PContractParagraphAnalysis, index: number) => <p className='text-darkIndigo text-base'>{data.summary}</p> },
    { header: "POSSIBLE SUGGESTIONS", renderer: (data: PContractParagraphAnalysis, index: number) => <p className='text-darkIndigo text-base'>{data.alternatives}</p> },
]

function loadDoc(history: H.History, setDoc: (doc: PDoc) => void) {
    DocsApi.getDoc(getFullDocId(history.location), (resp: PDocGetResponse) => {
        if (resp.docs && resp.docs.length === 1) {
            setDoc(resp.docs[0]);
        }
    });

}

export default function ContractReviewTool() {
    const [open, setOpen] = React.useState<boolean>(false);
    const history = useHistory();
    const [doc, setDoc] = React.useState<PDoc | undefined>(undefined);

    useEffect(() => {
        loadDoc(history, setDoc);

        const interval = setInterval(() => {
            loadDoc(history, setDoc);
        }, 10000)

        if (doc?.legalUnit?.uploadedFile?.reviewResult?.paragraphs) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, []);

    if (!doc?.legalUnit?.uploadedFile?.reviewResult) {
        return <div className="flex flex-col items-center justify-center w-full h-full">
            <p className='text-3xl'>{(doc?.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.BEING_ANALYZED || doc?.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.NEW) ?
                "Analyzing" : "Loading"}</p>
            <LoadingDots />
        </div>
    }

    let reviewResult = doc?.legalUnit?.uploadedFile?.reviewResult;

    if (!isBusinessLawDoc(reviewResult)) {
        return <Container>
            <p className='mt-8 text-4xl text-red-500'>This document doesn't appear to be a business law document. We only support documents related to businesses.</p>
        </Container>
    }

    return <div className="flex flex-col gap-8">
        <Container>
            <p className='mt-4 bg-progressBarShadow px-8 py-4 rounded-lg'>
                <u>DISCLAIMER:</u> This legal document analyzer is for informational purposes only and should not be relied upon as a substitute for professional legal advice or judgment.
                It doesn't create an attorney-client relationship or guarantee accuracy.
                By using the tool, you agree that you are solely responsible for any consequences that may arise.
            </p>
            <div className='flex justify-between items-center mb-8'>
                <div className="flex gap-2 mt-8">
                    <p className="text-darkIndigo text-2xl font-semibold">{"Analysis Summary"}</p>
                    <p className="text-primary text-2xl">{">"}</p>
                    {doc && <p className="text-darkIndigo text-2xl">{documentToName(doc)}</p>}
                </div>


		<div className="flex justify-end items-center gap-4">
			<p>Analysis</p>
			<Toggle open={open} onChange={() => setOpen(!open)} />
			<p>Contract</p>
		</div>
            </div>

            {!open ? <ReviewSummary result={reviewResult} /> : <ReviewFull result={reviewResult} />}
        </Container>
    </div>
}

function ReviewSummary({ result }: { result: PContractReviewResult | undefined }) {
    return <div className="border rounded-lg overflow-hidden border-0.5 mb-16 border-primaryVeryLight">
        <table className='w-full table-fixed' style={{ borderStyle: "hidden" }}>
            <thead>
                <tr>
                    {headersAndRenderers.map((header, index) => {
                        return <th className={`text-base text-tableCaption px-8 py-6 text-left font-medium border-t-0 border border-lightSkyBlue
                        ${index == 2 ? "w-1/4" : ""}
                        ${index == 1 ? "w-1/2" : ""}
                        ${index == 0 ? "bg-progressBarShadow w-1/4" : ""}`} key={index}>{header.header}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {result?.paragraphs?.
                    filter(p => !!p.summary).
                    filter(p => p.containsCommercialTerms || p.standardScore < 90).
                    sort((a, b) => a.standardScore - b.standardScore).
                    map((data, rowIndex) => {
                        return <tr className="" key={data.topic}>
                            {headersAndRenderers.map((header, colIndex) => {
                                return <td key={colIndex + "_" + header.header} className={`text-base px-8 py-6 text-left font-medium border border-lightSkyBlue ${colIndex == 0 ? "bg-progressBarShadow" : ""}`}>
                                    {header.renderer(data, rowIndex)}
                                </td>
                            })}
                        </tr>
                    })}
            </tbody>
        </table>
    </div>
}

function ReviewFull({ result }: { result: PContractReviewResult | undefined }) {
    return <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
            <hr />
            <TextDisplay result={result} />
        </div>
    </div>
}

function TextDisplay({ result }: { result: PContractReviewResult | undefined }) {
    if (!result || !result.paragraphs) {
        return null;
    }

    return <div className="border border-lightSkyBlue border-opacity-50 rounded-lg shadow-card flex flex-col w-full max-w-5xl mx-auto px-20 py-8 gap-6">
        {result.paragraphs?.map((paragraph, index) =>
            <ParagraphDisplay paragraph={paragraph} index={index} />
        )}
    </div>
}

function ParagraphDisplay({ paragraph, index }: { paragraph: PContractParagraphAnalysis, index: number }) {
    if (index === 0) {
        return <p className="text-darkIndigo text-center text-base font-semibold">{paragraph.originalText}</p>
    }

    return <div className={`relative text-justify ${indent(paragraph.originalText)}`}>
        <span className="absolute top-0 -left-14" contentEditable={false}>
            <KBNew key={index} text={paragraph.summary} uniqueId={"_" + index} />
        </span>
        <div id={"id_" + index}>
            <p className="text-darkIndigo text-base">{paragraph.originalText}</p>
        </div>
    </div>
}

function indent(text: string) {
    const trimmedText = text.trim();

    const numberingRegex = /((\()?[a-zA-z0-9]\)|([A-z0-9]\.)).*/
    const match = trimmedText.match(numberingRegex);

    if (match && match.index === 0) {
        return "pl-8";
    }

    return "";
}

function numberToLetter(index: number) {
    return String.fromCharCode(65 + index);
}

function DangerLevel({ level }: { level: number }) {
    return <div className='w-full'>
        <div className="flex w-full relative">
            <Triangle level={level} />
            <div className="rounded-l-full bg-warn h-2 w-1/3" />
            <div className="bg-secondary h-2 w-1/3" />
            <div className="rounded-r-full bg-greens-400 h-2 w-1/3" />
        </div>
    </div>
}

function Triangle({ level }: { level: number }) {
    let closest = "";

    if (level > 80) {
        closest = "83.33%";
    } else if (level > 60) {
        closest = "50%";
    } else {
        closest = "16.66%";
    }

    return <div style={{ left: closest }} className="border-solid border-t-darkIndigo border-t-[12px] border-x-transparent border-x-8 border-b-0 absolute -top-5 -translate-x-1/2"></div>
}

function isBusinessLawDoc(result: PContractReviewResult | undefined): boolean {
    if (!result) {
        return false;
    }

    let count = 0;

    result.paragraphs?.forEach(p => {
        if (p.isBusinessLaw) {
            ++count;
        }
    })

    return (count / result.paragraphs.length) > 0.3;
}
