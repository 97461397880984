import { PUserStatus } from "proto/PUser";
import { useSelector } from "react-redux";
import { MainStore } from "store/mainTypes";

export function usePlanState() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const plan = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.plan);
    const planState = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.planState
    );

    const isUserPaying = dbUser?.userStatus === PUserStatus.POST_PAYMENT || dbUser?.tier === "pro";
    const isDocumentUsed = (docId: string) => planState?.documentsUsed?.includes(docId) || false;
    const isDocumentLocked = (docId: string) => (isUserPaying ? false : isDocumentUsed(docId));

    let numDocumentsExceeded;
    if (isUserPaying) {
        numDocumentsExceeded = false;
    } else {
        if (planState?.documentsUsed && plan?.numDocuments) {
            numDocumentsExceeded = plan.numDocuments <= planState.documentsUsed.length;
        } else {
            numDocumentsExceeded = false;
        }
    }

    return { numDocumentsExceeded, plan, planState, isDocumentUsed, isDocumentLocked };
}
