import { AgreementContextProvider } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { getAutoContracts } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import DecisionSupportingToolsAlerts from "apps/legal-ide/features/DecisionSupportingTools/DecisionSupportingToolsAlerts";
import { StyleGuidePage } from "components/3_pages/StyleGuidePage/StyleGuidePage";
import { Modals } from "containers/Modals";
import { GenericOauthHandlingProviders } from "features/GenericOauthHandling";
import GenericOauthHandlingCallback from "features/GenericOauthHandling/GenericOauthHandlingCallback";
import GenericOauthHandlingA from "features/GenericOauthHandling/GenericOauthHandling_A";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { AppsContainer } from "./apps";
import MainPanel from "./components/MainPanel";
import Dialog from "./components/utils/Dialog";
import { isProd } from "./components/utils/EnvResolver";
import VideoPlayer from "./components/utils/VideoPlayer";
import ClawdiaImgWithCircle from "./img/clawdia-with-circle.png";
import { MainStore } from "./store/mainTypes";
import { ShopifyTour } from "components/ShopifyTour";

// wraps whole app with context for auto contract in prod mode
function WithAutoContractContextProd({ children }: any) {
    return <AgreementContextProvider variant="prod">{children}</AgreementContextProvider>;
}

export default function App() {
    const dialog = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dialog);
    const video = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.videoPlayer);
    const decisionSupportingToolAlert = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.decisionSupportingToolAlert
    );
    const history = useHistory();

    useEffect(() => {
        preloadImages();

        if (isProd()) {
            history.listen((change) => {
                const pathname = change.pathname;
                const parts = pathname.split("/");

                if (parts.length == 6) {
                    let eventName = parts[2] + "/" + parts[4] + "/" + parts[5];
                    mixpanel.track(eventName);
                } else {
                    mixpanel.track(pathname);
                }
            });
        }
    });

    return (
        <>
            <div
                className="flex overflow-y-hidden h-screen w-screen"
                style={{
                    maxHeight: "-webkit-fill-available",
                }}
            >
                <ShopifyTour />
                <Switch>
                    <Route path="/apps" component={AppsContainer} />
                    <Route path="/oauth" component={OauthRoutes} />
                    {/* dev routes */}
                    {process.env.NODE_ENV !== "production" && (
                        <Route path="/styleguide" component={StyleGuidePage} />
                    )}
                    <Route path="/">
                        <WithAutoContractContextProd>
                            <MainPanel />
                            {decisionSupportingToolAlert && <DecisionSupportingToolsAlerts />}
                        </WithAutoContractContextProd>
                    </Route>
                </Switch>
                {dialog && <Dialog />}
                {video && <VideoPlayer />}
            </div>
            <Modals />
        </>
    );
}

function preloadImages() {
    const contractTypeImages = getAutoContracts().map((contractType) => contractType.image);
    const imagesToPreload = [...contractTypeImages, ClawdiaImgWithCircle];

    imagesToPreload.forEach((imgUrl: any) => {
        const img = new Image();
        img.src = imgUrl;
    });
}

function OauthRoutes() {
    return (
        <Switch>
            {/* general oauth handling */}
            {GenericOauthHandlingProviders.map((provider) => {
                return [
                    <Route
                        path={`/oauth/${provider.name}/A`}
                        component={() => {
                            return <GenericOauthHandlingA provider={provider} />;
                        }}
                    />,
                    <Route
                        path={`/oauth/${provider.name}/callback`}
                        component={() => {
                            return <GenericOauthHandlingCallback provider={provider} />;
                        }}
                    />,
                ];
            })}
        </Switch>
    );
}
