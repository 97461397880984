import { request } from "./ApiCommon";

function getPlanState() {
    return request("/api/v1/plans/state", { method: "GET" });
}

function getPlan() {
    return request("/api/v1/plans", { method: "GET" });
}

export const PlansApi = { getPlanState, getPlan };
