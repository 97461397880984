/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export interface PTimeRecord {
  ownerEmail: string;
  recordUuid: string;
  contactEmail: string;
  taskName: string;
  hourlyFee: number;
  isBillable: boolean;
  isRunning: boolean;
  isPaused: boolean;
  startTime: Date | undefined;
  elapsedTime: number;
}

function createBasePTimeRecord(): PTimeRecord {
  return {
    ownerEmail: "",
    recordUuid: "",
    contactEmail: "",
    taskName: "",
    hourlyFee: 0,
    isBillable: false,
    isRunning: false,
    isPaused: false,
    startTime: undefined,
    elapsedTime: 0,
  };
}

export const PTimeRecord = {
  encode(message: PTimeRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownerEmail !== "") {
      writer.uint32(10).string(message.ownerEmail);
    }
    if (message.recordUuid !== "") {
      writer.uint32(18).string(message.recordUuid);
    }
    if (message.contactEmail !== "") {
      writer.uint32(26).string(message.contactEmail);
    }
    if (message.taskName !== "") {
      writer.uint32(34).string(message.taskName);
    }
    if (message.hourlyFee !== 0) {
      writer.uint32(41).double(message.hourlyFee);
    }
    if (message.isBillable === true) {
      writer.uint32(48).bool(message.isBillable);
    }
    if (message.isRunning === true) {
      writer.uint32(56).bool(message.isRunning);
    }
    if (message.isPaused === true) {
      writer.uint32(64).bool(message.isPaused);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.elapsedTime !== 0) {
      writer.uint32(81).double(message.elapsedTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTimeRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTimeRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.ownerEmail = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.recordUuid = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.contactEmail = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.taskName = reader.string();
          continue;
        case 5:
          if (tag != 41) {
            break;
          }

          message.hourlyFee = reader.double();
          continue;
        case 6:
          if (tag != 48) {
            break;
          }

          message.isBillable = reader.bool();
          continue;
        case 7:
          if (tag != 56) {
            break;
          }

          message.isRunning = reader.bool();
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.isPaused = reader.bool();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 81) {
            break;
          }

          message.elapsedTime = reader.double();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTimeRecord {
    return {
      ownerEmail: isSet(object.ownerEmail) ? String(object.ownerEmail) : "",
      recordUuid: isSet(object.recordUuid) ? String(object.recordUuid) : "",
      contactEmail: isSet(object.contactEmail) ? String(object.contactEmail) : "",
      taskName: isSet(object.taskName) ? String(object.taskName) : "",
      hourlyFee: isSet(object.hourlyFee) ? Number(object.hourlyFee) : 0,
      isBillable: isSet(object.isBillable) ? Boolean(object.isBillable) : false,
      isRunning: isSet(object.isRunning) ? Boolean(object.isRunning) : false,
      isPaused: isSet(object.isPaused) ? Boolean(object.isPaused) : false,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      elapsedTime: isSet(object.elapsedTime) ? Number(object.elapsedTime) : 0,
    };
  },

  toJSON(message: PTimeRecord): unknown {
    const obj: any = {};
    message.ownerEmail !== undefined && (obj.ownerEmail = message.ownerEmail);
    message.recordUuid !== undefined && (obj.recordUuid = message.recordUuid);
    message.contactEmail !== undefined && (obj.contactEmail = message.contactEmail);
    message.taskName !== undefined && (obj.taskName = message.taskName);
    message.hourlyFee !== undefined && (obj.hourlyFee = message.hourlyFee);
    message.isBillable !== undefined && (obj.isBillable = message.isBillable);
    message.isRunning !== undefined && (obj.isRunning = message.isRunning);
    message.isPaused !== undefined && (obj.isPaused = message.isPaused);
    message.startTime !== undefined && (obj.startTime = message.startTime.toISOString());
    message.elapsedTime !== undefined && (obj.elapsedTime = message.elapsedTime);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTimeRecord>, I>>(base?: I): PTimeRecord {
    return PTimeRecord.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTimeRecord>, I>>(object: I): PTimeRecord {
    const message = createBasePTimeRecord();
    message.ownerEmail = object.ownerEmail ?? "";
    message.recordUuid = object.recordUuid ?? "";
    message.contactEmail = object.contactEmail ?? "";
    message.taskName = object.taskName ?? "";
    message.hourlyFee = object.hourlyFee ?? 0;
    message.isBillable = object.isBillable ?? false;
    message.isRunning = object.isRunning ?? false;
    message.isPaused = object.isPaused ?? false;
    message.startTime = object.startTime ?? undefined;
    message.elapsedTime = object.elapsedTime ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
