import { SvgIconSettings } from "components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MainStore } from "store/mainTypes";
import { Menu, UserImg } from "./Menu";
import { NotificationsWidget } from "./NotificationsWidget";

const menuItemDetails = [
    { href: "/", text: "Home" },
    { href: "/docs", text: "My Docs" },
    { href: "/library", text: "Legal Topics" },
    { href: "/tools", text: "Tools" },
    { href: "/settings", text: "Settings", mobileOnly: true },
    { href: "/logout", text: "Logout", mobileOnly: true },
];

function SettingsWidget() {
    const history = useHistory();

    return (
        <div
            className="cursor-pointer"
            onClick={() => {
                history.push("/settings");
            }}
        >
            <SvgIconSettings />
        </div>
    );
}

const widgets = [
    { label: <SettingsWidget /> },
    { label: <NotificationsWidget /> },
    { label: <UserImg className="w-full h-full rounded-full" />, isUser: true },
];

export function AppBar() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
    const uploadedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.uploadedDocs);

    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const sum = drafts.length + sentDocs.length + inboxDocs.length + completedDocs.length + uploadedDocs.length;

    const menuItemDetailsWithBadges = menuItemDetails.map((item) => {
        if (item.href === "/docs" && sum > 0) {
            return { ...item, text: `${item.text} (${sum})` };
        }
        return item;
    });

    return <Menu menuItemDetails={menuItemDetailsWithBadges} widgets={widgets} />;
}
