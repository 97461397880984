/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { PLegalUnit } from "./PLegalUnit";
import { PUser } from "./PUser";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export enum PDocStatus {
  DRAFT = 0,
  SENT = 1,
  INBOX = 2,
  COMPLETED = 3,
  UPLOADED = 4,
  UNRECOGNIZED = -1,
}

export function pDocStatusFromJSON(object: any): PDocStatus {
  switch (object) {
    case 0:
    case "DRAFT":
      return PDocStatus.DRAFT;
    case 1:
    case "SENT":
      return PDocStatus.SENT;
    case 2:
    case "INBOX":
      return PDocStatus.INBOX;
    case 3:
    case "COMPLETED":
      return PDocStatus.COMPLETED;
    case 4:
    case "UPLOADED":
      return PDocStatus.UPLOADED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PDocStatus.UNRECOGNIZED;
  }
}

export function pDocStatusToJSON(object: PDocStatus): string {
  switch (object) {
    case PDocStatus.DRAFT:
      return "DRAFT";
    case PDocStatus.SENT:
      return "SENT";
    case PDocStatus.INBOX:
      return "INBOX";
    case PDocStatus.COMPLETED:
      return "COMPLETED";
    case PDocStatus.UPLOADED:
      return "UPLOADED";
    case PDocStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PDoc {
  accountName: string;
  id: string;
  legalUnit: PLegalUnit | undefined;
  docStatus: PDocStatus;
  permanentContext: PPermanentContext | undefined;
  templateId: string;
}

export interface PPermanentContext {
  ownerUser: PUserWithSignature | undefined;
  sharedWithUser: PUserWithSignature | undefined;
}

export interface PUserWithSignature {
  user: PUser | undefined;
  signingDate: Date | undefined;
  signatures: string[];
}

function createBasePDoc(): PDoc {
  return { accountName: "", id: "", legalUnit: undefined, docStatus: 0, permanentContext: undefined, templateId: "" };
}

export const PDoc = {
  encode(message: PDoc, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountName !== "") {
      writer.uint32(10).string(message.accountName);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.legalUnit !== undefined) {
      PLegalUnit.encode(message.legalUnit, writer.uint32(26).fork()).ldelim();
    }
    if (message.docStatus !== 0) {
      writer.uint32(32).int32(message.docStatus);
    }
    if (message.permanentContext !== undefined) {
      PPermanentContext.encode(message.permanentContext, writer.uint32(106).fork()).ldelim();
    }
    if (message.templateId !== "") {
      writer.uint32(114).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PDoc {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePDoc();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.legalUnit = PLegalUnit.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.docStatus = reader.int32() as any;
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.permanentContext = PPermanentContext.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PDoc {
    return {
      accountName: isSet(object.accountName) ? String(object.accountName) : "",
      id: isSet(object.id) ? String(object.id) : "",
      legalUnit: isSet(object.legalUnit) ? PLegalUnit.fromJSON(object.legalUnit) : undefined,
      docStatus: isSet(object.docStatus) ? pDocStatusFromJSON(object.docStatus) : 0,
      permanentContext: isSet(object.permanentContext)
        ? PPermanentContext.fromJSON(object.permanentContext)
        : undefined,
      templateId: isSet(object.templateId) ? String(object.templateId) : "",
    };
  },

  toJSON(message: PDoc): unknown {
    const obj: any = {};
    message.accountName !== undefined && (obj.accountName = message.accountName);
    message.id !== undefined && (obj.id = message.id);
    message.legalUnit !== undefined &&
      (obj.legalUnit = message.legalUnit ? PLegalUnit.toJSON(message.legalUnit) : undefined);
    message.docStatus !== undefined && (obj.docStatus = pDocStatusToJSON(message.docStatus));
    message.permanentContext !== undefined &&
      (obj.permanentContext = message.permanentContext
        ? PPermanentContext.toJSON(message.permanentContext)
        : undefined);
    message.templateId !== undefined && (obj.templateId = message.templateId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PDoc>, I>>(base?: I): PDoc {
    return PDoc.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PDoc>, I>>(object: I): PDoc {
    const message = createBasePDoc();
    message.accountName = object.accountName ?? "";
    message.id = object.id ?? "";
    message.legalUnit = (object.legalUnit !== undefined && object.legalUnit !== null)
      ? PLegalUnit.fromPartial(object.legalUnit)
      : undefined;
    message.docStatus = object.docStatus ?? 0;
    message.permanentContext = (object.permanentContext !== undefined && object.permanentContext !== null)
      ? PPermanentContext.fromPartial(object.permanentContext)
      : undefined;
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBasePPermanentContext(): PPermanentContext {
  return { ownerUser: undefined, sharedWithUser: undefined };
}

export const PPermanentContext = {
  encode(message: PPermanentContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownerUser !== undefined) {
      PUserWithSignature.encode(message.ownerUser, writer.uint32(10).fork()).ldelim();
    }
    if (message.sharedWithUser !== undefined) {
      PUserWithSignature.encode(message.sharedWithUser, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPermanentContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPermanentContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.ownerUser = PUserWithSignature.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.sharedWithUser = PUserWithSignature.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPermanentContext {
    return {
      ownerUser: isSet(object.ownerUser) ? PUserWithSignature.fromJSON(object.ownerUser) : undefined,
      sharedWithUser: isSet(object.sharedWithUser) ? PUserWithSignature.fromJSON(object.sharedWithUser) : undefined,
    };
  },

  toJSON(message: PPermanentContext): unknown {
    const obj: any = {};
    message.ownerUser !== undefined &&
      (obj.ownerUser = message.ownerUser ? PUserWithSignature.toJSON(message.ownerUser) : undefined);
    message.sharedWithUser !== undefined &&
      (obj.sharedWithUser = message.sharedWithUser ? PUserWithSignature.toJSON(message.sharedWithUser) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPermanentContext>, I>>(base?: I): PPermanentContext {
    return PPermanentContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPermanentContext>, I>>(object: I): PPermanentContext {
    const message = createBasePPermanentContext();
    message.ownerUser = (object.ownerUser !== undefined && object.ownerUser !== null)
      ? PUserWithSignature.fromPartial(object.ownerUser)
      : undefined;
    message.sharedWithUser = (object.sharedWithUser !== undefined && object.sharedWithUser !== null)
      ? PUserWithSignature.fromPartial(object.sharedWithUser)
      : undefined;
    return message;
  },
};

function createBasePUserWithSignature(): PUserWithSignature {
  return { user: undefined, signingDate: undefined, signatures: [] };
}

export const PUserWithSignature = {
  encode(message: PUserWithSignature, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      PUser.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.signingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.signingDate), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.signatures) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PUserWithSignature {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePUserWithSignature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.user = PUser.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.signingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.signatures.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PUserWithSignature {
    return {
      user: isSet(object.user) ? PUser.fromJSON(object.user) : undefined,
      signingDate: isSet(object.signingDate) ? fromJsonTimestamp(object.signingDate) : undefined,
      signatures: Array.isArray(object?.signatures) ? object.signatures.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: PUserWithSignature): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? PUser.toJSON(message.user) : undefined);
    message.signingDate !== undefined && (obj.signingDate = message.signingDate.toISOString());
    if (message.signatures) {
      obj.signatures = message.signatures.map((e) => e);
    } else {
      obj.signatures = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PUserWithSignature>, I>>(base?: I): PUserWithSignature {
    return PUserWithSignature.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PUserWithSignature>, I>>(object: I): PUserWithSignature {
    const message = createBasePUserWithSignature();
    message.user = (object.user !== undefined && object.user !== null) ? PUser.fromPartial(object.user) : undefined;
    message.signingDate = object.signingDate ?? undefined;
    message.signatures = object.signatures?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
