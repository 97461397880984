import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";
import { CapTableStore } from "../../store/cap-table-store/capTableTypes";

function SafeChart() {
    const foundersShares = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.foundersShares);
    const allocatedOptions = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.allocatedOptions);
    const unallocatedOptions = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.unallocatedOptions);

    const sharesPerSafe = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.sharesPerSafe);
    const allSafes = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.allSafes);

    const showConversion = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.showConversion);

    const qualifiedRoundShares = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.qualifiedRoundShares);
    const newOptionPoolShares = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.newOptionPoolShares);
    let totalShares = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.totalShares);

    if (!showConversion) {
        totalShares = foundersShares + allocatedOptions + unallocatedOptions
    }

    let percentages: number[] = [];
    let names: string[] = []

    percentages.push(foundersShares * 100 / totalShares)
    names.push("founders")

    percentages.push(allocatedOptions * 100 / totalShares)
    names.push("allocated options")

    if (showConversion) {
        percentages.push(...sharesPerSafe.map(shares => shares * 100 / totalShares))
        names.push(...allSafes.map(safe => safe.legalUnit?.safe?.investorName || "N/A"))

        percentages.push(newOptionPoolShares * 100 / totalShares)
        names.push("new option pool")

        percentages.push(qualifiedRoundShares * 100 / totalShares)
        names.push("qualified round")
    }
    else {
        percentages.push(unallocatedOptions * 100 / totalShares)
        names.push("unallocated pool")
    }

    let stuff: any = {

        series: percentages,
        options: {
            yaxis: {
                labels: {
                    formatter: function (val: number) {
                        return val.toFixed(2) + '%'
                    }
                }
            },
            labels: names,
            chart: {
                width: 300,
                type: "pie",

                size: "50%",
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 640,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                position: 'left',
                offsetY: 0,
                height: 230,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '60%',
                    }
                }
            }
        },


    }

    return (
        <div>
            <div className="chart-wrap mt-4 mb-4">
                <div id="chart">
                    <ReactApexChart className="w-full flex justify-center items-center"
                        options={stuff.options} formatter={stuff.options.yaxis.formatter} series={stuff.series} type="donut" width={400} />
                </div>
            </div>
        </div>
    );
}


export default SafeChart;