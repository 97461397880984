import React from "react";

export const InputWithDollarSign = ({ disabled, val, onChange, className }: { disabled: boolean, val: number, onChange: (n: number) => void, className: string }) => {
  const [value, setValue] = React.useState<string>(val + '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue.replaceAll(',', '').replaceAll(/[^0-9.]/g, ''));

    if (isNaN(numericValue) || inputValue.endsWith('.')) {
      setValue(inputValue);
      return;
    }

    setValue(numericValue.toLocaleString());
    onChange(numericValue);
  };

  return (
    <div className="relative">
      <input
        disabled={disabled}
        className={`${className} pl-6`}
        type="text"
        value={value}
        onChange={handleChange}
      />
      <p className="absolute left-3 text-base top-[22%]">$</p>
    </div>
  );
};