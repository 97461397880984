import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import clsx from "clsx";
import { Card, Container, Toggle } from "components";
import { ProgressForActiveDraft, ProgressForActiveDraftMobile } from "components/life-cycle/ProgressBar";
import { useMedia, useToggle } from "hooks";
import { ReactNode, useContext } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useAppSelector } from "store";

interface Props {
    center?: ReactNode | null;
    header?: ReactNode | null;
    left?: ReactNode | null;
    right?: ReactNode | null;
    bottomSheet?: ReactNode | null;
}
export const WizardLayout = (props: Props) => {
    const bottomSheetToggle = useToggle();

    const agreementContext = useContext(AgreementContext)!;
    const activeDraft = agreementContext.getActiveDraft();

    const smallScreen = useMedia({ max: "lg" });

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    return (
        <div id="WizardLayout" className="w-full">
            <div id="WizardLayout-sub_header" className="bg-white shadow-md border h-24 lg:h-32">
                {props.header}
            </div>
            {smallScreen && <ProgressForActiveDraftMobile doc={activeDraft} />}
            <Container disabled={smallScreen}>
                <div
                    id="WizardLayout-sub_body"
                    dir={agreement?.lang == "HE" ? "rtl": "ltr"}
                    lang={agreement?.lang === "HE" ? "he" : "en"}
                    className={clsx("justify-center gap-6 py-6 flex mx-auto items-start", {
                        "pb-32": smallScreen,
                    })}
                >
                    {!!props.left && (
                        <div id="WizardLayout-sub_body-left" className="lg:w-[22%] lg:sticky top-16 flex-shrink-0">
                            {props.left}
                        </div>
                    )}

                    <div
                        id="WizardLayout-sub_body-center"
                        className={clsx(
                            "flex-shrink-0 transition-all duration-300 overflow-hidden",
                            {
                                "flex-grow": !props.right && !props.left,
                                "flex-grow-[1]": !!activeDraft?.legalUnit?.freestyleSrc && !props.right,
                            },
                            !!props.center ? "w-[45%]" : "w-0"
                        )}
                    >
                        {props.center}
                    </div>

                    {!!props.right && (
                        <div id="WizardLayout-sub_body-right" className="flex-grow lg:sticky top-16">
                            {!smallScreen && <ProgressForActiveDraft doc={activeDraft} />}
                            <Card className={clsx({ "mr-6": smallScreen })}>{props.right}</Card>
                        </div>
                    )}
                </div>
            </Container>
            {smallScreen && !!props.bottomSheet && (
                <BottomSheet
                    blocking={false}
                    skipInitialTransition
                    defaultSnap={({ maxHeight, snapPoints }) => Math.min(...snapPoints)}
                    snapPoints={({ maxHeight }) => [maxHeight / 2, maxHeight]}
                    open={bottomSheetToggle.state.isOn}
                    onDismiss={bottomSheetToggle.off}
                    className="pb-32"
                >
                    {props.bottomSheet}
                </BottomSheet>
            )}
            {smallScreen && !!props.bottomSheet && (
                <div className="fixed border-t bg-white p-4 bottom-0 left-0 right-0 z-50 h-16 flex items-center justify-between">
                    <div className="">hide contract</div>
                    <div className="">
                        <Toggle open={bottomSheetToggle.state.isOn} onChange={bottomSheetToggle.toggle} />
                    </div>
                </div>
            )}
        </div>
    );
};
