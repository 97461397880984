import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import { createRef, forwardRef } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Descendant, Editor, Transforms } from "slate";
import { RenderElementProps, useSlate } from "slate-react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { MainStore } from "store/mainTypes";
import KBImg from "img/icons/light-bulb.png";

export type KbElement = {
    type: "kb";
    align?: string;
    kbId: string;
    children: Descendant[];
};

export const KnowLedgeBaseElement = forwardRef(function KB(props: RenderElementProps, ref: any) {
    const { element, ...rest } = props;

    const { kbId } = element as KbElement;

    const editor = useSlate();

    const context = useAgreementContext();

    const isEditable = !context;

    const currentAgreements = useAppSelector((state) => state.agreementManager.currentAgreement);
    const kb = currentAgreements?.knowledgeBases.find((kb) => kb.id == kbId);

    const dispatch = useAppDispatch();

    function onHover() {
        const all = document.querySelectorAll<HTMLElement>(`#id${kb?.id}`);

        all.forEach((el) => {
            el.style.animation = "blink 1.5s";
        });

        setTimeout(() => {
            all.forEach((el) => {
                el.style.animation = "";
            });
        }, 1500);
    }

    const handleClick = () => {
        if (!isEditable) return;

        dispatch(
            Actions.ui.openModal({
                name: "LEGAL_IDE_WIZARD_EDIT_KB",
                data: { kb: kb?.text, onSave: (kb: string) => onSave(kb), onRemove: onRemove },
            })
        );
    };
    const onSave = (newKb: string) => {
        kb && dispatch(Actions.agreementManager.updateKnowlageBaseText({ id: kb?.id, text: newKb }));
    };
    const onRemove = () => {
        Transforms.unwrapNodes(editor, {
            at: [],
            match: (node) => {
                return Editor.isBlock(editor, node) && node?.type === "kb" && node.kbId === kb?.id;
            },
        });

        kb && dispatch(Actions.agreementManager.removeKnowledgeBase({ id: kb.id }));
    };

    return (
        <div ref={ref} {...rest}>
            <PreviewContractSegment onClick={handleClick} onHover={onHover} showIcon={true} simple={kb?.text}>
                <span id={"id" + kb}>{props.children}</span>
            </PreviewContractSegment>
        </div>
    );
});

export function PreviewContractSegment(props: {
    children: JSX.Element | JSX.Element[];
    simple?: string;
    showIcon?: boolean;
    onHover?: () => any;
    onClick?: () => any;
}) {
    const showExplanations = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.showExplanations
    );
    const ref = createRef<HTMLDivElement>();

    return (
        <div className="flex relative" ref={ref} >
            {showExplanations && !!props.simple && (
                <ExplanationBullet
                    simple={props.simple}
                    showIcon={props.showIcon}
                    onHover={props.onHover}
                    onClick={props.onClick}
                />
            )}
            <div className="ContractSegment w-full">{props.children}</div>
        </div>
    );
}
PreviewContractSegment.defaultProps = {
    showIcon: true,
}


export function ExplanationBullet(props: {
    simple: string,
    showIcon?: boolean;
    onHover?: () => any;
    onClick?: () => any;
}) {
    const isMobile = useCheckMobileScreen();
    const randomId = "preview-kb-" + Math.random().toString(16)


    return (
        <>
            {/* todo backgroundColor */}
            <ReactTooltip
                id={randomId}
                place={isMobile ? "right" : "left"}
                className="max-w-xs whitespace-pre-wrap opacity-100 ImpOpactiy100"
                backgroundColor={"rgb(255, 210, 21)"}
                textColor={"#000"}
                border={false}
            />
            <div
                contentEditable={false}
                onMouseEnter={props.onHover}
                className={
                    "ContractSegmentBullet cursor-pointer w-7 flex-grow-0 flex-shrink-0 absolute"
                }
                style={{ whiteSpace: "break-spaces" }}
            >
                {props.showIcon && <img className="w-full" onClick={props.onClick} src={KBImg} data-tip={props.simple} data-for={randomId} />}
            </div>
        </>
    );
}