import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { KBNew } from "apps/legal-ide/features";
import { forwardRef, Fragment, ReactNode, useContext } from "react";
import { useAppSelector } from "store";
import { Nodes, Parser, TextNode } from "../Parser";
import { MarkDownRenderer } from "./MarkDownRenderer";

import jsonLogic from "json-logic-js";
import { toCompoundJsonLogic } from "apps/legal-ide/App/components/WizardUi/WizardForm";
import { ScrollableFillable } from "apps/legal-ide/App/Editor/customElements";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import {
    ConditionalStrategy,
    MULTI_SELECTION_OPERATORS,
    MULTI_SELECTION_OPERATORS_ARR,
} from "apps/legal-ide/App/Editor/parser";
import { ConditionRenderer } from "./ConditionRenderer";
import { KnowledgebaseRenderer } from "./KnowledgebaseRenderer";

export const Renderer = ({ text }: { text: string }) => {
    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);

    const parser = new Parser({ fields });
    const ast = parser.parse(text).tree;

    return <Render node={ast} />;
};

function isTextNode(node: Nodes): node is TextNode {
    return node.type === "text";
}

interface TableRow {
    [key: string]: string;
}

interface TableData {
    headers: string[];
    data: TableRow[];
    hasHeader: boolean;
}

const parseMarkdownTable = (markdownTable: string): TableData => {
    const rows = markdownTable.split("\n");

    const hasHeader = !!rows.find((row) => row.includes("---"));

    const headers = hasHeader
        ? rows
              .shift()
              ?.trim()
              .split("|")
              .map((x) => x.trim())
              .filter((header) => header) ?? []
        : [];

    const data = rows
        .slice(hasHeader ? 1 : 0) // remove seperator line
        .map((row) => {
            const columns = row
                .trim()
                .split("|")
                .map((x) => x.trim())
                .slice(1, -1); // remove empty columns end and start

            if (hasHeader) {
                return headers?.reduce((obj, header, i) => {
                    const td = columns[i];
                    obj[header] = td;
                    return obj;
                }, {} as TableRow);
            }
            return columns?.reduce((acc, col, i) => {
                acc[i] = col;
                return acc;
            }, {} as TableRow);
        });

    return { headers, data, hasHeader };
};

function Td({ text }: { text: string }) {
    return (
        <td style={{ border: "1px solid", padding: "8px" }}>
            <MarkDownRenderer txt={text ?? ""} />
        </td>
    );
}

export function Render({ node }: { node: Nodes | Nodes[] }) {
    if (Array.isArray(node))
        return (
            <>
                {node.map((n, i) => {
                    return <Render key={i} node={n} />;
                })}
            </>
        );

    if (node.type === "table") {
        const child = node.children?.[0];
        if (isTextNode(child)) {
            const tableData = parseMarkdownTable(child.content);

            return (
                <table
                    style={{
                        borderCollapse: "collapse",
                        border: "1px solid",
                        width: "100%",
                        tableLayout: "fixed",
                        emptyCells: "show",
                    }}
                >
                    {tableData.hasHeader && (
                        <thead>
                            <tr>
                                {tableData.headers.map((header, i) => (
                                    <th key={i} style={{ border: "1px solid", padding: "8px", textAlign: "center" }}>
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {tableData.data.map((row, i) => (
                            <tr key={i}>
                                {tableData.hasHeader
                                    ? tableData.headers.map((header, j) => {
                                          return <Td key={j} text={row[header]} />;
                                      })
                                    : Object.values(row).map((val, j) => {
                                          return <Td key={j} text={val} />;
                                      })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    }
    if (node.type === "root") {
        return (
            <div id="root" className="simple-page m-4">
                {node.children.map((n, i) => (
                    <Render key={i} node={n} />
                ))}
            </div>
        );
    }

    if (node.type === "ol") {
        return (
            <ol>
                {node.children.map((n) => (
                    <Render node={n} />
                ))}
            </ol>
        );
    }

    if (node.type === "text") return <MarkDownRenderer txt={node.content} />;
    if (node.type === "conditional") {
        return (
            <ConditionRenderer node={node}>
                {node.children.map((n) => (
                    <Render node={n} />
                ))}
            </ConditionRenderer>
        );
    }

    if (node.type === "kb") {
        return (
            <KnowledgebaseRenderer node={node}>
                {node.children.map((n) => (
                    <Render node={n} />
                ))}
            </KnowledgebaseRenderer>
        );
    }
    return null;
}
