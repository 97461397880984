type DebouncedFunction<F extends (...args: any[]) => void> = {
    (...args: Parameters<F>): void;
    cancel: () => void;
};

export function debounce<F extends (...args: any[]) => void>(func: F, delay: number): DebouncedFunction<F> {
    let timerId: NodeJS.Timeout;

    const debounced: DebouncedFunction<F> = (...args) => {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };

    debounced.cancel = () => {
        clearTimeout(timerId);
    };

    return debounced;
}
