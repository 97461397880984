import { AxiosResponse } from "axios";
import { PTimeRecord } from "proto/PTimeRecord";
import { PGetAllTimeRecordResponse, PTimeRecordPutRequest, PTimeRecordPutResponse } from "../proto/PApi";
import { request } from "./ApiCommon";

export function putTimeRecord(timeRecord: PTimeRecord, successCallback: (resp: PTimeRecordPutResponse) => void) {
    const req = {} as PTimeRecordPutRequest;
    req.timeRecord = timeRecord;

    request("/api/v1/time-record", {
        method: "PUT",
        data: PTimeRecordPutRequest.toJSON(req),
    })?.then((response) => successCallback(PTimeRecordPutResponse.fromJSON(response.data)))
        .catch(() => { }); // todo
}

export function removeTimeRecord(uuid: string, successCallback: () => void) {
    request("/api/v1/time-record/" + uuid, {
        method: "DELETE",
    })?.then(() => successCallback())
        .catch(() => { }); // todo
}

export function getAllTimeRecords(successCallback: (reps: PGetAllTimeRecordResponse) => void) {
    request("/api/v1/time-record", {
        method: "GET",
    })
        ?.then((response) =>
            successCallback(PGetAllTimeRecordResponse.fromJSON(response.data))
        )
        .catch(() => { }); // todo
}

export const TimeRecordApi = { putTimeRecord, removeTimeRecord, getAllTimeRecords };
