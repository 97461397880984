import clsx from 'clsx';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { callDialog } from '../../store/mainActions';
import { DialogType, MainStore } from '../../store/mainTypes';
import Modal, { ExitButton } from '../Modal'

export default function Dialog() {
    const dialog = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dialog);
    const dispatch = useDispatch()
    

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();

            if (e.code === "Enter" || e.code === "NumpadEnter") {
                dispatch(callDialog(DialogType.NONE))
                dialog?.callback?.()
            }
        }

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };

    }, [])


    if (!dialog) {
        return null;
    }

    return (
        <Modal>
            <div
                className="relative rounded-2xl mx-auto border md:w-140 w-full shadow-lg bg-white md:p-14 p-10"
            >
                <ExitButton onClick={() => {
                    dispatch(callDialog(DialogType.NONE));
                    dialog?.callback?.(false);
                }} />

                <div className={`text-center flex flex-col ${dialog.type === DialogType.CONFIRM ? "gap-6" : "gap-6"}`}>
                    {dialog.type === DialogType.SUCCESS && <SuccessHeader />}
                    {dialog.type === DialogType.FAILURE && <WarnHeader />}
                    {dialog.type === DialogType.CONFIRM && <ConfirmHeader />}

                    <p className="text-xl text-darkIndigo mt-5 whitespace-pre-wrap text-left">
                        {dialog?.text}
                    </p>

                    {dialog.type === DialogType.CONFIRM ? (
                        <div className="flex justify-around items-center gap-9">
                            <DialogBtn text="NO"
                                onClick={
                                    () => {
                                        dispatch(callDialog(DialogType.NONE))
                                        dialog?.callback?.(false)
                                    }}
                                className={"w-45 bg-primary hover:bg-primaryHover text-white"}
                            />
                            <DialogBtn text="YES"
                                onClick={() => {
                                    dispatch(callDialog(DialogType.NONE))
                                    dialog?.callback?.(true)
                                }}
                                className={"w-45 border-warn hover:bg-warn text-darkIndigo hover:text-white"}
                            />
                        </div>
                    ) :
                        (
                            <div className="items-center">
                                <DialogBtn
                                    text="OK"
                                    onClick={
                                        () => {
                                            dispatch(callDialog(DialogType.NONE))
                                            dialog?.callback?.()
                                        }}
                                    className={`
                                    ${dialog.type == DialogType.SUCCESS ? "bg-primary hover:bg-primaryHover text-white w-full" : ""}
                                    ${dialog.type == DialogType.FAILURE ? "border-warn hover:bg-warn text-darkIndigo hover:text-white w-full" : ""}
                                    `}
                                />
                            </div>
                        )}
                </div>
            </div >
        </Modal >
    )
}


function DialogBtn({ onClick, text, className }: { onClick: Function, text: string, className: string }) {
    return <button className={`h-14 text-xl border rounded-lg ${className}`}
        onClick={() => onClick()}
    >
        {text}
    </button>
}

function SuccessHeader() {
    return <>
        <div
            className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-greens-100"
        >
            <svg
                className="h-10 w-10 text-seaweed"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M5 13l4 4L19 7"
                ></path>
            </svg>
        </div>
        <h3 className="text-3xl leading-6 font-medium text-darkIndigo">Successful!</h3>
    </>
}

function ConfirmHeader() {
    return null
}

function WarnHeader() {
    return <>
        <div
            className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
        >
            <svg
                className="h-10 w-10 text-red-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                ></path>
            </svg>
        </div>
        <h3 className="text-3xl leading-6 font-medium text-darkIndigo">Warning!</h3>
    </>
}