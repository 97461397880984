import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { RootState } from "store";

// 1) store chatbot messages array
// 2) allow read write access to messages array

export interface ChatBotMessage {
    text: string | React.ReactNode;
    isUser: boolean;
    threeDots?: boolean;
}

export interface Conversation {
    id: string;
    title: string;
    messages: ChatBotMessage[];
}

export interface chatbotState {
    conversations: Conversation[];
    currentConversation: Conversation["id"];
}

let savedState: any = localStorage.getItem("chatbot");

savedState = savedState
    ? JSON.parse(savedState)
    : ({
          conversations: [],
          currentConversation: "",
      } as chatbotState);

const initialState: chatbotState = savedState;

export const chatbotSlice = createSlice({
    name: "chatbot",
    initialState,
    reducers: {
        add: {
            prepare: (payload: ChatBotMessage) => {
                return { payload };
            },
            reducer: (state, action: PayloadAction<ChatBotMessage, string>) => {
                const currentConversation = state.conversations.find((c) => c.id === state.currentConversation);
                if (currentConversation) {
                    currentConversation.messages.push(action.payload);
                }
            },
        },
        replaceLastThreeDots: {
            prepare: (payload: { message: ChatBotMessage; conversationId: Conversation["id"] }) => {
                return { payload };
            },
            reducer: (
                state,
                action: PayloadAction<{ message: ChatBotMessage; conversationId: Conversation["id"] }, string>
            ) => {
                const { conversationId, message } = action.payload;
                const currentConversation = state.conversations.find((c) => c.id === conversationId);
                if (currentConversation) {
                    if (
                        currentConversation.messages.length > 0 &&
                        currentConversation.messages[currentConversation.messages.length - 1].threeDots
                    ) {
                        currentConversation.messages[currentConversation.messages.length - 1] = message;
                    }
                }
            },
        },
        addChatTopic: {
            prepare: () => {
                return { payload: "" };
            },
            reducer: (state, action: PayloadAction<string, string>) => {
                let id = nanoid(8);
                state.conversations.push({
                    id,
                    title: "New Chat",
                    messages: [],
                });
                state.currentConversation = id;
            },
        },
        switchChatTopic: {
            prepare: (payload: string) => {
                return { payload };
            },
            reducer: (state, action: PayloadAction<string, string>) => {
                state.currentConversation = action.payload;
            },
        },
        renameCurrentChatTopic: {
            prepare: (payload: string) => {
                return { payload };
            },
            reducer: (state, action: PayloadAction<string, string>) => {
                const currentConversation = state.conversations.find((c) => c.id === state.currentConversation);
                if (currentConversation) {
                    currentConversation.title = action.payload;
                }
            },
        },
    },
});

export const selectors = {
    selectAll: (state: RootState) => state.chatbot.conversations,
    selectCurrentConversation: (state: RootState) =>
        state.chatbot.conversations.find((c) => c.id === state.chatbot.currentConversation),
    selectCurrentMessages: (state: RootState) =>
        state.chatbot.conversations.find((c) => c.id === state.chatbot.currentConversation)?.messages ?? [],
};
