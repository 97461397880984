/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { PFile } from "../PUtils";

export const protobufPackage = "";

export enum EAnnualReview2AttachJobDescription {
  AnnualReview2_attachJobDescription_NA = 0,
  AnnualReview2_attachJobDescription_ATTACH = 1,
  AnnualReview2_attachJobDescription_PASTE = 2,
  AnnualReview2_attachJobDescription_NONE = 3,
  UNRECOGNIZED = -1,
}

export function eAnnualReview2AttachJobDescriptionFromJSON(object: any): EAnnualReview2AttachJobDescription {
  switch (object) {
    case 0:
    case "AnnualReview2_attachJobDescription_NA":
      return EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_NA;
    case 1:
    case "AnnualReview2_attachJobDescription_ATTACH":
      return EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_ATTACH;
    case 2:
    case "AnnualReview2_attachJobDescription_PASTE":
      return EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_PASTE;
    case 3:
    case "AnnualReview2_attachJobDescription_NONE":
      return EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAnnualReview2AttachJobDescription.UNRECOGNIZED;
  }
}

export function eAnnualReview2AttachJobDescriptionToJSON(object: EAnnualReview2AttachJobDescription): string {
  switch (object) {
    case EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_NA:
      return "AnnualReview2_attachJobDescription_NA";
    case EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_ATTACH:
      return "AnnualReview2_attachJobDescription_ATTACH";
    case EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_PASTE:
      return "AnnualReview2_attachJobDescription_PASTE";
    case EAnnualReview2AttachJobDescription.AnnualReview2_attachJobDescription_NONE:
      return "AnnualReview2_attachJobDescription_NONE";
    case EAnnualReview2AttachJobDescription.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MAnnualReview2AttachJobDescription {
  optionDbValue: EAnnualReview2AttachJobDescription;
  other: string;
}

export interface AnnualReview2 {
  employeeTitle: string;
  employeeNameFull: string;
  attachJobDescriptionCopy: PFile | undefined;
  attachJobDescription: MAnnualReview2AttachJobDescription | undefined;
  employeePerformance: string[];
  reviewDate: Date | undefined;
  reviewPeriod: string;
  reviewerName: string;
  reviewerTitle: string;
  employeeGoals: string[];
  jobDescription: string;
  areasExcellence: string[];
  areasImprovement: string[];
  areasFutureGoals: string[];
  coreValues: string;
  reviewerComments: string;
}

function createBaseMAnnualReview2AttachJobDescription(): MAnnualReview2AttachJobDescription {
  return { optionDbValue: 0, other: "" };
}

export const MAnnualReview2AttachJobDescription = {
  encode(message: MAnnualReview2AttachJobDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAnnualReview2AttachJobDescription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAnnualReview2AttachJobDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAnnualReview2AttachJobDescription {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAnnualReview2AttachJobDescriptionFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAnnualReview2AttachJobDescription): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eAnnualReview2AttachJobDescriptionToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAnnualReview2AttachJobDescription>, I>>(
    base?: I,
  ): MAnnualReview2AttachJobDescription {
    return MAnnualReview2AttachJobDescription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAnnualReview2AttachJobDescription>, I>>(
    object: I,
  ): MAnnualReview2AttachJobDescription {
    const message = createBaseMAnnualReview2AttachJobDescription();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseAnnualReview2(): AnnualReview2 {
  return {
    employeeTitle: "",
    employeeNameFull: "",
    attachJobDescriptionCopy: undefined,
    attachJobDescription: undefined,
    employeePerformance: [],
    reviewDate: undefined,
    reviewPeriod: "",
    reviewerName: "",
    reviewerTitle: "",
    employeeGoals: [],
    jobDescription: "",
    areasExcellence: [],
    areasImprovement: [],
    areasFutureGoals: [],
    coreValues: "",
    reviewerComments: "",
  };
}

export const AnnualReview2 = {
  encode(message: AnnualReview2, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.employeeTitle !== "") {
      writer.uint32(10).string(message.employeeTitle);
    }
    if (message.employeeNameFull !== "") {
      writer.uint32(18).string(message.employeeNameFull);
    }
    if (message.attachJobDescriptionCopy !== undefined) {
      PFile.encode(message.attachJobDescriptionCopy, writer.uint32(26).fork()).ldelim();
    }
    if (message.attachJobDescription !== undefined) {
      MAnnualReview2AttachJobDescription.encode(message.attachJobDescription, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.employeePerformance) {
      writer.uint32(42).string(v!);
    }
    if (message.reviewDate !== undefined) {
      Timestamp.encode(toTimestamp(message.reviewDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.reviewPeriod !== "") {
      writer.uint32(58).string(message.reviewPeriod);
    }
    if (message.reviewerName !== "") {
      writer.uint32(66).string(message.reviewerName);
    }
    if (message.reviewerTitle !== "") {
      writer.uint32(74).string(message.reviewerTitle);
    }
    for (const v of message.employeeGoals) {
      writer.uint32(82).string(v!);
    }
    if (message.jobDescription !== "") {
      writer.uint32(90).string(message.jobDescription);
    }
    for (const v of message.areasExcellence) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.areasImprovement) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.areasFutureGoals) {
      writer.uint32(114).string(v!);
    }
    if (message.coreValues !== "") {
      writer.uint32(122).string(message.coreValues);
    }
    if (message.reviewerComments !== "") {
      writer.uint32(130).string(message.reviewerComments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnualReview2 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnualReview2();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.employeeTitle = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.employeeNameFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.attachJobDescriptionCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.attachJobDescription = MAnnualReview2AttachJobDescription.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.employeePerformance.push(reader.string());
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.reviewDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.reviewPeriod = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.reviewerName = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.reviewerTitle = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.employeeGoals.push(reader.string());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.jobDescription = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.areasExcellence.push(reader.string());
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.areasImprovement.push(reader.string());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.areasFutureGoals.push(reader.string());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.coreValues = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.reviewerComments = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnnualReview2 {
    return {
      employeeTitle: isSet(object.employeeTitle) ? String(object.employeeTitle) : "",
      employeeNameFull: isSet(object.employeeNameFull) ? String(object.employeeNameFull) : "",
      attachJobDescriptionCopy: isSet(object.attachJobDescriptionCopy)
        ? PFile.fromJSON(object.attachJobDescriptionCopy)
        : undefined,
      attachJobDescription: isSet(object.attachJobDescription)
        ? MAnnualReview2AttachJobDescription.fromJSON(object.attachJobDescription)
        : undefined,
      employeePerformance: Array.isArray(object?.employeePerformance)
        ? object.employeePerformance.map((e: any) => String(e))
        : [],
      reviewDate: isSet(object.reviewDate) ? fromJsonTimestamp(object.reviewDate) : undefined,
      reviewPeriod: isSet(object.reviewPeriod) ? String(object.reviewPeriod) : "",
      reviewerName: isSet(object.reviewerName) ? String(object.reviewerName) : "",
      reviewerTitle: isSet(object.reviewerTitle) ? String(object.reviewerTitle) : "",
      employeeGoals: Array.isArray(object?.employeeGoals) ? object.employeeGoals.map((e: any) => String(e)) : [],
      jobDescription: isSet(object.jobDescription) ? String(object.jobDescription) : "",
      areasExcellence: Array.isArray(object?.areasExcellence) ? object.areasExcellence.map((e: any) => String(e)) : [],
      areasImprovement: Array.isArray(object?.areasImprovement)
        ? object.areasImprovement.map((e: any) => String(e))
        : [],
      areasFutureGoals: Array.isArray(object?.areasFutureGoals)
        ? object.areasFutureGoals.map((e: any) => String(e))
        : [],
      coreValues: isSet(object.coreValues) ? String(object.coreValues) : "",
      reviewerComments: isSet(object.reviewerComments) ? String(object.reviewerComments) : "",
    };
  },

  toJSON(message: AnnualReview2): unknown {
    const obj: any = {};
    message.employeeTitle !== undefined && (obj.employeeTitle = message.employeeTitle);
    message.employeeNameFull !== undefined && (obj.employeeNameFull = message.employeeNameFull);
    message.attachJobDescriptionCopy !== undefined && (obj.attachJobDescriptionCopy = message.attachJobDescriptionCopy
      ? PFile.toJSON(message.attachJobDescriptionCopy)
      : undefined);
    message.attachJobDescription !== undefined && (obj.attachJobDescription = message.attachJobDescription
      ? MAnnualReview2AttachJobDescription.toJSON(message.attachJobDescription)
      : undefined);
    if (message.employeePerformance) {
      obj.employeePerformance = message.employeePerformance.map((e) => e);
    } else {
      obj.employeePerformance = [];
    }
    message.reviewDate !== undefined && (obj.reviewDate = message.reviewDate.toISOString());
    message.reviewPeriod !== undefined && (obj.reviewPeriod = message.reviewPeriod);
    message.reviewerName !== undefined && (obj.reviewerName = message.reviewerName);
    message.reviewerTitle !== undefined && (obj.reviewerTitle = message.reviewerTitle);
    if (message.employeeGoals) {
      obj.employeeGoals = message.employeeGoals.map((e) => e);
    } else {
      obj.employeeGoals = [];
    }
    message.jobDescription !== undefined && (obj.jobDescription = message.jobDescription);
    if (message.areasExcellence) {
      obj.areasExcellence = message.areasExcellence.map((e) => e);
    } else {
      obj.areasExcellence = [];
    }
    if (message.areasImprovement) {
      obj.areasImprovement = message.areasImprovement.map((e) => e);
    } else {
      obj.areasImprovement = [];
    }
    if (message.areasFutureGoals) {
      obj.areasFutureGoals = message.areasFutureGoals.map((e) => e);
    } else {
      obj.areasFutureGoals = [];
    }
    message.coreValues !== undefined && (obj.coreValues = message.coreValues);
    message.reviewerComments !== undefined && (obj.reviewerComments = message.reviewerComments);
    return obj;
  },

  create<I extends Exact<DeepPartial<AnnualReview2>, I>>(base?: I): AnnualReview2 {
    return AnnualReview2.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnnualReview2>, I>>(object: I): AnnualReview2 {
    const message = createBaseAnnualReview2();
    message.employeeTitle = object.employeeTitle ?? "";
    message.employeeNameFull = object.employeeNameFull ?? "";
    message.attachJobDescriptionCopy =
      (object.attachJobDescriptionCopy !== undefined && object.attachJobDescriptionCopy !== null)
        ? PFile.fromPartial(object.attachJobDescriptionCopy)
        : undefined;
    message.attachJobDescription = (object.attachJobDescription !== undefined && object.attachJobDescription !== null)
      ? MAnnualReview2AttachJobDescription.fromPartial(object.attachJobDescription)
      : undefined;
    message.employeePerformance = object.employeePerformance?.map((e) => e) || [];
    message.reviewDate = object.reviewDate ?? undefined;
    message.reviewPeriod = object.reviewPeriod ?? "";
    message.reviewerName = object.reviewerName ?? "";
    message.reviewerTitle = object.reviewerTitle ?? "";
    message.employeeGoals = object.employeeGoals?.map((e) => e) || [];
    message.jobDescription = object.jobDescription ?? "";
    message.areasExcellence = object.areasExcellence?.map((e) => e) || [];
    message.areasImprovement = object.areasImprovement?.map((e) => e) || [];
    message.areasFutureGoals = object.areasFutureGoals?.map((e) => e) || [];
    message.coreValues = object.coreValues ?? "";
    message.reviewerComments = object.reviewerComments ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
