import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";
import { PDoc } from "proto/PDoc";
import { useAppSelector } from "store";

export function GenericProgress({
    label = "percent",
    value,
    limit,
    endState = "positive",
}: {
    label: "value" | "percent";
    value: number;
    limit: number;
    endState?: "positive" | "negative";
}) {
    const progress = (value / limit) * 100;
    const isDone = Math.round(progress) === 100;
    return (
        <div className="flex items-center">
            <span className="w-10 text-darkIndigo text-xs font-semibold">
                {label === "percent" ? `${progress}%` : value}/
                {label === "percent" ? "100%" : limit}
            </span>
            <span className="flex w-full relative items-center">
                <span
                    style={{ width: "100%" }}
                    className="bg-progressBarShadow h-[11px] rounded-md absolute"
                />
                <span
                    style={{ width: `${progress}%` }}
                    className={clsx("h-[11px] rounded-md absolute", {
                        "bg-progressBar": endState === "positive",
                        "bg-red-400": endState === "negative" && isDone,
                    })}
                />
            </span>
        </div>
    );
}

// todo - refactor to combine all different variations of this component into one
export function ProgressBarWithText({ doc }: { doc: PDoc }) {
    const progress = doc.legalUnit?.progress || 0;

    return (
        <div className="hidden xl:flex flex-shrink-0 justify-center items-center">
            <span className="w-10 text-darkIndigo text-xs font-semibold">{progress}%</span>
            <span className="flex w-full relative items-center">
                <span
                    style={{ width: "100%" }}
                    className="bg-progressBarShadow h-[11px] rounded-md absolute"
                />
                <span
                    style={{ width: `${progress}%` }}
                    className="bg-progressBar h-[11px] rounded-md absolute"
                />
            </span>
        </div>
    );
}

export function ProgressBarForMobile({ doc }: { doc: PDoc }) {
    const progress = doc.legalUnit?.progress || 0;

    return (
        <span className="flex w-full relative">
            <span
                style={{ width: "100%" }}
                className="bg-progressBarShadow h-progress rounded-b-3xl absolute "
            />
            <animated.span
                style={{ width: `${progress}%` }}
                className={`bg-progressBar h-progress rounded-bl-3xl absolute ${
                    progress === 100 ? "rounded-br-3xl" : ""
                }`}
            />
        </span>
    );
}

export function ProgressForActiveDraft({ doc }: { doc: PDoc | undefined }) {
    const progress = doc?.legalUnit?.progress || 0;
    const he = useAppSelector((state) => state.agreementManager.currentAgreement?.lang === "HE");

    const progressStyle = useSpring({
        from: { width: "0%" },
        to: { width: progress + "%" },
    });
    const progressSpeechBubbleStyle = useSpring({
        from: { [he ? "right" : "left"]: "0%" },
        to: { [he ? "right" : "left"]: progress + "%" },
    });
    const progressText = useSpring({
        from: { val: 0 },
        to: { val: progress },
    });

    return (
        <span className="flex w-full relative">
            <animated.div
                className="absolute -top-10 ltr:-translate-x-6 rtl:translate-x-6"
                style={progressSpeechBubbleStyle}
            >
                <ProgressSpeechBubble>
                    <animated.span className="text-darkIndigo text-xs font-semibold">
                        {progressText.val.to((p) => p.toFixed(0) + "%")}
                    </animated.span>
                </ProgressSpeechBubble>
            </animated.div>
            <span
                style={{ width: "100%" }}
                className="bg-progressBarShadow h-progress rounded-t-3xl absolute "
            />
            <animated.span
                style={progressStyle}
                className={`bg-progressBar h-progress rounded-tl-3xl absolute ${
                    progress === 100 ? "rounded-tr-3xl" : ""
                }`}
            />
        </span>
    );
}

export function ProgressForActiveDraftMobile({ doc }: { doc: PDoc | undefined }) {
    const progress = doc?.legalUnit?.progress || 0;

    const progressStyle = useSpring({
        from: { width: "0%" },
        to: { width: progress + "%" },
    });
    return (
        <span className="flex w-full relative">
            <span style={{ width: "100%" }} className="bg-progressBarShadow h-progress absolute " />
            <animated.span style={progressStyle} className={`bg-progressBar h-progress absolute`} />
        </span>
    );
}

function ProgressSpeechBubble({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex flex-col items-center">
            <div className="w-12 h-8 border bg-white border-skyBlue rounded-md drop-shadow-md">
                <div className="w-full h-full z-20 flex items-center justify-center">
                    {children}
                </div>
            </div>
            <div className="rotate-45 w-2 h-2 border border-skyBlue border-t-0 border-l-0 z-10 absolute -bottom-1 bg-white drop-shadow-md"></div>
        </div>
    );
}
