import {
    autoDocService,
    getAutoContracts,
} from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import {
    AutoContractDocument,
    AutoContractGroup,
    ContractGroup,
    contractGroups,
    findFamily,
    preferContractsFromThisFamily,
} from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { Button, Container, Ribbon, Toggle } from "components";
import ScrollIcon from "img/icons/scroll.png";
import ShopifyBag from "img/shopify_bag.png";
import _ from "lodash";
import { PUser } from "proto/PUser";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { debounce } from "utils/debounce";
import { DocsApi } from "../api-services/DocsApi";
import { PDocPutRequest } from "../proto/PApi";
import { PDoc } from "../proto/PDoc";
import { PLegalUnit } from "../proto/PLegalUnit";
import { addDraftState } from "../store/mainActions";
import { MainStore } from "../store/mainTypes";
import { EmptyState } from "./0_common/EmptyState/EmptyState";
import { useAuth } from "./contexts/AuthContext";
import ABTesting, { AB_TEST_NAMES } from "./utils/ABTesting";
import { getMarketingUtmTerm } from "./utils/UtilFunctions";

import EmptyDirImg from "img/tempEmpty.png";
import { isProd } from "./utils/EnvResolver";
import { behaviorsApi } from "api-services/behaviorsApi";
import { useMedia } from "hooks";
import { arrayUtils } from "utils/arrayUtils";
import { SHOPIFY_SUPPORTED_DOCUMENTS } from "consts";

export function Library() {
    return (
        <div className="w-full h-full">
            <div id="LibraryLayout-header" className="w-full bg-primary">
                <LibraryHeader />
            </div>
            <LegalOptions />
        </div>
    );
}

function LibraryHeader() {
    const auth = useAuth();

    const params = useParams<{ key: AutoContractGroup }>();

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

    const draftsExist =
        useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts).length > 0;
    const history = useHistory();
    const smallScreen = useMedia({ max: "md" });

    const dispatch = useAppDispatch();

    const libraryPageState = useAppSelector((state) => state.ui.pages.library);

    const debounceSearch = useCallback(
        debounce((text) => {
            dispatch(Actions.ui.librarySetSearchText(text));

            if (isProd() && !!text.trim()) {
                behaviorsApi.track({ type: "DOC_SEARCH", payload: text });
            }
        }, 1000),
        []
    );

    return (
        <Container className="flex items-start pb-12 md:pb-36">
            <div className="flex flex-wrap gap-4 flex-row justify-between w-full">
                <div className="flex flex-col w-full md:w-auto">
                    <p className="text-white opacity-40 font-medium md:text-2xl text-lg">
                        Good {timeOfTheDay()}, {auth?.getFirstName()}
                    </p>
                    <div className="flex items-center gap-2 md:mt-2">
                        <Link
                            to="/library"
                            className={`text-white md:text-3xl text-1_5xl  hover:text-white ${
                                !params.key ? "font-semibold" : ""
                            }`}
                        >
                            Legal Topics
                        </Link>
                        {!!params.key && (
                            <>
                                <span className="text-white md:text-2xl text-1xl ">{">"}</span>
                                <Link
                                    to={"/library/submenu/" + params.key}
                                    className="text-white md:text-3xl text-1_5xl font-semibold  hover:text-white"
                                >
                                    {params.key}
                                </Link>
                            </>
                        )}
                    </div>
                    <div className="w-full md:w-96 md:mt-6 mt-2">
                        <SearchInput onChange={debounceSearch} />
                    </div>
                </div>
                <div className="flex flex-col gap-8">
                    {draftsExist && !smallScreen && (
                        <Button
                            size="lg"
                            color="white"
                            onClick={() => {
                                history.push("/docs/drafts");
                            }}
                        >
                            <span className="flex gap-2 text-base">
                                <img className="w-5" src={ScrollIcon} />
                                {"work on my drafts"}
                            </span>
                        </Button>
                    )}

                    {dbUser?.country === "Israel" && (
                        <div lang="he" dir="rtl" className="flex justify-start items-center gap-4">
                            <p className="text-white">עברית בלבד</p>
                            <Toggle
                                open={libraryPageState.hebOnly}
                                onChange={() =>
                                    dispatch(Actions.ui.hebrewOnly(!libraryPageState.hebOnly))
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}

function SearchInput({ value, onChange }: { value?: string; onChange: (value: string) => void }) {
    const libraryPageState = useAppSelector((state) => state.ui.pages.library);

    return (
        <div
            tabIndex={0}
            className="flex gap-4 items-center rounded-full text-white text-lg relative"
            style={{
                background: "rgba(90, 129, 251, 0.78)",
                boxShadow: "0 5px 10px 0 rgba(27, 56, 114, 0.1)",
                border: "solid 1px rgba(255, 255, 255, 0.21)",
            }}
        >
            <div className="absolute left-3">
                <svg width="21" height="23" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.786 16.42a7.654 7.654 0 1 1 0-15.309 7.654 7.654 0 0 1 0 15.308zm4.94-1.764 6.101 7.138"
                        stroke="#FCCB11"
                        strokeWidth="2.2"
                        fill="none"
                        fillRule="evenodd"
                    />
                </svg>
            </div>
            <div className="flex-grow">
                <input
                    defaultValue={libraryPageState.searchText}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    type="text"
                    className="py-4 pl-10 pr-4 rounded-full h-full bg-transparent font-semibold text-lg w-full outline-none placeholder:text-white placeholder:opacity-40"
                    placeholder="Search"
                />
            </div>
        </div>
    );
}

function LegalOptions() {
    const libraryPageState = useAppSelector((state) => state.ui.pages.library);

    const params = useParams<{ key: AutoContractGroup }>();

    return (
        <Container className="flex flex-col md:-mt-30 -mt-10">
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-5 gap-y-5 mx-1 z-10">
                <ABTesting
                    componentA={
                        <AutoContractsCards
                            filter={{
                                searchText: libraryPageState.searchText,
                                group: params.key,
                                hebOnly: libraryPageState.hebOnly,
                            }}
                            order={false}
                        />
                    }
                    componentB={
                        <AutoContractsCards
                            filter={{
                                searchText: libraryPageState.searchText,
                                group: params.key,
                                hebOnly: libraryPageState.hebOnly,
                            }}
                            order={true}
                        />
                    }
                    isSticky={true}
                    firstVariantProbability={0.7}
                    testName={AB_TEST_NAMES.TEST_9__ORDER_CARDS_BY_FAMILY}
                />{" "}
            </div>
            <MoreOptions />
        </Container>
    );
}
export function AutoOptionGroupCard({
    // autoContract,
    showDesc,
    className,
    textClassName,
    imgClassName,
    group,
}: {
    // autoContract: AutoContractDocument;
    showDesc: boolean;
    className?: string;
    textClassName?: string;
    imgClassName: string;
    group: ContractGroup;
}) {
    const history = useHistory();

    return (
        <div className="relative min-h-[80px]">
            {[1, 2, 3].map((_, i) => {
                return (
                    <div
                        style={{
                            position: i == 1 ? "relative" : "absolute",
                            top: 2 * i,
                            right: 4 * i,
                        }}
                        className={
                            "flex relative flex-grow w-full md:rounded-2xl rounded-lg bg-white \
            shadow-card border hover:shadow-cardHover bg-libraryCard backdrop-blur-md " +
                            "cursor-pointer"
                        }
                        onClick={() => {
                            return history.push("/library/submenu/" + group.type);
                        }}
                    >
                        <div className={`${className} md:gap-8 gap-4 h-full px-5 w-full`}>
                            <img
                                className={`${imgClassName}`}
                                src={group.img}
                                alt="contract group"
                            />

                            <div className="flex flex-col md:items-start items-center gap-2">
                                <div className="h-19 flex md:items-start items-center">
                                    <p
                                        className={`${textClassName} whitespace-pre-wrap text-mainAccent`}
                                    >
                                        {group.title}
                                    </p>
                                </div>

                                {showDesc && <p className="hidden md:block">{group.desc}</p>}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export function AutoOptionCard({
    autoContract,
    showDesc,
    className,
    textClassName,
    imgClassName,
}: {
    autoContract: AutoContractDocument;
    showDesc: boolean;
    className?: string;
    textClassName?: string;
    imgClassName: string;
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const isShopifyContract =
        dbUser?.isShopifyUser && SHOPIFY_SUPPORTED_DOCUMENTS.includes(autoContract.kind);
    return (
        <div
            id={autoContract.kind}
            dir={autoContract.hebOnly ? "rtl" : "ltr"}
            lang={autoContract.hebOnly ? "he" : "en"}
            className={
                "flex relative flex-grow w-full md:rounded-2xl rounded-lg bg-white \
    shadow-card hover:shadow-cardHover bg-libraryCard backdrop-blur-md " +
                (autoContract.comingSoon(dbUser) ? "cursor-not-allowed" : "cursor-pointer")
            }
            onClick={() => {
                createAndRedirectToDraft(autoContract, dispatch, history, dbUser, undefined);
            }}
        >
            {isShopifyContract && (
                <div className="absolute top-3 right-3">
                    <div className="relative">
                        <img className="w-5 cursor-pointer" src={ShopifyBag} alt="Shopify icon" />
                    </div>
                </div>
            )}
            <div className={`${className} md:gap-8 gap-4 h-full px-5`}>
                <img
                    alt="contract card"
                    className={`${imgClassName} ${autoContract.blur ? "blur-md" : ""}`}
                    src={autoContract.image}
                />
                <div className="flex flex-col md:items-start items-center gap-2">
                    <div className="h-19 flex md:items-start items-center">
                        <p className={`${textClassName} whitespace-pre-wrap text-mainAccent`}>
                            {autoContract.blur ? "" : autoContract.text}
                        </p>
                    </div>

                    {showDesc && <p className="hidden md:block">{autoContract.desc}</p>}
                </div>
            </div>
            {autoContract.comingSoon(dbUser) && (
                <>
                    <div className="hidden md:block">
                        <Ribbon
                            position="top-right"
                            content="Coming Soon"
                            bgClassName="bg-progressBar"
                            textClassName="text-white"
                        />
                    </div>
                    <div className="block md:hidden">
                        <Ribbon
                            position="top-right"
                            content="Coming Soon"
                            bgClassName="bg-progressBar"
                            textClassName="text-white"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export function newAutoDraft(
    kind: string,
    version: string,
    dispatch: Dispatch<any>,
    cb: Function,
    baseDoc: PDoc | undefined
) {
    let legalUnit: PLegalUnit;

    if (baseDoc?.legalUnit) {
        legalUnit = _.cloneDeep(baseDoc.legalUnit);
        legalUnit.docVersion = version;
        legalUnit.kind = kind;
    } else {
        legalUnit = PLegalUnit.fromJSON({ [kind]: { name: "" }, kind: kind, docVersion: version });
    }

    // todo - fix naming here
    DocsApi.createNewDraft(legalUnit, (response: PDocPutRequest) => {
        let docId = response.docId;

        let doc: PDoc;
        doc = PDoc.fromJSON({ id: docId, legalUnit: legalUnit });

        dispatch(addDraftState(doc));

        cb(docId);
    });
}

function timeOfTheDay() {
    const nowHours = new Date().getHours();

    if (nowHours < 5) {
        return "evening";
    } else if (nowHours < 12) {
        return "morning";
    } else if (nowHours < 18) {
        return "afternoon";
    } else if (nowHours < 22) {
        return "evening";
    }

    return "evening";
}

function MoreOptions() {
    const history = useHistory();

    return (
        <div className="flex justify-center mt-9 md:mt-36 mb-12 md:mb-8 w-full">
            <p
                onClick={() => {
                    history.push("/legal-topics-survey");
                }}
                className="flex flex-col md:flex-row text-darkIndigo text-xl text-center cursor-pointer"
            >
                <span>Need a different legal topic?&nbsp;</span>
                <span className="text-primary font-semibold">Let me know</span>
            </p>
        </div>
    );
}

export function createDraft(
    autoContract: AutoContractDocument,
    dispatch: Dispatch<any>,
    dbUser: PUser | undefined,
    baseDoc: PDoc | undefined,
    cb: (docId: string) => void
) {
    const version = autoDocService.getLatest(autoContract.filename);

    if (!autoContract.comingSoon(dbUser)) {
        newAutoDraft(
            autoContract.kind,
            version,
            dispatch,
            (docId: string) => {
                cb(docId);
            },
            baseDoc
        );
    }
}

export function createAndRedirectToDraft(
    autoContract: AutoContractDocument,
    dispatch: Dispatch<any>,
    history: any,
    dbUser: PUser | undefined,
    baseDoc: PDoc | undefined
) {
    createDraft(autoContract, dispatch, dbUser, baseDoc, (docId: string) => {
        history.push(`/${docId}/A/1`);
    });
}

interface Filter {
    group?: AutoContractDocument["group"];
    searchText?: string;
    hebOnly?: boolean;
}

function AutoContractsCards({ order, filter }: { order: boolean; filter?: Filter }) {
    let autoContracts = getAutoContracts();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const groups = arrayUtils.group(autoContracts, (c) => c.group ?? "others");

    let { others, ...rest } = groups;

    const showGroups = !filter?.searchText && !filter?.group && !filter?.hebOnly; //don't show groups on search and submenu

    let contracts = showGroups ? others : autoContracts;

    const Groups = Object.entries(rest).map(([groupName, items]) => {
        return (
            <AutoOptionGroupCard
                group={contractGroups[groupName as AutoContractGroup]}
                key={groupName}
                showDesc={true}
                className="flex flex-row md:flex-col leading-[1.08] items-center justify-center md:justify-start md:pt-14 h-28 md:h-100"
                textClassName="text-left text-lg md:text-1_5xl font-medium"
                imgClassName="md:h-32 h-20"
            />
        );
    });

    const user = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    if (order) {
        const utmTermValue = getMarketingUtmTerm().toLowerCase();

        if (utmTermValue) {
            const family = findFamily(utmTermValue);

            contracts = preferContractsFromThisFamily(contracts, family);
        }
    }

    function filterFunction(c: AutoContractDocument) {
        if (c?.hideInLibrary?.(user)) return false;
        const inGroup = filter?.group ? c.group === filter?.group : true;
        let isMatchSearch = true;
        let isHebMatch = true;

        if (filter?.searchText) {
            const searchKeywords = filter.searchText
                .toLowerCase()
                .split(/\s+/)
                .filter((w) => !!w);

            if (!searchKeywords.length) isMatchSearch = true;

            isMatchSearch = searchKeywords.some((word) =>
                c.tags.some((tag) => tag.toLowerCase().includes(word.toLowerCase()))
            );
        }

        if (filter?.hebOnly) {
            isHebMatch = !!c.hebOnly;
        }

        return inGroup && isMatchSearch && isHebMatch;
    }

    contracts = contracts.filter(filterFunction);

    if (contracts.length === 0) {
        return (
            <div className="mx-auto col-span-full max-w-xl">
                <EmptyState
                    image={EmptyDirImg}
                    title="No results match your search"
                    description={`Consider trying different keywords.\nIt's also possible that we don't currently support it.
                    \nWe are constantly updating our library. We will notify you if we add support for this topic.`}
                />
            </div>
        );
    }

    return (
        <>
            {contracts
                .filter((c) => !c.comingSoon(user))
                .filter((c) => !c.hideInLibrary?.(user))
                .filter(
                    (c) =>
                        (c.forUSA && dbUser?.country !== "Israel") ||
                        (c.forIsrael && dbUser?.country === "Israel")
                )
                .map((autoContract) => {
                    return (
                        <LibraryAutoOptionCard
                            key={autoContract.kind}
                            autoContract={autoContract}
                        />
                    );
                })}

            {showGroups && Groups}

            {contracts
                .filter((c) => !!c.comingSoon(user))
                .map((autoContract) => {
                    return (
                        <LibraryAutoOptionCard
                            key={autoContract.kind}
                            autoContract={autoContract}
                        />
                    );
                })}
        </>
    );
}

function LibraryAutoOptionCard({ autoContract }: { autoContract: AutoContractDocument }) {
    return (
        <AutoOptionCard
            key={autoContract.kind}
            autoContract={autoContract}
            showDesc={true}
            className="flex flex-row md:flex-col leading-[1.08] items-center justify-center md:justify-start md:pt-14 h-28 md:h-100"
            textClassName="text-left text-lg md:text-1_5xl font-medium"
            imgClassName="md:h-32 h-20"
        />
    );
}
