import { useAppSelector } from "store";

import { createPortal } from "react-dom";
import { modalName } from "store/features";

import * as AllModals from "./AllModals";

const modalRoot = document.getElementById("modals");

const modalsMap: { [key in modalName]: JSX.Element } = {
    FillTableModal: <AllModals.FillTableModal key="FillTableModal" />,
    editVideo: <AllModals.EditVideoModal key="EditVideoModal" />,
    editWizardStep: <AllModals.EditStepModal key="EditStepModal" />,
    ForLawyers_editSection: <AllModals.EditSectionModal key="EditSectionModal" />,
    ForLawyers_createFillable: <AllModals.CreateFillableModal key="CreateFillableModal" />,
    LEGAL_IDE_WIZARD_CONDITION: <AllModals.WizardConditionModal key="WizardConditionModal" />,
    LEGAL_IDE_WIZARD_EDIT_FIELD: <AllModals.EditFieldModal key="EditFieldModal" />,
    LEGAL_IDE_WIZARD_EDIT_KB: <AllModals.EditKBModal key="EditKBModal" />,
};

export const Modals = () => {
    const modals = useAppSelector((state) => state.ui.modals);

    const openModals = Object.entries(modals)
        .map(([key, value]) => {
            return {
                name: key,
                ...value,
            };
        })
        .filter((modal) => {
            return modal.isOpen;
        });

    const children = openModals.map((modal) => modalsMap[modal.name as modalName]);
    if (!modalRoot) return null;
    return createPortal(children, modalRoot);
};
