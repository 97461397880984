import { useSelector } from "react-redux";
import { MainStore } from "../../store/mainTypes";
import DocsBase from "./DocsBase";

export default function Drafts() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);

    return <DocsBase relevantDocs={drafts}
        caption="Drafts"
        noDocsMsg="You currently don't have any drafts"
        mainCTA="Continue"
    />
}
