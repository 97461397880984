import { ItemBranch } from "apps/legal-ide/App/components/QuestionWizard/Item";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import { Button, NewIcon, TextField } from "components";
import { CTable } from "components/0_common/Table";
import { TableBuilder } from "components/0_common/Table/TableBuilder";
import { SELECT_CUSTOM_OTHER_DB_VALUE } from "consts";
import { nanoid } from "nanoid";
import { Fragment, HTMLInputTypeAttribute, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

export const EditFieldModal = () => {
    const fieldData = useAppSelector((state) => state.ui.modals.LEGAL_IDE_WIZARD_EDIT_FIELD.data);

    const [fieldToEdit, setFieldToEdit] = useState<Field | null>(fieldData || null);

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_EDIT_FIELD" }));
    };

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        setFieldToEdit((prev) => {
            if (!prev || !result.destination) return prev;
            return {
                ...prev,
                options: reorder([...(prev.options || [])], result.source.index, result.destination.index),
            };
        });
    };

    if (!fieldToEdit) return null;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <BaseModal title="Edit Field" onClose={onCancel} size="lg">
                <div className="grid grid-cols-[min-content_1fr] gap-4 items-center">
                    {Object.entries(fieldToEdit).map(([key, value]) => {
                        const isSelect = key === "type" && Number(value) === FieldType.ENUM;
                        const hasOptions = key === "type" && (isSelect || Number(value) === FieldType.MULTI_ENUM);
                        const isNumber = key === "type" && Number(value) === FieldType.NUMBER;
                        const isEmbeddedFile = key === "type" && Number(value) === FieldType.EMBEDDED_FILE;
                        const isTable = key === "type" && Number(value) === FieldType.TABLE;

                        const editableKeys = ["name", "type"];
                        const isEditable = editableKeys.includes(key);
                        const options = fieldToEdit?.options ? fieldToEdit?.options : [];

                        if (key === "options") return null;
                        if (key === "numberProperties") return null;
                        if (key === "selectProperties") return null;
                        
                        // don't remove - legacy support for address properties for old version of address field
                        if (key === "addressProperties") return null;
                        if (key === "fileProperties") return null;
                        if (key === "optionCounter" && options.length == 0) return null;

                        return (
                            <Fragment key={key}>
                                <label htmlFor={key}>{key}</label>
                                <TextField
                                    onChange={(name, val) => setFieldToEdit({ ...fieldToEdit, [name]: val })}
                                    id={key}
                                    name={key}
                                    value={value}
                                    size="sm"
                                    disabled={!isEditable}
                                />

                                {hasOptions && (
                                    <div className="my-4 flex flex-col gap-4 col-span-2">
                                        {isSelect && (
                                            <div className="flex gap-8">
                                                <div className="flex">
                                                    <input
                                                        className="text-lg"
                                                        type="checkbox"
                                                        id="showAsDropdownMenu"
                                                        name="showAsDropdownMenu"
                                                        checked={fieldToEdit.selectProperties?.isDropdown}
                                                        onChange={(e) => {
                                                            const prevSelectProps = fieldToEdit?.selectProperties
                                                                ? fieldToEdit.selectProperties
                                                                : {};
                                                            const newSelectProps = {
                                                                ...prevSelectProps,
                                                                isDropdown: e.target.checked,
                                                            };

                                                            setFieldToEdit({
                                                                ...fieldToEdit,
                                                                selectProperties: newSelectProps,
                                                            });
                                                        }}
                                                    />
                                                    <label className="text-lg ml-1" htmlFor="showAsDropdownMenu">
                                                        Show as a dropdown
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {hasOptions && (
                                            <div className="flex">
                                                <input
                                                    className="text-lg"
                                                    type="checkbox"
                                                    id="hasCustomOther"
                                                    name="hasCustomOther"
                                                    checked={
                                                        !!fieldToEdit.options?.find(
                                                            (o) => o.dbValue === SELECT_CUSTOM_OTHER_DB_VALUE
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        const isSelected = e.target.checked;

                                                        const prev = fieldToEdit?.options ? fieldToEdit.options : [];

                                                        let newOptions: typeof fieldToEdit.options = [];
                                                        if (isSelected) {
                                                            newOptions = [
                                                                ...prev,
                                                                {
                                                                    id: nanoid(),
                                                                    fieldId: fieldToEdit.id,
                                                                    label: "Other",
                                                                    value: "OTHER",
                                                                    dbValue: SELECT_CUSTOM_OTHER_DB_VALUE,
                                                                    kb: "",
                                                                    type: "FieldOption",
                                                                },
                                                            ];
                                                        } else {
                                                            newOptions = prev.filter(
                                                                (o) => o.dbValue !== SELECT_CUSTOM_OTHER_DB_VALUE
                                                            );
                                                        }
                                                        setFieldToEdit({ ...fieldToEdit, options: newOptions });
                                                    }}
                                                />
                                                <label className="text-lg ml-1" htmlFor="hasCustomOther">
                                                    has custom other
                                                </label>
                                            </div>
                                        )}

                                        <Droppable droppableId="options" type="options">
                                            {(dropProps) => (
                                                <div
                                                    className="flex flex-col gap-4"
                                                    ref={dropProps.innerRef}
                                                    {...dropProps.droppableProps}
                                                >
                                                    {options.map((opt, index) => (
                                                        <Draggable
                                                            key={opt.dbValue}
                                                            index={index}
                                                            draggableId={opt.dbValue.toString()}
                                                            isDragDisabled={
                                                                opt.dbValue === SELECT_CUSTOM_OTHER_DB_VALUE
                                                            }
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    className="flex gap-2 items-center"
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <div {...provided.dragHandleProps}>
                                                                        <NewIcon
                                                                            name="grip_vertical"
                                                                            className="select-none text-gray-400"
                                                                        />
                                                                    </div>
                                                                    <ItemBranch item={opt} />

                                                                    <TextField
                                                                        size="sm"
                                                                        name="option"
                                                                        label="Label"
                                                                        value={opt.label}
                                                                        onChange={(_, value) => {
                                                                            const prev = fieldToEdit?.options
                                                                                ? fieldToEdit.options
                                                                                : [];
                                                                            setFieldToEdit({
                                                                                ...fieldToEdit,
                                                                                options: prev.map((o, i) =>
                                                                                    i === index
                                                                                        ? { ...o, label: value }
                                                                                        : o
                                                                                ),
                                                                            });
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        name="option"
                                                                        size="sm"
                                                                        label="Value"
                                                                        value={opt.value}
                                                                        onChange={(_, value) => {
                                                                            const prev = fieldToEdit?.options
                                                                                ? fieldToEdit.options
                                                                                : [];
                                                                            setFieldToEdit({
                                                                                ...fieldToEdit,
                                                                                options: prev.map((o, i) =>
                                                                                    i === index
                                                                                        ? { ...o, value: value }
                                                                                        : o
                                                                                ),
                                                                            });
                                                                        }}
                                                                    />

                                                                    {/*we can't render kb with a dropdown*/}
                                                                    {!fieldToEdit.selectProperties?.isDropdown && (
                                                                        <TextField
                                                                            key={index}
                                                                            name="option"
                                                                            label="kb"
                                                                            size="sm"
                                                                            value={opt.kb}
                                                                            onChange={(_, value) => {
                                                                                const prev = fieldToEdit?.options
                                                                                    ? fieldToEdit.options
                                                                                    : [];
                                                                                setFieldToEdit({
                                                                                    ...fieldToEdit,
                                                                                    options: prev.map((o, i) =>
                                                                                        i === index
                                                                                            ? { ...o, kb: value }
                                                                                            : o
                                                                                    ),
                                                                                });
                                                                            }}
                                                                        />
                                                                    )}

                                                                    <TextField
                                                                        name="optionDBValue"
                                                                        label="dbVal"
                                                                        size="sm"
                                                                        value={opt.dbValue}
                                                                        disabled={true}
                                                                    />

                                                                    <Button
                                                                        onClick={() => {
                                                                            const prev = fieldToEdit?.options
                                                                                ? fieldToEdit.options
                                                                                : [];
                                                                            setFieldToEdit({
                                                                                ...fieldToEdit,
                                                                                options: prev.filter(
                                                                                    (o) => o.value !== opt.value
                                                                                ),
                                                                            });
                                                                        }}
                                                                    >
                                                                        x
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {dropProps.placeholder}
                                                </div>
                                            )}
                                        </Droppable>

                                        <Button
                                            className="w-16 text-center"
                                            onClick={() => {
                                                const prev = fieldToEdit?.options ? fieldToEdit.options : [];

                                                setFieldToEdit({
                                                    ...fieldToEdit,
                                                    options: [
                                                        ...prev,
                                                        {
                                                            label: "",
                                                            value: "",
                                                            dbValue: fieldToEdit.optionCounter,
                                                            id: nanoid(),
                                                            fieldId: fieldToEdit.id,
                                                            type: "FieldOption",
                                                        },
                                                    ],
                                                    optionCounter: fieldToEdit.optionCounter + 1,
                                                });
                                            }}
                                        >
                                            +
                                        </Button>
                                    </div>
                                )}

                                {isNumber && (
                                    <div className="my-4 flex flex-col gap-4 col-span-2">
                                        <NumberExtensionField
                                            fieldToEdit={fieldToEdit}
                                            setFieldToEdit={setFieldToEdit}
                                            label="Prefix symbol (like $)"
                                            keyofNumProps="prefixSymbol"
                                            type="text"
                                        />
                                        <NumberExtensionField
                                            fieldToEdit={fieldToEdit}
                                            setFieldToEdit={setFieldToEdit}
                                            label="Suffix symbol (like %)"
                                            keyofNumProps="suffixSymbol"
                                            type="text"
                                        />
                                        <NumberExtensionField
                                            fieldToEdit={fieldToEdit}
                                            setFieldToEdit={setFieldToEdit}
                                            label="Max value"
                                            keyofNumProps="maxValue"
                                            type="number"
                                        />
                                        <NumberExtensionField
                                            fieldToEdit={fieldToEdit}
                                            setFieldToEdit={setFieldToEdit}
                                            label="Min value"
                                            keyofNumProps="minValue"
                                            type="number"
                                        />
                                    </div>
                                )}

                                {isEmbeddedFile && (
                                    <div className="flex flex-col w-full col-span-2">
                                        <TextField
                                            size="sm"
                                            name="option"
                                            label="Accept"
                                            type="text"
                                            value={fieldToEdit.fileProperties?.accept}
                                            defaultValue="application/pdf"
                                            onChange={(_, value) => {
                                                let fileProperties = fieldToEdit?.fileProperties
                                                    ? fieldToEdit.fileProperties
                                                    : ({} as FileProperties);
                                                fileProperties = { ...fileProperties };

                                                fileProperties.accept = value;

                                                setFieldToEdit({
                                                    ...fieldToEdit,
                                                    fileProperties: fileProperties,
                                                });
                                            }}
                                        />
                                    </div>
                                )}
                                {isTable && (
                                    <>
                                        <div className=""></div>
                                        <TableBuilder
                                            tableProperties={fieldToEdit?.tableProperties}
                                            onChange={(newTableProperties) => {
                                                setFieldToEdit({
                                                    ...fieldToEdit,
                                                    tableProperties: newTableProperties,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
                <div className="flex justify-between gap-4">
                    <Button
                        onClick={() => {
                            dispatch(Actions.agreementManager.setFields(fieldToEdit));
                            dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_EDIT_FIELD" }));
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            </BaseModal>
        </DragDropContext>
    );
};

function NumberExtensionField({
    fieldToEdit,
    setFieldToEdit,
    label,
    keyofNumProps,
    type,
}: {
    fieldToEdit: Field;
    setFieldToEdit: (field: Field) => void;
    label: string;
    keyofNumProps: keyof NumberProperties;
    type: HTMLInputTypeAttribute;
}) {
    return (
        <TextField
            size="sm"
            name="option"
            label={label}
            type={type}
            value={fieldToEdit.numberProperties?.[keyofNumProps]}
            onChange={(_, value) => {
                let numberProps = fieldToEdit?.numberProperties ? fieldToEdit.numberProperties : {};
                numberProps = { ...numberProps };

                if (!value) {
                    numberProps[keyofNumProps] = undefined;
                } else {
                    numberProps[keyofNumProps] = value;
                }

                setFieldToEdit({
                    ...fieldToEdit,
                    numberProperties: { ...numberProps },
                });
            }}
        />
    );
}
