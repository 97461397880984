export const clipboardApi = {
    // copy text/html as string to clipboard
    async writeText(data: string) {
        try {
            return await navigator.clipboard.writeText(data);
        } catch (error: any) {
            // handleError
        }
    },
};
