import "./Snackbar.scss";

import clsx from "clsx";

export interface Snackbar {
    id: string;
    message: string;
    type: "info" | "success" | "error";
}
interface Props {
    snackbar: Snackbar;
}
export const Snackbar = (props: Props) => {
    const { snackbar } = props;
    return <div className={clsx("Snackbar", snackbar.type)}>{snackbar.message}</div>;
};
