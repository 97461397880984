import { pricingApi } from "api-services/PricingApi";
import React, { createContext, useContext, useState, useEffect } from "react";

interface PricePlan {
    planName: string;
    monthlyPrice: number;
    yearlyPrice: number;
    saveMoreThan: string;
}

const PriceContext = createContext<PricePlan[] | null>(null);

export const usePrices = (): PricePlan[] | null => {
    return useContext(PriceContext);
};

interface PriceProviderProps {
    children: React.ReactNode;
}

export const PriceProvider: React.FC<PriceProviderProps> = ({ children }) => {
    const [prices, setPrices] = useState<PricePlan[] | null>(null);

    useEffect(() => {
        pricingApi.getPricing((resp: any) => {
            setPrices(resp?.data.prices);
        });
    }, []);

    return <PriceContext.Provider value={prices}>{children}</PriceContext.Provider>;
};
