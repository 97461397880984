import { PBackground, PTemplate } from "proto/PTemplate";
import create from "zustand";

interface TemplatesState {
    templates: PTemplate[];
    currentTemplate: PTemplate | null;
    createTemplate: (template: PTemplate) => void;
    setTemplates: (templates: PTemplate[]) => void;
    setCurrentTemplate: (template: PTemplate | null) => void;
    setLogo: ({ templateId, logo }: { templateId: string; logo: any }) => void;
    setName: ({ name }: { name: string }) => void;
    setCoverImage: ({ img }: { img: PBackground | undefined }) => void;
    addBackground: (background: PBackground) => void;
    removeBackground: (id: PBackground["id"]) => void;
    setColor: (payload: { templateId: string; color: string }) => void;
    setPrimaryBackgorund: (payload: { templateId: string; color: string }) => void;
    updateBackground: (payload: { backgroundId: string; name: keyof PBackground; value: any }) => void;
    reorderBackground: (payload: { srcIndex: number; distIndex: number }) => void;
}
export const useTemplatesStore = create<TemplatesState>((set, get) => {
    return {
        currentTemplate: null,
        templates: [],
        reorderBackground({ srcIndex, distIndex }) {
            const t = get().currentTemplate;
            if (!t?.design) return;
            const target = t.design.backgrounds[srcIndex];
            const dist = t.design.backgrounds[distIndex];
            t.design.backgrounds[distIndex] = { ...target };
            t.design.backgrounds[srcIndex] = { ...dist };
            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        setCoverImage({ img }) {
            const t = get().currentTemplate;
            if (!t?.design) return;
            t.design.coverBackground = img;
            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        setLogo({ logo }) {
            const t = get().currentTemplate;
            if (!t?.design) return;
            t.logo = logo;
            set(() => ({
                currentTemplate: t,
            }));
        },
        setName({ name }) {
            const t = get().currentTemplate;
            if (!t) return;
            t.name = name;
            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        setColor({ color }) {
            const t = get().currentTemplate;
            if (!t?.design) return;
            t.design.primaryColor = color;
            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        setPrimaryBackgorund({ color }) {
            const t = get().currentTemplate;
            if (!t?.design) return;

            t.design.primaryBackground = color;
            set(() => ({
                currentTemplate: { ...t },
            }));
        },

        updateBackground({ backgroundId, name, value }) {
            const t = get().currentTemplate;
            if (!t) return;
            const bg = t?.design?.backgrounds.find((b) => b.id === backgroundId);

            if (!bg || !t) return;

            bg[name] = value;

            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        addBackground(background: PBackground) {
            const t = get().currentTemplate;
            if (!t) return;

            t?.design?.backgrounds.push(background);

            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        removeBackground(id) {
            const t = get().currentTemplate;
            if (!t || !t.design) return;
            t.design.backgrounds = t.design.backgrounds.filter((b) => b.id !== id);
            set(() => ({
                currentTemplate: { ...t },
            }));
        },
        createTemplate(template) {
            const templates = [...get().templates];
            templates.push(template);
            set(() => ({
                templates: templates,
            }));
        },
        setTemplates(templates) {
            set(() => ({
                templates: templates,
            }));
        },
        setCurrentTemplate(template) {
            set(() => ({ currentTemplate: template }));
        },
    };
});
