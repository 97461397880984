/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export interface MutualNDA {
  otherName: string;
  otherAddress: string;
  addressNeedToBeSet: InternalBoolean;
  governLawState: string;
  venue: string;
}

function createBaseMutualNDA(): MutualNDA {
  return { otherName: "", otherAddress: "", addressNeedToBeSet: 0, governLawState: "", venue: "" };
}

export const MutualNDA = {
  encode(message: MutualNDA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otherName !== "") {
      writer.uint32(10).string(message.otherName);
    }
    if (message.otherAddress !== "") {
      writer.uint32(18).string(message.otherAddress);
    }
    if (message.addressNeedToBeSet !== 0) {
      writer.uint32(24).int32(message.addressNeedToBeSet);
    }
    if (message.governLawState !== "") {
      writer.uint32(34).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(42).string(message.venue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MutualNDA {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMutualNDA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.otherName = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.otherAddress = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.addressNeedToBeSet = reader.int32() as any;
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.venue = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MutualNDA {
    return {
      otherName: isSet(object.otherName) ? String(object.otherName) : "",
      otherAddress: isSet(object.otherAddress) ? String(object.otherAddress) : "",
      addressNeedToBeSet: isSet(object.addressNeedToBeSet) ? internalBooleanFromJSON(object.addressNeedToBeSet) : 0,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
    };
  },

  toJSON(message: MutualNDA): unknown {
    const obj: any = {};
    message.otherName !== undefined && (obj.otherName = message.otherName);
    message.otherAddress !== undefined && (obj.otherAddress = message.otherAddress);
    message.addressNeedToBeSet !== undefined &&
      (obj.addressNeedToBeSet = internalBooleanToJSON(message.addressNeedToBeSet));
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    return obj;
  },

  create<I extends Exact<DeepPartial<MutualNDA>, I>>(base?: I): MutualNDA {
    return MutualNDA.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MutualNDA>, I>>(object: I): MutualNDA {
    const message = createBaseMutualNDA();
    message.otherName = object.otherName ?? "";
    message.otherAddress = object.otherAddress ?? "";
    message.addressNeedToBeSet = object.addressNeedToBeSet ?? 0;
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
