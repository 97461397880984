import IntroVideo from "videos/service-provider/intro.mp4"
import ServicesVideo from "videos/service-provider/services.mp4"
import PaymentVideo from "videos/service-provider/payment.mp4"
import TermVideo from "videos/service-provider/term.mp4"
import ReimbursementVideo from "videos/service-provider/reimbursement.mp4"

import SaasIntroVideo from "videos/saas-sales-agreement/intro.mp4"

export const VideosCollection = {
    // Independent Contractor Agreement
    "INDEPENDENT_CONTRACTOR_INTRO": IntroVideo,
    "INDEPENDENT_CONTRACTOR_SERVICES": ServicesVideo,
    "INDEPENDENT_CONTRACTOR_PAYMENT": PaymentVideo,
    "INDEPENDENT_CONTRACTOR_TERM": TermVideo,
    "INDEPENDENT_CONTRACTOR_REIMBURSEMENT": ReimbursementVideo,

    // SaaS Sales Agreement
    "SAAS_SALES_AGREEMENT_INTRO": SaasIntroVideo,

    // Multi hebrew example
    "MULTI_HEBREW_EXAMPLE": {
        title: "Sprite Fight",
        src: "https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/low.mp4",
        poster: "https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980",
        thumbnails: "https://media-files.vidstack.io/sprite-fight/thumbnails.vtt",
        chapters: "https://media-files.vidstack.io/sprite-fight/chapters.vtt",
    } as MultiVideoProps,
}

export interface MultiVideoProps {
    title: string;
    src: string;
    poster: string;
    thumbnails: string;
    chapters: string;
}
