import axios, { AxiosRequestConfig } from "axios";
import firebase from "components/contexts/firebase";
import { safeArray } from "components/utils/UtilFunctions";

export async function httpClient(url: string, options?: AxiosRequestConfig) {
    const headers = options?.headers || {};

    let idToken;
    try {
        idToken = await firebase.auth().currentUser?.getIdToken(false);
    } catch (error) {}

    const auth = idToken && { Authorization: `Bearer ${idToken}` };
    try {
        const response = await axios({
            method: options?.method || "GET",
            url,
            headers: { ...auth, ...headers },
            data: options?.data,
        });
        return {
            success: true,
            ...response,
        };
    } catch (error: any) {
        return {
            success: false,
            data: error?.response?.data,
        };
    }
}

class Api {
    async getAll() {
        const items = localStorage.getItem("agreements");
        return {
            data: items ? JSON.parse(items) : [],
            success: true,
        };
    }
    async create(item: any) {
        const res = await this.getAll();
        res.data.push(item);
        localStorage.setItem("agreements", JSON.stringify(res.data));
        return {
            data: item,
            success: true,
        };
    }
    async remove(id: Agreement["id"]) {
        const res = await this.getAll();
        res.data = res.data.filter((a: Agreement) => a.id !== id);
        localStorage.setItem("agreements", JSON.stringify(res.data));
        return {
            data: null,
            success: true,
        };
    }
    async update(agreement: Agreement) {
        const { data } = await this.getAll();
        const result = safeArray<any>(data).map((item: Agreement) => {
            if (item.id === agreement.id) return agreement;
            return item;
        });
        localStorage.setItem("agreements", JSON.stringify(result));
        return {
            data: null,
            success: true,
        };
    }
}

export const agreementsApi = new Api();
