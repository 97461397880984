import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { SvgIconKnowledge, SvgIconKnowledgeOff, Tooltip } from "components";
import { useMedia, useToggle } from "hooks";
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { Descendant, Editor, Transforms } from "slate";
import { RenderElementProps, useSlate } from "slate-react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { MainStore } from "store/mainTypes";

type KbElement = {
    type: "kb";
    align?: string;
    kbId: string;
    children: Descendant[];
};

export const KnowLedgeBaseElementNew = forwardRef(function KB(props: RenderElementProps, ref: any) {
    const { element, ...rest } = props;

    const { kbId } = element as KbElement;

    const editor = useSlate();

    const context = useAgreementContext();

    const isEditable = !context;

    const currentAgreements = useAppSelector((state) => state.agreementManager.currentAgreement);
    const kb = currentAgreements?.knowledgeBases.find((kb) => kb.id == kbId);

    const dispatch = useAppDispatch();

    function onHover() {
        const all = document.querySelectorAll<HTMLElement>(`#id${kb?.id}`);

        all.forEach((el) => {
            el.style.animation = "blink 1.5s";
        });

        setTimeout(() => {
            all.forEach((el) => {
                el.style.animation = "";
            });
        }, 1500);
    }

    const handleClick = () => {
        if (!isEditable) return;

        dispatch(
            Actions.ui.openModal({
                name: "LEGAL_IDE_WIZARD_EDIT_KB",
                data: { kb: kb?.text, onSave: (kb: string) => onSave(kb), onRemove: onRemove },
            })
        );
    };
    const onSave = (newKb: string) => {
        kb && dispatch(Actions.agreementManager.updateKnowlageBaseText({ id: kb?.id, text: newKb }));
    };
    const onRemove = () => {
        Transforms.unwrapNodes(editor, {
            at: [],
            match: (node) => {
                return Editor.isBlock(editor, node) && node?.type === "kb" && node.kbId === kb?.id;
            },
        });

        kb && dispatch(Actions.agreementManager.removeKnowledgeBase({ id: kb.id }));
    };

    return (
        <div data-component="kb" ref={ref} {...rest} className="relative">
            <span className="absolute top-0 ltr:-left-14 rtl:-right-14" contentEditable={false}>
                <KBNew onClick={handleClick} text={kb?.text} uniqueId={kb?.id} />
            </span>
            <div data-content="true" id={"id" + kb?.id}>
                {props.children}
            </div>
        </div>
    );
});

export function KBNew({
    uniqueId,
    text,
    onClick,
}: {
    uniqueId: string | undefined;
    text: string | undefined;
    onClick?: () => void;
}) {
    const [state, setState] = useState(false);

    const smallScreen = useMedia({ max: "lg" });
    const showExplanations = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.showExplanations);

    const icon = state ? <SvgIconKnowledge /> : <SvgIconKnowledgeOff />;

    if (!text || !uniqueId) return null;

    if (!showExplanations) return null;

    return (
        <div className="flex-shrink-0 cursor-pointer">
            <Tooltip
                onChange={(open) => {
                    setState(open);
                    if (open) {
                        const all = document.querySelectorAll<HTMLElement>(`#id${uniqueId}`);

                        all.forEach((el) => {
                            el.style.animation = "blink 1.5s";
                        });

                        setTimeout(() => {
                            all.forEach((el) => {
                                el.style.animation = "";
                            });
                        }, 1500);
                    }
                }}
                position={smallScreen ? "right" : "left"}
                title={text}
            >
                <div onClick={onClick} className="">
                    {icon}
                </div>
            </Tooltip>
        </div>
    );
}
