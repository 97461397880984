import { Layers } from "apps/legal-ide/features/Field";
import { SELECT_CUSTOM_OTHER_DB_VALUE } from "consts";
import parse from "html-react-parser";
import { PFile } from "proto/contracts/PUtils";
import { PDoc, PDocStatus } from "proto/PDoc";
import { PUser } from "proto/PUser";
import { createRef, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RenderElementProps } from "slate-react";
import { useAppSelector } from "store";
import { callDialog, setIsDocPreviewOpen } from "store/mainActions";
import { DialogType, MainStore } from "store/mainTypes";
import { AgreementContext } from "../../components/WizardUi/AgreementContext/AgreementContext";
import { FieldType, InputElement } from "../types/custom-types";
import PDFViewer from "../PDFViewer";
import { getSearchParams } from "utils";
import { safeArray } from "components/utils/UtilFunctions";
import { useMedia } from "hooks";
import { formatAddress, formatNumber } from "common";
import { createTableDefaultValue, CTable, tableConvertor } from "components/0_common/Table";

// predefined user data fields
export const nameField: Field = {
    id: "name",
    type: FieldType.STRING,
    name: "name",
    optionCounter: 1,
}; //contract
export const addressField: Field = { id: "address", type: FieldType.ADDRESS, name: "address", optionCounter: 1 }; //contract
export const usStateField: Field = { id: "usState", type: FieldType.STRING, name: "usState", optionCounter: 1 }; //contract
export const fullNameField: Field = { id: "fullName", type: FieldType.STRING, name: "fullName", optionCounter: 1 }; //user - SHARED_WITH_CONTEXT
export const titleField: Field = { id: "title", type: FieldType.STRING, name: "title", optionCounter: 1 }; //user - SHARED_WITH_CONTEXT
export const emailField: Field = { id: "email", type: FieldType.STRING, name: "email", optionCounter: 1 }; //user - SHARED_WITH_CONTEXT
export const accountNameField: Field = {
    id: "accountName",
    type: FieldType.STRING,
    name: "accountName",
    optionCounter: 1,
}; //user - SHARED_WITH_CONTEXT
export const companyFullName: Field = {
    id: "companyFullName",
    type: FieldType.STRING,
    name: "companyFullName",
    optionCounter: 1,
}; //user - SHARED_WITH_CONTEXT

export const docStatusField: Field = { id: "status", type: FieldType.STRING, name: "status", optionCounter: 1 };
export const docAccountNameField: Field = {
    id: "accountName",
    type: FieldType.STRING,
    name: "accountName",
    optionCounter: 1,
};
export const docCompanyFullName: Field = {
    id: "companyFullName",
    type: FieldType.STRING,
    name: "companyFullName",
    optionCounter: 1,
};

export const docFields = [docStatusField, docAccountNameField, docCompanyFullName];

export const userFields = [
    nameField,
    addressField,
    usStateField,
    fullNameField,
    emailField,
    accountNameField,
    companyFullName,
    titleField,
];

const PIPES_MAP: { [key: string]: (text: string | undefined) => string | undefined } = {
    UPPERCASE: (value?: string) => {
        if (!value) {
            return value;
        }

        return value?.toUpperCase();
    },
    LOWERCASE: (value?: string) => {
        if (!value) {
            return value;
        }

        return value?.toLowerCase();
    },
    FIRST: (value?: string) => {
        if (!value) {
            return value;
        }

        return value.split(" ")[0];
    },
    LAST: (value?: string) => {
        if (!value) {
            return value;
        }

        return value.split(" ").slice(-1)[0];
    },
    DURATION_TO_NUMBER: (value?: string) => {
        if (value === "monthly") return "1";
        if (value === "quarterly") return "3";
        if (value === "annually") return "12";
        return value;
    },
    MULTIPLY_ONE_AND_A_HALF: (value?: string) => {
        const num = Number(value);
        return num * 1.5 + "";
    },
    OPPOSITE_PERCENTAGE: (value?: string) => {
        if (!value) {
            return value;
        }

        const num = Number(value?.replaceAll("%", ""));
        return 100 - num + "%";
    },
};

const numericalPipes = ["MULTIPLY_ONE_AND_A_HALF"];
const textPipes = ["UPPERCASE", "LOWERCASE", "FIRST", "LAST", "DURATION_TO_NUMBER", "OPPOSITE_PERCENTAGE"];
export const validPipes = [...numericalPipes, ...textPipes];

export default function Fillable(props: {
    field: Field;
    layer?: InputElement["layer"];
    hasABefore?: boolean;
    pipes?: string[];
}) {
    const { field, layer, pipes = [], hasABefore } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const filteredTextPipes = pipes.filter((p) => textPipes.includes(p));
    const mappedTextPipes = filteredTextPipes.map((pipe) => PIPES_MAP[pipe]);
    const filteredNumericalPipes = pipes.filter((p) => numericalPipes.includes(p));
    const mappedNumericalPipes = filteredNumericalPipes.map((pipe) => PIPES_MAP[pipe]);

    const showExplanations = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.showExplanations);

    const history = useHistory();

    const agreementContext = useContext(AgreementContext);
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement!);

    const { wizard } = currentAgreement;
    let activeDraft = agreementContext?.getActiveDraft();

    let whereTolook = agreementContext?.wizardApi.getCurrentContract(activeDraft); //default

    const smallScreen = useMedia({ max: "lg" });

    if (layer === Layers.DOC) {
        whereTolook = activeDraft;
    } else if (layer === Layers.OWNER) {
        whereTolook = activeDraft?.permanentContext?.ownerUser?.user ?? dbUser;
    } else if (layer === Layers.OTHER) {
        whereTolook = activeDraft?.permanentContext?.sharedWithUser?.user;
        if (activeDraft?.docStatus === PDocStatus.SENT && whereTolook?.email === dbUser?.email) {
            whereTolook = dbUser;
        }
    }

    if (!whereTolook) return null;

    let fieldPath = field?.name;
    let value = whereTolook[fieldPath];

    //TODO:
    // let withFunction = props.withFunction;
    // if (!withFunction) {
    //     withFunction = (x: any) => x;
    // }

    let isProfile = layer === Layers.OWNER;

    // then we try in PermanentContext

    function goto() {
        if (activeDraft?.docStatus !== PDocStatus.DRAFT) {
            return;
        }

        if (agreementContext?.navigation.goToQuestionByFieldName(field.name)) {
            if (smallScreen) {
                dispatch(setIsDocPreviewOpen(false));
            }
        } else if (isProfile) {
            dispatch(
                callDialog(
                    DialogType.CONFIRM,
                    "This was set by your profile. Do you want to see where? (You can always come back here by clicking the back arrow).",
                    (answer: boolean) => {
                        if (answer) {
                            history.push("/settings");
                        }
                    }
                )
            );
        }
    }

    // TODO: we will handle this as part of layers.
    // const implicitChoice =
    //     !isProfile && (layer || !agreementContext?.wizardApi.getCurrentContract(activeDraft)[field.name]);

    const isPrintMode = !showExplanations;
    const hideUnderline = currentAgreement.docShareType === "DOCUMENT" && isPrintMode;

    if (value) {
        if (field.type === FieldType.DATE) {
            value = new Date(value).toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric" });
        }

        if (field.type === FieldType.ENUM) {
            const ops = field.options || [];
            if (value.optionDbValue === SELECT_CUSTOM_OTHER_DB_VALUE) {
                value = value.other;
            } else {
                const option = ops?.find((opt) => opt.dbValue === value.optionDbValue);
                value = option?.label;
            }
        }

        if (field.type === FieldType.ADDRESS) {
            value = formatAddress(value);
        }

        if (field.type === FieldType.NUMBER) {
            const numericalValue = mappedNumericalPipes?.reduce((a, f) => f(a), value);

            value = formatNumber(numericalValue, {
                prefixSymbol: field.numberProperties?.prefixSymbol,
                suffixSymbol: field.numberProperties?.suffixSymbol,
            });
        }
    }

    const displayValue = mappedTextPipes?.reduce((a, f) => f(a), value);

    if (field.type === FieldType.TABLE) {
        return (
            <CTable
                onDataChange={(data) => {
                    agreementContext?.handleAnswerByField({
                        field,
                        value: tableConvertor.to(data),
                    });
                }}
                data={value ? tableConvertor.from(value) : createTableDefaultValue(field.tableProperties)}
                options={{
                    row: {
                        unlimited: field.tableProperties?.unlimitedRows,
                        hideActions: isPrintMode,
                        heading: field.tableProperties?.rowHeading,
                        defaultRows: field?.tableProperties?.rows,
                    },
                    columns: field.tableProperties?.columns ?? [],
                    footer: { hide: isPrintMode },
                    column: {
                        headingLabel: field.tableProperties?.columnHeading,
                    },
                }}
            />
        );
    }

    return (
        <span>
            {aBeforeValue(field, hasABefore, value)}
            <span
                onClick={goto}
                className={isPrintMode ? "text-accent" : "text-primary cursor-pointer"}
                style={hideUnderline ? {} : { textDecoration: "underline" }}
            >
                {value ? (
                    <span>
                        <RenderValue field={field} value={displayValue} />
                    </span>
                ) : (
                    <span>{parse("&nbsp;".repeat(20))}</span>
                )}
            </span>
        </span>
    );
}

function RenderValue({ field, value }: { field: Field; value: any }) {
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const he = currentAgreement?.lang === "HE";

    if (field.type === FieldType.EMBEDDED_FILE) {
        const pFile = value as PFile;

        return (
            <div className="w-full h-full">
                <PDFViewer url={pFile.content} />
            </div>
        );
    }

    if (field.type === FieldType.LINK) {
        return (
            <a href={value} target="_blank">
                {value}
            </a>
        );
    }

    if (field.type === FieldType.MULTI_ENUM) {
        const dbValueArray = (value.optionDbValue as number[]) || [];

        return (
            <ul>
                {field.options
                    ?.filter(
                        (opt) => dbValueArray.includes(opt.dbValue) && opt.dbValue !== SELECT_CUSTOM_OTHER_DB_VALUE
                    )
                    .map((opt) => (
                        <li key={opt.dbValue}>{opt.label}</li>
                    ))}
                {!!value.other && <li>{value.other}</li>}
            </ul>
        );
    }

    if (field.type === FieldType.ARRAY) {
        return (
            <ul>
                {safeArray(value).map((v: any) => (
                    <li key={v}>{v}</li>
                ))}
            </ul>
        );
    }

    if (field.type === FieldType.LONG_STRING) {
        return <pre style={{whiteSpace: "pre-wrap",fontFamily: 'Arial'}}>{value}</pre>;
    }

    if (field.type === FieldType.GOVERNING_LAW_STATE) {
        if (he) {
            if (value === "Israel") {
                return "ישראל";
            }
        }
    }

    return value;
}

function aBeforeValue(field: Field, hasABefore: boolean | undefined, value: any) {
    // Currently only states are supported
    //
    const AN_STATES = [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Israel"
    ];

    if (!hasABefore) {
        return "";
    }

    if (!value) {
        return " a ";
    }

    if (field.type != FieldType.GOVERNING_LAW_STATE) {
        return " a ";
    }

    if (AN_STATES.includes(value)) {
        return " an ";
    }

    return " a ";
}

// enables us to look for the field in the permanentContext area if the LegalLayer is not LEGAL_UNIT
function supportPermanentContext(
    layer: LegalLayer,
    activeDraft: PDoc | undefined,
    dbUser: PUser | undefined,
    originalWhereToLook: any
) {
    let whereToLook = originalWhereToLook;

    if (layer === LegalLayer.OWNER_CONTEXT) {
        whereToLook = activeDraft?.permanentContext?.ownerUser?.user;

        if (!whereToLook) {
            if (activeDraft?.docStatus === PDocStatus.DRAFT) {
                whereToLook = dbUser;
            }
        }
    } else if (layer === LegalLayer.SHARED_WITH_CONTEXT) {
        whereToLook = activeDraft?.permanentContext?.sharedWithUser?.user;

        if (
            activeDraft?.docStatus === PDocStatus.SENT &&
            activeDraft.permanentContext?.sharedWithUser?.user?.email === dbUser?.email
        ) {
            whereToLook = dbUser;
        }
    }

    return whereToLook;
}

const periods = ["years", "months", "weeks", "days", "hours", "minutes", "seconds"];

export function adjustApostropheForPluralVsSingularInWizard(suffixSymbol: string | undefined) {
    if (!suffixSymbol) {
        return "";
    }

    let result = suffixSymbol;

    periods.forEach((period) => {
        const periodWithApostrophe = period + "'";

        if (result.includes(periodWithApostrophe)) {
            result = result.replace(periodWithApostrophe, period);
        }
    });

    return result;
}

export enum LegalLayer {
    LEGAL_UNIT,
    OWNER_CONTEXT,
    SHARED_WITH_CONTEXT,
}

export const InputComponent = ({ attributes, children, element }: RenderElementProps) => {
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement)!;

    if (element.type !== "input") return null;

    // const field = currentAgreement.fields.find((field) => field.id === (element as any as InputElement)?.fieldId);

    let field: Field | undefined;
    if (element.layer === Layers.OTHER || element.layer === Layers.OWNER)
        field = userFields.find((f) => f.id == element.fieldId);
    else if (element.layer === Layers.DOC) field = docFields.find((f) => f.id == element.fieldId);
    else field = currentAgreement.fields.find((f) => f.id === element.fieldId);

    if (!field) return null;

    return (
        <span {...attributes}>
            <span contentEditable={false}>
                <ScrollableFillable field={field} element={element} isFromCondition={false} />
                {children}
            </span>
        </span>
    );
};

let lastScroll = 0;
let lastScrollFieldId = "";

export const ScrollableFillable = ({
    field,
    element,
    isFromCondition,
}: {
    field: Field;
    element: InputElement;
    isFromCondition: boolean;
}) => {
    const ref = createRef<HTMLDivElement>();
    const agreementContext = useContext(AgreementContext);

    const searchParams = getSearchParams();

    const activeField = searchParams.get("field");

    const isActive = activeField === field.name;
    const currentStep = agreementContext?.navigation.getCurrentStep();
    const questions = agreementContext?.wizardApi.getFilteredQuestionsForStep(currentStep);
    const firstQuestionInCurrentStep = questions?.[0];

    // we should also be dependent on the legal unit, because a change in the legal unit will change the fillable content
    const legalUnit = agreementContext?.getActiveDraft()?.legalUnit;

    useEffect(() => {
        // no fillable scrolling for non-drafts
        if (agreementContext?.getActiveDraft()?.docStatus !== PDocStatus.DRAFT) {
            return;
        }

        //todo: firstQuestionInCurrentStep.fieldId === field.id why we need this
        if ((activeField && isActive) || firstQuestionInCurrentStep?.fieldId === field.id) {
            // todo - heal this. Supposed to scroll to the first occurrence of fillable
            if (lastScroll < Date.now() - 40 || lastScrollFieldId !== field.id) {
                lastScroll = Date.now();
                lastScrollFieldId = field.id;

                ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [activeField, isActive, firstQuestionInCurrentStep, field.id, legalUnit]);

    if (isFromCondition) {
        return <span ref={ref} />;
    }

    return (
        <span ref={ref}>
            <Fillable layer={element.layer} hasABefore={element.hasABefore} pipes={element.pipes} field={field} />
        </span>
    );
};
