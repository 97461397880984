/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EPhotoReleaseEntityType {
  PhotoRelease_entityType_NA = 0,
  PhotoRelease_entityType_CORPORATION = 1,
  PhotoRelease_entityType_LIMITED_LIABILITY_COMPANY = 2,
  PhotoRelease_entityType_SOLE_PROPRIETORSHIP = 3,
  PhotoRelease_entityType_LIMITED_PARTNERSHIP = 4,
  PhotoRelease_entityType_PROFESSIONAL_CORPORATION = 5,
  PhotoRelease_entityType_PROFESSIONAL_ASSOCIATION = 6,
  PhotoRelease_entityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  PhotoRelease_entityType_NONPROFIT_CORPORATION = 8,
  PhotoRelease_entityType_UNINCORPORATED_ASSOCIATION = 9,
  UNRECOGNIZED = -1,
}

export function ePhotoReleaseEntityTypeFromJSON(object: any): EPhotoReleaseEntityType {
  switch (object) {
    case 0:
    case "PhotoRelease_entityType_NA":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_NA;
    case 1:
    case "PhotoRelease_entityType_CORPORATION":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_CORPORATION;
    case 2:
    case "PhotoRelease_entityType_LIMITED_LIABILITY_COMPANY":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "PhotoRelease_entityType_SOLE_PROPRIETORSHIP":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "PhotoRelease_entityType_LIMITED_PARTNERSHIP":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_LIMITED_PARTNERSHIP;
    case 5:
    case "PhotoRelease_entityType_PROFESSIONAL_CORPORATION":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "PhotoRelease_entityType_PROFESSIONAL_ASSOCIATION":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "PhotoRelease_entityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "PhotoRelease_entityType_NONPROFIT_CORPORATION":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_NONPROFIT_CORPORATION;
    case 9:
    case "PhotoRelease_entityType_UNINCORPORATED_ASSOCIATION":
      return EPhotoReleaseEntityType.PhotoRelease_entityType_UNINCORPORATED_ASSOCIATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPhotoReleaseEntityType.UNRECOGNIZED;
  }
}

export function ePhotoReleaseEntityTypeToJSON(object: EPhotoReleaseEntityType): string {
  switch (object) {
    case EPhotoReleaseEntityType.PhotoRelease_entityType_NA:
      return "PhotoRelease_entityType_NA";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_CORPORATION:
      return "PhotoRelease_entityType_CORPORATION";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_LIMITED_LIABILITY_COMPANY:
      return "PhotoRelease_entityType_LIMITED_LIABILITY_COMPANY";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_SOLE_PROPRIETORSHIP:
      return "PhotoRelease_entityType_SOLE_PROPRIETORSHIP";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_LIMITED_PARTNERSHIP:
      return "PhotoRelease_entityType_LIMITED_PARTNERSHIP";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_CORPORATION:
      return "PhotoRelease_entityType_PROFESSIONAL_CORPORATION";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_ASSOCIATION:
      return "PhotoRelease_entityType_PROFESSIONAL_ASSOCIATION";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "PhotoRelease_entityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_NONPROFIT_CORPORATION:
      return "PhotoRelease_entityType_NONPROFIT_CORPORATION";
    case EPhotoReleaseEntityType.PhotoRelease_entityType_UNINCORPORATED_ASSOCIATION:
      return "PhotoRelease_entityType_UNINCORPORATED_ASSOCIATION";
    case EPhotoReleaseEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MPhotoReleaseEntityType {
  optionDbValue: EPhotoReleaseEntityType;
  other: string;
}

export interface PhotoRelease {
  companyFull: string;
  companyState: string;
  entityType: MPhotoReleaseEntityType | undefined;
  companyShort: string;
  subjectName: string;
  subjectAddress: string;
  minorName: string;
  minorSubject: InternalBoolean;
}

function createBaseMPhotoReleaseEntityType(): MPhotoReleaseEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MPhotoReleaseEntityType = {
  encode(message: MPhotoReleaseEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPhotoReleaseEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPhotoReleaseEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPhotoReleaseEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? ePhotoReleaseEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPhotoReleaseEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = ePhotoReleaseEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPhotoReleaseEntityType>, I>>(base?: I): MPhotoReleaseEntityType {
    return MPhotoReleaseEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPhotoReleaseEntityType>, I>>(object: I): MPhotoReleaseEntityType {
    const message = createBaseMPhotoReleaseEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBasePhotoRelease(): PhotoRelease {
  return {
    companyFull: "",
    companyState: "",
    entityType: undefined,
    companyShort: "",
    subjectName: "",
    subjectAddress: "",
    minorName: "",
    minorSubject: 0,
  };
}

export const PhotoRelease = {
  encode(message: PhotoRelease, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.entityType !== undefined) {
      MPhotoReleaseEntityType.encode(message.entityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.companyShort !== "") {
      writer.uint32(34).string(message.companyShort);
    }
    if (message.subjectName !== "") {
      writer.uint32(42).string(message.subjectName);
    }
    if (message.subjectAddress !== "") {
      writer.uint32(50).string(message.subjectAddress);
    }
    if (message.minorName !== "") {
      writer.uint32(58).string(message.minorName);
    }
    if (message.minorSubject !== 0) {
      writer.uint32(64).int32(message.minorSubject);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhotoRelease {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhotoRelease();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.entityType = MPhotoReleaseEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.subjectName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.subjectAddress = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.minorName = reader.string();
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.minorSubject = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhotoRelease {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      entityType: isSet(object.entityType) ? MPhotoReleaseEntityType.fromJSON(object.entityType) : undefined,
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      subjectName: isSet(object.subjectName) ? String(object.subjectName) : "",
      subjectAddress: isSet(object.subjectAddress) ? String(object.subjectAddress) : "",
      minorName: isSet(object.minorName) ? String(object.minorName) : "",
      minorSubject: isSet(object.minorSubject) ? internalBooleanFromJSON(object.minorSubject) : 0,
    };
  },

  toJSON(message: PhotoRelease): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.entityType !== undefined &&
      (obj.entityType = message.entityType ? MPhotoReleaseEntityType.toJSON(message.entityType) : undefined);
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.subjectName !== undefined && (obj.subjectName = message.subjectName);
    message.subjectAddress !== undefined && (obj.subjectAddress = message.subjectAddress);
    message.minorName !== undefined && (obj.minorName = message.minorName);
    message.minorSubject !== undefined && (obj.minorSubject = internalBooleanToJSON(message.minorSubject));
    return obj;
  },

  create<I extends Exact<DeepPartial<PhotoRelease>, I>>(base?: I): PhotoRelease {
    return PhotoRelease.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PhotoRelease>, I>>(object: I): PhotoRelease {
    const message = createBasePhotoRelease();
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.entityType = (object.entityType !== undefined && object.entityType !== null)
      ? MPhotoReleaseEntityType.fromPartial(object.entityType)
      : undefined;
    message.companyShort = object.companyShort ?? "";
    message.subjectName = object.subjectName ?? "";
    message.subjectAddress = object.subjectAddress ?? "";
    message.minorName = object.minorName ?? "";
    message.minorSubject = object.minorSubject ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
