import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { PDocStatus, PDoc } from "../proto/PDoc";
import {
    ActionTypes,
    ACTIVATE_DRAFT,
    ADD_DRAFT,
    BACKUP_VIDEO_TIME,
    CALL_DIALOG,
    CHANGE_ALREADY_SIGNED,
    DRAFTS_LOADED,
    MARK_LAST_STEP,
    PLAY_VIDEO,
    REGISTER_DOC_NAMER,
    REGISTER_SIGN_CANVAS,
    RENAME_LEGAL_UNIT,
    SET_IS_DOC_PREVIEW_OPEN,
    SET_WIZARD_NAVIGATION_OPEN,
    SET_SHOW_EXPLANATIONS,
    SET_SIDEBAR_OPEN,
    TOGGLE_SIDEBAR,
    UPDATE_ACTIVE_CONTRACT,
    SET_DB_USER,
    SET_PLAN_STATE,
    SET_PLAN,
    SET_PAYMENT_PERIOD,
    UPDATE_ACTIVE_DRAFT,
    UPDATE_ACTIVE_DRAFT_STATUS,
    SET_DECISION_SUPPORTING_TOOL_ALERT,
    UPDATE_ACTIVE_DRAFT_TEMPLATE,
} from "./mainActions";
import { MainStore } from "./mainTypes";
import capTableReducer from "./cap-table-store/capTableStore";

import {
    agreementManagerSlice,
    currentContractSlice,
    legalIdeSlice,
    notificationsSlice,
    uiSlice,
} from "./features";
import { timeTrackingSlice } from "./features/notifications/timeTracking";
import { chatbotSlice } from "./features/chatbot";
import { locaStorageMiddleware } from "./middleware";

const initialStore: MainStore = {
    planState: undefined,
    plan: undefined,
    dbUser: undefined,
    lastKnownVideoTime: 0,
    videoPlayer: undefined,
    pendingShareWithEmail: "",
    sideBarOpen: false,
    dialog: undefined,
    signCanvases: [],
    alreadySigned: [],
    docNamerRef: undefined,
    activeDocId: undefined,
    isSideBarOpen: false,
    wizardNavigationOpen: true,
    draftsWereLoaded: false,
    drafts: [],
    sentDocs: [],
    inboxDocs: [],
    completedDocs: [],
    uploadedDocs: [],
    showExplanations: true,
    isDocPreviewOpen: true,
    isPaymentYearly: false,
    decisionSupportingToolAlert: undefined,
};

function mainReducer(state: MainStore = initialStore, action: ActionTypes): MainStore {
    switch (action.type) {
        case TOGGLE_SIDEBAR: {
            return { ...state, isSideBarOpen: !state.isSideBarOpen };
        }

        case DRAFTS_LOADED: {
            const docs = action.payload.sort((draft1, draft2) => {
                return (
                    (draft2.legalUnit?.createdAt?.getTime() || 0) -
                    (draft1.legalUnit?.createdAt?.getTime() || 0)
                );
            });

            let actualDrafts = docs.filter((draft: PDoc) => draft.docStatus === PDocStatus.DRAFT);
            let myAccountName = state.dbUser?.accountName || "";

            const sentDocs = docs.filter((draft: PDoc) => {
                return (
                    (draft.docStatus === PDocStatus.SENT &&
                        createdByMyAccount(myAccountName, draft.id)) ||
                    (draft.docStatus === PDocStatus.INBOX &&
                        !createdByMyAccount(myAccountName, draft.id))
                );
            });

            const inboxDocs = docs.filter((draft: PDoc) => isInMyInbox(draft, myAccountName));

            const completedDocs = docs.filter(
                (draft: PDoc) => draft.docStatus === PDocStatus.COMPLETED
            );

            const uploadedDocs = docs.filter(
                (draft: PDoc) => draft.docStatus === PDocStatus.UPLOADED
            );

            return {
                ...state,
                draftsWereLoaded: true,
                drafts: actualDrafts,
                sentDocs: sentDocs,
                inboxDocs: inboxDocs,
                completedDocs: completedDocs,
                uploadedDocs: uploadedDocs,
            };
        }

        case ADD_DRAFT: {
            return { ...state, drafts: [...state.drafts, action.payload] };
        }

        case ACTIVATE_DRAFT: {
            return { ...state, activeDraft: action.payload };
        }

        case SET_IS_DOC_PREVIEW_OPEN: {
            return { ...state, isDocPreviewOpen: action.payload };
        }

        case UPDATE_ACTIVE_DRAFT_TEMPLATE: {
            let drafts = [...state.drafts];

            if (!state.activeDraft) return state;

            if (state.activeDraft) {
                drafts = drafts.filter((draft) => draft.id !== state.activeDraft?.id);

                let newDraft: PDoc = { ...state.activeDraft, templateId: action.payload };

                drafts.push(newDraft);
            }

            return {
                ...state,
                activeDraft: {
                    ...state.activeDraft,
                    templateId: action.payload,
                },
                drafts: drafts,
            };
        }

        case UPDATE_ACTIVE_DRAFT: {
            let drafts = [...state.drafts];

            if (state.activeDraft) {
                drafts = drafts.filter((draft) => draft.id !== state.activeDraft?.id);

                let newDraft: PDoc = { ...state.activeDraft, legalUnit: action.payload };

                drafts.push(newDraft);
            }

            return {
                ...state,
                activeDraft: {
                    docStatus: PDocStatus.UNRECOGNIZED,
                    id: "",
                    accountName: "",
                    templateId: "",
                    permanentContext: undefined,
                    ...state.activeDraft,
                    legalUnit: action.payload,
                },
                drafts: drafts,
            };
        }

        case UPDATE_ACTIVE_DRAFT_STATUS: {
            if (!state.activeDraft) {
                return { ...state };
            }

            let drafts = [...state.drafts];

            drafts = drafts.filter((draft) => draft.id !== state.activeDraft?.id);

            let newDraft: PDoc = { ...state.activeDraft, docStatus: action.payload };

            drafts.push(newDraft);

            return {
                ...state,
                activeDraft: newDraft,
                drafts: drafts,
            };
        }

        case UPDATE_ACTIVE_CONTRACT: {
            let drafts = [...state.drafts];

            if (state.activeDraft) {
                drafts = drafts.filter((draft) => draft.id !== state.activeDraft?.id);

                let newDraft: PDoc = { ...state.activeDraft, legalUnit: action.payload };

                drafts.push(newDraft);
            }

            return {
                ...state,
                activeDraft: {
                    docStatus: PDocStatus.UNRECOGNIZED,
                    id: "",
                    accountName: "",
                    permanentContext: undefined,
                    ...state.activeDraft,
                    legalUnit: action.payload,
                    templateId: "",
                },
                drafts: drafts,
            };
        }

        case SET_SHOW_EXPLANATIONS: {
            return { ...state, showExplanations: action.payload };
        }

        case SET_WIZARD_NAVIGATION_OPEN: {
            return { ...state, wizardNavigationOpen: action.payload };
        }

        case RENAME_LEGAL_UNIT: {
            if (!state.activeDraft) {
                return { ...state };
            }

            let newDraft: PDoc = { ...state.activeDraft };
            newDraft.legalUnit = { ...newDraft.legalUnit!, name: action.payload };

            return { ...state, activeDraft: newDraft };
        }

        case MARK_LAST_STEP: {
            if (!state.activeDraft) {
                return { ...state };
            }

            let newDraft: PDoc = { ...state.activeDraft };
            newDraft.legalUnit = { ...newDraft.legalUnit!, lastStep: action.payload };

            return { ...state, activeDraft: newDraft };
        }

        case SET_DB_USER: {
            return { ...state, dbUser: action.payload };
        }

        case SET_PLAN_STATE: {
            return { ...state, planState: action.payload };
        }

        case SET_PLAN: {
            return { ...state, plan: action.payload };
        }

        case REGISTER_SIGN_CANVAS: {
            if (action.payload === undefined) {
                return { ...state, signCanvases: [] };
            }

            const signCanvases = [...state.signCanvases];
            signCanvases[action.payload.count] = action.payload.signCanvas;

            return { ...state, signCanvases: signCanvases };
        }

        case CHANGE_ALREADY_SIGNED: {
            let newAlreadySigned = [...state.alreadySigned];
            newAlreadySigned[action.payload.index] = action.payload.added;

            return { ...state, alreadySigned: newAlreadySigned };
        }

        case REGISTER_DOC_NAMER: {
            return { ...state, docNamerRef: action.payload as unknown as HTMLInputElement };
        }

        case CALL_DIALOG: {
            return { ...state, dialog: action.payload };
        }

        case SET_SIDEBAR_OPEN: {
            return { ...state, sideBarOpen: action.payload };
        }

        case PLAY_VIDEO: {
            return { ...state, videoPlayer: action.payload };
        }

        case BACKUP_VIDEO_TIME: {
            return { ...state, lastKnownVideoTime: action.payload };
        }

        case SET_PAYMENT_PERIOD: {
            return { ...state, isPaymentYearly: action.payload };
        }

        case SET_DECISION_SUPPORTING_TOOL_ALERT: {
            return { ...state, decisionSupportingToolAlert: action.payload };
        }

        // todo warn here

        default:
            return { ...state };
    }
}

export const rootReducer = combineReducers({
    mainReducer: mainReducer,
    capTableReducer,
    agreementManager: agreementManagerSlice.reducer,
    currentContract: currentContractSlice.reducer,
    ui: uiSlice.reducer,
    legalIde: legalIdeSlice.reducer,
    notifications: notificationsSlice.reducer,
    timeTracking: timeTrackingSlice.reducer,
    chatbot: chatbotSlice.reducer,
});
const store = configureStore({
    reducer: rootReducer,
    middleware: [locaStorageMiddleware],
    devTools: {
        // todo: fix devtolls crash
        // stateSanitizer: (state) => (state.data ? { ...state, data: "<<LONG_BLOB>>" } : state),
        // actionSanitizer: (action: any): any => {
        //     const length = JSON.stringify(action)?.length;
        //     if (length > 100000) {
        //         return { type: action.type, payload: "Pylaod to logn..." };
        //     }
        //     return action;
        // },
    },
});

export default store;

export function createdByMyAccount(myAccountName?: string, docId?: string): boolean {
    let ownerAccount = docId?.split("/")[0];

    return myAccountName === ownerAccount;
}

export function isInMyInbox(draft: PDoc | undefined, myAccountName: string | undefined) {
    if (!myAccountName || !draft) {
        return false;
    }

    return (
        (draft.docStatus === PDocStatus.INBOX && createdByMyAccount(myAccountName, draft.id)) ||
        (draft.docStatus === PDocStatus.SENT && !createdByMyAccount(myAccountName, draft.id))
    );
}
