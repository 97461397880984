import { match } from "assert";
import { InstanceOf } from "reselect/es/types";

interface RegexStrategy {
    test: (text: string) => boolean;
    match?: (text: string) => any;
    matchAll?: (text: string) => any;
}

export class FillableStrategy implements RegexStrategy {
    private regex: RegExp;
    private regexAll: RegExp;
    constructor() {
        // this.regex = /( a )?\[\[([\[\[([a-zA-Z0-9]*)\s*(\|\s*([a-zA-Z0-9]+)\s*)?\]\]/;
        // this.regexAll = /( a )?\[\[([\[\[([a-zA-Z0-9]*)\s*(\|\s*([a-zA-Z0-9]+)\s*)?\]\]/g;
        this.regex =
            /( a )?\[\[([a-zA-Z][a-zA-Z0-9]*\.)?([a-zA-Z][a-zA-Z0-9]*\.)?([a-zA-Z][a-zA-Z0-9]*)\s*(\|\s*([a-zA-Z][a-zA-Z0-9_]+)\s*)?\]\]/;
        this.regexAll = new RegExp(this.regex, "g");
    }

    test(text: string) {
        return !!text.match(this.regex);
    }
    match(text: string) {
        const match = text.match(this.regex);
        if (!match) return null;
        return this.getDataFromMatch(match);
    }

    getDataFromMatch(match: RegExpMatchArray) {
        const [fullMatch, prefix, context, subContext, fieldName, _, pipe1] = match;
        const pipes = [];
        pipe1 && pipes.push(pipe1);
        return {
            fieldName: fieldName,
            pipes,
            index: match.index || 0,
            fullMatch,
            prefix,
            context: context ? context.split(".")[0] : context,
            subContext: subContext ? subContext.split(".")[0] : subContext,
        };
    }
    matchAll(text: string) {
        const matches = text.matchAll(this.regexAll);
        const result = [];
        for (const match of matches) {
            result.push(this.getDataFromMatch(match));
        }
        return result;
    }

    getData(text: string) {
        const match = text.match(this.regex);
        if (!match) return { fieldName: "", index: 0, fullMatch: "" };
        const [fullMatch, children] = match;
        return { fieldName: children, index: match.index || 0, fullMatch };
    }
}

export class GptSplitStrategy implements RegexStrategy {
    private regex: RegExp;
    constructor() {
        this.regex = /\{{gpt-split\}\}/;
    }

    test(text: string) {
        return !!text.match(this.regex);
    }
    match(text: string) {
        const match = text.match(this.regex);

        if (!match) return null;

        const [fullMatch] = match;
        return { fullMatch, matchIndex: match.index || 0 };
    }

    getData(text: string) {
        const match = text.match(this.regex);
        if (!match) return { children: "", index: 0, fullMatch: "" };
        const [fullMatch, children] = match;
        return { children, index: match.index || 0, fullMatch };
    }
}

export class SignatureStrategy implements RegexStrategy {
    private regex: RegExp;
    constructor() {
        this.regex = /\{{signature\}\}/;
    }

    test(text: string) {
        return !!text.match(this.regex);
    }
    match(text: string) {
        const match = text.match(this.regex);

        if (!match) return null;

        const [fullMatch] = match;
        return { fullMatch, matchIndex: match.index || 0 };
    }

    getData(text: string) {
        const match = text.match(this.regex);
        if (!match) return { children: "", index: 0, fullMatch: "" };
        const [fullMatch, children] = match;
        return { children, index: match.index || 0, fullMatch };
    }
}

export class PageBreakStrategy implements RegexStrategy {
    private regex: RegExp;
    constructor() {
        this.regex = /\{{pageBreak\}\}/;
    }

    test(text: string) {
        return !!text.match(this.regex);
    }

    getData(text: string) {
        const match = text.match(this.regex);
        if (!match) return { children: "", index: 0, fullMatch: "" };
        const [fullMatch, children] = match;
        return { children, index: match.index || 0, fullMatch };
    }
}

// todo: new location in Contract/Syntax
export const MULTI_SELECTION_OPERATORS = {
    ALL: "all",
    SOME: "some",
    NOT_ALL: "not all",
    NOT_SOME: "not some",
    IS_EMPTY: "is empty",
    NOT_EMPTY: "not empty",
};
export const MULTI_SELECTION_OPERATORS_ARR = Object.values(MULTI_SELECTION_OPERATORS);
export const MULTI_SELECTION_OPERATORS_REGEX = MULTI_SELECTION_OPERATORS_ARR.join("|");

export class ConditionalStrategy implements RegexStrategy {
    private regex: RegExp;
    private regexAll: RegExp;

    constructor() {
        this.regex = new RegExp(
            `<<if \\s*([A-Za-z]+)\\s*(==|!=|${MULTI_SELECTION_OPERATORS_REGEX})\\s*(true|false|[A-Z][A-Z0-9_,\\s*]*)?\\s*>>|(<<\\/if>>\\s*)`,
            ""
        );
        this.regexAll = new RegExp(this.regex, "g");
    }

    test(text: string) {
        return !!text.match(this.regexAll);
    }

    match(text: string) {
        const match = text.match(this.regex);

        if (!match) return null;

        const [fullMatch, fieldName, operator, value, endIfBlock] = match;
        return { fullMatch, fieldName, operator, value, endIfBlock, matchIndex: match.index || 0 };
    }

    matchAll(text: string) {
        const matches = text.matchAll(this.regexAll);
        const result = [];

        for (const match of matches) {
            const [fullMatch, fieldName, operator, value, endIfBlock] = match;
            result.push({ fullMatch, fieldName, operator, value, endIfBlock, matchIndex: match.index || 0 });
        }

        return result;
    }
}
