interface DropDownOption {
    label: string;
    value: string;
}
interface DropDownProps {
    name: string;
    value: string;
    placeholder?: string;
    required?: boolean;
    options: DropDownOption[];
    onChange: (name: string, value: string) => void;
}

export const DropDown = (props: DropDownProps) => {
    const { value, options, name, placeholder = "select item", required, onChange } = props;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        onChange(name, value);
    };
    return (
        <select required={required} className="border rounded py-2 px-4 bg-primary text-white" value={value} onChange={handleChange}>
            <option value="">{placeholder}</option>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};
