import { AxiosResponse } from "axios";
import { isProd } from "components/utils/EnvResolver";
import { GTMHelper } from "components/utils/GTMHelper";
import mixpanel from "mixpanel-browser";
import { PLogoSettings, PUser, PUserStatus, PUserSubscriptionType } from "proto/PUser";
import { setDBUser } from "store/mainActions";
import { PGetUserResponse, PPostUserRequest, PValidateAccountRequest } from "../proto/PApi";
import { request } from "./ApiCommon";

export function getVideoUrl(callback: Function) {
    request("/api/v1/checklist", { method: "GET" })
        ?.then((response) => {
            callback(response.data);
        })
        .catch((e: any) => {}); // todo
}

export function watchedSomeOfChecklist() {
    request("/api/v1/checklist/watched", { method: "POST" })
        ?.then((response) => {})
        .catch((e: any) => {}); // todo
}

export const checklistApi = {
    getVideoUrl,
    watchedSomeOfChecklist,
};