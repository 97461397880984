import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ClawdiaHead2 from "img/clawdia-head2.png";
import { UserImg, userImgUrl } from "../AppBar/Menu";
import { useAuth } from "components/contexts/AuthContext";
import { Actions, Selectors, useAppDispatch, useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { ChatBotMessage, chatbotSlice, selectors } from "store/features/chatbot";
import { useHistory } from "react-router";
import { AgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { GPTApi } from "api-services/gptApi";
import { PAskRequest } from "proto/PApi";
import { PLegalUnit } from "proto/PLegalUnit";
import {
    getAllFilteredQuestions,
    getAllQuestions,
    getCurrentContract,
    isEmpty,
} from "apps/legal-ide/App/components/WizardUi/AgreementContext/shared-api";
import {
    getFieldDisplayValue,
    useWizardApi,
} from "apps/legal-ide/App/components/WizardUi/AgreementContext/useWizardApi";
import { Button } from "components";

export default function ChatBot() {
    return (
        <div className="flex flex-col w-full">
            <ChatMessages />
            <InputField />
        </div>
    );
}

function ChatMessages() {
    const messages = useAppSelector(selectors.selectCurrentMessages);
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <div className="flex flex-col h-full">
            {messages.map((msg) => (
                <Message msg={msg} />
            ))}
            {messages.length === 1 && <div className="h-28"></div>}
            <div ref={ref} className="h-28"></div>
        </div>
    );
}

export function ChatTopics() {
    const dispatch = useDispatch();
    const conversations = useAppSelector((state) => state.chatbot.conversations);
    const currentConversation = useAppSelector((state) => state.chatbot.currentConversation);

    const chatTopicsReverse = [...conversations].reverse();

    return (
        <div className="flex flex-col w-full bg-primaryVeryLight rounded-lg p-3 gap-2">
            <p
                onClick={() => {
                    dispatch(Actions.chatbot.addChatTopic());
                }}
                className="text-darkIndigo  text-base rounded-lg cursor-pointer pl-2 border-2 py-1 border-darkIndigo"
            >
                + New chat
            </p>
            {chatTopicsReverse.map((topic, index) => (
                <div
                    onClick={() => {
                        dispatch(Actions.chatbot.switchChatTopic(topic.id));
                    }}
                    className={`flex flex-row items-center gap-2 hover:bg-white rounded-md p-2 ${
                        currentConversation === topic.id ? "bg-white" : ""
                    }`}
                >
                    <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        className="h-4 w-4"
                        height="1em"
                        width="1em"
                    >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    <p className="text-darkIndigo text-base rounded-lg cursor-pointer">
                        {topic.title}
                    </p>
                </div>
            ))}
        </div>
    );
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}
function extractText() {
    const div = document.querySelectorAll("#Editable > *");

    let lines = Array.from(div);

    let chunks: string[] = [];

    while (true) {
        const index = lines.findIndex((el) => el.querySelector(".gpt-split"));
        if (index === -1) {
            const text = lines
                .map((p) => p.textContent)
                .filter((x) => !!x?.trim())
                .join("\n\n");
            lines = lines.slice(index + 1);
            chunks.push(text);
            break;
        }
        const c = lines.slice(0, index);
        const text = c
            .map((p) => p.textContent)
            .filter((x) => !!x?.trim())
            .join("\n\n");
        lines = lines.slice(index + 1);
        chunks.push(text);
    }

    return chunks;
}

interface Answer {
    answer: string;
    hasAnswer: boolean;
    source: string;
    quote: string;
}
function pickAnswer(answers: string[]): Answer | null {
    try {
        for (const answer of answers) {
            let a = JSON.parse(answer) as Answer;

            if (a.hasAnswer) {
                return a;
            }
        }
        return null;
    } catch (error) {
        return null;
    }
}

function InputField() {
    const ref = React.useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = React.useState(false);
    const history = useHistory();
    const currentChat = useAppSelector(selectors.selectCurrentConversation);

    const agreementContext = useContext(AgreementContext);
    const activeDraft = agreementContext?.getActiveDraft();
    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const wizardApi = useWizardApi({ agreement, draft: activeDraft });

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

    const allQuestions = agreement ? getAllQuestions(agreement) : [];
    const allKbs = allQuestions
        .filter((q) => !!q.kb)
        .map((q) => q.kb)
        .join("\n");

    const userAnswers = useMemo(() => {
        let obj: { [key in Question["text"]]: string } = {};
        const filteredQuestions = getAllFilteredQuestions(
            agreement,
            getCurrentContract(activeDraft)
        );
        filteredQuestions.forEach((q) => {
            const value = wizardApi.getQuestionValue(q);
            const field = agreement?.fields.find((f) => f.id === q.fieldId);
            if (!isEmpty(value) && field) {
                obj[q.text] = getFieldDisplayValue({
                    field,
                    value,
                });
            }
        });
        return obj;
    }, [agreement, activeDraft]);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref.current]);

    function normalizeOutput(a: string): React.ReactNode {
        if (a.trim().startsWith(":")) {
            return a.trim().substring(1).trim();
        }

        return a.trim();
    }

    return (
        <div className="w-full self-center absolute bottom-0 flex py-8">
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    if (!currentChat) return;

                    if (ref.current?.value?.trim() === "") return;

                    const text = ref.current?.value || "";

                    dispatch(
                        Actions.chatbot.add({
                            text: text,
                            isUser: true,
                        })
                    );

                    dispatch(
                        Actions.chatbot.add({
                            text: "",
                            isUser: false,
                            threeDots: true,
                        })
                    );

                    setDisabled(true);

                    setTimeout(() => {
                        setDisabled(false);

                        // Put instructions at the beginning of the prompt and use ### or """ to separate the instruction and context

                        //      6. Avoid things like "as outlined in the contract" if the information is not in this current contract
                        // 8. Forget your previous knowledge of anything legal related, and only answer according to the contract, the assumptions above, and the user profile

                        let prompt = `instructions: act as if you are chatbot. response should be always valid JSON format.
                            1. Answer user questions as if you're name is Clawdia. Clawdia is a legal expert in US for businesses.
                            2. Answer user questions only if answer exists in provided context (contract, user profile, chat history)
                            3. Try to answer as short as possible.
                            4. Try replace symbols with appropriate words when possible.
                            5. try to give US state specific answer.
                            6. Your Response always return valid JSON format:
                            {
                                "hasAnswer": <true or false if answer found in provided context>,
                                "source": "<CONTRACT | PROFILE | CHAT_HISTORY>",
                                "quote": "'<original text source that contain the answer>'",
                                "answer": "<result>",
                            }.

                            7. Answer user questions only if you sure the answer exists in: 1) contract 2) chat history 3) user profile, otherwize return valid json:
                            {
                                "hasAnswer": false,
                                "source": "NONE",
                                "quote": "",
                                "answer": "I don't know!"
                            }.


                            context start ###


                            user profile:
                            ###
                            ${JSON.stringify(dbUser)}
                            ###

                            user answers for contract:
                            ${JSON.stringify(userAnswers)}

                            contract: 
                            ###
                            {contract_replace}
                            ###


                            chat history: 
                            ###
                            ${currentChat?.messages?.reduce((history, message, i) => {
                                if (message.isUser) {
                                    history += "User: " + message.text + "\n";
                                } else {
                                    history += "Clawdia: " + message.text + "\n";
                                }
                                return history;
                            }, "")}
                            ###

                            ### context end 

                        
                        `;

                        prompt += `\n\nUser: ${text}\nClawdia:`;

                        if (activeDraft?.legalUnit) {
                            const askReq = {
                                q: prompt,
                                contract: extractText().concat([allKbs]),
                            } as PAskRequest;
                            GPTApi.ask(
                                askReq,
                                (res) => {
                                    let text = "I don't know";
                                    try {
                                        const result = pickAnswer(res.responses);
                                        if (result?.answer) {
                                            text = result.answer;
                                        }
                                    } catch (error) {
                                        console.error("ERRROR", error);
                                    }
                                    dispatch(
                                        Actions.chatbot.replaceLastThreeDots({
                                            message: {
                                                text: normalizeOutput(text),
                                                isUser: false,
                                            },
                                            conversationId: currentChat.id,
                                        })
                                    );
                                },
                                () => {
                                    dispatch(
                                        Actions.chatbot.replaceLastThreeDots({
                                            message: {
                                                text: " We had problem answering the question. try later..",
                                                isUser: false,
                                            },
                                            conversationId: currentChat.id,
                                        })
                                    );
                                }
                            );
                        } else if (text.toLocaleLowerCase().includes("commerce")) {
                            dispatch(
                                Actions.chatbot.renameCurrentChatTopic("California e-commerce")
                            );

                            dispatch(
                                Actions.chatbot.replaceLastThreeDots({
                                    message: {
                                        text: (
                                            <p>
                                                Certainly! In California, there are a number of laws
                                                that may apply to rewards programs, including the
                                                California Gift Card Act and the California Consumer
                                                Legal Remedies Act. The California Gift Card Act
                                                regulates certain aspects of gift cards and rewards
                                                programs, including prohibiting expiration dates for
                                                rewards within three years of the date they were
                                                earned and requiring that fees for participation in
                                                the rewards program be clearly disclosed to
                                                customers. The California Consumer Legal Remedies
                                                Act prohibits deceptive or misleading practices in
                                                the sale of goods or services.
                                                <br />
                                                <span className="font-semibold">
                                                    have access to our personalized legal tools and
                                                    resources to help you create a tailor-made
                                                    rewards program that complies with all
                                                    applicable laws and meets your specific needs
                                                    and goals
                                                </span>
                                                <div className="mt-2">
                                                    <Button
                                                        onClick={() => {
                                                            history.push("/readme");
                                                        }}
                                                    >
                                                        Create a Tailor-Made Rewards Program
                                                    </Button>
                                                </div>
                                            </p>
                                        ),
                                        isUser: false,
                                    },
                                    conversationId: currentChat.id,
                                })
                            );
                        } else if (text.toLocaleLowerCase().includes("early")) {
                            dispatch(Actions.chatbot.renameCurrentChatTopic("Early termination"));

                            dispatch(
                                Actions.chatbot.replaceLastThreeDots({
                                    message: {
                                        text: "I've reviewed the termination clause in your contract and it appears that either party may terminate the contract upon written notice, \
                                        provided that at least 30 days' notice is given.",
                                        isUser: false,
                                    },
                                    conversationId: currentChat.id,
                                })
                            );
                        } else if (text.toLocaleLowerCase().includes("releasing ")) {
                            dispatch(
                                Actions.chatbot.replaceLastThreeDots({
                                    message: {
                                        text: "After reviewing the terms of your contract, it looks like there is a clause stating that you must deliver any agreed upon deliverables in a timely manner. \
    Withholding work or deliverables until payment has been received could be considered a breach of contract on your part. It's generally not a good idea to do this, \
    as it could damage your professional reputation and potentially lead to legal dispute",
                                        isUser: false,
                                    },
                                    conversationId: currentChat.id,
                                })
                            );
                        } else {
                            dispatch(
                                Actions.chatbot.replaceLastThreeDots({
                                    message: {
                                        text: "I'm sorry, I don't understand. Please try again.",
                                        isUser: false,
                                    },
                                    conversationId: currentChat.id,
                                })
                            );
                        }

                        ref.current?.focus();
                    }, 3000);

                    ref.current!.value = "";
                }}
                className="rounded-md w-1/2 shadow-xl px-auto translate-x-1/2 relative"
            >
                <input
                    autoFocus={true}
                    disabled={disabled}
                    ref={ref}
                    className="py-4 px-4 text-lg block w-full leading-5 rounded-md border outline-none focus:border-gray-400 border-gray-200"
                    type="text"
                    placeholder=""
                />
                <div className="absolute right-10 top-2.5 h-5 w-5">
                    <div className="flex">
                        <UploadFileButton />
                        <SubmitButton />
                    </div>
                </div>
            </form>
        </div>
    );
}

function UploadFileButton() {
    const dispatch = useDispatch();
    const history = useHistory();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const currentChat = useAppSelector(selectors.selectCurrentConversation);

    useEffect(() => {
        if (selectedFile) {
            dispatch(
                Actions.chatbot.add({
                    text: "",
                    isUser: false,
                    threeDots: true,
                })
            );

            setTimeout(() => {
                if (!currentChat) return;
                dispatch(
                    Actions.chatbot.replaceLastThreeDots({
                        message: {
                            text: (
                                <div className="flex flex-col">
                                    <span>
                                        File uploaded successfully. It appears this is a term sheet.
                                        Let's analyze and negotiate the terms together.
                                    </span>
                                    <div className="w-1/2 mt-2">
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                history.push("/negotiator");
                                            }}
                                        >
                                            {"Analyze & Negotiate"}
                                        </Button>
                                    </div>
                                </div>
                            ),
                            isUser: false,
                        },
                        conversationId: currentChat.id,
                    })
                );
            }, 3000);
        }
    }, [selectedFile]);

    return (
        <>
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    fileInputRef?.current?.click();
                }}
                className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
            >
                <svg className="h-4 stroke-gray-500" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="45" fill="none" stroke-width="7.5"></circle>
                    <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="5"></line>
                    <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="5"></line>
                </svg>
            </button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => {
                    setSelectedFile(e.target.files?.[0] || null);
                }}
                style={{ display: "none" }}
            />
        </>
    );
}

function SubmitButton() {
    return (
        <button
            type="submit"
            className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
        >
            <svg
                className="w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.59 16.34L13.17 12L8.59 7.66L10 6L16 12L10 18L8.59 16.34Z"
                    fill="currentColor"
                />
            </svg>
        </button>
    );
}

function Message({ msg }: { msg: ChatBotMessage }) {
    const auth = useAuth();

    return (
        <div
            className={`flex flex-row items-center gap-2 px-4 py-4 rounded-t-lg border-b border-gray-300 ${
                msg.isUser ? "bg-white" : "bg-gray-100"
            }`}
        >
            <img
                src={msg.isUser ? userImgUrl(auth) : ClawdiaHead2}
                className="self-start h-16 w-16 rounded-full"
            />
            {msg.threeDots && <LoadingDots />}
            {!msg.threeDots && (
                <p className="text-darkIndigo whitespace-pre-wrap break-normal px-2 py-2 text-lg rounded-lg cursor-pointer">
                    {msg.text}
                </p>
            )}
        </div>
    );
}

const LoadingDots = () => {
    return (
        <div className="flex justify-center items-center gap-2">
            <span className="animate-grow-shrink rounded-full h-1 w-1 bg-primaryHalf"></span>
            <span className="animate-grow-shrink rounded-full h-1 w-1 delay-dot-2 bg-primaryHalf"></span>
            <span className="animate-grow-shrink rounded-full h-1 w-1 delay-dot-3 bg-primaryHalf"></span>
        </div>
    );
};
