import { AxiosResponse } from "axios";
import { PPostFrontendError } from "proto/PApi";
import { PFrontendError } from "proto/PFrontendError";
import { request } from "./ApiCommon";

export function errorHandler(req: PFrontendError) {
    const postMessage = PPostFrontendError.fromJSON({
        feError: req,
    });

    const dataJson = PPostFrontendError.toJSON(postMessage);

    request("/api/v1/frontend-error", { method: "POST", data: dataJson });
}
