import jsonLogic from "json-logic-js";
import { useContext } from "react";
import { useAppSelector } from "store";
import { jsonToCompoundJsonLogic } from ".";
import { AgreementContext } from "../../components/WizardUi/AgreementContext/AgreementContext";

export const Leaf = ({ attributes, children, leaf }: any) => {
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const agreementContext = useContext(AgreementContext);

    let errorStyle: any = {};
    if (leaf.error) {
        errorStyle.textDecoration = "underline wavy red";
    }

    if (leaf.fillable) {
        children = <code style={{ background: "lightgray" }}>{children}</code>;
    }
    if (leaf.error) {
        children = (
            <code title={leaf.message} style={{ textDecoration: "underline wavy red" }}>
                {children}
            </code>
        );
    }
    if (leaf.signature) {
        children = <code style={{ background: "yellow" }}>{children}</code>;
    }

    if (leaf.pageBreak) {
        children = <div style={{ pageBreakAfter: "always" }}>{children}</div>;
    }

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.conditionSyntax) {
        children = <code style={{ color: "red" }}>{children}</code>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.condition) {
        const jsonLogicValue = jsonToCompoundJsonLogic(leaf.condition, currentAgreement?.fields);

        const isValid = jsonLogic.apply(
            jsonLogicValue,
            agreementContext?.wizardApi.getCurrentContract(agreementContext.getActiveDraft())
        );
        if (!isValid) children = "";
        else {
            children = <span>{children}</span>;
        }
    }

    return (
        <span
            {...attributes}
            style={{ ...leaf.style, background: leaf.kb ? "yellow" : "inherit", ...errorStyle }}
            data-kb={leaf.kb}
        >
            {children}
        </span>
    );
};
