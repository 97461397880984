import { oauthApi } from 'api-services/oauthApi';
import { Spinner } from 'components';
import { EmptyState } from 'components/0_common/EmptyState/EmptyState';
import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { GenericOauthHandlingProvider } from './GenericOauthHandlingProviders';

// This component is used to serve as entry point callback which receives the code from the provider and redirects to the next step in the authentication process
export default function GenericOauthHandlingCallback({provider}: {provider: GenericOauthHandlingProvider}) {
  const [code, setCode] = useState<string | null>(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');
    
    setCode(codeParam)
    
    if (codeParam) {
      oauthApi.signInEndpoint(window.location.search, provider.name, (firebaseAaccessToken: string) => {
        firebase.auth().signInWithCustomToken(firebaseAaccessToken).then(() => {
          
          if(provider.getEmbeddedUrl){
            return provider.getEmbeddedUrl();
          }
           window.location.replace('/')
        }
      )
      },
      (error: any) => {
        alert("There was an error with the authentication process. Please try again.\n\nError:" + error)
      })
    } else {

    }
  }, [])

  
  if (!code) {
    return <EmptyState title="Error" description="No code param found in url" />
  }

  return <div className="flex flex-col items-center justify-center h-screen w-screen">
  <Spinner/>
  <p className='text-2xl'>Clawdia AI is authenticating your identity passed from {provider.name.toUpperCase()}</p>
</div>
}
