import { useState } from "react";
import "./TestimonialCarousel.scss";

import CathleenTurnerImg from "img/testimonials/cathleen_turner.png";
import JackTramaImg from "img/testimonials/jack_trama.webp";
import QueenEwudoImg from "img/testimonials/queen_ewudo.png";
import JoseBedoyaImg from "img/testimonials/jose_bedoya.png";
import AdamCohenImg from "img/testimonials/adam_cohen.png";
import JonathanLazovskiImg from "img/testimonials/jonathan.png";
import TalCroitoruImg from "img/testimonials/tal_croitoru.png";
import TomerMarshalImg from "img/testimonials/tomer_marshal.png";
import ShaharGluzmanImg from "img/testimonials/shahar_gluzman.png";
import AvivLandauImg from "img/testimonials/aviv_landau.png";
import EyalKamaImg from "img/testimonials/eyal_kama.png";
import AmitGalImg from "img/testimonials/amit_gal.png";
import OronSnirImg from "img/testimonials/oron_snir.png";
import MaozKainImg from "img/testimonials/maoz_kain.png";
import C4N2Img from "img/testimonials/c4n2.png";
import EricHurtadoImg from "img/testimonials/eric_hurtado.png";
import TroyDavisImg from "img/testimonials/troy_davis.png";

import StarImg from "img/icons/star.png";

interface TestimonialDetails {
    name: string;
    title: string;
    company: string;
    testimonial: string;
    img: string
}

export function TestimonialsCarousel() {
    const testimonials: TestimonialDetails[] = [
        {
            name: "Cathleen Turner",
            title: "Founder",
            company: "CZero Foods",
            testimonial: "Clawdia has been indispensable for our business. We are able to make important legal documents in minutes, allowing us to move quickly and scale efficiently without blockers.",
            img: CathleenTurnerImg
        },
        {
            name: "Ramola Macnair",
            title: "COO",
            company: "c4n2 Agency",
            testimonial: "Thank you for your wonderful legal solution from the past year! We have been able to use it to streamline our legal research processes and help us save time. It has also enabled us to better understand the laws that govern our business. Thank you for your amazing work",
            img: C4N2Img
        },
        {
            name: "Jack Trama",
            title: "Founder",
            company: "",
            testimonial: "When I hire independent contractors I use Clawdia for my confidentiality and work for hire agreements. Super easy platform to use and the agreements are written in such a way that protect all parties involved. Highly recommended and a great platform.",
            img: JackTramaImg,
        },
        {
            name: "Queen Ewudo",
            title: "Founder",
            company: "Catered by Africa",
            testimonial: "There are so many companies that we could of chosen but Clawdia stood out the most with their Saas Agreement template. It was well written and easy to draft to send out to onboard new vendors onto our platform. Clawdia makes Agreements easy to understand for both parties and easy to sign. We would recommend it to anyone.",
            img: QueenEwudoImg,
        },
        {
            name: "Troy Davis",
            title: "CEO",
            company: "Continuity LLC",
            testimonial: "I've been using the Clawdia software and frankly I am thoroughly impressed with the performance. They make creating legal documents, quick and easy, with an emphasis on efficiency. I would highly recommend Clawdia to any business",
            img: TroyDavisImg
        },
        {
            name: "Eric Hurtado",
            title: "CEO",
            company: "The Eric Show",
            testimonial: "After researching for a legal solution for my business - I found Clawdia. I am very pleased with the user interface of the website, the ease of use, the guidance provided and the overall clarity from start to finish.",
            img: EricHurtadoImg
        },
        {
            name: "José Bedoya",
            title: "CEO",
            company: "Gryphen Media",
            testimonial: "I used Clawdia a few days ago for a freelance contract and I was surprised by how fast, easy, and quick it is to use. As a sole proprietor, I have quite the experience with contracts and freelancers. I recommend to everybody to use it, even my clients.",
            img: JoseBedoyaImg,
        },
        {
            name: "Adam Cohen",
            title: "CEO",
            company: "",
            testimonial: "I am a first-time founder. I was lucky enough to come across Clawdia. I can say that I am more than pleased. Clawdia taught me how to approach and understand my legal needs, and I recommend every fellow founder that finds the legal world intimidating, try Clawdia.",
            img: AdamCohenImg,
        },
        {
            name: "Jonathan Lazovski",
            title: "CEO",
            company: "",
            testimonial: "Clawdia covers all the legal issues and questions I didn’t even know I need to take into consideration. Clawdia helped me avoid critical legal mistakes and without her help, I can say for sure – I could have lost my startup.",
            img: JonathanLazovskiImg,
        },
        {
            name: "Dr. Tal Croitoru",
            title: "CEO",
            company: "",
            testimonial: "It sounds dramatic, but Clawdia saved my startup from death! If I had known about Clawdia earlier, I could have saved a lot of time and money while protecting my company from damages.",
            img: TalCroitoruImg
        },
        {
            name: "Tomer Marshal",
            title: "CEO",
            company: "",
            testimonial: "Clawdia is a perfect tool with accurate guidelines waiting for me exactly at the moment I need them. Clawdia leads me and gives me the confidence I need. For the first time, I’m seeing the full picture and making the right decisions.",
            img: TomerMarshalImg
        },
        {
            name: "Shahar Gluzman",
            title: "CEO",
            company: "",
            testimonial: "I heard about Clawdia from a friend. Clawdia is helping me make the best decisions and giving me all the legal tools I need.",
            img: ShaharGluzmanImg,
        },
        {
            name: "Aviv Landau-Artzi",
            title: "CEO",
            company: "",
            testimonial: "I have been an entrepreneur for many years and am a proud customer of Clawdia. Everything with Clawdia is clear and accurate, so much so that an 8-year-old can understand it. In my opinion, Clawdia is a critical tool that helps you avoid mistakes. It’s an absolute treasure.",
            img: AvivLandauImg,
        },
        {
            name: "Eyal Kama",
            title: "CEO",
            company: "",
            testimonial: "With Clawdia, this is the first time that I am not wasting money on legal advice, and I feel that I understand my business and legal environment.",
            img: EyalKamaImg,
        },
        {
            name: "Amit Gal",
            title: "CEO",
            company: "",
            testimonial: "I admire Clawdia! I never could have imagined that Clawdia would be so useful to me, with all the legal tools that I need in one place – Clawdia is helping me avoid any legal mistakes and take care of the legal process easily.",
            img: AmitGalImg,
        },
        {
            name: "Oron Snir",
            title: "CEO",
            company: "",
            testimonial: "In the uncertain environment of an entrepreneur, Clawdia is an anchor of safety. The tool is helpful for both first-time entrepreneurs as well as seasoned ones. What’s unique about Clawdia, is that she puts the emphasis on the entrepreneurs and not just on the contracts!",
            img: OronSnirImg,
        },
        {
            name: "Maoz Kain",
            title: "CEO",
            company: "",
            testimonial: "Clawdia helped me focus on the questions I need to be asking myself and my partners. Clawdia is friendly, clear, and simple. The tool is full of examples and explanations that really helped me out.",
            img: MaozKainImg,
        }
    ]

    return (
        <div className="testimonials-carousel">
            <div className="slide-track flex flex-row gap-4 p-4">
                {testimonials.map((testimonial, index) => (
                    <div className="slide shadow-cardHover px-8 py-4" key={index}>
                        <div className="flex flex-row justify-start gap-2 items-center">
                            <img className="w-10 h-10 rounded-full" src={testimonial.img} alt={testimonial.name} />
                            <FiveStars />
                        </div>
                        <p className="font-medium text-lg mt-1">{testimonial.name}, {testimonial.title}</p>
                        <div className="text-justify text-base mt-1">
                            <p>{testimonial.testimonial}</p>
                        </div>
                    </div>))}
            </div>
        </div>);
}

function FiveStars() {
    return (
        <div className="inline-flex gap-1">
            {[1, 2, 3, 4, 5].map((_, index) => {
                return <img key={index} className="w-5 h-5" src={StarImg} />
            })}
        </div>
    );
}
