import { RenderElementProps } from "slate-react";
import { SignatureElement } from "./Signature/SignaureElement";

import { SignatureElementType } from "../../Editor/types/custom-types";
import { useContext } from "react";
import { AgreementContext } from "../../components/WizardUi/AgreementContext/AgreementContext";
import { getAutoContracts } from "../../components/WizardUi/auto-contracts";
import Fillable, { ScrollableFillable } from "./InputComponent";
import { useAppSelector } from "store";

export const SignatureComponent = (props: RenderElementProps) => {
    const { attributes, children, element } = props;

    const agreementContext = useContext(AgreementContext);
    let legalUnit = agreementContext?.getActiveDraft()?.legalUnit;

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const he = agreement?.lang === "HE";
    const OTHERPARTY_CAPTION = he ? "הצד השני" : "Other Party";

    const foundAutoContract = getAutoContracts().find((autoContract) => legalUnit?.kind === autoContract.kind);
    const signer = foundAutoContract?.signer?.(legalUnit);
    const otherPartyType = signer?.titleForOtherSigner || OTHERPARTY_CAPTION;
    const otherPartyName = signer?.otherNameField || "";
    const otherPartyAddress = signer?.otherAddressField || "";
    const otherPartyTitle = signer?.otherTitleField || "";

    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);

    const otherPartyNameField = fields?.filter((field) => field.name === otherPartyName)?.[0];
    const otherPartyNameFillable = otherPartyNameField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyNameField.id }}
            isFromCondition={false}
            field={otherPartyNameField}
        />
    ) : undefined;

    const otherPartyAddressField = fields?.filter((field) => field.name === otherPartyAddress)?.[0];
    const otherPartyAddressFillable = otherPartyAddressField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyAddressField.id }}
            isFromCondition={false}
            field={otherPartyAddressField}
        />
    ) : undefined;

    const otherPartyTitleField = fields?.filter((field) => field.name === otherPartyTitle)?.[0];
    const otherPartyTitleFillable = otherPartyTitleField ? (
        <ScrollableFillable
            element={{ hasABefore: false, type: "input", children: [], fieldId: otherPartyTitleField.id }}
            isFromCondition={false}
            field={otherPartyTitleField}
        />
    ) : undefined;

    return (
        <span {...attributes} data-component="signature">
            {children}
            <span contentEditable={false}>
                <SignatureElement
                    count={(element as SignatureElementType).count}
                    otherPartyType={otherPartyType}
                    otherPartyDefaultName={otherPartyNameFillable}
                    otherPartyDefaultAddress={otherPartyAddressFillable}
                    otherPartyDefaultTitle={otherPartyTitleFillable}
                />
            </span>
        </span>
    );
};
