import { useCallback, useEffect, useRef, useState } from "react";

const sizes = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
} as const;

type size = keyof typeof sizes;

export const useMedia = (options: { min?: size; max?: size }) => {
    const { min, max } = options;
    const minQuery = min ? createQuery(sizes[min], "min") : "";
    const maxQuery = max ? createQuery(sizes[max] - 1, "max") : "";
    const isMulti = min && max;

    const mql = useRef(matchMedia(`${minQuery} ${isMulti ? "and" : ""} ${maxQuery}`));
    const [state, setState] = useState(mql.current.matches);

    const callback = useCallback((event: MediaQueryListEvent) => {
        setState(event.matches);
    }, []);

    useEffect(() => {
        mql.current.addEventListener("change", callback);

        return () =>  mql.current.removeEventListener("change", callback);
    }, [callback]);

    return state;
};

function createQuery(value: number, type: "min" | "max") {
    return `(${type}-width: ${value}px)`;
}
