import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AutoContractDocument } from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { PDoc, PDocStatus } from "proto/PDoc";

//  state used for simulator
// todo: maybe we should rename it
// todo: maybe load async on legal ide load only

export interface SimulatorDoc extends PDoc {
    legalUnit: any; // todo: what a correct type?
}
export interface currentContractState {
    currentQuestionIndex: number;
    answers: any;
    contract: AutoContractDocument;

    activeDraft?: SimulatorDoc;
}

const initialState: currentContractState = {
    currentQuestionIndex: 0,
    answers: {},
    contract: {
        name: "testContract",
        title: "test contract",
        image: "string",
        text: "text test contact",
        desc: "",
        comingSoon: () => false,
        kind: "testContract",
        keyto: "advisoryAgreement",
        contract: () => undefined,
        filename: "testContract",
        namer: () => "testContract",
        families: [],
        tags: [],
        forUSA: true,
        forIsrael: true,
    },
    activeDraft: {
        accountName: "accountName",
        id: "dev/test",
        legalUnit: {
            docVersion: "",
            wasAddressSet: false,
            kind: "testContract",
            name: "string",
            lastStep: "string",
            version: 1,
            freestyleSrc: "",
            testContract: {},
        },
        docStatus: PDocStatus.DRAFT,
        permanentContext: undefined,
        templateId: "",
    },
};

export const currentContractSlice = createSlice({
    name: "currentContract",
    initialState,
    reducers: {
        onAnswer: (state, action: PayloadAction<{ name: string; value: any }>) => {
            if (!state.activeDraft?.legalUnit?.testContract) return;
            state.activeDraft.legalUnit.testContract[action.payload.name] = action.payload.value;
        },
        onReset: (state, action: PayloadAction<{ name: string }>) => {
            state.answers[action.payload.name] = undefined;
        },
        setCurrentQuestionIndex: (state, action) => {
            state.currentQuestionIndex = action.payload;
        },
    },
});
