import { DocsApi } from "api-services/DocsApi";
import { LoadingDots } from "components/0_common/Loading/LoadingDots";
import { PDoc } from "proto/PDoc";
import { PLegalUnit, UploadFileStatus } from "proto/PLegalUnit";
import { ChangeEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { callDialog } from "store/mainActions";
import { DialogType, MainStore } from "../../store/mainTypes";
import DocsBase, { continueDraft } from "./DocsBase";

export default function InboxDocs() {
    const uploadedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.uploadedDocs);
    const dispatch = useDispatch();
    const history = useHistory();

    return <DocsBase relevantDocs={uploadedDocs}
        caption={"Upload & Analyze"}
        noDocsMsg="You currently don't have any uploaded documents"
        mainCTA=""
        hideDuplicate={true}
        headerButton={<UploadDoc />}
        customCTA={(doc: PDoc) =>
            <button type="button"
                className={`flex justify-center items-center  text-base w-64 h-10
                 border rounded-lg overflow-hidden whitespace-nowrap
                ${doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.NEW ?
                        "bg-secondary hover:bg-secondaryHover border-secondary text-darkIndigo" :
                        "bg-primary text-center hover:bg-primaryHover border-primary text-white"
                    }
                `}
                onClick={() => {
                    if (doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.ANALYZED) {
                        continueDraft(doc, history)
                        return;
                    }

                    if (doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.BEING_ANALYZED) {
                        return;
                    }

                    dispatch(callDialog(DialogType.CONFIRM, "Are you sure you want to analyze this document? It may take a few minutes.", (confirm: boolean) => {
                        if (!confirm) return;

                        continueDraft(doc, history)

                        DocsApi.reviewContract(doc.id, (res: { success: boolean, data: string }) => {
                            if (res.success) {
                                DocsApi.getAllDocs(dispatch)
                            } else {
                                dispatch(callDialog(DialogType.FAILURE, "Document analysis failed. Reason: " + res.data, () => { }))
                            }
                        },
                            () => {
                                dispatch(callDialog(DialogType.FAILURE, "Document analysis failed. Please try again later.", () => { }))
                            }
                        )

                        // will change status to BEING_ANALYZED
                        setTimeout(() => {
                            DocsApi.getAllDocs(dispatch)
                        }, 3000)
                    }))
                }}
            >
                {doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.ANALYZED && "View"}
                {doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.NEW && "Analyze"}
                {doc.legalUnit?.uploadedFile?.fileStatus === UploadFileStatus.BEING_ANALYZED && <LoadingDots />}

            </button>
        }
    />
}

function UploadDoc() {
    const fileInput = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const handleButtonClick = () => {
        fileInput.current?.click();
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const result = e.target?.result as ArrayBuffer;
                const data = result ? new Uint8Array(result) : undefined;

                if (data) {
                    DocsApi.createNewDraft({
                        name: file.name,
                        kind: "uploadedFile",
                        uploadedFile: {
                            fileData: data,
                            fileType: file.type,
                            isAnalyzed: false,
                            reviewResult: undefined
                        },
                    } as unknown as PLegalUnit,
                        () => {
                            dispatch(callDialog(DialogType.SUCCESS, "Document uploaded successfully!", () => { }))

                            DocsApi.getAllDocs(dispatch)
                        },
                        () => {
                            dispatch(callDialog(DialogType.FAILURE, "Document upload failed. Please try again later.", () => { }))
                        }
                    );
                }
                else {
                    dispatch(callDialog(DialogType.FAILURE, "Couldn't read the file", () => { }))
                }
            }

            reader.readAsArrayBuffer(file);
        };
    }

    return (
        <div>
            <button onClick={handleButtonClick}
                className="flex gap-1 items-center justify-center text-white text-base w-full bg-primary text-center hover:bg-primaryHover border-primary border rounded-lg px-4 py-3">
                <span className="flex items-center justify-center w-5 h-5 rounded-full bg-iceBlue bg-opacity-20 text-xs font-thin -rotate-90">&#x279C;</span>
                <span>Upload Doc</span>
            </button>

            <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                accept=".txt,.pdf,.docx"
            />
        </div>
    );
}

