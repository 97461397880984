import { useReducer } from "react";

type State = {
    isOn: boolean;
};
type Action =
    | {
          type: "on";
      }
    | {
          type: "off";
      }
    | {
          type: "toggle";
      };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "on":
            return { ...state, isOn: true };
        case "off":
            return { ...state, isOn: false };
        case "toggle":
            return { ...state, isOn: !state.isOn };
        default:
            return state;
    }
}
export const useToggle = () => {
    const [state, dispatch] = useReducer(reducer, { isOn: false });

    const on = () => dispatch({ type: "on" });
    const off = () => dispatch({ type: "off" });
    const toggle = () => dispatch({ type: "toggle" });

    return { state, on, off, toggle };
};
