import { Container } from "components/0_common";
import { useMedia } from "hooks";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { DrawingCanvas } from "../components/elements/DrawingCanvas";
import { SignatureElement } from "../components/elements/PdfElements";
import { usePdfContext } from "../PdfContext";
import { scrollToPage } from "../utils/scrollToView";
import { PdfCanvas } from "./PdfCanvas";
import { PdfToolbar } from "./PdfToolbar";

export const PdfPageLayout = () => {
    const context = usePdfContext();

    const isMobile = useMedia({ max: "sm" });

    function addSignature(data: string) {
        if (!context.state.selectedPage) return context.state.elements;

        const pageObjects = [...(context.state.elements[context.state.selectedPage] ?? [])];

        const signatureElement: SignatureElement = {
            type: "signature",
            id: nanoid(6),
            value: data,
            x: 50,
            y: 50,
            width: 150,
            height: 50,
        };
        pageObjects.push(signatureElement);
        scrollToPage(context.state.selectedPage);

        return { ...context.state.elements, [context.state.selectedPage]: pageObjects };
    }

    useEffect(() => {
        if (context.state.file && !context.state.selectedPage) {
            context.updateState({
                selectedPage: 1,
            });
        }
    }, [context, context.state.file]);

    return (
        <Container className="flex-grow w-screen flex flex-col gap-4 p-4 justify-center">
            {context.state.drawingIsOpen && (
                <div
                    className="fixed z-20 top-0 left-0 right-0 border-b border-gray-300 bg-white h-1/2
                  shadow-lg"
                    onClick={(e) => e.stopPropagation()}
                >
                    <DrawingCanvas
                        onDone={(data) => {
                            context.updateState({
                                drawingIsOpen: false,
                                elements: data ? addSignature(data) : context.state.elements,
                            });
                        }}
                    />
                </div>
            )}
            <div className="sticky z-10 top-0  w-full">
                <PdfToolbar />
            </div>
            <div className="mx-auto overflow-x-auto max-w-full max-h-full w-screen md:w-auto">
                <PdfCanvas />
            </div>
        </Container>
    );
};
