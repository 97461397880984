/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface PContractReviewResult {
  paragraphs: PContractParagraphAnalysis[];
}

export interface PContractParagraphAnalysis {
  topic: string;
  standardScore: number;
  importanceScore: number;
  alternatives: string;
  isBusinessLaw: boolean;
  containsCommercialTerms: boolean;
  summary: string;
  originalText: string;
}

function createBasePContractReviewResult(): PContractReviewResult {
  return { paragraphs: [] };
}

export const PContractReviewResult = {
  encode(message: PContractReviewResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.paragraphs) {
      PContractParagraphAnalysis.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContractReviewResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContractReviewResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.paragraphs.push(PContractParagraphAnalysis.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContractReviewResult {
    return {
      paragraphs: Array.isArray(object?.paragraphs)
        ? object.paragraphs.map((e: any) => PContractParagraphAnalysis.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PContractReviewResult): unknown {
    const obj: any = {};
    if (message.paragraphs) {
      obj.paragraphs = message.paragraphs.map((e) => e ? PContractParagraphAnalysis.toJSON(e) : undefined);
    } else {
      obj.paragraphs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PContractReviewResult>, I>>(base?: I): PContractReviewResult {
    return PContractReviewResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContractReviewResult>, I>>(object: I): PContractReviewResult {
    const message = createBasePContractReviewResult();
    message.paragraphs = object.paragraphs?.map((e) => PContractParagraphAnalysis.fromPartial(e)) || [];
    return message;
  },
};

function createBasePContractParagraphAnalysis(): PContractParagraphAnalysis {
  return {
    topic: "",
    standardScore: 0,
    importanceScore: 0,
    alternatives: "",
    isBusinessLaw: false,
    containsCommercialTerms: false,
    summary: "",
    originalText: "",
  };
}

export const PContractParagraphAnalysis = {
  encode(message: PContractParagraphAnalysis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.topic !== "") {
      writer.uint32(10).string(message.topic);
    }
    if (message.standardScore !== 0) {
      writer.uint32(16).int32(message.standardScore);
    }
    if (message.importanceScore !== 0) {
      writer.uint32(24).int32(message.importanceScore);
    }
    if (message.alternatives !== "") {
      writer.uint32(34).string(message.alternatives);
    }
    if (message.isBusinessLaw === true) {
      writer.uint32(40).bool(message.isBusinessLaw);
    }
    if (message.containsCommercialTerms === true) {
      writer.uint32(48).bool(message.containsCommercialTerms);
    }
    if (message.summary !== "") {
      writer.uint32(58).string(message.summary);
    }
    if (message.originalText !== "") {
      writer.uint32(66).string(message.originalText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContractParagraphAnalysis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContractParagraphAnalysis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.standardScore = reader.int32();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.importanceScore = reader.int32();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.alternatives = reader.string();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.isBusinessLaw = reader.bool();
          continue;
        case 6:
          if (tag != 48) {
            break;
          }

          message.containsCommercialTerms = reader.bool();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.originalText = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContractParagraphAnalysis {
    return {
      topic: isSet(object.topic) ? String(object.topic) : "",
      standardScore: isSet(object.standardScore) ? Number(object.standardScore) : 0,
      importanceScore: isSet(object.importanceScore) ? Number(object.importanceScore) : 0,
      alternatives: isSet(object.alternatives) ? String(object.alternatives) : "",
      isBusinessLaw: isSet(object.isBusinessLaw) ? Boolean(object.isBusinessLaw) : false,
      containsCommercialTerms: isSet(object.containsCommercialTerms) ? Boolean(object.containsCommercialTerms) : false,
      summary: isSet(object.summary) ? String(object.summary) : "",
      originalText: isSet(object.originalText) ? String(object.originalText) : "",
    };
  },

  toJSON(message: PContractParagraphAnalysis): unknown {
    const obj: any = {};
    message.topic !== undefined && (obj.topic = message.topic);
    message.standardScore !== undefined && (obj.standardScore = Math.round(message.standardScore));
    message.importanceScore !== undefined && (obj.importanceScore = Math.round(message.importanceScore));
    message.alternatives !== undefined && (obj.alternatives = message.alternatives);
    message.isBusinessLaw !== undefined && (obj.isBusinessLaw = message.isBusinessLaw);
    message.containsCommercialTerms !== undefined && (obj.containsCommercialTerms = message.containsCommercialTerms);
    message.summary !== undefined && (obj.summary = message.summary);
    message.originalText !== undefined && (obj.originalText = message.originalText);
    return obj;
  },

  create<I extends Exact<DeepPartial<PContractParagraphAnalysis>, I>>(base?: I): PContractParagraphAnalysis {
    return PContractParagraphAnalysis.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContractParagraphAnalysis>, I>>(object: I): PContractParagraphAnalysis {
    const message = createBasePContractParagraphAnalysis();
    message.topic = object.topic ?? "";
    message.standardScore = object.standardScore ?? 0;
    message.importanceScore = object.importanceScore ?? 0;
    message.alternatives = object.alternatives ?? "";
    message.isBusinessLaw = object.isBusinessLaw ?? false;
    message.containsCommercialTerms = object.containsCommercialTerms ?? false;
    message.summary = object.summary ?? "";
    message.originalText = object.originalText ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
