import { Button, Select, TextField } from "components";
import { safeArray } from "components/utils/UtilFunctions";
import { InternalBoolean } from "proto/contracts/PUtils";
import { useAppSelector } from "store";
import { MULTI_SELECTION_OPERATORS, MULTI_SELECTION_OPERATORS_ARR } from "../Editor/parser";
import { FieldType } from "../Editor/types/custom-types";

interface ConditionRuleProps {
    compoundJsonLogic?: CompoundJsonLogic;
    onChange: (compoundJsonLogic: CompoundJsonLogic) => void;
}

export const ConditionRule = (props: ConditionRuleProps) => {
    const { compoundJsonLogic, onChange } = props;

    let editableCompoundJsonLogic: CompoundJsonLogic = (compoundJsonLogic && (compoundJsonLogic?.conditions?.length || 0) > 0 ? compoundJsonLogic : {
        concatOperator: "AND",
        conditions: [{
            fieldId: "",
            operator: "",
            value: "",
        }],
    });

    const nameValueOnChangePerRow = (index: number, name: string, value: any) => {
        onChange({
            ...editableCompoundJsonLogic,
            conditions: editableCompoundJsonLogic.conditions.map((condition, i) => i === index ? { ...condition, [name]: value } : condition)
        });
    };

    return (
        <div className="flex gap-4 flex-col">
            {editableCompoundJsonLogic?.conditions?.map((jsonLogic, index) => {
                const nameValueOnChange = nameValueOnChangePerRow.bind(null, index)
                // todo: key warning cant be solved, todo - add id for each condition
                return <div className="flex gap-4 items-start">
                    <SelectField
                        onChange={nameValueOnChange}
                        value={jsonLogic?.fieldId}
                        types={[FieldType.BOOL, FieldType.ENUM, FieldType.MULTI_ENUM]}
                    />
                    <OperatorField value={jsonLogic?.operator} onChange={nameValueOnChange} fieldId={jsonLogic?.fieldId} />
                    <ValueField jsonLogic={jsonLogic} onChange={nameValueOnChange} />
                    {index === 0 && <Button className="h-5 w-10 flex justify-center" onClick={() => onChange({
                        ...editableCompoundJsonLogic,
                        conditions: [...editableCompoundJsonLogic.conditions, {
                            fieldId: "",
                            operator: "",
                            value: "",
                        }]
                    })}>+</Button>}
                    {index > 0 && <Button className="h-5 w-10 flex justify-center" onClick={() => onChange({
                        ...editableCompoundJsonLogic,
                        conditions: editableCompoundJsonLogic.conditions.filter((_, i) => i !== index)
                    })}>x</Button>}
                </div>
            })}

            {editableCompoundJsonLogic?.conditions?.length > 1 &&
                <div className="flex items-center gap-4">
                    <label>
                        <input type="radio" id="concatOperator_AND" name="concatOperator" value="AND" checked={editableCompoundJsonLogic.concatOperator === "AND"} onChange={(e) => onChange({ ...editableCompoundJsonLogic, concatOperator: "AND" })} />
                        {" "}AND
                    </label>
                    <label>
                        <input type="radio" id="concatOperator_OR" name="concatOperator" value="OR" checked={editableCompoundJsonLogic.concatOperator === "OR"} onChange={(e) => onChange({ ...editableCompoundJsonLogic, concatOperator: "OR" })} />
                        {" "}OR
                    </label>
                </div>
            }
        </div>
    );
};

const OPERATORS = ["==", "!=" /*">", "<", ">=", "<=", */];
// also, MULTI_SELECTION_OPERATORS_ARR

function ValueField(props: { jsonLogic: JsonLogic, onChange: (name: string, value: any) => void }) {
    const { jsonLogic } = props;

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const field = agreement?.fields.find((f) => f.id === jsonLogic?.fieldId);
    const name = "value";
    const value = jsonLogic?.value;

    switch (field?.type) {
        case FieldType.BOOL:
            return (
                <Select
                    required={true}
                    options={[
                        { label: "YES", value: InternalBoolean.YES },
                        { label: "NO", value: InternalBoolean.NO },
                    ]}
                    name={name}
                    value={value}
                    onChange={props.onChange}
                    asNumber
                />
            );
        case FieldType.STRING:
            return (
                <TextField
                    required={true}
                    size="sm"
                    name={name}
                    value={value}
                    type="text"
                    onChange={props.onChange}
                    placeholder="enter value"
                />
            );

        case FieldType.NUMBER:
            return (
                <TextField
                    required={true}
                    name={name}
                    value={value}
                    type="number"
                    onChange={props.onChange}
                    placeholder="enter value"
                />
            );
        case FieldType.ENUM:
            return (
                <Select
                    required={true}
                    options={field.options || []}
                    name={name}
                    value={field.options?.find((o) => o.dbValue === value)?.value}
                    onChange={(name, value) => {
                        props.onChange(name, Number(field.options?.find((o) => o.value === value)?.dbValue));
                    }}
                />
            );
        case FieldType.MULTI_ENUM: {
            if (
                jsonLogic?.operator === MULTI_SELECTION_OPERATORS.NOT_EMPTY ||
                jsonLogic?.operator === MULTI_SELECTION_OPERATORS.IS_EMPTY
            ) {
                return null;
            }

            return (
                <div className="flex flex-col justify-start items-start">
                    {safeArray<FieldOption>(field.options).map((o) => {
                        return (
                            <label key={o.id} className="flex justify-center gap-2">
                                <input
                                    onChange={(e) => {
                                        const dbValues = (value || []) as number[];

                                        if (e.target.checked) {
                                            props.onChange(name, [...dbValues, o.dbValue]);
                                        } else {
                                            props.onChange(
                                                name,
                                                dbValues.filter((v) => v !== o.dbValue)
                                            );
                                        }
                                    }}
                                    checked={(value || ([] as number[]))?.includes(o.dbValue)}
                                    type="checkbox"
                                    id={o.value}
                                    name={o.value}
                                    value={o.dbValue}
                                />
                                {o.value}
                            </label>
                        );
                    })}
                </div>
            );
        }
        default:
            return null;
    }
}

function OperatorField(props: { value?: string; onChange?: any; fieldId?: string }) {
    const { fieldId, value, onChange } = props;
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const field = currentAgreement?.fields.find((f) => f.id === fieldId);

    if (!currentAgreement) return null;

    const isDisabled = [FieldType.STRING].includes(field?.type || FieldType.UNKNOWN);
    const operators = field?.type === FieldType.MULTI_ENUM ? MULTI_SELECTION_OPERATORS_ARR : OPERATORS;

    return (
        <Select
            required={true}
            disabled={isDisabled}
            name="operator"
            value={isDisabled ? "==" : value}
            onChange={onChange}
            options={operators.map((operator) => ({
                label: operator,
                value: operator,
            }))}
        />
    );
}

interface SelectFieldProps {
    value: any;
    onChange?: (name: string, value: any) => void;
    types?: FieldType[];
}
export function SelectField(props: SelectFieldProps) {
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const field = currentAgreement?.fields.find((f) => f.id === props.value);

    if (!currentAgreement) return null;
    return (
        <div className="flex flex-col gap-1">
            <Select
                placeholder="select field"
                name="fieldId"
                required
                value={field?.id}
                onChange={(name, fieldId) =>
                    props.onChange?.(name, currentAgreement.fields.find((f) => f.id === fieldId)?.id)
                }
                options={filterFieldTypes(currentAgreement?.fields, props.types).map((field) => ({
                    label: `${!!field.layer ? field.layer + "." : ""}${field.name}`,
                    value: field.id,
                }))}
            />
            {!props.value && <span className="text-sm text-red-500">question must have field</span>}
        </div>
    );
}

function filterFieldTypes(fields: Field[], types?: FieldType[]): Field[] {
    if (!types) {
        return fields;
    }

    return fields.filter((field) => types.includes(field.type));
}
