import { PDoc } from "proto/PDoc";
import { conditionToJsonLogic } from "../WizardForm";
import { getCurrentContract, getSections, isEmpty } from "./shared-api";

import * as WizardUtil from "./shared-api";
import { useAppSelector } from "store";
import { PUser } from "proto/PUser";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import { SELECT_CUSTOM_OTHER_DB_VALUE } from "consts";
import { formatAddress, formatNumber } from "common";

/**
 * @returns {string} Human readable value
 */
export function getFieldDisplayValue({ field, value }: { field: Field; value: any }): string {
    if (field.type === FieldType.ENUM) {
        const ops = field.options || [];
        if (value.optionDbValue === SELECT_CUSTOM_OTHER_DB_VALUE) {
            return value.other;
        } else {
            const option = ops?.find((opt) => opt.dbValue === value.optionDbValue);
            return option?.value ?? "";
        }
    }
    if (field.type === FieldType.MULTI_ENUM) {
        const ops = field.options || [];
        const dbValueArray = (value.optionDbValue as number[]) || [];
        return dbValueArray
            .map((val) => {
                if (val === SELECT_CUSTOM_OTHER_DB_VALUE) {
                    return value.other ?? "";
                }
                return ops.find((opt) => opt.dbValue === val)?.value ?? "";
            })
            .join(", ");
    }
    if (field.type === FieldType.NUMBER) {
        const { suffixSymbol, prefixSymbol } = field.numberProperties ?? {};
        return formatNumber(value, { prefixSymbol: prefixSymbol, suffixSymbol: suffixSymbol });
    }
    if (field.type === FieldType.ADDRESS) {
        return formatAddress(value);
    }
    return value;
}

export const useWizardApi = ({ agreement, draft }: { agreement: Agreement | undefined; draft?: PDoc }) => {
    const currentContract = getCurrentContract(draft);

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

    const getQuestionValue = (question: Question) => {
        const field = agreement?.fields.find((f) => f.id === question?.fieldId)!;
        if (!field) return;
        if (field.layer === "owner" && dbUser) {
            return dbUser[field.id as keyof PUser];
        }
        return getCurrentContract(draft)?.[field.name] || undefined;
    };

    const isWizardCompleted = () => {
        return WizardUtil.getAllFilteredSteps(agreement, currentContract).every(isStepCompleted);
    };

    const getQuestionIndexInStep = (question: Question): number => {
        const steps = WizardUtil.getAllFilteredSteps(agreement, currentContract);
        const step = steps.find((s) => s.questions.includes(question.id));
        return step?.questions.indexOf(question.id) ?? -1;
    };

    const isStepCompleted = (step: Step) => {
        const stepQuestions = WizardUtil.getFilteredQuestionsForStep(agreement, currentContract, step);

        return stepQuestions.every((question) => {
            const value = getQuestionValue(question!);

            return !isEmpty(value);
        });
    };

    const isSectionCompleted = (section: Section) => {
        const filteredSteps = WizardUtil.getAllFilteredSteps(agreement, currentContract);
        return section.steps.every((stepId) => {
            const step = agreement?.wizard.steps.byId[stepId];
            if (!step) return true;

            if (!filteredSteps.map((s) => s.id).includes(step.id)) {
                return true;
            }

            return isStepCompleted(step);
        });
    };

    const isSectionSemiCompleted = (section: Section) => {
        const filteredSteps = WizardUtil.getAllFilteredSteps(agreement, currentContract);
        return section.steps.some((stepId) => {
            const step = agreement?.wizard.steps.byId[stepId];
            if (!step) return false;

            if (!filteredSteps.map((s) => s.id).includes(step.id)) {
                return false;
            }

            return isStepCompleted(step);
        });
    };

    const getNotCompletedSteps = (): Step[] => {
        return WizardUtil.getAllFilteredSteps(agreement, currentContract).filter((step) => !isStepCompleted(step));
    };

    const getCurrentSection = () => {
        return WizardUtil.getCurrentSection(agreement, currentContract);
    };

    const getProgress = () => {
        const questions = WizardUtil.getAllFilteredQuestions(agreement, currentContract);
        const answers = questions.filter((question) => !isEmpty(getQuestionValue(question)));

        return {
            percentage: ((answers.length * 100) / questions.length) ^ 0,
        };
    };

    const getFieldValue = (field: Field) => {
        return currentContract[field?.name];
    };

    return {
        getQuestionValue,
        getQuestionIndexInStep,
        getFieldValue,
        getProgress,
        getCurrentContract,
        isStepCompleted,
        getCurrentSection,
        getNotCompletedSteps,
        isSectionCompleted,
        isSectionSemiCompleted,
        isWizardCompleted,
        getQuestionTextById: (id: string) => {
            return WizardUtil.getAllFilteredQuestions(agreement, currentContract).find((q) => q.id === id)?.text || "";
        },
        getFilteredQuestionsForStep: (step?: Step) => {
            if (!step) return [];
            return WizardUtil.getFilteredQuestionsForStep(agreement, currentContract, step);
        },
        getMaxStep: () => {
            return WizardUtil.getMaxStep(agreement, currentContract);
        },
    };
};
