import Modal, { ExitButton } from 'components/Modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDecisionSupportingToolAlert } from 'store/mainActions';
import { DecisionSupportingToolAlertType, MainStore } from 'store/mainTypes';
import { PrivacyPolicyScanner } from './PrivacyScanner';

export default function DecisionSupportingToolsAlerts() {
  const decisionSupportingToolAlert = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.decisionSupportingToolAlert);
  const dispatch = useDispatch()

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      if (e.code === "Enter" || e.code === "NumpadEnter") {
        dispatch(setDecisionSupportingToolAlert(undefined))
        decisionSupportingToolAlert?.callback()
      }
    }

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };

  }, [])


  if (!decisionSupportingToolAlert) {
    return null;
  }

  return (
    <Modal>
      <div className="relative rounded-2xl mx-auto border md:w-172 w-full shadow-lg bg-white p-8">
        <ExitButton onClick={() => {
          dispatch(setDecisionSupportingToolAlert(undefined));
          decisionSupportingToolAlert?.callback(false);
        }} />

        <div className={`text-center flex flex-col gap-6`}>
          {decisionSupportingToolAlert.type === DecisionSupportingToolAlertType.PRIVACY_POLICY_WEBSITE_SCAN && (
            <PrivacyPolicyScanner websiteUrl={decisionSupportingToolAlert.text} />
          )}
        </div >
      </div >
    </Modal >
  )
}
