import { useTransition, animated, config } from "@react-spring/web";
import { useState } from "react";
import "components/0_common/Scrollbar/Scrollbar.scss";
import { NewIcon } from "components/0_common";

interface TableProps {
    columns: any[];
    data: any[];
    renderRow?: any;
    columnActions?: any;
}

export const Table = (props: TableProps) => {
    const { columns = [], data = [] } = props;

    const [sort, setSort] = useState<{ key: string; dir: "asc" | "desc" | "" }>({ key: "", dir: "" });
    const sortedData = [...data].sort((a, b) => {
        if (!sort.key) return 0;
        if (sort.dir === "asc") return a[sort.key] > b[sort.key] ? 1 : -1;
        if (sort.dir === "desc") return a[sort.key] > b[sort.key] ? -1 : 1;
        return 0;
    });
    const transactions = useTransition(sortedData, {
        from: { opacity: 1, x: -300 },
        enter: { opacity: 1, x: 0 },
        leave: { opacity: 0, x: -300 },
        config: config.default,
        keys: (item) => item.id,
    });

    const handleLabelAction = (column: any, index: number) => {
        if (sort.key !== column.key) {
            setSort({ key: column.key, dir: "asc" });
        } else {
            if (sort.dir === "") setSort({ key: column.key, dir: "asc" });
            if (sort.dir === "asc") setSort({ key: column.key, dir: "desc" });
            if (sort.dir === "desc") setSort({ key: column.key, dir: "" });
        }
    };

    return (
        <div className="flex flex-col">
            <div className="overflow-x-hidden overflow-y-auto _ScrollbarNarrow shadow-md sm:rounded-lg">
                <div className="inline-block min-w-full align-center">
                    <div className="overflow-hidden ">
                        <div className=" min-w-full divide-y divide-gray-200 ">
                            <div className="bg-gray-100 flex items-center">
                                {columns.map((column, index) => (
                                    <div
                                        onClick={() => handleLabelAction(column, index)}
                                        key={index}
                                        className="flex items-center py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                                    >
                                        {column.label}
                                        {!!column.label && sort.key === column.key && !!sort.dir && (
                                            <NewIcon
                                                size={"xs"}
                                                name={sort.dir === "asc" ? "caret_up_fill" : "caret_down_fill"}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="bg-white divide-y divide-gray-200">
                                {transactions((style, item) => {
                                    return (
                                        <animated.div
                                            id={`row-${item.id}`}
                                            style={style}
                                            className="hover:bg-gray-100 flex items-center"
                                        >
                                            {columns.map((column, index) => {
                                                const field = item[column.key];

                                                return (
                                                    <div
                                                        key={index}
                                                        className="py-4 px-2 text-sm font-medium text-gray-500 whitespace-nowrap "
                                                    >
                                                        {column.renderCell
                                                            ? column.renderCell({
                                                                  field,
                                                                  row: item,
                                                                  index,
                                                                  column,
                                                              })
                                                            : field}
                                                    </div>
                                                );
                                            })}
                                        </animated.div>
                                    );
                                })}

                                {!data.length && (
                                    <div>
                                        <div>no rows to display</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
