import { createContext, ReactNode, useContext } from "react";
import { useSimulatorApi } from "./simulatorContext";
import { AgreementContextType } from "./AgreementContextType";
import { useProdApi } from "./prodContext";

export const AgreementContext = createContext<AgreementContextType | null>(null);

export const useAgreementContext = () => useContext(AgreementContext);

interface Props {
    children: ReactNode;

    variant: "simulator" | "prod";
}

const wizardApis: { [key in Props["variant"]]: any } = {
    simulator: useSimulatorApi,

    prod: useProdApi,
};
export const AgreementContextProvider = (props: Props) => {
    const { children, variant } = props;

    const wizardApi = wizardApis[variant]();

    return <AgreementContext.Provider value={wizardApi}>{children}</AgreementContext.Provider>;
};
