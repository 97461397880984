import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { Layers } from "apps/legal-ide/features/Field";
import useCheckMobileScreen from "components/../hooks/UseCheckMobileScreen";
import { formatDate } from "components/utils/UtilFunctions";
import { useAppSelector } from "store";
import { companyFullName } from "..";
import Fillable, {
    accountNameField,
    addressField,
    emailField,
    fullNameField,
    ScrollableFillable,
    titleField,
} from "../InputComponent";
import Signature from "./Signature";

// signature part
export function SignatureElement(props: {
    count: number;
    otherPartyType: string;
    hideEmail?: boolean;
    otherPartyDefaultName?: JSX.Element;
    otherPartyDefaultAddress?: JSX.Element;
    otherPartyDefaultTitle?: JSX.Element;
}) {
    const basicFontSize = "1em";
    const smallerFont = "1em";
    const topSize = "0.004em";
    const isMobile = useCheckMobileScreen();

    const agreementContext = useAgreementContext();
    let activeDraft = agreementContext?.getActiveDraft();

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const docShareType = agreement?.docShareType;
    const he = agreement?.lang === "HE";

    const NAME_CAPTION = he ? "שם" : "Name";
    const TITLE_CAPTION = he ? "תפקיד" : "Title";
    const EMAIL_CAPTION = he ? "דואר אלקטרוני" : "Email";
    const BY_CAPTION = he ? "בשם" : "By";
    const DATE_CAPTION = he ? "בתאריך" : "Date";

    const ACKNOWLEDGE_CAPTION = he ? "מכיר ומאשר" : "Acknowledged";

    if (docShareType === "DOCUMENT") {
        return null;
    }

    if (docShareType === "ONLY_OWNER_SIGNS") {
        return (
            <div className="w-1/2">
                <Signature owner={true} count={props.count} signatureHeadline={" "} />
            </div>
        );
    }

    const hideOwnerSignature = docShareType === "ONLY_OTHER_SIGNS";
    const hideOtherSignature = false;

    return (
        <div data-component="signature" dir={he ? "rtl": "ltr"}>
            {hideOtherSignature ? null : (
                <>
                    <p style={{ lineHeight: "1.2", marginTop: "0rem", marginBottom: "0rem" }}>
                        <span
                            style={{
                                fontSize: basicFontSize,
                                fontFamily: '"Arial"',
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {props.otherPartyType}:&nbsp;
                        </span>
                    </p>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            marginLeft: isMobile ? "-2rem" : "0rem",
                        }}
                        className="flex flex-col-reverse lg:flex-row gap-4"
                    >
                        <Signature
                            owner={false}
                            count={props.count}
                            signatureHeadline={`${props.otherPartyType} signature:`}
                        />

                        <div style={{ marginTop: "2em", marginLeft: "0.5em", width: "100%" }}>
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {BY_CAPTION}:{" "}
                                    <ScrollableFillable
                                        element={{
                                            layer: Layers.OTHER,
                                            children: [],
                                            fieldId: fullNameField.id,
                                            hasABefore: false,
                                            type: "input",
                                        }}
                                        isFromCondition={false}
                                        field={fullNameField}
                                    />
                                </span>
                            </p>
                            {props.otherPartyDefaultName ? (
                                <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {NAME_CAPTION}: {props.otherPartyDefaultName}
                                    </span>
                                </p>
                            ) : (
                                <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {NAME_CAPTION}:{" "}
                                        <ScrollableFillable
                                            element={{
                                                layer: Layers.OTHER,
                                                children: [],
                                                fieldId: companyFullName.id,
                                                hasABefore: false,
                                                type: "input",
                                            }}
                                            isFromCondition={false}
                                            field={companyFullName}
                                        />
                                    </span>
                                </p>
                            )}
                            {props.otherPartyDefaultTitle ? (
                                <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {TITLE_CAPTION}: {props.otherPartyDefaultTitle}
                                    </span>
                                </p>
                            ) : (
                                <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {TITLE_CAPTION}:{" "}
                                        <ScrollableFillable
                                            element={{
                                                layer: Layers.OTHER,
                                                children: [],
                                                fieldId: titleField.id,
                                                hasABefore: false,
                                                type: "input",
                                            }}
                                            isFromCondition={false}
                                            field={titleField}
                                        />
                                    </span>
                                </p>
                            )}
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {DATE_CAPTION}: {formatDate(activeDraft?.permanentContext?.sharedWithUser?.signingDate)}
                                </span>
                            </p>
                            {!props.hideEmail && (
                                <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {EMAIL_CAPTION}:{" "}
                                        <ScrollableFillable
                                            element={{
                                                layer: Layers.OTHER,
                                                children: [],
                                                fieldId: emailField.id,
                                                hasABefore: false,
                                                type: "input",
                                            }}
                                            isFromCondition={false}
                                            field={emailField}
                                        />
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                </>
            )}
            {hideOwnerSignature ? null : (
                <>
                    <p style={{ lineHeight: "0.02em" }}>&nbsp;</p>
                    <p style={{ lineHeight: "0.02em" }}>&nbsp;</p>
                    <p style={{ lineHeight: "1.2", marginTop: "0rem", marginBottom: "0rem" }}>
                        <span
                            style={{
                                fontSize: basicFontSize,
                                fontFamily: '"Arial"',
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {hideOtherSignature ? "" : ACKNOWLEDGE_CAPTION}:&nbsp;
                        </span>
                    </p>
                    <p style={{ lineHeight: "0.02em" }}>&nbsp;</p>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            marginLeft: isMobile ? "-2rem" : "0rem",
                        }}
                        className="flex flex-col lg:flex-row gap-4"
                    >
                        <div style={{ marginTop: "2em", width: "100%" }}>
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {BY_CAPTION}:{" "}
                                    <ScrollableFillable
                                        element={{
                                            layer: Layers.OWNER,
                                            children: [],
                                            fieldId: fullNameField.id,
                                            hasABefore: false,
                                            type: "input",
                                        }}
                                        isFromCondition={false}
                                        field={fullNameField}
                                    />
                                </span>
                            </p>
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {NAME_CAPTION}:{" "}
                                    <ScrollableFillable
                                        element={{
                                            layer: Layers.OWNER,
                                            children: [],
                                            fieldId: companyFullName.id,
                                            hasABefore: false,
                                            type: "input",
                                        }}
                                        isFromCondition={false}
                                        field={companyFullName}
                                    />
                                </span>
                            </p>
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {TITLE_CAPTION}:{" "}
                                    <ScrollableFillable
                                        element={{
                                            layer: Layers.OWNER,
                                            children: [],
                                            fieldId: titleField.id,
                                            hasABefore: false,
                                            type: "input",
                                        }}
                                        isFromCondition={false}
                                        field={titleField}
                                    />
                                </span>
                            </p>
                            <p style={{ lineHeight: "1.2", marginTop: topSize, marginBottom: "0rem" }}>
                                <span
                                    style={{
                                        fontSize: smallerFont,
                                        fontFamily: '"Arial"',
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    {DATE_CAPTION}: {formatDate(activeDraft?.permanentContext?.ownerUser?.signingDate)}
                                </span>
                            </p>

                            {!props.hideEmail && (
                                <p
                                    style={{
                                        fontSize: "100%",
                                        whiteSpace: "pre-wrap",
                                        overflow: "hidden",
                                        lineHeight: "1.2",
                                        marginTop: topSize,
                                        marginBottom: "0rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: smallerFont,
                                            fontFamily: '"Arial"',
                                            backgroundColor: "transparent",
                                            fontWeight: 400,
                                            fontStyle: "normal",
                                            fontVariant: "normal",
                                            textDecoration: "none",
                                            verticalAlign: "baseline",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {EMAIL_CAPTION}:{" "}
                                        <ScrollableFillable
                                            element={{
                                                layer: Layers.OWNER,
                                                children: [],
                                                fieldId: emailField.id,
                                                hasABefore: false,
                                                type: "input",
                                            }}
                                            isFromCondition={false}
                                            field={emailField}
                                        />
                                    </span>
                                </p>
                            )}
                        </div>

                        <Signature owner={true} count={props.count} signatureHeadline="Company signature:" />
                    </div>
                </>
            )}
        </div>
    );
}
