import { useEffect, useRef, useState } from "react";
import { usePdfContext } from "../PdfContext";
import { BaseElement } from "./elements/BaseElement";
import { PdfElement } from "./elements/PdfElements";

interface Props {
    pageIndex: number;
}
export function PdfPageCanvas({ pageIndex }: Props) {
    const context = usePdfContext();

    const { pdfJsDoc, selectedElement, elements } = context.state;
    const pageElements = elements[pageIndex] ?? [];

    function updateElements(pageIndex: number, pageObjects: PdfElement[]) {
        context.updateState({
            elements: { ...elements, [pageIndex]: pageObjects },
        });
    }

    const [size, setSize] = useState({
        height: 0,
        width: 0,
    });

    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        pdfJsDoc?.getPage(pageIndex).then(function (page) {
            const canvas = canvasRef.current;
            if (!canvas) return;

            var viewport = page.getViewport({ scale: 1 });

            // Prepare canvas using PDF page dimensions
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            setSize(viewport);

            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {});
        });
    }, [pageIndex, pdfJsDoc]);

    return (
        <div
            id={`page-${pageIndex}`}
            className="border mx-auto  relative flex-shrink-0 scroll-m-20"
            style={{ width: size.width + "px", height: size.height + "px" }}
        >
            <canvas style={{ width: "100%", height: "100%" }} title="pdf" ref={canvasRef} id="pdf"></canvas>
            <div className="absolute w-f h-full left-0 right-0 top-0 bottom-0">
                {pageElements.map((object) => {
                    const isSelected = object.id === selectedElement?.id;

                    return (
                        <BaseElement
                            key={object.id}
                            element={object}
                            isSelected={isSelected}
                            updateElement={(element) =>
                                updateElements(
                                    pageIndex,
                                    pageElements.map((o: any) => (o.id === element.id ? element : o))
                                )
                            }
                            onClick={() => {
                                context.updateState({
                                    selectedElement: object,
                                });
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
