import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./SlidingOverlay.scss";

export default function SlidingOverlay(props: {
    children: JSX.Element[] | JSX.Element;
    closeUrl?: string;
    style?: CSSProperties | undefined;
}) {
    const [appear, setAppear] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            setAppear(true);
        }, 1)
        window.addEventListener("keydown", handleEsc);

        return () => {
            setTimeout(() => {
                setAppear(false);
            }, 1)
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    function handleEsc(event: KeyboardEvent) {
        event.stopPropagation()
        event.stopPropagation()
        event.stopImmediatePropagation()

        if (event.code === "Escape") {
            handleClose();
        }
    }

    function handleClose() {
        if (props.closeUrl) {
            setAppear(false);

            setTimeout(() => {
                history.push(props.closeUrl ?? "/");
            }, 500)
        }
    }

    return (
        <div
            onClick={() => {
                handleClose();
            }}
            className="h-screen w-screen bg-opacity-0 overflow-hidden absolute z-30 top-0 left-0 justify-center items-center">
            <div
                style={appear ? props.style : undefined}
                className={`SlidingOverlayContent h-screen absolute bg-white w-screen overflow-hidden z-40 flex`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
