import { fromAlpha, toAlpha } from "components/wizard-display/AlphaUtils";
import { PDoc } from "proto/PDoc";
import { useHistory } from "react-router-dom";
import { Selectors, useAppSelector } from "store";
import { WizardNavigation } from "./AgreementContextType";
import {
    getCurrentSection,
    getCurrentStep,
    getNextStepUrl,
    getSectionUrl,
    getStepUrl,
    getUrlsStats,
    SECTION_OFFSET,
    STEP_OFFSET,
    isHelpOpen,
    getCurrentContract,
    getSections,
    getAllFilteredSteps,
    getAllFilteredQuestions,
    getSectionSteps,
} from "./shared-api";

export const useNavigation = (props: { draft: PDoc | undefined }): WizardNavigation => {
    const history = useHistory();

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const currentAgreement = getCurrentContract(props.draft);

    const getActiveSectionAlpa = () => {
        const { sectionAlpa } = getUrlsStats();
        return sectionAlpa;
    };

    const isPaymentPage = () => getUrlsStats().isPaymentPage;

    const isFinishStep = () => {
        return getUrlsStats().sectionAlpa === "finish";
    };

    const isActiveStepByIndex = (index: number) => index + 1 === getUrlsStats().stepIndex;

    const navigateToSectionByIndex = (index: number) => {
        const url = getSectionUrl(index);
        history.push(url);
    };
    const navigateToStepByIndex = (index: number) => {
        const url = getStepUrl(index);
        history.push(url);
    };

    const goToQuestionByFieldName = (name: string) => {
        const field = agreement?.fields.find((f) => f.name === name);
        if (!field) return false;

        const questions = getAllFilteredQuestions(agreement, currentAgreement);
        const steps = getAllFilteredSteps(agreement, currentAgreement);

        const question = questions.find((q) => q?.fieldId === field.id);

        if (!question) return false;

        const step = steps.find((s) => s.questions.includes(question.id));

        step &&
            history.push({
                pathname: getStepPath(step),
                search:  `?field=${field.name}`
            });
        return true;
    };

    const goToQuestionByField = (field: Field) => {
        const questions = getAllFilteredQuestions(agreement, currentAgreement);
        const question = questions.find((q) => q.fieldId === field?.id || field.id === q.fieldId);

        if (!question) return;

        const step = getAllFilteredSteps(agreement, currentAgreement).find((s) => s.questions.includes(question.id));
        step && history.push(getStepPath(step));
    };

    const isLastSection = () => {
        const section = getActiveSectionAlpa();
        const index = fromAlpha(section) + 1;
        if (!agreement) return false;
        return index >= getSections(agreement, currentAgreement).length;
    };

    const isLastStep = () => {
        const paths = window.location.pathname.split("/").slice(-4);
        const [name, id, sectionAlpa, stepIndex] = paths;
        const section = getCurrentSection(agreement, currentAgreement);
        if (!section) return false;
        return isLastSection() && Number(stepIndex) >= getSectionSteps(section.id, agreement, currentAgreement).length;
    };

    const getStepPath = (step: Step) => {
        const { paths } = getUrlsStats();
        const sections = getSections(agreement, currentAgreement);

        const sectionIndex = sections.findIndex((s) => s.steps.includes(step.id));
        const section = sections.find((s) => s.steps.includes(step.id));
        if (!section) return paths.join("/");
        const selctionApla = toAlpha(sectionIndex + 1);
        const stepIndex = getSectionSteps(section.id, agreement, currentAgreement).findIndex((s) => s.id === step.id);

        let newPath = paths;
        newPath[paths.length - 2] = selctionApla;
        newPath[paths.length - 1] = (stepIndex + 1).toString();

        const result = newPath.join("/");

        return result;
    };

    function onNextStep() {
        if (!agreement?.wizard) return;

        const sections = getSections(agreement, currentAgreement);
        const steps = getAllFilteredSteps(agreement, currentAgreement);

        const newPath = getNextStepUrl(sections, steps);

        history.push(newPath);
    }

    const onBack = () => {
        if (!agreement) return;
        const paths = window.location.pathname.split("/");
        const [sectionAlpa, stepIndex] = paths.slice(-2);
        const isFIrstStep = +stepIndex === 1;
        const sectionIndex = fromAlpha(sectionAlpa);
        const isNotLst = fromAlpha(sectionAlpa);

        if (isFIrstStep && sectionIndex) {
            // go to prev section last step
            const sections = getSections(agreement, currentAgreement);

            const section = sections[sectionIndex - 1];
            paths[paths.length - SECTION_OFFSET] = toAlpha(sectionIndex);
            paths[paths.length - STEP_OFFSET] = getSectionSteps(section.id, agreement, currentAgreement).length.toString();
            history.push(paths.join("/"));

            return;
        }

        if (+stepIndex > 1) {
            paths[paths.length - STEP_OFFSET] = (+stepIndex - 1).toString();
            history.push(paths.join("/"));
        } else {
            //todo:  backto intro if exists
        }
    };

    return {
        getActiveSectionAlpa,
        getCurrentStep: () => getCurrentStep(agreement, currentAgreement),
        isActiveStepByIndex,
        navigateToSectionByIndex,
        navigateToStepByIndex,
        onBack,
        onNextStep,
        getStepPath,
        goToQuestionByFieldName,
        goToQuestionByField,
        isLastStep,
        isLastSection,
        isFinishStep,
        isHelpOpen,
        isPaymentPage,
    };
};
