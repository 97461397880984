import { Container } from 'components'
import React from 'react'

import MoneySavedImg from "img/weekly-report/coins.png"
import SignatureImg from "img/weekly-report/signature.png"
import SentImg from "img/weekly-report/sent.png"
import DocsImg from "img/weekly-report/docs.png"
import UploadImg from "img/weekly-report/uploaded.png"
import NewImg from "img/weekly-report/new.png"
import { MainStore } from 'store/mainTypes'
import { useSelector } from 'react-redux'
import { PDoc } from 'proto/PDoc'
import { useHistory } from 'react-router'
import { getAutoContracts } from 'apps/legal-ide/App/components/WizardUi/auto-contracts'

export default function WeeklyReport() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
    const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);

    const uploadedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.uploadedDocs);
    const uploadedDocsThisWeek = createdThisWeek([uploadedDocs]);

    const totalDocs = drafts.length + sentDocs.length + inboxDocs.length + completedDocs.length + uploadedDocs.length;
    const totalDocsCreatedThisWeek = createdThisWeek([drafts, sentDocs, inboxDocs, completedDocs, uploadedDocs]);

    const newContractTypes = getNewContractTypes();

    const history = useHistory()

  return (
    <div className="w-full flex-col bg-powderBlue bg-opacity-20 flex justify-start">
        <Container className='pb-8'>
            <div className="mt-8 max-w-xl mx-auto bg-white rounded-xl shadow-cardHover overflow-hidden lg:max-w-3xl p-8">
                <div className="tracking-wide text-3xl text-darkIndigo font-semibold">Your Weekly Report</div>
                <div className="mt-4 text-darkIndigo text-lg">Here are some insights for you for the week of {lastWeekDuration()}</div>

                <br/>

                <div className="flex flex-col gap-4 lg:gap-1">
                    <Stripe img={NewImg} title={`Try out our new tool! Clawdia AI can now help you edit and sign PDFs!`}
                    description={<span>
                        You can try out our new tool&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/tools")
                        }}>here</span>!
                    </span>} />

                    <Stripe img={DocsImg} title={totalDocsCreatedThisWeek > 0 ?
                    `You've created ${totalDocsCreatedThisWeek} docs this week!` : `You've created ${totalDocs} docs so far!`}
                    description={totalDocsCreatedThisWeek > 0 ?
                    <span>
                        Congratulations! keep using Clawdia to safely manage your legal environment. Create more&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/library")
                        }}>here</span>!
                    </span> :
                    <span>
                        But you have yet to create a document this week. Create new documents&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/library")
                        }}>here</span>!
                    </span>
                    } />

                    <Stripe img={MoneySavedImg} title={totalDocsCreatedThisWeek > 0 ?
                    `You've saved $${(totalDocsCreatedThisWeek * 500).toLocaleString()} in legal fees this week!` :
                    `You've saved $${(totalDocs * 500).toLocaleString()} in legal fees so far!`}    
                    description={
                    <span>
                        Amazing! By taking care of these legal matters, you've saved an estimated&nbsp;
                        ${(totalDocs * 500).toLocaleString()} in legal fees so far in total.
                        </span>
                    } />

                    <Stripe img={UploadImg} title={uploadedDocsThisWeek > 0 ?
                    `You've uploaded ${uploadedDocsThisWeek} documents this week!` :
                    `You've uploaded ${uploadedDocs.length} documents so far!`}    
                    description={(uploadedDocsThisWeek > 0 || uploadedDocs.length > 0) ?
                    <span>
                        Nice! Upload more documents&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/docs/uploaded")
                        }}>here</span>!
                    </span> :
                    <span>
                        Try out uploading documents for analysis&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/docs/uploaded")
                        }}>here</span>!
                    </span>
                    } />

                    <Stripe img={SentImg} title={`You have shared ${sentDocs.length} documents so far!`}
                    description={
                    <span>
                        Wonderful! You can remind your recipients to e-sign &nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/docs/out-for-signature")
                        }}>here</span>!
                    </span>
                    } />

                    <Stripe img={SignatureImg} title={`You have ${inboxDocs.length} documents awaiting your signature!`}
                    description={inboxDocs.length > 0 ?
                    <span>
                        Great! You can e-sign and complete the documents&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/docs/awaiting-signature")
                        }}>here</span>!
                    </span> :
                    <span>
                        Great! You have no documents awaiting your signature. You can create new docs and share them&nbsp;
                        <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                            history.push("/library")
                        }}>here</span>!
                    </span>
                    } />

                    {newContractTypes.length > 0 &&
                        <Stripe img={NewImg} title={`There is ${newContractTypes.length} new legal topic!`}
                        description={<span>
                            Here are the new legal topics that Clawdia AI can help you with:
                            <br/>
                            <div className="flex flex-col">
                            {newContractTypes.map(contract => {
                                return <span className="font-semibold text-primary text-underline cursor-pointer" onClick={() => {
                                    history.push(`/${contract.kind}/gen-new`)
                                }}>{contract.name}</span>
                            }
                            )}
                            </div>
                            </span>} />
                        }
                </div>
            </div>
      </Container>
    </div>
    )
}

function Stripe({img, title, description}: {img: string, title: string, description: React.ReactNode}) {
    return (
        <div className="flex flex-col lg:flex-row items-center gap-0 lg:gap-1 lg:h-44">
            <div className={`flex h-full justify-center items-center flex-shrink-0 bg-primary bg-opacity-60 rounded-t-md lg:rounded-tr-none lg:rounded-l-md w-full lg:w-44 py-8 lg:py-0`}>
                <img src={img} className="w-16 h-16" />
            </div>
            <div className='w-full h-full bg-secondary p-6 bg-opacity-25 rounded-b-md lg:rounded-bl-none lg:rounded-r-md'>
                <p className="font-semibold text-2xl">{title}</p>
                <p className="text-gray-500 text-lg mt-2">{description}</p>
            </div>
        </div>
    )
}

function lastWeekDuration() {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    return `${lastWeek.toLocaleString('default', { month: 'long' })} ${lastWeek.getDate()} - ${today.toLocaleString('default', { month: 'long' })} ${today.getDate()}`
}

function createdThisWeek(docsCategories: PDoc[][]) {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    let count = 0;

    docsCategories.forEach(docs => {
        docs.forEach(doc => {
            const created = new Date(doc.legalUnit?.createdAt?.getTime() || 0);

            if (created > lastWeek) {
                count++;
            }
        })
    }) 

    return count;

}

function getNewContractTypes() {
    const autoContracts = getAutoContracts();

    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    return autoContracts.filter(contract => {
        const created = new Date(1000 * (contract.timeAddedInSeconds || 0));

        if (created > lastWeek) {
            return true;
        }

        return false;
    })
}