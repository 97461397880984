import { Button, Container, SvgIconUpload } from "components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useTemplatesStore } from "./store";

import html2canvas from "html2canvas";

import { TemplateApi } from "api-services/templatesApi";
import { nanoid } from "nanoid";

import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { ContentMock } from "./ContentMock";
import { TemplateCanvas } from "./TemplateCanvas";
import { useDispatch } from "react-redux";
import { callDialog } from "store/mainActions";
import { DialogType } from "store/mainTypes";

const RemoveIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-secondary"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <line x1="4" y1="7" x2="20" y2="7"></line>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
    </svg>
);

export const TemplatesEditor = () => {
    const params = useParams<{ id: string }>();

    const template = useTemplatesStore((state) => state.currentTemplate);
    const templatesStore = useTemplatesStore();

    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const [isEditable, setIsEditable] = useState(true);
    const [onSave, setOnSave] = useState(false);

    useEffect(() => {
        TemplateApi.getTemplate(params.id, (res) => {
            templatesStore.setCurrentTemplate(res.template ?? null);
        });
        return () => templatesStore.setCurrentTemplate(null);
    }, [params.id]);

    useEffect(() => {
        template && updateTemplate();
    }, [template]);

    useEffect(() => {
        if (onSave) {
            if (!template?.templateId) return;
            let element = ref.current!;
            html2canvas(element).then((c) => {
                const url = c.toDataURL();
                TemplateApi.saveTemplate(template?.templateId, url, (res) => {
                    dispatch(
                        callDialog(
                            DialogType.SUCCESS,
                            "Template successfully saved. Now go back to your document and use it to download branded PDFs."
                        )
                    );
                });

                setOnSave(false);
                setIsEditable(true);
                return;
            });
        }
    }, [onSave]);
    const formRef = useRef<HTMLFormElement>(null);

    if (!template || !template?.design) return null;

    const { backgrounds = [], primaryBackground, primaryColor } = template.design;

    async function updateTemplate() {
        if (!template) return;

        TemplateApi.updateTemplate(template.templateId, template, (res) => {});
    }

    async function save() {
        setIsEditable(false);
        setOnSave(true);
    }

    const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            templatesStore.addBackground({
                id: nanoid(6),
                name: file.name,
                position: {
                    x: 100,
                    y: 100,
                },
                size: {
                    height: 100,
                    width: 100,
                },
                repeat: "no-repeat",
                src: e.target?.result as string,
            });
        };
        reader.readAsDataURL(file);
    };

    const uploadCoverImage = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            templatesStore.setCoverImage({
                img: {
                    id: nanoid(6),
                    name: file.name,
                    position: {
                        x: 100,
                        y: 100,
                    },
                    size: {
                        height: 100,
                        width: 100,
                    },

                    repeat: "no-repeat",
                    src: e.target?.result as string,
                },
            });
        };
        reader.readAsDataURL(file);
    };

    return (
        <DragDropContext
            onDragEnd={(result) => {
                const { destination, source } = result;
                if (!destination) {
                    return;
                }

                if (destination.droppableId === source.droppableId && destination.index === source.index) {
                    return;
                }
                templatesStore.reorderBackground({
                    srcIndex: source.index,
                    distIndex: destination.index,
                });
            }}
        >
            <div className={`w-full bg-primaryBg`}>
                <Container className="flex min-h-screen gap-6 py-6">
                    <div className="max-h-full overflow-y-auto w-[300px] rounded-lg  sticky top-4 p-4 flex-shrink-0 bg-primaryVeryLight text-darkIndigo flex flex-col gap-6">
                        <div className="flex items-center justify-between gap-2">
                            <label className="" htmlFor="">
                                Name
                            </label>
                            <input
                                className="px-2 py-1 w-full"
                                value={template.name}
                                onChange={(e) => {
                                    templatesStore.setName({ name: e.target.value });
                                }}
                                type="text"
                            />
                        </div>

                        <div>
                            <Button color="primary" onClick={save}>
                                Save Template
                            </Button>
                        </div>

                        <div className="flex items-center justify-between">
                            <label className="" htmlFor="">
                                Font Color
                            </label>
                            <input
                                value={primaryColor}
                                onChange={(e) => {
                                    templatesStore.setColor({ templateId: params.id, color: e.target.value });
                                }}
                                type="color"
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="" htmlFor="">
                                Backgorund Color
                            </label>
                            <input
                                value={primaryBackground}
                                onChange={(e) => {
                                    templatesStore.setPrimaryBackgorund({
                                        templateId: params.id,
                                        color: e.target.value,
                                    });
                                }}
                                type="color"
                            />
                        </div>
                        <form ref={formRef} className="flex items-center justify-between">
                            <div className="">
                                <button
                                    type="button"
                                    className="bg-seaweed text-white rounded-md py-2 px-6 cursor-pointer"
                                >
                                    <label
                                        className="cursor-pointer w-full h-full flex items-center gap-2"
                                        htmlFor={`src-cover-image`}
                                    >
                                        <SvgIconUpload />
                                        <span className="whitespace-nowrap font-semibold  text-xs">
                                            Upload Cover Image
                                        </span>
                                    </label>
                                    <input id={`src-cover-image`} type="file" hidden onChange={uploadCoverImage} />
                                </button>
                            </div>
                        </form>
                        {template.design.coverBackground && (
                            <div className="flex items-center justify-between gap-2 max-h-[300px]">
                                <div className="flex-grow">
                                    <img
                                        src={template.design.coverBackground.src}
                                        alt=""
                                        className="max-h-full max-w-full  rounded-md object-contain"
                                    />
                                </div>

                                <button
                                    type="button"
                                    className="flex-shrink-0"
                                    onClick={() => {
                                        formRef.current?.reset();
                                        templatesStore.setCoverImage({
                                            img: undefined,
                                        });
                                    }}
                                >
                                    {RemoveIcon}
                                </button>
                            </div>
                        )}
                        <div className="flex items-center justify-between">
                            <div className="">
                                <button className="bg-seaweed text-white rounded-md py-2 px-6 cursor-pointer">
                                    <label
                                        className="cursor-pointer w-full h-full flex items-center gap-2"
                                        htmlFor={`src-add-image`}
                                    >
                                        <SvgIconUpload />
                                        <span className="whitespace-nowrap font-semibold text-xs"> Upload Image</span>
                                    </label>
                                    <input id={`src-add-image`} type="file" hidden onChange={(e) => uploadImage(e)} />
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <Droppable droppableId="background" type="bg">
                                {(provided) => (
                                    <div
                                        className="flex flex-col gap-4"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {backgrounds.map((background, index) => (
                                            <Draggable draggableId={background.id} key={background.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        className="py-4 flex flex-col gap-4  shadow-xl rounded-lg p-4"
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <div className="flex items-center justify-between gap-2">
                                                            <label
                                                                className="cursor-pointer w-12 h-12 shadow-sm flex items-center gap-2"
                                                                htmlFor={`src-${background.id}`}
                                                            >
                                                                <img
                                                                    src={background.src}
                                                                    alt=""
                                                                    className="object-cover max-h-full w-full h-full rounded-md"
                                                                />
                                                            </label>
                                                            <span className="mr-auto text-gray-500">
                                                                {background.name}
                                                            </span>
                                                            <button
                                                                onClick={() => {
                                                                    templatesStore.removeBackground(background.id);
                                                                }}
                                                            >
                                                                {RemoveIcon}
                                                            </button>
                                                            <input
                                                                id={`src-${background.id}`}
                                                                type="file"
                                                                hidden
                                                                onChange={(e) => {
                                                                    const file = e.target.files?.[0];
                                                                    if (!file) return;
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e) => {
                                                                        templatesStore.updateBackground({
                                                                            backgroundId: background.id,
                                                                            name: "src",
                                                                            value: e.target?.result,
                                                                        });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        <div>{provided.placeholder}</div>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <div
                        className="relative flex-grow shadow-md bg-white overflow-hidden border rounded-sm border-skyBlue flex-shrink-0"
                        style={{ height: "297mm", width: "210mm" }}
                    >
                        <TemplateCanvas
                            onMove={(move) => {
                                templatesStore.updateBackground({
                                    backgroundId: move.id,
                                    name: "position",
                                    value: { x: move.x, y: move.y },
                                });
                            }}
                            onResize={(resize) => {
                                templatesStore.updateBackground({
                                    backgroundId: resize.id,
                                    name: "size",
                                    value: {
                                        width: resize.width,
                                        height: resize.height,
                                    },
                                });
                            }}
                            template={template}
                            isEditable={isEditable}
                            ref={ref}
                        />
                        <ContentMock style={{ color: template.design.primaryColor }} />
                    </div>
                </Container>
            </div>
        </DragDropContext>
    );
};
