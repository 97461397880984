import { clipboardApi } from "api";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowExplanations } from "store/mainActions";
import { MainStore } from "store/mainTypes";

export default function CopyToClipboard({
    btnCaption,
    type,
    getContent,
    icon,
}: {
    btnCaption: string;
    type: string;
    getContent: Function;
    icon?: string;
}) {
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();

    const copyToClipboard = () => {
        // TODO: remove dependency on showExplanations
        dispatch(setShowExplanations(false));

        setTimeout(() => {
            const content = getContent();

            clipboardApi.writeText(content).then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                    dispatch(setShowExplanations(true));
                }, 500);
            });
        }, 100);
    };

    const btnStyle = copied ? "bg-gray-300 text-white" : "";

    return (
        <button
            onClick={copyToClipboard}
            className={
                btnStyle +
                " text-xs w-32 sm:w-36 p-4 transition text-center my-5 bg-knowledge hover:bg-knowledgeHover rounded-2xl"
            }
        >
            <div className="flex flex-col justify-center text-sm sm:text-lg font-bold items-center gap-2">
                {icon && <img className="w-10 inline-block" src={icon} />}
                {copied ? "Copied" : btnCaption}
            </div>
        </button>
    );
}
