/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum InternalBoolean {
  NOT_AN_OPTION = 0,
  NO = 1,
  YES = 2,
  UNRECOGNIZED = -1,
}

export function internalBooleanFromJSON(object: any): InternalBoolean {
  switch (object) {
    case 0:
    case "NOT_AN_OPTION":
      return InternalBoolean.NOT_AN_OPTION;
    case 1:
    case "NO":
      return InternalBoolean.NO;
    case 2:
    case "YES":
      return InternalBoolean.YES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InternalBoolean.UNRECOGNIZED;
  }
}

export function internalBooleanToJSON(object: InternalBoolean): string {
  switch (object) {
    case InternalBoolean.NOT_AN_OPTION:
      return "NOT_AN_OPTION";
    case InternalBoolean.NO:
      return "NO";
    case InternalBoolean.YES:
      return "YES";
    case InternalBoolean.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PFile {
  content: string;
  name: string;
  type: string;
}

export interface PTable {
  rows: PTableRow[];
}

export interface PTableRow {
  data: PTableData[];
}

export interface PTableData {
  key: string;
  value: string;
}

function createBasePFile(): PFile {
  return { content: "", name: "", type: "" };
}

export const PFile = {
  encode(message: PFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.content !== "") {
      writer.uint32(10).string(message.content);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.content = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PFile {
    return {
      content: isSet(object.content) ? String(object.content) : "",
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
    };
  },

  toJSON(message: PFile): unknown {
    const obj: any = {};
    message.content !== undefined && (obj.content = message.content);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  create<I extends Exact<DeepPartial<PFile>, I>>(base?: I): PFile {
    return PFile.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PFile>, I>>(object: I): PFile {
    const message = createBasePFile();
    message.content = object.content ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBasePTable(): PTable {
  return { rows: [] };
}

export const PTable = {
  encode(message: PTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rows) {
      PTableRow.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.rows.push(PTableRow.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTable {
    return { rows: Array.isArray(object?.rows) ? object.rows.map((e: any) => PTableRow.fromJSON(e)) : [] };
  },

  toJSON(message: PTable): unknown {
    const obj: any = {};
    if (message.rows) {
      obj.rows = message.rows.map((e) => e ? PTableRow.toJSON(e) : undefined);
    } else {
      obj.rows = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PTable>, I>>(base?: I): PTable {
    return PTable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTable>, I>>(object: I): PTable {
    const message = createBasePTable();
    message.rows = object.rows?.map((e) => PTableRow.fromPartial(e)) || [];
    return message;
  },
};

function createBasePTableRow(): PTableRow {
  return { data: [] };
}

export const PTableRow = {
  encode(message: PTableRow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      PTableData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTableRow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTableRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.data.push(PTableData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTableRow {
    return { data: Array.isArray(object?.data) ? object.data.map((e: any) => PTableData.fromJSON(e)) : [] };
  },

  toJSON(message: PTableRow): unknown {
    const obj: any = {};
    if (message.data) {
      obj.data = message.data.map((e) => e ? PTableData.toJSON(e) : undefined);
    } else {
      obj.data = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PTableRow>, I>>(base?: I): PTableRow {
    return PTableRow.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTableRow>, I>>(object: I): PTableRow {
    const message = createBasePTableRow();
    message.data = object.data?.map((e) => PTableData.fromPartial(e)) || [];
    return message;
  },
};

function createBasePTableData(): PTableData {
  return { key: "", value: "" };
}

export const PTableData = {
  encode(message: PTableData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTableData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTableData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTableData {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: PTableData): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTableData>, I>>(base?: I): PTableData {
    return PTableData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTableData>, I>>(object: I): PTableData {
    const message = createBasePTableData();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
