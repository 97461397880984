import { wixRegisterAppInstall } from "api-services/WixApi";
import { isWixFlow } from "components/utils/UtilFunctions";
import firebase from "firebase/app";
import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "store";
import { NotificationTypes } from "store/features";
import { getUser } from "../../api-services/UseresApi";
import { PGetUserResponse } from "../../proto/PApi";
import { PUserStatus } from "../../proto/PUser";
import { setDBUser } from "../../store/mainActions";
import { MainStore } from "../../store/mainTypes";
import { isProd } from "../utils/EnvResolver";
import { GTMHelper } from "../utils/GTMHelper";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { DudaApi } from "api-services/DudaApi";

export interface UserDetails {
    loading: boolean;
    user: firebase.User | null | undefined;
    userStatus: PUserStatus;
    setUserStatus: Function;
    login: Function;
    signup: Function;
    logout: Function;
    resetPassword: Function;
    updateEmail: Function;
    updatePassword: Function;
    getFirstName: Function;
}

export const AuthContext = React.createContext<UserDetails | null>(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props: { children: JSX.Element }) {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>();
    const [loading, setLoading] = useState(true);
    const [userStatus, setUserStatus] = useState<PUserStatus>(PUserStatus.PRE_ONBOARDING);
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const dispatch = useDispatch();
    const history = useHistory();

    function signup(email: string, password: string) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email: string, password: string) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email: string) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email: string) {
        return currentUser?.updateEmail(email);
    }

    function updatePassword(password: string) {
        return currentUser?.updatePassword(password);
    }

    function getFirstName() {
        return dbUser?.fullName?.split(/\s+/)[0];
    }

    async function registerIfWixUser() {
        // wixFlow 3 - register wix user in db (dbUser.isWixUser + wixDetails {email instanceId refresh_token})
        const code = localStorage.getItem("marketing_code");
        const instanceId = localStorage.getItem("marketing_instanceId");

        const isWixAppInstallFlow = code && instanceId;
        if (isWixAppInstallFlow && isWixFlow()) {
            const result = await wixRegisterAppInstall({
                code: code ?? "",
                instanceId: instanceId ?? "",
            });
            if (result.success) {
                getUser(async (resp: PGetUserResponse) => {
                    setUserStatus(resp?.user?.userStatus || PUserStatus.PRE_ONBOARDING);
                    dispatch(setDBUser(resp?.user));
                });
                localStorage.removeItem("marketing_code");
                localStorage.removeItem("marketing_instanceId");
            }
        }
    }

    // todo - make this less error-prone
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        // Shopify
        const token = urlParams.get("token");

        // Duda
        const timestamp = urlParams.get("timestamp");
        const siteName = urlParams.get("site_name");
        const sdkUrl = urlParams.get("sdk_url");
        const secureSig = urlParams.get("secure_sig");

        if (token) {
            // Shopify user
            firebase
                .auth()
                .signInWithCustomToken(token)
                .then((data) => {
                    setCurrentUser(data.user);

                    getUser(async (resp: PGetUserResponse) => {
                        await setUserStatus(resp?.user?.userStatus || PUserStatus.PRE_ONBOARDING);
                        await dispatch(setDBUser(resp?.user));

                        setLoading(false);
                    });
                });
        } else if (timestamp && siteName && sdkUrl && secureSig) {
            // Duda user

            // Embed the Duda SDK to the document
            const script = document.createElement("script");
            script.id = "duda-sdk";
            script.src = sdkUrl;
            script.async = true;
            document.body.appendChild(script);

            const unsubscribe = () => document.body.removeChild(script);
            DudaApi.authenticate({
                timestamp,
                siteName,
                sdkUrl,
                secureSig,
            }).then((data) => {
                firebase
                    .auth()
                    .signInWithCustomToken(data.token)
                    .then((data) => {
                        setCurrentUser(data.user);

                        getUser(async (resp: PGetUserResponse) => {
                            await setUserStatus(
                                resp?.user?.userStatus || PUserStatus.PRE_ONBOARDING
                            );
                            await dispatch(setDBUser(resp?.user));

                            setLoading(false);
                        });
                    });
            });

            return unsubscribe;
        } else {
            const unsubscribe = auth.onAuthStateChanged(
                (firebaseUser: React.SetStateAction<firebase.User | null | undefined>) => {
                    if (!firebaseUser) {
                        setLoading(false);
                        return;
                    }

                    setCurrentUser(firebaseUser);

                    getUser(async (resp: PGetUserResponse) => {
                        await setUserStatus(resp?.user?.userStatus || PUserStatus.PRE_ONBOARDING);

                        await dispatch(setDBUser(resp?.user));
                        registerIfWixUser(); // todo: find way update user profile before on boarding

                        if (resp.user?.email) {
                            if (isProd()) {
                                if (resp.user.userStatus === PUserStatus.PRE_ONBOARDING) {
                                    mixpanel.track("Signup");
                                    mixpanel.alias(resp.user.email);
                                    GTMHelper.push({ event: "signup" });
                                } else {
                                    mixpanel.track("Login");
                                    mixpanel.identify(resp.user.email);
                                }
                            }

                            addPersonalizedNotification(resp.user?.email, dispatch);
                        }

                        // todo - potential bug in case of failure
                        setLoading(false);
                    });
                }
            );

            return unsubscribe;
        }
    }, []);

    const value: UserDetails = {
        loading,
        user: currentUser,
        userStatus: userStatus,
        setUserStatus: setUserStatus,
        login: login,
        signup: signup,
        logout: logout,
        resetPassword: resetPassword,
        updateEmail: updateEmail,
        updatePassword: updatePassword,
        getFirstName: getFirstName,
    };

    // todo
    return <AuthContext.Provider value={value}>{!loading && props.children}</AuthContext.Provider>;
}

function addPersonalizedNotification(email: string, dispatch: Function) {
    if (email.endsWith("@clawdia.ai")) {
        dispatch(
            Actions.notifications.add({
                type: NotificationTypes.NEW_FEATURE,
                creationDate: new Date(),
                message:
                    "Dear Vardit, since your company is incorporated in NY, I thought you should know that as of Nov. 1st, 2022 companies operating in NYC, must include salary ranges and compensations in their job postings. The intention of the new bill is to prevent forms of pay gap and discrimination.",
                title: "New York City Salary Disclosure Bill",
            })
        );
    }
}
