/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile, PTable } from "../PUtils";

export const protobufPackage = "";

export enum EConvertibleNotePurchaseAgreementCompanyEntityType {
  ConvertibleNotePurchaseAgreement_companyEntityType_NA = 0,
  ConvertibleNotePurchaseAgreement_companyEntityType_CORPORATION = 1,
  ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  ConvertibleNotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP = 4,
  ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  ConvertibleNotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION = 8,
  ConvertibleNotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  ConvertibleNotePurchaseAgreement_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementCompanyEntityTypeFromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementCompanyEntityType {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_NA":
      return EConvertibleNotePurchaseAgreementCompanyEntityType.ConvertibleNotePurchaseAgreement_companyEntityType_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_CORPORATION":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_CORPORATION;
    case 2:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "ConvertibleNotePurchaseAgreement_companyEntityType_PARTNERSHIP":
      return EConvertibleNotePurchaseAgreementCompanyEntityType
        .ConvertibleNotePurchaseAgreement_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementCompanyEntityType.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementCompanyEntityTypeToJSON(
  object: EConvertibleNotePurchaseAgreementCompanyEntityType,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementCompanyEntityType.ConvertibleNotePurchaseAgreement_companyEntityType_NA:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_NA";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_CORPORATION:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_CORPORATION";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_SOLE_PROPRIETORSHIP";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_LIMITED_PARTNERSHIP";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_CORPORATION";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_NONPROFIT_CORPORATION";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EConvertibleNotePurchaseAgreementCompanyEntityType
      .ConvertibleNotePurchaseAgreement_companyEntityType_PARTNERSHIP:
      return "ConvertibleNotePurchaseAgreement_companyEntityType_PARTNERSHIP";
    case EConvertibleNotePurchaseAgreementCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementJurisdictions {
  ConvertibleNotePurchaseAgreement_jurisdictions_NA = 0,
  ConvertibleNotePurchaseAgreement_jurisdictions_NONE = 1,
  ConvertibleNotePurchaseAgreement_jurisdictions_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementJurisdictionsFromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementJurisdictions {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_jurisdictions_NA":
      return EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_jurisdictions_NONE":
      return EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_jurisdictions_OTHER":
      return EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementJurisdictions.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementJurisdictionsToJSON(
  object: EConvertibleNotePurchaseAgreementJurisdictions,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_NA:
      return "ConvertibleNotePurchaseAgreement_jurisdictions_NA";
    case EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_NONE:
      return "ConvertibleNotePurchaseAgreement_jurisdictions_NONE";
    case EConvertibleNotePurchaseAgreementJurisdictions.ConvertibleNotePurchaseAgreement_jurisdictions_OTHER:
      return "ConvertibleNotePurchaseAgreement_jurisdictions_OTHER";
    case EConvertibleNotePurchaseAgreementJurisdictions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure31 {
  ConvertibleNotePurchaseAgreement_disclosure31_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure31_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure31_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure31FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure31 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure31_NA":
      return EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure31_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure31_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure31.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure31ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure31,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure31_NA";
    case EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure31_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure31.ConvertibleNotePurchaseAgreement_disclosure31_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure31_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure31.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure32 {
  ConvertibleNotePurchaseAgreement_disclosure32_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure32_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure32_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure32FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure32 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure32_NA":
      return EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure32_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure32_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure32.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure32ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure32,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure32_NA";
    case EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure32_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure32.ConvertibleNotePurchaseAgreement_disclosure32_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure32_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure32.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure33 {
  ConvertibleNotePurchaseAgreement_disclosure33_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure33_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure33_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure33FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure33 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure33_NA":
      return EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure33_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure33_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure33.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure33ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure33,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure33_NA";
    case EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure33_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure33.ConvertibleNotePurchaseAgreement_disclosure33_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure33_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure33.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure34 {
  ConvertibleNotePurchaseAgreement_disclosure34_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure34_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure34_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure34FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure34 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure34_NA":
      return EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure34_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure34_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure34.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure34ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure34,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure34_NA";
    case EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure34_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure34.ConvertibleNotePurchaseAgreement_disclosure34_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure34_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure34.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure35 {
  ConvertibleNotePurchaseAgreement_disclosure35_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure35_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure35_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure35FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure35 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure35_NA":
      return EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure35_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure35_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure35.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure35ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure35,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure35_NA";
    case EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure35_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure35.ConvertibleNotePurchaseAgreement_disclosure35_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure35_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure35.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure36 {
  ConvertibleNotePurchaseAgreement_disclosure36_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure36_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure36_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure36FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure36 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure36_NA":
      return EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure36_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure36_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure36.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure36ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure36,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure36_NA";
    case EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure36_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure36.ConvertibleNotePurchaseAgreement_disclosure36_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure36_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure36.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure37 {
  ConvertibleNotePurchaseAgreement_disclosure37_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure37_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure37_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure37FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure37 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure37_NA":
      return EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure37_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure37_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure37.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure37ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure37,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure37_NA";
    case EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure37_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure37.ConvertibleNotePurchaseAgreement_disclosure37_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure37_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure37.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure38 {
  ConvertibleNotePurchaseAgreement_disclosure38_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure38_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure38_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure38FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure38 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure38_NA":
      return EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure38_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure38_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure38.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure38ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure38,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure38_NA";
    case EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure38_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure38.ConvertibleNotePurchaseAgreement_disclosure38_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure38_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure38.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure39 {
  ConvertibleNotePurchaseAgreement_disclosure39_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure39_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure39_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure39FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure39 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure39_NA":
      return EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure39_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure39_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure39.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure39ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure39,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure39_NA";
    case EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure39_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure39.ConvertibleNotePurchaseAgreement_disclosure39_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure39_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure39.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure310 {
  ConvertibleNotePurchaseAgreement_disclosure310_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure310_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure310_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure310FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure310 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure310_NA":
      return EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure310_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure310_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure310.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure310ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure310,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure310_NA";
    case EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure310_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure310.ConvertibleNotePurchaseAgreement_disclosure310_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure310_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure310.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure311 {
  ConvertibleNotePurchaseAgreement_disclosure311_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure311_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure311_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure311FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure311 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure311_NA":
      return EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure311_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure311_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure311.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure311ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure311,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure311_NA";
    case EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure311_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure311.ConvertibleNotePurchaseAgreement_disclosure311_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure311_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure311.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure312 {
  ConvertibleNotePurchaseAgreement_disclosure312_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure312_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure312_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure312FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure312 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure312_NA":
      return EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure312_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure312_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure312.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure312ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure312,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure312_NA";
    case EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure312_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure312.ConvertibleNotePurchaseAgreement_disclosure312_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure312_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure312.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure313 {
  ConvertibleNotePurchaseAgreement_disclosure313_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure313_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure313_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure313FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure313 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure313_NA":
      return EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure313_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure313_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure313.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure313ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure313,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure313_NA";
    case EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure313_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure313.ConvertibleNotePurchaseAgreement_disclosure313_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure313_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure313.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure314 {
  ConvertibleNotePurchaseAgreement_disclosure314_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure314_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure314_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure314FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure314 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure314_NA":
      return EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure314_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure314_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure314.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure314ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure314,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure314_NA";
    case EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure314_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure314.ConvertibleNotePurchaseAgreement_disclosure314_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure314_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure314.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure315 {
  ConvertibleNotePurchaseAgreement_disclosure315_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure315_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure315_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure315FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure315 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure315_NA":
      return EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure315_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure315_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure315.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure315ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure315,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure315_NA";
    case EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure315_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure315.ConvertibleNotePurchaseAgreement_disclosure315_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure315_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure315.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure316 {
  ConvertibleNotePurchaseAgreement_disclosure316_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure316_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure316_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure316FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure316 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure316_NA":
      return EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure316_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure316_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure316.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure316ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure316,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure316_NA";
    case EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure316_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure316.ConvertibleNotePurchaseAgreement_disclosure316_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure316_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure316.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure317 {
  ConvertibleNotePurchaseAgreement_disclosure317_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure317_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure317_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure317FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure317 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure317_NA":
      return EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure317_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure317_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure317.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure317ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure317,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure317_NA";
    case EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure317_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure317.ConvertibleNotePurchaseAgreement_disclosure317_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure317_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure317.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure318 {
  ConvertibleNotePurchaseAgreement_disclosure318_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure318_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure318_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure318FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure318 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure318_NA":
      return EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure318_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure318_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure318.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure318ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure318,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure318_NA";
    case EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure318_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure318.ConvertibleNotePurchaseAgreement_disclosure318_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure318_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure318.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure319 {
  ConvertibleNotePurchaseAgreement_disclosure319_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure319_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure319_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure319FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure319 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure319_NA":
      return EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure319_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure319_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure319.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure319ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure319,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure319_NA";
    case EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure319_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure319.ConvertibleNotePurchaseAgreement_disclosure319_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure319_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure319.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure320 {
  ConvertibleNotePurchaseAgreement_disclosure320_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure320_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure320_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure320FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure320 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure320_NA":
      return EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure320_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure320_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure320.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure320ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure320,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure320_NA";
    case EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure320_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure320.ConvertibleNotePurchaseAgreement_disclosure320_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure320_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure320.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure321 {
  ConvertibleNotePurchaseAgreement_disclosure321_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure321_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure321_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure321FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure321 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure321_NA":
      return EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure321_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure321_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure321.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure321ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure321,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure321_NA";
    case EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure321_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure321.ConvertibleNotePurchaseAgreement_disclosure321_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure321_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure321.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure322 {
  ConvertibleNotePurchaseAgreement_disclosure322_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure322_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure322_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure322FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure322 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure322_NA":
      return EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure322_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure322_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure322.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure322ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure322,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure322_NA";
    case EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure322_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure322.ConvertibleNotePurchaseAgreement_disclosure322_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure322_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure322.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure323 {
  ConvertibleNotePurchaseAgreement_disclosure323_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure323_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure323_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure323FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure323 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure323_NA":
      return EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure323_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure323_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure323.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure323ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure323,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure323_NA";
    case EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure323_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure323.ConvertibleNotePurchaseAgreement_disclosure323_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure323_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure323.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure49 {
  ConvertibleNotePurchaseAgreement_disclosure49_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure49_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure49_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure49FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure49 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure49_NA":
      return EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure49_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure49_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure49.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure49ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure49,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure49_NA";
    case EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure49_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure49.ConvertibleNotePurchaseAgreement_disclosure49_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure49_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure49.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure41 {
  ConvertibleNotePurchaseAgreement_disclosure41_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure41_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure41_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure41FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure41 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure41_NA":
      return EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure41_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure41_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure41.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure41ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure41,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure41_NA";
    case EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure41_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure41.ConvertibleNotePurchaseAgreement_disclosure41_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure41_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure41.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure42 {
  ConvertibleNotePurchaseAgreement_disclosure42_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure42_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure42_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure42FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure42 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure42_NA":
      return EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure42_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure42_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure42.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure42ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure42,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure42_NA";
    case EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure42_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure42.ConvertibleNotePurchaseAgreement_disclosure42_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure42_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure42.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure43 {
  ConvertibleNotePurchaseAgreement_disclosure43_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure43_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure43_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure43FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure43 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure43_NA":
      return EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure43_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure43_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure43.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure43ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure43,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure43_NA";
    case EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure43_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure43.ConvertibleNotePurchaseAgreement_disclosure43_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure43_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure43.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure44 {
  ConvertibleNotePurchaseAgreement_disclosure44_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure44_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure44_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure44FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure44 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure44_NA":
      return EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure44_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure44_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure44.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure44ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure44,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure44_NA";
    case EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure44_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure44.ConvertibleNotePurchaseAgreement_disclosure44_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure44_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure44.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure45 {
  ConvertibleNotePurchaseAgreement_disclosure45_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure45_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure45_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure45FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure45 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure45_NA":
      return EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure45_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure45_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure45.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure45ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure45,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure45_NA";
    case EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure45_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure45.ConvertibleNotePurchaseAgreement_disclosure45_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure45_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure45.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure46 {
  ConvertibleNotePurchaseAgreement_disclosure46_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure46_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure46_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure46FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure46 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure46_NA":
      return EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure46_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure46_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure46.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure46ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure46,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure46_NA";
    case EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure46_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure46.ConvertibleNotePurchaseAgreement_disclosure46_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure46_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure46.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure47 {
  ConvertibleNotePurchaseAgreement_disclosure47_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure47_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure47_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure47FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure47 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure47_NA":
      return EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure47_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure47_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure47.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure47ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure47,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure47_NA";
    case EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure47_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure47.ConvertibleNotePurchaseAgreement_disclosure47_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure47_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure47.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertibleNotePurchaseAgreementDisclosure48 {
  ConvertibleNotePurchaseAgreement_disclosure48_NA = 0,
  ConvertibleNotePurchaseAgreement_disclosure48_NONE = 1,
  ConvertibleNotePurchaseAgreement_disclosure48_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertibleNotePurchaseAgreementDisclosure48FromJSON(
  object: any,
): EConvertibleNotePurchaseAgreementDisclosure48 {
  switch (object) {
    case 0:
    case "ConvertibleNotePurchaseAgreement_disclosure48_NA":
      return EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_NA;
    case 1:
    case "ConvertibleNotePurchaseAgreement_disclosure48_NONE":
      return EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_NONE;
    case 999999:
    case "ConvertibleNotePurchaseAgreement_disclosure48_OTHER":
      return EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertibleNotePurchaseAgreementDisclosure48.UNRECOGNIZED;
  }
}

export function eConvertibleNotePurchaseAgreementDisclosure48ToJSON(
  object: EConvertibleNotePurchaseAgreementDisclosure48,
): string {
  switch (object) {
    case EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_NA:
      return "ConvertibleNotePurchaseAgreement_disclosure48_NA";
    case EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_NONE:
      return "ConvertibleNotePurchaseAgreement_disclosure48_NONE";
    case EConvertibleNotePurchaseAgreementDisclosure48.ConvertibleNotePurchaseAgreement_disclosure48_OTHER:
      return "ConvertibleNotePurchaseAgreement_disclosure48_OTHER";
    case EConvertibleNotePurchaseAgreementDisclosure48.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MConvertibleNotePurchaseAgreementCompanyEntityType {
  optionDbValue: EConvertibleNotePurchaseAgreementCompanyEntityType;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementJurisdictions {
  optionDbValue: EConvertibleNotePurchaseAgreementJurisdictions;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure31 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure31;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure32 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure32;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure33 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure33;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure34 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure34;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure35 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure35;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure36 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure36;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure37 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure37;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure38 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure38;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure39 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure39;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure310 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure310;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure311 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure311;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure312 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure312;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure313 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure313;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure314 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure314;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure315 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure315;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure316 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure316;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure317 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure317;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure318 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure318;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure319 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure319;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure320 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure320;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure321 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure321;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure322 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure322;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure323 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure323;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure49 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure49;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure41 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure41;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure42 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure42;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure43 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure43;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure44 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure44;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure45 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure45;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure46 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure46;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure47 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure47;
  other: string;
}

export interface MConvertibleNotePurchaseAgreementDisclosure48 {
  optionDbValue: EConvertibleNotePurchaseAgreementDisclosure48;
  other: string;
}

export interface ConvertibleNotePurchaseAgreement {
  companyFull: string;
  companyState: string;
  companyEntityType: MConvertibleNotePurchaseAgreementCompanyEntityType | undefined;
  effectiveDate: Date | undefined;
  lenderFull: string;
  principalAmount: number;
  contractThreshold: number;
  financialStatementsDate: Date | undefined;
  governLawState: string;
  jurisdictions: MConvertibleNotePurchaseAgreementJurisdictions | undefined;
  capTableUpload: PFile | undefined;
  complianceSigner: string;
  tableAdvances: PTable | undefined;
  baseInterestRate: number;
  maturityDate: Date | undefined;
  qualifiedOfferingValue: number;
  valuationCap: number;
  discountRate: number;
  defaultInterestRate: number;
  venue: string;
  companyAddress: string;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyPerson: string;
  companyNoticeCopyEmail: string;
  lenderAddress: string;
  holderNoticeEmail: string;
  holderNoticePerson: string;
  companyNoticeCopy: InternalBoolean;
  disclosure31: MConvertibleNotePurchaseAgreementDisclosure31 | undefined;
  disclosure32: MConvertibleNotePurchaseAgreementDisclosure32 | undefined;
  disclosure33: MConvertibleNotePurchaseAgreementDisclosure33 | undefined;
  disclosure34: MConvertibleNotePurchaseAgreementDisclosure34 | undefined;
  disclosure35: MConvertibleNotePurchaseAgreementDisclosure35 | undefined;
  disclosure36: MConvertibleNotePurchaseAgreementDisclosure36 | undefined;
  disclosure37: MConvertibleNotePurchaseAgreementDisclosure37 | undefined;
  disclosure38: MConvertibleNotePurchaseAgreementDisclosure38 | undefined;
  disclosure39: MConvertibleNotePurchaseAgreementDisclosure39 | undefined;
  disclosure310: MConvertibleNotePurchaseAgreementDisclosure310 | undefined;
  disclosure311: MConvertibleNotePurchaseAgreementDisclosure311 | undefined;
  disclosure312: MConvertibleNotePurchaseAgreementDisclosure312 | undefined;
  disclosure313: MConvertibleNotePurchaseAgreementDisclosure313 | undefined;
  disclosure314: MConvertibleNotePurchaseAgreementDisclosure314 | undefined;
  disclosure315: MConvertibleNotePurchaseAgreementDisclosure315 | undefined;
  disclosure316: MConvertibleNotePurchaseAgreementDisclosure316 | undefined;
  disclosure317: MConvertibleNotePurchaseAgreementDisclosure317 | undefined;
  disclosure318: MConvertibleNotePurchaseAgreementDisclosure318 | undefined;
  disclosure319: MConvertibleNotePurchaseAgreementDisclosure319 | undefined;
  disclosure320: MConvertibleNotePurchaseAgreementDisclosure320 | undefined;
  disclosure321: MConvertibleNotePurchaseAgreementDisclosure321 | undefined;
  disclosure322: MConvertibleNotePurchaseAgreementDisclosure322 | undefined;
  disclosure323: MConvertibleNotePurchaseAgreementDisclosure323 | undefined;
  disclosure49: MConvertibleNotePurchaseAgreementDisclosure49 | undefined;
  disclosure41: MConvertibleNotePurchaseAgreementDisclosure41 | undefined;
  disclosure42: MConvertibleNotePurchaseAgreementDisclosure42 | undefined;
  disclosure43: MConvertibleNotePurchaseAgreementDisclosure43 | undefined;
  disclosure44: MConvertibleNotePurchaseAgreementDisclosure44 | undefined;
  disclosure45: MConvertibleNotePurchaseAgreementDisclosure45 | undefined;
  disclosure46: MConvertibleNotePurchaseAgreementDisclosure46 | undefined;
  disclosure47: MConvertibleNotePurchaseAgreementDisclosure47 | undefined;
  disclosure48: MConvertibleNotePurchaseAgreementDisclosure48 | undefined;
}

function createBaseMConvertibleNotePurchaseAgreementCompanyEntityType(): MConvertibleNotePurchaseAgreementCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementCompanyEntityType = {
  encode(
    message: MConvertibleNotePurchaseAgreementCompanyEntityType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementCompanyEntityType>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementCompanyEntityType {
    return MConvertibleNotePurchaseAgreementCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementCompanyEntityType>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementCompanyEntityType {
    const message = createBaseMConvertibleNotePurchaseAgreementCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementJurisdictions(): MConvertibleNotePurchaseAgreementJurisdictions {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementJurisdictions = {
  encode(
    message: MConvertibleNotePurchaseAgreementJurisdictions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementJurisdictions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementJurisdictions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementJurisdictions {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementJurisdictionsFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementJurisdictions): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementJurisdictionsToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementJurisdictions>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementJurisdictions {
    return MConvertibleNotePurchaseAgreementJurisdictions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementJurisdictions>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementJurisdictions {
    const message = createBaseMConvertibleNotePurchaseAgreementJurisdictions();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure31(): MConvertibleNotePurchaseAgreementDisclosure31 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure31 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure31, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure31 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure31();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure31 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure31FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure31): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure31ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure31>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure31 {
    return MConvertibleNotePurchaseAgreementDisclosure31.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure31>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure31 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure31();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure32(): MConvertibleNotePurchaseAgreementDisclosure32 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure32 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure32, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure32 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure32();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure32 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure32FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure32): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure32ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure32>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure32 {
    return MConvertibleNotePurchaseAgreementDisclosure32.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure32>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure32 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure32();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure33(): MConvertibleNotePurchaseAgreementDisclosure33 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure33 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure33, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure33 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure33();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure33 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure33FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure33): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure33ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure33>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure33 {
    return MConvertibleNotePurchaseAgreementDisclosure33.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure33>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure33 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure33();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure34(): MConvertibleNotePurchaseAgreementDisclosure34 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure34 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure34, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure34 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure34();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure34 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure34FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure34): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure34ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure34>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure34 {
    return MConvertibleNotePurchaseAgreementDisclosure34.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure34>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure34 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure34();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure35(): MConvertibleNotePurchaseAgreementDisclosure35 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure35 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure35, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure35 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure35();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure35 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure35FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure35): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure35ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure35>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure35 {
    return MConvertibleNotePurchaseAgreementDisclosure35.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure35>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure35 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure35();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure36(): MConvertibleNotePurchaseAgreementDisclosure36 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure36 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure36, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure36 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure36();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure36 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure36FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure36): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure36ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure36>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure36 {
    return MConvertibleNotePurchaseAgreementDisclosure36.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure36>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure36 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure36();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure37(): MConvertibleNotePurchaseAgreementDisclosure37 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure37 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure37, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure37 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure37();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure37 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure37FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure37): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure37ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure37>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure37 {
    return MConvertibleNotePurchaseAgreementDisclosure37.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure37>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure37 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure37();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure38(): MConvertibleNotePurchaseAgreementDisclosure38 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure38 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure38, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure38 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure38();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure38 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure38FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure38): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure38ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure38>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure38 {
    return MConvertibleNotePurchaseAgreementDisclosure38.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure38>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure38 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure38();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure39(): MConvertibleNotePurchaseAgreementDisclosure39 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure39 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure39, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure39 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure39();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure39 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure39FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure39): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure39ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure39>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure39 {
    return MConvertibleNotePurchaseAgreementDisclosure39.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure39>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure39 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure39();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure310(): MConvertibleNotePurchaseAgreementDisclosure310 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure310 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure310,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure310 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure310();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure310 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure310FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure310): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure310ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure310>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure310 {
    return MConvertibleNotePurchaseAgreementDisclosure310.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure310>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure310 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure310();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure311(): MConvertibleNotePurchaseAgreementDisclosure311 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure311 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure311,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure311 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure311();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure311 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure311FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure311): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure311ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure311>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure311 {
    return MConvertibleNotePurchaseAgreementDisclosure311.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure311>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure311 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure311();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure312(): MConvertibleNotePurchaseAgreementDisclosure312 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure312 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure312,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure312 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure312();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure312 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure312FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure312): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure312ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure312>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure312 {
    return MConvertibleNotePurchaseAgreementDisclosure312.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure312>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure312 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure312();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure313(): MConvertibleNotePurchaseAgreementDisclosure313 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure313 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure313,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure313 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure313();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure313 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure313FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure313): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure313ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure313>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure313 {
    return MConvertibleNotePurchaseAgreementDisclosure313.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure313>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure313 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure313();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure314(): MConvertibleNotePurchaseAgreementDisclosure314 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure314 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure314,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure314 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure314();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure314 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure314FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure314): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure314ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure314>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure314 {
    return MConvertibleNotePurchaseAgreementDisclosure314.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure314>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure314 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure314();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure315(): MConvertibleNotePurchaseAgreementDisclosure315 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure315 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure315,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure315 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure315();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure315 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure315FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure315): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure315ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure315>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure315 {
    return MConvertibleNotePurchaseAgreementDisclosure315.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure315>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure315 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure315();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure316(): MConvertibleNotePurchaseAgreementDisclosure316 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure316 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure316,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure316 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure316();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure316 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure316FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure316): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure316ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure316>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure316 {
    return MConvertibleNotePurchaseAgreementDisclosure316.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure316>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure316 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure316();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure317(): MConvertibleNotePurchaseAgreementDisclosure317 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure317 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure317,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure317 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure317();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure317 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure317FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure317): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure317ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure317>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure317 {
    return MConvertibleNotePurchaseAgreementDisclosure317.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure317>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure317 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure317();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure318(): MConvertibleNotePurchaseAgreementDisclosure318 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure318 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure318,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure318 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure318();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure318 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure318FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure318): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure318ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure318>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure318 {
    return MConvertibleNotePurchaseAgreementDisclosure318.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure318>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure318 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure318();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure319(): MConvertibleNotePurchaseAgreementDisclosure319 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure319 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure319,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure319 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure319();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure319 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure319FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure319): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure319ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure319>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure319 {
    return MConvertibleNotePurchaseAgreementDisclosure319.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure319>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure319 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure319();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure320(): MConvertibleNotePurchaseAgreementDisclosure320 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure320 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure320,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure320 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure320();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure320 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure320FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure320): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure320ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure320>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure320 {
    return MConvertibleNotePurchaseAgreementDisclosure320.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure320>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure320 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure320();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure321(): MConvertibleNotePurchaseAgreementDisclosure321 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure321 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure321,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure321 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure321();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure321 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure321FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure321): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure321ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure321>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure321 {
    return MConvertibleNotePurchaseAgreementDisclosure321.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure321>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure321 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure321();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure322(): MConvertibleNotePurchaseAgreementDisclosure322 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure322 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure322,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure322 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure322();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure322 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure322FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure322): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure322ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure322>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure322 {
    return MConvertibleNotePurchaseAgreementDisclosure322.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure322>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure322 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure322();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure323(): MConvertibleNotePurchaseAgreementDisclosure323 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure323 = {
  encode(
    message: MConvertibleNotePurchaseAgreementDisclosure323,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure323 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure323();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure323 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure323FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure323): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure323ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure323>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure323 {
    return MConvertibleNotePurchaseAgreementDisclosure323.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure323>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure323 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure323();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure49(): MConvertibleNotePurchaseAgreementDisclosure49 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure49 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure49, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure49 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure49();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure49 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure49FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure49): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure49ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure49>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure49 {
    return MConvertibleNotePurchaseAgreementDisclosure49.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure49>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure49 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure49();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure41(): MConvertibleNotePurchaseAgreementDisclosure41 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure41 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure41, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure41 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure41();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure41 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure41FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure41): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure41ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure41>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure41 {
    return MConvertibleNotePurchaseAgreementDisclosure41.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure41>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure41 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure41();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure42(): MConvertibleNotePurchaseAgreementDisclosure42 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure42 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure42, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure42 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure42();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure42 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure42FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure42): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure42ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure42>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure42 {
    return MConvertibleNotePurchaseAgreementDisclosure42.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure42>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure42 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure42();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure43(): MConvertibleNotePurchaseAgreementDisclosure43 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure43 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure43, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure43 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure43();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure43 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure43FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure43): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure43ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure43>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure43 {
    return MConvertibleNotePurchaseAgreementDisclosure43.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure43>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure43 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure43();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure44(): MConvertibleNotePurchaseAgreementDisclosure44 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure44 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure44, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure44 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure44();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure44 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure44FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure44): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure44ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure44>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure44 {
    return MConvertibleNotePurchaseAgreementDisclosure44.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure44>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure44 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure44();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure45(): MConvertibleNotePurchaseAgreementDisclosure45 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure45 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure45, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure45 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure45();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure45 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure45FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure45): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure45ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure45>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure45 {
    return MConvertibleNotePurchaseAgreementDisclosure45.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure45>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure45 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure45();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure46(): MConvertibleNotePurchaseAgreementDisclosure46 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure46 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure46, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure46 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure46();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure46 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure46FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure46): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure46ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure46>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure46 {
    return MConvertibleNotePurchaseAgreementDisclosure46.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure46>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure46 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure46();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure47(): MConvertibleNotePurchaseAgreementDisclosure47 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure47 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure47, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure47 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure47();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure47 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure47FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure47): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure47ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure47>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure47 {
    return MConvertibleNotePurchaseAgreementDisclosure47.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure47>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure47 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure47();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertibleNotePurchaseAgreementDisclosure48(): MConvertibleNotePurchaseAgreementDisclosure48 {
  return { optionDbValue: 0, other: "" };
}

export const MConvertibleNotePurchaseAgreementDisclosure48 = {
  encode(message: MConvertibleNotePurchaseAgreementDisclosure48, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertibleNotePurchaseAgreementDisclosure48 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure48();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertibleNotePurchaseAgreementDisclosure48 {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertibleNotePurchaseAgreementDisclosure48FromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertibleNotePurchaseAgreementDisclosure48): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertibleNotePurchaseAgreementDisclosure48ToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure48>, I>>(
    base?: I,
  ): MConvertibleNotePurchaseAgreementDisclosure48 {
    return MConvertibleNotePurchaseAgreementDisclosure48.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertibleNotePurchaseAgreementDisclosure48>, I>>(
    object: I,
  ): MConvertibleNotePurchaseAgreementDisclosure48 {
    const message = createBaseMConvertibleNotePurchaseAgreementDisclosure48();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseConvertibleNotePurchaseAgreement(): ConvertibleNotePurchaseAgreement {
  return {
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    effectiveDate: undefined,
    lenderFull: "",
    principalAmount: 0,
    contractThreshold: 0,
    financialStatementsDate: undefined,
    governLawState: "",
    jurisdictions: undefined,
    capTableUpload: undefined,
    complianceSigner: "",
    tableAdvances: undefined,
    baseInterestRate: 0,
    maturityDate: undefined,
    qualifiedOfferingValue: 0,
    valuationCap: 0,
    discountRate: 0,
    defaultInterestRate: 0,
    venue: "",
    companyAddress: "",
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyPerson: "",
    companyNoticeCopyEmail: "",
    lenderAddress: "",
    holderNoticeEmail: "",
    holderNoticePerson: "",
    companyNoticeCopy: 0,
    disclosure31: undefined,
    disclosure32: undefined,
    disclosure33: undefined,
    disclosure34: undefined,
    disclosure35: undefined,
    disclosure36: undefined,
    disclosure37: undefined,
    disclosure38: undefined,
    disclosure39: undefined,
    disclosure310: undefined,
    disclosure311: undefined,
    disclosure312: undefined,
    disclosure313: undefined,
    disclosure314: undefined,
    disclosure315: undefined,
    disclosure316: undefined,
    disclosure317: undefined,
    disclosure318: undefined,
    disclosure319: undefined,
    disclosure320: undefined,
    disclosure321: undefined,
    disclosure322: undefined,
    disclosure323: undefined,
    disclosure49: undefined,
    disclosure41: undefined,
    disclosure42: undefined,
    disclosure43: undefined,
    disclosure44: undefined,
    disclosure45: undefined,
    disclosure46: undefined,
    disclosure47: undefined,
    disclosure48: undefined,
  };
}

export const ConvertibleNotePurchaseAgreement = {
  encode(message: ConvertibleNotePurchaseAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MConvertibleNotePurchaseAgreementCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork())
        .ldelim();
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.lenderFull !== "") {
      writer.uint32(42).string(message.lenderFull);
    }
    if (message.principalAmount !== 0) {
      writer.uint32(53).float(message.principalAmount);
    }
    if (message.contractThreshold !== 0) {
      writer.uint32(61).float(message.contractThreshold);
    }
    if (message.financialStatementsDate !== undefined) {
      Timestamp.encode(toTimestamp(message.financialStatementsDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(74).string(message.governLawState);
    }
    if (message.jurisdictions !== undefined) {
      MConvertibleNotePurchaseAgreementJurisdictions.encode(message.jurisdictions, writer.uint32(82).fork()).ldelim();
    }
    if (message.capTableUpload !== undefined) {
      PFile.encode(message.capTableUpload, writer.uint32(90).fork()).ldelim();
    }
    if (message.complianceSigner !== "") {
      writer.uint32(98).string(message.complianceSigner);
    }
    if (message.tableAdvances !== undefined) {
      PTable.encode(message.tableAdvances, writer.uint32(106).fork()).ldelim();
    }
    if (message.baseInterestRate !== 0) {
      writer.uint32(117).float(message.baseInterestRate);
    }
    if (message.maturityDate !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityDate), writer.uint32(122).fork()).ldelim();
    }
    if (message.qualifiedOfferingValue !== 0) {
      writer.uint32(133).float(message.qualifiedOfferingValue);
    }
    if (message.valuationCap !== 0) {
      writer.uint32(141).float(message.valuationCap);
    }
    if (message.discountRate !== 0) {
      writer.uint32(149).float(message.discountRate);
    }
    if (message.defaultInterestRate !== 0) {
      writer.uint32(157).float(message.defaultInterestRate);
    }
    if (message.venue !== "") {
      writer.uint32(162).string(message.venue);
    }
    if (message.companyAddress !== "") {
      writer.uint32(170).string(message.companyAddress);
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(178).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(186).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(194).string(message.companyNoticeCopyPerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(202).string(message.companyNoticeCopyEmail);
    }
    if (message.lenderAddress !== "") {
      writer.uint32(210).string(message.lenderAddress);
    }
    if (message.holderNoticeEmail !== "") {
      writer.uint32(218).string(message.holderNoticeEmail);
    }
    if (message.holderNoticePerson !== "") {
      writer.uint32(226).string(message.holderNoticePerson);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(232).int32(message.companyNoticeCopy);
    }
    if (message.disclosure31 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure31.encode(message.disclosure31, writer.uint32(242).fork()).ldelim();
    }
    if (message.disclosure32 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure32.encode(message.disclosure32, writer.uint32(250).fork()).ldelim();
    }
    if (message.disclosure33 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure33.encode(message.disclosure33, writer.uint32(258).fork()).ldelim();
    }
    if (message.disclosure34 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure34.encode(message.disclosure34, writer.uint32(266).fork()).ldelim();
    }
    if (message.disclosure35 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure35.encode(message.disclosure35, writer.uint32(274).fork()).ldelim();
    }
    if (message.disclosure36 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure36.encode(message.disclosure36, writer.uint32(282).fork()).ldelim();
    }
    if (message.disclosure37 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure37.encode(message.disclosure37, writer.uint32(290).fork()).ldelim();
    }
    if (message.disclosure38 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure38.encode(message.disclosure38, writer.uint32(298).fork()).ldelim();
    }
    if (message.disclosure39 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure39.encode(message.disclosure39, writer.uint32(306).fork()).ldelim();
    }
    if (message.disclosure310 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure310.encode(message.disclosure310, writer.uint32(314).fork()).ldelim();
    }
    if (message.disclosure311 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure311.encode(message.disclosure311, writer.uint32(322).fork()).ldelim();
    }
    if (message.disclosure312 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure312.encode(message.disclosure312, writer.uint32(330).fork()).ldelim();
    }
    if (message.disclosure313 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure313.encode(message.disclosure313, writer.uint32(338).fork()).ldelim();
    }
    if (message.disclosure314 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure314.encode(message.disclosure314, writer.uint32(346).fork()).ldelim();
    }
    if (message.disclosure315 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure315.encode(message.disclosure315, writer.uint32(354).fork()).ldelim();
    }
    if (message.disclosure316 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure316.encode(message.disclosure316, writer.uint32(362).fork()).ldelim();
    }
    if (message.disclosure317 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure317.encode(message.disclosure317, writer.uint32(370).fork()).ldelim();
    }
    if (message.disclosure318 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure318.encode(message.disclosure318, writer.uint32(378).fork()).ldelim();
    }
    if (message.disclosure319 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure319.encode(message.disclosure319, writer.uint32(386).fork()).ldelim();
    }
    if (message.disclosure320 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure320.encode(message.disclosure320, writer.uint32(394).fork()).ldelim();
    }
    if (message.disclosure321 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure321.encode(message.disclosure321, writer.uint32(402).fork()).ldelim();
    }
    if (message.disclosure322 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure322.encode(message.disclosure322, writer.uint32(410).fork()).ldelim();
    }
    if (message.disclosure323 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure323.encode(message.disclosure323, writer.uint32(418).fork()).ldelim();
    }
    if (message.disclosure49 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure49.encode(message.disclosure49, writer.uint32(426).fork()).ldelim();
    }
    if (message.disclosure41 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure41.encode(message.disclosure41, writer.uint32(434).fork()).ldelim();
    }
    if (message.disclosure42 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure42.encode(message.disclosure42, writer.uint32(442).fork()).ldelim();
    }
    if (message.disclosure43 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure43.encode(message.disclosure43, writer.uint32(450).fork()).ldelim();
    }
    if (message.disclosure44 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure44.encode(message.disclosure44, writer.uint32(458).fork()).ldelim();
    }
    if (message.disclosure45 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure45.encode(message.disclosure45, writer.uint32(466).fork()).ldelim();
    }
    if (message.disclosure46 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure46.encode(message.disclosure46, writer.uint32(474).fork()).ldelim();
    }
    if (message.disclosure47 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure47.encode(message.disclosure47, writer.uint32(482).fork()).ldelim();
    }
    if (message.disclosure48 !== undefined) {
      MConvertibleNotePurchaseAgreementDisclosure48.encode(message.disclosure48, writer.uint32(490).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConvertibleNotePurchaseAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConvertibleNotePurchaseAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MConvertibleNotePurchaseAgreementCompanyEntityType.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.lenderFull = reader.string();
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.principalAmount = reader.float();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.contractThreshold = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.financialStatementsDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.jurisdictions = MConvertibleNotePurchaseAgreementJurisdictions.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.capTableUpload = PFile.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.complianceSigner = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.tableAdvances = PTable.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 117) {
            break;
          }

          message.baseInterestRate = reader.float();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.maturityDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.qualifiedOfferingValue = reader.float();
          continue;
        case 17:
          if (tag != 141) {
            break;
          }

          message.valuationCap = reader.float();
          continue;
        case 18:
          if (tag != 149) {
            break;
          }

          message.discountRate = reader.float();
          continue;
        case 19:
          if (tag != 157) {
            break;
          }

          message.defaultInterestRate = reader.float();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.lenderAddress = reader.string();
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.holderNoticeEmail = reader.string();
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.holderNoticePerson = reader.string();
          continue;
        case 29:
          if (tag != 232) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.disclosure31 = MConvertibleNotePurchaseAgreementDisclosure31.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.disclosure32 = MConvertibleNotePurchaseAgreementDisclosure32.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.disclosure33 = MConvertibleNotePurchaseAgreementDisclosure33.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.disclosure34 = MConvertibleNotePurchaseAgreementDisclosure34.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.disclosure35 = MConvertibleNotePurchaseAgreementDisclosure35.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.disclosure36 = MConvertibleNotePurchaseAgreementDisclosure36.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.disclosure37 = MConvertibleNotePurchaseAgreementDisclosure37.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.disclosure38 = MConvertibleNotePurchaseAgreementDisclosure38.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.disclosure39 = MConvertibleNotePurchaseAgreementDisclosure39.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.disclosure310 = MConvertibleNotePurchaseAgreementDisclosure310.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.disclosure311 = MConvertibleNotePurchaseAgreementDisclosure311.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.disclosure312 = MConvertibleNotePurchaseAgreementDisclosure312.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.disclosure313 = MConvertibleNotePurchaseAgreementDisclosure313.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag != 346) {
            break;
          }

          message.disclosure314 = MConvertibleNotePurchaseAgreementDisclosure314.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag != 354) {
            break;
          }

          message.disclosure315 = MConvertibleNotePurchaseAgreementDisclosure315.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag != 362) {
            break;
          }

          message.disclosure316 = MConvertibleNotePurchaseAgreementDisclosure316.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag != 370) {
            break;
          }

          message.disclosure317 = MConvertibleNotePurchaseAgreementDisclosure317.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag != 378) {
            break;
          }

          message.disclosure318 = MConvertibleNotePurchaseAgreementDisclosure318.decode(reader, reader.uint32());
          continue;
        case 48:
          if (tag != 386) {
            break;
          }

          message.disclosure319 = MConvertibleNotePurchaseAgreementDisclosure319.decode(reader, reader.uint32());
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.disclosure320 = MConvertibleNotePurchaseAgreementDisclosure320.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag != 402) {
            break;
          }

          message.disclosure321 = MConvertibleNotePurchaseAgreementDisclosure321.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag != 410) {
            break;
          }

          message.disclosure322 = MConvertibleNotePurchaseAgreementDisclosure322.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag != 418) {
            break;
          }

          message.disclosure323 = MConvertibleNotePurchaseAgreementDisclosure323.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag != 426) {
            break;
          }

          message.disclosure49 = MConvertibleNotePurchaseAgreementDisclosure49.decode(reader, reader.uint32());
          continue;
        case 54:
          if (tag != 434) {
            break;
          }

          message.disclosure41 = MConvertibleNotePurchaseAgreementDisclosure41.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag != 442) {
            break;
          }

          message.disclosure42 = MConvertibleNotePurchaseAgreementDisclosure42.decode(reader, reader.uint32());
          continue;
        case 56:
          if (tag != 450) {
            break;
          }

          message.disclosure43 = MConvertibleNotePurchaseAgreementDisclosure43.decode(reader, reader.uint32());
          continue;
        case 57:
          if (tag != 458) {
            break;
          }

          message.disclosure44 = MConvertibleNotePurchaseAgreementDisclosure44.decode(reader, reader.uint32());
          continue;
        case 58:
          if (tag != 466) {
            break;
          }

          message.disclosure45 = MConvertibleNotePurchaseAgreementDisclosure45.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag != 474) {
            break;
          }

          message.disclosure46 = MConvertibleNotePurchaseAgreementDisclosure46.decode(reader, reader.uint32());
          continue;
        case 60:
          if (tag != 482) {
            break;
          }

          message.disclosure47 = MConvertibleNotePurchaseAgreementDisclosure47.decode(reader, reader.uint32());
          continue;
        case 61:
          if (tag != 490) {
            break;
          }

          message.disclosure48 = MConvertibleNotePurchaseAgreementDisclosure48.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConvertibleNotePurchaseAgreement {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MConvertibleNotePurchaseAgreementCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      lenderFull: isSet(object.lenderFull) ? String(object.lenderFull) : "",
      principalAmount: isSet(object.principalAmount) ? Number(object.principalAmount) : 0,
      contractThreshold: isSet(object.contractThreshold) ? Number(object.contractThreshold) : 0,
      financialStatementsDate: isSet(object.financialStatementsDate)
        ? fromJsonTimestamp(object.financialStatementsDate)
        : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      jurisdictions: isSet(object.jurisdictions)
        ? MConvertibleNotePurchaseAgreementJurisdictions.fromJSON(object.jurisdictions)
        : undefined,
      capTableUpload: isSet(object.capTableUpload) ? PFile.fromJSON(object.capTableUpload) : undefined,
      complianceSigner: isSet(object.complianceSigner) ? String(object.complianceSigner) : "",
      tableAdvances: isSet(object.tableAdvances) ? PTable.fromJSON(object.tableAdvances) : undefined,
      baseInterestRate: isSet(object.baseInterestRate) ? Number(object.baseInterestRate) : 0,
      maturityDate: isSet(object.maturityDate) ? fromJsonTimestamp(object.maturityDate) : undefined,
      qualifiedOfferingValue: isSet(object.qualifiedOfferingValue) ? Number(object.qualifiedOfferingValue) : 0,
      valuationCap: isSet(object.valuationCap) ? Number(object.valuationCap) : 0,
      discountRate: isSet(object.discountRate) ? Number(object.discountRate) : 0,
      defaultInterestRate: isSet(object.defaultInterestRate) ? Number(object.defaultInterestRate) : 0,
      venue: isSet(object.venue) ? String(object.venue) : "",
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      lenderAddress: isSet(object.lenderAddress) ? String(object.lenderAddress) : "",
      holderNoticeEmail: isSet(object.holderNoticeEmail) ? String(object.holderNoticeEmail) : "",
      holderNoticePerson: isSet(object.holderNoticePerson) ? String(object.holderNoticePerson) : "",
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      disclosure31: isSet(object.disclosure31)
        ? MConvertibleNotePurchaseAgreementDisclosure31.fromJSON(object.disclosure31)
        : undefined,
      disclosure32: isSet(object.disclosure32)
        ? MConvertibleNotePurchaseAgreementDisclosure32.fromJSON(object.disclosure32)
        : undefined,
      disclosure33: isSet(object.disclosure33)
        ? MConvertibleNotePurchaseAgreementDisclosure33.fromJSON(object.disclosure33)
        : undefined,
      disclosure34: isSet(object.disclosure34)
        ? MConvertibleNotePurchaseAgreementDisclosure34.fromJSON(object.disclosure34)
        : undefined,
      disclosure35: isSet(object.disclosure35)
        ? MConvertibleNotePurchaseAgreementDisclosure35.fromJSON(object.disclosure35)
        : undefined,
      disclosure36: isSet(object.disclosure36)
        ? MConvertibleNotePurchaseAgreementDisclosure36.fromJSON(object.disclosure36)
        : undefined,
      disclosure37: isSet(object.disclosure37)
        ? MConvertibleNotePurchaseAgreementDisclosure37.fromJSON(object.disclosure37)
        : undefined,
      disclosure38: isSet(object.disclosure38)
        ? MConvertibleNotePurchaseAgreementDisclosure38.fromJSON(object.disclosure38)
        : undefined,
      disclosure39: isSet(object.disclosure39)
        ? MConvertibleNotePurchaseAgreementDisclosure39.fromJSON(object.disclosure39)
        : undefined,
      disclosure310: isSet(object.disclosure310)
        ? MConvertibleNotePurchaseAgreementDisclosure310.fromJSON(object.disclosure310)
        : undefined,
      disclosure311: isSet(object.disclosure311)
        ? MConvertibleNotePurchaseAgreementDisclosure311.fromJSON(object.disclosure311)
        : undefined,
      disclosure312: isSet(object.disclosure312)
        ? MConvertibleNotePurchaseAgreementDisclosure312.fromJSON(object.disclosure312)
        : undefined,
      disclosure313: isSet(object.disclosure313)
        ? MConvertibleNotePurchaseAgreementDisclosure313.fromJSON(object.disclosure313)
        : undefined,
      disclosure314: isSet(object.disclosure314)
        ? MConvertibleNotePurchaseAgreementDisclosure314.fromJSON(object.disclosure314)
        : undefined,
      disclosure315: isSet(object.disclosure315)
        ? MConvertibleNotePurchaseAgreementDisclosure315.fromJSON(object.disclosure315)
        : undefined,
      disclosure316: isSet(object.disclosure316)
        ? MConvertibleNotePurchaseAgreementDisclosure316.fromJSON(object.disclosure316)
        : undefined,
      disclosure317: isSet(object.disclosure317)
        ? MConvertibleNotePurchaseAgreementDisclosure317.fromJSON(object.disclosure317)
        : undefined,
      disclosure318: isSet(object.disclosure318)
        ? MConvertibleNotePurchaseAgreementDisclosure318.fromJSON(object.disclosure318)
        : undefined,
      disclosure319: isSet(object.disclosure319)
        ? MConvertibleNotePurchaseAgreementDisclosure319.fromJSON(object.disclosure319)
        : undefined,
      disclosure320: isSet(object.disclosure320)
        ? MConvertibleNotePurchaseAgreementDisclosure320.fromJSON(object.disclosure320)
        : undefined,
      disclosure321: isSet(object.disclosure321)
        ? MConvertibleNotePurchaseAgreementDisclosure321.fromJSON(object.disclosure321)
        : undefined,
      disclosure322: isSet(object.disclosure322)
        ? MConvertibleNotePurchaseAgreementDisclosure322.fromJSON(object.disclosure322)
        : undefined,
      disclosure323: isSet(object.disclosure323)
        ? MConvertibleNotePurchaseAgreementDisclosure323.fromJSON(object.disclosure323)
        : undefined,
      disclosure49: isSet(object.disclosure49)
        ? MConvertibleNotePurchaseAgreementDisclosure49.fromJSON(object.disclosure49)
        : undefined,
      disclosure41: isSet(object.disclosure41)
        ? MConvertibleNotePurchaseAgreementDisclosure41.fromJSON(object.disclosure41)
        : undefined,
      disclosure42: isSet(object.disclosure42)
        ? MConvertibleNotePurchaseAgreementDisclosure42.fromJSON(object.disclosure42)
        : undefined,
      disclosure43: isSet(object.disclosure43)
        ? MConvertibleNotePurchaseAgreementDisclosure43.fromJSON(object.disclosure43)
        : undefined,
      disclosure44: isSet(object.disclosure44)
        ? MConvertibleNotePurchaseAgreementDisclosure44.fromJSON(object.disclosure44)
        : undefined,
      disclosure45: isSet(object.disclosure45)
        ? MConvertibleNotePurchaseAgreementDisclosure45.fromJSON(object.disclosure45)
        : undefined,
      disclosure46: isSet(object.disclosure46)
        ? MConvertibleNotePurchaseAgreementDisclosure46.fromJSON(object.disclosure46)
        : undefined,
      disclosure47: isSet(object.disclosure47)
        ? MConvertibleNotePurchaseAgreementDisclosure47.fromJSON(object.disclosure47)
        : undefined,
      disclosure48: isSet(object.disclosure48)
        ? MConvertibleNotePurchaseAgreementDisclosure48.fromJSON(object.disclosure48)
        : undefined,
    };
  },

  toJSON(message: ConvertibleNotePurchaseAgreement): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MConvertibleNotePurchaseAgreementCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.lenderFull !== undefined && (obj.lenderFull = message.lenderFull);
    message.principalAmount !== undefined && (obj.principalAmount = message.principalAmount);
    message.contractThreshold !== undefined && (obj.contractThreshold = message.contractThreshold);
    message.financialStatementsDate !== undefined &&
      (obj.financialStatementsDate = message.financialStatementsDate.toISOString());
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.jurisdictions !== undefined && (obj.jurisdictions = message.jurisdictions
      ? MConvertibleNotePurchaseAgreementJurisdictions.toJSON(message.jurisdictions)
      : undefined);
    message.capTableUpload !== undefined &&
      (obj.capTableUpload = message.capTableUpload ? PFile.toJSON(message.capTableUpload) : undefined);
    message.complianceSigner !== undefined && (obj.complianceSigner = message.complianceSigner);
    message.tableAdvances !== undefined &&
      (obj.tableAdvances = message.tableAdvances ? PTable.toJSON(message.tableAdvances) : undefined);
    message.baseInterestRate !== undefined && (obj.baseInterestRate = message.baseInterestRate);
    message.maturityDate !== undefined && (obj.maturityDate = message.maturityDate.toISOString());
    message.qualifiedOfferingValue !== undefined && (obj.qualifiedOfferingValue = message.qualifiedOfferingValue);
    message.valuationCap !== undefined && (obj.valuationCap = message.valuationCap);
    message.discountRate !== undefined && (obj.discountRate = message.discountRate);
    message.defaultInterestRate !== undefined && (obj.defaultInterestRate = message.defaultInterestRate);
    message.venue !== undefined && (obj.venue = message.venue);
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.lenderAddress !== undefined && (obj.lenderAddress = message.lenderAddress);
    message.holderNoticeEmail !== undefined && (obj.holderNoticeEmail = message.holderNoticeEmail);
    message.holderNoticePerson !== undefined && (obj.holderNoticePerson = message.holderNoticePerson);
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.disclosure31 !== undefined && (obj.disclosure31 = message.disclosure31
      ? MConvertibleNotePurchaseAgreementDisclosure31.toJSON(message.disclosure31)
      : undefined);
    message.disclosure32 !== undefined && (obj.disclosure32 = message.disclosure32
      ? MConvertibleNotePurchaseAgreementDisclosure32.toJSON(message.disclosure32)
      : undefined);
    message.disclosure33 !== undefined && (obj.disclosure33 = message.disclosure33
      ? MConvertibleNotePurchaseAgreementDisclosure33.toJSON(message.disclosure33)
      : undefined);
    message.disclosure34 !== undefined && (obj.disclosure34 = message.disclosure34
      ? MConvertibleNotePurchaseAgreementDisclosure34.toJSON(message.disclosure34)
      : undefined);
    message.disclosure35 !== undefined && (obj.disclosure35 = message.disclosure35
      ? MConvertibleNotePurchaseAgreementDisclosure35.toJSON(message.disclosure35)
      : undefined);
    message.disclosure36 !== undefined && (obj.disclosure36 = message.disclosure36
      ? MConvertibleNotePurchaseAgreementDisclosure36.toJSON(message.disclosure36)
      : undefined);
    message.disclosure37 !== undefined && (obj.disclosure37 = message.disclosure37
      ? MConvertibleNotePurchaseAgreementDisclosure37.toJSON(message.disclosure37)
      : undefined);
    message.disclosure38 !== undefined && (obj.disclosure38 = message.disclosure38
      ? MConvertibleNotePurchaseAgreementDisclosure38.toJSON(message.disclosure38)
      : undefined);
    message.disclosure39 !== undefined && (obj.disclosure39 = message.disclosure39
      ? MConvertibleNotePurchaseAgreementDisclosure39.toJSON(message.disclosure39)
      : undefined);
    message.disclosure310 !== undefined && (obj.disclosure310 = message.disclosure310
      ? MConvertibleNotePurchaseAgreementDisclosure310.toJSON(message.disclosure310)
      : undefined);
    message.disclosure311 !== undefined && (obj.disclosure311 = message.disclosure311
      ? MConvertibleNotePurchaseAgreementDisclosure311.toJSON(message.disclosure311)
      : undefined);
    message.disclosure312 !== undefined && (obj.disclosure312 = message.disclosure312
      ? MConvertibleNotePurchaseAgreementDisclosure312.toJSON(message.disclosure312)
      : undefined);
    message.disclosure313 !== undefined && (obj.disclosure313 = message.disclosure313
      ? MConvertibleNotePurchaseAgreementDisclosure313.toJSON(message.disclosure313)
      : undefined);
    message.disclosure314 !== undefined && (obj.disclosure314 = message.disclosure314
      ? MConvertibleNotePurchaseAgreementDisclosure314.toJSON(message.disclosure314)
      : undefined);
    message.disclosure315 !== undefined && (obj.disclosure315 = message.disclosure315
      ? MConvertibleNotePurchaseAgreementDisclosure315.toJSON(message.disclosure315)
      : undefined);
    message.disclosure316 !== undefined && (obj.disclosure316 = message.disclosure316
      ? MConvertibleNotePurchaseAgreementDisclosure316.toJSON(message.disclosure316)
      : undefined);
    message.disclosure317 !== undefined && (obj.disclosure317 = message.disclosure317
      ? MConvertibleNotePurchaseAgreementDisclosure317.toJSON(message.disclosure317)
      : undefined);
    message.disclosure318 !== undefined && (obj.disclosure318 = message.disclosure318
      ? MConvertibleNotePurchaseAgreementDisclosure318.toJSON(message.disclosure318)
      : undefined);
    message.disclosure319 !== undefined && (obj.disclosure319 = message.disclosure319
      ? MConvertibleNotePurchaseAgreementDisclosure319.toJSON(message.disclosure319)
      : undefined);
    message.disclosure320 !== undefined && (obj.disclosure320 = message.disclosure320
      ? MConvertibleNotePurchaseAgreementDisclosure320.toJSON(message.disclosure320)
      : undefined);
    message.disclosure321 !== undefined && (obj.disclosure321 = message.disclosure321
      ? MConvertibleNotePurchaseAgreementDisclosure321.toJSON(message.disclosure321)
      : undefined);
    message.disclosure322 !== undefined && (obj.disclosure322 = message.disclosure322
      ? MConvertibleNotePurchaseAgreementDisclosure322.toJSON(message.disclosure322)
      : undefined);
    message.disclosure323 !== undefined && (obj.disclosure323 = message.disclosure323
      ? MConvertibleNotePurchaseAgreementDisclosure323.toJSON(message.disclosure323)
      : undefined);
    message.disclosure49 !== undefined && (obj.disclosure49 = message.disclosure49
      ? MConvertibleNotePurchaseAgreementDisclosure49.toJSON(message.disclosure49)
      : undefined);
    message.disclosure41 !== undefined && (obj.disclosure41 = message.disclosure41
      ? MConvertibleNotePurchaseAgreementDisclosure41.toJSON(message.disclosure41)
      : undefined);
    message.disclosure42 !== undefined && (obj.disclosure42 = message.disclosure42
      ? MConvertibleNotePurchaseAgreementDisclosure42.toJSON(message.disclosure42)
      : undefined);
    message.disclosure43 !== undefined && (obj.disclosure43 = message.disclosure43
      ? MConvertibleNotePurchaseAgreementDisclosure43.toJSON(message.disclosure43)
      : undefined);
    message.disclosure44 !== undefined && (obj.disclosure44 = message.disclosure44
      ? MConvertibleNotePurchaseAgreementDisclosure44.toJSON(message.disclosure44)
      : undefined);
    message.disclosure45 !== undefined && (obj.disclosure45 = message.disclosure45
      ? MConvertibleNotePurchaseAgreementDisclosure45.toJSON(message.disclosure45)
      : undefined);
    message.disclosure46 !== undefined && (obj.disclosure46 = message.disclosure46
      ? MConvertibleNotePurchaseAgreementDisclosure46.toJSON(message.disclosure46)
      : undefined);
    message.disclosure47 !== undefined && (obj.disclosure47 = message.disclosure47
      ? MConvertibleNotePurchaseAgreementDisclosure47.toJSON(message.disclosure47)
      : undefined);
    message.disclosure48 !== undefined && (obj.disclosure48 = message.disclosure48
      ? MConvertibleNotePurchaseAgreementDisclosure48.toJSON(message.disclosure48)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConvertibleNotePurchaseAgreement>, I>>(
    base?: I,
  ): ConvertibleNotePurchaseAgreement {
    return ConvertibleNotePurchaseAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConvertibleNotePurchaseAgreement>, I>>(
    object: I,
  ): ConvertibleNotePurchaseAgreement {
    const message = createBaseConvertibleNotePurchaseAgreement();
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MConvertibleNotePurchaseAgreementCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.lenderFull = object.lenderFull ?? "";
    message.principalAmount = object.principalAmount ?? 0;
    message.contractThreshold = object.contractThreshold ?? 0;
    message.financialStatementsDate = object.financialStatementsDate ?? undefined;
    message.governLawState = object.governLawState ?? "";
    message.jurisdictions = (object.jurisdictions !== undefined && object.jurisdictions !== null)
      ? MConvertibleNotePurchaseAgreementJurisdictions.fromPartial(object.jurisdictions)
      : undefined;
    message.capTableUpload = (object.capTableUpload !== undefined && object.capTableUpload !== null)
      ? PFile.fromPartial(object.capTableUpload)
      : undefined;
    message.complianceSigner = object.complianceSigner ?? "";
    message.tableAdvances = (object.tableAdvances !== undefined && object.tableAdvances !== null)
      ? PTable.fromPartial(object.tableAdvances)
      : undefined;
    message.baseInterestRate = object.baseInterestRate ?? 0;
    message.maturityDate = object.maturityDate ?? undefined;
    message.qualifiedOfferingValue = object.qualifiedOfferingValue ?? 0;
    message.valuationCap = object.valuationCap ?? 0;
    message.discountRate = object.discountRate ?? 0;
    message.defaultInterestRate = object.defaultInterestRate ?? 0;
    message.venue = object.venue ?? "";
    message.companyAddress = object.companyAddress ?? "";
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.lenderAddress = object.lenderAddress ?? "";
    message.holderNoticeEmail = object.holderNoticeEmail ?? "";
    message.holderNoticePerson = object.holderNoticePerson ?? "";
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.disclosure31 = (object.disclosure31 !== undefined && object.disclosure31 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure31.fromPartial(object.disclosure31)
      : undefined;
    message.disclosure32 = (object.disclosure32 !== undefined && object.disclosure32 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure32.fromPartial(object.disclosure32)
      : undefined;
    message.disclosure33 = (object.disclosure33 !== undefined && object.disclosure33 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure33.fromPartial(object.disclosure33)
      : undefined;
    message.disclosure34 = (object.disclosure34 !== undefined && object.disclosure34 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure34.fromPartial(object.disclosure34)
      : undefined;
    message.disclosure35 = (object.disclosure35 !== undefined && object.disclosure35 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure35.fromPartial(object.disclosure35)
      : undefined;
    message.disclosure36 = (object.disclosure36 !== undefined && object.disclosure36 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure36.fromPartial(object.disclosure36)
      : undefined;
    message.disclosure37 = (object.disclosure37 !== undefined && object.disclosure37 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure37.fromPartial(object.disclosure37)
      : undefined;
    message.disclosure38 = (object.disclosure38 !== undefined && object.disclosure38 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure38.fromPartial(object.disclosure38)
      : undefined;
    message.disclosure39 = (object.disclosure39 !== undefined && object.disclosure39 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure39.fromPartial(object.disclosure39)
      : undefined;
    message.disclosure310 = (object.disclosure310 !== undefined && object.disclosure310 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure310.fromPartial(object.disclosure310)
      : undefined;
    message.disclosure311 = (object.disclosure311 !== undefined && object.disclosure311 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure311.fromPartial(object.disclosure311)
      : undefined;
    message.disclosure312 = (object.disclosure312 !== undefined && object.disclosure312 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure312.fromPartial(object.disclosure312)
      : undefined;
    message.disclosure313 = (object.disclosure313 !== undefined && object.disclosure313 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure313.fromPartial(object.disclosure313)
      : undefined;
    message.disclosure314 = (object.disclosure314 !== undefined && object.disclosure314 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure314.fromPartial(object.disclosure314)
      : undefined;
    message.disclosure315 = (object.disclosure315 !== undefined && object.disclosure315 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure315.fromPartial(object.disclosure315)
      : undefined;
    message.disclosure316 = (object.disclosure316 !== undefined && object.disclosure316 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure316.fromPartial(object.disclosure316)
      : undefined;
    message.disclosure317 = (object.disclosure317 !== undefined && object.disclosure317 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure317.fromPartial(object.disclosure317)
      : undefined;
    message.disclosure318 = (object.disclosure318 !== undefined && object.disclosure318 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure318.fromPartial(object.disclosure318)
      : undefined;
    message.disclosure319 = (object.disclosure319 !== undefined && object.disclosure319 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure319.fromPartial(object.disclosure319)
      : undefined;
    message.disclosure320 = (object.disclosure320 !== undefined && object.disclosure320 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure320.fromPartial(object.disclosure320)
      : undefined;
    message.disclosure321 = (object.disclosure321 !== undefined && object.disclosure321 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure321.fromPartial(object.disclosure321)
      : undefined;
    message.disclosure322 = (object.disclosure322 !== undefined && object.disclosure322 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure322.fromPartial(object.disclosure322)
      : undefined;
    message.disclosure323 = (object.disclosure323 !== undefined && object.disclosure323 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure323.fromPartial(object.disclosure323)
      : undefined;
    message.disclosure49 = (object.disclosure49 !== undefined && object.disclosure49 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure49.fromPartial(object.disclosure49)
      : undefined;
    message.disclosure41 = (object.disclosure41 !== undefined && object.disclosure41 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure41.fromPartial(object.disclosure41)
      : undefined;
    message.disclosure42 = (object.disclosure42 !== undefined && object.disclosure42 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure42.fromPartial(object.disclosure42)
      : undefined;
    message.disclosure43 = (object.disclosure43 !== undefined && object.disclosure43 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure43.fromPartial(object.disclosure43)
      : undefined;
    message.disclosure44 = (object.disclosure44 !== undefined && object.disclosure44 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure44.fromPartial(object.disclosure44)
      : undefined;
    message.disclosure45 = (object.disclosure45 !== undefined && object.disclosure45 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure45.fromPartial(object.disclosure45)
      : undefined;
    message.disclosure46 = (object.disclosure46 !== undefined && object.disclosure46 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure46.fromPartial(object.disclosure46)
      : undefined;
    message.disclosure47 = (object.disclosure47 !== undefined && object.disclosure47 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure47.fromPartial(object.disclosure47)
      : undefined;
    message.disclosure48 = (object.disclosure48 !== undefined && object.disclosure48 !== null)
      ? MConvertibleNotePurchaseAgreementDisclosure48.fromPartial(object.disclosure48)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
