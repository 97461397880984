import * as H from "history";

// path is:
// /accountId/doctype/docId/section/step

const ACCOUNT_OFFSET = 1;
const DOCTYPE_OFFSET = 2;
const DOCID_OFFSET = 3;
const SECTION_OFFSET = 4;

export function goToFinish(location: H.Location) {
    return location.pathname.split("/").slice(0, SECTION_OFFSET).join("/") + "/finish/1"
}

export function getDocId(location: H.Location) {
    return location.pathname.split("/")[DOCID_OFFSET];
}

export function getFullDocId(location: H.Location) {
    let parts = location.pathname.split("/");
    return parts[ACCOUNT_OFFSET] + "/" + parts[DOCTYPE_OFFSET] + "/" + parts[DOCID_OFFSET];
}

export function getLastStep(location: H.Location) {
    return location.pathname.split("/").slice(SECTION_OFFSET).join("/");
}

export function isLocationFinish(location: H.Location) {
    return location.pathname.split("/")[SECTION_OFFSET] === "finish"
}
