/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface PContactInfo {
  ownerEmail: string;
  clientEmail: string;
  clientName: string;
  billingAddress: string;
  phone: string;
}

function createBasePContactInfo(): PContactInfo {
  return { ownerEmail: "", clientEmail: "", clientName: "", billingAddress: "", phone: "" };
}

export const PContactInfo = {
  encode(message: PContactInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownerEmail !== "") {
      writer.uint32(10).string(message.ownerEmail);
    }
    if (message.clientEmail !== "") {
      writer.uint32(18).string(message.clientEmail);
    }
    if (message.clientName !== "") {
      writer.uint32(26).string(message.clientName);
    }
    if (message.billingAddress !== "") {
      writer.uint32(34).string(message.billingAddress);
    }
    if (message.phone !== "") {
      writer.uint32(42).string(message.phone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContactInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContactInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.ownerEmail = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.clientEmail = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.clientName = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.billingAddress = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.phone = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContactInfo {
    return {
      ownerEmail: isSet(object.ownerEmail) ? String(object.ownerEmail) : "",
      clientEmail: isSet(object.clientEmail) ? String(object.clientEmail) : "",
      clientName: isSet(object.clientName) ? String(object.clientName) : "",
      billingAddress: isSet(object.billingAddress) ? String(object.billingAddress) : "",
      phone: isSet(object.phone) ? String(object.phone) : "",
    };
  },

  toJSON(message: PContactInfo): unknown {
    const obj: any = {};
    message.ownerEmail !== undefined && (obj.ownerEmail = message.ownerEmail);
    message.clientEmail !== undefined && (obj.clientEmail = message.clientEmail);
    message.clientName !== undefined && (obj.clientName = message.clientName);
    message.billingAddress !== undefined && (obj.billingAddress = message.billingAddress);
    message.phone !== undefined && (obj.phone = message.phone);
    return obj;
  },

  create<I extends Exact<DeepPartial<PContactInfo>, I>>(base?: I): PContactInfo {
    return PContactInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContactInfo>, I>>(object: I): PContactInfo {
    const message = createBasePContactInfo();
    message.ownerEmail = object.ownerEmail ?? "";
    message.clientEmail = object.clientEmail ?? "";
    message.clientName = object.clientName ?? "";
    message.billingAddress = object.billingAddress ?? "";
    message.phone = object.phone ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
