import { fromAlpha, toAlpha } from "components/wizard-display/AlphaUtils";
import { PDoc } from "proto/PDoc";
import { PLegalUnit } from "proto/PLegalUnit";
import { conditionToJsonLogic } from "../WizardForm";
import { AgreementContextType } from "./AgreementContextType";

export const ACCOUNT_OFFSET = 5;
export const DOCTYPE_OFFSET = 4;
export const DOCID_OFFSET = 3;
export const SECTION_OFFSET = 2;
export const STEP_OFFSET = 1;

const isNullish = (value: any) => [undefined, null, ""].includes(value);

export const isEmpty = (value: any) => {
    if (isNullish(value)) return true;
    if (Array.isArray(value)) return !value.length ? true : value.some((val) => isNullish(val));

    return false;
};

const getPaths = () => window.location.pathname.split("/");

export const isHelpOpen = () => {
    const paths = getPaths();

    return paths[paths.length - 1] === "help";
};

export const getUrlsStats = () => {
    const paths = getPaths();

    const [user, name, id, sectionAlpa, stepIndex] = paths.slice(isHelpOpen() ? -6 : -5);

    return {
        user,
        name,
        id,
        sectionAlpa,
        paths,
        stepIndex: +stepIndex,
        isPaymentPage: ["payment", "payment-plans"].includes(stepIndex),
    };
};

export const getFullDocId = () =>
    getPaths()
        .slice(1, 4)
        .join("/");

export const getCurrentContract = (draft: PDoc | undefined): any => {
    // auto contract use legalUnit name to detect current contract
    let kind = draft?.legalUnit?.kind;
    if (!kind || !draft) return;
    return draft.legalUnit?.[kind as keyof PLegalUnit];
};
export const getAllSectionSteps = (sectionId: string, agreement: Agreement | undefined, contract: any) => {
    const sectionSteps =
        agreement?.wizard?.sections.byId[sectionId].steps.map((id) => agreement?.wizard.steps.byId[id]) || [];

    return sectionSteps;
};

// filtered base on user answers
export const getSectionSteps = (sectionId: string, agreement: Agreement | undefined, contract: any) => {
    const sectionSteps =
        agreement?.wizard?.sections.byId[sectionId].steps.map((id) => agreement?.wizard.steps.byId[id]) || [];

    return sectionSteps.filter(
        (step) => !step.condition || conditionToJsonLogic(step.condition, agreement?.fields, contract)
    );
};

export const getSections = (agreement: Agreement | undefined, contract: any) => {
    const sections = agreement?.wizard?.sections.allIds.map((id) => agreement?.wizard?.sections.byId[id]) || [];

    return sections.filter((s) => !s.condition || conditionToJsonLogic(s.condition, agreement?.fields, contract));
};
export const getStepQuestions = (agreement: Agreement | undefined, contract: any, stepId: string) => {
    const step = agreement?.wizard?.steps.byId[stepId];
    if (!step) return [];
    return step.questions.map((id) => agreement.wizard.questions.byId[id]);
};

export const getAllFilteredSteps = (agreement: Agreement | undefined, contract: any) => {
    return getSections(agreement, contract)?.reduce(
        (prevResult: Step[], currSection) => prevResult.concat(getSectionSteps(currSection.id, agreement, contract)),
        []
    );
};

export const getCurrentSection = (agreement: Agreement | undefined, contract: any) => {
    if (!agreement?.wizard) return;

    const sectionIndex = fromAlpha(getUrlsStats().sectionAlpa);

    return getSections(agreement, contract)[sectionIndex];
};

export const getCurrentStep = (agreement: Agreement | undefined, contract: any) => {
    if (!agreement?.wizard) return;

    const section = getCurrentSection(agreement, contract);

    if (!section?.id) return;

    return getSectionSteps(section.id, agreement, contract)[Number(getUrlsStats().stepIndex) - 1];
};

export const getFilteredQuestionsForStep = (
    agreement: Agreement | undefined,
    contract: any,
    step: Step
): Question[] => {
    if (!agreement?.wizard || !step) {
        return [];
    }

    const questions = step?.questions.filter((q) => q).map((q) => agreement.wizard.questions.byId[q]);

    return questions.filter((q) => checkQuestionConditions(agreement, contract, q));
};

export const getAllQuestions = (agreement: Agreement): Question[] => {
    return agreement?.wizard?.questions?.allIds?.map((q) => agreement.wizard.questions.byId[q]) ?? [];
};

export const checkQuestionConditions = (agreement: Agreement | undefined, contract: any, q: Question): boolean => {
    return !q.condition || conditionToJsonLogic(q.condition, agreement?.fields, contract);
};

export const getAllFilteredQuestions = (agreement: Agreement | undefined, contract: any): Question[] => {
    if (!agreement?.wizard) {
        return [];
    }

    const allSteps = getAllFilteredSteps(agreement, contract);

    return allSteps?.reduce(
        (prevResult: Question[], currStep) =>
            prevResult.concat(getFilteredQuestionsForStep(agreement, contract, currStep)),
        []
    );
};

export const getSectionUrl = (sectionIndex: number) => {
    const alpa = toAlpha(sectionIndex + 1);
    const paths = getPaths();
    paths[paths.length - SECTION_OFFSET] = alpa;
    paths[paths.length - STEP_OFFSET] = "1"; //first step of section
    const path = paths.join("/");
    return path;
};

export function getNextStepUrl(sections: Section[], steps: Step[]) {
    const paths = getPaths();
    const { sectionAlpa, stepIndex } = getUrlsStats();
    const sectionIndex = fromAlpha(sectionAlpa);
    const section = sections[sectionIndex];

    const relevantSteps = steps.filter((step) => section.steps.includes(step.id));

    if (stepIndex < relevantSteps.length) {
        paths[paths.length - 1] = (stepIndex + 1).toString();
    } else if (sectionIndex + 1 < sections.length) {
        paths[paths.length - 2] = toAlpha(sectionIndex + 2);
        paths[paths.length - 1] = "1";
    }

    const newPath = paths.join("/");

    return newPath;
}

export const getStepUrl = (stepIndex: number) => {
    const paths = getPaths();
    paths[paths.length - STEP_OFFSET] = String(stepIndex + 1);
    const path = paths.join("/");
    return path;
};

export const getMaxStep = (agreement: Agreement | undefined, contract: any) => {
    const sections = getSections(agreement, contract);
    const lastSetion = sections[sections.length - 1];

    if (!sections.length) return "";

    const lastSectionUrl = getSectionUrl(sections.length - 1);

    const lastSectionSteps = getSectionSteps(lastSetion.id, agreement, contract);

    return lastSectionUrl.split("/").slice(0, -1).join("/") + "/" + lastSectionSteps.length;
};
