import clsx from "clsx";
import { SvgIconFinishSecondary } from "components/0_common";
import { toAlpha } from "components/wizard-display/AlphaUtils";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import { forwardRef, Fragment, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { MainStore } from "store/mainTypes";
import { AgreementContext } from "./AgreementContext/AgreementContext";
import { getSections, getSectionSteps } from "./AgreementContext/shared-api";

interface WizardNavigatorProps {
    agreement: Agreement;
}

export function WizardNavigatorNew(props: WizardNavigatorProps) {
    const { agreement } = props;

    const agreementContext = useContext(AgreementContext);
    let activeSection = agreementContext?.navigation.getActiveSectionAlpa();

    const history = useHistory();
    const location = useLocation();
    let pathname = location.pathname;
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const dispatch = useDispatch();

    let account = dbUser?.accountName;
    let activeDraft = agreementContext?.getActiveDraft();

    const isFreeStyleDoc = !!activeDraft?.legalUnit?.freestyleSrc;

    const currentContract = agreementContext?.wizardApi.getCurrentContract(activeDraft);

    let wizardNavigationOpen = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.wizardNavigationOpen
    );
    const isMobile = useCheckMobileScreen();
    wizardNavigationOpen = wizardNavigationOpen && !isMobile;

    const ref = useRef<HTMLDivElement | null>(null);

    const [progress, setProgress] = useState(0);
    const [activeSectionPos, setActiveSectionPos] = useState(0);

    const he = agreement.lang === "HE"

    useEffect(() => {
        const stepEl = document.getElementById("active-step");
        if (stepEl && ref.current) {
            const rootRect = ref.current?.getBoundingClientRect();
            const rect = stepEl.getBoundingClientRect();
            setProgress(stepEl.offsetTop);
        }

        if (activeSectionRef.current) {
            setActiveSectionPos(activeSectionRef.current.offsetTop);
        }
    });

    const activeStepRef = useRef<HTMLDivElement | null>(null);
    const activeSectionRef = useRef<HTMLDivElement | null>(null);

    return (
        <div
            ref={ref}
            className={clsx("pr-4 py-4 shadow rounded-lg relative Truncated bg-primaryVeryLight z-[1] overflow-y-auto max-h-[85vh] _ScrollbarNarrow", {
                "rounded-r-[42px] w-[70px]": isMobile,
            })}
        >
            <div
                className="w-full h-full -z-[2]"
                style={{
                    backgroundImage: `linear-gradient(#2d5ffb,#2d5ffb),repeating-linear-gradient(to bottom,transparent 0 2px,black 4px 6px)`,
                    backgroundPosition: "2.2rem 0, 2.2rem, center",
                    backgroundPositionX: !he? "2.2rem": "calc(100% - 2.2rem)",
                    backgroundSize: `2px ${progress}px, 2px 100%, cover`,
                    backgroundRepeat: "no-repeat",
                    transition: "background-size 0.5s ease",
                    backgroundOrigin: "content-box,content-box, border-box",
                }}
            >
                <div
                    style={{
                        top: activeSectionPos - 4 + "px",
                    }}
                    className={`transition-all duration-300 absolute h-12 bg-powderBlue -z-[1]
		    ltr:rounded-r-full rtl:rounded-l-full
		    right-0 left-0 ltr:right-4 rtl:left-4`}
                />

                <div className="flex gap-4 flex-col flex-grow-0 flex-shrink-0">
                    {isFreeStyleDoc && (
                        <Fragment>
                            <Section
                                icon={toAlpha(0 + 1)}
                                label={"Doc Preview"}
                                active={activeSection === toAlpha(0 + 1)}
                                completed={true}
                                semiCompleted={true}
                                onClick={() => {
                                    return agreementContext?.navigation.navigateToSectionByIndex(0);
                                }}
                                ref={activeSection === toAlpha(0 + 1) ? activeSectionRef : undefined}
                            />
                        </Fragment>
                    )}
                    {!isFreeStyleDoc &&
                        getSections(agreement, currentContract).map((section: Section, index: number) => {
                            return (
                                <Fragment key={section.id}>
                                    <Section
                                        key={section.id}
                                        icon={toAlpha(index + 1)}
                                        label={section.name}
                                        active={activeSection === toAlpha(index + 1)}
                                        completed={agreementContext?.wizardApi.isSectionCompleted(section) ?? false}
                                        semiCompleted={
                                            agreementContext?.wizardApi.isSectionSemiCompleted(section) ?? false
                                        }
                                        onClick={() => {
                                            return agreementContext?.navigation.navigateToSectionByIndex(index);
                                        }}
                                        ref={activeSection === toAlpha(index + 1) ? activeSectionRef : undefined}
                                    />

                                    {activeSection !== toAlpha(index + 1)
                                        ? null
                                        : getSectionSteps(section.id, agreement, currentContract).map(
                                              (step: Step, innerIndex: number) => {
                                                  let isAllDone = agreementContext?.wizardApi.isStepCompleted(step);
                                                  let isActiveStep =
                                                      agreementContext?.navigation.isActiveStepByIndex(innerIndex);

                                                  return (
                                                      <Step
                                                          key={step.id}
                                                          ref={activeStepRef}
                                                          active={isActiveStep ?? false}
                                                          completed={isAllDone ?? false}
                                                          label={step.name}
                                                          onClick={() => {
                                                              agreementContext?.navigation.navigateToStepByIndex(
                                                                  innerIndex
                                                              );
                                                          }}
                                                      />
                                                  );
                                              }
                                          )}
                                </Fragment>
                            );
                        })}
                    <Section
                        icon={
                            // workaround todo: find better way handle icons
                            <div className="bg-secondary rounded-lg">
                                <SvgIconFinishSecondary />
                            </div>
                        }
                        label={!he ? "Finish & Download" : "סיום והורדה"}
                        active={activeSection === "finish"}
                        completed={agreementContext?.wizardApi.isWizardCompleted() ?? false}
                        semiCompleted={false}
                        onClick={() => {
                            history.push("../finish/1");
                        }}
                        ref={activeSection === "finish" ? activeSectionRef : undefined}
                    />
                </div>
            </div>
        </div>
    );
}

const Step = forwardRef<
    HTMLDivElement,
    { icon?: string; label: string; active: boolean; completed: boolean; onClick: any }
>((props, ref) => {
    const { icon, label, active, onClick, completed } = props;

    return (
        <div
            id={active ? "active-step" : ""}
            className={`flex gap-4 items-center  rounded-r-full px-4 cursor-pointer flex-shrink-0 flex-row`}
            ref={ref}
            onClick={onClick}
        >
            <div
                className={`h-4 w-4 flex-shrink-0 rounded-full shadow mx-3 transition-all duration-1000 delay-[450]
		${completed ? "bg-primary text-white" : "bg-white text-primaryLight"} ${active ? "border-2 border-secondary " : " "}
                flex items-center justify-center`}
            >
                {icon}
            </div>
            <div className={`hidden lg:block ${active || completed ? "text-textBase" : " text-primaryLight"}`}>
                {label}
            </div>
        </div>
    );
});

const Section = forwardRef<
    HTMLDivElement,
    { icon: ReactNode; label: string; active: boolean; completed: boolean; semiCompleted: boolean; onClick: any }
>((props, ref) => {
    const { icon, label, active, completed, semiCompleted, onClick } = props;

    return (
        <div className={`flex gap-4 items-center rounded-r-full  px-4 cursor-pointer flex-row`} ref={ref} onClick={onClick}>
            {typeof icon === "string" ? (
                <div
                    className={`h-10 w-10 rounded-full shadow-md flex-shrink-0 
                    ${
                        completed
                            ? "bg-primary text-white "
                            : semiCompleted
                            ? "bg-primaryHalf text-white "
                            : " bg-white text-primaryLight"
                    }
            ${active ? "border-2 border-secondary " : " "}
                    flex items-center justify-center`}
                >
                    {icon}
                </div>
            ) : (
                <div className={`h-10 w-10 rounded-full flex-shrink-0 flex items-center justify-center`}>{icon}</div>
            )}

            <div className={`hidden lg:block ${completed || active ? "text-textBase" : "text-primaryLight"}`}>
                {label}
            </div>
        </div>
    );
});
