import type { Step } from "react-joyride";
// db value used when select/multi-select has custom(free text) other option
export const SELECT_CUSTOM_OTHER_DB_VALUE = 999999;

// Shopify supported documents. In case the user came from Shopify, the following
// documents can be embedded to the store automagically!
export const SHOPIFY_SUPPORTED_DOCUMENTS = ["privacyPolicy", "jointTermsPhysical"];

export const SHOPIFY_TOUR_STEPS: Array<Step> = [
    {
        content:
            "Welcome to Clawdia, Shopify user! With Clawdia, you can easily create your privacy policy, terms of use, and more for your online store to ensure you are legally covered.",
        placement: "center",
        target: "body",
        disableBeacon: true,
    },
    {
        target: "#privacyPolicy",
        content: "Create your privacy policy here.",
    },
    {
        target: "#jointTermsPhysical",
        content: "Create your terms of use here.",
    },
    {
        target: "#contracts-container",
        content:
            "You can also do a lot more, like a creating a mutual NDA, constructing a resignation letter, and much more!",
    },
];
