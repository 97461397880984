import { Button, TextField } from "components";
import { CTable, tableConvertor } from "components/0_common/Table";
import { useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

export const FillTableModal = () => {
    const payload = useAppSelector((state) => state.ui.modals.FillTableModal.data);

    const [data, setData] = useState(tableConvertor.from(payload?.value) ?? []);
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "FillTableModal" }));
    };

    const onSave = () => {
        dispatch(Actions.ui.closeModal({ name: "FillTableModal" }));
        payload?.onSave?.(tableConvertor.to(data));
    };

    if (!payload?.field) return null;

    const { field } = payload;

    return (
        <BaseModal>
            <div className="flex flex-col gap-4">
                <CTable
                    onDataChange={setData}
                    data={data}
                    options={{
                        row: {
                            unlimited: field.tableProperties?.unlimitedRows,
                            heading: field.tableProperties?.rowHeading,
                            defaultRows: field?.tableProperties?.rows,
                        },
                        columns: field.tableProperties?.columns ?? [],
                        column: {
                            headingLabel: field.tableProperties?.columnHeading,
                        },
                    }}
                />
                <div className="flex justify-between">
                    <Button type="button" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={onSave}>
                        Save
                    </Button>
                </div>
            </div>
        </BaseModal>
    );
};
