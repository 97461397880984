import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { playVideo } from "../store/mainActions";
import "./Modal.scss";

export default function Modal(props: {
    children: JSX.Element[] | JSX.Element;
    closeable?: boolean;
    closeUrl?: string;
    className?: string;
    fixed?: boolean;
}) {
    const [appear, setAppear] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setAppear(true);
        window.addEventListener("keydown", handleEsc);

        return () => {
            setAppear(false);
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    function handleEsc(event: KeyboardEvent) {
        if (event.code === "Escape") {
            handleClose();
        }
    }

    function handleClose() {
        if (props.closeable) {
            history.push(props.closeUrl ?? "/");
            // todo - this is a hack
            dispatch(playVideo(undefined));
        }
    }

    return (
        <div
            className={`h-full w-full bg-accent bg-opacity-60 overflow-hidden ${props.fixed ? "fixed" : "absolute"
                } z-30 top-0 left-0 flex justify-center items-center`}
            onClick={() => {
                handleClose();
            }}
        >
            <div
                className={
                    "ModalContent bg-white w-11/12 sm:w-auto rounded-2xl absolute z-40 opacity-100 flex flex-col " +
                    (appear ? "Appear" : "") +
                    (props.className ? " " + props.className : "")
                }
                onClick={(e) => {
                    if (props.closeable) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                {props.children}
            </div>
        </div>
    );
}

export function ModalFooter(props: { children: JSX.Element[] | JSX.Element }) {
    return <div className="bg-primaryFooter rounded-b-2xl">{props.children}</div>;
}

export function ExitButton({ onClick, className }: { onClick: () => void, className?: string }) {
    return <div
        onClick={onClick}
        className={`shadow-md cursor-pointer w-9 h-9 absolute top-6 right-6 border rounded-lg border-skyBlue flex items-center justify-center text-darkIndigo text-lg font-medium ${className ?? ""}`}
    >
        &times;
    </div>
}
