import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
}

export function Card(props: Props) {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} data-name="Card" className={clsx("border border-lightSkyBlue border-opacity-50 rounded-lg shadow-card", className)}>
            {children}
        </div>
    );
}
