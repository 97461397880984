import { Button, TextField } from "components";
import { useForm } from "hooks";
import { useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

export const EditSectionModal = () => {
    const section = useAppSelector((state) => state.ui.modals.ForLawyers_editSection.data);

    const form = useForm<Section>(section, {
        onSubmit: (values) => {},
    });

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "ForLawyers_editSection" }));
    };

    const onSave = () => {
        if (!section) return;
        dispatch(
            Actions.agreementManager.updateSection({
                sectionId: section.id,
                data: form.values,
            })
        );
        dispatch(Actions.ui.closeModal({ name: "ForLawyers_editSection" }));
    };

    return (
        <BaseModal>
            <form onSubmit={form.onSubmit} className="flex flex-col gap-8">
                <TextField name="name" placeholder={`Enter name`} onChange={form.onChange} value={form.values.name} />
                <div className="flex justify-between">
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit" onClick={onSave}>
                        Save
                    </Button>
                </div>
            </form>
        </BaseModal>
    );
};
