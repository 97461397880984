import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { AgreementContextType } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContextType";
import { isEmpty } from "apps/legal-ide/App/components/WizardUi/AgreementContext/shared-api";
import { checkIfLegalIDEContract } from "apps/legal-ide/App/components/WizardUi/WizardUtils";
import { Button, SvgIconArrowRight, SvgIconArrowRightSecondary } from "components";
import { useAuth } from "components/contexts/AuthContext";
import { useMouse } from "hooks";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store";
import { MainStore } from "store/mainTypes";
import ClawdiaImgWithCircle from "../../img/clawdia-with-circle.png";

import { documentToTitle } from "../ContractChooser";

interface FinishDraftMessageProps {
    steps: any[];
    showLogo?: boolean;
}

export const FinishDraftMessage = (props: FinishDraftMessageProps) => {
    const { steps = [], showLogo = true } = props;

    const auth = useAuth();

    const history = useHistory();
    let activeDraft = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.activeDraft);
    const agreementContext = useAgreementContext();

    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const he = agreement?.lang === "HE";

    if (!steps.length || !agreementContext) return null;

    const emptyQuestionsCount = steps.map((step) => emptyQuestionsPerStep(step, agreementContext)).flat().length

    return (
        <div className="p-6">
            <div className="border-b border-skyBlue py-6 font-semibold text-xl">
                {he && auth?.getFirstName() + `, יש לך רק עוד ${emptyQuestionsCount} שאלות נוספות לסיים ולהוריד את `}
                {!he && auth?.getFirstName() + `, you have just ${emptyQuestionsCount} more questions left to finish and download your `}
                {documentToTitle(activeDraft)}.
            </div>

            <div className="">
                {steps.map((step: Step) => {
                    return <StepQuestionsToResolve step={step} key={step.id} agreementContext={agreementContext} />
                })}
            </div>
        </div>
    );
};

function emptyQuestionsPerStep(step: Step, agreementContext: AgreementContextType) {
    const emptyQuestions = agreementContext?.wizardApi.getFilteredQuestionsForStep(step).filter(q => isEmpty(
        agreementContext.wizardApi.getQuestionValue(q)));
    
    return emptyQuestions;
}

function StepQuestionsToResolve({ step, agreementContext }: { step: Step, agreementContext: AgreementContextType }) {
    const emptyQuestions = emptyQuestionsPerStep(step, agreementContext);

    return <>{emptyQuestions?.map(q => <QuestionToResolve question={q} step={step} key={q.id} />)}</>;
}

function QuestionToResolve({ question, step }: { question: Question, step: Step }) {
    const mouse = useMouse<HTMLDivElement>();

    const isHover = mouse.isIn;
    const agreementContext = useAgreementContext();
    const currentAgreement = useAppSelector(state => state.agreementManager.currentAgreement)
    const field = currentAgreement?.fields.find(f => f.id === question.fieldId)


    return <div ref={mouse.ref} className="border-b border-skyBlue py-6 flex justify-between items-center cursor-pointer" key={question.id}
        onClick={() => field && agreementContext?.navigation.goToQuestionByFieldName(field.name)}
    >
        <div className="flex items-center gap-4">
            <span className="h-10 w-10 flex-shrink-0">{isHover ? <SvgIconArrowRightSecondary /> : <SvgIconArrowRight />}</span>
            <span>{cleanupQuestionText(question.text || "")}</span>
        </div>
        <Button
            variant={isHover ? "contained" : "outlined"}
            color={isHover ? "primary" : "skyBlue"}
        >
            Resolve
        </Button>
    </div>
}

function cleanupQuestionText(questionStr: string) {
    return questionStr
        .split("\n")[0]
        .replaceAll(/\s*\(.*?\)\s*/g, " ")
        .trim();
}
