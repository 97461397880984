import { Node, Descendant, BaseEditor, Location } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";
import { Layers } from "apps/legal-ide/features/Field";
import { KbElement } from "apps/legal-ide/features";

export enum FieldType {
    STRING,
    NUMBER,
    BOOL,
    ENUM,
    MULTI_ENUM,
    DATE,
    ADDRESS,
    ARRAY,
    GOVERNING_LAW_STATE,
    LINK,
    EMBEDDED_FILE,
    LONG_STRING,
    UNKNOWN,
    TABLE,
}
export interface Field {
    id: string;
    name: string;
    type: FieldType;
    layer?: "doc" | "legalUnit" | "owner" | "other";
    options?: FieldOption[];
    optionCounter: number; // auto increase id for options
    numberProperties?: NumberProperties;
    selectProperties?: SelectProperties;
    fileProperties?: FileProperties;
}

export type BlockQuoteElement = { align?: string; type: "block-quote"; children: Descendant[] };

export type BulletedListElement = {
    type: "bulleted-list";
    children: Descendant[];
    align?: string;
};

export type OrderedListElement = {
    type: "numbered-list";
    children: Descendant[];
    align?: string;
};

export type CheckListItemElement = {
    type: "check-list-item";
    checked: boolean;
    children: Descendant[];
    align?: string;
};

export type EditableVoidElement = {
    type: "editable-void";
    children: EmptyText[];
    align?: string;
};

export type HeadingOneElement = {
    type: "heading-one";
    children: Descendant[];
    align?: string;
};

export type HeadingTwoElement = {
    type: "heading-two";
    children: Descendant[];
    align?: string;
};

export type HeadingThreeElement = {
    type: "heading-three";
    children: Descendant[];
    align?: string;
};

export type HeadingFiveElement = {
    type: "heading-five";
    children: Descendant[];
    align?: string;
};

export type PreParagraphMarginElement = {
    type: "pre-paragraph-margin";
    children: Descendant[];
    align?: string;
};

export type El = HTMLElement & Node & ChildNode;

export type LinkElement = { align?: string; type: "link"; url: string; children: Descendant[] };

export type ButtonElement = { align?: string; type: "button"; children: Descendant[] };

export type ListItemElement = { align?: string; type: "list-item"; children: Descendant[] };

export type ParagraphElement = { type: "paragraph"; align?: string; children: Descendant[] };

export type TitleElement = { align?: string; type: "title"; children: Descendant[] };

export enum PIPES {
    UPPERCASE = "UPPERCASE",
    LOWERCASE = "LOWERCASE",
    FIRST = "FIRST",
    LAST = "LAST",
    DURATION_TO_NUMBER = "DURATION_TO_NUMBER",
    MULTIPLY_ONE_AND_A_HALF = "MULTIPLY_ONE_AND_A_HALF",
    OPPOSITE_PERCENTAGE = "OPPOSITE_PERCENTAGE",
}

export type InputElement = {
    align?: string;
    type: "input";
    children: EmptyText[];
    fieldId: string;
    layer?: Layers;
    pipes?: string[];
    hasABefore: boolean;
};

export type SignatureElementType = { align?: string; type: "signature"; children: EmptyText[]; count: number };
export type GptSplitElementType = { align?: string; type: "gpt-split"; children: EmptyText[]};

export type PageBreakElementType = { align?: string; type: "page-break"; children: EmptyText[] };

// todo: check if can be removed - old way
export type ConditionElement = {
    type: "condition";
    condition: any;
    selection: Location;
    children: Descendant[];
    conditionId: string;
    align?: string;
};

export type ConditionBlock = {
    // new
    type: "condition-block";
    children: Descendant | Descendant[];
    condition?: CompoundJsonLogic | undefined;
    align?: string;
};
export type ConditionInline = {
    // new
    type: "condition-inline";
    children: Descendant | Descendant[];
    condition?: CompoundJsonLogic | undefined;
    align?: string;
};

export type CustomElement =
    | CheckListItemElement
    | LinkElement
    | ButtonElement
    | ListItemElement
    | ParagraphElement
    | TitleElement
    | InputElement
    | SignatureElementType
    | GptSplitElementType
    | PageBreakElementType
    | KbElement
    | ConditionBlock
    | ConditionInline
    | ConditionElement
    | BlockQuoteElement
    | BulletedListElement
    | OrderedListElement
    | HeadingOneElement
    | HeadingTwoElement
    | HeadingThreeElement
    | HeadingFiveElement
    | PreParagraphMarginElement;

export type CustomElementTypes = keyof CustomElement["type"];

export type CustomText = {
    fillable?: boolean;
    bold?: boolean;
    if?: boolean;
    italic?: boolean;
    code?: boolean;
    underline?: boolean;
    textAlign?: string;
    kb?: string;
    text: string;
    tag?: boolean;
    heading1?: boolean;
    signature?: boolean;
    pageBreak?: boolean;
    error?: boolean;
    tooltip?: string;

    // block
    quote?: string;
};

export type EmptyText = {
    text: string;
};

export type Mark = keyof Omit<CustomText, "text">;

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module "slate" {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: CustomElement;
        Text: CustomText;
    }
}
