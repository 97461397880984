import {
    Button,
    colors,
    ButtonVariants,
    ButtonSizes,
    ButtonShapes,
    IconsNames,
    NewIcon,
} from "components";
import { ReactNode, useEffect, useRef } from "react";

function Title({ children }: { children: ReactNode }) {
    return <h2 className="font-semibold text-2xl">{children}</h2>;
}

function Section({ children }: { children: ReactNode }) {
    return (
        <div className="w-full flex flex-col gap-4">
            {children}
            <hr />
        </div>
    );
}

export const StyleGuidePage = () => {
    const ref = useRef<HTMLButtonElement | null>(null);

    return (
        <div
            className="w-full p-6 flex flex-col gap-8 overflow-y-auto"
            style={
                {
                    // position: "relative",
                    // backgroundColor: "#131c2a",
                    // backgroundImage:
                    //     "linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px)",
                    // backgroundSize: "60px 60px, 60px 60px, 20px 20px, 20px 20px",
                    // backgroundPosition: "-2px -2px, -2px -2px, -1px -1px, -1px -1px",
                    // backgroundAttachment: "fixed",
                }
            }
        >
            <Section>
                <Title>default</Title>
                <div className="flex gap-4">
                    {IconsNames.map((name) => (
                        <NewIcon key={name} name={name} />
                    ))}
                </div>
            </Section>
            <Section>
                <Title>default</Title>
                <div className="flex gap-4">
                    <Button onClick={() => console.log("click")} ref={ref}>
                        default
                    </Button>
                </div>
            </Section>
            <Section>
                <Title>colors</Title>
                <div className="flex gap-4">
                    {colors.map((color) => (
                        <Button key={color} color={color}>
                            {color}
                        </Button>
                    ))}
                </div>
            </Section>
            <Section>
                <Title>sizes</Title>
                <span className="text-sm">text</span>
                <div className="flex gap-4 items-center">
                    {ButtonSizes.map((size) => (
                        <Button key={size} size={size}>
                            btn {size}
                        </Button>
                    ))}
                    {ButtonSizes.map((size) =>
                        ButtonShapes.map((shape) => (
                            <Button key={size + shape} shape={shape} size={size}>
                                +
                            </Button>
                        ))
                    )}
                </div>
            </Section>
            <Section>
                <Title>shapes</Title>
                <div className="flex gap-4 items-center">
                    {ButtonVariants.map((variant) =>
                        ButtonShapes.map((shape) => (
                            <Button key={shape + variant} variant={variant} shape={shape}>
                                +
                            </Button>
                        ))
                    )}
                </div>
            </Section>
            <Section>
                <Title>icons</Title>
                <div className="flex gap-4 items-center">
                    {ButtonVariants.map((variant) => (
                        <Button startIcon="arrow_left" key={variant} variant={variant}>
                            back
                        </Button>
                    ))}
                    {ButtonVariants.map((variant) => (
                        <Button endIcon="arrow_right" key={variant} variant={variant}>
                            next
                        </Button>
                    ))}
                </div>
            </Section>
            <Section>
                <Title>variants</Title>
                <div className="flex gap-4">
                    {ButtonVariants.map((variant) => (
                        <Button key={variant} variant={variant}>
                            {variant}
                        </Button>
                    ))}
                </div>
            </Section>
            <Section>
                <Title>loading</Title>
                <div className="flex gap-4">
                    <Button loading variant="contained">
                        contained
                    </Button>
                    <Button loading variant="outlined">
                        outlined
                    </Button>
                    <Button loading variant="text">
                        text
                    </Button>
                </div>
            </Section>
            <Section>
                <Title>disabled</Title>
                <div className="flex gap-4">
                    <Button disabled variant="contained">
                        contained
                    </Button>
                    <Button disabled variant="outlined">
                        outlined
                    </Button>
                    <Button disabled variant="text">
                        text
                    </Button>
                </div>
            </Section>
            <Section>
                <Title>fullWidth</Title>
                <div className="flex flex-col gap-4">
                    <Button fullWidth variant="contained">
                        contained
                    </Button>
                    <Button fullWidth variant="outlined">
                        outlined
                    </Button>
                    <Button fullWidth variant="text">
                        text
                    </Button>
                </div>
            </Section>
            <Section>
                <Title>combinations</Title>
                <div className="flex gap-4 flex-wrap">
                    {ButtonVariants.map((variant) =>
                        colors.map((color) => (
                            <Button key={variant} color={color} variant={variant}>
                                {variant} {color}
                            </Button>
                        ))
                    )}
                </div>
            </Section>
        </div>
    );
};
