import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

// 1) store notifications array
// 2) allow read write update remove notifications

export enum NotificationTypes {
    NEW_FEATURE = "NEW_FEATURE",
}

export interface Notification {
    type: NotificationTypes;
    title: string;
    message: string;
    creationDate: Date;
}
export interface notificationsState {
    list: Notification[];
}

const initialState: notificationsState = {
    list: [],
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        add: {
            prepare: (payload: Notification) => {
                return { payload };
            },
            reducer: (state, action: PayloadAction<Notification, string>) => {
                state.list.push(action.payload);
            },
        },
    },
});

export const selectors = {
    selectAll: (state: RootState) => state.notifications.list,
};
