import { isJson } from "components/utils/UtilFunctions";

export const formatNumber = (
    number: number | string,
    options?: {
        prefixSymbol?: string;
        suffixSymbol?: string;
    }
) => {
    let result = (typeof number === "string" ? parseFloat(number) : number).toLocaleString(); // this will make sure 1000 is formatted as 1,000

    if (options?.prefixSymbol != undefined) {
        result = `${options.prefixSymbol}${result}`;
    }
    if (options?.suffixSymbol != undefined) {
        result = `${result}${supportPluralVsSingularSuffix(number, options.suffixSymbol)}`;
    }
    return result;
};

const periods = ["years", "months", "weeks", "days", "hours", "minutes", "seconds"];

function supportPluralVsSingularSuffix(numericalValue: number | string, suffixSymbol: string | undefined) {
    if (!suffixSymbol) {
        return "";
    }
    if (numericalValue !== 1 && Number(numericalValue) !== 1) {
        return suffixSymbol;
    }

    let result = suffixSymbol;

    periods.forEach((period) => {
        const periodWithApostrophe = period + "'";

        if (suffixSymbol.includes(periodWithApostrophe)) {
            result = suffixSymbol.replace(periodWithApostrophe, period.slice(0, -1) + "'s");
        } else if (suffixSymbol.includes(period)) {
            result = result.replace(period, period.slice(0, -1));
        }
    });

    return result;
}

export function formatAddress(addressValue: string) {
    if (!addressValue) {
        return "";
    }

    if (isJson(addressValue)) {
        const addressJson = JSON.parse(addressValue || "{}") as Address;

        if (addressJson?.addressWithoutState) {
            addressValue = addressJson.addressWithoutState;
        }

        if (addressJson?.usState) {
            addressValue = (addressValue ? addressValue + ", " : "") + addressJson.usState;
        }
        
        if (addressJson.zipCode) {
            addressValue = (addressValue ? addressValue + ", " : "") + addressJson.zipCode;
        }
    }

    return addressValue;
}

export interface Address {
    addressWithoutState: string;
    usState: string;
    zipCode: string;
}
