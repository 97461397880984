import { useContext, createContext, ReactNode, useMemo, useState, useCallback } from "react";
import { PdfElement } from "./components/elements/PdfElements";
import { PdfJSDoc } from "./utils/pdfJsApi";

interface ContextType {
    state: State;
    updateState: (payload: Partial<State>) => void;
    addNewElement: (element: PdfElement) => void;
}
const PdfStoreContext = createContext<ContextType | null>(null);

export const usePdfContext = () => useContext(PdfStoreContext)!;

interface State {
    file: File | null;
    drawingIsOpen: boolean;
    elements: { [pageNumber: number]: PdfElement[] };
    selectedPage: number; // 0 == not selected
    selectedElement: PdfElement | null;
    totalPages: number;
    pdfJsDoc: PdfJSDoc | null;
}

export const PdfStoreProvider = ({ children }: { children: ReactNode }) => {
    const [state, setState] = useState<State>({
        file: null,
        drawingIsOpen: false,
        totalPages: 0,
        elements: {},
        selectedElement: null,
        selectedPage: 0,
        pdfJsDoc: null,
    });

    const updateState = useCallback((payload: Partial<State>) => {
        setState((state) => ({ ...state, ...payload }));
    }, []);

    const addNewElement = useCallback(
        (element: PdfElement) => {
            if (!state.selectedPage) return;
            const pageObjects = [...(state.elements[state.selectedPage] ?? [])];

            pageObjects.push(element);
            updateState({
                elements: { ...state.elements, [state.selectedPage]: pageObjects },
            });
        },
        [state, updateState]
    );

    const value = useMemo<ContextType>(() => {
        return { updateState, state, addNewElement };
    }, [updateState, state, addNewElement]);

    return <PdfStoreContext.Provider value={value}>{children}</PdfStoreContext.Provider>;
};
