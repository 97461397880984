import sx from "clsx";
import React, { forwardRef, ReactNode, useRef } from "react";
import { IconName, NewIcon } from "../Icons";
import { LoadingDots } from "../Loading/LoadingDots";

export const colors = [
    "skyBlue",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "gray",
    "white",
] as const;
export const ButtonVariants = ["contained", "outlined", "text"] as const;
export const ButtonSizes = ["sm", "md", "lg"] as const;
export const ButtonShapes = ["square", "circle"] as const;

const sizeMap = {
    sm: "text-sm px-4 py-2", //36
    md: "text-base px-5 py-2.5", //44
    lg: "text-lg px-6 py-3", //52
};

const shapeSize = {
    sm: "text-sm p-1", //36
    md: "text-base p-2", //44
    lg: "text-lg p-3", //52
};

const btnHeight = {
    sm: "h-9", //36
    md: "h-11", //44
    lg: "h-13", //52
};

const heightMap = {
    sm: "h-9",
    md: "h-11",
    lg: "h-13",
};

type color = (typeof colors)[number];
type shape = (typeof ButtonShapes)[number];
type variant = (typeof ButtonVariants)[number];
type size = (typeof ButtonSizes)[number];

function getSize(size: size, variant: variant, shape?: shape) {
    if (shape) return shapeSize[size];
    if (variant === "outlined") {
        const outlined = {
            sm: "text-sm px-4 py-1.75", //36
            md: "text-base px-5 py-2.25", //44
            lg: "text-lg px-6 py-2.75", //52
        };
        return outlined[size];
    } else {
        return sizeMap[size];
    }
}

interface ButtonProps {
    children: ReactNode;
    variant?: variant;
    color?: color;
    loading?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    size?: (typeof ButtonSizes)[number];
    startIcon?: IconName;
    endIcon?: IconName;
    shape?: shape;
    type?: "button" | "submit";
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    title?: string;
    id?: string;
}

export const Button = forwardRef<HTMLButtonElement | null, ButtonProps>((props, ref) => {
    const {
        size = "md",
        color = "primary",
        variant = "contained",
        children,
        disabled,
        loading,
        fullWidth,
        startIcon,
        endIcon,
        shape,
        type,
        title,
        ...restProps
    } = props;

    const btnRef = useRef<HTMLButtonElement | null>(null);

    return (
        <button
            data-name="Button"
            ref={(instance) => {
                btnRef.current = instance;
                if (typeof ref === "function") ref(instance);
                else if (ref !== null) ref.current = instance;
            }}
            title={title}
            disabled={disabled}
            className={sx(
                `${outline[color]} ${btnHeight[size]} outline-offset-2`,
                `items-center transition-all justify-center flex gap-2 font-medium  disabled:opacity-50 relative active:scale-95`,
                getShape(shape),
                getVariantStyle(variant, color),
                { "w-full": fullWidth }
            )}
            type={type}
            onMouseDown={(e) => e.preventDefault()} // disable outline on click
            {...restProps}
        >
            <span
                className={`capitalize ${getSize(size, variant, shape)} ${getShape(
                    shape
                )} h-full w-full rounded-[inherit] gap-1 flex items-center justify-center  hover:bg-[rgba(0,0,0,0.05)] text-center`}
            >
                {loading ? (
                    <span className={`${heightMap[size]}  flex items-center justify-center`}>
                        <LoadingDots />
                    </span>
                ) : (
                    <>
                        {!!startIcon && <NewIcon className="pr-1" name={startIcon} />}
                        {children}
                        {!!endIcon && <NewIcon className="pl-1" name={endIcon} />}
                    </>
                )}
            </span>
        </button>
    );
});

type IconButtonProp = Omit<ButtonProps, "endIcon" | "startIcon" | "children"> & { name: IconName };

export const IconButton = forwardRef<HTMLButtonElement | null, IconButtonProp>((props, ref) => {
    const { name, shape = "circle", ...restProps } = props;

    const btnRef = useRef<HTMLButtonElement | null>(null);

    return (
        <Button
            ref={(instance) => {
                btnRef.current = instance;
                if (typeof ref === "function") ref(instance);
                else if (ref !== null) ref.current = instance;
            }}
            {...restProps}
            shape={shape}
        >
            <NewIcon className="" name={name} />
        </Button>
    );
});

function getShape(shape?: shape) {
    switch (shape) {
        case "circle":
            return "aspect-square rounded-full";
        case "square":
            return "aspect-square rounded-lg";
        default:
            return "rounded-lg";
    }
}

function getVariantStyle(variant: variant, color: color): string {
    switch (variant) {
        case "contained":
            return `${bg[color]}  shadow-md active:shadow-none`;
        case "outlined":
            return `border ${border[color]} border shadow-md active:shadow-none`;
        case "text":
            return `${textColor[color]}`;
        default:
            return "";
    }
}

const textColor: { [key in color]: string } = {
    skyBlue: "text-skyBlue",
    primary: "text-primary",
    secondary: "text-secondary",
    info: "text-blue-500",
    success: "text-green-500",
    warning: "text-orange-500",
    error: "text-red-500",
    gray: "text-gray-500",
    white: "text-white",
};

const bg: { [key in color]: string } = {
    skyBlue: "bg-skyBlue text-darkIndigo",
    primary: "bg-primary text-white",
    secondary: "bg-secondary text-darkIndigo",
    info: "bg-blue-500 text-white",
    success: "bg-green-500 text-white",
    warning: "bg-orange-500 text-white",
    error: "bg-red-500 text-white",
    gray: "bg-gray-500 text-white",
    white: "bg-white text-darkIndigo",
};

const outline: { [key in color]: string } = {
    skyBlue: "outline-skyBlue",
    primary: " outline-primary",
    secondary: "outline-secondary",
    info: "outline-blue-500",
    success: "outline-green-500",
    warning: "outline-orange-500",
    error: "outline-red-500",
    gray: "outline-gray-500",
    white: "outline-white",
};

const border: { [key in color]: string } = {
    skyBlue: "border-skyBlue ",
    primary: "border-primary",
    secondary: "border-secondary",
    info: "border-blue-500 text-blue-500",
    success: "border-green-500 text-green-500",
    warning: "border-orange-500 text-orange-500",
    error: "border-red-500 text-red-500",
    gray: "border-gray-500 text-gray-500",
    white: "border-white text-darkIndigo",
};
