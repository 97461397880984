import { AxiosResponse } from "axios";
import { PContactInfo } from "proto/PContactInfo";
import { PContactInfoPutRequest, PGetAllContactInfoResponse } from "../proto/PApi";
import { request } from "./ApiCommon";

export function putContact(contact: PContactInfo, successCallback: () => void) {
    const req = {} as PContactInfoPutRequest;
    req.contactInfo = contact;

    request("/api/v1/contact", {
        method: "PUT",
        data: PContactInfoPutRequest.toJSON(req),
    })?.then(() => successCallback());
}

export function removeContact(clientEmail: string, successCallback: () => void) {
    request("/api/v1/contact/" + clientEmail, {
        method: "DELETE",
    })?.then(() => successCallback());
}

export function getAllContacts(successCallback: (reps: PGetAllContactInfoResponse) => void) {
    request("/api/v1/contact", {
        method: "GET",
    })?.then((response) => successCallback(PGetAllContactInfoResponse.fromJSON(response.data)));
}

export const ContactApi = { putContact, removeContact, getAllContacts };
