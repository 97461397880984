/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface ConflictOfInterestEmployee {
  companyFull: string;
}

function createBaseConflictOfInterestEmployee(): ConflictOfInterestEmployee {
  return { companyFull: "" };
}

export const ConflictOfInterestEmployee = {
  encode(message: ConflictOfInterestEmployee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConflictOfInterestEmployee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConflictOfInterestEmployee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConflictOfInterestEmployee {
    return { companyFull: isSet(object.companyFull) ? String(object.companyFull) : "" };
  },

  toJSON(message: ConflictOfInterestEmployee): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConflictOfInterestEmployee>, I>>(base?: I): ConflictOfInterestEmployee {
    return ConflictOfInterestEmployee.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConflictOfInterestEmployee>, I>>(object: I): ConflictOfInterestEmployee {
    const message = createBaseConflictOfInterestEmployee();
    message.companyFull = object.companyFull ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
