/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EIndependentContractorService {
  IndependentContractor_service_NA = 0,
  IndependentContractor_service_UI_UX = 1,
  IndependentContractor_service_DIGITAL_MARKETING = 2,
  IndependentContractor_service_BRAND_STRATEGY = 3,
  IndependentContractor_service_SEO = 4,
  IndependentContractor_service_OTHER = 5,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorServiceFromJSON(object: any): EIndependentContractorService {
  switch (object) {
    case 0:
    case "IndependentContractor_service_NA":
      return EIndependentContractorService.IndependentContractor_service_NA;
    case 1:
    case "IndependentContractor_service_UI_UX":
      return EIndependentContractorService.IndependentContractor_service_UI_UX;
    case 2:
    case "IndependentContractor_service_DIGITAL_MARKETING":
      return EIndependentContractorService.IndependentContractor_service_DIGITAL_MARKETING;
    case 3:
    case "IndependentContractor_service_BRAND_STRATEGY":
      return EIndependentContractorService.IndependentContractor_service_BRAND_STRATEGY;
    case 4:
    case "IndependentContractor_service_SEO":
      return EIndependentContractorService.IndependentContractor_service_SEO;
    case 5:
    case "IndependentContractor_service_OTHER":
      return EIndependentContractorService.IndependentContractor_service_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorService.UNRECOGNIZED;
  }
}

export function eIndependentContractorServiceToJSON(object: EIndependentContractorService): string {
  switch (object) {
    case EIndependentContractorService.IndependentContractor_service_NA:
      return "IndependentContractor_service_NA";
    case EIndependentContractorService.IndependentContractor_service_UI_UX:
      return "IndependentContractor_service_UI_UX";
    case EIndependentContractorService.IndependentContractor_service_DIGITAL_MARKETING:
      return "IndependentContractor_service_DIGITAL_MARKETING";
    case EIndependentContractorService.IndependentContractor_service_BRAND_STRATEGY:
      return "IndependentContractor_service_BRAND_STRATEGY";
    case EIndependentContractorService.IndependentContractor_service_SEO:
      return "IndependentContractor_service_SEO";
    case EIndependentContractorService.IndependentContractor_service_OTHER:
      return "IndependentContractor_service_OTHER";
    case EIndependentContractorService.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EIndependentContractorPayType {
  IndependentContractor_payType_NA = 0,
  IndependentContractor_payType_HOURLY = 1,
  IndependentContractor_payType_DAILY = 4,
  IndependentContractor_payType_WEEKLY = 2,
  IndependentContractor_payType_MONTHLY = 3,
  IndependentContractor_payType_FIXED = 5,
  IndependentContractor_payType_OTHER_PAYMENT = 6,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorPayTypeFromJSON(object: any): EIndependentContractorPayType {
  switch (object) {
    case 0:
    case "IndependentContractor_payType_NA":
      return EIndependentContractorPayType.IndependentContractor_payType_NA;
    case 1:
    case "IndependentContractor_payType_HOURLY":
      return EIndependentContractorPayType.IndependentContractor_payType_HOURLY;
    case 4:
    case "IndependentContractor_payType_DAILY":
      return EIndependentContractorPayType.IndependentContractor_payType_DAILY;
    case 2:
    case "IndependentContractor_payType_WEEKLY":
      return EIndependentContractorPayType.IndependentContractor_payType_WEEKLY;
    case 3:
    case "IndependentContractor_payType_MONTHLY":
      return EIndependentContractorPayType.IndependentContractor_payType_MONTHLY;
    case 5:
    case "IndependentContractor_payType_FIXED":
      return EIndependentContractorPayType.IndependentContractor_payType_FIXED;
    case 6:
    case "IndependentContractor_payType_OTHER_PAYMENT":
      return EIndependentContractorPayType.IndependentContractor_payType_OTHER_PAYMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorPayType.UNRECOGNIZED;
  }
}

export function eIndependentContractorPayTypeToJSON(object: EIndependentContractorPayType): string {
  switch (object) {
    case EIndependentContractorPayType.IndependentContractor_payType_NA:
      return "IndependentContractor_payType_NA";
    case EIndependentContractorPayType.IndependentContractor_payType_HOURLY:
      return "IndependentContractor_payType_HOURLY";
    case EIndependentContractorPayType.IndependentContractor_payType_DAILY:
      return "IndependentContractor_payType_DAILY";
    case EIndependentContractorPayType.IndependentContractor_payType_WEEKLY:
      return "IndependentContractor_payType_WEEKLY";
    case EIndependentContractorPayType.IndependentContractor_payType_MONTHLY:
      return "IndependentContractor_payType_MONTHLY";
    case EIndependentContractorPayType.IndependentContractor_payType_FIXED:
      return "IndependentContractor_payType_FIXED";
    case EIndependentContractorPayType.IndependentContractor_payType_OTHER_PAYMENT:
      return "IndependentContractor_payType_OTHER_PAYMENT";
    case EIndependentContractorPayType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EIndependentContractorIsReimburse {
  IndependentContractor_isReimburse_NA = 0,
  IndependentContractor_isReimburse_YES = 1,
  IndependentContractor_isReimburse_NO = 2,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorIsReimburseFromJSON(object: any): EIndependentContractorIsReimburse {
  switch (object) {
    case 0:
    case "IndependentContractor_isReimburse_NA":
      return EIndependentContractorIsReimburse.IndependentContractor_isReimburse_NA;
    case 1:
    case "IndependentContractor_isReimburse_YES":
      return EIndependentContractorIsReimburse.IndependentContractor_isReimburse_YES;
    case 2:
    case "IndependentContractor_isReimburse_NO":
      return EIndependentContractorIsReimburse.IndependentContractor_isReimburse_NO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorIsReimburse.UNRECOGNIZED;
  }
}

export function eIndependentContractorIsReimburseToJSON(object: EIndependentContractorIsReimburse): string {
  switch (object) {
    case EIndependentContractorIsReimburse.IndependentContractor_isReimburse_NA:
      return "IndependentContractor_isReimburse_NA";
    case EIndependentContractorIsReimburse.IndependentContractor_isReimburse_YES:
      return "IndependentContractor_isReimburse_YES";
    case EIndependentContractorIsReimburse.IndependentContractor_isReimburse_NO:
      return "IndependentContractor_isReimburse_NO";
    case EIndependentContractorIsReimburse.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EIndependentContractorTermType {
  IndependentContractor_termType_NA = 0,
  IndependentContractor_termType_SPECIFIC_DATE = 1,
  IndependentContractor_termType_PROJECT_COMPLETION = 2,
  IndependentContractor_termType_OPEN_ENDED = 3,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorTermTypeFromJSON(object: any): EIndependentContractorTermType {
  switch (object) {
    case 0:
    case "IndependentContractor_termType_NA":
      return EIndependentContractorTermType.IndependentContractor_termType_NA;
    case 1:
    case "IndependentContractor_termType_SPECIFIC_DATE":
      return EIndependentContractorTermType.IndependentContractor_termType_SPECIFIC_DATE;
    case 2:
    case "IndependentContractor_termType_PROJECT_COMPLETION":
      return EIndependentContractorTermType.IndependentContractor_termType_PROJECT_COMPLETION;
    case 3:
    case "IndependentContractor_termType_OPEN_ENDED":
      return EIndependentContractorTermType.IndependentContractor_termType_OPEN_ENDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorTermType.UNRECOGNIZED;
  }
}

export function eIndependentContractorTermTypeToJSON(object: EIndependentContractorTermType): string {
  switch (object) {
    case EIndependentContractorTermType.IndependentContractor_termType_NA:
      return "IndependentContractor_termType_NA";
    case EIndependentContractorTermType.IndependentContractor_termType_SPECIFIC_DATE:
      return "IndependentContractor_termType_SPECIFIC_DATE";
    case EIndependentContractorTermType.IndependentContractor_termType_PROJECT_COMPLETION:
      return "IndependentContractor_termType_PROJECT_COMPLETION";
    case EIndependentContractorTermType.IndependentContractor_termType_OPEN_ENDED:
      return "IndependentContractor_termType_OPEN_ENDED";
    case EIndependentContractorTermType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EIndependentContractorRole {
  IndependentContractor_role_NA = 0,
  IndependentContractor_role_PROVIDER = 1,
  IndependentContractor_role_RECIPIENT = 2,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorRoleFromJSON(object: any): EIndependentContractorRole {
  switch (object) {
    case 0:
    case "IndependentContractor_role_NA":
      return EIndependentContractorRole.IndependentContractor_role_NA;
    case 1:
    case "IndependentContractor_role_PROVIDER":
      return EIndependentContractorRole.IndependentContractor_role_PROVIDER;
    case 2:
    case "IndependentContractor_role_RECIPIENT":
      return EIndependentContractorRole.IndependentContractor_role_RECIPIENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorRole.UNRECOGNIZED;
  }
}

export function eIndependentContractorRoleToJSON(object: EIndependentContractorRole): string {
  switch (object) {
    case EIndependentContractorRole.IndependentContractor_role_NA:
      return "IndependentContractor_role_NA";
    case EIndependentContractorRole.IndependentContractor_role_PROVIDER:
      return "IndependentContractor_role_PROVIDER";
    case EIndependentContractorRole.IndependentContractor_role_RECIPIENT:
      return "IndependentContractor_role_RECIPIENT";
    case EIndependentContractorRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EIndependentContractorUpfrontOption {
  IndependentContractor_upfrontOption_NA = 0,
  IndependentContractor_upfrontOption_FULL_ADVANCE = 1,
  IndependentContractor_upfrontOption_FULL_END = 2,
  IndependentContractor_upfrontOption_CUSTOM = 3,
  UNRECOGNIZED = -1,
}

export function eIndependentContractorUpfrontOptionFromJSON(object: any): EIndependentContractorUpfrontOption {
  switch (object) {
    case 0:
    case "IndependentContractor_upfrontOption_NA":
      return EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_NA;
    case 1:
    case "IndependentContractor_upfrontOption_FULL_ADVANCE":
      return EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_FULL_ADVANCE;
    case 2:
    case "IndependentContractor_upfrontOption_FULL_END":
      return EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_FULL_END;
    case 3:
    case "IndependentContractor_upfrontOption_CUSTOM":
      return EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EIndependentContractorUpfrontOption.UNRECOGNIZED;
  }
}

export function eIndependentContractorUpfrontOptionToJSON(object: EIndependentContractorUpfrontOption): string {
  switch (object) {
    case EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_NA:
      return "IndependentContractor_upfrontOption_NA";
    case EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_FULL_ADVANCE:
      return "IndependentContractor_upfrontOption_FULL_ADVANCE";
    case EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_FULL_END:
      return "IndependentContractor_upfrontOption_FULL_END";
    case EIndependentContractorUpfrontOption.IndependentContractor_upfrontOption_CUSTOM:
      return "IndependentContractor_upfrontOption_CUSTOM";
    case EIndependentContractorUpfrontOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MIndependentContractorService {
  optionDbValue: EIndependentContractorService;
  other: string;
}

export interface MIndependentContractorPayType {
  optionDbValue: EIndependentContractorPayType;
  other: string;
}

export interface MIndependentContractorIsReimburse {
  optionDbValue: EIndependentContractorIsReimburse;
  other: string;
}

export interface MIndependentContractorTermType {
  optionDbValue: EIndependentContractorTermType;
  other: string;
}

export interface MIndependentContractorRole {
  optionDbValue: EIndependentContractorRole;
  other: string;
}

export interface MIndependentContractorUpfrontOption {
  optionDbValue: EIndependentContractorUpfrontOption;
  other: string;
}

export interface IndependentContractor {
  effectiveDate: Date | undefined;
  service: MIndependentContractorService | undefined;
  serviceDetails: string[];
  rate: number;
  payType: MIndependentContractorPayType | undefined;
  postInvoiceDays: number;
  fee: number;
  upfrontAmount: number;
  isReimburse: MIndependentContractorIsReimburse | undefined;
  termType: MIndependentContractorTermType | undefined;
  endDate: Date | undefined;
  noticePeriod: number;
  role: MIndependentContractorRole | undefined;
  otherName: string;
  otherAddress: string;
  canTerminateAtAnyTime: InternalBoolean;
  feeFreeDesc: string;
  otherService: string;
  upfrontOption: MIndependentContractorUpfrontOption | undefined;
  addressNeedToBeSet: InternalBoolean;
  governingLaw: string;
}

function createBaseMIndependentContractorService(): MIndependentContractorService {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorService = {
  encode(message: MIndependentContractorService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorService {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eIndependentContractorServiceFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorService): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorServiceToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorService>, I>>(base?: I): MIndependentContractorService {
    return MIndependentContractorService.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorService>, I>>(
    object: I,
  ): MIndependentContractorService {
    const message = createBaseMIndependentContractorService();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMIndependentContractorPayType(): MIndependentContractorPayType {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorPayType = {
  encode(message: MIndependentContractorPayType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorPayType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorPayType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorPayType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eIndependentContractorPayTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorPayType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorPayTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorPayType>, I>>(base?: I): MIndependentContractorPayType {
    return MIndependentContractorPayType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorPayType>, I>>(
    object: I,
  ): MIndependentContractorPayType {
    const message = createBaseMIndependentContractorPayType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMIndependentContractorIsReimburse(): MIndependentContractorIsReimburse {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorIsReimburse = {
  encode(message: MIndependentContractorIsReimburse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorIsReimburse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorIsReimburse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorIsReimburse {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eIndependentContractorIsReimburseFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorIsReimburse): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorIsReimburseToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorIsReimburse>, I>>(
    base?: I,
  ): MIndependentContractorIsReimburse {
    return MIndependentContractorIsReimburse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorIsReimburse>, I>>(
    object: I,
  ): MIndependentContractorIsReimburse {
    const message = createBaseMIndependentContractorIsReimburse();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMIndependentContractorTermType(): MIndependentContractorTermType {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorTermType = {
  encode(message: MIndependentContractorTermType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorTermType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorTermType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorTermType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eIndependentContractorTermTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorTermType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorTermTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorTermType>, I>>(base?: I): MIndependentContractorTermType {
    return MIndependentContractorTermType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorTermType>, I>>(
    object: I,
  ): MIndependentContractorTermType {
    const message = createBaseMIndependentContractorTermType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMIndependentContractorRole(): MIndependentContractorRole {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorRole = {
  encode(message: MIndependentContractorRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorRole {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eIndependentContractorRoleFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorRole): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorRoleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorRole>, I>>(base?: I): MIndependentContractorRole {
    return MIndependentContractorRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorRole>, I>>(object: I): MIndependentContractorRole {
    const message = createBaseMIndependentContractorRole();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMIndependentContractorUpfrontOption(): MIndependentContractorUpfrontOption {
  return { optionDbValue: 0, other: "" };
}

export const MIndependentContractorUpfrontOption = {
  encode(message: MIndependentContractorUpfrontOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MIndependentContractorUpfrontOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMIndependentContractorUpfrontOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MIndependentContractorUpfrontOption {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eIndependentContractorUpfrontOptionFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MIndependentContractorUpfrontOption): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eIndependentContractorUpfrontOptionToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MIndependentContractorUpfrontOption>, I>>(
    base?: I,
  ): MIndependentContractorUpfrontOption {
    return MIndependentContractorUpfrontOption.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MIndependentContractorUpfrontOption>, I>>(
    object: I,
  ): MIndependentContractorUpfrontOption {
    const message = createBaseMIndependentContractorUpfrontOption();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseIndependentContractor(): IndependentContractor {
  return {
    effectiveDate: undefined,
    service: undefined,
    serviceDetails: [],
    rate: 0,
    payType: undefined,
    postInvoiceDays: 0,
    fee: 0,
    upfrontAmount: 0,
    isReimburse: undefined,
    termType: undefined,
    endDate: undefined,
    noticePeriod: 0,
    role: undefined,
    otherName: "",
    otherAddress: "",
    canTerminateAtAnyTime: 0,
    feeFreeDesc: "",
    otherService: "",
    upfrontOption: undefined,
    addressNeedToBeSet: 0,
    governingLaw: "",
  };
}

export const IndependentContractor = {
  encode(message: IndependentContractor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.service !== undefined) {
      MIndependentContractorService.encode(message.service, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.serviceDetails) {
      writer.uint32(26).string(v!);
    }
    if (message.rate !== 0) {
      writer.uint32(37).float(message.rate);
    }
    if (message.payType !== undefined) {
      MIndependentContractorPayType.encode(message.payType, writer.uint32(42).fork()).ldelim();
    }
    if (message.postInvoiceDays !== 0) {
      writer.uint32(53).float(message.postInvoiceDays);
    }
    if (message.fee !== 0) {
      writer.uint32(61).float(message.fee);
    }
    if (message.upfrontAmount !== 0) {
      writer.uint32(69).float(message.upfrontAmount);
    }
    if (message.isReimburse !== undefined) {
      MIndependentContractorIsReimburse.encode(message.isReimburse, writer.uint32(74).fork()).ldelim();
    }
    if (message.termType !== undefined) {
      MIndependentContractorTermType.encode(message.termType, writer.uint32(82).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(90).fork()).ldelim();
    }
    if (message.noticePeriod !== 0) {
      writer.uint32(101).float(message.noticePeriod);
    }
    if (message.role !== undefined) {
      MIndependentContractorRole.encode(message.role, writer.uint32(106).fork()).ldelim();
    }
    if (message.otherName !== "") {
      writer.uint32(114).string(message.otherName);
    }
    if (message.otherAddress !== "") {
      writer.uint32(122).string(message.otherAddress);
    }
    if (message.canTerminateAtAnyTime !== 0) {
      writer.uint32(128).int32(message.canTerminateAtAnyTime);
    }
    if (message.feeFreeDesc !== "") {
      writer.uint32(138).string(message.feeFreeDesc);
    }
    if (message.otherService !== "") {
      writer.uint32(146).string(message.otherService);
    }
    if (message.upfrontOption !== undefined) {
      MIndependentContractorUpfrontOption.encode(message.upfrontOption, writer.uint32(154).fork()).ldelim();
    }
    if (message.addressNeedToBeSet !== 0) {
      writer.uint32(160).int32(message.addressNeedToBeSet);
    }
    if (message.governingLaw !== "") {
      writer.uint32(170).string(message.governingLaw);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IndependentContractor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIndependentContractor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.service = MIndependentContractorService.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.serviceDetails.push(reader.string());
          continue;
        case 4:
          if (tag != 37) {
            break;
          }

          message.rate = reader.float();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.payType = MIndependentContractorPayType.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.postInvoiceDays = reader.float();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.fee = reader.float();
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.upfrontAmount = reader.float();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.isReimburse = MIndependentContractorIsReimburse.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.termType = MIndependentContractorTermType.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.noticePeriod = reader.float();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.role = MIndependentContractorRole.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.otherName = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.otherAddress = reader.string();
          continue;
        case 16:
          if (tag != 128) {
            break;
          }

          message.canTerminateAtAnyTime = reader.int32() as any;
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.feeFreeDesc = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.otherService = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.upfrontOption = MIndependentContractorUpfrontOption.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.addressNeedToBeSet = reader.int32() as any;
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.governingLaw = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IndependentContractor {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      service: isSet(object.service) ? MIndependentContractorService.fromJSON(object.service) : undefined,
      serviceDetails: Array.isArray(object?.serviceDetails) ? object.serviceDetails.map((e: any) => String(e)) : [],
      rate: isSet(object.rate) ? Number(object.rate) : 0,
      payType: isSet(object.payType) ? MIndependentContractorPayType.fromJSON(object.payType) : undefined,
      postInvoiceDays: isSet(object.postInvoiceDays) ? Number(object.postInvoiceDays) : 0,
      fee: isSet(object.fee) ? Number(object.fee) : 0,
      upfrontAmount: isSet(object.upfrontAmount) ? Number(object.upfrontAmount) : 0,
      isReimburse: isSet(object.isReimburse)
        ? MIndependentContractorIsReimburse.fromJSON(object.isReimburse)
        : undefined,
      termType: isSet(object.termType) ? MIndependentContractorTermType.fromJSON(object.termType) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      noticePeriod: isSet(object.noticePeriod) ? Number(object.noticePeriod) : 0,
      role: isSet(object.role) ? MIndependentContractorRole.fromJSON(object.role) : undefined,
      otherName: isSet(object.otherName) ? String(object.otherName) : "",
      otherAddress: isSet(object.otherAddress) ? String(object.otherAddress) : "",
      canTerminateAtAnyTime: isSet(object.canTerminateAtAnyTime)
        ? internalBooleanFromJSON(object.canTerminateAtAnyTime)
        : 0,
      feeFreeDesc: isSet(object.feeFreeDesc) ? String(object.feeFreeDesc) : "",
      otherService: isSet(object.otherService) ? String(object.otherService) : "",
      upfrontOption: isSet(object.upfrontOption)
        ? MIndependentContractorUpfrontOption.fromJSON(object.upfrontOption)
        : undefined,
      addressNeedToBeSet: isSet(object.addressNeedToBeSet) ? internalBooleanFromJSON(object.addressNeedToBeSet) : 0,
      governingLaw: isSet(object.governingLaw) ? String(object.governingLaw) : "",
    };
  },

  toJSON(message: IndependentContractor): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.service !== undefined &&
      (obj.service = message.service ? MIndependentContractorService.toJSON(message.service) : undefined);
    if (message.serviceDetails) {
      obj.serviceDetails = message.serviceDetails.map((e) => e);
    } else {
      obj.serviceDetails = [];
    }
    message.rate !== undefined && (obj.rate = message.rate);
    message.payType !== undefined &&
      (obj.payType = message.payType ? MIndependentContractorPayType.toJSON(message.payType) : undefined);
    message.postInvoiceDays !== undefined && (obj.postInvoiceDays = message.postInvoiceDays);
    message.fee !== undefined && (obj.fee = message.fee);
    message.upfrontAmount !== undefined && (obj.upfrontAmount = message.upfrontAmount);
    message.isReimburse !== undefined &&
      (obj.isReimburse = message.isReimburse
        ? MIndependentContractorIsReimburse.toJSON(message.isReimburse)
        : undefined);
    message.termType !== undefined &&
      (obj.termType = message.termType ? MIndependentContractorTermType.toJSON(message.termType) : undefined);
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    message.noticePeriod !== undefined && (obj.noticePeriod = message.noticePeriod);
    message.role !== undefined &&
      (obj.role = message.role ? MIndependentContractorRole.toJSON(message.role) : undefined);
    message.otherName !== undefined && (obj.otherName = message.otherName);
    message.otherAddress !== undefined && (obj.otherAddress = message.otherAddress);
    message.canTerminateAtAnyTime !== undefined &&
      (obj.canTerminateAtAnyTime = internalBooleanToJSON(message.canTerminateAtAnyTime));
    message.feeFreeDesc !== undefined && (obj.feeFreeDesc = message.feeFreeDesc);
    message.otherService !== undefined && (obj.otherService = message.otherService);
    message.upfrontOption !== undefined && (obj.upfrontOption = message.upfrontOption
      ? MIndependentContractorUpfrontOption.toJSON(message.upfrontOption)
      : undefined);
    message.addressNeedToBeSet !== undefined &&
      (obj.addressNeedToBeSet = internalBooleanToJSON(message.addressNeedToBeSet));
    message.governingLaw !== undefined && (obj.governingLaw = message.governingLaw);
    return obj;
  },

  create<I extends Exact<DeepPartial<IndependentContractor>, I>>(base?: I): IndependentContractor {
    return IndependentContractor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IndependentContractor>, I>>(object: I): IndependentContractor {
    const message = createBaseIndependentContractor();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.service = (object.service !== undefined && object.service !== null)
      ? MIndependentContractorService.fromPartial(object.service)
      : undefined;
    message.serviceDetails = object.serviceDetails?.map((e) => e) || [];
    message.rate = object.rate ?? 0;
    message.payType = (object.payType !== undefined && object.payType !== null)
      ? MIndependentContractorPayType.fromPartial(object.payType)
      : undefined;
    message.postInvoiceDays = object.postInvoiceDays ?? 0;
    message.fee = object.fee ?? 0;
    message.upfrontAmount = object.upfrontAmount ?? 0;
    message.isReimburse = (object.isReimburse !== undefined && object.isReimburse !== null)
      ? MIndependentContractorIsReimburse.fromPartial(object.isReimburse)
      : undefined;
    message.termType = (object.termType !== undefined && object.termType !== null)
      ? MIndependentContractorTermType.fromPartial(object.termType)
      : undefined;
    message.endDate = object.endDate ?? undefined;
    message.noticePeriod = object.noticePeriod ?? 0;
    message.role = (object.role !== undefined && object.role !== null)
      ? MIndependentContractorRole.fromPartial(object.role)
      : undefined;
    message.otherName = object.otherName ?? "";
    message.otherAddress = object.otherAddress ?? "";
    message.canTerminateAtAnyTime = object.canTerminateAtAnyTime ?? 0;
    message.feeFreeDesc = object.feeFreeDesc ?? "";
    message.otherService = object.otherService ?? "";
    message.upfrontOption = (object.upfrontOption !== undefined && object.upfrontOption !== null)
      ? MIndependentContractorUpfrontOption.fromPartial(object.upfrontOption)
      : undefined;
    message.addressNeedToBeSet = object.addressNeedToBeSet ?? 0;
    message.governingLaw = object.governingLaw ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
