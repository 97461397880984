/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { PContactInfo } from "./PContactInfo";
import { PDoc } from "./PDoc";
import { PFrontendError } from "./PFrontendError";
import { PTemplate } from "./PTemplate";
import { PTimeRecord } from "./PTimeRecord";
import { PLegalTopicsSurvey, PNumericStats } from "./PTracking";
import { PUser } from "./PUser";

export const protobufPackage = "";

export enum PDocShareType {
  AGREEMENT = 0,
  DOCUMENT = 1,
  ONLY_OWNER_SIGNS = 2,
  ONLY_OTHER_SIGNS = 3,
  UNRECOGNIZED = -1,
}

export function pDocShareTypeFromJSON(object: any): PDocShareType {
  switch (object) {
    case 0:
    case "AGREEMENT":
      return PDocShareType.AGREEMENT;
    case 1:
    case "DOCUMENT":
      return PDocShareType.DOCUMENT;
    case 2:
    case "ONLY_OWNER_SIGNS":
      return PDocShareType.ONLY_OWNER_SIGNS;
    case 3:
    case "ONLY_OTHER_SIGNS":
      return PDocShareType.ONLY_OTHER_SIGNS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PDocShareType.UNRECOGNIZED;
  }
}

export function pDocShareTypeToJSON(object: PDocShareType): string {
  switch (object) {
    case PDocShareType.AGREEMENT:
      return "AGREEMENT";
    case PDocShareType.DOCUMENT:
      return "DOCUMENT";
    case PDocShareType.ONLY_OWNER_SIGNS:
      return "ONLY_OWNER_SIGNS";
    case PDocShareType.ONLY_OTHER_SIGNS:
      return "ONLY_OTHER_SIGNS";
    case PDocShareType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * /////////////////////////
 * //////// DOC ////////////
 * /////////////////////////
 */
export interface PDocGetRequest {
  accountName: string;
}

export interface PDocGetResponse {
  accountName: string;
  docs: PDoc[];
}

export interface PTemplateUpdateRequest {
  templateId: string;
}

export interface PTemplateSaveRequest {
  url: string;
}

export interface PTemplatesGetResponse {
  templates: PTemplate[];
}

export interface PTemplateGetResponse {
  template: PTemplate | undefined;
}

export interface PDocPutRequest {
  docId: string;
}

export interface PShareRequest {
  username: string;
}

export interface PSignRequest {
  sigBlob: string[];
  docShareType: PDocShareType;
}

/**
 * /////////////////////////
 * //////// USER //////////
 * /////////////////////////
 */
export interface PGetUserResponse {
  user: PUser | undefined;
}

export interface PPostUserRequest {
  user: PUser | undefined;
}

export interface PValidateAccountRequest {
  accountName: string;
}

export interface PValidateAccountResponse {
  isOk: boolean;
}

/** TRACK */
export interface PGetAllTracks {
  tracks: PLegalTopicsSurvey[];
  numericStats: PNumericStats | undefined;
  usersBoughtList: string[];
}

/** Google Ads */
export interface PPostGoogleAdsMetrics {
  dateString: string;
  clicks: number;
  costMicros: number;
  dollarToNis: number;
  campaign: string;
  deviceType: string;
}

export interface PCreateBrunch {
  src: string;
  dest: string;
}

export interface PCreateFIle {
  sha: string;
  content: string;
  name: string;
}

export interface PPostFrontendError {
  feError: PFrontendError | undefined;
}

export interface PScrapeWebsiteRequest {
  url: string;
}

export interface PScrapeWebsiteResponse {
  scripts: string[];
}

/** contact-info */
export interface PContactInfoPutRequest {
  contactInfo: PContactInfo | undefined;
}

export interface PGetAllContactInfoResponse {
  contactInfos: PContactInfo[];
}

/** time-record */
export interface PTimeRecordPutRequest {
  timeRecord: PTimeRecord | undefined;
}

export interface PTimeRecordPutResponse {
  uuid: string;
}

export interface PGetAllTimeRecordResponse {
  timeRecords: PTimeRecord[];
}

export interface PPageViewRequest {
  pageUrl: string;
}

/** contract review */
export interface PUploadFileRequest {
  fileType: string;
  fileName: string;
  fileData: Uint8Array;
}

export interface PContractReviewRequest {
  docId: string;
}

export interface PContractReviewResponse {
  isSuccessful: boolean;
  msg: string;
}

/** ask */
export interface PAskRequest {
  json: string;
  q: string;
  contract: string[];
}

export interface PAskResponse {
  a: string;
}

export interface PAskResponseNew {
  responses: string[];
}

/** tip */
export interface PTipRequest {
  companyDetails: string;
}

export interface PTipResponse {
  theTip: string;
}

export interface PWixInstallAppRequest {
  instanceId: string;
  code: string;
}

export interface PWixInstallAppResponse {
}

export interface PWixCheckoutUrlResponse {
  checkoutUrl: string;
}

export interface PWixCheckoutUrlRequest {
  cycle: string;
}

export interface PShopifyCheckoutUrlResponse {
  checkoutUrl: string;
}

export interface PShopifyCheckoutUrlRequest {
  cycle: string;
}

export interface PBehaviourRequest {
  op: string;
  details: string;
  extraKeyValuePairs: BehaviourKeyValuePair[];
}

export interface BehaviourKeyValuePair {
  key: string;
  value: string;
}

function createBasePDocGetRequest(): PDocGetRequest {
  return { accountName: "" };
}

export const PDocGetRequest = {
  encode(message: PDocGetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountName !== "") {
      writer.uint32(10).string(message.accountName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PDocGetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePDocGetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.accountName = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PDocGetRequest {
    return { accountName: isSet(object.accountName) ? String(object.accountName) : "" };
  },

  toJSON(message: PDocGetRequest): unknown {
    const obj: any = {};
    message.accountName !== undefined && (obj.accountName = message.accountName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PDocGetRequest>, I>>(base?: I): PDocGetRequest {
    return PDocGetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PDocGetRequest>, I>>(object: I): PDocGetRequest {
    const message = createBasePDocGetRequest();
    message.accountName = object.accountName ?? "";
    return message;
  },
};

function createBasePDocGetResponse(): PDocGetResponse {
  return { accountName: "", docs: [] };
}

export const PDocGetResponse = {
  encode(message: PDocGetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountName !== "") {
      writer.uint32(10).string(message.accountName);
    }
    for (const v of message.docs) {
      PDoc.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PDocGetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePDocGetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.docs.push(PDoc.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PDocGetResponse {
    return {
      accountName: isSet(object.accountName) ? String(object.accountName) : "",
      docs: Array.isArray(object?.docs) ? object.docs.map((e: any) => PDoc.fromJSON(e)) : [],
    };
  },

  toJSON(message: PDocGetResponse): unknown {
    const obj: any = {};
    message.accountName !== undefined && (obj.accountName = message.accountName);
    if (message.docs) {
      obj.docs = message.docs.map((e) => e ? PDoc.toJSON(e) : undefined);
    } else {
      obj.docs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PDocGetResponse>, I>>(base?: I): PDocGetResponse {
    return PDocGetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PDocGetResponse>, I>>(object: I): PDocGetResponse {
    const message = createBasePDocGetResponse();
    message.accountName = object.accountName ?? "";
    message.docs = object.docs?.map((e) => PDoc.fromPartial(e)) || [];
    return message;
  },
};

function createBasePTemplateUpdateRequest(): PTemplateUpdateRequest {
  return { templateId: "" };
}

export const PTemplateUpdateRequest = {
  encode(message: PTemplateUpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTemplateUpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTemplateUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTemplateUpdateRequest {
    return { templateId: isSet(object.templateId) ? String(object.templateId) : "" };
  },

  toJSON(message: PTemplateUpdateRequest): unknown {
    const obj: any = {};
    message.templateId !== undefined && (obj.templateId = message.templateId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTemplateUpdateRequest>, I>>(base?: I): PTemplateUpdateRequest {
    return PTemplateUpdateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTemplateUpdateRequest>, I>>(object: I): PTemplateUpdateRequest {
    const message = createBasePTemplateUpdateRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBasePTemplateSaveRequest(): PTemplateSaveRequest {
  return { url: "" };
}

export const PTemplateSaveRequest = {
  encode(message: PTemplateSaveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTemplateSaveRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTemplateSaveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTemplateSaveRequest {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: PTemplateSaveRequest): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTemplateSaveRequest>, I>>(base?: I): PTemplateSaveRequest {
    return PTemplateSaveRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTemplateSaveRequest>, I>>(object: I): PTemplateSaveRequest {
    const message = createBasePTemplateSaveRequest();
    message.url = object.url ?? "";
    return message;
  },
};

function createBasePTemplatesGetResponse(): PTemplatesGetResponse {
  return { templates: [] };
}

export const PTemplatesGetResponse = {
  encode(message: PTemplatesGetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      PTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTemplatesGetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTemplatesGetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.templates.push(PTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTemplatesGetResponse {
    return {
      templates: Array.isArray(object?.templates) ? object.templates.map((e: any) => PTemplate.fromJSON(e)) : [],
    };
  },

  toJSON(message: PTemplatesGetResponse): unknown {
    const obj: any = {};
    if (message.templates) {
      obj.templates = message.templates.map((e) => e ? PTemplate.toJSON(e) : undefined);
    } else {
      obj.templates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PTemplatesGetResponse>, I>>(base?: I): PTemplatesGetResponse {
    return PTemplatesGetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTemplatesGetResponse>, I>>(object: I): PTemplatesGetResponse {
    const message = createBasePTemplatesGetResponse();
    message.templates = object.templates?.map((e) => PTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBasePTemplateGetResponse(): PTemplateGetResponse {
  return { template: undefined };
}

export const PTemplateGetResponse = {
  encode(message: PTemplateGetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.template !== undefined) {
      PTemplate.encode(message.template, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTemplateGetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTemplateGetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.template = PTemplate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTemplateGetResponse {
    return { template: isSet(object.template) ? PTemplate.fromJSON(object.template) : undefined };
  },

  toJSON(message: PTemplateGetResponse): unknown {
    const obj: any = {};
    message.template !== undefined &&
      (obj.template = message.template ? PTemplate.toJSON(message.template) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTemplateGetResponse>, I>>(base?: I): PTemplateGetResponse {
    return PTemplateGetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTemplateGetResponse>, I>>(object: I): PTemplateGetResponse {
    const message = createBasePTemplateGetResponse();
    message.template = (object.template !== undefined && object.template !== null)
      ? PTemplate.fromPartial(object.template)
      : undefined;
    return message;
  },
};

function createBasePDocPutRequest(): PDocPutRequest {
  return { docId: "" };
}

export const PDocPutRequest = {
  encode(message: PDocPutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.docId !== "") {
      writer.uint32(10).string(message.docId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PDocPutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePDocPutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.docId = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PDocPutRequest {
    return { docId: isSet(object.docId) ? String(object.docId) : "" };
  },

  toJSON(message: PDocPutRequest): unknown {
    const obj: any = {};
    message.docId !== undefined && (obj.docId = message.docId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PDocPutRequest>, I>>(base?: I): PDocPutRequest {
    return PDocPutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PDocPutRequest>, I>>(object: I): PDocPutRequest {
    const message = createBasePDocPutRequest();
    message.docId = object.docId ?? "";
    return message;
  },
};

function createBasePShareRequest(): PShareRequest {
  return { username: "" };
}

export const PShareRequest = {
  encode(message: PShareRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PShareRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePShareRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PShareRequest {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: PShareRequest): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<PShareRequest>, I>>(base?: I): PShareRequest {
    return PShareRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PShareRequest>, I>>(object: I): PShareRequest {
    const message = createBasePShareRequest();
    message.username = object.username ?? "";
    return message;
  },
};

function createBasePSignRequest(): PSignRequest {
  return { sigBlob: [], docShareType: 0 };
}

export const PSignRequest = {
  encode(message: PSignRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sigBlob) {
      writer.uint32(10).string(v!);
    }
    if (message.docShareType !== 0) {
      writer.uint32(16).int32(message.docShareType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PSignRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePSignRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.sigBlob.push(reader.string());
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.docShareType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PSignRequest {
    return {
      sigBlob: Array.isArray(object?.sigBlob) ? object.sigBlob.map((e: any) => String(e)) : [],
      docShareType: isSet(object.docShareType) ? pDocShareTypeFromJSON(object.docShareType) : 0,
    };
  },

  toJSON(message: PSignRequest): unknown {
    const obj: any = {};
    if (message.sigBlob) {
      obj.sigBlob = message.sigBlob.map((e) => e);
    } else {
      obj.sigBlob = [];
    }
    message.docShareType !== undefined && (obj.docShareType = pDocShareTypeToJSON(message.docShareType));
    return obj;
  },

  create<I extends Exact<DeepPartial<PSignRequest>, I>>(base?: I): PSignRequest {
    return PSignRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PSignRequest>, I>>(object: I): PSignRequest {
    const message = createBasePSignRequest();
    message.sigBlob = object.sigBlob?.map((e) => e) || [];
    message.docShareType = object.docShareType ?? 0;
    return message;
  },
};

function createBasePGetUserResponse(): PGetUserResponse {
  return { user: undefined };
}

export const PGetUserResponse = {
  encode(message: PGetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      PUser.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PGetUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePGetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.user = PUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PGetUserResponse {
    return { user: isSet(object.user) ? PUser.fromJSON(object.user) : undefined };
  },

  toJSON(message: PGetUserResponse): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? PUser.toJSON(message.user) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PGetUserResponse>, I>>(base?: I): PGetUserResponse {
    return PGetUserResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PGetUserResponse>, I>>(object: I): PGetUserResponse {
    const message = createBasePGetUserResponse();
    message.user = (object.user !== undefined && object.user !== null) ? PUser.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBasePPostUserRequest(): PPostUserRequest {
  return { user: undefined };
}

export const PPostUserRequest = {
  encode(message: PPostUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      PUser.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPostUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPostUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.user = PUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPostUserRequest {
    return { user: isSet(object.user) ? PUser.fromJSON(object.user) : undefined };
  },

  toJSON(message: PPostUserRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? PUser.toJSON(message.user) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPostUserRequest>, I>>(base?: I): PPostUserRequest {
    return PPostUserRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPostUserRequest>, I>>(object: I): PPostUserRequest {
    const message = createBasePPostUserRequest();
    message.user = (object.user !== undefined && object.user !== null) ? PUser.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBasePValidateAccountRequest(): PValidateAccountRequest {
  return { accountName: "" };
}

export const PValidateAccountRequest = {
  encode(message: PValidateAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountName !== "") {
      writer.uint32(10).string(message.accountName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PValidateAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePValidateAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.accountName = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PValidateAccountRequest {
    return { accountName: isSet(object.accountName) ? String(object.accountName) : "" };
  },

  toJSON(message: PValidateAccountRequest): unknown {
    const obj: any = {};
    message.accountName !== undefined && (obj.accountName = message.accountName);
    return obj;
  },

  create<I extends Exact<DeepPartial<PValidateAccountRequest>, I>>(base?: I): PValidateAccountRequest {
    return PValidateAccountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PValidateAccountRequest>, I>>(object: I): PValidateAccountRequest {
    const message = createBasePValidateAccountRequest();
    message.accountName = object.accountName ?? "";
    return message;
  },
};

function createBasePValidateAccountResponse(): PValidateAccountResponse {
  return { isOk: false };
}

export const PValidateAccountResponse = {
  encode(message: PValidateAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isOk === true) {
      writer.uint32(8).bool(message.isOk);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PValidateAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePValidateAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.isOk = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PValidateAccountResponse {
    return { isOk: isSet(object.isOk) ? Boolean(object.isOk) : false };
  },

  toJSON(message: PValidateAccountResponse): unknown {
    const obj: any = {};
    message.isOk !== undefined && (obj.isOk = message.isOk);
    return obj;
  },

  create<I extends Exact<DeepPartial<PValidateAccountResponse>, I>>(base?: I): PValidateAccountResponse {
    return PValidateAccountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PValidateAccountResponse>, I>>(object: I): PValidateAccountResponse {
    const message = createBasePValidateAccountResponse();
    message.isOk = object.isOk ?? false;
    return message;
  },
};

function createBasePGetAllTracks(): PGetAllTracks {
  return { tracks: [], numericStats: undefined, usersBoughtList: [] };
}

export const PGetAllTracks = {
  encode(message: PGetAllTracks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tracks) {
      PLegalTopicsSurvey.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.numericStats !== undefined) {
      PNumericStats.encode(message.numericStats, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.usersBoughtList) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PGetAllTracks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePGetAllTracks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.tracks.push(PLegalTopicsSurvey.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.numericStats = PNumericStats.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.usersBoughtList.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PGetAllTracks {
    return {
      tracks: Array.isArray(object?.tracks) ? object.tracks.map((e: any) => PLegalTopicsSurvey.fromJSON(e)) : [],
      numericStats: isSet(object.numericStats) ? PNumericStats.fromJSON(object.numericStats) : undefined,
      usersBoughtList: Array.isArray(object?.usersBoughtList) ? object.usersBoughtList.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: PGetAllTracks): unknown {
    const obj: any = {};
    if (message.tracks) {
      obj.tracks = message.tracks.map((e) => e ? PLegalTopicsSurvey.toJSON(e) : undefined);
    } else {
      obj.tracks = [];
    }
    message.numericStats !== undefined &&
      (obj.numericStats = message.numericStats ? PNumericStats.toJSON(message.numericStats) : undefined);
    if (message.usersBoughtList) {
      obj.usersBoughtList = message.usersBoughtList.map((e) => e);
    } else {
      obj.usersBoughtList = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PGetAllTracks>, I>>(base?: I): PGetAllTracks {
    return PGetAllTracks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PGetAllTracks>, I>>(object: I): PGetAllTracks {
    const message = createBasePGetAllTracks();
    message.tracks = object.tracks?.map((e) => PLegalTopicsSurvey.fromPartial(e)) || [];
    message.numericStats = (object.numericStats !== undefined && object.numericStats !== null)
      ? PNumericStats.fromPartial(object.numericStats)
      : undefined;
    message.usersBoughtList = object.usersBoughtList?.map((e) => e) || [];
    return message;
  },
};

function createBasePPostGoogleAdsMetrics(): PPostGoogleAdsMetrics {
  return { dateString: "", clicks: 0, costMicros: 0, dollarToNis: 0, campaign: "", deviceType: "" };
}

export const PPostGoogleAdsMetrics = {
  encode(message: PPostGoogleAdsMetrics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateString !== "") {
      writer.uint32(10).string(message.dateString);
    }
    if (message.clicks !== 0) {
      writer.uint32(16).int32(message.clicks);
    }
    if (message.costMicros !== 0) {
      writer.uint32(24).int64(message.costMicros);
    }
    if (message.dollarToNis !== 0) {
      writer.uint32(33).double(message.dollarToNis);
    }
    if (message.campaign !== "") {
      writer.uint32(42).string(message.campaign);
    }
    if (message.deviceType !== "") {
      writer.uint32(50).string(message.deviceType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPostGoogleAdsMetrics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPostGoogleAdsMetrics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.dateString = reader.string();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.clicks = reader.int32();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.costMicros = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag != 33) {
            break;
          }

          message.dollarToNis = reader.double();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.campaign = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.deviceType = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPostGoogleAdsMetrics {
    return {
      dateString: isSet(object.dateString) ? String(object.dateString) : "",
      clicks: isSet(object.clicks) ? Number(object.clicks) : 0,
      costMicros: isSet(object.costMicros) ? Number(object.costMicros) : 0,
      dollarToNis: isSet(object.dollarToNis) ? Number(object.dollarToNis) : 0,
      campaign: isSet(object.campaign) ? String(object.campaign) : "",
      deviceType: isSet(object.deviceType) ? String(object.deviceType) : "",
    };
  },

  toJSON(message: PPostGoogleAdsMetrics): unknown {
    const obj: any = {};
    message.dateString !== undefined && (obj.dateString = message.dateString);
    message.clicks !== undefined && (obj.clicks = Math.round(message.clicks));
    message.costMicros !== undefined && (obj.costMicros = Math.round(message.costMicros));
    message.dollarToNis !== undefined && (obj.dollarToNis = message.dollarToNis);
    message.campaign !== undefined && (obj.campaign = message.campaign);
    message.deviceType !== undefined && (obj.deviceType = message.deviceType);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPostGoogleAdsMetrics>, I>>(base?: I): PPostGoogleAdsMetrics {
    return PPostGoogleAdsMetrics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPostGoogleAdsMetrics>, I>>(object: I): PPostGoogleAdsMetrics {
    const message = createBasePPostGoogleAdsMetrics();
    message.dateString = object.dateString ?? "";
    message.clicks = object.clicks ?? 0;
    message.costMicros = object.costMicros ?? 0;
    message.dollarToNis = object.dollarToNis ?? 0;
    message.campaign = object.campaign ?? "";
    message.deviceType = object.deviceType ?? "";
    return message;
  },
};

function createBasePCreateBrunch(): PCreateBrunch {
  return { src: "", dest: "" };
}

export const PCreateBrunch = {
  encode(message: PCreateBrunch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.src !== "") {
      writer.uint32(10).string(message.src);
    }
    if (message.dest !== "") {
      writer.uint32(18).string(message.dest);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCreateBrunch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePCreateBrunch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.src = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.dest = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PCreateBrunch {
    return { src: isSet(object.src) ? String(object.src) : "", dest: isSet(object.dest) ? String(object.dest) : "" };
  },

  toJSON(message: PCreateBrunch): unknown {
    const obj: any = {};
    message.src !== undefined && (obj.src = message.src);
    message.dest !== undefined && (obj.dest = message.dest);
    return obj;
  },

  create<I extends Exact<DeepPartial<PCreateBrunch>, I>>(base?: I): PCreateBrunch {
    return PCreateBrunch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PCreateBrunch>, I>>(object: I): PCreateBrunch {
    const message = createBasePCreateBrunch();
    message.src = object.src ?? "";
    message.dest = object.dest ?? "";
    return message;
  },
};

function createBasePCreateFIle(): PCreateFIle {
  return { sha: "", content: "", name: "" };
}

export const PCreateFIle = {
  encode(message: PCreateFIle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sha !== "") {
      writer.uint32(10).string(message.sha);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PCreateFIle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePCreateFIle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.sha = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PCreateFIle {
    return {
      sha: isSet(object.sha) ? String(object.sha) : "",
      content: isSet(object.content) ? String(object.content) : "",
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: PCreateFIle): unknown {
    const obj: any = {};
    message.sha !== undefined && (obj.sha = message.sha);
    message.content !== undefined && (obj.content = message.content);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PCreateFIle>, I>>(base?: I): PCreateFIle {
    return PCreateFIle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PCreateFIle>, I>>(object: I): PCreateFIle {
    const message = createBasePCreateFIle();
    message.sha = object.sha ?? "";
    message.content = object.content ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBasePPostFrontendError(): PPostFrontendError {
  return { feError: undefined };
}

export const PPostFrontendError = {
  encode(message: PPostFrontendError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.feError !== undefined) {
      PFrontendError.encode(message.feError, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPostFrontendError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPostFrontendError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.feError = PFrontendError.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPostFrontendError {
    return { feError: isSet(object.feError) ? PFrontendError.fromJSON(object.feError) : undefined };
  },

  toJSON(message: PPostFrontendError): unknown {
    const obj: any = {};
    message.feError !== undefined &&
      (obj.feError = message.feError ? PFrontendError.toJSON(message.feError) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPostFrontendError>, I>>(base?: I): PPostFrontendError {
    return PPostFrontendError.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPostFrontendError>, I>>(object: I): PPostFrontendError {
    const message = createBasePPostFrontendError();
    message.feError = (object.feError !== undefined && object.feError !== null)
      ? PFrontendError.fromPartial(object.feError)
      : undefined;
    return message;
  },
};

function createBasePScrapeWebsiteRequest(): PScrapeWebsiteRequest {
  return { url: "" };
}

export const PScrapeWebsiteRequest = {
  encode(message: PScrapeWebsiteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PScrapeWebsiteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePScrapeWebsiteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PScrapeWebsiteRequest {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: PScrapeWebsiteRequest): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<PScrapeWebsiteRequest>, I>>(base?: I): PScrapeWebsiteRequest {
    return PScrapeWebsiteRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PScrapeWebsiteRequest>, I>>(object: I): PScrapeWebsiteRequest {
    const message = createBasePScrapeWebsiteRequest();
    message.url = object.url ?? "";
    return message;
  },
};

function createBasePScrapeWebsiteResponse(): PScrapeWebsiteResponse {
  return { scripts: [] };
}

export const PScrapeWebsiteResponse = {
  encode(message: PScrapeWebsiteResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scripts) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PScrapeWebsiteResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePScrapeWebsiteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.scripts.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PScrapeWebsiteResponse {
    return { scripts: Array.isArray(object?.scripts) ? object.scripts.map((e: any) => String(e)) : [] };
  },

  toJSON(message: PScrapeWebsiteResponse): unknown {
    const obj: any = {};
    if (message.scripts) {
      obj.scripts = message.scripts.map((e) => e);
    } else {
      obj.scripts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PScrapeWebsiteResponse>, I>>(base?: I): PScrapeWebsiteResponse {
    return PScrapeWebsiteResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PScrapeWebsiteResponse>, I>>(object: I): PScrapeWebsiteResponse {
    const message = createBasePScrapeWebsiteResponse();
    message.scripts = object.scripts?.map((e) => e) || [];
    return message;
  },
};

function createBasePContactInfoPutRequest(): PContactInfoPutRequest {
  return { contactInfo: undefined };
}

export const PContactInfoPutRequest = {
  encode(message: PContactInfoPutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contactInfo !== undefined) {
      PContactInfo.encode(message.contactInfo, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContactInfoPutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContactInfoPutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.contactInfo = PContactInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContactInfoPutRequest {
    return { contactInfo: isSet(object.contactInfo) ? PContactInfo.fromJSON(object.contactInfo) : undefined };
  },

  toJSON(message: PContactInfoPutRequest): unknown {
    const obj: any = {};
    message.contactInfo !== undefined &&
      (obj.contactInfo = message.contactInfo ? PContactInfo.toJSON(message.contactInfo) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PContactInfoPutRequest>, I>>(base?: I): PContactInfoPutRequest {
    return PContactInfoPutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContactInfoPutRequest>, I>>(object: I): PContactInfoPutRequest {
    const message = createBasePContactInfoPutRequest();
    message.contactInfo = (object.contactInfo !== undefined && object.contactInfo !== null)
      ? PContactInfo.fromPartial(object.contactInfo)
      : undefined;
    return message;
  },
};

function createBasePGetAllContactInfoResponse(): PGetAllContactInfoResponse {
  return { contactInfos: [] };
}

export const PGetAllContactInfoResponse = {
  encode(message: PGetAllContactInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.contactInfos) {
      PContactInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PGetAllContactInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePGetAllContactInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.contactInfos.push(PContactInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PGetAllContactInfoResponse {
    return {
      contactInfos: Array.isArray(object?.contactInfos)
        ? object.contactInfos.map((e: any) => PContactInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PGetAllContactInfoResponse): unknown {
    const obj: any = {};
    if (message.contactInfos) {
      obj.contactInfos = message.contactInfos.map((e) => e ? PContactInfo.toJSON(e) : undefined);
    } else {
      obj.contactInfos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PGetAllContactInfoResponse>, I>>(base?: I): PGetAllContactInfoResponse {
    return PGetAllContactInfoResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PGetAllContactInfoResponse>, I>>(object: I): PGetAllContactInfoResponse {
    const message = createBasePGetAllContactInfoResponse();
    message.contactInfos = object.contactInfos?.map((e) => PContactInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBasePTimeRecordPutRequest(): PTimeRecordPutRequest {
  return { timeRecord: undefined };
}

export const PTimeRecordPutRequest = {
  encode(message: PTimeRecordPutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeRecord !== undefined) {
      PTimeRecord.encode(message.timeRecord, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTimeRecordPutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTimeRecordPutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.timeRecord = PTimeRecord.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTimeRecordPutRequest {
    return { timeRecord: isSet(object.timeRecord) ? PTimeRecord.fromJSON(object.timeRecord) : undefined };
  },

  toJSON(message: PTimeRecordPutRequest): unknown {
    const obj: any = {};
    message.timeRecord !== undefined &&
      (obj.timeRecord = message.timeRecord ? PTimeRecord.toJSON(message.timeRecord) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTimeRecordPutRequest>, I>>(base?: I): PTimeRecordPutRequest {
    return PTimeRecordPutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTimeRecordPutRequest>, I>>(object: I): PTimeRecordPutRequest {
    const message = createBasePTimeRecordPutRequest();
    message.timeRecord = (object.timeRecord !== undefined && object.timeRecord !== null)
      ? PTimeRecord.fromPartial(object.timeRecord)
      : undefined;
    return message;
  },
};

function createBasePTimeRecordPutResponse(): PTimeRecordPutResponse {
  return { uuid: "" };
}

export const PTimeRecordPutResponse = {
  encode(message: PTimeRecordPutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTimeRecordPutResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTimeRecordPutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTimeRecordPutResponse {
    return { uuid: isSet(object.uuid) ? String(object.uuid) : "" };
  },

  toJSON(message: PTimeRecordPutResponse): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTimeRecordPutResponse>, I>>(base?: I): PTimeRecordPutResponse {
    return PTimeRecordPutResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTimeRecordPutResponse>, I>>(object: I): PTimeRecordPutResponse {
    const message = createBasePTimeRecordPutResponse();
    message.uuid = object.uuid ?? "";
    return message;
  },
};

function createBasePGetAllTimeRecordResponse(): PGetAllTimeRecordResponse {
  return { timeRecords: [] };
}

export const PGetAllTimeRecordResponse = {
  encode(message: PGetAllTimeRecordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.timeRecords) {
      PTimeRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PGetAllTimeRecordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePGetAllTimeRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.timeRecords.push(PTimeRecord.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PGetAllTimeRecordResponse {
    return {
      timeRecords: Array.isArray(object?.timeRecords)
        ? object.timeRecords.map((e: any) => PTimeRecord.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PGetAllTimeRecordResponse): unknown {
    const obj: any = {};
    if (message.timeRecords) {
      obj.timeRecords = message.timeRecords.map((e) => e ? PTimeRecord.toJSON(e) : undefined);
    } else {
      obj.timeRecords = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PGetAllTimeRecordResponse>, I>>(base?: I): PGetAllTimeRecordResponse {
    return PGetAllTimeRecordResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PGetAllTimeRecordResponse>, I>>(object: I): PGetAllTimeRecordResponse {
    const message = createBasePGetAllTimeRecordResponse();
    message.timeRecords = object.timeRecords?.map((e) => PTimeRecord.fromPartial(e)) || [];
    return message;
  },
};

function createBasePPageViewRequest(): PPageViewRequest {
  return { pageUrl: "" };
}

export const PPageViewRequest = {
  encode(message: PPageViewRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageUrl !== "") {
      writer.uint32(10).string(message.pageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPageViewRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPageViewRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.pageUrl = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPageViewRequest {
    return { pageUrl: isSet(object.pageUrl) ? String(object.pageUrl) : "" };
  },

  toJSON(message: PPageViewRequest): unknown {
    const obj: any = {};
    message.pageUrl !== undefined && (obj.pageUrl = message.pageUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<PPageViewRequest>, I>>(base?: I): PPageViewRequest {
    return PPageViewRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPageViewRequest>, I>>(object: I): PPageViewRequest {
    const message = createBasePPageViewRequest();
    message.pageUrl = object.pageUrl ?? "";
    return message;
  },
};

function createBasePUploadFileRequest(): PUploadFileRequest {
  return { fileType: "", fileName: "", fileData: new Uint8Array() };
}

export const PUploadFileRequest = {
  encode(message: PUploadFileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fileType !== "") {
      writer.uint32(10).string(message.fileType);
    }
    if (message.fileName !== "") {
      writer.uint32(18).string(message.fileName);
    }
    if (message.fileData.length !== 0) {
      writer.uint32(26).bytes(message.fileData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PUploadFileRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePUploadFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.fileType = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.fileName = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.fileData = reader.bytes();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PUploadFileRequest {
    return {
      fileType: isSet(object.fileType) ? String(object.fileType) : "",
      fileName: isSet(object.fileName) ? String(object.fileName) : "",
      fileData: isSet(object.fileData) ? bytesFromBase64(object.fileData) : new Uint8Array(),
    };
  },

  toJSON(message: PUploadFileRequest): unknown {
    const obj: any = {};
    message.fileType !== undefined && (obj.fileType = message.fileType);
    message.fileName !== undefined && (obj.fileName = message.fileName);
    message.fileData !== undefined &&
      (obj.fileData = base64FromBytes(message.fileData !== undefined ? message.fileData : new Uint8Array()));
    return obj;
  },

  create<I extends Exact<DeepPartial<PUploadFileRequest>, I>>(base?: I): PUploadFileRequest {
    return PUploadFileRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PUploadFileRequest>, I>>(object: I): PUploadFileRequest {
    const message = createBasePUploadFileRequest();
    message.fileType = object.fileType ?? "";
    message.fileName = object.fileName ?? "";
    message.fileData = object.fileData ?? new Uint8Array();
    return message;
  },
};

function createBasePContractReviewRequest(): PContractReviewRequest {
  return { docId: "" };
}

export const PContractReviewRequest = {
  encode(message: PContractReviewRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.docId !== "") {
      writer.uint32(10).string(message.docId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContractReviewRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContractReviewRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.docId = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContractReviewRequest {
    return { docId: isSet(object.docId) ? String(object.docId) : "" };
  },

  toJSON(message: PContractReviewRequest): unknown {
    const obj: any = {};
    message.docId !== undefined && (obj.docId = message.docId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PContractReviewRequest>, I>>(base?: I): PContractReviewRequest {
    return PContractReviewRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContractReviewRequest>, I>>(object: I): PContractReviewRequest {
    const message = createBasePContractReviewRequest();
    message.docId = object.docId ?? "";
    return message;
  },
};

function createBasePContractReviewResponse(): PContractReviewResponse {
  return { isSuccessful: false, msg: "" };
}

export const PContractReviewResponse = {
  encode(message: PContractReviewResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSuccessful === true) {
      writer.uint32(8).bool(message.isSuccessful);
    }
    if (message.msg !== "") {
      writer.uint32(18).string(message.msg);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PContractReviewResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePContractReviewResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.isSuccessful = reader.bool();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.msg = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PContractReviewResponse {
    return {
      isSuccessful: isSet(object.isSuccessful) ? Boolean(object.isSuccessful) : false,
      msg: isSet(object.msg) ? String(object.msg) : "",
    };
  },

  toJSON(message: PContractReviewResponse): unknown {
    const obj: any = {};
    message.isSuccessful !== undefined && (obj.isSuccessful = message.isSuccessful);
    message.msg !== undefined && (obj.msg = message.msg);
    return obj;
  },

  create<I extends Exact<DeepPartial<PContractReviewResponse>, I>>(base?: I): PContractReviewResponse {
    return PContractReviewResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PContractReviewResponse>, I>>(object: I): PContractReviewResponse {
    const message = createBasePContractReviewResponse();
    message.isSuccessful = object.isSuccessful ?? false;
    message.msg = object.msg ?? "";
    return message;
  },
};

function createBasePAskRequest(): PAskRequest {
  return { json: "", q: "", contract: [] };
}

export const PAskRequest = {
  encode(message: PAskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.json !== "") {
      writer.uint32(10).string(message.json);
    }
    if (message.q !== "") {
      writer.uint32(18).string(message.q);
    }
    for (const v of message.contract) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PAskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePAskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.json = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.q = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.contract.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PAskRequest {
    return {
      json: isSet(object.json) ? String(object.json) : "",
      q: isSet(object.q) ? String(object.q) : "",
      contract: Array.isArray(object?.contract) ? object.contract.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: PAskRequest): unknown {
    const obj: any = {};
    message.json !== undefined && (obj.json = message.json);
    message.q !== undefined && (obj.q = message.q);
    if (message.contract) {
      obj.contract = message.contract.map((e) => e);
    } else {
      obj.contract = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PAskRequest>, I>>(base?: I): PAskRequest {
    return PAskRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PAskRequest>, I>>(object: I): PAskRequest {
    const message = createBasePAskRequest();
    message.json = object.json ?? "";
    message.q = object.q ?? "";
    message.contract = object.contract?.map((e) => e) || [];
    return message;
  },
};

function createBasePAskResponse(): PAskResponse {
  return { a: "" };
}

export const PAskResponse = {
  encode(message: PAskResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.a !== "") {
      writer.uint32(10).string(message.a);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PAskResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePAskResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.a = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PAskResponse {
    return { a: isSet(object.a) ? String(object.a) : "" };
  },

  toJSON(message: PAskResponse): unknown {
    const obj: any = {};
    message.a !== undefined && (obj.a = message.a);
    return obj;
  },

  create<I extends Exact<DeepPartial<PAskResponse>, I>>(base?: I): PAskResponse {
    return PAskResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PAskResponse>, I>>(object: I): PAskResponse {
    const message = createBasePAskResponse();
    message.a = object.a ?? "";
    return message;
  },
};

function createBasePAskResponseNew(): PAskResponseNew {
  return { responses: [] };
}

export const PAskResponseNew = {
  encode(message: PAskResponseNew, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.responses) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PAskResponseNew {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePAskResponseNew();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.responses.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PAskResponseNew {
    return { responses: Array.isArray(object?.responses) ? object.responses.map((e: any) => String(e)) : [] };
  },

  toJSON(message: PAskResponseNew): unknown {
    const obj: any = {};
    if (message.responses) {
      obj.responses = message.responses.map((e) => e);
    } else {
      obj.responses = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PAskResponseNew>, I>>(base?: I): PAskResponseNew {
    return PAskResponseNew.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PAskResponseNew>, I>>(object: I): PAskResponseNew {
    const message = createBasePAskResponseNew();
    message.responses = object.responses?.map((e) => e) || [];
    return message;
  },
};

function createBasePTipRequest(): PTipRequest {
  return { companyDetails: "" };
}

export const PTipRequest = {
  encode(message: PTipRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyDetails !== "") {
      writer.uint32(10).string(message.companyDetails);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTipRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTipRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyDetails = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTipRequest {
    return { companyDetails: isSet(object.companyDetails) ? String(object.companyDetails) : "" };
  },

  toJSON(message: PTipRequest): unknown {
    const obj: any = {};
    message.companyDetails !== undefined && (obj.companyDetails = message.companyDetails);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTipRequest>, I>>(base?: I): PTipRequest {
    return PTipRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTipRequest>, I>>(object: I): PTipRequest {
    const message = createBasePTipRequest();
    message.companyDetails = object.companyDetails ?? "";
    return message;
  },
};

function createBasePTipResponse(): PTipResponse {
  return { theTip: "" };
}

export const PTipResponse = {
  encode(message: PTipResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.theTip !== "") {
      writer.uint32(10).string(message.theTip);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTipResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTipResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.theTip = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTipResponse {
    return { theTip: isSet(object.theTip) ? String(object.theTip) : "" };
  },

  toJSON(message: PTipResponse): unknown {
    const obj: any = {};
    message.theTip !== undefined && (obj.theTip = message.theTip);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTipResponse>, I>>(base?: I): PTipResponse {
    return PTipResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTipResponse>, I>>(object: I): PTipResponse {
    const message = createBasePTipResponse();
    message.theTip = object.theTip ?? "";
    return message;
  },
};

function createBasePWixInstallAppRequest(): PWixInstallAppRequest {
  return { instanceId: "", code: "" };
}

export const PWixInstallAppRequest = {
  encode(message: PWixInstallAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instanceId !== "") {
      writer.uint32(10).string(message.instanceId);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PWixInstallAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePWixInstallAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.instanceId = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PWixInstallAppRequest {
    return {
      instanceId: isSet(object.instanceId) ? String(object.instanceId) : "",
      code: isSet(object.code) ? String(object.code) : "",
    };
  },

  toJSON(message: PWixInstallAppRequest): unknown {
    const obj: any = {};
    message.instanceId !== undefined && (obj.instanceId = message.instanceId);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<PWixInstallAppRequest>, I>>(base?: I): PWixInstallAppRequest {
    return PWixInstallAppRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PWixInstallAppRequest>, I>>(object: I): PWixInstallAppRequest {
    const message = createBasePWixInstallAppRequest();
    message.instanceId = object.instanceId ?? "";
    message.code = object.code ?? "";
    return message;
  },
};

function createBasePWixInstallAppResponse(): PWixInstallAppResponse {
  return {};
}

export const PWixInstallAppResponse = {
  encode(_: PWixInstallAppResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PWixInstallAppResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePWixInstallAppResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PWixInstallAppResponse {
    return {};
  },

  toJSON(_: PWixInstallAppResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<PWixInstallAppResponse>, I>>(base?: I): PWixInstallAppResponse {
    return PWixInstallAppResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PWixInstallAppResponse>, I>>(_: I): PWixInstallAppResponse {
    const message = createBasePWixInstallAppResponse();
    return message;
  },
};

function createBasePWixCheckoutUrlResponse(): PWixCheckoutUrlResponse {
  return { checkoutUrl: "" };
}

export const PWixCheckoutUrlResponse = {
  encode(message: PWixCheckoutUrlResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checkoutUrl !== "") {
      writer.uint32(10).string(message.checkoutUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PWixCheckoutUrlResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePWixCheckoutUrlResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.checkoutUrl = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PWixCheckoutUrlResponse {
    return { checkoutUrl: isSet(object.checkoutUrl) ? String(object.checkoutUrl) : "" };
  },

  toJSON(message: PWixCheckoutUrlResponse): unknown {
    const obj: any = {};
    message.checkoutUrl !== undefined && (obj.checkoutUrl = message.checkoutUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<PWixCheckoutUrlResponse>, I>>(base?: I): PWixCheckoutUrlResponse {
    return PWixCheckoutUrlResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PWixCheckoutUrlResponse>, I>>(object: I): PWixCheckoutUrlResponse {
    const message = createBasePWixCheckoutUrlResponse();
    message.checkoutUrl = object.checkoutUrl ?? "";
    return message;
  },
};

function createBasePWixCheckoutUrlRequest(): PWixCheckoutUrlRequest {
  return { cycle: "" };
}

export const PWixCheckoutUrlRequest = {
  encode(message: PWixCheckoutUrlRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cycle !== "") {
      writer.uint32(10).string(message.cycle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PWixCheckoutUrlRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePWixCheckoutUrlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.cycle = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PWixCheckoutUrlRequest {
    return { cycle: isSet(object.cycle) ? String(object.cycle) : "" };
  },

  toJSON(message: PWixCheckoutUrlRequest): unknown {
    const obj: any = {};
    message.cycle !== undefined && (obj.cycle = message.cycle);
    return obj;
  },

  create<I extends Exact<DeepPartial<PWixCheckoutUrlRequest>, I>>(base?: I): PWixCheckoutUrlRequest {
    return PWixCheckoutUrlRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PWixCheckoutUrlRequest>, I>>(object: I): PWixCheckoutUrlRequest {
    const message = createBasePWixCheckoutUrlRequest();
    message.cycle = object.cycle ?? "";
    return message;
  },
};

function createBasePShopifyCheckoutUrlResponse(): PShopifyCheckoutUrlResponse {
  return { checkoutUrl: "" };
}

export const PShopifyCheckoutUrlResponse = {
  encode(message: PShopifyCheckoutUrlResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checkoutUrl !== "") {
      writer.uint32(10).string(message.checkoutUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PShopifyCheckoutUrlResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePShopifyCheckoutUrlResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.checkoutUrl = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PShopifyCheckoutUrlResponse {
    return { checkoutUrl: isSet(object.checkoutUrl) ? String(object.checkoutUrl) : "" };
  },

  toJSON(message: PShopifyCheckoutUrlResponse): unknown {
    const obj: any = {};
    message.checkoutUrl !== undefined && (obj.checkoutUrl = message.checkoutUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<PShopifyCheckoutUrlResponse>, I>>(base?: I): PShopifyCheckoutUrlResponse {
    return PShopifyCheckoutUrlResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PShopifyCheckoutUrlResponse>, I>>(object: I): PShopifyCheckoutUrlResponse {
    const message = createBasePShopifyCheckoutUrlResponse();
    message.checkoutUrl = object.checkoutUrl ?? "";
    return message;
  },
};

function createBasePShopifyCheckoutUrlRequest(): PShopifyCheckoutUrlRequest {
  return { cycle: "" };
}

export const PShopifyCheckoutUrlRequest = {
  encode(message: PShopifyCheckoutUrlRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cycle !== "") {
      writer.uint32(10).string(message.cycle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PShopifyCheckoutUrlRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePShopifyCheckoutUrlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.cycle = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PShopifyCheckoutUrlRequest {
    return { cycle: isSet(object.cycle) ? String(object.cycle) : "" };
  },

  toJSON(message: PShopifyCheckoutUrlRequest): unknown {
    const obj: any = {};
    message.cycle !== undefined && (obj.cycle = message.cycle);
    return obj;
  },

  create<I extends Exact<DeepPartial<PShopifyCheckoutUrlRequest>, I>>(base?: I): PShopifyCheckoutUrlRequest {
    return PShopifyCheckoutUrlRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PShopifyCheckoutUrlRequest>, I>>(object: I): PShopifyCheckoutUrlRequest {
    const message = createBasePShopifyCheckoutUrlRequest();
    message.cycle = object.cycle ?? "";
    return message;
  },
};

function createBasePBehaviourRequest(): PBehaviourRequest {
  return { op: "", details: "", extraKeyValuePairs: [] };
}

export const PBehaviourRequest = {
  encode(message: PBehaviourRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.op !== "") {
      writer.uint32(10).string(message.op);
    }
    if (message.details !== "") {
      writer.uint32(18).string(message.details);
    }
    for (const v of message.extraKeyValuePairs) {
      BehaviourKeyValuePair.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PBehaviourRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePBehaviourRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.op = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.details = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.extraKeyValuePairs.push(BehaviourKeyValuePair.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PBehaviourRequest {
    return {
      op: isSet(object.op) ? String(object.op) : "",
      details: isSet(object.details) ? String(object.details) : "",
      extraKeyValuePairs: Array.isArray(object?.extraKeyValuePairs)
        ? object.extraKeyValuePairs.map((e: any) => BehaviourKeyValuePair.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PBehaviourRequest): unknown {
    const obj: any = {};
    message.op !== undefined && (obj.op = message.op);
    message.details !== undefined && (obj.details = message.details);
    if (message.extraKeyValuePairs) {
      obj.extraKeyValuePairs = message.extraKeyValuePairs.map((e) => e ? BehaviourKeyValuePair.toJSON(e) : undefined);
    } else {
      obj.extraKeyValuePairs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PBehaviourRequest>, I>>(base?: I): PBehaviourRequest {
    return PBehaviourRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PBehaviourRequest>, I>>(object: I): PBehaviourRequest {
    const message = createBasePBehaviourRequest();
    message.op = object.op ?? "";
    message.details = object.details ?? "";
    message.extraKeyValuePairs = object.extraKeyValuePairs?.map((e) => BehaviourKeyValuePair.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBehaviourKeyValuePair(): BehaviourKeyValuePair {
  return { key: "", value: "" };
}

export const BehaviourKeyValuePair = {
  encode(message: BehaviourKeyValuePair, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BehaviourKeyValuePair {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBehaviourKeyValuePair();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BehaviourKeyValuePair {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: BehaviourKeyValuePair): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<BehaviourKeyValuePair>, I>>(base?: I): BehaviourKeyValuePair {
    return BehaviourKeyValuePair.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BehaviourKeyValuePair>, I>>(object: I): BehaviourKeyValuePair {
    const message = createBaseBehaviourKeyValuePair();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

export interface AddService {
  Add(request: PUser): Promise<PUser>;
}

export class AddServiceClientImpl implements AddService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "AddService";
    this.rpc = rpc;
    this.Add = this.Add.bind(this);
  }
  Add(request: PUser): Promise<PUser> {
    const data = PUser.encode(request).finish();
    const promise = this.rpc.request(this.service, "Add", data);
    return promise.then((data) => PUser.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
