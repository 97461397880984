/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "";

export enum ECommissionLetterAgreementExclusive {
  CommissionLetterAgreement_exclusive_NA = 0,
  CommissionLetterAgreement_exclusive_NONE = 3,
  CommissionLetterAgreement_exclusive_TERRITORY = 1,
  CommissionLetterAgreement_exclusive_OTHER = 2,
  UNRECOGNIZED = -1,
}

export function eCommissionLetterAgreementExclusiveFromJSON(object: any): ECommissionLetterAgreementExclusive {
  switch (object) {
    case 0:
    case "CommissionLetterAgreement_exclusive_NA":
      return ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_NA;
    case 3:
    case "CommissionLetterAgreement_exclusive_NONE":
      return ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_NONE;
    case 1:
    case "CommissionLetterAgreement_exclusive_TERRITORY":
      return ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_TERRITORY;
    case 2:
    case "CommissionLetterAgreement_exclusive_OTHER":
      return ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECommissionLetterAgreementExclusive.UNRECOGNIZED;
  }
}

export function eCommissionLetterAgreementExclusiveToJSON(object: ECommissionLetterAgreementExclusive): string {
  switch (object) {
    case ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_NA:
      return "CommissionLetterAgreement_exclusive_NA";
    case ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_NONE:
      return "CommissionLetterAgreement_exclusive_NONE";
    case ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_TERRITORY:
      return "CommissionLetterAgreement_exclusive_TERRITORY";
    case ECommissionLetterAgreementExclusive.CommissionLetterAgreement_exclusive_OTHER:
      return "CommissionLetterAgreement_exclusive_OTHER";
    case ECommissionLetterAgreementExclusive.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ECommissionLetterAgreementProducts {
  CommissionLetterAgreement_products_NA = 0,
  CommissionLetterAgreement_products_GOODS = 1,
  CommissionLetterAgreement_products_SERVICES = 2,
  CommissionLetterAgreement_products_BOTH = 4,
  UNRECOGNIZED = -1,
}

export function eCommissionLetterAgreementProductsFromJSON(object: any): ECommissionLetterAgreementProducts {
  switch (object) {
    case 0:
    case "CommissionLetterAgreement_products_NA":
      return ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_NA;
    case 1:
    case "CommissionLetterAgreement_products_GOODS":
      return ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_GOODS;
    case 2:
    case "CommissionLetterAgreement_products_SERVICES":
      return ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_SERVICES;
    case 4:
    case "CommissionLetterAgreement_products_BOTH":
      return ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_BOTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECommissionLetterAgreementProducts.UNRECOGNIZED;
  }
}

export function eCommissionLetterAgreementProductsToJSON(object: ECommissionLetterAgreementProducts): string {
  switch (object) {
    case ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_NA:
      return "CommissionLetterAgreement_products_NA";
    case ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_GOODS:
      return "CommissionLetterAgreement_products_GOODS";
    case ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_SERVICES:
      return "CommissionLetterAgreement_products_SERVICES";
    case ECommissionLetterAgreementProducts.CommissionLetterAgreement_products_BOTH:
      return "CommissionLetterAgreement_products_BOTH";
    case ECommissionLetterAgreementProducts.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ECommissionLetterAgreementCommissionTail {
  CommissionLetterAgreement_commissionTail_NA = 0,
  CommissionLetterAgreement_commissionTail_INITIAL = 1,
  CommissionLetterAgreement_commissionTail_TERM = 2,
  CommissionLetterAgreement_commissionTail_TAIL = 3,
  CommissionLetterAgreement_commissionTail_LIFETIME = 4,
  UNRECOGNIZED = -1,
}

export function eCommissionLetterAgreementCommissionTailFromJSON(
  object: any,
): ECommissionLetterAgreementCommissionTail {
  switch (object) {
    case 0:
    case "CommissionLetterAgreement_commissionTail_NA":
      return ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_NA;
    case 1:
    case "CommissionLetterAgreement_commissionTail_INITIAL":
      return ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_INITIAL;
    case 2:
    case "CommissionLetterAgreement_commissionTail_TERM":
      return ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_TERM;
    case 3:
    case "CommissionLetterAgreement_commissionTail_TAIL":
      return ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_TAIL;
    case 4:
    case "CommissionLetterAgreement_commissionTail_LIFETIME":
      return ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_LIFETIME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECommissionLetterAgreementCommissionTail.UNRECOGNIZED;
  }
}

export function eCommissionLetterAgreementCommissionTailToJSON(
  object: ECommissionLetterAgreementCommissionTail,
): string {
  switch (object) {
    case ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_NA:
      return "CommissionLetterAgreement_commissionTail_NA";
    case ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_INITIAL:
      return "CommissionLetterAgreement_commissionTail_INITIAL";
    case ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_TERM:
      return "CommissionLetterAgreement_commissionTail_TERM";
    case ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_TAIL:
      return "CommissionLetterAgreement_commissionTail_TAIL";
    case ECommissionLetterAgreementCommissionTail.CommissionLetterAgreement_commissionTail_LIFETIME:
      return "CommissionLetterAgreement_commissionTail_LIFETIME";
    case ECommissionLetterAgreementCommissionTail.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ECommissionLetterAgreementReferSales {
  CommissionLetterAgreement_referSales_NA = 0,
  CommissionLetterAgreement_referSales_REFER = 1,
  CommissionLetterAgreement_referSales_SALES = 2,
  UNRECOGNIZED = -1,
}

export function eCommissionLetterAgreementReferSalesFromJSON(object: any): ECommissionLetterAgreementReferSales {
  switch (object) {
    case 0:
    case "CommissionLetterAgreement_referSales_NA":
      return ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_NA;
    case 1:
    case "CommissionLetterAgreement_referSales_REFER":
      return ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_REFER;
    case 2:
    case "CommissionLetterAgreement_referSales_SALES":
      return ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_SALES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECommissionLetterAgreementReferSales.UNRECOGNIZED;
  }
}

export function eCommissionLetterAgreementReferSalesToJSON(object: ECommissionLetterAgreementReferSales): string {
  switch (object) {
    case ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_NA:
      return "CommissionLetterAgreement_referSales_NA";
    case ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_REFER:
      return "CommissionLetterAgreement_referSales_REFER";
    case ECommissionLetterAgreementReferSales.CommissionLetterAgreement_referSales_SALES:
      return "CommissionLetterAgreement_referSales_SALES";
    case ECommissionLetterAgreementReferSales.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MCommissionLetterAgreementExclusive {
  optionDbValue: ECommissionLetterAgreementExclusive;
  other: string;
}

export interface MCommissionLetterAgreementProducts {
  optionDbValue: ECommissionLetterAgreementProducts;
  other: string;
}

export interface MCommissionLetterAgreementCommissionTail {
  optionDbValue: ECommissionLetterAgreementCommissionTail;
  other: string;
}

export interface MCommissionLetterAgreementReferSales {
  optionDbValue: ECommissionLetterAgreementReferSales;
  other: string;
}

export interface CommissionLetterAgreement {
  effectiveDate: Date | undefined;
  governLawState: string;
  causeTermNotice: number;
  agentCompanyFull: string;
  agentAddressOther: string;
  agentAddress: string;
  agentEmail: string;
  agentName: string;
  exclusive: MCommissionLetterAgreementExclusive | undefined;
  territories: string[];
  products: MCommissionLetterAgreementProducts | undefined;
  term: number;
  commissionPercentage: number;
  commissionTail: MCommissionLetterAgreementCommissionTail | undefined;
  commissionPaymentDays: string;
  referSales: MCommissionLetterAgreementReferSales | undefined;
  commissionTailMonths: number;
}

function createBaseMCommissionLetterAgreementExclusive(): MCommissionLetterAgreementExclusive {
  return { optionDbValue: 0, other: "" };
}

export const MCommissionLetterAgreementExclusive = {
  encode(message: MCommissionLetterAgreementExclusive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCommissionLetterAgreementExclusive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCommissionLetterAgreementExclusive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCommissionLetterAgreementExclusive {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eCommissionLetterAgreementExclusiveFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCommissionLetterAgreementExclusive): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCommissionLetterAgreementExclusiveToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCommissionLetterAgreementExclusive>, I>>(
    base?: I,
  ): MCommissionLetterAgreementExclusive {
    return MCommissionLetterAgreementExclusive.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCommissionLetterAgreementExclusive>, I>>(
    object: I,
  ): MCommissionLetterAgreementExclusive {
    const message = createBaseMCommissionLetterAgreementExclusive();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMCommissionLetterAgreementProducts(): MCommissionLetterAgreementProducts {
  return { optionDbValue: 0, other: "" };
}

export const MCommissionLetterAgreementProducts = {
  encode(message: MCommissionLetterAgreementProducts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCommissionLetterAgreementProducts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCommissionLetterAgreementProducts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCommissionLetterAgreementProducts {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eCommissionLetterAgreementProductsFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCommissionLetterAgreementProducts): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCommissionLetterAgreementProductsToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCommissionLetterAgreementProducts>, I>>(
    base?: I,
  ): MCommissionLetterAgreementProducts {
    return MCommissionLetterAgreementProducts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCommissionLetterAgreementProducts>, I>>(
    object: I,
  ): MCommissionLetterAgreementProducts {
    const message = createBaseMCommissionLetterAgreementProducts();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMCommissionLetterAgreementCommissionTail(): MCommissionLetterAgreementCommissionTail {
  return { optionDbValue: 0, other: "" };
}

export const MCommissionLetterAgreementCommissionTail = {
  encode(message: MCommissionLetterAgreementCommissionTail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCommissionLetterAgreementCommissionTail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCommissionLetterAgreementCommissionTail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCommissionLetterAgreementCommissionTail {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eCommissionLetterAgreementCommissionTailFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCommissionLetterAgreementCommissionTail): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCommissionLetterAgreementCommissionTailToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCommissionLetterAgreementCommissionTail>, I>>(
    base?: I,
  ): MCommissionLetterAgreementCommissionTail {
    return MCommissionLetterAgreementCommissionTail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCommissionLetterAgreementCommissionTail>, I>>(
    object: I,
  ): MCommissionLetterAgreementCommissionTail {
    const message = createBaseMCommissionLetterAgreementCommissionTail();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMCommissionLetterAgreementReferSales(): MCommissionLetterAgreementReferSales {
  return { optionDbValue: 0, other: "" };
}

export const MCommissionLetterAgreementReferSales = {
  encode(message: MCommissionLetterAgreementReferSales, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCommissionLetterAgreementReferSales {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCommissionLetterAgreementReferSales();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCommissionLetterAgreementReferSales {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eCommissionLetterAgreementReferSalesFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCommissionLetterAgreementReferSales): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCommissionLetterAgreementReferSalesToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCommissionLetterAgreementReferSales>, I>>(
    base?: I,
  ): MCommissionLetterAgreementReferSales {
    return MCommissionLetterAgreementReferSales.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCommissionLetterAgreementReferSales>, I>>(
    object: I,
  ): MCommissionLetterAgreementReferSales {
    const message = createBaseMCommissionLetterAgreementReferSales();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseCommissionLetterAgreement(): CommissionLetterAgreement {
  return {
    effectiveDate: undefined,
    governLawState: "",
    causeTermNotice: 0,
    agentCompanyFull: "",
    agentAddressOther: "",
    agentAddress: "",
    agentEmail: "",
    agentName: "",
    exclusive: undefined,
    territories: [],
    products: undefined,
    term: 0,
    commissionPercentage: 0,
    commissionTail: undefined,
    commissionPaymentDays: "",
    referSales: undefined,
    commissionTailMonths: 0,
  };
}

export const CommissionLetterAgreement = {
  encode(message: CommissionLetterAgreement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(18).string(message.governLawState);
    }
    if (message.causeTermNotice !== 0) {
      writer.uint32(29).float(message.causeTermNotice);
    }
    if (message.agentCompanyFull !== "") {
      writer.uint32(34).string(message.agentCompanyFull);
    }
    if (message.agentAddressOther !== "") {
      writer.uint32(42).string(message.agentAddressOther);
    }
    if (message.agentAddress !== "") {
      writer.uint32(50).string(message.agentAddress);
    }
    if (message.agentEmail !== "") {
      writer.uint32(58).string(message.agentEmail);
    }
    if (message.agentName !== "") {
      writer.uint32(66).string(message.agentName);
    }
    if (message.exclusive !== undefined) {
      MCommissionLetterAgreementExclusive.encode(message.exclusive, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.territories) {
      writer.uint32(82).string(v!);
    }
    if (message.products !== undefined) {
      MCommissionLetterAgreementProducts.encode(message.products, writer.uint32(90).fork()).ldelim();
    }
    if (message.term !== 0) {
      writer.uint32(101).float(message.term);
    }
    if (message.commissionPercentage !== 0) {
      writer.uint32(109).float(message.commissionPercentage);
    }
    if (message.commissionTail !== undefined) {
      MCommissionLetterAgreementCommissionTail.encode(message.commissionTail, writer.uint32(114).fork()).ldelim();
    }
    if (message.commissionPaymentDays !== "") {
      writer.uint32(122).string(message.commissionPaymentDays);
    }
    if (message.referSales !== undefined) {
      MCommissionLetterAgreementReferSales.encode(message.referSales, writer.uint32(130).fork()).ldelim();
    }
    if (message.commissionTailMonths !== 0) {
      writer.uint32(141).float(message.commissionTailMonths);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommissionLetterAgreement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommissionLetterAgreement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 3:
          if (tag != 29) {
            break;
          }

          message.causeTermNotice = reader.float();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.agentCompanyFull = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.agentAddressOther = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.agentAddress = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.agentEmail = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.agentName = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.exclusive = MCommissionLetterAgreementExclusive.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.territories.push(reader.string());
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.products = MCommissionLetterAgreementProducts.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.term = reader.float();
          continue;
        case 13:
          if (tag != 109) {
            break;
          }

          message.commissionPercentage = reader.float();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.commissionTail = MCommissionLetterAgreementCommissionTail.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.commissionPaymentDays = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.referSales = MCommissionLetterAgreementReferSales.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag != 141) {
            break;
          }

          message.commissionTailMonths = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommissionLetterAgreement {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      causeTermNotice: isSet(object.causeTermNotice) ? Number(object.causeTermNotice) : 0,
      agentCompanyFull: isSet(object.agentCompanyFull) ? String(object.agentCompanyFull) : "",
      agentAddressOther: isSet(object.agentAddressOther) ? String(object.agentAddressOther) : "",
      agentAddress: isSet(object.agentAddress) ? String(object.agentAddress) : "",
      agentEmail: isSet(object.agentEmail) ? String(object.agentEmail) : "",
      agentName: isSet(object.agentName) ? String(object.agentName) : "",
      exclusive: isSet(object.exclusive) ? MCommissionLetterAgreementExclusive.fromJSON(object.exclusive) : undefined,
      territories: Array.isArray(object?.territories) ? object.territories.map((e: any) => String(e)) : [],
      products: isSet(object.products) ? MCommissionLetterAgreementProducts.fromJSON(object.products) : undefined,
      term: isSet(object.term) ? Number(object.term) : 0,
      commissionPercentage: isSet(object.commissionPercentage) ? Number(object.commissionPercentage) : 0,
      commissionTail: isSet(object.commissionTail)
        ? MCommissionLetterAgreementCommissionTail.fromJSON(object.commissionTail)
        : undefined,
      commissionPaymentDays: isSet(object.commissionPaymentDays) ? String(object.commissionPaymentDays) : "",
      referSales: isSet(object.referSales)
        ? MCommissionLetterAgreementReferSales.fromJSON(object.referSales)
        : undefined,
      commissionTailMonths: isSet(object.commissionTailMonths) ? Number(object.commissionTailMonths) : 0,
    };
  },

  toJSON(message: CommissionLetterAgreement): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.causeTermNotice !== undefined && (obj.causeTermNotice = message.causeTermNotice);
    message.agentCompanyFull !== undefined && (obj.agentCompanyFull = message.agentCompanyFull);
    message.agentAddressOther !== undefined && (obj.agentAddressOther = message.agentAddressOther);
    message.agentAddress !== undefined && (obj.agentAddress = message.agentAddress);
    message.agentEmail !== undefined && (obj.agentEmail = message.agentEmail);
    message.agentName !== undefined && (obj.agentName = message.agentName);
    message.exclusive !== undefined &&
      (obj.exclusive = message.exclusive ? MCommissionLetterAgreementExclusive.toJSON(message.exclusive) : undefined);
    if (message.territories) {
      obj.territories = message.territories.map((e) => e);
    } else {
      obj.territories = [];
    }
    message.products !== undefined &&
      (obj.products = message.products ? MCommissionLetterAgreementProducts.toJSON(message.products) : undefined);
    message.term !== undefined && (obj.term = message.term);
    message.commissionPercentage !== undefined && (obj.commissionPercentage = message.commissionPercentage);
    message.commissionTail !== undefined && (obj.commissionTail = message.commissionTail
      ? MCommissionLetterAgreementCommissionTail.toJSON(message.commissionTail)
      : undefined);
    message.commissionPaymentDays !== undefined && (obj.commissionPaymentDays = message.commissionPaymentDays);
    message.referSales !== undefined &&
      (obj.referSales = message.referSales
        ? MCommissionLetterAgreementReferSales.toJSON(message.referSales)
        : undefined);
    message.commissionTailMonths !== undefined && (obj.commissionTailMonths = message.commissionTailMonths);
    return obj;
  },

  create<I extends Exact<DeepPartial<CommissionLetterAgreement>, I>>(base?: I): CommissionLetterAgreement {
    return CommissionLetterAgreement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CommissionLetterAgreement>, I>>(object: I): CommissionLetterAgreement {
    const message = createBaseCommissionLetterAgreement();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.governLawState = object.governLawState ?? "";
    message.causeTermNotice = object.causeTermNotice ?? 0;
    message.agentCompanyFull = object.agentCompanyFull ?? "";
    message.agentAddressOther = object.agentAddressOther ?? "";
    message.agentAddress = object.agentAddress ?? "";
    message.agentEmail = object.agentEmail ?? "";
    message.agentName = object.agentName ?? "";
    message.exclusive = (object.exclusive !== undefined && object.exclusive !== null)
      ? MCommissionLetterAgreementExclusive.fromPartial(object.exclusive)
      : undefined;
    message.territories = object.territories?.map((e) => e) || [];
    message.products = (object.products !== undefined && object.products !== null)
      ? MCommissionLetterAgreementProducts.fromPartial(object.products)
      : undefined;
    message.term = object.term ?? 0;
    message.commissionPercentage = object.commissionPercentage ?? 0;
    message.commissionTail = (object.commissionTail !== undefined && object.commissionTail !== null)
      ? MCommissionLetterAgreementCommissionTail.fromPartial(object.commissionTail)
      : undefined;
    message.commissionPaymentDays = object.commissionPaymentDays ?? "";
    message.referSales = (object.referSales !== undefined && object.referSales !== null)
      ? MCommissionLetterAgreementReferSales.fromPartial(object.referSales)
      : undefined;
    message.commissionTailMonths = object.commissionTailMonths ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
