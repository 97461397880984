import { AxiosResponse, AxiosError } from "axios";
import { PlanType } from "components/life-cycle/payment/PaymentPlans";
import { reportErrorToBE } from "utils";
import { PWixCheckoutUrlRequest, PWixCheckoutUrlResponse, PWixInstallAppRequest } from "../proto/PApi";
import { request } from "./ApiCommon";

// when user register after app install
export async function wixRegisterAppInstall(req: PWixInstallAppRequest): Promise<{ success: boolean }> {
    try {
        const response = await request("/api/v1/wix/install", {
            method: "POST",
            data: PWixInstallAppRequest.toJSON(req),
        });
        return { success: true };
    } catch (error) {
        reportErrorToBE({ methodName: "wixRegisterAppInstall", error });

        return {
            success: false,
        };
    }
}

export async function wixGetCheckoutUrl(req: { cycle: PlanType; email?: string }): Promise<PWixCheckoutUrlResponse> {
    const fallbackUrl = fallbackUrls[req.cycle] + encodeURIComponent(req?.email || "");

    try {
        const response = await request(`/api/v1/wix/checkout?cycle=${req.cycle}`, {
            method: "GET",
        });

        if (response?.status !== 200) {
            return {
                checkoutUrl: fallbackUrl,
            };
        }
        return PWixCheckoutUrlResponse.fromJSON(response?.data);
    } catch (error) {
        reportErrorToBE({ methodName: "wixGetCheckoutUrl", error });
        return {
            checkoutUrl: fallbackUrl,
        };
    }
}

const fallbackUrls = {
    yearly: "https://store.payproglobal.com/checkout?products[1][id]=82706&page-template=15553&billing-country=US&exfo=742&billing-email=",
    monthly:
        "https://store.payproglobal.com/checkout?products[1][id]=82705&page-template=15553&billing-country=US&exfo=742&billing-email=",

    // same as monthly
    quarterly:
        "https://store.payproglobal.com/checkout?products[1][id]=82705&page-template=15553&billing-country=US&exfo=742&billing-email=",
} as const;
