import { PBehaviourRequest } from "proto/PApi";
import { request } from "./ApiCommon";

export interface AppEvent {
    type: "DOC_SEARCH" | "FREE_TEXT_EDIT";
    payload: string;
}

async function track(event: AppEvent) {
    const { type, payload } = event;
    let data: PBehaviourRequest = { op: type, details: payload, extraKeyValuePairs: [] };
    return await request("/api/v1/behaviors", {
        method: "POST",
        data: data,
    });
}

export const behaviorsApi = { track };
