/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { PContractReviewResult } from "./PGPT";
import { Advisory } from "./contracts/auto-contracts/Advisory";
import { Affiliate } from "./contracts/auto-contracts/Affiliate";
import { AnnualReview2 } from "./contracts/auto-contracts/AnnualReview2";
import { BusinessPromissoryNote } from "./contracts/auto-contracts/BusinessPromissoryNote";
import { CDContractBreach } from "./contracts/auto-contracts/CD_ContractBreach";
import { CDCopyrightInfringement } from "./contracts/auto-contracts/CD_CopyrightInfringement";
import { CDTrademarkInfringement } from "./contracts/auto-contracts/CD_TrademarkInfringement";
import { CommissionLetterAgreement } from "./contracts/auto-contracts/CommissionLetterAgreement";
import { ConflictOfInterestEmployee } from "./contracts/auto-contracts/ConflictOfInterestEmployee";
import { ConsultingMulti } from "./contracts/auto-contracts/ConsultingMulti";
import { ConvertibleNotePurchaseAgreement } from "./contracts/auto-contracts/ConvertibleNotePurchaseAgreement";
import { ConvertiblePromissoryNote } from "./contracts/auto-contracts/ConvertiblePromissoryNote";
import { DemandForEmployeeCure } from "./contracts/auto-contracts/DemandForEmployeeCure";
import { DemandLetter } from "./contracts/auto-contracts/DemandLetter";
import { EmploymentNY } from "./contracts/auto-contracts/EmploymentNY";
import { FullResignationLetter } from "./contracts/auto-contracts/FullResignationLetter";
import { HebExample } from "./contracts/auto-contracts/HebExample";
import { IndependentContractor } from "./contracts/auto-contracts/IndependentContractor";
import { InfluencerAgreement } from "./contracts/auto-contracts/InfluencerAgreement";
import { JointTermsOfUseSaaS } from "./contracts/auto-contracts/JointTermsOfUseSaaS";
import { JointTermsPhysical } from "./contracts/auto-contracts/JointTermsPhysical";
import { MutualNDA } from "./contracts/auto-contracts/MutualNDA";
import { NotePurchaseAgreement } from "./contracts/auto-contracts/NotePurchaseAgreement";
import { NotePurchaseAgreementShortForm } from "./contracts/auto-contracts/NotePurchaseAgreementShortForm";
import { NoticeOfTermination } from "./contracts/auto-contracts/NoticeOfTermination";
import { OfferLetterEmployment } from "./contracts/auto-contracts/OfferLetterEmployment";
import { PhotoRelease } from "./contracts/auto-contracts/PhotoRelease";
import { PrivacyPolicy } from "./contracts/auto-contracts/PrivacyPolicy";
import { ProposalOfServices } from "./contracts/auto-contracts/ProposalOfServices";
import { Referral } from "./contracts/auto-contracts/Referral";
import { SAFE } from "./contracts/auto-contracts/SAFE";
import { SaasSalesAgreement } from "./contracts/auto-contracts/SaasSalesAgreement";
import { SaasSalesAgreementV2 } from "./contracts/auto-contracts/SaasSalesAgreement_V2";
import { SingleMemberLLCAgreement } from "./contracts/auto-contracts/SingleMemberLLCAgreement";
import { TermsOfUse } from "./contracts/auto-contracts/TermsOfUse";
import { TermsheetConvertiblePromissoryNote } from "./contracts/auto-contracts/TermsheetConvertiblePromissoryNote";
import { UnilateralNDA } from "./contracts/auto-contracts/UnilateralNDA";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export enum UploadFileStatus {
  NEW = 0,
  BEING_ANALYZED = 1,
  ANALYZED = 2,
  UNRECOGNIZED = -1,
}

export function uploadFileStatusFromJSON(object: any): UploadFileStatus {
  switch (object) {
    case 0:
    case "NEW":
      return UploadFileStatus.NEW;
    case 1:
    case "BEING_ANALYZED":
      return UploadFileStatus.BEING_ANALYZED;
    case 2:
    case "ANALYZED":
      return UploadFileStatus.ANALYZED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UploadFileStatus.UNRECOGNIZED;
  }
}

export function uploadFileStatusToJSON(object: UploadFileStatus): string {
  switch (object) {
    case UploadFileStatus.NEW:
      return "NEW";
    case UploadFileStatus.BEING_ANALYZED:
      return "BEING_ANALYZED";
    case UploadFileStatus.ANALYZED:
      return "ANALYZED";
    case UploadFileStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DynamicTable {
  rows: Row[];
  columns: Column[];
  cells: DynamicCell[];
}

export interface Row {
  label: string;
}

export interface Column {
  label: string;
}

export interface DynamicCell {
  value: string;
}

export interface PLegalUnit {
  kind: string;
  docVersion: string;
  freestyleSrc: string;
  safe: SAFE | undefined;
  privacyPolicy: PrivacyPolicy | undefined;
  independentContractor: IndependentContractor | undefined;
  consultingMulti: ConsultingMulti | undefined;
  mutualNDA: MutualNDA | undefined;
  oneWayNDA: UnilateralNDA | undefined;
  employmentNY: EmploymentNY | undefined;
  employmentOfferLetter: OfferLetterEmployment | undefined;
  saasSalesAgreement: SaasSalesAgreement | undefined;
  saasSalesAgreementV2: SaasSalesAgreementV2 | undefined;
  advisoryAgreement: Advisory | undefined;
  termsOfUse: TermsOfUse | undefined;
  proposalOfServices: ProposalOfServices | undefined;
  jointTermsPhysical: JointTermsPhysical | undefined;
  jointTermsSaaS: JointTermsOfUseSaaS | undefined;
  referralAgreement: Referral | undefined;
  copyrightInfringement: CDCopyrightInfringement | undefined;
  trademarkInfringement: CDTrademarkInfringement | undefined;
  demandLetter: DemandLetter | undefined;
  photoRelease: PhotoRelease | undefined;
  contractBreach: CDContractBreach | undefined;
  influencerAgreement: InfluencerAgreement | undefined;
  resignationLetter: FullResignationLetter | undefined;
  noticeOfTermination: NoticeOfTermination | undefined;
  affiliateAgreement: Affiliate | undefined;
  commissionLetter: CommissionLetterAgreement | undefined;
  demandForEmployeeCure: DemandForEmployeeCure | undefined;
  annualEmployeeReview: AnnualReview2 | undefined;
  singleMemberLLCAgreement: SingleMemberLLCAgreement | undefined;
  uploadedFile: UploadedFile | undefined;
  employeeConflict: ConflictOfInterestEmployee | undefined;
  convertibleNotePurchaseAgreement: ConvertibleNotePurchaseAgreement | undefined;
  convertiblePromissoryNote: ConvertiblePromissoryNote | undefined;
  termsheetConvertiblePromissoryNote: TermsheetConvertiblePromissoryNote | undefined;
  businessPromissoryNote: BusinessPromissoryNote | undefined;
  notePurchaseAgreement: NotePurchaseAgreement | undefined;
  notePurchaseAgreementShortForm: NotePurchaseAgreementShortForm | undefined;
  hebExample: HebExample | undefined;
  createdAt: Date | undefined;
  name: string;
  lastStep: string;
  wasAddressSet: boolean;
  version: number;
  progress: number;
}

export interface UploadedFile {
  fileData: Uint8Array;
  fileType: string;
  fileStatus: UploadFileStatus;
  reviewResult: PContractReviewResult | undefined;
}

function createBaseDynamicTable(): DynamicTable {
  return { rows: [], columns: [], cells: [] };
}

export const DynamicTable = {
  encode(message: DynamicTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rows) {
      Row.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.cells) {
      DynamicCell.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynamicTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynamicTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.rows.push(Row.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.cells.push(DynamicCell.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynamicTable {
    return {
      rows: Array.isArray(object?.rows) ? object.rows.map((e: any) => Row.fromJSON(e)) : [],
      columns: Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      cells: Array.isArray(object?.cells) ? object.cells.map((e: any) => DynamicCell.fromJSON(e)) : [],
    };
  },

  toJSON(message: DynamicTable): unknown {
    const obj: any = {};
    if (message.rows) {
      obj.rows = message.rows.map((e) => e ? Row.toJSON(e) : undefined);
    } else {
      obj.rows = [];
    }
    if (message.columns) {
      obj.columns = message.columns.map((e) => e ? Column.toJSON(e) : undefined);
    } else {
      obj.columns = [];
    }
    if (message.cells) {
      obj.cells = message.cells.map((e) => e ? DynamicCell.toJSON(e) : undefined);
    } else {
      obj.cells = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DynamicTable>, I>>(base?: I): DynamicTable {
    return DynamicTable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynamicTable>, I>>(object: I): DynamicTable {
    const message = createBaseDynamicTable();
    message.rows = object.rows?.map((e) => Row.fromPartial(e)) || [];
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.cells = object.cells?.map((e) => DynamicCell.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRow(): Row {
  return { label: "" };
}

export const Row = {
  encode(message: Row, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Row {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.label = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Row {
    return { label: isSet(object.label) ? String(object.label) : "" };
  },

  toJSON(message: Row): unknown {
    const obj: any = {};
    message.label !== undefined && (obj.label = message.label);
    return obj;
  },

  create<I extends Exact<DeepPartial<Row>, I>>(base?: I): Row {
    return Row.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Row>, I>>(object: I): Row {
    const message = createBaseRow();
    message.label = object.label ?? "";
    return message;
  },
};

function createBaseColumn(): Column {
  return { label: "" };
}

export const Column = {
  encode(message: Column, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Column {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseColumn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.label = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Column {
    return { label: isSet(object.label) ? String(object.label) : "" };
  },

  toJSON(message: Column): unknown {
    const obj: any = {};
    message.label !== undefined && (obj.label = message.label);
    return obj;
  },

  create<I extends Exact<DeepPartial<Column>, I>>(base?: I): Column {
    return Column.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Column>, I>>(object: I): Column {
    const message = createBaseColumn();
    message.label = object.label ?? "";
    return message;
  },
};

function createBaseDynamicCell(): DynamicCell {
  return { value: "" };
}

export const DynamicCell = {
  encode(message: DynamicCell, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DynamicCell {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDynamicCell();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DynamicCell {
    return { value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: DynamicCell): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<DynamicCell>, I>>(base?: I): DynamicCell {
    return DynamicCell.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DynamicCell>, I>>(object: I): DynamicCell {
    const message = createBaseDynamicCell();
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePLegalUnit(): PLegalUnit {
  return {
    kind: "",
    docVersion: "",
    freestyleSrc: "",
    safe: undefined,
    privacyPolicy: undefined,
    independentContractor: undefined,
    consultingMulti: undefined,
    mutualNDA: undefined,
    oneWayNDA: undefined,
    employmentNY: undefined,
    employmentOfferLetter: undefined,
    saasSalesAgreement: undefined,
    saasSalesAgreementV2: undefined,
    advisoryAgreement: undefined,
    termsOfUse: undefined,
    proposalOfServices: undefined,
    jointTermsPhysical: undefined,
    jointTermsSaaS: undefined,
    referralAgreement: undefined,
    copyrightInfringement: undefined,
    trademarkInfringement: undefined,
    demandLetter: undefined,
    photoRelease: undefined,
    contractBreach: undefined,
    influencerAgreement: undefined,
    resignationLetter: undefined,
    noticeOfTermination: undefined,
    affiliateAgreement: undefined,
    commissionLetter: undefined,
    demandForEmployeeCure: undefined,
    annualEmployeeReview: undefined,
    singleMemberLLCAgreement: undefined,
    uploadedFile: undefined,
    employeeConflict: undefined,
    convertibleNotePurchaseAgreement: undefined,
    convertiblePromissoryNote: undefined,
    termsheetConvertiblePromissoryNote: undefined,
    businessPromissoryNote: undefined,
    notePurchaseAgreement: undefined,
    notePurchaseAgreementShortForm: undefined,
    hebExample: undefined,
    createdAt: undefined,
    name: "",
    lastStep: "",
    wasAddressSet: false,
    version: 0,
    progress: 0,
  };
}

export const PLegalUnit = {
  encode(message: PLegalUnit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kind !== "") {
      writer.uint32(10).string(message.kind);
    }
    if (message.docVersion !== "") {
      writer.uint32(114).string(message.docVersion);
    }
    if (message.freestyleSrc !== "") {
      writer.uint32(7994).string(message.freestyleSrc);
    }
    if (message.safe !== undefined) {
      SAFE.encode(message.safe, writer.uint32(74).fork()).ldelim();
    }
    if (message.privacyPolicy !== undefined) {
      PrivacyPolicy.encode(message.privacyPolicy, writer.uint32(146).fork()).ldelim();
    }
    if (message.independentContractor !== undefined) {
      IndependentContractor.encode(message.independentContractor, writer.uint32(154).fork()).ldelim();
    }
    if (message.consultingMulti !== undefined) {
      ConsultingMulti.encode(message.consultingMulti, writer.uint32(306).fork()).ldelim();
    }
    if (message.mutualNDA !== undefined) {
      MutualNDA.encode(message.mutualNDA, writer.uint32(162).fork()).ldelim();
    }
    if (message.oneWayNDA !== undefined) {
      UnilateralNDA.encode(message.oneWayNDA, writer.uint32(378).fork()).ldelim();
    }
    if (message.employmentNY !== undefined) {
      EmploymentNY.encode(message.employmentNY, writer.uint32(170).fork()).ldelim();
    }
    if (message.employmentOfferLetter !== undefined) {
      OfferLetterEmployment.encode(message.employmentOfferLetter, writer.uint32(338).fork()).ldelim();
    }
    if (message.saasSalesAgreement !== undefined) {
      SaasSalesAgreement.encode(message.saasSalesAgreement, writer.uint32(178).fork()).ldelim();
    }
    if (message.saasSalesAgreementV2 !== undefined) {
      SaasSalesAgreementV2.encode(message.saasSalesAgreementV2, writer.uint32(314).fork()).ldelim();
    }
    if (message.advisoryAgreement !== undefined) {
      Advisory.encode(message.advisoryAgreement, writer.uint32(186).fork()).ldelim();
    }
    if (message.termsOfUse !== undefined) {
      TermsOfUse.encode(message.termsOfUse, writer.uint32(130).fork()).ldelim();
    }
    if (message.proposalOfServices !== undefined) {
      ProposalOfServices.encode(message.proposalOfServices, writer.uint32(194).fork()).ldelim();
    }
    if (message.jointTermsPhysical !== undefined) {
      JointTermsPhysical.encode(message.jointTermsPhysical, writer.uint32(202).fork()).ldelim();
    }
    if (message.jointTermsSaaS !== undefined) {
      JointTermsOfUseSaaS.encode(message.jointTermsSaaS, writer.uint32(258).fork()).ldelim();
    }
    if (message.referralAgreement !== undefined) {
      Referral.encode(message.referralAgreement, writer.uint32(266).fork()).ldelim();
    }
    if (message.copyrightInfringement !== undefined) {
      CDCopyrightInfringement.encode(message.copyrightInfringement, writer.uint32(210).fork()).ldelim();
    }
    if (message.trademarkInfringement !== undefined) {
      CDTrademarkInfringement.encode(message.trademarkInfringement, writer.uint32(218).fork()).ldelim();
    }
    if (message.demandLetter !== undefined) {
      DemandLetter.encode(message.demandLetter, writer.uint32(226).fork()).ldelim();
    }
    if (message.photoRelease !== undefined) {
      PhotoRelease.encode(message.photoRelease, writer.uint32(234).fork()).ldelim();
    }
    if (message.contractBreach !== undefined) {
      CDContractBreach.encode(message.contractBreach, writer.uint32(242).fork()).ldelim();
    }
    if (message.influencerAgreement !== undefined) {
      InfluencerAgreement.encode(message.influencerAgreement, writer.uint32(250).fork()).ldelim();
    }
    if (message.resignationLetter !== undefined) {
      FullResignationLetter.encode(message.resignationLetter, writer.uint32(274).fork()).ldelim();
    }
    if (message.noticeOfTermination !== undefined) {
      NoticeOfTermination.encode(message.noticeOfTermination, writer.uint32(282).fork()).ldelim();
    }
    if (message.affiliateAgreement !== undefined) {
      Affiliate.encode(message.affiliateAgreement, writer.uint32(290).fork()).ldelim();
    }
    if (message.commissionLetter !== undefined) {
      CommissionLetterAgreement.encode(message.commissionLetter, writer.uint32(298).fork()).ldelim();
    }
    if (message.demandForEmployeeCure !== undefined) {
      DemandForEmployeeCure.encode(message.demandForEmployeeCure, writer.uint32(346).fork()).ldelim();
    }
    if (message.annualEmployeeReview !== undefined) {
      AnnualReview2.encode(message.annualEmployeeReview, writer.uint32(354).fork()).ldelim();
    }
    if (message.singleMemberLLCAgreement !== undefined) {
      SingleMemberLLCAgreement.encode(message.singleMemberLLCAgreement, writer.uint32(362).fork()).ldelim();
    }
    if (message.uploadedFile !== undefined) {
      UploadedFile.encode(message.uploadedFile, writer.uint32(370).fork()).ldelim();
    }
    if (message.employeeConflict !== undefined) {
      ConflictOfInterestEmployee.encode(message.employeeConflict, writer.uint32(386).fork()).ldelim();
    }
    if (message.convertibleNotePurchaseAgreement !== undefined) {
      ConvertibleNotePurchaseAgreement.encode(message.convertibleNotePurchaseAgreement, writer.uint32(402).fork())
        .ldelim();
    }
    if (message.convertiblePromissoryNote !== undefined) {
      ConvertiblePromissoryNote.encode(message.convertiblePromissoryNote, writer.uint32(322).fork()).ldelim();
    }
    if (message.termsheetConvertiblePromissoryNote !== undefined) {
      TermsheetConvertiblePromissoryNote.encode(message.termsheetConvertiblePromissoryNote, writer.uint32(330).fork())
        .ldelim();
    }
    if (message.businessPromissoryNote !== undefined) {
      BusinessPromissoryNote.encode(message.businessPromissoryNote, writer.uint32(394).fork()).ldelim();
    }
    if (message.notePurchaseAgreement !== undefined) {
      NotePurchaseAgreement.encode(message.notePurchaseAgreement, writer.uint32(410).fork()).ldelim();
    }
    if (message.notePurchaseAgreementShortForm !== undefined) {
      NotePurchaseAgreementShortForm.encode(message.notePurchaseAgreementShortForm, writer.uint32(418).fork()).ldelim();
    }
    if (message.hebExample !== undefined) {
      HebExample.encode(message.hebExample, writer.uint32(426).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.lastStep !== "") {
      writer.uint32(58).string(message.lastStep);
    }
    if (message.wasAddressSet === true) {
      writer.uint32(80).bool(message.wasAddressSet);
    }
    if (message.version !== 0) {
      writer.uint32(88).uint32(message.version);
    }
    if (message.progress !== 0) {
      writer.uint32(120).uint32(message.progress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PLegalUnit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePLegalUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.kind = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.docVersion = reader.string();
          continue;
        case 999:
          if (tag != 7994) {
            break;
          }

          message.freestyleSrc = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.safe = SAFE.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.privacyPolicy = PrivacyPolicy.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.independentContractor = IndependentContractor.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.consultingMulti = ConsultingMulti.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.mutualNDA = MutualNDA.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag != 378) {
            break;
          }

          message.oneWayNDA = UnilateralNDA.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.employmentNY = EmploymentNY.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.employmentOfferLetter = OfferLetterEmployment.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.saasSalesAgreement = SaasSalesAgreement.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.saasSalesAgreementV2 = SaasSalesAgreementV2.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.advisoryAgreement = Advisory.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.termsOfUse = TermsOfUse.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.proposalOfServices = ProposalOfServices.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.jointTermsPhysical = JointTermsPhysical.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.jointTermsSaaS = JointTermsOfUseSaaS.decode(reader, reader.uint32());
          continue;
        case 33:
          if (tag != 266) {
            break;
          }

          message.referralAgreement = Referral.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.copyrightInfringement = CDCopyrightInfringement.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.trademarkInfringement = CDTrademarkInfringement.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.demandLetter = DemandLetter.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.photoRelease = PhotoRelease.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.contractBreach = CDContractBreach.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.influencerAgreement = InfluencerAgreement.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.resignationLetter = FullResignationLetter.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.noticeOfTermination = NoticeOfTermination.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.affiliateAgreement = Affiliate.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.commissionLetter = CommissionLetterAgreement.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag != 346) {
            break;
          }

          message.demandForEmployeeCure = DemandForEmployeeCure.decode(reader, reader.uint32());
          continue;
        case 44:
          if (tag != 354) {
            break;
          }

          message.annualEmployeeReview = AnnualReview2.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag != 362) {
            break;
          }

          message.singleMemberLLCAgreement = SingleMemberLLCAgreement.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag != 370) {
            break;
          }

          message.uploadedFile = UploadedFile.decode(reader, reader.uint32());
          continue;
        case 48:
          if (tag != 386) {
            break;
          }

          message.employeeConflict = ConflictOfInterestEmployee.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag != 402) {
            break;
          }

          message.convertibleNotePurchaseAgreement = ConvertibleNotePurchaseAgreement.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.convertiblePromissoryNote = ConvertiblePromissoryNote.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.termsheetConvertiblePromissoryNote = TermsheetConvertiblePromissoryNote.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.businessPromissoryNote = BusinessPromissoryNote.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag != 410) {
            break;
          }

          message.notePurchaseAgreement = NotePurchaseAgreement.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag != 418) {
            break;
          }

          message.notePurchaseAgreementShortForm = NotePurchaseAgreementShortForm.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag != 426) {
            break;
          }

          message.hebExample = HebExample.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.lastStep = reader.string();
          continue;
        case 10:
          if (tag != 80) {
            break;
          }

          message.wasAddressSet = reader.bool();
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.version = reader.uint32();
          continue;
        case 15:
          if (tag != 120) {
            break;
          }

          message.progress = reader.uint32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PLegalUnit {
    return {
      kind: isSet(object.kind) ? String(object.kind) : "",
      docVersion: isSet(object.docVersion) ? String(object.docVersion) : "",
      freestyleSrc: isSet(object.freestyleSrc) ? String(object.freestyleSrc) : "",
      safe: isSet(object.safe) ? SAFE.fromJSON(object.safe) : undefined,
      privacyPolicy: isSet(object.privacyPolicy) ? PrivacyPolicy.fromJSON(object.privacyPolicy) : undefined,
      independentContractor: isSet(object.independentContractor)
        ? IndependentContractor.fromJSON(object.independentContractor)
        : undefined,
      consultingMulti: isSet(object.consultingMulti) ? ConsultingMulti.fromJSON(object.consultingMulti) : undefined,
      mutualNDA: isSet(object.mutualNDA) ? MutualNDA.fromJSON(object.mutualNDA) : undefined,
      oneWayNDA: isSet(object.oneWayNDA) ? UnilateralNDA.fromJSON(object.oneWayNDA) : undefined,
      employmentNY: isSet(object.employmentNY) ? EmploymentNY.fromJSON(object.employmentNY) : undefined,
      employmentOfferLetter: isSet(object.employmentOfferLetter)
        ? OfferLetterEmployment.fromJSON(object.employmentOfferLetter)
        : undefined,
      saasSalesAgreement: isSet(object.saasSalesAgreement)
        ? SaasSalesAgreement.fromJSON(object.saasSalesAgreement)
        : undefined,
      saasSalesAgreementV2: isSet(object.saasSalesAgreementV2)
        ? SaasSalesAgreementV2.fromJSON(object.saasSalesAgreementV2)
        : undefined,
      advisoryAgreement: isSet(object.advisoryAgreement) ? Advisory.fromJSON(object.advisoryAgreement) : undefined,
      termsOfUse: isSet(object.termsOfUse) ? TermsOfUse.fromJSON(object.termsOfUse) : undefined,
      proposalOfServices: isSet(object.proposalOfServices)
        ? ProposalOfServices.fromJSON(object.proposalOfServices)
        : undefined,
      jointTermsPhysical: isSet(object.jointTermsPhysical)
        ? JointTermsPhysical.fromJSON(object.jointTermsPhysical)
        : undefined,
      jointTermsSaaS: isSet(object.jointTermsSaaS) ? JointTermsOfUseSaaS.fromJSON(object.jointTermsSaaS) : undefined,
      referralAgreement: isSet(object.referralAgreement) ? Referral.fromJSON(object.referralAgreement) : undefined,
      copyrightInfringement: isSet(object.copyrightInfringement)
        ? CDCopyrightInfringement.fromJSON(object.copyrightInfringement)
        : undefined,
      trademarkInfringement: isSet(object.trademarkInfringement)
        ? CDTrademarkInfringement.fromJSON(object.trademarkInfringement)
        : undefined,
      demandLetter: isSet(object.demandLetter) ? DemandLetter.fromJSON(object.demandLetter) : undefined,
      photoRelease: isSet(object.photoRelease) ? PhotoRelease.fromJSON(object.photoRelease) : undefined,
      contractBreach: isSet(object.contractBreach) ? CDContractBreach.fromJSON(object.contractBreach) : undefined,
      influencerAgreement: isSet(object.influencerAgreement)
        ? InfluencerAgreement.fromJSON(object.influencerAgreement)
        : undefined,
      resignationLetter: isSet(object.resignationLetter)
        ? FullResignationLetter.fromJSON(object.resignationLetter)
        : undefined,
      noticeOfTermination: isSet(object.noticeOfTermination)
        ? NoticeOfTermination.fromJSON(object.noticeOfTermination)
        : undefined,
      affiliateAgreement: isSet(object.affiliateAgreement) ? Affiliate.fromJSON(object.affiliateAgreement) : undefined,
      commissionLetter: isSet(object.commissionLetter)
        ? CommissionLetterAgreement.fromJSON(object.commissionLetter)
        : undefined,
      demandForEmployeeCure: isSet(object.demandForEmployeeCure)
        ? DemandForEmployeeCure.fromJSON(object.demandForEmployeeCure)
        : undefined,
      annualEmployeeReview: isSet(object.annualEmployeeReview)
        ? AnnualReview2.fromJSON(object.annualEmployeeReview)
        : undefined,
      singleMemberLLCAgreement: isSet(object.singleMemberLLCAgreement)
        ? SingleMemberLLCAgreement.fromJSON(object.singleMemberLLCAgreement)
        : undefined,
      uploadedFile: isSet(object.uploadedFile) ? UploadedFile.fromJSON(object.uploadedFile) : undefined,
      employeeConflict: isSet(object.employeeConflict)
        ? ConflictOfInterestEmployee.fromJSON(object.employeeConflict)
        : undefined,
      convertibleNotePurchaseAgreement: isSet(object.convertibleNotePurchaseAgreement)
        ? ConvertibleNotePurchaseAgreement.fromJSON(object.convertibleNotePurchaseAgreement)
        : undefined,
      convertiblePromissoryNote: isSet(object.convertiblePromissoryNote)
        ? ConvertiblePromissoryNote.fromJSON(object.convertiblePromissoryNote)
        : undefined,
      termsheetConvertiblePromissoryNote: isSet(object.termsheetConvertiblePromissoryNote)
        ? TermsheetConvertiblePromissoryNote.fromJSON(object.termsheetConvertiblePromissoryNote)
        : undefined,
      businessPromissoryNote: isSet(object.businessPromissoryNote)
        ? BusinessPromissoryNote.fromJSON(object.businessPromissoryNote)
        : undefined,
      notePurchaseAgreement: isSet(object.notePurchaseAgreement)
        ? NotePurchaseAgreement.fromJSON(object.notePurchaseAgreement)
        : undefined,
      notePurchaseAgreementShortForm: isSet(object.notePurchaseAgreementShortForm)
        ? NotePurchaseAgreementShortForm.fromJSON(object.notePurchaseAgreementShortForm)
        : undefined,
      hebExample: isSet(object.hebExample) ? HebExample.fromJSON(object.hebExample) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      name: isSet(object.name) ? String(object.name) : "",
      lastStep: isSet(object.lastStep) ? String(object.lastStep) : "",
      wasAddressSet: isSet(object.wasAddressSet) ? Boolean(object.wasAddressSet) : false,
      version: isSet(object.version) ? Number(object.version) : 0,
      progress: isSet(object.progress) ? Number(object.progress) : 0,
    };
  },

  toJSON(message: PLegalUnit): unknown {
    const obj: any = {};
    message.kind !== undefined && (obj.kind = message.kind);
    message.docVersion !== undefined && (obj.docVersion = message.docVersion);
    message.freestyleSrc !== undefined && (obj.freestyleSrc = message.freestyleSrc);
    message.safe !== undefined && (obj.safe = message.safe ? SAFE.toJSON(message.safe) : undefined);
    message.privacyPolicy !== undefined &&
      (obj.privacyPolicy = message.privacyPolicy ? PrivacyPolicy.toJSON(message.privacyPolicy) : undefined);
    message.independentContractor !== undefined && (obj.independentContractor = message.independentContractor
      ? IndependentContractor.toJSON(message.independentContractor)
      : undefined);
    message.consultingMulti !== undefined &&
      (obj.consultingMulti = message.consultingMulti ? ConsultingMulti.toJSON(message.consultingMulti) : undefined);
    message.mutualNDA !== undefined &&
      (obj.mutualNDA = message.mutualNDA ? MutualNDA.toJSON(message.mutualNDA) : undefined);
    message.oneWayNDA !== undefined &&
      (obj.oneWayNDA = message.oneWayNDA ? UnilateralNDA.toJSON(message.oneWayNDA) : undefined);
    message.employmentNY !== undefined &&
      (obj.employmentNY = message.employmentNY ? EmploymentNY.toJSON(message.employmentNY) : undefined);
    message.employmentOfferLetter !== undefined && (obj.employmentOfferLetter = message.employmentOfferLetter
      ? OfferLetterEmployment.toJSON(message.employmentOfferLetter)
      : undefined);
    message.saasSalesAgreement !== undefined && (obj.saasSalesAgreement = message.saasSalesAgreement
      ? SaasSalesAgreement.toJSON(message.saasSalesAgreement)
      : undefined);
    message.saasSalesAgreementV2 !== undefined && (obj.saasSalesAgreementV2 = message.saasSalesAgreementV2
      ? SaasSalesAgreementV2.toJSON(message.saasSalesAgreementV2)
      : undefined);
    message.advisoryAgreement !== undefined &&
      (obj.advisoryAgreement = message.advisoryAgreement ? Advisory.toJSON(message.advisoryAgreement) : undefined);
    message.termsOfUse !== undefined &&
      (obj.termsOfUse = message.termsOfUse ? TermsOfUse.toJSON(message.termsOfUse) : undefined);
    message.proposalOfServices !== undefined && (obj.proposalOfServices = message.proposalOfServices
      ? ProposalOfServices.toJSON(message.proposalOfServices)
      : undefined);
    message.jointTermsPhysical !== undefined && (obj.jointTermsPhysical = message.jointTermsPhysical
      ? JointTermsPhysical.toJSON(message.jointTermsPhysical)
      : undefined);
    message.jointTermsSaaS !== undefined &&
      (obj.jointTermsSaaS = message.jointTermsSaaS ? JointTermsOfUseSaaS.toJSON(message.jointTermsSaaS) : undefined);
    message.referralAgreement !== undefined &&
      (obj.referralAgreement = message.referralAgreement ? Referral.toJSON(message.referralAgreement) : undefined);
    message.copyrightInfringement !== undefined && (obj.copyrightInfringement = message.copyrightInfringement
      ? CDCopyrightInfringement.toJSON(message.copyrightInfringement)
      : undefined);
    message.trademarkInfringement !== undefined && (obj.trademarkInfringement = message.trademarkInfringement
      ? CDTrademarkInfringement.toJSON(message.trademarkInfringement)
      : undefined);
    message.demandLetter !== undefined &&
      (obj.demandLetter = message.demandLetter ? DemandLetter.toJSON(message.demandLetter) : undefined);
    message.photoRelease !== undefined &&
      (obj.photoRelease = message.photoRelease ? PhotoRelease.toJSON(message.photoRelease) : undefined);
    message.contractBreach !== undefined &&
      (obj.contractBreach = message.contractBreach ? CDContractBreach.toJSON(message.contractBreach) : undefined);
    message.influencerAgreement !== undefined && (obj.influencerAgreement = message.influencerAgreement
      ? InfluencerAgreement.toJSON(message.influencerAgreement)
      : undefined);
    message.resignationLetter !== undefined && (obj.resignationLetter = message.resignationLetter
      ? FullResignationLetter.toJSON(message.resignationLetter)
      : undefined);
    message.noticeOfTermination !== undefined && (obj.noticeOfTermination = message.noticeOfTermination
      ? NoticeOfTermination.toJSON(message.noticeOfTermination)
      : undefined);
    message.affiliateAgreement !== undefined &&
      (obj.affiliateAgreement = message.affiliateAgreement ? Affiliate.toJSON(message.affiliateAgreement) : undefined);
    message.commissionLetter !== undefined && (obj.commissionLetter = message.commissionLetter
      ? CommissionLetterAgreement.toJSON(message.commissionLetter)
      : undefined);
    message.demandForEmployeeCure !== undefined && (obj.demandForEmployeeCure = message.demandForEmployeeCure
      ? DemandForEmployeeCure.toJSON(message.demandForEmployeeCure)
      : undefined);
    message.annualEmployeeReview !== undefined && (obj.annualEmployeeReview = message.annualEmployeeReview
      ? AnnualReview2.toJSON(message.annualEmployeeReview)
      : undefined);
    message.singleMemberLLCAgreement !== undefined && (obj.singleMemberLLCAgreement = message.singleMemberLLCAgreement
      ? SingleMemberLLCAgreement.toJSON(message.singleMemberLLCAgreement)
      : undefined);
    message.uploadedFile !== undefined &&
      (obj.uploadedFile = message.uploadedFile ? UploadedFile.toJSON(message.uploadedFile) : undefined);
    message.employeeConflict !== undefined && (obj.employeeConflict = message.employeeConflict
      ? ConflictOfInterestEmployee.toJSON(message.employeeConflict)
      : undefined);
    message.convertibleNotePurchaseAgreement !== undefined &&
      (obj.convertibleNotePurchaseAgreement = message.convertibleNotePurchaseAgreement
        ? ConvertibleNotePurchaseAgreement.toJSON(message.convertibleNotePurchaseAgreement)
        : undefined);
    message.convertiblePromissoryNote !== undefined &&
      (obj.convertiblePromissoryNote = message.convertiblePromissoryNote
        ? ConvertiblePromissoryNote.toJSON(message.convertiblePromissoryNote)
        : undefined);
    message.termsheetConvertiblePromissoryNote !== undefined &&
      (obj.termsheetConvertiblePromissoryNote = message.termsheetConvertiblePromissoryNote
        ? TermsheetConvertiblePromissoryNote.toJSON(message.termsheetConvertiblePromissoryNote)
        : undefined);
    message.businessPromissoryNote !== undefined && (obj.businessPromissoryNote = message.businessPromissoryNote
      ? BusinessPromissoryNote.toJSON(message.businessPromissoryNote)
      : undefined);
    message.notePurchaseAgreement !== undefined && (obj.notePurchaseAgreement = message.notePurchaseAgreement
      ? NotePurchaseAgreement.toJSON(message.notePurchaseAgreement)
      : undefined);
    message.notePurchaseAgreementShortForm !== undefined &&
      (obj.notePurchaseAgreementShortForm = message.notePurchaseAgreementShortForm
        ? NotePurchaseAgreementShortForm.toJSON(message.notePurchaseAgreementShortForm)
        : undefined);
    message.hebExample !== undefined &&
      (obj.hebExample = message.hebExample ? HebExample.toJSON(message.hebExample) : undefined);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.name !== undefined && (obj.name = message.name);
    message.lastStep !== undefined && (obj.lastStep = message.lastStep);
    message.wasAddressSet !== undefined && (obj.wasAddressSet = message.wasAddressSet);
    message.version !== undefined && (obj.version = Math.round(message.version));
    message.progress !== undefined && (obj.progress = Math.round(message.progress));
    return obj;
  },

  create<I extends Exact<DeepPartial<PLegalUnit>, I>>(base?: I): PLegalUnit {
    return PLegalUnit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PLegalUnit>, I>>(object: I): PLegalUnit {
    const message = createBasePLegalUnit();
    message.kind = object.kind ?? "";
    message.docVersion = object.docVersion ?? "";
    message.freestyleSrc = object.freestyleSrc ?? "";
    message.safe = (object.safe !== undefined && object.safe !== null) ? SAFE.fromPartial(object.safe) : undefined;
    message.privacyPolicy = (object.privacyPolicy !== undefined && object.privacyPolicy !== null)
      ? PrivacyPolicy.fromPartial(object.privacyPolicy)
      : undefined;
    message.independentContractor =
      (object.independentContractor !== undefined && object.independentContractor !== null)
        ? IndependentContractor.fromPartial(object.independentContractor)
        : undefined;
    message.consultingMulti = (object.consultingMulti !== undefined && object.consultingMulti !== null)
      ? ConsultingMulti.fromPartial(object.consultingMulti)
      : undefined;
    message.mutualNDA = (object.mutualNDA !== undefined && object.mutualNDA !== null)
      ? MutualNDA.fromPartial(object.mutualNDA)
      : undefined;
    message.oneWayNDA = (object.oneWayNDA !== undefined && object.oneWayNDA !== null)
      ? UnilateralNDA.fromPartial(object.oneWayNDA)
      : undefined;
    message.employmentNY = (object.employmentNY !== undefined && object.employmentNY !== null)
      ? EmploymentNY.fromPartial(object.employmentNY)
      : undefined;
    message.employmentOfferLetter =
      (object.employmentOfferLetter !== undefined && object.employmentOfferLetter !== null)
        ? OfferLetterEmployment.fromPartial(object.employmentOfferLetter)
        : undefined;
    message.saasSalesAgreement = (object.saasSalesAgreement !== undefined && object.saasSalesAgreement !== null)
      ? SaasSalesAgreement.fromPartial(object.saasSalesAgreement)
      : undefined;
    message.saasSalesAgreementV2 = (object.saasSalesAgreementV2 !== undefined && object.saasSalesAgreementV2 !== null)
      ? SaasSalesAgreementV2.fromPartial(object.saasSalesAgreementV2)
      : undefined;
    message.advisoryAgreement = (object.advisoryAgreement !== undefined && object.advisoryAgreement !== null)
      ? Advisory.fromPartial(object.advisoryAgreement)
      : undefined;
    message.termsOfUse = (object.termsOfUse !== undefined && object.termsOfUse !== null)
      ? TermsOfUse.fromPartial(object.termsOfUse)
      : undefined;
    message.proposalOfServices = (object.proposalOfServices !== undefined && object.proposalOfServices !== null)
      ? ProposalOfServices.fromPartial(object.proposalOfServices)
      : undefined;
    message.jointTermsPhysical = (object.jointTermsPhysical !== undefined && object.jointTermsPhysical !== null)
      ? JointTermsPhysical.fromPartial(object.jointTermsPhysical)
      : undefined;
    message.jointTermsSaaS = (object.jointTermsSaaS !== undefined && object.jointTermsSaaS !== null)
      ? JointTermsOfUseSaaS.fromPartial(object.jointTermsSaaS)
      : undefined;
    message.referralAgreement = (object.referralAgreement !== undefined && object.referralAgreement !== null)
      ? Referral.fromPartial(object.referralAgreement)
      : undefined;
    message.copyrightInfringement =
      (object.copyrightInfringement !== undefined && object.copyrightInfringement !== null)
        ? CDCopyrightInfringement.fromPartial(object.copyrightInfringement)
        : undefined;
    message.trademarkInfringement =
      (object.trademarkInfringement !== undefined && object.trademarkInfringement !== null)
        ? CDTrademarkInfringement.fromPartial(object.trademarkInfringement)
        : undefined;
    message.demandLetter = (object.demandLetter !== undefined && object.demandLetter !== null)
      ? DemandLetter.fromPartial(object.demandLetter)
      : undefined;
    message.photoRelease = (object.photoRelease !== undefined && object.photoRelease !== null)
      ? PhotoRelease.fromPartial(object.photoRelease)
      : undefined;
    message.contractBreach = (object.contractBreach !== undefined && object.contractBreach !== null)
      ? CDContractBreach.fromPartial(object.contractBreach)
      : undefined;
    message.influencerAgreement = (object.influencerAgreement !== undefined && object.influencerAgreement !== null)
      ? InfluencerAgreement.fromPartial(object.influencerAgreement)
      : undefined;
    message.resignationLetter = (object.resignationLetter !== undefined && object.resignationLetter !== null)
      ? FullResignationLetter.fromPartial(object.resignationLetter)
      : undefined;
    message.noticeOfTermination = (object.noticeOfTermination !== undefined && object.noticeOfTermination !== null)
      ? NoticeOfTermination.fromPartial(object.noticeOfTermination)
      : undefined;
    message.affiliateAgreement = (object.affiliateAgreement !== undefined && object.affiliateAgreement !== null)
      ? Affiliate.fromPartial(object.affiliateAgreement)
      : undefined;
    message.commissionLetter = (object.commissionLetter !== undefined && object.commissionLetter !== null)
      ? CommissionLetterAgreement.fromPartial(object.commissionLetter)
      : undefined;
    message.demandForEmployeeCure =
      (object.demandForEmployeeCure !== undefined && object.demandForEmployeeCure !== null)
        ? DemandForEmployeeCure.fromPartial(object.demandForEmployeeCure)
        : undefined;
    message.annualEmployeeReview = (object.annualEmployeeReview !== undefined && object.annualEmployeeReview !== null)
      ? AnnualReview2.fromPartial(object.annualEmployeeReview)
      : undefined;
    message.singleMemberLLCAgreement =
      (object.singleMemberLLCAgreement !== undefined && object.singleMemberLLCAgreement !== null)
        ? SingleMemberLLCAgreement.fromPartial(object.singleMemberLLCAgreement)
        : undefined;
    message.uploadedFile = (object.uploadedFile !== undefined && object.uploadedFile !== null)
      ? UploadedFile.fromPartial(object.uploadedFile)
      : undefined;
    message.employeeConflict = (object.employeeConflict !== undefined && object.employeeConflict !== null)
      ? ConflictOfInterestEmployee.fromPartial(object.employeeConflict)
      : undefined;
    message.convertibleNotePurchaseAgreement =
      (object.convertibleNotePurchaseAgreement !== undefined && object.convertibleNotePurchaseAgreement !== null)
        ? ConvertibleNotePurchaseAgreement.fromPartial(object.convertibleNotePurchaseAgreement)
        : undefined;
    message.convertiblePromissoryNote =
      (object.convertiblePromissoryNote !== undefined && object.convertiblePromissoryNote !== null)
        ? ConvertiblePromissoryNote.fromPartial(object.convertiblePromissoryNote)
        : undefined;
    message.termsheetConvertiblePromissoryNote =
      (object.termsheetConvertiblePromissoryNote !== undefined && object.termsheetConvertiblePromissoryNote !== null)
        ? TermsheetConvertiblePromissoryNote.fromPartial(object.termsheetConvertiblePromissoryNote)
        : undefined;
    message.businessPromissoryNote =
      (object.businessPromissoryNote !== undefined && object.businessPromissoryNote !== null)
        ? BusinessPromissoryNote.fromPartial(object.businessPromissoryNote)
        : undefined;
    message.notePurchaseAgreement =
      (object.notePurchaseAgreement !== undefined && object.notePurchaseAgreement !== null)
        ? NotePurchaseAgreement.fromPartial(object.notePurchaseAgreement)
        : undefined;
    message.notePurchaseAgreementShortForm =
      (object.notePurchaseAgreementShortForm !== undefined && object.notePurchaseAgreementShortForm !== null)
        ? NotePurchaseAgreementShortForm.fromPartial(object.notePurchaseAgreementShortForm)
        : undefined;
    message.hebExample = (object.hebExample !== undefined && object.hebExample !== null)
      ? HebExample.fromPartial(object.hebExample)
      : undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.name = object.name ?? "";
    message.lastStep = object.lastStep ?? "";
    message.wasAddressSet = object.wasAddressSet ?? false;
    message.version = object.version ?? 0;
    message.progress = object.progress ?? 0;
    return message;
  },
};

function createBaseUploadedFile(): UploadedFile {
  return { fileData: new Uint8Array(), fileType: "", fileStatus: 0, reviewResult: undefined };
}

export const UploadedFile = {
  encode(message: UploadedFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fileData.length !== 0) {
      writer.uint32(10).bytes(message.fileData);
    }
    if (message.fileType !== "") {
      writer.uint32(18).string(message.fileType);
    }
    if (message.fileStatus !== 0) {
      writer.uint32(24).int32(message.fileStatus);
    }
    if (message.reviewResult !== undefined) {
      PContractReviewResult.encode(message.reviewResult, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadedFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadedFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.fileData = reader.bytes();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.fileType = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.fileStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.reviewResult = PContractReviewResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadedFile {
    return {
      fileData: isSet(object.fileData) ? bytesFromBase64(object.fileData) : new Uint8Array(),
      fileType: isSet(object.fileType) ? String(object.fileType) : "",
      fileStatus: isSet(object.fileStatus) ? uploadFileStatusFromJSON(object.fileStatus) : 0,
      reviewResult: isSet(object.reviewResult) ? PContractReviewResult.fromJSON(object.reviewResult) : undefined,
    };
  },

  toJSON(message: UploadedFile): unknown {
    const obj: any = {};
    message.fileData !== undefined &&
      (obj.fileData = base64FromBytes(message.fileData !== undefined ? message.fileData : new Uint8Array()));
    message.fileType !== undefined && (obj.fileType = message.fileType);
    message.fileStatus !== undefined && (obj.fileStatus = uploadFileStatusToJSON(message.fileStatus));
    message.reviewResult !== undefined &&
      (obj.reviewResult = message.reviewResult ? PContractReviewResult.toJSON(message.reviewResult) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadedFile>, I>>(base?: I): UploadedFile {
    return UploadedFile.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UploadedFile>, I>>(object: I): UploadedFile {
    const message = createBaseUploadedFile();
    message.fileData = object.fileData ?? new Uint8Array();
    message.fileType = object.fileType ?? "";
    message.fileStatus = object.fileStatus ?? 0;
    message.reviewResult = (object.reviewResult !== undefined && object.reviewResult !== null)
      ? PContractReviewResult.fromPartial(object.reviewResult)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
