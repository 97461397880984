import mixpanel from 'mixpanel-browser'
import React, { ReactNode } from 'react'
import { isProd } from './EnvResolver'

const A = "A"
const B = "B"

export default function ABTesting({ componentA, componentB, isSticky, testName, firstVariantProbability }: {
    componentA: JSX.Element, componentB: JSX.Element,
    isSticky: boolean, testName: AB_TEST_NAMES, firstVariantProbability: number
}) {
    const variant = React.useMemo(() => {
        let localVariant: string | null = null

        if (isSticky) {
            const localStorageItemName = "clawdia_stickyABTesting__" + testName
            // get from local storage or choose randomly and save to local storage - A/B testing
            localVariant = localStorage.getItem(localStorageItemName)

            if (localVariant === null || (localVariant !== A && localVariant !== B)) {
                localVariant = chooseVariant(firstVariantProbability)
                localStorage.setItem(localStorageItemName, localVariant)

                reportABTestVariant(testName, localVariant)
            }
        }
        else {
            localVariant = chooseVariant(firstVariantProbability)

            reportABTestVariant(testName, localVariant)
        }

        return localVariant
    }, [])

    if (variant === B) {
        return componentB
    }

    return componentA
}

function chooseVariant(firstVariantProbability: number) {
    return Math.random() < firstVariantProbability ? A : B
}

// Since tests are saved to localStorage it's a good practice to not delete old tests
export enum AB_TEST_NAMES {
    TEST_1__MORE_CLAWDIA_IMAGES = "TEST_1__MORE_CLAWDIA_IMAGES",
    TEST_2__LEARN_BUTTON = "TEST_2__LEARN_BUTTON",
    // TEST_3__SLIDING_PAYMENT = "TEST_3__SLIDING_PAYMENT",
    TEST_4__PAYMENT_STARTS = "TEST_4__PAYMENT_STARTS",
    TEST_5__CONTRACT_TEXT_FADE = "TEST_5__CONTRACT_TEXT_FADE",
    TEST_6__PAYMENT_WITH_TESTIMONIALS = "TEST_6__PAYMENT_WITH_TESTIMONIALS",
    // TEST_7__CHATBOT = "TEST_7__CHATBOT",
    TEST_8__PAYMENT_DR_JACOB = "TEST_8__PAYMENT_DR_JACOB",
    TEST_9__ORDER_CARDS_BY_FAMILY = "TEST_9__ORDER_CARDS_BY_FAMILY",
}

function reportABTestVariant(testName: string, localVariant: string) {
    if (isProd()) {
        mixpanel.track("ABTesting", { testName: testName, testVariant: localVariant })
    }
}

export function ABTestContractText({ children }: { children: ReactNode }) {
    return <ABTesting testName={AB_TEST_NAMES.TEST_5__CONTRACT_TEXT_FADE}
        isSticky={true}
        componentA={<>{children}</>}
        componentB={<span className="contract-text">{children}</span>}
        firstVariantProbability={0.8}
    />;
}
