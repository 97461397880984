import { getAutoContracts } from 'apps/legal-ide/App/components/WizardUi/auto-contracts';
import { Button, Container } from 'components';
import { createAndRedirectToDraft } from 'components/Library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Switch from 'react-switch';
import { MainStore } from 'store/mainTypes';
import { setAllocatedOptions, setAmountRaised, setFoundersShares, setPreMoneyValuation, setShowConversion, setTargetOptionPool, setUnallocatedOptions } from '../../store/cap-table-store/capTableActions';
import { CapTableStore } from '../../store/cap-table-store/capTableTypes';
import SafeChart from './SAFEChart';
import SAFEDataTable from './SAFEDataTable';

export default function CapTable() {
    return (
        <div className="w-full h-full" data-name="CapTable">
            <div className='w-full bg-primary h-44'>
                <Container>
                    <p className='text-white text-3xl'>Cap Table Estimate</p>
                </Container>
            </div>
            <Container className='-mt-30 pb-12'>
                <div className="flex w-full rounded-xl justify-between flex-col px-8 bg-white pt-8 shadow-card">
                    <CapTableScreenWidgets />
                </div>
            </Container>
        </div>
    )
}

function CapTableScreenWidgets() {
    const dispatch = useDispatch()
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const safeContract = getAutoContracts().find((contract) => contract.kind === "safe")!
    const showConversion = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.showConversion);
    const history = useHistory()

    return <div className="flex flex-col">
        <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col flex-grow w-full lg:w-1/2">
                <p className="text-xl mb-4 font-semibold">Current Status</p>
                <CurrentStatus />
                <p className="text-xl mt-8 mb-4 font-semibold">Future Equity Round</p>
                <FutureEquity />
            </div>
            <div className="flex flex-col flex-grow flex-shrink-0 w-full lg:w-1/2">
                <p className="text-xl mb-4 font-semibold flex-grow">Share Distribution</p>
                <div className="self-start bg-white shadow-card border border-skyBlue h-full w-full rounded-lg">
                    <SafeChart />
                </div>
            </div>
        </div>

        <div className="flex flex-grow-0 w-full mt-8 border-dotted border-skyBlue border-b" />

        <div className="flex mt-4 flex-col items-center">
            <p className="font-medium text-3xl mt-8">Simulate</p>
            <div className="flex gap-3 text-center align-middle">
                <p className={"text-accent font-medium mt-1 text-lg " + (showConversion && " opacity-30")}>Current status</p>
                <Switch uncheckedIcon={false} checkedIcon={false} checked={showConversion} onChange={(show) => { dispatch(setShowConversion(show)) }}
                    offColor="#dddddd" onColor="#65A0FF" color="#fff" onHandleColor="#FFD215" offHandleColor="#FFD215"
                ></Switch>
                <p className={"text-accent font-medium mt-1 text-lg " + (!showConversion && " opacity-30")}>Future estimate</p>
            </div>
        </div>


        <p className="font-medium text-2xl">SAFEs</p>
        <p className="text-sm text-gray-500">The following SAFE contracts are based on your account SAFEs that you have either completed or filled 100% of the questions (for accuracy).</p>
        <div className="flex flex-grow-0 w-full mb-4 mt-4">
            <SAFEDataTable />
        </div>
        <div className="self-center">
            <Button type="button" onClick={() => {
                createAndRedirectToDraft(safeContract, dispatch, history, dbUser, undefined)
            }} >+ Add new SAFE</Button>
        </div>
        <div className='h-4'>{" "}</div>
    </div>
}

function CurrentStatus() {
    const dispatch = useDispatch()
    const foundersShares = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.foundersShares);
    const allocatedOptions = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.allocatedOptions);
    const unallocatedOptions = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.unallocatedOptions);

    return <div className='bg-white rounded-lg'>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text-base">Founders shares:</p>
            <NumberInput className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={foundersShares}
                onChangeValue={(val: number) => {
                    dispatch(setFoundersShares(val))
                }} />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text text-base">ESOP - allocated options:</p>
            <NumberInput className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={allocatedOptions}
                onChangeValue={(val: number) => {
                    dispatch(setAllocatedOptions(val))
                }} />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text-base">ESOP - unallocated options:</p>
            <NumberInput className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={unallocatedOptions}
                onChangeValue={(val: number) => {
                    dispatch(setUnallocatedOptions(val))
                }} />
        </div >
    </div>
}

function FutureEquity() {
    const dispatch = useDispatch()
    const targetOptionPool = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.targetOptionPool);
    const preMoneyValuation = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.preMoneyValuation);
    const amountRaised = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.amountRaised);

    return <div className='bg-white rounded-lg'>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text-base">Target option pool:</p>
            <NumberInput maxVal={100} suffixSymbol="%" className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={targetOptionPool}
                onChangeValue={(val: number) => {
                    dispatch(setTargetOptionPool(val))
                }} />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text-base">Pre-money valuation:</p>
            <NumberInput prefixSymbol="$" className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={preMoneyValuation}
                onChangeValue={(val: number) => {
                    dispatch(setPreMoneyValuation(val))
                }} />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
            <p className="mr-2 text-base">Amount raised:</p>
            <NumberInput prefixSymbol="$" className="border border-skyBlue focus:border-primary px-2 rounded-lg h-7 xl:w-80 w-64"
                value={amountRaised}
                onChangeValue={(val: number) => {
                    dispatch(setAmountRaised(val))
                }} />        </div >
    </div>
}


// todo: make this a global useful util
export function NumberInput(props: { value?: number, defaultValue?: number, onChangeValue?: Function, onBlur?: Function, className?: string, maxVal?: number, prefixSymbol?: string, suffixSymbol?: string }) {
    const myRef = React.createRef<HTMLInputElement>();
    const prefix = props.prefixSymbol ?? ""
    const suffix = props.suffixSymbol ?? ""
    let formattedVal = props.value ? props.value.toLocaleString() : ""
    formattedVal = prefix + formattedVal + suffix

    function fixCaretPosition() {
        if ((myRef.current?.selectionStart) && (myRef.current?.selectionStart > formattedVal.length - suffix.length)) {
            myRef.current?.setSelectionRange(formattedVal.length - suffix.length, formattedVal.length - suffix.length)
        }

        if ((myRef.current?.selectionStart || 0) < prefix.length) {
            myRef.current?.setSelectionRange(prefix.length, myRef.current.selectionEnd)
        }
    }

    useEffect(() => {
        fixCaretPosition()
    })

    return <div className="flex relative items-center mb-1">
        <input onClick={fixCaretPosition}
            type="text"
            ref={myRef}
            className={props.className}
            onChange={(e) => {
                let val = e?.target.value;

                if (prefix) {
                    val = val?.substring(prefix.length)
                }

                if (suffix) {
                    val = val?.replaceAll(suffix, "")
                }

                val = val?.replaceAll(",", "");
                val = val?.replace(/\./g, ",");

                if (props.maxVal) {
                    if (parseFloat(val || "") > props.maxVal) {
                        val = "" + props.maxVal;
                    }
                }

                const numVal = parseFloat(val || "")

                if (props.onChangeValue) {
                    props.onChangeValue(numVal)
                }
            }}
            onKeyDown={fixCaretPosition}
            value={formattedVal}
            onBlur={() => {
                if (props.onBlur) {
                    props.onBlur()
                }
            }}
        ></input>
    </div>
}
