import { PLegalUnit } from "proto/PLegalUnit";
import { PDoc } from "../../proto/PDoc";

export const SET_SHOULD_APPLY_SAFE = "SET_SHOULD_APPLY_SAFE";
export const INIT_SHOULD_APPLY_SAFES = "INIT_SHOULD_APPLY_SAFES";
export const INIT_ALL_SAFES = "INIT_ALL_SAFES";
export const SET_TARGET_OPTION_POOL = "SET_TARGET_OPTION_POOL";
export const SET_PRE_MONEY_VALUATION = "SET_PRE_MONEY_VALUATION";
export const SET_AMOUNT_RAISED = "SET_AMOUNT_RAISED";
export const SET_FOUNDERS_SHARES = "SET_FOUNDERS_SHARES";
export const SET_ALLOCATED_OPTIONS = "SET_ALLOCATED_OPTIONS";
export const SET_UNALLOCATED_OPTIONS = "SET_UNALLOCATED_OPTIONS";
export const SET_SHOW_CONVERSION = "SET_SHOW_CONVERSION";
export const ADD_TRANSIENT_SAFE = "ADD_TRANSIENT_SAFE";

export type CapTableActionTypes =
  | { type: typeof SET_SHOULD_APPLY_SAFE; payload: { shouldApply: boolean, index: number } }
  | { type: typeof INIT_SHOULD_APPLY_SAFES; payload: number }
  | { type: typeof INIT_ALL_SAFES; payload: PDoc[] }

  | { type: typeof SET_TARGET_OPTION_POOL; payload: number }
  | { type: typeof SET_PRE_MONEY_VALUATION; payload: number }
  | { type: typeof SET_AMOUNT_RAISED; payload: number }
  | { type: typeof SET_FOUNDERS_SHARES; payload: number }
  | { type: typeof SET_ALLOCATED_OPTIONS; payload: number }
  | { type: typeof SET_UNALLOCATED_OPTIONS; payload: number }
  | { type: typeof SET_SHOW_CONVERSION; payload: boolean }
  | { type: typeof ADD_TRANSIENT_SAFE; payload: PLegalUnit }

export function setShouldApplySafe(shouldApply: boolean, index: number) {
  return { type: SET_SHOULD_APPLY_SAFE, payload: { shouldApply: shouldApply, index: index } };
}

export function initShouldApplySafes(length: number) {
  return { type: INIT_SHOULD_APPLY_SAFES, payload: length };
}

export function initAllSafes(safes: PDoc[]) {
  return { type: INIT_ALL_SAFES, payload: safes };
}

export function setTargetOptionPool(val: number) {
  return { type: SET_TARGET_OPTION_POOL, payload: val };
}

export function setPreMoneyValuation(val: number) {
  return { type: SET_PRE_MONEY_VALUATION, payload: val };
}

export function setAmountRaised(val: number) {
  return { type: SET_AMOUNT_RAISED, payload: val };
}

export function setFoundersShares(val: number) {
  return { type: SET_FOUNDERS_SHARES, payload: val };
}

export function setAllocatedOptions(val: number) {
  return { type: SET_ALLOCATED_OPTIONS, payload: val };
}

export function setUnallocatedOptions(val: number) {
  return { type: SET_UNALLOCATED_OPTIONS, payload: val };
}

export function setShowConversion(show: boolean) {
  return { type: SET_SHOW_CONVERSION, payload: show };
}

export function addTransientSafe(safeLegalUnit: PLegalUnit) {
  return { type: ADD_TRANSIENT_SAFE, payload: safeLegalUnit };
}
