/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface PTemplate {
  ownerId: string;
  templateId: string;
  design: PDesign | undefined;
  logo: PLogo | undefined;
  name: string;
}

export interface PLogo {
  src: string;
}

export interface PDesign {
  primaryColor: string;
  primaryBackground: string;
  backgrounds: PBackground[];
  coverBackground: PBackground | undefined;
}

export interface PSize {
  width: number;
  height: number;
}

export interface PPosition {
  x: number;
  y: number;
}

export interface PBackground {
  repeat: string;
  size: PSize | undefined;
  position: PPosition | undefined;
  src: string;
  id: string;
  name: string;
}

function createBasePTemplate(): PTemplate {
  return { ownerId: "", templateId: "", design: undefined, logo: undefined, name: "" };
}

export const PTemplate = {
  encode(message: PTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownerId !== "") {
      writer.uint32(10).string(message.ownerId);
    }
    if (message.templateId !== "") {
      writer.uint32(18).string(message.templateId);
    }
    if (message.design !== undefined) {
      PDesign.encode(message.design, writer.uint32(26).fork()).ldelim();
    }
    if (message.logo !== undefined) {
      PLogo.encode(message.logo, writer.uint32(34).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.ownerId = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.design = PDesign.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.logo = PLogo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PTemplate {
    return {
      ownerId: isSet(object.ownerId) ? String(object.ownerId) : "",
      templateId: isSet(object.templateId) ? String(object.templateId) : "",
      design: isSet(object.design) ? PDesign.fromJSON(object.design) : undefined,
      logo: isSet(object.logo) ? PLogo.fromJSON(object.logo) : undefined,
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: PTemplate): unknown {
    const obj: any = {};
    message.ownerId !== undefined && (obj.ownerId = message.ownerId);
    message.templateId !== undefined && (obj.templateId = message.templateId);
    message.design !== undefined && (obj.design = message.design ? PDesign.toJSON(message.design) : undefined);
    message.logo !== undefined && (obj.logo = message.logo ? PLogo.toJSON(message.logo) : undefined);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PTemplate>, I>>(base?: I): PTemplate {
    return PTemplate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PTemplate>, I>>(object: I): PTemplate {
    const message = createBasePTemplate();
    message.ownerId = object.ownerId ?? "";
    message.templateId = object.templateId ?? "";
    message.design = (object.design !== undefined && object.design !== null)
      ? PDesign.fromPartial(object.design)
      : undefined;
    message.logo = (object.logo !== undefined && object.logo !== null) ? PLogo.fromPartial(object.logo) : undefined;
    message.name = object.name ?? "";
    return message;
  },
};

function createBasePLogo(): PLogo {
  return { src: "" };
}

export const PLogo = {
  encode(message: PLogo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.src !== "") {
      writer.uint32(10).string(message.src);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PLogo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePLogo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.src = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PLogo {
    return { src: isSet(object.src) ? String(object.src) : "" };
  },

  toJSON(message: PLogo): unknown {
    const obj: any = {};
    message.src !== undefined && (obj.src = message.src);
    return obj;
  },

  create<I extends Exact<DeepPartial<PLogo>, I>>(base?: I): PLogo {
    return PLogo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PLogo>, I>>(object: I): PLogo {
    const message = createBasePLogo();
    message.src = object.src ?? "";
    return message;
  },
};

function createBasePDesign(): PDesign {
  return { primaryColor: "", primaryBackground: "", backgrounds: [], coverBackground: undefined };
}

export const PDesign = {
  encode(message: PDesign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primaryColor !== "") {
      writer.uint32(10).string(message.primaryColor);
    }
    if (message.primaryBackground !== "") {
      writer.uint32(18).string(message.primaryBackground);
    }
    for (const v of message.backgrounds) {
      PBackground.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.coverBackground !== undefined) {
      PBackground.encode(message.coverBackground, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PDesign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePDesign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.primaryColor = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.primaryBackground = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.backgrounds.push(PBackground.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.coverBackground = PBackground.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PDesign {
    return {
      primaryColor: isSet(object.primaryColor) ? String(object.primaryColor) : "",
      primaryBackground: isSet(object.primaryBackground) ? String(object.primaryBackground) : "",
      backgrounds: Array.isArray(object?.backgrounds)
        ? object.backgrounds.map((e: any) => PBackground.fromJSON(e))
        : [],
      coverBackground: isSet(object.coverBackground) ? PBackground.fromJSON(object.coverBackground) : undefined,
    };
  },

  toJSON(message: PDesign): unknown {
    const obj: any = {};
    message.primaryColor !== undefined && (obj.primaryColor = message.primaryColor);
    message.primaryBackground !== undefined && (obj.primaryBackground = message.primaryBackground);
    if (message.backgrounds) {
      obj.backgrounds = message.backgrounds.map((e) => e ? PBackground.toJSON(e) : undefined);
    } else {
      obj.backgrounds = [];
    }
    message.coverBackground !== undefined &&
      (obj.coverBackground = message.coverBackground ? PBackground.toJSON(message.coverBackground) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PDesign>, I>>(base?: I): PDesign {
    return PDesign.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PDesign>, I>>(object: I): PDesign {
    const message = createBasePDesign();
    message.primaryColor = object.primaryColor ?? "";
    message.primaryBackground = object.primaryBackground ?? "";
    message.backgrounds = object.backgrounds?.map((e) => PBackground.fromPartial(e)) || [];
    message.coverBackground = (object.coverBackground !== undefined && object.coverBackground !== null)
      ? PBackground.fromPartial(object.coverBackground)
      : undefined;
    return message;
  },
};

function createBasePSize(): PSize {
  return { width: 0, height: 0 };
}

export const PSize = {
  encode(message: PSize, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.width !== 0) {
      writer.uint32(24).int32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(32).int32(message.height);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PSize {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePSize();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag != 24) {
            break;
          }

          message.width = reader.int32();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.height = reader.int32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PSize {
    return {
      width: isSet(object.width) ? Number(object.width) : 0,
      height: isSet(object.height) ? Number(object.height) : 0,
    };
  },

  toJSON(message: PSize): unknown {
    const obj: any = {};
    message.width !== undefined && (obj.width = Math.round(message.width));
    message.height !== undefined && (obj.height = Math.round(message.height));
    return obj;
  },

  create<I extends Exact<DeepPartial<PSize>, I>>(base?: I): PSize {
    return PSize.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PSize>, I>>(object: I): PSize {
    const message = createBasePSize();
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    return message;
  },
};

function createBasePPosition(): PPosition {
  return { x: 0, y: 0 };
}

export const PPosition = {
  encode(message: PPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(24).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(32).int32(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag != 24) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.y = reader.int32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PPosition {
    return { x: isSet(object.x) ? Number(object.x) : 0, y: isSet(object.y) ? Number(object.y) : 0 };
  },

  toJSON(message: PPosition): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = Math.round(message.x));
    message.y !== undefined && (obj.y = Math.round(message.y));
    return obj;
  },

  create<I extends Exact<DeepPartial<PPosition>, I>>(base?: I): PPosition {
    return PPosition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PPosition>, I>>(object: I): PPosition {
    const message = createBasePPosition();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBasePBackground(): PBackground {
  return { repeat: "", size: undefined, position: undefined, src: "", id: "", name: "" };
}

export const PBackground = {
  encode(message: PBackground, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.repeat !== "") {
      writer.uint32(10).string(message.repeat);
    }
    if (message.size !== undefined) {
      PSize.encode(message.size, writer.uint32(18).fork()).ldelim();
    }
    if (message.position !== undefined) {
      PPosition.encode(message.position, writer.uint32(26).fork()).ldelim();
    }
    if (message.src !== "") {
      writer.uint32(34).string(message.src);
    }
    if (message.id !== "") {
      writer.uint32(42).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PBackground {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePBackground();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.repeat = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.size = PSize.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.position = PPosition.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.src = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.id = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PBackground {
    return {
      repeat: isSet(object.repeat) ? String(object.repeat) : "",
      size: isSet(object.size) ? PSize.fromJSON(object.size) : undefined,
      position: isSet(object.position) ? PPosition.fromJSON(object.position) : undefined,
      src: isSet(object.src) ? String(object.src) : "",
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: PBackground): unknown {
    const obj: any = {};
    message.repeat !== undefined && (obj.repeat = message.repeat);
    message.size !== undefined && (obj.size = message.size ? PSize.toJSON(message.size) : undefined);
    message.position !== undefined &&
      (obj.position = message.position ? PPosition.toJSON(message.position) : undefined);
    message.src !== undefined && (obj.src = message.src);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<PBackground>, I>>(base?: I): PBackground {
    return PBackground.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PBackground>, I>>(object: I): PBackground {
    const message = createBasePBackground();
    message.repeat = object.repeat ?? "";
    message.size = (object.size !== undefined && object.size !== null) ? PSize.fromPartial(object.size) : undefined;
    message.position = (object.position !== undefined && object.position !== null)
      ? PPosition.fromPartial(object.position)
      : undefined;
    message.src = object.src ?? "";
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
