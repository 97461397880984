/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EConvertiblePromissoryNoteCompanyEntityType {
  ConvertiblePromissoryNote_companyEntityType_NA = 0,
  ConvertiblePromissoryNote_companyEntityType_CORPORATION = 1,
  ConvertiblePromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  ConvertiblePromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  ConvertiblePromissoryNote_companyEntityType_LIMITED_PARTNERSHIP = 4,
  ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  ConvertiblePromissoryNote_companyEntityType_NONPROFIT_CORPORATION = 8,
  ConvertiblePromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  ConvertiblePromissoryNote_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eConvertiblePromissoryNoteCompanyEntityTypeFromJSON(
  object: any,
): EConvertiblePromissoryNoteCompanyEntityType {
  switch (object) {
    case 0:
    case "ConvertiblePromissoryNote_companyEntityType_NA":
      return EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_NA;
    case 1:
    case "ConvertiblePromissoryNote_companyEntityType_CORPORATION":
      return EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_CORPORATION;
    case 2:
    case "ConvertiblePromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "ConvertiblePromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "ConvertiblePromissoryNote_companyEntityType_LIMITED_PARTNERSHIP":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "ConvertiblePromissoryNote_companyEntityType_NONPROFIT_CORPORATION":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "ConvertiblePromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EConvertiblePromissoryNoteCompanyEntityType
        .ConvertiblePromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "ConvertiblePromissoryNote_companyEntityType_PARTNERSHIP":
      return EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertiblePromissoryNoteCompanyEntityType.UNRECOGNIZED;
  }
}

export function eConvertiblePromissoryNoteCompanyEntityTypeToJSON(
  object: EConvertiblePromissoryNoteCompanyEntityType,
): string {
  switch (object) {
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_NA:
      return "ConvertiblePromissoryNote_companyEntityType_NA";
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_CORPORATION:
      return "ConvertiblePromissoryNote_companyEntityType_CORPORATION";
    case EConvertiblePromissoryNoteCompanyEntityType
      .ConvertiblePromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "ConvertiblePromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP:
      return "ConvertiblePromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP";
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_LIMITED_PARTNERSHIP:
      return "ConvertiblePromissoryNote_companyEntityType_LIMITED_PARTNERSHIP";
    case EConvertiblePromissoryNoteCompanyEntityType
      .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION:
      return "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION";
    case EConvertiblePromissoryNoteCompanyEntityType
      .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EConvertiblePromissoryNoteCompanyEntityType
      .ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "ConvertiblePromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_NONPROFIT_CORPORATION:
      return "ConvertiblePromissoryNote_companyEntityType_NONPROFIT_CORPORATION";
    case EConvertiblePromissoryNoteCompanyEntityType
      .ConvertiblePromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "ConvertiblePromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EConvertiblePromissoryNoteCompanyEntityType.ConvertiblePromissoryNote_companyEntityType_PARTNERSHIP:
      return "ConvertiblePromissoryNote_companyEntityType_PARTNERSHIP";
    case EConvertiblePromissoryNoteCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConvertiblePromissoryNoteDiscountRate {
  ConvertiblePromissoryNote_discountRate_NA = 0,
  ConvertiblePromissoryNote_discountRate_EIGHTY = 1,
  ConvertiblePromissoryNote_discountRate_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConvertiblePromissoryNoteDiscountRateFromJSON(object: any): EConvertiblePromissoryNoteDiscountRate {
  switch (object) {
    case 0:
    case "ConvertiblePromissoryNote_discountRate_NA":
      return EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_NA;
    case 1:
    case "ConvertiblePromissoryNote_discountRate_EIGHTY":
      return EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_EIGHTY;
    case 999999:
    case "ConvertiblePromissoryNote_discountRate_OTHER":
      return EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConvertiblePromissoryNoteDiscountRate.UNRECOGNIZED;
  }
}

export function eConvertiblePromissoryNoteDiscountRateToJSON(object: EConvertiblePromissoryNoteDiscountRate): string {
  switch (object) {
    case EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_NA:
      return "ConvertiblePromissoryNote_discountRate_NA";
    case EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_EIGHTY:
      return "ConvertiblePromissoryNote_discountRate_EIGHTY";
    case EConvertiblePromissoryNoteDiscountRate.ConvertiblePromissoryNote_discountRate_OTHER:
      return "ConvertiblePromissoryNote_discountRate_OTHER";
    case EConvertiblePromissoryNoteDiscountRate.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MConvertiblePromissoryNoteCompanyEntityType {
  optionDbValue: EConvertiblePromissoryNoteCompanyEntityType;
  other: string;
}

export interface MConvertiblePromissoryNoteDiscountRate {
  optionDbValue: EConvertiblePromissoryNoteDiscountRate;
  other: string;
}

export interface ConvertiblePromissoryNote {
  effectiveDate: Date | undefined;
  companyFull: string;
  companyEntityType: MConvertiblePromissoryNoteCompanyEntityType | undefined;
  holderFull: string;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyEmail: string;
  companyNoticeCopyPerson: string;
  holderNoticeEmail: string;
  holderNoticePerson: string;
  holderNoticeCopyEmail: string;
  holderNoticeCopyPerson: string;
  governLawState: string;
  venue: string;
  companyNoticeCopy: InternalBoolean;
  holderNoticeCopy: InternalBoolean;
  companyAddress: string;
  principalSum: number;
  companyEntityState: string;
  baseInterestRate: number;
  maturityDate: Date | undefined;
  qualifiedOfferingValue: number;
  valuationCap: number;
  discountRate: MConvertiblePromissoryNoteDiscountRate | undefined;
  defaultInterestRate: number;
  holderAddress: string;
}

function createBaseMConvertiblePromissoryNoteCompanyEntityType(): MConvertiblePromissoryNoteCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MConvertiblePromissoryNoteCompanyEntityType = {
  encode(message: MConvertiblePromissoryNoteCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertiblePromissoryNoteCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertiblePromissoryNoteCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertiblePromissoryNoteCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertiblePromissoryNoteCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertiblePromissoryNoteCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertiblePromissoryNoteCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertiblePromissoryNoteCompanyEntityType>, I>>(
    base?: I,
  ): MConvertiblePromissoryNoteCompanyEntityType {
    return MConvertiblePromissoryNoteCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertiblePromissoryNoteCompanyEntityType>, I>>(
    object: I,
  ): MConvertiblePromissoryNoteCompanyEntityType {
    const message = createBaseMConvertiblePromissoryNoteCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConvertiblePromissoryNoteDiscountRate(): MConvertiblePromissoryNoteDiscountRate {
  return { optionDbValue: 0, other: "" };
}

export const MConvertiblePromissoryNoteDiscountRate = {
  encode(message: MConvertiblePromissoryNoteDiscountRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConvertiblePromissoryNoteDiscountRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConvertiblePromissoryNoteDiscountRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConvertiblePromissoryNoteDiscountRate {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eConvertiblePromissoryNoteDiscountRateFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConvertiblePromissoryNoteDiscountRate): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConvertiblePromissoryNoteDiscountRateToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConvertiblePromissoryNoteDiscountRate>, I>>(
    base?: I,
  ): MConvertiblePromissoryNoteDiscountRate {
    return MConvertiblePromissoryNoteDiscountRate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConvertiblePromissoryNoteDiscountRate>, I>>(
    object: I,
  ): MConvertiblePromissoryNoteDiscountRate {
    const message = createBaseMConvertiblePromissoryNoteDiscountRate();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseConvertiblePromissoryNote(): ConvertiblePromissoryNote {
  return {
    effectiveDate: undefined,
    companyFull: "",
    companyEntityType: undefined,
    holderFull: "",
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyEmail: "",
    companyNoticeCopyPerson: "",
    holderNoticeEmail: "",
    holderNoticePerson: "",
    holderNoticeCopyEmail: "",
    holderNoticeCopyPerson: "",
    governLawState: "",
    venue: "",
    companyNoticeCopy: 0,
    holderNoticeCopy: 0,
    companyAddress: "",
    principalSum: 0,
    companyEntityState: "",
    baseInterestRate: 0,
    maturityDate: undefined,
    qualifiedOfferingValue: 0,
    valuationCap: 0,
    discountRate: undefined,
    defaultInterestRate: 0,
    holderAddress: "",
  };
}

export const ConvertiblePromissoryNote = {
  encode(message: ConvertiblePromissoryNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyFull !== "") {
      writer.uint32(18).string(message.companyFull);
    }
    if (message.companyEntityType !== undefined) {
      MConvertiblePromissoryNoteCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.holderFull !== "") {
      writer.uint32(34).string(message.holderFull);
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(42).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(50).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(58).string(message.companyNoticeCopyEmail);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(66).string(message.companyNoticeCopyPerson);
    }
    if (message.holderNoticeEmail !== "") {
      writer.uint32(74).string(message.holderNoticeEmail);
    }
    if (message.holderNoticePerson !== "") {
      writer.uint32(82).string(message.holderNoticePerson);
    }
    if (message.holderNoticeCopyEmail !== "") {
      writer.uint32(90).string(message.holderNoticeCopyEmail);
    }
    if (message.holderNoticeCopyPerson !== "") {
      writer.uint32(98).string(message.holderNoticeCopyPerson);
    }
    if (message.governLawState !== "") {
      writer.uint32(106).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(114).string(message.venue);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(120).int32(message.companyNoticeCopy);
    }
    if (message.holderNoticeCopy !== 0) {
      writer.uint32(128).int32(message.holderNoticeCopy);
    }
    if (message.companyAddress !== "") {
      writer.uint32(138).string(message.companyAddress);
    }
    if (message.principalSum !== 0) {
      writer.uint32(149).float(message.principalSum);
    }
    if (message.companyEntityState !== "") {
      writer.uint32(154).string(message.companyEntityState);
    }
    if (message.baseInterestRate !== 0) {
      writer.uint32(165).float(message.baseInterestRate);
    }
    if (message.maturityDate !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityDate), writer.uint32(170).fork()).ldelim();
    }
    if (message.qualifiedOfferingValue !== 0) {
      writer.uint32(181).float(message.qualifiedOfferingValue);
    }
    if (message.valuationCap !== 0) {
      writer.uint32(189).float(message.valuationCap);
    }
    if (message.discountRate !== undefined) {
      MConvertiblePromissoryNoteDiscountRate.encode(message.discountRate, writer.uint32(194).fork()).ldelim();
    }
    if (message.defaultInterestRate !== 0) {
      writer.uint32(205).float(message.defaultInterestRate);
    }
    if (message.holderAddress !== "") {
      writer.uint32(210).string(message.holderAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConvertiblePromissoryNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConvertiblePromissoryNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MConvertiblePromissoryNoteCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.holderFull = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.holderNoticeEmail = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.holderNoticePerson = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.holderNoticeCopyEmail = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.holderNoticeCopyPerson = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 15:
          if (tag != 120) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 16:
          if (tag != 128) {
            break;
          }

          message.holderNoticeCopy = reader.int32() as any;
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
        case 18:
          if (tag != 149) {
            break;
          }

          message.principalSum = reader.float();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.companyEntityState = reader.string();
          continue;
        case 20:
          if (tag != 165) {
            break;
          }

          message.baseInterestRate = reader.float();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.maturityDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag != 181) {
            break;
          }

          message.qualifiedOfferingValue = reader.float();
          continue;
        case 23:
          if (tag != 189) {
            break;
          }

          message.valuationCap = reader.float();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.discountRate = MConvertiblePromissoryNoteDiscountRate.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag != 205) {
            break;
          }

          message.defaultInterestRate = reader.float();
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.holderAddress = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConvertiblePromissoryNote {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MConvertiblePromissoryNoteCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      holderFull: isSet(object.holderFull) ? String(object.holderFull) : "",
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      holderNoticeEmail: isSet(object.holderNoticeEmail) ? String(object.holderNoticeEmail) : "",
      holderNoticePerson: isSet(object.holderNoticePerson) ? String(object.holderNoticePerson) : "",
      holderNoticeCopyEmail: isSet(object.holderNoticeCopyEmail) ? String(object.holderNoticeCopyEmail) : "",
      holderNoticeCopyPerson: isSet(object.holderNoticeCopyPerson) ? String(object.holderNoticeCopyPerson) : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      holderNoticeCopy: isSet(object.holderNoticeCopy) ? internalBooleanFromJSON(object.holderNoticeCopy) : 0,
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
      principalSum: isSet(object.principalSum) ? Number(object.principalSum) : 0,
      companyEntityState: isSet(object.companyEntityState) ? String(object.companyEntityState) : "",
      baseInterestRate: isSet(object.baseInterestRate) ? Number(object.baseInterestRate) : 0,
      maturityDate: isSet(object.maturityDate) ? fromJsonTimestamp(object.maturityDate) : undefined,
      qualifiedOfferingValue: isSet(object.qualifiedOfferingValue) ? Number(object.qualifiedOfferingValue) : 0,
      valuationCap: isSet(object.valuationCap) ? Number(object.valuationCap) : 0,
      discountRate: isSet(object.discountRate)
        ? MConvertiblePromissoryNoteDiscountRate.fromJSON(object.discountRate)
        : undefined,
      defaultInterestRate: isSet(object.defaultInterestRate) ? Number(object.defaultInterestRate) : 0,
      holderAddress: isSet(object.holderAddress) ? String(object.holderAddress) : "",
    };
  },

  toJSON(message: ConvertiblePromissoryNote): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MConvertiblePromissoryNoteCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.holderFull !== undefined && (obj.holderFull = message.holderFull);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.holderNoticeEmail !== undefined && (obj.holderNoticeEmail = message.holderNoticeEmail);
    message.holderNoticePerson !== undefined && (obj.holderNoticePerson = message.holderNoticePerson);
    message.holderNoticeCopyEmail !== undefined && (obj.holderNoticeCopyEmail = message.holderNoticeCopyEmail);
    message.holderNoticeCopyPerson !== undefined && (obj.holderNoticeCopyPerson = message.holderNoticeCopyPerson);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.holderNoticeCopy !== undefined && (obj.holderNoticeCopy = internalBooleanToJSON(message.holderNoticeCopy));
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    message.principalSum !== undefined && (obj.principalSum = message.principalSum);
    message.companyEntityState !== undefined && (obj.companyEntityState = message.companyEntityState);
    message.baseInterestRate !== undefined && (obj.baseInterestRate = message.baseInterestRate);
    message.maturityDate !== undefined && (obj.maturityDate = message.maturityDate.toISOString());
    message.qualifiedOfferingValue !== undefined && (obj.qualifiedOfferingValue = message.qualifiedOfferingValue);
    message.valuationCap !== undefined && (obj.valuationCap = message.valuationCap);
    message.discountRate !== undefined && (obj.discountRate = message.discountRate
      ? MConvertiblePromissoryNoteDiscountRate.toJSON(message.discountRate)
      : undefined);
    message.defaultInterestRate !== undefined && (obj.defaultInterestRate = message.defaultInterestRate);
    message.holderAddress !== undefined && (obj.holderAddress = message.holderAddress);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConvertiblePromissoryNote>, I>>(base?: I): ConvertiblePromissoryNote {
    return ConvertiblePromissoryNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConvertiblePromissoryNote>, I>>(object: I): ConvertiblePromissoryNote {
    const message = createBaseConvertiblePromissoryNote();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyFull = object.companyFull ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MConvertiblePromissoryNoteCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.holderFull = object.holderFull ?? "";
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.holderNoticeEmail = object.holderNoticeEmail ?? "";
    message.holderNoticePerson = object.holderNoticePerson ?? "";
    message.holderNoticeCopyEmail = object.holderNoticeCopyEmail ?? "";
    message.holderNoticeCopyPerson = object.holderNoticeCopyPerson ?? "";
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.holderNoticeCopy = object.holderNoticeCopy ?? 0;
    message.companyAddress = object.companyAddress ?? "";
    message.principalSum = object.principalSum ?? 0;
    message.companyEntityState = object.companyEntityState ?? "";
    message.baseInterestRate = object.baseInterestRate ?? 0;
    message.maturityDate = object.maturityDate ?? undefined;
    message.qualifiedOfferingValue = object.qualifiedOfferingValue ?? 0;
    message.valuationCap = object.valuationCap ?? 0;
    message.discountRate = (object.discountRate !== undefined && object.discountRate !== null)
      ? MConvertiblePromissoryNoteDiscountRate.fromPartial(object.discountRate)
      : undefined;
    message.defaultInterestRate = object.defaultInterestRate ?? 0;
    message.holderAddress = object.holderAddress ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
