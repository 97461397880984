import { Editor } from "slate";
import { CustomEditor, Mark } from "../types/custom-types";

interface EditorCommand {
    exucute: () => void;
}

export class ToggleMarkCommand implements EditorCommand {
    private editor: CustomEditor;
    mark: Mark;
    data: any;

    constructor(editor: CustomEditor, mark: Mark, data: any) {
        this.editor = editor;
        this.mark = mark;
        this.data = data;
    }

    exucute = () => {
        !isMarkActive(this.editor, this.mark)
            ? this.editor.addMark(this.mark, this.data)
            : this.editor.removeMark(this.mark);
    };
}

const isMarkActive = (editor: CustomEditor, mark: Mark): boolean => {
    try {
        const marks = Editor.marks(editor);

        return marks ? marks[mark] === true : false;
    } catch (error) {
        return false;
    }
};
