import { Container, Ribbon } from 'components';
import React from 'react'
import { useHistory } from 'react-router';
import PdfEditor from "img/icons/pdf-editor.png";
import PieChartIcon from "img/icons/pie-chart.png";
import TimeTrackingIcon from "img/icons/time-tracking.png";
import PrivacyScannerIcon from "img/icons/privacy-scanner.png";
import { useAppSelector } from 'store';
import AdvisoryImg from "img/advisory.png";

export default function ToolsMain() {
  return <div className='w-full h-full'>
    <div id="ToolsMainHeader-header" className='w-full bg-primary'>
      <ToolsMainHeader />
    </div>
    <ToolsOptions />
  </div>
}

function ToolsMainHeader() {
  return (
    <Container className="flex items-start md:h-72 h-40 md:pt-12">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <p className='text-white md:text-3xl text-xl md:mt-2'>Tools that help you better visualize or detect your legal state</p>
        </div>
      </div>
    </Container>
  );
}

interface Tool {
  name: string;
  description: string;
  img: string;
  link: string;
  comingSoon: boolean;
}

function ToolsOptions() {
  const user = useAppSelector((state) => state.mainReducer.dbUser);
  
  let tools: Tool[] = [
    {
      name: "PDF Editor and Signer",
      description: "Use this tool to upload and edit PDFs and to sign them electronically",
      img: PdfEditor,
      link: "/tools/pdf-editor",
      comingSoon: false,
    },
    {
      name: "Time Tracking",
      description: "Use this tool to track your time spent on tasks for clients",
      img: TimeTrackingIcon,
      link: "/tools/time-tracking",
      comingSoon: false,
    },
    {
      name: "Privacy Policy Scanner",
      description: "Use this tool to make sure your Clawdia generated Privacy Policy remains up-to-date",
      img: PrivacyScannerIcon,
      link: "/tools/privacy-scanner",
      comingSoon: false
    },
    {
      name: "Cap Table Estimate",
      description: "Use this tool to visualize your SAFEs and how they will convert",
      img: PieChartIcon,
      link: "/tools/cap-table",
      comingSoon: false,
    },
    {
      name: "הצ'קליסט המשפטי",
      description: "10 הטעויות המשפטיות הנפוצות שרוב היזמים עושים בתחילת הדרך",
      img: AdvisoryImg,
      link: "/tools/checklist-video",
      comingSoon: false,
    },
    {
      name: "New Tool Coming Soon",
      description: "",
      img: PieChartIcon,
      link: "/tools/legal-state",
      comingSoon: true,
    },
    {
      name: "New Tool Coming Soon",
      description: "",
      img: PieChartIcon,
      link: "/tools/legal-state",
      comingSoon: true,
    },
  ]

  if (user?.country !== "Israel") {
    tools = tools.filter(tool => tool.name !== "הצ'קליסט המשפטי")
  }

  return <Container className="flex flex-col md:-mt-30 -mt-10 pb-8">
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-5 gap-y-5 mx-1 z-10">
      {tools.map((tool, index) => {
        return <ToolCard key={index} tool={tool} />
      })}
    </div>
  </Container>
}

function ToolCard({ tool }: { tool: Tool }) {
  const history = useHistory()

  return <div className={"flex relative flex-grow w-full md:rounded-2xl rounded-lg bg-white \
    shadow-card hover:shadow-cardHover bg-libraryCard backdrop-blur-md " + (tool.comingSoon ? "cursor-not-allowed" : "cursor-pointer")}
    onClick={() => {
      if (!tool.comingSoon) {
        history.push(tool.link)
      }
    }}
  >
    <div className={`flex flex-row md:flex-col leading-[1.08] items-center justify-center md:justify-start md:pt-14 h-28 md:h-100 md:gap-8 gap-4 h-full px-5 md:py-20`}>
      <img className={`md:h-32 h-20 ${tool.comingSoon ? "blur-md" : ""}`} alt="tool" src={tool.img} />
      <div className='flex flex-col md:items-start items-center gap-2'>
        <div className='h-19 flex md:items-start items-center'>
          <p className={`text-left text-lg md:text-1_5xl font-medium whitespace-pre-wrap text-mainAccent`}>{tool.name}</p>
        </div>

        <p className='hidden md:block'>{tool.description}</p>
      </div>
    </div>
    {tool.comingSoon && <>
      <Ribbon position='top-right' content='Coming Soon' bgClassName='bg-progressBar' textClassName='text-white' />
    </>
    }
  </div>
}
