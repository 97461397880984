import { checklistApi } from 'api-services/ChecklistApi';
import { getUser } from 'api-services/UseresApi';
import { Container } from 'components';
import { auth } from 'components/contexts/firebase';
import { PUserStatus } from 'proto/PUser';
import { PUser } from 'proto/PUser';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import posterImg from 'img/checklist-cover.png';

export default function ChecklistVideo() {
  return <div className='w-full h-full'>
    <Container className="flex flex-col w-full">
          <ChecklistVideoBody />
      </Container>
    </div>
}

const ChecklistVideoBody = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const history = useHistory();
  const [bought, setBought] = useState(false);

  const fetchVideo = async () => {
  const user = auth.currentUser;
  
  if (user) {
    if (!videoUrl) {
      checklistApi.getVideoUrl((resp: { video_url: string }) => {
        setVideoUrl(resp.video_url);
      });
      }
    }
  }

  useEffect(() => {
    const checkUser = () => {
      getUser((resp: { user: PUser }) => {
        if (resp.user?.checklistPurchased) {
          setHasAccess(true);
          fetchVideo();

          if (resp.user?.userStatus === PUserStatus.POST_PAYMENT) {
            setBought(true);
          }
        }
      });
    }

    checkUser();

    if (hasAccess) {
      return
    }

    const interval = setInterval(() => checkUser(), 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!hasAccess) {
      return;
    }

    if (!videoRef.current) {
      return;
    }
    
    const videoElement = videoRef.current;

    const handleTimeUpdate = () => {
      if (videoElement.currentTime >= 600) {
        checklistApi.watchedSomeOfChecklist();
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };

    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    // Clean up the event listener when the component unmounts
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [hasAccess, videoRef]);

  return (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <div className="w-full lg:w-4/5 relative">
        <video
        poster={posterImg}
        ref={videoRef}
        src={videoUrl}
        className="w-full h-auto"
          controlsList="nodownload"
          controls>
          Your browser does not support the video tag.
        </video>
        {hasAccess ? null : (
        <button onClick={() => {
          window.open("https://payments.payplus.co.il/l/2981b9f9-f0b0-42a1-b8fc-68ffbecf1df9", "_blank");
        }} className="mt-4 text-2xl px-8 py-4 bg-secondary shadow-md shadow-accent border-accent text-accent font-bold rounded-lg hover:scale-110 transition duration-300 absolute top-[30%]
        left-[50%] transform translate-x-[-50%] translate-y-[-50%]
        ">
          {`הצ'קליסט המשפטי - קבלו גישה`}
        </button>
        )}
      </div>

        {(!hasAccess || bought) ? null : (
      <button dir="rtl" onClick={() => {
        history.push("/tools/checklist-video/payment");
      }} className="mt-4 text-xl rtl px-6 py-3 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition duration-300">
        {`לקבלת גישה ל-Clawdia`}
      </button>)}

    </div>
  );
};
