/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile } from "../PUtils";

export const protobufPackage = "";

export interface DemandForEmployeeCure {
  jobTitle: string;
  dateLetter: Date | undefined;
  employeeAddress: string;
  employeeEmail: string;
  employeeNameFull: string;
  employeeName: string;
  letterName: string;
  letterTitle: string;
  underlyingAgreementDate: Date | undefined;
  attachAgreementCopy: PFile | undefined;
  attachAgreement: InternalBoolean;
  employeeBreach: string[];
  noticeDays: number;
}

function createBaseDemandForEmployeeCure(): DemandForEmployeeCure {
  return {
    jobTitle: "",
    dateLetter: undefined,
    employeeAddress: "",
    employeeEmail: "",
    employeeNameFull: "",
    employeeName: "",
    letterName: "",
    letterTitle: "",
    underlyingAgreementDate: undefined,
    attachAgreementCopy: undefined,
    attachAgreement: 0,
    employeeBreach: [],
    noticeDays: 0,
  };
}

export const DemandForEmployeeCure = {
  encode(message: DemandForEmployeeCure, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobTitle !== "") {
      writer.uint32(10).string(message.jobTitle);
    }
    if (message.dateLetter !== undefined) {
      Timestamp.encode(toTimestamp(message.dateLetter), writer.uint32(18).fork()).ldelim();
    }
    if (message.employeeAddress !== "") {
      writer.uint32(26).string(message.employeeAddress);
    }
    if (message.employeeEmail !== "") {
      writer.uint32(34).string(message.employeeEmail);
    }
    if (message.employeeNameFull !== "") {
      writer.uint32(42).string(message.employeeNameFull);
    }
    if (message.employeeName !== "") {
      writer.uint32(50).string(message.employeeName);
    }
    if (message.letterName !== "") {
      writer.uint32(58).string(message.letterName);
    }
    if (message.letterTitle !== "") {
      writer.uint32(66).string(message.letterTitle);
    }
    if (message.underlyingAgreementDate !== undefined) {
      Timestamp.encode(toTimestamp(message.underlyingAgreementDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.attachAgreementCopy !== undefined) {
      PFile.encode(message.attachAgreementCopy, writer.uint32(82).fork()).ldelim();
    }
    if (message.attachAgreement !== 0) {
      writer.uint32(88).int32(message.attachAgreement);
    }
    for (const v of message.employeeBreach) {
      writer.uint32(98).string(v!);
    }
    if (message.noticeDays !== 0) {
      writer.uint32(109).float(message.noticeDays);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DemandForEmployeeCure {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDemandForEmployeeCure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.jobTitle = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.dateLetter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.employeeAddress = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.employeeEmail = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.employeeNameFull = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.employeeName = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.letterName = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.letterTitle = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.underlyingAgreementDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.attachAgreementCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.attachAgreement = reader.int32() as any;
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.employeeBreach.push(reader.string());
          continue;
        case 13:
          if (tag != 109) {
            break;
          }

          message.noticeDays = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DemandForEmployeeCure {
    return {
      jobTitle: isSet(object.jobTitle) ? String(object.jobTitle) : "",
      dateLetter: isSet(object.dateLetter) ? fromJsonTimestamp(object.dateLetter) : undefined,
      employeeAddress: isSet(object.employeeAddress) ? String(object.employeeAddress) : "",
      employeeEmail: isSet(object.employeeEmail) ? String(object.employeeEmail) : "",
      employeeNameFull: isSet(object.employeeNameFull) ? String(object.employeeNameFull) : "",
      employeeName: isSet(object.employeeName) ? String(object.employeeName) : "",
      letterName: isSet(object.letterName) ? String(object.letterName) : "",
      letterTitle: isSet(object.letterTitle) ? String(object.letterTitle) : "",
      underlyingAgreementDate: isSet(object.underlyingAgreementDate)
        ? fromJsonTimestamp(object.underlyingAgreementDate)
        : undefined,
      attachAgreementCopy: isSet(object.attachAgreementCopy) ? PFile.fromJSON(object.attachAgreementCopy) : undefined,
      attachAgreement: isSet(object.attachAgreement) ? internalBooleanFromJSON(object.attachAgreement) : 0,
      employeeBreach: Array.isArray(object?.employeeBreach) ? object.employeeBreach.map((e: any) => String(e)) : [],
      noticeDays: isSet(object.noticeDays) ? Number(object.noticeDays) : 0,
    };
  },

  toJSON(message: DemandForEmployeeCure): unknown {
    const obj: any = {};
    message.jobTitle !== undefined && (obj.jobTitle = message.jobTitle);
    message.dateLetter !== undefined && (obj.dateLetter = message.dateLetter.toISOString());
    message.employeeAddress !== undefined && (obj.employeeAddress = message.employeeAddress);
    message.employeeEmail !== undefined && (obj.employeeEmail = message.employeeEmail);
    message.employeeNameFull !== undefined && (obj.employeeNameFull = message.employeeNameFull);
    message.employeeName !== undefined && (obj.employeeName = message.employeeName);
    message.letterName !== undefined && (obj.letterName = message.letterName);
    message.letterTitle !== undefined && (obj.letterTitle = message.letterTitle);
    message.underlyingAgreementDate !== undefined &&
      (obj.underlyingAgreementDate = message.underlyingAgreementDate.toISOString());
    message.attachAgreementCopy !== undefined &&
      (obj.attachAgreementCopy = message.attachAgreementCopy ? PFile.toJSON(message.attachAgreementCopy) : undefined);
    message.attachAgreement !== undefined && (obj.attachAgreement = internalBooleanToJSON(message.attachAgreement));
    if (message.employeeBreach) {
      obj.employeeBreach = message.employeeBreach.map((e) => e);
    } else {
      obj.employeeBreach = [];
    }
    message.noticeDays !== undefined && (obj.noticeDays = message.noticeDays);
    return obj;
  },

  create<I extends Exact<DeepPartial<DemandForEmployeeCure>, I>>(base?: I): DemandForEmployeeCure {
    return DemandForEmployeeCure.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DemandForEmployeeCure>, I>>(object: I): DemandForEmployeeCure {
    const message = createBaseDemandForEmployeeCure();
    message.jobTitle = object.jobTitle ?? "";
    message.dateLetter = object.dateLetter ?? undefined;
    message.employeeAddress = object.employeeAddress ?? "";
    message.employeeEmail = object.employeeEmail ?? "";
    message.employeeNameFull = object.employeeNameFull ?? "";
    message.employeeName = object.employeeName ?? "";
    message.letterName = object.letterName ?? "";
    message.letterTitle = object.letterTitle ?? "";
    message.underlyingAgreementDate = object.underlyingAgreementDate ?? undefined;
    message.attachAgreementCopy = (object.attachAgreementCopy !== undefined && object.attachAgreementCopy !== null)
      ? PFile.fromPartial(object.attachAgreementCopy)
      : undefined;
    message.attachAgreement = object.attachAgreement ?? 0;
    message.employeeBreach = object.employeeBreach?.map((e) => e) || [];
    message.noticeDays = object.noticeDays ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
