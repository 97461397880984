import { useAgreementContext } from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { useAppSelector } from "store";

export const NOT_US = "NOT_US"

export function DropDownStates(props: { usState: string, disabled: boolean, setUsState: Function, defVal?: string,autoFocus?: boolean, className?: string, fullAddress?: boolean, addressOptions?: AddressOptions }) {
    let stateCode = ""

    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);
    const he = currentAgreement?.lang === "HE";
    const agreementContext = useAgreementContext()!;
    const israeli = dbUser?.country === "Israel" || agreementContext.isSimulator;

    if (props.usState) {
        let chosen = states.filter(usState => usState[0] === props.usState)

        if (chosen && chosen.length === 1) {
            stateCode = chosen[0][1]
        }
    }

    if (props.defVal && props.defVal.endsWith(", USA")) {
        stateCode = props.defVal.substring(props.defVal.length - 7, props.defVal.length - 5)
    }


    return <select id="stateChooser"  required={!props.fullAddress} autoFocus={props.autoFocus}
        disabled={props.disabled}
        className={"my-1 w-2/3 bg-white mr-2 py-2 px-4 border rounded-lg text-2xl border-paleLilac focus:outline-primary h-20 " + props.className}
        value={stateCode || props.usState} onChange={(e) => props.setUsState(toFull(e.currentTarget.value))}
    >
        <option  value="">{he ? "בחרו מדינה" : "Choose state:"}</option>
        <option disabled>---------</option>

        {props.addressOptions && <>
            <option value={NOT_US}>{props.addressOptions.optionCaptionForOutsideUS}</option>
            <option disabled>---------</option>
        </>
        }

        {states.filter(usState => {
            if (!israeli && usState[1] === "Israel") {
                return false
            }

            return true
        }).map(usState => {
            if (usState[1] === "Israel" && he) {
                return ['ישראל', 'Israel']
            }

            return usState
        }).map((usState,i) => <option key={i} value={usState[1]}>{usState[0]}</option>
        )}
    </select>
}

function toFull(code: string) {
    let filtered = states.filter(usState => usState[1] === code)

    if (filtered && filtered.length === 1) {
        return filtered[0][0]
    }

    return code;
}

const states = [
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['District Of Columbia', 'DC'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
    ['Israel', 'Israel'],
];

export interface AddressOptions {
    allowAddressOutsideUS: boolean;
    optionCaptionForOutsideUS: string;
    textIfUserChoosesOutsideUS: string;
}
