import { AxiosResponse } from "axios";
import {
    PAskRequest,
    PAskResponseNew,
    PContractReviewRequest,
    PContractReviewResponse,
    PTipRequest,
    PTipResponse,
} from "../proto/PApi";
import { HttpResponse, request } from "./ApiCommon";

function reviewContract(req: PContractReviewRequest, successCallback: (arg0: PContractReviewResponse) => void) {
    request("/api/v1/gpt/review-contract", {
        method: "POST",
        data: PContractReviewRequest.toJSON(req),
    })?.then((response) => {
        successCallback(PContractReviewResponse.fromJSON(response.data));
    });
}

function ask(req: PAskRequest, successCallback: (arg0: PAskResponseNew) => void, errorCallback?: () => void) {
    request("/api/v1/gpt/ask", {
        method: "POST",
        data: PAskRequest.toJSON(req),
    })
        ?.then((response) => {
            successCallback(PAskResponseNew.fromJSON(response.data));
        })
        .catch(() => {
            errorCallback?.();
        }); // todo
}

function tip(req: PTipRequest, successCallback: (arg0: PTipResponse) => void, errorCallback?: () => void) {
    request("/api/v1/gpt/tip", {
        method: "POST",
        data: PTipRequest.toJSON(req),
    })
        ?.then((response) => {
            successCallback(PTipResponse.fromJSON(response.data));
        })
        .catch(() => {
            errorCallback?.();
        }); // todo
}

export const GPTApi = { reviewContract, ask, tip };
