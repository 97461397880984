import EmploymentImg from "img/employment_agreement.png";
import IndependentContractorImg from "img/independent_contractor.png";
import NDAImg from "img/nda.png";
import PrivacyPolicyImg from "img/privacy_policy.png";
import SAFEImg from "img/safe.png";
import SaaSSalesImg from "img/sales_saas_agreement.png";
import AdvisoryImg from "img/advisory.png";
import TermsOfUseImg from "img/terms_of_use.png";
import { Affiliate } from "proto/contracts/auto-contracts/Affiliate";
import { CommissionLetterAgreement } from "proto/contracts/auto-contracts/CommissionLetterAgreement";
import {
    ConsultingMulti,
    EConsultingMultiRole,
} from "proto/contracts/auto-contracts/ConsultingMulti";
import { ConvertiblePromissoryNote } from "proto/contracts/auto-contracts/ConvertiblePromissoryNote";
import {
    EIndependentContractorRole,
    IndependentContractor,
} from "proto/contracts/auto-contracts/IndependentContractor";
import {
    EInfluencerAgreementRole,
    InfluencerAgreement,
} from "proto/contracts/auto-contracts/InfluencerAgreement";
import { MutualNDA } from "proto/contracts/auto-contracts/MutualNDA";
import { UnilateralNDA } from "proto/contracts/auto-contracts/UnilateralNDA";
import { ProposalOfServices } from "proto/contracts/auto-contracts/ProposalOfServices";
import { Referral } from "proto/contracts/auto-contracts/Referral";
import { SaasSalesAgreement } from "proto/contracts/auto-contracts/SaasSalesAgreement";
import { SingleMemberLLCAgreement } from "proto/contracts/auto-contracts/SingleMemberLLCAgreement";
import { PLegalUnit } from "proto/PLegalUnit";
import { PUser } from "proto/PUser";
import { AutoContractDocument, Family, nameOfICA } from "./autoContractsUtils";
import mapJson from "./shaTable.json";
import { BusinessPromissoryNote } from "proto/contracts/auto-contracts/BusinessPromissoryNote";
import { NotePurchaseAgreement } from "proto/contracts/auto-contracts/NotePurchaseAgreement";
import { NotePurchaseAgreementShortForm } from "proto/contracts/auto-contracts/NotePurchaseAgreementShortForm";
import { ConvertibleNotePurchaseAgreement } from "proto/contracts/auto-contracts/ConvertibleNotePurchaseAgreement";
import { Advisory } from "proto/contracts/auto-contracts/Advisory";

export class AutoDocService {
    data: { [key: string]: { SHA: string; Latest: string; List: string[] } };

    constructor() {
        this.data = mapJson;
    }

    getFullName(name: string, version: string) {
        return `${version}_${name}`;
    }

    async loadContract(version: string) {
        return import(`./docs/${version}.json`)
            .then((contract) => {
                return contract.default;
            })
            .catch((err) => {
                console.error("err", err.message);
                return null;
            });
    }

    getLatest(filename: string) {
        const doc = this.data[filename + ".json"];
        if (!doc) {
            throw new Error(`getLatest: doc ${filename} not exists`);
        }

        return this.getFullName(filename, doc.Latest);
    }
}

export const autoDocService = new AutoDocService();

export function getAutoContractByKey(key: AutoContractDocument["keyto"]) {
    return getAutoContracts().find((c) => c.keyto === key);
}

export function getAutoContracts() {
    let autoContracts: AutoContractDocument[] = [
        {
            name: nameOfICA(),
            title: nameOfICA(),
            image: IndependentContractorImg,
            text: nameOfICA(),
            desc: "Independent Contractor or hiring one? Use this contract to clearly define the services, payment and more.",
            comingSoon: () => false,
            kind: "independentContractor",
            keyto: "independentContractor",
            contract: (legalUnit) => {
                return legalUnit?.independentContractor;
            },
            filename: "IndependentContractor",
            namer: (legalUnit) => {
                return legalUnit?.independentContractor?.otherName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner:
                        legalUnit?.independentContractor?.role?.optionDbValue &&
                        legalUnit?.independentContractor?.role?.optionDbValue ===
                            EIndependentContractorRole.IndependentContractor_role_RECIPIENT
                            ? "Service Provider"
                            : "Service Recipient",
                    otherName: legalUnit?.independentContractor?.otherName || "",
                    otherNameField: "otherName" as keyof IndependentContractor,
                    otherAddressField: "otherAddress" as keyof IndependentContractor,
                };
            },
            families: [Family.Freelance],
            forUSA: true,
            forIsrael: true,
            tags: [
                "Freelance",
                "Contract",
                "Consulting",
                "Agreement",
                "Contractor",
                "Service",
                "Services",
                "Self-Employed",
                "Work-for-Hire",
                "Non-Employee",
                "Work",
                "Legal",
                "Independent",
                "Contractual",
            ],
        },
        {
            name: nameOfICA() + " - longer",
            title: nameOfICA() + " - longer",
            image: IndependentContractorImg,
            text: nameOfICA() + " - longer",
            desc: "Independent Contractor or hiring one? Use this contract to clearly define the services, payment and more.",
            comingSoon: () => false,
            kind: "consultingMulti",
            keyto: "consultingMulti",
            contract: (legalUnit) => {
                return legalUnit?.consultingMulti;
            },
            filename: "ConsultingMulti",
            namer: (legalUnit) => {
                return legalUnit?.consultingMulti?.otherFullName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner:
                        legalUnit?.consultingMulti?.role?.optionDbValue &&
                        legalUnit?.consultingMulti?.role?.optionDbValue ===
                            EConsultingMultiRole.ConsultingMulti_role_RECIPIENT
                            ? "Service Provider"
                            : "Service Recipient",
                    otherName: legalUnit?.consultingMulti?.otherFullName || "",
                    otherNameField: "otherFullName" as keyof ConsultingMulti,
                };
            },
            families: [Family.Freelance],
            tags: [],
            forUSA: true,
            forIsrael: true,
            hideInLibrary: (user?: PUser) => {
                if (user?.email === "dave@genaipartners.com") {
                    return false;
                }

                return true;
            },
        },
        {
            name: "Mutual NDA",
            title: "Mutual NDA",
            image: NDAImg,
            text: "Mutual NDA",
            desc: "Mutual NDA is a non-disclosure agreement between two parties.",
            comingSoon: () => false,
            kind: "mutualNDA",
            keyto: "mutualNDA",
            contract: (legalUnit) => {
                return legalUnit?.mutualNDA;
            },
            filename: "MutualNDA",
            namer: (legalUnit) => {
                return legalUnit?.mutualNDA?.otherName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Other Party",
                    otherName: legalUnit?.mutualNDA?.otherName || "",
                    otherNameField: "otherName" as keyof MutualNDA,
                    otherAddressField: "otherAddress" as keyof MutualNDA,
                };
            },
            families: [Family.Freelance],
            tags: [
                "Mutual",
                "NDA",
                "Non-Disclosure",
                "Confidentiality",
                "Agreement",
                "Contract",
                "Confidential",
                "Secret",
                "Protected",
                "Information",
                "Proprietary",
                "Trade",
                "Secrets",
                "Disclose",
                "Discloser",
                "Legal",
                "Document",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Proposal for Services",
            title: "Proposal for Services",
            image: IndependentContractorImg,
            text: "Proposal for Services",
            desc: "Independent Contractor? Use this non-binding proposal for services to clearly define commercial terms with clients.",
            comingSoon: () => false,
            kind: "proposalOfServices",
            keyto: "proposalOfServices",
            contract: (legalUnit) => {
                return legalUnit?.proposalOfServices;
            },
            filename: "ProposalOfServices",
            namer: (legalUnit) => {
                return legalUnit?.proposalOfServices?.clientFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Service Recipient",
                    otherName: legalUnit?.proposalOfServices?.clientFull || "",
                    otherNameField: "clientFull" as keyof ProposalOfServices,
                };
            },
            families: [Family.Freelance],
            tags: [
                "Proposal",
                "Services",
                "Offer",
                "Service",
                "Business",
                "Client",
                "Agreement",
                "Contract",
                "Scope",
                "Project",
                "Solution",
                "Deliverables",
                "Timeline",
                "Cost",
                "Budget",
                "Payment",
                "Proposal",
                "Consultation",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Employment Offer Letter",
            title: "Employment Offer Letter",
            image: EmploymentImg,
            text: "Employment Offer Letter",
            desc: "Use this offer letter to clearly define the terms of employment.",
            comingSoon: () => false,
            kind: "employmentOfferLetter",
            keyto: "employmentOfferLetter",
            contract: (legalUnit) => {
                return legalUnit?.employmentOfferLetter;
            },
            filename: "OfferLetterEmployment",
            namer: (legalUnit) => {
                return legalUnit?.employmentOfferLetter?.employeeNameFull || "";
            },
            families: [Family.HR],
            tags: [
                "Employment",
                "Offer",
                "Letter",
                "Job",
                "Employer",
                "Employee",
                "Agreement",
                "Compensation",
                "Salary",
                "Position",
                "Responsibilities",
                "Benefits",
                "Probation",
                "Termination",
                "Work",
                "Company",
            ],
            forUSA: true,
            forIsrael: false,
        },
        {
            name: "SaaS Sales Agreement",
            title: "SaaS Sales Agreement",
            image: SaaSSalesImg,
            text: "SaaS Sales Agreement",
            hideInLibrary: () => true,
            desc: "SaaS Sales Agreement is a legal document that defines the terms and conditions of selling SaaS products.",
            comingSoon: () => false,
            kind: "saasSalesAgreement",
            keyto: "saasSalesAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.saasSalesAgreement;
            },
            filename: "SaasSalesAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.saasSalesAgreement?.otherName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Customer",
                    otherName: legalUnit?.saasSalesAgreement?.otherName || "",
                    otherNameField: "otherName" as keyof SaasSalesAgreement,
                    otherAddressField: "otherAddress" as keyof SaasSalesAgreement,
                };
            },
            families: [Family.SaaS],
            tags: [
                "SaaS",
                "Sales",
                "Agreement",
                "Software",
                "Subscription",
                "License",
                "Pricing",
                "Renewal",
                "Pilot",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "SaaS Sales Agreement",
            title: "SaaS Sales Agreement",
            image: SaaSSalesImg,
            text: "SaaS Sales Agreement",
            desc: "SaaS Sales Agreement is a legal document that defines the terms and conditions of selling SaaS products.",
            comingSoon: () => false,
            kind: "saasSalesAgreementV2",
            keyto: "saasSalesAgreementV2",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.saasSalesAgreement;
            },
            filename: "SaasSalesAgreement_V2",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.saasSalesAgreement?.otherName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Customer",
                    otherName: legalUnit?.saasSalesAgreement?.otherName || "",
                    otherNameField: "otherName" as keyof SaasSalesAgreement,
                    otherAddressField: "otherAddress" as keyof SaasSalesAgreement,
                };
            },
            families: [Family.SaaS],
            tags: [
                "SaaS",
                "Sales",
                "Agreement",
                "Software",
                "Subscription",
                "License",
                "Pricing",
                "Renewal",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Influencer Agreement",
            title: "Influencer Agreement",
            image: IndependentContractorImg,
            text: "Influencer Agreement",
            desc: "A legal document that defines the terms and conditions of working with influencers.",
            comingSoon: () => false,
            kind: "influencerAgreement",
            keyto: "influencerAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.influencerAgreement;
            },
            filename: "InfluencerAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return (
                    (legalUnit?.influencerAgreement?.role?.optionDbValue &&
                    legalUnit.influencerAgreement.role.optionDbValue ===
                        EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER
                        ? legalUnit.influencerAgreement.companyFull
                        : legalUnit?.influencerAgreement?.consultantFull) || ""
                );
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner:
                        legalUnit?.influencerAgreement?.role?.optionDbValue &&
                        legalUnit.influencerAgreement.role.optionDbValue ===
                            EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER
                            ? "Company"
                            : "Influencer",
                    otherName:
                        legalUnit?.influencerAgreement?.role?.optionDbValue &&
                        legalUnit.influencerAgreement.role.optionDbValue ===
                            EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER
                            ? legalUnit?.influencerAgreement?.companyFull || ""
                            : legalUnit?.influencerAgreement?.consultantFull || "",
                    otherNameField:
                        legalUnit?.influencerAgreement?.role?.optionDbValue &&
                        legalUnit.influencerAgreement.role.optionDbValue ===
                            EInfluencerAgreementRole.InfluencerAgreement_role_INFLUENCER
                            ? ("companyFull" as keyof InfluencerAgreement)
                            : ("consultantFull" as keyof InfluencerAgreement),
                };
            },
            families: [Family.Freelance],
            tags: [
                "Influencer",
                "Agreement",
                "Collaboration",
                "Brand",
                "Social",
                "Media",
                "Promotion",
                "Compensation",
                "Content",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Privacy Policy",
            title: "Privacy Policy",
            image: PrivacyPolicyImg,
            text: "Privacy Policy",
            desc: "Privacy Policy is a legal document that defines how you collect, use, and share the personal information of your users.",
            comingSoon: () => false,
            kind: "privacyPolicy",
            keyto: "privacyPolicy",
            // TODO: fix
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.privacyPolicy;
            },
            filename: "PrivacyPolicy",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.privacyPolicy?.websiteUrl || "";
            },
            families: [Family.Website],
            tags: [
                "Privacy",
                "Policy",
                "Data",
                "DPO",
                "Cookie",
                "Personal",
                "Information",
                "Collection",
                "Usage",
                "Security",
                "Consent",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Website Terms of Use",
            title: "Website Terms of Use",
            image: TermsOfUseImg,
            text: "Website Terms of Use",
            desc: "Terms of Use is a legal document that defines the terms and conditions of using your website.",
            comingSoon: () => false,
            kind: "termsOfUse",
            keyto: "termsOfUse",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsOfUse;
            },
            filename: "TermsOfUse",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsOfUse?.websiteUrl || "";
            },
            families: [Family.Website],
            tags: [
                "Website",
                "App",
                "Terms",
                "Use",
                "Agreement",
                "Conditions",
                "Acceptance",
                "Content",
                "Liability",
                "Intellectual",
                "Property",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "E-Comm Website Terms and Conditions",
            title: "E-Comm Website Terms and Conditions",
            image: TermsOfUseImg,
            text: "E-Comm Terms and Conditions",
            subtitle: "Physical Goods",
            desc: "Legal document that defines the terms and conditions of selling physical goods online for your website.",
            comingSoon: () => false,
            kind: "jointTermsPhysical",
            keyto: "jointTermsPhysical",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.jointTermsPhysical;
            },
            filename: "JointTermsPhysical",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.jointTermsPhysical?.websiteUrl || "";
            },
            families: [Family.Website],
            tags: [
                "E-Comm",
                "App",
                "Website",
                "Terms",
                "Conditions",
                "Agreement",
                "Online",
                "Purchase",
                "Payment",
                "Returns",
                "Shipping",
                "Refunds",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "SaaS Website Terms and Conditions",
            title: "SaaS Website Terms and Conditions",
            image: TermsOfUseImg,
            text: "SaaS Website Terms and Conditions",
            desc: "Legal document that defines the terms and conditions of selling SaaS products online for your website.",
            comingSoon: () => false,
            kind: "jointTermsSaaS",
            keyto: "jointTermsSaaS",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.jointTermsSaaS;
            },
            filename: "JointTermsOfUseSaaS",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.jointTermsSaaS?.websiteUrl || "";
            },
            families: [Family.Website, Family.SaaS],
            tags: [
                "SaaS",
                "Website",
                "Terms",
                "Conditions",
                "Agreement",
                "Subscription",
                "Service",
                "License",
                "Intellectual",
                "Property",
            ],
            forUSA: true,
            forIsrael: true,
        },
        // {
        //     name: "Employment Agreement (NY)",
        //     title: "Employment Agreement (NY)",
        //     image: EmploymentImg,
        //     text: "Employment Agreement (NY)",
        //     desc: "Hiring employees? Use this employment agreement to clearly define the terms and conditions of employment in New York state.",
        //     comingSoon: () => false,
        //     kind: "employmentNY",
        //     keyto: "employmentNY",
        //     contract: (legalUnit: PLegalUnit | undefined) => {
        //         return legalUnit?.employmentNY;
        //     },
        //     filename: "EmploymentNY",
        //     namer: (legalUnit: PLegalUnit | undefined) => {
        //         return legalUnit?.employmentNY?.employeeName || "";
        //     },
        //     signer: (legalUnit) => {
        //         return {
        //             titleForOtherSigner: "Employee",
        //             otherName: legalUnit?.employmentNY?.employeeName || "",
        //             otherNameField: "employeeName" as keyof EmploymentNY,
        //             otherTitleField: "employeeTitle" as keyof EmploymentNY,
        //         };
        //     }
        // },
        {
            name: "One Way NDA",
            title: "One Way NDA",
            image: NDAImg,
            text: "One Way NDA",
            desc: "One Way NDA is a non-disclosure agreement between two parties.",
            comingSoon: () => false,
            kind: "oneWayNDA",
            keyto: "oneWayNDA",
            contract: (legalUnit) => {
                return legalUnit?.oneWayNDA;
            },
            filename: "UnilateralNDA",
            namer: (legalUnit) => {
                return legalUnit?.oneWayNDA?.receivingParty || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Other Party",
                    otherName: legalUnit?.oneWayNDA?.receivingParty || "",
                    otherNameField: "receivingParty" as keyof UnilateralNDA,
                };
            },
            timeAddedInSeconds: 1685386515,
            families: [Family.Freelance],
            tags: [
                "One-Way",
                "NDA",
                "Non-Disclosure",
                "Confidentiality",
                "Agreement",
                "Contract",
                "Confidential",
                "Secret",
                "Protected",
                "Information",
                "Proprietary",
                "Trade",
                "Secrets",
                "Disclose",
                "Discloser",
                "Legal",
                "Document",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Notice of Termination",
            title: "Notice of Termination",
            image: EmploymentImg,
            text: "Notice of Termination",
            desc: "A letter to notify an employee of their termination.",
            comingSoon: () => false,
            kind: "noticeOfTermination",
            keyto: "noticeOfTermination",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.noticeOfTermination;
            },
            filename: "NoticeOfTermination",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.noticeOfTermination?.employeeName || "";
            },
            families: [Family.HR],
            tags: [
                "Notice",
                "Termination",
                "Contract",
                "Agreement",
                "End",
                "Cessation",
                "Cancellation",
                "Expiration",
                "Effective",
                "Date",
                "Work",
                "Employee",
                "Employment",
                "Employer",
                "Job",
                "HR",
            ],
            forUSA: true,
            forIsrael: false,
        },
        {
            name: "Resignation Letter",
            title: "Resignation Letter",
            image: EmploymentImg,
            text: "Resignation Letter",
            desc: "A letter to notify your employer of your resignation.",
            comingSoon: () => false,
            kind: "resignationLetter",
            keyto: "resignationLetter",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.resignationLetter;
            },
            filename: "FullResignationLetter",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.resignationLetter?.signerName || "";
            },
            families: [Family.HR],
            tags: [
                "Resignation",
                "Letter",
                "Notice",
                "Employment",
                "Employee",
                "Resign",
                "Notice Period",
                "Effective Date",
                "Reason",
                "Appreciation",
                "Job",
                "Work",
                "Employer",
                "Employment",
                "Employee",
                "HR",
            ],
            forUSA: true,
            forIsrael: false,
        },
        {
            name: "Annual Employee Review",
            title: "Annual Employee Review",
            image: EmploymentImg,
            text: "Annual Employee Review",
            desc: "Annual Employee Review",
            comingSoon: () => false,
            kind: "annualEmployeeReview",
            keyto: "annualEmployeeReview",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.annualEmployeeReview;
            },
            filename: "AnnualReview2",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.annualEmployeeReview?.employeeNameFull || "";
            },
            families: [Family.HR],
            tags: [
                "Annual",
                "Employee",
                "Review",
                "Performance",
                "Evaluation",
                "Feedback",
                "Goals",
                "Development",
                "Recognition",
                "Improvement",
                "Promotion",
                "Job",
                "Work",
                "HR",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Demand for Employee Cure",
            title: "Demand for Employee Cure",
            image: EmploymentImg,
            text: "Demand for Employee Cure",
            desc: "Demand for Employee Cure",
            comingSoon: () => false,
            kind: "demandForEmployeeCure",
            keyto: "demandForEmployeeCure",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.demandForEmployeeCure;
            },
            filename: "DemandForEmployeeCure",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.demandForEmployeeCure?.employeeName || "";
            },
            families: [Family.HR],
            tags: [
                "Demand",
                "Employee",
                "Cure",
                "Performance",
                "Expectations",
                "Improvement",
                "Warning",
                "Corrective",
                "Action",
                "Deadline",
                "Job",
                "HR",
            ],
            forUSA: true,
            forIsrael: false,
        },
        {
            name: "Startup Advisory Agreement",
            title: "Startup Advisory Agreement",
            image: AdvisoryImg,
            text: "Startup Advisory Agreement",
            desc: "Advisory agreement for startups defines the terms and conditions of providing advisory services in return for equity.",
            comingSoon: () => false,
            kind: "advisoryAgreement",
            keyto: "advisoryAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.advisoryAgreement;
            },
            filename: "Advisory",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.advisoryAgreement?.advisoryName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Advisor",
                    otherName: legalUnit?.advisoryAgreement?.advisoryName || "",
                    otherNameField: "advisoryName" as keyof Advisory,
                    otherAddressField: "advisorAddress" as keyof Advisory,
                };
            },
            families: [Family.Startup],
            tags: [
                "Advisory",
                "Agreement",
                "Contract",
                "Advisor",
                "Adviser",
                "Advising",
                "Startup",
                "Equity",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Referral Agreement",
            title: "Referral Agreement",
            image: IndependentContractorImg,
            text: "Referral Agreement",
            desc: "Referral agreement defines the terms and conditions of providing referral services in return for a commission.",
            comingSoon: () => false,
            kind: "referralAgreement",
            keyto: "referralAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.referralAgreement;
            },
            filename: "Referral",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.referralAgreement?.referFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Other party",
                    otherName: legalUnit?.referralAgreement?.referFull || "",
                    otherNameField: "referFull" as keyof Referral,
                };
            },
            families: [Family.Freelance],
            tags: [
                "Referral",
                "Agreement",
                "Referral Program",
                "Commission",
                "Compensation",
                "Referral Fee",
                "Referrer",
                "Recipient",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Cease & Desist - Trademark Infringement",
            title: "Cease & Desist - Trademark Infringement",
            image: IndependentContractorImg,
            text: "Cease & Desist - Trademark Infringement",
            desc: "A legal document that demands the other party to stop using your trademark.",
            comingSoon: () => false,
            kind: "trademarkInfringement",
            keyto: "trademarkInfringement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.trademarkInfringement;
            },
            filename: "CD_TrademarkInfringement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.trademarkInfringement?.otherName || "";
            },
            families: [],
            tags: [
                "Cease",
                "Desist",
                "Trademark",
                "Infringement",
                "Letter",
                "Intellectual Property",
                "Violation",
                "Unauthorized Use",
                "Cessation",
                "Legal Action",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Cease & Desist - Copyright Infringement",
            title: "Cease & Desist - Copyright Infringement",
            image: IndependentContractorImg,
            text: "Cease & Desist - Copyright Infringement",
            desc: "A legal document that demands the other party to stop using your copyrighted material.",
            comingSoon: () => false,
            kind: "copyrightInfringement",
            keyto: "copyrightInfringement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.copyrightInfringement;
            },
            filename: "CD_CopyrightInfringement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.copyrightInfringement?.otherName || "";
            },
            families: [],
            tags: [
                "Cease",
                "Desist",
                "Copyright",
                "Infringement",
                "Letter",
                "Intellectual Property",
                "Violation",
                "Unauthorized Use",
                "Cessation",
                "Legal Action",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Cease & Desist - Contract Breach",
            title: "Cease & Desist - Contract Breach",
            image: IndependentContractorImg,
            text: "Cease & Desist - Contract Breach",
            desc: "A legal document that demands the other party to stop breaching the terms of your contract.",
            comingSoon: () => false,
            kind: "contractBreach",
            keyto: "contractBreach",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.contractBreach;
            },
            filename: "CD_ContractBreach",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.contractBreach?.otherName || "";
            },
            families: [],
            tags: [
                "Cease",
                "Desist",
                "Contract",
                "Breach",
                "Letter",
                "Legal Action",
                "Violation",
                "Noncompliance",
                "Cessation",
                "Remedy",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Demand Letter - Debt Collection",
            title: "Demand Letter - Debt Collection",
            image: IndependentContractorImg,
            text: "Demand Letter - Debt Collection",
            desc: "A legal document that demands the other party to pay you the amount you are owed.",
            comingSoon: () => false,
            kind: "demandLetter",
            keyto: "demandLetter",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.demandLetter;
            },
            filename: "DemandLetter",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.demandLetter?.debtorName || "";
            },
            families: [],
            tags: [
                "Demand",
                "Letter",
                "Debt",
                "Collection",
                "Payment",
                "Outstanding",
                "Owed",
                "Due",
                "Notice",
                "Legal Action",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Photo Release Form",
            title: "Photo Release Form",
            image: IndependentContractorImg,
            text: "Photo Release Form",
            desc: "A legal document that allows you to use a person's name, likeness, voice, & biographical information in your marketing materials.",
            comingSoon: () => false,
            kind: "photoRelease",
            keyto: "photoRelease",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.photoRelease;
            },
            filename: "PhotoRelease",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.photoRelease?.subjectName || "";
            },
            families: [Family.Freelance],
            tags: [
                "Photo",
                "Release",
                "Form",
                "Consent",
                "Permission",
                "Photography",
                "Image",
                "Video",
                "Audio",
                "Biographical",
                "Likeness",
                "Marketing",
                "Materials",
                "Model",
                "Legal",
                "Agreement",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Affiliate Agreement",
            title: "Affiliate Agreement",
            image: IndependentContractorImg,
            text: "Affiliate Agreement",
            desc: "A legal document that enables you to work with other businesses to promote your products or services.",
            comingSoon: () => false,
            kind: "affiliateAgreement",
            keyto: "affiliateAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.affiliateAgreement;
            },
            filename: "Affiliate",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.affiliateAgreement?.affiliateFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Affiliate",
                    otherName: legalUnit?.affiliateAgreement?.affiliateFull || "",
                    otherNameField: "affiliateFull" as keyof Affiliate,
                };
            },
            families: [],
            tags: [
                "Affiliate",
                "Agreement",
                "Partnership",
                "Commission",
                "Compensation",
                "Marketing",
                "Terms",
                "Conditions",
                "Program",
            ],
            timeAddedInSeconds: 1685386515,
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Commission Letter",
            title: "Commission Letter",
            image: IndependentContractorImg,
            text: "Commission Letter",
            desc: "A contract to pay a commission to an independent salesperson for selling your products or services.",
            comingSoon: () => false,
            kind: "commissionLetter",
            keyto: "commissionLetter",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.commissionLetter;
            },
            filename: "CommissionLetterAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.commissionLetter?.agentName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Agent",
                    otherName: legalUnit?.commissionLetter?.agentName || "",
                    otherNameField: "agentName" as keyof CommissionLetterAgreement,
                };
            },
            families: [],
            tags: [
                "Commission",
                "Letter",
                "Payment",
                "Compensation",
                "Sales",
                "Agreement",
                "Terms",
                "Conditions",
                "Percentage",
                "Incentive",
                "Sales",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "safe",
            title: "SAFE",
            image: SAFEImg,
            text: "SAFE",
            desc: "Investment contract between a startup and an investor that gives the investor the right to receive future equity of the company on certain triggering events.",
            comingSoon: () => false,
            kind: "safe",
            keyto: "safe",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsOfUse;
            },
            filename: "SAFE",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsOfUse?.websiteUrl || "";
            },
            families: [Family.Startup],
            tags: [
                "Safe",
                "Agreement",
                "Startup",
                "Financing",
                "Investment",
                "Equity",
                "Future",
                "Valuation",
                "Convertible",
                "Term",
                "Pre-Money",
                "Post-Money",
            ],
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Single Member LLC Operating Agreement",
            title: "Single Member LLC Operating Agreement",
            image: IndependentContractorImg,
            text: "Single Member LLC Operating Agreement",
            desc: "A legal document that outlines the ownership and management of a single member LLC.",
            comingSoon: () => false,
            kind: "singleMemberLLCAgreement",
            keyto: "singleMemberLLCAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.singleMemberLLCAgreement;
            },
            filename: "SingleMemberLLCAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.singleMemberLLCAgreement?.memberName || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Member",
                    otherName: legalUnit?.singleMemberLLCAgreement?.memberName || "",
                    otherNameField: "memberName" as keyof SingleMemberLLCAgreement,
                };
            },
            families: [],
            tags: [
                "Single",
                "Member",
                "LLC",
                "Operating",
                "Agreement",
                "Limited Liability Company",
                "Ownership",
                "Management",
                "Rights",
                "Responsibilities",
            ],
            forUSA: true,
            forIsrael: false,
        },
        {
            name: "Employee Conflict of Interest Policy",
            title: "Employee Conflict of Interest Policy",
            image: EmploymentImg,
            text: "Employee Conflict of Interest Policy",
            desc: "A legal document that outlines the rules and procedures for employees to avoid conflicts of interest",
            comingSoon: () => false,
            kind: "employeeConflict",
            keyto: "employeeConflict",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.employeeConflict;
            },
            filename: "ConflictOfInterestEmployee",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.employeeConflict?.companyFull || "";
            },
            families: [Family.HR],
            tags: [
                "Conflict",
                "Interest",
                "Policy",
                "Employee",
                "Employment",
                "Employer",
                "Job",
                "Work",
                "HR",
            ],
            forUSA: true,
            forIsrael: false,
        },
        // Loan Agreements
        {
            name: "Note Purchase Agreement",
            title: "Note Purchase Agreement",
            image: SAFEImg,
            text: "Note Purchase Agreement",
            desc: "Robust agreement used by an investor to purchase a promissory note from a company, where the investor wants substantial assurances and information from the company before completing the transaction.",
            comingSoon: () => false,
            kind: "notePurchaseAgreement",
            keyto: "notePurchaseAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.notePurchaseAgreement;
            },
            filename: "NotePurchaseAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.notePurchaseAgreement?.lenderFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Lender",
                    otherName: legalUnit?.businessPromissoryNote?.lenderFull || "",
                    otherNameField: "lenderFull" as keyof NotePurchaseAgreement,
                    otherAddressField: "lenderAddress" as keyof NotePurchaseAgreement,
                };
            },
            families: [],
            tags: [
                "Promissory",
                "Note",
                "Debt",
                "Loan",
                "Lender",
                "Borrower",
                "Interest",
                "Payment",
                "Terms",
                "Conditions",
                "Agreement",
            ],
            group: "Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Note Purchase Agreement Short Form",
            title: "Note Purchase Agreement Short Form",
            image: SAFEImg,
            text: "Note Purchase Agreement Short Form",
            desc: "Agreement used by an investor to purchase a promissory note from a company, where the investor is content with a limited set of assurances from the company before completing the transaction.",
            comingSoon: () => false,
            kind: "notePurchaseAgreementShortForm",
            keyto: "notePurchaseAgreementShortForm",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.notePurchaseAgreementShortForm;
            },
            filename: "NotePurchaseAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.notePurchaseAgreementShortForm?.lenderFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Lender",
                    otherName: legalUnit?.notePurchaseAgreementShortForm?.lenderFull || "",
                    otherNameField: "lenderFull" as keyof NotePurchaseAgreementShortForm,
                    otherAddressField: "lenderAddress" as keyof NotePurchaseAgreementShortForm,
                };
            },
            families: [],
            tags: [
                "Promissory",
                "Note",
                "Debt",
                "Loan",
                "Lender",
                "Borrower",
                "Interest",
                "Payment",
                "Terms",
                "Conditions",
                "Agreement",
            ],
            group: "Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Standard Promissory Note",
            title: "Standard Promissory Note",
            image: SAFEImg,
            text: "Standard Promissory Note",
            desc: "A written agreement where a business promises to repay a specific amount of money to another party. It includes details such as the loan amount, interest rate, repayment schedule, and more.",
            comingSoon: () => false,
            kind: "businessPromissoryNote",
            keyto: "businessPromissoryNote",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.businessPromissoryNote;
            },
            filename: "BusinessPromissoryNote",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.businessPromissoryNote?.lenderFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Lender",
                    otherName: legalUnit?.businessPromissoryNote?.lenderFull || "",
                    otherNameField: "lenderFull" as keyof BusinessPromissoryNote,
                    otherAddressField: "lenderAddress" as keyof BusinessPromissoryNote,
                };
            },
            families: [],
            tags: [
                "Promissory",
                "Note",
                "Debt",
                "Loan",
                "Lender",
                "Borrower",
                "Interest",
                "Payment",
                "Terms",
                "Conditions",
                "Agreement",
            ],
            group: "Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        // Convertible Loan Agreements
        {
            name: "Convertible Promissory Note",
            title: "Convertible Promissory Note",
            image: SAFEImg,
            text: "Convertible Promissory Note",
            desc: "A debt obligation in which a company borrows money from an investor for a promise of repayment and an option to convert the outstanding principal into equity",
            comingSoon: () => false,
            kind: "convertiblePromissoryNote",
            keyto: "convertiblePromissoryNote",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.convertiblePromissoryNote;
            },
            filename: "ConvertiblePromissoryNote",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.convertiblePromissoryNote?.holderFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Holder",
                    otherName: legalUnit?.convertiblePromissoryNote?.holderFull || "",
                    otherNameField: "holderFull" as keyof ConvertiblePromissoryNote,
                    otherAddressField: "otherAddress" as keyof ConvertiblePromissoryNote,
                };
            },
            families: [Family.Startup],
            tags: [
                "Convertible",
                "Promissory",
                "Note",
                "Debt",
                "Investment",
                "Convertible Note",
                "Term",
                "Interest",
                "Conversion",
                "Equity",
                "Startup",
            ],
            group: "Convertible Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Convertible Promissory Note – Term Sheet",
            title: "Convertible Promissory Note – Term Sheet",
            image: SAFEImg,
            text: "Convertible Promissory Note – Term Sheet",
            desc: "A non-binding agreement that outlines the terms of a potential investment.",
            comingSoon: () => false,
            kind: "termsheetConvertiblePromissoryNote",
            keyto: "termsheetConvertiblePromissoryNote",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsheetConvertiblePromissoryNote;
            },
            filename: "TermsheetConvertiblePromissoryNote",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.termsheetConvertiblePromissoryNote?.holderFull || "";
            },
            families: [Family.Startup],
            tags: [
                "Note",
                "Term",
                "Sheet",
                "Debt",
                "Investment",
                "Convertible Note",
                "Terms",
                "Equity",
                "Startup",
            ],
            group: "Convertible Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "Convertible Note Purchase Agreement",
            title: "Convertible Note Purchase Agreement",
            image: SAFEImg,
            text: "Convertible Note Purchase Agreement",
            desc: "Robust agreement used by investors to purchase a convertible note from a company.",
            comingSoon: () => false,
            kind: "convertibleNotePurchaseAgreement",
            keyto: "convertibleNotePurchaseAgreement",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.convertibleNotePurchaseAgreement;
            },
            filename: "ConvertibleNotePurchaseAgreement",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.convertibleNotePurchaseAgreement?.lenderFull || "";
            },
            signer: (legalUnit) => {
                return {
                    titleForOtherSigner: "Lender",
                    otherName: legalUnit?.convertibleNotePurchaseAgreement?.lenderFull || "",
                    otherNameField: "lenderFull" as keyof ConvertibleNotePurchaseAgreement,
                    otherAddressField: "holderAddress" as keyof ConvertibleNotePurchaseAgreement,
                }
            },
            families: [],
            tags: [
                "Note",
                "Term",
                "Sheet",
                "Debt",
                "Investment",
                "Convertible Note",
                "Terms",
                "Equity",
                "Startup",
            ],
            group: "Convertible Loan Agreements",
            forUSA: true,
            forIsrael: true,
        },
        {
            name: "דוגמא בעברית",
            title: "דוגמא בעברית",
            image: SAFEImg,
            text: "דוגמא בעברית",
            desc: "זאת דוגמא בעברית - אשר עוזרת למשתמשים מישראל לראות את יכולות המוצר קלאודיה בעברית",
            comingSoon: () => false,
            kind: "hebExample",
            keyto: "hebExample",
            contract: (legalUnit: PLegalUnit | undefined) => {
                return legalUnit?.hebExample;
            },
            filename: "HebExample",
            namer: (legalUnit: PLegalUnit | undefined) => {
                return "";
            },
            tags: [
                "עברית",
                "דוגמא",
            ],
            families: [],
            forIsrael: true,
            forUSA: false,
            hebOnly: true,
        }
    ];

    autoContracts = autoContracts.concat(
        new Array(3).fill(0).map((_, i) => {
            return {
                name: "temp",
                title: "",
                image: IndependentContractorImg,
                blur: true,
                text: "SAFE",
                desc: "",
                comingSoon: () => true,
                kind: `temp-${i}`,
                keyto: "safe",
                contract: (legalUnit: PLegalUnit | undefined) => {
                    return legalUnit?.termsOfUse;
                },
                filename: "TermsOfUse",
                namer: (legalUnit: PLegalUnit | undefined) => {
                    return legalUnit?.termsOfUse?.websiteUrl || "";
                },
                families: [],
                tags: [],
                forUSA: true,
                forIsrael: true,
            };
        })
    );

    return autoContracts;
}
