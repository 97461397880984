import clsx from "clsx";
import { nanoid } from "nanoid";
import { useRef } from "react";

type Props = {
    open: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
};
export const Toggle = (props: Props) => {
    const { open, onChange } = props;
    const ref = useRef<HTMLInputElement>(null);
    const toggle_id = nanoid();

    return (
        <label htmlFor={toggle_id} className="bg-powderBlue w-10 h-3 block rounded-full border relative cursor-pointer">
            <div
                className={clsx(
                    "absolute h-6 w-6 rounded-full top-0 -translate-y-1/3  transition-all",
                    open ? "bg-secondary border-2 border-darkIndigo ltr:left-1/2 rtl:right-1/2" : "bg-[#e1dccc] ltr:left-0 rtl:right-0"
                )}
            ></div>
            <input
                disabled={props.disabled}
                checked={open}
                onChange={() => {
                    onChange?.(ref.current?.checked ?? false);
                }}
                ref={ref}
                id={toggle_id}
                hidden
                type="checkbox"
            />
        </label>
    );
};
