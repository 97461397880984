import { PLegalUnit } from "../proto/PLegalUnit";
import { PDoc, PDocStatus } from "../proto/PDoc";
import { DecisionSupportingToolAlert, Dialog, DialogType } from "./mainTypes";
import { PUser } from "../proto/PUser";
import { MultiVideoProps } from "apps/legal-ide/App/components/QuestionWizard/VideosCollection";
import { PPlanState } from "proto/PPlanState";
import { PPlan } from "proto/PPlan";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const DRAFTS_LOADED = "DRAFTS_LOADED";
export const ADD_DRAFT = "ADD_DRAFT";
export const ACTIVATE_DRAFT = "ACTIVATE_DRAFT";
export const SET_IS_DOC_PREVIEW_OPEN = "SET_IS_DOC_PREVIEW_OPEN";
export const UPDATE_ACTIVE_CONTRACT = "UPDATE_ACTIVE_CONTRACT";
export const SET_SHOW_EXPLANATIONS = "SET_SHOW_EXPLANATIONS";
export const SET_WIZARD_NAVIGATION_OPEN = "SET_WIZARD_NAVIGATION_OPEN";
export const RENAME_LEGAL_UNIT = "RENAME_LEGAL_UNIT";
export const MARK_LAST_STEP = "MARK_LAST_STEP";
export const SET_DB_USER = "SET_DB_USER";
export const SET_PLAN_STATE = "SET_PLAN_STATE";
export const SET_PLAN = "SET_PLAN";
export const REGISTER_SIGN_CANVAS = "REGISTER_SIGN_CANVAS";
export const CHANGE_ALREADY_SIGNED = "CHANGE_ALREADY_SIGNED";
export const REGISTER_DOC_NAMER = "REGISTER_DOC_NAMER";
export const CALL_DIALOG = "CALL_DIALOG";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
export const PLAY_VIDEO = "PLAY_VIDEO";
export const BACKUP_VIDEO_TIME = "BACKUP_VIDEO_TIME";
export const SET_PAYMENT_PERIOD = "SET_PAYMENT_PERIOD";
export const SET_DECISION_SUPPORTING_TOOL_ALERT = "SET_DECISION_SUPPORTING_TOOL_ALERT";
export const UPDATE_ACTIVE_DRAFT_TEMPLATE = "UPDATE_ACTIVE_DRAFT_TEMPLATE";

// new
export const UPDATE_ACTIVE_DRAFT = "UPDATE_ACTIVE_DRAFT";
export const UPDATE_ACTIVE_DRAFT_STATUS = "UPDATE_ACTIVE_DRAFT_STATUS";

export function updateActiveDraftTemplate(payload: string) {
    return { type: UPDATE_ACTIVE_DRAFT_TEMPLATE, payload };
}

export function updateActiveDraft(payload: any) {
    return { type: UPDATE_ACTIVE_DRAFT, payload: payload };
}

export function updateActiveDraftStatus(payload: PDocStatus) {
    return { type: UPDATE_ACTIVE_DRAFT_STATUS, payload: payload };
}

export type ActionTypes =
    | { type: typeof TOGGLE_SIDEBAR }
    | { type: typeof DRAFTS_LOADED; payload: PDoc[] }
    | { type: typeof ADD_DRAFT; payload: PDoc }
    | { type: typeof ACTIVATE_DRAFT; payload: PDoc }
    | { type: typeof SET_IS_DOC_PREVIEW_OPEN; payload: boolean }
    | { type: typeof UPDATE_ACTIVE_CONTRACT; payload: PLegalUnit }
    | { type: typeof SET_SHOW_EXPLANATIONS; payload: boolean }
    | { type: typeof SET_WIZARD_NAVIGATION_OPEN; payload: boolean }
    | { type: typeof RENAME_LEGAL_UNIT; payload: string }
    | { type: typeof MARK_LAST_STEP; payload: string }
    | { type: typeof SET_DB_USER; payload: PUser }
    | { type: typeof SET_PLAN_STATE; payload: PPlanState }
    | { type: typeof SET_PLAN; payload: PPlan }
    | {
          type: typeof REGISTER_SIGN_CANVAS;
          payload: { signCanvas: React.MutableRefObject<any>; count: number };
      }
    | { type: typeof CHANGE_ALREADY_SIGNED; payload: { added: boolean; index: number } }
    | { type: typeof REGISTER_DOC_NAMER; payload: React.MutableRefObject<any> }
    | { type: typeof CALL_DIALOG; payload: Dialog }
    | { type: typeof SET_SIDEBAR_OPEN; payload: boolean }
    | { type: typeof PLAY_VIDEO; payload: string | MultiVideoProps }
    | { type: typeof BACKUP_VIDEO_TIME; payload: number }
    | { type: typeof SET_PAYMENT_PERIOD; payload: boolean }
    | { type: typeof UPDATE_ACTIVE_DRAFT; payload: any }
    | { type: typeof UPDATE_ACTIVE_DRAFT_STATUS; payload: PDocStatus }
    | { type: typeof SET_DECISION_SUPPORTING_TOOL_ALERT; payload: DecisionSupportingToolAlert }
    | { type: typeof UPDATE_ACTIVE_DRAFT_TEMPLATE; payload: string };

export function toggleSidebar() {
    return { type: TOGGLE_SIDEBAR };
}

export function draftsLoaded(drafts: PDoc[]) {
    return { type: DRAFTS_LOADED, payload: drafts };
}

export function addDraftState(draft: PDoc) {
    return { type: ADD_DRAFT, payload: draft };
}

export function activateDraft(draft: PDoc | undefined) {
    return { type: ACTIVATE_DRAFT, payload: draft };
}

// todo: remove this
export function setIsDocPreviewOpen(open: boolean) {
    return { type: SET_IS_DOC_PREVIEW_OPEN, payload: open };
}

export function setShowExplanations(show: boolean) {
    return { type: SET_SHOW_EXPLANATIONS, payload: show };
}

export function setWizardNavigatorOpen(open: boolean) {
    return { type: SET_WIZARD_NAVIGATION_OPEN, payload: open };
}

export function renameLegalUnit(newName: string) {
    return { type: RENAME_LEGAL_UNIT, payload: newName };
}

export function markLastStep(newName: string) {
    return { type: MARK_LAST_STEP, payload: newName };
}

export function setDBUser(user?: PUser) {
    return { type: SET_DB_USER, payload: user };
}

export function setPlanState(planState?: PPlanState) {
    return { type: SET_PLAN_STATE, payload: planState };
}

export function setPlan(plan?: PPlan) {
    return { type: SET_PLAN, payload: plan };
}

export function registerSignCanvas(
    signCanvas: React.MutableRefObject<any> | undefined,
    count: number
) {
    return { type: REGISTER_SIGN_CANVAS, payload: { signCanvas, count } };
}

export function changeAlreadySigned(added: boolean, index: number) {
    return { type: CHANGE_ALREADY_SIGNED, payload: { added: added, index: index } };
}

export function registerDocNamer(docNamerRef?: HTMLInputElement | null) {
    return { type: REGISTER_DOC_NAMER, payload: docNamerRef };
}

export function callDialog(type: DialogType, text?: string, callback?: Function) {
    if (type === DialogType.NONE) {
        return { type: CALL_DIALOG, payload: undefined };
    }

    return { type: CALL_DIALOG, payload: { text, type, callback } };
}

export function setSideBarOpen(sideBarOpen: boolean) {
    return { type: SET_SIDEBAR_OPEN, payload: sideBarOpen };
}

export function playVideo(video: string | MultiVideoProps | undefined) {
    return { type: PLAY_VIDEO, payload: video };
}

export function backupVideoLastTime(time: number) {
    return { type: BACKUP_VIDEO_TIME, payload: time };
}

export function setPaymentPeriod(isYearly: boolean) {
    return { type: SET_PAYMENT_PERIOD, payload: isYearly };
}

export function setDecisionSupportingToolAlert(alert: DecisionSupportingToolAlert | undefined) {
    return { type: SET_DECISION_SUPPORTING_TOOL_ALERT, payload: alert };
}
