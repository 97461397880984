import { useEffect, useState } from "react";

import { CTable } from ".";

interface CTableProps {
    tableProperties?: TableProperties;
    onChange?: (newTableProperties: TableProperties) => void;
}
export const TableBuilder = (props: CTableProps) => {
    const [state, setState] = useState<TableProperties>(
        props.tableProperties ?? {
            columns: [],
            rows: [],
            rowHeading: undefined,
            unlimitedRows: false,
            columnHeading: "",
        }
    );

    useEffect(() => {
        props.onChange?.(state);
    }, [state]);

    return (
        <div className="">
            <CTable
                onOptionsChange={(options) => {

                    setState({
                        ...state,
                        columns: options.columns,
                        rowHeading: options.row?.heading,
                        rows: options.row?.defaultRows ?? [],
                        unlimitedRows: options.row?.unlimited,
                        columnHeading: options.column?.headingLabel,
                    });
                }}
                options={{
                    columns: state.columns,
                    column: {
                        headingLabel: state.columnHeading,
                    },
                    editable: true,
                    row: {
                        deletable: true,
                        unlimited: state.unlimitedRows,
                        heading: state.rowHeading,
                        defaultRows: state.rows,
                    },
                }}
                data={state.rows ?? []}
            />
        </div>
    );
};
