import { validPipes } from "apps/legal-ide/App/Editor/customElements";
import { FillableStrategy } from "apps/legal-ide/App/Editor/parser";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import { CONDITION_RENDERER_REGEX } from "../Renderer/ConditionRenderer";
import { preprocess } from "./preprocess/preprocess";

export * from "./preprocess/preprocess";

interface Options {
    fields?: Field[];
}

export class Parser {
    fields: Field[];

    constructor(options?: Options) {
        this.fields = options?.fields || [];
    }

    parse(text: string, root?: BlockNode) {
        const txt = text;

        const lines = txt.split("\n");

        const linesNumbers = lines?.reduce<{ map: { [key: number]: number }; total: number }>(
            (acc, line, index) => {
                if (index > 0) {
                    // \n char
                    acc.map[acc.total] = +index;
                    acc.total++;
                }
                for (const char in [...line]) {
                    acc.map[acc.total] = +index + 1;
                    acc.total++;
                }
                return acc;
            },
            { total: 0, map: {} }
        );

        const getLineIndex = (i: number) => linesNumbers.map[i];

        let tree: BlockNode<any> = root || {
            parent: undefined,
            fullMatch: "",
            type: "root",
            children: [],
            payload: [],
            position: {
                open: { line: 1, start: 0, end: 0 },
                close: {
                    line: lines.length,
                    start: txt.length,
                    end: txt.length,
                },
            },
        };

        let lastNode: BlockNode<any> = tree;
        let messages: {
            type: "warning" | "error";
            description: string;
            code: MessageCode;
            position: {
                start: number;
                end: number;
                line: number;
                length: number;
            };
        }[] = [];

        // todo: postion nodes base on lines

        function appendNewNode(node: BlockNode<any> | TextNode) {
            if (lastNode.position?.open.end !== undefined) {
                // last node already closed, append child
                lastNode.children.push(node);
            } else {
                // last node is open, append payload
                lastNode.payload.push(node);
            }
        }

        const matches = [...txt.matchAll(blockRegex)];

        const lastMatch = matches[matches.length - 1];
        const contentAfter = txt.substring(lastMatch ? lastMatch.index! + lastMatch[0].length : 0, txt.length);

        const fillableStrategy = new FillableStrategy();

        matches.forEach((match, matchIndex) => {
            const [fullMatch] = match;
            const index = match.index ?? -1; // todo - error handling
            const startMatch = match.index ?? 0;
            const endMatch = startMatch + match[0].length;
            const groups = match.groups! as { closeTag: string; openTag: string; openTagClose: string };
            const { openTag, closeTag, openTagClose } = groups;

            const lineIndex = getLineIndex(startMatch);

            const nextMatch = matches[matchIndex + 1];
            const prevMatch = matches[matchIndex - 1];

            const contentBefore = txt.substring(
                prevMatch?.index !== undefined ? prevMatch.index + prevMatch[0].length : 0,
                index
            );
            const contentAfter = txt.substring(endMatch, nextMatch?.index ?? txt.length);

            // contentBefore
            if (contentBefore) {
                const contentBeforeIndex = startMatch - contentBefore.length;
                const fillables = fillableStrategy.matchAll(contentBefore);

                if (lastNode.type === "conditional" && openTagClose) {
                    const match: any = contentBefore.match(CONDITION_RENDERER_REGEX);

                    if (!match) {
                        //todo: need improvment
                        messages.push({
                            code: MessageCode.CONDITIONAL_INVALID_EXPRESSION,
                            description: "invalid conditional expression",
                            type: "error",
                            position: {
                                start: contentBeforeIndex,
                                end: contentBeforeIndex + contentBefore.length,
                                line: getLineIndex(contentBeforeIndex),
                                length: contentBefore.length,
                            },
                        });
                    } else {
                        const [, fieldName, operator, value = ""] = match;

                        const field = this.fields.find((f) => f.name === fieldName);

                        if (!field) {
                            messages.push({
                                code: MessageCode.FILLABLE_NOT_EXISTS,
                                description: "field not exists",
                                type: "error",
                                position: {
                                    start: contentBeforeIndex + contentBefore.indexOf(fieldName),
                                    end: contentBeforeIndex + fieldName.length,
                                    line: getLineIndex(contentBeforeIndex),
                                    length: fieldName.length,
                                },
                            });
                        } else {
                            if (field.type === FieldType.BOOL && !["true", "false"].includes(value)) {
                                messages.push({
                                    code: MessageCode.CONDITIONAL_INVALID_BOOLEAN_VALUE,
                                    description: `${fieldName} is boolean, value should me true or false (lowercase)`,
                                    type: "error",
                                    position: {
                                        start: contentBeforeIndex + contentBefore.indexOf(value),
                                        end: contentBeforeIndex + fieldName.length,
                                        line: getLineIndex(contentBeforeIndex),
                                        length: value.length,
                                    },
                                });
                            }
                        }
                    }
                }

                for (const fillable of fillables) {
                    const pipesIsValid = fillable.pipes.every((pipe) => validPipes.includes(pipe));

                    const field = this.fields.find((f) => f.name === fillable.fieldName);

                    if (!field || !pipesIsValid) {
                        messages.push({
                            code: pipesIsValid ? MessageCode.FILLABLE_NOT_EXISTS : MessageCode.FILLABLE_INVALID_PIPE,
                            description: pipesIsValid ? "field not exists" : "invalid pipe",
                            type: "error",
                            position: {
                                start: contentBeforeIndex + fillable.index,
                                end: contentBeforeIndex + fillable.index + fillable.fullMatch.length,
                                line: getLineIndex(contentBeforeIndex + fillable.index),
                                length: fillable.fullMatch.length,
                            },
                        });
                    }
                }

                const textNode: TextNode = {
                    parent: lastNode,
                    content: specialTrim(contentBefore),
                    type: "text",
                    position: {
                        open: { line: lineIndex, start: 0, end: 0 },
                        close: { line: lineIndex, start: contentBefore.length, end: contentBefore.length },
                    },
                    fullMatch: contentBefore,
                };
                appendNewNode(textNode);
            }

            if (openTag) {
                // start new block
                const node: BlockNode<string> = {
                    fullMatch,
                    parent: lastNode,
                    type: getNodeTypeByTag(openTag),
                    children: [],
                    payload: [],
                    position: {
                        open: { line: lineIndex, start: startMatch },
                    },
                };
                appendNewNode(node);

                lastNode = node;
            }
            if (closeTag) {
                // close current block
                if (lastNode.parent) {
                    if (lastNode.position) {
                        lastNode.position.close = { line: lineIndex, start: startMatch, end: endMatch };
                        if (closeTag === "<</kb>>") {
                            if (lastNode.children.length === 1 && lastNode.children[0].type === "conditional") {
                                messages.push({
                                    code: MessageCode.KB_WITH_CONDITIONAL_SINGLE_CHILD,
                                    description: "kb cant wrap condtional as single child",
                                    type: "error",
                                    position: {
                                        start: startMatch,
                                        end: startMatch + closeTag.length,
                                        line: getLineIndex(startMatch),
                                        length: closeTag.length,
                                    },
                                });
                            }
                        }
                        lastNode = lastNode.parent;
                    } else {
                        // error close tag before open tag
                        console.error("close tag without position", lastNode);
                    }
                } else {
                    messages.push({
                        code: MessageCode.CLOSE_TAG_BEFORE_OPEN_TAG,
                        description: "close tag appear before open tag",
                        type: "error",
                        position: {
                            start: startMatch,
                            end: startMatch + closeTag.length,
                            line: getLineIndex(startMatch),
                            length: closeTag.length,
                        },
                    });
                }
            }
            if (openTagClose && lastNode.position) {
                // close open tag end
                if (lastNode.position.open.end !== undefined) {
                    messages.push({
                        code: MessageCode.OPEN_TAG__END_BEFORE_OPEN_TAG_START,
                        description: "close open tag appear before open tag",
                        type: "error",
                        position: {
                            start: startMatch,
                            end: startMatch + openTagClose.length,
                            line: getLineIndex(startMatch),
                            length: openTagClose.length,
                        },
                    });
                } else {
                    lastNode.position.open.end = endMatch;
                }
            }

            if (contentAfter) {
                // const textNode: TextNode = {
                //     parent: lastNode,
                //     content: contentAfter,
                //     type: "text",
                //     position: {
                //         open: { line: lineIndex, start: index, end: index },
                //         close: {
                //             line: lineIndex,
                //             start: index + contentAfter.length,
                //             end: index + contentAfter.length,
                //         },
                //     },
                //     fullMatch: contentAfter,
                // };
                // appendNewNode(textNode);
            }
        });

        if (contentAfter) {
            const textNode: TextNode = {
                parent: lastNode,
                content: specialTrim(contentAfter),
                type: "text",
                position: {
                    open: { line: 1, start: 0, end: 0 },
                    close: { line: lines.length, start: contentAfter.length, end: contentAfter.length },
                },
                fullMatch: contentAfter,
            };
            appendNewNode(textNode);
        }
        // });

        return { tree, messages, linesNumbers };
    }
}

interface Position {
    open: { line: number; start: number; end?: number };
    close?: { line: number; start: number; end?: number };
}

export interface BaseNode {
    parent: BlockNode<any> | undefined;
    fullMatch: string;
    position?: Position;
}
export interface TextNode extends BaseNode {
    content: string;
    type: "text";
}

export interface BlockNode<type = string> extends BaseNode {
    children: Nodes[];
    type: type;
    payload: Nodes[]; // text in open tag without tags
}

export interface RootNode extends BlockNode<"root"> {
    type: "root";
}
export interface ConditionNode extends BlockNode<"conditional"> {
    type: "conditional";
}
export interface KbNode extends BlockNode<"kb"> {
    type: "kb";
}
export interface OrderedListNode extends BlockNode<"ol"> {
    type: "ol";
}
export interface TableNode extends BlockNode<"table"> {
    type: "table";
}

export type Nodes = TextNode | RootNode | OrderedListNode | KbNode | ConditionNode | TableNode;

// export interface Node {
//     type: string;
//     content: string;
//     fullMatch: string;
//     parent: Node | undefined;
//     type: "root" | "conditional" | "kb" | "regular" | "text";
//     index: number;
//     children: Node[];
// }


// const blockRegex = /<<([a-zA-Z]+)((?:(?!<<).|[\s\n])+)>>|<<\/([a-zA-Z]+)>>/g;
// const blockRegex = /<<([a-zA-Z]+)((?:(?!<<).|[\n])*)>>|<<\/([a-zA-Z]+)>>/g;
const blockRegex = /<<(?<openTag>kb|if|ol|table)|(?<openTagClose>>>)|(?<closeTag><<\/[a-zA-Z]+>>)/g;

enum MessageCode {
    MISSING_OPEN_TAG,
    MISSING_CLOSE_TAG,
    INVALID_TAG_NAME,
    OPEN_TAG__END_BEFORE_OPEN_TAG_START,
    CLOSE_TAG_BEFORE_OPEN_TAG,
    KB_WITH_CONDITIONAL_SINGLE_CHILD,
    FILLABLE_NOT_EXISTS,
    FILLABLE_INVALID_PIPE,
    CONDITIONAL_INVALID_EXPRESSION,
    CONDITIONAL_INVALID_BOOLEAN_VALUE,
}

// markdown
// fillable
// condition
// kb
// signature
function _preprocess({ txt, root }: { txt: string; root?: BlockNode }) {}

const TAG_TO_TYPE_MAP: { [key: string]: Nodes["type"] } = {
    if: "conditional",
    kb: "kb",
    ol: "ol",
    table: "table",
};

function getNodeTypeByTag(tag: string) {
    if (TAG_TO_TYPE_MAP.hasOwnProperty(tag)) {
        return TAG_TO_TYPE_MAP[tag as keyof typeof TAG_TO_TYPE_MAP];
    }
    console.warn("getNodeTypeByTag invalid tag name: ", tag);
    return "text";
}

function specialTrim(content: string) {
    const MAGIC_ZxRsUNvNever = "MAGIC_ZxRsUNvNever";
    return content.replaceAll("\n\n", MAGIC_ZxRsUNvNever).trim().replaceAll(MAGIC_ZxRsUNvNever, "\n\n");
}
