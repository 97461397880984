/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

/** keep */
export enum EPrivacyPolicyHelpType {
  PrivacyPolicy_helpType_NA = 0,
  PrivacyPolicy_helpType_EMAIL = 1,
  PrivacyPolicy_helpType_LINK = 2,
  PrivacyPolicy_helpType_BOTH = 3,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyHelpTypeFromJSON(object: any): EPrivacyPolicyHelpType {
  switch (object) {
    case 0:
    case "PrivacyPolicy_helpType_NA":
      return EPrivacyPolicyHelpType.PrivacyPolicy_helpType_NA;
    case 1:
    case "PrivacyPolicy_helpType_EMAIL":
      return EPrivacyPolicyHelpType.PrivacyPolicy_helpType_EMAIL;
    case 2:
    case "PrivacyPolicy_helpType_LINK":
      return EPrivacyPolicyHelpType.PrivacyPolicy_helpType_LINK;
    case 3:
    case "PrivacyPolicy_helpType_BOTH":
      return EPrivacyPolicyHelpType.PrivacyPolicy_helpType_BOTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyHelpType.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyHelpTypeToJSON(object: EPrivacyPolicyHelpType): string {
  switch (object) {
    case EPrivacyPolicyHelpType.PrivacyPolicy_helpType_NA:
      return "PrivacyPolicy_helpType_NA";
    case EPrivacyPolicyHelpType.PrivacyPolicy_helpType_EMAIL:
      return "PrivacyPolicy_helpType_EMAIL";
    case EPrivacyPolicyHelpType.PrivacyPolicy_helpType_LINK:
      return "PrivacyPolicy_helpType_LINK";
    case EPrivacyPolicyHelpType.PrivacyPolicy_helpType_BOTH:
      return "PrivacyPolicy_helpType_BOTH";
    case EPrivacyPolicyHelpType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserContactFields {
  PrivacyPolicy_userContactFields_NA = 0,
  PrivacyPolicy_userContactFields_NAME = 1,
  PrivacyPolicy_userContactFields_ADDRESS = 2,
  PrivacyPolicy_userContactFields_PHONE = 3,
  PrivacyPolicy_userContactFields_EMAIL = 4,
  PrivacyPolicy_userContactFields_COMPANY = 5,
  PrivacyPolicy_userContactFields_TITLE = 6,
  PrivacyPolicy_userContactFields_OTHER = 8,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserContactFieldsFromJSON(object: any): EPrivacyPolicyUserContactFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userContactFields_NA":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_NA;
    case 1:
    case "PrivacyPolicy_userContactFields_NAME":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_NAME;
    case 2:
    case "PrivacyPolicy_userContactFields_ADDRESS":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_ADDRESS;
    case 3:
    case "PrivacyPolicy_userContactFields_PHONE":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_PHONE;
    case 4:
    case "PrivacyPolicy_userContactFields_EMAIL":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_EMAIL;
    case 5:
    case "PrivacyPolicy_userContactFields_COMPANY":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_COMPANY;
    case 6:
    case "PrivacyPolicy_userContactFields_TITLE":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_TITLE;
    case 8:
    case "PrivacyPolicy_userContactFields_OTHER":
      return EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserContactFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserContactFieldsToJSON(object: EPrivacyPolicyUserContactFields): string {
  switch (object) {
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_NA:
      return "PrivacyPolicy_userContactFields_NA";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_NAME:
      return "PrivacyPolicy_userContactFields_NAME";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_ADDRESS:
      return "PrivacyPolicy_userContactFields_ADDRESS";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_PHONE:
      return "PrivacyPolicy_userContactFields_PHONE";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_EMAIL:
      return "PrivacyPolicy_userContactFields_EMAIL";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_COMPANY:
      return "PrivacyPolicy_userContactFields_COMPANY";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_TITLE:
      return "PrivacyPolicy_userContactFields_TITLE";
    case EPrivacyPolicyUserContactFields.PrivacyPolicy_userContactFields_OTHER:
      return "PrivacyPolicy_userContactFields_OTHER";
    case EPrivacyPolicyUserContactFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserFinanceFields {
  PrivacyPolicy_userFinanceFields_NA = 0,
  PrivacyPolicy_userFinanceFields_CREDIT = 1,
  PrivacyPolicy_userFinanceFields_BANK = 2,
  PrivacyPolicy_userFinanceFields_EINSSN = 3,
  PrivacyPolicy_userFinanceFields_PAYPAL = 5,
  PrivacyPolicy_userFinanceFields_OTHER = 6,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserFinanceFieldsFromJSON(object: any): EPrivacyPolicyUserFinanceFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userFinanceFields_NA":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_NA;
    case 1:
    case "PrivacyPolicy_userFinanceFields_CREDIT":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_CREDIT;
    case 2:
    case "PrivacyPolicy_userFinanceFields_BANK":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_BANK;
    case 3:
    case "PrivacyPolicy_userFinanceFields_EINSSN":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_EINSSN;
    case 5:
    case "PrivacyPolicy_userFinanceFields_PAYPAL":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_PAYPAL;
    case 6:
    case "PrivacyPolicy_userFinanceFields_OTHER":
      return EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserFinanceFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserFinanceFieldsToJSON(object: EPrivacyPolicyUserFinanceFields): string {
  switch (object) {
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_NA:
      return "PrivacyPolicy_userFinanceFields_NA";
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_CREDIT:
      return "PrivacyPolicy_userFinanceFields_CREDIT";
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_BANK:
      return "PrivacyPolicy_userFinanceFields_BANK";
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_EINSSN:
      return "PrivacyPolicy_userFinanceFields_EINSSN";
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_PAYPAL:
      return "PrivacyPolicy_userFinanceFields_PAYPAL";
    case EPrivacyPolicyUserFinanceFields.PrivacyPolicy_userFinanceFields_OTHER:
      return "PrivacyPolicy_userFinanceFields_OTHER";
    case EPrivacyPolicyUserFinanceFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserOtherFields {
  PrivacyPolicy_userOtherFields_NA = 0,
  PrivacyPolicy_userOtherFields_CONTACT3D = 1,
  PrivacyPolicy_userOtherFields_CONTACTORG = 2,
  PrivacyPolicy_userOtherFields_CUSTSUPPORT = 3,
  PrivacyPolicy_userOtherFields_OTHERCONV = 4,
  PrivacyPolicy_userOtherFields_USERSHARED = 5,
  PrivacyPolicy_userOtherFields_OTHER = 6,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserOtherFieldsFromJSON(object: any): EPrivacyPolicyUserOtherFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userOtherFields_NA":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_NA;
    case 1:
    case "PrivacyPolicy_userOtherFields_CONTACT3D":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CONTACT3D;
    case 2:
    case "PrivacyPolicy_userOtherFields_CONTACTORG":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CONTACTORG;
    case 3:
    case "PrivacyPolicy_userOtherFields_CUSTSUPPORT":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CUSTSUPPORT;
    case 4:
    case "PrivacyPolicy_userOtherFields_OTHERCONV":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_OTHERCONV;
    case 5:
    case "PrivacyPolicy_userOtherFields_USERSHARED":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_USERSHARED;
    case 6:
    case "PrivacyPolicy_userOtherFields_OTHER":
      return EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserOtherFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserOtherFieldsToJSON(object: EPrivacyPolicyUserOtherFields): string {
  switch (object) {
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_NA:
      return "PrivacyPolicy_userOtherFields_NA";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CONTACT3D:
      return "PrivacyPolicy_userOtherFields_CONTACT3D";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CONTACTORG:
      return "PrivacyPolicy_userOtherFields_CONTACTORG";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_CUSTSUPPORT:
      return "PrivacyPolicy_userOtherFields_CUSTSUPPORT";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_OTHERCONV:
      return "PrivacyPolicy_userOtherFields_OTHERCONV";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_USERSHARED:
      return "PrivacyPolicy_userOtherFields_USERSHARED";
    case EPrivacyPolicyUserOtherFields.PrivacyPolicy_userOtherFields_OTHER:
      return "PrivacyPolicy_userOtherFields_OTHER";
    case EPrivacyPolicyUserOtherFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserIdentifyFields {
  PrivacyPolicy_userIdentifyFields_NA = 0,
  PrivacyPolicy_userIdentifyFields_USERNAMES = 1,
  PrivacyPolicy_userIdentifyFields_PASSWORDS = 2,
  PrivacyPolicy_userIdentifyFields_ROLES = 3,
  PrivacyPolicy_userIdentifyFields_PROFILES = 4,
  PrivacyPolicy_userIdentifyFields_DOB = 5,
  PrivacyPolicy_userIdentifyFields_PICVIDEO = 6,
  PrivacyPolicy_userIdentifyFields_VOICE = 7,
  PrivacyPolicy_userIdentifyFields_AUTHID = 8,
  PrivacyPolicy_userIdentifyFields_OTHER = 9,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserIdentifyFieldsFromJSON(object: any): EPrivacyPolicyUserIdentifyFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userIdentifyFields_NA":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_NA;
    case 1:
    case "PrivacyPolicy_userIdentifyFields_USERNAMES":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_USERNAMES;
    case 2:
    case "PrivacyPolicy_userIdentifyFields_PASSWORDS":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PASSWORDS;
    case 3:
    case "PrivacyPolicy_userIdentifyFields_ROLES":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_ROLES;
    case 4:
    case "PrivacyPolicy_userIdentifyFields_PROFILES":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PROFILES;
    case 5:
    case "PrivacyPolicy_userIdentifyFields_DOB":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_DOB;
    case 6:
    case "PrivacyPolicy_userIdentifyFields_PICVIDEO":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PICVIDEO;
    case 7:
    case "PrivacyPolicy_userIdentifyFields_VOICE":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_VOICE;
    case 8:
    case "PrivacyPolicy_userIdentifyFields_AUTHID":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_AUTHID;
    case 9:
    case "PrivacyPolicy_userIdentifyFields_OTHER":
      return EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserIdentifyFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserIdentifyFieldsToJSON(object: EPrivacyPolicyUserIdentifyFields): string {
  switch (object) {
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_NA:
      return "PrivacyPolicy_userIdentifyFields_NA";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_USERNAMES:
      return "PrivacyPolicy_userIdentifyFields_USERNAMES";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PASSWORDS:
      return "PrivacyPolicy_userIdentifyFields_PASSWORDS";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_ROLES:
      return "PrivacyPolicy_userIdentifyFields_ROLES";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PROFILES:
      return "PrivacyPolicy_userIdentifyFields_PROFILES";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_DOB:
      return "PrivacyPolicy_userIdentifyFields_DOB";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_PICVIDEO:
      return "PrivacyPolicy_userIdentifyFields_PICVIDEO";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_VOICE:
      return "PrivacyPolicy_userIdentifyFields_VOICE";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_AUTHID:
      return "PrivacyPolicy_userIdentifyFields_AUTHID";
    case EPrivacyPolicyUserIdentifyFields.PrivacyPolicy_userIdentifyFields_OTHER:
      return "PrivacyPolicy_userIdentifyFields_OTHER";
    case EPrivacyPolicyUserIdentifyFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserUsageFields {
  PrivacyPolicy_userUsageFields_NA = 0,
  PrivacyPolicy_userUsageFields_CONTACTPREF = 1,
  PrivacyPolicy_userUsageFields_LOCATION = 2,
  PrivacyPolicy_userUsageFields_OTHER = 3,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserUsageFieldsFromJSON(object: any): EPrivacyPolicyUserUsageFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userUsageFields_NA":
      return EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_NA;
    case 1:
    case "PrivacyPolicy_userUsageFields_CONTACTPREF":
      return EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_CONTACTPREF;
    case 2:
    case "PrivacyPolicy_userUsageFields_LOCATION":
      return EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_LOCATION;
    case 3:
    case "PrivacyPolicy_userUsageFields_OTHER":
      return EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserUsageFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserUsageFieldsToJSON(object: EPrivacyPolicyUserUsageFields): string {
  switch (object) {
    case EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_NA:
      return "PrivacyPolicy_userUsageFields_NA";
    case EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_CONTACTPREF:
      return "PrivacyPolicy_userUsageFields_CONTACTPREF";
    case EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_LOCATION:
      return "PrivacyPolicy_userUsageFields_LOCATION";
    case EPrivacyPolicyUserUsageFields.PrivacyPolicy_userUsageFields_OTHER:
      return "PrivacyPolicy_userUsageFields_OTHER";
    case EPrivacyPolicyUserUsageFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserSensitiveFields {
  PrivacyPolicy_userSensitiveFields_NA = 0,
  PrivacyPolicy_userSensitiveFields_BIOMETRIC = 1,
  PrivacyPolicy_userSensitiveFields_CRIMINAL = 2,
  PrivacyPolicy_userSensitiveFields_GENDER = 3,
  PrivacyPolicy_userSensitiveFields_HEALTH = 4,
  PrivacyPolicy_userSensitiveFields_MARITAL = 5,
  PrivacyPolicy_userSensitiveFields_BELIEFS = 6,
  PrivacyPolicy_userSensitiveFields_RACE = 7,
  PrivacyPolicy_userSensitiveFields_RELIGION = 8,
  PrivacyPolicy_userSensitiveFields_SEXORIENTATION = 9,
  PrivacyPolicy_userSensitiveFields_SEXPRACTICES = 10,
  PrivacyPolicy_userSensitiveFields_UNION = 11,
  PrivacyPolicy_userSensitiveFields_INCOME = 12,
  PrivacyPolicy_userSensitiveFields_OTHER = 13,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserSensitiveFieldsFromJSON(object: any): EPrivacyPolicyUserSensitiveFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userSensitiveFields_NA":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_NA;
    case 1:
    case "PrivacyPolicy_userSensitiveFields_BIOMETRIC":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_BIOMETRIC;
    case 2:
    case "PrivacyPolicy_userSensitiveFields_CRIMINAL":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_CRIMINAL;
    case 3:
    case "PrivacyPolicy_userSensitiveFields_GENDER":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_GENDER;
    case 4:
    case "PrivacyPolicy_userSensitiveFields_HEALTH":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_HEALTH;
    case 5:
    case "PrivacyPolicy_userSensitiveFields_MARITAL":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_MARITAL;
    case 6:
    case "PrivacyPolicy_userSensitiveFields_BELIEFS":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_BELIEFS;
    case 7:
    case "PrivacyPolicy_userSensitiveFields_RACE":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_RACE;
    case 8:
    case "PrivacyPolicy_userSensitiveFields_RELIGION":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_RELIGION;
    case 9:
    case "PrivacyPolicy_userSensitiveFields_SEXORIENTATION":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_SEXORIENTATION;
    case 10:
    case "PrivacyPolicy_userSensitiveFields_SEXPRACTICES":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_SEXPRACTICES;
    case 11:
    case "PrivacyPolicy_userSensitiveFields_UNION":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_UNION;
    case 12:
    case "PrivacyPolicy_userSensitiveFields_INCOME":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_INCOME;
    case 13:
    case "PrivacyPolicy_userSensitiveFields_OTHER":
      return EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserSensitiveFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserSensitiveFieldsToJSON(object: EPrivacyPolicyUserSensitiveFields): string {
  switch (object) {
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_NA:
      return "PrivacyPolicy_userSensitiveFields_NA";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_BIOMETRIC:
      return "PrivacyPolicy_userSensitiveFields_BIOMETRIC";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_CRIMINAL:
      return "PrivacyPolicy_userSensitiveFields_CRIMINAL";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_GENDER:
      return "PrivacyPolicy_userSensitiveFields_GENDER";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_HEALTH:
      return "PrivacyPolicy_userSensitiveFields_HEALTH";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_MARITAL:
      return "PrivacyPolicy_userSensitiveFields_MARITAL";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_BELIEFS:
      return "PrivacyPolicy_userSensitiveFields_BELIEFS";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_RACE:
      return "PrivacyPolicy_userSensitiveFields_RACE";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_RELIGION:
      return "PrivacyPolicy_userSensitiveFields_RELIGION";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_SEXORIENTATION:
      return "PrivacyPolicy_userSensitiveFields_SEXORIENTATION";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_SEXPRACTICES:
      return "PrivacyPolicy_userSensitiveFields_SEXPRACTICES";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_UNION:
      return "PrivacyPolicy_userSensitiveFields_UNION";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_INCOME:
      return "PrivacyPolicy_userSensitiveFields_INCOME";
    case EPrivacyPolicyUserSensitiveFields.PrivacyPolicy_userSensitiveFields_OTHER:
      return "PrivacyPolicy_userSensitiveFields_OTHER";
    case EPrivacyPolicyUserSensitiveFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyThirdTechFields {
  PrivacyPolicy_thirdTechFields_NA = 0,
  PrivacyPolicy_thirdTechFields_COOKIES = 1,
  PrivacyPolicy_thirdTechFields_GOOGLE = 2,
  PrivacyPolicy_thirdTechFields_BEACONS = 3,
  PrivacyPolicy_thirdTechFields_SURVEYS = 4,
  PrivacyPolicy_thirdTechFields_WIDGETS = 5,
  PrivacyPolicy_thirdTechFields_ANALYTICS = 6,
  PrivacyPolicy_thirdTechFields_ADSERVICE = 7,
  PrivacyPolicy_thirdTechFields_ADDISPLAY = 8,
  PrivacyPolicy_thirdTechFields_EMAILSERVICE = 9,
  PrivacyPolicy_thirdTechFields_PAYMENTS = 10,
  PrivacyPolicy_thirdTechFields_OTHERDATA = 11,
  PrivacyPolicy_thirdTechFields_OTHER = 12,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyThirdTechFieldsFromJSON(object: any): EPrivacyPolicyThirdTechFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_thirdTechFields_NA":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_NA;
    case 1:
    case "PrivacyPolicy_thirdTechFields_COOKIES":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_COOKIES;
    case 2:
    case "PrivacyPolicy_thirdTechFields_GOOGLE":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_GOOGLE;
    case 3:
    case "PrivacyPolicy_thirdTechFields_BEACONS":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_BEACONS;
    case 4:
    case "PrivacyPolicy_thirdTechFields_SURVEYS":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_SURVEYS;
    case 5:
    case "PrivacyPolicy_thirdTechFields_WIDGETS":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_WIDGETS;
    case 6:
    case "PrivacyPolicy_thirdTechFields_ANALYTICS":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ANALYTICS;
    case 7:
    case "PrivacyPolicy_thirdTechFields_ADSERVICE":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADSERVICE;
    case 8:
    case "PrivacyPolicy_thirdTechFields_ADDISPLAY":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADDISPLAY;
    case 9:
    case "PrivacyPolicy_thirdTechFields_EMAILSERVICE":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_EMAILSERVICE;
    case 10:
    case "PrivacyPolicy_thirdTechFields_PAYMENTS":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_PAYMENTS;
    case 11:
    case "PrivacyPolicy_thirdTechFields_OTHERDATA":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_OTHERDATA;
    case 12:
    case "PrivacyPolicy_thirdTechFields_OTHER":
      return EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyThirdTechFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyThirdTechFieldsToJSON(object: EPrivacyPolicyThirdTechFields): string {
  switch (object) {
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_NA:
      return "PrivacyPolicy_thirdTechFields_NA";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_COOKIES:
      return "PrivacyPolicy_thirdTechFields_COOKIES";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_GOOGLE:
      return "PrivacyPolicy_thirdTechFields_GOOGLE";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_BEACONS:
      return "PrivacyPolicy_thirdTechFields_BEACONS";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_SURVEYS:
      return "PrivacyPolicy_thirdTechFields_SURVEYS";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_WIDGETS:
      return "PrivacyPolicy_thirdTechFields_WIDGETS";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ANALYTICS:
      return "PrivacyPolicy_thirdTechFields_ANALYTICS";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADSERVICE:
      return "PrivacyPolicy_thirdTechFields_ADSERVICE";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADDISPLAY:
      return "PrivacyPolicy_thirdTechFields_ADDISPLAY";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_EMAILSERVICE:
      return "PrivacyPolicy_thirdTechFields_EMAILSERVICE";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_PAYMENTS:
      return "PrivacyPolicy_thirdTechFields_PAYMENTS";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_OTHERDATA:
      return "PrivacyPolicy_thirdTechFields_OTHERDATA";
    case EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_OTHER:
      return "PrivacyPolicy_thirdTechFields_OTHER";
    case EPrivacyPolicyThirdTechFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyWidgetFields {
  PrivacyPolicy_widgetFields_NA = 0,
  PrivacyPolicy_widgetFields_DRIFT = 1,
  PrivacyPolicy_widgetFields_INTERCOM = 2,
  PrivacyPolicy_widgetFields_OLARK = 3,
  PrivacyPolicy_widgetFields_ADDTHIS = 5,
  PrivacyPolicy_widgetFields_GOOGLEMAPS = 6,
  PrivacyPolicy_widgetFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyWidgetFieldsFromJSON(object: any): EPrivacyPolicyWidgetFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_widgetFields_NA":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_NA;
    case 1:
    case "PrivacyPolicy_widgetFields_DRIFT":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_DRIFT;
    case 2:
    case "PrivacyPolicy_widgetFields_INTERCOM":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_INTERCOM;
    case 3:
    case "PrivacyPolicy_widgetFields_OLARK":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_OLARK;
    case 5:
    case "PrivacyPolicy_widgetFields_ADDTHIS":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_ADDTHIS;
    case 6:
    case "PrivacyPolicy_widgetFields_GOOGLEMAPS":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_GOOGLEMAPS;
    case 999999:
    case "PrivacyPolicy_widgetFields_OTHER":
      return EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyWidgetFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyWidgetFieldsToJSON(object: EPrivacyPolicyWidgetFields): string {
  switch (object) {
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_NA:
      return "PrivacyPolicy_widgetFields_NA";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_DRIFT:
      return "PrivacyPolicy_widgetFields_DRIFT";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_INTERCOM:
      return "PrivacyPolicy_widgetFields_INTERCOM";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_OLARK:
      return "PrivacyPolicy_widgetFields_OLARK";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_ADDTHIS:
      return "PrivacyPolicy_widgetFields_ADDTHIS";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_GOOGLEMAPS:
      return "PrivacyPolicy_widgetFields_GOOGLEMAPS";
    case EPrivacyPolicyWidgetFields.PrivacyPolicy_widgetFields_OTHER:
      return "PrivacyPolicy_widgetFields_OTHER";
    case EPrivacyPolicyWidgetFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyAnalyticFields {
  PrivacyPolicy_analyticFields_NA = 0,
  PrivacyPolicy_analyticFields_CLICKY = 1,
  PrivacyPolicy_analyticFields_CRAZY = 2,
  PrivacyPolicy_analyticFields_KISSMETRICS = 3,
  PrivacyPolicy_analyticFields_MIXPANEL = 4,
  PrivacyPolicy_analyticFields_OPENWEB = 5,
  PrivacyPolicy_analyticFields_STATCOUNTER = 6,
  PrivacyPolicy_analyticFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyAnalyticFieldsFromJSON(object: any): EPrivacyPolicyAnalyticFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_analyticFields_NA":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_NA;
    case 1:
    case "PrivacyPolicy_analyticFields_CLICKY":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CLICKY;
    case 2:
    case "PrivacyPolicy_analyticFields_CRAZY":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CRAZY;
    case 3:
    case "PrivacyPolicy_analyticFields_KISSMETRICS":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_KISSMETRICS;
    case 4:
    case "PrivacyPolicy_analyticFields_MIXPANEL":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_MIXPANEL;
    case 5:
    case "PrivacyPolicy_analyticFields_OPENWEB":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_OPENWEB;
    case 6:
    case "PrivacyPolicy_analyticFields_STATCOUNTER":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_STATCOUNTER;
    case 999999:
    case "PrivacyPolicy_analyticFields_OTHER":
      return EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyAnalyticFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyAnalyticFieldsToJSON(object: EPrivacyPolicyAnalyticFields): string {
  switch (object) {
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_NA:
      return "PrivacyPolicy_analyticFields_NA";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CLICKY:
      return "PrivacyPolicy_analyticFields_CLICKY";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_CRAZY:
      return "PrivacyPolicy_analyticFields_CRAZY";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_KISSMETRICS:
      return "PrivacyPolicy_analyticFields_KISSMETRICS";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_MIXPANEL:
      return "PrivacyPolicy_analyticFields_MIXPANEL";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_OPENWEB:
      return "PrivacyPolicy_analyticFields_OPENWEB";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_STATCOUNTER:
      return "PrivacyPolicy_analyticFields_STATCOUNTER";
    case EPrivacyPolicyAnalyticFields.PrivacyPolicy_analyticFields_OTHER:
      return "PrivacyPolicy_analyticFields_OTHER";
    case EPrivacyPolicyAnalyticFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyAdserviceFields {
  PrivacyPolicy_adserviceFields_NA = 0,
  PrivacyPolicy_adserviceFields_PIXEL = 1,
  PrivacyPolicy_adserviceFields_HUBSPOT = 2,
  PrivacyPolicy_adserviceFields_PARDOT = 3,
  PrivacyPolicy_adserviceFields_MARKETO = 4,
  PrivacyPolicy_adserviceFields_TIKTOK_PIXEL = 5,
  PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL = 6,
  PrivacyPolicy_adserviceFields_TABOOLA_PIXEL = 7,
  PrivacyPolicy_adserviceFields_TWITTER_PIXEL = 8,
  PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL = 9,
  PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL = 10,
  PrivacyPolicy_adserviceFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyAdserviceFieldsFromJSON(object: any): EPrivacyPolicyAdserviceFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_adserviceFields_NA":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_NA;
    case 1:
    case "PrivacyPolicy_adserviceFields_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PIXEL;
    case 2:
    case "PrivacyPolicy_adserviceFields_HUBSPOT":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_HUBSPOT;
    case 3:
    case "PrivacyPolicy_adserviceFields_PARDOT":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PARDOT;
    case 4:
    case "PrivacyPolicy_adserviceFields_MARKETO":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_MARKETO;
    case 5:
    case "PrivacyPolicy_adserviceFields_TIKTOK_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TIKTOK_PIXEL;
    case 6:
    case "PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL;
    case 7:
    case "PrivacyPolicy_adserviceFields_TABOOLA_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TABOOLA_PIXEL;
    case 8:
    case "PrivacyPolicy_adserviceFields_TWITTER_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TWITTER_PIXEL;
    case 9:
    case "PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL;
    case 10:
    case "PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL;
    case 999999:
    case "PrivacyPolicy_adserviceFields_OTHER":
      return EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyAdserviceFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyAdserviceFieldsToJSON(object: EPrivacyPolicyAdserviceFields): string {
  switch (object) {
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_NA:
      return "PrivacyPolicy_adserviceFields_NA";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PIXEL:
      return "PrivacyPolicy_adserviceFields_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_HUBSPOT:
      return "PrivacyPolicy_adserviceFields_HUBSPOT";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_PARDOT:
      return "PrivacyPolicy_adserviceFields_PARDOT";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_MARKETO:
      return "PrivacyPolicy_adserviceFields_MARKETO";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TIKTOK_PIXEL:
      return "PrivacyPolicy_adserviceFields_TIKTOK_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL:
      return "PrivacyPolicy_adserviceFields_SNAPCHAT_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TABOOLA_PIXEL:
      return "PrivacyPolicy_adserviceFields_TABOOLA_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_TWITTER_PIXEL:
      return "PrivacyPolicy_adserviceFields_TWITTER_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL:
      return "PrivacyPolicy_adserviceFields_LINKEDIN_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL:
      return "PrivacyPolicy_adserviceFields_OUTBRAIN_PIXEL";
    case EPrivacyPolicyAdserviceFields.PrivacyPolicy_adserviceFields_OTHER:
      return "PrivacyPolicy_adserviceFields_OTHER";
    case EPrivacyPolicyAdserviceFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyAdDisplayFields {
  PrivacyPolicy_adDisplayFields_NA = 0,
  PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE = 1,
  PrivacyPolicy_adDisplayFields_BING_ADS = 2,
  PrivacyPolicy_adDisplayFields_ADCOLONY = 3,
  PrivacyPolicy_adDisplayFields_APPLOVIN = 4,
  PrivacyPolicy_adDisplayFields_FACEBOOK_ADS = 5,
  PrivacyPolicy_adDisplayFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyAdDisplayFieldsFromJSON(object: any): EPrivacyPolicyAdDisplayFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_adDisplayFields_NA":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_NA;
    case 1:
    case "PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE;
    case 2:
    case "PrivacyPolicy_adDisplayFields_BING_ADS":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_BING_ADS;
    case 3:
    case "PrivacyPolicy_adDisplayFields_ADCOLONY":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_ADCOLONY;
    case 4:
    case "PrivacyPolicy_adDisplayFields_APPLOVIN":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_APPLOVIN;
    case 5:
    case "PrivacyPolicy_adDisplayFields_FACEBOOK_ADS":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_FACEBOOK_ADS;
    case 999999:
    case "PrivacyPolicy_adDisplayFields_OTHER":
      return EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyAdDisplayFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyAdDisplayFieldsToJSON(object: EPrivacyPolicyAdDisplayFields): string {
  switch (object) {
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_NA:
      return "PrivacyPolicy_adDisplayFields_NA";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE:
      return "PrivacyPolicy_adDisplayFields_GOOGLE_ADSENSE";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_BING_ADS:
      return "PrivacyPolicy_adDisplayFields_BING_ADS";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_ADCOLONY:
      return "PrivacyPolicy_adDisplayFields_ADCOLONY";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_APPLOVIN:
      return "PrivacyPolicy_adDisplayFields_APPLOVIN";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_FACEBOOK_ADS:
      return "PrivacyPolicy_adDisplayFields_FACEBOOK_ADS";
    case EPrivacyPolicyAdDisplayFields.PrivacyPolicy_adDisplayFields_OTHER:
      return "PrivacyPolicy_adDisplayFields_OTHER";
    case EPrivacyPolicyAdDisplayFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyEmailServiceFields {
  PrivacyPolicy_emailServiceFields_NA = 0,
  PrivacyPolicy_emailServiceFields_MAILCHIMP = 1,
  PrivacyPolicy_emailServiceFields_TINYLETTER = 2,
  PrivacyPolicy_emailServiceFields_AWEBER = 3,
  PrivacyPolicy_emailServiceFields_MAILERLITE = 4,
  PrivacyPolicy_emailServiceFields_SENDFOX = 5,
  PrivacyPolicy_emailServiceFields_CAMPAIGNMONITOR = 6,
  PrivacyPolicy_emailServiceFields_SALESFORCE = 7,
  PrivacyPolicy_emailServiceFields_PARDOT = 8,
  PrivacyPolicy_emailServiceFields_HUBSPOT = 9,
  PrivacyPolicy_emailServiceFields_GODADDY = 10,
  PrivacyPolicy_emailServiceFields_POSTMARK = 11,
  PrivacyPolicy_emailServiceFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyEmailServiceFieldsFromJSON(object: any): EPrivacyPolicyEmailServiceFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_emailServiceFields_NA":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_NA;
    case 1:
    case "PrivacyPolicy_emailServiceFields_MAILCHIMP":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_MAILCHIMP;
    case 2:
    case "PrivacyPolicy_emailServiceFields_TINYLETTER":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_TINYLETTER;
    case 3:
    case "PrivacyPolicy_emailServiceFields_AWEBER":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_AWEBER;
    case 4:
    case "PrivacyPolicy_emailServiceFields_MAILERLITE":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_MAILERLITE;
    case 5:
    case "PrivacyPolicy_emailServiceFields_SENDFOX":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_SENDFOX;
    case 6:
    case "PrivacyPolicy_emailServiceFields_CAMPAIGNMONITOR":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_CAMPAIGNMONITOR;
    case 7:
    case "PrivacyPolicy_emailServiceFields_SALESFORCE":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_SALESFORCE;
    case 8:
    case "PrivacyPolicy_emailServiceFields_PARDOT":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_PARDOT;
    case 9:
    case "PrivacyPolicy_emailServiceFields_HUBSPOT":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_HUBSPOT;
    case 10:
    case "PrivacyPolicy_emailServiceFields_GODADDY":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_GODADDY;
    case 11:
    case "PrivacyPolicy_emailServiceFields_POSTMARK":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_POSTMARK;
    case 999999:
    case "PrivacyPolicy_emailServiceFields_OTHER":
      return EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyEmailServiceFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyEmailServiceFieldsToJSON(object: EPrivacyPolicyEmailServiceFields): string {
  switch (object) {
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_NA:
      return "PrivacyPolicy_emailServiceFields_NA";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_MAILCHIMP:
      return "PrivacyPolicy_emailServiceFields_MAILCHIMP";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_TINYLETTER:
      return "PrivacyPolicy_emailServiceFields_TINYLETTER";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_AWEBER:
      return "PrivacyPolicy_emailServiceFields_AWEBER";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_MAILERLITE:
      return "PrivacyPolicy_emailServiceFields_MAILERLITE";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_SENDFOX:
      return "PrivacyPolicy_emailServiceFields_SENDFOX";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_CAMPAIGNMONITOR:
      return "PrivacyPolicy_emailServiceFields_CAMPAIGNMONITOR";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_SALESFORCE:
      return "PrivacyPolicy_emailServiceFields_SALESFORCE";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_PARDOT:
      return "PrivacyPolicy_emailServiceFields_PARDOT";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_HUBSPOT:
      return "PrivacyPolicy_emailServiceFields_HUBSPOT";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_GODADDY:
      return "PrivacyPolicy_emailServiceFields_GODADDY";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_POSTMARK:
      return "PrivacyPolicy_emailServiceFields_POSTMARK";
    case EPrivacyPolicyEmailServiceFields.PrivacyPolicy_emailServiceFields_OTHER:
      return "PrivacyPolicy_emailServiceFields_OTHER";
    case EPrivacyPolicyEmailServiceFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyOtherDataFields {
  PrivacyPolicy_otherDataFields_NA = 0,
  PrivacyPolicy_otherDataFields_GEOIP = 1,
  PrivacyPolicy_otherDataFields_GOOGLEMAPS = 2,
  PrivacyPolicy_otherDataFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyOtherDataFieldsFromJSON(object: any): EPrivacyPolicyOtherDataFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_otherDataFields_NA":
      return EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_NA;
    case 1:
    case "PrivacyPolicy_otherDataFields_GEOIP":
      return EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_GEOIP;
    case 2:
    case "PrivacyPolicy_otherDataFields_GOOGLEMAPS":
      return EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_GOOGLEMAPS;
    case 999999:
    case "PrivacyPolicy_otherDataFields_OTHER":
      return EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyOtherDataFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyOtherDataFieldsToJSON(object: EPrivacyPolicyOtherDataFields): string {
  switch (object) {
    case EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_NA:
      return "PrivacyPolicy_otherDataFields_NA";
    case EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_GEOIP:
      return "PrivacyPolicy_otherDataFields_GEOIP";
    case EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_GOOGLEMAPS:
      return "PrivacyPolicy_otherDataFields_GOOGLEMAPS";
    case EPrivacyPolicyOtherDataFields.PrivacyPolicy_otherDataFields_OTHER:
      return "PrivacyPolicy_otherDataFields_OTHER";
    case EPrivacyPolicyOtherDataFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyPaymentFields {
  PrivacyPolicy_paymentFields_NA = 0,
  PrivacyPolicy_paymentFields_PAYPAL = 1,
  PrivacyPolicy_paymentFields_SQUARE = 2,
  PrivacyPolicy_paymentFields_STRIPE = 3,
  PrivacyPolicy_paymentFields_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyPaymentFieldsFromJSON(object: any): EPrivacyPolicyPaymentFields {
  switch (object) {
    case 0:
    case "PrivacyPolicy_paymentFields_NA":
      return EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_NA;
    case 1:
    case "PrivacyPolicy_paymentFields_PAYPAL":
      return EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_PAYPAL;
    case 2:
    case "PrivacyPolicy_paymentFields_SQUARE":
      return EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_SQUARE;
    case 3:
    case "PrivacyPolicy_paymentFields_STRIPE":
      return EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_STRIPE;
    case 999999:
    case "PrivacyPolicy_paymentFields_OTHER":
      return EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyPaymentFields.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyPaymentFieldsToJSON(object: EPrivacyPolicyPaymentFields): string {
  switch (object) {
    case EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_NA:
      return "PrivacyPolicy_paymentFields_NA";
    case EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_PAYPAL:
      return "PrivacyPolicy_paymentFields_PAYPAL";
    case EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_SQUARE:
      return "PrivacyPolicy_paymentFields_SQUARE";
    case EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_STRIPE:
      return "PrivacyPolicy_paymentFields_STRIPE";
    case EPrivacyPolicyPaymentFields.PrivacyPolicy_paymentFields_OTHER:
      return "PrivacyPolicy_paymentFields_OTHER";
    case EPrivacyPolicyPaymentFields.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserCalifornia {
  PrivacyPolicy_userCalifornia_NA = 0,
  PrivacyPolicy_userCalifornia_PROFIT = 1,
  PrivacyPolicy_userCalifornia_CONSUMERINFO = 2,
  PrivacyPolicy_userCalifornia_DOBUSINESS = 3,
  PrivacyPolicy_userCalifornia_NONE = 4,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserCaliforniaFromJSON(object: any): EPrivacyPolicyUserCalifornia {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userCalifornia_NA":
      return EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_NA;
    case 1:
    case "PrivacyPolicy_userCalifornia_PROFIT":
      return EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_PROFIT;
    case 2:
    case "PrivacyPolicy_userCalifornia_CONSUMERINFO":
      return EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_CONSUMERINFO;
    case 3:
    case "PrivacyPolicy_userCalifornia_DOBUSINESS":
      return EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_DOBUSINESS;
    case 4:
    case "PrivacyPolicy_userCalifornia_NONE":
      return EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserCalifornia.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserCaliforniaToJSON(object: EPrivacyPolicyUserCalifornia): string {
  switch (object) {
    case EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_NA:
      return "PrivacyPolicy_userCalifornia_NA";
    case EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_PROFIT:
      return "PrivacyPolicy_userCalifornia_PROFIT";
    case EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_CONSUMERINFO:
      return "PrivacyPolicy_userCalifornia_CONSUMERINFO";
    case EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_DOBUSINESS:
      return "PrivacyPolicy_userCalifornia_DOBUSINESS";
    case EPrivacyPolicyUserCalifornia.PrivacyPolicy_userCalifornia_NONE:
      return "PrivacyPolicy_userCalifornia_NONE";
    case EPrivacyPolicyUserCalifornia.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyUserCalsize {
  PrivacyPolicy_userCalsize_NA = 0,
  PrivacyPolicy_userCalsize_REVENUE = 1,
  PrivacyPolicy_userCalsize_USERS = 2,
  PrivacyPolicy_userCalsize_SELLINFO = 3,
  PrivacyPolicy_userCalsize_NONE = 4,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyUserCalsizeFromJSON(object: any): EPrivacyPolicyUserCalsize {
  switch (object) {
    case 0:
    case "PrivacyPolicy_userCalsize_NA":
      return EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_NA;
    case 1:
    case "PrivacyPolicy_userCalsize_REVENUE":
      return EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_REVENUE;
    case 2:
    case "PrivacyPolicy_userCalsize_USERS":
      return EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_USERS;
    case 3:
    case "PrivacyPolicy_userCalsize_SELLINFO":
      return EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_SELLINFO;
    case 4:
    case "PrivacyPolicy_userCalsize_NONE":
      return EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyUserCalsize.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyUserCalsizeToJSON(object: EPrivacyPolicyUserCalsize): string {
  switch (object) {
    case EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_NA:
      return "PrivacyPolicy_userCalsize_NA";
    case EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_REVENUE:
      return "PrivacyPolicy_userCalsize_REVENUE";
    case EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_USERS:
      return "PrivacyPolicy_userCalsize_USERS";
    case EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_SELLINFO:
      return "PrivacyPolicy_userCalsize_SELLINFO";
    case EPrivacyPolicyUserCalsize.PrivacyPolicy_userCalsize_NONE:
      return "PrivacyPolicy_userCalsize_NONE";
    case EPrivacyPolicyUserCalsize.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EPrivacyPolicyHelpTypeMulti {
  PrivacyPolicy_helpTypeMulti_NA = 0,
  PrivacyPolicy_helpTypeMulti_EMAIL = 1,
  PrivacyPolicy_helpTypeMulti_LINK = 2,
  UNRECOGNIZED = -1,
}

export function ePrivacyPolicyHelpTypeMultiFromJSON(object: any): EPrivacyPolicyHelpTypeMulti {
  switch (object) {
    case 0:
    case "PrivacyPolicy_helpTypeMulti_NA":
      return EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_NA;
    case 1:
    case "PrivacyPolicy_helpTypeMulti_EMAIL":
      return EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_EMAIL;
    case 2:
    case "PrivacyPolicy_helpTypeMulti_LINK":
      return EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_LINK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EPrivacyPolicyHelpTypeMulti.UNRECOGNIZED;
  }
}

export function ePrivacyPolicyHelpTypeMultiToJSON(object: EPrivacyPolicyHelpTypeMulti): string {
  switch (object) {
    case EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_NA:
      return "PrivacyPolicy_helpTypeMulti_NA";
    case EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_EMAIL:
      return "PrivacyPolicy_helpTypeMulti_EMAIL";
    case EPrivacyPolicyHelpTypeMulti.PrivacyPolicy_helpTypeMulti_LINK:
      return "PrivacyPolicy_helpTypeMulti_LINK";
    case EPrivacyPolicyHelpTypeMulti.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MPrivacyPolicyHelpType {
  optionDbValue: EPrivacyPolicyHelpType;
  other: string;
}

export interface MPrivacyPolicyUserContactFields {
  optionDbValue: EPrivacyPolicyUserContactFields[];
  other: string;
}

export interface MPrivacyPolicyUserFinanceFields {
  optionDbValue: EPrivacyPolicyUserFinanceFields[];
  other: string;
}

export interface MPrivacyPolicyUserOtherFields {
  optionDbValue: EPrivacyPolicyUserOtherFields[];
  other: string;
}

export interface MPrivacyPolicyUserIdentifyFields {
  optionDbValue: EPrivacyPolicyUserIdentifyFields[];
  other: string;
}

export interface MPrivacyPolicyUserUsageFields {
  optionDbValue: EPrivacyPolicyUserUsageFields[];
  other: string;
}

export interface MPrivacyPolicyUserSensitiveFields {
  optionDbValue: EPrivacyPolicyUserSensitiveFields[];
  other: string;
}

export interface MPrivacyPolicyThirdTechFields {
  optionDbValue: EPrivacyPolicyThirdTechFields[];
  other: string;
}

export interface MPrivacyPolicyWidgetFields {
  optionDbValue: EPrivacyPolicyWidgetFields[];
  other: string;
}

export interface MPrivacyPolicyAnalyticFields {
  optionDbValue: EPrivacyPolicyAnalyticFields[];
  other: string;
}

export interface MPrivacyPolicyAdserviceFields {
  optionDbValue: EPrivacyPolicyAdserviceFields[];
  other: string;
}

export interface MPrivacyPolicyAdDisplayFields {
  optionDbValue: EPrivacyPolicyAdDisplayFields[];
  other: string;
}

export interface MPrivacyPolicyEmailServiceFields {
  optionDbValue: EPrivacyPolicyEmailServiceFields[];
  other: string;
}

export interface MPrivacyPolicyOtherDataFields {
  optionDbValue: EPrivacyPolicyOtherDataFields[];
  other: string;
}

export interface MPrivacyPolicyPaymentFields {
  optionDbValue: EPrivacyPolicyPaymentFields[];
  other: string;
}

export interface MPrivacyPolicyUserCalifornia {
  optionDbValue: EPrivacyPolicyUserCalifornia[];
  other: string;
}

export interface MPrivacyPolicyUserCalsize {
  optionDbValue: EPrivacyPolicyUserCalsize[];
  other: string;
}

export interface MPrivacyPolicyHelpTypeMulti {
  optionDbValue: EPrivacyPolicyHelpTypeMulti[];
  other: string;
}

export interface PrivacyPolicy {
  companyShort: string;
  websiteUrl: string;
  helpEmail: string;
  helpLink: string;
  helpAddress: string;
  privacyUrl: string;
  /** keep */
  helpType: MPrivacyPolicyHelpType | undefined;
  userProvide: InternalBoolean;
  userContactFields: MPrivacyPolicyUserContactFields | undefined;
  userFinanceFields: MPrivacyPolicyUserFinanceFields | undefined;
  userContact: InternalBoolean;
  userFinance: InternalBoolean;
  userOtherFields: MPrivacyPolicyUserOtherFields | undefined;
  userIdentifyFields: MPrivacyPolicyUserIdentifyFields | undefined;
  userUsageFields: MPrivacyPolicyUserUsageFields | undefined;
  userSensitiveFields: MPrivacyPolicyUserSensitiveFields | undefined;
  userOther: InternalBoolean;
  userIdentify: InternalBoolean;
  userUsage: InternalBoolean;
  userSensitive: InternalBoolean;
  thirdTech: InternalBoolean;
  thirdTechFields: MPrivacyPolicyThirdTechFields | undefined;
  widgetFields: MPrivacyPolicyWidgetFields | undefined;
  analyticFields: MPrivacyPolicyAnalyticFields | undefined;
  adserviceFields: MPrivacyPolicyAdserviceFields | undefined;
  adDisplayFields: MPrivacyPolicyAdDisplayFields | undefined;
  emailServiceFields: MPrivacyPolicyEmailServiceFields | undefined;
  otherDataFields: MPrivacyPolicyOtherDataFields | undefined;
  paymentFields: MPrivacyPolicyPaymentFields | undefined;
  shareData: InternalBoolean;
  shareDataUGC: InternalBoolean;
  storeOther: string;
  storeUSA: InternalBoolean;
  userCalifornia: MPrivacyPolicyUserCalifornia | undefined;
  sellData: InternalBoolean;
  sellGPC: InternalBoolean;
  optoutLink: string;
  helpTollfree: string;
  userCalsize: MPrivacyPolicyUserCalsize | undefined;
  userCalAffiliate: InternalBoolean;
  lastUpdateTime: Date | undefined;
  onlineOnly: InternalBoolean;
  coloradoSale: InternalBoolean;
  connecticutSale: InternalBoolean;
  utahSale: InternalBoolean;
  utahSensitive: InternalBoolean;
  virginiaSale: InternalBoolean;
  dataName: string;
  dpoEmail: string;
  dataProtectionOfficer: InternalBoolean;
  applyGDPR: InternalBoolean;
  helpTypeMulti: MPrivacyPolicyHelpTypeMulti | undefined;
  iowaSale: InternalBoolean;
  iowaSensitive: InternalBoolean;
  /** keep */
  scripts: string[];
}

function createBaseMPrivacyPolicyHelpType(): MPrivacyPolicyHelpType {
  return { optionDbValue: 0, other: "" };
}

export const MPrivacyPolicyHelpType = {
  encode(message: MPrivacyPolicyHelpType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyHelpType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyHelpType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyHelpType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? ePrivacyPolicyHelpTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyHelpType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = ePrivacyPolicyHelpTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyHelpType>, I>>(base?: I): MPrivacyPolicyHelpType {
    return MPrivacyPolicyHelpType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyHelpType>, I>>(object: I): MPrivacyPolicyHelpType {
    const message = createBaseMPrivacyPolicyHelpType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserContactFields(): MPrivacyPolicyUserContactFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserContactFields = {
  encode(message: MPrivacyPolicyUserContactFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserContactFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserContactFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserContactFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserContactFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserContactFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserContactFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserContactFields>, I>>(base?: I): MPrivacyPolicyUserContactFields {
    return MPrivacyPolicyUserContactFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserContactFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserContactFields {
    const message = createBaseMPrivacyPolicyUserContactFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserFinanceFields(): MPrivacyPolicyUserFinanceFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserFinanceFields = {
  encode(message: MPrivacyPolicyUserFinanceFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserFinanceFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserFinanceFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserFinanceFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserFinanceFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserFinanceFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserFinanceFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserFinanceFields>, I>>(base?: I): MPrivacyPolicyUserFinanceFields {
    return MPrivacyPolicyUserFinanceFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserFinanceFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserFinanceFields {
    const message = createBaseMPrivacyPolicyUserFinanceFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserOtherFields(): MPrivacyPolicyUserOtherFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserOtherFields = {
  encode(message: MPrivacyPolicyUserOtherFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserOtherFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserOtherFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserOtherFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserOtherFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserOtherFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserOtherFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserOtherFields>, I>>(base?: I): MPrivacyPolicyUserOtherFields {
    return MPrivacyPolicyUserOtherFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserOtherFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserOtherFields {
    const message = createBaseMPrivacyPolicyUserOtherFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserIdentifyFields(): MPrivacyPolicyUserIdentifyFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserIdentifyFields = {
  encode(message: MPrivacyPolicyUserIdentifyFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserIdentifyFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserIdentifyFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserIdentifyFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserIdentifyFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserIdentifyFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserIdentifyFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserIdentifyFields>, I>>(
    base?: I,
  ): MPrivacyPolicyUserIdentifyFields {
    return MPrivacyPolicyUserIdentifyFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserIdentifyFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserIdentifyFields {
    const message = createBaseMPrivacyPolicyUserIdentifyFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserUsageFields(): MPrivacyPolicyUserUsageFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserUsageFields = {
  encode(message: MPrivacyPolicyUserUsageFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserUsageFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserUsageFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserUsageFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserUsageFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserUsageFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserUsageFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserUsageFields>, I>>(base?: I): MPrivacyPolicyUserUsageFields {
    return MPrivacyPolicyUserUsageFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserUsageFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserUsageFields {
    const message = createBaseMPrivacyPolicyUserUsageFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserSensitiveFields(): MPrivacyPolicyUserSensitiveFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserSensitiveFields = {
  encode(message: MPrivacyPolicyUserSensitiveFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserSensitiveFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserSensitiveFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserSensitiveFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserSensitiveFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserSensitiveFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserSensitiveFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserSensitiveFields>, I>>(
    base?: I,
  ): MPrivacyPolicyUserSensitiveFields {
    return MPrivacyPolicyUserSensitiveFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserSensitiveFields>, I>>(
    object: I,
  ): MPrivacyPolicyUserSensitiveFields {
    const message = createBaseMPrivacyPolicyUserSensitiveFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyThirdTechFields(): MPrivacyPolicyThirdTechFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyThirdTechFields = {
  encode(message: MPrivacyPolicyThirdTechFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyThirdTechFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyThirdTechFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyThirdTechFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyThirdTechFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyThirdTechFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyThirdTechFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyThirdTechFields>, I>>(base?: I): MPrivacyPolicyThirdTechFields {
    return MPrivacyPolicyThirdTechFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyThirdTechFields>, I>>(
    object: I,
  ): MPrivacyPolicyThirdTechFields {
    const message = createBaseMPrivacyPolicyThirdTechFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyWidgetFields(): MPrivacyPolicyWidgetFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyWidgetFields = {
  encode(message: MPrivacyPolicyWidgetFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyWidgetFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyWidgetFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyWidgetFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyWidgetFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyWidgetFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyWidgetFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyWidgetFields>, I>>(base?: I): MPrivacyPolicyWidgetFields {
    return MPrivacyPolicyWidgetFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyWidgetFields>, I>>(object: I): MPrivacyPolicyWidgetFields {
    const message = createBaseMPrivacyPolicyWidgetFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyAnalyticFields(): MPrivacyPolicyAnalyticFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyAnalyticFields = {
  encode(message: MPrivacyPolicyAnalyticFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyAnalyticFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyAnalyticFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyAnalyticFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyAnalyticFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyAnalyticFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyAnalyticFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyAnalyticFields>, I>>(base?: I): MPrivacyPolicyAnalyticFields {
    return MPrivacyPolicyAnalyticFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyAnalyticFields>, I>>(object: I): MPrivacyPolicyAnalyticFields {
    const message = createBaseMPrivacyPolicyAnalyticFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyAdserviceFields(): MPrivacyPolicyAdserviceFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyAdserviceFields = {
  encode(message: MPrivacyPolicyAdserviceFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyAdserviceFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyAdserviceFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyAdserviceFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyAdserviceFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyAdserviceFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyAdserviceFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyAdserviceFields>, I>>(base?: I): MPrivacyPolicyAdserviceFields {
    return MPrivacyPolicyAdserviceFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyAdserviceFields>, I>>(
    object: I,
  ): MPrivacyPolicyAdserviceFields {
    const message = createBaseMPrivacyPolicyAdserviceFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyAdDisplayFields(): MPrivacyPolicyAdDisplayFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyAdDisplayFields = {
  encode(message: MPrivacyPolicyAdDisplayFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyAdDisplayFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyAdDisplayFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyAdDisplayFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyAdDisplayFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyAdDisplayFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyAdDisplayFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyAdDisplayFields>, I>>(base?: I): MPrivacyPolicyAdDisplayFields {
    return MPrivacyPolicyAdDisplayFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyAdDisplayFields>, I>>(
    object: I,
  ): MPrivacyPolicyAdDisplayFields {
    const message = createBaseMPrivacyPolicyAdDisplayFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyEmailServiceFields(): MPrivacyPolicyEmailServiceFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyEmailServiceFields = {
  encode(message: MPrivacyPolicyEmailServiceFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyEmailServiceFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyEmailServiceFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyEmailServiceFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyEmailServiceFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyEmailServiceFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyEmailServiceFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyEmailServiceFields>, I>>(
    base?: I,
  ): MPrivacyPolicyEmailServiceFields {
    return MPrivacyPolicyEmailServiceFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyEmailServiceFields>, I>>(
    object: I,
  ): MPrivacyPolicyEmailServiceFields {
    const message = createBaseMPrivacyPolicyEmailServiceFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyOtherDataFields(): MPrivacyPolicyOtherDataFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyOtherDataFields = {
  encode(message: MPrivacyPolicyOtherDataFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyOtherDataFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyOtherDataFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyOtherDataFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyOtherDataFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyOtherDataFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyOtherDataFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyOtherDataFields>, I>>(base?: I): MPrivacyPolicyOtherDataFields {
    return MPrivacyPolicyOtherDataFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyOtherDataFields>, I>>(
    object: I,
  ): MPrivacyPolicyOtherDataFields {
    const message = createBaseMPrivacyPolicyOtherDataFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyPaymentFields(): MPrivacyPolicyPaymentFields {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyPaymentFields = {
  encode(message: MPrivacyPolicyPaymentFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyPaymentFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyPaymentFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyPaymentFields {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyPaymentFieldsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyPaymentFields): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyPaymentFieldsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyPaymentFields>, I>>(base?: I): MPrivacyPolicyPaymentFields {
    return MPrivacyPolicyPaymentFields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyPaymentFields>, I>>(object: I): MPrivacyPolicyPaymentFields {
    const message = createBaseMPrivacyPolicyPaymentFields();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserCalifornia(): MPrivacyPolicyUserCalifornia {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserCalifornia = {
  encode(message: MPrivacyPolicyUserCalifornia, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserCalifornia {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserCalifornia();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserCalifornia {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserCaliforniaFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserCalifornia): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserCaliforniaToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserCalifornia>, I>>(base?: I): MPrivacyPolicyUserCalifornia {
    return MPrivacyPolicyUserCalifornia.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserCalifornia>, I>>(object: I): MPrivacyPolicyUserCalifornia {
    const message = createBaseMPrivacyPolicyUserCalifornia();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyUserCalsize(): MPrivacyPolicyUserCalsize {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyUserCalsize = {
  encode(message: MPrivacyPolicyUserCalsize, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyUserCalsize {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyUserCalsize();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyUserCalsize {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyUserCalsizeFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyUserCalsize): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyUserCalsizeToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyUserCalsize>, I>>(base?: I): MPrivacyPolicyUserCalsize {
    return MPrivacyPolicyUserCalsize.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyUserCalsize>, I>>(object: I): MPrivacyPolicyUserCalsize {
    const message = createBaseMPrivacyPolicyUserCalsize();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMPrivacyPolicyHelpTypeMulti(): MPrivacyPolicyHelpTypeMulti {
  return { optionDbValue: [], other: "" };
}

export const MPrivacyPolicyHelpTypeMulti = {
  encode(message: MPrivacyPolicyHelpTypeMulti, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MPrivacyPolicyHelpTypeMulti {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMPrivacyPolicyHelpTypeMulti();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MPrivacyPolicyHelpTypeMulti {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => ePrivacyPolicyHelpTypeMultiFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MPrivacyPolicyHelpTypeMulti): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => ePrivacyPolicyHelpTypeMultiToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MPrivacyPolicyHelpTypeMulti>, I>>(base?: I): MPrivacyPolicyHelpTypeMulti {
    return MPrivacyPolicyHelpTypeMulti.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MPrivacyPolicyHelpTypeMulti>, I>>(object: I): MPrivacyPolicyHelpTypeMulti {
    const message = createBaseMPrivacyPolicyHelpTypeMulti();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBasePrivacyPolicy(): PrivacyPolicy {
  return {
    companyShort: "",
    websiteUrl: "",
    helpEmail: "",
    helpLink: "",
    helpAddress: "",
    privacyUrl: "",
    helpType: undefined,
    userProvide: 0,
    userContactFields: undefined,
    userFinanceFields: undefined,
    userContact: 0,
    userFinance: 0,
    userOtherFields: undefined,
    userIdentifyFields: undefined,
    userUsageFields: undefined,
    userSensitiveFields: undefined,
    userOther: 0,
    userIdentify: 0,
    userUsage: 0,
    userSensitive: 0,
    thirdTech: 0,
    thirdTechFields: undefined,
    widgetFields: undefined,
    analyticFields: undefined,
    adserviceFields: undefined,
    adDisplayFields: undefined,
    emailServiceFields: undefined,
    otherDataFields: undefined,
    paymentFields: undefined,
    shareData: 0,
    shareDataUGC: 0,
    storeOther: "",
    storeUSA: 0,
    userCalifornia: undefined,
    sellData: 0,
    sellGPC: 0,
    optoutLink: "",
    helpTollfree: "",
    userCalsize: undefined,
    userCalAffiliate: 0,
    lastUpdateTime: undefined,
    onlineOnly: 0,
    coloradoSale: 0,
    connecticutSale: 0,
    utahSale: 0,
    utahSensitive: 0,
    virginiaSale: 0,
    dataName: "",
    dpoEmail: "",
    dataProtectionOfficer: 0,
    applyGDPR: 0,
    helpTypeMulti: undefined,
    iowaSale: 0,
    iowaSensitive: 0,
    scripts: [],
  };
}

export const PrivacyPolicy = {
  encode(message: PrivacyPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyShort !== "") {
      writer.uint32(10).string(message.companyShort);
    }
    if (message.websiteUrl !== "") {
      writer.uint32(18).string(message.websiteUrl);
    }
    if (message.helpEmail !== "") {
      writer.uint32(26).string(message.helpEmail);
    }
    if (message.helpLink !== "") {
      writer.uint32(34).string(message.helpLink);
    }
    if (message.helpAddress !== "") {
      writer.uint32(42).string(message.helpAddress);
    }
    if (message.privacyUrl !== "") {
      writer.uint32(50).string(message.privacyUrl);
    }
    if (message.helpType !== undefined) {
      MPrivacyPolicyHelpType.encode(message.helpType, writer.uint32(58).fork()).ldelim();
    }
    if (message.userProvide !== 0) {
      writer.uint32(64).int32(message.userProvide);
    }
    if (message.userContactFields !== undefined) {
      MPrivacyPolicyUserContactFields.encode(message.userContactFields, writer.uint32(74).fork()).ldelim();
    }
    if (message.userFinanceFields !== undefined) {
      MPrivacyPolicyUserFinanceFields.encode(message.userFinanceFields, writer.uint32(82).fork()).ldelim();
    }
    if (message.userContact !== 0) {
      writer.uint32(88).int32(message.userContact);
    }
    if (message.userFinance !== 0) {
      writer.uint32(96).int32(message.userFinance);
    }
    if (message.userOtherFields !== undefined) {
      MPrivacyPolicyUserOtherFields.encode(message.userOtherFields, writer.uint32(106).fork()).ldelim();
    }
    if (message.userIdentifyFields !== undefined) {
      MPrivacyPolicyUserIdentifyFields.encode(message.userIdentifyFields, writer.uint32(114).fork()).ldelim();
    }
    if (message.userUsageFields !== undefined) {
      MPrivacyPolicyUserUsageFields.encode(message.userUsageFields, writer.uint32(122).fork()).ldelim();
    }
    if (message.userSensitiveFields !== undefined) {
      MPrivacyPolicyUserSensitiveFields.encode(message.userSensitiveFields, writer.uint32(130).fork()).ldelim();
    }
    if (message.userOther !== 0) {
      writer.uint32(136).int32(message.userOther);
    }
    if (message.userIdentify !== 0) {
      writer.uint32(144).int32(message.userIdentify);
    }
    if (message.userUsage !== 0) {
      writer.uint32(152).int32(message.userUsage);
    }
    if (message.userSensitive !== 0) {
      writer.uint32(160).int32(message.userSensitive);
    }
    if (message.thirdTech !== 0) {
      writer.uint32(168).int32(message.thirdTech);
    }
    if (message.thirdTechFields !== undefined) {
      MPrivacyPolicyThirdTechFields.encode(message.thirdTechFields, writer.uint32(178).fork()).ldelim();
    }
    if (message.widgetFields !== undefined) {
      MPrivacyPolicyWidgetFields.encode(message.widgetFields, writer.uint32(186).fork()).ldelim();
    }
    if (message.analyticFields !== undefined) {
      MPrivacyPolicyAnalyticFields.encode(message.analyticFields, writer.uint32(194).fork()).ldelim();
    }
    if (message.adserviceFields !== undefined) {
      MPrivacyPolicyAdserviceFields.encode(message.adserviceFields, writer.uint32(202).fork()).ldelim();
    }
    if (message.adDisplayFields !== undefined) {
      MPrivacyPolicyAdDisplayFields.encode(message.adDisplayFields, writer.uint32(210).fork()).ldelim();
    }
    if (message.emailServiceFields !== undefined) {
      MPrivacyPolicyEmailServiceFields.encode(message.emailServiceFields, writer.uint32(218).fork()).ldelim();
    }
    if (message.otherDataFields !== undefined) {
      MPrivacyPolicyOtherDataFields.encode(message.otherDataFields, writer.uint32(226).fork()).ldelim();
    }
    if (message.paymentFields !== undefined) {
      MPrivacyPolicyPaymentFields.encode(message.paymentFields, writer.uint32(234).fork()).ldelim();
    }
    if (message.shareData !== 0) {
      writer.uint32(240).int32(message.shareData);
    }
    if (message.shareDataUGC !== 0) {
      writer.uint32(248).int32(message.shareDataUGC);
    }
    if (message.storeOther !== "") {
      writer.uint32(258).string(message.storeOther);
    }
    if (message.storeUSA !== 0) {
      writer.uint32(264).int32(message.storeUSA);
    }
    if (message.userCalifornia !== undefined) {
      MPrivacyPolicyUserCalifornia.encode(message.userCalifornia, writer.uint32(274).fork()).ldelim();
    }
    if (message.sellData !== 0) {
      writer.uint32(280).int32(message.sellData);
    }
    if (message.sellGPC !== 0) {
      writer.uint32(288).int32(message.sellGPC);
    }
    if (message.optoutLink !== "") {
      writer.uint32(298).string(message.optoutLink);
    }
    if (message.helpTollfree !== "") {
      writer.uint32(306).string(message.helpTollfree);
    }
    if (message.userCalsize !== undefined) {
      MPrivacyPolicyUserCalsize.encode(message.userCalsize, writer.uint32(314).fork()).ldelim();
    }
    if (message.userCalAffiliate !== 0) {
      writer.uint32(320).int32(message.userCalAffiliate);
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(330).fork()).ldelim();
    }
    if (message.onlineOnly !== 0) {
      writer.uint32(336).int32(message.onlineOnly);
    }
    if (message.coloradoSale !== 0) {
      writer.uint32(344).int32(message.coloradoSale);
    }
    if (message.connecticutSale !== 0) {
      writer.uint32(352).int32(message.connecticutSale);
    }
    if (message.utahSale !== 0) {
      writer.uint32(360).int32(message.utahSale);
    }
    if (message.utahSensitive !== 0) {
      writer.uint32(368).int32(message.utahSensitive);
    }
    if (message.virginiaSale !== 0) {
      writer.uint32(376).int32(message.virginiaSale);
    }
    if (message.dataName !== "") {
      writer.uint32(386).string(message.dataName);
    }
    if (message.dpoEmail !== "") {
      writer.uint32(394).string(message.dpoEmail);
    }
    if (message.dataProtectionOfficer !== 0) {
      writer.uint32(400).int32(message.dataProtectionOfficer);
    }
    if (message.applyGDPR !== 0) {
      writer.uint32(408).int32(message.applyGDPR);
    }
    if (message.helpTypeMulti !== undefined) {
      MPrivacyPolicyHelpTypeMulti.encode(message.helpTypeMulti, writer.uint32(418).fork()).ldelim();
    }
    if (message.iowaSale !== 0) {
      writer.uint32(424).int32(message.iowaSale);
    }
    if (message.iowaSensitive !== 0) {
      writer.uint32(432).int32(message.iowaSensitive);
    }
    for (const v of message.scripts) {
      writer.uint32(442).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrivacyPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrivacyPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.helpEmail = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.helpLink = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.helpAddress = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.privacyUrl = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.helpType = MPrivacyPolicyHelpType.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.userProvide = reader.int32() as any;
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.userContactFields = MPrivacyPolicyUserContactFields.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.userFinanceFields = MPrivacyPolicyUserFinanceFields.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.userContact = reader.int32() as any;
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.userFinance = reader.int32() as any;
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.userOtherFields = MPrivacyPolicyUserOtherFields.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.userIdentifyFields = MPrivacyPolicyUserIdentifyFields.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.userUsageFields = MPrivacyPolicyUserUsageFields.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.userSensitiveFields = MPrivacyPolicyUserSensitiveFields.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag != 136) {
            break;
          }

          message.userOther = reader.int32() as any;
          continue;
        case 18:
          if (tag != 144) {
            break;
          }

          message.userIdentify = reader.int32() as any;
          continue;
        case 19:
          if (tag != 152) {
            break;
          }

          message.userUsage = reader.int32() as any;
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.userSensitive = reader.int32() as any;
          continue;
        case 21:
          if (tag != 168) {
            break;
          }

          message.thirdTech = reader.int32() as any;
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.thirdTechFields = MPrivacyPolicyThirdTechFields.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.widgetFields = MPrivacyPolicyWidgetFields.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.analyticFields = MPrivacyPolicyAnalyticFields.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.adserviceFields = MPrivacyPolicyAdserviceFields.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.adDisplayFields = MPrivacyPolicyAdDisplayFields.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.emailServiceFields = MPrivacyPolicyEmailServiceFields.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.otherDataFields = MPrivacyPolicyOtherDataFields.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.paymentFields = MPrivacyPolicyPaymentFields.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 240) {
            break;
          }

          message.shareData = reader.int32() as any;
          continue;
        case 31:
          if (tag != 248) {
            break;
          }

          message.shareDataUGC = reader.int32() as any;
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.storeOther = reader.string();
          continue;
        case 33:
          if (tag != 264) {
            break;
          }

          message.storeUSA = reader.int32() as any;
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.userCalifornia = MPrivacyPolicyUserCalifornia.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag != 280) {
            break;
          }

          message.sellData = reader.int32() as any;
          continue;
        case 36:
          if (tag != 288) {
            break;
          }

          message.sellGPC = reader.int32() as any;
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.optoutLink = reader.string();
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.helpTollfree = reader.string();
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.userCalsize = MPrivacyPolicyUserCalsize.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 320) {
            break;
          }

          message.userCalAffiliate = reader.int32() as any;
          continue;
        case 41:
          if (tag != 330) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 42:
          if (tag != 336) {
            break;
          }

          message.onlineOnly = reader.int32() as any;
          continue;
        case 43:
          if (tag != 344) {
            break;
          }

          message.coloradoSale = reader.int32() as any;
          continue;
        case 44:
          if (tag != 352) {
            break;
          }

          message.connecticutSale = reader.int32() as any;
          continue;
        case 45:
          if (tag != 360) {
            break;
          }

          message.utahSale = reader.int32() as any;
          continue;
        case 46:
          if (tag != 368) {
            break;
          }

          message.utahSensitive = reader.int32() as any;
          continue;
        case 47:
          if (tag != 376) {
            break;
          }

          message.virginiaSale = reader.int32() as any;
          continue;
        case 48:
          if (tag != 386) {
            break;
          }

          message.dataName = reader.string();
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.dpoEmail = reader.string();
          continue;
        case 50:
          if (tag != 400) {
            break;
          }

          message.dataProtectionOfficer = reader.int32() as any;
          continue;
        case 51:
          if (tag != 408) {
            break;
          }

          message.applyGDPR = reader.int32() as any;
          continue;
        case 52:
          if (tag != 418) {
            break;
          }

          message.helpTypeMulti = MPrivacyPolicyHelpTypeMulti.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag != 424) {
            break;
          }

          message.iowaSale = reader.int32() as any;
          continue;
        case 54:
          if (tag != 432) {
            break;
          }

          message.iowaSensitive = reader.int32() as any;
          continue;
        case 55:
          if (tag != 442) {
            break;
          }

          message.scripts.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrivacyPolicy {
    return {
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      helpEmail: isSet(object.helpEmail) ? String(object.helpEmail) : "",
      helpLink: isSet(object.helpLink) ? String(object.helpLink) : "",
      helpAddress: isSet(object.helpAddress) ? String(object.helpAddress) : "",
      privacyUrl: isSet(object.privacyUrl) ? String(object.privacyUrl) : "",
      helpType: isSet(object.helpType) ? MPrivacyPolicyHelpType.fromJSON(object.helpType) : undefined,
      userProvide: isSet(object.userProvide) ? internalBooleanFromJSON(object.userProvide) : 0,
      userContactFields: isSet(object.userContactFields)
        ? MPrivacyPolicyUserContactFields.fromJSON(object.userContactFields)
        : undefined,
      userFinanceFields: isSet(object.userFinanceFields)
        ? MPrivacyPolicyUserFinanceFields.fromJSON(object.userFinanceFields)
        : undefined,
      userContact: isSet(object.userContact) ? internalBooleanFromJSON(object.userContact) : 0,
      userFinance: isSet(object.userFinance) ? internalBooleanFromJSON(object.userFinance) : 0,
      userOtherFields: isSet(object.userOtherFields)
        ? MPrivacyPolicyUserOtherFields.fromJSON(object.userOtherFields)
        : undefined,
      userIdentifyFields: isSet(object.userIdentifyFields)
        ? MPrivacyPolicyUserIdentifyFields.fromJSON(object.userIdentifyFields)
        : undefined,
      userUsageFields: isSet(object.userUsageFields)
        ? MPrivacyPolicyUserUsageFields.fromJSON(object.userUsageFields)
        : undefined,
      userSensitiveFields: isSet(object.userSensitiveFields)
        ? MPrivacyPolicyUserSensitiveFields.fromJSON(object.userSensitiveFields)
        : undefined,
      userOther: isSet(object.userOther) ? internalBooleanFromJSON(object.userOther) : 0,
      userIdentify: isSet(object.userIdentify) ? internalBooleanFromJSON(object.userIdentify) : 0,
      userUsage: isSet(object.userUsage) ? internalBooleanFromJSON(object.userUsage) : 0,
      userSensitive: isSet(object.userSensitive) ? internalBooleanFromJSON(object.userSensitive) : 0,
      thirdTech: isSet(object.thirdTech) ? internalBooleanFromJSON(object.thirdTech) : 0,
      thirdTechFields: isSet(object.thirdTechFields)
        ? MPrivacyPolicyThirdTechFields.fromJSON(object.thirdTechFields)
        : undefined,
      widgetFields: isSet(object.widgetFields) ? MPrivacyPolicyWidgetFields.fromJSON(object.widgetFields) : undefined,
      analyticFields: isSet(object.analyticFields)
        ? MPrivacyPolicyAnalyticFields.fromJSON(object.analyticFields)
        : undefined,
      adserviceFields: isSet(object.adserviceFields)
        ? MPrivacyPolicyAdserviceFields.fromJSON(object.adserviceFields)
        : undefined,
      adDisplayFields: isSet(object.adDisplayFields)
        ? MPrivacyPolicyAdDisplayFields.fromJSON(object.adDisplayFields)
        : undefined,
      emailServiceFields: isSet(object.emailServiceFields)
        ? MPrivacyPolicyEmailServiceFields.fromJSON(object.emailServiceFields)
        : undefined,
      otherDataFields: isSet(object.otherDataFields)
        ? MPrivacyPolicyOtherDataFields.fromJSON(object.otherDataFields)
        : undefined,
      paymentFields: isSet(object.paymentFields)
        ? MPrivacyPolicyPaymentFields.fromJSON(object.paymentFields)
        : undefined,
      shareData: isSet(object.shareData) ? internalBooleanFromJSON(object.shareData) : 0,
      shareDataUGC: isSet(object.shareDataUGC) ? internalBooleanFromJSON(object.shareDataUGC) : 0,
      storeOther: isSet(object.storeOther) ? String(object.storeOther) : "",
      storeUSA: isSet(object.storeUSA) ? internalBooleanFromJSON(object.storeUSA) : 0,
      userCalifornia: isSet(object.userCalifornia)
        ? MPrivacyPolicyUserCalifornia.fromJSON(object.userCalifornia)
        : undefined,
      sellData: isSet(object.sellData) ? internalBooleanFromJSON(object.sellData) : 0,
      sellGPC: isSet(object.sellGPC) ? internalBooleanFromJSON(object.sellGPC) : 0,
      optoutLink: isSet(object.optoutLink) ? String(object.optoutLink) : "",
      helpTollfree: isSet(object.helpTollfree) ? String(object.helpTollfree) : "",
      userCalsize: isSet(object.userCalsize) ? MPrivacyPolicyUserCalsize.fromJSON(object.userCalsize) : undefined,
      userCalAffiliate: isSet(object.userCalAffiliate) ? internalBooleanFromJSON(object.userCalAffiliate) : 0,
      lastUpdateTime: isSet(object.lastUpdateTime) ? fromJsonTimestamp(object.lastUpdateTime) : undefined,
      onlineOnly: isSet(object.onlineOnly) ? internalBooleanFromJSON(object.onlineOnly) : 0,
      coloradoSale: isSet(object.coloradoSale) ? internalBooleanFromJSON(object.coloradoSale) : 0,
      connecticutSale: isSet(object.connecticutSale) ? internalBooleanFromJSON(object.connecticutSale) : 0,
      utahSale: isSet(object.utahSale) ? internalBooleanFromJSON(object.utahSale) : 0,
      utahSensitive: isSet(object.utahSensitive) ? internalBooleanFromJSON(object.utahSensitive) : 0,
      virginiaSale: isSet(object.virginiaSale) ? internalBooleanFromJSON(object.virginiaSale) : 0,
      dataName: isSet(object.dataName) ? String(object.dataName) : "",
      dpoEmail: isSet(object.dpoEmail) ? String(object.dpoEmail) : "",
      dataProtectionOfficer: isSet(object.dataProtectionOfficer)
        ? internalBooleanFromJSON(object.dataProtectionOfficer)
        : 0,
      applyGDPR: isSet(object.applyGDPR) ? internalBooleanFromJSON(object.applyGDPR) : 0,
      helpTypeMulti: isSet(object.helpTypeMulti)
        ? MPrivacyPolicyHelpTypeMulti.fromJSON(object.helpTypeMulti)
        : undefined,
      iowaSale: isSet(object.iowaSale) ? internalBooleanFromJSON(object.iowaSale) : 0,
      iowaSensitive: isSet(object.iowaSensitive) ? internalBooleanFromJSON(object.iowaSensitive) : 0,
      scripts: Array.isArray(object?.scripts)
        ? object.scripts.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PrivacyPolicy): unknown {
    const obj: any = {};
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    message.helpEmail !== undefined && (obj.helpEmail = message.helpEmail);
    message.helpLink !== undefined && (obj.helpLink = message.helpLink);
    message.helpAddress !== undefined && (obj.helpAddress = message.helpAddress);
    message.privacyUrl !== undefined && (obj.privacyUrl = message.privacyUrl);
    message.helpType !== undefined &&
      (obj.helpType = message.helpType ? MPrivacyPolicyHelpType.toJSON(message.helpType) : undefined);
    message.userProvide !== undefined && (obj.userProvide = internalBooleanToJSON(message.userProvide));
    message.userContactFields !== undefined && (obj.userContactFields = message.userContactFields
      ? MPrivacyPolicyUserContactFields.toJSON(message.userContactFields)
      : undefined);
    message.userFinanceFields !== undefined && (obj.userFinanceFields = message.userFinanceFields
      ? MPrivacyPolicyUserFinanceFields.toJSON(message.userFinanceFields)
      : undefined);
    message.userContact !== undefined && (obj.userContact = internalBooleanToJSON(message.userContact));
    message.userFinance !== undefined && (obj.userFinance = internalBooleanToJSON(message.userFinance));
    message.userOtherFields !== undefined && (obj.userOtherFields = message.userOtherFields
      ? MPrivacyPolicyUserOtherFields.toJSON(message.userOtherFields)
      : undefined);
    message.userIdentifyFields !== undefined && (obj.userIdentifyFields = message.userIdentifyFields
      ? MPrivacyPolicyUserIdentifyFields.toJSON(message.userIdentifyFields)
      : undefined);
    message.userUsageFields !== undefined && (obj.userUsageFields = message.userUsageFields
      ? MPrivacyPolicyUserUsageFields.toJSON(message.userUsageFields)
      : undefined);
    message.userSensitiveFields !== undefined && (obj.userSensitiveFields = message.userSensitiveFields
      ? MPrivacyPolicyUserSensitiveFields.toJSON(message.userSensitiveFields)
      : undefined);
    message.userOther !== undefined && (obj.userOther = internalBooleanToJSON(message.userOther));
    message.userIdentify !== undefined && (obj.userIdentify = internalBooleanToJSON(message.userIdentify));
    message.userUsage !== undefined && (obj.userUsage = internalBooleanToJSON(message.userUsage));
    message.userSensitive !== undefined && (obj.userSensitive = internalBooleanToJSON(message.userSensitive));
    message.thirdTech !== undefined && (obj.thirdTech = internalBooleanToJSON(message.thirdTech));
    message.thirdTechFields !== undefined && (obj.thirdTechFields = message.thirdTechFields
      ? MPrivacyPolicyThirdTechFields.toJSON(message.thirdTechFields)
      : undefined);
    message.widgetFields !== undefined &&
      (obj.widgetFields = message.widgetFields ? MPrivacyPolicyWidgetFields.toJSON(message.widgetFields) : undefined);
    message.analyticFields !== undefined && (obj.analyticFields = message.analyticFields
      ? MPrivacyPolicyAnalyticFields.toJSON(message.analyticFields)
      : undefined);
    message.adserviceFields !== undefined && (obj.adserviceFields = message.adserviceFields
      ? MPrivacyPolicyAdserviceFields.toJSON(message.adserviceFields)
      : undefined);
    message.adDisplayFields !== undefined && (obj.adDisplayFields = message.adDisplayFields
      ? MPrivacyPolicyAdDisplayFields.toJSON(message.adDisplayFields)
      : undefined);
    message.emailServiceFields !== undefined && (obj.emailServiceFields = message.emailServiceFields
      ? MPrivacyPolicyEmailServiceFields.toJSON(message.emailServiceFields)
      : undefined);
    message.otherDataFields !== undefined && (obj.otherDataFields = message.otherDataFields
      ? MPrivacyPolicyOtherDataFields.toJSON(message.otherDataFields)
      : undefined);
    message.paymentFields !== undefined &&
      (obj.paymentFields = message.paymentFields
        ? MPrivacyPolicyPaymentFields.toJSON(message.paymentFields)
        : undefined);
    message.shareData !== undefined && (obj.shareData = internalBooleanToJSON(message.shareData));
    message.shareDataUGC !== undefined && (obj.shareDataUGC = internalBooleanToJSON(message.shareDataUGC));
    message.storeOther !== undefined && (obj.storeOther = message.storeOther);
    message.storeUSA !== undefined && (obj.storeUSA = internalBooleanToJSON(message.storeUSA));
    message.userCalifornia !== undefined && (obj.userCalifornia = message.userCalifornia
      ? MPrivacyPolicyUserCalifornia.toJSON(message.userCalifornia)
      : undefined);
    message.sellData !== undefined && (obj.sellData = internalBooleanToJSON(message.sellData));
    message.sellGPC !== undefined && (obj.sellGPC = internalBooleanToJSON(message.sellGPC));
    message.optoutLink !== undefined && (obj.optoutLink = message.optoutLink);
    message.helpTollfree !== undefined && (obj.helpTollfree = message.helpTollfree);
    message.userCalsize !== undefined &&
      (obj.userCalsize = message.userCalsize ? MPrivacyPolicyUserCalsize.toJSON(message.userCalsize) : undefined);
    message.userCalAffiliate !== undefined && (obj.userCalAffiliate = internalBooleanToJSON(message.userCalAffiliate));
    message.lastUpdateTime !== undefined && (obj.lastUpdateTime = message.lastUpdateTime.toISOString());
    message.onlineOnly !== undefined && (obj.onlineOnly = internalBooleanToJSON(message.onlineOnly));
    message.coloradoSale !== undefined && (obj.coloradoSale = internalBooleanToJSON(message.coloradoSale));
    message.connecticutSale !== undefined && (obj.connecticutSale = internalBooleanToJSON(message.connecticutSale));
    message.utahSale !== undefined && (obj.utahSale = internalBooleanToJSON(message.utahSale));
    message.utahSensitive !== undefined && (obj.utahSensitive = internalBooleanToJSON(message.utahSensitive));
    message.virginiaSale !== undefined && (obj.virginiaSale = internalBooleanToJSON(message.virginiaSale));
    message.dataName !== undefined && (obj.dataName = message.dataName);
    message.dpoEmail !== undefined && (obj.dpoEmail = message.dpoEmail);
    message.dataProtectionOfficer !== undefined &&
      (obj.dataProtectionOfficer = internalBooleanToJSON(message.dataProtectionOfficer));
    message.applyGDPR !== undefined && (obj.applyGDPR = internalBooleanToJSON(message.applyGDPR));
    message.helpTypeMulti !== undefined &&
      (obj.helpTypeMulti = message.helpTypeMulti
        ? MPrivacyPolicyHelpTypeMulti.toJSON(message.helpTypeMulti)
        : undefined);
    message.iowaSale !== undefined && (obj.iowaSale = internalBooleanToJSON(message.iowaSale));
    message.iowaSensitive !== undefined && (obj.iowaSensitive = internalBooleanToJSON(message.iowaSensitive));
    if (message.scripts) {
      obj.scripts = message.scripts.map((e) => e);
    } else {
      obj.scripts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivacyPolicy>, I>>(base?: I): PrivacyPolicy {
    return PrivacyPolicy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrivacyPolicy>, I>>(object: I): PrivacyPolicy {
    const message = createBasePrivacyPolicy();
    message.companyShort = object.companyShort ?? "";
    message.websiteUrl = object.websiteUrl ?? "";
    message.helpEmail = object.helpEmail ?? "";
    message.helpLink = object.helpLink ?? "";
    message.helpAddress = object.helpAddress ?? "";
    message.privacyUrl = object.privacyUrl ?? "";
    message.helpType = (object.helpType !== undefined && object.helpType !== null)
      ? MPrivacyPolicyHelpType.fromPartial(object.helpType)
      : undefined;
    message.userProvide = object.userProvide ?? 0;
    message.userContactFields = (object.userContactFields !== undefined && object.userContactFields !== null)
      ? MPrivacyPolicyUserContactFields.fromPartial(object.userContactFields)
      : undefined;
    message.userFinanceFields = (object.userFinanceFields !== undefined && object.userFinanceFields !== null)
      ? MPrivacyPolicyUserFinanceFields.fromPartial(object.userFinanceFields)
      : undefined;
    message.userContact = object.userContact ?? 0;
    message.userFinance = object.userFinance ?? 0;
    message.userOtherFields = (object.userOtherFields !== undefined && object.userOtherFields !== null)
      ? MPrivacyPolicyUserOtherFields.fromPartial(object.userOtherFields)
      : undefined;
    message.userIdentifyFields = (object.userIdentifyFields !== undefined && object.userIdentifyFields !== null)
      ? MPrivacyPolicyUserIdentifyFields.fromPartial(object.userIdentifyFields)
      : undefined;
    message.userUsageFields = (object.userUsageFields !== undefined && object.userUsageFields !== null)
      ? MPrivacyPolicyUserUsageFields.fromPartial(object.userUsageFields)
      : undefined;
    message.userSensitiveFields = (object.userSensitiveFields !== undefined && object.userSensitiveFields !== null)
      ? MPrivacyPolicyUserSensitiveFields.fromPartial(object.userSensitiveFields)
      : undefined;
    message.userOther = object.userOther ?? 0;
    message.userIdentify = object.userIdentify ?? 0;
    message.userUsage = object.userUsage ?? 0;
    message.userSensitive = object.userSensitive ?? 0;
    message.thirdTech = object.thirdTech ?? 0;
    message.thirdTechFields = (object.thirdTechFields !== undefined && object.thirdTechFields !== null)
      ? MPrivacyPolicyThirdTechFields.fromPartial(object.thirdTechFields)
      : undefined;
    message.widgetFields = (object.widgetFields !== undefined && object.widgetFields !== null)
      ? MPrivacyPolicyWidgetFields.fromPartial(object.widgetFields)
      : undefined;
    message.analyticFields = (object.analyticFields !== undefined && object.analyticFields !== null)
      ? MPrivacyPolicyAnalyticFields.fromPartial(object.analyticFields)
      : undefined;
    message.adserviceFields = (object.adserviceFields !== undefined && object.adserviceFields !== null)
      ? MPrivacyPolicyAdserviceFields.fromPartial(object.adserviceFields)
      : undefined;
    message.adDisplayFields = (object.adDisplayFields !== undefined && object.adDisplayFields !== null)
      ? MPrivacyPolicyAdDisplayFields.fromPartial(object.adDisplayFields)
      : undefined;
    message.emailServiceFields = (object.emailServiceFields !== undefined && object.emailServiceFields !== null)
      ? MPrivacyPolicyEmailServiceFields.fromPartial(object.emailServiceFields)
      : undefined;
    message.otherDataFields = (object.otherDataFields !== undefined && object.otherDataFields !== null)
      ? MPrivacyPolicyOtherDataFields.fromPartial(object.otherDataFields)
      : undefined;
    message.paymentFields = (object.paymentFields !== undefined && object.paymentFields !== null)
      ? MPrivacyPolicyPaymentFields.fromPartial(object.paymentFields)
      : undefined;
    message.shareData = object.shareData ?? 0;
    message.shareDataUGC = object.shareDataUGC ?? 0;
    message.storeOther = object.storeOther ?? "";
    message.storeUSA = object.storeUSA ?? 0;
    message.userCalifornia = (object.userCalifornia !== undefined && object.userCalifornia !== null)
      ? MPrivacyPolicyUserCalifornia.fromPartial(object.userCalifornia)
      : undefined;
    message.sellData = object.sellData ?? 0;
    message.sellGPC = object.sellGPC ?? 0;
    message.optoutLink = object.optoutLink ?? "";
    message.helpTollfree = object.helpTollfree ?? "";
    message.userCalsize = (object.userCalsize !== undefined && object.userCalsize !== null)
      ? MPrivacyPolicyUserCalsize.fromPartial(object.userCalsize)
      : undefined;
    message.userCalAffiliate = object.userCalAffiliate ?? 0;
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.onlineOnly = object.onlineOnly ?? 0;
    message.coloradoSale = object.coloradoSale ?? 0;
    message.connecticutSale = object.connecticutSale ?? 0;
    message.utahSale = object.utahSale ?? 0;
    message.utahSensitive = object.utahSensitive ?? 0;
    message.virginiaSale = object.virginiaSale ?? 0;
    message.dataName = object.dataName ?? "";
    message.dpoEmail = object.dpoEmail ?? "";
    message.dataProtectionOfficer = object.dataProtectionOfficer ?? 0;
    message.applyGDPR = object.applyGDPR ?? 0;
    message.helpTypeMulti = (object.helpTypeMulti !== undefined && object.helpTypeMulti !== null)
      ? MPrivacyPolicyHelpTypeMulti.fromPartial(object.helpTypeMulti)
      : undefined;
    message.iowaSale = object.iowaSale ?? 0;
    message.iowaSensitive = object.iowaSensitive ?? 0;
    message.scripts = object.scripts?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
