import { ConditionRule } from "apps/legal-ide/App/components/ConditionRule";
import { toCompoundJsonLogic } from "apps/legal-ide/App/components/WizardUi/WizardForm";
import { Button } from "components";
import { useForm } from "hooks";
import { useState } from "react";
import { Actions, AppDispatch, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

function submitOrRemoveCommonHelper(dispatch: AppDispatch, data: Section | Step | Question | FieldOption, values?: CompoundJsonLogic) {
    if (data.type === "Question") {
        dispatch(
            Actions.agreementManager.updateQuestion({
                questionId: data.id,
                data: { ...(data as Question), condition: values },
            })
        );
    } else if (data.type === "Step") {
        dispatch(
            Actions.agreementManager.updateStep({
                stepId: data.id,
                data: { ...(data as Step), condition: values },
            })
        );
    } else if (data.type === "Section") {
        dispatch(
            Actions.agreementManager.updateSection({
                sectionId: data.id,
                data: { ...(data as Section), condition: values },
            })
        );
    }
    else if (data.type === "FieldOption") {
        dispatch(
            Actions.agreementManager.updateFieldOption({
                optionId: data.id, fieldId: data.fieldId,
                value: { ...(data as FieldOption), condition: values },
            })
        );
    }

    dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_CONDITION" }));
}

//  modal for edit wizard conditions (section,step,question)
export const WizardConditionModal = () => {
    const data = useAppSelector((state) => state.ui.modals.LEGAL_IDE_WIZARD_CONDITION.data);

    const [form, setForm] = useState<CompoundJsonLogic | undefined>(toCompoundJsonLogic(data?.condition));

    const onSubmit = () => {
            if (!data) return;
            submitOrRemoveCommonHelper(dispatch, data, form);
    }

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "LEGAL_IDE_WIZARD_CONDITION" }));
    };

    const onRemove = () => {
        if (!data) return;

        submitOrRemoveCommonHelper(dispatch, data, undefined);
    };

    return (
        <BaseModal size="md" className="z-60">
            <form onSubmit={onSubmit} className="flex flex-col gap-8">
                <ConditionRule
                    compoundJsonLogic={form}
                    onChange={(newValue) => {
                        setForm(newValue);
                    }}
                />
                <div className="flex justify-between">
                    <Button onClick={onCancel}>Cancel</Button>
                    <div className="flex gap-4">
                        {data?.condition && (
                            <Button onClick={onRemove} type="submit">
                                Remove
                            </Button>
                        )}
                        <Button type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </BaseModal>
    );
};
