import { SelectField } from "apps/legal-ide/App/components/ConditionRule";
import { Button, TextField } from "components";
import { useForm } from "hooks";
import { useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { BaseModal } from "./BaseModal";

export const CreateFillableModal = () => {
    const payload = useAppSelector((state) => state.ui.modals.ForLawyers_createFillable.data);

    const form = useForm(
        {},
        {
            onSubmit: (values: any) => {
                const node = {
                    type: "input",
                    children: [{ text: "" }],
                    fieldId: values.fieldId,
                };

                payload.callback?.(node);
                dispatch(Actions.ui.closeModal({ name: "ForLawyers_createFillable" }));
            },
        }
    );

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.ui.closeModal({ name: "ForLawyers_createFillable" }));
    };

    return (
        <BaseModal>
            <form onSubmit={form.onSubmit} className="flex flex-col gap-8">
                <SelectField value={form.values.fieldId} onChange={form.onChange} />
                <div className="flex justify-between">
                    <Button type="button" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit">
                        Save
                    </Button>
                </div>
            </form>
        </BaseModal>
    );
};
