import { Rnd } from "react-rnd";
import { usePdfContext } from "../../PdfContext";
import { ImageElementRender } from "./ImageElement";
import { PdfElement } from "./PdfElements";
import { PdfSignature } from "./PdfSignature";
import { Text } from "./Text";

export const BaseElement = ({
    element,
    updateElement,
    onClick,
    isSelected,
}: {
    element: PdfElement;
    isSelected: boolean;
    updateElement: (element: PdfElement) => void;
    onClick: (element: PdfElement) => void;
}) => {
    const context = usePdfContext();
    const { state } = context;

    function removeElement(id: string) {
        if (!context.state.selectedPage) return;
        const pageElements = [...(state.elements[state.selectedPage] ?? [])];
        return { ...state.elements, [state.selectedPage]: pageElements.filter((o) => o.id !== id) };
    }

    return (
        <Rnd
            default={{
                height: (element as any).height,
                width: (element as any).width,
                x: element.x,
                y: element.y,
            }}
            cancel="#remove,#edit"
            className={`${isSelected ? "border" : ""}`}
            bounds="parent"
            onDragStop={(_, data) => {
                const newElement = { ...element };
                newElement.x = data.x;
                newElement.y = data.y;
                updateElement(newElement);
            }}
            onDragStart={() => {
                onClick(element); // for mobile
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                if (element.type === "text") return;

                const newElement = { ...element };
                newElement.width += delta.width;
                newElement.height += delta.height;
                updateElement?.(newElement);
            }}
            enableResizing={element.type !== "text"}
        >
            <div onClick={() => onClick(element)} className="relative h-full w-full">
                <button
                    onClick={() => {
                        context.updateState({
                            selectedElement: null,
                            elements: removeElement(element.id),
                        });
                    }}
                    id="remove"
                    className="absolute z-50 left-0 top-0 right-0 w-12 h-12 md:w-6 md:h-6 m-auto rounded-full bg-white
    cursor-pointer transform -translate-y-20 md:-translate-y-10"
                >
                    <img className="w-full h-full" src="/delete.svg" alt="delete object" />
                </button>
                {element.type === "image" && <ImageElementRender key={element.id} element={element} />}
                {element.type === "text" && <Text updateElement={updateElement} key={element.id} text={element} />}
                {element.type === "signature" && <PdfSignature key={element.id} signature={element} />}
            </div>
        </Rnd>
    );
};
