import { forwardRef } from "react";

const NAMES = {
    EDIT: "edit",
    MENU_LEFT: "format_align_left",
    MENU: "format_align_center",
    MENU_RIGHT: "format_align_right",
    MENU_JUSTIFY: "format_align_justify",
    X_CIRCLE: "cancel",
    ARROW_EXPAND: "zoom_out_map",
    QUOTE: "format_quote",
    OL: "format_list_numbered",
    UL: "format_list_bulleted",
    FORMAT_BOLD: "format_bold",
    FORMAT_UNDERLINED: "format_underlined",
    FORMAT_ITALIC: "format_italic",
    CODE: "code",
    SAVE: "save",
    ADD: "add",
    PLAY_CIRCLE: "play_circle",
    PLAY: "play_arrow",
    RESUME: "play_pause",
    PAUSE: "pause",
    STOP: "stop",
    DELETE: "delete",
    OPEN_IN_FULL: "open_in_full",
    FORMAT_LINE_SPACING: "format_line_spacing",
    FORMAT_SIZE: "format_size",
    FORMAT_INDENT_INCREASE: "format_indent_increase",
    LOOKS_ONE: "looks_one",
    LOOKS_TWO: "looks_two",
    LOOKS_3: "looks_3",
    LOOKS_5: "looks_5",
    WARNING: "warning",
    ERROR: "error",
    ARROW_DROP_DOWN: "arrow_drop_down",
    ARROW_DROP_UP: "arrow_drop_up",
    TRASH: "delete",
    DUPLICATE: "library_add",
    MANAGE_HISTORY: "manage_history",
    ACCOUNT_TREE: "account_tree",
};

interface IconProps {
    name: string;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    color?: string;
    className?: string;
}
const _Icon = forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
    const { name, onClick, className = "", ...rest } = props;

    return (
        <span ref={ref} onClick={onClick} className={`material-symbols-outlined cursor-pointer ${className}`} {...rest}>
            {name}
        </span>
    );
});
export const Icon = Object.assign(_Icon, { NAMES });
// Icon.NAMES = NAMES;
