import { Button } from "components";
import { nanoid } from "nanoid";
import { fileToBytes } from "utils";
import { ImageElement, TextElement } from "../components/elements/PdfElements";
import { usePdfContext } from "../PdfContext";
import { pdfJsApi } from "../utils/pdfJsApi";
import imgDraw from "./img-draw.png";
import imgPicture from "./img-picture.png";
import imgText from "./img-text.png";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { useAppDispatch } from "store";
import { callDialog } from "store/mainActions";
import { DialogType } from "store/mainTypes";
import { scrollToPage } from "../utils/scrollToView";
import { useMedia } from "hooks";

// fix drawing width
// add to tools page

export const PdfToolbar = () => {
    const context = usePdfContext();

    const { state } = context;
    const { selectedPage, elements, file } = state;

    const dispatch = useAppDispatch();

    const isMobile = useMedia({ max: "sm" });

    async function onUploadPDF(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files ?? []; //|| (e.dataTransfer && e.dataTransfer.files)
        const file = files[0];
        if (!file || file.type !== "application/pdf") return;

        const url = window.URL.createObjectURL(new Blob([file]));

        const pdf = await pdfJsApi.loadPdfFile(url);

        context?.updateState({
            file: file,
            pdfJsDoc: pdf,
            totalPages: pdf?.numPages ?? 0,
            elements: [],
            selectedElement: null,
            selectedPage: 1,
        });
    }

    function addTextField(text = "New Text Field") {
        if (!selectedPage) return;

        const textElement: TextElement = {
            type: "text",
            id: nanoid(6),
            value: text,
            x: 50,
            y: 50,
            fontSize: 20,
        };

        context.addNewElement(textElement);
        scrollToPage(selectedPage);
    }

    function addPicture(src: string, fileType: string) {
        if (!selectedPage) return;

        const picture: ImageElement = {
            type: "image",
            id: nanoid(6),
            value: src,
            x: 50,
            y: 50,
            height: 100,
            width: 100,
            fileType: fileType as ImageElement["fileType"],
        };
        context.addNewElement(picture);
        scrollToPage(selectedPage);
    }

    async function save() {
        if (!file) return;

        const bytes = await fileToBytes(file);

        const pdfDoc = await PDFDocument.load(bytes);
        const pages = pdfDoc.getPages();

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        for (const index in pages) {
            const pageIndex = Number(index) + 1;

            const page = pages[index];
            const size = page.getSize();

            const pageObjects = elements[pageIndex] ?? [];
            for (const object of pageObjects) {
                if (object.type === "text") {
                    page.drawText(object.value, {
                        x: object.x,
                        y: size.height - object.y - object.fontSize,
                        size: object.fontSize,
                        lineHeight: 1,
                        font: timesRomanFont,
                    });
                }
                if (object.type === "signature") {
                    const bytes = await fetch(object.value).then((res) => res.arrayBuffer());

                    const img = await pdfDoc.embedPng(bytes);

                    page.drawImage(img, {
                        x: object.x,
                        y: size.height - object.y - object.height,
                        width: object.width,
                        height: object.height,
                    });
                }
                if (object.type === "image") {
                    const bytes = await fetch(object.value).then((res) => res.arrayBuffer());
                    const img =
                        object.fileType === "image/png"
                            ? await pdfDoc.embedPng(bytes)
                            : await pdfDoc.embedJpg(bytes);

                    page.drawImage(img, {
                        x: object.x,
                        y: size.height - object.y - object.height,
                        width: object.width,
                        height: object.height,
                    });
                }
            }
        }

        const docUri = await pdfDoc.saveAsBase64({ dataUri: true });

        var link = document.createElement("a");
        link.href = docUri;
        link.download = "pdf.pdf";
        link.click();
    }

    const elementsOptions = [
        {
            label: "draw",
            img: imgDraw,
            action: () => {
                context.updateState({
                    drawingIsOpen: true,
                });
            },
        },
        // { label: "text", img: imgText, action: addTextField },
        // { label: "picture", img: imgPicture, action: () => {} },
    ];

    if (!isMobile) {
        elementsOptions.push({ label: "text", img: imgText, action: addTextField });
        elementsOptions.push({ label: "picture", img: imgPicture, action: () => {} });
    }

    const disabled = !context.state.file;

    return (
        <div className=" overflow-y-auto w-full rounded-lg  p-4 flex-shrink-0 bg-primaryVeryLight text-darkIndigo flex justify-between items-center gap-6">
            <div className="">
                <input
                    accept=".pdf"
                    type="file"
                    name="pdf"
                    id="pdf"
                    onChange={onUploadPDF}
                    className="hidden"
                />
                <label
                    className="whitespace-no-wrap bg-primary hover:bg-blue-700 text-white text-center
                    font-medium py-2.5 text-base px-4 rounded-lg w-full block cursor-pointer"
                    htmlFor="pdf"
                >
                    Choose PDF
                </label>
            </div>

            <div className="flex gap-3 justify-center">
                {elementsOptions.map((option) => (
                    <div key={option.label} className="">
                        <label
                            className={`flex items-center justify-center h-full w-10 p-2 rounded-md bg-white shadow-sm hover:shadow-md cursor-pointer
                            ${disabled && "opacity-50"}
                            `}
                            htmlFor={option.label}
                            onClick={(e) => {
                                if (disabled) {
                                    return;
                                }
                                e.stopPropagation();

                                option.action();
                            }}
                        >
                            <img src={option.img} alt="an icon" />
                            {option.label === "picture" && (
                                <input
                                    onChange={(e) => {
                                        const files = e.target.files ?? []; //|| (e.dataTransfer && e.dataTransfer.files)
                                        const file = files[0];
                                        if (!file) return;

                                        const url = window.URL.createObjectURL(new Blob([file]));

                                        if (!["image/png", "image/jpg"].includes(file.type))
                                            return alert("png and jpg images only");

                                        // 👇️ reset file input
                                        e.target.value = null as any;

                                        addPicture(url, file.type);
                                    }}
                                    id={option.label}
                                    type="file"
                                    hidden
                                    accept="image/png, image/jpg"
                                    disabled={disabled}
                                />
                            )}
                        </label>
                    </div>
                ))}
            </div>

            <div className="">
                <Button disabled={disabled} fullWidth onClick={save}>
                    Download
                </Button>
            </div>
        </div>
    );
};
