import React, { ReactNode } from "react";
import "./Ribbon.scss";

type Props = {
    textClassName: string;
    bgClassName: string;
    position?: RibbonPosition;
    content: ReactNode;
};

type RibbonPosition = "top-right" | "bottom-left";

const topRightDivClasses = "-top-[10px] -right-[10px]"
const topRightPClasses = ""

const bottomLeftDivClasses = "ribbon -scale-x-100 -rotate-90 -bottom-[10px] -left-[10px]"
const bottomLeftPClasses = "-scale-x-100 rotate-180"

// TODO: think of merging this with website
export const Ribbon = (props: Props) => {
    const { textClassName, bgClassName, position, content } = props;

    return <div className={`ribbon ${divClasses(position)}`}>
        <span className={`${bgClassName}`}>
        <p className={`text-center ${textClassName} ${pClasses(position)}`}>
                {content}
            </p>
        </span>
    </div>
};

function divClasses(position: RibbonPosition | undefined) {
    if (position === "bottom-left") return bottomLeftDivClasses;

    return topRightDivClasses;
}

function pClasses(position: RibbonPosition | undefined) {
    if (position === "bottom-left") return bottomLeftPClasses;

    return topRightPClasses;
}
