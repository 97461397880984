import { useSelector } from "react-redux";
import { MainStore } from "../../store/mainTypes";
import DocsBase from "./DocsBase";

export default function InboxDocs() {
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);

    return <DocsBase relevantDocs={sentDocs}
        caption="Out for Signature"
        noDocsMsg="You currently don't have any contract sent out for signatures"
        showRemind={true}
        mainCTA="View"
    />
}
