import { getAutoContracts } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import { AutoContractDocument } from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { WizardUi } from "apps/legal-ide/App/components/WizardUi/WizardUi";
import "components/0_common/Scrollbar/Scrollbar.scss";
import { TemplatesEditor } from "features";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import CapTable from "tools/capTable/CapTable";
import PrivacyScannerTool from "tools/PrivacyScannerTool";
import TimeTrackingTool from "tools/timeTrackingTool/TimeTrackingTool";
import ToolsMain from "tools/ToolsMain";
import { DocsApi } from "../api-services/DocsApi";
import { MainStore } from "../store/mainTypes";
import { Container } from "./0_common";
import { AppBar } from "./1_features";
import ChatPage from "./3_pages/ChatPage/ChatPage";
import { FloatingChat } from "./3_pages/ChatPage/FloatingChat";
import Forgot from "./account/Forgot";
import Login from "./account/Login";
import OnBoarding from "./account/OnBoarding";
import ProtectedRoute from "./account/ProtectedRoute";
import SignUp from "./account/SignUp";
import { useAuth } from "./contexts/AuthContext";
import HomePage from "./HomePage";
import HelpInWizard from "./internal-management/HelpInWizard";
import InternalManagementPanel from "./internal-management/InternalManagementPanel";
import LegalTopicsSurvey from "./internal-management/LegalTopicsSurvey";
import { createAndRedirectToDraft, Library } from "./Library";
import CompletedDocs from "./life-cycle/CompletedDocs";
import Drafts from "./life-cycle/Drafts";
import InboxDocs from "./life-cycle/InboxDocs";
import {
    default as PaymentPlans,
    default as PaymentPlansB,
} from "./life-cycle/payment/PaymentPlans";
import SentDocs from "./life-cycle/SentDocs";
import { Spinner } from "./Spinner";
import ContractReviewTool from "tools/ContractReviewTool";
import UploadedDocs from "./life-cycle/UploadedDocs";
import UserSettings from "./3_pages/Settings/UserSettings";
import { FreeStyleEditor } from "apps/legal-ide/App/components/FreeStyleEditor/FreeStyleEditor";
import WeeklyReport from "apps/legal-ide/pages/Reports/WeeklyReport";
import PaymentWithSlidingOverlay from "./life-cycle/payment/PaymentWithSlidingOverlay";
import { PdfTools } from "./3_pages/PdfTools/PdfTools";
import { RedeemCode } from "./account/RedeemCode";
import ChecklistVideo from "tools/ChecklistVideo";

export default function MainPanel() {
    const dispatch = useDispatch();
    const myAccountName = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.dbUser?.accountName
    );

    useEffect(() => {
        if (myAccountName) {
            DocsApi.getAllDocs(dispatch, () => {});
        }

        setInterval(() => {
            if (myAccountName) {
                DocsApi.getAllDocs(dispatch, () => {});
            }
        }, 60000);
    }, [myAccountName]);

    return (
        <main className="_ScrollbarSpecial flex flex-col flex-grow overflow-x-hidden bg-primaryBg w-full">
            <div className="bg-primary w-full">
                <AppBar />
            </div>

            <Switch>
                <Route
                    path="/login"
                    children={
                        <>
                            <HomePage key="bg" />
                            <Login />
                        </>
                    }
                />
                <Route
                    path="/signup"
                    children={
                        <>
                            <HomePage key="bg" />
                            <SignUp />
                        </>
                    }
                />
                <Route
                    path="/forgot-password"
                    children={
                        <>
                            <HomePage key="bg" />
                            <Forgot />
                        </>
                    }
                />
                <ProtectedRoute
                    path="/onboard"
                    children={
                        <>
                            <HomePage key="bg" />
                            <OnBoarding />
                        </>
                    }
                />
                <ProtectedRoute
                    path="/redeem-code"
                    children={
                        <>
                            <HomePage key="bg" />
                            <RedeemCode />
                        </>
                    }
                />
                <ProtectedRoute
                    path="/il-payment"
                    children={
                        <>
                            <HomePage key="bg" />
                            <PaymentPlans />
                        </>
                    }
                />

                <ProtectedRoute path="/tools/checklist-video/payment" children={
                    <>
                    <ChecklistVideo />
                    <PaymentPlansB
                        closeUrlProp="/tools/checklist-video"
                        />
                    </>
                }
                />
                <ProtectedRoute path="/tools/checklist-video" component={ChecklistVideo} />

                <ProtectedRoute path="/library/submenu/:key" children={Library} />
                <ProtectedRoute path="/library" children={Library} />
                <ProtectedRoute path="/docs/completed" component={CompletedDocs} />
                <ProtectedRoute path="/docs/drafts" component={Drafts} />
                <ProtectedRoute path="/docs/awaiting-signature" component={InboxDocs} />
                <ProtectedRoute path="/docs/out-for-signature" component={SentDocs} />
                <ProtectedRoute path="/docs/uploaded" component={UploadedDocs} />
                <Redirect from="/docs" to="/docs/drafts" />
                <ProtectedRoute path="/tools/contract-review" component={ContractReviewTool} />
                <ProtectedRoute path="/tools/privacy-scanner" component={PrivacyScannerTool} />
                <ProtectedRoute path="/tools/cap-table" component={CapTable} />
                <ProtectedRoute path="/tools/pdf-editor" component={PdfTools} />
                <ProtectedRoute
                    path="/tools/time-tracking/payment"
                    children={
                        <>
                            <TimeTrackingTool />
                            <PaymentPlansB
                                closeUrlProp="/tools/time-tracking"
                                feature="Time Tracking"
                            />
                        </>
                    }
                />
                <ProtectedRoute path="/tools/time-tracking" component={TimeTrackingTool} />
                <ProtectedRoute path="/tools" component={ToolsMain} />
                <ProtectedRoute
                    path="/payment-plans/payment"
                    children={
                        <Container>
                            <HomePage />
                            <PaymentPlans />
                            <PaymentWithSlidingOverlay />
                        </Container>
                    }
                />
                <ProtectedRoute
                    path="/payment-plans"
                    children={
                        <Container>
                            <HomePage />
                            <PaymentPlans />
                        </Container>
                    }
                />
                <ProtectedRoute path="/logout" component={Logout} />,
                <ProtectedRoute path="/:a/uploadedFile/:b/:c" children={<ContractReviewTool />} />
                {getAutoContracts().map((contract) => {
                    return [
                        <ProtectedRoute
                            path={`/:a/${contract.kind}/:b/finish/payment-plans/payment`}
                            exact
                            children={
                                <>
                                    <WizardUi contract={contract} />
                                    <PaymentWithSlidingOverlay />
                                </>
                            }
                        />,
                        <ProtectedRoute
                            exact
                            path={`/:a/${contract.kind}/:b/finish/payment-plans`}
                            children={
                                <>
                                    <WizardUi
                                        key="payment"
                                        contract={contract}
                                        paymentDocPreviewMode={true}
                                    />
                                    <PaymentPlans />
                                </>
                            }
                        />,
                        <ProtectedRoute
                            path={`/:a/${contract.kind}/:b/:c/:d/help`}
                            children={
                                <>
                                    <WizardUi contract={contract} />
                                    <HelpInWizard />
                                </>
                            }
                        />,
                        <ProtectedRoute
                            path={`/:a/${contract.kind}/:b/:c/:d`}
                            children={<WizardUi contract={contract} />}
                        />,
                        <ProtectedRoute
                            exact
                            path={`/:a/${contract.kind}/:b/edit`}
                            children={<FreeStyleEditor />}
                        />,
                        <ProtectedRoute
                            path={`/${contract.kind}/gen-new`}
                            children={
                                <>
                                    <CreateNewContract autoContract={contract} />
                                </>
                            }
                        />,
                    ];
                })}
                <ProtectedRoute path={"/weekly-report"} children={<WeeklyReport />} />
                <ProtectedRoute path={"/latest-doc"} children={<LatestDoc />} />
                <ProtectedRoute path="/settings/templates/:id" component={TemplatesEditor} />
                <ProtectedRoute path="/settings" component={UserSettings} />
                <ProtectedRoute
                    path="/legal-topics-survey"
                    children={
                        <>
                            <Library />
                            <LegalTopicsSurvey />
                        </>
                    }
                />
                <ProtectedRoute path="/internal-management" component={InternalManagementPanel} />
                <ProtectedRoute path="/chat" children={<ChatPage />} />
                <ProtectedRoute
                    path="/"
                    children={
                        <>
                            <HomePage key="home" />
                        </>
                    }
                    exact
                />
                <Route
                    path="*"
                    children={
                        <>
                            <Redirect to={"/"} />
                        </>
                    }
                />
            </Switch>
        </main>
    );
}

function FloatingChatWidget() {
    const path = useLocation().pathname;

    if (
        path === "/login" ||
        path === "/signup" ||
        path === "/forgot-password" ||
        path === "/onboard" ||
        path === "/chat"
    ) {
        return null;
    }

    return <FloatingChat />;
}

function LatestDoc() {
    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const draftsWereLoaded = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.draftsWereLoaded
    );

    if (!draftsWereLoaded) {
        return (
            <div className="m-auto">
                <Spinner />
            </div>
        );
    }

    // find latest
    const latestDraft = drafts?.reduce((acc, curr) => {
        if ((acc.legalUnit?.createdAt || 0) > (curr.legalUnit?.createdAt || 0)) {
            return acc;
        }
        return curr;
    });

    const lastStep = latestDraft.legalUnit?.lastStep || "A/1";

    const latestStepInLatestDoc = latestDraft.id + "/" + lastStep;

    return <Redirect to={latestStepInLatestDoc} />;
}

function Logout() {
    const auth = useAuth();

    auth?.logout();

    window.location.href = "https://www.clawdia.ai";

    return null;
}

function CreateNewContract({ autoContract }: { autoContract: AutoContractDocument }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    useEffect(() => {
        if (dbUser) {
            createAndRedirectToDraft(autoContract, dispatch, history, dbUser, undefined);
        }
    }, [dbUser]);

    return null;
}
