import { nanoid } from "nanoid";
import { createContext, ReactNode, useContext, useMemo } from "react";

interface ContextType {
    options?: TableOptions;
    removeColumn: (id: Column["id"]) => void;
    updateColumnName: (details: { columnId: string; name: string; heading: boolean; index: number }) => void;
    getColumns: () => Column[];
    updateCellData: ({ rowIndex, columnId, value }: { rowIndex: number; columnId: string; value: any }) => void;
    getCellValue: ({ columnId, rowIndex }: { columnId: string; rowIndex: number }) => any;
    isTableEditable: () => boolean;
    isRowDeletable: () => boolean;
    isRowUnlimited: () => boolean;
    removeRow: ({ rowId }: { rowId: string }) => void;
    removeRowByIdex: (index: number) => void;
    addRow: () => void;
    toggleRowHeading: () => void;
    toggleUnlimitedRows: () => void;
    addDefaultRow: () => void;
    addColumn: () => void;
    removeDefaultRowByIndex: (index: number) => void;
}

const TableContext = createContext<ContextType | null>(null);

export const useTable = () => useContext(TableContext)!;

export interface TableOptions {
    editable?: boolean; // if true table is editable mode
    columns?: Column[]; // add row header (vertical columns)
    column?: {
        headingLabel?: string;
    };
    row?: {
        heading?: boolean; // add row header (vertical columns)
        deletable?: boolean; // ?
        unlimited?: boolean; // user can rows
        hideActions?: boolean; // how row actions
        defaultRows?: Row[];
    };
    footer?: {
        hide?: boolean;
    };
}

export interface ContextProps {
    children: ReactNode;
    options?: TableOptions;
    data: { [key: string]: any }[];
    onDataChange?: (data: { [key: string]: any }[]) => void;
    onOptionsChange?: (options: TableOptions) => void;
}

export const TableContextProvider = ({ children, data, options, onOptionsChange, onDataChange }: ContextProps) => {
    const addRow = () => {
        onDataChange?.([...data, {}]);
    };

    const toggleRowHeading = () => {
        onOptionsChange?.({
            ...options,
            row: { ...options?.row, heading: !options?.row?.heading ?? false },
        });
    };
    const toggleUnlimitedRows = () => {
        onOptionsChange?.({
            ...options,
            row: { ...options?.row, unlimited: !options?.row?.unlimited ?? false },
        });
    };

    const addColumn = () => {
        onOptionsChange?.({
            ...options,
            columns: [...(options?.columns ?? []), { id: nanoid(6), name: "New Column" }],
        });
    };

    const api: ContextType = useMemo(() => {
        return {
            options,

            addColumn,
            addRow,
            addDefaultRow() {
                onOptionsChange?.({
                    ...options,
                    row: {
                        ...options?.row,
                        defaultRows: [...(options?.row?.defaultRows ?? []), { id: nanoid(6), heading: "" }],
                    },
                });
            },
            removeDefaultRowByIndex(index: number) {
                onOptionsChange?.({
                    ...options,
                    row: {
                        ...options?.row,
                        defaultRows: (options?.row?.defaultRows ?? []).filter((_, i) => i !== index),
                    },
                });
            },
            toggleRowHeading,
            toggleUnlimitedRows,
            removeRowByIdex(index) {
                onDataChange?.(data.filter((_, i) => i !== index));
            },
            updateCellData({ rowIndex, columnId, value }) {
                let newData = [...data];
                let obj = newData[rowIndex] ? { ...newData[rowIndex] } : {};
                obj[columnId] = value;
                newData[rowIndex] = obj;
                onDataChange?.(newData);
            },
            getColumns() {
                return options?.columns ?? [];
            },
            removeColumn(id) {
                onOptionsChange?.({
                    ...options,
                    columns:
                        options?.columns?.filter((col) => {
                            return col.id !== id;
                        }) ?? [],
                });
            },

            updateColumnName({ columnId, name, heading, index }) {
                const rows = options?.row?.defaultRows ?? [];

                if (heading) {
                    if (index === -1) {
                        // column heading
                        console.log("here");

                        return onOptionsChange?.({
                            ...options,
                            column: {
                                ...options?.column,
                                headingLabel: name,
                            },
                        });
                    }
                    return onOptionsChange?.({
                        ...options,
                        row: {
                            ...options?.row,
                            defaultRows: rows.map((r, i) => {
                                if (i === index) {
                                    return { ...r, heading: name };
                                }
                                return r;
                            }),
                        },
                    });
                }
                onOptionsChange?.({
                    ...options,
                    columns:
                        options?.columns?.map((col) => {
                            return col.id === columnId ? { ...col, name: name } : col;
                        }) ?? [],
                });
            },
            getCellValue({ columnId, rowIndex }) {
                return data?.[rowIndex]?.[columnId];
            },
            isTableEditable() {
                return options?.editable ?? false;
            },
            isRowUnlimited() {
                return options?.row?.unlimited ?? false;
            },
            isRowDeletable() {
                return options?.row?.deletable ?? false;
            },
            removeRow({ rowId }) {
                onOptionsChange?.({
                    ...options,
                    row: { ...options?.row, defaultRows: options?.row?.defaultRows?.filter((r) => r.id !== rowId) },
                });
            },
        };
    }, [options]);

    return <TableContext.Provider value={api}>{children}</TableContext.Provider>;
};
