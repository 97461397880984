/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EOfferLetterEmploymentPayScale {
  OfferLetterEmployment_payScale_NA = 0,
  OfferLetterEmployment_payScale_HOURLY = 1,
  OfferLetterEmployment_payScale_WEEKLY = 2,
  OfferLetterEmployment_payScale_MONTHLY = 3,
  OfferLetterEmployment_payScale_ANNUAL = 4,
  OfferLetterEmployment_payScale_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eOfferLetterEmploymentPayScaleFromJSON(object: any): EOfferLetterEmploymentPayScale {
  switch (object) {
    case 0:
    case "OfferLetterEmployment_payScale_NA":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_NA;
    case 1:
    case "OfferLetterEmployment_payScale_HOURLY":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_HOURLY;
    case 2:
    case "OfferLetterEmployment_payScale_WEEKLY":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_WEEKLY;
    case 3:
    case "OfferLetterEmployment_payScale_MONTHLY":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_MONTHLY;
    case 4:
    case "OfferLetterEmployment_payScale_ANNUAL":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_ANNUAL;
    case 999999:
    case "OfferLetterEmployment_payScale_OTHER":
      return EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOfferLetterEmploymentPayScale.UNRECOGNIZED;
  }
}

export function eOfferLetterEmploymentPayScaleToJSON(object: EOfferLetterEmploymentPayScale): string {
  switch (object) {
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_NA:
      return "OfferLetterEmployment_payScale_NA";
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_HOURLY:
      return "OfferLetterEmployment_payScale_HOURLY";
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_WEEKLY:
      return "OfferLetterEmployment_payScale_WEEKLY";
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_MONTHLY:
      return "OfferLetterEmployment_payScale_MONTHLY";
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_ANNUAL:
      return "OfferLetterEmployment_payScale_ANNUAL";
    case EOfferLetterEmploymentPayScale.OfferLetterEmployment_payScale_OTHER:
      return "OfferLetterEmployment_payScale_OTHER";
    case EOfferLetterEmploymentPayScale.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EOfferLetterEmploymentPayTiming {
  OfferLetterEmployment_payTiming_NA = 0,
  OfferLetterEmployment_payTiming_FORTNIGHTLY = 1,
  OfferLetterEmployment_payTiming_TWICE_MONTHLY = 2,
  OfferLetterEmployment_payTiming_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eOfferLetterEmploymentPayTimingFromJSON(object: any): EOfferLetterEmploymentPayTiming {
  switch (object) {
    case 0:
    case "OfferLetterEmployment_payTiming_NA":
      return EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_NA;
    case 1:
    case "OfferLetterEmployment_payTiming_FORTNIGHTLY":
      return EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_FORTNIGHTLY;
    case 2:
    case "OfferLetterEmployment_payTiming_TWICE_MONTHLY":
      return EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_TWICE_MONTHLY;
    case 999999:
    case "OfferLetterEmployment_payTiming_OTHER":
      return EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOfferLetterEmploymentPayTiming.UNRECOGNIZED;
  }
}

export function eOfferLetterEmploymentPayTimingToJSON(object: EOfferLetterEmploymentPayTiming): string {
  switch (object) {
    case EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_NA:
      return "OfferLetterEmployment_payTiming_NA";
    case EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_FORTNIGHTLY:
      return "OfferLetterEmployment_payTiming_FORTNIGHTLY";
    case EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_TWICE_MONTHLY:
      return "OfferLetterEmployment_payTiming_TWICE_MONTHLY";
    case EOfferLetterEmploymentPayTiming.OfferLetterEmployment_payTiming_OTHER:
      return "OfferLetterEmployment_payTiming_OTHER";
    case EOfferLetterEmploymentPayTiming.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EOfferLetterEmploymentBenefitsStatus {
  OfferLetterEmployment_benefitsStatus_NA = 0,
  OfferLetterEmployment_benefitsStatus_STANDARD = 1,
  OfferLetterEmployment_benefitsStatus_NOT_YET = 2,
  UNRECOGNIZED = -1,
}

export function eOfferLetterEmploymentBenefitsStatusFromJSON(object: any): EOfferLetterEmploymentBenefitsStatus {
  switch (object) {
    case 0:
    case "OfferLetterEmployment_benefitsStatus_NA":
      return EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_NA;
    case 1:
    case "OfferLetterEmployment_benefitsStatus_STANDARD":
      return EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_STANDARD;
    case 2:
    case "OfferLetterEmployment_benefitsStatus_NOT_YET":
      return EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_NOT_YET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOfferLetterEmploymentBenefitsStatus.UNRECOGNIZED;
  }
}

export function eOfferLetterEmploymentBenefitsStatusToJSON(object: EOfferLetterEmploymentBenefitsStatus): string {
  switch (object) {
    case EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_NA:
      return "OfferLetterEmployment_benefitsStatus_NA";
    case EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_STANDARD:
      return "OfferLetterEmployment_benefitsStatus_STANDARD";
    case EOfferLetterEmploymentBenefitsStatus.OfferLetterEmployment_benefitsStatus_NOT_YET:
      return "OfferLetterEmployment_benefitsStatus_NOT_YET";
    case EOfferLetterEmploymentBenefitsStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EOfferLetterEmploymentNonSolicit {
  OfferLetterEmployment_nonSolicit_NA = 0,
  OfferLetterEmployment_nonSolicit_TERM = 2,
  OfferLetterEmployment_nonSolicit_TAIL = 3,
  OfferLetterEmployment_nonSolicit_NONE = 1,
  UNRECOGNIZED = -1,
}

export function eOfferLetterEmploymentNonSolicitFromJSON(object: any): EOfferLetterEmploymentNonSolicit {
  switch (object) {
    case 0:
    case "OfferLetterEmployment_nonSolicit_NA":
      return EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_NA;
    case 2:
    case "OfferLetterEmployment_nonSolicit_TERM":
      return EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_TERM;
    case 3:
    case "OfferLetterEmployment_nonSolicit_TAIL":
      return EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_TAIL;
    case 1:
    case "OfferLetterEmployment_nonSolicit_NONE":
      return EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOfferLetterEmploymentNonSolicit.UNRECOGNIZED;
  }
}

export function eOfferLetterEmploymentNonSolicitToJSON(object: EOfferLetterEmploymentNonSolicit): string {
  switch (object) {
    case EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_NA:
      return "OfferLetterEmployment_nonSolicit_NA";
    case EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_TERM:
      return "OfferLetterEmployment_nonSolicit_TERM";
    case EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_TAIL:
      return "OfferLetterEmployment_nonSolicit_TAIL";
    case EOfferLetterEmploymentNonSolicit.OfferLetterEmployment_nonSolicit_NONE:
      return "OfferLetterEmployment_nonSolicit_NONE";
    case EOfferLetterEmploymentNonSolicit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EOfferLetterEmploymentAntiRaiding {
  OfferLetterEmployment_antiRaiding_NA = 0,
  OfferLetterEmployment_antiRaiding_TERM = 2,
  OfferLetterEmployment_antiRaiding_TAIL = 3,
  OfferLetterEmployment_antiRaiding_NONE = 1,
  UNRECOGNIZED = -1,
}

export function eOfferLetterEmploymentAntiRaidingFromJSON(object: any): EOfferLetterEmploymentAntiRaiding {
  switch (object) {
    case 0:
    case "OfferLetterEmployment_antiRaiding_NA":
      return EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_NA;
    case 2:
    case "OfferLetterEmployment_antiRaiding_TERM":
      return EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_TERM;
    case 3:
    case "OfferLetterEmployment_antiRaiding_TAIL":
      return EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_TAIL;
    case 1:
    case "OfferLetterEmployment_antiRaiding_NONE":
      return EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EOfferLetterEmploymentAntiRaiding.UNRECOGNIZED;
  }
}

export function eOfferLetterEmploymentAntiRaidingToJSON(object: EOfferLetterEmploymentAntiRaiding): string {
  switch (object) {
    case EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_NA:
      return "OfferLetterEmployment_antiRaiding_NA";
    case EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_TERM:
      return "OfferLetterEmployment_antiRaiding_TERM";
    case EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_TAIL:
      return "OfferLetterEmployment_antiRaiding_TAIL";
    case EOfferLetterEmploymentAntiRaiding.OfferLetterEmployment_antiRaiding_NONE:
      return "OfferLetterEmployment_antiRaiding_NONE";
    case EOfferLetterEmploymentAntiRaiding.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MOfferLetterEmploymentPayScale {
  optionDbValue: EOfferLetterEmploymentPayScale;
  other: string;
}

export interface MOfferLetterEmploymentPayTiming {
  optionDbValue: EOfferLetterEmploymentPayTiming;
  other: string;
}

export interface MOfferLetterEmploymentBenefitsStatus {
  optionDbValue: EOfferLetterEmploymentBenefitsStatus;
  other: string;
}

export interface MOfferLetterEmploymentNonSolicit {
  optionDbValue: EOfferLetterEmploymentNonSolicit;
  other: string;
}

export interface MOfferLetterEmploymentAntiRaiding {
  optionDbValue: EOfferLetterEmploymentAntiRaiding;
  other: string;
}

export interface OfferLetterEmployment {
  jobDuties: string;
  jobTitle: string;
  startDate: Date | undefined;
  payScale: MOfferLetterEmploymentPayScale | undefined;
  payRate: number;
  payTiming: MOfferLetterEmploymentPayTiming | undefined;
  dateLetter: Date | undefined;
  employeeAddress: string;
  employeeEmail: string;
  employeeNameFull: string;
  employeeName: string;
  letterName: string;
  letterTitle: string;
  bonusEligible: InternalBoolean;
  bonusText: string;
  bonusProrateStart: InternalBoolean;
  benefitsStatus: MOfferLetterEmploymentBenefitsStatus | undefined;
  leaveAnnual: string;
  nonSolicit: MOfferLetterEmploymentNonSolicit | undefined;
  nonSolicitTailMonths: number;
  antiRaidingTailMonths: number;
  antiRaiding: MOfferLetterEmploymentAntiRaiding | undefined;
  restrictiveCovenant: InternalBoolean;
}

function createBaseMOfferLetterEmploymentPayScale(): MOfferLetterEmploymentPayScale {
  return { optionDbValue: 0, other: "" };
}

export const MOfferLetterEmploymentPayScale = {
  encode(message: MOfferLetterEmploymentPayScale, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MOfferLetterEmploymentPayScale {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMOfferLetterEmploymentPayScale();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MOfferLetterEmploymentPayScale {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eOfferLetterEmploymentPayScaleFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MOfferLetterEmploymentPayScale): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eOfferLetterEmploymentPayScaleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MOfferLetterEmploymentPayScale>, I>>(base?: I): MOfferLetterEmploymentPayScale {
    return MOfferLetterEmploymentPayScale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MOfferLetterEmploymentPayScale>, I>>(
    object: I,
  ): MOfferLetterEmploymentPayScale {
    const message = createBaseMOfferLetterEmploymentPayScale();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMOfferLetterEmploymentPayTiming(): MOfferLetterEmploymentPayTiming {
  return { optionDbValue: 0, other: "" };
}

export const MOfferLetterEmploymentPayTiming = {
  encode(message: MOfferLetterEmploymentPayTiming, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MOfferLetterEmploymentPayTiming {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMOfferLetterEmploymentPayTiming();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MOfferLetterEmploymentPayTiming {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eOfferLetterEmploymentPayTimingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MOfferLetterEmploymentPayTiming): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eOfferLetterEmploymentPayTimingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MOfferLetterEmploymentPayTiming>, I>>(base?: I): MOfferLetterEmploymentPayTiming {
    return MOfferLetterEmploymentPayTiming.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MOfferLetterEmploymentPayTiming>, I>>(
    object: I,
  ): MOfferLetterEmploymentPayTiming {
    const message = createBaseMOfferLetterEmploymentPayTiming();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMOfferLetterEmploymentBenefitsStatus(): MOfferLetterEmploymentBenefitsStatus {
  return { optionDbValue: 0, other: "" };
}

export const MOfferLetterEmploymentBenefitsStatus = {
  encode(message: MOfferLetterEmploymentBenefitsStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MOfferLetterEmploymentBenefitsStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMOfferLetterEmploymentBenefitsStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MOfferLetterEmploymentBenefitsStatus {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eOfferLetterEmploymentBenefitsStatusFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MOfferLetterEmploymentBenefitsStatus): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eOfferLetterEmploymentBenefitsStatusToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MOfferLetterEmploymentBenefitsStatus>, I>>(
    base?: I,
  ): MOfferLetterEmploymentBenefitsStatus {
    return MOfferLetterEmploymentBenefitsStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MOfferLetterEmploymentBenefitsStatus>, I>>(
    object: I,
  ): MOfferLetterEmploymentBenefitsStatus {
    const message = createBaseMOfferLetterEmploymentBenefitsStatus();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMOfferLetterEmploymentNonSolicit(): MOfferLetterEmploymentNonSolicit {
  return { optionDbValue: 0, other: "" };
}

export const MOfferLetterEmploymentNonSolicit = {
  encode(message: MOfferLetterEmploymentNonSolicit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MOfferLetterEmploymentNonSolicit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMOfferLetterEmploymentNonSolicit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MOfferLetterEmploymentNonSolicit {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eOfferLetterEmploymentNonSolicitFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MOfferLetterEmploymentNonSolicit): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eOfferLetterEmploymentNonSolicitToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MOfferLetterEmploymentNonSolicit>, I>>(
    base?: I,
  ): MOfferLetterEmploymentNonSolicit {
    return MOfferLetterEmploymentNonSolicit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MOfferLetterEmploymentNonSolicit>, I>>(
    object: I,
  ): MOfferLetterEmploymentNonSolicit {
    const message = createBaseMOfferLetterEmploymentNonSolicit();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMOfferLetterEmploymentAntiRaiding(): MOfferLetterEmploymentAntiRaiding {
  return { optionDbValue: 0, other: "" };
}

export const MOfferLetterEmploymentAntiRaiding = {
  encode(message: MOfferLetterEmploymentAntiRaiding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MOfferLetterEmploymentAntiRaiding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMOfferLetterEmploymentAntiRaiding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MOfferLetterEmploymentAntiRaiding {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eOfferLetterEmploymentAntiRaidingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MOfferLetterEmploymentAntiRaiding): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eOfferLetterEmploymentAntiRaidingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MOfferLetterEmploymentAntiRaiding>, I>>(
    base?: I,
  ): MOfferLetterEmploymentAntiRaiding {
    return MOfferLetterEmploymentAntiRaiding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MOfferLetterEmploymentAntiRaiding>, I>>(
    object: I,
  ): MOfferLetterEmploymentAntiRaiding {
    const message = createBaseMOfferLetterEmploymentAntiRaiding();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseOfferLetterEmployment(): OfferLetterEmployment {
  return {
    jobDuties: "",
    jobTitle: "",
    startDate: undefined,
    payScale: undefined,
    payRate: 0,
    payTiming: undefined,
    dateLetter: undefined,
    employeeAddress: "",
    employeeEmail: "",
    employeeNameFull: "",
    employeeName: "",
    letterName: "",
    letterTitle: "",
    bonusEligible: 0,
    bonusText: "",
    bonusProrateStart: 0,
    benefitsStatus: undefined,
    leaveAnnual: "",
    nonSolicit: undefined,
    nonSolicitTailMonths: 0,
    antiRaidingTailMonths: 0,
    antiRaiding: undefined,
    restrictiveCovenant: 0,
  };
}

export const OfferLetterEmployment = {
  encode(message: OfferLetterEmployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobDuties !== "") {
      writer.uint32(10).string(message.jobDuties);
    }
    if (message.jobTitle !== "") {
      writer.uint32(18).string(message.jobTitle);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.payScale !== undefined) {
      MOfferLetterEmploymentPayScale.encode(message.payScale, writer.uint32(34).fork()).ldelim();
    }
    if (message.payRate !== 0) {
      writer.uint32(45).float(message.payRate);
    }
    if (message.payTiming !== undefined) {
      MOfferLetterEmploymentPayTiming.encode(message.payTiming, writer.uint32(50).fork()).ldelim();
    }
    if (message.dateLetter !== undefined) {
      Timestamp.encode(toTimestamp(message.dateLetter), writer.uint32(58).fork()).ldelim();
    }
    if (message.employeeAddress !== "") {
      writer.uint32(66).string(message.employeeAddress);
    }
    if (message.employeeEmail !== "") {
      writer.uint32(74).string(message.employeeEmail);
    }
    if (message.employeeNameFull !== "") {
      writer.uint32(82).string(message.employeeNameFull);
    }
    if (message.employeeName !== "") {
      writer.uint32(90).string(message.employeeName);
    }
    if (message.letterName !== "") {
      writer.uint32(98).string(message.letterName);
    }
    if (message.letterTitle !== "") {
      writer.uint32(106).string(message.letterTitle);
    }
    if (message.bonusEligible !== 0) {
      writer.uint32(112).int32(message.bonusEligible);
    }
    if (message.bonusText !== "") {
      writer.uint32(122).string(message.bonusText);
    }
    if (message.bonusProrateStart !== 0) {
      writer.uint32(128).int32(message.bonusProrateStart);
    }
    if (message.benefitsStatus !== undefined) {
      MOfferLetterEmploymentBenefitsStatus.encode(message.benefitsStatus, writer.uint32(138).fork()).ldelim();
    }
    if (message.leaveAnnual !== "") {
      writer.uint32(146).string(message.leaveAnnual);
    }
    if (message.nonSolicit !== undefined) {
      MOfferLetterEmploymentNonSolicit.encode(message.nonSolicit, writer.uint32(154).fork()).ldelim();
    }
    if (message.nonSolicitTailMonths !== 0) {
      writer.uint32(165).float(message.nonSolicitTailMonths);
    }
    if (message.antiRaidingTailMonths !== 0) {
      writer.uint32(173).float(message.antiRaidingTailMonths);
    }
    if (message.antiRaiding !== undefined) {
      MOfferLetterEmploymentAntiRaiding.encode(message.antiRaiding, writer.uint32(178).fork()).ldelim();
    }
    if (message.restrictiveCovenant !== 0) {
      writer.uint32(184).int32(message.restrictiveCovenant);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfferLetterEmployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfferLetterEmployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.jobDuties = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.jobTitle = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.payScale = MOfferLetterEmploymentPayScale.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 45) {
            break;
          }

          message.payRate = reader.float();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.payTiming = MOfferLetterEmploymentPayTiming.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.dateLetter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.employeeAddress = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.employeeEmail = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.employeeNameFull = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.employeeName = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.letterName = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.letterTitle = reader.string();
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.bonusEligible = reader.int32() as any;
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.bonusText = reader.string();
          continue;
        case 16:
          if (tag != 128) {
            break;
          }

          message.bonusProrateStart = reader.int32() as any;
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.benefitsStatus = MOfferLetterEmploymentBenefitsStatus.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.leaveAnnual = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.nonSolicit = MOfferLetterEmploymentNonSolicit.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag != 165) {
            break;
          }

          message.nonSolicitTailMonths = reader.float();
          continue;
        case 21:
          if (tag != 173) {
            break;
          }

          message.antiRaidingTailMonths = reader.float();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.antiRaiding = MOfferLetterEmploymentAntiRaiding.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag != 184) {
            break;
          }

          message.restrictiveCovenant = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfferLetterEmployment {
    return {
      jobDuties: isSet(object.jobDuties) ? String(object.jobDuties) : "",
      jobTitle: isSet(object.jobTitle) ? String(object.jobTitle) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      payScale: isSet(object.payScale) ? MOfferLetterEmploymentPayScale.fromJSON(object.payScale) : undefined,
      payRate: isSet(object.payRate) ? Number(object.payRate) : 0,
      payTiming: isSet(object.payTiming) ? MOfferLetterEmploymentPayTiming.fromJSON(object.payTiming) : undefined,
      dateLetter: isSet(object.dateLetter) ? fromJsonTimestamp(object.dateLetter) : undefined,
      employeeAddress: isSet(object.employeeAddress) ? String(object.employeeAddress) : "",
      employeeEmail: isSet(object.employeeEmail) ? String(object.employeeEmail) : "",
      employeeNameFull: isSet(object.employeeNameFull) ? String(object.employeeNameFull) : "",
      employeeName: isSet(object.employeeName) ? String(object.employeeName) : "",
      letterName: isSet(object.letterName) ? String(object.letterName) : "",
      letterTitle: isSet(object.letterTitle) ? String(object.letterTitle) : "",
      bonusEligible: isSet(object.bonusEligible) ? internalBooleanFromJSON(object.bonusEligible) : 0,
      bonusText: isSet(object.bonusText) ? String(object.bonusText) : "",
      bonusProrateStart: isSet(object.bonusProrateStart) ? internalBooleanFromJSON(object.bonusProrateStart) : 0,
      benefitsStatus: isSet(object.benefitsStatus)
        ? MOfferLetterEmploymentBenefitsStatus.fromJSON(object.benefitsStatus)
        : undefined,
      leaveAnnual: isSet(object.leaveAnnual) ? String(object.leaveAnnual) : "",
      nonSolicit: isSet(object.nonSolicit) ? MOfferLetterEmploymentNonSolicit.fromJSON(object.nonSolicit) : undefined,
      nonSolicitTailMonths: isSet(object.nonSolicitTailMonths) ? Number(object.nonSolicitTailMonths) : 0,
      antiRaidingTailMonths: isSet(object.antiRaidingTailMonths) ? Number(object.antiRaidingTailMonths) : 0,
      antiRaiding: isSet(object.antiRaiding)
        ? MOfferLetterEmploymentAntiRaiding.fromJSON(object.antiRaiding)
        : undefined,
      restrictiveCovenant: isSet(object.restrictiveCovenant) ? internalBooleanFromJSON(object.restrictiveCovenant) : 0,
    };
  },

  toJSON(message: OfferLetterEmployment): unknown {
    const obj: any = {};
    message.jobDuties !== undefined && (obj.jobDuties = message.jobDuties);
    message.jobTitle !== undefined && (obj.jobTitle = message.jobTitle);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.payScale !== undefined &&
      (obj.payScale = message.payScale ? MOfferLetterEmploymentPayScale.toJSON(message.payScale) : undefined);
    message.payRate !== undefined && (obj.payRate = message.payRate);
    message.payTiming !== undefined &&
      (obj.payTiming = message.payTiming ? MOfferLetterEmploymentPayTiming.toJSON(message.payTiming) : undefined);
    message.dateLetter !== undefined && (obj.dateLetter = message.dateLetter.toISOString());
    message.employeeAddress !== undefined && (obj.employeeAddress = message.employeeAddress);
    message.employeeEmail !== undefined && (obj.employeeEmail = message.employeeEmail);
    message.employeeNameFull !== undefined && (obj.employeeNameFull = message.employeeNameFull);
    message.employeeName !== undefined && (obj.employeeName = message.employeeName);
    message.letterName !== undefined && (obj.letterName = message.letterName);
    message.letterTitle !== undefined && (obj.letterTitle = message.letterTitle);
    message.bonusEligible !== undefined && (obj.bonusEligible = internalBooleanToJSON(message.bonusEligible));
    message.bonusText !== undefined && (obj.bonusText = message.bonusText);
    message.bonusProrateStart !== undefined &&
      (obj.bonusProrateStart = internalBooleanToJSON(message.bonusProrateStart));
    message.benefitsStatus !== undefined && (obj.benefitsStatus = message.benefitsStatus
      ? MOfferLetterEmploymentBenefitsStatus.toJSON(message.benefitsStatus)
      : undefined);
    message.leaveAnnual !== undefined && (obj.leaveAnnual = message.leaveAnnual);
    message.nonSolicit !== undefined &&
      (obj.nonSolicit = message.nonSolicit ? MOfferLetterEmploymentNonSolicit.toJSON(message.nonSolicit) : undefined);
    message.nonSolicitTailMonths !== undefined && (obj.nonSolicitTailMonths = message.nonSolicitTailMonths);
    message.antiRaidingTailMonths !== undefined && (obj.antiRaidingTailMonths = message.antiRaidingTailMonths);
    message.antiRaiding !== undefined &&
      (obj.antiRaiding = message.antiRaiding
        ? MOfferLetterEmploymentAntiRaiding.toJSON(message.antiRaiding)
        : undefined);
    message.restrictiveCovenant !== undefined &&
      (obj.restrictiveCovenant = internalBooleanToJSON(message.restrictiveCovenant));
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferLetterEmployment>, I>>(base?: I): OfferLetterEmployment {
    return OfferLetterEmployment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OfferLetterEmployment>, I>>(object: I): OfferLetterEmployment {
    const message = createBaseOfferLetterEmployment();
    message.jobDuties = object.jobDuties ?? "";
    message.jobTitle = object.jobTitle ?? "";
    message.startDate = object.startDate ?? undefined;
    message.payScale = (object.payScale !== undefined && object.payScale !== null)
      ? MOfferLetterEmploymentPayScale.fromPartial(object.payScale)
      : undefined;
    message.payRate = object.payRate ?? 0;
    message.payTiming = (object.payTiming !== undefined && object.payTiming !== null)
      ? MOfferLetterEmploymentPayTiming.fromPartial(object.payTiming)
      : undefined;
    message.dateLetter = object.dateLetter ?? undefined;
    message.employeeAddress = object.employeeAddress ?? "";
    message.employeeEmail = object.employeeEmail ?? "";
    message.employeeNameFull = object.employeeNameFull ?? "";
    message.employeeName = object.employeeName ?? "";
    message.letterName = object.letterName ?? "";
    message.letterTitle = object.letterTitle ?? "";
    message.bonusEligible = object.bonusEligible ?? 0;
    message.bonusText = object.bonusText ?? "";
    message.bonusProrateStart = object.bonusProrateStart ?? 0;
    message.benefitsStatus = (object.benefitsStatus !== undefined && object.benefitsStatus !== null)
      ? MOfferLetterEmploymentBenefitsStatus.fromPartial(object.benefitsStatus)
      : undefined;
    message.leaveAnnual = object.leaveAnnual ?? "";
    message.nonSolicit = (object.nonSolicit !== undefined && object.nonSolicit !== null)
      ? MOfferLetterEmploymentNonSolicit.fromPartial(object.nonSolicit)
      : undefined;
    message.nonSolicitTailMonths = object.nonSolicitTailMonths ?? 0;
    message.antiRaidingTailMonths = object.antiRaidingTailMonths ?? 0;
    message.antiRaiding = (object.antiRaiding !== undefined && object.antiRaiding !== null)
      ? MOfferLetterEmploymentAntiRaiding.fromPartial(object.antiRaiding)
      : undefined;
    message.restrictiveCovenant = object.restrictiveCovenant ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
