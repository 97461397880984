/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "";

export enum ESAFEPreOrPost {
  SAFE_preOrPost_NA = 0,
  SAFE_preOrPost_PRE = 1,
  SAFE_preOrPost_POST = 2,
  UNRECOGNIZED = -1,
}

export function eSAFEPreOrPostFromJSON(object: any): ESAFEPreOrPost {
  switch (object) {
    case 0:
    case "SAFE_preOrPost_NA":
      return ESAFEPreOrPost.SAFE_preOrPost_NA;
    case 1:
    case "SAFE_preOrPost_PRE":
      return ESAFEPreOrPost.SAFE_preOrPost_PRE;
    case 2:
    case "SAFE_preOrPost_POST":
      return ESAFEPreOrPost.SAFE_preOrPost_POST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ESAFEPreOrPost.UNRECOGNIZED;
  }
}

export function eSAFEPreOrPostToJSON(object: ESAFEPreOrPost): string {
  switch (object) {
    case ESAFEPreOrPost.SAFE_preOrPost_NA:
      return "SAFE_preOrPost_NA";
    case ESAFEPreOrPost.SAFE_preOrPost_PRE:
      return "SAFE_preOrPost_PRE";
    case ESAFEPreOrPost.SAFE_preOrPost_POST:
      return "SAFE_preOrPost_POST";
    case ESAFEPreOrPost.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ESAFEFlavor {
  SAFE_flavor_NA = 0,
  SAFE_flavor_VAL_CAP = 1,
  SAFE_flavor_DISCOUNT = 2,
  SAFE_flavor_VAL_CAP_AND_DISCOUNT = 3,
  UNRECOGNIZED = -1,
}

export function eSAFEFlavorFromJSON(object: any): ESAFEFlavor {
  switch (object) {
    case 0:
    case "SAFE_flavor_NA":
      return ESAFEFlavor.SAFE_flavor_NA;
    case 1:
    case "SAFE_flavor_VAL_CAP":
      return ESAFEFlavor.SAFE_flavor_VAL_CAP;
    case 2:
    case "SAFE_flavor_DISCOUNT":
      return ESAFEFlavor.SAFE_flavor_DISCOUNT;
    case 3:
    case "SAFE_flavor_VAL_CAP_AND_DISCOUNT":
      return ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ESAFEFlavor.UNRECOGNIZED;
  }
}

export function eSAFEFlavorToJSON(object: ESAFEFlavor): string {
  switch (object) {
    case ESAFEFlavor.SAFE_flavor_NA:
      return "SAFE_flavor_NA";
    case ESAFEFlavor.SAFE_flavor_VAL_CAP:
      return "SAFE_flavor_VAL_CAP";
    case ESAFEFlavor.SAFE_flavor_DISCOUNT:
      return "SAFE_flavor_DISCOUNT";
    case ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT:
      return "SAFE_flavor_VAL_CAP_AND_DISCOUNT";
    case ESAFEFlavor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MSAFEPreOrPost {
  optionDbValue: ESAFEPreOrPost;
  other: string;
}

export interface MSAFEFlavor {
  optionDbValue: ESAFEFlavor;
  other: string;
}

export interface SAFE {
  investorName: string;
  investorAddress: string;
  effectiveDate: Date | undefined;
  amount: number;
  preOrPost: MSAFEPreOrPost | undefined;
  flavor: MSAFEFlavor | undefined;
  incorporationState: string;
  valCap: number;
  discount: number;
}

function createBaseMSAFEPreOrPost(): MSAFEPreOrPost {
  return { optionDbValue: 0, other: "" };
}

export const MSAFEPreOrPost = {
  encode(message: MSAFEPreOrPost, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MSAFEPreOrPost {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMSAFEPreOrPost();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MSAFEPreOrPost {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eSAFEPreOrPostFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MSAFEPreOrPost): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eSAFEPreOrPostToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MSAFEPreOrPost>, I>>(base?: I): MSAFEPreOrPost {
    return MSAFEPreOrPost.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MSAFEPreOrPost>, I>>(object: I): MSAFEPreOrPost {
    const message = createBaseMSAFEPreOrPost();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMSAFEFlavor(): MSAFEFlavor {
  return { optionDbValue: 0, other: "" };
}

export const MSAFEFlavor = {
  encode(message: MSAFEFlavor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MSAFEFlavor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMSAFEFlavor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MSAFEFlavor {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eSAFEFlavorFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MSAFEFlavor): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eSAFEFlavorToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MSAFEFlavor>, I>>(base?: I): MSAFEFlavor {
    return MSAFEFlavor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MSAFEFlavor>, I>>(object: I): MSAFEFlavor {
    const message = createBaseMSAFEFlavor();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseSAFE(): SAFE {
  return {
    investorName: "",
    investorAddress: "",
    effectiveDate: undefined,
    amount: 0,
    preOrPost: undefined,
    flavor: undefined,
    incorporationState: "",
    valCap: 0,
    discount: 0,
  };
}

export const SAFE = {
  encode(message: SAFE, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.investorName !== "") {
      writer.uint32(10).string(message.investorName);
    }
    if (message.investorAddress !== "") {
      writer.uint32(18).string(message.investorAddress);
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.amount !== 0) {
      writer.uint32(37).float(message.amount);
    }
    if (message.preOrPost !== undefined) {
      MSAFEPreOrPost.encode(message.preOrPost, writer.uint32(42).fork()).ldelim();
    }
    if (message.flavor !== undefined) {
      MSAFEFlavor.encode(message.flavor, writer.uint32(50).fork()).ldelim();
    }
    if (message.incorporationState !== "") {
      writer.uint32(58).string(message.incorporationState);
    }
    if (message.valCap !== 0) {
      writer.uint32(69).float(message.valCap);
    }
    if (message.discount !== 0) {
      writer.uint32(77).float(message.discount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SAFE {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSAFE();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.investorName = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.investorAddress = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag != 37) {
            break;
          }

          message.amount = reader.float();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.preOrPost = MSAFEPreOrPost.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.flavor = MSAFEFlavor.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.incorporationState = reader.string();
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.valCap = reader.float();
          continue;
        case 9:
          if (tag != 77) {
            break;
          }

          message.discount = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SAFE {
    return {
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
      investorAddress: isSet(object.investorAddress) ? String(object.investorAddress) : "",
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      preOrPost: isSet(object.preOrPost) ? MSAFEPreOrPost.fromJSON(object.preOrPost) : undefined,
      flavor: isSet(object.flavor) ? MSAFEFlavor.fromJSON(object.flavor) : undefined,
      incorporationState: isSet(object.incorporationState) ? String(object.incorporationState) : "",
      valCap: isSet(object.valCap) ? Number(object.valCap) : 0,
      discount: isSet(object.discount) ? Number(object.discount) : 0,
    };
  },

  toJSON(message: SAFE): unknown {
    const obj: any = {};
    message.investorName !== undefined && (obj.investorName = message.investorName);
    message.investorAddress !== undefined && (obj.investorAddress = message.investorAddress);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.amount !== undefined && (obj.amount = message.amount);
    message.preOrPost !== undefined &&
      (obj.preOrPost = message.preOrPost ? MSAFEPreOrPost.toJSON(message.preOrPost) : undefined);
    message.flavor !== undefined && (obj.flavor = message.flavor ? MSAFEFlavor.toJSON(message.flavor) : undefined);
    message.incorporationState !== undefined && (obj.incorporationState = message.incorporationState);
    message.valCap !== undefined && (obj.valCap = message.valCap);
    message.discount !== undefined && (obj.discount = message.discount);
    return obj;
  },

  create<I extends Exact<DeepPartial<SAFE>, I>>(base?: I): SAFE {
    return SAFE.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SAFE>, I>>(object: I): SAFE {
    const message = createBaseSAFE();
    message.investorName = object.investorName ?? "";
    message.investorAddress = object.investorAddress ?? "";
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.amount = object.amount ?? 0;
    message.preOrPost = (object.preOrPost !== undefined && object.preOrPost !== null)
      ? MSAFEPreOrPost.fromPartial(object.preOrPost)
      : undefined;
    message.flavor = (object.flavor !== undefined && object.flavor !== null)
      ? MSAFEFlavor.fromPartial(object.flavor)
      : undefined;
    message.incorporationState = object.incorporationState ?? "";
    message.valCap = object.valCap ?? 0;
    message.discount = object.discount ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
