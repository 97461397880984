import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
    agreementManagerSlice,
    agreementManagerSelector,
    currentContractSlice,
    uiSlice,
    legalIdeSlice,
    notificationsSlice,
} from "./features";
import { timeTrackingSlice } from "./features/notifications/timeTracking";
import { chatbotSlice } from "./features/chatbot";
import store, { rootReducer } from "./mainStore";

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Selectors = {
    agreementManager: agreementManagerSelector,
};

export const Actions = {
    agreementManager: agreementManagerSlice.actions,
    currentContract: currentContractSlice.actions,
    ui: uiSlice.actions,
    legalIde: legalIdeSlice.actions,
    notifications: notificationsSlice.actions,
    timeTracking: timeTrackingSlice.actions,
    chatbot: chatbotSlice.actions,
};
