import { postUser } from "api-services/UseresApi";
import { httpClient } from "apps/legal-ide/App/api";
import { PUser } from "proto/PUser";
import { useParams } from "react-router-dom";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { setDBUser } from "store/mainActions";
import { AgreementContextType, Answer } from "./AgreementContextType";
import { getFullDocId } from "./shared-api";
import { useNavigation } from "./useNavigation";
import { useWizardApi } from "./useWizardApi";

export const useSimulatorApi = (): AgreementContextType => {
    const agreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const activeDraft = useAppSelector((state) => state.legalIde.simulator.activeDraft);
    const simulator = useAppSelector((state) => state.legalIde.simulator);
    const wizardApi = useWizardApi({ draft: activeDraft, agreement: agreement });

    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);

    const navigation = useNavigation({ draft: activeDraft });

    const dispatch = useAppDispatch();

    const getActiveDraft = () => activeDraft;

    const params = useParams<{ env: string; agreement: string }>();

    const handleAnswer = ({ value, question }: Answer) => {
        const field = agreement?.fields.find((f) => f.id === question.fieldId);

        if (!field) return null;

        if (field.layer === "owner") {
            if (!dbUser) return;
            const newUser: PUser = { ...dbUser, [field.id]: value };

            dispatch(setDBUser(newUser));
            postUser({ user: newUser }, () => {});
        } else {
            dispatch(Actions.legalIde.onAnswer({ name: field.name, value }));
        }

        // TODO: this can be improved or be done by the BE as part of derived actions
        dispatch(Actions.legalIde.onAnswer({ name: "lastUpdateTime", value: new Date() }));
    };
    const handleAnswerByField = ({ value, field }: { value: any; field: Field }) => {
        if (!field) return null;

        if (field.layer === "owner") {
            if (!dbUser) return;
            const newUser: PUser = { ...dbUser, [field.id]: value };

            dispatch(setDBUser(newUser));
            postUser({ user: newUser }, () => {});
        } else {
            dispatch(Actions.legalIde.onAnswer({ name: field.name, value }));
        }

        // TODO: this can be improved or be done by the BE as part of derived actions
        dispatch(Actions.legalIde.onAnswer({ name: "lastUpdateTime", value: new Date() }));
    };

    const getDocTitle = () => {
        return simulator.contract.title;
    };

    return {
        navigation,
        wizardApi,
        getDocTitle,
        handleAnswer,
        handleAnswerByField,
        getActiveDraft,
        getFullDocId,
        getDoc: () => {},
        getOtherSideName: () => "other name placeholder goes here",
        loadAgreement: () => {
            if (!params.agreement || params.env) return;
            httpClient(`/api/v1/agreements/branches/${params?.env}/docs/${params?.agreement}/content`)
                .then((res) => {
                    dispatch(Actions.agreementManager.setCurrentAgreement(JSON.parse(res.data)));
                })
                .catch((err: any) => {
                });
        },
        // cleanup in simulator doesn't do anything, and especially doesn't clean up the agreement - because
        // the agreement is still used by the legal-ide
        cleanup: () => {},
        isSimulator: true,
    };
};
