import ChatLayout from 'components/2_layout/ChatLayout/ChatLayout';
import { useAuth } from 'components/contexts/AuthContext';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Actions, useAppSelector } from 'store';
import { selectors } from 'store/features/chatbot';

export default function ChatPage() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const messages = useAppSelector(selectors.selectCurrentMessages);

    useEffect(() => {
        if (messages.length === 0) {
            dispatch(Actions.chatbot.add({
                text: `Hi, ${auth?.getFirstName()}! How can I help you today?`,
                isUser: false,
            }))
        }
    }, []);

    return (
        <ChatLayout />
    )
}