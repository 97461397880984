/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile } from "../PUtils";

export const protobufPackage = "";

export enum ECDContractBreachBreachChoice {
  CD_ContractBreach_breachChoice_NA = 0,
  CD_ContractBreach_breachChoice_NONE = 1,
  CD_ContractBreach_breachChoice_SUSPEND = 2,
  CD_ContractBreach_breachChoice_NOTICE = 3,
  CD_ContractBreach_breachChoice_TERMINATE = 4,
  UNRECOGNIZED = -1,
}

export function eCDContractBreachBreachChoiceFromJSON(object: any): ECDContractBreachBreachChoice {
  switch (object) {
    case 0:
    case "CD_ContractBreach_breachChoice_NA":
      return ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NA;
    case 1:
    case "CD_ContractBreach_breachChoice_NONE":
      return ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NONE;
    case 2:
    case "CD_ContractBreach_breachChoice_SUSPEND":
      return ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_SUSPEND;
    case 3:
    case "CD_ContractBreach_breachChoice_NOTICE":
      return ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NOTICE;
    case 4:
    case "CD_ContractBreach_breachChoice_TERMINATE":
      return ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_TERMINATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECDContractBreachBreachChoice.UNRECOGNIZED;
  }
}

export function eCDContractBreachBreachChoiceToJSON(object: ECDContractBreachBreachChoice): string {
  switch (object) {
    case ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NA:
      return "CD_ContractBreach_breachChoice_NA";
    case ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NONE:
      return "CD_ContractBreach_breachChoice_NONE";
    case ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_SUSPEND:
      return "CD_ContractBreach_breachChoice_SUSPEND";
    case ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_NOTICE:
      return "CD_ContractBreach_breachChoice_NOTICE";
    case ECDContractBreachBreachChoice.CD_ContractBreach_breachChoice_TERMINATE:
      return "CD_ContractBreach_breachChoice_TERMINATE";
    case ECDContractBreachBreachChoice.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MCDContractBreachBreachChoice {
  optionDbValue: ECDContractBreachBreachChoice;
  other: string;
}

export interface CDContractBreach {
  dateLetter: Date | undefined;
  otherCompanyFull: string;
  otherAddressOther: string;
  otherAddress: string;
  otherName: string;
  underlyingAgreement: string;
  noticeDays: number;
  letterName: string;
  letterTitle: string;
  breachChoice: MCDContractBreachBreachChoice | undefined;
  attachAgreement: InternalBoolean;
  attachAgreementCopy: PFile | undefined;
  otherEmail: string;
  companyShort: string;
  breachList: string[];
  warningDays: number;
  obligationList: string[];
}

function createBaseMCDContractBreachBreachChoice(): MCDContractBreachBreachChoice {
  return { optionDbValue: 0, other: "" };
}

export const MCDContractBreachBreachChoice = {
  encode(message: MCDContractBreachBreachChoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCDContractBreachBreachChoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCDContractBreachBreachChoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCDContractBreachBreachChoice {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eCDContractBreachBreachChoiceFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCDContractBreachBreachChoice): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCDContractBreachBreachChoiceToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCDContractBreachBreachChoice>, I>>(base?: I): MCDContractBreachBreachChoice {
    return MCDContractBreachBreachChoice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCDContractBreachBreachChoice>, I>>(
    object: I,
  ): MCDContractBreachBreachChoice {
    const message = createBaseMCDContractBreachBreachChoice();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseCDContractBreach(): CDContractBreach {
  return {
    dateLetter: undefined,
    otherCompanyFull: "",
    otherAddressOther: "",
    otherAddress: "",
    otherName: "",
    underlyingAgreement: "",
    noticeDays: 0,
    letterName: "",
    letterTitle: "",
    breachChoice: undefined,
    attachAgreement: 0,
    attachAgreementCopy: undefined,
    otherEmail: "",
    companyShort: "",
    breachList: [],
    warningDays: 0,
    obligationList: [],
  };
}

export const CDContractBreach = {
  encode(message: CDContractBreach, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateLetter !== undefined) {
      Timestamp.encode(toTimestamp(message.dateLetter), writer.uint32(10).fork()).ldelim();
    }
    if (message.otherCompanyFull !== "") {
      writer.uint32(18).string(message.otherCompanyFull);
    }
    if (message.otherAddressOther !== "") {
      writer.uint32(26).string(message.otherAddressOther);
    }
    if (message.otherAddress !== "") {
      writer.uint32(34).string(message.otherAddress);
    }
    if (message.otherName !== "") {
      writer.uint32(42).string(message.otherName);
    }
    if (message.underlyingAgreement !== "") {
      writer.uint32(50).string(message.underlyingAgreement);
    }
    if (message.noticeDays !== 0) {
      writer.uint32(61).float(message.noticeDays);
    }
    if (message.letterName !== "") {
      writer.uint32(66).string(message.letterName);
    }
    if (message.letterTitle !== "") {
      writer.uint32(74).string(message.letterTitle);
    }
    if (message.breachChoice !== undefined) {
      MCDContractBreachBreachChoice.encode(message.breachChoice, writer.uint32(82).fork()).ldelim();
    }
    if (message.attachAgreement !== 0) {
      writer.uint32(88).int32(message.attachAgreement);
    }
    if (message.attachAgreementCopy !== undefined) {
      PFile.encode(message.attachAgreementCopy, writer.uint32(98).fork()).ldelim();
    }
    if (message.otherEmail !== "") {
      writer.uint32(106).string(message.otherEmail);
    }
    if (message.companyShort !== "") {
      writer.uint32(114).string(message.companyShort);
    }
    for (const v of message.breachList) {
      writer.uint32(122).string(v!);
    }
    if (message.warningDays !== 0) {
      writer.uint32(133).float(message.warningDays);
    }
    for (const v of message.obligationList) {
      writer.uint32(138).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CDContractBreach {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCDContractBreach();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.dateLetter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.otherCompanyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.otherAddressOther = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.otherAddress = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.otherName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.underlyingAgreement = reader.string();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.noticeDays = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.letterName = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.letterTitle = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.breachChoice = MCDContractBreachBreachChoice.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.attachAgreement = reader.int32() as any;
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.attachAgreementCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.otherEmail = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.breachList.push(reader.string());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.warningDays = reader.float();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.obligationList.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CDContractBreach {
    return {
      dateLetter: isSet(object.dateLetter) ? fromJsonTimestamp(object.dateLetter) : undefined,
      otherCompanyFull: isSet(object.otherCompanyFull) ? String(object.otherCompanyFull) : "",
      otherAddressOther: isSet(object.otherAddressOther) ? String(object.otherAddressOther) : "",
      otherAddress: isSet(object.otherAddress) ? String(object.otherAddress) : "",
      otherName: isSet(object.otherName) ? String(object.otherName) : "",
      underlyingAgreement: isSet(object.underlyingAgreement) ? String(object.underlyingAgreement) : "",
      noticeDays: isSet(object.noticeDays) ? Number(object.noticeDays) : 0,
      letterName: isSet(object.letterName) ? String(object.letterName) : "",
      letterTitle: isSet(object.letterTitle) ? String(object.letterTitle) : "",
      breachChoice: isSet(object.breachChoice)
        ? MCDContractBreachBreachChoice.fromJSON(object.breachChoice)
        : undefined,
      attachAgreement: isSet(object.attachAgreement) ? internalBooleanFromJSON(object.attachAgreement) : 0,
      attachAgreementCopy: isSet(object.attachAgreementCopy) ? PFile.fromJSON(object.attachAgreementCopy) : undefined,
      otherEmail: isSet(object.otherEmail) ? String(object.otherEmail) : "",
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      breachList: Array.isArray(object?.breachList) ? object.breachList.map((e: any) => String(e)) : [],
      warningDays: isSet(object.warningDays) ? Number(object.warningDays) : 0,
      obligationList: Array.isArray(object?.obligationList) ? object.obligationList.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: CDContractBreach): unknown {
    const obj: any = {};
    message.dateLetter !== undefined && (obj.dateLetter = message.dateLetter.toISOString());
    message.otherCompanyFull !== undefined && (obj.otherCompanyFull = message.otherCompanyFull);
    message.otherAddressOther !== undefined && (obj.otherAddressOther = message.otherAddressOther);
    message.otherAddress !== undefined && (obj.otherAddress = message.otherAddress);
    message.otherName !== undefined && (obj.otherName = message.otherName);
    message.underlyingAgreement !== undefined && (obj.underlyingAgreement = message.underlyingAgreement);
    message.noticeDays !== undefined && (obj.noticeDays = message.noticeDays);
    message.letterName !== undefined && (obj.letterName = message.letterName);
    message.letterTitle !== undefined && (obj.letterTitle = message.letterTitle);
    message.breachChoice !== undefined &&
      (obj.breachChoice = message.breachChoice
        ? MCDContractBreachBreachChoice.toJSON(message.breachChoice)
        : undefined);
    message.attachAgreement !== undefined && (obj.attachAgreement = internalBooleanToJSON(message.attachAgreement));
    message.attachAgreementCopy !== undefined &&
      (obj.attachAgreementCopy = message.attachAgreementCopy ? PFile.toJSON(message.attachAgreementCopy) : undefined);
    message.otherEmail !== undefined && (obj.otherEmail = message.otherEmail);
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    if (message.breachList) {
      obj.breachList = message.breachList.map((e) => e);
    } else {
      obj.breachList = [];
    }
    message.warningDays !== undefined && (obj.warningDays = message.warningDays);
    if (message.obligationList) {
      obj.obligationList = message.obligationList.map((e) => e);
    } else {
      obj.obligationList = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CDContractBreach>, I>>(base?: I): CDContractBreach {
    return CDContractBreach.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CDContractBreach>, I>>(object: I): CDContractBreach {
    const message = createBaseCDContractBreach();
    message.dateLetter = object.dateLetter ?? undefined;
    message.otherCompanyFull = object.otherCompanyFull ?? "";
    message.otherAddressOther = object.otherAddressOther ?? "";
    message.otherAddress = object.otherAddress ?? "";
    message.otherName = object.otherName ?? "";
    message.underlyingAgreement = object.underlyingAgreement ?? "";
    message.noticeDays = object.noticeDays ?? 0;
    message.letterName = object.letterName ?? "";
    message.letterTitle = object.letterTitle ?? "";
    message.breachChoice = (object.breachChoice !== undefined && object.breachChoice !== null)
      ? MCDContractBreachBreachChoice.fromPartial(object.breachChoice)
      : undefined;
    message.attachAgreement = object.attachAgreement ?? 0;
    message.attachAgreementCopy = (object.attachAgreementCopy !== undefined && object.attachAgreementCopy !== null)
      ? PFile.fromPartial(object.attachAgreementCopy)
      : undefined;
    message.otherEmail = object.otherEmail ?? "";
    message.companyShort = object.companyShort ?? "";
    message.breachList = object.breachList?.map((e) => e) || [];
    message.warningDays = object.warningDays ?? 0;
    message.obligationList = object.obligationList?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
