import React from "react";

interface SelectProps {
    name: string;
    value: any;
    placeholder?: string;
    onChange: (name: string, value: any) => void;
    options: Option[];
    disabled?: boolean;
    required?: boolean;
    className?: string;
    autoFocus?: boolean;
    asNumber?: boolean;
    overrideOriginalClass?: boolean;
}

export const Select = (props: SelectProps) => {
    const { value, name, onChange, options, disabled, placeholder, required, asNumber } = props;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let newValue: any = event.target.value;
        newValue = asNumber ? Number(newValue) : newValue;
        onChange?.(name, newValue);
    };

    return (
        <select
            required={required}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            value={value}
            className={`${props.overrideOriginalClass ? "" : "border-2 w-48"} ${props.className || ""}`}
            autoFocus={props.autoFocus}
        >
            <option value=""> {placeholder || "Select Option"}</option>
            {options.map((option, index) => (
                <option value={option.value} key={index}>
                    {option.label || option.value}
                </option>
            ))}
        </select>
    );
};
