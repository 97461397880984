import React from 'react'
import SvgRadarPausedIcon from "img/icons/radar-paused.svg"
import { useAppSelector } from 'store'
import { useAgreementContext } from 'apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext';
import { ANALYTICS_TOOLS, checkIfScriptIncludesUrlPiece, DISPLAY_ADS, AD_SERVICE_TOOLS, PrivacyRelatedTool } from './PrivacyScanner';
import { PDoc } from 'proto/PDoc';
import { EPrivacyPolicyThirdTechFields, PrivacyPolicy } from 'proto/contracts/auto-contracts/PrivacyPolicy';

export default function DecisionSupportingToolOptionHint({ option, fieldName }: { option: FieldOption, fieldName: string }) {
    const agreementContext = useAgreementContext();
    let activeDraft = agreementContext?.getActiveDraft();

    if (activeDraft?.legalUnit?.kind === "PrivacyPolicy") {
        return null;
    }

    if (shouldHaveIcon(activeDraft, option.dbValue, fieldName as keyof PrivacyPolicy)) {
        return <Icon />
    }

    return null;
}

function shouldHaveIcon(privacyPolicyDoc: PDoc | undefined, optionDBValue: number, fieldName: keyof PrivacyPolicy) {
    const scripts = privacyPolicyDoc?.legalUnit?.privacyPolicy?.scripts;

    if (privacyPolicyDoc === undefined) {
        return false
    }

    if (!scripts || scripts.length === 0) {
        return false
    }

    if (fieldName === "thirdTechFields" && optionDBValue === EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_COOKIES) {
        return true
    }

    if (fieldName === "thirdTechFields" && optionDBValue === EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_GOOGLE) {
        return true
    }

    if (fieldName === "thirdTechFields" && optionDBValue === EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ANALYTICS) {
        const analyticsScripts = scripts?.filter(s => Object.values(ANALYTICS_TOOLS).some(t => checkIfScriptIncludesUrlPiece(s, t.urlPieceToDetect)))
        if (analyticsScripts.length > 0) {
            return true
        }
    }

    if (fieldName === "thirdTechFields" && optionDBValue === EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADSERVICE) {
        const adServiceScripts = scripts?.filter(s => Object.values(AD_SERVICE_TOOLS).some(t => checkIfScriptIncludesUrlPiece(s, t.urlPieceToDetect)))
        if (adServiceScripts.length > 0) {
            return true
        }
    }

    if (fieldName === "thirdTechFields" && optionDBValue === EPrivacyPolicyThirdTechFields.PrivacyPolicy_thirdTechFields_ADDISPLAY) {
        const adDisplayScripts = scripts?.filter(s => Object.values(AD_SERVICE_TOOLS).some(t => checkIfScriptIncludesUrlPiece(s, t.urlPieceToDetect)))
        if (adDisplayScripts.length > 0) {
            return true
        }
    }

    if (fieldName === "analyticFields") {
        return shouldHaveIconForTool(optionDBValue, ANALYTICS_TOOLS, scripts)
    }

    if (fieldName === "adserviceFields") {
        return shouldHaveIconForTool(optionDBValue, AD_SERVICE_TOOLS, scripts)
    }

    if (fieldName === "adDisplayFields") {
        return shouldHaveIconForTool(optionDBValue, DISPLAY_ADS, scripts)
    }

    return false
}

function shouldHaveIconForTool(optionDBValue: number, tools: PrivacyRelatedTool[], scripts: string[]) {
    const analyticsTool = tools.find(t => (t?.enumVal || 0) === optionDBValue)

    if (!analyticsTool) {
        return false
    }

    if (scripts.filter(s => checkIfScriptIncludesUrlPiece(s, analyticsTool.urlPieceToDetect)).length > 0) {
        return true
    }

    return false;
}

function Icon() {
    return <img title='Detected via Scan' className="inline" src={SvgRadarPausedIcon} width={20} height={20} />
}

export function computeDecisionSupportingToolIssues(doc: PDoc) {
    const privacyPolicy = doc.legalUnit?.privacyPolicy
    const fields = ["thirdTechFields", "analytics", "adserviceFields", "adDisplayFields"] as ((keyof PrivacyPolicy)[])
    let issuesCount = [0, 0, 0, 0];

    if (!privacyPolicy) {
        return [];
    }

    if (doc?.legalUnit?.progress !== 100) {
        return [`Please complete the policy policy. It appears it is only ${doc?.legalUnit?.progress || 0}% complete`];
    }

    if (!privacyPolicy.scripts) {
        return [`Scan hasn't been enabled for this policy. Please enable it by answering websiteUrl and enabling scan`];
    }

    let j = 0;

    fields.forEach(f => {
        // TODO: this is a hack, we should have a better way to get the options
        for (let i = 1; i < 50; i++) {
            if (shouldHaveIcon(doc, i, f)) {
                if (!(privacyPolicy as any)?.[f]?.["optionDbValue"]?.includes(i)) {
                    issuesCount[j] += 1;
                }
            }
        }

        j++;
    });

    let issues = []

    if (issuesCount[0] > 0) {
        issues.push(`Detected ${issuesCount[0]} third party tools used that are not listed in the policy`)
    }

    if (issuesCount[1] > 0) {
        issues.push(`Detected ${issuesCount[1]} analytics tools used that are not listed in the policy`)
    }

    if (issuesCount[2] > 0) {
        issues.push(`Detected ${issuesCount[2]} advertising service tools used that are not listed in the policy`)
    }

    if (issuesCount[3] > 0) {
        issues.push(`Detected ${issuesCount[3]} advertising display tools used that are not listed in the policy`)
    }

    return issues;
}
