import clsx from "clsx";
import isKeyHotkey from "is-hotkey";
import React from "react";
import { useSelector } from "react-redux";
import { BaseRange, Descendant, Node, NodeEntry, Range, Text, Transforms } from "slate";
import { Editable, RenderElementProps, RenderLeafProps, Slate } from "slate-react";
import { MainStore } from "store/mainTypes";
import { Element, InputComponent, Leaf, SignatureComponent } from "./customElements";
import { ToolBar } from "./editor-components";
import "./Editor.css";
import { PageBreakStrategy, SignatureStrategy } from "./parser";
import { CustomEditor } from "./types/custom-types";
import "components/0_common/Scrollbar/Scrollbar.scss";
import { AgreementToolbar } from "./editor-components/AgreementToolbar";
import { useAppSelector } from "store";

const renderElement = (props: RenderElementProps) => {
    if (props.element.type === "input") return <InputComponent {...props} />;
    if (props.element.type === "signature") return <SignatureComponent {...props} />;
    if (props.element.type === "page-break") return <div style={{ pageBreakAfter: "always" }}></div>;
    if (props.element.type === "gpt-split") return <div className="gpt-split" style={{ display: "none" }}></div>;

    return <Element {...props} />;
};

const renderLeaf = (props: RenderLeafProps) => <Leaf {...props} />;

export const RichEditor = (props: {
    value: Descendant[];
    editor: CustomEditor;
    onChange?: (value: Descendant[]) => void;
    mode: "edit" | "preview";
    userFreestyle?: boolean;
    decorate?: (entry: NodeEntry<Node>) => BaseRange[];
}) => {
    const { value, onChange, mode, decorate, editor, userFreestyle = false } = props;

    const isPrintMode = useSelector((state: { mainReducer: MainStore }) => !state.mainReducer.showExplanations);

    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const he = currentAgreement?.lang === "HE"

    return (
        <Slate editor={editor} value={value} onChange={onChange ? onChange : () => {}}>
            <div
                id="RichEditor"
                className={`flex flex-col gap-2 ${mode === "edit" ? "overflow-hidden h-full flex-grow" : ""}`}
            >
                {mode === "edit" && <ToolBar userFreestyle={userFreestyle} />}
                <div
                    id="root"
                    className={`flex-grow  relative overflow-auto ${
                        mode === "edit" ? "h-[calc(100vh-15rem)] _ScrollbarNarrow" : ""
                    }`}
                >
                    <Editable
                        id="Editable"
                        renderElement={renderElement}
                        readOnly={mode !== "edit"}
                        renderLeaf={renderLeaf}
                        placeholder="Enter some rich text…"
                        dir={!he ? "ltr" : "rtl"}
                        lang={he ? "he" : "en"}
                        spellCheck
                        decorate={(entry) => {
                            // syntex hightlight
                            const [node, path] = entry;
                            const regExp = /\[\[.+?\]\]/g;

                            const signatureStrategy = new SignatureStrategy();
                            const pageBreakStrategy = new PageBreakStrategy();

                            let ranges: any = decorate ? decorate(entry) : [];
                            if (Text.isText(node)) {
                                const { text } = node;

                                for (const match of [...text.matchAll(regExp)]) {
                                    if (match) {
                                        const m = match[0];
                                        const index = match.index || 0;

                                        ranges.push({
                                            anchor: { path, offset: index },
                                            focus: { path, offset: index + m.length },
                                            fillable: true,
                                        });
                                    }
                                }

                                [signatureStrategy, pageBreakStrategy].forEach((strategy) => {
                                    if (strategy.test(text)) {
                                        const match = strategy.getData(text);

                                        ranges.push({
                                            anchor: { path, offset: match.index },
                                            focus: { path, offset: match.fullMatch.length },
                                            signature: true,
                                        });
                                    }
                                });
                            }

                            return ranges;
                        }}
                        autoFocus
                        style={{
                            // position: `${isPrintMode ? "relative" : "absolute"}`, todo: why??
                            position: "relative",
                            fontSize: "1rem",
                            fontFamily: "Arial",
                            lineHeight: "1.8",
                        }}
                        className={clsx(" w-full overflow-y-auto", {
                            "pt-8 px-8 ltr:pl-16 rtl:pr-16": mode === "preview",
                            "top-0 left-0 bottom-0 right-0 border-2 p-16": mode === "edit",
                        })}
                        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                            const controlPressed = event.ctrlKey;

                            if (event.key === "Enter" && !event.shiftKey) {
                                event.preventDefault();

                                Transforms.insertNodes(editor, {
                                    type: "paragraph",
                                    children: [{ text: "" }],
                                });
                            }

                            const { selection } = editor;

                            if (selection && Range.isCollapsed(selection)) {
                                const { nativeEvent } = event;
                                if (isKeyHotkey("left", nativeEvent)) {
                                    event.preventDefault();
                                    Transforms.move(editor, { unit: "offset", reverse: true });
                                    return;
                                }
                                if (isKeyHotkey("right", nativeEvent)) {
                                    event.preventDefault();
                                    Transforms.move(editor, { unit: "offset" });
                                    return;
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </Slate>
    );
};
