export function formatDate(date: Date | undefined) {
    return date?.toString().replace(/\w+ (\w+) (\d+) (\d+).*/, '$1-$2-$3')
}

export function getBase64Image(img: HTMLImageElement | null, cb: Function) {
    if (!img) {
        return ""
    }
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    img.onload = () => {
        ctx?.drawImage(img, 0, 0, img.width, img.height);

        const dataURL = canvas.toDataURL("image/png");
        cb(dataURL)
    }
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function safeArray<T>(arr: T[] | undefined): T[] {
    if (!arr) {
        return []
    }

    if (!Array.isArray(arr)) {
        return []
    }

    return arr;
}

export function capitalizeFirstLetter(str?: string) {
    if (!str) {
        return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCookieValue(a: string) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

const MARKETING_PREFIX = "marketing_";

export function saveQueryParamsToLocalStorage(location: string) {
    const queryParams = new URLSearchParams(location.split("?")[1]);

    queryParams.forEach((value, key) => {
        localStorage.setItem(MARKETING_PREFIX + key, value);
    });
}

export function getMarketingUtmTerm() {
    return localStorage.getItem(MARKETING_PREFIX + "utm_term") || "";
}

export function isWixFlow() {
    return !!localStorage.getItem(MARKETING_PREFIX + "wix");
}

export function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
