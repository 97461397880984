import { PContactInfo } from 'proto/PContactInfo';
import { PTimeRecord } from 'proto/PTimeRecord';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { MainStore } from 'store/mainTypes';
import { secondsToClock, TimeTrackingToolTable } from './TimeTrackingTool';

export const TimeTrackingPdfTemplate = forwardRef<HTMLDivElement, { client: PContactInfo, monthStr: string, relevantRecords: PTimeRecord[] }>(
    (props, ref) => {
        const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
        const { client, monthStr, relevantRecords } = props;

        return (
            <div ref={ref}>
                <p style={{ fontSize: "24px", fontWeight: "600", textAlign: "center" }}>Time Tracking Report</p>
                <p style={{ fontSize: "20px", fontWeight: "500", textAlign: "center" }}>{monthStr}</p>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>From: {dbUser?.fullName}</p>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Email: {dbUser?.email}</p>
                        {dbUser?.companyFullName && <p style={{ fontSize: "18px", fontWeight: "500" }}>Company name: {dbUser?.companyFullName}</p>}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>To: {client.clientName}</p>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Email: {client.clientEmail}</p>
                        {client.phone && <p style={{ fontSize: "18px", fontWeight: "500" }}>Phone: {client.phone}</p>}
                        {client.billingAddress && <p style={{ fontSize: "18px", fontWeight: "500" }}>Billing Address: {client.billingAddress}</p>}
                    </div>
                </div>
                <hr />
                <p><br /></p>
                <TimeTrackingToolTable headersAndRenderers={headersAndRenderers} />
                <hr />
                <p style={{ fontSize: "18px", fontWeight: "700" }}>Total due: ${relevantRecords?.reduce((acc, curr) => acc + (curr.hourlyFee * (curr.elapsedTime / 1000 / 60 / 60)), 0).toLocaleString()}</p>
            </div>
        );
    });


const headersAndRenderers = [
    {
        header: "Billable",
        renderer: (data: PTimeRecord) => <p>{data.isBillable ? "Yes" : "No"}</p>
    },
    {
        header: "Task",
        renderer: (data: PTimeRecord) => <p>{data.taskName}</p>
    },
    {
        header: "Start Time",
        renderer: (data: PTimeRecord) => <p>{data.startTime?.toLocaleDateString() || ""}</p>
    },
    {
        header: "Duration",
        renderer: (data: PTimeRecord) => <p>{secondsToClock(data.elapsedTime / 1000)}</p>
    },
    {
        header: "Hourly Rate",
        renderer: (data: PTimeRecord) => <p>{"$" + data.hourlyFee.toLocaleString()}</p>
    },
    {
        header: "Total",
        renderer: (data: PTimeRecord) => <p>{"$" + (data.hourlyFee * (data.elapsedTime / 1000 / 60 / 60)).toLocaleString()}</p>
    }
]
