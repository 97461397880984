import { createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { Descendant } from "slate";
import { nanoid } from "nanoid";
import { RootState } from "store";
import { FieldType } from "apps/legal-ide/App/Editor/types/custom-types";
import { DraggableLocation } from "react-beautiful-dnd";
import { ReactNode } from "react";

type AgreementManagerSliceState = {
    isPreviewOpen: boolean;
    agreements: AgreementManager.File[];
    currentAgreement: Agreement | undefined;
    env: {
        currentEnv: string;
        list: AgreementManager.Env[];
    };
};

export namespace IDEHealthStatus {
    export type status = {
        severity: "warning" | "error";
        message: ReactNode;
    };
}

function selectHealthStatus(state: RootState): {
    all: IDEHealthStatus.status[];
    warnings: IDEHealthStatus.status[];
    errors: IDEHealthStatus.status[];
    isHealthy: boolean;
} {
    const agreement = state.agreementManager.currentAgreement;

    const questions = agreement?.wizard.questions.allIds.map((id) => agreement.wizard.questions.byId[id]) || [];
    const questionsWithoutField = questions.filter((q) => !q.fieldId);

    let bugs: IDEHealthStatus.status[] = [];

    // questions without fields
    questionsWithoutField.forEach((q) => {
        bugs.push({ severity: "warning", message: `question without field (${q.text})` });
    });

    // question that has invalid fieldId property
    questions
        .filter((q) => q.fieldId && !agreement?.fields.find((f) => f.id === q.fieldId))
        .forEach((q) => {
            bugs.push({
                severity: "warning",
                message: `question (${q.text}) - has an invalid fieldId (field with "${q.fieldId}" id not found)`,
            });
        });

    // question that ask about SAME field
    // let duplicates = questions.reduce<{ [key: string]: string[] }>((acc, q) => {
    //     if (!q.fieldId) return acc;
    //     if (acc[q.fieldId]) {
    //         acc[q.fieldId].push(q.id);
    //     } else {
    //         acc[q.fieldId] = [q.id];
    //     }

    //     return acc;
    // }, {});

    // Object.entries(duplicates).forEach((entry) => {
    //     const [fieldId, questionIds] = entry;
    //     const field = agreement?.fields.find((f) => f.id === fieldId);
    //     if (questionIds.length > 1) {
    //         bugs.push({
    //             severity: "warning",
    //             message: `field "${field?.name} has more than one question (${questionIds.length})"`,
    //         });
    //     }
    // });

    // fields without question binding
    agreement?.fields.forEach((field) => {
        if (!questions.find((q) => q.fieldId === field.id) && !field.layer) {
            bugs.push({
                severity: "warning",
                message: `field "${field.name}" doesn't have a question referencing it`,
            });
        }
    });

    const warnings = bugs.filter((bug) => bug.severity === "warning");
    const errors = bugs.filter((bug) => bug.severity === "error");

    return {
        all: bugs,
        warnings,
        errors,
        isHealthy: !errors.length && !warnings.length,
    };
}

export const selectIsProdEnv = createDraftSafeSelector(
    (state: RootState) => state.agreementManager.env.currentEnv,
    (env) => env === "main"
);

export const agreementManagerSelector = {
    selectQuestions: createSelector(
        (state: RootState) => state.agreementManager.currentAgreement?.wizard.questions,
        (q) => (q ? q?.allIds.map((id) => q.byId[id]) : [])
    ),

    selectSections: createSelector(
        (state: RootState) => state.agreementManager.currentAgreement?.wizard.sections,
        (q) => (q ? q?.allIds.map((id) => q.byId[id]) : [])
    ),
    selectSteps: createSelector(
        (state: RootState) => state.agreementManager.currentAgreement?.wizard.steps,
        (q) => (q ? q?.allIds.map((id) => q.byId[id]) : [])
    ),
    selectIsProdEnv,
    selectHealthStatus,
};

function arraySwap(arr: any[], a: number, b: number, draggableId: string) {
    arr.splice(a, 1);
    arr.splice(b, 0, draggableId);
    return arr;
}

export const agreementManagerSlice = createSlice({
    name: "agreementManager",
    initialState: {
        isPreviewOpen: false,
        agreements: [],
        currentAgreement: null,
        env: { currentEnv: "", list: [] },
    } as unknown as AgreementManagerSliceState,
    reducers: {
        // ENV
        setEnvs: (state, action: PayloadAction<AgreementManager.Env[]>) => {
            state.env.list = action.payload;
        },
        addEnv: (state, action: PayloadAction<AgreementManager.Env>) => {
            state.env.list.push(action.payload);
        },
        setCurrentEnv: (state, action: PayloadAction<string>) => {
            state.env.currentEnv = action.payload;
        },

        setIntroVideo: (state, action: PayloadAction<VideoInfo>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.introVideo = action.payload;
        },

        //questionsWizard
        addQuestion: (state, action: PayloadAction<{ sectionId: string; stepId: string }>) => {
            if (!state.currentAgreement) return;
            const id = nanoid();
            state.currentAgreement.wizard.questions.allIds.push(id);
            state.currentAgreement.wizard.questions.byId[id] = {
                type: "Question",
                id,
                text: "new question?",
            };
            state.currentAgreement.wizard.steps.byId[action.payload.stepId].questions.push(id);
        },
        addStep: (state, action: PayloadAction<{ sectionId: string; stepId: string }>) => {
            if (!state.currentAgreement) return;
            const { sectionId, stepId } = action.payload;
            state.currentAgreement.wizard.sections.byId[sectionId].steps.push(stepId);
            state.currentAgreement.wizard.steps.byId[stepId] = {
                name: "new step",
                questions: [],
                id: stepId,
                type: "Step",
            };
            state.currentAgreement.wizard.steps.allIds.push(stepId);
        },
        updateQuestionKb: (state, action: PayloadAction<{ questionId: string; value: string }>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.wizard.questions.byId[action.payload.questionId].kb = action.payload.value;
        },
        updateQuestionVideo: (state, action: PayloadAction<{ questionId: string; value: string }>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.wizard.questions.byId[action.payload.questionId].video = action.payload.value;
        },
        updateQuestionField: (state, action: PayloadAction<{ questionId: string; fieldId: string }>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.wizard.questions.byId[action.payload.questionId].fieldId = action.payload.fieldId;
        },
        addSection: (state) => {
            if (!state.currentAgreement) return;
            let newId = nanoid();
            state.currentAgreement?.wizard.sections.allIds.push(newId);
            state.currentAgreement.wizard.sections.byId[newId] = {
                steps: [],
                name: "new section",
                id: newId,
                type: "Section",
            };
        },
        replaceSections: (state, action: PayloadAction<{ source: any; destination: any; draggableId: string }>) => {
            const { source, destination, draggableId } = action.payload;
            arraySwap(
                state.currentAgreement?.wizard.sections.allIds || [],
                source.index,
                destination.index,
                draggableId
            );
        },

        replaceSteps: (
            state,
            action: PayloadAction<{
                destination: DraggableLocation;
                source: DraggableLocation;
                draggableId: string;
            }>
        ) => {
            const { source, destination, draggableId } = action.payload;
            if (!state.currentAgreement) return;

            const sectionIndex = state.currentAgreement?.wizard.sections.allIds.findIndex((id) => {
                return id === source.droppableId;
            });
            if (sectionIndex === undefined || sectionIndex === null) return;

            const sectionId = state.currentAgreement?.wizard.sections.allIds[sectionIndex];
            if (destination.droppableId === source.droppableId) {
                arraySwap(
                    state.currentAgreement.wizard.sections.byId[sectionId].steps,
                    source.index,
                    destination.index,
                    draggableId
                );
                return;
            }
            const item = state.currentAgreement?.wizard.sections.byId[source.droppableId].steps[source.index];
            state.currentAgreement?.wizard.sections.byId[source.droppableId].steps.splice(source.index, 1);
            const newSection = state.currentAgreement?.wizard.sections.byId[destination.droppableId];
            if (!newSection) return;
            newSection.steps.splice(destination.index, 0, item);
        },
        replaceQuestions: (
            state,
            action: PayloadAction<{
                destination: DraggableLocation;
                source: DraggableLocation;
                draggableId: string;
            }>
        ) => {
            const { source, destination, draggableId } = action.payload;

            if (!state.currentAgreement) return;

            const sourceStepId = source.droppableId;
            const destinationStepId = destination.droppableId;

            if (destination.droppableId === source.droppableId) {
                arraySwap(
                    state.currentAgreement?.wizard.steps.byId[sourceStepId].questions,
                    source.index,
                    destination.index,
                    draggableId
                );
                return;
            }
            const qId = state.currentAgreement?.wizard.steps.byId[sourceStepId].questions.splice(source.index, 1);
            state.currentAgreement.wizard.steps.byId[destinationStepId].questions.splice(destination.index, 0, qId[0]);
        },
        removeSectionByIndex: (state, action: PayloadAction<{ index: number }>) => {
            const { index } = action.payload;
            const deletedId = state.currentAgreement?.wizard.sections.allIds.splice(index, 1);
            if (!deletedId) return;
            const section = state.currentAgreement?.wizard.sections.byId[deletedId[0]];
            delete state.currentAgreement?.wizard.sections.byId[deletedId[0]];
            // remove section steps
            section?.steps.forEach((stepId) => {
                const index = state.currentAgreement!.wizard.steps.allIds.indexOf(stepId);
                const step = state.currentAgreement?.wizard.steps.byId[stepId];
                state.currentAgreement?.wizard.steps.allIds.splice(index, 1);
                delete state.currentAgreement?.wizard.steps.byId[stepId];

                step?.questions.forEach((qId) => {
                    const index = state.currentAgreement?.wizard.questions.allIds.indexOf(qId);
                    if (index === -1 || index === undefined) return;
                    state.currentAgreement?.wizard.questions.allIds.splice(index, 1);
                    delete state.currentAgreement?.wizard.questions.byId[qId];
                });
            });
        },

        updateSectionName: (state, action: PayloadAction<{ sectionId: string; name: string }>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.wizard.sections.byId[action.payload.sectionId].name = action.payload.name;
        },
        updateSection: (state, action: PayloadAction<{ sectionId: string; data: Section }>) => {
            state.currentAgreement!.wizard.sections.byId[action.payload.sectionId] = action.payload.data;
        },
        updateStep: (state, action: PayloadAction<{ stepId: string; data: Step }>) => {
            state.currentAgreement!.wizard.steps.byId[action.payload.stepId] = action.payload.data;
        },
        updateQuestion: (state, action: PayloadAction<{ questionId: string; data: Question }>) => {
            state.currentAgreement!.wizard.questions.byId[action.payload.questionId] = action.payload.data;
        },

        removeStepById: (state, action: PayloadAction<{ sectionId: string; stepId: string }>) => {
            const { stepId, sectionId } = action.payload;

            const index = state.currentAgreement?.wizard.steps.allIds.indexOf(stepId);
            if (index === -1 || index === undefined) return;
            const step = state.currentAgreement?.wizard.steps.byId[stepId];

            state.currentAgreement?.wizard.steps.allIds.splice(index, 1);
            delete state.currentAgreement?.wizard.steps.byId[stepId];

            step?.questions.forEach((qId) => {
                const index = state.currentAgreement?.wizard.questions.allIds.indexOf(qId);
                if (index === -1 || index === undefined) return;
                state.currentAgreement?.wizard.questions.allIds.splice(index, 1);
                delete state.currentAgreement?.wizard.questions.byId[qId];
            });

            state.currentAgreement!.wizard.sections.byId[sectionId].steps =
                state.currentAgreement!.wizard.sections.byId[sectionId].steps.filter((q) => q !== stepId);
        },

        updateStepName: (state, action: PayloadAction<{ sectionId: string; stepId: string; name: string }>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.wizard.steps.byId[action.payload.stepId].name = action.payload.name;
        },

        removeQuestionById: (state, action: PayloadAction<{ questionId: string; stepId: string }>) => {
            const { questionId, stepId } = action.payload;

            const index = state.currentAgreement?.wizard.questions.allIds.indexOf(questionId);
            if (index === -1 || index === undefined) return;

            state.currentAgreement?.wizard.questions.allIds.splice(index, 1);
            delete state.currentAgreement?.wizard.questions.byId[questionId];

            state.currentAgreement!.wizard.steps.byId[stepId].questions = state.currentAgreement!.wizard.steps.byId[
                stepId
            ].questions.filter((q) => q !== questionId);
        },

        updateQuestionName: (
            state,
            action: PayloadAction<{ sectionId: string; stepId: string; questionId: string; name: string }>
        ) => {
            if (!state.currentAgreement) return;

            state.currentAgreement.wizard.questions.byId[action.payload.questionId].text = action.payload.name;
        },

        // PREVIEW
        showPreview: (state) => {
            state.isPreviewOpen = true;
        },

        updateFieldName: (state, action: PayloadAction<{ id: string; name: string }>) => {
            if (!state.currentAgreement) return;
            const index = state.currentAgreement.fields.findIndex((field) => field.id === action.payload.id);
            if (index === -1) return;
            state.currentAgreement.fields[index].name = action.payload.name;
        },
        removeField: (state, action: PayloadAction<{ id: string }>) => {
            if (!state.currentAgreement) return;
            const index = state.currentAgreement.fields.findIndex((field) => field.id === action.payload.id);
            if (index === -1) return;
            state.currentAgreement.fields.splice(index, 1);
        },
        addField: (state) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.fields.push({
                id: nanoid(),
                name: `new_field_${state.currentAgreement.fields.length}`,
                type: FieldType.STRING,
                optionCounter: 1,
            });
        },
        // todo: refactor this code
        upadteField: (state, action: PayloadAction<{ row: { id: string }; column: { key: string }; value: any }>) => {
            if (!state.currentAgreement) return;
            const index = state.currentAgreement.fields.findIndex((field) => field.id === action.payload.row.id);
            if (index === -1) return;
            const key = action.payload.column.key as keyof Field;
            if (key === "optionCounter") return;

            state.currentAgreement.fields[index][key] = action.payload.value;
        },

        addFields: (state, action: PayloadAction<Field[]>) => {
            if (!state.currentAgreement) return;
            const allFields = state.currentAgreement.fields.concat(action.payload);
            const uniqueAllFields = allFields.filter((f) => allFields.find((f2) => f2.name === f.name) === f);
            state.currentAgreement.fields = uniqueAllFields;
        },
        setFields: (state, action: PayloadAction<Field>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.fields = state.currentAgreement.fields.map((f) =>
                f.id === action.payload.id ? action.payload : f
            );
        },
        updateFieldOption: (
            state,
            action: PayloadAction<{ fieldId: string; optionId: string; value: FieldOption }>
        ) => {
            if (!state.currentAgreement) return;

            state.currentAgreement.fields = state.currentAgreement.fields.map((f) =>
                f.id === action.payload.fieldId
                    ? {
                          ...f,
                          options: f.options?.map((o) => (o.id === action.payload.optionId ? action.payload.value : o)),
                      }
                    : f
            );
        },

        updateFieldOptionByIdex: (state, action: PayloadAction<Field>) => {
            if (!state.currentAgreement) return;
            const index = state.currentAgreement.fields.findIndex((field) => field.id === action.payload.id);
            if (index === -1) return;
            const field = state.currentAgreement.fields[index];
            const options = field.options ? state.currentAgreement.fields[index].options : [];
            options?.push({ label: "", value: "", dbValue: 1, id: nanoid(), type: "FieldOption", fieldId: field.id });
            state.currentAgreement.fields[index].options = options;
        },
        addCondition: (state, action: PayloadAction<Condition>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.conditions.push(action.payload);
        },
        addConditionRule: (state, action: PayloadAction<Condition>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.conditions.push(action.payload);
        },
        removeCondition: (state, action: PayloadAction<{ id: string }>) => {
            if (!state.currentAgreement) return;
            const { payload } = action;
            const index = state.currentAgreement?.conditions.findIndex((con) => con.id === payload.id);
            state.currentAgreement?.conditions.splice(index, 1);
        },
        addKnowlageBase: (state, action: PayloadAction<KnowledgeBase>) => {
            if (!state.currentAgreement) return;
            const { payload } = action;
            state.currentAgreement.knowledgeBases.push(payload);
        },
        removeKnowledgeBase: (state, action: PayloadAction<{ id: string }>) => {
            if (!state.currentAgreement) return;
            const { payload } = action;
            const index = state.currentAgreement?.knowledgeBases.findIndex((kb) => kb.id === payload.id);
            state.currentAgreement?.knowledgeBases.splice(index, 1);
        },
        updateKnowlageBaseText: (state, action: PayloadAction<KnowledgeBase>) => {
            if (!state.currentAgreement) return;
            const { payload } = action;
            const index = state.currentAgreement?.knowledgeBases.findIndex((kb) => kb.id === payload.id);
            state.currentAgreement.knowledgeBases[index].text = payload.text;
        },
        saveDocument: (state, action: PayloadAction<AgreementManager.File>) => {
            if (!state.currentAgreement) return;
            state.agreements = state.agreements.map((agreement) => {
                if (agreement.name === action.payload.name) return action.payload;
                return agreement;
            });
        },
        createAgreement: (state, action: PayloadAction<AgreementManager.File>) => {
            state.agreements.push(action.payload);
        },

        setAgreements: (state, action: PayloadAction<AgreementManager.File[]>) => {
            state.agreements = action.payload;
        },
        removeAgreement: (state, action: PayloadAction<Agreement["id"]>) => {
            state.agreements = state.agreements.filter((a) => a.name !== action.payload);
        },
        setAgreementSrc: (state, action: PayloadAction<Descendant[]>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.src = action.payload;
        },
        setAgreementMarkdownSrc: (state, action: PayloadAction<string>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.markdownSrc = action.payload;
        },
        setCurrentAgreement: (state, action: PayloadAction<Agreement | undefined>) => {
            state.currentAgreement = action.payload;
        },

        setCurrentAgreementName: (state, action: PayloadAction<Agreement["name"]>) => {
            if (!state.currentAgreement) return;
            state.currentAgreement.name = action.payload;
        },
    },
});
