/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum ESaasSalesAgreementV2PaymentDesc {
  SaasSalesAgreement_V2_paymentDesc_NA = 0,
  SaasSalesAgreement_V2_paymentDesc_MONTHLY_PRE_PAID = 1,
  SaasSalesAgreement_V2_paymentDesc_FREE_TEXT = 2,
  UNRECOGNIZED = -1,
}

export function eSaasSalesAgreementV2PaymentDescFromJSON(object: any): ESaasSalesAgreementV2PaymentDesc {
  switch (object) {
    case 0:
    case "SaasSalesAgreement_V2_paymentDesc_NA":
      return ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_NA;
    case 1:
    case "SaasSalesAgreement_V2_paymentDesc_MONTHLY_PRE_PAID":
      return ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_MONTHLY_PRE_PAID;
    case 2:
    case "SaasSalesAgreement_V2_paymentDesc_FREE_TEXT":
      return ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_FREE_TEXT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ESaasSalesAgreementV2PaymentDesc.UNRECOGNIZED;
  }
}

export function eSaasSalesAgreementV2PaymentDescToJSON(object: ESaasSalesAgreementV2PaymentDesc): string {
  switch (object) {
    case ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_NA:
      return "SaasSalesAgreement_V2_paymentDesc_NA";
    case ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_MONTHLY_PRE_PAID:
      return "SaasSalesAgreement_V2_paymentDesc_MONTHLY_PRE_PAID";
    case ESaasSalesAgreementV2PaymentDesc.SaasSalesAgreement_V2_paymentDesc_FREE_TEXT:
      return "SaasSalesAgreement_V2_paymentDesc_FREE_TEXT";
    case ESaasSalesAgreementV2PaymentDesc.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MSaasSalesAgreementV2PaymentDesc {
  optionDbValue: ESaasSalesAgreementV2PaymentDesc;
  other: string;
}

export interface SaasSalesAgreementV2 {
  effectiveDate: Date | undefined;
  otherName: string;
  otherAddress: string;
  services: string;
  servicesFee: number;
  initialServiceTerm: number;
  serviceCapacity: string[];
  implementationFee: number;
  pilotPeriodDays: number;
  pilotUseFee: number;
  enableSLA: InternalBoolean;
  hasPilotPeriod: InternalBoolean;
  areServicesDuringPilotRestrictedToNonProductive: InternalBoolean;
  willCustomerOwnDerivativeData: InternalBoolean;
  companyHasRightToCollectAndAnalyze: InternalBoolean;
  uponTerminationMakeDataAvailable: InternalBoolean;
  includeIndemnificationByCompany: InternalBoolean;
  willDoJointPressRelease: InternalBoolean;
  canUseAsRefence: InternalBoolean;
  hasPhoneSupport: InternalBoolean;
  statementOfWork: string[];
  supportHours: string;
  supportPhone: string;
  supportEmail: string;
  expectedDaysToRespondTicket: number;
  creditForDowntime: number;
  maxCreditPercentage: number;
  governingLawState: string;
  feesForOverages: string;
  addressNeedToBeSet: InternalBoolean;
  paymentDesc: MSaasSalesAgreementV2PaymentDesc | undefined;
  paymentFreeTextDesc: string;
  enableDataProtection: InternalBoolean;
  includePolicyLinks: InternalBoolean;
  policyLinks: string[];
  venue: string;
}

function createBaseMSaasSalesAgreementV2PaymentDesc(): MSaasSalesAgreementV2PaymentDesc {
  return { optionDbValue: 0, other: "" };
}

export const MSaasSalesAgreementV2PaymentDesc = {
  encode(message: MSaasSalesAgreementV2PaymentDesc, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MSaasSalesAgreementV2PaymentDesc {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMSaasSalesAgreementV2PaymentDesc();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MSaasSalesAgreementV2PaymentDesc {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eSaasSalesAgreementV2PaymentDescFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MSaasSalesAgreementV2PaymentDesc): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eSaasSalesAgreementV2PaymentDescToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MSaasSalesAgreementV2PaymentDesc>, I>>(
    base?: I,
  ): MSaasSalesAgreementV2PaymentDesc {
    return MSaasSalesAgreementV2PaymentDesc.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MSaasSalesAgreementV2PaymentDesc>, I>>(
    object: I,
  ): MSaasSalesAgreementV2PaymentDesc {
    const message = createBaseMSaasSalesAgreementV2PaymentDesc();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseSaasSalesAgreementV2(): SaasSalesAgreementV2 {
  return {
    effectiveDate: undefined,
    otherName: "",
    otherAddress: "",
    services: "",
    servicesFee: 0,
    initialServiceTerm: 0,
    serviceCapacity: [],
    implementationFee: 0,
    pilotPeriodDays: 0,
    pilotUseFee: 0,
    enableSLA: 0,
    hasPilotPeriod: 0,
    areServicesDuringPilotRestrictedToNonProductive: 0,
    willCustomerOwnDerivativeData: 0,
    companyHasRightToCollectAndAnalyze: 0,
    uponTerminationMakeDataAvailable: 0,
    includeIndemnificationByCompany: 0,
    willDoJointPressRelease: 0,
    canUseAsRefence: 0,
    hasPhoneSupport: 0,
    statementOfWork: [],
    supportHours: "",
    supportPhone: "",
    supportEmail: "",
    expectedDaysToRespondTicket: 0,
    creditForDowntime: 0,
    maxCreditPercentage: 0,
    governingLawState: "",
    feesForOverages: "",
    addressNeedToBeSet: 0,
    paymentDesc: undefined,
    paymentFreeTextDesc: "",
    enableDataProtection: 0,
    includePolicyLinks: 0,
    policyLinks: [],
    venue: "",
  };
}

export const SaasSalesAgreementV2 = {
  encode(message: SaasSalesAgreementV2, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.otherName !== "") {
      writer.uint32(18).string(message.otherName);
    }
    if (message.otherAddress !== "") {
      writer.uint32(26).string(message.otherAddress);
    }
    if (message.services !== "") {
      writer.uint32(34).string(message.services);
    }
    if (message.servicesFee !== 0) {
      writer.uint32(45).float(message.servicesFee);
    }
    if (message.initialServiceTerm !== 0) {
      writer.uint32(53).float(message.initialServiceTerm);
    }
    for (const v of message.serviceCapacity) {
      writer.uint32(58).string(v!);
    }
    if (message.implementationFee !== 0) {
      writer.uint32(69).float(message.implementationFee);
    }
    if (message.pilotPeriodDays !== 0) {
      writer.uint32(77).float(message.pilotPeriodDays);
    }
    if (message.pilotUseFee !== 0) {
      writer.uint32(85).float(message.pilotUseFee);
    }
    if (message.enableSLA !== 0) {
      writer.uint32(88).int32(message.enableSLA);
    }
    if (message.hasPilotPeriod !== 0) {
      writer.uint32(96).int32(message.hasPilotPeriod);
    }
    if (message.areServicesDuringPilotRestrictedToNonProductive !== 0) {
      writer.uint32(104).int32(message.areServicesDuringPilotRestrictedToNonProductive);
    }
    if (message.willCustomerOwnDerivativeData !== 0) {
      writer.uint32(112).int32(message.willCustomerOwnDerivativeData);
    }
    if (message.companyHasRightToCollectAndAnalyze !== 0) {
      writer.uint32(120).int32(message.companyHasRightToCollectAndAnalyze);
    }
    if (message.uponTerminationMakeDataAvailable !== 0) {
      writer.uint32(128).int32(message.uponTerminationMakeDataAvailable);
    }
    if (message.includeIndemnificationByCompany !== 0) {
      writer.uint32(136).int32(message.includeIndemnificationByCompany);
    }
    if (message.willDoJointPressRelease !== 0) {
      writer.uint32(144).int32(message.willDoJointPressRelease);
    }
    if (message.canUseAsRefence !== 0) {
      writer.uint32(152).int32(message.canUseAsRefence);
    }
    if (message.hasPhoneSupport !== 0) {
      writer.uint32(160).int32(message.hasPhoneSupport);
    }
    for (const v of message.statementOfWork) {
      writer.uint32(170).string(v!);
    }
    if (message.supportHours !== "") {
      writer.uint32(178).string(message.supportHours);
    }
    if (message.supportPhone !== "") {
      writer.uint32(186).string(message.supportPhone);
    }
    if (message.supportEmail !== "") {
      writer.uint32(194).string(message.supportEmail);
    }
    if (message.expectedDaysToRespondTicket !== 0) {
      writer.uint32(205).float(message.expectedDaysToRespondTicket);
    }
    if (message.creditForDowntime !== 0) {
      writer.uint32(213).float(message.creditForDowntime);
    }
    if (message.maxCreditPercentage !== 0) {
      writer.uint32(221).float(message.maxCreditPercentage);
    }
    if (message.governingLawState !== "") {
      writer.uint32(226).string(message.governingLawState);
    }
    if (message.feesForOverages !== "") {
      writer.uint32(234).string(message.feesForOverages);
    }
    if (message.addressNeedToBeSet !== 0) {
      writer.uint32(240).int32(message.addressNeedToBeSet);
    }
    if (message.paymentDesc !== undefined) {
      MSaasSalesAgreementV2PaymentDesc.encode(message.paymentDesc, writer.uint32(250).fork()).ldelim();
    }
    if (message.paymentFreeTextDesc !== "") {
      writer.uint32(258).string(message.paymentFreeTextDesc);
    }
    if (message.enableDataProtection !== 0) {
      writer.uint32(264).int32(message.enableDataProtection);
    }
    if (message.includePolicyLinks !== 0) {
      writer.uint32(272).int32(message.includePolicyLinks);
    }
    for (const v of message.policyLinks) {
      writer.uint32(282).string(v!);
    }
    if (message.venue !== "") {
      writer.uint32(290).string(message.venue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SaasSalesAgreementV2 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaasSalesAgreementV2();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.otherName = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.otherAddress = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.services = reader.string();
          continue;
        case 5:
          if (tag != 45) {
            break;
          }

          message.servicesFee = reader.float();
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.initialServiceTerm = reader.float();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.serviceCapacity.push(reader.string());
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.implementationFee = reader.float();
          continue;
        case 9:
          if (tag != 77) {
            break;
          }

          message.pilotPeriodDays = reader.float();
          continue;
        case 10:
          if (tag != 85) {
            break;
          }

          message.pilotUseFee = reader.float();
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.enableSLA = reader.int32() as any;
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.hasPilotPeriod = reader.int32() as any;
          continue;
        case 13:
          if (tag != 104) {
            break;
          }

          message.areServicesDuringPilotRestrictedToNonProductive = reader.int32() as any;
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.willCustomerOwnDerivativeData = reader.int32() as any;
          continue;
        case 15:
          if (tag != 120) {
            break;
          }

          message.companyHasRightToCollectAndAnalyze = reader.int32() as any;
          continue;
        case 16:
          if (tag != 128) {
            break;
          }

          message.uponTerminationMakeDataAvailable = reader.int32() as any;
          continue;
        case 17:
          if (tag != 136) {
            break;
          }

          message.includeIndemnificationByCompany = reader.int32() as any;
          continue;
        case 18:
          if (tag != 144) {
            break;
          }

          message.willDoJointPressRelease = reader.int32() as any;
          continue;
        case 19:
          if (tag != 152) {
            break;
          }

          message.canUseAsRefence = reader.int32() as any;
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.hasPhoneSupport = reader.int32() as any;
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.statementOfWork.push(reader.string());
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.supportHours = reader.string();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.supportPhone = reader.string();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.supportEmail = reader.string();
          continue;
        case 25:
          if (tag != 205) {
            break;
          }

          message.expectedDaysToRespondTicket = reader.float();
          continue;
        case 26:
          if (tag != 213) {
            break;
          }

          message.creditForDowntime = reader.float();
          continue;
        case 27:
          if (tag != 221) {
            break;
          }

          message.maxCreditPercentage = reader.float();
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.governingLawState = reader.string();
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.feesForOverages = reader.string();
          continue;
        case 30:
          if (tag != 240) {
            break;
          }

          message.addressNeedToBeSet = reader.int32() as any;
          continue;
        case 31:
          if (tag != 250) {
            break;
          }

          message.paymentDesc = MSaasSalesAgreementV2PaymentDesc.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag != 258) {
            break;
          }

          message.paymentFreeTextDesc = reader.string();
          continue;
        case 33:
          if (tag != 264) {
            break;
          }

          message.enableDataProtection = reader.int32() as any;
          continue;
        case 34:
          if (tag != 272) {
            break;
          }

          message.includePolicyLinks = reader.int32() as any;
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.policyLinks.push(reader.string());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.venue = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SaasSalesAgreementV2 {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      otherName: isSet(object.otherName) ? String(object.otherName) : "",
      otherAddress: isSet(object.otherAddress) ? String(object.otherAddress) : "",
      services: isSet(object.services) ? String(object.services) : "",
      servicesFee: isSet(object.servicesFee) ? Number(object.servicesFee) : 0,
      initialServiceTerm: isSet(object.initialServiceTerm) ? Number(object.initialServiceTerm) : 0,
      serviceCapacity: Array.isArray(object?.serviceCapacity) ? object.serviceCapacity.map((e: any) => String(e)) : [],
      implementationFee: isSet(object.implementationFee) ? Number(object.implementationFee) : 0,
      pilotPeriodDays: isSet(object.pilotPeriodDays) ? Number(object.pilotPeriodDays) : 0,
      pilotUseFee: isSet(object.pilotUseFee) ? Number(object.pilotUseFee) : 0,
      enableSLA: isSet(object.enableSLA) ? internalBooleanFromJSON(object.enableSLA) : 0,
      hasPilotPeriod: isSet(object.hasPilotPeriod) ? internalBooleanFromJSON(object.hasPilotPeriod) : 0,
      areServicesDuringPilotRestrictedToNonProductive: isSet(object.areServicesDuringPilotRestrictedToNonProductive)
        ? internalBooleanFromJSON(object.areServicesDuringPilotRestrictedToNonProductive)
        : 0,
      willCustomerOwnDerivativeData: isSet(object.willCustomerOwnDerivativeData)
        ? internalBooleanFromJSON(object.willCustomerOwnDerivativeData)
        : 0,
      companyHasRightToCollectAndAnalyze: isSet(object.companyHasRightToCollectAndAnalyze)
        ? internalBooleanFromJSON(object.companyHasRightToCollectAndAnalyze)
        : 0,
      uponTerminationMakeDataAvailable: isSet(object.uponTerminationMakeDataAvailable)
        ? internalBooleanFromJSON(object.uponTerminationMakeDataAvailable)
        : 0,
      includeIndemnificationByCompany: isSet(object.includeIndemnificationByCompany)
        ? internalBooleanFromJSON(object.includeIndemnificationByCompany)
        : 0,
      willDoJointPressRelease: isSet(object.willDoJointPressRelease)
        ? internalBooleanFromJSON(object.willDoJointPressRelease)
        : 0,
      canUseAsRefence: isSet(object.canUseAsRefence) ? internalBooleanFromJSON(object.canUseAsRefence) : 0,
      hasPhoneSupport: isSet(object.hasPhoneSupport) ? internalBooleanFromJSON(object.hasPhoneSupport) : 0,
      statementOfWork: Array.isArray(object?.statementOfWork)
        ? object.statementOfWork.map((e: any) => String(e))
        : [],
      supportHours: isSet(object.supportHours) ? String(object.supportHours) : "",
      supportPhone: isSet(object.supportPhone) ? String(object.supportPhone) : "",
      supportEmail: isSet(object.supportEmail) ? String(object.supportEmail) : "",
      expectedDaysToRespondTicket: isSet(object.expectedDaysToRespondTicket)
        ? Number(object.expectedDaysToRespondTicket)
        : 0,
      creditForDowntime: isSet(object.creditForDowntime) ? Number(object.creditForDowntime) : 0,
      maxCreditPercentage: isSet(object.maxCreditPercentage) ? Number(object.maxCreditPercentage) : 0,
      governingLawState: isSet(object.governingLawState) ? String(object.governingLawState) : "",
      feesForOverages: isSet(object.feesForOverages) ? String(object.feesForOverages) : "",
      addressNeedToBeSet: isSet(object.addressNeedToBeSet) ? internalBooleanFromJSON(object.addressNeedToBeSet) : 0,
      paymentDesc: isSet(object.paymentDesc)
        ? MSaasSalesAgreementV2PaymentDesc.fromJSON(object.paymentDesc)
        : undefined,
      paymentFreeTextDesc: isSet(object.paymentFreeTextDesc) ? String(object.paymentFreeTextDesc) : "",
      enableDataProtection: isSet(object.enableDataProtection)
        ? internalBooleanFromJSON(object.enableDataProtection)
        : 0,
      includePolicyLinks: isSet(object.includePolicyLinks) ? internalBooleanFromJSON(object.includePolicyLinks) : 0,
      policyLinks: Array.isArray(object?.policyLinks) ? object.policyLinks.map((e: any) => String(e)) : [],
      venue: isSet(object.venue) ? String(object.venue) : "",
    };
  },

  toJSON(message: SaasSalesAgreementV2): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.otherName !== undefined && (obj.otherName = message.otherName);
    message.otherAddress !== undefined && (obj.otherAddress = message.otherAddress);
    message.services !== undefined && (obj.services = message.services);
    message.servicesFee !== undefined && (obj.servicesFee = message.servicesFee);
    message.initialServiceTerm !== undefined && (obj.initialServiceTerm = message.initialServiceTerm);
    if (message.serviceCapacity) {
      obj.serviceCapacity = message.serviceCapacity.map((e) => e);
    } else {
      obj.serviceCapacity = [];
    }
    message.implementationFee !== undefined && (obj.implementationFee = message.implementationFee);
    message.pilotPeriodDays !== undefined && (obj.pilotPeriodDays = message.pilotPeriodDays);
    message.pilotUseFee !== undefined && (obj.pilotUseFee = message.pilotUseFee);
    message.enableSLA !== undefined && (obj.enableSLA = internalBooleanToJSON(message.enableSLA));
    message.hasPilotPeriod !== undefined && (obj.hasPilotPeriod = internalBooleanToJSON(message.hasPilotPeriod));
    message.areServicesDuringPilotRestrictedToNonProductive !== undefined &&
      (obj.areServicesDuringPilotRestrictedToNonProductive = internalBooleanToJSON(
        message.areServicesDuringPilotRestrictedToNonProductive,
      ));
    message.willCustomerOwnDerivativeData !== undefined &&
      (obj.willCustomerOwnDerivativeData = internalBooleanToJSON(message.willCustomerOwnDerivativeData));
    message.companyHasRightToCollectAndAnalyze !== undefined &&
      (obj.companyHasRightToCollectAndAnalyze = internalBooleanToJSON(message.companyHasRightToCollectAndAnalyze));
    message.uponTerminationMakeDataAvailable !== undefined &&
      (obj.uponTerminationMakeDataAvailable = internalBooleanToJSON(message.uponTerminationMakeDataAvailable));
    message.includeIndemnificationByCompany !== undefined &&
      (obj.includeIndemnificationByCompany = internalBooleanToJSON(message.includeIndemnificationByCompany));
    message.willDoJointPressRelease !== undefined &&
      (obj.willDoJointPressRelease = internalBooleanToJSON(message.willDoJointPressRelease));
    message.canUseAsRefence !== undefined && (obj.canUseAsRefence = internalBooleanToJSON(message.canUseAsRefence));
    message.hasPhoneSupport !== undefined && (obj.hasPhoneSupport = internalBooleanToJSON(message.hasPhoneSupport));
    if (message.statementOfWork) {
      obj.statementOfWork = message.statementOfWork.map((e) => e);
    } else {
      obj.statementOfWork = [];
    }
    message.supportHours !== undefined && (obj.supportHours = message.supportHours);
    message.supportPhone !== undefined && (obj.supportPhone = message.supportPhone);
    message.supportEmail !== undefined && (obj.supportEmail = message.supportEmail);
    message.expectedDaysToRespondTicket !== undefined &&
      (obj.expectedDaysToRespondTicket = message.expectedDaysToRespondTicket);
    message.creditForDowntime !== undefined && (obj.creditForDowntime = message.creditForDowntime);
    message.maxCreditPercentage !== undefined && (obj.maxCreditPercentage = message.maxCreditPercentage);
    message.governingLawState !== undefined && (obj.governingLawState = message.governingLawState);
    message.feesForOverages !== undefined && (obj.feesForOverages = message.feesForOverages);
    message.addressNeedToBeSet !== undefined &&
      (obj.addressNeedToBeSet = internalBooleanToJSON(message.addressNeedToBeSet));
    message.paymentDesc !== undefined &&
      (obj.paymentDesc = message.paymentDesc
        ? MSaasSalesAgreementV2PaymentDesc.toJSON(message.paymentDesc)
        : undefined);
    message.paymentFreeTextDesc !== undefined && (obj.paymentFreeTextDesc = message.paymentFreeTextDesc);
    message.enableDataProtection !== undefined &&
      (obj.enableDataProtection = internalBooleanToJSON(message.enableDataProtection));
    message.includePolicyLinks !== undefined &&
      (obj.includePolicyLinks = internalBooleanToJSON(message.includePolicyLinks));
    if (message.policyLinks) {
      obj.policyLinks = message.policyLinks.map((e) => e);
    } else {
      obj.policyLinks = [];
    }
    message.venue !== undefined && (obj.venue = message.venue);
    return obj;
  },

  create<I extends Exact<DeepPartial<SaasSalesAgreementV2>, I>>(base?: I): SaasSalesAgreementV2 {
    return SaasSalesAgreementV2.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SaasSalesAgreementV2>, I>>(object: I): SaasSalesAgreementV2 {
    const message = createBaseSaasSalesAgreementV2();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.otherName = object.otherName ?? "";
    message.otherAddress = object.otherAddress ?? "";
    message.services = object.services ?? "";
    message.servicesFee = object.servicesFee ?? 0;
    message.initialServiceTerm = object.initialServiceTerm ?? 0;
    message.serviceCapacity = object.serviceCapacity?.map((e) => e) || [];
    message.implementationFee = object.implementationFee ?? 0;
    message.pilotPeriodDays = object.pilotPeriodDays ?? 0;
    message.pilotUseFee = object.pilotUseFee ?? 0;
    message.enableSLA = object.enableSLA ?? 0;
    message.hasPilotPeriod = object.hasPilotPeriod ?? 0;
    message.areServicesDuringPilotRestrictedToNonProductive = object.areServicesDuringPilotRestrictedToNonProductive ??
      0;
    message.willCustomerOwnDerivativeData = object.willCustomerOwnDerivativeData ?? 0;
    message.companyHasRightToCollectAndAnalyze = object.companyHasRightToCollectAndAnalyze ?? 0;
    message.uponTerminationMakeDataAvailable = object.uponTerminationMakeDataAvailable ?? 0;
    message.includeIndemnificationByCompany = object.includeIndemnificationByCompany ?? 0;
    message.willDoJointPressRelease = object.willDoJointPressRelease ?? 0;
    message.canUseAsRefence = object.canUseAsRefence ?? 0;
    message.hasPhoneSupport = object.hasPhoneSupport ?? 0;
    message.statementOfWork = object.statementOfWork?.map((e) => e) || [];
    message.supportHours = object.supportHours ?? "";
    message.supportPhone = object.supportPhone ?? "";
    message.supportEmail = object.supportEmail ?? "";
    message.expectedDaysToRespondTicket = object.expectedDaysToRespondTicket ?? 0;
    message.creditForDowntime = object.creditForDowntime ?? 0;
    message.maxCreditPercentage = object.maxCreditPercentage ?? 0;
    message.governingLawState = object.governingLawState ?? "";
    message.feesForOverages = object.feesForOverages ?? "";
    message.addressNeedToBeSet = object.addressNeedToBeSet ?? 0;
    message.paymentDesc = (object.paymentDesc !== undefined && object.paymentDesc !== null)
      ? MSaasSalesAgreementV2PaymentDesc.fromPartial(object.paymentDesc)
      : undefined;
    message.paymentFreeTextDesc = object.paymentFreeTextDesc ?? "";
    message.enableDataProtection = object.enableDataProtection ?? 0;
    message.includePolicyLinks = object.includePolicyLinks ?? 0;
    message.policyLinks = object.policyLinks?.map((e) => e) || [];
    message.venue = object.venue ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
