import SlidingOverlay from "components/SlidingOverlay";
import { isWixFlow } from "components/utils/UtilFunctions";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { MainStore } from "store/mainTypes";
import { getCheckoutUrl, PlanType } from "./PaymentPlans";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PaymentWithSlidingOverlay() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    const query = useQuery();
    const locationPath = useLocation().pathname;
    const isMobileLayout = useCheckMobileScreen();
    const [url, setUrl] = useState("");

    const closeUrl = locationPath.split("/").slice(0, -1).join("/");

    const isWIxUser = dbUser?.isWixUser || isWixFlow();

    const plan = query.get("plan");

    useEffect(() => {
        getCheckoutUrl(plan as PlanType, dbUser, isWIxUser).then(setUrl);
    }, []);

    return (
        <SlidingOverlay
            closeUrl={closeUrl}
            style={isMobileLayout ? { left: "0%", width: "100%" } : { left: "17%", width: "83%" }}
        >
            <iframe width={"100%"} height={"100%"} title="payment" src={url}></iframe>
        </SlidingOverlay>
    );
}
