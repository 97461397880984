import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Spinner } from "components";
import { useAuth } from "components/contexts/AuthContext";
import AdminConsole from "./admin-console/AdminConsole";

const LegalIDEApp = lazy(() => import("./legal-ide"));
const DocCompareApp = lazy(() => import("./doc-compare"));

const Fallback = () => {
    return (
        <div className="flex items-center justify-center w-full">
            <Spinner />
        </div>
    );
};

export const AppsContainer = () => {
    const { path } = useRouteMatch();

    const auth = useAuth();

    // Only clawdia members can access the legal ide app.
    // This is also enforced in the backend (agreements.go).
    // If ever we open it to other set of users, we need to also modify logic in the backend.
    //
    if (!auth?.user?.email?.endsWith("@clawdia.ai")) {
        return <Redirect to="/" />;
    }

    return (
        <Suspense fallback={<Fallback />}>
            <Switch>
                <Route path={`${path}/legal-ide`} component={LegalIDEApp} />
            </Switch>
            <Switch>
                <Route path={`${path}/doc-compare`} component={DocCompareApp} />
            </Switch>
            <Switch>
                <Route path={`${path}/admin-console`} component={AdminConsole} />
            </Switch>
        </Suspense>
    );
};

export { LegalIDEApp };
