import { AdminConsoleApi } from 'api-services/AdminConsoleApi';
import { Button } from 'components';
import { LoadingDots } from 'components/0_common/Loading/LoadingDots';
import { PUser, PUserStatus, pUserStatusToJSON, PUserSubscriptionType } from 'proto/PUser';
import React, { useEffect, useState } from 'react'

export default function AdminConsole() {
  return <div className='h-full w-full overflow-auto'>
    <div className='w-1/2 m-10'>
    <h1 className='text-3xl text-left'>Admin Console</h1>

    <UserForm operation='Retrieve User Data'
        operationFunc={
            async (email: string) => {
                let result = ""

                await AdminConsoleApi.retrieveUser(email, (response) => {
                    result = JSON.stringify(response.data);
                }, (error) => {
                    result = error.message
                })

                return result
            }} />

    <UserForm operation='Get User Type (PayPro, Wix, etc.)'
        operationFunc={
            async (email: string) => {
                let result = ""

                await AdminConsoleApi.retrieveUserType(email, (response) => {
                    result = JSON.stringify(response.data);
                }, (error) => {
                    result = error.message
                })

                return result
            }} />

    <UserForm operation='Cancel Subscription'
        operationFunc={
            async (email: string) => {
                let result = ""

                await AdminConsoleApi.cancelSubscription(email, (response) => {
                    result = JSON.stringify(response.data);
                }, (error) => {
                    result = error.message
                })

                return result
            }} />


        <UserForm operation='Change Email'
            extraInputLabels={["New Email"]}
            operationFunc={
                async (email: string, extraValues) => {
                    let result = ""

                    await AdminConsoleApi.changeEmail(email, extraValues![0], (response) => {
                        result = JSON.stringify(response.data);
                    }, (error) => {
                        result = error.message
                    })

                    return result
                }} />

        <UserForm operation='Change Country (options are: USA, Israel, Global)'
            extraInputLabels={["Country"]}
            operationFunc={
                async (email: string, extraValues) => {
                    let result = ""

                    await AdminConsoleApi.changeCountry(email, extraValues![0], (response) => {
                        result = JSON.stringify(response.data);
                    }, (error) => {
                        result = error.message
                    })

                    return result
                }} />

        <UserForm operation="Unlock User (They won't need to pay)"
            operationFunc={
                async (email: string, extraValues) => {
                    let result = ""

                    await AdminConsoleApi.unlockUser(email, (response) => {
                        result = JSON.stringify(response.data);
                    }, (error) => {
                        result = error.message
                    })

                    return result
                }} />

        <UserForm operation='Lock User (Even if user paid, they will lose their paid access)'
            operationFunc={
                async (email: string, extraValues) => {
                    let result = ""

                    await AdminConsoleApi.lockUser(email, (response) => {
                        result = JSON.stringify(response.data);
                    }, (error) => {
                        result = error.message
                    })

                    return result
                }} />

    <UserForm operation='Delete User (Dangerous, cannot be recovered. Be careful!)'
        operationFunc={
            async (email: string) => {
                let result = ""

                await AdminConsoleApi.deleteEmail(email, (response) => {
                    result = JSON.stringify(response.data);
                }, (error) => {
                    result = error.message
                })

                return result
            }} />

        <Grouping title="PAYPRO ONLY">
            <UserForm operation="Change payment amount (put new amount in dollars with / without $ sign [doesn't matter])"
                extraInputLabels={["New Amount"]}
                operationFunc={
                    async (email: string, extraValues) => {
                        let result = ""

                        const fee = extraValues![0].replace("$", "").trim()

                        await AdminConsoleApi.reducePayproFee(email, fee, (response) => {
                            result = JSON.stringify(response.data);
                        }, (error) => {
                            result = error.message
                        })

                        return result
                    }} />
        </Grouping>

            <Grouping title="WIX ONLY">
                <UserForm label='Wix InstanceId:' type='text' operation='Wix InstanceId to Email'
                    operationFunc={
                        async (instanceId: string) => {
                            let result = ""

                            await AdminConsoleApi.wixInstanceIdToEmail(instanceId, (response) => {
                                result = JSON.stringify(response.data);
                            }, (error) => {
                                result = error.message
                            })

                            return result
                        }} />

                
                <UserForm label='Csv file:' type='file' operation='Wix Renewals'
                    operationFunc={
                        async (instanceId: string) => {
                            let result = ""

                            await AdminConsoleApi.wixRenewalsCsv(instanceId, (response) => {
                                result = JSON.stringify(response.data);
                            }, (error) => {
                                result = error.message
                            })

                            return result
                        }} />
            </Grouping>

            <Grouping title="APPSUMO ONLY">
                <UserForm operation="Mark code as refunded"
                    label='AppSumo Code'
                    type='text'
                    operationFunc={
                        async (code: string) => {
                            let result = ""

                            await AdminConsoleApi.markAppSumoCodeRefunded(code, (response) => {
                                result = JSON.stringify(response.data);
                            }, (error) => {
                                result = error.message
                            })

                            return result
                        }} />
        </Grouping>
        </div>
    </div>
}

function Grouping({title, children} : {title: string, children: React.ReactNode}) {
    return <fieldset className='flex flex-col gap-4 border-2 border-primary px-12 pb-4 mt-4 rounded-md'>
    <legend className='text-2xl font-semibold'>{title}</legend>
        {children}
    </fieldset>
}


function UserForm({operation, operationFunc, type, label, extraInputLabels}: {operation: string, operationFunc: (email: string, extraValues?: string[]) => any, type ?: string, label?: string, extraInputLabels?: string[]}) {
    const [val, setVal] = useState<string>("");
    const [response, setResponse] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [extraInputs, setExtraInputs] = useState<string[]>([]);

    const handleResponse = (response: string) => {
        if (typeof response === 'string') {
            setResponse(convert(response));
            setError("");
        } else {
            setError("There was an error! " + JSON.stringify(response, null, 4));
            setResponse("");
        }

        setLoading(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError("")
        setResponse("")
        setLoading(true)

        let response = ""

        if (type === 'file') {
            const file = (e.target as any).elements[0].files[0]
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onload = async () => {
                response = await operationFunc(reader.result as string, extraInputs)
                handleResponse(response)
            }
        } else {
            response = await operationFunc(val, extraInputs)
            handleResponse(response)
        }
    }
    
    return <div className="flex flex-col p-4 border-2 border-primary rounded-lg mt-8 gap-4">
            <h2 className='text-xl font-medium'>{operation}</h2>
            <form className='gap-4 flex flex-col' onSubmit={handleSubmit}>
                <>
                <div className='flex gap-4 items-center'>
                    <label className='font-medium text-lg flex-shrink-0'>{label ?? "Email:"}</label>
                    <input 
                        className='border-2 p-2 rounded-md text-lg w-full'
                        type={type ?? "email"} 
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                        required
                    />
                </div>

                {extraInputLabels?.map((label, i) => {
                    return <div className='flex gap-4 items-center'>
                        <label className='font-medium text-lg flex-shrink-0'>{label}</label>
                        <input
                            className='border-2 p-2 rounded-md text-lg w-full'
                            type='text'
                            value={extraInputs[i] ?? ""}
                            onChange={(e) => {
                                let newInputs = {...extraInputs};
                                newInputs[i] = e.target.value;
                                setExtraInputs(newInputs);
                            }}
                            required
                        />
                    </div>
                    })}
                <Button type="submit">OK</Button>
                </> 
            </form>
            {loading && <LoadingDots/>}
            {(response.length > 0 || error.length > 0) &&
                <>
                    <hr className='border-2 border-primary' />
                    <p className='text-xl'>Response:</p>
                    <div className={`border-2 border-primary rounded-lg p-4 text-lg ${error.length > 0 ? "text-red": "text-accent"}`}>
                        <textarea className='w-full h-64 flex-shrink-0 resize-none' value={response ?? error} readOnly />
                    </div>
                </>
            }
    </div>
}

function convert(str: string) {
    try {
        let user: PUser = JSON.parse(str);

        if (typeof (user) === 'string') {
            const errorMsg = user;
            return errorMsg;
        }

        return JSON.stringify(user, null, 4);
    } catch (e) {
        return str;
    }
}