import { Button, Container, SvgIconPencilEdit } from "components";
import ABTesting, { AB_TEST_NAMES } from "components/utils/ABTesting";
import { HeadlessSaveButtons } from "components/wizard-display/agreements/DocPreview";
import { TemplatesDropdown } from "features/Templates/TemplatesDropdown";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen";
import LearnIcon from "img/icons/learn.png";
import { PDoc, PDocStatus } from "proto/PDoc";
import { PLegalUnit } from "proto/PLegalUnit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { playVideo, renameLegalUnit } from "store/mainActions";
import { VideosCollection } from "../QuestionWizard/VideosCollection";
import { useAgreementContext } from "./AgreementContext/AgreementContext";
import { AutoContractDocument } from "./auto-contracts/autoContractsUtils";
import { callToSaveDraft } from "./WizardForm";
import { usePlanState } from "hooks/usePlanState";

// todo: remove any type here
export function WizardHeaderNew(props: any) {
    const { docTitle, contract } = props;
    const activeDraft = useAgreementContext()?.getActiveDraft();
    const dispatch = useDispatch();
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    const he = currentAgreement?.lang === "HE";

    const showTemplateDropdown = false;

    return (
        <Container
            lang={!he ? "en" : "he"}
            dir={!he ? "ltr" : "rtl"}
            className="flex justify-between items-center h-full gap-6"
        >
            <div className="flex flex-col justify-around flex-grow">
                <p className="font-bold w-full text-sm">{docTitle || ""}</p>
                <div className="flex-grow max-w-xl">
                    <PencilEditBox contract={contract} />
                </div>
            </div>

            <div className="flex items-center gap-4 flex-shrink">
                {activeDraft?.docStatus === PDocStatus.DRAFT && !props.paymentDocPreviewMode ? (
                    <>
                        {currentAgreement?.introVideo && (
                            <ABTesting
                                componentA={
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                playVideo(
                                                    VideosCollection[
                                                        currentAgreement.introVideo
                                                            ?.urlRef as keyof typeof VideosCollection
                                                    ]
                                                )
                                            )
                                        }
                                        variant="outlined"
                                    >
                                        Learn
                                    </Button>
                                }
                                componentB={
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                playVideo(
                                                    VideosCollection[
                                                        currentAgreement.introVideo
                                                            ?.urlRef as keyof typeof VideosCollection
                                                    ]
                                                )
                                            )
                                        }
                                        startIcon="video_camera"
                                    >
                                        {!he ? "2 Min Video" : "סרטונים לימודיים"}
                                    </Button>
                                }
                                isSticky={true}
                                firstVariantProbability={0}
                                testName={AB_TEST_NAMES.TEST_2__LEARN_BUTTON}
                            />
                        )}
                    </>
                ) : (
                    <HeadlessSaveButtons />
                )}
                {showTemplateDropdown && <TemplatesDropdown />}
            </div>
        </Container>
    );
}

function PencilEditBox(props: { contract: AutoContractDocument }) {
    const location = useLocation();
    const isMobile = useCheckMobileScreen();

    const agreementContext = useAgreementContext();
    const activeDraft: PDoc | undefined = agreementContext?.getActiveDraft();
    const dispatch = useDispatch();
    const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
    const [name, setName] = useState(activeDraft?.legalUnit?.name || "");
    const [tempName, setTempName] = useState(props.contract.namer(activeDraft?.legalUnit));
    const { isDocumentLocked } = usePlanState();

    const he = useAppSelector((state) => state.agreementManager.currentAgreement?.lang === "HE");

    const commonStyle = "text-2xl font-medium overflow-ellipsis font-mono";

    const onEnterListener = (e: KeyboardEvent) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            e.preventDefault();
            e.stopPropagation();
            inputEl?.blur();
        }
    };

    useEffect(() => {
        setTempName(props.contract.namer(activeDraft?.legalUnit));
    }, [activeDraft]);

    function nameDraft() {
        let finalName = inputEl?.value || "";

        if (activeDraft?.legalUnit) {
            dispatch(renameLegalUnit(finalName));

            let newLegalUnit: PLegalUnit = {
                ...activeDraft?.legalUnit,
                name: finalName,
            };
            callToSaveDraft(location, newLegalUnit);
        }
    }

    function calcWidth() {
        let finalName = inputEl?.value || tempName;

        if (finalName.length <= 8) {
            return "8ch";
        } else if (isMobile) {
            return "12ch";
        } else if (finalName.length >= 50) {
            return "50ch";
        } else {
            return "" + finalName.length + "ch";
        }
    }

    if (activeDraft?.docStatus !== PDocStatus.DRAFT) {
        return <p className={commonStyle}>{props.contract.namer(activeDraft?.legalUnit)}</p>;
    }

    return (
        <span className="flex mt-2 items-cente w-full  gap-2 text-primaryLight">
            <label htmlFor="pencil-edit" className="cursor-pointer flex items-center">
                <SvgIconPencilEdit />
            </label>
            <div className="inline-flex flex-col flex-grow">
                <input
                    onBlur={() => {
                        setTempName(props.contract.namer(activeDraft?.legalUnit));
                        nameDraft();
                        inputEl?.removeEventListener("keydown", onEnterListener);
                    }}
                    ref={(ref) => setInputEl(ref)}
                    onFocus={() => {
                        inputEl?.addEventListener("keydown", onEnterListener);
                    }}
                    onChange={(e) => {
                        if (e.target.value) {
                            setName(e.target.value);
                        } else {
                            setTempName("");
                            setName("");
                        }
                    }}
                    className={`_EditBox border-0 outline-none w-full ${commonStyle}`}
                    // style={{ width: calcWidth() }}
                    placeholder={he ? "ללא שם" : "Untitled"}
                    value={name || tempName}
                    disabled={isDocumentLocked(activeDraft?.id!)}
                    id="pencil-edit"
                />
                <span className="_EditBoxBorder" />
            </div>
        </span>
    );
}
