/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EConsultingMultiCompanyEntityType {
  ConsultingMulti_companyEntityType_NA = 0,
  ConsultingMulti_companyEntityType_CORPORATION = 1,
  ConsultingMulti_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  ConsultingMulti_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  ConsultingMulti_companyEntityType_LIMITED_PARTNERSHIP = 4,
  ConsultingMulti_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  ConsultingMulti_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  ConsultingMulti_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  ConsultingMulti_companyEntityType_NONPROFIT_CORPORATION = 8,
  ConsultingMulti_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  ConsultingMulti_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiCompanyEntityTypeFromJSON(object: any): EConsultingMultiCompanyEntityType {
  switch (object) {
    case 0:
    case "ConsultingMulti_companyEntityType_NA":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_NA;
    case 1:
    case "ConsultingMulti_companyEntityType_CORPORATION":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_CORPORATION;
    case 2:
    case "ConsultingMulti_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "ConsultingMulti_companyEntityType_SOLE_PROPRIETORSHIP":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "ConsultingMulti_companyEntityType_LIMITED_PARTNERSHIP":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "ConsultingMulti_companyEntityType_PROFESSIONAL_CORPORATION":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "ConsultingMulti_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "ConsultingMulti_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "ConsultingMulti_companyEntityType_NONPROFIT_CORPORATION":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "ConsultingMulti_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "ConsultingMulti_companyEntityType_PARTNERSHIP":
      return EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiCompanyEntityType.UNRECOGNIZED;
  }
}

export function eConsultingMultiCompanyEntityTypeToJSON(object: EConsultingMultiCompanyEntityType): string {
  switch (object) {
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_NA:
      return "ConsultingMulti_companyEntityType_NA";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_CORPORATION:
      return "ConsultingMulti_companyEntityType_CORPORATION";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "ConsultingMulti_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_SOLE_PROPRIETORSHIP:
      return "ConsultingMulti_companyEntityType_SOLE_PROPRIETORSHIP";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_LIMITED_PARTNERSHIP:
      return "ConsultingMulti_companyEntityType_LIMITED_PARTNERSHIP";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_CORPORATION:
      return "ConsultingMulti_companyEntityType_PROFESSIONAL_CORPORATION";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "ConsultingMulti_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "ConsultingMulti_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_NONPROFIT_CORPORATION:
      return "ConsultingMulti_companyEntityType_NONPROFIT_CORPORATION";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "ConsultingMulti_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EConsultingMultiCompanyEntityType.ConsultingMulti_companyEntityType_PARTNERSHIP:
      return "ConsultingMulti_companyEntityType_PARTNERSHIP";
    case EConsultingMultiCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiOtherEntityType {
  ConsultingMulti_otherEntityType_NA = 0,
  ConsultingMulti_otherEntityType_CORPORATION = 1,
  ConsultingMulti_otherEntityType_LIMITED_LIABILITY_COMPANY = 2,
  ConsultingMulti_otherEntityType_SOLE_PROPRIETORSHIP = 3,
  ConsultingMulti_otherEntityType_LIMITED_PARTNERSHIP = 4,
  ConsultingMulti_otherEntityType_PROFESSIONAL_CORPORATION = 5,
  ConsultingMulti_otherEntityType_PROFESSIONAL_ASSOCIATION = 6,
  ConsultingMulti_otherEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  ConsultingMulti_otherEntityType_NONPROFIT_CORPORATION = 8,
  ConsultingMulti_otherEntityType_UNINCORPORATED_ASSOCIATION = 9,
  ConsultingMulti_otherEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiOtherEntityTypeFromJSON(object: any): EConsultingMultiOtherEntityType {
  switch (object) {
    case 0:
    case "ConsultingMulti_otherEntityType_NA":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_NA;
    case 1:
    case "ConsultingMulti_otherEntityType_CORPORATION":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_CORPORATION;
    case 2:
    case "ConsultingMulti_otherEntityType_LIMITED_LIABILITY_COMPANY":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "ConsultingMulti_otherEntityType_SOLE_PROPRIETORSHIP":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "ConsultingMulti_otherEntityType_LIMITED_PARTNERSHIP":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "ConsultingMulti_otherEntityType_PROFESSIONAL_CORPORATION":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "ConsultingMulti_otherEntityType_PROFESSIONAL_ASSOCIATION":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "ConsultingMulti_otherEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "ConsultingMulti_otherEntityType_NONPROFIT_CORPORATION":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "ConsultingMulti_otherEntityType_UNINCORPORATED_ASSOCIATION":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "ConsultingMulti_otherEntityType_PARTNERSHIP":
      return EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiOtherEntityType.UNRECOGNIZED;
  }
}

export function eConsultingMultiOtherEntityTypeToJSON(object: EConsultingMultiOtherEntityType): string {
  switch (object) {
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_NA:
      return "ConsultingMulti_otherEntityType_NA";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_CORPORATION:
      return "ConsultingMulti_otherEntityType_CORPORATION";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_LIMITED_LIABILITY_COMPANY:
      return "ConsultingMulti_otherEntityType_LIMITED_LIABILITY_COMPANY";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_SOLE_PROPRIETORSHIP:
      return "ConsultingMulti_otherEntityType_SOLE_PROPRIETORSHIP";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_LIMITED_PARTNERSHIP:
      return "ConsultingMulti_otherEntityType_LIMITED_PARTNERSHIP";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_CORPORATION:
      return "ConsultingMulti_otherEntityType_PROFESSIONAL_CORPORATION";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_ASSOCIATION:
      return "ConsultingMulti_otherEntityType_PROFESSIONAL_ASSOCIATION";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "ConsultingMulti_otherEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_NONPROFIT_CORPORATION:
      return "ConsultingMulti_otherEntityType_NONPROFIT_CORPORATION";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_UNINCORPORATED_ASSOCIATION:
      return "ConsultingMulti_otherEntityType_UNINCORPORATED_ASSOCIATION";
    case EConsultingMultiOtherEntityType.ConsultingMulti_otherEntityType_PARTNERSHIP:
      return "ConsultingMulti_otherEntityType_PARTNERSHIP";
    case EConsultingMultiOtherEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiNonCompete {
  ConsultingMulti_nonCompete_NA = 0,
  ConsultingMulti_nonCompete_NONE = 1,
  ConsultingMulti_nonCompete_NOTICE = 2,
  ConsultingMulti_nonCompete_TERM = 3,
  ConsultingMulti_nonCompete_TAIL = 4,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiNonCompeteFromJSON(object: any): EConsultingMultiNonCompete {
  switch (object) {
    case 0:
    case "ConsultingMulti_nonCompete_NA":
      return EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NA;
    case 1:
    case "ConsultingMulti_nonCompete_NONE":
      return EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NONE;
    case 2:
    case "ConsultingMulti_nonCompete_NOTICE":
      return EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NOTICE;
    case 3:
    case "ConsultingMulti_nonCompete_TERM":
      return EConsultingMultiNonCompete.ConsultingMulti_nonCompete_TERM;
    case 4:
    case "ConsultingMulti_nonCompete_TAIL":
      return EConsultingMultiNonCompete.ConsultingMulti_nonCompete_TAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiNonCompete.UNRECOGNIZED;
  }
}

export function eConsultingMultiNonCompeteToJSON(object: EConsultingMultiNonCompete): string {
  switch (object) {
    case EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NA:
      return "ConsultingMulti_nonCompete_NA";
    case EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NONE:
      return "ConsultingMulti_nonCompete_NONE";
    case EConsultingMultiNonCompete.ConsultingMulti_nonCompete_NOTICE:
      return "ConsultingMulti_nonCompete_NOTICE";
    case EConsultingMultiNonCompete.ConsultingMulti_nonCompete_TERM:
      return "ConsultingMulti_nonCompete_TERM";
    case EConsultingMultiNonCompete.ConsultingMulti_nonCompete_TAIL:
      return "ConsultingMulti_nonCompete_TAIL";
    case EConsultingMultiNonCompete.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiNonSolicit {
  ConsultingMulti_nonSolicit_NA = 0,
  ConsultingMulti_nonSolicit_NONE = 1,
  ConsultingMulti_nonSolicit_TERM = 2,
  ConsultingMulti_nonSolicit_TAIL = 3,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiNonSolicitFromJSON(object: any): EConsultingMultiNonSolicit {
  switch (object) {
    case 0:
    case "ConsultingMulti_nonSolicit_NA":
      return EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_NA;
    case 1:
    case "ConsultingMulti_nonSolicit_NONE":
      return EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_NONE;
    case 2:
    case "ConsultingMulti_nonSolicit_TERM":
      return EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_TERM;
    case 3:
    case "ConsultingMulti_nonSolicit_TAIL":
      return EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_TAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiNonSolicit.UNRECOGNIZED;
  }
}

export function eConsultingMultiNonSolicitToJSON(object: EConsultingMultiNonSolicit): string {
  switch (object) {
    case EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_NA:
      return "ConsultingMulti_nonSolicit_NA";
    case EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_NONE:
      return "ConsultingMulti_nonSolicit_NONE";
    case EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_TERM:
      return "ConsultingMulti_nonSolicit_TERM";
    case EConsultingMultiNonSolicit.ConsultingMulti_nonSolicit_TAIL:
      return "ConsultingMulti_nonSolicit_TAIL";
    case EConsultingMultiNonSolicit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiAntiRaiding {
  ConsultingMulti_antiRaiding_NA = 0,
  ConsultingMulti_antiRaiding_NONE = 1,
  ConsultingMulti_antiRaiding_TERM = 2,
  ConsultingMulti_antiRaiding_TAIL = 3,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiAntiRaidingFromJSON(object: any): EConsultingMultiAntiRaiding {
  switch (object) {
    case 0:
    case "ConsultingMulti_antiRaiding_NA":
      return EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_NA;
    case 1:
    case "ConsultingMulti_antiRaiding_NONE":
      return EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_NONE;
    case 2:
    case "ConsultingMulti_antiRaiding_TERM":
      return EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_TERM;
    case 3:
    case "ConsultingMulti_antiRaiding_TAIL":
      return EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_TAIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiAntiRaiding.UNRECOGNIZED;
  }
}

export function eConsultingMultiAntiRaidingToJSON(object: EConsultingMultiAntiRaiding): string {
  switch (object) {
    case EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_NA:
      return "ConsultingMulti_antiRaiding_NA";
    case EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_NONE:
      return "ConsultingMulti_antiRaiding_NONE";
    case EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_TERM:
      return "ConsultingMulti_antiRaiding_TERM";
    case EConsultingMultiAntiRaiding.ConsultingMulti_antiRaiding_TAIL:
      return "ConsultingMulti_antiRaiding_TAIL";
    case EConsultingMultiAntiRaiding.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiInvoiceFreq {
  ConsultingMulti_invoiceFreq_NA = 0,
  ConsultingMulti_invoiceFreq_MADVANCE = 1,
  ConsultingMulti_invoiceFreq_MARREARS = 2,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiInvoiceFreqFromJSON(object: any): EConsultingMultiInvoiceFreq {
  switch (object) {
    case 0:
    case "ConsultingMulti_invoiceFreq_NA":
      return EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_NA;
    case 1:
    case "ConsultingMulti_invoiceFreq_MADVANCE":
      return EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_MADVANCE;
    case 2:
    case "ConsultingMulti_invoiceFreq_MARREARS":
      return EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_MARREARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiInvoiceFreq.UNRECOGNIZED;
  }
}

export function eConsultingMultiInvoiceFreqToJSON(object: EConsultingMultiInvoiceFreq): string {
  switch (object) {
    case EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_NA:
      return "ConsultingMulti_invoiceFreq_NA";
    case EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_MADVANCE:
      return "ConsultingMulti_invoiceFreq_MADVANCE";
    case EConsultingMultiInvoiceFreq.ConsultingMulti_invoiceFreq_MARREARS:
      return "ConsultingMulti_invoiceFreq_MARREARS";
    case EConsultingMultiInvoiceFreq.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiRemainderType {
  ConsultingMulti_remainderType_NA = 0,
  ConsultingMulti_remainderType_FULL_ADVANCE = 1,
  ConsultingMulti_remainderType_FULL_END = 2,
  ConsultingMulti_remainderType_INITIAL = 3,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiRemainderTypeFromJSON(object: any): EConsultingMultiRemainderType {
  switch (object) {
    case 0:
    case "ConsultingMulti_remainderType_NA":
      return EConsultingMultiRemainderType.ConsultingMulti_remainderType_NA;
    case 1:
    case "ConsultingMulti_remainderType_FULL_ADVANCE":
      return EConsultingMultiRemainderType.ConsultingMulti_remainderType_FULL_ADVANCE;
    case 2:
    case "ConsultingMulti_remainderType_FULL_END":
      return EConsultingMultiRemainderType.ConsultingMulti_remainderType_FULL_END;
    case 3:
    case "ConsultingMulti_remainderType_INITIAL":
      return EConsultingMultiRemainderType.ConsultingMulti_remainderType_INITIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiRemainderType.UNRECOGNIZED;
  }
}

export function eConsultingMultiRemainderTypeToJSON(object: EConsultingMultiRemainderType): string {
  switch (object) {
    case EConsultingMultiRemainderType.ConsultingMulti_remainderType_NA:
      return "ConsultingMulti_remainderType_NA";
    case EConsultingMultiRemainderType.ConsultingMulti_remainderType_FULL_ADVANCE:
      return "ConsultingMulti_remainderType_FULL_ADVANCE";
    case EConsultingMultiRemainderType.ConsultingMulti_remainderType_FULL_END:
      return "ConsultingMulti_remainderType_FULL_END";
    case EConsultingMultiRemainderType.ConsultingMulti_remainderType_INITIAL:
      return "ConsultingMulti_remainderType_INITIAL";
    case EConsultingMultiRemainderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiEarlyTermFee {
  ConsultingMulti_earlyTermFee_NA = 0,
  ConsultingMulti_earlyTermFee_COMPLETED = 1,
  ConsultingMulti_earlyTermFee_VALUE = 2,
  ConsultingMulti_earlyTermFee_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiEarlyTermFeeFromJSON(object: any): EConsultingMultiEarlyTermFee {
  switch (object) {
    case 0:
    case "ConsultingMulti_earlyTermFee_NA":
      return EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_NA;
    case 1:
    case "ConsultingMulti_earlyTermFee_COMPLETED":
      return EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_COMPLETED;
    case 2:
    case "ConsultingMulti_earlyTermFee_VALUE":
      return EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_VALUE;
    case 999999:
    case "ConsultingMulti_earlyTermFee_OTHER":
      return EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiEarlyTermFee.UNRECOGNIZED;
  }
}

export function eConsultingMultiEarlyTermFeeToJSON(object: EConsultingMultiEarlyTermFee): string {
  switch (object) {
    case EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_NA:
      return "ConsultingMulti_earlyTermFee_NA";
    case EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_COMPLETED:
      return "ConsultingMulti_earlyTermFee_COMPLETED";
    case EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_VALUE:
      return "ConsultingMulti_earlyTermFee_VALUE";
    case EConsultingMultiEarlyTermFee.ConsultingMulti_earlyTermFee_OTHER:
      return "ConsultingMulti_earlyTermFee_OTHER";
    case EConsultingMultiEarlyTermFee.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiFeeBasis {
  ConsultingMulti_feeBasis_NA = 0,
  ConsultingMulti_feeBasis_FIXED = 1,
  ConsultingMulti_feeBasis_HOURLY = 2,
  ConsultingMulti_feeBasis_DAILY = 3,
  ConsultingMulti_feeBasis_WEEKLY = 4,
  ConsultingMulti_feeBasis_MONTHLY = 5,
  ConsultingMulti_feeBasis_CUSTOM = 6,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiFeeBasisFromJSON(object: any): EConsultingMultiFeeBasis {
  switch (object) {
    case 0:
    case "ConsultingMulti_feeBasis_NA":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_NA;
    case 1:
    case "ConsultingMulti_feeBasis_FIXED":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_FIXED;
    case 2:
    case "ConsultingMulti_feeBasis_HOURLY":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_HOURLY;
    case 3:
    case "ConsultingMulti_feeBasis_DAILY":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_DAILY;
    case 4:
    case "ConsultingMulti_feeBasis_WEEKLY":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_WEEKLY;
    case 5:
    case "ConsultingMulti_feeBasis_MONTHLY":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_MONTHLY;
    case 6:
    case "ConsultingMulti_feeBasis_CUSTOM":
      return EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiFeeBasis.UNRECOGNIZED;
  }
}

export function eConsultingMultiFeeBasisToJSON(object: EConsultingMultiFeeBasis): string {
  switch (object) {
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_NA:
      return "ConsultingMulti_feeBasis_NA";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_FIXED:
      return "ConsultingMulti_feeBasis_FIXED";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_HOURLY:
      return "ConsultingMulti_feeBasis_HOURLY";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_DAILY:
      return "ConsultingMulti_feeBasis_DAILY";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_WEEKLY:
      return "ConsultingMulti_feeBasis_WEEKLY";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_MONTHLY:
      return "ConsultingMulti_feeBasis_MONTHLY";
    case EConsultingMultiFeeBasis.ConsultingMulti_feeBasis_CUSTOM:
      return "ConsultingMulti_feeBasis_CUSTOM";
    case EConsultingMultiFeeBasis.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiServicesType {
  ConsultingMulti_servicesType_NA = 0,
  ConsultingMulti_servicesType_WEB_DESIGN = 1,
  ConsultingMulti_servicesType_WEB_DEV = 2,
  ConsultingMulti_servicesType_UIUX_DESIGN = 3,
  ConsultingMulti_servicesType_VIDEO = 4,
  ConsultingMulti_servicesType_MARKETING = 5,
  ConsultingMulti_servicesType_SEO = 6,
  ConsultingMulti_servicesType_SOCIAL_MARKETING = 7,
  ConsultingMulti_servicesType_SOCIAL_MANAGEMENT = 8,
  ConsultingMulti_servicesType_PPC = 9,
  ConsultingMulti_servicesType_BRAND_DEV = 10,
  ConsultingMulti_servicesType_GENERAL = 11,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiServicesTypeFromJSON(object: any): EConsultingMultiServicesType {
  switch (object) {
    case 0:
    case "ConsultingMulti_servicesType_NA":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_NA;
    case 1:
    case "ConsultingMulti_servicesType_WEB_DESIGN":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_WEB_DESIGN;
    case 2:
    case "ConsultingMulti_servicesType_WEB_DEV":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_WEB_DEV;
    case 3:
    case "ConsultingMulti_servicesType_UIUX_DESIGN":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_UIUX_DESIGN;
    case 4:
    case "ConsultingMulti_servicesType_VIDEO":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_VIDEO;
    case 5:
    case "ConsultingMulti_servicesType_MARKETING":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_MARKETING;
    case 6:
    case "ConsultingMulti_servicesType_SEO":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_SEO;
    case 7:
    case "ConsultingMulti_servicesType_SOCIAL_MARKETING":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_SOCIAL_MARKETING;
    case 8:
    case "ConsultingMulti_servicesType_SOCIAL_MANAGEMENT":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_SOCIAL_MANAGEMENT;
    case 9:
    case "ConsultingMulti_servicesType_PPC":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_PPC;
    case 10:
    case "ConsultingMulti_servicesType_BRAND_DEV":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_BRAND_DEV;
    case 11:
    case "ConsultingMulti_servicesType_GENERAL":
      return EConsultingMultiServicesType.ConsultingMulti_servicesType_GENERAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiServicesType.UNRECOGNIZED;
  }
}

export function eConsultingMultiServicesTypeToJSON(object: EConsultingMultiServicesType): string {
  switch (object) {
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_NA:
      return "ConsultingMulti_servicesType_NA";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_WEB_DESIGN:
      return "ConsultingMulti_servicesType_WEB_DESIGN";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_WEB_DEV:
      return "ConsultingMulti_servicesType_WEB_DEV";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_UIUX_DESIGN:
      return "ConsultingMulti_servicesType_UIUX_DESIGN";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_VIDEO:
      return "ConsultingMulti_servicesType_VIDEO";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_MARKETING:
      return "ConsultingMulti_servicesType_MARKETING";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_SEO:
      return "ConsultingMulti_servicesType_SEO";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_SOCIAL_MARKETING:
      return "ConsultingMulti_servicesType_SOCIAL_MARKETING";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_SOCIAL_MANAGEMENT:
      return "ConsultingMulti_servicesType_SOCIAL_MANAGEMENT";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_PPC:
      return "ConsultingMulti_servicesType_PPC";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_BRAND_DEV:
      return "ConsultingMulti_servicesType_BRAND_DEV";
    case EConsultingMultiServicesType.ConsultingMulti_servicesType_GENERAL:
      return "ConsultingMulti_servicesType_GENERAL";
    case EConsultingMultiServicesType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EConsultingMultiRole {
  ConsultingMulti_role_NA = 0,
  ConsultingMulti_role_PROVIDER = 1,
  ConsultingMulti_role_RECIPIENT = 2,
  UNRECOGNIZED = -1,
}

export function eConsultingMultiRoleFromJSON(object: any): EConsultingMultiRole {
  switch (object) {
    case 0:
    case "ConsultingMulti_role_NA":
      return EConsultingMultiRole.ConsultingMulti_role_NA;
    case 1:
    case "ConsultingMulti_role_PROVIDER":
      return EConsultingMultiRole.ConsultingMulti_role_PROVIDER;
    case 2:
    case "ConsultingMulti_role_RECIPIENT":
      return EConsultingMultiRole.ConsultingMulti_role_RECIPIENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EConsultingMultiRole.UNRECOGNIZED;
  }
}

export function eConsultingMultiRoleToJSON(object: EConsultingMultiRole): string {
  switch (object) {
    case EConsultingMultiRole.ConsultingMulti_role_NA:
      return "ConsultingMulti_role_NA";
    case EConsultingMultiRole.ConsultingMulti_role_PROVIDER:
      return "ConsultingMulti_role_PROVIDER";
    case EConsultingMultiRole.ConsultingMulti_role_RECIPIENT:
      return "ConsultingMulti_role_RECIPIENT";
    case EConsultingMultiRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MConsultingMultiCompanyEntityType {
  optionDbValue: EConsultingMultiCompanyEntityType;
  other: string;
}

export interface MConsultingMultiOtherEntityType {
  optionDbValue: EConsultingMultiOtherEntityType;
  other: string;
}

export interface MConsultingMultiNonCompete {
  optionDbValue: EConsultingMultiNonCompete;
  other: string;
}

export interface MConsultingMultiNonSolicit {
  optionDbValue: EConsultingMultiNonSolicit;
  other: string;
}

export interface MConsultingMultiAntiRaiding {
  optionDbValue: EConsultingMultiAntiRaiding;
  other: string;
}

export interface MConsultingMultiInvoiceFreq {
  optionDbValue: EConsultingMultiInvoiceFreq;
  other: string;
}

export interface MConsultingMultiRemainderType {
  optionDbValue: EConsultingMultiRemainderType;
  other: string;
}

export interface MConsultingMultiEarlyTermFee {
  optionDbValue: EConsultingMultiEarlyTermFee;
  other: string;
}

export interface MConsultingMultiFeeBasis {
  optionDbValue: EConsultingMultiFeeBasis;
  other: string;
}

export interface MConsultingMultiServicesType {
  optionDbValue: EConsultingMultiServicesType;
  other: string;
}

export interface MConsultingMultiRole {
  optionDbValue: EConsultingMultiRole;
  other: string;
}

export interface ConsultingMulti {
  effectiveDate: Date | undefined;
  companyFull: string;
  companyState: string;
  companyEntityType: MConsultingMultiCompanyEntityType | undefined;
  otherFullName: string;
  otherState: string;
  otherEntityType: MConsultingMultiOtherEntityType | undefined;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyEmail: string;
  companyNoticeCopyPerson: string;
  consultantNoticeEmail: string;
  consultantNoticePerson: string;
  consultantNoticeCopyEmail: string;
  consultantNoticeCopyPerson: string;
  governLawState: string;
  venue: string;
  arbShort: InternalBoolean;
  companyNoticeCopy: InternalBoolean;
  consultantNoticeCopy: InternalBoolean;
  consultantTermNotice: number;
  companyTermNotice: number;
  causeTermNotice: number;
  nonCompete: MConsultingMultiNonCompete | undefined;
  nonCompeteTailMonths: number;
  nonSolicit: MConsultingMultiNonSolicit | undefined;
  nonSolicitTailMonths: number;
  antiRaiding: MConsultingMultiAntiRaiding | undefined;
  antiRaidingTailMonths: number;
  consultantServices: string[];
  fixedFee: number;
  feeAmount: number;
  timeLimitMax: number;
  feeCustom: string;
  invoiceFreq: MConsultingMultiInvoiceFreq | undefined;
  remainderType: MConsultingMultiRemainderType | undefined;
  kickoffPayment: number;
  paymentDays: number;
  earlyTermFee: MConsultingMultiEarlyTermFee | undefined;
  feeBasis: MConsultingMultiFeeBasis | undefined;
  timeLimit: InternalBoolean;
  limitFixedFeeList: string[];
  limitFixedFee: InternalBoolean;
  servicesType: MConsultingMultiServicesType | undefined;
  role: MConsultingMultiRole | undefined;
}

function createBaseMConsultingMultiCompanyEntityType(): MConsultingMultiCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiCompanyEntityType = {
  encode(message: MConsultingMultiCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiCompanyEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiCompanyEntityType>, I>>(
    base?: I,
  ): MConsultingMultiCompanyEntityType {
    return MConsultingMultiCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiCompanyEntityType>, I>>(
    object: I,
  ): MConsultingMultiCompanyEntityType {
    const message = createBaseMConsultingMultiCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiOtherEntityType(): MConsultingMultiOtherEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiOtherEntityType = {
  encode(message: MConsultingMultiOtherEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiOtherEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiOtherEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiOtherEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiOtherEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiOtherEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiOtherEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiOtherEntityType>, I>>(base?: I): MConsultingMultiOtherEntityType {
    return MConsultingMultiOtherEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiOtherEntityType>, I>>(
    object: I,
  ): MConsultingMultiOtherEntityType {
    const message = createBaseMConsultingMultiOtherEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiNonCompete(): MConsultingMultiNonCompete {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiNonCompete = {
  encode(message: MConsultingMultiNonCompete, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiNonCompete {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiNonCompete();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiNonCompete {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiNonCompeteFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiNonCompete): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiNonCompeteToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiNonCompete>, I>>(base?: I): MConsultingMultiNonCompete {
    return MConsultingMultiNonCompete.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiNonCompete>, I>>(object: I): MConsultingMultiNonCompete {
    const message = createBaseMConsultingMultiNonCompete();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiNonSolicit(): MConsultingMultiNonSolicit {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiNonSolicit = {
  encode(message: MConsultingMultiNonSolicit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiNonSolicit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiNonSolicit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiNonSolicit {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiNonSolicitFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiNonSolicit): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiNonSolicitToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiNonSolicit>, I>>(base?: I): MConsultingMultiNonSolicit {
    return MConsultingMultiNonSolicit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiNonSolicit>, I>>(object: I): MConsultingMultiNonSolicit {
    const message = createBaseMConsultingMultiNonSolicit();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiAntiRaiding(): MConsultingMultiAntiRaiding {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiAntiRaiding = {
  encode(message: MConsultingMultiAntiRaiding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiAntiRaiding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiAntiRaiding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiAntiRaiding {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiAntiRaidingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiAntiRaiding): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiAntiRaidingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiAntiRaiding>, I>>(base?: I): MConsultingMultiAntiRaiding {
    return MConsultingMultiAntiRaiding.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiAntiRaiding>, I>>(object: I): MConsultingMultiAntiRaiding {
    const message = createBaseMConsultingMultiAntiRaiding();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiInvoiceFreq(): MConsultingMultiInvoiceFreq {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiInvoiceFreq = {
  encode(message: MConsultingMultiInvoiceFreq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiInvoiceFreq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiInvoiceFreq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiInvoiceFreq {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiInvoiceFreqFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiInvoiceFreq): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiInvoiceFreqToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiInvoiceFreq>, I>>(base?: I): MConsultingMultiInvoiceFreq {
    return MConsultingMultiInvoiceFreq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiInvoiceFreq>, I>>(object: I): MConsultingMultiInvoiceFreq {
    const message = createBaseMConsultingMultiInvoiceFreq();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiRemainderType(): MConsultingMultiRemainderType {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiRemainderType = {
  encode(message: MConsultingMultiRemainderType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiRemainderType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiRemainderType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiRemainderType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiRemainderTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiRemainderType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiRemainderTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiRemainderType>, I>>(base?: I): MConsultingMultiRemainderType {
    return MConsultingMultiRemainderType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiRemainderType>, I>>(
    object: I,
  ): MConsultingMultiRemainderType {
    const message = createBaseMConsultingMultiRemainderType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiEarlyTermFee(): MConsultingMultiEarlyTermFee {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiEarlyTermFee = {
  encode(message: MConsultingMultiEarlyTermFee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiEarlyTermFee {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiEarlyTermFee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiEarlyTermFee {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiEarlyTermFeeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiEarlyTermFee): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiEarlyTermFeeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiEarlyTermFee>, I>>(base?: I): MConsultingMultiEarlyTermFee {
    return MConsultingMultiEarlyTermFee.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiEarlyTermFee>, I>>(object: I): MConsultingMultiEarlyTermFee {
    const message = createBaseMConsultingMultiEarlyTermFee();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiFeeBasis(): MConsultingMultiFeeBasis {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiFeeBasis = {
  encode(message: MConsultingMultiFeeBasis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiFeeBasis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiFeeBasis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiFeeBasis {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiFeeBasisFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiFeeBasis): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eConsultingMultiFeeBasisToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiFeeBasis>, I>>(base?: I): MConsultingMultiFeeBasis {
    return MConsultingMultiFeeBasis.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiFeeBasis>, I>>(object: I): MConsultingMultiFeeBasis {
    const message = createBaseMConsultingMultiFeeBasis();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiServicesType(): MConsultingMultiServicesType {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiServicesType = {
  encode(message: MConsultingMultiServicesType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiServicesType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiServicesType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiServicesType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiServicesTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiServicesType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eConsultingMultiServicesTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiServicesType>, I>>(base?: I): MConsultingMultiServicesType {
    return MConsultingMultiServicesType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiServicesType>, I>>(object: I): MConsultingMultiServicesType {
    const message = createBaseMConsultingMultiServicesType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMConsultingMultiRole(): MConsultingMultiRole {
  return { optionDbValue: 0, other: "" };
}

export const MConsultingMultiRole = {
  encode(message: MConsultingMultiRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MConsultingMultiRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMConsultingMultiRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MConsultingMultiRole {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eConsultingMultiRoleFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MConsultingMultiRole): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eConsultingMultiRoleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MConsultingMultiRole>, I>>(base?: I): MConsultingMultiRole {
    return MConsultingMultiRole.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MConsultingMultiRole>, I>>(object: I): MConsultingMultiRole {
    const message = createBaseMConsultingMultiRole();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseConsultingMulti(): ConsultingMulti {
  return {
    effectiveDate: undefined,
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    otherFullName: "",
    otherState: "",
    otherEntityType: undefined,
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyEmail: "",
    companyNoticeCopyPerson: "",
    consultantNoticeEmail: "",
    consultantNoticePerson: "",
    consultantNoticeCopyEmail: "",
    consultantNoticeCopyPerson: "",
    governLawState: "",
    venue: "",
    arbShort: 0,
    companyNoticeCopy: 0,
    consultantNoticeCopy: 0,
    consultantTermNotice: 0,
    companyTermNotice: 0,
    causeTermNotice: 0,
    nonCompete: undefined,
    nonCompeteTailMonths: 0,
    nonSolicit: undefined,
    nonSolicitTailMonths: 0,
    antiRaiding: undefined,
    antiRaidingTailMonths: 0,
    consultantServices: [],
    fixedFee: 0,
    feeAmount: 0,
    timeLimitMax: 0,
    feeCustom: "",
    invoiceFreq: undefined,
    remainderType: undefined,
    kickoffPayment: 0,
    paymentDays: 0,
    earlyTermFee: undefined,
    feeBasis: undefined,
    timeLimit: 0,
    limitFixedFeeList: [],
    limitFixedFee: 0,
    servicesType: undefined,
    role: undefined,
  };
}

export const ConsultingMulti = {
  encode(message: ConsultingMulti, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyFull !== "") {
      writer.uint32(18).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(26).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MConsultingMultiCompanyEntityType.encode(message.companyEntityType, writer.uint32(34).fork()).ldelim();
    }
    if (message.otherFullName !== "") {
      writer.uint32(42).string(message.otherFullName);
    }
    if (message.otherState !== "") {
      writer.uint32(50).string(message.otherState);
    }
    if (message.otherEntityType !== undefined) {
      MConsultingMultiOtherEntityType.encode(message.otherEntityType, writer.uint32(58).fork()).ldelim();
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(66).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(74).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(82).string(message.companyNoticeCopyEmail);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(90).string(message.companyNoticeCopyPerson);
    }
    if (message.consultantNoticeEmail !== "") {
      writer.uint32(98).string(message.consultantNoticeEmail);
    }
    if (message.consultantNoticePerson !== "") {
      writer.uint32(106).string(message.consultantNoticePerson);
    }
    if (message.consultantNoticeCopyEmail !== "") {
      writer.uint32(114).string(message.consultantNoticeCopyEmail);
    }
    if (message.consultantNoticeCopyPerson !== "") {
      writer.uint32(122).string(message.consultantNoticeCopyPerson);
    }
    if (message.governLawState !== "") {
      writer.uint32(130).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(138).string(message.venue);
    }
    if (message.arbShort !== 0) {
      writer.uint32(144).int32(message.arbShort);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(152).int32(message.companyNoticeCopy);
    }
    if (message.consultantNoticeCopy !== 0) {
      writer.uint32(160).int32(message.consultantNoticeCopy);
    }
    if (message.consultantTermNotice !== 0) {
      writer.uint32(173).float(message.consultantTermNotice);
    }
    if (message.companyTermNotice !== 0) {
      writer.uint32(181).float(message.companyTermNotice);
    }
    if (message.causeTermNotice !== 0) {
      writer.uint32(189).float(message.causeTermNotice);
    }
    if (message.nonCompete !== undefined) {
      MConsultingMultiNonCompete.encode(message.nonCompete, writer.uint32(194).fork()).ldelim();
    }
    if (message.nonCompeteTailMonths !== 0) {
      writer.uint32(205).float(message.nonCompeteTailMonths);
    }
    if (message.nonSolicit !== undefined) {
      MConsultingMultiNonSolicit.encode(message.nonSolicit, writer.uint32(210).fork()).ldelim();
    }
    if (message.nonSolicitTailMonths !== 0) {
      writer.uint32(221).float(message.nonSolicitTailMonths);
    }
    if (message.antiRaiding !== undefined) {
      MConsultingMultiAntiRaiding.encode(message.antiRaiding, writer.uint32(226).fork()).ldelim();
    }
    if (message.antiRaidingTailMonths !== 0) {
      writer.uint32(237).float(message.antiRaidingTailMonths);
    }
    for (const v of message.consultantServices) {
      writer.uint32(242).string(v!);
    }
    if (message.fixedFee !== 0) {
      writer.uint32(253).float(message.fixedFee);
    }
    if (message.feeAmount !== 0) {
      writer.uint32(261).float(message.feeAmount);
    }
    if (message.timeLimitMax !== 0) {
      writer.uint32(269).float(message.timeLimitMax);
    }
    if (message.feeCustom !== "") {
      writer.uint32(274).string(message.feeCustom);
    }
    if (message.invoiceFreq !== undefined) {
      MConsultingMultiInvoiceFreq.encode(message.invoiceFreq, writer.uint32(282).fork()).ldelim();
    }
    if (message.remainderType !== undefined) {
      MConsultingMultiRemainderType.encode(message.remainderType, writer.uint32(290).fork()).ldelim();
    }
    if (message.kickoffPayment !== 0) {
      writer.uint32(301).float(message.kickoffPayment);
    }
    if (message.paymentDays !== 0) {
      writer.uint32(309).float(message.paymentDays);
    }
    if (message.earlyTermFee !== undefined) {
      MConsultingMultiEarlyTermFee.encode(message.earlyTermFee, writer.uint32(314).fork()).ldelim();
    }
    if (message.feeBasis !== undefined) {
      MConsultingMultiFeeBasis.encode(message.feeBasis, writer.uint32(322).fork()).ldelim();
    }
    if (message.timeLimit !== 0) {
      writer.uint32(328).int32(message.timeLimit);
    }
    for (const v of message.limitFixedFeeList) {
      writer.uint32(338).string(v!);
    }
    if (message.limitFixedFee !== 0) {
      writer.uint32(344).int32(message.limitFixedFee);
    }
    if (message.servicesType !== undefined) {
      MConsultingMultiServicesType.encode(message.servicesType, writer.uint32(354).fork()).ldelim();
    }
    if (message.role !== undefined) {
      MConsultingMultiRole.encode(message.role, writer.uint32(362).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsultingMulti {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsultingMulti();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.companyEntityType = MConsultingMultiCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.otherFullName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.otherState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.otherEntityType = MConsultingMultiOtherEntityType.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.consultantNoticeEmail = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.consultantNoticePerson = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.consultantNoticeCopyEmail = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.consultantNoticeCopyPerson = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 18:
          if (tag != 144) {
            break;
          }

          message.arbShort = reader.int32() as any;
          continue;
        case 19:
          if (tag != 152) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.consultantNoticeCopy = reader.int32() as any;
          continue;
        case 21:
          if (tag != 173) {
            break;
          }

          message.consultantTermNotice = reader.float();
          continue;
        case 22:
          if (tag != 181) {
            break;
          }

          message.companyTermNotice = reader.float();
          continue;
        case 23:
          if (tag != 189) {
            break;
          }

          message.causeTermNotice = reader.float();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.nonCompete = MConsultingMultiNonCompete.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag != 205) {
            break;
          }

          message.nonCompeteTailMonths = reader.float();
          continue;
        case 26:
          if (tag != 210) {
            break;
          }

          message.nonSolicit = MConsultingMultiNonSolicit.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag != 221) {
            break;
          }

          message.nonSolicitTailMonths = reader.float();
          continue;
        case 28:
          if (tag != 226) {
            break;
          }

          message.antiRaiding = MConsultingMultiAntiRaiding.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag != 237) {
            break;
          }

          message.antiRaidingTailMonths = reader.float();
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.consultantServices.push(reader.string());
          continue;
        case 31:
          if (tag != 253) {
            break;
          }

          message.fixedFee = reader.float();
          continue;
        case 32:
          if (tag != 261) {
            break;
          }

          message.feeAmount = reader.float();
          continue;
        case 33:
          if (tag != 269) {
            break;
          }

          message.timeLimitMax = reader.float();
          continue;
        case 34:
          if (tag != 274) {
            break;
          }

          message.feeCustom = reader.string();
          continue;
        case 35:
          if (tag != 282) {
            break;
          }

          message.invoiceFreq = MConsultingMultiInvoiceFreq.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag != 290) {
            break;
          }

          message.remainderType = MConsultingMultiRemainderType.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag != 301) {
            break;
          }

          message.kickoffPayment = reader.float();
          continue;
        case 38:
          if (tag != 309) {
            break;
          }

          message.paymentDays = reader.float();
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.earlyTermFee = MConsultingMultiEarlyTermFee.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.feeBasis = MConsultingMultiFeeBasis.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag != 328) {
            break;
          }

          message.timeLimit = reader.int32() as any;
          continue;
        case 42:
          if (tag != 338) {
            break;
          }

          message.limitFixedFeeList.push(reader.string());
          continue;
        case 43:
          if (tag != 344) {
            break;
          }

          message.limitFixedFee = reader.int32() as any;
          continue;
        case 44:
          if (tag != 354) {
            break;
          }

          message.servicesType = MConsultingMultiServicesType.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag != 362) {
            break;
          }

          message.role = MConsultingMultiRole.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsultingMulti {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MConsultingMultiCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      otherFullName: isSet(object.otherFullName) ? String(object.otherFullName) : "",
      otherState: isSet(object.otherState) ? String(object.otherState) : "",
      otherEntityType: isSet(object.otherEntityType)
        ? MConsultingMultiOtherEntityType.fromJSON(object.otherEntityType)
        : undefined,
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      consultantNoticeEmail: isSet(object.consultantNoticeEmail) ? String(object.consultantNoticeEmail) : "",
      consultantNoticePerson: isSet(object.consultantNoticePerson) ? String(object.consultantNoticePerson) : "",
      consultantNoticeCopyEmail: isSet(object.consultantNoticeCopyEmail)
        ? String(object.consultantNoticeCopyEmail)
        : "",
      consultantNoticeCopyPerson: isSet(object.consultantNoticeCopyPerson)
        ? String(object.consultantNoticeCopyPerson)
        : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      arbShort: isSet(object.arbShort) ? internalBooleanFromJSON(object.arbShort) : 0,
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      consultantNoticeCopy: isSet(object.consultantNoticeCopy)
        ? internalBooleanFromJSON(object.consultantNoticeCopy)
        : 0,
      consultantTermNotice: isSet(object.consultantTermNotice) ? Number(object.consultantTermNotice) : 0,
      companyTermNotice: isSet(object.companyTermNotice) ? Number(object.companyTermNotice) : 0,
      causeTermNotice: isSet(object.causeTermNotice) ? Number(object.causeTermNotice) : 0,
      nonCompete: isSet(object.nonCompete) ? MConsultingMultiNonCompete.fromJSON(object.nonCompete) : undefined,
      nonCompeteTailMonths: isSet(object.nonCompeteTailMonths) ? Number(object.nonCompeteTailMonths) : 0,
      nonSolicit: isSet(object.nonSolicit) ? MConsultingMultiNonSolicit.fromJSON(object.nonSolicit) : undefined,
      nonSolicitTailMonths: isSet(object.nonSolicitTailMonths) ? Number(object.nonSolicitTailMonths) : 0,
      antiRaiding: isSet(object.antiRaiding) ? MConsultingMultiAntiRaiding.fromJSON(object.antiRaiding) : undefined,
      antiRaidingTailMonths: isSet(object.antiRaidingTailMonths) ? Number(object.antiRaidingTailMonths) : 0,
      consultantServices: Array.isArray(object?.consultantServices)
        ? object.consultantServices.map((e: any) => String(e))
        : [],
      fixedFee: isSet(object.fixedFee) ? Number(object.fixedFee) : 0,
      feeAmount: isSet(object.feeAmount) ? Number(object.feeAmount) : 0,
      timeLimitMax: isSet(object.timeLimitMax) ? Number(object.timeLimitMax) : 0,
      feeCustom: isSet(object.feeCustom) ? String(object.feeCustom) : "",
      invoiceFreq: isSet(object.invoiceFreq) ? MConsultingMultiInvoiceFreq.fromJSON(object.invoiceFreq) : undefined,
      remainderType: isSet(object.remainderType)
        ? MConsultingMultiRemainderType.fromJSON(object.remainderType)
        : undefined,
      kickoffPayment: isSet(object.kickoffPayment) ? Number(object.kickoffPayment) : 0,
      paymentDays: isSet(object.paymentDays) ? Number(object.paymentDays) : 0,
      earlyTermFee: isSet(object.earlyTermFee) ? MConsultingMultiEarlyTermFee.fromJSON(object.earlyTermFee) : undefined,
      feeBasis: isSet(object.feeBasis) ? MConsultingMultiFeeBasis.fromJSON(object.feeBasis) : undefined,
      timeLimit: isSet(object.timeLimit) ? internalBooleanFromJSON(object.timeLimit) : 0,
      limitFixedFeeList: Array.isArray(object?.limitFixedFeeList)
        ? object.limitFixedFeeList.map((e: any) => String(e))
        : [],
      limitFixedFee: isSet(object.limitFixedFee) ? internalBooleanFromJSON(object.limitFixedFee) : 0,
      servicesType: isSet(object.servicesType) ? MConsultingMultiServicesType.fromJSON(object.servicesType) : undefined,
      role: isSet(object.role) ? MConsultingMultiRole.fromJSON(object.role) : undefined,
    };
  },

  toJSON(message: ConsultingMulti): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MConsultingMultiCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.otherFullName !== undefined && (obj.otherFullName = message.otherFullName);
    message.otherState !== undefined && (obj.otherState = message.otherState);
    message.otherEntityType !== undefined && (obj.otherEntityType = message.otherEntityType
      ? MConsultingMultiOtherEntityType.toJSON(message.otherEntityType)
      : undefined);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.consultantNoticeEmail !== undefined && (obj.consultantNoticeEmail = message.consultantNoticeEmail);
    message.consultantNoticePerson !== undefined && (obj.consultantNoticePerson = message.consultantNoticePerson);
    message.consultantNoticeCopyEmail !== undefined &&
      (obj.consultantNoticeCopyEmail = message.consultantNoticeCopyEmail);
    message.consultantNoticeCopyPerson !== undefined &&
      (obj.consultantNoticeCopyPerson = message.consultantNoticeCopyPerson);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.arbShort !== undefined && (obj.arbShort = internalBooleanToJSON(message.arbShort));
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.consultantNoticeCopy !== undefined &&
      (obj.consultantNoticeCopy = internalBooleanToJSON(message.consultantNoticeCopy));
    message.consultantTermNotice !== undefined && (obj.consultantTermNotice = message.consultantTermNotice);
    message.companyTermNotice !== undefined && (obj.companyTermNotice = message.companyTermNotice);
    message.causeTermNotice !== undefined && (obj.causeTermNotice = message.causeTermNotice);
    message.nonCompete !== undefined &&
      (obj.nonCompete = message.nonCompete ? MConsultingMultiNonCompete.toJSON(message.nonCompete) : undefined);
    message.nonCompeteTailMonths !== undefined && (obj.nonCompeteTailMonths = message.nonCompeteTailMonths);
    message.nonSolicit !== undefined &&
      (obj.nonSolicit = message.nonSolicit ? MConsultingMultiNonSolicit.toJSON(message.nonSolicit) : undefined);
    message.nonSolicitTailMonths !== undefined && (obj.nonSolicitTailMonths = message.nonSolicitTailMonths);
    message.antiRaiding !== undefined &&
      (obj.antiRaiding = message.antiRaiding ? MConsultingMultiAntiRaiding.toJSON(message.antiRaiding) : undefined);
    message.antiRaidingTailMonths !== undefined && (obj.antiRaidingTailMonths = message.antiRaidingTailMonths);
    if (message.consultantServices) {
      obj.consultantServices = message.consultantServices.map((e) => e);
    } else {
      obj.consultantServices = [];
    }
    message.fixedFee !== undefined && (obj.fixedFee = message.fixedFee);
    message.feeAmount !== undefined && (obj.feeAmount = message.feeAmount);
    message.timeLimitMax !== undefined && (obj.timeLimitMax = message.timeLimitMax);
    message.feeCustom !== undefined && (obj.feeCustom = message.feeCustom);
    message.invoiceFreq !== undefined &&
      (obj.invoiceFreq = message.invoiceFreq ? MConsultingMultiInvoiceFreq.toJSON(message.invoiceFreq) : undefined);
    message.remainderType !== undefined && (obj.remainderType = message.remainderType
      ? MConsultingMultiRemainderType.toJSON(message.remainderType)
      : undefined);
    message.kickoffPayment !== undefined && (obj.kickoffPayment = message.kickoffPayment);
    message.paymentDays !== undefined && (obj.paymentDays = message.paymentDays);
    message.earlyTermFee !== undefined &&
      (obj.earlyTermFee = message.earlyTermFee ? MConsultingMultiEarlyTermFee.toJSON(message.earlyTermFee) : undefined);
    message.feeBasis !== undefined &&
      (obj.feeBasis = message.feeBasis ? MConsultingMultiFeeBasis.toJSON(message.feeBasis) : undefined);
    message.timeLimit !== undefined && (obj.timeLimit = internalBooleanToJSON(message.timeLimit));
    if (message.limitFixedFeeList) {
      obj.limitFixedFeeList = message.limitFixedFeeList.map((e) => e);
    } else {
      obj.limitFixedFeeList = [];
    }
    message.limitFixedFee !== undefined && (obj.limitFixedFee = internalBooleanToJSON(message.limitFixedFee));
    message.servicesType !== undefined &&
      (obj.servicesType = message.servicesType ? MConsultingMultiServicesType.toJSON(message.servicesType) : undefined);
    message.role !== undefined && (obj.role = message.role ? MConsultingMultiRole.toJSON(message.role) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsultingMulti>, I>>(base?: I): ConsultingMulti {
    return ConsultingMulti.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConsultingMulti>, I>>(object: I): ConsultingMulti {
    const message = createBaseConsultingMulti();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MConsultingMultiCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.otherFullName = object.otherFullName ?? "";
    message.otherState = object.otherState ?? "";
    message.otherEntityType = (object.otherEntityType !== undefined && object.otherEntityType !== null)
      ? MConsultingMultiOtherEntityType.fromPartial(object.otherEntityType)
      : undefined;
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.consultantNoticeEmail = object.consultantNoticeEmail ?? "";
    message.consultantNoticePerson = object.consultantNoticePerson ?? "";
    message.consultantNoticeCopyEmail = object.consultantNoticeCopyEmail ?? "";
    message.consultantNoticeCopyPerson = object.consultantNoticeCopyPerson ?? "";
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.arbShort = object.arbShort ?? 0;
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.consultantNoticeCopy = object.consultantNoticeCopy ?? 0;
    message.consultantTermNotice = object.consultantTermNotice ?? 0;
    message.companyTermNotice = object.companyTermNotice ?? 0;
    message.causeTermNotice = object.causeTermNotice ?? 0;
    message.nonCompete = (object.nonCompete !== undefined && object.nonCompete !== null)
      ? MConsultingMultiNonCompete.fromPartial(object.nonCompete)
      : undefined;
    message.nonCompeteTailMonths = object.nonCompeteTailMonths ?? 0;
    message.nonSolicit = (object.nonSolicit !== undefined && object.nonSolicit !== null)
      ? MConsultingMultiNonSolicit.fromPartial(object.nonSolicit)
      : undefined;
    message.nonSolicitTailMonths = object.nonSolicitTailMonths ?? 0;
    message.antiRaiding = (object.antiRaiding !== undefined && object.antiRaiding !== null)
      ? MConsultingMultiAntiRaiding.fromPartial(object.antiRaiding)
      : undefined;
    message.antiRaidingTailMonths = object.antiRaidingTailMonths ?? 0;
    message.consultantServices = object.consultantServices?.map((e) => e) || [];
    message.fixedFee = object.fixedFee ?? 0;
    message.feeAmount = object.feeAmount ?? 0;
    message.timeLimitMax = object.timeLimitMax ?? 0;
    message.feeCustom = object.feeCustom ?? "";
    message.invoiceFreq = (object.invoiceFreq !== undefined && object.invoiceFreq !== null)
      ? MConsultingMultiInvoiceFreq.fromPartial(object.invoiceFreq)
      : undefined;
    message.remainderType = (object.remainderType !== undefined && object.remainderType !== null)
      ? MConsultingMultiRemainderType.fromPartial(object.remainderType)
      : undefined;
    message.kickoffPayment = object.kickoffPayment ?? 0;
    message.paymentDays = object.paymentDays ?? 0;
    message.earlyTermFee = (object.earlyTermFee !== undefined && object.earlyTermFee !== null)
      ? MConsultingMultiEarlyTermFee.fromPartial(object.earlyTermFee)
      : undefined;
    message.feeBasis = (object.feeBasis !== undefined && object.feeBasis !== null)
      ? MConsultingMultiFeeBasis.fromPartial(object.feeBasis)
      : undefined;
    message.timeLimit = object.timeLimit ?? 0;
    message.limitFixedFeeList = object.limitFixedFeeList?.map((e) => e) || [];
    message.limitFixedFee = object.limitFixedFee ?? 0;
    message.servicesType = (object.servicesType !== undefined && object.servicesType !== null)
      ? MConsultingMultiServicesType.fromPartial(object.servicesType)
      : undefined;
    message.role = (object.role !== undefined && object.role !== null)
      ? MConsultingMultiRole.fromPartial(object.role)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
