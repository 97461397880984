import { Button } from "components";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

interface DrawingCanvasProps {
    onDone: (dataUrl: string | null) => void;
}
export function DrawingCanvas({ onDone }: DrawingCanvasProps) {
    const ref = useRef<SignatureCanvas>(null);
    return (
        <div className="relative w-full h-full select-none">
            <SignatureCanvas
                ref={ref}
                canvasProps={{
                    className: "h-full w-full",
                }}
                maxWidth={9}
                minWidth={3}
                dotSize={6}
            />
            <div className="absolute right-0 bottom-0 mr-4 mb-4 flex gap-4">
                <Button onClick={() => onDone(null)} color="error">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onDone(ref.current!.getTrimmedCanvas().toDataURL());
                    }}
                >
                    Done
                </Button>
            </div>
        </div>
    );
}
