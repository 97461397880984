import { ReactNode, useRef, useState } from "react";
import { useAppSelector } from "store";
import { KbNode } from "../Parser";

import { Render, SvgIconKnowledge, SvgIconKnowledgeOff, Tooltip } from "components";
import { useMedia } from "hooks";

export const KnowledgebaseRenderer = (props: { node: KbNode; children: ReactNode }) => {
    const { node, children } = props;

    const ref = useRef<HTMLDivElement>(null);

    const [state, setState] = useState(false);
    const smallScreen = useMedia({ max: "lg" });
    const icon = state ? <SvgIconKnowledge /> : <SvgIconKnowledgeOff />;

    const showExplanations = useAppSelector((state) => state.mainReducer.showExplanations);

    return (
        <div
            ref={ref}
            style={{
                position: "relative",
            }}
        >
            {showExplanations && (

                <div className="absolute top-0 -left-14">
                    <Tooltip
                        onChange={(open) => {
                            setState(open);
                            if (open && ref.current) {
                                ref.current.style.animation = "blink 1.5s";

                                setTimeout(() => {
                                    if (!ref.current) return;
                                    ref.current.style.animation = "";
                                }, 1500);
                            }
                        }}
                        position={smallScreen ? "right" : "left"}
                        title={<Render node={node.payload} />}
                    // todo: fix title
                    >
                        <div className="">{icon}</div>
                    </Tooltip>
                </div>
            )}
            {children}
        </div>
    );
};
