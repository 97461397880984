import { AnyAction, Middleware } from "redux";
import { RootState } from "store";

export const locaStorageMiddleware: Middleware<{}, RootState> = (store) => (next) => (action: AnyAction) => {
    next(action);

    if (typeof action.type === "string" && action.type.startsWith("chatbot")) {
        localStorage.setItem("chatbot", JSON.stringify(store.getState().chatbot));
    }
};
