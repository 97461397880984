import { LogoImg } from 'components/1_features/AppBar/Menu'
import ABTesting, { AB_TEST_NAMES } from 'components/utils/ABTesting'
import mixpanel from 'mixpanel-browser'
import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import GoogleImg from "../../img/icons/google.png"
import { useAuth } from '../contexts/AuthContext'
import Modal, { ExitButton, ModalFooter } from '../Modal'
import { isProd } from '../utils/EnvResolver'
import { GTMHelper } from '../utils/GTMHelper'
import "./Auth.scss"
import { getRedirectUrl, passCurrentSearchQuery, TextWithCrossingLine } from './AuthUtils'
import SocialSignin, { SocialButton, SocialType } from './SocialSignin'
import ClawdiaHead from "img/clawdia-head.png"

export default function SignUp() {
    const history = useHistory()

    const authContext = useAuth()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")

    useEffect(() => {
        if (isProd()) {
            mixpanel.track("signup_page_view")
            GTMHelper.push({ event: "signup_page_view" });
        }

        return () => {
        }
    }, [])

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        try {
            setError("")
            await authContext?.signup(email, password)

            setTimeout(() => {
                history.push(getRedirectUrl())
            }, 10);

        } catch (e: any) {
            setError("Signup failed! " +
                (e.message ?? "")
            )
        }
    }

    return (
        <Modal>
            <div className="relative sm:w-172 h-full flex flex-col">

                <ExitButton onClick={() => {
                    window.location.href = 'https://www.clawdia.ai';
                }} />

                <div className="flex-grow">
                    <div className="mx-6 sm:mx-28">
                        <div className={"_ErrorHeight mt-2 px-4 rounded-lg border border-white flex items-center justify-center " + (error ? "border-dangerBorder bg-dangerBg" : "")}>
                            <p className="text-danger text-sm overflow-ellipsis">{error}</p>
                        </div>

                        <SignUpHeader />

                        <form onSubmit={handleSubmit}>
                            <p className="text-1_5xl mt-2 text-darkIndigo">Work Email</p>

                            <input required onChange={(e) => setEmail(e.target.value)}
                                type="email" autoFocus className="InputField w-full mt-1 text-base rounded-md border px-4 py-3 border-sky" placeholder="Ex: name@company.com" />

                            <p className="text-1_5xl mt-3 text-darkIndigo">Password:</p>

                            <input required onChange={(e) => setPassword(e.target.value)}
                                type="password" className="InputField w-full mt-1 text-base rounded-md border px-4 py-3 border-paleLilac" placeholder="Ex: dontforgetme$798213" />

                            <input type="submit" className="mb-4 mt-6 h-14 bg-primary text-white text-xl w-full rounded-lg hover:bg-primaryHover cursor-pointer" value="Continue" />

                            <TextWithCrossingLine text="Or sign up with" />

                            <SocialButtons setError={setError} />
                        </form>
                    </div>
                </div>
                <ModalFooter>
                    <div className="h-16 flex flex-col items-center justify-center">
                        <p className="text-lg text-darkIndigo">Already have an account? <a onClick={() => {
                            history.push("/login" + passCurrentSearchQuery())
                        }}>Log in</a></p>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

function SocialButtons(props: { setError: Function }) {
    const history = useHistory()

    let scopes = ['r_liteprofile', 'r_emailaddress'];

    return <div className="flex justify-between gap-2 w-full">

        <SocialButton text="Continue with Google" icon={GoogleImg} onClick={() => SocialSignin(SocialType.GOOGLE, history, props.setError)} />
        {/* <SocialButton text="Twitter" icon={TwitterImg} onClick={() => SocialSignin(SocialType.TWITTER, history, props.setError)} />
        <SocialButton text="Linkedin" icon={LinkedInImg} onClick={() => {
            window.open(
                `https://www.linkedin.com/oauth/v2/authorization?client_id=${"77ekwwg4kir7au"}&redirect_uri=${"https://app.clawdia.ai"}&response_type=code&scope=${scopes.join('+')}`,
                'Login with linkedin',
                `height=600,width=500`,
            )

            setTimeout(() => {
                props.setError("An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.")
            }, 1000)
        }} /> */}
    </div>
}

function SignUpHeader() {
    const source = localStorage.getItem("marketing_utm_source");

    if (source === "adwords") {
        return <>
            <div className="mt-2">
                <LogoImg mode='light' size='medium' />
            </div>

            <p className="text-primary text-3xl font-semibold mt-8">
                <span>Let's Create Your Contract<br />With A Few Simple Steps!</span>
            </p>
        </>
    }

    return <>
        <div className="mt-2">
            <LogoImg mode='light' size='large' />
        </div>

        <p className="text-darkIndigo text-3xl font-medium mt-8">
            <span>Create Your Account</span>
        </p>
    </>
}
