export const toAlpha = (num: number) => { // todo - util
    if(num < 1 || num > 26 || typeof num !== 'number'){
       return "";
    }
    const leveller = 64;
  
    return String.fromCharCode(num + leveller);
  };

 export const fromAlpha = (s: string) => s.toLowerCase().charCodeAt(0) - 97
