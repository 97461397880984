import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BlockNode, Parser } from "../Parser";

import { Editable, Slate, withReact } from "slate-react";
// Import the Slate editor factory.
import { FillableStrategy } from "apps/legal-ide/App/Editor/parser";
import { BaseRange, createEditor, Descendant, Node, NodeEntry, Text, Transforms } from "slate";
import { useAppSelector } from "store";

interface Props {
    initialValue: string;
    onChange?: (value: string) => void;
}

type fn = (...args: any[]) => any;
let id: NodeJS.Timeout;
function debounce(callback: fn | undefined, timeount: number) {
    clearTimeout(id);
    id = setTimeout(() => {
        callback?.();
    }, timeount);
}
export const LegalEditor = (props: Props) => {
    const { initialValue, onChange } = props;

    const fields = useAppSelector((state) => state.agreementManager.currentAgreement?.fields);

    const [editor] = useState(() => withReact(createEditor()));

    const [nodes, setNodes] = useState<Descendant[]>(() => {
        const lines = initialValue.split("\n");
        return lines.map((line) => ({ type: "paragraph", children: [{ text: line }] }));
    });
    const [text, setText] = useState("");

    const ref = useRef<HTMLDivElement>(null);

    function isBlockNode(node: unknown): node is BlockNode {
        return !!node && Object.hasOwn(node, "children");
    }

    const ast = useMemo(() => {
        const parser = new Parser({ fields: fields });
        return parser.parse(text);
    }, [text, fields]);

    const stack: any[] = [];
    const decorate = useCallback<(entry: NodeEntry) => BaseRange[]>(
        ([node, path]) => {
            const tagsRegex = /<<\/?|>>/g;
            const ranges: BaseRange[] = [];

            const fillableStrategy = new FillableStrategy();

            if (Text.isText(node)) {
                const slateLineIndex = path[0] + 1;
                const lineMessages = ast.messages.filter((message) => message.position.line === slateLineIndex);

                lineMessages.forEach((message) => {
                    let slatendex = 0;

                    const start = ast.linesNumbers.map[message.position.start];

                    for (let i = 0; i < ast.linesNumbers.total; i++) {
                        const l = ast.linesNumbers.map[message.position.start - i];

                        if (l !== start) break;
                        slatendex = i;
                    }

                    ranges.push({
                        error: true,
                        tooltip: message.description,
                        anchor: { path, offset: slatendex },
                        focus: { path, offset: slatendex + message.position.length },
                    } as any);
                });

                // markdown

                const boldRegex = /\*\*([^\*]+)\*\*/g;
                const bolds = [...node.text.matchAll(boldRegex)];
                for (const bold of bolds) {
                    ranges.push({
                        bold: true,
                        anchor: { path, offset: bold.index! },
                        focus: { path, offset: bold.index! + bold[0].length },
                    } as any);
                }

                const signatureIndex = node.text.indexOf("{{signature}}");
                if (signatureIndex >= 0) {
                    ranges.push({
                        signature: true,
                        anchor: { path, offset: signatureIndex },
                        focus: { path, offset: signatureIndex + "{{signature}}".length },
                    } as any);
                }

                const pageBreakIndex = node.text.indexOf("{{pageBreak}}");
                if (pageBreakIndex >= 0) {
                    ranges.push({
                        pageBreak: true,
                        anchor: { path, offset: signatureIndex },
                        focus: { path, offset: signatureIndex + "{{pageBreak}}".length },
                    } as any);
                }

                if (node.text.startsWith("# ")) {
                    ranges.push({
                        heading1: true,
                        anchor: { path, offset: 0 },
                        focus: { path, offset: node.text.length },
                    } as any);
                }

                // markdown end

                const matches = [...node.text.matchAll(tagsRegex)];

                const fillables = fillableStrategy.matchAll(node.text);

                for (const fillable of fillables) {
                    ranges.push({
                        fillable: true,
                        anchor: { path, offset: fillable.index },
                        focus: { path, offset: fillable.index + fillable.fullMatch.length },
                    } as any);
                }

                if (stack.length && !matches.length) {
                    ranges.push({
                        [stack[stack.length - 1].tag]: true,
                        anchor: { path, offset: 0 },
                        focus: { path, offset: node.text.length },
                    } as any);
                }

                matches.forEach((match, i) => {
                    const nextMatch = matches[i + 1];
                    if (match[0] === ">>") {
                        stack.pop();
                    }
                    if (match[0] === "<<") {
                        const tag = node.text.split(" ")[0]?.substring(2);
                        if (tag) {
                            stack.push({ tag });

                            const end = nextMatch && nextMatch[0] === ">>" ? nextMatch.index! : node.text.length;

                            ranges.push({
                                [tag]: true,
                                anchor: { path, offset: match.index! + tag.length + 2 },
                                focus: { path, offset: match.index! + end },
                            } as any);
                        }
                    }

                    ranges.push({
                        tag: true,
                        anchor: { path, offset: match.index ?? 0 },
                        focus: { path, offset: (match.index ?? 0) + match[0].length },
                    } as any);
                });
            }

            return ranges;
        },
        [text, fields]
    );

    useEffect(() => {
        const t = nodes.map((node) => Node.string(node)).join("\n");
        setText(t);
        debounce(() => onChange?.(t), 1000);
    }, [nodes]);

    return (
        <Slate onChange={setNodes} editor={editor} value={nodes}>
            <Editable
                className="w-full h-full bg-white"
                renderLeaf={(props) => {
                    const { leaf, children } = props;

                    const style = {
                        ...(leaf.fillable && { color: "blue" }),
                        ...(leaf.if && { color: "darkBlue", fontWeight: "bold" }),
                        ...(leaf.heading1 && { fontSize: 40, fontWeight: "bold" }),
                        ...(leaf.bold && { fontWeight: "bold" }),
                        ...(leaf.signature && { fontWeight: "bold", background: "red" }),
                        ...(leaf.pageBreak && { fontWeight: "bold", background: "pink" }),
                        ...(leaf.error && { textDecoration: "wavy underline red" }),
                    };
                    return (
                        <span
                            title={leaf.tooltip}
                            style={{
                                color: props.leaf.tag ? "red" : "inherit",
                                backgroundColor: props.leaf.kb ? "#E9BC00" : "inherit",
                                ...style,
                            }}
                            {...props.attributes}
                        >
                            {props.children}
                        </span>
                    );
                }}
                decorate={decorate}
                onKeyDown={(event) => {
                    if (event.key === "Tab") {
                        if (event.ctrlKey || event.altKey || event.metaKey) return;

                        event.preventDefault();
                        Transforms.insertText(editor, "\t");
                    }
                }}
            />
        </Slate>
    );
};
