import { Button } from "../Button";
import { Cell } from "./Cell";
import { Column } from "./Column";
import { useTable } from "./TableContext";

interface RowProps {
    item?: { [key: string]: any };
    index: number;
}

export const Row = ({ item, index }: RowProps) => {
    const api = useTable();

    const columns = api?.getColumns();

    const hasActions = api.isRowDeletable() || api.isRowUnlimited();

    const row = api?.options?.row?.defaultRows?.[index];

    return (
        <tr className="">
            {!!api.options?.row?.heading && (
                <Column heading column={{ id: row?.id ?? "", name: row?.heading ?? "" }} index={index} />
            )}
            {columns?.map((col, colIndex) => {
                // const value = api.getCellValue({ columnId: col.id, rowIndex: index }) ?? "";

                const value = item?.[col.id] ?? "";

                return (
                    <Cell
                        onChange={(newValue: any) => {
                            api?.updateCellData?.({ rowIndex: index, columnId: col.id, value: newValue });
                        }}
                        // row={row}
                        // col={col}
                    >
                        {value}
                    </Cell>
                );
            })}
            <RowActions index={index} />
        </tr>
    );
};

function RowActions({ index }: { index: number }) {
    const api = useTable();

    const editMode = api.options?.editable;
    const hide = api.options?.row?.hideActions;
    const unlimited = api.options?.row?.unlimited;

    const onClick = () => {
        if (editMode) {
            api.removeDefaultRowByIndex(index);
        } else {
            api.removeRowByIdex(index);
        }
    };

    if (hide || (!editMode && !unlimited)) {
        return null;
    }

    return (
        <Cell className="border-none">
            <Button className="print:hidden" onClick={onClick} variant="outlined">
                x
            </Button>
        </Cell>
    );
}
