import React from "react";
import { useEffect, useRef } from "react";
import { PdfPageCanvas } from "../components/PdfPageCanvas";
import { usePdfContext } from "../PdfContext";

export const PdfCanvas = () => {
    const context = usePdfContext();
    const pages = new Array(context.state.totalPages).fill({});
    const pageRefs = useRef<(React.RefObject<HTMLDivElement>)[]>([]);

    useEffect(() => {
        window.addEventListener('scroll', checkVisibility, true);

        return () => {
          window.removeEventListener('scroll', checkVisibility);
        };
      }, []);

      useEffect(() => {
        pageRefs.current = Array(context.state.totalPages).fill({}).map((_, i) => pageRefs.current[i] || React.createRef());
      },
      [context.state.file]);

      const checkVisibility = () => {
        let maxVisiblePercentage = 0;
        let mostVisiblePage = 1;
        
        pageRefs.current.forEach((ref, index) => {
            const rect = ref.current?.getBoundingClientRect();
          
            if (!rect) {
            	return;
            }

            const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
            const visiblePercentage = visibleHeight / rect.height;
        
            if (visiblePercentage > maxVisiblePercentage) {
                maxVisiblePercentage = visiblePercentage;
                mostVisiblePage = index + 1;
            }
        });
      
        context.updateState({
            selectedPage: mostVisiblePage,
        });
      };      

    return (
        <div className="flex flex-col gap-4">
            {!pages.length && (
                <div
                    className="flex justify-center border items-center text-grayAccent tex-lg bg-white shadow-sm"
                    style={{
                        width: 595 + "px",
                        height: 842 + "px",
                    }}
                >
                    add pdf file
                </div>
            )}
            {pages.map((_, pageIndex) => {
                const selected = pageIndex + 1 === context.state.selectedPage;
                return (
                    <div
                        key={pageIndex}
                        ref={pageRefs.current[pageIndex]}
                        onClick={(e) => {
                            e.stopPropagation();
                            context.updateState({
                                selectedPage: pageIndex + 1,
                            });
                        }}
                        className={selected ? "border-2 border-blue-500" : ""}
                    >
                        <PdfPageCanvas pageIndex={pageIndex + 1} />
                    </div>
                );
            })}
        </div>
    );
};
