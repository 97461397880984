import { computeDecisionSupportingToolIssues } from "apps/legal-ide/features/DecisionSupportingTools/DecisionSupportingToolOptionHint";
import { Button, Container } from "components";
import { continueDraft } from "components/life-cycle/DocsBase";
import PCImg from "img/icons/pc.png";
import { PDoc } from "proto/PDoc";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MainStore } from "store/mainTypes";

export default function PrivacyScannerTool() {
    const history = useHistory();

    const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
    const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
    const inboxDocs = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs
    );
    const completedDocs: PDoc[] = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.completedDocs
    );

    const allPrivacyPolicies = [...drafts, ...sentDocs, ...inboxDocs, ...completedDocs].filter(
        (doc) => doc.legalUnit?.kind === "privacyPolicy"
    );

    return (
        <Container className="mt-4 mb-8">
            <>
                <h2 className="text-2xl font-semibold text-darkIndigo">My Scanned Sites</h2>
                <p className="text-lg mt-2">
                    This tool runs daily scans on your completed privacy policies - making sure they
                    are up-to-date.
                </p>
                <p className="text-lg">
                    You will be notified in events where you change your website, the data you
                    collect, etc, as well as cases where the law has changed.
                </p>
                {allPrivacyPolicies.length === 0 && (
                    <p className="text-xl mt-6">No Privacy Policies exist yet</p>
                )}
                <ListSites allPrivacyPolicies={allPrivacyPolicies} />

                <div className="mt-8 self-center">
                    <Button
                        color="secondary"
                        size="lg"
                        onClick={() => {
                            history.push("/privacyPolicy/gen-new");
                        }}
                    >
                        Generate a New Privacy Policy
                    </Button>
                </div>
            </>
        </Container>
    );
}

function ListSites({ allPrivacyPolicies }: { allPrivacyPolicies: any[] }) {
    return (
        <div className="flex flex-col gap-6 mt-6">
            {allPrivacyPolicies.map((privacyPolicy, index) => {
                return <SiteCard key={index} privacyPolicy={privacyPolicy} />;
            })}
        </div>
    );
}

function SiteCard({ privacyPolicy }: { privacyPolicy: PDoc }) {
    const history = useHistory();

    if (!privacyPolicy.legalUnit?.privacyPolicy?.websiteUrl) {
        return null;
    }

    return (
        <div
            className={
                "flex p-4 relative flex-grow w-full md:rounded-2xl rounded-lg bg-white \
    shadow-card hover:shadow-cardHover bg-libraryCard backdrop-blur-md cursor-pointer border border-lightSkyBlue"
            }
            onClick={() => {}}
        >
            <div className="flex flex-col sm:flex-row gap-4 w-full items-center px-4 py-2 justify-between">
                <div className="flex flex-col sm:flex-row gap-8 justify-between items-center">
                    <img src={PCImg} alt="PC" className="w-20 h-20" />
                    <div className="flex flex-col ml-8 w-60">
                        <p className="text-2xl font-medium overflow-hidden whitespace-nowrap overflow-ellipsis w-60">
                            {privacyPolicy.legalUnit?.privacyPolicy.websiteUrl}
                        </p>
                        {/* todo: add real scan */}
                        <p className="text-sm mt-2">
                            Last Scanned:{" "}
                            <span className="text-primary">
                                {new Date().toLocaleDateString("en-us", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })}
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl">Known Issues</p>
                        <KnownIssues privacyPolicy={privacyPolicy} />
                    </div>
                </div>
                <div className="self-center w-40">
                    <Button
                        fullWidth
                        onClick={() => {
                            continueDraft(privacyPolicy, history, "C/1");
                        }}
                    >
                        Fix issues
                    </Button>
                </div>
            </div>
        </div>
    );
}

function KnownIssues({ privacyPolicy }: { privacyPolicy: PDoc }) {
    const issues = computeDecisionSupportingToolIssues(privacyPolicy);

    return (
        <div className="flex flex-col gap-2">
            {issues.length === 0 ? (
                <p>No issues found. Your Privacy Policy looks ok!</p>
            ) : (
                issues.map((issue, index) => {
                    return (
                        <div key={index} className="flex gap-2 items-center justify-start">
                            <div className="w-3 h-3 flex-shrink-0 rounded-full bg-red-500"></div>
                            <p className="text-sm">{issue}</p>
                        </div>
                    );
                })
            )}
        </div>
    );
}
