import { reportErrorToBE } from "utils";
import { request } from "./ApiCommon";

export async function authenticate({
    timestamp,
    siteName,
    sdkUrl,
    secureSig,
}: {
    timestamp: string;
    siteName: string;
    sdkUrl: string;
    secureSig: string;
}) {
    try {
        const response = await request("/api/v1/duda/auth", {
            method: "POST",
            data: {
                timestamp,
                siteName,
                sdkUrl,
                secureSig,
            },
        });
        return response.data;
    } catch (error) {
        reportErrorToBE({ methodName: "dudaAuthenticate", error });
        throw error;
    }
}

export const DudaApi = { authenticate };
