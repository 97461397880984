import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AutoContractDocument } from "apps/legal-ide/App/components/WizardUi/auto-contracts/autoContractsUtils";
import { PDoc, PDocStatus } from "proto/PDoc";

//  state used for simulator
// todo: maybe we should rename it
// todo: maybe load async on legal ide laod only

interface SimulatorDoc extends Omit<PDoc, "legalUnit"> {
    legalUnit: any;
    permanentContext: any; // allow to use  custom  context fir simulator
}

interface SimulatorContract extends Omit<AutoContractDocument, "keyto"> {
    keyto: string;
}
interface LegalIdeState {
    simulator: {
        activeDraft?: SimulatorDoc;
        contract: SimulatorContract;
        showKBs: boolean;
    };
}

const initialState: LegalIdeState = {
    simulator: {
        activeDraft: {
            accountName: "accountName",
            id: "dev/test/1",
            legalUnit: {
                docVersion: "contract_1.0.0",
                kind: "testContract",
                name: "testContract",
                lastStep: "string",
                version: 1,
                testContract: {},
                freestyleSrc: "",
            },
            templateId: "",
            docStatus: PDocStatus.DRAFT,
            permanentContext: {
                sharedWithUser: {
                    user: {
                        email: "otherSideEmail@example.com",
                    },
                    signatures: [""],
                    signingDate: new Date(),
                },
                ownerUser: {
                    user: {
                        email: "myEmail@example.com",
                        companyFullName: "myCompany",
                    },
                    signatures: [""],
                    signingDate: new Date(),
                },
            },
        },
        contract: {
            keyto: "testContract",
            name: "testContract",
            image: "",
            title: "auto testContract",
            text: "text",
            desc: "",
            comingSoon: () => false,
            kind: "testContract",
            contract: () => undefined,
            filename: "testContract",
            namer: () => "testContract",
            families: [],
            tags: [],
            forUSA: true,
            forIsrael: true,
        },
        showKBs: true,
    },
};

export const legalIdeSlice = createSlice({
    name: "legalIde",
    initialState: initialState,
    reducers: {
        onAnswer: (state, action: PayloadAction<{ name: string; value: any }>) => {
            if (!state.simulator.activeDraft?.legalUnit?.testContract) return;
            state.simulator.activeDraft.legalUnit.testContract[action.payload.name] = action.payload.value;
        },
        toggleKBs: (state) => {
            state.simulator.showKBs = !state.simulator.showKBs;
        },
    },
});
