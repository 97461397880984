/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EAdvisoryEquityType {
  Advisory_equityType_NA = 0,
  Advisory_equityType_SHARES = 1,
  Advisory_equityType_STOCK_OPTIONS = 2,
  UNRECOGNIZED = -1,
}

export function eAdvisoryEquityTypeFromJSON(object: any): EAdvisoryEquityType {
  switch (object) {
    case 0:
    case "Advisory_equityType_NA":
      return EAdvisoryEquityType.Advisory_equityType_NA;
    case 1:
    case "Advisory_equityType_SHARES":
      return EAdvisoryEquityType.Advisory_equityType_SHARES;
    case 2:
    case "Advisory_equityType_STOCK_OPTIONS":
      return EAdvisoryEquityType.Advisory_equityType_STOCK_OPTIONS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAdvisoryEquityType.UNRECOGNIZED;
  }
}

export function eAdvisoryEquityTypeToJSON(object: EAdvisoryEquityType): string {
  switch (object) {
    case EAdvisoryEquityType.Advisory_equityType_NA:
      return "Advisory_equityType_NA";
    case EAdvisoryEquityType.Advisory_equityType_SHARES:
      return "Advisory_equityType_SHARES";
    case EAdvisoryEquityType.Advisory_equityType_STOCK_OPTIONS:
      return "Advisory_equityType_STOCK_OPTIONS";
    case EAdvisoryEquityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EAdvisoryVestingSchedule {
  Advisory_vestingSchedule_NA = 0,
  Advisory_vestingSchedule_MONTHLY = 1,
  Advisory_vestingSchedule_QUARTERLY = 2,
  Advisory_vestingSchedule_ANNUALLY = 3,
  UNRECOGNIZED = -1,
}

export function eAdvisoryVestingScheduleFromJSON(object: any): EAdvisoryVestingSchedule {
  switch (object) {
    case 0:
    case "Advisory_vestingSchedule_NA":
      return EAdvisoryVestingSchedule.Advisory_vestingSchedule_NA;
    case 1:
    case "Advisory_vestingSchedule_MONTHLY":
      return EAdvisoryVestingSchedule.Advisory_vestingSchedule_MONTHLY;
    case 2:
    case "Advisory_vestingSchedule_QUARTERLY":
      return EAdvisoryVestingSchedule.Advisory_vestingSchedule_QUARTERLY;
    case 3:
    case "Advisory_vestingSchedule_ANNUALLY":
      return EAdvisoryVestingSchedule.Advisory_vestingSchedule_ANNUALLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAdvisoryVestingSchedule.UNRECOGNIZED;
  }
}

export function eAdvisoryVestingScheduleToJSON(object: EAdvisoryVestingSchedule): string {
  switch (object) {
    case EAdvisoryVestingSchedule.Advisory_vestingSchedule_NA:
      return "Advisory_vestingSchedule_NA";
    case EAdvisoryVestingSchedule.Advisory_vestingSchedule_MONTHLY:
      return "Advisory_vestingSchedule_MONTHLY";
    case EAdvisoryVestingSchedule.Advisory_vestingSchedule_QUARTERLY:
      return "Advisory_vestingSchedule_QUARTERLY";
    case EAdvisoryVestingSchedule.Advisory_vestingSchedule_ANNUALLY:
      return "Advisory_vestingSchedule_ANNUALLY";
    case EAdvisoryVestingSchedule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EAdvisoryVestingCliff {
  Advisory_vestingCliff_NA = 0,
  Advisory_vestingCliff_THREE_MONTHS = 1,
  Advisory_vestingCliff_SIX_MONTHS = 2,
  Advisory_vestingCliff_TWELVE_MONTHS = 3,
  UNRECOGNIZED = -1,
}

export function eAdvisoryVestingCliffFromJSON(object: any): EAdvisoryVestingCliff {
  switch (object) {
    case 0:
    case "Advisory_vestingCliff_NA":
      return EAdvisoryVestingCliff.Advisory_vestingCliff_NA;
    case 1:
    case "Advisory_vestingCliff_THREE_MONTHS":
      return EAdvisoryVestingCliff.Advisory_vestingCliff_THREE_MONTHS;
    case 2:
    case "Advisory_vestingCliff_SIX_MONTHS":
      return EAdvisoryVestingCliff.Advisory_vestingCliff_SIX_MONTHS;
    case 3:
    case "Advisory_vestingCliff_TWELVE_MONTHS":
      return EAdvisoryVestingCliff.Advisory_vestingCliff_TWELVE_MONTHS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EAdvisoryVestingCliff.UNRECOGNIZED;
  }
}

export function eAdvisoryVestingCliffToJSON(object: EAdvisoryVestingCliff): string {
  switch (object) {
    case EAdvisoryVestingCliff.Advisory_vestingCliff_NA:
      return "Advisory_vestingCliff_NA";
    case EAdvisoryVestingCliff.Advisory_vestingCliff_THREE_MONTHS:
      return "Advisory_vestingCliff_THREE_MONTHS";
    case EAdvisoryVestingCliff.Advisory_vestingCliff_SIX_MONTHS:
      return "Advisory_vestingCliff_SIX_MONTHS";
    case EAdvisoryVestingCliff.Advisory_vestingCliff_TWELVE_MONTHS:
      return "Advisory_vestingCliff_TWELVE_MONTHS";
    case EAdvisoryVestingCliff.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MAdvisoryEquityType {
  optionDbValue: EAdvisoryEquityType;
  other: string;
}

export interface MAdvisoryVestingSchedule {
  optionDbValue: EAdvisoryVestingSchedule;
  other: string;
}

export interface MAdvisoryVestingCliff {
  optionDbValue: EAdvisoryVestingCliff;
  other: string;
}

export interface Advisory {
  effectiveDate: Date | undefined;
  equityType: MAdvisoryEquityType | undefined;
  vestingDuration: number;
  vestingSchedule: MAdvisoryVestingSchedule | undefined;
  vestingCliff: MAdvisoryVestingCliff | undefined;
  hoursPerWeek: number;
  isVesting: InternalBoolean;
  isCliff: InternalBoolean;
  servicesDescription: string;
  advisoryName: string;
  advisorAddress: string;
  equityStocksAmount: number;
  equitySharesAmount: number;
  addressNeedToBeSet: InternalBoolean;
}

function createBaseMAdvisoryEquityType(): MAdvisoryEquityType {
  return { optionDbValue: 0, other: "" };
}

export const MAdvisoryEquityType = {
  encode(message: MAdvisoryEquityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAdvisoryEquityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAdvisoryEquityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAdvisoryEquityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAdvisoryEquityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAdvisoryEquityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eAdvisoryEquityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAdvisoryEquityType>, I>>(base?: I): MAdvisoryEquityType {
    return MAdvisoryEquityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAdvisoryEquityType>, I>>(object: I): MAdvisoryEquityType {
    const message = createBaseMAdvisoryEquityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMAdvisoryVestingSchedule(): MAdvisoryVestingSchedule {
  return { optionDbValue: 0, other: "" };
}

export const MAdvisoryVestingSchedule = {
  encode(message: MAdvisoryVestingSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAdvisoryVestingSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAdvisoryVestingSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAdvisoryVestingSchedule {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAdvisoryVestingScheduleFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAdvisoryVestingSchedule): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eAdvisoryVestingScheduleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAdvisoryVestingSchedule>, I>>(base?: I): MAdvisoryVestingSchedule {
    return MAdvisoryVestingSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAdvisoryVestingSchedule>, I>>(object: I): MAdvisoryVestingSchedule {
    const message = createBaseMAdvisoryVestingSchedule();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMAdvisoryVestingCliff(): MAdvisoryVestingCliff {
  return { optionDbValue: 0, other: "" };
}

export const MAdvisoryVestingCliff = {
  encode(message: MAdvisoryVestingCliff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MAdvisoryVestingCliff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMAdvisoryVestingCliff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MAdvisoryVestingCliff {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eAdvisoryVestingCliffFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MAdvisoryVestingCliff): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eAdvisoryVestingCliffToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MAdvisoryVestingCliff>, I>>(base?: I): MAdvisoryVestingCliff {
    return MAdvisoryVestingCliff.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MAdvisoryVestingCliff>, I>>(object: I): MAdvisoryVestingCliff {
    const message = createBaseMAdvisoryVestingCliff();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseAdvisory(): Advisory {
  return {
    effectiveDate: undefined,
    equityType: undefined,
    vestingDuration: 0,
    vestingSchedule: undefined,
    vestingCliff: undefined,
    hoursPerWeek: 0,
    isVesting: 0,
    isCliff: 0,
    servicesDescription: "",
    advisoryName: "",
    advisorAddress: "",
    equityStocksAmount: 0,
    equitySharesAmount: 0,
    addressNeedToBeSet: 0,
  };
}

export const Advisory = {
  encode(message: Advisory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.equityType !== undefined) {
      MAdvisoryEquityType.encode(message.equityType, writer.uint32(18).fork()).ldelim();
    }
    if (message.vestingDuration !== 0) {
      writer.uint32(29).float(message.vestingDuration);
    }
    if (message.vestingSchedule !== undefined) {
      MAdvisoryVestingSchedule.encode(message.vestingSchedule, writer.uint32(34).fork()).ldelim();
    }
    if (message.vestingCliff !== undefined) {
      MAdvisoryVestingCliff.encode(message.vestingCliff, writer.uint32(42).fork()).ldelim();
    }
    if (message.hoursPerWeek !== 0) {
      writer.uint32(53).float(message.hoursPerWeek);
    }
    if (message.isVesting !== 0) {
      writer.uint32(56).int32(message.isVesting);
    }
    if (message.isCliff !== 0) {
      writer.uint32(64).int32(message.isCliff);
    }
    if (message.servicesDescription !== "") {
      writer.uint32(74).string(message.servicesDescription);
    }
    if (message.advisoryName !== "") {
      writer.uint32(82).string(message.advisoryName);
    }
    if (message.advisorAddress !== "") {
      writer.uint32(90).string(message.advisorAddress);
    }
    if (message.equityStocksAmount !== 0) {
      writer.uint32(101).float(message.equityStocksAmount);
    }
    if (message.equitySharesAmount !== 0) {
      writer.uint32(109).float(message.equitySharesAmount);
    }
    if (message.addressNeedToBeSet !== 0) {
      writer.uint32(112).int32(message.addressNeedToBeSet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Advisory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvisory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.equityType = MAdvisoryEquityType.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 29) {
            break;
          }

          message.vestingDuration = reader.float();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.vestingSchedule = MAdvisoryVestingSchedule.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.vestingCliff = MAdvisoryVestingCliff.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.hoursPerWeek = reader.float();
          continue;
        case 7:
          if (tag != 56) {
            break;
          }

          message.isVesting = reader.int32() as any;
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.isCliff = reader.int32() as any;
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.servicesDescription = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.advisoryName = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.advisorAddress = reader.string();
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.equityStocksAmount = reader.float();
          continue;
        case 13:
          if (tag != 109) {
            break;
          }

          message.equitySharesAmount = reader.float();
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.addressNeedToBeSet = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Advisory {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      equityType: isSet(object.equityType) ? MAdvisoryEquityType.fromJSON(object.equityType) : undefined,
      vestingDuration: isSet(object.vestingDuration) ? Number(object.vestingDuration) : 0,
      vestingSchedule: isSet(object.vestingSchedule)
        ? MAdvisoryVestingSchedule.fromJSON(object.vestingSchedule)
        : undefined,
      vestingCliff: isSet(object.vestingCliff) ? MAdvisoryVestingCliff.fromJSON(object.vestingCliff) : undefined,
      hoursPerWeek: isSet(object.hoursPerWeek) ? Number(object.hoursPerWeek) : 0,
      isVesting: isSet(object.isVesting) ? internalBooleanFromJSON(object.isVesting) : 0,
      isCliff: isSet(object.isCliff) ? internalBooleanFromJSON(object.isCliff) : 0,
      servicesDescription: isSet(object.servicesDescription) ? String(object.servicesDescription) : "",
      advisoryName: isSet(object.advisoryName) ? String(object.advisoryName) : "",
      advisorAddress: isSet(object.advisorAddress) ? String(object.advisorAddress) : "",
      equityStocksAmount: isSet(object.equityStocksAmount) ? Number(object.equityStocksAmount) : 0,
      equitySharesAmount: isSet(object.equitySharesAmount) ? Number(object.equitySharesAmount) : 0,
      addressNeedToBeSet: isSet(object.addressNeedToBeSet) ? internalBooleanFromJSON(object.addressNeedToBeSet) : 0,
    };
  },

  toJSON(message: Advisory): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.equityType !== undefined &&
      (obj.equityType = message.equityType ? MAdvisoryEquityType.toJSON(message.equityType) : undefined);
    message.vestingDuration !== undefined && (obj.vestingDuration = message.vestingDuration);
    message.vestingSchedule !== undefined && (obj.vestingSchedule = message.vestingSchedule
      ? MAdvisoryVestingSchedule.toJSON(message.vestingSchedule)
      : undefined);
    message.vestingCliff !== undefined &&
      (obj.vestingCliff = message.vestingCliff ? MAdvisoryVestingCliff.toJSON(message.vestingCliff) : undefined);
    message.hoursPerWeek !== undefined && (obj.hoursPerWeek = message.hoursPerWeek);
    message.isVesting !== undefined && (obj.isVesting = internalBooleanToJSON(message.isVesting));
    message.isCliff !== undefined && (obj.isCliff = internalBooleanToJSON(message.isCliff));
    message.servicesDescription !== undefined && (obj.servicesDescription = message.servicesDescription);
    message.advisoryName !== undefined && (obj.advisoryName = message.advisoryName);
    message.advisorAddress !== undefined && (obj.advisorAddress = message.advisorAddress);
    message.equityStocksAmount !== undefined && (obj.equityStocksAmount = message.equityStocksAmount);
    message.equitySharesAmount !== undefined && (obj.equitySharesAmount = message.equitySharesAmount);
    message.addressNeedToBeSet !== undefined &&
      (obj.addressNeedToBeSet = internalBooleanToJSON(message.addressNeedToBeSet));
    return obj;
  },

  create<I extends Exact<DeepPartial<Advisory>, I>>(base?: I): Advisory {
    return Advisory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Advisory>, I>>(object: I): Advisory {
    const message = createBaseAdvisory();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.equityType = (object.equityType !== undefined && object.equityType !== null)
      ? MAdvisoryEquityType.fromPartial(object.equityType)
      : undefined;
    message.vestingDuration = object.vestingDuration ?? 0;
    message.vestingSchedule = (object.vestingSchedule !== undefined && object.vestingSchedule !== null)
      ? MAdvisoryVestingSchedule.fromPartial(object.vestingSchedule)
      : undefined;
    message.vestingCliff = (object.vestingCliff !== undefined && object.vestingCliff !== null)
      ? MAdvisoryVestingCliff.fromPartial(object.vestingCliff)
      : undefined;
    message.hoursPerWeek = object.hoursPerWeek ?? 0;
    message.isVesting = object.isVesting ?? 0;
    message.isCliff = object.isCliff ?? 0;
    message.servicesDescription = object.servicesDescription ?? "";
    message.advisoryName = object.advisoryName ?? "";
    message.advisorAddress = object.advisorAddress ?? "";
    message.equityStocksAmount = object.equityStocksAmount ?? 0;
    message.equitySharesAmount = object.equitySharesAmount ?? 0;
    message.addressNeedToBeSet = object.addressNeedToBeSet ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
