import { Table2, Column, Cell } from "@blueprintjs/table";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/table/lib/css/table.css";
import { MainStore } from "../../store/mainTypes";
import { useDispatch, useSelector } from "react-redux";
import { PDoc, PDocStatus } from "../../proto/PDoc";
import Switch from "react-switch"
import { useHistory } from "react-router";
import { activateDraft, setIsDocPreviewOpen } from "../../store/mainActions";
import { useEffect } from "react";
import { DocsApi } from "../../api-services/DocsApi";
import { CapTableStore } from "../../store/cap-table-store/capTableTypes";
import { initAllSafes, initShouldApplySafes, setShouldApplySafe } from "../../store/cap-table-store/capTableActions";
import { NumberInput } from "./CapTable";
import { isNumber } from "lodash-es";
import { ESAFEFlavor, ESAFEPreOrPost, SAFE } from "proto/contracts/auto-contracts/SAFE";
import { formatDate } from "components/utils/UtilFunctions";

export default function SAFEDataTable() {
  const drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);
  const inboxDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.inboxDocs);
  const sentDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.sentDocs);
  const completedDocs = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.completedDocs);
  const history = useHistory()
  const dispatch = useDispatch()

  const showConversion = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.showConversion);
  const sharesPerSafe = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.sharesPerSafe);
  const allSafes = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.allSafes);

  const allSafesFromMainReducer = getAllSAFEs(drafts, inboxDocs, sentDocs, completedDocs)

  const shouldApplySafes = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.shouldApplySafes);

  useEffect(() => {
    DocsApi.getAllDocs(dispatch);
  }, [])

  useEffect(() => {
    dispatch(initShouldApplySafes(allSafes.length))
  }, [allSafes.length])

  useEffect(() => {

    dispatch(initAllSafes(allSafesFromMainReducer))
  }, [drafts])

  function investorName(rowIndex: number) {
    return < Cell >
      <div className="text-primary underline" onClick={
        () => {
          let doc = allSafes[rowIndex]
          if (doc.docStatus === PDocStatus.DRAFT) {
            history.push("/" + doc.id + "/A/1")
          }
          else {
            history.push(perStatusPath(doc.docStatus))
            dispatch(activateDraft(doc))
            dispatch(setIsDocPreviewOpen(true))
          }
        }
      }>
        {allSafes[rowIndex].legalUnit?.safe?.investorAddress ? allSafes[rowIndex].legalUnit?.safe?.investorName : "n/a"}
      </div>
    </Cell >
  }

  function startDate(rowIndex: number) {
    return < Cell >
      {formatDate(allSafes[rowIndex].legalUnit?.safe?.effectiveDate)}
    </Cell >
  }

  function preOrPost(rowIndex: number) {
    const safe: SAFE | undefined = allSafes[rowIndex].legalUnit?.safe
    const result = safe?.preOrPost?.optionDbValue || 0

    return < Cell>
      <select name="preOrPost" id="preOrPost" className="w-full h-full" value={result} onChange={async e => {
      }}>
        <option value="">Choose Pre/Post:</option>
        <option disabled>---------</option>
        <option value={ESAFEPreOrPost.SAFE_preOrPost_PRE}>Pre</option>
        <option value={ESAFEPreOrPost.SAFE_preOrPost_POST}>Post</option>
      </select>
    </Cell >
  }

  function flavor(rowIndex: number) {
    const result = allSafes[rowIndex].legalUnit?.safe?.flavor?.optionDbValue || 0

    return < Cell>
      <select name="mfn" id="mfn" className="w-full h-full" value={result} onChange={async e => {
      }}>
        <option value="">Choose flavor:</option>
        <option disabled>---------</option>
        <option value={ESAFEFlavor.SAFE_flavor_VAL_CAP}>Cap only</option>
        <option value={ESAFEFlavor.SAFE_flavor_DISCOUNT}>Discount only</option>
        <option value={ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT}>Cap and Discount</option>
        {/* <option value={SafeFlavor.SafeFlavor_MFN_ONLY}>MFN</option> */}
      </select>
    </Cell >
  }

  function cap(rowIndex: number) {
    const flavor = allSafes[rowIndex].legalUnit?.safe?.flavor?.optionDbValue
    const hasCap = flavor == ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT || flavor == ESAFEFlavor.SAFE_flavor_VAL_CAP

    if (!hasCap) {
      return <Cell></Cell>
    }

    return < Cell >
      {"$" + allSafes[rowIndex].legalUnit?.safe?.valCap}
    </Cell >
  }

  function discount(rowIndex: number) {
    const flavor = allSafes[rowIndex].legalUnit?.safe?.flavor?.optionDbValue
    const hasDiscount = flavor == ESAFEFlavor.SAFE_flavor_VAL_CAP_AND_DISCOUNT || flavor == ESAFEFlavor.SAFE_flavor_DISCOUNT

    if (!hasDiscount) {
      return <Cell></Cell>
    }

    if (allSafes[rowIndex].docStatus !== PDocStatus.DRAFT) {
      return < Cell >
        {allSafes[rowIndex].legalUnit?.safe?.valCap + "%"}
      </Cell >
    }

    return < Cell >
      <EditableWithSymbol maxVal={99} rowIndex={rowIndex} suffixSymbol="%" field="discount" />
    </Cell>
  }


  function safeAmount(rowIndex: number) {
    if (allSafes[rowIndex].docStatus !== PDocStatus.DRAFT) {
      return < Cell >
        {"$" + allSafes[rowIndex].legalUnit?.safe?.amount}
      </Cell >
    }

    return < Cell >
      <EditableWithSymbol rowIndex={rowIndex} prefixSymbol="$" field="amount" />
    </Cell>
  }

  function postConversionShares(rowIndex: number) {
    return < Cell >
      {showConversion ? sharesPerSafe[rowIndex]?.toFixed(2) : ""}
    </Cell >
  }

  function shouldApply(rowIndex: number) {
    let should = shouldApplySafes.length > rowIndex ? shouldApplySafes[rowIndex] : false

    return <Cell>
      <div className="flex self-center">
        <Switch className="scale-50" uncheckedIcon={false} checkedIcon={false} checked={should} onChange={(change) => {
          dispatch(setShouldApplySafe(change, rowIndex))
        }}
          offColor="#dddddd" onColor="#65A0FF" color="#fff" onHandleColor="#FFD215" offHandleColor="#FFD215"
        ></Switch>
      </div>
    </Cell>
  }

  function status(rowIndex: number) {
    let docStatus = "Draft"
    switch (allSafes[rowIndex].docStatus) {
      case PDocStatus.INBOX: docStatus = "Awaiting signagure"; break;
      case PDocStatus.SENT: docStatus = "Out for signature"; break;
      case PDocStatus.DRAFT: docStatus = "Draft"; break;
      case PDocStatus.COMPLETED: docStatus = "Completed"; break;
    }

    return <Cell>
      {docStatus}
    </Cell>
  }
  let columnWidths = new Array(showConversion ? 10 : 9).fill(150)
  columnWidths[0] = 100

  return (
    <Table2 columnWidths={columnWidths} defaultRowHeight={35} numRows={allSafes.length} numFrozenColumns={1}>
      {[
        <Column name="Apply?" cellRenderer={shouldApply} />,
        <Column className="bg-primaryVeryLight" name="Post-Conversion Shares" cellRenderer={postConversionShares} />,
        <Column name="Investor name" cellRenderer={investorName} />,
        <Column name="SAFE amount" cellRenderer={safeAmount} />,
        <Column className="text-green" name="Status" cellRenderer={status} />,
        <Column name="Investment date" cellRenderer={startDate} />,
        <Column name="Safe flavor" cellRenderer={flavor} />,
        <Column name="Valuation Cap" cellRenderer={cap} />,
        <Column name="Discount" cellRenderer={discount} />,
        <Column name="Pre or Post" cellRenderer={preOrPost} />,
      ].filter((elem, index) => {
        if ((index != 1) || showConversion) {
          return elem
        }
      })}
    </Table2>
  );
}

function getAllSAFEs(...allDocs: PDoc[][]) {
  let safes: PDoc[] = []

  allDocs.filter(doc => doc).forEach(docs => {
    docs.forEach(doc => {
      if (doc.legalUnit?.progress !== 100) {
        return
      }

      if (doc.legalUnit?.safe) {
        safes.push(doc)
      }
    });
  })

  safes.sort((x, y) => {
    let xParts = x.id.split("/")
    let yParts = y.id.split("/")
    let xId = xParts[xParts.length - 1]
    let yId = yParts[yParts.length - 1]

    return parseInt(xId) - parseInt(yId)
  })

  return safes;
}

function perStatusPath(docStatus: PDocStatus) {
  switch (docStatus) {
    case PDocStatus.INBOX: return "/awaiting-signature"
    case PDocStatus.COMPLETED: return "/completed"
    case PDocStatus.SENT: return "/out-for-signature"
    case PDocStatus.UPLOADED: return "/uploaded"

    default:
      return ""
  }
}

function EditableWithSymbol(props: { rowIndex: number, maxVal?: number, prefixSymbol?: string, suffixSymbol?: string, field: keyof (SAFE) }) {
  const dispatch = useDispatch()
  const allSafes = useSelector((state: { capTableReducer: CapTableStore }) => state.capTableReducer.allSafes);
  const theSafe = allSafes[props.rowIndex].legalUnit?.safe

  if (!theSafe) {
    return null
  }

  const val = theSafe[props.field]
  let valNum = isNumber(val) ? val : 0

  return <NumberInput maxVal={props.maxVal} onChangeValue={async (val: number) => {
    // await dispatch(activateDraft(allSafes[props.rowIndex]))
    // const updateRes = updateActiveContractField(
    //   new RQuestionField("", props.field, "", "", ""),
    //   allSafes[props.rowIndex]?.legalUnit!,
    //   val
    // )

    // dispatch(updateRes);
  }}
    onBlur={() => {
      // let loc: any = {}
      // loc.pathname = "/" + allSafes[props.rowIndex].id + "/A/1"
      // callToSaveDraft(loc, allSafes[props.rowIndex].legalUnit)
    }}

    prefixSymbol={props.prefixSymbol} suffixSymbol={props.suffixSymbol} className="mt-1 text-xs" value={valNum} />
}
