export const MULTI_SELECTION_OPERATORS = {
    ALL: "all",
    SOME: "some",
    NOT_ALL: "not all",
    NOT_SOME: "not some",
    IS_EMPTY: "is empty",
    NOT_EMPTY: "not empty",
};

export const MULTI_SELECTION_OPERATORS_ARR = Object.values(MULTI_SELECTION_OPERATORS);
export const MULTI_SELECTION_OPERATORS_REGEX = MULTI_SELECTION_OPERATORS_ARR.join("|");

export const SPACES = "\\s*";
export const FIELD_GROUP = "([A-Za-z]+)";
export const OPERATOR_GROUP = `(==|!=|${MULTI_SELECTION_OPERATORS_REGEX})`;
export const VALUE_GROUP = `(true|false|[A-Z][A-Z0-9_,\\s*]*)`;

export const CONDITION = `${SPACES}${FIELD_GROUP}${SPACES}${OPERATOR_GROUP}${SPACES}${VALUE_GROUP}${SPACES}`;
export const CONDITION_REGEX = `^(?<conOne>${CONDITION})((?<opt>AND|OR)(?<conTwo>${CONDITION}))?$`;

export const TAG_NAMES = ["if", "kb", "ol"] as const;
