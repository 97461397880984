export const arrayUtils = {
    group<T>(arr: T[], callback: (item: T) => string) {
        const result: { [key: string]: T[] } = {};
        arr.forEach((item) => {
            const key = callback(item);
            if (result[key]) {
                result[key].push(item);
            } else {
                result[key] = [item];
            }
        });
        return result;
    },
};
