import React, { useEffect, useState } from 'react'
import { getAllTracks } from '../../api-services/TrackApi'
import { PGetAllTracks } from '../../proto/PApi'
import { pLegalTopicsFromJSON, PLegalTopicsSurvey, PNumericStats } from '../../proto/PTracking';
import { tempTranslate } from './LegalTopicsSurvey';
import "./InternalManagement.scss"

let count: number = 0;
while (pLegalTopicsFromJSON(count) !== pLegalTopicsFromJSON(-1)) {
    count++
}
const topics = Array(count).fill("")

for (let i = 0; i < count; i++) {
    topics[i] = tempTranslate(i)
}

export default function InternalManagementPanel() {
    const [tracks, settracks] = useState<PLegalTopicsSurvey[]>([])
    const [votes, setvotes] = useState<number[]>([])
    const [totalVotes, settotalVotes] = useState(0)
    const [numbericStats, setNumericStats] = useState<PNumericStats | undefined>(undefined)
    const [usersBoughtList, setUsersBoughtList] = useState<string[]>([])

    const localVotess = Array(count).fill(0)
    let localTotalVotes = 0;

    useEffect(() => {
        getAllTracks((resp: PGetAllTracks) => {
            settracks(resp.tracks)
            setNumericStats(resp.numericStats)
            setUsersBoughtList(resp.usersBoughtList)

            resp.tracks.forEach(track => {
                track.legalTopics.forEach(val => {
                    localVotess[val]++
                    localTotalVotes++;
                })
            })

            setvotes(localVotess)
            settotalVotes(localTotalVotes)
        })
        return () => {
        }
    }, [])

    return (
        <div className="flex flex-col items-center">
            <p className="text-xl mb-4 mt-4 font-bold underline">Legal topics statistical summary:</p>
            {topics.map((topic, index) =>
                <p className="text-md">{topic + ": " + votes[index] + " out of " + tracks.length + ` (${(votes[index] * 100 / tracks.length).toFixed(2)}%)`}</p>)}

            <br />
            <p className="text-xl font-bold">Details:</p>
            <table className="tracks">
                <tr>
                    <th>Email</th>
                    <th>Purchased?</th>
                    {topics.map(topic => <th>{topic}</th>)}
                    <th>Other</th>
                </tr>
                {tracks.sort(
                   (a, b) => {
                        if (usersBoughtList.includes(a.email) && !usersBoughtList.includes(b.email)) {
                            return -1
                        } else if (!usersBoughtList.includes(a.email) && usersBoughtList.includes(b.email)) {
                            return 1
                        } else {
                            return 0
                        }
                    }
                ).map(track => {
                    return <tr>
                        <td>{track.email}</td>
                        <td>{usersBoughtList.includes(track.email) ? "✔" : ""}</td>
                        {topics.map((topic, index) => <td>{track.legalTopics.includes(index) ? "✔" : ""}</td>)}
                        <td>{track.other}</td>
                    </tr>
                })}
            </table>
        </div>
    )
}
