import "./style.css";

export const SvgRadarScan = ({ paused = false }: { paused?: boolean }) => {
    const state = paused ? "puased" : "";
    return (
        <svg
            width="184px"
            height="180px"
            viewBox="0 0 184 180"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <circle id="path-1" cx="78.278641" cy="65.9989523" r="65.9302051"></circle>
                <filter
                    x="-16.3%"
                    y="-15.5%"
                    width="132.6%"
                    height="132.6%"
                    filterUnits="objectBoundingBox"
                    id="filter-2"
                >
                    <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="7" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix
                        values="0 0 0 0 0.0862745098   0 0 0 0 0.137254902   0 0 0 0 0.443137255  0 0 0 0.05 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                    ></feColorMatrix>
                </filter>
                <radialGradient
                    cx="50%"
                    cy="10.5992166%"
                    fx="50%"
                    fy="10.5992166%"
                    r="110.727395%"
                    gradientTransform="translate(0.500000,0.105992),scale(0.997077,1.000000),rotate(53.842225),translate(-0.500000,-0.105992)"
                    id="radialGradient-3"
                >
                    <stop stop-color="#FCCB11" offset="0%"></stop>
                    <stop stop-color="#FCCB11" stop-opacity="0" offset="100%"></stop>
                </radialGradient>
            </defs>
            <g id="WF-SaaS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Clawdia_privacy-policy-scaner" transform="translate(-1009.000000, -240.000000)">
                    <g id="Group-12" transform="translate(1011.327910, 253.519288)">
                        <path
                            d="M145.742972,8.33622004 C175.007144,14.5643702 191.269588,57.9127692 175.444416,99.7008518 C159.619244,141.488934 117.458154,177.092304 63.4924411,162.778991 C9.52672852,148.465678 -5.82273344,106.520136 1.85199754,78.1054973 C9.52672852,49.6908582 30.4575891,33.4793699 49.4084091,33.4793699 C51.4107637,33.4793699 53.4204615,33.5595172 55.4375025,33.719812 L56.4466258,33.8067376 C75.9188885,35.6096516 95.3493439,29.9516737 110.809611,17.9767138 C122.334919,9.04542262 133.980028,5.83277028 145.742972,8.33622004 Z"
                            id="Path-71"
                            fill="#ECF1FC"
                            fillRule="nonzero"
                        ></path>
                        <g id="Oval" fillRule="nonzero">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                            <use fill="#FFFFFF" xlinkHref="#path-1"></use>
                        </g>
                        <circle
                            id="Oval"
                            fill="#A3BAFF"
                            fillRule="nonzero"
                            cx="78.278641"
                            cy="66.7015911"
                            r="50.7155424"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#D5E0FF"
                            fillRule="nonzero"
                            cx="78.1317408"
                            cy="66.7015911"
                            r="38.0366568"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#A3BAFF"
                            fillRule="nonzero"
                            cx="78.1317408"
                            cy="66.7015911"
                            r="25.3577712"
                        ></circle>
                        <circle
                            id="Oval"
                            fill="#111C5B"
                            fillRule="nonzero"
                            cx="78.1317408"
                            cy="66.7015911"
                            r="12.6788856"
                        ></circle>
                        <line
                            x1="78.278641"
                            y1="0.63394428"
                            x2="78.278641"
                            y2="132.494354"
                            id="Line"
                            stroke="#000000"
                            strokeLinecap="square"
                        ></line>
                        <line
                            x1="144.208846"
                            y1="66.5641494"
                            x2="12.3484359"
                            y2="66.5641494"
                            id="Line-Copy"
                            stroke="#000000"
                            strokeLinecap="square"
                        ></line>
                        <path
                            className={`${state} scan`}
                            d="M78.1317408,117.417134 L78.2800032,66.9570738 C59.6611433,66.9570738 42.7555084,66.9208796 27.5630986,66.8484913 C27.5630986,94.7767812 50.2034509,117.417134 78.1317408,117.417134 Z"
                            id="Oval"
                            fill="url(#radialGradient-3)"
                            fillRule="nonzero"
                        ></path>
                        <circle
                            className={`${state} p2`}
                            id="Oval"
                            fill="#2D5FFB"
                            fillRule="nonzero"
                            cx="113.107977"
                            cy="50.9622849"
                            r="3.49762361"
                        ></circle>
                        <circle
                            className={`${state} p1`}
                            id="Oval"
                            fill="#2D5FFB"
                            fillRule="nonzero"
                            cx="54.6436228"
                            cy="36.5892447"
                            r="7.92431034"
                        ></circle>
                        <circle
                            className={`${state} p3`}
                            id="Oval"
                            fill="#2D5FFB"
                            fillRule="nonzero"
                            cx="103.489512"
                            cy="95.5569859"
                            r="3.49762361"
                        ></circle>
                    </g>
                </g>
            </g>
        </svg>
    );
};
