import * as PopoverPrimitive from "@radix-ui/react-popover";
import { SvgIconClose } from "../Icons";
import { PopoverTriggerProps, PopperContentProps, PopoverProps } from "@radix-ui/react-popover";
import { ReactNode } from "react";

interface Props {
    children: React.ReactNode; // content
    element: React.ReactNode; // trigger
    side?: PopperContentProps["side"];
    sideOffset?: PopperContentProps["sideOffset"];
    align?: PopperContentProps["align"];
    closable?: boolean;
    open?: PopoverProps["open"];
}
export const Popover = (props: Props) => {
    const { children, element, side = "bottom", align = "center", closable = false,sideOffset} = props;

    const alignOffsetDirection = align === "end" ? -1 : 1;
    return (
        <PopoverPrimitive.Root open={!children ? false : undefined}>
            <PopoverPrimitive.Trigger className="outline-none">{element}</PopoverPrimitive.Trigger>
            {/* <PopoverPrimitive.Portal className="overflow-auto"> */}
            {!!children && (
                <PopoverPrimitive.Content
                    alignOffset={20 * alignOffsetDirection}
                    align={align}
                    side={side}
                    asChild
                    sideOffset={sideOffset}
                >
                    <>
                        {children}
                        <PopoverPrimitive.Arrow height={5} width={10} fill="white" />
                        {closable && (
                            <PopoverPrimitive.Close className="absolute text-black top-2 right-2" aria-label="Close">
                                <div
                                    className={`shadow-md cursor-pointer w-9 h-9  border rounded-lg border-skyBlue flex items-center justify-center text-darkIndigo text-lg font-medium`}
                                >
                                    &times;
                                </div>
                            </PopoverPrimitive.Close>
                        )}
                    </>
                </PopoverPrimitive.Content>
            )}
            {/* </PopoverPrimitive.Portal> */}
        </PopoverPrimitive.Root>
    );
};
