/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile } from "../PUtils";

export const protobufPackage = "";

export enum EDemandLetterPaymentDetails {
  DemandLetter_paymentDetails_NA = 0,
  DemandLetter_paymentDetails_WIRE = 1,
  DemandLetter_paymentDetails_CHECK = 2,
  DemandLetter_paymentDetails_CREDIT = 3,
  UNRECOGNIZED = -1,
}

export function eDemandLetterPaymentDetailsFromJSON(object: any): EDemandLetterPaymentDetails {
  switch (object) {
    case 0:
    case "DemandLetter_paymentDetails_NA":
      return EDemandLetterPaymentDetails.DemandLetter_paymentDetails_NA;
    case 1:
    case "DemandLetter_paymentDetails_WIRE":
      return EDemandLetterPaymentDetails.DemandLetter_paymentDetails_WIRE;
    case 2:
    case "DemandLetter_paymentDetails_CHECK":
      return EDemandLetterPaymentDetails.DemandLetter_paymentDetails_CHECK;
    case 3:
    case "DemandLetter_paymentDetails_CREDIT":
      return EDemandLetterPaymentDetails.DemandLetter_paymentDetails_CREDIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EDemandLetterPaymentDetails.UNRECOGNIZED;
  }
}

export function eDemandLetterPaymentDetailsToJSON(object: EDemandLetterPaymentDetails): string {
  switch (object) {
    case EDemandLetterPaymentDetails.DemandLetter_paymentDetails_NA:
      return "DemandLetter_paymentDetails_NA";
    case EDemandLetterPaymentDetails.DemandLetter_paymentDetails_WIRE:
      return "DemandLetter_paymentDetails_WIRE";
    case EDemandLetterPaymentDetails.DemandLetter_paymentDetails_CHECK:
      return "DemandLetter_paymentDetails_CHECK";
    case EDemandLetterPaymentDetails.DemandLetter_paymentDetails_CREDIT:
      return "DemandLetter_paymentDetails_CREDIT";
    case EDemandLetterPaymentDetails.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EDemandLetterBreachChoice {
  DemandLetter_breachChoice_NA = 0,
  DemandLetter_breachChoice_NONE = 1,
  DemandLetter_breachChoice_SUSPEND = 2,
  DemandLetter_breachChoice_NOTICE = 3,
  DemandLetter_breachChoice_TERMINATE = 4,
  UNRECOGNIZED = -1,
}

export function eDemandLetterBreachChoiceFromJSON(object: any): EDemandLetterBreachChoice {
  switch (object) {
    case 0:
    case "DemandLetter_breachChoice_NA":
      return EDemandLetterBreachChoice.DemandLetter_breachChoice_NA;
    case 1:
    case "DemandLetter_breachChoice_NONE":
      return EDemandLetterBreachChoice.DemandLetter_breachChoice_NONE;
    case 2:
    case "DemandLetter_breachChoice_SUSPEND":
      return EDemandLetterBreachChoice.DemandLetter_breachChoice_SUSPEND;
    case 3:
    case "DemandLetter_breachChoice_NOTICE":
      return EDemandLetterBreachChoice.DemandLetter_breachChoice_NOTICE;
    case 4:
    case "DemandLetter_breachChoice_TERMINATE":
      return EDemandLetterBreachChoice.DemandLetter_breachChoice_TERMINATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EDemandLetterBreachChoice.UNRECOGNIZED;
  }
}

export function eDemandLetterBreachChoiceToJSON(object: EDemandLetterBreachChoice): string {
  switch (object) {
    case EDemandLetterBreachChoice.DemandLetter_breachChoice_NA:
      return "DemandLetter_breachChoice_NA";
    case EDemandLetterBreachChoice.DemandLetter_breachChoice_NONE:
      return "DemandLetter_breachChoice_NONE";
    case EDemandLetterBreachChoice.DemandLetter_breachChoice_SUSPEND:
      return "DemandLetter_breachChoice_SUSPEND";
    case EDemandLetterBreachChoice.DemandLetter_breachChoice_NOTICE:
      return "DemandLetter_breachChoice_NOTICE";
    case EDemandLetterBreachChoice.DemandLetter_breachChoice_TERMINATE:
      return "DemandLetter_breachChoice_TERMINATE";
    case EDemandLetterBreachChoice.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EDemandLetterCreditContact {
  DemandLetter_creditContact_NA = 0,
  DemandLetter_creditContact_EMAIL = 1,
  DemandLetter_creditContact_PHONE = 2,
  UNRECOGNIZED = -1,
}

export function eDemandLetterCreditContactFromJSON(object: any): EDemandLetterCreditContact {
  switch (object) {
    case 0:
    case "DemandLetter_creditContact_NA":
      return EDemandLetterCreditContact.DemandLetter_creditContact_NA;
    case 1:
    case "DemandLetter_creditContact_EMAIL":
      return EDemandLetterCreditContact.DemandLetter_creditContact_EMAIL;
    case 2:
    case "DemandLetter_creditContact_PHONE":
      return EDemandLetterCreditContact.DemandLetter_creditContact_PHONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EDemandLetterCreditContact.UNRECOGNIZED;
  }
}

export function eDemandLetterCreditContactToJSON(object: EDemandLetterCreditContact): string {
  switch (object) {
    case EDemandLetterCreditContact.DemandLetter_creditContact_NA:
      return "DemandLetter_creditContact_NA";
    case EDemandLetterCreditContact.DemandLetter_creditContact_EMAIL:
      return "DemandLetter_creditContact_EMAIL";
    case EDemandLetterCreditContact.DemandLetter_creditContact_PHONE:
      return "DemandLetter_creditContact_PHONE";
    case EDemandLetterCreditContact.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MDemandLetterPaymentDetails {
  optionDbValue: EDemandLetterPaymentDetails[];
  other: string;
}

export interface MDemandLetterBreachChoice {
  optionDbValue: EDemandLetterBreachChoice;
  other: string;
}

export interface MDemandLetterCreditContact {
  optionDbValue: EDemandLetterCreditContact[];
  other: string;
}

export interface DemandLetter {
  dateLetter: Date | undefined;
  debtorCompanyFull: string;
  debtorAddressOther: string;
  debtorAddress: string;
  debtorName: string;
  underlyingAgreement: string;
  debtAmount: number;
  invoiceNumber: string;
  invoiceDueDate: Date | undefined;
  paymentDetails: MDemandLetterPaymentDetails | undefined;
  noticeDays: number;
  letterName: string;
  letterTitle: string;
  invoice: InternalBoolean;
  breachChoice: MDemandLetterBreachChoice | undefined;
  wireBankName: string;
  wireAccountName: string;
  wireAccountNumber: string;
  wireRoutingSWIFT: string;
  checkPayable: string;
  paymentAddressOther: string;
  paymentAddress: string;
  creditContact: MDemandLetterCreditContact | undefined;
  paymentEmail: string;
  paymentPhone: string;
  attachAgreement: InternalBoolean;
  attachAgreementCopy: PFile | undefined;
  attachInvoice: InternalBoolean;
  attachInvoiceCopy: PFile | undefined;
  debtorEmail: string;
}

function createBaseMDemandLetterPaymentDetails(): MDemandLetterPaymentDetails {
  return { optionDbValue: [], other: "" };
}

export const MDemandLetterPaymentDetails = {
  encode(message: MDemandLetterPaymentDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MDemandLetterPaymentDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMDemandLetterPaymentDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MDemandLetterPaymentDetails {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => eDemandLetterPaymentDetailsFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MDemandLetterPaymentDetails): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => eDemandLetterPaymentDetailsToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MDemandLetterPaymentDetails>, I>>(base?: I): MDemandLetterPaymentDetails {
    return MDemandLetterPaymentDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MDemandLetterPaymentDetails>, I>>(object: I): MDemandLetterPaymentDetails {
    const message = createBaseMDemandLetterPaymentDetails();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMDemandLetterBreachChoice(): MDemandLetterBreachChoice {
  return { optionDbValue: 0, other: "" };
}

export const MDemandLetterBreachChoice = {
  encode(message: MDemandLetterBreachChoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MDemandLetterBreachChoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMDemandLetterBreachChoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MDemandLetterBreachChoice {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eDemandLetterBreachChoiceFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MDemandLetterBreachChoice): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eDemandLetterBreachChoiceToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MDemandLetterBreachChoice>, I>>(base?: I): MDemandLetterBreachChoice {
    return MDemandLetterBreachChoice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MDemandLetterBreachChoice>, I>>(object: I): MDemandLetterBreachChoice {
    const message = createBaseMDemandLetterBreachChoice();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMDemandLetterCreditContact(): MDemandLetterCreditContact {
  return { optionDbValue: [], other: "" };
}

export const MDemandLetterCreditContact = {
  encode(message: MDemandLetterCreditContact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.optionDbValue) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MDemandLetterCreditContact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMDemandLetterCreditContact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 8) {
            message.optionDbValue.push(reader.int32() as any);
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.optionDbValue.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MDemandLetterCreditContact {
    return {
      optionDbValue: Array.isArray(object?.optionDbValue)
        ? object.optionDbValue.map((e: any) => eDemandLetterCreditContactFromJSON(e))
        : [],
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MDemandLetterCreditContact): unknown {
    const obj: any = {};
    if (message.optionDbValue) {
      obj.optionDbValue = message.optionDbValue.map((e) => eDemandLetterCreditContactToJSON(e));
    } else {
      obj.optionDbValue = [];
    }
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MDemandLetterCreditContact>, I>>(base?: I): MDemandLetterCreditContact {
    return MDemandLetterCreditContact.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MDemandLetterCreditContact>, I>>(object: I): MDemandLetterCreditContact {
    const message = createBaseMDemandLetterCreditContact();
    message.optionDbValue = object.optionDbValue?.map((e) => e) || [];
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseDemandLetter(): DemandLetter {
  return {
    dateLetter: undefined,
    debtorCompanyFull: "",
    debtorAddressOther: "",
    debtorAddress: "",
    debtorName: "",
    underlyingAgreement: "",
    debtAmount: 0,
    invoiceNumber: "",
    invoiceDueDate: undefined,
    paymentDetails: undefined,
    noticeDays: 0,
    letterName: "",
    letterTitle: "",
    invoice: 0,
    breachChoice: undefined,
    wireBankName: "",
    wireAccountName: "",
    wireAccountNumber: "",
    wireRoutingSWIFT: "",
    checkPayable: "",
    paymentAddressOther: "",
    paymentAddress: "",
    creditContact: undefined,
    paymentEmail: "",
    paymentPhone: "",
    attachAgreement: 0,
    attachAgreementCopy: undefined,
    attachInvoice: 0,
    attachInvoiceCopy: undefined,
    debtorEmail: "",
  };
}

export const DemandLetter = {
  encode(message: DemandLetter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateLetter !== undefined) {
      Timestamp.encode(toTimestamp(message.dateLetter), writer.uint32(10).fork()).ldelim();
    }
    if (message.debtorCompanyFull !== "") {
      writer.uint32(18).string(message.debtorCompanyFull);
    }
    if (message.debtorAddressOther !== "") {
      writer.uint32(26).string(message.debtorAddressOther);
    }
    if (message.debtorAddress !== "") {
      writer.uint32(34).string(message.debtorAddress);
    }
    if (message.debtorName !== "") {
      writer.uint32(42).string(message.debtorName);
    }
    if (message.underlyingAgreement !== "") {
      writer.uint32(50).string(message.underlyingAgreement);
    }
    if (message.debtAmount !== 0) {
      writer.uint32(61).float(message.debtAmount);
    }
    if (message.invoiceNumber !== "") {
      writer.uint32(66).string(message.invoiceNumber);
    }
    if (message.invoiceDueDate !== undefined) {
      Timestamp.encode(toTimestamp(message.invoiceDueDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.paymentDetails !== undefined) {
      MDemandLetterPaymentDetails.encode(message.paymentDetails, writer.uint32(82).fork()).ldelim();
    }
    if (message.noticeDays !== 0) {
      writer.uint32(93).float(message.noticeDays);
    }
    if (message.letterName !== "") {
      writer.uint32(98).string(message.letterName);
    }
    if (message.letterTitle !== "") {
      writer.uint32(106).string(message.letterTitle);
    }
    if (message.invoice !== 0) {
      writer.uint32(112).int32(message.invoice);
    }
    if (message.breachChoice !== undefined) {
      MDemandLetterBreachChoice.encode(message.breachChoice, writer.uint32(122).fork()).ldelim();
    }
    if (message.wireBankName !== "") {
      writer.uint32(130).string(message.wireBankName);
    }
    if (message.wireAccountName !== "") {
      writer.uint32(138).string(message.wireAccountName);
    }
    if (message.wireAccountNumber !== "") {
      writer.uint32(146).string(message.wireAccountNumber);
    }
    if (message.wireRoutingSWIFT !== "") {
      writer.uint32(154).string(message.wireRoutingSWIFT);
    }
    if (message.checkPayable !== "") {
      writer.uint32(162).string(message.checkPayable);
    }
    if (message.paymentAddressOther !== "") {
      writer.uint32(170).string(message.paymentAddressOther);
    }
    if (message.paymentAddress !== "") {
      writer.uint32(178).string(message.paymentAddress);
    }
    if (message.creditContact !== undefined) {
      MDemandLetterCreditContact.encode(message.creditContact, writer.uint32(186).fork()).ldelim();
    }
    if (message.paymentEmail !== "") {
      writer.uint32(194).string(message.paymentEmail);
    }
    if (message.paymentPhone !== "") {
      writer.uint32(202).string(message.paymentPhone);
    }
    if (message.attachAgreement !== 0) {
      writer.uint32(208).int32(message.attachAgreement);
    }
    if (message.attachAgreementCopy !== undefined) {
      PFile.encode(message.attachAgreementCopy, writer.uint32(218).fork()).ldelim();
    }
    if (message.attachInvoice !== 0) {
      writer.uint32(224).int32(message.attachInvoice);
    }
    if (message.attachInvoiceCopy !== undefined) {
      PFile.encode(message.attachInvoiceCopy, writer.uint32(234).fork()).ldelim();
    }
    if (message.debtorEmail !== "") {
      writer.uint32(242).string(message.debtorEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DemandLetter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDemandLetter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.dateLetter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.debtorCompanyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.debtorAddressOther = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.debtorAddress = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.debtorName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.underlyingAgreement = reader.string();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.debtAmount = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.invoiceNumber = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.invoiceDueDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.paymentDetails = MDemandLetterPaymentDetails.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 93) {
            break;
          }

          message.noticeDays = reader.float();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.letterName = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.letterTitle = reader.string();
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.invoice = reader.int32() as any;
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.breachChoice = MDemandLetterBreachChoice.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.wireBankName = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.wireAccountName = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.wireAccountNumber = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.wireRoutingSWIFT = reader.string();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.checkPayable = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.paymentAddressOther = reader.string();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.paymentAddress = reader.string();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.creditContact = MDemandLetterCreditContact.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.paymentEmail = reader.string();
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.paymentPhone = reader.string();
          continue;
        case 26:
          if (tag != 208) {
            break;
          }

          message.attachAgreement = reader.int32() as any;
          continue;
        case 27:
          if (tag != 218) {
            break;
          }

          message.attachAgreementCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag != 224) {
            break;
          }

          message.attachInvoice = reader.int32() as any;
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.attachInvoiceCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 242) {
            break;
          }

          message.debtorEmail = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DemandLetter {
    return {
      dateLetter: isSet(object.dateLetter) ? fromJsonTimestamp(object.dateLetter) : undefined,
      debtorCompanyFull: isSet(object.debtorCompanyFull) ? String(object.debtorCompanyFull) : "",
      debtorAddressOther: isSet(object.debtorAddressOther) ? String(object.debtorAddressOther) : "",
      debtorAddress: isSet(object.debtorAddress) ? String(object.debtorAddress) : "",
      debtorName: isSet(object.debtorName) ? String(object.debtorName) : "",
      underlyingAgreement: isSet(object.underlyingAgreement) ? String(object.underlyingAgreement) : "",
      debtAmount: isSet(object.debtAmount) ? Number(object.debtAmount) : 0,
      invoiceNumber: isSet(object.invoiceNumber) ? String(object.invoiceNumber) : "",
      invoiceDueDate: isSet(object.invoiceDueDate) ? fromJsonTimestamp(object.invoiceDueDate) : undefined,
      paymentDetails: isSet(object.paymentDetails)
        ? MDemandLetterPaymentDetails.fromJSON(object.paymentDetails)
        : undefined,
      noticeDays: isSet(object.noticeDays) ? Number(object.noticeDays) : 0,
      letterName: isSet(object.letterName) ? String(object.letterName) : "",
      letterTitle: isSet(object.letterTitle) ? String(object.letterTitle) : "",
      invoice: isSet(object.invoice) ? internalBooleanFromJSON(object.invoice) : 0,
      breachChoice: isSet(object.breachChoice) ? MDemandLetterBreachChoice.fromJSON(object.breachChoice) : undefined,
      wireBankName: isSet(object.wireBankName) ? String(object.wireBankName) : "",
      wireAccountName: isSet(object.wireAccountName) ? String(object.wireAccountName) : "",
      wireAccountNumber: isSet(object.wireAccountNumber) ? String(object.wireAccountNumber) : "",
      wireRoutingSWIFT: isSet(object.wireRoutingSWIFT) ? String(object.wireRoutingSWIFT) : "",
      checkPayable: isSet(object.checkPayable) ? String(object.checkPayable) : "",
      paymentAddressOther: isSet(object.paymentAddressOther) ? String(object.paymentAddressOther) : "",
      paymentAddress: isSet(object.paymentAddress) ? String(object.paymentAddress) : "",
      creditContact: isSet(object.creditContact)
        ? MDemandLetterCreditContact.fromJSON(object.creditContact)
        : undefined,
      paymentEmail: isSet(object.paymentEmail) ? String(object.paymentEmail) : "",
      paymentPhone: isSet(object.paymentPhone) ? String(object.paymentPhone) : "",
      attachAgreement: isSet(object.attachAgreement) ? internalBooleanFromJSON(object.attachAgreement) : 0,
      attachAgreementCopy: isSet(object.attachAgreementCopy) ? PFile.fromJSON(object.attachAgreementCopy) : undefined,
      attachInvoice: isSet(object.attachInvoice) ? internalBooleanFromJSON(object.attachInvoice) : 0,
      attachInvoiceCopy: isSet(object.attachInvoiceCopy) ? PFile.fromJSON(object.attachInvoiceCopy) : undefined,
      debtorEmail: isSet(object.debtorEmail) ? String(object.debtorEmail) : "",
    };
  },

  toJSON(message: DemandLetter): unknown {
    const obj: any = {};
    message.dateLetter !== undefined && (obj.dateLetter = message.dateLetter.toISOString());
    message.debtorCompanyFull !== undefined && (obj.debtorCompanyFull = message.debtorCompanyFull);
    message.debtorAddressOther !== undefined && (obj.debtorAddressOther = message.debtorAddressOther);
    message.debtorAddress !== undefined && (obj.debtorAddress = message.debtorAddress);
    message.debtorName !== undefined && (obj.debtorName = message.debtorName);
    message.underlyingAgreement !== undefined && (obj.underlyingAgreement = message.underlyingAgreement);
    message.debtAmount !== undefined && (obj.debtAmount = message.debtAmount);
    message.invoiceNumber !== undefined && (obj.invoiceNumber = message.invoiceNumber);
    message.invoiceDueDate !== undefined && (obj.invoiceDueDate = message.invoiceDueDate.toISOString());
    message.paymentDetails !== undefined && (obj.paymentDetails = message.paymentDetails
      ? MDemandLetterPaymentDetails.toJSON(message.paymentDetails)
      : undefined);
    message.noticeDays !== undefined && (obj.noticeDays = message.noticeDays);
    message.letterName !== undefined && (obj.letterName = message.letterName);
    message.letterTitle !== undefined && (obj.letterTitle = message.letterTitle);
    message.invoice !== undefined && (obj.invoice = internalBooleanToJSON(message.invoice));
    message.breachChoice !== undefined &&
      (obj.breachChoice = message.breachChoice ? MDemandLetterBreachChoice.toJSON(message.breachChoice) : undefined);
    message.wireBankName !== undefined && (obj.wireBankName = message.wireBankName);
    message.wireAccountName !== undefined && (obj.wireAccountName = message.wireAccountName);
    message.wireAccountNumber !== undefined && (obj.wireAccountNumber = message.wireAccountNumber);
    message.wireRoutingSWIFT !== undefined && (obj.wireRoutingSWIFT = message.wireRoutingSWIFT);
    message.checkPayable !== undefined && (obj.checkPayable = message.checkPayable);
    message.paymentAddressOther !== undefined && (obj.paymentAddressOther = message.paymentAddressOther);
    message.paymentAddress !== undefined && (obj.paymentAddress = message.paymentAddress);
    message.creditContact !== undefined &&
      (obj.creditContact = message.creditContact
        ? MDemandLetterCreditContact.toJSON(message.creditContact)
        : undefined);
    message.paymentEmail !== undefined && (obj.paymentEmail = message.paymentEmail);
    message.paymentPhone !== undefined && (obj.paymentPhone = message.paymentPhone);
    message.attachAgreement !== undefined && (obj.attachAgreement = internalBooleanToJSON(message.attachAgreement));
    message.attachAgreementCopy !== undefined &&
      (obj.attachAgreementCopy = message.attachAgreementCopy ? PFile.toJSON(message.attachAgreementCopy) : undefined);
    message.attachInvoice !== undefined && (obj.attachInvoice = internalBooleanToJSON(message.attachInvoice));
    message.attachInvoiceCopy !== undefined &&
      (obj.attachInvoiceCopy = message.attachInvoiceCopy ? PFile.toJSON(message.attachInvoiceCopy) : undefined);
    message.debtorEmail !== undefined && (obj.debtorEmail = message.debtorEmail);
    return obj;
  },

  create<I extends Exact<DeepPartial<DemandLetter>, I>>(base?: I): DemandLetter {
    return DemandLetter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DemandLetter>, I>>(object: I): DemandLetter {
    const message = createBaseDemandLetter();
    message.dateLetter = object.dateLetter ?? undefined;
    message.debtorCompanyFull = object.debtorCompanyFull ?? "";
    message.debtorAddressOther = object.debtorAddressOther ?? "";
    message.debtorAddress = object.debtorAddress ?? "";
    message.debtorName = object.debtorName ?? "";
    message.underlyingAgreement = object.underlyingAgreement ?? "";
    message.debtAmount = object.debtAmount ?? 0;
    message.invoiceNumber = object.invoiceNumber ?? "";
    message.invoiceDueDate = object.invoiceDueDate ?? undefined;
    message.paymentDetails = (object.paymentDetails !== undefined && object.paymentDetails !== null)
      ? MDemandLetterPaymentDetails.fromPartial(object.paymentDetails)
      : undefined;
    message.noticeDays = object.noticeDays ?? 0;
    message.letterName = object.letterName ?? "";
    message.letterTitle = object.letterTitle ?? "";
    message.invoice = object.invoice ?? 0;
    message.breachChoice = (object.breachChoice !== undefined && object.breachChoice !== null)
      ? MDemandLetterBreachChoice.fromPartial(object.breachChoice)
      : undefined;
    message.wireBankName = object.wireBankName ?? "";
    message.wireAccountName = object.wireAccountName ?? "";
    message.wireAccountNumber = object.wireAccountNumber ?? "";
    message.wireRoutingSWIFT = object.wireRoutingSWIFT ?? "";
    message.checkPayable = object.checkPayable ?? "";
    message.paymentAddressOther = object.paymentAddressOther ?? "";
    message.paymentAddress = object.paymentAddress ?? "";
    message.creditContact = (object.creditContact !== undefined && object.creditContact !== null)
      ? MDemandLetterCreditContact.fromPartial(object.creditContact)
      : undefined;
    message.paymentEmail = object.paymentEmail ?? "";
    message.paymentPhone = object.paymentPhone ?? "";
    message.attachAgreement = object.attachAgreement ?? 0;
    message.attachAgreementCopy = (object.attachAgreementCopy !== undefined && object.attachAgreementCopy !== null)
      ? PFile.fromPartial(object.attachAgreementCopy)
      : undefined;
    message.attachInvoice = object.attachInvoice ?? 0;
    message.attachInvoiceCopy = (object.attachInvoiceCopy !== undefined && object.attachInvoiceCopy !== null)
      ? PFile.fromPartial(object.attachInvoiceCopy)
      : undefined;
    message.debtorEmail = object.debtorEmail ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
