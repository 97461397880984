import { Spinner } from 'components';
import { LoadingDots } from 'components/0_common/Loading/LoadingDots';
import firebase from 'firebase';
import React, { useEffect } from 'react'
import { Redirect } from 'react-router';
import { GenericOauthHandlingProvider } from './GenericOauthHandlingProviders';

// This component is used to serve entry point A which creates a url to init the authentication process and redirects to it
export default function GenericOauthHandlingA({provider}: {provider: GenericOauthHandlingProvider}) {
  useEffect(() => {
    if (firebase.auth().currentUser) {
      return
    }

    const codeUrl = provider.getCodeUrl()
    window.location.replace(codeUrl)
  }, [])

  if (firebase.auth().currentUser) {
    return <Redirect to="/" />
  }

  return <div className="flex flex-col items-center justify-center h-screen w-screen">
      <Spinner/>
      <p className='text-2xl'>Clawdia AI will redirect your shortly to {provider.name.toUpperCase()}</p>
    </div>
}
