import React, { FormEvent, useState } from 'react'
import Modal, { ExitButton, ModalFooter } from '../Modal'
import { getRedirectUrl, passCurrentSearchQuery, TextWithCrossingLine } from './AuthUtils'

import GoogleImg from "../../img/icons/google.png"
import SocialSignin, { SocialButton, SocialType } from './SocialSignin'
import "./Auth.scss"
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router'
import { LogoImg } from 'components/1_features/AppBar/Menu'

export default function Login() {
    const authContext = useAuth()
    const history = useHistory()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        try {
            setError("")
            await authContext?.login(email, password)
            history.push(getRedirectUrl())
        } catch (e: any) {
            setError("Failed to login! " +
                (e.message ?? "")
            )
        }
    }

    return (
        <Modal>
            <div className="sm:w-172 h-full flex flex-col">

                <ExitButton onClick={() => {
                    window.location.href = 'https://www.clawdia.ai';
                }} />


                <div className="flex-grow">
                    <div className="mt-3 mx-6 sm:mx-28">
                        <div className={"_ErrorHeight mt-2 px-4 rounded-lg border border-white flex items-center justify-center " + (error ? "border-dangerBorder bg-dangerBg" : "")}>
                            <p className="text-danger text-sm overflow-ellipsis">{error}</p>
                        </div>

                        <div className="mt-2">
                            <LogoImg mode='light' size='large' />
                        </div>

                        <form onSubmit={handleSubmit}>
                            <p className="text-darkIndigo text-3xl font-medium mt-8">
                                <span>Login</span>
                            </p>

                            <p className="text-1_5xl mt-6 text-darkIndigo">Work Email</p>

                            <input required onChange={(e) => setEmail(e.target.value)}
                                type="email" autoFocus className="InputField w-full mt-1 text-base rounded-md border px-4 py-3 border-sky" placeholder="Ex: name@company.com" />

                            <p className="text-1_5xl mt-3 text-darkIndigo">Password:</p>

                            <input required onChange={(e) => setPassword(e.target.value)}
                                type="password" className="InputField w-full mt-1 text-base rounded-md border px-4 py-3 border-paleLilac" placeholder="Ex: dontforgetme$798213" />

                            <input type="submit" className="mb-4 mt-6 h-14 bg-primary text-white text-xl w-full rounded-lg hover:bg-primaryHover cursor-pointer" value="Continue" />

                            <TextWithCrossingLine text="Or login with" />

                            <SocialButtons setError={setError} />
                        </form>
                    </div>
                </div>
                <ModalFooter>
                    <div className="h-25 flex flex-col items-center justify-center">
                        <p className="text-lg text-darkIndigo"><a onClick={() => {
                            history.push("/forgot-password" + passCurrentSearchQuery())
                        }}>Forgot your password?</a></p>
                        <p className="text-lg text-darkIndigo">Don't have an account? <a onClick={() => {
                            history.push("/signup" + passCurrentSearchQuery())
                        }}>Sign up</a></p>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

function SocialButtons(props: { setError: Function }) {
    const history = useHistory()

    return <div className="flex justify-between gap-2 w-full">
        <SocialButton text="Login with Google" icon={GoogleImg} onClick={() => SocialSignin(SocialType.GOOGLE, history, props.setError)} />
    </div>
}
