import cx from "clsx";
import { ReactNode } from "react";

type Props = {
    children?: JSX.Element | JSX.Element[] | ReactNode;
    className?: string;
    disabled?: boolean;
    dir?: "ltr" | "rtl";
    lang?: "en" | "he";
};
export const Container = (props: Props) => {
    const { children, className, disabled } = props;

    if (disabled) {
        return <>{children}</>;
    }

    return (
        <div
            dir={props.dir}
            lang={props.lang}
            className={cx(
                `
    2xl:max-w-large
    xl:max-w-standard
    container
    xl:px-0 md:px-0 sm:px-16 px-8
    mx-auto`,
                className
            )}
        >
            {children}
        </div>
    );
};
