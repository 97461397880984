import axios, { AxiosRequestConfig } from "axios";
import { isProd } from "components/utils/EnvResolver";
import firebase from "firebase/app";
import { MIXPANEL_KEY } from "index";
import { isAxiosError, reportErrorToBE } from "utils";

if (isProd()) {
    if (window.location.hostname === "preprod.clawdia.ai" && window.location.port === "9090") {
        axios.defaults.baseURL = "http://preprod.clawdia.ai:9090/";
    } else {
        axios.defaults.baseURL = "https://api.clawdia.ai/";
    }
}

export interface HttpResponse<T = any> {
    success: boolean;
    data: T | null;
    status?: number;
}

export async function request(url: string, options: AxiosRequestConfig): Promise<HttpResponse> {
    try {
        const headers = options.headers || {};
        const idToken = await firebase?.auth()?.currentUser?.getIdToken(false);
        const auth = idToken && { Authorization: `Bearer ${idToken}` };

        const hubspotToken = document.cookie
            .split(";")
            .find((c) => c.trim().startsWith("hubspotutk="));
        const hubspotHeader = hubspotToken && { "X-HubSpot-Token": hubspotToken.split("=")[1] };

        const mixpanelToken = document.cookie
            .split(";")
            .find((c) => c.trim().startsWith("mp_" + MIXPANEL_KEY + "_mixpanel="));
        const mixpanelHeader = mixpanelToken && { "X-Mixpanel-Token": mixpanelToken.split("=")[1] };

        const impersonateAs = localStorage.getItem("kakarot_dragon_ball_z");

        if (impersonateAs) {
            url += (url.includes("?") ? "&" : "?") + `kakarot_dragon_ball_z=${impersonateAs}`;
        }

        const response = await axios({
            method: options.method,
            params: options.params,
            url,
            headers: { ...auth, ...headers, ...hubspotHeader, ...mixpanelHeader },
            data: options.data,
        });

        return { success: true, data: response.data, status: response.status };
    } catch (error) {
        let status = undefined;
        if (isAxiosError(error)) {
            status = error.response?.status;
        }

        let data = error;

        if ((error as any)?.response?.data) {
            data = (error as any).response.data;
        }

        return { success: false, data: data, status };
    }
}

export function retrieveAxios() {
    return axios;
}
