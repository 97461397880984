interface PdfjsLib {
    getDocument: (url: string) => { promise: Promise<any> };
}
declare global {
    var pdfjsLib: PdfjsLib;
}

interface Page {
    render: (config: {}) => { promise: Promise<undefined> };
    getViewport: (config: { scale: number }) => { height: number; width: number };
}
export interface PdfJSDoc {
    numPages: number;
    getPage: (index: number) => Promise<Page>;
}

export const pdfJsApi = {
    async loadPdfFile(url: string): Promise<PdfJSDoc | null> {
        try {
            const task = await pdfjsLib.getDocument(url).promise;
            return await task;
        } catch (error) {
            console.error("pdfJsApi.loadPdfFile", error);
            return null;
        }
    },
};
