import React, { createRef, useEffect, useState, VideoHTMLAttributes } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { backupVideoLastTime, callDialog, playVideo } from '../../store/mainActions';
import { MainStore } from '../../store/mainTypes';
import Modal from '../Modal'
import mixpanel from "mixpanel-browser"
import { isProd } from './EnvResolver';
import VideoStack from 'components/1_features/VideoStack/VideoStack';
import { MultiVideoProps } from 'apps/legal-ide/App/components/QuestionWizard/VideosCollection';

export default function VideoPlayer() {
    const videoUrl = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.videoPlayer);
    const location = useLocation()
    const dispatch = useDispatch()

    if (!videoUrl) {
        return null;
    }

    const isMultiVideoProps = !!((videoUrl as MultiVideoProps).chapters)

    return (
        <Modal closeable={true} closeUrl={location.pathname} className="bg-crossLineGray">
            <div
                className="relative rounded-lg border-accent mx-auto p-1 border sm:w-50v sm:h-28w w-90v h-50w shadow-lg bg-accent flex justify-center self-center items-center"
            >
                {isMultiVideoProps ?
                <VideoStack multiVideoProps={videoUrl as MultiVideoProps} /> :
                <TheVideo videoUrl={videoUrl as string} fromModal={true} />}
            </div >
            <button type="button" onClick={(e) => {
                e.preventDefault()
                dispatch(playVideo(undefined))
            }} className="absolute bg-primary h-12 w-32 text-lg text-white font-bold px-4 py-2 rounded-lg" style={{ "bottom": "-4rem", right: "0rem" }}>Got it</button>
        </Modal >
    )
}

export function TheVideo(props: { videoUrl?: string, fromModal: boolean }) {
    const [myRef, setMyRef] = useState<HTMLVideoElement | null>(null)
    const lastKnownVideoTime = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.lastKnownVideoTime);
    let startTime = new Date();
    let playing = false

    function stopPlaying() {
        const duration = new Date().getTime() - startTime.getTime()

        if (isProd() && playing) {
            mixpanel.track("video-stopped-playing", { videoName: props.videoUrl?.toString(), duration_ms: duration })
        }

        playing = false
    }

    useEffect(() => {
        if (myRef) {
            let anonynousRef: any = myRef
            anonynousRef.currentTime = lastKnownVideoTime
        }

        return () => {
            // we don't currently support the netflix feature of backup of last time of video, because videos are now short :)
            // dispatch(backupVideoLastTime(myRef?.currentTime || 0))
            stopPlaying()
        }
    }, [myRef])

    if (!props.videoUrl) {
        return null
    }

    return <video
        onPlay={() => {
            playing = true
            startTime = new Date()

            if (isProd()) {
                mixpanel.track("video-started-playing", { videoName: props.videoUrl?.toString() })
            }
        }}

        onPause={() => {
            stopPlaying()
        }}

        onClick={() => {
            if (props.fromModal) {
                if (myRef?.paused) {
                    myRef?.play()
                }
                else {
                    myRef?.pause()
                }
            }
        }}
        ref={videoRef => { setMyRef(videoRef) }} className="w-full h-full object-fill" poster="" controls={true} controlsList="nodownload">
        <source src={props.videoUrl}
            type="video/mp4" />
    </video>
}
