import React, { useEffect } from 'react'

// Todo: this needs to be refactored
// pdf-dist is broken with CRA due to pdf-worker.js (https://github.com/mozilla/pdf.js/issues/10813)
// @ts-ignore
// when changing this to import based, don't forget to remove two pdf.js scripts from index.html
const pdfjs = window.pdfjsLib

export default function PDFViewer({ url }: { url: string }) {


  const [urls, setUrls] = React.useState<string[]>([]);

  useEffect(() => {
    renderAllUrls(url).then((urls) => {
      setUrls(urls);
    })
  },
    []
  );

  return <Images urls={urls} />
}

function Images({ urls }: { urls: string[] }) {
  return (
    <>
      {urls.map((url, index) => <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {index > 0 ? <p style={{ pageBreakBefore: 'always' }} /> : null}
        <img width={550} style={{ aspectRatio: 'auto' }} src={url} />
      </div>
      )}
    </>
  )
}

async function renderAllUrls(url: string) {
  const scale = 1;
  // First, we need to load the document using the getDocument utility
  const pdf = await pdfjs.getDocument(url).promise;

  const { numPages } = pdf;

  const canvasURLs = [];

  // Now for every page in the document, we're going to add a page to the array
  for (let i = 0; i < numPages; i++) {
    const page = await pdf.getPage(i + 1);

    const viewport = page.getViewport({ scale });
    const { width, height } = viewport;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.className = 'page'

    const canvasContext = canvas.getContext('2d');

    if (canvasContext)
      await page.render({
        canvasContext: canvasContext,
        viewport
      })

    canvasURLs.push(canvas.toDataURL());
  }

  return canvasURLs;
}
