import { useSpring, animated } from "@react-spring/web";

interface BaseModalProps {
    children?: JSX.Element | JSX.Element[];
    size?: "sm" | "md" | "lg" | "full";
    title?: string;
    onClose?: () => void;
    className?: string;
}
const sizes = {
    sm: "max-w-xl",
    md: "max-w-3xl",
    lg: "max-w-7xl",
    full: "max-w-full",
};

export const BaseModal = (props: BaseModalProps) => {
    const { children, size = "sm", title, onClose } = props;

    const springStyle = useSpring({
        from: { opacity: 0, scale: 0 },
        to: { opacity: 1, scale: 1 },
    });

    const hasHeader = !!onClose || !!title;

    return (
        <div
            id="defaultModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`bg-gray-900 bg-opacity-50  inset-0 flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full ${props.className || ""}`}
        >
            <animated.div style={springStyle} className={`relative p-4  w-full ${sizes[size]}`}>
                <div className="relative bg-white rounded-lg shadow ">
                    {hasHeader && (
                        <div className="flex justify-between items-start p-4 rounded-t border-b">
                            <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
                            {!!onClose && (
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                    data-modal-toggle="defaultModal"
                                    onClick={onClose}
                                >
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                    <div className="p-6 space-y-6">{children}</div>
                </div>
            </animated.div>
        </div>
    );
};
