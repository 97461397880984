import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PContactInfo } from "proto/PContactInfo";
import { PTimeRecord } from "proto/PTimeRecord";
import { RootState } from "store";

export enum EditingMode {
    NEW,
    EDIT,
    NONE
}

export interface TimeTrackingState {
    newClientMode: EditingMode;
    records: PTimeRecord[];
    allContacts: PContactInfo[];
    currentContactEmail: string;
    currentTaskName: string;
    currentHourlyRate: number;
    currentIsBillable: boolean;
    currentRecordBeingEditedUuid: string;

    clientEmailFilter: string;
    monthEpochFilter: number;
}

export interface FullTimeRecord {
    billable: boolean;
    client: string;
    task: string;
    date: string;
    duration: string;
    value: string;
    actions: string;
    currentlyRunning: boolean;
    uuid: string;
}

export interface Contact {
    email: string;
    name: string;
    phone: string;
    address: string;

}

const initialState: TimeTrackingState = {
    records: [],
    newClientMode: EditingMode.NONE,
    currentContactEmail: "",
    allContacts: [],
    currentTaskName: "",
    currentIsBillable: false,
    currentHourlyRate: 0,
    currentRecordBeingEditedUuid: "",

    clientEmailFilter: "",
    monthEpochFilter: 0,
};

export const timeTrackingSlice = createSlice({
    name: "timeTracking",
    initialState,
    reducers: {
        setNewClientMode: (state, action: PayloadAction<EditingMode>) => {
            state.newClientMode = action.payload;
        },
        setAllContacts: (state, action: PayloadAction<PContactInfo[]>) => {
            state.allContacts = action.payload;
        },
        setCurrentContactEmail: (state, action: PayloadAction<string>) => {
            state.currentContactEmail = action.payload;
        },
        setAllTimeRecords: (state, action: PayloadAction<PTimeRecord[]>) => {
            state.records = action.payload;
        },
        setCurrentTaskName: (state, action: PayloadAction<string>) => {
            state.currentTaskName = action.payload;
        },
        setCurrentIsBillable: (state, action: PayloadAction<boolean>) => {
            state.currentIsBillable = action.payload;
        },
        setCurrentHourlyRate: (state, action: PayloadAction<number>) => {
            state.currentHourlyRate = action.payload;
        },
        setCurrentRecordBeingEditedUuid: (state, action: PayloadAction<string>) => {
            state.currentRecordBeingEditedUuid = action.payload;
        },

        setClientFilter: (state, action: PayloadAction<string>) => {
            state.clientEmailFilter = action.payload;
        },
        setMonthFilter: (state, action: PayloadAction<number>) => {
            state.monthEpochFilter = action.payload;
        },
    },
});

export const selectors = {
    selectAll: (state: RootState) => state.notifications.list,
};
