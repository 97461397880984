import { PTemplate } from "proto/PTemplate";
import { forwardRef } from "react";
import { Rnd } from "react-rnd";

interface Props {
    template: PTemplate;
    isEditable: boolean;
    onResize: (resize: { id: string; width: number; height: number }) => void;
    onMove: (resize: { id: string; x: number; y: number }) => void;
}
export const TemplateCanvas = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { isEditable, template } = props;
    const { backgrounds = [], primaryBackground, coverBackground } = template.design ?? {};

    return (
        <div
            ref={ref}
            style={{
                overflow: "hidden",
                position: "relative",
                backgroundColor: primaryBackground,
                padding: "60px",
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                ...(coverBackground && { backgroundImage: `url(${coverBackground?.src})` }),
            }}
            className="overflow-y-auto p-4 flex-shrink-0 w-full h-full"
        >
            {backgrounds.map((background) => {
                return (
                    <Rnd
                        key={background.id}
                        default={{
                            height: background.size?.height ?? 100,
                            width: background.size?.width ?? 100,
                            x: background.position?.x ?? 0,
                            y: background.position?.y ?? 0,
                        }}
                        className={`${isEditable ? "border" : ""}`}
                        bounds="parent"
                        onDragStop={(e, data) => {
                            props.onMove({
                                id: background.id,
                                x: data.x,
                                y: data.y,
                            });
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                            props.onResize({
                                id: background.id,
                                width: (background.size?.width ?? 0) + delta.width,
                                height: (background.size?.height ?? 0) + delta.height,
                            });
                        }}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${background.src})`,
                                backgroundPosition: "center center",
                                backgroundSize: "100% 100%",
                                backgroundRepeat: "no-repeat",
                                height: "100%",
                                width: "100%",
                            }}
                        ></div>
                    </Rnd>
                );
            })}
        </div>
    );
});
