import { Container } from 'components/0_common'
import ChatBot, { ChatTopics } from 'components/1_features/ChatBot/ChatBot'
import React from 'react'

export default function ChatLayout() {
    return (
        <Container>
            <div className='flex flex-row mt-8 gap-4'>
                <div id="left" className="lg:w-[22%] h-min sticky top-8 flex-shrink-0">
                    <ChatLeftPane />
                </div>
                <ChatBot />
            </div>
        </Container>
    )
}

function ChatLeftPane() {
    return <div className='flex flex-col w-full gap-4'>
        <ChatTopics />
    </div>
}
