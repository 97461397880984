/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON, PFile } from "../PUtils";

export const protobufPackage = "";

export enum ECDTrademarkInfringementBreachChoice {
  CD_TrademarkInfringement_breachChoice_NA = 0,
  CD_TrademarkInfringement_breachChoice_NONE = 1,
  CD_TrademarkInfringement_breachChoice_SUSPEND = 2,
  CD_TrademarkInfringement_breachChoice_NOTICE = 3,
  CD_TrademarkInfringement_breachChoice_TERMINATE = 4,
  UNRECOGNIZED = -1,
}

export function eCDTrademarkInfringementBreachChoiceFromJSON(object: any): ECDTrademarkInfringementBreachChoice {
  switch (object) {
    case 0:
    case "CD_TrademarkInfringement_breachChoice_NA":
      return ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NA;
    case 1:
    case "CD_TrademarkInfringement_breachChoice_NONE":
      return ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NONE;
    case 2:
    case "CD_TrademarkInfringement_breachChoice_SUSPEND":
      return ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_SUSPEND;
    case 3:
    case "CD_TrademarkInfringement_breachChoice_NOTICE":
      return ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NOTICE;
    case 4:
    case "CD_TrademarkInfringement_breachChoice_TERMINATE":
      return ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_TERMINATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECDTrademarkInfringementBreachChoice.UNRECOGNIZED;
  }
}

export function eCDTrademarkInfringementBreachChoiceToJSON(object: ECDTrademarkInfringementBreachChoice): string {
  switch (object) {
    case ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NA:
      return "CD_TrademarkInfringement_breachChoice_NA";
    case ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NONE:
      return "CD_TrademarkInfringement_breachChoice_NONE";
    case ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_SUSPEND:
      return "CD_TrademarkInfringement_breachChoice_SUSPEND";
    case ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_NOTICE:
      return "CD_TrademarkInfringement_breachChoice_NOTICE";
    case ECDTrademarkInfringementBreachChoice.CD_TrademarkInfringement_breachChoice_TERMINATE:
      return "CD_TrademarkInfringement_breachChoice_TERMINATE";
    case ECDTrademarkInfringementBreachChoice.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ECDTrademarkInfringementInfringingUse {
  CD_TrademarkInfringement_infringingUse_NA = 0,
  CD_TrademarkInfringement_infringingUse_SAME = 1,
  CD_TrademarkInfringement_infringingUse_SIMILAR = 2,
  UNRECOGNIZED = -1,
}

export function eCDTrademarkInfringementInfringingUseFromJSON(object: any): ECDTrademarkInfringementInfringingUse {
  switch (object) {
    case 0:
    case "CD_TrademarkInfringement_infringingUse_NA":
      return ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_NA;
    case 1:
    case "CD_TrademarkInfringement_infringingUse_SAME":
      return ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_SAME;
    case 2:
    case "CD_TrademarkInfringement_infringingUse_SIMILAR":
      return ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_SIMILAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ECDTrademarkInfringementInfringingUse.UNRECOGNIZED;
  }
}

export function eCDTrademarkInfringementInfringingUseToJSON(object: ECDTrademarkInfringementInfringingUse): string {
  switch (object) {
    case ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_NA:
      return "CD_TrademarkInfringement_infringingUse_NA";
    case ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_SAME:
      return "CD_TrademarkInfringement_infringingUse_SAME";
    case ECDTrademarkInfringementInfringingUse.CD_TrademarkInfringement_infringingUse_SIMILAR:
      return "CD_TrademarkInfringement_infringingUse_SIMILAR";
    case ECDTrademarkInfringementInfringingUse.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MCDTrademarkInfringementBreachChoice {
  optionDbValue: ECDTrademarkInfringementBreachChoice;
  other: string;
}

export interface MCDTrademarkInfringementInfringingUse {
  optionDbValue: ECDTrademarkInfringementInfringingUse;
  other: string;
}

export interface CDTrademarkInfringement {
  dateLetter: Date | undefined;
  otherCompanyFull: string;
  otherAddressOther: string;
  otherAddress: string;
  otherName: string;
  underlyingAgreement: string;
  noticeDays: number;
  letterName: string;
  letterTitle: string;
  breachChoice: MCDTrademarkInfringementBreachChoice | undefined;
  attachAgreement: InternalBoolean;
  attachAgreementCopy: PFile | undefined;
  otherEmail: string;
  underlyingDispute: InternalBoolean;
  companyShort: string;
  documentNumber: string;
  infringingUse: MCDTrademarkInfringementInfringingUse | undefined;
  warningDays: number;
  workRegistered: InternalBoolean;
  obligationList: string[];
  trademark: string;
  trademarkUseDate: Date | undefined;
  infringingMark: string;
  attachRegistrationCopy: PFile | undefined;
  companyServices: string;
}

function createBaseMCDTrademarkInfringementBreachChoice(): MCDTrademarkInfringementBreachChoice {
  return { optionDbValue: 0, other: "" };
}

export const MCDTrademarkInfringementBreachChoice = {
  encode(message: MCDTrademarkInfringementBreachChoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCDTrademarkInfringementBreachChoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCDTrademarkInfringementBreachChoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCDTrademarkInfringementBreachChoice {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eCDTrademarkInfringementBreachChoiceFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCDTrademarkInfringementBreachChoice): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCDTrademarkInfringementBreachChoiceToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCDTrademarkInfringementBreachChoice>, I>>(
    base?: I,
  ): MCDTrademarkInfringementBreachChoice {
    return MCDTrademarkInfringementBreachChoice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCDTrademarkInfringementBreachChoice>, I>>(
    object: I,
  ): MCDTrademarkInfringementBreachChoice {
    const message = createBaseMCDTrademarkInfringementBreachChoice();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMCDTrademarkInfringementInfringingUse(): MCDTrademarkInfringementInfringingUse {
  return { optionDbValue: 0, other: "" };
}

export const MCDTrademarkInfringementInfringingUse = {
  encode(message: MCDTrademarkInfringementInfringingUse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MCDTrademarkInfringementInfringingUse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMCDTrademarkInfringementInfringingUse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MCDTrademarkInfringementInfringingUse {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eCDTrademarkInfringementInfringingUseFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MCDTrademarkInfringementInfringingUse): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eCDTrademarkInfringementInfringingUseToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MCDTrademarkInfringementInfringingUse>, I>>(
    base?: I,
  ): MCDTrademarkInfringementInfringingUse {
    return MCDTrademarkInfringementInfringingUse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MCDTrademarkInfringementInfringingUse>, I>>(
    object: I,
  ): MCDTrademarkInfringementInfringingUse {
    const message = createBaseMCDTrademarkInfringementInfringingUse();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseCDTrademarkInfringement(): CDTrademarkInfringement {
  return {
    dateLetter: undefined,
    otherCompanyFull: "",
    otherAddressOther: "",
    otherAddress: "",
    otherName: "",
    underlyingAgreement: "",
    noticeDays: 0,
    letterName: "",
    letterTitle: "",
    breachChoice: undefined,
    attachAgreement: 0,
    attachAgreementCopy: undefined,
    otherEmail: "",
    underlyingDispute: 0,
    companyShort: "",
    documentNumber: "",
    infringingUse: undefined,
    warningDays: 0,
    workRegistered: 0,
    obligationList: [],
    trademark: "",
    trademarkUseDate: undefined,
    infringingMark: "",
    attachRegistrationCopy: undefined,
    companyServices: "",
  };
}

export const CDTrademarkInfringement = {
  encode(message: CDTrademarkInfringement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateLetter !== undefined) {
      Timestamp.encode(toTimestamp(message.dateLetter), writer.uint32(10).fork()).ldelim();
    }
    if (message.otherCompanyFull !== "") {
      writer.uint32(18).string(message.otherCompanyFull);
    }
    if (message.otherAddressOther !== "") {
      writer.uint32(26).string(message.otherAddressOther);
    }
    if (message.otherAddress !== "") {
      writer.uint32(34).string(message.otherAddress);
    }
    if (message.otherName !== "") {
      writer.uint32(42).string(message.otherName);
    }
    if (message.underlyingAgreement !== "") {
      writer.uint32(50).string(message.underlyingAgreement);
    }
    if (message.noticeDays !== 0) {
      writer.uint32(61).float(message.noticeDays);
    }
    if (message.letterName !== "") {
      writer.uint32(66).string(message.letterName);
    }
    if (message.letterTitle !== "") {
      writer.uint32(74).string(message.letterTitle);
    }
    if (message.breachChoice !== undefined) {
      MCDTrademarkInfringementBreachChoice.encode(message.breachChoice, writer.uint32(82).fork()).ldelim();
    }
    if (message.attachAgreement !== 0) {
      writer.uint32(88).int32(message.attachAgreement);
    }
    if (message.attachAgreementCopy !== undefined) {
      PFile.encode(message.attachAgreementCopy, writer.uint32(98).fork()).ldelim();
    }
    if (message.otherEmail !== "") {
      writer.uint32(106).string(message.otherEmail);
    }
    if (message.underlyingDispute !== 0) {
      writer.uint32(112).int32(message.underlyingDispute);
    }
    if (message.companyShort !== "") {
      writer.uint32(122).string(message.companyShort);
    }
    if (message.documentNumber !== "") {
      writer.uint32(130).string(message.documentNumber);
    }
    if (message.infringingUse !== undefined) {
      MCDTrademarkInfringementInfringingUse.encode(message.infringingUse, writer.uint32(138).fork()).ldelim();
    }
    if (message.warningDays !== 0) {
      writer.uint32(149).float(message.warningDays);
    }
    if (message.workRegistered !== 0) {
      writer.uint32(152).int32(message.workRegistered);
    }
    for (const v of message.obligationList) {
      writer.uint32(162).string(v!);
    }
    if (message.trademark !== "") {
      writer.uint32(170).string(message.trademark);
    }
    if (message.trademarkUseDate !== undefined) {
      Timestamp.encode(toTimestamp(message.trademarkUseDate), writer.uint32(178).fork()).ldelim();
    }
    if (message.infringingMark !== "") {
      writer.uint32(186).string(message.infringingMark);
    }
    if (message.attachRegistrationCopy !== undefined) {
      PFile.encode(message.attachRegistrationCopy, writer.uint32(194).fork()).ldelim();
    }
    if (message.companyServices !== "") {
      writer.uint32(202).string(message.companyServices);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CDTrademarkInfringement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCDTrademarkInfringement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.dateLetter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.otherCompanyFull = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.otherAddressOther = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.otherAddress = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.otherName = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.underlyingAgreement = reader.string();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.noticeDays = reader.float();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.letterName = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.letterTitle = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.breachChoice = MCDTrademarkInfringementBreachChoice.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.attachAgreement = reader.int32() as any;
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.attachAgreementCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.otherEmail = reader.string();
          continue;
        case 14:
          if (tag != 112) {
            break;
          }

          message.underlyingDispute = reader.int32() as any;
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.documentNumber = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.infringingUse = MCDTrademarkInfringementInfringingUse.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag != 149) {
            break;
          }

          message.warningDays = reader.float();
          continue;
        case 19:
          if (tag != 152) {
            break;
          }

          message.workRegistered = reader.int32() as any;
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.obligationList.push(reader.string());
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.trademark = reader.string();
          continue;
        case 22:
          if (tag != 178) {
            break;
          }

          message.trademarkUseDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.infringingMark = reader.string();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.attachRegistrationCopy = PFile.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.companyServices = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CDTrademarkInfringement {
    return {
      dateLetter: isSet(object.dateLetter) ? fromJsonTimestamp(object.dateLetter) : undefined,
      otherCompanyFull: isSet(object.otherCompanyFull) ? String(object.otherCompanyFull) : "",
      otherAddressOther: isSet(object.otherAddressOther) ? String(object.otherAddressOther) : "",
      otherAddress: isSet(object.otherAddress) ? String(object.otherAddress) : "",
      otherName: isSet(object.otherName) ? String(object.otherName) : "",
      underlyingAgreement: isSet(object.underlyingAgreement) ? String(object.underlyingAgreement) : "",
      noticeDays: isSet(object.noticeDays) ? Number(object.noticeDays) : 0,
      letterName: isSet(object.letterName) ? String(object.letterName) : "",
      letterTitle: isSet(object.letterTitle) ? String(object.letterTitle) : "",
      breachChoice: isSet(object.breachChoice)
        ? MCDTrademarkInfringementBreachChoice.fromJSON(object.breachChoice)
        : undefined,
      attachAgreement: isSet(object.attachAgreement) ? internalBooleanFromJSON(object.attachAgreement) : 0,
      attachAgreementCopy: isSet(object.attachAgreementCopy) ? PFile.fromJSON(object.attachAgreementCopy) : undefined,
      otherEmail: isSet(object.otherEmail) ? String(object.otherEmail) : "",
      underlyingDispute: isSet(object.underlyingDispute) ? internalBooleanFromJSON(object.underlyingDispute) : 0,
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      documentNumber: isSet(object.documentNumber) ? String(object.documentNumber) : "",
      infringingUse: isSet(object.infringingUse)
        ? MCDTrademarkInfringementInfringingUse.fromJSON(object.infringingUse)
        : undefined,
      warningDays: isSet(object.warningDays) ? Number(object.warningDays) : 0,
      workRegistered: isSet(object.workRegistered) ? internalBooleanFromJSON(object.workRegistered) : 0,
      obligationList: Array.isArray(object?.obligationList) ? object.obligationList.map((e: any) => String(e)) : [],
      trademark: isSet(object.trademark) ? String(object.trademark) : "",
      trademarkUseDate: isSet(object.trademarkUseDate) ? fromJsonTimestamp(object.trademarkUseDate) : undefined,
      infringingMark: isSet(object.infringingMark) ? String(object.infringingMark) : "",
      attachRegistrationCopy: isSet(object.attachRegistrationCopy)
        ? PFile.fromJSON(object.attachRegistrationCopy)
        : undefined,
      companyServices: isSet(object.companyServices) ? String(object.companyServices) : "",
    };
  },

  toJSON(message: CDTrademarkInfringement): unknown {
    const obj: any = {};
    message.dateLetter !== undefined && (obj.dateLetter = message.dateLetter.toISOString());
    message.otherCompanyFull !== undefined && (obj.otherCompanyFull = message.otherCompanyFull);
    message.otherAddressOther !== undefined && (obj.otherAddressOther = message.otherAddressOther);
    message.otherAddress !== undefined && (obj.otherAddress = message.otherAddress);
    message.otherName !== undefined && (obj.otherName = message.otherName);
    message.underlyingAgreement !== undefined && (obj.underlyingAgreement = message.underlyingAgreement);
    message.noticeDays !== undefined && (obj.noticeDays = message.noticeDays);
    message.letterName !== undefined && (obj.letterName = message.letterName);
    message.letterTitle !== undefined && (obj.letterTitle = message.letterTitle);
    message.breachChoice !== undefined && (obj.breachChoice = message.breachChoice
      ? MCDTrademarkInfringementBreachChoice.toJSON(message.breachChoice)
      : undefined);
    message.attachAgreement !== undefined && (obj.attachAgreement = internalBooleanToJSON(message.attachAgreement));
    message.attachAgreementCopy !== undefined &&
      (obj.attachAgreementCopy = message.attachAgreementCopy ? PFile.toJSON(message.attachAgreementCopy) : undefined);
    message.otherEmail !== undefined && (obj.otherEmail = message.otherEmail);
    message.underlyingDispute !== undefined &&
      (obj.underlyingDispute = internalBooleanToJSON(message.underlyingDispute));
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.documentNumber !== undefined && (obj.documentNumber = message.documentNumber);
    message.infringingUse !== undefined && (obj.infringingUse = message.infringingUse
      ? MCDTrademarkInfringementInfringingUse.toJSON(message.infringingUse)
      : undefined);
    message.warningDays !== undefined && (obj.warningDays = message.warningDays);
    message.workRegistered !== undefined && (obj.workRegistered = internalBooleanToJSON(message.workRegistered));
    if (message.obligationList) {
      obj.obligationList = message.obligationList.map((e) => e);
    } else {
      obj.obligationList = [];
    }
    message.trademark !== undefined && (obj.trademark = message.trademark);
    message.trademarkUseDate !== undefined && (obj.trademarkUseDate = message.trademarkUseDate.toISOString());
    message.infringingMark !== undefined && (obj.infringingMark = message.infringingMark);
    message.attachRegistrationCopy !== undefined && (obj.attachRegistrationCopy = message.attachRegistrationCopy
      ? PFile.toJSON(message.attachRegistrationCopy)
      : undefined);
    message.companyServices !== undefined && (obj.companyServices = message.companyServices);
    return obj;
  },

  create<I extends Exact<DeepPartial<CDTrademarkInfringement>, I>>(base?: I): CDTrademarkInfringement {
    return CDTrademarkInfringement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CDTrademarkInfringement>, I>>(object: I): CDTrademarkInfringement {
    const message = createBaseCDTrademarkInfringement();
    message.dateLetter = object.dateLetter ?? undefined;
    message.otherCompanyFull = object.otherCompanyFull ?? "";
    message.otherAddressOther = object.otherAddressOther ?? "";
    message.otherAddress = object.otherAddress ?? "";
    message.otherName = object.otherName ?? "";
    message.underlyingAgreement = object.underlyingAgreement ?? "";
    message.noticeDays = object.noticeDays ?? 0;
    message.letterName = object.letterName ?? "";
    message.letterTitle = object.letterTitle ?? "";
    message.breachChoice = (object.breachChoice !== undefined && object.breachChoice !== null)
      ? MCDTrademarkInfringementBreachChoice.fromPartial(object.breachChoice)
      : undefined;
    message.attachAgreement = object.attachAgreement ?? 0;
    message.attachAgreementCopy = (object.attachAgreementCopy !== undefined && object.attachAgreementCopy !== null)
      ? PFile.fromPartial(object.attachAgreementCopy)
      : undefined;
    message.otherEmail = object.otherEmail ?? "";
    message.underlyingDispute = object.underlyingDispute ?? 0;
    message.companyShort = object.companyShort ?? "";
    message.documentNumber = object.documentNumber ?? "";
    message.infringingUse = (object.infringingUse !== undefined && object.infringingUse !== null)
      ? MCDTrademarkInfringementInfringingUse.fromPartial(object.infringingUse)
      : undefined;
    message.warningDays = object.warningDays ?? 0;
    message.workRegistered = object.workRegistered ?? 0;
    message.obligationList = object.obligationList?.map((e) => e) || [];
    message.trademark = object.trademark ?? "";
    message.trademarkUseDate = object.trademarkUseDate ?? undefined;
    message.infringingMark = object.infringingMark ?? "";
    message.attachRegistrationCopy =
      (object.attachRegistrationCopy !== undefined && object.attachRegistrationCopy !== null)
        ? PFile.fromPartial(object.attachRegistrationCopy)
        : undefined;
    message.companyServices = object.companyServices ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
