import React, { FormEvent, useState } from 'react'
import Modal, { ExitButton, ModalFooter } from '../Modal'

import { useAuth } from '../contexts/AuthContext'
import "./Auth.scss"
import { useHistory } from 'react-router'
import { LogoImg } from 'components/1_features/AppBar/Menu'
import { passCurrentSearchQuery } from './AuthUtils'

export default function Forgot() {
    const auth = useAuth()
    const history = useHistory()
    const [error, setError] = useState("")
    const [message, setMessage] = useState<string>("")
    const [email, setEmail] = useState("");

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            await auth?.resetPassword(email)
            setMessage("Check your inbox for further instructions")
        } catch {
            setError("Failed to reset password")
        }
    }

    return (
        <Modal>
            <div className="sm:w-172 h-full flex flex-col">

                <ExitButton onClick={() => {
                    window.location.href = 'https://www.clawdia.ai';
                }} />

                <div className="flex-grow">
                    <div className="mt-3 mx-6 sm:mx-28">
                        <div className={"_ErrorHeight mt-2 px-4 rounded-lg border border-white flex items-center justify-center " + (error ? "border-dangerBorder bg-dangerBg" : "") +
                            (message ? "border-stroke3 bg-stroke2" : "")
                        }>
                            {error && <p className="text-danger text-sm overflow-ellipsis">{error}</p>}
                            {message && <p className="text-primary text-sm overflow-ellipsis">{message}</p>}
                        </div>

                        <div className="mt-2">
                            <LogoImg mode='light' size='large' />
                        </div>

                        <form onSubmit={handleSubmit}>
                            <p className="text-darkIndigo text-3xl font-medium mt-8">
                                <span>Forgot Your Password?</span>
                            </p>
                            <p className="text-xl text-darkIndigo mt-2">
                                We'll email you instructions on how to reset your password
                            </p>

                            <p className="text-1_5xl mt-6 text-darkIndigo">Your Email</p>

                            <input required onChange={(e) => setEmail(e.target.value)}
                                type="email" autoFocus className="InputField w-full mt-1 text-base rounded-md border px-4 py-3 border-sky" placeholder="Ex: name@company.com" />

                            <input type="submit" className="mb-4 mt-6 h-14 bg-primary text-white text-xl w-full rounded-lg hover:bg-primaryHover cursor-pointer" value="Submit" />
                        </form>
                    </div>
                </div>
                <ModalFooter>
                    <div className="h-25 flex flex-col items-center justify-center">
                        <p className="text-lg text-darkIndigo">Need an account? <a onClick={() => {
                            history.push("/login" + passCurrentSearchQuery())
                        }}>Sign up</a></p>
                        <p className="text-lg text-darkIndigo">Want to retry login? <a onClick={() => {
                            history.push("/login" + passCurrentSearchQuery())
                        }}>Log in</a></p>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}
