import { MultiVideoProps } from "apps/legal-ide/App/components/QuestionWizard/VideosCollection";
import { PDoc } from "../proto/PDoc";
import { PUser } from "../proto/PUser";
import { PPlanState } from "../proto/PPlanState";
import { PPlan } from "proto/PPlan";

export interface MainStore {
    isSideBarOpen: boolean;
    draftsWereLoaded: boolean;
    drafts: PDoc[];
    sentDocs: PDoc[];
    inboxDocs: PDoc[];
    completedDocs: PDoc[];
    uploadedDocs: PDoc[];
    isDocPreviewOpen: boolean;
    activeDraft?: PDoc;
    showExplanations: boolean;
    wizardNavigationOpen: boolean;
    planState: PPlanState | undefined;
    plan: PPlan | undefined;
    dbUser: PUser | undefined;
    signCanvases: React.MutableRefObject<any>[];
    alreadySigned: boolean[];
    docNamerRef: HTMLInputElement | undefined;
    dialog: Dialog | undefined;
    sideBarOpen: boolean;
    lastKnownVideoTime: number;
    videoPlayer: string | MultiVideoProps | undefined;
    isPaymentYearly: boolean;
    pendingShareWithEmail: string;
    activeDocId?: string;
    decisionSupportingToolAlert?: DecisionSupportingToolAlert;
}

export interface Dialog {
    text: string;
    type: DialogType;
    callback?: Function;
}

export enum DialogType {
    NONE,
    SUCCESS,
    FAILURE,
    CONFIRM,
}

export interface DecisionSupportingToolAlert {
    text: string;
    type: DecisionSupportingToolAlertType;
    callback: Function;
}

export enum DecisionSupportingToolAlertType {
    NONE,
    PRIVACY_POLICY_WEBSITE_SCAN,
}
