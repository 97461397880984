/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EReferralCompanyEntityType {
  Referral_companyEntityType_NA = 0,
  Referral_companyEntityType_CORPORATION = 1,
  Referral_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  Referral_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  Referral_companyEntityType_LIMITED_PARTNERSHIP = 4,
  Referral_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  Referral_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  Referral_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  Referral_companyEntityType_NONPROFIT_CORPORATION = 8,
  Referral_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  Referral_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eReferralCompanyEntityTypeFromJSON(object: any): EReferralCompanyEntityType {
  switch (object) {
    case 0:
    case "Referral_companyEntityType_NA":
      return EReferralCompanyEntityType.Referral_companyEntityType_NA;
    case 1:
    case "Referral_companyEntityType_CORPORATION":
      return EReferralCompanyEntityType.Referral_companyEntityType_CORPORATION;
    case 2:
    case "Referral_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EReferralCompanyEntityType.Referral_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "Referral_companyEntityType_SOLE_PROPRIETORSHIP":
      return EReferralCompanyEntityType.Referral_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "Referral_companyEntityType_LIMITED_PARTNERSHIP":
      return EReferralCompanyEntityType.Referral_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "Referral_companyEntityType_PROFESSIONAL_CORPORATION":
      return EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "Referral_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "Referral_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "Referral_companyEntityType_NONPROFIT_CORPORATION":
      return EReferralCompanyEntityType.Referral_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "Referral_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EReferralCompanyEntityType.Referral_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "Referral_companyEntityType_PARTNERSHIP":
      return EReferralCompanyEntityType.Referral_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralCompanyEntityType.UNRECOGNIZED;
  }
}

export function eReferralCompanyEntityTypeToJSON(object: EReferralCompanyEntityType): string {
  switch (object) {
    case EReferralCompanyEntityType.Referral_companyEntityType_NA:
      return "Referral_companyEntityType_NA";
    case EReferralCompanyEntityType.Referral_companyEntityType_CORPORATION:
      return "Referral_companyEntityType_CORPORATION";
    case EReferralCompanyEntityType.Referral_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "Referral_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EReferralCompanyEntityType.Referral_companyEntityType_SOLE_PROPRIETORSHIP:
      return "Referral_companyEntityType_SOLE_PROPRIETORSHIP";
    case EReferralCompanyEntityType.Referral_companyEntityType_LIMITED_PARTNERSHIP:
      return "Referral_companyEntityType_LIMITED_PARTNERSHIP";
    case EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_CORPORATION:
      return "Referral_companyEntityType_PROFESSIONAL_CORPORATION";
    case EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "Referral_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EReferralCompanyEntityType.Referral_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "Referral_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EReferralCompanyEntityType.Referral_companyEntityType_NONPROFIT_CORPORATION:
      return "Referral_companyEntityType_NONPROFIT_CORPORATION";
    case EReferralCompanyEntityType.Referral_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "Referral_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EReferralCompanyEntityType.Referral_companyEntityType_PARTNERSHIP:
      return "Referral_companyEntityType_PARTNERSHIP";
    case EReferralCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EReferralReferEntityType {
  Referral_referEntityType_NA = 0,
  Referral_referEntityType_CORPORATION = 1,
  Referral_referEntityType_LIMITED_LIABILITY_COMPANY = 2,
  Referral_referEntityType_SOLE_PROPRIETORSHIP = 3,
  Referral_referEntityType_LIMITED_PARTNERSHIP = 4,
  Referral_referEntityType_PROFESSIONAL_CORPORATION = 5,
  Referral_referEntityType_PROFESSIONAL_ASSOCIATION = 6,
  Referral_referEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  Referral_referEntityType_NONPROFIT_CORPORATION = 8,
  Referral_referEntityType_UNINCORPORATED_ASSOCIATION = 9,
  Referral_referEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eReferralReferEntityTypeFromJSON(object: any): EReferralReferEntityType {
  switch (object) {
    case 0:
    case "Referral_referEntityType_NA":
      return EReferralReferEntityType.Referral_referEntityType_NA;
    case 1:
    case "Referral_referEntityType_CORPORATION":
      return EReferralReferEntityType.Referral_referEntityType_CORPORATION;
    case 2:
    case "Referral_referEntityType_LIMITED_LIABILITY_COMPANY":
      return EReferralReferEntityType.Referral_referEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "Referral_referEntityType_SOLE_PROPRIETORSHIP":
      return EReferralReferEntityType.Referral_referEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "Referral_referEntityType_LIMITED_PARTNERSHIP":
      return EReferralReferEntityType.Referral_referEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "Referral_referEntityType_PROFESSIONAL_CORPORATION":
      return EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "Referral_referEntityType_PROFESSIONAL_ASSOCIATION":
      return EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "Referral_referEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "Referral_referEntityType_NONPROFIT_CORPORATION":
      return EReferralReferEntityType.Referral_referEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "Referral_referEntityType_UNINCORPORATED_ASSOCIATION":
      return EReferralReferEntityType.Referral_referEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "Referral_referEntityType_PARTNERSHIP":
      return EReferralReferEntityType.Referral_referEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralReferEntityType.UNRECOGNIZED;
  }
}

export function eReferralReferEntityTypeToJSON(object: EReferralReferEntityType): string {
  switch (object) {
    case EReferralReferEntityType.Referral_referEntityType_NA:
      return "Referral_referEntityType_NA";
    case EReferralReferEntityType.Referral_referEntityType_CORPORATION:
      return "Referral_referEntityType_CORPORATION";
    case EReferralReferEntityType.Referral_referEntityType_LIMITED_LIABILITY_COMPANY:
      return "Referral_referEntityType_LIMITED_LIABILITY_COMPANY";
    case EReferralReferEntityType.Referral_referEntityType_SOLE_PROPRIETORSHIP:
      return "Referral_referEntityType_SOLE_PROPRIETORSHIP";
    case EReferralReferEntityType.Referral_referEntityType_LIMITED_PARTNERSHIP:
      return "Referral_referEntityType_LIMITED_PARTNERSHIP";
    case EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_CORPORATION:
      return "Referral_referEntityType_PROFESSIONAL_CORPORATION";
    case EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_ASSOCIATION:
      return "Referral_referEntityType_PROFESSIONAL_ASSOCIATION";
    case EReferralReferEntityType.Referral_referEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "Referral_referEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EReferralReferEntityType.Referral_referEntityType_NONPROFIT_CORPORATION:
      return "Referral_referEntityType_NONPROFIT_CORPORATION";
    case EReferralReferEntityType.Referral_referEntityType_UNINCORPORATED_ASSOCIATION:
      return "Referral_referEntityType_UNINCORPORATED_ASSOCIATION";
    case EReferralReferEntityType.Referral_referEntityType_PARTNERSHIP:
      return "Referral_referEntityType_PARTNERSHIP";
    case EReferralReferEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EReferralPaymentPeriod {
  Referral_paymentPeriod_NA = 0,
  Referral_paymentPeriod_MONTH = 1,
  Referral_paymentPeriod_FQUARTER = 2,
  Referral_paymentPeriod_FYEAR = 3,
  UNRECOGNIZED = -1,
}

export function eReferralPaymentPeriodFromJSON(object: any): EReferralPaymentPeriod {
  switch (object) {
    case 0:
    case "Referral_paymentPeriod_NA":
      return EReferralPaymentPeriod.Referral_paymentPeriod_NA;
    case 1:
    case "Referral_paymentPeriod_MONTH":
      return EReferralPaymentPeriod.Referral_paymentPeriod_MONTH;
    case 2:
    case "Referral_paymentPeriod_FQUARTER":
      return EReferralPaymentPeriod.Referral_paymentPeriod_FQUARTER;
    case 3:
    case "Referral_paymentPeriod_FYEAR":
      return EReferralPaymentPeriod.Referral_paymentPeriod_FYEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralPaymentPeriod.UNRECOGNIZED;
  }
}

export function eReferralPaymentPeriodToJSON(object: EReferralPaymentPeriod): string {
  switch (object) {
    case EReferralPaymentPeriod.Referral_paymentPeriod_NA:
      return "Referral_paymentPeriod_NA";
    case EReferralPaymentPeriod.Referral_paymentPeriod_MONTH:
      return "Referral_paymentPeriod_MONTH";
    case EReferralPaymentPeriod.Referral_paymentPeriod_FQUARTER:
      return "Referral_paymentPeriod_FQUARTER";
    case EReferralPaymentPeriod.Referral_paymentPeriod_FYEAR:
      return "Referral_paymentPeriod_FYEAR";
    case EReferralPaymentPeriod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EReferralNondisclosurePlan {
  Referral_nondisclosurePlan_NA = 0,
  Referral_nondisclosurePlan_TAIL = 3,
  Referral_nondisclosurePlan_INDEFINITE = 2,
  Referral_nondisclosurePlan_TERM = 1,
  UNRECOGNIZED = -1,
}

export function eReferralNondisclosurePlanFromJSON(object: any): EReferralNondisclosurePlan {
  switch (object) {
    case 0:
    case "Referral_nondisclosurePlan_NA":
      return EReferralNondisclosurePlan.Referral_nondisclosurePlan_NA;
    case 3:
    case "Referral_nondisclosurePlan_TAIL":
      return EReferralNondisclosurePlan.Referral_nondisclosurePlan_TAIL;
    case 2:
    case "Referral_nondisclosurePlan_INDEFINITE":
      return EReferralNondisclosurePlan.Referral_nondisclosurePlan_INDEFINITE;
    case 1:
    case "Referral_nondisclosurePlan_TERM":
      return EReferralNondisclosurePlan.Referral_nondisclosurePlan_TERM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralNondisclosurePlan.UNRECOGNIZED;
  }
}

export function eReferralNondisclosurePlanToJSON(object: EReferralNondisclosurePlan): string {
  switch (object) {
    case EReferralNondisclosurePlan.Referral_nondisclosurePlan_NA:
      return "Referral_nondisclosurePlan_NA";
    case EReferralNondisclosurePlan.Referral_nondisclosurePlan_TAIL:
      return "Referral_nondisclosurePlan_TAIL";
    case EReferralNondisclosurePlan.Referral_nondisclosurePlan_INDEFINITE:
      return "Referral_nondisclosurePlan_INDEFINITE";
    case EReferralNondisclosurePlan.Referral_nondisclosurePlan_TERM:
      return "Referral_nondisclosurePlan_TERM";
    case EReferralNondisclosurePlan.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EReferralFeePhaseout {
  Referral_feePhaseout_NA = 0,
  Referral_feePhaseout_LEVEL = 1,
  Referral_feePhaseout_TERM_PHASE = 2,
  Referral_feePhaseout_Y1_PHASE = 3,
  Referral_feePhaseout_PROSPECT1_PHASE = 4,
  UNRECOGNIZED = -1,
}

export function eReferralFeePhaseoutFromJSON(object: any): EReferralFeePhaseout {
  switch (object) {
    case 0:
    case "Referral_feePhaseout_NA":
      return EReferralFeePhaseout.Referral_feePhaseout_NA;
    case 1:
    case "Referral_feePhaseout_LEVEL":
      return EReferralFeePhaseout.Referral_feePhaseout_LEVEL;
    case 2:
    case "Referral_feePhaseout_TERM_PHASE":
      return EReferralFeePhaseout.Referral_feePhaseout_TERM_PHASE;
    case 3:
    case "Referral_feePhaseout_Y1_PHASE":
      return EReferralFeePhaseout.Referral_feePhaseout_Y1_PHASE;
    case 4:
    case "Referral_feePhaseout_PROSPECT1_PHASE":
      return EReferralFeePhaseout.Referral_feePhaseout_PROSPECT1_PHASE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralFeePhaseout.UNRECOGNIZED;
  }
}

export function eReferralFeePhaseoutToJSON(object: EReferralFeePhaseout): string {
  switch (object) {
    case EReferralFeePhaseout.Referral_feePhaseout_NA:
      return "Referral_feePhaseout_NA";
    case EReferralFeePhaseout.Referral_feePhaseout_LEVEL:
      return "Referral_feePhaseout_LEVEL";
    case EReferralFeePhaseout.Referral_feePhaseout_TERM_PHASE:
      return "Referral_feePhaseout_TERM_PHASE";
    case EReferralFeePhaseout.Referral_feePhaseout_Y1_PHASE:
      return "Referral_feePhaseout_Y1_PHASE";
    case EReferralFeePhaseout.Referral_feePhaseout_PROSPECT1_PHASE:
      return "Referral_feePhaseout_PROSPECT1_PHASE";
    case EReferralFeePhaseout.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EReferralCommissionTail {
  Referral_commissionTail_NA = 0,
  Referral_commissionTail_INITIAL = 1,
  Referral_commissionTail_TERM = 2,
  Referral_commissionTail_TAIL = 3,
  Referral_commissionTail_LIFETIME = 4,
  UNRECOGNIZED = -1,
}

export function eReferralCommissionTailFromJSON(object: any): EReferralCommissionTail {
  switch (object) {
    case 0:
    case "Referral_commissionTail_NA":
      return EReferralCommissionTail.Referral_commissionTail_NA;
    case 1:
    case "Referral_commissionTail_INITIAL":
      return EReferralCommissionTail.Referral_commissionTail_INITIAL;
    case 2:
    case "Referral_commissionTail_TERM":
      return EReferralCommissionTail.Referral_commissionTail_TERM;
    case 3:
    case "Referral_commissionTail_TAIL":
      return EReferralCommissionTail.Referral_commissionTail_TAIL;
    case 4:
    case "Referral_commissionTail_LIFETIME":
      return EReferralCommissionTail.Referral_commissionTail_LIFETIME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EReferralCommissionTail.UNRECOGNIZED;
  }
}

export function eReferralCommissionTailToJSON(object: EReferralCommissionTail): string {
  switch (object) {
    case EReferralCommissionTail.Referral_commissionTail_NA:
      return "Referral_commissionTail_NA";
    case EReferralCommissionTail.Referral_commissionTail_INITIAL:
      return "Referral_commissionTail_INITIAL";
    case EReferralCommissionTail.Referral_commissionTail_TERM:
      return "Referral_commissionTail_TERM";
    case EReferralCommissionTail.Referral_commissionTail_TAIL:
      return "Referral_commissionTail_TAIL";
    case EReferralCommissionTail.Referral_commissionTail_LIFETIME:
      return "Referral_commissionTail_LIFETIME";
    case EReferralCommissionTail.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MReferralCompanyEntityType {
  optionDbValue: EReferralCompanyEntityType;
  other: string;
}

export interface MReferralReferEntityType {
  optionDbValue: EReferralReferEntityType;
  other: string;
}

export interface MReferralPaymentPeriod {
  optionDbValue: EReferralPaymentPeriod;
  other: string;
}

export interface MReferralNondisclosurePlan {
  optionDbValue: EReferralNondisclosurePlan;
  other: string;
}

export interface MReferralFeePhaseout {
  optionDbValue: EReferralFeePhaseout;
  other: string;
}

export interface MReferralCommissionTail {
  optionDbValue: EReferralCommissionTail;
  other: string;
}

export interface Referral {
  effectiveDate: Date | undefined;
  companyState: string;
  companyEntityType: MReferralCompanyEntityType | undefined;
  referFull: string;
  referState: string;
  referEntityType: MReferralReferEntityType | undefined;
  governLawState: string;
  venue: string;
  arbVenue: string;
  arbShort: InternalBoolean;
  paymentPeriod: MReferralPaymentPeriod | undefined;
  termNoticeDays: number;
  nondisclosurePlan: MReferralNondisclosurePlan | undefined;
  initialLeadsList: string[];
  initialProspectsList: string[];
  commissionPercentage: number;
  commissionTailMonths: number;
  feePhaseout: MReferralFeePhaseout | undefined;
  phaseoutYears: number;
  commissionTail: MReferralCommissionTail | undefined;
  planTargets: InternalBoolean;
  initialLeads: InternalBoolean;
  initialProspects: InternalBoolean;
  nondisclosureYears: number;
}

function createBaseMReferralCompanyEntityType(): MReferralCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MReferralCompanyEntityType = {
  encode(message: MReferralCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralCompanyEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eReferralCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralCompanyEntityType>, I>>(base?: I): MReferralCompanyEntityType {
    return MReferralCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralCompanyEntityType>, I>>(object: I): MReferralCompanyEntityType {
    const message = createBaseMReferralCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMReferralReferEntityType(): MReferralReferEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MReferralReferEntityType = {
  encode(message: MReferralReferEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralReferEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralReferEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralReferEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralReferEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralReferEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eReferralReferEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralReferEntityType>, I>>(base?: I): MReferralReferEntityType {
    return MReferralReferEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralReferEntityType>, I>>(object: I): MReferralReferEntityType {
    const message = createBaseMReferralReferEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMReferralPaymentPeriod(): MReferralPaymentPeriod {
  return { optionDbValue: 0, other: "" };
}

export const MReferralPaymentPeriod = {
  encode(message: MReferralPaymentPeriod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralPaymentPeriod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralPaymentPeriod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralPaymentPeriod {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralPaymentPeriodFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralPaymentPeriod): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eReferralPaymentPeriodToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralPaymentPeriod>, I>>(base?: I): MReferralPaymentPeriod {
    return MReferralPaymentPeriod.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralPaymentPeriod>, I>>(object: I): MReferralPaymentPeriod {
    const message = createBaseMReferralPaymentPeriod();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMReferralNondisclosurePlan(): MReferralNondisclosurePlan {
  return { optionDbValue: 0, other: "" };
}

export const MReferralNondisclosurePlan = {
  encode(message: MReferralNondisclosurePlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralNondisclosurePlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralNondisclosurePlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralNondisclosurePlan {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralNondisclosurePlanFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralNondisclosurePlan): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eReferralNondisclosurePlanToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralNondisclosurePlan>, I>>(base?: I): MReferralNondisclosurePlan {
    return MReferralNondisclosurePlan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralNondisclosurePlan>, I>>(object: I): MReferralNondisclosurePlan {
    const message = createBaseMReferralNondisclosurePlan();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMReferralFeePhaseout(): MReferralFeePhaseout {
  return { optionDbValue: 0, other: "" };
}

export const MReferralFeePhaseout = {
  encode(message: MReferralFeePhaseout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralFeePhaseout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralFeePhaseout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralFeePhaseout {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralFeePhaseoutFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralFeePhaseout): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eReferralFeePhaseoutToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralFeePhaseout>, I>>(base?: I): MReferralFeePhaseout {
    return MReferralFeePhaseout.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralFeePhaseout>, I>>(object: I): MReferralFeePhaseout {
    const message = createBaseMReferralFeePhaseout();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMReferralCommissionTail(): MReferralCommissionTail {
  return { optionDbValue: 0, other: "" };
}

export const MReferralCommissionTail = {
  encode(message: MReferralCommissionTail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MReferralCommissionTail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMReferralCommissionTail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MReferralCommissionTail {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eReferralCommissionTailFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MReferralCommissionTail): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eReferralCommissionTailToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MReferralCommissionTail>, I>>(base?: I): MReferralCommissionTail {
    return MReferralCommissionTail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MReferralCommissionTail>, I>>(object: I): MReferralCommissionTail {
    const message = createBaseMReferralCommissionTail();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseReferral(): Referral {
  return {
    effectiveDate: undefined,
    companyState: "",
    companyEntityType: undefined,
    referFull: "",
    referState: "",
    referEntityType: undefined,
    governLawState: "",
    venue: "",
    arbVenue: "",
    arbShort: 0,
    paymentPeriod: undefined,
    termNoticeDays: 0,
    nondisclosurePlan: undefined,
    initialLeadsList: [],
    initialProspectsList: [],
    commissionPercentage: 0,
    commissionTailMonths: 0,
    feePhaseout: undefined,
    phaseoutYears: 0,
    commissionTail: undefined,
    planTargets: 0,
    initialLeads: 0,
    initialProspects: 0,
    nondisclosureYears: 0,
  };
}

export const Referral = {
  encode(message: Referral, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MReferralCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.referFull !== "") {
      writer.uint32(34).string(message.referFull);
    }
    if (message.referState !== "") {
      writer.uint32(42).string(message.referState);
    }
    if (message.referEntityType !== undefined) {
      MReferralReferEntityType.encode(message.referEntityType, writer.uint32(50).fork()).ldelim();
    }
    if (message.governLawState !== "") {
      writer.uint32(58).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(66).string(message.venue);
    }
    if (message.arbVenue !== "") {
      writer.uint32(74).string(message.arbVenue);
    }
    if (message.arbShort !== 0) {
      writer.uint32(80).int32(message.arbShort);
    }
    if (message.paymentPeriod !== undefined) {
      MReferralPaymentPeriod.encode(message.paymentPeriod, writer.uint32(90).fork()).ldelim();
    }
    if (message.termNoticeDays !== 0) {
      writer.uint32(101).float(message.termNoticeDays);
    }
    if (message.nondisclosurePlan !== undefined) {
      MReferralNondisclosurePlan.encode(message.nondisclosurePlan, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.initialLeadsList) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.initialProspectsList) {
      writer.uint32(122).string(v!);
    }
    if (message.commissionPercentage !== 0) {
      writer.uint32(133).float(message.commissionPercentage);
    }
    if (message.commissionTailMonths !== 0) {
      writer.uint32(141).float(message.commissionTailMonths);
    }
    if (message.feePhaseout !== undefined) {
      MReferralFeePhaseout.encode(message.feePhaseout, writer.uint32(146).fork()).ldelim();
    }
    if (message.phaseoutYears !== 0) {
      writer.uint32(157).float(message.phaseoutYears);
    }
    if (message.commissionTail !== undefined) {
      MReferralCommissionTail.encode(message.commissionTail, writer.uint32(162).fork()).ldelim();
    }
    if (message.planTargets !== 0) {
      writer.uint32(168).int32(message.planTargets);
    }
    if (message.initialLeads !== 0) {
      writer.uint32(176).int32(message.initialLeads);
    }
    if (message.initialProspects !== 0) {
      writer.uint32(184).int32(message.initialProspects);
    }
    if (message.nondisclosureYears !== 0) {
      writer.uint32(197).float(message.nondisclosureYears);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Referral {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReferral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MReferralCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.referFull = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.referState = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.referEntityType = MReferralReferEntityType.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.arbVenue = reader.string();
          continue;
        case 10:
          if (tag != 80) {
            break;
          }

          message.arbShort = reader.int32() as any;
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.paymentPeriod = MReferralPaymentPeriod.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag != 101) {
            break;
          }

          message.termNoticeDays = reader.float();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.nondisclosurePlan = MReferralNondisclosurePlan.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.initialLeadsList.push(reader.string());
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.initialProspectsList.push(reader.string());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.commissionPercentage = reader.float();
          continue;
        case 17:
          if (tag != 141) {
            break;
          }

          message.commissionTailMonths = reader.float();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.feePhaseout = MReferralFeePhaseout.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag != 157) {
            break;
          }

          message.phaseoutYears = reader.float();
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.commissionTail = MReferralCommissionTail.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag != 168) {
            break;
          }

          message.planTargets = reader.int32() as any;
          continue;
        case 22:
          if (tag != 176) {
            break;
          }

          message.initialLeads = reader.int32() as any;
          continue;
        case 23:
          if (tag != 184) {
            break;
          }

          message.initialProspects = reader.int32() as any;
          continue;
        case 24:
          if (tag != 197) {
            break;
          }

          message.nondisclosureYears = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Referral {
    return {
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MReferralCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      referFull: isSet(object.referFull) ? String(object.referFull) : "",
      referState: isSet(object.referState) ? String(object.referState) : "",
      referEntityType: isSet(object.referEntityType)
        ? MReferralReferEntityType.fromJSON(object.referEntityType)
        : undefined,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      arbVenue: isSet(object.arbVenue) ? String(object.arbVenue) : "",
      arbShort: isSet(object.arbShort) ? internalBooleanFromJSON(object.arbShort) : 0,
      paymentPeriod: isSet(object.paymentPeriod) ? MReferralPaymentPeriod.fromJSON(object.paymentPeriod) : undefined,
      termNoticeDays: isSet(object.termNoticeDays) ? Number(object.termNoticeDays) : 0,
      nondisclosurePlan: isSet(object.nondisclosurePlan)
        ? MReferralNondisclosurePlan.fromJSON(object.nondisclosurePlan)
        : undefined,
      initialLeadsList: Array.isArray(object?.initialLeadsList)
        ? object.initialLeadsList.map((e: any) => String(e))
        : [],
      initialProspectsList: Array.isArray(object?.initialProspectsList)
        ? object.initialProspectsList.map((e: any) => String(e))
        : [],
      commissionPercentage: isSet(object.commissionPercentage) ? Number(object.commissionPercentage) : 0,
      commissionTailMonths: isSet(object.commissionTailMonths) ? Number(object.commissionTailMonths) : 0,
      feePhaseout: isSet(object.feePhaseout) ? MReferralFeePhaseout.fromJSON(object.feePhaseout) : undefined,
      phaseoutYears: isSet(object.phaseoutYears) ? Number(object.phaseoutYears) : 0,
      commissionTail: isSet(object.commissionTail)
        ? MReferralCommissionTail.fromJSON(object.commissionTail)
        : undefined,
      planTargets: isSet(object.planTargets) ? internalBooleanFromJSON(object.planTargets) : 0,
      initialLeads: isSet(object.initialLeads) ? internalBooleanFromJSON(object.initialLeads) : 0,
      initialProspects: isSet(object.initialProspects) ? internalBooleanFromJSON(object.initialProspects) : 0,
      nondisclosureYears: isSet(object.nondisclosureYears) ? Number(object.nondisclosureYears) : 0,
    };
  },

  toJSON(message: Referral): unknown {
    const obj: any = {};
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MReferralCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.referFull !== undefined && (obj.referFull = message.referFull);
    message.referState !== undefined && (obj.referState = message.referState);
    message.referEntityType !== undefined && (obj.referEntityType = message.referEntityType
      ? MReferralReferEntityType.toJSON(message.referEntityType)
      : undefined);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.arbVenue !== undefined && (obj.arbVenue = message.arbVenue);
    message.arbShort !== undefined && (obj.arbShort = internalBooleanToJSON(message.arbShort));
    message.paymentPeriod !== undefined &&
      (obj.paymentPeriod = message.paymentPeriod ? MReferralPaymentPeriod.toJSON(message.paymentPeriod) : undefined);
    message.termNoticeDays !== undefined && (obj.termNoticeDays = message.termNoticeDays);
    message.nondisclosurePlan !== undefined && (obj.nondisclosurePlan = message.nondisclosurePlan
      ? MReferralNondisclosurePlan.toJSON(message.nondisclosurePlan)
      : undefined);
    if (message.initialLeadsList) {
      obj.initialLeadsList = message.initialLeadsList.map((e) => e);
    } else {
      obj.initialLeadsList = [];
    }
    if (message.initialProspectsList) {
      obj.initialProspectsList = message.initialProspectsList.map((e) => e);
    } else {
      obj.initialProspectsList = [];
    }
    message.commissionPercentage !== undefined && (obj.commissionPercentage = message.commissionPercentage);
    message.commissionTailMonths !== undefined && (obj.commissionTailMonths = message.commissionTailMonths);
    message.feePhaseout !== undefined &&
      (obj.feePhaseout = message.feePhaseout ? MReferralFeePhaseout.toJSON(message.feePhaseout) : undefined);
    message.phaseoutYears !== undefined && (obj.phaseoutYears = message.phaseoutYears);
    message.commissionTail !== undefined &&
      (obj.commissionTail = message.commissionTail
        ? MReferralCommissionTail.toJSON(message.commissionTail)
        : undefined);
    message.planTargets !== undefined && (obj.planTargets = internalBooleanToJSON(message.planTargets));
    message.initialLeads !== undefined && (obj.initialLeads = internalBooleanToJSON(message.initialLeads));
    message.initialProspects !== undefined && (obj.initialProspects = internalBooleanToJSON(message.initialProspects));
    message.nondisclosureYears !== undefined && (obj.nondisclosureYears = message.nondisclosureYears);
    return obj;
  },

  create<I extends Exact<DeepPartial<Referral>, I>>(base?: I): Referral {
    return Referral.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Referral>, I>>(object: I): Referral {
    const message = createBaseReferral();
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MReferralCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.referFull = object.referFull ?? "";
    message.referState = object.referState ?? "";
    message.referEntityType = (object.referEntityType !== undefined && object.referEntityType !== null)
      ? MReferralReferEntityType.fromPartial(object.referEntityType)
      : undefined;
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.arbVenue = object.arbVenue ?? "";
    message.arbShort = object.arbShort ?? 0;
    message.paymentPeriod = (object.paymentPeriod !== undefined && object.paymentPeriod !== null)
      ? MReferralPaymentPeriod.fromPartial(object.paymentPeriod)
      : undefined;
    message.termNoticeDays = object.termNoticeDays ?? 0;
    message.nondisclosurePlan = (object.nondisclosurePlan !== undefined && object.nondisclosurePlan !== null)
      ? MReferralNondisclosurePlan.fromPartial(object.nondisclosurePlan)
      : undefined;
    message.initialLeadsList = object.initialLeadsList?.map((e) => e) || [];
    message.initialProspectsList = object.initialProspectsList?.map((e) => e) || [];
    message.commissionPercentage = object.commissionPercentage ?? 0;
    message.commissionTailMonths = object.commissionTailMonths ?? 0;
    message.feePhaseout = (object.feePhaseout !== undefined && object.feePhaseout !== null)
      ? MReferralFeePhaseout.fromPartial(object.feePhaseout)
      : undefined;
    message.phaseoutYears = object.phaseoutYears ?? 0;
    message.commissionTail = (object.commissionTail !== undefined && object.commissionTail !== null)
      ? MReferralCommissionTail.fromPartial(object.commissionTail)
      : undefined;
    message.planTargets = object.planTargets ?? 0;
    message.initialLeads = object.initialLeads ?? 0;
    message.initialProspects = object.initialProspects ?? 0;
    message.nondisclosureYears = object.nondisclosureYears ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
