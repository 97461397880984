import { direction } from "html2canvas/dist/types/css/property-descriptors/direction";
import { useRef } from "react";
import { createEditor, Editor, Transforms } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";
import { CustomEditor, El } from "./types/custom-types";
import { deserialize } from "./utils";

export function editorDecorator(editor: CustomEditor) {
    const { isInline, isVoid, insertData, deleteBackward, deleteForward, deleteFragment } = editor;

    editor.insertData = (data: DataTransfer) => {
        const html = data.getData("text/html");

        if (!html) return insertData(data);

        const dom = new DOMParser().parseFromString(html, "text/html");

        const fragment = deserialize({count: 0}, dom.body as El);
        Transforms.insertFragment(editor, fragment);
    };
    editor.deleteForward = (...args) => {
        const [match] = Editor.nodes(editor, {
            match: (node: any) => {
                return node.type === "signature" || !!node.children?.find((n: any) => n?.type === "signature");
            },
        });

        if (match) {
            return;
        }

        deleteForward(...args);
    };
    editor.deleteFragment = (...args) => {
        const [match] = Editor.nodes(editor, {
            match: (node: any) => {
                return node.type === "signature" || !!node.children?.find((n: any) => n?.type === "signature");
            },
        });

        if (match) {
            return;
        }

        deleteFragment(...args);
    };

    editor.deleteBackward = (...args) => {
        const [match] = Editor.nodes(editor, {
            match: (node: any) => {
                return node.type === "signature" || !!node.children?.find((n: any) => n?.type === "signature");
            },
        });

        if (match) {
            return;
        }

        deleteBackward(...args);
    };

    editor.isInline = (element) => {
        return (
            ["link", "input", "signature", "page-break", "condition-inline"].includes(element.type || "") ||
            isInline(element)
        );
    };
    editor.isVoid = (element) => {
        return ["input", "signature", "page-break"].includes(element.type || "") || isVoid(element);
    };

    return editor;
}

export const useEditor = () => {
    const editorRef = useRef<CustomEditor>();
    if (!editorRef.current) editorRef.current = editorDecorator(withHistory(withReact(createEditor())));
    const editor = editorRef.current;
    return editor;
};
