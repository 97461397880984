import { AxiosResponse } from "axios";
import { PScrapeWebsiteRequest } from "../proto/PApi";
import { PLegalTopicsSurvey } from "../proto/PTracking";
import { request } from "./ApiCommon";

export function scriptsRetrieve(req: PScrapeWebsiteRequest, successCallback: Function, errorCallback: Function) {
    request("/api/v1/web-scraper", { method: "POST", data: PScrapeWebsiteRequest.toJSON(req) })
        ?.then((response) => successCallback(response.data))
        .catch(() => {
            errorCallback();
        }); // todo
}
