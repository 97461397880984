import { errorHandler } from "api-services/ErrorHandlingApi";
import { PFrontendError } from "proto/PFrontendError";

export class ErrorHandler {
    static init() {
        window.onerror = (message, source, lineno, colno, error) => {
            const details = JSON.stringify({
                message: message,
                source: source,
                lineno: lineno,
                colno: colno,
                error: error,
            });

            reportFEError("on error", details);
        };
    }
}

export function reportFEError(methodName: string, details: string) {
    const feError = PFrontendError.fromJSON({
        details: details,
        methodName: methodName,
    });

    errorHandler(feError);
}
