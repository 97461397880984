import { Dispatch } from "react";
import firebase from "../components/contexts/firebase";
import {
    PDocGetResponse,
    PDocShareType,
    PShareRequest,
    PSignRequest,
    PTemplateUpdateRequest
} from "../proto/PApi";
import { PLegalUnit } from "../proto/PLegalUnit";
import { draftsLoaded } from "../store/mainActions";
import { request } from "./ApiCommon";

function createNewDraft(wizard: PLegalUnit, successCallback: Function, failureCallback?: Function) {
    request("/api/v1/doc", { method: "PUT", data: PLegalUnit.toJSON(wizard) })
        ?.then((response) => successCallback(response.data))
        .catch((response) => failureCallback?.(response));
}

function getDoc(docId: string, successCallback: Function, failureCallback?: Function) {
    request(`/api/v1/doc/${docId}`, { method: "GET" })
        ?.then((response) => successCallback(PDocGetResponse.fromJSON(response.data)))
        .catch(() => {
            failureCallback?.();
        });
}

function updateDraft(draftId: string, wizard: PLegalUnit, successCallback: Function, failureCallback: Function) {
    request(`/api/v1/doc/${draftId}`, { method: "POST", data: PLegalUnit.toJSON(wizard) })
        ?.then((response) => successCallback(response.data))
        .catch((response) => failureCallback(response));
}

function updateDocTemplate(
    draftId: string,
    { templateId }: { templateId: string },
    successCallback?: Function,
    failureCallback?: Function
) {
    request(`/api/v1/doc/${draftId}/template`, { method: "POST", data: PTemplateUpdateRequest.toJSON({ templateId }) })
        ?.then((response) => successCallback?.(response.data))
        .catch((response) => failureCallback?.(response));
}

function getAllDocs(dispatch: Dispatch<any>, successCallback?: Function) {
    request("/api/v1/doc/all", { method: "GET" })?.then((response) => {
        dispatch(draftsLoaded(PDocGetResponse.fromJSON(response.data).docs));

        if (successCallback) {
            setTimeout(() => successCallback(), 1);
        }
    });
}

function deleteDraft(id: string, cb: Function) {
    request(`/api/v1/doc/${id}`, { method: "DELETE" })?.then(() => cb());
}

function shareDoc(id: string, username: string, cb: Function, paymentFailure: Function) {
    request(`/api/v1/doc/share/${id}`, { method: "POST", data: PShareRequest.toJSON({ username: username }) })
        ?.then(() => cb())
        .catch((e) => {
            if (e?.response?.status === 403) {
                paymentFailure();
            }
        });
}

function attachSignature(docId: string, sigBlog: string[], docShareType: DocShareType, cb: Function) {
    const pDocShareType = translateDocShareType(docShareType);

    request(`/api/v1/doc/sign/${docId}`, {
        method: "POST",
        data: PSignRequest.toJSON({ sigBlob: sigBlog, docShareType: pDocShareType }),
    })?.then(() => cb());
}

function reviewContract(docId: string, cb: Function, failureCallback: Function) {
    request(`/api/v1/doc/review/${docId}`, { method: "POST" })
        ?.then((res) => cb(res))
        ?.catch(() => failureCallback());
}

function withSecurityHeaders() {
    return firebase
        ?.auth()
        ?.currentUser?.getIdToken(false)
        .then((idToken) => {
            const auth = idToken && { Authorization: `Bearer ${idToken}` };

            return new Promise<any>((resolve, reject) => {
                resolve(auth);
            });
        });
}

function remindSigners(docId: string, cb: Function, failureCallback: Function) {
    request(`/api/v1/doc/remind/${docId}`, { method: "POST" })
    ?.then(() => cb())
    ?.catch(() => failureCallback());
}

function translateDocShareType(docShareType: DocShareType) {
    let pDocShareType: PDocShareType;
    switch (docShareType) {
        case "AGREEMENT":
            pDocShareType = PDocShareType.AGREEMENT;
            break;
        case "DOCUMENT":
            pDocShareType = PDocShareType.DOCUMENT;
            break;
        case "ONLY_OTHER_SIGNS":
            pDocShareType = PDocShareType.ONLY_OTHER_SIGNS;
            break;
        case "ONLY_OWNER_SIGNS":
            pDocShareType = PDocShareType.ONLY_OWNER_SIGNS;
            break;
        default:
            pDocShareType = PDocShareType.AGREEMENT;
    }

    return pDocShareType;
}

export const DocsApi = {createNewDraft, withSecurityHeaders, getDoc, updateDraft, updateDocTemplate, getAllDocs, deleteDraft, shareDoc, attachSignature, reviewContract, remindSigners};