import cx from "clsx";
import { Icon } from "components/Icon/Icon";
import kbImg from "img/icons/light-bulb.png";
import { nanoid } from "nanoid";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";
import { Actions } from "store";
import { ToggleMarkCommand } from "../commands";
import { CustomElement, Mark } from "../types/custom-types";

export const Button = ({ className, active, ...props }: any) => <span {...props} />;

const marksButtons: { mark: Mark; label: string | ReactNode; data: any }[] = [
    {
        mark: "bold",
        label: <Icon name={Icon.NAMES.FORMAT_BOLD} />,
        data: true,
    },
    {
        mark: "italic",
        label: <Icon name={Icon.NAMES.FORMAT_ITALIC} />,
        data: true,
    },
    {
        mark: "underline",
        label: <Icon name={Icon.NAMES.FORMAT_UNDERLINED} />,
        data: true,
    },
];

const blockMarks: {
    mark: CustomElement["type"] | "left" | "right" | "center" | "justify" | "";
    label: string | ReactNode;
    data: any;
}[] = [
    {
        mark: "left",
        label: <Icon name={Icon.NAMES.MENU_LEFT} />,
        data: { textAlign: "left" },
    },
    {
        mark: "center",
        label: <Icon name={Icon.NAMES.MENU} />,
        data: { textAlign: "center" },
    },
    {
        mark: "right",
        label: <Icon name={Icon.NAMES.MENU_RIGHT} />,
        data: { textAlign: "right" },
    },
    {
        mark: "justify",
        label: <Icon name={Icon.NAMES.MENU_JUSTIFY} />,
        data: { textAlign: "justify" },
    },
    {
        mark: "numbered-list",
        label: <Icon name={Icon.NAMES.OL} />,

        data: {},
    },
    {
        mark: "bulleted-list",
        label: <Icon name={Icon.NAMES.UL} />,

        data: {},
    },
    {
        mark: "heading-one",
        label: <Icon name={Icon.NAMES.LOOKS_ONE} />,
        data: {},
    },
    {
        mark: "heading-two",
        label: <Icon name={Icon.NAMES.LOOKS_TWO} />,
        data: {},
    },
    {
        mark: "heading-three",
        label: <Icon name={Icon.NAMES.LOOKS_3} />,
        data: {},
    },
    {
        mark: "heading-five",
        label: <Icon name={Icon.NAMES.LOOKS_5} />,
        data: {},
    },
    {
        mark: "pre-paragraph-margin",
        label: <Icon name={Icon.NAMES.FORMAT_INDENT_INCREASE} className="rotate-90" />,
        data: {},
    },
];

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const ToolBar = ({ userFreestyle }: { userFreestyle?: boolean }) => {
    const editor = useSlate();
    const dispatch = useDispatch();

    const isMarkActive = (mark: Mark): boolean => {
        try {
            const marks = Editor.marks(editor);

            return marks ? marks[mark] === true : false;
        } catch (error) {
            return false;
        }
    };

    const isBlockActive = (format: string, blockType: "type" | "align" = "type") => {
        const { selection } = editor;
        if (!selection) return false;

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
            })
        );

        return !!match;
    };

    const toggleMark = (mark: Mark, data: any): any => {
        return new ToggleMarkCommand(editor, mark, data).exucute();
    };

    const toggleBlock = (format: string, data?: any) => {
        const isActive = isBlockActive(format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                !!n.type &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format),
            split: true,
        });

        let newProperties: Partial<SlateElement>;
        if (TEXT_ALIGN_TYPES.includes(format)) {
            newProperties = {
                align: isActive ? undefined : format,
            };
        } else {
            newProperties = {
                [format]: isActive ? undefined : data,
                type: isActive ? "paragraph" : isList ? "list-item" : (format as any),
            };
        }
        Transforms.setNodes<SlateElement>(editor, newProperties);

        if (!isActive && isList) {
            const block = { type: format, children: [] };
            Transforms.wrapNodes(editor, block as any);
        }
    };

    const addKnowladgeBase = (kbText: string) => {
        const kbID = nanoid();

        const kb = { id: kbID, text: kbText };

        const [match] = Editor.nodes(editor, {
            match: (n: any) => n.type === "kb",
        });

        if (!kbText) {
            return alert(" text required");
        }

        if (match) {
            if (!window.confirm("Already marked. Would you like to override it?")) {
                return;
            }
        }

        Transforms.wrapNodes(
            editor,
            {
                type: "kb",
                kbId: kb.id,
                children: [],
            },
            { match: (n) => Editor.isBlock(editor, n) }
        );
        dispatch(Actions.agreementManager.addKnowlageBase(kb));
    };

    function showAddQuestion(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        dispatch(
            Actions.ui.openModal({
                name: "LEGAL_IDE_WIZARD_EDIT_KB",
                data: { kb: "", onSave: (kb: string) => addKnowladgeBase(kb) },
            })
        );
    }

    return (
        <div className="border-2 p-2 flex gap-1">
            {!userFreestyle && (
                <div
                    onClick={showAddQuestion}
                    className="flex flex-col group justify-center items-center flex-shrink-0 border rounded px-1"
                >
                    <img className="w-5 h-5" src={kbImg} alt="" />
                </div>
            )}

            {marksButtons.map((button) => {
                const isActive = isMarkActive(button.mark);

                return (
                    <span
                        className={cx(
                            "text-center text-xl p-1 font-semibold w-10 h-10 border rounded flex items-center justify-center",
                            isActive ? "text-primary" : "text-gray-400"
                        )}
                        key={button.mark}
                        onMouseDown={(event) => {
                            event?.preventDefault();
                            toggleMark(button.mark, button.data);
                        }}
                    >
                        {button.label}
                    </span>
                );
            })}
            {blockMarks.map((button, i) => {
                const isActive = isBlockActive(button.mark, TEXT_ALIGN_TYPES.includes(button.mark) ? "align" : "type");

                return (
                    <div
                        className={cx(
                            " text-xl p-1 font-semibold w-10 h-10 border rounded flex items-center justify-center",
                            isActive ? "text-primary" : "text-gray-400"
                        )}
                        key={i}
                        onMouseDown={(event: any) => {
                            event?.preventDefault();

                            toggleBlock(button.mark, button.data);
                        }}
                    >
                        {button.label}
                    </div>
                );
            })}
        </div>
    );
};
