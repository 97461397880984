import React from 'react'

import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';

import { MediaCommunitySkin, MediaOutlet, MediaPIPButton, MediaPlayer, MediaPoster } from '@vidstack/react';
import { MultiVideoProps } from 'apps/legal-ide/App/components/QuestionWizard/VideosCollection';

export default function VideoStack({multiVideoProps}: {multiVideoProps: MultiVideoProps}) {
  return (
    <MediaPlayer
  title={multiVideoProps.title}
  src={multiVideoProps.src}
  poster={multiVideoProps.poster}
  thumbnails={multiVideoProps.thumbnails}
  aspectRatio={16 / 9}
  crossorigin=""
>
  <MediaOutlet>
    <track
      src={multiVideoProps.chapters}
      srcLang="en-US"
      kind="chapters"
      default
    />
  </MediaOutlet>
  <MediaCommunitySkin />
</MediaPlayer>

  )
}
