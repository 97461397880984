import cx from "clsx";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { ReactNode, useState } from "react";
import { useTable } from "./TableContext";
import parse from "html-react-parser";

interface MenuItem {
    label: string;
    action: () => void;
}
interface Props {
    children: string | ReactNode;
    className?: string;
    isHeader?: boolean;
    menu?: MenuItem[];
    onChange?: (value: string) => void;
}

export const Cell = ({ className = "", children, isHeader = false, menu, onChange }: Props) => {
    const [editMode, setEditMode] = useState(false);

    const api = useTable();
    const Tag = isHeader ? "th" : "td";

    const handleClick = () => {
        if (isHeader && !api.isTableEditable()) return;
        if (typeof children !== "string") return;
        setEditMode(!editMode);
    };


    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger asChild className="ContextMenuTrigger">
                <Tag
                    onClick={handleClick}
                    className={cx("border relative h-10 px-2 overflow-hidden overflow-ellipsis", className)}
                >
                    {editMode ? (
                        <input
                            onChange={(event) => onChange?.(event.target.value)}
                            onBlur={handleClick}
                            className="text-accent w-full"
                            autoFocus
                            defaultValue={typeof children === "string" ? children : ""}
                        />
                    ) : !!children ? (
                        children
                    ) : (
                        <span className="underline">{parse("&nbsp;".repeat(20))}</span>
                    )}
                </Tag>
            </ContextMenu.Trigger>
            {!!menu && !!menu.length && api.isTableEditable() && (
                <ContextMenu.Portal>
                    <ContextMenu.Content className="ContextMenuContent">
                        {menu?.map((menuItem, i) => (
                            <ContextMenu.Item
                                onSelect={() => {
                                    menuItem.action();
                                }}
                                className="ContextMenuItem"
                            >
                                {menuItem.label}
                            </ContextMenu.Item>
                        ))}
                    </ContextMenu.Content>
                </ContextMenu.Portal>
            )}
        </ContextMenu.Root>
    );
};
