import { Cell } from "./Cell";
import { useTable } from "./TableContext";

interface ColumnProps {
    column?: Column;
    index: number;
    heading?: boolean;
}

export const Columns = () => {
    const api = useTable();

    const columns = api.getColumns();
    return (
        <tr>
            {!!api.options?.row?.heading && (
                <Column index={-1} heading column={{ id: "", name: api.options.column?.headingLabel ?? "" }} />
            )}

            {columns.map((column, i) => {
                return <Column key={column.id} column={column} index={!!api.options?.row?.heading ? i + 1 : i} />;
            })}
        </tr>
    );
};

export const Column = ({ column, index, heading = false }: ColumnProps) => {
    const api = useTable();

    if (!column) return null;
    return (
        <Cell
            onChange={(name) => api.updateColumnName({ columnId: column?.id, name: name, heading, index })}
            menu={heading ? [] : [{ label: "remove column", action: () => api.removeColumn(column?.id) }]}
            isHeader
            key={column?.id}
            className=""
        >
            {column?.name}
        </Cell>
    );
};
