/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EEmploymentNYGrantType {
  EmploymentNY_grantType_NA = 0,
  EmploymentNY_grantType_STOCKS = 1,
  EmploymentNY_grantType_SHARES = 2,
  UNRECOGNIZED = -1,
}

export function eEmploymentNYGrantTypeFromJSON(object: any): EEmploymentNYGrantType {
  switch (object) {
    case 0:
    case "EmploymentNY_grantType_NA":
      return EEmploymentNYGrantType.EmploymentNY_grantType_NA;
    case 1:
    case "EmploymentNY_grantType_STOCKS":
      return EEmploymentNYGrantType.EmploymentNY_grantType_STOCKS;
    case 2:
    case "EmploymentNY_grantType_SHARES":
      return EEmploymentNYGrantType.EmploymentNY_grantType_SHARES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EEmploymentNYGrantType.UNRECOGNIZED;
  }
}

export function eEmploymentNYGrantTypeToJSON(object: EEmploymentNYGrantType): string {
  switch (object) {
    case EEmploymentNYGrantType.EmploymentNY_grantType_NA:
      return "EmploymentNY_grantType_NA";
    case EEmploymentNYGrantType.EmploymentNY_grantType_STOCKS:
      return "EmploymentNY_grantType_STOCKS";
    case EEmploymentNYGrantType.EmploymentNY_grantType_SHARES:
      return "EmploymentNY_grantType_SHARES";
    case EEmploymentNYGrantType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EEmploymentNYVestingPeriod {
  EmploymentNY_vestingPeriod_NA = 0,
  EmploymentNY_vestingPeriod_MONTHLY = 1,
  EmploymentNY_vestingPeriod_QUARTERLY = 2,
  EmploymentNY_vestingPeriod_ANNUALLY = 3,
  UNRECOGNIZED = -1,
}

export function eEmploymentNYVestingPeriodFromJSON(object: any): EEmploymentNYVestingPeriod {
  switch (object) {
    case 0:
    case "EmploymentNY_vestingPeriod_NA":
      return EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_NA;
    case 1:
    case "EmploymentNY_vestingPeriod_MONTHLY":
      return EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_MONTHLY;
    case 2:
    case "EmploymentNY_vestingPeriod_QUARTERLY":
      return EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_QUARTERLY;
    case 3:
    case "EmploymentNY_vestingPeriod_ANNUALLY":
      return EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_ANNUALLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EEmploymentNYVestingPeriod.UNRECOGNIZED;
  }
}

export function eEmploymentNYVestingPeriodToJSON(object: EEmploymentNYVestingPeriod): string {
  switch (object) {
    case EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_NA:
      return "EmploymentNY_vestingPeriod_NA";
    case EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_MONTHLY:
      return "EmploymentNY_vestingPeriod_MONTHLY";
    case EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_QUARTERLY:
      return "EmploymentNY_vestingPeriod_QUARTERLY";
    case EEmploymentNYVestingPeriod.EmploymentNY_vestingPeriod_ANNUALLY:
      return "EmploymentNY_vestingPeriod_ANNUALLY";
    case EEmploymentNYVestingPeriod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EEmploymentNYPTOType {
  EmploymentNY_PTOType_NA = 0,
  EmploymentNY_PTOType_REASONABLE = 1,
  EmploymentNY_PTOType_SPECIFIC = 2,
  UNRECOGNIZED = -1,
}

export function eEmploymentNYPTOTypeFromJSON(object: any): EEmploymentNYPTOType {
  switch (object) {
    case 0:
    case "EmploymentNY_PTOType_NA":
      return EEmploymentNYPTOType.EmploymentNY_PTOType_NA;
    case 1:
    case "EmploymentNY_PTOType_REASONABLE":
      return EEmploymentNYPTOType.EmploymentNY_PTOType_REASONABLE;
    case 2:
    case "EmploymentNY_PTOType_SPECIFIC":
      return EEmploymentNYPTOType.EmploymentNY_PTOType_SPECIFIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EEmploymentNYPTOType.UNRECOGNIZED;
  }
}

export function eEmploymentNYPTOTypeToJSON(object: EEmploymentNYPTOType): string {
  switch (object) {
    case EEmploymentNYPTOType.EmploymentNY_PTOType_NA:
      return "EmploymentNY_PTOType_NA";
    case EEmploymentNYPTOType.EmploymentNY_PTOType_REASONABLE:
      return "EmploymentNY_PTOType_REASONABLE";
    case EEmploymentNYPTOType.EmploymentNY_PTOType_SPECIFIC:
      return "EmploymentNY_PTOType_SPECIFIC";
    case EEmploymentNYPTOType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EEmploymentNYLocationType {
  EmploymentNY_locationType_NA = 0,
  EmploymentNY_locationType_REMOTE = 1,
  EmploymentNY_locationType_HYBRID = 2,
  EmploymentNY_locationType_OFFICE = 3,
  UNRECOGNIZED = -1,
}

export function eEmploymentNYLocationTypeFromJSON(object: any): EEmploymentNYLocationType {
  switch (object) {
    case 0:
    case "EmploymentNY_locationType_NA":
      return EEmploymentNYLocationType.EmploymentNY_locationType_NA;
    case 1:
    case "EmploymentNY_locationType_REMOTE":
      return EEmploymentNYLocationType.EmploymentNY_locationType_REMOTE;
    case 2:
    case "EmploymentNY_locationType_HYBRID":
      return EEmploymentNYLocationType.EmploymentNY_locationType_HYBRID;
    case 3:
    case "EmploymentNY_locationType_OFFICE":
      return EEmploymentNYLocationType.EmploymentNY_locationType_OFFICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EEmploymentNYLocationType.UNRECOGNIZED;
  }
}

export function eEmploymentNYLocationTypeToJSON(object: EEmploymentNYLocationType): string {
  switch (object) {
    case EEmploymentNYLocationType.EmploymentNY_locationType_NA:
      return "EmploymentNY_locationType_NA";
    case EEmploymentNYLocationType.EmploymentNY_locationType_REMOTE:
      return "EmploymentNY_locationType_REMOTE";
    case EEmploymentNYLocationType.EmploymentNY_locationType_HYBRID:
      return "EmploymentNY_locationType_HYBRID";
    case EEmploymentNYLocationType.EmploymentNY_locationType_OFFICE:
      return "EmploymentNY_locationType_OFFICE";
    case EEmploymentNYLocationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EEmploymentNYExemption {
  EmploymentNY_exemption_NA = 0,
  EmploymentNY_exemption_EXEMPT = 1,
  EmploymentNY_exemption_NON_EXEMPT = 2,
  UNRECOGNIZED = -1,
}

export function eEmploymentNYExemptionFromJSON(object: any): EEmploymentNYExemption {
  switch (object) {
    case 0:
    case "EmploymentNY_exemption_NA":
      return EEmploymentNYExemption.EmploymentNY_exemption_NA;
    case 1:
    case "EmploymentNY_exemption_EXEMPT":
      return EEmploymentNYExemption.EmploymentNY_exemption_EXEMPT;
    case 2:
    case "EmploymentNY_exemption_NON_EXEMPT":
      return EEmploymentNYExemption.EmploymentNY_exemption_NON_EXEMPT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EEmploymentNYExemption.UNRECOGNIZED;
  }
}

export function eEmploymentNYExemptionToJSON(object: EEmploymentNYExemption): string {
  switch (object) {
    case EEmploymentNYExemption.EmploymentNY_exemption_NA:
      return "EmploymentNY_exemption_NA";
    case EEmploymentNYExemption.EmploymentNY_exemption_EXEMPT:
      return "EmploymentNY_exemption_EXEMPT";
    case EEmploymentNYExemption.EmploymentNY_exemption_NON_EXEMPT:
      return "EmploymentNY_exemption_NON_EXEMPT";
    case EEmploymentNYExemption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MEmploymentNYGrantType {
  optionDbValue: EEmploymentNYGrantType;
  other: string;
}

export interface MEmploymentNYVestingPeriod {
  optionDbValue: EEmploymentNYVestingPeriod;
  other: string;
}

export interface MEmploymentNYPTOType {
  optionDbValue: EEmploymentNYPTOType;
  other: string;
}

export interface MEmploymentNYLocationType {
  optionDbValue: EEmploymentNYLocationType;
  other: string;
}

export interface MEmploymentNYExemption {
  optionDbValue: EEmploymentNYExemption;
  other: string;
}

export interface EmploymentNY {
  offerDate: Date | undefined;
  employeeName: string;
  employeeTitle: string;
  supervisor: string;
  startDate: Date | undefined;
  duties: string;
  annualSalary: number;
  hourlyRate: number;
  hours: string;
  equityAmount: number;
  isTravel: InternalBoolean;
  equityGrant: InternalBoolean;
  grantType: MEmploymentNYGrantType | undefined;
  cliffDuration: number;
  vestingPeriod: MEmploymentNYVestingPeriod | undefined;
  noticeDays: number;
  businessDescription: string;
  isCliff: InternalBoolean;
  PTOType: MEmploymentNYPTOType | undefined;
  isFourOOneK: InternalBoolean;
  priorInventions: string[];
  PTOdays: number;
  vestingDuration: number;
  expirationDate: Date | undefined;
  locationType: MEmploymentNYLocationType | undefined;
  hasPriorInventions: InternalBoolean;
  hasBenefits: InternalBoolean;
  isVesting: InternalBoolean;
  exemption: MEmploymentNYExemption | undefined;
  addressNeedToBeSet: InternalBoolean;
}

function createBaseMEmploymentNYGrantType(): MEmploymentNYGrantType {
  return { optionDbValue: 0, other: "" };
}

export const MEmploymentNYGrantType = {
  encode(message: MEmploymentNYGrantType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MEmploymentNYGrantType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMEmploymentNYGrantType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MEmploymentNYGrantType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eEmploymentNYGrantTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MEmploymentNYGrantType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eEmploymentNYGrantTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MEmploymentNYGrantType>, I>>(base?: I): MEmploymentNYGrantType {
    return MEmploymentNYGrantType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MEmploymentNYGrantType>, I>>(object: I): MEmploymentNYGrantType {
    const message = createBaseMEmploymentNYGrantType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMEmploymentNYVestingPeriod(): MEmploymentNYVestingPeriod {
  return { optionDbValue: 0, other: "" };
}

export const MEmploymentNYVestingPeriod = {
  encode(message: MEmploymentNYVestingPeriod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MEmploymentNYVestingPeriod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMEmploymentNYVestingPeriod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MEmploymentNYVestingPeriod {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eEmploymentNYVestingPeriodFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MEmploymentNYVestingPeriod): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eEmploymentNYVestingPeriodToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MEmploymentNYVestingPeriod>, I>>(base?: I): MEmploymentNYVestingPeriod {
    return MEmploymentNYVestingPeriod.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MEmploymentNYVestingPeriod>, I>>(object: I): MEmploymentNYVestingPeriod {
    const message = createBaseMEmploymentNYVestingPeriod();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMEmploymentNYPTOType(): MEmploymentNYPTOType {
  return { optionDbValue: 0, other: "" };
}

export const MEmploymentNYPTOType = {
  encode(message: MEmploymentNYPTOType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MEmploymentNYPTOType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMEmploymentNYPTOType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MEmploymentNYPTOType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eEmploymentNYPTOTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MEmploymentNYPTOType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eEmploymentNYPTOTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MEmploymentNYPTOType>, I>>(base?: I): MEmploymentNYPTOType {
    return MEmploymentNYPTOType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MEmploymentNYPTOType>, I>>(object: I): MEmploymentNYPTOType {
    const message = createBaseMEmploymentNYPTOType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMEmploymentNYLocationType(): MEmploymentNYLocationType {
  return { optionDbValue: 0, other: "" };
}

export const MEmploymentNYLocationType = {
  encode(message: MEmploymentNYLocationType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MEmploymentNYLocationType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMEmploymentNYLocationType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MEmploymentNYLocationType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eEmploymentNYLocationTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MEmploymentNYLocationType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eEmploymentNYLocationTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MEmploymentNYLocationType>, I>>(base?: I): MEmploymentNYLocationType {
    return MEmploymentNYLocationType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MEmploymentNYLocationType>, I>>(object: I): MEmploymentNYLocationType {
    const message = createBaseMEmploymentNYLocationType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMEmploymentNYExemption(): MEmploymentNYExemption {
  return { optionDbValue: 0, other: "" };
}

export const MEmploymentNYExemption = {
  encode(message: MEmploymentNYExemption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MEmploymentNYExemption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMEmploymentNYExemption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MEmploymentNYExemption {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eEmploymentNYExemptionFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MEmploymentNYExemption): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined && (obj.optionDbValue = eEmploymentNYExemptionToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MEmploymentNYExemption>, I>>(base?: I): MEmploymentNYExemption {
    return MEmploymentNYExemption.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MEmploymentNYExemption>, I>>(object: I): MEmploymentNYExemption {
    const message = createBaseMEmploymentNYExemption();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseEmploymentNY(): EmploymentNY {
  return {
    offerDate: undefined,
    employeeName: "",
    employeeTitle: "",
    supervisor: "",
    startDate: undefined,
    duties: "",
    annualSalary: 0,
    hourlyRate: 0,
    hours: "",
    equityAmount: 0,
    isTravel: 0,
    equityGrant: 0,
    grantType: undefined,
    cliffDuration: 0,
    vestingPeriod: undefined,
    noticeDays: 0,
    businessDescription: "",
    isCliff: 0,
    PTOType: undefined,
    isFourOOneK: 0,
    priorInventions: [],
    PTOdays: 0,
    vestingDuration: 0,
    expirationDate: undefined,
    locationType: undefined,
    hasPriorInventions: 0,
    hasBenefits: 0,
    isVesting: 0,
    exemption: undefined,
    addressNeedToBeSet: 0,
  };
}

export const EmploymentNY = {
  encode(message: EmploymentNY, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offerDate !== undefined) {
      Timestamp.encode(toTimestamp(message.offerDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.employeeName !== "") {
      writer.uint32(18).string(message.employeeName);
    }
    if (message.employeeTitle !== "") {
      writer.uint32(26).string(message.employeeTitle);
    }
    if (message.supervisor !== "") {
      writer.uint32(34).string(message.supervisor);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(42).fork()).ldelim();
    }
    if (message.duties !== "") {
      writer.uint32(50).string(message.duties);
    }
    if (message.annualSalary !== 0) {
      writer.uint32(61).float(message.annualSalary);
    }
    if (message.hourlyRate !== 0) {
      writer.uint32(69).float(message.hourlyRate);
    }
    if (message.hours !== "") {
      writer.uint32(74).string(message.hours);
    }
    if (message.equityAmount !== 0) {
      writer.uint32(85).float(message.equityAmount);
    }
    if (message.isTravel !== 0) {
      writer.uint32(88).int32(message.isTravel);
    }
    if (message.equityGrant !== 0) {
      writer.uint32(96).int32(message.equityGrant);
    }
    if (message.grantType !== undefined) {
      MEmploymentNYGrantType.encode(message.grantType, writer.uint32(106).fork()).ldelim();
    }
    if (message.cliffDuration !== 0) {
      writer.uint32(117).float(message.cliffDuration);
    }
    if (message.vestingPeriod !== undefined) {
      MEmploymentNYVestingPeriod.encode(message.vestingPeriod, writer.uint32(122).fork()).ldelim();
    }
    if (message.noticeDays !== 0) {
      writer.uint32(133).float(message.noticeDays);
    }
    if (message.businessDescription !== "") {
      writer.uint32(138).string(message.businessDescription);
    }
    if (message.isCliff !== 0) {
      writer.uint32(144).int32(message.isCliff);
    }
    if (message.PTOType !== undefined) {
      MEmploymentNYPTOType.encode(message.PTOType, writer.uint32(154).fork()).ldelim();
    }
    if (message.isFourOOneK !== 0) {
      writer.uint32(160).int32(message.isFourOOneK);
    }
    for (const v of message.priorInventions) {
      writer.uint32(170).string(v!);
    }
    if (message.PTOdays !== 0) {
      writer.uint32(181).float(message.PTOdays);
    }
    if (message.vestingDuration !== 0) {
      writer.uint32(189).float(message.vestingDuration);
    }
    if (message.expirationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationDate), writer.uint32(194).fork()).ldelim();
    }
    if (message.locationType !== undefined) {
      MEmploymentNYLocationType.encode(message.locationType, writer.uint32(202).fork()).ldelim();
    }
    if (message.hasPriorInventions !== 0) {
      writer.uint32(208).int32(message.hasPriorInventions);
    }
    if (message.hasBenefits !== 0) {
      writer.uint32(216).int32(message.hasBenefits);
    }
    if (message.isVesting !== 0) {
      writer.uint32(224).int32(message.isVesting);
    }
    if (message.exemption !== undefined) {
      MEmploymentNYExemption.encode(message.exemption, writer.uint32(234).fork()).ldelim();
    }
    if (message.addressNeedToBeSet !== 0) {
      writer.uint32(240).int32(message.addressNeedToBeSet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmploymentNY {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmploymentNY();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.offerDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.employeeName = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.employeeTitle = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.supervisor = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.duties = reader.string();
          continue;
        case 7:
          if (tag != 61) {
            break;
          }

          message.annualSalary = reader.float();
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.hourlyRate = reader.float();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.hours = reader.string();
          continue;
        case 10:
          if (tag != 85) {
            break;
          }

          message.equityAmount = reader.float();
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.isTravel = reader.int32() as any;
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.equityGrant = reader.int32() as any;
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.grantType = MEmploymentNYGrantType.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 117) {
            break;
          }

          message.cliffDuration = reader.float();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.vestingPeriod = MEmploymentNYVestingPeriod.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag != 133) {
            break;
          }

          message.noticeDays = reader.float();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.businessDescription = reader.string();
          continue;
        case 18:
          if (tag != 144) {
            break;
          }

          message.isCliff = reader.int32() as any;
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.PTOType = MEmploymentNYPTOType.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.isFourOOneK = reader.int32() as any;
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.priorInventions.push(reader.string());
          continue;
        case 22:
          if (tag != 181) {
            break;
          }

          message.PTOdays = reader.float();
          continue;
        case 23:
          if (tag != 189) {
            break;
          }

          message.vestingDuration = reader.float();
          continue;
        case 24:
          if (tag != 194) {
            break;
          }

          message.expirationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag != 202) {
            break;
          }

          message.locationType = MEmploymentNYLocationType.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag != 208) {
            break;
          }

          message.hasPriorInventions = reader.int32() as any;
          continue;
        case 27:
          if (tag != 216) {
            break;
          }

          message.hasBenefits = reader.int32() as any;
          continue;
        case 28:
          if (tag != 224) {
            break;
          }

          message.isVesting = reader.int32() as any;
          continue;
        case 29:
          if (tag != 234) {
            break;
          }

          message.exemption = MEmploymentNYExemption.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag != 240) {
            break;
          }

          message.addressNeedToBeSet = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmploymentNY {
    return {
      offerDate: isSet(object.offerDate) ? fromJsonTimestamp(object.offerDate) : undefined,
      employeeName: isSet(object.employeeName) ? String(object.employeeName) : "",
      employeeTitle: isSet(object.employeeTitle) ? String(object.employeeTitle) : "",
      supervisor: isSet(object.supervisor) ? String(object.supervisor) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      duties: isSet(object.duties) ? String(object.duties) : "",
      annualSalary: isSet(object.annualSalary) ? Number(object.annualSalary) : 0,
      hourlyRate: isSet(object.hourlyRate) ? Number(object.hourlyRate) : 0,
      hours: isSet(object.hours) ? String(object.hours) : "",
      equityAmount: isSet(object.equityAmount) ? Number(object.equityAmount) : 0,
      isTravel: isSet(object.isTravel) ? internalBooleanFromJSON(object.isTravel) : 0,
      equityGrant: isSet(object.equityGrant) ? internalBooleanFromJSON(object.equityGrant) : 0,
      grantType: isSet(object.grantType) ? MEmploymentNYGrantType.fromJSON(object.grantType) : undefined,
      cliffDuration: isSet(object.cliffDuration) ? Number(object.cliffDuration) : 0,
      vestingPeriod: isSet(object.vestingPeriod)
        ? MEmploymentNYVestingPeriod.fromJSON(object.vestingPeriod)
        : undefined,
      noticeDays: isSet(object.noticeDays) ? Number(object.noticeDays) : 0,
      businessDescription: isSet(object.businessDescription) ? String(object.businessDescription) : "",
      isCliff: isSet(object.isCliff) ? internalBooleanFromJSON(object.isCliff) : 0,
      PTOType: isSet(object.PTOType) ? MEmploymentNYPTOType.fromJSON(object.PTOType) : undefined,
      isFourOOneK: isSet(object.isFourOOneK) ? internalBooleanFromJSON(object.isFourOOneK) : 0,
      priorInventions: Array.isArray(object?.priorInventions) ? object.priorInventions.map((e: any) => String(e)) : [],
      PTOdays: isSet(object.PTOdays) ? Number(object.PTOdays) : 0,
      vestingDuration: isSet(object.vestingDuration) ? Number(object.vestingDuration) : 0,
      expirationDate: isSet(object.expirationDate) ? fromJsonTimestamp(object.expirationDate) : undefined,
      locationType: isSet(object.locationType) ? MEmploymentNYLocationType.fromJSON(object.locationType) : undefined,
      hasPriorInventions: isSet(object.hasPriorInventions) ? internalBooleanFromJSON(object.hasPriorInventions) : 0,
      hasBenefits: isSet(object.hasBenefits) ? internalBooleanFromJSON(object.hasBenefits) : 0,
      isVesting: isSet(object.isVesting) ? internalBooleanFromJSON(object.isVesting) : 0,
      exemption: isSet(object.exemption) ? MEmploymentNYExemption.fromJSON(object.exemption) : undefined,
      addressNeedToBeSet: isSet(object.addressNeedToBeSet) ? internalBooleanFromJSON(object.addressNeedToBeSet) : 0,
    };
  },

  toJSON(message: EmploymentNY): unknown {
    const obj: any = {};
    message.offerDate !== undefined && (obj.offerDate = message.offerDate.toISOString());
    message.employeeName !== undefined && (obj.employeeName = message.employeeName);
    message.employeeTitle !== undefined && (obj.employeeTitle = message.employeeTitle);
    message.supervisor !== undefined && (obj.supervisor = message.supervisor);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.duties !== undefined && (obj.duties = message.duties);
    message.annualSalary !== undefined && (obj.annualSalary = message.annualSalary);
    message.hourlyRate !== undefined && (obj.hourlyRate = message.hourlyRate);
    message.hours !== undefined && (obj.hours = message.hours);
    message.equityAmount !== undefined && (obj.equityAmount = message.equityAmount);
    message.isTravel !== undefined && (obj.isTravel = internalBooleanToJSON(message.isTravel));
    message.equityGrant !== undefined && (obj.equityGrant = internalBooleanToJSON(message.equityGrant));
    message.grantType !== undefined &&
      (obj.grantType = message.grantType ? MEmploymentNYGrantType.toJSON(message.grantType) : undefined);
    message.cliffDuration !== undefined && (obj.cliffDuration = message.cliffDuration);
    message.vestingPeriod !== undefined &&
      (obj.vestingPeriod = message.vestingPeriod
        ? MEmploymentNYVestingPeriod.toJSON(message.vestingPeriod)
        : undefined);
    message.noticeDays !== undefined && (obj.noticeDays = message.noticeDays);
    message.businessDescription !== undefined && (obj.businessDescription = message.businessDescription);
    message.isCliff !== undefined && (obj.isCliff = internalBooleanToJSON(message.isCliff));
    message.PTOType !== undefined &&
      (obj.PTOType = message.PTOType ? MEmploymentNYPTOType.toJSON(message.PTOType) : undefined);
    message.isFourOOneK !== undefined && (obj.isFourOOneK = internalBooleanToJSON(message.isFourOOneK));
    if (message.priorInventions) {
      obj.priorInventions = message.priorInventions.map((e) => e);
    } else {
      obj.priorInventions = [];
    }
    message.PTOdays !== undefined && (obj.PTOdays = message.PTOdays);
    message.vestingDuration !== undefined && (obj.vestingDuration = message.vestingDuration);
    message.expirationDate !== undefined && (obj.expirationDate = message.expirationDate.toISOString());
    message.locationType !== undefined &&
      (obj.locationType = message.locationType ? MEmploymentNYLocationType.toJSON(message.locationType) : undefined);
    message.hasPriorInventions !== undefined &&
      (obj.hasPriorInventions = internalBooleanToJSON(message.hasPriorInventions));
    message.hasBenefits !== undefined && (obj.hasBenefits = internalBooleanToJSON(message.hasBenefits));
    message.isVesting !== undefined && (obj.isVesting = internalBooleanToJSON(message.isVesting));
    message.exemption !== undefined &&
      (obj.exemption = message.exemption ? MEmploymentNYExemption.toJSON(message.exemption) : undefined);
    message.addressNeedToBeSet !== undefined &&
      (obj.addressNeedToBeSet = internalBooleanToJSON(message.addressNeedToBeSet));
    return obj;
  },

  create<I extends Exact<DeepPartial<EmploymentNY>, I>>(base?: I): EmploymentNY {
    return EmploymentNY.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmploymentNY>, I>>(object: I): EmploymentNY {
    const message = createBaseEmploymentNY();
    message.offerDate = object.offerDate ?? undefined;
    message.employeeName = object.employeeName ?? "";
    message.employeeTitle = object.employeeTitle ?? "";
    message.supervisor = object.supervisor ?? "";
    message.startDate = object.startDate ?? undefined;
    message.duties = object.duties ?? "";
    message.annualSalary = object.annualSalary ?? 0;
    message.hourlyRate = object.hourlyRate ?? 0;
    message.hours = object.hours ?? "";
    message.equityAmount = object.equityAmount ?? 0;
    message.isTravel = object.isTravel ?? 0;
    message.equityGrant = object.equityGrant ?? 0;
    message.grantType = (object.grantType !== undefined && object.grantType !== null)
      ? MEmploymentNYGrantType.fromPartial(object.grantType)
      : undefined;
    message.cliffDuration = object.cliffDuration ?? 0;
    message.vestingPeriod = (object.vestingPeriod !== undefined && object.vestingPeriod !== null)
      ? MEmploymentNYVestingPeriod.fromPartial(object.vestingPeriod)
      : undefined;
    message.noticeDays = object.noticeDays ?? 0;
    message.businessDescription = object.businessDescription ?? "";
    message.isCliff = object.isCliff ?? 0;
    message.PTOType = (object.PTOType !== undefined && object.PTOType !== null)
      ? MEmploymentNYPTOType.fromPartial(object.PTOType)
      : undefined;
    message.isFourOOneK = object.isFourOOneK ?? 0;
    message.priorInventions = object.priorInventions?.map((e) => e) || [];
    message.PTOdays = object.PTOdays ?? 0;
    message.vestingDuration = object.vestingDuration ?? 0;
    message.expirationDate = object.expirationDate ?? undefined;
    message.locationType = (object.locationType !== undefined && object.locationType !== null)
      ? MEmploymentNYLocationType.fromPartial(object.locationType)
      : undefined;
    message.hasPriorInventions = object.hasPriorInventions ?? 0;
    message.hasBenefits = object.hasBenefits ?? 0;
    message.isVesting = object.isVesting ?? 0;
    message.exemption = (object.exemption !== undefined && object.exemption !== null)
      ? MEmploymentNYExemption.fromPartial(object.exemption)
      : undefined;
    message.addressNeedToBeSet = object.addressNeedToBeSet ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
