/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface UnilateralNDA {
  governLawState: string;
  venue: string;
  disclosingParty: string;
  receivingParty: string;
}

function createBaseUnilateralNDA(): UnilateralNDA {
  return { governLawState: "", venue: "", disclosingParty: "", receivingParty: "" };
}

export const UnilateralNDA = {
  encode(message: UnilateralNDA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.governLawState !== "") {
      writer.uint32(10).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(18).string(message.venue);
    }
    if (message.disclosingParty !== "") {
      writer.uint32(26).string(message.disclosingParty);
    }
    if (message.receivingParty !== "") {
      writer.uint32(34).string(message.receivingParty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnilateralNDA {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnilateralNDA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.disclosingParty = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.receivingParty = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnilateralNDA {
    return {
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      disclosingParty: isSet(object.disclosingParty) ? String(object.disclosingParty) : "",
      receivingParty: isSet(object.receivingParty) ? String(object.receivingParty) : "",
    };
  },

  toJSON(message: UnilateralNDA): unknown {
    const obj: any = {};
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.disclosingParty !== undefined && (obj.disclosingParty = message.disclosingParty);
    message.receivingParty !== undefined && (obj.receivingParty = message.receivingParty);
    return obj;
  },

  create<I extends Exact<DeepPartial<UnilateralNDA>, I>>(base?: I): UnilateralNDA {
    return UnilateralNDA.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UnilateralNDA>, I>>(object: I): UnilateralNDA {
    const message = createBaseUnilateralNDA();
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.disclosingParty = object.disclosingParty ?? "";
    message.receivingParty = object.receivingParty ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
