import clsx from "clsx";
import { nanoid } from "nanoid";
import { useRef } from "react";

type Props = {
    open: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
};
export const BigToggle = (props: Props) => {
    const { open, onChange } = props;
    const ref = useRef<HTMLInputElement>(null);
    const toggle_id = nanoid();

    return (
        <label htmlFor={toggle_id} className="bg-primaryVeryLight w-50 h-12 block rounded-full relative cursor-pointer">
            <div
                className={clsx(
                    "absolute flex justify-center items-center h-[2.7rem] w-28 rounded-full top-[0.15rem] transition-all",
                    open ? "bg-secondary" : "bg-[#e1dccc]"
                )}
                style={{
                    left: open ? "42%" : "2%",
                }}
            >
                <span className={`text-lg ${open ? "text-darkIndigo" : "text-darkIndigo"} font-medium`}>{open ? "Yes" : "No"}</span>
            </div>
            <input
                disabled={props.disabled}
                checked={open}
                onChange={() => {
                    onChange?.(ref.current?.checked ?? false);
                }}
                ref={ref}
                id={toggle_id}
                hidden
                type="checkbox"
            />
        </label>
    );
};
