import { useCallback, useLayoutEffect, useRef, useState } from "react";

export const useMouse = <T extends HTMLElement>() => {
    const ref = useRef<T | null>(null);
    const [state, setState] = useState({
        isIn: false,
    });

    const callbackIn = useCallback((event: MouseEvent) => {
        setState({ ...state, isIn: true });
    }, []);
    const callbackOut = useCallback((event: MouseEvent) => {
        setState({ ...state, isIn: false });
    }, []);
    useLayoutEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("mouseenter", callbackIn);
            ref.current.addEventListener("mouseleave", callbackOut);
        }
        return () => {
            ref.current?.removeEventListener("mouseenter", callbackIn);
            ref.current?.removeEventListener("mouseenter", callbackOut);
        };
    }, [callbackIn, callbackOut]);

    return { ref, ...state };
};
