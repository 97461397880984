import { DocsApi } from "api-services/DocsApi";
import { useContext, useEffect, createRef, useState } from "react";
import { Actions, useAppDispatch, useAppSelector } from "store";
import { activateDraft, callDialog } from "store/mainActions";
import { AgreementContext } from "../WizardUi/AgreementContext/AgreementContext";
import { autoDocService } from "../WizardUi/auto-contracts";
import { PDoc } from "proto/PDoc";
import { callToSaveDraft } from "../WizardUi/WizardForm";
import { useHistory, useLocation } from "react-router";
import { DocPreviewContent, HeadlessDocPreview } from "components/wizard-display/agreements/DocPreview";
import { Button, Container } from "components";
import { deserialize } from "../../Editor/utils";
import { Descendant } from "slate";
import { getFullDocId } from "../WizardUi/AgreementContext/shared-api";
import { RichEditor } from "../../Editor/Editor";
import { useEditor } from "../../Editor/hooks";
import { DialogType } from "store/mainTypes";
import { isProd } from "components/utils/EnvResolver";
import { behaviorsApi } from "api-services/behaviorsApi";

export const FreeStyleEditor = () => {
    const agreementContext = useContext(AgreementContext);
    const activeDraft = agreementContext?.getActiveDraft();
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);
    const dbUser = useAppSelector((state) => state.mainReducer.dbUser);
    const location = useLocation();

    const history = useHistory();

    const editor = useEditor();

    const [value, setValue] = useState<Descendant[] | null>(null);

    const htmlRef = createRef<HTMLDivElement>();

    const dispatch = useAppDispatch();

    const draftId = location.pathname.split("/").slice(1, -1).join("/");

    useEffect(() => {
        DocsApi.getAllDocs(dispatch);

        DocsApi.getDoc(draftId, (resp: any) => {
            if (resp.docs && resp.docs.length === 1) {
                let savedDraft = resp.docs[0];
                let translated = savedDraft.legalUnit?.kind || "";

                let lu: any = savedDraft.legalUnit!; // todo - not any

                // todo
                let newWiz = {
                    docVersion: "",
                    ...lu,
                    [translated]: {
                        ...lu[translated],
                        accountName: dbUser?.accountName!,
                        usState: dbUser?.usState!,
                        title: dbUser?.title || "",

                        // todo ADDRESS: fix part of refactoring and migration
                        addressNeedToBeSet: !dbUser?.address || lu?.[translated]?.addressNeedToBeSet == 2 ? 2 : 1,
                    },
                };

                let draft = PDoc.fromJSON({ ...savedDraft, legalUnit: newWiz });

                dispatch(activateDraft(draft));

                callToSaveDraft(location, newWiz);
            }
        });

        return () => {
            dispatch(activateDraft(undefined));
            agreementContext?.cleanup();
        };
    }, []);

    useEffect(() => {
        if (!activeDraft?.legalUnit?.docVersion) return;
        agreementContext?.loadAgreement();
    }, [activeDraft?.legalUnit?.docVersion]);

    const onSave = async () => {
        if (!activeDraft?.legalUnit) return;

        const freeStyleSrc = JSON.stringify(value);
        const newDraft = { ...activeDraft.legalUnit, freestyleSrc: freeStyleSrc };
        const newDoc = PDoc.fromJSON({ ...activeDraft, legalUnit: newDraft });

        DocsApi.updateDraft(
            draftId,
            newDraft,
            () => {
                dispatch(callDialog(DialogType.SUCCESS, "Your changes have been saved"))
                console.log("save success");

                DocsApi.getAllDocs(dispatch);
                dispatch(activateDraft(newDoc));
		
		if (isProd()) {
            		behaviorsApi.track({ type: "FREE_TEXT_EDIT", payload: activeDraft.id });
        	}
            },
            () => {
                dispatch(callDialog(DialogType.FAILURE, "Failed to save your changes"))
            }
        );
    };

    useEffect(() => {
        if (value || !activateDraft || !currentAgreement) return;

        try {
            if (activeDraft?.legalUnit?.freestyleSrc) return setValue(JSON.parse(activeDraft.legalUnit.freestyleSrc));

            if (htmlRef.current) return setValue(deserialize({count: 0}, htmlRef.current as any).filter((el: any) => el !== null));
        } catch (error) {
            setValue(null);
        }
    }, [currentAgreement, activeDraft]);

    if (!currentAgreement || !activeDraft) {
        return null;
    }

    return (
        <div className="">
            <div className="bg-white shadow-md border h-24 lg:h-32">
                <Container className="flex  items-center h-full gap-4">
                    <Button
                        variant="contained"
                        color="skyBlue"
                        onClick={() => {
                            history.push(
                                draftId +
                                    "/" +
                                    activeDraft.legalUnit?.lastStep ?? "A/1"
                            );
                        }}
                    >
                        Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={onSave}>
                        Save
                    </Button>
                </Container>
            </div>
            <div className="bg-white mx-auto shadow-md rounded p-6 max-w-5xl mt-8">
                {!!value && (
                    <RichEditor
                        value={value}
                        onChange={(value) => {
                            setValue(value);
                        }}
                        mode="edit"
                        editor={editor}
                        userFreestyle
                    />
                )}
            </div>
            <div className="">{!!currentAgreement && <HeadlessDocPreview htmlRef={htmlRef} />}</div>
        </div>
    );
};
