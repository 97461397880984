/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EBusinessPromissoryNoteCompanyEntityType {
  BusinessPromissoryNote_companyEntityType_NA = 0,
  BusinessPromissoryNote_companyEntityType_CORPORATION = 1,
  BusinessPromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY = 2,
  BusinessPromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP = 3,
  BusinessPromissoryNote_companyEntityType_LIMITED_PARTNERSHIP = 4,
  BusinessPromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION = 5,
  BusinessPromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION = 6,
  BusinessPromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  BusinessPromissoryNote_companyEntityType_NONPROFIT_CORPORATION = 8,
  BusinessPromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION = 9,
  BusinessPromissoryNote_companyEntityType_PARTNERSHIP = 10,
  UNRECOGNIZED = -1,
}

export function eBusinessPromissoryNoteCompanyEntityTypeFromJSON(
  object: any,
): EBusinessPromissoryNoteCompanyEntityType {
  switch (object) {
    case 0:
    case "BusinessPromissoryNote_companyEntityType_NA":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_NA;
    case 1:
    case "BusinessPromissoryNote_companyEntityType_CORPORATION":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_CORPORATION;
    case 2:
    case "BusinessPromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY":
      return EBusinessPromissoryNoteCompanyEntityType
        .BusinessPromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "BusinessPromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "BusinessPromissoryNote_companyEntityType_LIMITED_PARTNERSHIP":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_LIMITED_PARTNERSHIP;
    case 5:
    case "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EBusinessPromissoryNoteCompanyEntityType
        .BusinessPromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "BusinessPromissoryNote_companyEntityType_NONPROFIT_CORPORATION":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_NONPROFIT_CORPORATION;
    case 9:
    case "BusinessPromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION":
      return EBusinessPromissoryNoteCompanyEntityType
        .BusinessPromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION;
    case 10:
    case "BusinessPromissoryNote_companyEntityType_PARTNERSHIP":
      return EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EBusinessPromissoryNoteCompanyEntityType.UNRECOGNIZED;
  }
}

export function eBusinessPromissoryNoteCompanyEntityTypeToJSON(
  object: EBusinessPromissoryNoteCompanyEntityType,
): string {
  switch (object) {
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_NA:
      return "BusinessPromissoryNote_companyEntityType_NA";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_CORPORATION:
      return "BusinessPromissoryNote_companyEntityType_CORPORATION";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY:
      return "BusinessPromissoryNote_companyEntityType_LIMITED_LIABILITY_COMPANY";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP:
      return "BusinessPromissoryNote_companyEntityType_SOLE_PROPRIETORSHIP";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_LIMITED_PARTNERSHIP:
      return "BusinessPromissoryNote_companyEntityType_LIMITED_PARTNERSHIP";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION:
      return "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_CORPORATION";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION:
      return "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_ASSOCIATION";
    case EBusinessPromissoryNoteCompanyEntityType
      .BusinessPromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "BusinessPromissoryNote_companyEntityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_NONPROFIT_CORPORATION:
      return "BusinessPromissoryNote_companyEntityType_NONPROFIT_CORPORATION";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION:
      return "BusinessPromissoryNote_companyEntityType_UNINCORPORATED_ASSOCIATION";
    case EBusinessPromissoryNoteCompanyEntityType.BusinessPromissoryNote_companyEntityType_PARTNERSHIP:
      return "BusinessPromissoryNote_companyEntityType_PARTNERSHIP";
    case EBusinessPromissoryNoteCompanyEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EBusinessPromissoryNotePaymentSchedule {
  BusinessPromissoryNote_paymentSchedule_NA = 0,
  BusinessPromissoryNote_paymentSchedule_BALLOON = 1,
  BusinessPromissoryNote_paymentSchedule_AMORTIZED = 2,
  BusinessPromissoryNote_paymentSchedule_AMORTIZED_BALLOON = 3,
  UNRECOGNIZED = -1,
}

export function eBusinessPromissoryNotePaymentScheduleFromJSON(object: any): EBusinessPromissoryNotePaymentSchedule {
  switch (object) {
    case 0:
    case "BusinessPromissoryNote_paymentSchedule_NA":
      return EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_NA;
    case 1:
    case "BusinessPromissoryNote_paymentSchedule_BALLOON":
      return EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_BALLOON;
    case 2:
    case "BusinessPromissoryNote_paymentSchedule_AMORTIZED":
      return EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_AMORTIZED;
    case 3:
    case "BusinessPromissoryNote_paymentSchedule_AMORTIZED_BALLOON":
      return EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_AMORTIZED_BALLOON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EBusinessPromissoryNotePaymentSchedule.UNRECOGNIZED;
  }
}

export function eBusinessPromissoryNotePaymentScheduleToJSON(object: EBusinessPromissoryNotePaymentSchedule): string {
  switch (object) {
    case EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_NA:
      return "BusinessPromissoryNote_paymentSchedule_NA";
    case EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_BALLOON:
      return "BusinessPromissoryNote_paymentSchedule_BALLOON";
    case EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_AMORTIZED:
      return "BusinessPromissoryNote_paymentSchedule_AMORTIZED";
    case EBusinessPromissoryNotePaymentSchedule.BusinessPromissoryNote_paymentSchedule_AMORTIZED_BALLOON:
      return "BusinessPromissoryNote_paymentSchedule_AMORTIZED_BALLOON";
    case EBusinessPromissoryNotePaymentSchedule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MBusinessPromissoryNoteCompanyEntityType {
  optionDbValue: EBusinessPromissoryNoteCompanyEntityType;
  other: string;
}

export interface MBusinessPromissoryNotePaymentSchedule {
  optionDbValue: EBusinessPromissoryNotePaymentSchedule;
  other: string;
}

export interface BusinessPromissoryNote {
  companyFull: string;
  companyState: string;
  companyEntityType: MBusinessPromissoryNoteCompanyEntityType | undefined;
  effectiveDate: Date | undefined;
  lenderFull: string;
  principalAmount: number;
  governLawState: string;
  baseInterestRate: number;
  maturityDate: Date | undefined;
  defaultInterestRate: number;
  venue: string;
  companyAddress: string;
  companyNoticeEmail: string;
  companyNoticePerson: string;
  companyNoticeCopyPerson: string;
  companyNoticeCopyEmail: string;
  lenderAddress: string;
  holderNoticeEmail: string;
  holderNoticePerson: string;
  companyNoticeCopy: InternalBoolean;
  deferPayment: InternalBoolean;
  deferMonths: number;
  paymentSchedule: MBusinessPromissoryNotePaymentSchedule | undefined;
  amortizedMonths: number;
}

function createBaseMBusinessPromissoryNoteCompanyEntityType(): MBusinessPromissoryNoteCompanyEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MBusinessPromissoryNoteCompanyEntityType = {
  encode(message: MBusinessPromissoryNoteCompanyEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MBusinessPromissoryNoteCompanyEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMBusinessPromissoryNoteCompanyEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MBusinessPromissoryNoteCompanyEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eBusinessPromissoryNoteCompanyEntityTypeFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MBusinessPromissoryNoteCompanyEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eBusinessPromissoryNoteCompanyEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MBusinessPromissoryNoteCompanyEntityType>, I>>(
    base?: I,
  ): MBusinessPromissoryNoteCompanyEntityType {
    return MBusinessPromissoryNoteCompanyEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MBusinessPromissoryNoteCompanyEntityType>, I>>(
    object: I,
  ): MBusinessPromissoryNoteCompanyEntityType {
    const message = createBaseMBusinessPromissoryNoteCompanyEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMBusinessPromissoryNotePaymentSchedule(): MBusinessPromissoryNotePaymentSchedule {
  return { optionDbValue: 0, other: "" };
}

export const MBusinessPromissoryNotePaymentSchedule = {
  encode(message: MBusinessPromissoryNotePaymentSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MBusinessPromissoryNotePaymentSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMBusinessPromissoryNotePaymentSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MBusinessPromissoryNotePaymentSchedule {
    return {
      optionDbValue: isSet(object.optionDbValue)
        ? eBusinessPromissoryNotePaymentScheduleFromJSON(object.optionDbValue)
        : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MBusinessPromissoryNotePaymentSchedule): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eBusinessPromissoryNotePaymentScheduleToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MBusinessPromissoryNotePaymentSchedule>, I>>(
    base?: I,
  ): MBusinessPromissoryNotePaymentSchedule {
    return MBusinessPromissoryNotePaymentSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MBusinessPromissoryNotePaymentSchedule>, I>>(
    object: I,
  ): MBusinessPromissoryNotePaymentSchedule {
    const message = createBaseMBusinessPromissoryNotePaymentSchedule();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseBusinessPromissoryNote(): BusinessPromissoryNote {
  return {
    companyFull: "",
    companyState: "",
    companyEntityType: undefined,
    effectiveDate: undefined,
    lenderFull: "",
    principalAmount: 0,
    governLawState: "",
    baseInterestRate: 0,
    maturityDate: undefined,
    defaultInterestRate: 0,
    venue: "",
    companyAddress: "",
    companyNoticeEmail: "",
    companyNoticePerson: "",
    companyNoticeCopyPerson: "",
    companyNoticeCopyEmail: "",
    lenderAddress: "",
    holderNoticeEmail: "",
    holderNoticePerson: "",
    companyNoticeCopy: 0,
    deferPayment: 0,
    deferMonths: 0,
    paymentSchedule: undefined,
    amortizedMonths: 0,
  };
}

export const BusinessPromissoryNote = {
  encode(message: BusinessPromissoryNote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyFull !== "") {
      writer.uint32(10).string(message.companyFull);
    }
    if (message.companyState !== "") {
      writer.uint32(18).string(message.companyState);
    }
    if (message.companyEntityType !== undefined) {
      MBusinessPromissoryNoteCompanyEntityType.encode(message.companyEntityType, writer.uint32(26).fork()).ldelim();
    }
    if (message.effectiveDate !== undefined) {
      Timestamp.encode(toTimestamp(message.effectiveDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.lenderFull !== "") {
      writer.uint32(42).string(message.lenderFull);
    }
    if (message.principalAmount !== 0) {
      writer.uint32(53).float(message.principalAmount);
    }
    if (message.governLawState !== "") {
      writer.uint32(58).string(message.governLawState);
    }
    if (message.baseInterestRate !== 0) {
      writer.uint32(69).float(message.baseInterestRate);
    }
    if (message.maturityDate !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityDate), writer.uint32(74).fork()).ldelim();
    }
    if (message.defaultInterestRate !== 0) {
      writer.uint32(85).float(message.defaultInterestRate);
    }
    if (message.venue !== "") {
      writer.uint32(90).string(message.venue);
    }
    if (message.companyAddress !== "") {
      writer.uint32(98).string(message.companyAddress);
    }
    if (message.companyNoticeEmail !== "") {
      writer.uint32(106).string(message.companyNoticeEmail);
    }
    if (message.companyNoticePerson !== "") {
      writer.uint32(114).string(message.companyNoticePerson);
    }
    if (message.companyNoticeCopyPerson !== "") {
      writer.uint32(122).string(message.companyNoticeCopyPerson);
    }
    if (message.companyNoticeCopyEmail !== "") {
      writer.uint32(130).string(message.companyNoticeCopyEmail);
    }
    if (message.lenderAddress !== "") {
      writer.uint32(138).string(message.lenderAddress);
    }
    if (message.holderNoticeEmail !== "") {
      writer.uint32(146).string(message.holderNoticeEmail);
    }
    if (message.holderNoticePerson !== "") {
      writer.uint32(154).string(message.holderNoticePerson);
    }
    if (message.companyNoticeCopy !== 0) {
      writer.uint32(160).int32(message.companyNoticeCopy);
    }
    if (message.deferPayment !== 0) {
      writer.uint32(168).int32(message.deferPayment);
    }
    if (message.deferMonths !== 0) {
      writer.uint32(181).float(message.deferMonths);
    }
    if (message.paymentSchedule !== undefined) {
      MBusinessPromissoryNotePaymentSchedule.encode(message.paymentSchedule, writer.uint32(186).fork()).ldelim();
    }
    if (message.amortizedMonths !== 0) {
      writer.uint32(197).float(message.amortizedMonths);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BusinessPromissoryNote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBusinessPromissoryNote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.companyFull = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.companyState = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.companyEntityType = MBusinessPromissoryNoteCompanyEntityType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.effectiveDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.lenderFull = reader.string();
          continue;
        case 6:
          if (tag != 53) {
            break;
          }

          message.principalAmount = reader.float();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 8:
          if (tag != 69) {
            break;
          }

          message.baseInterestRate = reader.float();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.maturityDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 85) {
            break;
          }

          message.defaultInterestRate = reader.float();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.companyAddress = reader.string();
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.companyNoticeEmail = reader.string();
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.companyNoticePerson = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.companyNoticeCopyPerson = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.companyNoticeCopyEmail = reader.string();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.lenderAddress = reader.string();
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.holderNoticeEmail = reader.string();
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.holderNoticePerson = reader.string();
          continue;
        case 20:
          if (tag != 160) {
            break;
          }

          message.companyNoticeCopy = reader.int32() as any;
          continue;
        case 21:
          if (tag != 168) {
            break;
          }

          message.deferPayment = reader.int32() as any;
          continue;
        case 22:
          if (tag != 181) {
            break;
          }

          message.deferMonths = reader.float();
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.paymentSchedule = MBusinessPromissoryNotePaymentSchedule.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag != 197) {
            break;
          }

          message.amortizedMonths = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BusinessPromissoryNote {
    return {
      companyFull: isSet(object.companyFull) ? String(object.companyFull) : "",
      companyState: isSet(object.companyState) ? String(object.companyState) : "",
      companyEntityType: isSet(object.companyEntityType)
        ? MBusinessPromissoryNoteCompanyEntityType.fromJSON(object.companyEntityType)
        : undefined,
      effectiveDate: isSet(object.effectiveDate) ? fromJsonTimestamp(object.effectiveDate) : undefined,
      lenderFull: isSet(object.lenderFull) ? String(object.lenderFull) : "",
      principalAmount: isSet(object.principalAmount) ? Number(object.principalAmount) : 0,
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      baseInterestRate: isSet(object.baseInterestRate) ? Number(object.baseInterestRate) : 0,
      maturityDate: isSet(object.maturityDate) ? fromJsonTimestamp(object.maturityDate) : undefined,
      defaultInterestRate: isSet(object.defaultInterestRate) ? Number(object.defaultInterestRate) : 0,
      venue: isSet(object.venue) ? String(object.venue) : "",
      companyAddress: isSet(object.companyAddress) ? String(object.companyAddress) : "",
      companyNoticeEmail: isSet(object.companyNoticeEmail) ? String(object.companyNoticeEmail) : "",
      companyNoticePerson: isSet(object.companyNoticePerson) ? String(object.companyNoticePerson) : "",
      companyNoticeCopyPerson: isSet(object.companyNoticeCopyPerson) ? String(object.companyNoticeCopyPerson) : "",
      companyNoticeCopyEmail: isSet(object.companyNoticeCopyEmail) ? String(object.companyNoticeCopyEmail) : "",
      lenderAddress: isSet(object.lenderAddress) ? String(object.lenderAddress) : "",
      holderNoticeEmail: isSet(object.holderNoticeEmail) ? String(object.holderNoticeEmail) : "",
      holderNoticePerson: isSet(object.holderNoticePerson) ? String(object.holderNoticePerson) : "",
      companyNoticeCopy: isSet(object.companyNoticeCopy) ? internalBooleanFromJSON(object.companyNoticeCopy) : 0,
      deferPayment: isSet(object.deferPayment) ? internalBooleanFromJSON(object.deferPayment) : 0,
      deferMonths: isSet(object.deferMonths) ? Number(object.deferMonths) : 0,
      paymentSchedule: isSet(object.paymentSchedule)
        ? MBusinessPromissoryNotePaymentSchedule.fromJSON(object.paymentSchedule)
        : undefined,
      amortizedMonths: isSet(object.amortizedMonths) ? Number(object.amortizedMonths) : 0,
    };
  },

  toJSON(message: BusinessPromissoryNote): unknown {
    const obj: any = {};
    message.companyFull !== undefined && (obj.companyFull = message.companyFull);
    message.companyState !== undefined && (obj.companyState = message.companyState);
    message.companyEntityType !== undefined && (obj.companyEntityType = message.companyEntityType
      ? MBusinessPromissoryNoteCompanyEntityType.toJSON(message.companyEntityType)
      : undefined);
    message.effectiveDate !== undefined && (obj.effectiveDate = message.effectiveDate.toISOString());
    message.lenderFull !== undefined && (obj.lenderFull = message.lenderFull);
    message.principalAmount !== undefined && (obj.principalAmount = message.principalAmount);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.baseInterestRate !== undefined && (obj.baseInterestRate = message.baseInterestRate);
    message.maturityDate !== undefined && (obj.maturityDate = message.maturityDate.toISOString());
    message.defaultInterestRate !== undefined && (obj.defaultInterestRate = message.defaultInterestRate);
    message.venue !== undefined && (obj.venue = message.venue);
    message.companyAddress !== undefined && (obj.companyAddress = message.companyAddress);
    message.companyNoticeEmail !== undefined && (obj.companyNoticeEmail = message.companyNoticeEmail);
    message.companyNoticePerson !== undefined && (obj.companyNoticePerson = message.companyNoticePerson);
    message.companyNoticeCopyPerson !== undefined && (obj.companyNoticeCopyPerson = message.companyNoticeCopyPerson);
    message.companyNoticeCopyEmail !== undefined && (obj.companyNoticeCopyEmail = message.companyNoticeCopyEmail);
    message.lenderAddress !== undefined && (obj.lenderAddress = message.lenderAddress);
    message.holderNoticeEmail !== undefined && (obj.holderNoticeEmail = message.holderNoticeEmail);
    message.holderNoticePerson !== undefined && (obj.holderNoticePerson = message.holderNoticePerson);
    message.companyNoticeCopy !== undefined &&
      (obj.companyNoticeCopy = internalBooleanToJSON(message.companyNoticeCopy));
    message.deferPayment !== undefined && (obj.deferPayment = internalBooleanToJSON(message.deferPayment));
    message.deferMonths !== undefined && (obj.deferMonths = message.deferMonths);
    message.paymentSchedule !== undefined && (obj.paymentSchedule = message.paymentSchedule
      ? MBusinessPromissoryNotePaymentSchedule.toJSON(message.paymentSchedule)
      : undefined);
    message.amortizedMonths !== undefined && (obj.amortizedMonths = message.amortizedMonths);
    return obj;
  },

  create<I extends Exact<DeepPartial<BusinessPromissoryNote>, I>>(base?: I): BusinessPromissoryNote {
    return BusinessPromissoryNote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BusinessPromissoryNote>, I>>(object: I): BusinessPromissoryNote {
    const message = createBaseBusinessPromissoryNote();
    message.companyFull = object.companyFull ?? "";
    message.companyState = object.companyState ?? "";
    message.companyEntityType = (object.companyEntityType !== undefined && object.companyEntityType !== null)
      ? MBusinessPromissoryNoteCompanyEntityType.fromPartial(object.companyEntityType)
      : undefined;
    message.effectiveDate = object.effectiveDate ?? undefined;
    message.lenderFull = object.lenderFull ?? "";
    message.principalAmount = object.principalAmount ?? 0;
    message.governLawState = object.governLawState ?? "";
    message.baseInterestRate = object.baseInterestRate ?? 0;
    message.maturityDate = object.maturityDate ?? undefined;
    message.defaultInterestRate = object.defaultInterestRate ?? 0;
    message.venue = object.venue ?? "";
    message.companyAddress = object.companyAddress ?? "";
    message.companyNoticeEmail = object.companyNoticeEmail ?? "";
    message.companyNoticePerson = object.companyNoticePerson ?? "";
    message.companyNoticeCopyPerson = object.companyNoticeCopyPerson ?? "";
    message.companyNoticeCopyEmail = object.companyNoticeCopyEmail ?? "";
    message.lenderAddress = object.lenderAddress ?? "";
    message.holderNoticeEmail = object.holderNoticeEmail ?? "";
    message.holderNoticePerson = object.holderNoticePerson ?? "";
    message.companyNoticeCopy = object.companyNoticeCopy ?? 0;
    message.deferPayment = object.deferPayment ?? 0;
    message.deferMonths = object.deferMonths ?? 0;
    message.paymentSchedule = (object.paymentSchedule !== undefined && object.paymentSchedule !== null)
      ? MBusinessPromissoryNotePaymentSchedule.fromPartial(object.paymentSchedule)
      : undefined;
    message.amortizedMonths = object.amortizedMonths ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
