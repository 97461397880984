import React, { useState } from "react";

export * from "./useToggle";
export * from "./useMouse";
export * from './useMedia'
interface Options {
    onSubmit?: <T>(values: T) => void;
}

export const useForm = <T = any>(initialValues?: any, options?: Options) => {
    const [values, setValues] = useState<T>(initialValues || {});

    const onChange = (name: string, value: any) => {
        setValues({ ...values, [name]: value });
    };
    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        options?.onSubmit?.<T>(values);
    };
    return { values, onChange, onSubmit };
};
