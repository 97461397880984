/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { InternalBoolean, internalBooleanFromJSON, internalBooleanToJSON } from "../PUtils";

export const protobufPackage = "";

export enum EJointTermsPhysicalEntityType {
  JointTermsPhysical_entityType_NA = 0,
  JointTermsPhysical_entityType_CORPORATION = 1,
  JointTermsPhysical_entityType_LIMITED_LIABILITY_COMPANY = 2,
  JointTermsPhysical_entityType_SOLE_PROPRIETORSHIP = 3,
  JointTermsPhysical_entityType_LIMITED_PARTNERSHIP = 4,
  JointTermsPhysical_entityType_PROFESSIONAL_CORPORATION = 5,
  JointTermsPhysical_entityType_PROFESSIONAL_ASSOCIATION = 6,
  JointTermsPhysical_entityType_PROFESSIONAL_LIMITED_LIABILITY = 7,
  JointTermsPhysical_entityType_NONPROFIT_CORPORATION = 8,
  JointTermsPhysical_entityType_UNINCORPORATED_ASSOCIATION = 9,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalEntityTypeFromJSON(object: any): EJointTermsPhysicalEntityType {
  switch (object) {
    case 0:
    case "JointTermsPhysical_entityType_NA":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_NA;
    case 1:
    case "JointTermsPhysical_entityType_CORPORATION":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_CORPORATION;
    case 2:
    case "JointTermsPhysical_entityType_LIMITED_LIABILITY_COMPANY":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_LIMITED_LIABILITY_COMPANY;
    case 3:
    case "JointTermsPhysical_entityType_SOLE_PROPRIETORSHIP":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_SOLE_PROPRIETORSHIP;
    case 4:
    case "JointTermsPhysical_entityType_LIMITED_PARTNERSHIP":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_LIMITED_PARTNERSHIP;
    case 5:
    case "JointTermsPhysical_entityType_PROFESSIONAL_CORPORATION":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_CORPORATION;
    case 6:
    case "JointTermsPhysical_entityType_PROFESSIONAL_ASSOCIATION":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_ASSOCIATION;
    case 7:
    case "JointTermsPhysical_entityType_PROFESSIONAL_LIMITED_LIABILITY":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_LIMITED_LIABILITY;
    case 8:
    case "JointTermsPhysical_entityType_NONPROFIT_CORPORATION":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_NONPROFIT_CORPORATION;
    case 9:
    case "JointTermsPhysical_entityType_UNINCORPORATED_ASSOCIATION":
      return EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_UNINCORPORATED_ASSOCIATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalEntityType.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalEntityTypeToJSON(object: EJointTermsPhysicalEntityType): string {
  switch (object) {
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_NA:
      return "JointTermsPhysical_entityType_NA";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_CORPORATION:
      return "JointTermsPhysical_entityType_CORPORATION";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_LIMITED_LIABILITY_COMPANY:
      return "JointTermsPhysical_entityType_LIMITED_LIABILITY_COMPANY";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_SOLE_PROPRIETORSHIP:
      return "JointTermsPhysical_entityType_SOLE_PROPRIETORSHIP";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_LIMITED_PARTNERSHIP:
      return "JointTermsPhysical_entityType_LIMITED_PARTNERSHIP";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_CORPORATION:
      return "JointTermsPhysical_entityType_PROFESSIONAL_CORPORATION";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_ASSOCIATION:
      return "JointTermsPhysical_entityType_PROFESSIONAL_ASSOCIATION";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_PROFESSIONAL_LIMITED_LIABILITY:
      return "JointTermsPhysical_entityType_PROFESSIONAL_LIMITED_LIABILITY";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_NONPROFIT_CORPORATION:
      return "JointTermsPhysical_entityType_NONPROFIT_CORPORATION";
    case EJointTermsPhysicalEntityType.JointTermsPhysical_entityType_UNINCORPORATED_ASSOCIATION:
      return "JointTermsPhysical_entityType_UNINCORPORATED_ASSOCIATION";
    case EJointTermsPhysicalEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalHelpType {
  JointTermsPhysical_helpType_NA = 0,
  JointTermsPhysical_helpType_EMAIL = 1,
  JointTermsPhysical_helpType_CONTACT_PAGE = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalHelpTypeFromJSON(object: any): EJointTermsPhysicalHelpType {
  switch (object) {
    case 0:
    case "JointTermsPhysical_helpType_NA":
      return EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_NA;
    case 1:
    case "JointTermsPhysical_helpType_EMAIL":
      return EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_EMAIL;
    case 2:
    case "JointTermsPhysical_helpType_CONTACT_PAGE":
      return EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_CONTACT_PAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalHelpType.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalHelpTypeToJSON(object: EJointTermsPhysicalHelpType): string {
  switch (object) {
    case EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_NA:
      return "JointTermsPhysical_helpType_NA";
    case EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_EMAIL:
      return "JointTermsPhysical_helpType_EMAIL";
    case EJointTermsPhysicalHelpType.JointTermsPhysical_helpType_CONTACT_PAGE:
      return "JointTermsPhysical_helpType_CONTACT_PAGE";
    case EJointTermsPhysicalHelpType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalWarrantyPeriod {
  JointTermsPhysical_warrantyPeriod_NA = 0,
  JointTermsPhysical_warrantyPeriod_30D = 1,
  JointTermsPhysical_warrantyPeriod_60D = 2,
  JointTermsPhysical_warrantyPeriod_6M = 3,
  JointTermsPhysical_warrantyPeriod_1Y = 4,
  JointTermsPhysical_warrantyPeriod_LIFETIME = 5,
  JointTermsPhysical_warrantyPeriod_OTHER = 999999,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalWarrantyPeriodFromJSON(object: any): EJointTermsPhysicalWarrantyPeriod {
  switch (object) {
    case 0:
    case "JointTermsPhysical_warrantyPeriod_NA":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_NA;
    case 1:
    case "JointTermsPhysical_warrantyPeriod_30D":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_30D;
    case 2:
    case "JointTermsPhysical_warrantyPeriod_60D":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_60D;
    case 3:
    case "JointTermsPhysical_warrantyPeriod_6M":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_6M;
    case 4:
    case "JointTermsPhysical_warrantyPeriod_1Y":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_1Y;
    case 5:
    case "JointTermsPhysical_warrantyPeriod_LIFETIME":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_LIFETIME;
    case 999999:
    case "JointTermsPhysical_warrantyPeriod_OTHER":
      return EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalWarrantyPeriod.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalWarrantyPeriodToJSON(object: EJointTermsPhysicalWarrantyPeriod): string {
  switch (object) {
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_NA:
      return "JointTermsPhysical_warrantyPeriod_NA";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_30D:
      return "JointTermsPhysical_warrantyPeriod_30D";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_60D:
      return "JointTermsPhysical_warrantyPeriod_60D";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_6M:
      return "JointTermsPhysical_warrantyPeriod_6M";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_1Y:
      return "JointTermsPhysical_warrantyPeriod_1Y";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_LIFETIME:
      return "JointTermsPhysical_warrantyPeriod_LIFETIME";
    case EJointTermsPhysicalWarrantyPeriod.JointTermsPhysical_warrantyPeriod_OTHER:
      return "JointTermsPhysical_warrantyPeriod_OTHER";
    case EJointTermsPhysicalWarrantyPeriod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalDefectShipping {
  JointTermsPhysical_defectShipping_NA = 0,
  JointTermsPhysical_defectShipping_WILLNOT = 1,
  JointTermsPhysical_defectShipping_WILL = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalDefectShippingFromJSON(object: any): EJointTermsPhysicalDefectShipping {
  switch (object) {
    case 0:
    case "JointTermsPhysical_defectShipping_NA":
      return EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_NA;
    case 1:
    case "JointTermsPhysical_defectShipping_WILLNOT":
      return EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_WILLNOT;
    case 2:
    case "JointTermsPhysical_defectShipping_WILL":
      return EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_WILL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalDefectShipping.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalDefectShippingToJSON(object: EJointTermsPhysicalDefectShipping): string {
  switch (object) {
    case EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_NA:
      return "JointTermsPhysical_defectShipping_NA";
    case EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_WILLNOT:
      return "JointTermsPhysical_defectShipping_WILLNOT";
    case EJointTermsPhysicalDefectShipping.JointTermsPhysical_defectShipping_WILL:
      return "JointTermsPhysical_defectShipping_WILL";
    case EJointTermsPhysicalDefectShipping.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalReturnDays {
  JointTermsPhysical_returnDays_NA = 0,
  JointTermsPhysical_returnDays_30D = 1,
  JointTermsPhysical_returnDays_60D = 2,
  JointTermsPhysical_returnDays_90D = 3,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalReturnDaysFromJSON(object: any): EJointTermsPhysicalReturnDays {
  switch (object) {
    case 0:
    case "JointTermsPhysical_returnDays_NA":
      return EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_NA;
    case 1:
    case "JointTermsPhysical_returnDays_30D":
      return EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_30D;
    case 2:
    case "JointTermsPhysical_returnDays_60D":
      return EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_60D;
    case 3:
    case "JointTermsPhysical_returnDays_90D":
      return EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_90D;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalReturnDays.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalReturnDaysToJSON(object: EJointTermsPhysicalReturnDays): string {
  switch (object) {
    case EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_NA:
      return "JointTermsPhysical_returnDays_NA";
    case EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_30D:
      return "JointTermsPhysical_returnDays_30D";
    case EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_60D:
      return "JointTermsPhysical_returnDays_60D";
    case EJointTermsPhysicalReturnDays.JointTermsPhysical_returnDays_90D:
      return "JointTermsPhysical_returnDays_90D";
    case EJointTermsPhysicalReturnDays.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalSalesHelp {
  JointTermsPhysical_salesHelp_NA = 0,
  JointTermsPhysical_salesHelp_EMAIL = 1,
  JointTermsPhysical_salesHelp_CONTACT_PAGE = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalSalesHelpFromJSON(object: any): EJointTermsPhysicalSalesHelp {
  switch (object) {
    case 0:
    case "JointTermsPhysical_salesHelp_NA":
      return EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_NA;
    case 1:
    case "JointTermsPhysical_salesHelp_EMAIL":
      return EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_EMAIL;
    case 2:
    case "JointTermsPhysical_salesHelp_CONTACT_PAGE":
      return EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_CONTACT_PAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalSalesHelp.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalSalesHelpToJSON(object: EJointTermsPhysicalSalesHelp): string {
  switch (object) {
    case EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_NA:
      return "JointTermsPhysical_salesHelp_NA";
    case EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_EMAIL:
      return "JointTermsPhysical_salesHelp_EMAIL";
    case EJointTermsPhysicalSalesHelp.JointTermsPhysical_salesHelp_CONTACT_PAGE:
      return "JointTermsPhysical_salesHelp_CONTACT_PAGE";
    case EJointTermsPhysicalSalesHelp.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalReturnType {
  JointTermsPhysical_returnType_NA = 0,
  JointTermsPhysical_returnType_REFUND = 1,
  JointTermsPhysical_returnType_CREDIT = 2,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalReturnTypeFromJSON(object: any): EJointTermsPhysicalReturnType {
  switch (object) {
    case 0:
    case "JointTermsPhysical_returnType_NA":
      return EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_NA;
    case 1:
    case "JointTermsPhysical_returnType_REFUND":
      return EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_REFUND;
    case 2:
    case "JointTermsPhysical_returnType_CREDIT":
      return EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_CREDIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalReturnType.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalReturnTypeToJSON(object: EJointTermsPhysicalReturnType): string {
  switch (object) {
    case EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_NA:
      return "JointTermsPhysical_returnType_NA";
    case EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_REFUND:
      return "JointTermsPhysical_returnType_REFUND";
    case EJointTermsPhysicalReturnType.JointTermsPhysical_returnType_CREDIT:
      return "JointTermsPhysical_returnType_CREDIT";
    case EJointTermsPhysicalReturnType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EJointTermsPhysicalReturnShipping {
  JointTermsPhysical_returnShipping_NA = 0,
  JointTermsPhysical_returnShipping_CUST_NOPAY = 1,
  JointTermsPhysical_returnShipping_CUST_PAY = 2,
  JointTermsPhysical_returnShipping_CUST_EXCHANGE = 3,
  UNRECOGNIZED = -1,
}

export function eJointTermsPhysicalReturnShippingFromJSON(object: any): EJointTermsPhysicalReturnShipping {
  switch (object) {
    case 0:
    case "JointTermsPhysical_returnShipping_NA":
      return EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_NA;
    case 1:
    case "JointTermsPhysical_returnShipping_CUST_NOPAY":
      return EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_NOPAY;
    case 2:
    case "JointTermsPhysical_returnShipping_CUST_PAY":
      return EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_PAY;
    case 3:
    case "JointTermsPhysical_returnShipping_CUST_EXCHANGE":
      return EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_EXCHANGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EJointTermsPhysicalReturnShipping.UNRECOGNIZED;
  }
}

export function eJointTermsPhysicalReturnShippingToJSON(object: EJointTermsPhysicalReturnShipping): string {
  switch (object) {
    case EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_NA:
      return "JointTermsPhysical_returnShipping_NA";
    case EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_NOPAY:
      return "JointTermsPhysical_returnShipping_CUST_NOPAY";
    case EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_PAY:
      return "JointTermsPhysical_returnShipping_CUST_PAY";
    case EJointTermsPhysicalReturnShipping.JointTermsPhysical_returnShipping_CUST_EXCHANGE:
      return "JointTermsPhysical_returnShipping_CUST_EXCHANGE";
    case EJointTermsPhysicalReturnShipping.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MJointTermsPhysicalEntityType {
  optionDbValue: EJointTermsPhysicalEntityType;
  other: string;
}

export interface MJointTermsPhysicalHelpType {
  optionDbValue: EJointTermsPhysicalHelpType;
  other: string;
}

export interface MJointTermsPhysicalWarrantyPeriod {
  optionDbValue: EJointTermsPhysicalWarrantyPeriod;
  other: string;
}

export interface MJointTermsPhysicalDefectShipping {
  optionDbValue: EJointTermsPhysicalDefectShipping;
  other: string;
}

export interface MJointTermsPhysicalReturnDays {
  optionDbValue: EJointTermsPhysicalReturnDays;
  other: string;
}

export interface MJointTermsPhysicalSalesHelp {
  optionDbValue: EJointTermsPhysicalSalesHelp;
  other: string;
}

export interface MJointTermsPhysicalReturnType {
  optionDbValue: EJointTermsPhysicalReturnType;
  other: string;
}

export interface MJointTermsPhysicalReturnShipping {
  optionDbValue: EJointTermsPhysicalReturnShipping;
  other: string;
}

export interface JointTermsPhysical {
  websiteUrl: string;
  privacyUrl: string;
  helpEmail: string;
  helpLink: string;
  companyShort: string;
  entityState: string;
  entityType: MJointTermsPhysicalEntityType | undefined;
  emailLegal: string;
  addressLegal: string;
  governLawState: string;
  venue: string;
  lastUpdateTime: Date | undefined;
  helpType: MJointTermsPhysicalHelpType | undefined;
  salesEmail: string;
  salesLink: string;
  warrantyPeriod: MJointTermsPhysicalWarrantyPeriod | undefined;
  defectShipping: MJointTermsPhysicalDefectShipping | undefined;
  returnDays: MJointTermsPhysicalReturnDays | undefined;
  returnPolicyList: string[];
  salesHelp: MJointTermsPhysicalSalesHelp | undefined;
  returnType: MJointTermsPhysicalReturnType | undefined;
  returnPolicy: InternalBoolean;
  returnShipping: MJointTermsPhysicalReturnShipping | undefined;
}

function createBaseMJointTermsPhysicalEntityType(): MJointTermsPhysicalEntityType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalEntityType = {
  encode(message: MJointTermsPhysicalEntityType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalEntityType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalEntityType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalEntityType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalEntityTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalEntityType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalEntityTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalEntityType>, I>>(base?: I): MJointTermsPhysicalEntityType {
    return MJointTermsPhysicalEntityType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalEntityType>, I>>(
    object: I,
  ): MJointTermsPhysicalEntityType {
    const message = createBaseMJointTermsPhysicalEntityType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalHelpType(): MJointTermsPhysicalHelpType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalHelpType = {
  encode(message: MJointTermsPhysicalHelpType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalHelpType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalHelpType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalHelpType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalHelpTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalHelpType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalHelpTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalHelpType>, I>>(base?: I): MJointTermsPhysicalHelpType {
    return MJointTermsPhysicalHelpType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalHelpType>, I>>(object: I): MJointTermsPhysicalHelpType {
    const message = createBaseMJointTermsPhysicalHelpType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalWarrantyPeriod(): MJointTermsPhysicalWarrantyPeriod {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalWarrantyPeriod = {
  encode(message: MJointTermsPhysicalWarrantyPeriod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalWarrantyPeriod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalWarrantyPeriod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalWarrantyPeriod {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalWarrantyPeriodFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalWarrantyPeriod): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalWarrantyPeriodToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalWarrantyPeriod>, I>>(
    base?: I,
  ): MJointTermsPhysicalWarrantyPeriod {
    return MJointTermsPhysicalWarrantyPeriod.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalWarrantyPeriod>, I>>(
    object: I,
  ): MJointTermsPhysicalWarrantyPeriod {
    const message = createBaseMJointTermsPhysicalWarrantyPeriod();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalDefectShipping(): MJointTermsPhysicalDefectShipping {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalDefectShipping = {
  encode(message: MJointTermsPhysicalDefectShipping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalDefectShipping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalDefectShipping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalDefectShipping {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalDefectShippingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalDefectShipping): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalDefectShippingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalDefectShipping>, I>>(
    base?: I,
  ): MJointTermsPhysicalDefectShipping {
    return MJointTermsPhysicalDefectShipping.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalDefectShipping>, I>>(
    object: I,
  ): MJointTermsPhysicalDefectShipping {
    const message = createBaseMJointTermsPhysicalDefectShipping();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalReturnDays(): MJointTermsPhysicalReturnDays {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalReturnDays = {
  encode(message: MJointTermsPhysicalReturnDays, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalReturnDays {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalReturnDays();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalReturnDays {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalReturnDaysFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalReturnDays): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalReturnDaysToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalReturnDays>, I>>(base?: I): MJointTermsPhysicalReturnDays {
    return MJointTermsPhysicalReturnDays.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalReturnDays>, I>>(
    object: I,
  ): MJointTermsPhysicalReturnDays {
    const message = createBaseMJointTermsPhysicalReturnDays();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalSalesHelp(): MJointTermsPhysicalSalesHelp {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalSalesHelp = {
  encode(message: MJointTermsPhysicalSalesHelp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalSalesHelp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalSalesHelp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalSalesHelp {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalSalesHelpFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalSalesHelp): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalSalesHelpToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalSalesHelp>, I>>(base?: I): MJointTermsPhysicalSalesHelp {
    return MJointTermsPhysicalSalesHelp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalSalesHelp>, I>>(object: I): MJointTermsPhysicalSalesHelp {
    const message = createBaseMJointTermsPhysicalSalesHelp();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalReturnType(): MJointTermsPhysicalReturnType {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalReturnType = {
  encode(message: MJointTermsPhysicalReturnType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalReturnType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalReturnType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalReturnType {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalReturnTypeFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalReturnType): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalReturnTypeToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalReturnType>, I>>(base?: I): MJointTermsPhysicalReturnType {
    return MJointTermsPhysicalReturnType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalReturnType>, I>>(
    object: I,
  ): MJointTermsPhysicalReturnType {
    const message = createBaseMJointTermsPhysicalReturnType();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseMJointTermsPhysicalReturnShipping(): MJointTermsPhysicalReturnShipping {
  return { optionDbValue: 0, other: "" };
}

export const MJointTermsPhysicalReturnShipping = {
  encode(message: MJointTermsPhysicalReturnShipping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionDbValue !== 0) {
      writer.uint32(8).int32(message.optionDbValue);
    }
    if (message.other !== "") {
      writer.uint32(18).string(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MJointTermsPhysicalReturnShipping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMJointTermsPhysicalReturnShipping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.optionDbValue = reader.int32() as any;
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.other = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MJointTermsPhysicalReturnShipping {
    return {
      optionDbValue: isSet(object.optionDbValue) ? eJointTermsPhysicalReturnShippingFromJSON(object.optionDbValue) : 0,
      other: isSet(object.other) ? String(object.other) : "",
    };
  },

  toJSON(message: MJointTermsPhysicalReturnShipping): unknown {
    const obj: any = {};
    message.optionDbValue !== undefined &&
      (obj.optionDbValue = eJointTermsPhysicalReturnShippingToJSON(message.optionDbValue));
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<MJointTermsPhysicalReturnShipping>, I>>(
    base?: I,
  ): MJointTermsPhysicalReturnShipping {
    return MJointTermsPhysicalReturnShipping.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MJointTermsPhysicalReturnShipping>, I>>(
    object: I,
  ): MJointTermsPhysicalReturnShipping {
    const message = createBaseMJointTermsPhysicalReturnShipping();
    message.optionDbValue = object.optionDbValue ?? 0;
    message.other = object.other ?? "";
    return message;
  },
};

function createBaseJointTermsPhysical(): JointTermsPhysical {
  return {
    websiteUrl: "",
    privacyUrl: "",
    helpEmail: "",
    helpLink: "",
    companyShort: "",
    entityState: "",
    entityType: undefined,
    emailLegal: "",
    addressLegal: "",
    governLawState: "",
    venue: "",
    lastUpdateTime: undefined,
    helpType: undefined,
    salesEmail: "",
    salesLink: "",
    warrantyPeriod: undefined,
    defectShipping: undefined,
    returnDays: undefined,
    returnPolicyList: [],
    salesHelp: undefined,
    returnType: undefined,
    returnPolicy: 0,
    returnShipping: undefined,
  };
}

export const JointTermsPhysical = {
  encode(message: JointTermsPhysical, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.websiteUrl !== "") {
      writer.uint32(10).string(message.websiteUrl);
    }
    if (message.privacyUrl !== "") {
      writer.uint32(18).string(message.privacyUrl);
    }
    if (message.helpEmail !== "") {
      writer.uint32(26).string(message.helpEmail);
    }
    if (message.helpLink !== "") {
      writer.uint32(34).string(message.helpLink);
    }
    if (message.companyShort !== "") {
      writer.uint32(42).string(message.companyShort);
    }
    if (message.entityState !== "") {
      writer.uint32(50).string(message.entityState);
    }
    if (message.entityType !== undefined) {
      MJointTermsPhysicalEntityType.encode(message.entityType, writer.uint32(58).fork()).ldelim();
    }
    if (message.emailLegal !== "") {
      writer.uint32(66).string(message.emailLegal);
    }
    if (message.addressLegal !== "") {
      writer.uint32(74).string(message.addressLegal);
    }
    if (message.governLawState !== "") {
      writer.uint32(82).string(message.governLawState);
    }
    if (message.venue !== "") {
      writer.uint32(90).string(message.venue);
    }
    if (message.lastUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdateTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.helpType !== undefined) {
      MJointTermsPhysicalHelpType.encode(message.helpType, writer.uint32(106).fork()).ldelim();
    }
    if (message.salesEmail !== "") {
      writer.uint32(114).string(message.salesEmail);
    }
    if (message.salesLink !== "") {
      writer.uint32(122).string(message.salesLink);
    }
    if (message.warrantyPeriod !== undefined) {
      MJointTermsPhysicalWarrantyPeriod.encode(message.warrantyPeriod, writer.uint32(130).fork()).ldelim();
    }
    if (message.defectShipping !== undefined) {
      MJointTermsPhysicalDefectShipping.encode(message.defectShipping, writer.uint32(138).fork()).ldelim();
    }
    if (message.returnDays !== undefined) {
      MJointTermsPhysicalReturnDays.encode(message.returnDays, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.returnPolicyList) {
      writer.uint32(154).string(v!);
    }
    if (message.salesHelp !== undefined) {
      MJointTermsPhysicalSalesHelp.encode(message.salesHelp, writer.uint32(162).fork()).ldelim();
    }
    if (message.returnType !== undefined) {
      MJointTermsPhysicalReturnType.encode(message.returnType, writer.uint32(170).fork()).ldelim();
    }
    if (message.returnPolicy !== 0) {
      writer.uint32(176).int32(message.returnPolicy);
    }
    if (message.returnShipping !== undefined) {
      MJointTermsPhysicalReturnShipping.encode(message.returnShipping, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JointTermsPhysical {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJointTermsPhysical();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.privacyUrl = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.helpEmail = reader.string();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.helpLink = reader.string();
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.companyShort = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.entityState = reader.string();
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.entityType = MJointTermsPhysicalEntityType.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.emailLegal = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.addressLegal = reader.string();
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.governLawState = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.venue = reader.string();
          continue;
        case 12:
          if (tag != 98) {
            break;
          }

          message.lastUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.helpType = MJointTermsPhysicalHelpType.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag != 114) {
            break;
          }

          message.salesEmail = reader.string();
          continue;
        case 15:
          if (tag != 122) {
            break;
          }

          message.salesLink = reader.string();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.warrantyPeriod = MJointTermsPhysicalWarrantyPeriod.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.defectShipping = MJointTermsPhysicalDefectShipping.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag != 146) {
            break;
          }

          message.returnDays = MJointTermsPhysicalReturnDays.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag != 154) {
            break;
          }

          message.returnPolicyList.push(reader.string());
          continue;
        case 20:
          if (tag != 162) {
            break;
          }

          message.salesHelp = MJointTermsPhysicalSalesHelp.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.returnType = MJointTermsPhysicalReturnType.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag != 176) {
            break;
          }

          message.returnPolicy = reader.int32() as any;
          continue;
        case 23:
          if (tag != 186) {
            break;
          }

          message.returnShipping = MJointTermsPhysicalReturnShipping.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JointTermsPhysical {
    return {
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      privacyUrl: isSet(object.privacyUrl) ? String(object.privacyUrl) : "",
      helpEmail: isSet(object.helpEmail) ? String(object.helpEmail) : "",
      helpLink: isSet(object.helpLink) ? String(object.helpLink) : "",
      companyShort: isSet(object.companyShort) ? String(object.companyShort) : "",
      entityState: isSet(object.entityState) ? String(object.entityState) : "",
      entityType: isSet(object.entityType) ? MJointTermsPhysicalEntityType.fromJSON(object.entityType) : undefined,
      emailLegal: isSet(object.emailLegal) ? String(object.emailLegal) : "",
      addressLegal: isSet(object.addressLegal) ? String(object.addressLegal) : "",
      governLawState: isSet(object.governLawState) ? String(object.governLawState) : "",
      venue: isSet(object.venue) ? String(object.venue) : "",
      lastUpdateTime: isSet(object.lastUpdateTime) ? fromJsonTimestamp(object.lastUpdateTime) : undefined,
      helpType: isSet(object.helpType) ? MJointTermsPhysicalHelpType.fromJSON(object.helpType) : undefined,
      salesEmail: isSet(object.salesEmail) ? String(object.salesEmail) : "",
      salesLink: isSet(object.salesLink) ? String(object.salesLink) : "",
      warrantyPeriod: isSet(object.warrantyPeriod)
        ? MJointTermsPhysicalWarrantyPeriod.fromJSON(object.warrantyPeriod)
        : undefined,
      defectShipping: isSet(object.defectShipping)
        ? MJointTermsPhysicalDefectShipping.fromJSON(object.defectShipping)
        : undefined,
      returnDays: isSet(object.returnDays) ? MJointTermsPhysicalReturnDays.fromJSON(object.returnDays) : undefined,
      returnPolicyList: Array.isArray(object?.returnPolicyList)
        ? object.returnPolicyList.map((e: any) => String(e))
        : [],
      salesHelp: isSet(object.salesHelp) ? MJointTermsPhysicalSalesHelp.fromJSON(object.salesHelp) : undefined,
      returnType: isSet(object.returnType) ? MJointTermsPhysicalReturnType.fromJSON(object.returnType) : undefined,
      returnPolicy: isSet(object.returnPolicy) ? internalBooleanFromJSON(object.returnPolicy) : 0,
      returnShipping: isSet(object.returnShipping)
        ? MJointTermsPhysicalReturnShipping.fromJSON(object.returnShipping)
        : undefined,
    };
  },

  toJSON(message: JointTermsPhysical): unknown {
    const obj: any = {};
    message.websiteUrl !== undefined && (obj.websiteUrl = message.websiteUrl);
    message.privacyUrl !== undefined && (obj.privacyUrl = message.privacyUrl);
    message.helpEmail !== undefined && (obj.helpEmail = message.helpEmail);
    message.helpLink !== undefined && (obj.helpLink = message.helpLink);
    message.companyShort !== undefined && (obj.companyShort = message.companyShort);
    message.entityState !== undefined && (obj.entityState = message.entityState);
    message.entityType !== undefined &&
      (obj.entityType = message.entityType ? MJointTermsPhysicalEntityType.toJSON(message.entityType) : undefined);
    message.emailLegal !== undefined && (obj.emailLegal = message.emailLegal);
    message.addressLegal !== undefined && (obj.addressLegal = message.addressLegal);
    message.governLawState !== undefined && (obj.governLawState = message.governLawState);
    message.venue !== undefined && (obj.venue = message.venue);
    message.lastUpdateTime !== undefined && (obj.lastUpdateTime = message.lastUpdateTime.toISOString());
    message.helpType !== undefined &&
      (obj.helpType = message.helpType ? MJointTermsPhysicalHelpType.toJSON(message.helpType) : undefined);
    message.salesEmail !== undefined && (obj.salesEmail = message.salesEmail);
    message.salesLink !== undefined && (obj.salesLink = message.salesLink);
    message.warrantyPeriod !== undefined && (obj.warrantyPeriod = message.warrantyPeriod
      ? MJointTermsPhysicalWarrantyPeriod.toJSON(message.warrantyPeriod)
      : undefined);
    message.defectShipping !== undefined && (obj.defectShipping = message.defectShipping
      ? MJointTermsPhysicalDefectShipping.toJSON(message.defectShipping)
      : undefined);
    message.returnDays !== undefined &&
      (obj.returnDays = message.returnDays ? MJointTermsPhysicalReturnDays.toJSON(message.returnDays) : undefined);
    if (message.returnPolicyList) {
      obj.returnPolicyList = message.returnPolicyList.map((e) => e);
    } else {
      obj.returnPolicyList = [];
    }
    message.salesHelp !== undefined &&
      (obj.salesHelp = message.salesHelp ? MJointTermsPhysicalSalesHelp.toJSON(message.salesHelp) : undefined);
    message.returnType !== undefined &&
      (obj.returnType = message.returnType ? MJointTermsPhysicalReturnType.toJSON(message.returnType) : undefined);
    message.returnPolicy !== undefined && (obj.returnPolicy = internalBooleanToJSON(message.returnPolicy));
    message.returnShipping !== undefined && (obj.returnShipping = message.returnShipping
      ? MJointTermsPhysicalReturnShipping.toJSON(message.returnShipping)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<JointTermsPhysical>, I>>(base?: I): JointTermsPhysical {
    return JointTermsPhysical.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JointTermsPhysical>, I>>(object: I): JointTermsPhysical {
    const message = createBaseJointTermsPhysical();
    message.websiteUrl = object.websiteUrl ?? "";
    message.privacyUrl = object.privacyUrl ?? "";
    message.helpEmail = object.helpEmail ?? "";
    message.helpLink = object.helpLink ?? "";
    message.companyShort = object.companyShort ?? "";
    message.entityState = object.entityState ?? "";
    message.entityType = (object.entityType !== undefined && object.entityType !== null)
      ? MJointTermsPhysicalEntityType.fromPartial(object.entityType)
      : undefined;
    message.emailLegal = object.emailLegal ?? "";
    message.addressLegal = object.addressLegal ?? "";
    message.governLawState = object.governLawState ?? "";
    message.venue = object.venue ?? "";
    message.lastUpdateTime = object.lastUpdateTime ?? undefined;
    message.helpType = (object.helpType !== undefined && object.helpType !== null)
      ? MJointTermsPhysicalHelpType.fromPartial(object.helpType)
      : undefined;
    message.salesEmail = object.salesEmail ?? "";
    message.salesLink = object.salesLink ?? "";
    message.warrantyPeriod = (object.warrantyPeriod !== undefined && object.warrantyPeriod !== null)
      ? MJointTermsPhysicalWarrantyPeriod.fromPartial(object.warrantyPeriod)
      : undefined;
    message.defectShipping = (object.defectShipping !== undefined && object.defectShipping !== null)
      ? MJointTermsPhysicalDefectShipping.fromPartial(object.defectShipping)
      : undefined;
    message.returnDays = (object.returnDays !== undefined && object.returnDays !== null)
      ? MJointTermsPhysicalReturnDays.fromPartial(object.returnDays)
      : undefined;
    message.returnPolicyList = object.returnPolicyList?.map((e) => e) || [];
    message.salesHelp = (object.salesHelp !== undefined && object.salesHelp !== null)
      ? MJointTermsPhysicalSalesHelp.fromPartial(object.salesHelp)
      : undefined;
    message.returnType = (object.returnType !== undefined && object.returnType !== null)
      ? MJointTermsPhysicalReturnType.fromPartial(object.returnType)
      : undefined;
    message.returnPolicy = object.returnPolicy ?? 0;
    message.returnShipping = (object.returnShipping !== undefined && object.returnShipping !== null)
      ? MJointTermsPhysicalReturnShipping.fromPartial(object.returnShipping)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
