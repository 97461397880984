import React from "react";
import { Popover } from "components";
import { useAppSelector } from "store";

function NotificationsBadge({ className }: { className?: string }) {
    return <div className={`${className} w-2 h-2 bg-[#fccb11] rounded-full`} />;
}

const NotificationsIcon = (props: { badge: boolean }) => {
    const { badge } = props;
    return (
        <div data-name="NotificationsIcon" className="cursor-pointer relative">
            <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <filter
                        x="-43.3%"
                        y="-65.4%"
                        width="186.7%"
                        height="252.1%"
                        filterUnits="objectBoundingBox"
                        id="9m0dwoehdb"
                    >
                        <feOffset dx="4" in="SourceAlpha" result="shadowOffsetInner1" />
                        <feComposite
                            in="shadowOffsetInner1"
                            in2="SourceAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                            result="shadowInnerInner1"
                        />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0" in="shadowInnerInner1" />
                    </filter>
                    <filter
                        x="-14.8%"
                        y="-16.1%"
                        width="129.6%"
                        height="132.2%"
                        filterUnits="objectBoundingBox"
                        id="fh5tby7jxd"
                    >
                        <feOffset dx="4" in="SourceAlpha" result="shadowOffsetInner1" />
                        <feComposite
                            in="shadowOffsetInner1"
                            in2="SourceAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                            result="shadowInnerInner1"
                        />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0" in="shadowInnerInner1" />
                    </filter>
                    <path d="M5.917 16.443a3.057 3.057 0 1 0 6.115 0" id="lyrwx3z34a" />
                    <path
                        d="M8.908 0a6.694 6.694 0 0 1 6.694 6.694v4.498l2.174 3.75a1 1 0 0 1-.865 1.5H1a1 1 0 0 1-.875-1.484l2.089-3.766V6.694A6.694 6.694 0 0 1 8.908 0z"
                        id="q8aps82qpc"
                    />
                </defs>
                <g fill="none" fillRule="evenodd" strokeLinejoin="round">
                    <g transform="translate(.848 1.268)">
                        <use fill="#D5DFFE" xlinkHref="#lyrwx3z34a" />
                        <use fill="#000" filter="url(#9m0dwoehdb)" xlinkHref="#lyrwx3z34a" />
                        <use stroke="#111C5B" strokeWidth="1.3" xlinkHref="#lyrwx3z34a" />
                    </g>
                    <g transform="translate(.848 1.268)">
                        <use fill="#D5DFFE" xlinkHref="#q8aps82qpc" />
                        <use fill="#000" filter="url(#fh5tby7jxd)" xlinkHref="#q8aps82qpc" />
                        <use stroke="#111C5B" strokeWidth="1.3" xlinkHref="#q8aps82qpc" />
                    </g>
                </g>
            </svg>
            {badge && <NotificationsBadge className="absolute right-full top-full" />}
        </div>
    );
};
export function NotificationsWidget() {
    const notifications = useAppSelector((state) => state.notifications.list);

    return (
        <Popover sideOffset={20} align="end" element={<NotificationsIcon badge={notifications.length > 0} />}>
            {!!notifications.length && (
                <div className="bg-white  rounded text-darkIndigo shadow-md">
                    {notifications.map((item, index) => {
                        return (
                            <div key={index} className="w-72 px-4 py-2 last:border-b-0 border-dashed border-b border-[#c9d8fd]">
                                <div className="text-lg font-semibold">{item.title}</div>
                                <div className="text-base mt-2">{item.message}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Popover>
    );
}
