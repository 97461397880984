import { AxiosResponse } from "axios";
import { PDesign, PTemplate } from "proto/PTemplate";
import { PGetAllTracks, PTemplateGetResponse, PTemplatesGetResponse } from "../proto/PApi";
import { PHelpInWizard, PLegalTopicsSurvey } from "../proto/PTracking";
import { request } from "./ApiCommon";

export function createTemplate(successCallback: Function) {
    request("/api/v1/template", {
        method: "PUT",
    })
        ?.then((response) => successCallback(PTemplate.fromJSON(response.data)))
        .catch(() => {}); // todo
}

export function getAllTemplates(successCallback: (reps: PTemplatesGetResponse) => void) {
    request("/api/v1/template", {
        method: "GET",
    })
        ?.then((response) => successCallback(PTemplatesGetResponse.fromJSON(response.data)))
        .catch(() => {}); // todo
}
export function getTemplate(id: string, successCallback: (reps: PTemplateGetResponse) => void) {
    request("/api/v1/template/" + id, {
        method: "GET",
    })
        ?.then((response) => successCallback(PTemplateGetResponse.fromJSON(response.data)))
        .catch(() => {}); // todo
}

export function updateTemplate(id: string, temp: PTemplate, successCallback: (reps: PTemplateGetResponse) => void) {
    request("/api/v1/template/" + id, {
        method: "POST",
        data: PTemplate.toJSON(temp),
    })
        ?.then((response) => successCallback(PTemplateGetResponse.fromJSON(response.data)))
        .catch(() => {}); // todo
}

export function saveTemplate(id: string, url: string, successCallback: (reps: PTemplateGetResponse) => void) {
    request("/api/v1/template/" + id + "/save", {
        method: "POST",
        data: { url },
    })
        ?.then((response) => successCallback(PTemplateGetResponse.fromJSON(response.data)))
        .catch(() => {}); // todo
}

export const TemplateApi = { saveTemplate, createTemplate, getAllTemplates, getTemplate, updateTemplate };
